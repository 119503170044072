import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReplyStore from '../../../../ChatReplyStore';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../../ConversationStore/Types';
import { findLinkBy } from '../../../../Fetch';
import ChatReplyMenuButton from './ChatReplyMenuButton';

interface Props {
  chatReplyStore: ChatReplyStore;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
}

const findCreateReplyLink = findLinkBy({ rel: 'replies', method: 'post' });

const ReplyToThisMessage: React.FC<Props> = ({ chatReplyStore, messageResource }) =>
  just(messageResource.links)
    .andThen(findCreateReplyLink)
    .map(() => <ChatReplyMenuButton chatReplyStore={chatReplyStore} />)
    .getOrElse(() => <></>);

export default observer(ReplyToThisMessage);
