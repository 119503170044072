import { observer } from 'mobx-react';
import { when } from '@execonline-inc/maybe-adapter';
import { Drawer, useOpenable } from '@execonline-inc/execonline-ui';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import MainNavigation from './MainNavigation';
import UseCaseNavigation from './UseCaseNavigation';

export interface Props {
  isOpen: boolean;
  user: CurrentUserResource;
  onClose: () => void;
}

function SidebarNavigation({ isOpen, onClose, user }: Props) {
  const { isOpen: isSubMenuOpen, onClose: onSubMenuClose, onOpen: onSubMenuOpen } = useOpenable();

  const handleClose = () => {
    onClose();
    onSubMenuClose();
  };

  return (
    <Drawer open={isOpen} onClose={handleClose} placement="left">
      {when(isSubMenuOpen, true)
        .map(() => <UseCaseNavigation onClose={handleClose} onSubMenuClose={onSubMenuClose} />)
        .getOrElse(() => (
          <MainNavigation user={user} onClose={handleClose} onSubMenuOpen={onSubMenuOpen} />
        ))}
    </Drawer>
  );
}

export default observer(SidebarNavigation);
