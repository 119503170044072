import * as React from 'react';
import { observer } from 'mobx-react';
import CoachSelectOption from './CoachSelectOption';
import * as style from './style.module.css';
import CoachSelectStore from './Store';
import CoachSelectReactions from './Reactions';
import SegmentStore from '../../../../../SegmentStore';
import AutoSaveReactions from '../../../Reactions/AutoSaveReactions';
import { CoachSelectFormField } from '../../../Types';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: CoachSelectFormField;
  uuid: string;
}

class CoachSelect extends React.Component<Props> {
  coachSelectStore: CoachSelectStore = new CoachSelectStore(this.props.uuid);

  componentDidMount() {
    this.coachSelectStore.initialize(this.props.segmentStore, this.props.fieldDescription);
  }

  render() {
    switch (this.coachSelectStore.state.kind) {
      case 'waiting':
      case 'initializing':
        return <CoachSelectReactions store={this.coachSelectStore} fireImmediately={true} />;
      case 'ready':
      case 'auto-saving':
        const { fieldDescription } = this.props;
        return (
          <div className={style.root}>
            {fieldDescription.options.map((option) =>
              option.resource
                .map((resource) => (
                  <CoachSelectOption
                    key={resource.payload.id}
                    coachSelectStore={this.coachSelectStore}
                    coachProfileResource={resource}
                    image={option.image}
                  />
                ))
                .getOrElse(() => <></>)
            )}
            <AutoSaveReactions
              store={this.coachSelectStore}
              segmentStore={this.props.segmentStore}
              debounceDelay={1000}
            />
            <CoachSelectReactions store={this.coachSelectStore} />
          </div>
        );
    }
  }
}

export default observer(CoachSelect);
