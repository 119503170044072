import * as React from 'react';
import { observer } from 'mobx-react';
import { CoachingSurveyButtonNode } from './Types';
import SurveyModalStore from '../../SurveyModal/Modal/Store';
import CoachingSurveyModal from '../../CoachingSurveyModal';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';
import { T } from '../../../Translations';

interface Props {
  node: CoachingSurveyButtonNode;
}
class CoachingSurveyButtonEmbeddable extends React.Component<Props> {
  surveyModalStore = new SurveyModalStore(this.props.node.surveyTrackingResource);

  render() {
    return (
      <>
        <button
          className={style.buttonSecondaryWithIcon}
          onClick={() => this.surveyModalStore.reopenSurvey()}
        >
          <T kind="Provide Feedback" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </button>
        <CoachingSurveyModal
          surveyModalStore={this.surveyModalStore}
          surveyLaunchkind="manual-launch"
        />
      </>
    );
  }
}

export default observer(CoachingSurveyButtonEmbeddable);
