import { first } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { AjaxResponse } from 'ajaxian';
import { Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import CountryRegionOptionsStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import ProfileFormStore from '../ProfileFormStore';
import { Link } from '../Resource/Types';
import {
  countryRegionOptionsResourceDecoder,
  countryRegionOptionsResourceDecoderWithErrors,
} from './Decoders';
import { CountryRegionOptionsResource, CountryRegionOptionsState } from './Types';

interface Props {
  store: CountryRegionOptionsStore;
  profileStore: ProfileFormStore;
}

export interface BadCountryIdError {
  kind: 'bad-country-id';
}

type SubmissionError = MissingLinkError | AppyError | BadCountryIdError;

const handleSubmissionError = (store: CountryRegionOptionsStore) => {
  store.error('You cannot perform this operation at this time.');
};

const handleBadStatus = (
  response: AjaxResponse,
  error: AppyError,
  store: CountryRegionOptionsStore,
) => {
  countryRegionOptionsResourceDecoderWithErrors.decodeJson(response.body).cata({
    Ok: (resource) => store.readyWithErrors(resource, nothing()),
    Err: () => handleError(store, error),
  });
};

export const handleServerErrors =
  (store: CountryRegionOptionsStore) =>
  (error: SubmissionError): void => {
    switch (error.kind) {
      case 'bad-status':
        handleBadStatus(error.response, error, store);
        break;
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'bad-payload':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        handleError(store, error);
        break;
      case 'missing-link-error':
      case 'bad-country-id':
        handleSubmissionError(store);
        break;
      default:
        assertNever(error);
    }
  };

const setRegions =
  (store: CountryRegionOptionsStore) =>
  (countryRegionOptionsResource: CountryRegionOptionsResource) =>
    first(countryRegionOptionsResource.payload.countryRegionOptions)
      .map((_) => store.readyWithRegions(countryRegionOptionsResource, nothing(), nothing()))
      .getOrElse(() => store.readyWithNoRegions(countryRegionOptionsResource, nothing()));

const countryIdT = (countryId: Maybe<string>) =>
  toTask<SubmissionError, string>({ kind: 'bad-country-id' })(countryId);

interface NewLinkArgs {
  originalLink: Link;
  countryId: string;
}

const newLink = ({ originalLink, countryId }: NewLinkArgs): Link => ({
  ...originalLink,
  href: originalLink.href.replace(':id', countryId),
});

const loadRegionOptions = (
  countryId: Maybe<string>,
  store: CountryRegionOptionsStore,
  profileStore: ProfileFormStore,
) => {
  Task.succeed<SubmissionError, ReadonlyArray<Link>>(profileStore.links)
    .assign('originalLink', findLinkT('country-region-options'))
    .assign('countryId', countryIdT(countryId))
    .map(newLink)
    .andThen(callApi(countryRegionOptionsResourceDecoder, {}))
    .fork(handleServerErrors(store), setRegions(store));
};

class CountryRegionOptionsStoreReactions extends ErrorActionableReaction<
  CountryRegionOptionsStore,
  CountryRegionOptionsState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: CountryRegionOptionsState): void => {
    const { store, profileStore } = this.props;
    switch (state.kind) {
      case 'loading':
        loadRegionOptions(state.countryId, store, profileStore);
        break;
      case 'ready-with-regions':
      case 'ready-without-regions':
        profileStore.profileFormResource.map((resource) =>
          profileStore.regionsLoaded(resource, profileStore.avatarLink),
        );
        break;
      case 'waiting':
      case 'ready-with-errors':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default CountryRegionOptionsStoreReactions;
