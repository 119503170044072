import { noop } from '@kofno/piper';
import i18next, { TFunction } from '@prebsch-exo/i18next';
import { Task } from 'taskarian';
import { CouldNotReloadError, locationReload } from '../Location';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';

export interface LocaleChangeSkipped {
  kind: 'locale-change-skipped';
}

const localeChangeSkipped = (): LocaleChangeSkipped => ({
  kind: 'locale-change-skipped',
});

export interface I18nextInteractionError {
  kind: 'i18next-interaction-error';
  err: unknown;
}

export const i18nextInteractionError = (err: unknown): I18nextInteractionError => ({
  kind: 'i18next-interaction-error',
  err,
});

export type LocaleChangeError = LocaleChangeSkipped | I18nextInteractionError | CouldNotReloadError;

const whenSelectedLanguageDiffersFromCurrent = (
  selectedLanguage: SupportedLanguageCode,
): Task<LocaleChangeSkipped, SupportedLanguageCode> =>
  new Task((reject, resolve) => {
    i18next.language === selectedLanguage
      ? reject(localeChangeSkipped())
      : resolve(selectedLanguage);
    return noop;
  });

const changeLocale = (
  languageCode: SupportedLanguageCode,
): Task<I18nextInteractionError, TFunction> =>
  Task.fromPromise(() => i18next.changeLanguage(languageCode)).mapError<I18nextInteractionError>(
    i18nextInteractionError,
  );

export const changeLocaleT = (locale: SupportedLanguageCode): Task<LocaleChangeError, void> =>
  Task.succeed<LocaleChangeError, SupportedLanguageCode>(locale)
    .andThen(whenSelectedLanguageDiffersFromCurrent)
    .andThen(changeLocale)
    .andThen(locationReload);
