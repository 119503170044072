import { observer } from 'mobx-react';
import * as React from 'react';
import { L } from '../../Translations';
import * as style from './style.module.css';
import { SectionEventDatesNode } from './Types';
interface Props {
  node: SectionEventDatesNode;
}

const SectionEventDates: React.FC<Props> = ({ node }) => {
  return (
    <div>
      {node.sectionEvents.map((event) => (
        <span key={event.payload.id} className={style.date}>
          <L localizeable={event.payload.start_at} format="long-date-and-time" />
        </span>
      ))}
    </div>
  );
};

export default observer(SectionEventDates);
