import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReplyNotifications from '../../../ReplyNotifications/index';

class ReplyNotificationsRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/index.html" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/calendar" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/resources" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/teams/:teamsId" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/team" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/teams" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/programs/:programId" exact={true} render={() => <ReplyNotifications />} />
        <Route
          path="/program_chats/:programsId"
          exact={true}
          render={() => <ReplyNotifications />}
        />
        <Route path="/program_chat" exact={true} render={() => <ReplyNotifications />} />
        <Route path="/communities" exact={true} render={() => <ReplyNotifications />} />
      </Switch>
    );
  }
}

export default ReplyNotificationsRouter;
