import { emptyFragment } from '@execonline-inc/execonline-ui';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import ConversationPresence from '../../ConversationPresence';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import { TeamsStoreContext } from '../../TeamsStore/TeamsStoreContext';
import { T } from '../../Translations';
import TeamBreadCrumb from '../TeamProfile/TeamBreadCrumb';
import ResumeProgram from '../TeamProfile/TeamChat/ResumeProgram';
import * as style from '../TeamProfile/style.module.css';
import Breadcrumb from './Breadcrumb';
import TeamMembers from './TeamMembers';

interface Props {
  teamId: number;
}

function TeamMembersContent({ teamId }: Props) {
  const teamsStore = useContext(TeamsStoreContext);
  return (
    <WithCurrentUser
      children={(currentUserResource) =>
        teamsStore
          .teamResource(teamId)
          .map((teamResource) => (
            <>
              <Breadcrumb teamResource={teamResource} teamId={teamId} />
              <div className={style.resumeProgramButton}>
                <ResumeProgram courseId={teamResource.payload.courseId} />
              </div>
              <div className={style.teamMemberBody}>
                <div className={style.newBreadcrumb}>
                  <div>
                    <TeamBreadCrumb teamResource={teamResource} links={currentUserResource.links} />
                  </div>
                  <div className={style.subMember}>
                    <T kind="Members" />
                  </div>
                </div>
                <GlobalPresenceContext.Consumer>
                  {(presenceStore) => (
                    <ConversationPresence
                      conversationResource={
                        teamResource.payload.teamChat.payload.conversationResource
                      }
                      currentUserResource={currentUserResource}
                      channelName={teamResource.payload.teamChat.payload.messagingChannel}
                      chatablePersonResourceList={nothing()}
                      client={(presenceMembersStore) => (
                        <TeamMembers
                          teamMembers={teamResource.payload.teamMembers}
                          globalPresence={presenceStore}
                          presenceMembersStore={presenceMembersStore}
                        />
                      )}
                    />
                  )}
                </GlobalPresenceContext.Consumer>
              </div>
            </>
          ))
          .getOrElse(emptyFragment)
      }
    />
  );
}

export default observer(TeamMembersContent);
