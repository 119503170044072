import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Typography, cn, emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  description: Maybe<AlreadyTranslatedText>;
  className?: string;
}

function ExperienceDescription({ className, description }: Props) {
  return description
    .map((description) => (
      <div
        className={cn(
          'inline-block w-fit rounded-[3px] border border-white px-3 py-1.5',
          className,
        )}
      >
        <Typography
          className="tracking-[0.2px]"
          color="white"
          variant="bodySmall"
          fontWeight="medium"
          as="p"
        >
          <AlreadyTranslated content={description} />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceDescription);
