import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { Link, Resource } from '../../../Resource/Types';
import { VideoAssetResource } from '../../Video/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  links: ReadonlyArray<Link>;
}

interface Ready {
  kind: 'ready';
  resourcesListResource: ResourcesListResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (links: ReadonlyArray<Link>): Loading => ({
  kind: 'loading',
  links,
});

export const ready = (resourcesListResource: ResourcesListResource): Ready => ({
  kind: 'ready',
  resourcesListResource,
});

export type LiveMeetingRecordingsState = Waiting | Loading | Ready | Error;

export interface Video {
  kind: 'video';
  title: string;
  id: number;
  videoAssetResource: VideoAssetResource;
  important: boolean;
  assetFileExtension: Maybe<string>;
  programId: number;
  description: Maybe<string>;
}

export interface Document {
  kind: 'document';
  title: string;
  assetFileExtension: Maybe<string>;
  description: Maybe<string>;
}

export type DocumentResource = Resource<Document>;
export type VideoResource = Resource<Video>;

export type ResourceResource = DocumentResource | VideoResource;

export interface Resources {
  id: number;
  resources: Maybe<NonEmptyList<ResourceResource>>;
  title: string;
}

export type ResourcesResource = Resource<Resources>;

export type ResourcesListResource = Resource<ResourcesResource[]>;

const isDocumentResource = (resource: ResourceResource): resource is DocumentResource => {
  switch (resource.payload.kind) {
    case 'document':
      return true;
    case 'video':
      return false;
  }
};

interface ResourceResourceCata<T> {
  document: (r: DocumentResource) => T;
  video: (r: VideoResource) => T;
}

export function cata<T>(fold: ResourceResourceCata<T>, r: ResourceResource): T {
  return isDocumentResource(r) ? fold.document(r) : fold.video(r);
}
