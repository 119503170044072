import { IconListItem } from '../Types';
import * as style from './style.module.css';
import { T } from '../../../Translations';

interface Props {
  item: IconListItem;
}

const IconListDropdownItem = ({ item }: Props) => {
  return (
    <li className={style.root}>
      <a className={style.itemLink} href={item.href} target="_blank" rel="noopener noreferrer">
        {item.icon}
        <T kind={item.name} />
      </a>
    </li>
  );
};

export default IconListDropdownItem;
