import { Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { experienceDetailsRoute } from '../../../../../ClientRoutes';
import NativeLink from '../../../../NativeLink';
import { useDiscoveryContext } from '../../../DiscoveryContext';
import { handleEnterKeyDown, handleMouseDown } from '../../Types';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

function ExperienceTitle() {
  const discoveryContextState = useDiscoveryContext();
  const { invitationUuid, experienceResource } = useExperiencePopoverContext();
  const { experienceId } = experienceResource.payload;

  switch (discoveryContextState.kind) {
    case 'learn-more':
      return (
        <Typography className="text-gray-800" variant="subtitleMedium" as="h5" fontWeight="medium">
          <AlreadyTranslated content={experienceResource.payload.title} />
        </Typography>
      );
    case 'all-experiences':
    case 'direct':
    case 'dynamic-recommendation':
    case 'group-coaching-groups-recommendation':
    case 'learning-design':
    case 'none':
    case 'recommendation-engine':
      return (
        <NativeLink
          to={experienceDetailsRoute(invitationUuid, experienceId, discoveryContextState)}
        >
          {(onClick) => (
            <Typography
              className="cursor-pointer text-gray-800"
              tabIndex={0}
              variant="subtitleMedium"
              as="h5"
              fontWeight="medium"
              onClick={handleMouseDown(onClick)}
              onKeyDown={handleEnterKeyDown(onClick)}
            >
              <AlreadyTranslated content={experienceResource.payload.title} />
            </Typography>
          )}
        </NativeLink>
      );
  }
}

export default observer(ExperienceTitle);
