import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import { ActiveProgram, CoachingProductDetails } from '../../../ProgramStore/Types';
import * as style from '../../Dashboard/style.module.css';
import ModalStore from '../../Modal/Store';
import Orientation from './Orientation';
import OrientationModal from './Orientation/OrientationModal';

interface Props {
  activeProgram: ActiveProgram;
  store: ProgramStore;
  coachingProductDetails: CoachingProductDetails;
}

class ActiveCoachingProductOverview extends React.Component<Props, {}> {
  modalStore = new ModalStore();

  render() {
    const { store, activeProgram, coachingProductDetails } = this.props;

    return (
      <div className={style.program} data-test-active-program={true}>
        <OrientationModal modalStore={this.modalStore} programStore={store} />
        <Orientation
          programStore={store}
          modalStore={this.modalStore}
          activeProgram={activeProgram}
          coachingProductDetails={coachingProductDetails}
        />
      </div>
    );
  }
}

export default observer(ActiveCoachingProductOverview);
