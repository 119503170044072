import { observer } from 'mobx-react';
import { fromEmpty } from 'maybeasy';
import {
  Chip,
  ChipColor,
  Typography,
  cn,
  emptyFragment,
  TypographyColor,
} from '@execonline-inc/execonline-ui';
import { CompetencyResource } from '../../../Common/Experience/Types';
import { AlreadyTranslated } from '@execonline-inc/translations';

interface Props {
  competencies: CompetencyResource[];
  className?: string;
  chipColor?: ChipColor;
  textColor?: TypographyColor;
}

function ExperienceCompetencies({
  className,
  competencies,
  chipColor = 'gray',
  textColor = 'black',
}: Props) {
  return fromEmpty(competencies)
    .map((competencies) => (
      <div className={cn('flex flex-wrap gap-2 sm:gap-3', className)}>
        {competencies.map((competency) => (
          <Chip
            key={competency.payload.id}
            className="flex-wrap bg-inherit px-3 py-1.5"
            variant="outlined"
            radius="md"
            color={chipColor}
            size="sm"
          >
            <Typography color={textColor} variant="bodySmall" fontWeight="medium">
              <AlreadyTranslated content={competency.payload.name} />
            </Typography>
          </Chip>
        ))}
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceCompetencies);
