import * as React from 'react';
import { observer } from 'mobx-react';

import Button from './Button';
import FreebusyStore from '../../../../../FreebusyStore';

interface Props {
  freebusyStore: FreebusyStore;
}

class FreebusyActiveButton extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.freebusyStore.loading();
  }

  render() {
    return <Button freebusyStore={this.props.freebusyStore} />;
  }
}

export default observer(FreebusyActiveButton);
