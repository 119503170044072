import * as React from 'react';
import { observer } from 'mobx-react';
import { fromNullable } from 'maybeasy';
import ThemeContext from '../../../Themes/ThemeContext';
import { ColorPalette } from '../../../Themes/ThemeContext/Types';
import { setIconColor } from '../helpers';
import SVGIcon from '../SVGIcon';

interface Props {
  color?: keyof ColorPalette;
}

const ArrowLeftIcon: React.FC<Props> = ({ color }) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <SVGIcon viewBox="0 0 32 32">
            <path
              fill={setIconColor(fromNullable(color), theme)}
              d="M 19.03125 4.28125 L 8.03125 15.28125 L 7.34375 16 L 8.03125 16.71875 L 19.03125 27.71875 L 20.46875 26.28125 L 10.1875 16 L 20.46875 5.71875 Z "
            />
          </SVGIcon>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default observer(ArrowLeftIcon);
