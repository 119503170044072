import { warn } from '@execonline-inc/logging';
import { seconds } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import Pusher from 'pusher-js';
import * as React from 'react';
import { Task } from 'taskarian';
import { handleAppyError } from '../ErrorHandling';
import { warnAndNotify } from '../Honeybadger';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import NotificationSource from '../NotificationSource';
import {
  MissingPusherSettings,
  PusherAuthHeaderError,
  PusherConnectionError,
  PusherError,
  connectToPusher,
  pusherErrorDecoder,
} from '../Pusher';
import { PusherSettingsResource } from '../ToolingsStore/Types';
import Reactions from './Reactions';
import SocketBanner from './SocketBanner';
import SocketContext, { socketDetails } from './SocketContext';
import Store from './Store';
import { connected, connecting, disconnected, initialized, unavailable } from './Types';

type PusherMountError =
  | MissingLinkError
  | MissingPusherSettings
  | PusherConnectionError
  | PusherAuthHeaderError;

interface Props {
  pusherSettingsResource: PusherSettingsResource;
}

interface State {}

const handlePusherError =
  (store: Store) =>
  (error: PusherMountError): void => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify(
          error.kind,
          `Could not create a secure pusher connection because there was not auth link`,
          error,
        );
        break;
      case 'missing-pusher-settings':
        warnAndNotify(
          error.kind,
          `Could not create a pusher connection. Settings are not available.`,
          error,
        );
        break;
      case 'pusher-connection-error':
        warnAndNotify(
          error.kind,
          `Could not create a pusher connection. There was a javascript execption initializing Pusher`,
          error,
        );
        break;
      case 'pusher-auth-header-error':
        warnAndNotify(error.kind, handleAppyError(error.error), error);
        break;
      default:
        assertNever(error);
    }
    store.error(`Realtime features are not available at this time`);
  };

const bindPusherEvents =
  (store: Store) =>
  (s: Pusher): Pusher => {
    s.connection.bind('connected', () => store.ready(connected(s)));
    s.connection.bind('connecting', () => store.ready(connecting(s)));
    s.connection.bind('unavailable', () => store.ready(unavailable(s)));
    s.connection.bind('connecting_in', (delay: number) => store.connectingIn(seconds(delay)));
    s.bind('error', (err: unknown) => {
      pusherErrorDecoder
        .decodeAny(err)
        .do((data: PusherError) => warn(`Pusher error: ${data.error.data.code}`));
      store.error('Realtime features are not available at this time');
    });
    s.connection.bind('failed', () => {
      warnAndNotify(
        'PusherConnectionFailure',
        'Pusher connection failed. <down>Channels not supported on this platform.',
        s,
      );
      store.error('Websockets not supported on this platform');
    });
    s.connection.bind('disconnected', () => store.ready(disconnected()));
    return s;
  };

class Socket extends React.Component<Props, State> {
  private socketStore = new Store();

  componentDidMount() {
    (Pusher as any).logToConsole = true;

    Task.succeed<PusherMountError, PusherSettingsResource>(this.props.pusherSettingsResource)
      .map(({ payload: { key, cluster }, links }) => ({ key, cluster, links }))
      .assign('link', ({ links }) => findLinkT('auth', links))
      .andThen(connectToPusher)
      .map(bindPusherEvents(this.socketStore))
      .fork(handlePusherError(this.socketStore), (socket) =>
        this.socketStore.ready(initialized(socket)),
      );
  }

  componentWillUnmount() {
    this.socketStore.disconnect();
  }

  render() {
    return (
      <>
        <SocketBanner store={this.socketStore} />
        <SocketContext.Provider value={socketDetails(this.socketStore)}>
          {this.props.children}
        </SocketContext.Provider>
        <Reactions store={this.socketStore} />
        <NotificationSource store={this.socketStore} />
      </>
    );
  }
}

export default Socket;
