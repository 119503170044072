import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import LectureSlideStore from '../../../../LectureSlideStore';
import { LectureSegment } from '../../../../LectureStore/Types';
import { T } from '../../../../Translations';
import ArrowLeftIcon from '../../../Cutesies/ArrowLeftIcon';
import ArrowRightIcon from '../../../Cutesies/ArrowRightIcon';
import * as style from '../style.module.css';
import ScrollReactions from './ScrollReactions';
import VideoPositionReactions from './VideoPositionReactions';

interface Props {
  segment: LectureSegment;
  kettle: Kettle;
}

@observer
class Slides extends React.Component<Props, {}> {
  elementToScroll: React.RefObject<HTMLDivElement> = React.createRef();
  slideWidth: number = 0;

  private slideStore: LectureSlideStore;

  imageClass = (current: boolean) => (current ? style.activeSlide : style.slide);
  slideLabel = (current: boolean) => (current ? <T kind="Current" /> : <></>);

  constructor(props: Props) {
    super(props);
    this.slideStore = new LectureSlideStore(props.segment, props.kettle);
  }

  componentDidMount() {
    if (this.elementToScroll.current) {
      this.elementToScroll.current.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount() {
    if (this.elementToScroll.current) {
      this.elementToScroll.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  setSlideWidth = (el: HTMLDivElement) => {
    if (this.slideWidth || !el) {
      return;
    }
    const width = el.getBoundingClientRect().width;
    const slideStyle = window.getComputedStyle(el);
    const marginLeft = slideStyle.marginLeft || '';
    const marginRight = slideStyle.marginRight || '';
    const margin = parseFloat(marginLeft) + parseFloat(marginRight);
    this.slideWidth = width + margin;
  };

  handleScroll = (event: Event) => {
    if (!(event.target instanceof HTMLDivElement)) {
      return;
    }
    this.slideStore.updateCurrentScroll(event.target.scrollLeft);
  };

  render() {
    return (
      <div className={style.slidesContainer} data-test-slides={true}>
        {this.slideStore.showPrevArrow && (
          <div
            className={style.prevSlide}
            onClick={() => this.slideStore.scroll('left')}
            data-test-prev-slide={true}
          >
            <ArrowLeftIcon />
          </div>
        )}
        <div className={style.slidesScrollContainer} ref={this.elementToScroll}>
          <div className={style.slides}>
            {this.slideStore.visibleSlides.map((slide) => (
              <div
                className={this.imageClass(this.slideStore.isCurrent(slide))}
                key={slide.payload.id}
                ref={this.setSlideWidth}
                data-test-slide={slide.payload.id}
              >
                <div className={style.slideLabel} data-test-slide-label={true}>
                  {this.slideLabel(this.slideStore.isCurrent(slide))}
                </div>
                <img
                  src={slide.payload.url}
                  alt={slide.payload.title}
                  className={style.slideImage}
                  onClick={() => this.slideStore.seekTo(slide)}
                  data-test-slide-img={true}
                />
              </div>
            ))}
          </div>
        </div>
        {this.slideStore.showNextArrow(this.elementToScroll.current) && (
          <div
            className={style.nextSlide}
            onClick={() => this.slideStore.scroll('right')}
            data-test-next-slide={true}
          >
            <ArrowRightIcon />
          </div>
        )}
        <ScrollReactions
          store={this.slideStore}
          elementToScroll={this.elementToScroll.current}
          distanceToScroll={this.slideWidth}
          fireImmediately={true}
        />
        <VideoPositionReactions
          store={this.slideStore}
          elementToScroll={this.elementToScroll.current}
          slideWidth={this.slideWidth}
        />
      </div>
    );
  }
}

export default Slides;
