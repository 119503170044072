import { emptyFragment } from '@execonline-inc/execonline-ui';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import EnrollmentStore from '../../Enrollment/Store';
import Experiences from './Experiences';
import { ExperiencesContext } from './ExperiencesContext';
import ExperiencesStore from './ExperiencesStore';
import { ProductCollections } from './ProductCollections';

interface Props {
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
}

function ProductCollectionsAndExperiences({
  invitationUuid,
  enrollmentStore,
  experiencesStore,
}: Props) {
  useEffect(() => {
    enrollmentStore.settingUp(experiencesStore.useCase);
  }, [experiencesStore.useCase]);

  switch (experiencesStore.state.kind) {
    case 'experiences':
      return (
        <ExperiencesContext.Provider value={experiencesStore}>
          <Experiences
            invitationUuid={invitationUuid}
            searchValue={experiencesStore.state.search}
            displayType={experiencesStore.state.resource.payload.useCase.displayType}
            experienceResources={experiencesStore.state.experiences}
            enrollmentStore={enrollmentStore}
          />
        </ExperiencesContext.Provider>
      );
    case 'product-collections':
      return (
        <ExperiencesContext.Provider value={experiencesStore}>
          <ProductCollections
            invitationUuid={invitationUuid}
            searchValue={experiencesStore.state.search}
            productCollections={experiencesStore.productCollections}
            enrollmentStore={enrollmentStore}
          />
        </ExperiencesContext.Provider>
      );
    case 'product-collections-and-experiences':
      return (
        <ExperiencesContext.Provider value={experiencesStore}>
          <ProductCollections
            invitationUuid={invitationUuid}
            searchValue={experiencesStore.state.search}
            productCollections={experiencesStore.productCollections}
            enrollmentStore={enrollmentStore}
          />
          <Experiences
            invitationUuid={invitationUuid}
            searchValue={experiencesStore.state.search}
            displayType={experiencesStore.state.resource.payload.useCase.displayType}
            experienceResources={experiencesStore.state.experiences}
            enrollmentStore={enrollmentStore}
          />
        </ExperiencesContext.Provider>
      );
    case 'error':
    case 'loading':
    case 'waiting':
    case 'searching':
      return emptyFragment();
    default:
      assertNever(experiencesStore.state);
  }
}

export default observer(ProductCollectionsAndExperiences);
