import * as React from 'react';
import { observer } from 'mobx-react';
import CoachTeamsSummaryStore from './CoachCommunitySummaryStore';
import * as style from '../../TeamSummary/style.module.css';
import { T } from '../../../Translations';
import LoadAllCommunities from './LoadAllCommunities';
import SummaryContent from './SummaryContent';

interface Props {
  coachTeamsSummaryStore: CoachTeamsSummaryStore;
}

const CommunitySummary: React.FC<Props> = ({ coachTeamsSummaryStore }) => {
  return (
    <>
      {coachTeamsSummaryStore.communitiesResource
        .map((communitiesResource) => (
          <>
            <p className={style.resultCount}>
              <T
                kind="Showing {{currentCount}} of {{totalCount}} results"
                currentCount={communitiesResource.metadata.perPage}
                totalCount={communitiesResource.metadata.resultsCount}
              />
            </p>
            {communitiesResource.payload.map((communityResource) => (
              <SummaryContent communityResource={communityResource} />
            ))}
            <LoadAllCommunities
              communitiesResource={communitiesResource}
              coachTeamsSummaryStore={coachTeamsSummaryStore}
            />
          </>
        ))
        .getOrElse(() => (
          <div>
            <T kind="No team found" />
          </div>
        ))}
    </>
  );
};

export default observer(CommunitySummary);
