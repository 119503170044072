import * as React from 'react';
import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import SurveyModalStore from './Modal/Store';
import SurveyModalImpl from './SurveyModalImpl';
import SurveyVideoReactions from './SurveyVideoReactions';
import ReopenSurveyButton from './ReopenSurveyButton';
import SurveyModalReactions from './Modal/Store/SurveyModalReactions';
import { SurveyCompletionTrackingResource } from '../ReqHlsVideo/Types';
import { just } from 'maybeasy';

export interface Props {
  kettle: Kettle;
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

@observer
class SurveyModal extends React.Component<Props, {}> {
  surveyModalStore = new SurveyModalStore(this.props.surveyTrackingResource);

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { surveyTrackingResource } = this.props;
    return (
      <>
        <ReopenSurveyButton store={this.surveyModalStore} />
        <SurveyModalImpl store={this.surveyModalStore} />
        <SurveyModalReactions
          store={this.surveyModalStore}
          fireImmediately={true}
          kettle={just(this.props.kettle)}
        />
        <SurveyVideoReactions
          store={this.props.kettle}
          fireImmediately={true}
          launchPosition={surveyTrackingResource.payload.launchPosition}
          surveyModalStore={this.surveyModalStore}
        />
      </>
    );
  }
}

export default SurveyModal;
