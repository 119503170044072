import { observer } from 'mobx-react';
import * as React from 'react';
import { ResourceResource } from '../../Types';
import DocumentResource from './DocumentResource';
import UserUploadedDocumentResource from './UserUploadedDocumentResource';
import VideoResource from './VideoResource';

interface Props {
  resource: ResourceResource;
  moduleId: number;
  segmentId: number;
}

const ProgramResourcesSegmentResource: React.FC<Props> = ({ resource, moduleId, segmentId }) => {
  switch (resource.payload.kind) {
    case 'document':
      return <DocumentResource document={resource.payload} links={resource.links} />;
    case 'video':
      return <VideoResource video={resource.payload} moduleId={moduleId} segmentId={segmentId} />;
    case 'user-uploaded-document':
      return <UserUploadedDocumentResource upload={resource.payload} />;
  }
};

export default observer(ProgramResourcesSegmentResource);
