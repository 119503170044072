import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

function BeginnableCardBody() {
  return (
    <Typography
      className="tracking-wide text-[#5E5E5E]"
      variant="subtitleSmall"
      fontWeight="medium"
      as="p"
    >
      <T kind="You are currently enrolled in this experience." />
    </Typography>
  );
}

export default observer(BeginnableCardBody);
