import { Time } from '@execonline-inc/time';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import RenderContext from '../RenderContext';
import TimeToCompleteRenderer from './TimeToCompleteRenderer';

interface Props {
  ttc: Maybe<Time>;
}

const TimeToComplete: React.FC<Props> = ({ ttc }) => {
  return (
    <RenderContext
      render={(renderContext) => (
        <div>
          <TimeToCompleteRenderer ttc={ttc} position={renderContext.position} />
        </div>
      )}
    />
  );
};
export default TimeToComplete;
