import { observer } from 'mobx-react';
import * as React from 'react';
import SizedImageImpl from './SizedImageImpl';
import SizedImageReactions from './SizedImageReactions';
import SizedImageStore from './SizedImageStore';
import { SizedImageProps } from './Types';

// The standard React Native `Image` component has the warning:
//
//   "Note that for network and data images, you will need to manually specify the dimensions of
//   your image!"
//
// To get images to render at the proper dimensions, this component was created to query the
// image dimensions and provide those to the wrapped children.
//
class SizedImage extends React.Component<SizedImageProps> {
  store = new SizedImageStore();

  componentDidMount() {
    this.store.loading(this.props.uri);
  }

  render() {
    return (
      <>
        <SizedImageImpl store={this.store} {...this.props} />
        <SizedImageReactions store={this.store} />
      </>
    );
  }
}

export default observer(SizedImage);
