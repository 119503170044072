import { fromEmpty, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import {
  ConferenceParticipant,
  ConferenceParticipants,
} from '../../../ConferenceRosterStore/Types';
import { T } from '../../../Translations';
import Participant from '../Participant';
import * as style from '../style.module.css';

interface Props {
  participants: ConferenceParticipants;
  conferenceRosterStore: ConferenceRosterStore;
}

const ProgramParticipants: React.FC<Props> = ({ conferenceRosterStore, participants }) => {
  return fromEmpty(
    participants.filter(
      (participant: ConferenceParticipant) =>
        participant.participantType === 'Student' || participant.participantType === 'Auditor'
    )
  ).cata({
    Just: (participants: ConferenceParticipants) => (
      <div data-test-program-participants={true}>
        <h2 className={style.supPanelHeader}>
          <T kind="Program Participants" />
        </h2>
        <ul className={style.scroller}>
          {participants.map((participant: ConferenceParticipant) => (
            <Participant
              conferenceRosterStore={just(conferenceRosterStore)}
              key={participant.id}
              participant={participant}
            />
          ))}
        </ul>
      </div>
    ),
    Nothing: () => <></>,
  });
};

export default observer(ProgramParticipants);
