import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import GroupCoachSessionDateTime from '../GroupCoachSessionDateTime';
import GroupCoachSessionTitle from '../GroupCoachSessionTitle';

interface Props {
  title: Maybe<AlreadyTranslatedText>;
  startTime: Maybe<Date>;
}

function GroupCoachSessionHeader({ title, startTime }: Props) {
  return (
    <Typography className="flex flex-wrap gap-x-3 gap-y-2" variant="bodyMedium" as="p">
      <GroupCoachSessionTitle title={title} />
      <GroupCoachSessionDateTime startTime={startTime} />
    </Typography>
  );
}

export default observer(GroupCoachSessionHeader);
