import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import BeginnableCardFooter from './BeginnableCardFooter';
import ResumableCardFooter from './ResumableCardFooter';
import ReturnableCardFooter from './ReturnableCardFooter';

interface Props {
  experience: Experience;
}

function EnrollmentCardFooter({ experience }: Props) {
  switch (experience.kind) {
    case 'beginnable':
      return <BeginnableCardFooter experience={experience} />;
    case 'resumable':
      return <ResumableCardFooter experience={experience} />;
    case 'returnable':
      return <ReturnableCardFooter experience={experience} />;
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
    case 'graduated':
      return emptyFragment();
  }
}

export default observer(EnrollmentCardFooter);
