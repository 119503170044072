import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from '../FreebusyCoachingAd/style.module.css';
import { T } from '../../../Translations';
import CoachAdGraphic from '../../Cutesies/CoachAdGraphic';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import FreebusyStore from '../../../FreebusyStore';

interface Props {
  freebusyStore: FreebusyStore;
}

const AdDisplayContent: React.FC<Props> = ({ freebusyStore }) => (
  <div className={style.coaching} data-test-freebusy-program-ad={true}>
    <h2>
      <T kind="Live Project Feedback" />
    </h2>
    <div className={style.container}>
      <div className={style.graphic}>
        <div className={style.image}>
          <CoachAdGraphic />
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.title}>
          <T kind="Schedule Your Live Feedback Session" />
        </h2>
        <p className={style.description}>
          <T kind="Use our secure and convenient portal to find available times ..." />
        </p>
        <button
          className={style.buttonSecondaryWithIcon}
          data-test-freebusy-program-ad-button
          id="freebusy-program-ad-button"
          disabled={freebusyStore.schedulingDisabled}
        >
          <T kind="Schedule Your Session Now" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </button>
        <p className={style.description}>
          <T
            kind="To cancel or reschedule your session please email ..."
            link1={(content) => (
              <a className={style.mailToLink} href="mailto:support@execonline.com">
                {content}
              </a>
            )}
          />
        </p>
      </div>
    </div>
  </div>
);

export default observer(AdDisplayContent);
