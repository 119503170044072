import { observer } from 'mobx-react';
import { ExperienceResource } from '../../Types';
import ExperienceProductDetails from './ExperienceProductDetails';
import ProgramProductBadge from './ProgramProductBadge';
import ProgramSequenceProductBadge from './ProgramSequenceProductBadge';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductBadge({ experienceResource }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return <ProgramProductBadge experienceResource={experienceResource} />;
    case 'program-sequence':
      return <ProgramSequenceProductBadge />;
    case 'coaching':
    case 'group-coaching':
      return <ExperienceProductDetails experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceProductBadge);
