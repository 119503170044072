import * as React from 'react';
import { Link, Route } from 'react-router-dom';
import { ProgramResource } from '../../ProgramChatStore/Types';
import { Link as CurrentUserLinks } from '../../Resource/Types';
import BreadCrumbSeparatorIcon from '../Cutesies/BreadCrumbSeparatorIcon';
import TeamLabelBreadCrumb from '../TeamMembersList/TeamLabelBreadCrumb';
import * as style from '../TeamProfile/style.module.css';

interface Props {
  programResource: ProgramResource;
  links: ReadonlyArray<CurrentUserLinks>;
}

const ProgramBreadCrumb: React.FC<Props> = ({ programResource, links }) => (
  <h1 className={style.breadcrumb} data-test-page-header="Epcot Squad">
    <Route
      exact={true}
      path="/program_chats/:programId"
      render={() => {
        return (
          <>
            <Link to="/communities" data-test-communities-link="Communities">
              <TeamLabelBreadCrumb links={links} />
            </Link>
            <span className={style.breadcrumbSeparator}>
              <BreadCrumbSeparatorIcon />
            </span>
          </>
        );
      }}
    />
    <span data-test-program-name={programResource.payload.name}>
      {programResource.payload.name}
    </span>
  </h1>
);

export default ProgramBreadCrumb;
