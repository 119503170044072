import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import ProgramChatStore from '../../ProgramChatStore';
import TeamStore from '../../TeamStore';
import CommunitiesChats from './CommunitiesChats';

interface Props {
  conversationStore: ConversationStore;
  programChatStore: Maybe<ProgramChatStore>;
  teamStore: Maybe<TeamStore>;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  kind: 'team-chat' | 'program-chat';
}

@observer
class CommunityChat extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    this.props.conversationStore.loading();
  }

  render() {
    return (
      <CommunitiesChats
        conversationStore={this.props.conversationStore}
        programChatStore={this.props.programChatStore}
        teamStore={this.props.teamStore}
        presenceStore={this.props.presenceStore}
        kind={this.props.kind}
      />
    );
  }
}

export default CommunityChat;
