import { assertNever } from '@kofno/piper';
import { Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { State, closed, confirming, loading, open, reopened, waiting } from './Types';

class PrivacyModalStore {
  @observable
  state: State = waiting();

  programId: Maybe<number> = nothing();

  constructor(programId: Maybe<number>) {
    this.programId = programId;
  }

  @action
  load = () => {
    this.state = loading();
  };

  @action
  open = () => {
    this.state = open();
  };

  @action
  reopen = () => {
    this.state = reopened();
  };

  @action
  close = () => {
    switch (this.state.kind) {
      case 'open':
        this.state = confirming();
        break;
      case 'closed':
      case 'confirming':
      case 'loading':
      case 'waiting':
      case 'reopened':
        this.state = closed();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  confirm = () => {
    this.state = confirming();
  };

  @computed
  get stringifiedProgramId(): Maybe<string> {
    return this.programId.map((id) => id.toString());
  }
}
export default PrivacyModalStore;
