import { observer } from 'mobx-react';
import * as React from 'react';
import { fromBool } from '@execonline-inc/maybe-adapter';
import { EditorState } from 'draft-js';
import ChatMessageStore from '../../ChatMessageStore';
import * as style from '../Conference/style.module.css';
import Button from '../Form/Button';
import ChatEditor from '../TeamProfile/TeamChat/StyleControls/ChatEditor';

interface Props {
  store: ChatMessageStore;
}

const ChatMessageForm: React.FC<Props> = ({ store }) => {
  const submitChatMessage = () =>
    store.message.andThen((m) => fromBool(m.getCurrentContent().hasText(), m)).do(store.updating);

  const onChange = (value: EditorState) => {
    store.setEditorMessage(value);
  };

  const submit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    submitChatMessage();
  };

  return (
    <form className={style.form}>
      <div className={style.textarea}>
        <ChatEditor
          editorState={store.message.getOrElse(EditorState.createEmpty)}
          submitChatMessage={submitChatMessage}
          store={store}
          onChange={onChange}
          placeholder="Send a message to the group..."
        />
      </div>
      <Button
        type="submit"
        className={style.submitButton}
        value="Send"
        onClick={submit}
        disabled={store.state.kind === 'updating'}
      />
    </form>
  );
};
export default observer(ChatMessageForm);
