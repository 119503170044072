import { observer } from 'mobx-react';
import { Alert, Typography } from '../../../../../components/UI';
import { T } from '../../../../../Translations';

const LockedUserStatusMessage = () => (
  <Alert className="mb-3 sm:mb-7" status="warning" variant="outlined">
    <Typography variant="body2" color="black">
      <T kind="Your Account has been locked..." />
    </Typography>
  </Alert>
);

export default observer(LockedUserStatusMessage);
