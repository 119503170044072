import { noop } from '@kofno/piper';
import * as Cookies from 'js-cookie';
import { Task } from 'taskarian';

export interface MissingCookieError {
  kind: 'missing-cookie-error';
  message: string;
}

export interface CookieWriteError {
  kind: 'cookie-write-error';
  message: string;
}

export const missingCookieError = (message: string): MissingCookieError => ({
  kind: 'missing-cookie-error',
  message,
});

export const cookieWriteError = (message: string): CookieWriteError => ({
  kind: 'cookie-write-error',
  message,
});

export const getCookieT = (key: string): Task<MissingCookieError, string> =>
  new Task((reject, resolve) => {
    const cookie = Cookies.get(key);
    cookie ? resolve(cookie) : reject(missingCookieError(`Failed to get cookie: ${key}`));
    return noop;
  });

export const setCookieT = (key: string, value: string): Task<CookieWriteError, void> =>
  new Task((reject, resolve) => {
    try {
      resolve(Cookies.set(key, value));
    } catch (error) {
      reject(cookieWriteError(`Failed to set cookie '${key}': ${error}`));
    }
    return noop;
  });
