import { Route, Switch } from 'react-router-dom';
import Main from '../../BrowserSupport/Main';
import I18nTitle from '../../Title/I18nTitle';
import ByPrograms from './ByPrograms';
import SelectProgram from './ByPrograms/SelectProgram';
import CrossProgramResources from './CrossProgramResources';
import LiveMeetingRecordings from './LiveMeetingRecordings';
import PersonalResources from './PersonalResources';
import ResourcesDashboard from './ResourcesDashboardView';
import { ResourcesDashboardResource } from './Types';

interface Props {
  resource: ResourcesDashboardResource;
}

export function Routes({ resource }: Props) {
  const { programResources } = resource.payload;

  return (
    <Switch>
      <Route
        path="/resources"
        exact={true}
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources" />
            <ResourcesDashboard resource={resource} />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/programs"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Select Program" />
            <SelectProgram programResources={programResources} />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/programs/:programId"
        render={({ match }) => (
          <Main id="main">
            <I18nTitle title="Resources - By Programs" />
            <ByPrograms match={match} programResources={programResources} />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/needed_for_assignments"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Needed For Assignments" />
            <CrossProgramResources
              resource={resource}
              breadCrumbPath="/resources/needed_for_assignments"
              breadCrumbTitle="Needed For Assignments"
              rel="needed-for-assignment-resources"
            />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/personalized_to_you"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Personalized To You" />
            <PersonalResources resource={resource} />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/live_meeting_recordings"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Live Meeting Recordings" />
            <LiveMeetingRecordings resource={resource} />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/uploaded_by_you"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Uploaded By You" />
            <CrossProgramResources
              resource={resource}
              breadCrumbPath="/resources/uploaded_by_you"
              breadCrumbTitle="Uploaded By You"
              rel="my-uploaded-resources"
            />
          </Main>
        )}
      />
      <Route
        exact={true}
        path="/resources/uploaded_by_learning_partner"
        render={() => (
          <Main id="main">
            <I18nTitle title="Resources - Shared By A Learning Partner" />
            <CrossProgramResources
              resource={resource}
              breadCrumbPath="/resources/uploaded_by_learning_partner"
              breadCrumbTitle="Shared By A Learning Partner"
              rel="learning-partner-resources"
            />
          </Main>
        )}
      />
    </Switch>
  );
}
