import { observer } from 'mobx-react';
import CompletedProgramOverviewDate from './CompletedProgramOverviewDate';
import { ActiveProgram, CompletedProgram } from '../../ProgramStore/Types';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const ProgramCompletionDetails: React.FC<Props> = ({ programDetail }) =>
  programDetail.courseCompletedAt
    .map((courseCompletedAt) => (
      <CompletedProgramOverviewDate title="Sessions Completed" date={courseCompletedAt} />
    ))
    .getOrElse(() => <></>);

export default observer(ProgramCompletionDetails);
