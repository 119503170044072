import * as React from 'react';
import { OtherEvent as TOtherEvent } from '../../../EventsStore/Types';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';
interface Props {
  event: TOtherEvent;
}

const OtherEvent: React.FC<Props> = ({ event: { title } }) => (
  <a href="#" className={style.otherEvent} data-test-other-event={title}>
    <span className={style.eventLabel} data-test-event-title={title}>
      <NotTranslated text={title} />
    </span>
  </a>
);

export default OtherEvent;
