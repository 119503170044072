import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import * as style from '../../../../ProgramSummary/style.module.css';

interface Props {
  days: number;
}

const EndedDangerRemainderMessage = ({ days }: Props) => {
  return (
    <div
      className={`${style.scheduledCohortProgressDangerTooltip} ${style.scheduledCohortProgressTooltip}`}
      data-test-progress-tooltip={true}
    >
      <span>
        <T kind="Warning: your experience ended {{count}} day ago" count={days} />
      </span>
      <div className={style.scheduledCohortProgressDangerTooltipArrow} />
    </div>
  );
};

export default observer(EndedDangerRemainderMessage);
