import * as fetch from '@execonline-inc/fetch.private';
import { FetchError, RequestOptions } from '@execonline-inc/fetch.private';
import * as links from '@execonline-inc/links.private';
import { Method } from '@execonline-inc/links.private';
import * as resourceable from '@execonline-inc/resourceable.private';
import { assertNever, pick, pipe } from '@kofno/piper';
import type Decoder from 'jsonous';
import { useMemo, type DependencyList } from 'react';
import { ok, Result } from 'resulty';
import { Task } from 'taskarian';
import { useAbortable } from './useAbortable';

export interface Loading {
  kind: 'loading';
}

export interface Ready<T> {
  kind: 'ready';
  data: T;
}

export interface Errored<E> {
  kind: 'errored';
  error: E;
}

export type State<E, T> = Loading | Ready<T> | Errored<E>;

export const ready = <T>(data: T): Ready<T> => ({ kind: 'ready', data });
export const errored = <E>(error: E): Errored<E> => ({ kind: 'errored', error });

type LinksModule<Rel extends string, Endpoint extends string> = ReturnType<
  typeof links.initialize<Rel, Endpoint>
>;
type FetchModule<Rel extends string, Endpoint extends string> = ReturnType<
  typeof fetch.initialize<Rel, Endpoint>
>;
type ResourceableModule<Rel extends string, EndpointSchemas> = ReturnType<
  typeof resourceable.initialize<Rel, EndpointSchemas>
>;

type Type = 'application/json';
type Options = Omit<RequestOptions, 'signal'>;

export const initialize = <Rel extends string, EndpointSchemas>(
  LinksModule: LinksModule<Rel, Extract<keyof EndpointSchemas, string>>,
  FetchModule: FetchModule<Rel, Extract<keyof EndpointSchemas, string>>,
  ResourceableModule: ResourceableModule<Rel, EndpointSchemas>,
) => {
  type Endpoint = Extract<keyof EndpointSchemas, string>;
  type Links = ReadonlyArray<links.Link<Rel, Endpoint>>;

  type Link<R extends Rel, E extends Endpoint | undefined, M extends Method> = links.Link<
    R,
    E,
    M,
    Type
  >;

  const useFetcher = <E, T>(
    fetcher: (options: { signal: AbortSignal }) => Task<E, T>,
    deps: DependencyList,
  ): State<E, T> => {
    const { signal, useAbortableState, useAbortableEffect } = useAbortable();

    const [state, setState] = useAbortableState<State<E, T>>(() => ({
      kind: 'loading',
    }));

    useAbortableEffect(() => {
      switch (state.kind) {
        case 'loading':
          break;
        case 'ready':
        case 'errored':
          setState({ kind: 'loading' });
          break;
        default:
          assertNever(state);
      }
    }, [...deps]);

    useAbortableEffect(() => {
      switch (state.kind) {
        case 'loading':
          fetcher({ signal }).fork(pipe(errored, setState), pipe(ready, setState));
          break;
        case 'ready':
          break;
        case 'errored':
          console.error(`API fetch failed:`, state.error);
          break;
        default:
          assertNever(state);
      }
    }, [state]);

    return state;
  };

  const forRequest = <R extends Rel, E extends Endpoint | undefined, M extends Method>(
    signal: AbortSignal,
    link: Link<R, E, M>,
    options?: Options,
  ) => ({
    method: link.method,
    href: link.href,
    options: { ...options, signal },
  });

  const toDependencies = (
    link:
      | Link<Rel, Endpoint | undefined, Method>
      | Result<unknown, Link<Rel, Endpoint | undefined, Method>>,
  ) => {
    const normalized = 'cata' in link ? link : ok(link);

    return normalized
      .map<[string | undefined, string]>(({ endpoint, method }) => [endpoint, method])
      .getOrElseValue([undefined, '']);
  };

  function useFetchResponse<R extends Rel, E extends Endpoint | undefined, M extends Method>(
    link: Link<R, E, M>,
    options?: Options,
  ): State<fetch.FetchError, fetch.ReadResponse<string>>;

  function useFetchResponse<R extends Rel, E extends Endpoint | undefined, M extends Method>(
    link: Result<links.MissingLinkError<R, E, M, Type>, Link<R, E, M>>,
    options?: Options,
  ): State<fetch.FetchError | links.MissingLinkError<R, E, M, Type>, fetch.ReadResponse<string>>;

  function useFetchResponse<R extends Rel, E extends Endpoint | undefined, M extends Method>(
    link: Link<R, E, M> | Result<links.MissingLinkError<R, E, M, Type>, Link<R, E, M>>,
    options?: Options,
  ) {
    type Err = FetchError | links.MissingLinkError<R, E, M, Type>;
    type T = fetch.ReadResponse<string>;

    return useFetcher(({ signal }: { signal: AbortSignal }) => {
      const toRequest = (l: Link<R, E, M>) =>
        FetchModule.withoutDecoder.fetch(forRequest(signal, l, options));

      return 'cata' in link
        ? link.cata<Task<Err, T>>({ Err: Task.fail, Ok: toRequest })
        : toRequest(link);
    }, toDependencies(link));
  }

  function useFetch<R extends Rel, E extends Endpoint | undefined, M extends Method, T>(
    link: Link<R, E, M>,
    decoder: Decoder<T>,
    options?: Options,
  ): State<fetch.FetchError, T>;

  function useFetch<R extends Rel, E extends Endpoint | undefined, M extends Method, T>(
    link: Result<links.MissingLinkError<R, E, M, Type>, Link<R, E, M>>,
    decoder: Decoder<T>,
    options?: Options,
  ): State<fetch.FetchError | links.MissingLinkError<R, E, M, Type>, T>;

  function useFetch<R extends Rel, E extends Endpoint | undefined, M extends Method, T>(
    link: Link<R, E, M> | Result<links.MissingLinkError<R, E, M, Type>, Link<R, E, M>>,
    decoder: Decoder<T>,
    options?: Options,
  ) {
    type Err = FetchError | links.MissingLinkError<R, E, M, Type>;

    return useFetcher(({ signal }: { signal: AbortSignal }) => {
      const toRequest = (l: Link<R, E, M>) =>
        FetchModule.withDecoder.fetch(forRequest(signal, l, options), decoder).map(pick('data'));

      return 'cata' in link
        ? link.cata<Task<Err, T>>({ Err: Task.fail, Ok: toRequest })
        : toRequest(link);
    }, toDependencies(link));
  }

  function useFetchResource<
    R extends Rel,
    E extends Extract<keyof EndpointSchemas, string>,
    M extends keyof EndpointSchemas[E] & Method,
  >(
    link: Link<R, E, M> & { endpoint: E },
    options?: Options,
  ): State<fetch.FetchError, resourceable.ParsedResponse<EndpointSchemas, E, M>>;

  function useFetchResource<
    R extends Rel,
    E extends Extract<keyof EndpointSchemas, string>,
    M extends keyof EndpointSchemas[E] & Method,
  >(
    link: Result<
      links.MissingLinkError<R, string | undefined, Method, Type>,
      Link<R, E, M> & { endpoint: E }
    >,
    options?: Options,
  ): State<
    fetch.FetchError | links.MissingLinkError<R, string | undefined, Method, Type>,
    resourceable.ParsedResponse<EndpointSchemas, E, M>
  >;

  function useFetchResource<
    R extends Rel,
    E extends Extract<keyof EndpointSchemas, string>,
    M extends keyof EndpointSchemas[E] & Method,
  >(
    link:
      | (Link<R, E, M> & { endpoint: E })
      | Result<
          links.MissingLinkError<R, string | undefined, Method, Type>,
          Link<R, E, M> & { endpoint: E }
        >,
    options?: Options,
  ) {
    type Err = FetchError | links.MissingLinkError<R, string | undefined, Method, Type>;
    type T = resourceable.ParsedResponse<EndpointSchemas, E, M>;

    return useFetcher(({ signal }: { signal: AbortSignal }) => {
      const toRequest = (l: Link<R, E, M> & { endpoint: E }) =>
        ResourceableModule.fetch(l, { ...options, signal });

      return 'cata' in link
        ? link.cata<Task<Err, T>>({ Err: Task.fail, Ok: toRequest })
        : toRequest(link);
    }, toDependencies(link));
  }

  const useFetchCore = (): FetchModule<Rel, Endpoint> => {
    const { signal } = useAbortable();
    return fetch.initialize<Rel, Endpoint>(LinksModule, { ...FetchModule.config, signal });
  };

  const useFindLinkBy = <
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
  >(
    ...args: Parameters<typeof FetchModule.findLinkBy<L, R, E, M>>
  ) => {
    const { rel, endpoint, method } = args[0];
    return useMemo(() => FetchModule.findLinkBy(...args), [rel, endpoint, method]);
  };

  const useFindLinkByR = <
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
  >(
    ...args: Parameters<typeof FetchModule.findLinkByR<L, R, E, M>>
  ) => {
    const { rel, endpoint, method } = args[0];
    return useMemo(() => FetchModule.findLinkByR(...args), [rel, endpoint, method]);
  };

  const useFindLinkByT = <
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
  >(
    ...args: Parameters<typeof FetchModule.findLinkByT<L, R, E, M>>
  ) => {
    const { rel, endpoint, method } = args[0];
    return useMemo(() => FetchModule.findLinkByT(...args), [rel, endpoint, method]);
  };

  return {
    useFindLinkBy,
    useFindLinkByR,
    useFindLinkByT,
    useFetch,
    useFetchResponse,
    useFetchCore,
    useFetchResource,
  } as const;
};
