import { observer } from 'mobx-react';
import * as React from 'react';
import CalendarStore from '../../CalendarStore';
import { T } from '../../Translations';
import Loader from '../Loader';
import CalendarImpl from './CalendarImpl';
import * as style from './style.module.css';

interface Props {
  store: CalendarStore;
  headingLevel: 2 | 3;
}

const UpdateCalendarState: React.FC<Props> = ({ store, headingLevel }) => {
  const state = store.state;
  switch (state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <div className={style.loading}>
          <Loader />
        </div>
      );
    case 'navigating':
      return (
        <CalendarImpl
          headingLevel={headingLevel}
          calendarResource={state.calendarResource}
          store={store}
        >
          <div className={style.reloader}>
            <Loader />
          </div>
          <div className={style.shade} />
        </CalendarImpl>
      );
    case 'ready':
    case 'refresh':
      return (
        <CalendarImpl
          headingLevel={headingLevel}
          calendarResource={state.calendarResource}
          store={store}
        />
      );
    case 'error':
      return (
        <div>
          <T kind={state.message} />
        </div>
      );
  }
};

export default observer(UpdateCalendarState);
