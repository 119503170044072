import { observer } from 'mobx-react';
import * as React from 'react';
import { EventResource } from '../../EventsStore/Types';
import { T } from '../../Translations';
import EventSummary from '../EventSummary';
import ViewAllButton from './ViewAllButton';
import * as style from './style.module.css';

interface Props {
  events: EventResource[];
  limit: number;
  viewAllButton: boolean;
}

const UpcomingEventsImpl: React.FC<Props> = ({ events, limit, viewAllButton }) => (
  <>
    <h2 className={style.supPanelHeader} data-test-upcoming-events-title="Upcoming Events">
      <T kind="Upcoming Events" />
    </h2>
    {events.slice(0, limit).map((eventResource) => (
      <EventSummary eventResource={eventResource} key={eventResource.payload.id} />
    ))}
    {viewAllButton && <ViewAllButton />}
  </>
);

export default observer(UpcomingEventsImpl);
