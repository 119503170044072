import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { TPlainTextKey } from '../Translations';
import { SsoError } from './Types';

export const onFailure =
  ({ handleSsoError }: { handleSsoError: (err: SsoError) => void }) =>
  (err: SsoError) => {
    handleSsoError(err);
  };

export const handleSsoError =
  ({
    onSessionAlreadyEstablished,
    handler,
  }: {
    onSessionAlreadyEstablished: () => void;
    handler: (err: SsoError) => (message: TPlainTextKey) => void;
  }) =>
  (error: SsoError) => {
    const handleWithMessage = handler(error);
    switch (error.kind) {
      case 'session-already-established':
        onSessionAlreadyEstablished();
        break;
      case 'form-build-error':
        warn('The SSO submission form could not be built.', toJS(error));
        handleWithMessage('An error occurred, please try again later');
        break;
      case 'missing-cookie-error':
        warn('The CSRF cookie for the SSO flow was not found.', toJS(error));
        handleWithMessage('An error occurred, please try again later');
        break;
      case 'missing-identification-param':
        warn('The SSO URL lacks an identification parameter.', toJS(error));
        handleWithMessage('An error occurred, please try again later');
        break;
      case 'missing-param-error':
        warn('The SSO URL lacks a required parameter.', toJS(error));
        handleWithMessage('An error occurred, please try again later');
        break;
      default:
        assertNever(error);
    }
  };
