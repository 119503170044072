import { uniqBy } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { ConferenceParticipants } from '../../../ConferenceRosterStore/Types';

export interface PusherStaffPresenceMember {
  id: number;
  short_name: string;
}

export interface StaffPresenceMember {
  id: number;
  shortName: string;
}

interface Waiting {
  kind: 'waiting';
}

interface SettingStaffList {
  kind: 'setting-staff-list';
  presentParticipants: Maybe<ConferenceParticipants>;
}

export const settingStaffList = (
  presentParticipants: Maybe<ConferenceParticipants>
): SettingStaffList => ({ kind: 'setting-staff-list', presentParticipants });

interface Ready {
  kind: 'ready';
}

export const ready = (): Ready => ({
  kind: 'ready',
});

export const waiting = (): Waiting => ({ kind: 'waiting' });

export type StaffPresenceState = Waiting | SettingStaffList | Ready;

export const uniqMembers = uniqBy((m: StaffPresenceMember) => m.id);
