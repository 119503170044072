import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import { findLinkBy } from '../../Links';
import { Link } from '../../Resource/Types';
import { rootResourceStore } from '../../RootResourceStore';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import Modal from '../Modal';
import PrivacyModalStore from './PrivacyModalStore';
import * as style from './style.module.css';

interface Props {
  store: PrivacyModalStore;
  conferenceRoomResource: LocalConferenceRoomResource;
}

const ProviderSpecificPrivacyAndTerms: React.FC<{
  conferenceRoomResource: LocalConferenceRoomResource;
  termsLink: Link;
  privacyLink: Link;
}> = ({ conferenceRoomResource, termsLink, privacyLink }) => {
  switch (conferenceRoomResource.payload.kind) {
    case 'daily-live-meeting':
      return (
        <T
          kind="ExecOnline utilizes Daily technology for live video sessions. By ..."
          dtos={(content) => (
            <a
              href="https://www.daily.co/legal/terms-of-service/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {content}
            </a>
          )}
          dpp={(content) => (
            <a href="https://www.daily.co/legal/privacy/" target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          )}
          eotou={(content) => (
            <a href={termsLink.href} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          )}
          eopp={(content) => (
            <a href={privacyLink.href} target="_blank" rel="noopener noreferrer">
              {content}
            </a>
          )}
        />
      );
  }
};

function PrivacyModal({ store, conferenceRoomResource }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'loading':
    case 'closed':
    case 'confirming':
      return <></>;
    case 'reopened':
    case 'open':
      return just({})
        .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
        .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
        .map(({ privacyLink, termsLink }) => (
          <Modal title={<T kind="Live Meeting Terms and Privacy Policy" />} close={store.close}>
            <T kind="This session may be recorded and maintained on the ExecOnline ..." />
            <br />
            <br />
            <ProviderSpecificPrivacyAndTerms
              conferenceRoomResource={conferenceRoomResource}
              privacyLink={privacyLink}
              termsLink={termsLink}
            />
            <div className={style.modalButtonContainer}>
              <button
                className={style.modalButton}
                onClick={store.close}
                data-test-continue-button={true}
              >
                <T kind="Continue" />
                <div className={style.modalButtonIcon}>
                  <ArrowRightIcon />
                </div>
              </button>
            </div>
          </Modal>
        ))
        .getOrElse(() => <></>);
  }
}

export default observer(PrivacyModal);
