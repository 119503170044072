import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../../Links';
import SegmentStore from '../../../../SegmentStore';
import ReadyButton from './ReadyButton';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function PreviousAdvanceButton({ store, ...others }: Props) {
  return findLinkBy({ rel: 'previous' }, store.links)
    .map(() => <ReadyButton store={store} {...others} />)
    .getOrElse(emptyFragment);
}

export default observer(PreviousAdvanceButton);
