import { ArrowDownIcon, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { handleEnterOrSpaceKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { Link } from '../../../../Resource/Types';
import { NotTranslated } from '../../../../Translations';
import DocumentIcon from '../../../Cutesies/DocumentIcon';
import DownloadViewModal from '../../../DownloadViewModal';
import { useOpenable } from '../../../Openable';
import ImportantIcon from '../../ImportantIcon';
import { Document } from '../../Types';
import * as style from './style.module.css';

interface Props {
  document: Document;
  links: ReadonlyArray<Link>;
}

function DocumentResource({ document, links }: Props) {
  const { openableState, close, open } = useOpenable();

  return (
    <div className={style.resource}>
      <a
        className={style.content}
        onClick={open}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(open)}
        data-test-document-resource={true}
      >
        <ImportantIcon important={document.important} className={style.importantIcon} />
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.resourceDetails}>
          <span className={style.name} data-test-resource-title={true}>
            <NotTranslated text={document.title} />{' '}
            {document.assetFileExtension
              .map((assetFileExtension) => (
                <span className={style.metaData}>
                  <NotTranslated text={assetFileExtension} />
                </span>
              ))
              .getOrElse(emptyFragment)}
          </span>
          {document.description
            .map((description) => (
              <div className={style.description}>{ReactHtmlParser(description)}</div>
            ))
            .getOrElse(emptyFragment)}
        </div>
        <div className={style.download}>
          <div className={style.downloadIcon}>
            <ArrowDownIcon color="white" size="xs" />
          </div>
        </div>
      </a>
      <DownloadViewModal
        resource={{ payload: document, links }}
        close={close}
        modalState={openableState}
      />
    </div>
  );
}

export default observer(DocumentResource);
