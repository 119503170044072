import { observer } from 'mobx-react';
import LoadMoreAnnouncementsButton from './LoadMoreAnnouncementsButton';
import {
  AllAnnouncementsLoaded,
  LoadingMoreAnnouncements,
  LoadingMoreFailed,
  OnLoadMore,
  SomeAnnouncementsLoaded,
} from './UseAnnouncementsLoading';

interface AnnouncementsActionsProps {
  state:
    | AllAnnouncementsLoaded
    | SomeAnnouncementsLoaded
    | LoadingMoreAnnouncements
    | LoadingMoreFailed;
  onLoadMore: OnLoadMore;
}

function AnnouncementsActions({ state, onLoadMore }: AnnouncementsActionsProps) {
  switch (state.kind) {
    case 'all-announcements-loaded':
    case 'loading-more-failed':
      return <></>;
    case 'some-announcements-loaded':
    case 'loading-more-announcements':
      return <LoadMoreAnnouncementsButton state={state} onLoadMore={onLoadMore} />;
  }
}

export default observer(AnnouncementsActions);
