import { observer } from 'mobx-react';
import { OpenableState, emptyFragment } from '@execonline-inc/execonline-ui';
import { EnrollmentModalContextState } from '../Common';
import ExperienceEnrollmentContent from '../ExperienceEnrollmentContent';
import ExperiencePaymentContent from '../ExperiencePaymentContent';

interface Props extends EnrollmentModalContextState {
  isOpen: OpenableState;
}

function ExperienceEnrollmentModal(props: Props) {
  const { enrollmentStore } = props;

  switch (enrollmentStore.state.kind) {
    case 'waiting':
    case 'setting-up':
    case 'waiting-commerce':
    case 'payment-loading-error':
    case 'created':
    case 'creating':
    case 'error':
    case 'exiting':
      return <ExperienceEnrollmentContent {...props} />;
    case 'initializing-payment':
    case 'ready-for-payment':
      return <ExperiencePaymentContent {...props} />;
    case 'server-error':
      return emptyFragment();
  }
}

export default observer(ExperienceEnrollmentModal);
