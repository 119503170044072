import { seconds } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutChannel from '../Socket/BreakoutChannel';
import BreakoutChannelStore from '../Socket/BreakoutChannel/BreakoutChannelStore';
import Handler from '../Socket/BreakoutChannel/Handler';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';

interface Props {
  channelName: string;
  publicChannelStore: BreakoutChannelStore;
}

class BreakoutChannelImpl extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();

  componentDidMount() {
    this.props.publicChannelStore.breakout.endAt.do(endAt => {
      this.props.publicChannelStore.timeRemainingStore.startTimer(seconds(endAt));
    });
  }

  render() {
    return (
      <>
        <BreakoutChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <Handler
          publicChannelStore={this.props.publicChannelStore}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        {this.props.children}
      </>
    );
  }
}

export default observer(BreakoutChannelImpl);
