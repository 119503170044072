import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { truncate } from '../../../../../../String';
import { AlreadyTranslated, NotTranslated, T } from '../../../../../../Translations';
import ModalStore from '../../../../../Modal/Store';
import { CoachProfileResource, SelectFormFieldOptionImage } from '../../../../Types';
import CoachSelectStore from '../Store';
import IntroductoryVideo from './IntroductoryVideo';
import ProfileImage from './ProfileImage';
import CoachSelectOptionFieldValueReactions from './Reactions/CoachSelectOptionFieldValueReactions';
import CoachSelectOptionStore from './Store';
import * as style from './style.module.css';

interface Props {
  coachProfileResource: CoachProfileResource;
  image: Maybe<SelectFormFieldOptionImage>;
  coachSelectStore: CoachSelectStore;
  coachSelectOptionStore: CoachSelectOptionStore;
  modalStore: ModalStore;
  children: {
    selectButton: JSX.Element;
  };
}

const CoachTile: React.FC<Props> = ({
  coachProfileResource,
  image,
  coachSelectStore,
  coachSelectOptionStore,
  modalStore,
  children,
}) => (
  <>
    <div>
      <div className={style.coachHeading}>
        <ProfileImage image={image} />
        <p className={style.coachName}>
          <NotTranslated text={coachProfileResource.payload.name} />
        </p>
      </div>
      <div className={style.description}>
        <div className={style.descriptionHeading}>
          <T kind="Coaching Style" />
        </div>
        <div>
          <AlreadyTranslated
            content={{
              ...coachProfileResource.payload.coachingStyle,
              text: truncate(200, coachProfileResource.payload.coachingStyle.text),
            }}
          />
          <button className={style.readMoreButton} onClick={modalStore.open}>
            (
            <span className={style.readMoreButtonText}>
              <T kind="read more" />
            </span>
            )
          </button>
        </div>
      </div>
    </div>
    <div className={style.baseContentWrapper}>
      <div className={style.descriptionHeading}>
        <T kind="Introductory Video" />
      </div>
      <IntroductoryVideo
        modalStore={modalStore}
        video={coachProfileResource.payload.introductoryVideo}
      />
      {children.selectButton}
    </div>
    <CoachSelectOptionFieldValueReactions
      store={coachSelectOptionStore}
      coachSelectStore={coachSelectStore}
      fireImmediately={true}
    />
  </>
);

export default observer(CoachTile);
