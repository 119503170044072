import { explicitMaybe } from '@execonline-inc/decoders';
import Decoder, { boolean, fail, field, number, string, succeed } from 'jsonous';
import { nothing } from 'maybeasy';
import { Result, ok } from 'resulty';
import { assertionBackendResourceDecoder } from '../../../../Login/Decoders';
import { AssertionBackendResource } from '../../../../Login/Types';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { assertionBackendToUrls } from '../../../../SingleSignOn/Functions';
import { supportedLanguageCodeDecoder } from '../../../../SupportedLanguages/Decoders';
import LockedAccessStore from '../../RequireAuthentication/LockedAccessStore';
import SetPasswordStore from '../../RequireAuthentication/SetPasswordStore';
import SsoLoginStore from '../../RequireAuthentication/SsoLoginStore';
import UsePasswordStore from '../../RequireAuthentication/UsePasswordStore';
import { sharedInvitationResourceDecoder } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Decoders';
import {
  Auth,
  CommerceDirectEnrollment,
  CommerceDirectEnrollmentResource,
  RegisterInfo,
  RegisterResource,
  SsoAuth,
} from './Types';

interface AuthParams {
  assertionBackend: AssertionBackendResource;
  termsAccepted: boolean;
  accountEstablished: boolean;
}

const toAuth = ({
  assertionBackend,
  termsAccepted,
  accountEstablished,
}: AuthParams): Result<string, Auth> => {
  switch (assertionBackend.payload.kind) {
    case 'locked-access':
      return ok({ kind: 'locked-access-auth', store: new LockedAccessStore(nothing()) });
    case 'password-backend':
      return ok({
        kind: 'password-auth',
        termsAccepted,
        passwordState: accountEstablished
          ? { kind: 'password-set', store: new UsePasswordStore() }
          : { kind: 'reset-password', store: new SetPasswordStore() },
      });
    case 'third-party-backend':
      return ok<string, Omit<SsoAuth, 'urls'>>({
        kind: 'sso-auth',
        termsAccepted,
        store: new SsoLoginStore(),
      }).assign(
        'urls',
        assertionBackendToUrls(assertionBackend).mapError(
          (err) => `Missing an SSO link: ${err.rel}`,
        ),
      );
  }
};

export const commerceDirectEnrollmentDecoder: Decoder<CommerceDirectEnrollment> = succeed({})
  .assign('courseRegistrationId', field('course_registration_id', number))
  .assign('availabilityId', field('course_id', number))
  .assign('experienceId', field('experience_id', string));

export const commerceDirectEnrollmentResourceDecoder: Decoder<CommerceDirectEnrollmentResource> =
  resourceDecoder(commerceDirectEnrollmentDecoder);

const authDecoder: Decoder<Auth> = succeed({})
  .assign('assertionBackend', field('assertion_backend', assertionBackendResourceDecoder))
  .assign('termsAccepted', field('terms_accepted', boolean))
  .assign('accountEstablished', field('account_established', boolean))
  .andThen((data) => toAuth(data).cata<Decoder<Auth>>({ Err: fail, Ok: succeed }));

export const registerInfoDecoder: Decoder<RegisterInfo> = succeed({})
  .assign('userId', field('user_id', number))
  .assign('emailAddress', field('email_address', string))
  .assign('sharedInvitation', field('shared_invitation', sharedInvitationResourceDecoder))
  .assign('auth', authDecoder)
  .assign(
    'preferredLanguage',
    field('preferred_language', explicitMaybe(supportedLanguageCodeDecoder)),
  )
  .assign(
    'commerceDirectEnrollmentResource',
    field(
      'commerce_direct_registration_resource',
      explicitMaybe(commerceDirectEnrollmentResourceDecoder),
    ),
  )
  .assign('guid', field('guid', explicitMaybe(string)))
  .assign('autoEnrollProgramId', field('auto_enroll_program_id', explicitMaybe(number)));

export const registerResourceDecoder: Decoder<RegisterResource> =
  resourceDecoder(registerInfoDecoder);
