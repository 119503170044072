import { observer } from 'mobx-react';
import * as React from 'react';
import { distanceOfTimeInWords } from '../../../Time';
import { NotTranslated, T } from '../../../Translations';
import { AnnouncementResource } from '../../Decoders/Types';
import * as style from '../../style.module.css';
import Content from '../Content';

interface Props {
  announcement: AnnouncementResource;
}

const DigitalCertificateDetails: React.FC<Props> = ({ announcement }) => {
  const { payload } = announcement;

  return (
    <div className={style.details} data-test-announcement-details>
      <div className={style.timestamp}>
        <T {...distanceOfTimeInWords(new Date(), payload.createdAt)} />
      </div>
      <h3 className={style.title}>
        <NotTranslated text={payload.subject} />
      </h3>
      <Content announcement={announcement} />
    </div>
  );
};

export default observer(DigitalCertificateDetails);
