import { warn } from '@execonline-inc/logging';
import { Time, toMilliseconds } from '@execonline-inc/time';
import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Cancel, Task } from 'taskarian';
import handRaisers from '.';
import { CurrentUserResource } from '../CurrentUser/Types';
import HandRaiser from '../HandRaiser';

const every = <E, T>(
  intervalTime: Time,
  task: () => Task<E, T>,
  warn?: (err: E) => void,
): Task<never, never> => {
  const interval = toMilliseconds(intervalTime);

  return new Task((_reject, _resolve) => {
    let innerTaskCancel: Cancel | undefined;
    let timeoutHandle: any;

    const loop = () => {
      innerTaskCancel = task().fork(
        (err) => {
          if (warn) {
            warn(err);
          }
          timeoutHandle = setTimeout(loop, interval.milliseconds);
        },
        () => {
          timeoutHandle = setTimeout(loop, interval.milliseconds);
        },
      );
    };

    loop();

    return () => {
      if (innerTaskCancel) {
        innerTaskCancel();
      }
      clearTimeout(timeoutHandle);
    };
  });
};

const broadcastHandStatus =
  (currentUserResource: CurrentUserResource) => (): Task<string, string> => {
    const myHandRaiser = (id: number): Task<string, HandRaiser> =>
      handRaisers
        .findHandRaiser(id)
        .map((hr) => Task.succeed<string, HandRaiser>(hr))
        .getOrElse(() => Task.fail(`Handraiser or conference doesn't exist`));

    return Task.succeed<string, number>(currentUserResource.payload.id)
      .andThen(myHandRaiser)
      .andThen((hr) => hr.statusBroadcaster)
      .mapError((err) => `[Broadcast Hand Status] ${err}`);
  };

interface Props {
  interval: Time;
  currentUserResource: CurrentUserResource;
}

@observer
class HandStatusBroadcaster extends React.Component<Props> {
  private stop?: Cancel;

  componentDidMount() {
    const { interval } = this.props;
    this.stop = every(interval, broadcastHandStatus(this.props.currentUserResource), warn).fork(
      noop,
      noop,
    );
  }

  componentWillUnmount() {
    if (this.stop) {
      this.stop();
    }
  }

  render() {
    return <></>;
  }
}

export default HandStatusBroadcaster;
