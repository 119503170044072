import { observer } from 'mobx-react';
import * as React from 'react';
import { EventResource } from '../../EventsStore/Types';
import { T } from '../../Translations';
import CurrentEventSummary from './CurrentEventSummary';
import * as style from './style.module.css';

interface Props {
  events: EventResource[];
}

const CurrentEvents: React.FC<Props> = ({ events }) => (
  <>
    <h2 className={style.supPanelHeader} data-test-current-events-title="Events Happening Now">
      <T kind="Events Happening Now" />
    </h2>
    {events.map((resource) => (
      <CurrentEventSummary eventResource={resource} key={resource.payload.id} />
    ))}
  </>
);

export default observer(CurrentEvents);
