import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { ProfessorResource } from '../../ProfessorsStore/Types';
import { T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  professors: NonEmptyList<ProfessorResource>;
}

const ProfessorHeader: React.FC<Props> = ({ professors }) => (
  <h2 className={style.supPanelHeader} data-test-professors-title={true}>
    <T kind="Instructor" count={professors.length} />
  </h2>
);

export default observer(ProfessorHeader);
