import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert, serverErrorAlert } from '../Notifications/Types';
import { Password, validatePassword } from '../Password';
import { AlreadyTranslatedText, TPlainTextKey } from '../Translations';
import {
  creating,
  EditPasswordState,
  loading,
  ready,
  readyWithClientError,
  readyWithServerError,
  waiting,
} from './Types';

class EditPasswordStore {
  @observable
  state: EditPasswordState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  readyWithClientErrors = (
    clientError: TPlainTextKey,
    password: Maybe<string>,
    passwordConfirmation: Maybe<string>
  ) => {
    this.state = readyWithClientError(clientError, password, passwordConfirmation);
  };

  @action
  readyWithServerErrors = (
    serverError: AlreadyTranslatedText,
    password: Maybe<string>,
    passwordConfirmation: Maybe<string>
  ) => {
    this.state = readyWithServerError(serverError, password, passwordConfirmation);
  };

  @action
  clientError = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  setPassword = (password: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-error':
      case 'ready-with-server-error':
        this.state.password = fromEmpty(password);
        break;
      case 'loading':
      case 'creating':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setPasswordConfirmation = (passwordConfirmation: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-error':
      case 'ready-with-server-error':
        this.state.passwordConfirmation = fromEmpty(passwordConfirmation);
        break;
      case 'loading':
      case 'creating':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get password(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-error':
      case 'ready-with-server-error':
        return this.state.password;
      case 'creating':
        return just(this.state.password);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get passwordConfirmation(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-error':
      case 'ready-with-server-error':
        return this.state.passwordConfirmation;
      case 'creating':
        return just(this.state.passwordConfirmation);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @action
  creating = () => {
    validatePassword(this.password, this.passwordConfirmation).cata({
      Err: (err) => {
        switch (err.kind) {
          case 'password-missing': {
            this.state = readyWithClientError(
              'Please provide a password',
              this.password,
              this.passwordConfirmation
            );
            break;
          }
          case 'password-confirmation-missing': {
            this.state = readyWithClientError(
              'Please provide a password confirmation',
              this.password,
              this.passwordConfirmation
            );
            break;
          }
          case 'password-confirmation-failed': {
            this.state = readyWithClientError(
              'Password confirmation should match password',
              this.password,
              this.passwordConfirmation
            );
            break;
          }
          default:
            assertNever(err);
        }
      },
      Ok: (passwordFields: Password) => {
        this.state = creating(passwordFields.password, passwordFields.passwordConfirmation);
      },
    });
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready-with-client-error':
        return just(errorAlert({ kind: 'error', message: this.state.clientError }));
      case 'ready-with-server-error':
        return just(serverErrorAlert({ kind: 'server-error', message: this.state.serverError }));
      case 'loading':
      case 'ready':
      case 'creating':
      case 'waiting':
        return nothing();
    }
  }
}

export default EditPasswordStore;
