import { observer } from 'mobx-react';
import { Assignment, Document } from '../../../../TeamResourceStore/Types';
import { useOpenable } from '../../../Openable';
import * as style from '../../style.module.css';
import AssignmentDetails from '../AssignmentDetails';
import AssignmentModal from './AssignmentModal';
import DocumentIcon from './DocumentIcon';
import DownloadIcon from './DownloadIcon';

interface Props {
  assignment: Assignment;
  resource: Document;
}

function CompletedResource({ assignment, resource }: Props) {
  const { openableState, open, close } = useOpenable();

  switch (openableState.kind) {
    case 'closed':
      return (
        <div className={style.file} onClick={open}>
          <DocumentIcon />
          <AssignmentDetails assignment={assignment} resource={resource} />
          <DownloadIcon />
        </div>
      );
    case 'open':
      return (
        <div className={style.file}>
          <DocumentIcon />
          <AssignmentDetails assignment={assignment} resource={resource} />
          <DownloadIcon />
          <AssignmentModal close={close} assignment={assignment} />
        </div>
      );
  }
}

export default observer(CompletedResource);
