import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import ChannelAvailabilityStore from '../../../../../../../Socket/ChannelAvailabilityStore';
import PaymentWizardStore from '../PaymentWizardStore';
import PresenceBinding from './PresenceBinding';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
  store: PaymentWizardStore;
}

function PresenceHandler({ channelAvailabilityStore, store }: Props) {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <PresenceBinding channel={channel} store={store} />;
    }
    case 'not-available': {
      return emptyFragment();
    }
  }
}

export default observer(PresenceHandler);
