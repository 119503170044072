import { assertNever } from '@kofno/piper';
import { fromArray } from 'nonempty-list';
import SimpleFileUploadStore from '.';
import AttachmentUploadStore from '../AttachmentUploadStore';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { SimpleFileUploadState } from './Types';

export interface Props extends EAProps<SimpleFileUploadStore> {
  store: SimpleFileUploadStore;
  attachmentStore: AttachmentUploadStore;
}

class SimpleFileUploadReactions extends ErrorActionableReaction<
  SimpleFileUploadStore,
  SimpleFileUploadState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: SimpleFileUploadState) => {
    switch (state.kind) {
      case 'loading':
        this.props.store.readyWithoutUpload();
        break;
      case 'ready':
        fromArray([state.newUpload]).do(this.props.attachmentStore.uploadSuccessful);
        break;
      case 'upload-successful':
        this.props.store.ready(state.newUpload);
        break;
      case 'ready-with-errors':
        state.newUpload.cata({
          Just: (newUpload) => this.props.store.ready(newUpload),
          Nothing: () => this.props.store.readyWithoutUpload(),
        });
        break;
      case 'waiting':
      case 'ready-without-upload':
      case 'upload-in-progress':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default SimpleFileUploadReactions;
