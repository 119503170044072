import { equals, find } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { pick } from '@kofno/piper';
import { array, field } from 'jsonous';
import { Maybe } from 'maybeasy';
import { formFieldOutputDecoder } from '../components/EmbeddedFormFieldAsset/Decoders';
import { FormFieldOutput, FormFieldOutputValue } from '../components/EmbeddedFormFieldAsset/Types';
import { asMaybe } from '@execonline-inc/error-handling';

const decodeFormFieldOutput = (results: unknown): Maybe<FormFieldOutput[]> =>
  asMaybe(field('value', array(formFieldOutputDecoder)).decodeAny(results));

export const getFieldValue = (
  results: Maybe<JsonValue>,
  formFieldId: string,
): Maybe<FormFieldOutputValue> =>
  results
    .andThen(decodeFormFieldOutput)
    .andThen(find(({ id }) => equals(id, formFieldId)))
    .map(pick('value'));
