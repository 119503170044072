import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamResource } from '../../../../TeamsStore/Types';
import RenderContext from '../../../RenderContext';
import TeamMembers from './TeamMembers';

import TeamTitle from './TeamTitle';

interface Props {
  teamResource: TeamResource;
}

const Team: React.FC<Props> = ({ teamResource }) => {
  return (
    <div data-test-team={teamResource.payload.id}>
      <RenderContext
        render={(renderContext) => (
          <TeamTitle name={teamResource.payload.name} position={renderContext.position} />
        )}
      />
      <TeamMembers teamResource={teamResource} />
    </div>
  );
};

export default observer(Team);
