import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import * as style from '../style.module.css';
import Avatar from './Avatar';

interface Props {
  store: PersonStoreContract;
}

const Photo: React.FC<Props> = ({ store }) => (
  <div className={style.profileAvatar} data-test-popout-photo={true}>
    <Avatar store={store} />
  </div>
);

export default observer(Photo);
