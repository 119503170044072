import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSessionResource } from '../../../../../ProgramStore/Types';
import SessionRow from '../SessionRow';

interface Props {
  sessions: CoachingSessionResource[];
}

const SessionsList: React.FC<Props> = ({ sessions }) => (
  <>
    {sessions.map((session) => (
      <SessionRow key={session.payload.id} session={session} />
    ))}
  </>
);

export default observer(SessionsList);
