import { hours } from '@execonline-inc/time';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { InactivityLogoutTracker } from '../../Inactivity/Core';
import { lightTheme } from '../../Native/styles';
import Notifications from '../../Notifications';
import ThemeContext from '../../Themes/ThemeContext';
import AutoLaunchable from '../AutoLaunchable';
import * as style from '../Conference/style.module.css';
import EventConference from '../EventConference';
import SkipNavLink from '../SkipNavLink';
import TeamBreakoutConference from '../TeamBreakoutConference';
import TeamConference from '../TeamConference';
import NavigationBar from '../Platform/NavigationBar';

function ConferenceRouter() {
  InactivityLogoutTracker.useInactivityExtension(hours(2));

  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <div className={clsx(style.conferenceWrapper, 'h-auto min-h-screen w-fit')}>
          <SkipNavLink />
          <NavigationBar />
          <div className={style.conferenceNav}>
            <Notifications />
            <AutoLaunchable />
          </div>
          <Main id="main">
            <ThemeContext.Provider value={lightTheme}>
              <Switch>
                <Route path="/teams/:teamId/conference" component={TeamConference} exact={true} />
                <Route
                  path="/events/:eventId/teams/:teamId"
                  component={TeamBreakoutConference}
                  exact={true}
                />
                <Route
                  path="/events/:eventId/conference"
                  render={({ match }) => (
                    <EventConference match={match} currentUserResource={currentUserResource} />
                  )}
                  exact={true}
                />
              </Switch>
            </ThemeContext.Provider>
          </Main>
        </div>
      )}
    />
  );
}

export default observer(ConferenceRouter);
