import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../../../Experience/Types';
import { TileViewProgramExperiencePreview } from '../../../../../ProgramExperience';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
}

function OnDemandExperience({ invitationUuid, experienceResource }: Props) {
  return (
    <div className="mx-auto flex flex-col gap-y-8">
      <div className="mx-auto">
        <TileViewProgramExperiencePreview
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
        />
      </div>
    </div>
  );
}

export default observer(OnDemandExperience);
