import { observer } from 'mobx-react';
import * as React from 'react';
import NotesStore from '../../../NotesStore';
import SegmentStore from '../../../SegmentStore';
import TabStore from '../../../TabStore';
import NotesTab from '../NotesTab';
import OutlineTab from '../OutlineTab';
import ResourcesTab from '../ResourcesTab';
import * as style from '../style.module.css';
import { findLink } from '../../../LinkyLinky';

interface Props {
  tabStore: TabStore;
  notesStore: NotesStore;
  segmentStore: SegmentStore;
}

const Tabs: React.FC<Props> = ({ tabStore, notesStore, segmentStore }) => {
  const notesTab = (): React.ReactChild =>
    findLink('notes', segmentStore.links)
      .map(() => <NotesTab tabStore={tabStore} notesStore={notesStore} />)
      .getOrElseValue(<></>);

  const resourcesTab = (): React.ReactChild =>
    findLink('resource-template', segmentStore.links)
      .map(() => <ResourcesTab tabStore={tabStore} segmentStore={segmentStore} />)
      .getOrElseValue(<></>);

  return (
    <section className={style.section} data-test-tabs={true}>
      <div className={style.tabs}>
        {notesTab()}
        {resourcesTab()}
        <OutlineTab tabStore={tabStore} />
      </div>
    </section>
  );
};

export default observer(Tabs);
