import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import * as style from '../../Conference/style.module.css';
import Arrived from './Arrived';
import NotArrived from './NotArrived';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const PublicRoster: React.FC<Props> = ({ conferenceRosterStore }) => (
  <div className={style.participants} data-test-public-roster={true}>
    <Arrived conferenceRosterStore={conferenceRosterStore} />
    <NotArrived conferenceRosterStore={conferenceRosterStore} />
  </div>
);

export default observer(PublicRoster);
