import { observer } from 'mobx-react';
import { findLinkByR, useFetch } from '../../../Fetch';
import { rootResourceStore } from '../../../RootResourceStore';
import { segmentResourceDecoder } from '../../../SegmentStore/Decoders';
import SegmentContent from '../../Segment/SegmentContent';
import { useMemo } from 'react';
import SegmentStore from '../../../SegmentStore';
import Errors from './Errors';
import { useConst } from '@execonline-inc/react-hooks.private';
import SegmentPreviewContainer from './SegmentPreviewContainer';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../Translations';
import { type Link, type Method } from '@execonline-inc/links.private';
import { Endpoint } from '../../../Resourceable';
import { Rel } from '../../../Resource/Types';

interface Props {
  match: { params: { programId: string; segmentId: string } };
}

function SegmentPreview({ match: { params } }: Props) {
  const processTemplateLink =
    (programId: string, segmentId: string) =>
    (link: Link<Rel, Endpoint | undefined, Method, 'application/json'>) => ({
      ...link,
      href: link.href
        .replace('{program_id}', `${programId}`)
        .replace('{segment_id}', `${segmentId}`),
    });
  const link = useMemo(
    () =>
      findLinkByR({ rel: 'segment-preview-template' }, rootResourceStore.links).map(
        processTemplateLink(params.programId, params.segmentId),
      ),
    [],
  );

  const state = useFetch(link, segmentResourceDecoder);
  const segmentStore = useConst(() => new SegmentStore('Full'));

  switch (state.kind) {
    case 'loading':
      return (
        <SegmentPreviewContainer>
          <Typography>
            <T kind="Loading..." />
          </Typography>
        </SegmentPreviewContainer>
      );
    case 'errored':
      return (
        <SegmentPreviewContainer>
          <Typography>
            <T kind="An error occurred, please try again later" />
          </Typography>
        </SegmentPreviewContainer>
      );
    case 'ready':
      segmentStore.ready(state.data);
      return (
        <>
          <Errors segmentResource={state.data} />
          <SegmentPreviewContainer>
            <SegmentContent store={segmentStore} />
          </SegmentPreviewContainer>
        </>
      );
  }
}

export default observer(SegmentPreview);
