import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../../ConversationStore';
import { ChatMessageResource } from '../../../ConversationStore/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { personStoreFromResource } from '../../../People';
import { messageAuthor } from '../../../Person/types';
import Message from './Message';

interface Props {
  messageResource: ChatMessageResource;
  conversationStore: ConversationStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

class TeamChatMessage extends React.Component<Props> {
  render() {
    const { messageResource, conversationStore, presenceStore, lastReadMessageStore } = this.props;
    const personStore = personStoreFromResource(messageResource.embedded.author, messageAuthor);
    return (
      <Message
        personStore={personStore}
        presenceStore={presenceStore}
        messageResource={messageResource}
        conversationStore={conversationStore}
        lastReadMessageStore={lastReadMessageStore}
      />
    );
  }
}

export default observer(TeamChatMessage);
