import { Time, toMinutes } from '@execonline-inc/time';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import TimerIcon from '../Cutesies/TimerIcon';
import CompletionTime from './CompletionTime';
import * as style from './style.module.css';

interface Props {
  ttc: Maybe<Time>;
}

const TimeToCompleteWithMutedIcon: React.FC<Props> = ({ ttc }) => {
  return ttc
    .map((timeToComplete) => {
      return (
        <span data-test-time-to-complete={toMinutes(timeToComplete).minutes}>
          <div className={style.ttcModule}>
            <div className={style.iconTimerMuted}>
              <TimerIcon />
            </div>
            <CompletionTime minutes={toMinutes(timeToComplete).minutes} />
          </div>
        </span>
      );
    })
    .getOrElseValue(<span />);
};

export default TimeToCompleteWithMutedIcon;
