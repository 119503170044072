import { Card, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';
import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';

interface Props {
  index: number;
  length: number;
}

function Steps({ index, length }: Props) {
  return (
    <div className="mb-3 flex flex-col items-center">
      <Card className="w-[108px] items-center justify-center py-3">
        <Typography variant="bodyLarge" fontWeight="bold" as="span">
          <T kind="Step {{current}}" current={index + 1} />
        </Typography>
        <Typography variant="bodyLarge" fontWeight="light">
          <T length={length} kind="of {{length}}" />
        </Typography>
      </Card>
      {when(equals(index + 1, length), {})
        .map(emptyFragment)
        .getOrElse(() => (
          <div className="sm:none mx-4 h-full border-l-2 border-black/20"></div>
        ))}
    </div>
  );
}

export default observer(Steps);
