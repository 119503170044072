import { Error } from '../ErrorHandling';
import { EventResource } from '../EventsStore/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  eventResource: EventResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (eventResource: EventResource): Ready => ({
  kind: 'ready',
  eventResource,
});

export type EventState = Waiting | Loading | Ready | Error;
