import * as React from 'react';
import { CalendarEvent, Event } from '../../EventsStore/Types';
import { L, T } from '../../Translations';

interface Props {
  event: CalendarEvent | Event;
}

const Now: React.FC<Props> = ({ event }) => (
  <>
    <T kind="Now - <date/>" date={<L localizeable={event.endAt} format="long-month-day-year" />} />
    <br />
    <L localizeable={event.endAt} format="time-of-day" />
  </>
);

export default Now;
