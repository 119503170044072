import * as React from 'react';
import ProgramChatStore from '../../ProgramChatStore';
import { T } from '../../Translations';
import PresenceProgramProfileContext from './PresenceProgramProfileContext';

interface Props {
  programChatStore: ProgramChatStore;
}

const PresenceProgramProfile: React.FC<Props> = ({ programChatStore }) =>
  programChatStore.program
    .map((programResource) => (
      <PresenceProgramProfileContext
        programChatStore={programChatStore}
        programResource={programResource}
      />
    ))
    .getOrElse(() => (
      <div>
        <T kind="Program not found" />
      </div>
    ));

export default PresenceProgramProfile;
