import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { T } from '../../../Translations';
import { ProgramResource } from '../Types';
import * as style from '../style.module.css';
import ProgramCard from './ProgramCard';
import SelectProgramBreadCrumb from './SelectProgramBreadCrumb';

interface Props {
  programResources: ProgramResource[];
}

const SelectProgram: React.FC<Props> = ({ programResources }) => {
  switch (programResources.length) {
    case 0:
      return (
        <>
          <section className={style.section}>
            <SelectProgramBreadCrumb />
          </section>
          <section className={style.section}>
            <T kind="No programs found." />
          </section>
        </>
      );
    case 1:
      return <Redirect to={`/resources/programs/${programResources[0].payload.id}`} />;
    default:
      return (
        <>
          <section className={style.section}>
            <SelectProgramBreadCrumb />
          </section>
          {programResources.map((programResource) => (
            <ProgramCard key={programResource.payload.id} programResource={programResource} />
          ))}
        </>
      );
  }
};

export default observer(SelectProgram);
