import { observer } from 'mobx-react';
import { Button, ButtonProps } from '@execonline-inc/execonline-ui';
import { experienceDetailsRoute } from '../../../../../ClientRoutes';
import { T } from '../../../../../Translations';
import NativeLink from '../../../../NativeLink';
import { useDiscoveryContext } from '../../../DiscoveryContext';
import { Experience } from '../Types';

interface Props {
  invitationUuid: string;
  experience: Experience;
  buttonProps?: ButtonProps;
}

function LearnMoreAction({ invitationUuid, experience, buttonProps }: Props) {
  const { experienceId } = experience;
  const discoveryContextState = useDiscoveryContext();

  return (
    <NativeLink to={experienceDetailsRoute(invitationUuid, experienceId, discoveryContextState)}>
      {(onClick) => (
        <Button color="gray" variant="outlined" {...buttonProps} onClick={onClick}>
          <T kind="Learn More" />
        </Button>
      )}
    </NativeLink>
  );
}

export default observer(LearnMoreAction);
