import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import BroadcastMessageStore from '../../BroadcastMessageStore';
import BroadcastMessageReactions from '../../BroadcastMessageStore/BroadcastMessageReactions';
import ChatablePersonResourcesStore from '../../ChatableResourcesStore';
import { whenLocalConferenceRoomResource } from '../../Conference/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import PollsModalStore from '../../EventPolls/PollsModal/PollsModalStore';
import { EventResource } from '../../EventsStore/Types';
import { findLink } from '../../LinkyLinky';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import BreakoutConversations from './BreakoutConversations';
import BroadcastMessage from './BroadcastMessage';
import ConferenceRoomInfo from './ConferenceRoomInfo';
import MainConversation from './MainConversation';
import PollBroadcaster from './PollBroadcaster';
import RemainingTime from './RemainingTime';
import Timer from './Timer';
import * as style from './style.module.css';

interface Props {
  eventResource: EventResource;
  broadcastMessageStore: BroadcastMessageStore;
  timerStore: BreakoutTimerStore;
  chatablePersonResourcesStore: ChatablePersonResourcesStore;
  breakoutStore: BreakoutChannelStore;
}

class EventReady extends React.Component<Props> {
  pollsModalStore = new PollsModalStore(this.props.eventResource.payload.polls.payload);

  componentDidMount() {
    const { eventResource, chatablePersonResourcesStore } = this.props;
    findLink('roster')(eventResource.links).map((link) =>
      chatablePersonResourcesStore.loading(link),
    );
  }

  render() {
    const {
      eventResource,
      broadcastMessageStore,
      timerStore,
      chatablePersonResourcesStore,
      breakoutStore,
    } = this.props;

    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <div className={style.headerContainer}>
              <div className={style.header}>
                <div className={style.leftActions}>
                  {eventResource.payload.conferenceRoom
                    .andThen((conferenceRoom) =>
                      findLink('broadcast-message', conferenceRoom.links),
                    )
                    .map((link) => (
                      <>
                        <BroadcastMessage store={broadcastMessageStore} />
                        <BroadcastMessageReactions
                          broadcastLink={link}
                          store={broadcastMessageStore}
                        />
                      </>
                    ))
                    .getOrElseValue(<span />)}
                  <div className={style.broadcastPollButtonContainer}>
                    <PollBroadcaster
                      eventResource={eventResource}
                      pollsModalStore={this.pollsModalStore}
                    />
                  </div>
                </div>
                {eventResource.payload.conferenceRoom
                  .map(() => <ConferenceRoomInfo />)
                  .getOrElseValue(<span />)}
                <Timer eventResource={eventResource} timerStore={timerStore} />
              </div>
              <RemainingTime timeRemainingStore={breakoutStore.timeRemainingStore} />
            </div>
            <div />
            <div className={style.rooms}>
              {eventResource.payload.conferenceRoom
                .andThen((conferenceRoom) => whenLocalConferenceRoomResource(conferenceRoom))
                .map((conferenceRoom) => (
                  <MainConversation
                    eventResource={eventResource}
                    currentUserResource={currentUserResource}
                    conferenceRoomResource={conferenceRoom}
                  />
                ))
                .getOrElse(emptyFragment)}
              <BreakoutConversations
                event={eventResource.payload}
                chatablePersonResourcesStore={chatablePersonResourcesStore}
              />
            </div>
          </>
        )}
      />
    );
  }
}

export default observer(EventReady);
