import { AvailabilityResource, ExperienceResource } from '../../Experience/Types';

function getModalSizeByAvailability(availability: AvailabilityResource): string {
  switch (availability.payload.kind) {
    case 'on-demand':
      return 'max-w-[40rem]';
    case 'scheduled':
      return 'max-w-3xl';
  }
}

export function getModalSizeByOfferingType(
  availability: AvailabilityResource,
  experience: ExperienceResource,
): string {
  switch (experience.payload.offeringType) {
    case 'aep':
    case 'epc':
      return getModalSizeByAvailability(availability);
    case 'msuite':
    case 'coaching':
    case 'group-coaching':
      return 'max-w-[40rem]';
    case 'program-sequence':
      return 'max-w-3xl';
  }
}
