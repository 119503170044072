import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import NotesStore from '../../../NotesStore';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import SegmentStore from '../../../SegmentStore';
import { SegmentResource } from '../../../SegmentStore/Types';
import TabStore from '../../../TabStore';
import NotesTabPane from '../NotesTabPane';
import OutlineTabPane from '../OutlineTabPane';
import ResourceTabPane from '../ResourceTabPane';
import * as style from '../style.module.css';

interface Props {
  segmentResource: Maybe<SegmentResource>;
  tabStore: TabStore;
  programDetailResource: Maybe<ProgramDetailResource>;
  notesStore: NotesStore;
  segmentStore: SegmentStore;
}

const ActiveTabPane: React.FC<Props> = ({
  segmentResource,
  tabStore,
  programDetailResource,
  notesStore,
  segmentStore,
}) => {
  const tabPane = () => {
    switch (tabStore.activeTab) {
      case 'notes': {
        return segmentResource
          .map((resource) => (
            <NotesTabPane
              segmentResource={resource}
              segmentStore={segmentStore}
              notesStore={notesStore}
            />
          ))
          .getOrElseValue(<></>);
      }
      case 'outline': {
        return <OutlineTabPane programDetailResource={programDetailResource} />;
      }
      case 'resources':
      default: {
        return <ResourceTabPane store={segmentStore} />;
      }
    }
  };

  return <section className={`${style.section} ${style.sectionBottom}`}>{tabPane()}</section>;
};

export default observer(ActiveTabPane);
