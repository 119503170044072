import { observer } from 'mobx-react';
import { LinkableDiscoveryPortalWizardScopeKind } from '../../../../../components/Linkable/Types';
import { AlreadyTranslated } from '../../../../../Translations';
import { ProductCollection } from '../../../Common/Experience/Types';
import { ExperienceSectionTitle } from '../ExperienceSection';
import UpdateFocusButton from './UpdateFocusButton';

interface Props {
  productCollection: ProductCollection;
  scopeType: LinkableDiscoveryPortalWizardScopeKind;
}

function ExperienceUpdateFocus({ productCollection, scopeType }: Props) {
  return (
    <div className="flex flex-1 flex-col-reverse items-start gap-4 sm:flex-row">
      <ExperienceSectionTitle>
        <AlreadyTranslated content={productCollection.name} />
      </ExperienceSectionTitle>
      <UpdateFocusButton productCollectionId={productCollection.id} scopeType={scopeType} />
    </div>
  );
}

export default observer(ExperienceUpdateFocus);
