import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import BeginnableCardBody from './BeginnableCardBody';
import GraduatedCardBody from './GraduatedCardBody';
import ResumableCardBody from './ResumableCardBody';
import ReturnableCardBody from './ReturnableCardBody';

interface Props {
  experience: Experience;
}

function EnrollmentCardBody({ experience }: Props) {
  switch (experience.kind) {
    case 'beginnable':
      return <BeginnableCardBody />;
    case 'resumable':
      return <ResumableCardBody />;
    case 'returnable':
      return <ReturnableCardBody experience={experience} />;
    case 'graduated':
      return <GraduatedCardBody experience={experience} />;
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(EnrollmentCardBody);
