import { fromBool } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { v4 as uuid } from 'uuid';
import { StorageError, StorageValue } from '../../../StorageWatcher/Types';

export const storageKey = 'singletonConference';

export const uniqueConferenceId: () => string = uuid;

export const whenCurrentSingletonConference = (
  singletonConferenceId: StorageValue,
  thisConferenceId: string,
): Maybe<string> =>
  singletonConferenceId.andThen((singletonConferenceId) =>
    fromBool(thisConferenceId === singletonConferenceId, thisConferenceId),
  );

export const initializing = (): Initializing => ({ kind: 'initializing' });

export const ready = (currentSingletonConferenceId: StorageValue): Ready => ({
  kind: 'ready',
  currentSingletonConferenceId,
});

export const error = (err: StorageError): Error => ({ kind: 'error', err });

interface Initializing {
  kind: 'initializing';
}

interface Ready {
  kind: 'ready';
  currentSingletonConferenceId: StorageValue;
}

interface Error {
  kind: 'error';
  err: StorageError;
}

export type State = Initializing | Ready | Error;
