import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { getEnrollAvailability } from '../../../../Availability/Types';
import { AvailabilityResources } from '../../../../Types';
import FormattedUpcomingDates from './FormattedUpcomingDates';

interface Props {
  availabilities: AvailabilityResources;
}

function ProgramUpcomingDates({ availabilities }: Props) {
  return fromArrayMaybe(availabilities)
    .map((availabilities) => availabilities.first)
    .andThen(getEnrollAvailability)
    .map((availability) => (
      <Typography className="text-gray-900" variant="caption">
        <FormattedUpcomingDates availability={availability} availabilities={availabilities} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramUpcomingDates);
