import { byPayloadId, find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe, fromEmpty, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { Error, error } from '../ErrorHandling';
import { NoteResource } from '../NoteStore/Types';
import NotesStore from '../NotesStore';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { programsStore } from '../ProgramsStore';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';

interface New {
  kind: 'new';
  content: Maybe<string>;
}

interface Editing {
  kind: 'editing';
  noteResource: NoteResource;
  content: Maybe<string>;
  originalContent: Maybe<string>;
}

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  noteResource: NoteResource;
}

interface Creating {
  kind: 'creating';
  content: string;
}

interface Updating {
  kind: 'updating';
  noteResource: NoteResource;
  content: string;
}

interface Confirming {
  kind: 'confirming';
  noteResource: NoteResource;
}

interface Loading {
  kind: 'loading';
}

interface Deleting {
  kind: 'deleting';
  noteResource: NoteResource;
}

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const newing = (): New => ({
  kind: 'new',
  content: nothing(),
});

const creating = (content: string): Creating => ({
  kind: 'creating',
  content,
});

const updating = (noteResource: NoteResource) => (content: string): Updating => ({
  kind: 'updating',
  content,
  noteResource,
});

const deleting = (noteResource: NoteResource): Deleting => ({
  kind: 'deleting',
  noteResource,
});

const confirming = (noteResource: NoteResource): Confirming => ({
  kind: 'confirming',
  noteResource,
});

const editing = (content: string, noteResource: NoteResource): Editing => ({
  kind: 'editing',
  content: just(content),
  originalContent: just(content),
  noteResource,
});

const loading = (): Loading => ({
  kind: 'loading',
});

const ready = (noteResource: NoteResource): Ready => ({
  kind: 'ready',
  noteResource,
});

const restore = (state: Editing) => (): Ready => {
  const payload = { ...state.noteResource.payload, content: state.originalContent };
  return ready({ ...state.noteResource, payload });
};

export type State =
  | Waiting
  | Error
  | Ready
  | New
  | Creating
  | Editing
  | Updating
  | Deleting
  | Confirming
  | Loading;

export const readyStore = (store: NotesStore) => (note: NoteResource) => {
  const s = new NoteStore(store);
  s.ready(note);
  return s;
};

class NoteStore {
  @observable
  state: State = waiting();
  notesStore: NotesStore;

  constructor(notesStore: NotesStore) {
    this.notesStore = notesStore;
    this.state = waiting();
  }

  represents = (note: NoteResource): boolean => {
    switch (this.state.kind) {
      case 'ready':
      case 'confirming':
      case 'deleting':
      case 'editing':
      case 'updating':
        return note.payload.id === this.state.noteResource.payload.id;
      case 'waiting':
      case 'creating':
      case 'error':
      case 'loading':
      case 'new':
        return false;
    }
  };

  @action
  ready = (noteResource: NoteResource) => {
    this.state = ready(noteResource);
  };

  @action
  deleting = () => {
    switch (this.state.kind) {
      case 'confirming': {
        this.state = deleting(this.state.noteResource);
        break;
      }
      case 'ready':
      case 'editing':
      case 'deleting':
      case 'new':
      case 'waiting':
      case 'creating':
      case 'error':
      case 'updating':
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  confirming = () => {
    switch (this.state.kind) {
      case 'ready': {
        this.state = confirming(this.state.noteResource);
        break;
      }
      case 'editing':
      case 'deleting':
      case 'new':
      case 'waiting':
      case 'creating':
      case 'error':
      case 'updating':
      case 'loading':
      case 'confirming':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  editing = () => {
    switch (this.state.kind) {
      case 'ready': {
        this.state = editing(this.content.getOrElseValue(''), this.state.noteResource);
        break;
      }
      case 'editing':
      case 'deleting':
      case 'new':
      case 'waiting':
      case 'creating':
      case 'error':
      case 'updating':
      case 'confirming':
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  save = () => {
    switch (this.state.kind) {
      case 'new': {
        this.state = this.state.content
          .map((c) => c.trim())
          .andThen(fromEmpty)
          .map<State>(creating)
          .getOrElse(newing);
        break;
      }
      case 'editing': {
        const noteResource = this.state.noteResource;
        this.state = this.state.content
          .map((c) => c.trim())
          .andThen(fromEmpty)
          .map<State>(updating(noteResource))
          .getOrElse(restore(this.state));
        break;
      }
      case 'ready':
      case 'waiting':
      case 'creating':
      case 'error':
      case 'updating':
      case 'confirming':
      case 'loading':
      case 'deleting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'creating':
      case 'ready': {
        this.state = loading();
        break;
      }
      case 'new':
      case 'editing':
      case 'waiting':
      case 'error':
      case 'updating':
      case 'confirming':
      case 'loading':
      case 'deleting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  new = () => {
    this.state = newing();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  setContent = (content: string) => {
    switch (this.state.kind) {
      case 'editing':
      case 'new':
        this.state.content = fromEmpty(content);
        break;
      case 'error':
        const state = newing();
        state.content = just(content);
        this.state = state;
        break;
      case 'ready':
      case 'waiting':
      case 'creating':
      case 'loading':
      case 'deleting':
      case 'updating':
      case 'confirming':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  cancelEditing = () => {
    switch (this.state.kind) {
      case 'new':
        this.new();
        break;
      case 'editing':
        this.ready({
          payload: { ...this.state.noteResource.payload, content: this.state.originalContent },
          links: this.state.noteResource.links,
        });
        break;
      case 'ready':
      case 'updating':
      case 'creating':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'confirming':
      case 'deleting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'updating':
      case 'creating':
      case 'editing':
      case 'new':
      case 'waiting':
      case 'loading':
      case 'confirming':
      case 'deleting':
        return nothing();
    }
  }

  @computed
  get content(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.noteResource.payload.content;
      case 'updating':
      case 'creating':
        return just(this.state.content);
      case 'editing':
      case 'new':
        return this.state.content;
      case 'waiting':
      case 'loading':
      case 'error':
      case 'confirming':
      case 'deleting':
        return nothing();
    }
  }

  @computed
  get createdAt(): Maybe<Date> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.noteResource.payload.createdAt);
      case 'new':
      case 'deleting':
      case 'editing':
      case 'waiting':
      case 'creating':
      case 'loading':
      case 'updating':
      case 'confirming':
      case 'error':
        return nothing();
    }
  }

  @computed
  get context(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.noteResource.payload.context;
      case 'new':
      case 'editing':
      case 'deleting':
      case 'waiting':
      case 'creating':
      case 'loading':
      case 'updating':
      case 'confirming':
      case 'error':
        return nothing();
    }
  }

  @computed
  get programTitle(): string {
    switch (this.state.kind) {
      case 'ready':
        return programsStore.resource
          .map(({ payload }) => payload.programs)
          .andThen(find(byPayloadId(this.state.noteResource.payload.programId)))
          .map((programResource) => programResource.payload.title.text)
          .getOrElseValue('none');
      case 'new':
      case 'editing':
      case 'deleting':
      case 'waiting':
      case 'creating':
      case 'loading':
      case 'updating':
      case 'confirming':
      case 'error':
        return 'none';
    }
  }

  @computed
  get noteResource(): Maybe<NoteResource> {
    switch (this.state.kind) {
      case 'deleting':
      case 'ready':
      case 'confirming':
        return just(this.state.noteResource);
      case 'creating':
      case 'editing':
      case 'waiting':
      case 'loading':
      case 'new':
      case 'updating':
      case 'error':
        return nothing();
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'deleting':
      case 'updating':
      case 'ready':
        return this.state.noteResource.links;
      case 'creating':
      case 'editing':
      case 'confirming':
      case 'waiting':
      case 'loading':
      case 'new':
      case 'error':
        return [];
    }
  }
}

export default NoteStore;
