import { fromNullable, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { ProductCollection } from '../../../../Common/Experience/Types';
import DiscoveryContext from '../../../../DiscoveryContext';
import EnrollmentStore from '../../../Enrollment/Store';
import { ListViewExperiences } from '../ListViewExperiences';
import { TileViewCarouselExperiences } from '../TileViewCarouselExperiences';
import { TileViewExperiences } from '../TileViewExperiences';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  productCollection: ProductCollection;
  searchValue: Maybe<string>;
}

export function ProductCollectionView({
  invitationUuid,
  enrollmentStore,
  productCollection,
  searchValue,
}: Props) {
  switch (productCollection.displayType) {
    case 'grid':
      return (
        <DiscoveryContext.Provider value={{ kind: 'all-experiences' }}>
          <TileViewExperiences
            kind="product-collection"
            invitationUuid={invitationUuid}
            productCollection={fromNullable(productCollection)}
            experienceResources={productCollection.experiences}
            enrollmentStore={enrollmentStore}
          />
        </DiscoveryContext.Provider>
      );
    case 'carousel':
      return (
        <TileViewCarouselExperiences
          invitationUuid={invitationUuid}
          productCollection={productCollection}
          enrollmentStore={enrollmentStore}
        />
      );
    case 'list':
      return (
        <ListViewExperiences
          kind="product-collection"
          invitationUuid={invitationUuid}
          searchValue={searchValue}
          productCollection={fromNullable(productCollection)}
          experienceResources={productCollection.experiences}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(ProductCollectionView);
