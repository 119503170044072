import { observer } from 'mobx-react';
import * as React from 'react';
import Login from '.';
import { currentUserStore } from '../../CurrentUser/Store';
import Loading from '../../components/Loading';

const WithAuthentication: React.FC = ({ children }) => {
  switch (currentUserStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'logging-out':
      return <Loading />;
    case 'anonymous':
    case 'errored':
      return <Login />;
    case 'ready':
    case 'refreshing':
      return <>{children}</>;
  }
};

export default observer(WithAuthentication);
