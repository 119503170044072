import * as React from 'react';
import SVGIcon from '../SVGIcon';

const path =
  'M26.0001 0.800903C21.8007 0.800903 17.7734 2.46909 14.804 5.43848C11.8346 8.40787 10.1664 12.4352 10.1664 16.6346C10.1664 20.8339 11.8346 24.8613 14.804 27.8307C17.7734 30.8001 21.8007 32.4683 26.0001 32.4683C30.1994 32.4683 34.2268 30.8001 37.1962 27.8307C40.1656 24.8613 41.8338 20.8339 41.8338 16.6346C41.8338 12.4352 40.1656 8.40787 37.1962 5.43848C34.2268 2.46909 30.1994 0.800903 26.0001 0.800903ZM45.0337 36.8242L32.9665 33.889L26.0001 25.8531L19.0336 33.889L6.96642 36.8242C3.49251 36.8242 0.666199 39.6505 0.666199 43.1244V45.5327C0.666199 50.7768 3.65085 55.3116 9.06913 58.2994C13.577 60.7885 19.5906 62.1581 26.0001 62.1581C38.2015 62.1581 51.334 56.9551 51.334 45.5327V43.1244C51.334 39.6505 48.5076 36.8242 45.0337 36.8242Z';

const PersonIcon: React.StatelessComponent = () => {
  return (
    <SVGIcon viewBox="0 0 48 60">
      <g stroke="none" strokeWidth="1" fill="#BEC0C5" fillRule="evenodd">
        <path d={path} fill="#BEC0C5" fillRule="nonzero" />
      </g>
    </SVGIcon>
  );
};

export default PersonIcon;
