import { Seconds, Time, seconds, toJS } from '@execonline-inc/time';
import * as date from 'date-fns';
import { useState } from 'react';
import { useConst } from './useConst';
import { useInterval } from './useInterval';

const floored = (count: number) => Math.max(0, count);

export const useCountdownSeconds = (time: Time): Seconds => {
  const until = useConst(() => date.addMilliseconds(new Date(), toJS(time)));
  return useCountdownSecondsUntil(until);
};

const secondsUntil = (time: Date): Seconds =>
  seconds(floored(date.differenceInSeconds(time, new Date())));

export const useCountdownSecondsUntil = (time: Date): Seconds => {
  const [countdown, setCountdown] = useState<Seconds>(secondsUntil(time));

  useInterval(seconds(1), () => setCountdown(secondsUntil(time)), [time]);

  return countdown;
};
