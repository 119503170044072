import { Maybe } from 'maybeasy';
import * as React from 'react';
import { L } from '../../Translations';

interface Props {
  timestamp: Maybe<Date>;
}

const Context: React.StatelessComponent<Props> = ({ timestamp }) => {
  return timestamp
    .map((ts) => {
      return (
        <div>
          <L localizeable={ts} format="long-date-and-time" />
        </div>
      );
    })
    .getOrElseValue(<span />);
};
export default Context;
