import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../../ConversationStore';
import { notAuthoredBy } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { ScrollPosition } from '../../../ScrollStore/Types';
import { T } from '../../../Translations';

interface Props {
  scrollPosition: ScrollPosition;
  conversationStore: ConversationStore;
  scrollToBottom: () => void;
  className: string;
  currentUserResource: CurrentUserResource;
}

const ScrollToNewMessage: React.FC<Props> = ({
  scrollPosition,
  conversationStore,
  scrollToBottom,
  className,
  currentUserResource,
}) => {
  const onClick = () => {
    scrollToBottom();
    conversationStore.clearUnseenMessage();
  };

  switch (scrollPosition) {
    case 'middle':
    case 'near-bottom':
    case 'top':
    case 'near-top': {
      return conversationStore.unseenMessage
        .andThen(notAuthoredBy(currentUserResource.payload.id))
        .map(() => (
          <button className={className} onClick={onClick}>
            <T kind="Scroll to New messages" />
          </button>
        ))
        .getOrElse(() => <span />);
    }
    case 'bottom':
      return <span />;
  }
};

export default observer(ScrollToNewMessage);
