import { identity } from '@kofno/piper';
import Decoder, { array, date, field, succeed } from 'jsonous';
import { eventsResourceDecoder } from '../../EventsStore/Decoders';
import { programDetailsResourceDecoder } from '../../ProgramStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { Calendar, CalendarResource } from '../Types';

export const calendarDecoder: Decoder<Calendar> = succeed({})
  .assign('eventsResource', field('events_resource', eventsResourceDecoder))
  .assign('firstDayOfMonth', field('first_day_of_month', date))
  .assign(
    'activeProgramResources',
    field('active_program_resources', array(programDetailsResourceDecoder))
  )
  .map<Calendar>(identity);

export const calendarResourceDecoder: Decoder<CalendarResource> = resourceDecoder(calendarDecoder);
