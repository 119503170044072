import { Maybe } from 'maybeasy';
import * as React from 'react';
import { translation, TranslationsContext } from '../../../Translations';
import FacebookIcon from '../../Cutesies/FacebookIcon';
import * as style from '../style.module.css';

interface Props {
  facebookPage: Maybe<string>;
}

const Facebook: React.FC<Props> = ({ facebookPage }) =>
  facebookPage
    .map((facebook) => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <a
            data-test-profile-popout-facebook={true}
            title={translation('Facebook Profile')(ts)}
            className={style.facebook}
            href={facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        )}
      </TranslationsContext.Consumer>
    ))
    .getOrElseValue(<div />);

export default Facebook;
