import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingProductDetails } from '../../../../../ProgramStore/Types';
import * as style from '../../style.module.css';
import SectionTitle from '../SectionTitle';
import SessionsList from '../SessionsList';
import NoticeBox from './NoticeBox';
import ProgramStore from '../../../../../ProgramStore';
import UpcomingSessionsRefresh from '../../../../../UpcomingSessionsRefresh';

interface Props {
  programStore: ProgramStore;
  coachingProductDetails: CoachingProductDetails;
}

const UpcomingSessions: React.FC<Props> = ({ coachingProductDetails, programStore }) => (
  <div className={style.upcomingSessions}>
    <SectionTitle text="Upcoming coaching sessions" />
    <UpcomingSessionsRefresh programStore={programStore} />
    {fromEmpty(coachingProductDetails.sessions)
      .map((sessions) => (
        <div className={style.contentBox}>
          <SessionsList sessions={sessions} />
        </div>
      ))
      .getOrElse(() => (
        <div>
          <NoticeBox />
        </div>
      ))}
  </div>
);

export default observer(UpcomingSessions);
