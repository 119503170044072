import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource, leadershipCoachingExperience } from '../../../Experience/Types';
import LeadershipCoachingTimeCommitment from '../LeadershipCoachingTimeCommitment';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return (
    <div className="flex flex-wrap gap-x-6 gap-y-1 empty:hidden">
      {leadershipCoachingExperience(experienceResource.payload)
        .map(() => <LeadershipCoachingTimeCommitment />)
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(ExperienceProductDetails);
