import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { ProductCollection } from '../../../../Common/Experience/Types';
import EnrollmentStore from '../../../Enrollment/Store';
import ProductCollectionsView from './ProductCollectionsView';

interface Props {
  invitationUuid: string;
  searchValue: Maybe<string>;
  productCollections: Maybe<NonEmptyList<ProductCollection>>;
  enrollmentStore: EnrollmentStore;
}

function ProductCollections({
  enrollmentStore,
  invitationUuid,
  productCollections,
  searchValue,
}: Props) {
  return productCollections
    .map((productCollectionsList) => (
      <ProductCollectionsView
        invitationUuid={invitationUuid}
        searchValue={searchValue}
        productCollectionsList={productCollectionsList}
        enrollmentStore={enrollmentStore}
      />
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProductCollections);
