import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../ChannelAvailabilityStore';
import ChannelReactions from '../ChannelReactions';
import SocketContext from '../SocketContext';

interface Props {
  name: string;
  channelAvailabilityStore: ChannelAvailabilityStore;
}

const EventsChannel: React.FC<Props> = ({ name, channelAvailabilityStore }) => (
  <SocketContext.Consumer>
    {(details) => (
      <ChannelReactions
        store={details.socketStore}
        availabilityStore={channelAvailabilityStore}
        name={name}
        fireImmediately={true}
      />
    )}
  </SocketContext.Consumer>
);

export default observer(EventsChannel);
