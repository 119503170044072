import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { programsStore } from '../../../../../ProgramsStore';
import Loader from '../../../../Loader';

interface Props {
  to: string;
}

function RedirectToProgram({ to }: Props) {
  switch (programsStore.state.kind) {
    case 'ready':
      return <Redirect to={to} />;
    case 'errored':
    case 'loading':
    case 'refreshing':
    case 'waiting':
      return <Loader color="black" type="spin" />;
  }
}

export default observer(RedirectToProgram);
