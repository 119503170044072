import { warn } from '@execonline-inc/logging';
import { minutes } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { just } from 'maybeasy';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { AppyError, callApi, request, retryOnSessionExpiration } from '../Appy';
import { warnAndNotify } from '../Honeybadger';
import { InactivityLogoutTracker } from '../Inactivity/Core';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { preferredLanguageSelectionStore } from '../PreferredLanguageSelection/Store';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { warnAndNotifyDecoderError } from '../Resource/Decoders';
import { Link } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import { RootResourceError } from '../RootResourceStore/Types';
import { discardSession } from '../Session';
import { sessionStore } from '../Session/Store';
import { currentUserResourceDecoder } from './Decoders';
import { Errored, LoggingOut, State } from './States';
import { CurrentUserStore, currentUserStore } from './Store';

interface Props extends RCProps<CurrentUserStore> {}

export class CurrentUserReactions extends ReactionComponent<CurrentUserStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
      case 'refreshing':
        loadResource(store);
        break;
      case 'ready':
        InactivityLogoutTracker.reconfig({
          timeLimit: minutes(state.currentUserResource.payload.inactivityTimeoutMinutes),
        });
        break;
      case 'logging-out':
        logout(store, state);
        break;
      case 'anonymous':
        resetUserSingletonStores();
        discardSession();
        break;
      case 'errored':
        warn('Current User store encountered an error.', toJS(state.error));
        logout(store, state);
        break;
      default:
        assertNever(state);
    }
  };
}

export const resetUserSingletonStores = (): void => {
  const stores = [...currentUserStore.childStores, preferredLanguageSelectionStore];
  stores.forEach((store) => store.reset());
};

const loadResource = (store: CurrentUserStore): void => {
  rootResourceStore
    .findLinkT('current-user')
    .andThen(callApi(currentUserResourceDecoder, {}))
    .fork(handleFetchFailure(store), store.ready);
};

const logout = (store: CurrentUserStore, state: LoggingOut | Errored): void => {
  Task.succeed<MissingLinkError | AppyError, ReadonlyArray<Link>>(rootResourceStore.links)
    .andThen(findLinkT('logout'))
    .andThen((link) => retryOnSessionExpiration(request(link, {})))
    .elseDo((err) => warn('Session logout failed:', err))
    .do(resetUserSingletonStores)
    .fork(
      () => handleLogoutFailure(store, state),
      () => sessionStore.requesting({ messageOnFailure: just(state.message) }),
    );
};

const handleLogoutFailure = (store: CurrentUserStore, state: LoggingOut | Errored): void => {
  switch (state.kind) {
    case 'logging-out':
      store.ready(state.currentUserResource);
      break;
    case 'errored':
      store.anonymous(just(state.message));
      break;
    default:
      assertNever(state);
  }
};

const handleFetchFailure =
  (store: CurrentUserStore) =>
  (error: RootResourceError): void => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify(
          'MissingRootsLinkError',
          `The root resource did not contain the expected link: "${error.rel}"`,
          { rootResourceStoreState: rootResourceStore.state },
        );
        store.errored({
          error,
          message: 'An error occurred, please refresh the page and try again',
        });
        break;
      case 'bad-payload':
        warnAndNotifyDecoderError('DecoderError on current user resource.')(error);
        store.errored({
          error,
          message: 'An error occurred, please refresh the page and try again',
        });
        break;
      case 'bad-url':
      case 'network-error':
      case 'timeout':
        store.errored({
          error,
          message: 'An error occurred, please refresh the page and try again',
        });
        break;
      case 'missing-application-id':
        store.errored({
          error,
          message: 'You are missing the application id',
        });
        break;
      case 'missing-api-compatibility':
        store.errored({
          error,
          message: 'You are missing the API compatibility version',
        });
        break;
      case 'bad-status':
        handleBadFetchStatus(store, error);
        break;
      default:
        assertNever(error);
    }
  };

const handleBadFetchStatus = (store: CurrentUserStore, error: BadStatus): void => {
  switch (error.response.status) {
    case 401:
      store.anonymous(just('Your session has expired. Please login.'));
      break;
    default:
      store.errored({ error, message: 'An unexpected error occurred.' });
  }
};
