import { observer } from 'mobx-react';
import * as React from 'react';
import { windowMessageDecoder } from './Decoders';
import { WindowMessage } from './Types';
import VPFAlumniSurveyModalStore from '..';

interface Props {
  store: VPFAlumniSurveyModalStore;
  iFrameRef: React.RefObject<HTMLIFrameElement>;
}

@observer
class WindowMessageReactions extends React.Component<Props> {
  messageHandler = (e: { data: string }) => {
    windowMessageDecoder.decodeJson(e.data).cata({
      Err: (err) => {
        return;
      },
      Ok: (windowMessage: WindowMessage) => {
        switch (windowMessage.kind) {
          case 'complete-and-close':
            this.props.store.closeAndComplete();
            break;
          default:
            break;
        }
      },
    });
  };

  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  render() {
    return <span />;
  }
}

export default WindowMessageReactions;
