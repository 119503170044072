import { observer } from 'mobx-react';
import { GraduationCapIcon, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import ExperienceAlert from './ExperienceAlert';

function ReturnableAlert() {
  return (
    <ExperienceAlert icon={<GraduationCapIcon className="text-teal-600" />}>
      <Typography variant="caption">
        <T kind="You graduated from this program" />
      </Typography>
    </ExperienceAlert>
  );
}

export default observer(ReturnableAlert);
