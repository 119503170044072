import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, field, number, oneOf, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import {
  ConferenceRoomResource,
  Conversation,
  ConversationResource,
  DailyConferenceRoom,
  ExternalConferenceRoom,
  GoToWebinarConferenceRoom,
  LastReadMessage,
  LocalConferenceRoomBase,
  LocalConferenceRoomResource,
  ZoomConferenceRoom,
} from '../../Conference/Types';
import {
  chatMessageReplyResourceDecoder,
  chatMessageResourceDecoder,
} from '../../ConversationStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { breakoutDecoder } from './BreakoutDecoders';

export const lastReadMessageDecoder: Decoder<LastReadMessage> = oneOf<LastReadMessage>([
  chatMessageReplyResourceDecoder.map<LastReadMessage>(identity),
  chatMessageResourceDecoder.map<LastReadMessage>(identity),
]);

const conversationDecoder: Decoder<Conversation> = succeed({})
  .assign('id', field('id', number))
  .assign('channelName', field('channel_name', string))
  .assign('lastReadMessage', field('last_read_message', explicitMaybe(lastReadMessageDecoder)));

export const conversationResourceDecoder: Decoder<ConversationResource> =
  resourceDecoder(conversationDecoder);

const localConferenceRoomBaseDecoder: Decoder<LocalConferenceRoomBase> = succeed({})
  .assign('id', field('id', number))
  .assign('conferenceId', field('conference_id', string))
  .assign('conferencePin', field('conference_pin', explicitMaybe(string)))
  .assign('conversationResource', field('conversation', conversationResourceDecoder))
  .assign('breakoutRooms', field('breakout_rooms', array(breakoutDecoder).map(fromArrayMaybe)))
  .assign('messagingChannel', field('messaging_channel', string))
  .assign('programId', field('program_id', explicitMaybe(number)))
  .assign('token', field('token', explicitMaybe(string)));

const dailyConferenceRoomDecoder: Decoder<DailyConferenceRoom> = succeed({})
  .assign('base', localConferenceRoomBaseDecoder)
  .assign('kind', succeed({}).assign('kind', field('kind', stringLiteral('daily-live-meeting'))))
  .map(({ base, kind }) => ({ ...base, ...kind }));

const externalConferenceRoomDecoder: Decoder<ExternalConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('external-live-meeting')),
);

const goToWebinarConferenceRoomDecoder: Decoder<GoToWebinarConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('gtw-live-meeting')),
);

const zoomConferenceRoomDecoder: Decoder<ZoomConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('zoom-live-meeting')),
);

export const conferenceRoomResourceDecoder: Decoder<ConferenceRoomResource> =
  oneOf<ConferenceRoomResource>([
    resourceDecoder(dailyConferenceRoomDecoder).map<ConferenceRoomResource>(identity),
    resourceDecoder(externalConferenceRoomDecoder).map<ConferenceRoomResource>(identity),
    resourceDecoder(zoomConferenceRoomDecoder).map<ConferenceRoomResource>(identity),
    resourceDecoder(goToWebinarConferenceRoomDecoder).map<ConferenceRoomResource>(identity),
  ]);

export const localConferenceRoomResourceDecoder: Decoder<LocalConferenceRoomResource> =
  oneOf<LocalConferenceRoomResource>([
    resourceDecoder(dailyConferenceRoomDecoder).map<LocalConferenceRoomResource>(identity),
  ]);

export const maybeConferenceRoomDecoder = explicitMaybe(conferenceRoomResourceDecoder);
