import { observer } from 'mobx-react';
import { groupCoachingExperience } from '../../../Experience/Types';
import { useEnrollmentModalContext } from '../../Common';
import GroupCoachingExperienceInfo from './GroupCoachingExperienceInfo';
import ProgramExperienceInfo from './ProgramExperienceInfo';
import ExperienceErrorNotification from './ExperienceErrorNotification';

function EnrollmentModalBody() {
  const { experienceResource, enrollmentStore } = useEnrollmentModalContext();

  return (
    <>
      <ExperienceErrorNotification notification={enrollmentStore.modalNotification} />
      {groupCoachingExperience(experienceResource.payload)
        .map(() => <GroupCoachingExperienceInfo />)
        .getOrElse(() => (
          <ProgramExperienceInfo />
        ))}
    </>
  );
}

export default observer(EnrollmentModalBody);
