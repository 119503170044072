import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SchoolPartnerLogo } from '../../../../Experience/SchoolPartner';
import { Experience } from '../../../../Experience/Types';
import ExperiencesHeaderLogoSuffix from './ExperiencesHeaderLogoSuffix';
import { schoolPartnerLogoProps } from './Types';

interface Props {
  experience: Experience;
}

function ExperiencesHeaderLogo({ experience }: Props) {
  const breakpoint = useMediaQuery();

  return (
    <div className="inline-flex items-end gap-x-3 self-start empty:hidden">
      <SchoolPartnerLogo
        {...schoolPartnerLogoProps(experience, breakpoint)}
        data={experience.schoolPartner}
      />
      <ExperiencesHeaderLogoSuffix experience={experience} />
    </div>
  );
}

export default observer(ExperiencesHeaderLogo);
