import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { useEnrollmentModalContext } from '../../Common';

function EnrollmentModalHeader() {
  const { experienceResource } = useEnrollmentModalContext();

  switch (experienceResource.payload.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
      return (
        <Typography
          className="p-0 tracking-normal text-gray-900"
          variant="h5"
          as="h2"
          fontWeight="bold"
        >
          <T kind="Confirm Your Enrollment" />
        </Typography>
      );
    case 'not-enrollable':
    case 'not-licensed':
    case 'beginnable':
    case 'upcoming':
    case 'graduated':
    case 'resumable':
    case 'returnable':
      return emptyFragment();
  }
}

export default observer(EnrollmentModalHeader);
