import * as React from 'react';
import { observer } from 'mobx-react';
import { fromNullable } from 'maybeasy';
import ThemeContext from '../../../Themes/ThemeContext';
import { ColorPalette } from '../../../Themes/ThemeContext/Types';
import { setIconColor } from '../helpers';
import SVGIcon from '../SVGIcon';

interface Props {
  color?: keyof ColorPalette;
}

const ArrowRightIcon: React.FC<Props> = ({ color }) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <SVGIcon viewBox="0 0 32 32">
            <path
              fill={setIconColor(fromNullable(color), theme)}
              d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z "
            />
          </SVGIcon>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default observer(ArrowRightIcon);
