import { minutes } from '@execonline-inc/time';
import { noop } from '@kofno/piper';
import * as xoid from 'xoid';
import { actions } from './Core';
import { useInactivityExtension, useInactivityTracking } from './Hooks';
import { uninitialized } from './States';
import { Actions, Config, SuppliedConfig, type Data } from './Types';

export const createInactivityTracker = (suppliedConfig: SuppliedConfig) => {
  const config: Config = {
    ...suppliedConfig,
    timeLimit: minutes(30),
    gracePeriod: minutes(1),
    activityEvents: suppliedConfig.activityEvents || ['keyup', 'pointerup', 'touchend'],
    onLocalActivity: suppliedConfig.onLocalActivity || noop,
  };

  const atom = xoid.atom<Data, Actions>({ config, state: uninitialized() }, actions);

  return {
    ...atom.actions,
    useInactivityTracking: useInactivityTracking(atom),
    useInactivityExtension: useInactivityExtension(atom),
  } as const;
};
