import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramDetailResource, Progress } from '../../../ProgramStore/Types';
import { T } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  programDetailResource: ProgramDetailResource;
}

const ProgramOverviewTitle: React.FC<Props> = ({ programDetailResource }) => {
  const whenProgramProgress = (content: JSX.Element, progress: Progress): Maybe<JSX.Element> => {
    switch (progress.kind) {
      case 'program-progress':
      case 'module-progress':
        return just(content);
      case 'registration-segment-progress':
        return nothing();
    }
  };

  const whenNotRegistering = (content: JSX.Element): Maybe<JSX.Element> => {
    switch (programDetailResource.payload.kind) {
      case 'active':
      case 'completed':
        return whenProgramProgress(content, programDetailResource.payload.progress);
      case 'expired':
      case 'upcoming':
      case 'inactive':
        return just(content);
    }
  };

  return whenNotRegistering(
    <h3 className={style.supPanelHeader} data-test-outline-header="Overview">
      <T kind="Overview" />
    </h3>
  ).getOrElseValue(<></>);
};

export default observer(ProgramOverviewTitle);
