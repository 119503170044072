import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import EventsStore from '../../../EventsStore';
import { TeamsStoreContext } from '../../../TeamsStore/TeamsStoreContext';
import { AutoLaunchableContext } from '../../AutoLaunchable/AutoLaunchableContext';
import Communities from '../../Communities';
import Dashboard from '../../Dashboard';
import Events from '../../Events';
import * as platformStyle from '../../Platform/style.module.css';
import ProgramSummary from '../../ProgramSummary';
import ResourcesDashboard from '../../ResourcesDashboard';
import SkipNavLink from '../../SkipNavLink';
import Toasts from '../../Toasts';
import SupplementalPanel from '../SupplementalPanel';
import Footer from '../../Footer';
import { getBackgroundImage } from '../../../Native/AEP/DiscoveryPortal/ExperienceSelection';
import NavigationBar from '../NavigationBar';

interface Props {
  eventsStore: EventsStore;
}

const MainPanel: React.FC<Props> = ({ eventsStore }) => {
  const teamsStore = useContext(TeamsStoreContext);

  return (
    <div style={getBackgroundImage()} className="bg-chevrons bg-[length:100%] bg-repeat-y">
      <div className={platformStyle.containerFull}>
        <SkipNavLink />
        <NavigationBar />
      </div>
      <Toasts teamsStore={just(teamsStore)} />
      <div className="flex">
        <div className="flex-1">
          <Switch>
            <AutoLaunchableContext.Provider value="learning_platform">
              <Route path="/calendar" component={Events} />
              <Route path="/resources" component={ResourcesDashboard} />
              <Route path="/communities" component={Communities} exact={true} />
              <Route path="/programs/:programId" component={ProgramSummary} exact={true} />
              <Route path="/index.html" exact={true} component={Dashboard} />
              <Route path="/" exact={true} component={Dashboard} />
            </AutoLaunchableContext.Provider>
          </Switch>
        </div>

        <SupplementalPanel eventsStore={eventsStore} />
      </div>
      <Footer />
    </div>
  );
};
export default observer(MainPanel);
