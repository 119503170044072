import { ChatMessageReactionsResource } from '../../../../ConversationStore/Types';
import { Error } from '../../../../ErrorHandling';
import { Link } from '../../../../Resource/Types';

interface MessageReportRequested {
  kind: 'message-report-requested';
}

interface ReportingMessage {
  kind: 'reporting-message';
  links: ReadonlyArray<Link>;
}

interface MessageReportSuccess {
  kind: 'message-report-success';
}

interface DeletingMessage {
  kind: 'deleting-message';
  links: ReadonlyArray<Link>;
}

interface MessageDeleteRequested {
  kind: 'message-delete-requested';
}

interface Ready {
  kind: 'ready';
}

interface ReactionMenuOpen {
  kind: 'reaction-menu-open';
  mouseX: number;
  mouseY: number;
}

interface ReactionSelected {
  kind: 'reaction-selected';
  emoji: string;
  reactionsResource: ChatMessageReactionsResource;
}

export const reactionSelected = (
  emoji: string,
  reactionsResource: ChatMessageReactionsResource
): ReactionSelected => ({
  kind: 'reaction-selected',
  emoji,
  reactionsResource,
});

export const reactionMenuOpen = (mouseX: number, mouseY: number): ReactionMenuOpen => ({
  kind: 'reaction-menu-open',
  mouseX,
  mouseY,
});

export const ready = (): Ready => ({ kind: 'ready' });

export const messageDeleteRequested = (): MessageDeleteRequested => ({
  kind: 'message-delete-requested',
});

export const deletingMessage = (links: ReadonlyArray<Link>): DeletingMessage => ({
  kind: 'deleting-message',
  links,
});

export const messageReportRequested = (): MessageReportRequested => ({
  kind: 'message-report-requested',
});

export const reportingMessage = (links: ReadonlyArray<Link>): ReportingMessage => ({
  kind: 'reporting-message',
  links,
});

export const messageReportSuccess = (): MessageReportSuccess => ({
  kind: 'message-report-success',
});

export type State =
  | Ready
  | ReactionMenuOpen
  | ReactionSelected
  | MessageDeleteRequested
  | DeletingMessage
  | MessageReportRequested
  | ReportingMessage
  | MessageReportSuccess
  | Error;
