import Decoder, { field, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../Resource/Decoders';
import { ViewableAsset, ViewableAssetResource } from '../Types';

export const viewableAssetDecoder: Decoder<ViewableAsset> = succeed({}).assign(
  'filename',
  field('filename', string)
);

export const viewableAssetResourceDecoder: Decoder<ViewableAssetResource> = resourceDecoder(
  viewableAssetDecoder
);
