import { ArrowDownIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { handleEnterOrSpaceKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { L, NotTranslated } from '../../../../Translations';
import DocumentIcon from '../../../Cutesies/DocumentIcon';
import DownloadViewModal from '../../../DownloadViewModal';
import { useOpenable } from '../../../Openable';
import { UserUploadedDocument } from '../../Types';
import StudentInfo from './StudentInfo';
import * as style from './style.module.css';

interface Props {
  upload: UserUploadedDocument;
}

function UserUploadedDocumentResource({ upload }: Props) {
  const { openableState, close, open } = useOpenable();
  const { assignment } = upload;
  const document = assignment.payload;

  return (
    <div className={style.resource}>
      <a
        className={style.content}
        onClick={open}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(open)}
        data-test-uploaded-document-resource={true}
      >
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.resourceDetails}>
          <span className={style.name} data-test-resource-title={true}>
            <NotTranslated text={document.title} />{' '}
            {document.fileExtension
              .map((fileExtension) => (
                <span className={style.metaData}>
                  <NotTranslated text={fileExtension} />
                </span>
              ))
              .getOrElseValue(<></>)}
            <span className={style.metaData}>
              <L localizeable={document.createdAt} format="long-month-day-year" />
            </span>
          </span>
        </div>
        <StudentInfo studentResource={upload.student} />
        <div className={style.download}>
          <div className={style.downloadIcon}>
            <ArrowDownIcon color="white" size="xs" />
          </div>
        </div>
      </a>
      <DownloadViewModal resource={assignment} close={close} modalState={openableState} />
    </div>
  );
}

export default observer(UserUploadedDocumentResource);
