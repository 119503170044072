import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { Link } from '../../../../Resource/Types';
import { TPlainTextKey } from '../../../../Translations';
import { ResourcesListResource, ResourcesResource } from '../../Shared/Types';
import { loading, PersonalResourcesState, ready, waiting } from './Types';

class PersonalResourcesStore {
  @observable
  state: PersonalResourcesState;

  constructor() {
    this.state = waiting();
  }

  @action
  load = (links: ReadonlyArray<Link>) => {
    this.state = loading(links);
  };

  @action
  ready = (personalResourcesListResource: ResourcesListResource) => {
    this.state = ready(personalResourcesListResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get personalResourcesResources(): ResourcesResource[] {
    switch (this.state.kind) {
      case 'ready':
        return this.state.personalResourcesListResource.payload;
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default PersonalResourcesStore;
