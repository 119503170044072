import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from './style.module.css';
import { T } from '../../Translations';

const ProgramMemberHeader: React.FC = () => (
  <>
    <h1 className={style.onlineMembers}>
      <T kind="Online Now" />
    </h1>
    <div className={style.membersText}>
      <T kind="The list below shows your peers who are currently in team messaging." />
    </div>
  </>
);

export default observer(ProgramMemberHeader);
