import { observer } from 'mobx-react';
import * as React from 'react';
import FlashError from '../../components/FlashMessage/FlashError';
import { T, TPlainTextKey } from '../../Translations';
import { UnifiedLoginError } from '../Errors';

interface Props {
  error: UnifiedLoginError;
}

const ErrorMessage: React.FC<Props> = ({ error }) => (
  <div className="flex items-center justify-center h-screen">
    <FlashError>
      <T kind={message(error)} />
    </FlashError>
  </div>
);

export default observer(ErrorMessage);

const message = (error: UnifiedLoginError): TPlainTextKey => {
  switch (error.kind) {
    case 'invalid-rel-error':
    case 'unknown-app-error':
      return 'The login request did not specify a valid application.';
    case 'window-interaction-error':
      return 'Unable to redirect back to the application.';
  }
};
