import { observer } from 'mobx-react';
import { ContingentResource } from '../../../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import { profileStore } from '../../../ProfileStore';
import { Profile } from '../../../ProfileStore/Types';

interface Props {
  children: (resource: ContingentResource<Profile>) => React.ReactNode;
}

function UserProfileReady({ children }: Props) {
  const profileState = profileStore.state;

  switch (profileState.kind) {
    case 'ready':
    case 'refreshing':
      return <>{children(profileState.resource)}</>;
    case 'waiting':
    case 'loading':
    case 'errored':
      return <></>;
  }
}

export default observer(UserProfileReady);
