import { SvgIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function LeadershipPersonaElliottIcon() {
  return (
    <SvgIcon size="inherit" viewBox="0 0 40 40">
      <path
        fill="#3B7D23"
        fill-opacity=".3"
        d="M20.33 40c11.046 0 20-8.954 20-20s-8.954-20-20-20C9.285 0 .33 8.954.33 20s8.955 20 20 20Z"
      />
      <mask id="a" width="41" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M20.33 40c11.046 0 20-8.954 20-20s-8.954-20-20-20C9.285 0 .33 8.954.33 20s8.955 20 20 20Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#471B18"
          d="M20.614 9.127s-2.798-.659-5.185 1.152c-2.386 1.81-1.119 4.386-3.423 6.444-2.304 2.057-1.975 3.908-.7 6.17 1.276 2.264-1.607 3.321-.794 6.083.788 2.677 3.592 4.046 3.592 4.046h12.549s4.046-2.992 4.252-5.172c.205-2.181-2.07-3.267-1.412-4.79.658-1.522.706-3.657-.808-5.326-1.866-2.058-.421-2.487-1.826-5.331-1.622-3.282-3.983-3.994-6.245-3.276Z"
        />
        <path
          fill="#F1C9A5"
          d="M29.908 32.31c-1.289-2.44-9.578-3.972-9.578-3.972s-8.289 1.531-9.578 3.972C9.8 35.697 9.002 40 9.002 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E4B692"
          d="M20.33 28.338s8.29 1.531 9.578 3.972c1.212 3.076 1.75 7.69 1.75 7.69H20.33V28.338Z"
        />
        <path fill="#F1C9A5" d="M22.587 23.377h-4.514v7.552h4.514v-7.552Z" />
        <path fill="#E4B692" d="M22.587 23.377H20.33v7.552h2.257v-7.552Z" />
        <path
          fill="#DDAC8C"
          d="M18.073 26.876c.406 1.165 2.044 1.833 3.247 1.833.435 0 .86-.073 1.267-.206v-5.127h-4.514v3.5Z"
          opacity=".1"
        />
        <path
          fill="#F1C9A5"
          d="M26.133 18.61c0-4.717-2.598-7.4-5.803-7.4-3.204 0-5.802 2.683-5.802 7.4 0 4.718 2.598 8.543 5.802 8.543 3.205 0 5.803-3.825 5.803-8.543Z"
        />
        <path
          fill="#E4B692"
          d="M25.25 21.881c-1.053-.117-1.763-1.321-1.585-2.689.176-1.368 1.172-2.384 2.225-2.268 1.051.117 1.76 1.32 1.583 2.689-.177 1.369-1.172 2.384-2.224 2.268Z"
        />
        <path
          fill="#F1C9A5"
          d="M13.229 19.613c-.178-1.369.531-2.573 1.582-2.689 1.053-.116 2.049.9 2.226 2.268.177 1.368-.534 2.572-1.585 2.69-1.052.115-2.047-.9-2.223-2.269Z"
        />
        <path
          fill="#E4B692"
          d="M26.133 18.61c0-4.717-2.598-7.4-5.803-7.4v15.943c3.205 0 5.803-3.825 5.803-8.543Z"
        />
        <path fill="#fff" d="M13.287 40h14.058v-7.617H13.287V40Z" />
        <path
          fill="#FE393E"
          d="m18.969 40-1.311-11.054c-2.933.46-6.256 2.133-6.906 3.364C9.8 35.697 9.002 40 9.002 40h9.967ZM22.25 40h9.408s-.538-4.614-1.75-7.69c-1.057-1.884-5.238-2.905-6.695-3.334L22.25 40Z"
        />
        <path
          fill="#471B18"
          d="M20.316 13.727s-1.25 2.697-3.012 3.546c-2.776 1.338-.367 3.38-.69 6.104-.292 2.457-3.146 3.578-1.481 6.22-2.894.57-2.756-.888-2.756-.888s-.277-7.588-.346-7.762c-.069-.174 1.658-4.871 1.658-4.871l2.483-5.319 4.442-.899-.298 3.87Z"
        />
        <path
          fill="#471B18"
          d="M20.316 13.727s.14 2.118 2.27 2.303c2.132.184 2.409 1.745 1.994 3.152-.414 1.407-.599 2.19.553 3.295 1.15 1.105-.139 5.372-.139 5.372l4.237-2.016s-.904-3.426-.414-5.336c.489-1.91-1.704-3.27-2.257-4.651-.553-1.382-3.327-6.366-5.572-5.988-2.245.379-2.02 2.764-2.02 2.764l1.348 1.105Z"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(LeadershipPersonaElliottIcon);
