import { mergeObjectDecoders } from '@execonline-inc/decoders';
import Decoder, { field, succeed } from 'jsonous';
import { learningPartnerResourceDecoder } from '../../LearningPartnersStore/Decoders';
import { LearningPartnerResource } from '../../LearningPartnersStore/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import { Resource } from '../../Resource/Types';
import { userSegmentDecoder } from '../../TeamResourceStore/Decoders';
import { UserSegment } from '../../TeamResourceStore/Types';

export interface LearningPartnerAssignment extends UserSegment {
  learningPartner: LearningPartnerResource;
}

export type LearningPartnerAssignmentResource = Resource<LearningPartnerAssignment>;

const learningPartnerDecoder = succeed({}).assign(
  'learningPartner',
  field('learning_partner', learningPartnerResourceDecoder)
);

export const learningPartnerAssignmentDecoder: Decoder<
  LearningPartnerAssignmentResource
> = resourceDecoder(mergeObjectDecoders(userSegmentDecoder, learningPartnerDecoder));
