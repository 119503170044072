import * as React from 'react';
import TeamStore from '../../TeamStore';
import { T } from '../../Translations';
import PresenceTeamProfileContext from './PresenceTeamProfileContext';

interface Props {
  teamStore: TeamStore;
}

const PresenceTeamProfile: React.FC<Props> = ({ teamStore }) =>
  teamStore.team
    .map((teamResource) => (
      <PresenceTeamProfileContext teamStore={teamStore} teamResource={teamResource} />
    ))
    .getOrElse(() => (
      <div>
        <T kind="Team not found" />
      </div>
    ));

export default PresenceTeamProfile;
