import { observer } from 'mobx-react';
import * as React from 'react';
import Loader from '../../components/Loader';
import { LoginStore } from '../Store';
import EmailForm from './EmailForm';
import LockoutNotice from './LockoutNotice';
import PasswordForm from './PasswordForm';
import SingleSignOn from './SingleSignOn';

interface Props {
  store: LoginStore;
}

const LoginView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'awaiting-username':
    case 'identifying-assertion-backend':
      return <EmailForm store={store} />;
    case 'awaiting-password':
    case 'submitting-password':
      return <PasswordForm store={store} username={store.state.username} />;
    case 'awaiting-sso-flow':
      return <SingleSignOn store={store} state={store.state} />;
    case 'locked-out':
      return <LockoutNotice store={store} />;
    case 'successful-login':
      return <Loader />;
  }
};

export default observer(LoginView);
