import * as React from 'react';
import { TeamResource } from '../../../TeamsStore/Types';
import { T } from '../../../Translations';
import * as style from '../style.module.css';
import FileGroups from './FileGroups';

interface Props {
  teamResource: TeamResource;
}
const TeamFiles: React.FC<Props> = ({ teamResource }) => (
  <div className={style.content}>
    <div className={style.files} data-test-team-files={true}>
      <div className={style.filesHeader}>
        <h2>
          <T kind="Team Files" />
        </h2>
      </div>
      <div>
        <p className={style.confidentialNotice}>
          <T kind="Please remember team files are confidential ..." />
        </p>
        <FileGroups assignments={teamResource.payload.assignments} />
      </div>
    </div>
  </div>
);

export default TeamFiles;
