import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { CarouselStore } from '../../../../Common/Experience/Carousel';
import { ExperienceResources, PerPageCount } from '../../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../../Common/ProgramExperience';
import { ProgramExperienceCarousel } from '../../../../Common/ProgramExperienceCarousel';
import EnrollmentStore from '../../../Enrollment/Store';
import { useExperiencesContext } from '../ExperiencesContext';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experienceResources: ExperienceResources;
  perPageCount: PerPageCount;
}

function TileCarouselExperiences({
  invitationUuid,
  experienceResources,
  perPageCount,
  enrollmentStore,
}: Props) {
  const experiencesStore = useExperiencesContext();
  const carouselStore = useMemo(
    () => new CarouselStore(experienceResources, perPageCount),
    [experienceResources, perPageCount],
  );

  return (
    <ProgramExperienceCarousel carouselStore={carouselStore}>
      {({ className, items }) => {
        return items.map((experienceResource) => (
          <TileViewProgramExperience
            key={experienceResource.payload.experienceId}
            className={className}
            invitationUuid={invitationUuid}
            experienceResource={experienceResource}
            experiencesStore={experiencesStore}
            enrollmentStore={enrollmentStore}
          />
        ));
      }}
    </ProgramExperienceCarousel>
  );
}

export default observer(TileCarouselExperiences);
