import { observer } from 'mobx-react';
import {
  CommunityProgram,
  CommunityResource,
  CommunityTeam,
} from '../../../CommunitiesStore/Types';
import CommunitySummaryContent from './CommunitySummaryContent';
import ProgramSummaryContent from './ProgramSummaryContent';
import { resource } from '../../../../src/Resource/Types';

interface Props {
  communityResource: CommunityResource;
}

const SummaryContent: React.FC<Props> = ({ communityResource }) => {
  switch (communityResource.payload.kind) {
    case 'team':
      return (
        <CommunitySummaryContent
          key={communityResource.payload.id}
          teamSummaryResource={resource<CommunityTeam>(
            communityResource.links,
            communityResource.payload
          )}
        />
      );
    case 'program':
      return (
        <ProgramSummaryContent
          key={communityResource.payload.id}
          programResource={resource<CommunityProgram>(
            communityResource.links,
            communityResource.payload
          )}
        />
      );
  }
};

export default observer(SummaryContent);
