import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import * as style from '../style.module.css';

const PendingActionLabel: React.FC = () => (
  <div className={style.actionPending} data-test-action-pending={true}>
    <T kind="Registration is processing. The meeting link will be available here shortly." />
  </div>
);

export default observer(PendingActionLabel);
