import { useContext } from 'react';
import { PreviewModeContext } from './PreviewModeContext';

export function usePreviewContext() {
  const context = useContext(PreviewModeContext);

  if (!context) {
    throw new Error('usePreviewContext has to be used within the PreviewModeProvider.');
  }

  return context;
}
