import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Experience, GroupCoachingProductDetails } from '../../../../../Experience/Types';
import {
  GroupCoachingDetailsItems,
  GroupCoachingOverviewItems,
  ProgramEnrolled,
  ProgramGraduated,
} from '../../../../../Experience/ExperienceBody';

interface Props {
  experience: Experience;
  productDetails: GroupCoachingProductDetails;
}

function GroupCoachingOverview({ experience, productDetails }: Props) {
  switch (experience.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return (
        <>
          <GroupCoachingOverviewItems productDetails={productDetails} />
          <GroupCoachingDetailsItems sessions={productDetails.groupCoachingSessions} />
        </>
      );
    case 'beginnable':
    case 'resumable':
      return (
        <>
          <ProgramEnrolled />
          <GroupCoachingDetailsItems sessions={productDetails.groupCoachingSessions} />
        </>
      );

    case 'graduated':
    case 'returnable':
      return (
        <>
          <ProgramGraduated />
          <GroupCoachingDetailsItems sessions={productDetails.groupCoachingSessions} />
        </>
      );
    case 'upcoming':
      return emptyFragment();
  }
}

export default observer(GroupCoachingOverview);
