import { observer } from 'mobx-react';
import * as React from 'react';
import { createLanguageUrl } from '../../CreateLanguageUrl';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { findLink } from '../../LinkyLinky';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import SupportGraphic from '../Cutesies/SupportGraphic';
import * as style from './style.module.css';

interface Props {
  currentUserResource: CurrentUserResource;
}

const Support: React.FC<Props> = ({ currentUserResource }) =>
  findLink('support', currentUserResource.links)
    .andThen((link) => createLanguageUrl(link.href, currentUserResource.payload.preferredLanguage))
    .map(({ href }) => {
      return (
        <div className={style.support} data-test-support-ad={true}>
          <h2>
            <T kind="Support" />
          </h2>
          <div className={style.container}>
            <div className={style.graphic}>
              <div className={style.image}>
                <SupportGraphic />
              </div>
            </div>
            <div className={style.content}>
              <h3 className={style.title}>
                <T kind="Have a question or need assistance?" />
              </h3>
              <p className={style.description}>
                <T kind="Visit our Support Desk, where you can search ..." />
              </p>
              <a
                href={href}
                className={style.buttonSecondaryWithIcon}
                target="_blank"
                rel="noopener noreferrer"
                data-test-support-ad-link
              >
                <T kind="Go to the Support Desk" />
                <div className={style.buttonIcon}>
                  <ArrowRightIcon />
                </div>
              </a>
            </div>
          </div>
        </div>
      );
    })
    .getOrElse(() => <div />);

export default observer(Support);
