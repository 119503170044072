import FreebusyStore, { FreebusySettings } from '.';
import { Freebusy } from '../components/Freebusy/Types';
import FreeBusyTestStore from '../FreeBusyTestStore';

type Store = FreebusyStore | FreeBusyTestStore;

export function successfulLoading(
  freebusySettings: FreebusySettings,
  freebusyStore: Store,
  freebusy: Freebusy
): void;
export function successfulLoading(
  freebusySettings: FreebusySettings,
  freebusyStore: Store
): (freebusy: Freebusy) => void;
export function successfulLoading(
  freebusySettings: FreebusySettings,
  freebusyStore: Store,
  freebusy?: Freebusy
) {
  const doit = (freebusy: Freebusy) => freebusyStore.initializing(freebusy, freebusySettings);

  return typeof freebusy === 'undefined' ? doit : doit(freebusy);
}
