import { observer } from 'mobx-react';
import { Chip, Typography, GraduationCapIcon } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';

function ProgramGraduated() {
  return (
    <Chip className="gap-x-2 self-start" color="blue" variant="outlined">
      <GraduationCapIcon color="inherit" size="sm" />
      <Typography
        color="inherit"
        className="tracking-[0.2px]"
        variant="caption"
        fontWeight="medium"
        as="span"
      >
        <T kind="Graduated" />
      </Typography>
    </Chip>
  );
}

export default observer(ProgramGraduated);
