import { observer } from 'mobx-react';
import * as React from 'react';
import { Linkable } from '../../Resource/Types';
import { T, TPlainTextKey } from '../../Translations';
import Calendar from '../Calendar';
import * as style from '../Calendar/style.module.css';

interface Props {
  header: TPlainTextKey;
  linkable: Linkable;
  headingLevel: 2 | 3;
}

const CalendarPanel: React.FC<Props> = ({ header, linkable, headingLevel }) => (
  <>
    <h2 className={style.supPanelHeader} data-test-sup-panel-header={header}>
      <T kind={header} />
    </h2>
    <div className={style.mini} data-test-calendar-panel={true}>
      <Calendar headingLevel={headingLevel} linkable={linkable} />
    </div>
  </>
);

export default observer(CalendarPanel);
