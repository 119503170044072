import { observer } from 'mobx-react';
import * as React from 'react';
import ImageStore from './ImageStore';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {}

class Image extends React.Component<Props> {
  imageStore: ImageStore = new ImageStore();

  render() {
    const { alt, ...others } = this.props;
    const onErrorHandle = () => {
      this.imageStore.error();
    };

    switch (this.imageStore.state.kind) {
      case 'loading':
        return <img alt={alt} data-test-image={alt} {...others} onError={onErrorHandle} />;
      case 'error':
        return <></>;
    }
  }
}
export default observer(Image);
