import { ChevronRightIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ConfirmationModal } from '../../../../../SegmentStore/Types';
import { NotTranslated } from '../../../../../Translations';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import Confirm from '../../../../Confirm';
import { useOpenable } from '../../../../Openable';
import { AdvanceButton } from '../../Common';

interface Props {
  confirmationModal: ConfirmationModal;
  onConfirm: () => void;
}

function ConfirmCompleteAndAdvanceButton({
  children,
  confirmationModal,
  onConfirm,
}: PropsWithChildren<Props>) {
  const { translate } = useTranslationsContext();
  const { openableState, open, close } = useOpenable();

  return (
    <>
      <AdvanceButton
        data-testid="confirm-complete-and-advance-button"
        title={translate('Complete and Advance')}
        endContent={<ChevronRightIcon color="inherit" size="sm" />}
        onClick={open}
      >
        {children}
      </AdvanceButton>
      <Confirm
        state={openableState}
        close={close}
        title={<NotTranslated text={confirmationModal.title} />}
        content={ReactHtmlParser(confirmationModal.content)}
        confirmText={<NotTranslated text={confirmationModal.button} />}
        onConfirm={onConfirm}
      />
    </>
  );
}

export default observer(ConfirmCompleteAndAdvanceButton);
