export type StorageType = 'LocalStorage' | 'SessionStorage';

interface StorageInteractionErrorData {
  storageType: StorageType;
  error: unknown;
}

export interface StorageRemoveItemError extends StorageInteractionErrorData {
  kind: 'storage-remove-item-error';
}

export interface StorageGetItemError extends StorageInteractionErrorData {
  kind: 'storage-get-item-error';
}

export interface StorageSetItemError extends StorageInteractionErrorData {
  kind: 'storage-set-item-error';
}

export const storageRemoveItemError = (
  data: StorageInteractionErrorData,
): StorageRemoveItemError => ({
  kind: 'storage-remove-item-error',
  ...data,
});

export const storageGetItemError = (data: StorageInteractionErrorData): StorageGetItemError => ({
  kind: 'storage-get-item-error',
  ...data,
});

export const storageSetItemError = (data: StorageInteractionErrorData): StorageSetItemError => ({
  kind: 'storage-set-item-error',
  ...data,
});
