import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DownloadAttachmentIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 22 21">
      <g fill="none">
        <ellipse cx="10.578" cy="10.5" fill="#666" rx="10.578" ry="10.5" />
        <path
          stroke={defaultTheme.variables['icon-on-dark']}
          fill={defaultTheme.variables['icon-on-dark']}
          d="M10.516 5v8.392l-2.858-2.836-.606.601 3.582 3.555.303.288.303-.288 3.582-3.555-.606-.601-2.857 2.836V5z"
        />
      </g>
    </SVGIcon>
  );
};

export default observer(DownloadAttachmentIcon);
