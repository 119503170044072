import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fill: string;
}

const ResourcesIcon: React.FC<Props> = ({ fill }) => {
  return (
    <SVGIcon viewBox="0 0 17 18">
      <path
        fill={fill}
        d="M2.25 0A2.262 2.262 0 0 0 0 2.25v10.5A2.262 2.262 0 0 0 2.25 15h3v.75A2.262 2.262 0 0 0 7.5 18h9V3h-5.25V0h-9Zm0 1.5h7.5V3H7.5c-.66 0-1.251.293-1.664.75H3v1.5h2.25v5.25h-3c-.264 0-.513.056-.75.14V2.25c0-.425.325-.75.75-.75Zm5.25 3H15v9H7.5c-.264 0-.513.056-.75.14V5.25c0-.425.325-.75.75-.75Zm.75 2.25v1.5h5.25v-1.5H8.25Zm-6 5.25h3v1.5h-3a.737.737 0 0 1-.75-.75c0-.425.325-.75.75-.75Zm5.25 3H15v1.5H7.5a.737.737 0 0 1-.75-.75c0-.425.325-.75.75-.75Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourcesIcon);
