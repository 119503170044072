import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { TPlainTextKey } from '../../../../../../../Translations';
import PaymentCheckoutFormStore from '.';

interface Waiting {
  kind: 'waiting';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

interface ReadyForPaymentConfirmation {
  kind: 'ready-for-payment-confirmation';
}

export const readyForPaymentConfirmation = (): ReadyForPaymentConfirmation => ({
  kind: 'ready-for-payment-confirmation',
});

interface ConfirmingPayment {
  kind: 'confirming-payment';
  stripe: Stripe;
  elements: StripeElements;
}

export const confirmingPayment = (stripe: Stripe, elements: StripeElements): ConfirmingPayment => ({
  kind: 'confirming-payment',
  stripe,
  elements,
});

interface PaymentDeclined {
  kind: 'payment-declined';
  message: TPlainTextKey;
}

export const paymentDeclined = (message: TPlainTextKey): PaymentDeclined => ({
  kind: 'payment-declined',
  message,
});

export type PaymentCheckoutFormState =
  | Waiting
  | ReadyForPaymentConfirmation
  | ConfirmingPayment
  | PaymentDeclined;

export const onPaymentCheckoutFormSubmit =
  (store: PaymentCheckoutFormStore, stripe: Stripe, elements: StripeElements) =>
  (e: React.FormEvent): void => {
    e.preventDefault();
    switch (store.state.kind) {
      case 'waiting':
      case 'confirming-payment':
        return warn(`Can't submit form from ${store.state.kind} state`);
      case 'ready-for-payment-confirmation':
      case 'payment-declined':
        return store.confirmingPayment(stripe, elements);
      default:
        assertNever(store.state);
    }
  };
