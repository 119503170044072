import { fromNullable } from 'maybeasy';
import { Task } from 'taskarian';
import { log } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { PaymentIntent, PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import PaymentCheckoutFormStore from '../PaymentCheckoutFormStore';

const handleSuccess = (result: PaymentIntentResult, onSuccess: (data: PaymentIntent) => void) => {
  fromNullable(result.paymentIntent).do(onSuccess);
};

const handleError = (store: PaymentCheckoutFormStore, result: PaymentIntentResult) => {
  fromNullable(result.error).do((error) => {
    switch (error.type) {
      case 'card_error':
        return store.paymentDeclined(
          'Your payment was declined by the credit card provider. Please try submitting payment again using a different credit card, or contact support@execonline.com for assistance.',
        );
      case 'validation_error':
      case 'api_connection_error':
      case 'api_error':
      case 'authentication_error':
      case 'idempotency_error':
      case 'invalid_request_error':
      case 'rate_limit_error':
        return store.readyForPaymentConfirmation();
      default:
        assertNever(error.type);
    }
  });
};

export const confirmPayment = (
  stripe: Stripe,
  elements: StripeElements,
  store: PaymentCheckoutFormStore,
  onSuccess: (data: PaymentIntent) => void,
): void => {
  Task.fromPromise(() => stripe.confirmPayment({ elements, redirect: 'if_required' })).fork(
    log,
    (result: PaymentIntentResult) => {
      handleError(store, result);
      handleSuccess(result, onSuccess);
    },
  );
};
