import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string;
}

function PageLayoutWrapper({ className, children }: PropsWithChildren<Props>) {
  return (
    <div
      data-testid="execonline-page-layout"
      className={cn('flex min-h-screen flex-col bg-board', className)}
    >
      {children}
    </div>
  );
}

export default observer(PageLayoutWrapper);
