import { ButtonProps, ButtonSize } from '../Button/Types';

export const buttonSizeClassNameMapping: Record<ButtonSize, string> = {
  sm: 'w-7 h-7',
  md: 'w-8 h-8',
  lg: 'w-10 h-10',
  xl: 'w-[52px] h-[52px]',
};

export interface IconButtonProps extends Omit<ButtonProps, 'variant'> {}
