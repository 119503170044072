import { findPayload } from '@execonline-inc/collections';
import { parseIntM } from '@execonline-inc/numbers';
import { Maybe, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { programsStore } from '../../ProgramsStore';
import { ProgramResource } from '../../ProgramsStore/Types';
import TeamStore from '../../TeamStore';
import TeamReactions from '../../TeamStore/TeamReactions';
import { TeamResource } from '../../TeamsStore/Types';
import Conference from '../Conference';
import ConferenceNotReady from '../Conference/ConferenceNotReady';
import { RoomConfiguration, roomConfigError, teamRoomConfig } from '../Conference/Types';

export interface Params {
  teamId: string;
}

export interface Props {
  match: { params: Params };
}

class TeamConference extends React.Component<Props, {}> {
  teamStore: TeamStore = new TeamStore();
  teamId = parseIntM(this.props.match.params.teamId);

  componentDidMount() {
    this.teamStore.loading();
  }

  programName = (programId: number): Maybe<string> =>
    programsStore.resource
      .map(({ payload }) => payload.programs)
      .andThen(findPayload(programId))
      .map((program: ProgramResource) => program.payload.title.text);

  findTeamResource = (id: string) => (resources: TeamResource[]): Maybe<TeamResource> =>
    parseIntM(id)
      .map((n) => findPayload(n))
      .andThen((fn) => fn(resources));

  roomConfig = (teamId: Maybe<number>) =>
    teamId
      .map((teamId) => teamRoomConfig(teamId) as RoomConfiguration)
      .getOrElseValue(roomConfigError());

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            {just({})
              .assign('teamResource', this.teamStore.team)
              .assign(
                'conferenceRoom',
                this.teamStore.team.andThen((team) => team.payload.conferenceRoom)
              )
              .map(({ teamResource, conferenceRoom }) => (
                <Conference
                  title={teamResource.payload.name}
                  detail={this.programName(teamResource.payload.courseId)}
                  conversationResource={teamResource.payload.teamChat.payload.conversationResource}
                  agenda={nothing()}
                  eventResource={nothing()}
                  conferenceableResource={teamResource}
                  roomConfig={this.roomConfig(this.teamId)}
                  currentUserResource={currentUserResource}
                  conferenceRoom={conferenceRoom.payload}
                />
              ))
              .getOrElse(() => (
                <ConferenceNotReady teamStore={this.teamStore} />
              ))}
            <TeamReactions
              store={this.teamStore}
              teamId={this.teamId}
              currentUserResource={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default observer(TeamConference);
