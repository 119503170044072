import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert, AlertStatus, Typography } from '../../components/UI';
import { FlashAlert } from '../../Notifications/Types';
import AlertMessage from './AlertMessage';

const alertStatus = (alert: FlashAlert): AlertStatus => {
  switch (alert.kind) {
    case 'error-alert':
    case 'server-error-alert':
    case 'payment-loading-error-alert':
      return 'error';
    case 'info-alert':
      return 'info';
    case 'success-alert':
      return 'success';
    case 'warning-alert':
      return 'warning';
  }
};

export interface Props {
  alert: FlashAlert;
}

const FlashMessage: React.FC<Props> = ({ alert }) => {
  return (
    <Alert className="mb-3 sm:mb-7" variant="outlined" status={alertStatus(alert)}>
      <Typography variant="body2" color="black">
        <AlertMessage alert={alert} />
      </Typography>
    </Alert>
  );
};

export default observer(FlashMessage);
