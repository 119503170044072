import React from 'react';
import { Maybe, nothing } from 'maybeasy';

export type PlatformWrapperRef = React.RefObject<HTMLDivElement>;

export type PlatformWrapper = Maybe<PlatformWrapperRef>;

const PlatformWrapperContext = React.createContext<PlatformWrapper>(nothing());

export default PlatformWrapperContext;
