import * as React from 'react';
import { T } from '../../../Translations';

interface Props {
  count: number;
}

const DisplayedCount: React.FC<Props> = ({ count }) => {
  switch (count) {
    case 0:
      return <></>;
    default:
      return <T kind="{{count}} resource" count={count} />;
  }
};

export default DisplayedCount;
