import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { directSegmentRoute, experienceDetailsRoute } from '../../../../ClientRoutes';
import DiscoveryContext from '../../DiscoveryContext';
import ExperienceDetailViewStore from '../../ExperienceDetailView/ExperienceDetailViewStore';
import ExperienceDetailViewReactions from '../../ExperienceDetailView/ExperienceDetailViewStore/ExperienceDetailViewReactions';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import EnrollmentStore from './Store';

interface Props {
  experienceId: string;
  invitationUuid: string;
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

function GoToProgram({ invitationUuid, experienceId, registerResource, experiencesStore }: Props) {
  const experienceDetailViewStore = useConst(() => new ExperienceDetailViewStore());

  useEffect(() => {
    experienceDetailViewStore.loading(experienceId, registerResource);
  }, [experienceId, registerResource]);

  return experienceDetailViewStore.experienceResource
    .map((r) => {
      switch (r.payload.kind) {
        case 'resumable':
        case 'beginnable':
          return <Redirect to={directSegmentRoute(r.payload)} />;
        case 'upcoming':
        case 'enrollable':
        case 'graduated':
        case 'returnable':
        case 'not-enrollable':
        case 'not-licensed':
        case 'commerce-enrollable':
          return (
            <DiscoveryContext.Consumer>
              {(discoveryContextState) => (
                <Redirect
                  to={experienceDetailsRoute(
                    invitationUuid,
                    r.payload.experienceId,
                    discoveryContextState,
                  )}
                />
              )}
            </DiscoveryContext.Consumer>
          );
      }
    })
    .getOrElse(() => (
      <ExperienceDetailViewReactions
        store={experienceDetailViewStore}
        discoveryPortal={experiencesStore.discoveryPortal}
      />
    ));
}

export default observer(GoToProgram);
