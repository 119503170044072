import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import * as style from '../../Conference/style.module.css';
import Arrived from './Arrived';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const PrivateRoster: React.FC<Props> = ({ conferenceRosterStore }) => (
  <div className={style.participants} data-test-private-roster={true}>
    <Arrived conferenceRosterStore={conferenceRosterStore} />
  </div>
);

export default observer(PrivateRoster);
