import React from 'react';
import { SegmentStatus } from '../../../../../ProgramStore/Types';
import { observer } from 'mobx-react';
import CompleteSegmentIcon from '../../../../Cutesies/CompleteSegmentIcon';
import style from './style.module.css';
import CurrentSegmentIcon from '../../../../Cutesies/CurrentSegmentIcon';

type IProps = {
  status: SegmentStatus
}
const SegmentStatusIcon: React.FC<IProps> = ({ status}): JSX.Element => {
  switch (status) {
    case 'Complete':
      return <CompleteSegmentIcon />;
    case 'Accessible':
      return <div className={style.currentSegmentIcon}><CurrentSegmentIcon /></div>;
    case 'Started':
    case 'Inaccessible':
      return <></>;
  }
};

export default observer(SegmentStatusIcon);