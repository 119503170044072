import { CountryOption } from '../../../CountryOptionsStore/Types';
import { CountryRegionOption } from '../../../CountryRegionOptionsStore/Types';
import { SelectOption } from '../../Form/Select/Option';

const emptyOption = { value: '', content: '' };

type Option = CountryRegionOption | CountryOption;

export const convertArrayToOptions = (options: Option[]): SelectOption[] => [
  emptyOption,
  ...options.map((option) => ({ content: option.name, value: option.id })),
];
