import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { currentUserStore } from '../CurrentUser/Store';
import { TPlainTextKey } from '../Translations';
import Loader from '../components/Loader';

export const logout = (message: TPlainTextKey): void => {
  currentUserStore.loggingOut(message);
};

class Logout extends React.Component {
  componentDidMount(): void {
    logout('Goodbye!');
  }

  render() {
    switch (currentUserStore.state.kind) {
      case 'waiting':
      case 'loading':
      case 'refreshing':
      case 'logging-out':
        return <Loader />;
      case 'ready':
      case 'errored':
      case 'anonymous':
        return <Redirect to="/" />;
    }
  }
}

export default observer(Logout);
