import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import DynamicHeading from '../../../Accessibility/DynamicHeading';
import { ActiveProgram, CompletedProgram, programEndOnDate } from '../../../ProgramStore/Types';
import { L, T } from '../../../Translations';
import * as style from '../../ProgramSummary/style.module.css';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
  headingLevel?: 3 | 4 | 5 | 6;
  classname?: string;
}

const endingFormatted = (program: ActiveProgram | CompletedProgram) =>
  just({})
    .assign('endsOn', programEndOnDate(program))
    .assign('daysRemaining', program.daysRemaining);

const EndDate: React.FC<Props> = ({ programDetail, headingLevel = 4, classname = '' }) => {
  return endingFormatted(programDetail)
    .map(({ endsOn, daysRemaining }) => (
      <dl className={`${style.dl} ${classname}`}>
        <dt data-test-end-date-label={true}>
          <DynamicHeading level={headingLevel} className={style.subHeader}>
            <T kind="End Date" />
          </DynamicHeading>
        </dt>
        <dd data-test-end-date-value={endsOn}>
          <L localizeable={endsOn} format="long-month-day-year" />
          &nbsp;
          <T kind="({{count}} day remaining)" count={daysRemaining} />
        </dd>
      </dl>
    ))
    .getOrElseValue(<></>);
};
export default observer(EndDate);
