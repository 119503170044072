import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import FileUploadStore from '../../FileUploadStore';
import AdvancedFileUpload from './AdvancedFileUpload';
import SimpleFileUpload from './SimpleFileUpload';

interface Props {
  store: FileUploadStore;
  buttonClass: string;
}

const FileUpload: React.FC<Props> = ({ store, buttonClass }) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (currentUserResource.payload.fileUploadType) {
        case 'Advanced':
          return (
            <AdvancedFileUpload
              store={store}
              containerClass=".attachment-upload"
              buttonClass={buttonClass}
              buttonText="add a photo"
              acceptableFileExtensions={['pdf', 'jpg', 'jpeg', 'png']}
              currentUserResource={currentUserResource}
            />
          );
        case 'Simple':
          return (
            <SimpleFileUpload
              store={store}
              buttonClass={buttonClass}
              buttonText="add a photo"
              currentUserResource={currentUserResource}
            />
          );
      }
    }}
  />
);

export default observer(FileUpload);
