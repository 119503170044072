import { Time, toJS } from '@execonline-inc/time';
import { DependencyList, useEffect } from 'react';
import { Destructor } from './Types';

/**
 * This hook is for effects that should run their cleanup after a period of time.
 */
export const useTemporaryEffect = (
  lifetime: Time,
  effect: () => Destructor,
  deps: DependencyList = [],
): void => {
  useEffect(() => {
    let done = false;

    const destructor = effect();

    const cleanUp = (): void => {
      if (done) return;
      done = true;
      destructor();
    };

    const timeout = setTimeout(cleanUp, toJS(lifetime));

    return () => {
      clearTimeout(timeout);
      cleanUp();
    };
  }, [...deps]);
};
