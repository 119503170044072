import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const NumberedListIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 13">
      <path
        fill="currentColor"
        d="M0 0v.778h.778v2.334h.778V0H0Zm3.889.778v1.555h11.667V.778H3.889ZM0 4.667v.777h1.395L0 6.84v.939h2.334V7H.938l1.394-1.395v-.938H0Zm3.889.777V7h11.667V5.444H3.889ZM0 9.334v.777h1.556v.389H.778v.778h.778v.389H0v.777h2.333V9.333H0Zm3.889.777v1.556h11.667V10.11H3.889Z"
      />
    </SVGIcon>
  );
};

export default observer(NumberedListIcon);
