import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Task } from 'taskarian';
import { PendingSurveyStore } from '.';
import { AppyError, callApi } from '../Appy';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { surveyCompletionTrackingResourceDecoder } from '../VideoStore/Legacy/Decoders';
import SurveyModalStore from '../components/SurveyModal/Modal/Store';
import { State } from './Types';

interface Props extends EAProps<PendingSurveyStore> {
  currentUserResource: CurrentUserResource;
}

type PendingSurveyError = MissingLinkError | AppyError;

const handleBadStatus = (store: PendingSurveyStore, error: BadStatus) => {
  switch (error.response.status) {
    case 404:
      // Expected if a user has a pending survey but it isn't ready to show
      // ex: they haven't hit the display_at date on the survey yet
      break;
    default:
      warn(error);
      handleError(store, error);
  }
};

const handlePendingSurveyError = (store: PendingSurveyStore) => (error: PendingSurveyError) => {
  switch (error.kind) {
    case 'missing-link-error':
      // expected if user has no pending surveys
      break;
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    default:
      warn(error);
      handleError(store, error);
  }
};

class PendingSurveyReactions extends ErrorActionableReaction<PendingSurveyStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State): void => {
    switch (state.kind) {
      case 'waiting':
        this.props.store.load(this.props.currentUserResource);
        break;
      case 'loading':
        Task.succeed<PendingSurveyError, ReadonlyArray<Link>>(state.linkable.links)
          .andThen(findLinkT('pending-surveys'))
          .andThen(callApi(surveyCompletionTrackingResourceDecoder, {}))
          .fork(handlePendingSurveyError(this.props.store), (s) =>
            this.props.store.ready(new SurveyModalStore(s)),
          );
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default PendingSurveyReactions;
