import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import TeamsStore from '../../TeamsStore';
import BreakoutButtonStates from './BreakoutButtonStates';
import BreakoutReactions from './BreakoutReactions';

interface Props {
  breakoutStore: BreakoutChannelStore;
}

class BreakoutButton extends React.Component<Props> {
  teamsStore = new TeamsStore();

  render() {
    const { breakoutStore } = this.props;

    switch (breakoutStore.timeRemainingStore.state.kind) {
      case 'active':
      case 'expiring':
        return (
          <WithCurrentUser
            children={(currentUserResource) => (
              <>
                <BreakoutButtonStates breakoutStore={breakoutStore} teamsStore={this.teamsStore} />
                <BreakoutReactions
                  store={this.teamsStore}
                  breakoutStore={breakoutStore}
                  currentUserResource={currentUserResource}
                />
              </>
            )}
          />
        );
      case 'waiting':
      case 'expired':
        return <></>;
    }
  }
}

export default observer(BreakoutButton);
