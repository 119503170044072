import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const UnstartedSegmentIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 22 22">
      <circle
        className="circle"
        cx="11"
        cy="11"
        r="11"
        fill={defaultTheme.variables['icon-standard-on-dark']}
      />
    </SVGIcon>
  );
};

export default observer(UnstartedSegmentIcon);
