import * as React from 'react';
import { ProgramSegment } from '../../../../../../../ProgramStore/Types';
import { NotTranslated, T } from '../../../../../../../Translations';
import UnstartedSegmentIcon from '../../../../../../Cutesies/UnstartedSegmentIcon';
import SegmentInfo from '../SegmentInfo';
import * as style from '../style.module.css';

interface Props {
  segment: ProgramSegment;
}

const InaccessibleSegment: React.FC<Props> = ({ segment }) => (
  <li
    className={style.segment}
    key={`segment-${segment.id}`}
    data-test-segment={segment.id}
    data-test-segment-type="inaccessible"
  >
    <div className={style.inaccessible}>
      <div className={style.iconStatus} data-test-segment-link={segment.id}>
        <UnstartedSegmentIcon />
        <div className={style.line} />
      </div>
      <div className={style.title} data-test-segment-long-title={segment.longTitle}>
        <NotTranslated text={segment.longTitle} />
      </div>
      <div className={style.stats}>
        <div className={style.statsContent}>
          <div className={style.message} data-test-inaccessible-message={true}>
            <T kind="Segments must be completed in order. Please ..." />
          </div>
          <SegmentInfo segment={segment} />
        </div>
      </div>
    </div>
  </li>
);

export default InaccessibleSegment;
