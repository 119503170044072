import { observer } from 'mobx-react';
import ProgramStore from '../../ProgramStore';
import { ActiveProgram, ProductDetails, ProgramDetailResource } from '../../ProgramStore/Types';
import ActiveProgramProductOverview from './ActiveProgramProductOverview';
import * as React from 'react';
import ActiveFullCoachingProductOverview from './ActiveFullCoachingProductOverview';
import GroupCoachingProgramOverview from './GroupCoachingProgramOverView';
import ActiveProgramSequenceOverview from './ActiveProgramSequenceOverview';
import * as style from '../Dashboard/style.module.css';

interface Props {
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
  productDetails: ProductDetails;
  activeProgram: ActiveProgram;
}

const ActiveProgramFullOverview: React.FC<Props> = ({
  programDetailResource,
  store,
  productDetails,
  activeProgram,
}) => {
  switch (productDetails.kind) {
    case 'group-coaching':
      return <GroupCoachingProgramOverview productDetails={productDetails} store={store} />;
    case 'program-sequence':
      return (
        <ActiveProgramSequenceOverview
          programDetailResource={programDetailResource}
          store={store}
        />
      );
    case 'program':
      return (
        <ActiveProgramProductOverview programDetailResource={programDetailResource} store={store} />
      );
    case 'coaching':
      return (
        <div className={style.program} data-test-active-program={true}>
          <ActiveFullCoachingProductOverview activeProgram={activeProgram} store={store} />
        </div>
      );
  }
};

export default observer(ActiveProgramFullOverview);
