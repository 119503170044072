import { observer } from 'mobx-react';
import CompletedProgramOverviewDate from './CompletedProgramOverviewDate';
import { ActiveProgram, CompletedProgram } from '../../ProgramStore/Types';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const ProgramEndingDetails: React.FC<Props> = ({ programDetail }) =>
  programDetail.accessEndsOn
    .map((accessEndsOn) => (
      <CompletedProgramOverviewDate title="Program Access Until" date={accessEndsOn} />
    ))
    .getOrElse(() => <></>);

export default observer(ProgramEndingDetails);
