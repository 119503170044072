import * as React from 'react';
import { TPlainTextKey } from '../../../Translations';
import Input from '../Input';
import Label from '../Label';

interface Props {
  labelClassName?: string;
  labelText: TPlainTextKey;
  required?: boolean;
  type: string;
  value?: string;
  name: string;
  className?: string;
  onChange(event: React.FormEvent<HTMLInputElement>): void;
  autoFocus?: boolean;
  hint?: TPlainTextKey;
  id: string;
  readOnly?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  ariaLabel?: TPlainTextKey;
}

class InputWithLabel extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Label
          labelText={this.props.labelText}
          required={this.props.required}
          htmlFor={this.props.id}
          className={this.props.labelClassName}
        />
        <Input
          type={this.props.type}
          readOnly={this.props.readOnly}
          value={this.props.value}
          name={this.props.name}
          className={this.props.className}
          autoFocus={this.props.autoFocus}
          hint={this.props.hint}
          id={this.props.id}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          autoComplete={this.props.autoComplete}
          ariaLabel={this.props.ariaLabel}
        />
      </>
    );
  }
}

export default InputWithLabel;
