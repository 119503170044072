import { observer } from 'mobx-react';
import * as React from 'react';
import FreebusyStore from '../../../FreebusyStore';
import AdDisplayContent from './AdDisplayContent';

interface Props {
  freebusyStore: FreebusyStore;
}

class AdDisplay extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.freebusyStore.loading();
  }
  render() {
    return <AdDisplayContent freebusyStore={this.props.freebusyStore} />;
  }
}

export default observer(AdDisplay);
