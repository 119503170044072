import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatablePersonResourcesStore from '../../ChatableResourcesStore';
import { whenLocalConferenceRoom } from '../../Conference/Types';
import { Event } from '../../EventsStore/Types';
import BreakoutConversation from './BreakoutConversation';

interface Props {
  event: Event;
  chatablePersonResourcesStore: ChatablePersonResourcesStore;
}

const BreakoutConversations: React.FC<Props> = ({ event, chatablePersonResourcesStore }) =>
  just({})
    .assign(
      'conferenceRoom',
      event.conferenceRoom.andThen((conferenceRoom) =>
        whenLocalConferenceRoom(conferenceRoom.payload)
      )
    )
    .assign('brs', ({ conferenceRoom }) => conferenceRoom.breakoutRooms)
    .assign('chatablePersonResourceList', chatablePersonResourcesStore.chatablePersonResourceList)
    .map(({ conferenceRoom, brs, chatablePersonResourceList }) =>
      brs
        .toArray()
        .map((breakoutRoom) => (
          <BreakoutConversation
            key={breakoutRoom.teamId}
            breakoutRoom={breakoutRoom}
            roomId={conferenceRoom.conferenceId}
            chatablePersonResourceList={chatablePersonResourceList}
          />
        ))
    )
    .map((convos) => <>{convos}</>)
    .getOrElseValue(<></>);

export default observer(BreakoutConversations);
