import { PopoverTrigger, useDeviceDetect } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../Experience/Types';
import { getTriggerTypeByExperience } from './Types';

function useProgramPopoverTrigger(experience: ExperienceResource): PopoverTrigger {
  const device = useDeviceDetect();

  switch (device) {
    case 'mobile':
      return 'none';
    case 'desktop':
      return getTriggerTypeByExperience(experience);
  }
}

export default useProgramPopoverTrigger;
