import { observer } from 'mobx-react';
import * as React from 'react';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import CommunityProgramOptionsStore from './CommunityProgramOptionsStore';
import ContentFaker from '../../ContentFaker';
import Filters from './Filters';

interface Props {
  coachTeamsSummaryStore: coachTeamsSummaryStore;
  optionsStore: CommunityProgramOptionsStore;
}

const CommunityProgramFilters: React.FC<Props> = ({ coachTeamsSummaryStore, optionsStore }) => {
  switch (optionsStore.state.kind) {
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
    case 'ready':
      return (
        <Filters coachTeamsSummaryStore={coachTeamsSummaryStore} optionsStore={optionsStore} />
      );
    case 'error':
      return <></>;
  }
};

export default observer(CommunityProgramFilters);
