import * as React from 'react';
import { CalendarEvent } from '../../EventsStore/Types';
import EventTimes from './EventTimes';
import * as style from './style.module.css';

interface Props {
  event: CalendarEvent;
}
const EventDetails: React.FC<Props> = ({ event }) => (
  <div className={style.details} data-test-event-details={true}>
    <EventTimes event={event} />
  </div>
);

export default EventDetails;
