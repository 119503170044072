import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { CoachingProductDetails } from '../../../../Common/Experience/Types';
import {
  ExperienceDetailsSection,
  ExperienceDetailsSectionHeader,
  ExperienceHtmlView,
} from '../../../Common';

interface Props {
  productDetails: CoachingProductDetails;
}

function KeyBenefits({ productDetails }: Props) {
  return productDetails.keyBenefitsHtml
    .map((data) => (
      <ExperienceDetailsSection className="xl:w-[85%]">
        <ExperienceDetailsSectionHeader
          title="Key Benefits"
          subTitle="Welcome to ExecOnline's Leadership Coaching! Your experience includes the following components."
        />
        <ExperienceHtmlView
          className="!text-sm [&>ul]:grid [&>ul]:grid-cols-1 [&>ul]:gap-3 md:[&>ul]:grid-cols-2 md:[&>ul]:gap-8"
          data={data}
        />
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(KeyBenefits);
