import * as React from 'react';
import { T } from '../../../Translations';

const IncompleteAttachment: React.FC = () => (
  <div data-test-incomplete-attachment={true}>
    <T kind="File not available. The upload is still being processed." />
  </div>
);

export default IncompleteAttachment;
