import { log } from '@execonline-inc/logging';
import * as React from 'react';
import { ScriptTag } from '../../ContentEmbed/Types';

interface Props {
  node: ScriptTag;
}
const Script: React.FC<Props> = ({ node }) => {
  try {
    // This is faster and safer than using eval
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#Do_not_ever_use_eval
    Function('"use strict";return (' + node.content + ')')();
  } catch (err) {
    log('Error caught:', err);
  }

  return <span />;
};

export default Script;
