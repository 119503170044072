import * as React from 'react';
import { Link } from 'react-router-dom';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';

interface Props {
  teamId: number;
}

const TeamProfileHeader: React.FC<Props> = ({ teamId }) => (
  <>
    <div className={style.membersHeader}>
      <h2>
        <T kind="Team Members" />
      </h2>
    </div>
    <Link
      to={`/teams/${teamId}/team-members`}
      className={style.newjoinButton}
      target="_blank"
      rel="noopener noreferrer"
    >
      <T kind="View Members List" />
      <div className={style.buttonIcon}>
        <ArrowRightIcon />
      </div>
    </Link>
  </>
);

export default TeamProfileHeader;
