import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import Modal from '../../Modal';
import ModalStore from '../../Modal/Store';
import * as style from '../style.module.css';
import ContactSupport from './ContactSupport';

interface Props {
  store: ModalStore;
}

const onClick = () => {
  location.reload();
};

const TroubleshootingModal: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'open':
      return (
        <Modal close={store.close} title={<T kind="Troubleshooting Meeting Issues" />}>
          <p>
            <T kind="troubleshooting" />
          </p>
          <div className={style.refreshMeetingButtonWrapper}>
            <button
              type="button"
              onClick={onClick}
              className={style.refreshMeetingButton}
              data-test-restart-meeting={true}
            >
              <T kind="Restart Meeting" />
            </button>
          </div>
          <ContactSupport />
        </Modal>
      );
    case 'closed':
      return <></>;
  }
};
export default observer(TroubleshootingModal);
