import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { mapMaybe } from '@execonline-inc/collections';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../../Translations';
import { ExperienceResource } from '../../../../../../Experience/Types';
import { whenNoConflictAvailability } from '../../../../../../Experience/Availability';

interface Props {
  experienceResource: ExperienceResource;
}

function AvailabilityDescription({ experienceResource }: Props) {
  const { availabilities } = experienceResource.payload;

  return fromArrayMaybe(mapMaybe(whenNoConflictAvailability, availabilities))
    .map(emptyFragment)
    .getOrElse(() => (
      <Typography className="mt-4 text-[#505050]" variant="caption" as="p">
        <T kind="Earlier options may not be available due to conflicts with other enrollments" />
      </Typography>
    ));
}

export default observer(AvailabilityDescription);
