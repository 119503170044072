import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import Modal from '../../Modal';
import { CloseAction, OpenableState, WithOpenableLogic } from '../../Openable';
import * as style from './style.module.css';

export interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  confirmText: React.ReactNode;
  onConfirm: () => void;
  close: CloseAction;
  state: OpenableState;
}

const ConfirmModal: React.FC<Props> = ({
  state,
  close,
  title,
  content,
  confirmText,
  onConfirm,
}) => {
  return (
    <WithOpenableLogic state={state}>
      <Modal close={close} title={title}>
        {content}
        <div className={style.buttons}>
          <button className={style.noButton} onClick={close} data-test-delete-message-no-button>
            <T kind="Cancel" />
          </button>
          <button
            className={style.yesButton}
            onClick={onConfirm}
            data-test-delete-message-yes-button
          >
            {confirmText}
          </button>
        </div>
      </Modal>
    </WithOpenableLogic>
  );
};

export default observer(ConfirmModal);
