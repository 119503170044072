import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Cancel, Task } from 'taskarian';
import { callApi } from '../../../../../Appy';
import { findLinkT } from '../../../../../LinkyLinky';
import { Link } from '../../../../../Resource/Types';
import { TPlainTextKey } from '../../../../../Translations';
import { CommerceType, UseCase } from '../../../Common/Experience/Types';
import { loadAndValidateStripe } from '../../../Common/Stripe';
import EnrollmentStore from '../Store';
import { enrollmentResourceDecoder } from '../Store/Decoders';
import { paymentIntentResourceDecoder } from '../Store/Decoders/PaymentDecoder';
import { enrollmentEncoder, paidEnrollmentEncoder } from '../Store/Encoders';
import {
  CommerceLoadingError,
  Creating,
  InitializingPayment,
  LoadingError,
  PaymentIntentResource,
} from '../Store/Types';
import { handleCreatePaymentIntentError, handleEnrollError } from './ErrorHandling';

interface EnrollLink {
  enroll: Link;
}

const fetchPaymentIntentResource =
  (state: InitializingPayment) =>
  ({ enroll }: EnrollLink): Task<CommerceLoadingError, PaymentIntentResource> =>
    Task.succeed<CommerceLoadingError, ReadonlyArray<Link>>(state.enrollableResource.links)
      .andThen(findLinkT('create'))
      .andThen(
        callApi(
          paymentIntentResourceDecoder,
          paidEnrollmentEncoder(state.discoveryContextState, enroll),
        ),
      );

const setWaitingOrWaitingCommerce =
  (enrollmentStore: EnrollmentStore) => (commerceType: CommerceType) => {
    switch (commerceType) {
      case 'cc-only':
      case 'cc-or-direct-bill':
        enrollmentStore.waitingCommerce(commerceType);
        break;
      case 'not-available':
        enrollmentStore.waiting();
        break;
      default:
        assertNever(commerceType);
    }
  };

export const setWaitingState = (useCase: Maybe<UseCase>, enrollmentStore: EnrollmentStore) =>
  toTask<TPlainTextKey, UseCase>('Unable to load your experiences', useCase)
    .map((useCase) => useCase.commerceType)
    .fork(enrollmentStore.error, setWaitingOrWaitingCommerce(enrollmentStore));

export const createPaymentIntent = (state: InitializingPayment, store: EnrollmentStore): Cancel =>
  Task.succeed<CommerceLoadingError, {}>({})
    .assign('enroll', findLinkT('enroll', state.enrollableResource.links))
    .assign('paymentIntentResource', fetchPaymentIntentResource(state))
    .assign('stripe', loadAndValidateStripe)
    .map(({ paymentIntentResource, stripe }) => ({ paymentIntentResource, stripe }))
    .fork(
      handleCreatePaymentIntentError(store),
      store.readyForPayment(state.enrollableResource, state.discoveryContextState, state.action),
    );

export const enrollInExperience = (state: Creating, store: EnrollmentStore): Cancel =>
  Task.succeed<LoadingError, ReadonlyArray<Link>>(state.enrollableResource.links)
    .andThen(findLinkT('enroll'))
    .andThen(callApi(enrollmentResourceDecoder, enrollmentEncoder(state.discoveryContextState)))
    .fork(handleEnrollError(store, state.action), store.created);
