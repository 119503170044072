import { observer } from 'mobx-react';
import * as React from 'react';
import { LoginReactions } from '../../Login/Reactions';
import { LoginStore } from '../../Login/Store';
import LoginView from './LoginView';

class Login extends React.Component {
  store = new LoginStore();

  render() {
    return (
      <>
        <LoginView store={this.store} />
        <LoginReactions store={this.store} />
      </>
    );
  }
}

export default observer(Login);
