import * as React from 'react';
import { observer } from 'mobx-react';
import TeamMemberOnBody from '../TeamMemberList/TeamMemberOnBody';
import { PresenceMember } from '../../Socket/PresenceChannel/PresenceMembersStore/Types';

interface Props {
  presenceMember: PresenceMember;
}

const LoadMember: React.FC<Props> = ({ presenceMember }) =>
  presenceMember.member
    .map((teamMemberResource) => (
      <TeamMemberOnBody key={presenceMember.id} teamMemberResource={teamMemberResource} />
    ))
    .getOrElse(() => <></>);

export default observer(LoadMember);
