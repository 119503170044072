import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UpcomingProgram } from '../../../ProgramStore/Types';
import { L, T } from '../../../Translations';
import * as style from '../../ProgramSummary/style.module.css';

interface Props {
  programDetail: UpcomingProgram;
}

export const whenAccessibleOn = (programDetail: UpcomingProgram): Maybe<Date> => {
  switch (programDetail.accessDate.kind) {
    case 'accessible-anytime':
      return nothing();
    case 'accessible-on':
      return just(programDetail.accessDate.date);
  }
};

const Upcoming: React.FC<Props> = ({ programDetail }) =>
  whenAccessibleOn(programDetail)
    .map((accessDate) => (
      <span className={style.contentAccessUntil}>
        <p data-test-access-date={true}>
          <T
            kind="This program will be accessible on <date/>. Return then to take this course."
            date={<L localizeable={accessDate} format="long-month-day-year" />}
          />
        </p>
        <p data-test-link-to-dashboard={true}>
          <Link to="/" className={style.dashboardLink}>
            <T kind="Go to dashboard" />
          </Link>
        </p>
      </span>
    ))
    .getOrElseValue(<></>);

export default observer(Upcoming);
