import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import { ResizableProps, SegmentType } from './LectureSizingReactions';

export const resizeCurrentSlide = (
  currentPptSlideEl: React.RefObject<HTMLIFrameElement>,
  currentPptSlideSizerEl: React.RefObject<HTMLDivElement>,
  currentSlideProps: ResizableProps,
) => {
  switch (currentSlideProps.kind) {
    case 'ppt':
      if (currentPptSlideEl.current && currentPptSlideSizerEl.current) {
        currentPptSlideEl.current.style.height = `${Math.floor(
          currentPptSlideEl.current.getBoundingClientRect().width / currentSlideProps.aspectRatio,
        )}px`;
        currentPptSlideSizerEl.current.style.height = `${
          currentPptSlideEl.current.getBoundingClientRect().height - 10
        }px`;
      }
      break;
    case 'unknown':
    case 'vimeo':
    case 'legacy':
    case 'jw-player':
      break;
    default:
      assertNever(currentSlideProps);
  }
};

export const getCurrentSlideAspectRatio = (
  segmentType: SegmentType,
): Task<never, ResizableProps> => {
  switch (segmentType) {
    case 'legacy-lecture':
      return Task.succeed<never, ResizableProps>({
        kind: 'legacy',
        aspectRatio: 1,
      });
    case 'lecture':
      return Task.succeed<never, ResizableProps>({
        kind: 'ppt',
        aspectRatio: 16 / 9,
      });
  }
};
