import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import { TileViewProgramExperiencePreview } from '../../../../ProgramExperience';
import { useEnrollmentModalContext } from '../../../Common';

function GroupCoachingExperienceInfo() {
  const { invitationUuid, experienceResource } = useEnrollmentModalContext();

  return (
    <div className="mx-auto flex flex-col gap-y-10">
      <div className="mx-auto">
        <TileViewProgramExperiencePreview
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
        />
      </div>

      <Typography variant="bodyMedium">
        <T kind="ExecOnline will send you calendar invitations for the sessions..." />
      </Typography>
    </div>
  );
}

export default observer(GroupCoachingExperienceInfo);
