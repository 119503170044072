import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import { EventDetails } from '../../Freebusy/Types';
import Modal from '../../Modal';
import ModalStore from '../../Modal/Store';
import FreebusyProgramAdStore from './FreebusyProgramAdStore';
import MeetingDetails from './MeetingDetails';

interface Props {
  eventDetails: EventDetails;
  freebusyProgramAdStore: FreebusyProgramAdStore;
}

class FreebusySubmitted extends React.Component<Props, {}> {
  modalStore = new ModalStore();

  componentDidMount() {
    this.modalStore.open();
  }

  render() {
    switch (this.modalStore.state.kind) {
      case 'open':
        return (
          <Modal
            close={this.props.freebusyProgramAdStore.reload}
            title={<T kind="Your meeting was successfully scheduled" />}
          >
            <MeetingDetails eventDetails={this.props.eventDetails} />
          </Modal>
        );
      case 'closed':
        return <></>;
    }
  }
}

export default observer(FreebusySubmitted);
