import { observer } from 'mobx-react';
import * as React from 'react';
import { T, TPlainTextKey } from '../../../Translations';
import * as style from './style.module.css';
import * as platformStyle from '../../Platform/style.module.css';
import { fromNullable } from 'maybeasy';
import { fromBool } from '@execonline-inc/maybe-adapter';

interface Props {
  htmlFor?: string;
  className?: string;
  labelText: TPlainTextKey;
  required?: boolean;
}

const Label: React.FC<Props> = (props) => {
  return (
    <label
      className={props.className ? props.className : style.label}
      htmlFor={props.htmlFor}
      data-test-label={props.labelText}
    >
      <T kind={props.labelText} />
      {fromNullable(props.required)
        .andThen((v) => fromBool(v, v))
        .map(() => (
          <>
            <span className={style.required} aria-hidden={true}>
              *
            </span>
            <span className={platformStyle.screenReaderOnly}>
              <T kind={'Required'} />
            </span>
          </>
        ))
        .getOrElse(() => (
          <></>
        ))}
    </label>
  );
};

export default observer(Label);
