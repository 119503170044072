import * as React from 'react';
import EmptyResourcePage from '../../../EmptyResourcePage';

const EmptyState: React.FC = () => {
  return (
    <EmptyResourcePage
      title="You do not currently have any notes"
      description="During your program, you will have the ability ..."
    />
  );
};

export default EmptyState;
