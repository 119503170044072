import { observer } from 'mobx-react';
import React from 'react';
import { AlreadyTranslatedText, T } from '../../../../../Translations';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  whoShouldAttendHtml: AlreadyTranslatedText;
}

const WhoShouldAttend: React.FC<Props> = ({ whoShouldAttendHtml }) => (
  <div className="flex-1 mb-8">
    <div className="font-semibold text-lg uppercase tracking-widest">
      <T kind="Who Should Attend" />
    </div>
    <div className="leading-normal text-base font-light mt-4 mb-5">
      {ReactHtmlParser(whoShouldAttendHtml.text)}
    </div>
  </div>
);

export default observer(WhoShouldAttend);
