import * as React from 'react';
import SVGIcon from '../Cutesies/SVGIcon';

// tslint:disable:max-line-length
const path =
  'M23,0 L21.9475446,0.280991736 L3.13169643,5.50275482 L0,5.50275482 L0,11.4972452 L3.13169643,11.4972452 L4.74888393,11.9421488 L4.72321429,12.0123967 L4.72321429,12.0358127 C4.19698661,13.619318 5.17243304,15.3784439 6.98214286,15.8760331 C6.98856068,15.8789599 7.0013955,15.8731062 7.0078125,15.8760331 C8.75013971,16.3589874 10.6817799,15.4808882 11.2176339,13.815427 L11.2433036,13.7451791 L21.9475446,16.7190083 L23,17 L23,0 Z M21.3571429,2.0137741 L21.3571429,14.9862259 L3.51674107,10.0220386 L3.4140625,9.99862259 L1.64285714,9.99862259 L1.64285714,7.00137741 L3.4140625,7.00137741 L3.51674107,6.97796143 L21.3571429,2.0137741 Z M6.31473214,12.3870523 L9.67745536,13.3002755 L9.65178571,13.3705234 L9.65178571,13.3939394 C9.36941964,14.2691118 8.34905175,14.6993805 7.46986607,14.4476584 C6.51046296,14.1900826 6.01311425,13.2827139 6.2890625,12.4807163 L6.2890625,12.4573003 L6.31473214,12.3870523 Z';
// tslint:enable:max-line-length

const BroadcastIcon: React.StatelessComponent = () => {
  return (
    <SVGIcon viewBox="0 0 23 17">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d={path} fill="#FFFFFF" fillRule="nonzero" />
      </g>
    </SVGIcon>
  );
};

export default BroadcastIcon;
