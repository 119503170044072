import * as React from 'react';
import Store from './Store';

export interface SocketDetails {
  socketStore: Store;
}

export const socketDetails = (socketStore: Store) => ({
  socketStore,
});

const SocketContext = React.createContext<SocketDetails>({
  socketStore: new Store(),
});

export default SocketContext;
