import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { equals } from '@execonline-inc/collections';
import { BarsFilterIcon, Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { T } from '../../../../../../Translations';
import { FilterChipButton } from '../CommonFilter';
import { useMobileSidebarFilterOpenable } from '../hooks';

interface Props {
  value: Maybe<number>;
  children: ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => ReactNode;
  onClear: () => void;
}

function FiltersButton({ value, children, onClear }: Props) {
  const { mode, isOpen, onOpen, onClose } = useMobileSidebarFilterOpenable();

  return when(equals(mode, 'mobile'), true)
    .map(() => (
      <>
        <Button
          startContent={<BarsFilterIcon size="md" />}
          endContent={value
            .map((value) => <FilterChipButton value={value} onClear={onClear} />)
            .getOrElse(emptyFragment)}
          className="bg-white py-[9px]"
          size="sm"
          variant="outlined"
          color="gray"
          onClick={onOpen}
        >
          <T kind="Filters" />
        </Button>
        {children({ isOpen, onClose })}
      </>
    ))
    .getOrElse(emptyFragment);
}

export default observer(FiltersButton);
