import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../../Translations';
import { ExperienceResource } from '../../../../../../Experience/Types';

interface Props {
  experienceResource: ExperienceResource;
}

function NextAvailableTitle({ experienceResource }: Props) {
  switch (experienceResource.payload.registrationInvitationKind) {
    case 'direct-enrollment':
      return emptyFragment();
    case 'open-enrollment':
    case 'new-program-family-shared-open-enrollment':
    case 'preview-only':
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
      return (
        <Typography variant="bodyMedium" as="span">
          <T kind="Next Available" />
        </Typography>
      );
  }
}

export default observer(NextAvailableTitle);
