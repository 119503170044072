import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import LearningPartnersStore from '../../../../LearningPartnersStore';
import LearningPartnersReactions from '../../../../LearningPartnersStore/LearningPartnersReactions';
import { LearningPartnersResource } from '../../../../LearningPartnersStore/Types';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import LearningPartnerBody from '../LearningPartnersPanel/LearningPartnerBody';
import LearningPartnersHeader from './LearningPartnersHeader';

interface Props {
  programDetailResource: ProgramDetailResource;
}

@observer
class LearningPartnersPanel extends React.Component<Props, {}> {
  private learningPartnersStore: LearningPartnersStore;

  constructor(props: Props) {
    super(props);
    this.learningPartnersStore = new LearningPartnersStore();
  }

  componentDidMount() {
    this.learningPartnersStore.loading(this.props.programDetailResource);
  }

  render() {
    return (
      <>
        {this.learningPartnersStore.learningPartners
          .assign<'resource', LearningPartnersResource>('resource', just)
          .assign('learningPartners', ({ resource }) => fromArrayMaybe(resource.payload))
          .map(({ learningPartners, resource }) => (
            <>
              <LearningPartnersHeader learningPartners={learningPartners} />
              <LearningPartnerBody learningPartnersResource={resource} />
            </>
          ))
          .getOrElseValue(<span />)}
        <LearningPartnersReactions
          store={this.learningPartnersStore}
          resourceWithLearningPartners={this.props.programDetailResource}
        />
      </>
    );
  }
}

export default LearningPartnersPanel;
