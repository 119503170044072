import { ConferenceParticipant } from '../ConferenceRosterStore/Types';
import { LearningPartnerResource } from '../LearningPartnersStore/Types';
import { findLink } from '../LinkyLinky';
import { PersonStore } from '../Person';
import {
  conferenceParticipantPerson,
  learningPartnerPerson,
  Person,
  PersonState,
} from '../Person/types';
import { IdentifiablePayload, Resource } from '../Resource/Types';

export const createStore = (personState: PersonState) => new PersonStore(personState);

export const conferenceParticipantStore = (participant: ConferenceParticipant): PersonStore =>
  createStore({
    photo: findLink('avatar', participant.profile.links),
    person: conferenceParticipantPerson(participant),
    id: participant.id,
  });

export const personStoreFromResource = <TPayload extends IdentifiablePayload>(
  resource: Resource<TPayload>,
  ctor: (payload: TPayload) => Person
): PersonStore =>
  createStore({
    photo: findLink('avatar', resource.links),
    person: ctor(resource.payload),
    id: resource.payload.id,
  });

export const learningPartnerStore = (lp: LearningPartnerResource): PersonStore =>
  personStoreFromResource(lp, learningPartnerPerson);
