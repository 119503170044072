import { log, warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import ConferenceRosterStore from '.';
import { AppyError, callApi } from '../Appy';
import { ConferenceRoomResource, ConferenceableResource } from '../Conference/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import {
  conferencePresentParticipantsResourceDecoder,
  conferenceRosterResourceDecoder,
} from './Decoders';
import {
  ConferencePresentParticipantsResource,
  ConferenceRosterResource,
  ConferenceRosterState,
} from './Types';

export interface Props extends EAProps<ConferenceRosterStore> {
  store: ConferenceRosterStore;
  conferenceableResource: ConferenceableResource;
}

const conferenceRosterEndPoint = callApi(conferenceRosterResourceDecoder, {});

const conferencePresentParticipantsEndPoint = callApi(
  conferencePresentParticipantsResourceDecoder,
  {},
);

type ConferenceRosterReactionsError = MissingLinkError | AppyError;

const handlePresentParticipantsRequestSuccess =
  (store: ConferenceRosterStore) =>
  (conferencePresentParticipantsResource: ConferencePresentParticipantsResource) => {
    store.pollingComplete(conferencePresentParticipantsResource.payload);
  };

const handleRosterRequestError =
  (store: ConferenceRosterStore) => (error: ConferenceRosterReactionsError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Conference roster is not available');
        break;
      default:
        handleError(store, error);
    }
  };

const handlePresentParticipantsRequestError =
  (store: ConferenceRosterStore) => (error: ConferenceRosterReactionsError) => {
    warn(
      'PresentParticipantsRequestError',
      `[EMBED] Unable to poll for present participants: ${error.kind}`,
      error,
    );
    store.ready();
  };

class ConferenceRosterReactions extends ErrorActionableReaction<
  ConferenceRosterStore,
  ConferenceRosterState,
  Props
> {
  tester = () => this.props.store.conferenceRosterState;

  effect = (state: ConferenceRosterState) => {
    log('[EMBED] Roster state ' + state.kind);

    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'stopped':
      case 'error':
        break;
      case 'loading':
        log('[EMBED] Participant loading');
        Task.succeed<ConferenceRosterReactionsError, ReadonlyArray<Link>>(
          this.props.conferenceableResource.links,
        )
          .andThen(findLinkT('roster'))
          .andThen(conferenceRosterEndPoint)
          .fork(
            handleRosterRequestError(this.props.store),
            (conferenceRosterResource: ConferenceRosterResource) =>
              this.props.store.rosterLoaded(
                conferenceRosterResource.payload.kind,
                conferenceRosterResource.payload.participants,
              ),
          );
        break;
      case 'roster-loaded':
      case 'polling':
        log('[EMBED] Polling for present participants');
        this.props.conferenceableResource.payload.conferenceRoom.map(
          (conferenceRoomResource: ConferenceRoomResource) => {
            Task.succeed<ConferenceRosterReactionsError, ReadonlyArray<Link>>(
              conferenceRoomResource.links,
            )
              .andThen(findLinkT('present-participants'))
              .andThen(conferencePresentParticipantsEndPoint)
              .fork(
                handlePresentParticipantsRequestError(this.props.store),
                handlePresentParticipantsRequestSuccess(this.props.store),
              );
          },
        );
        break;
      case 'updating':
      case 'polling-complete':
        this.props.store.ready();
        break;
      default:
        assertNever(state);
    }
  };
}

export default ConferenceRosterReactions;
