import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import * as style from '../style.module.css';
import Name from './Name';
import Organization from './Organization';
import Photo from './Photo';
import Social from './Social';
import WorkPlace from './WorkPlace';

interface Props {
  store: PersonStoreContract;
}

const Profile: React.FC<Props> = ({ store }) => (
  <div className={style.profile}>
    <div className={style.container}>
      <div className={style.left}>
        <Photo store={store} />
        <Social store={store} />
      </div>
      <div className={style.right}>
        <Name name={store.name} />
        <Organization organization={store.organization} />
        <WorkPlace store={store} />
      </div>
    </div>
  </div>
);
export default observer(Profile);
