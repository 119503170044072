import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Experience/Types';
import ExperienceProgramDetails from './ExperienceProgramDetails';
import { LeadershipCoachingOverview, LearningJourneyOverview } from './ExperienceOverview';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceBodyContent({ experienceResource }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return <ExperienceProgramDetails experienceResource={experienceResource} />;
    case 'coaching':
      return <LeadershipCoachingOverview experience={experienceResource.payload} />;
    case 'program-sequence':
      return <LearningJourneyOverview experienceResource={experienceResource} />;
    case 'group-coaching':
      return emptyFragment();
  }
}

export default observer(ExperienceBodyContent);
