import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const PlayIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 9 11">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M0 0v11l.777-.507L8.543 5.5.777.507z"
      />
    </SVGIcon>
  );
};

export default observer(PlayIcon);
