interface Waiting {
  kind: 'waiting';
}

interface Active {
  kind: 'active';
}

interface Expiring {
  kind: 'expiring';
}

interface Expired {
  kind: 'expired';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const active = (): Active => ({
  kind: 'active',
});

export const expired = (): Expired => ({
  kind: 'expired',
});

export const expiring = (): Expiring => ({
  kind: 'expiring',
});

export type State = Waiting | Active | Expiring | Expired;
