import * as React from 'react';
import CustomerCharacteristics from './CustomerCharacteristics';
import ProductDescription from './ProductDescription';
import ProductAttributes from './ProductAttributes';
import Submission from './Submission';
import * as style from './style.module.css';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import ConjointAnalysisStoreReactions from '../../ConjointAnalysisStore/ConjointAnalysisStoreReactions';
import { ConjointAnalysisNode } from './Types';
import ConjointAnalysisErrorMessage from './ConjointAnalysisErrorMessage';
import SegmentStore from '../../SegmentStore';
import { Maybe } from 'maybeasy';

interface Props {
  store: ConjointAnalysisStore;
  node: ConjointAnalysisNode;
  segmentStore: Maybe<SegmentStore>;
}

class ConjointAnalysis extends React.Component<Props> {
  componentDidMount() {
    this.props.store.ready();
  }
  render() {
    return (
      <form className={style.conjointForm}>
        <ConjointAnalysisErrorMessage store={this.props.store} />
        <CustomerCharacteristics store={this.props.store} />
        <ProductDescription store={this.props.store} />
        <ProductAttributes store={this.props.store} />
        <Submission store={this.props.store} />
        <ConjointAnalysisStoreReactions
          segmentStore={this.props.segmentStore}
          node={this.props.node}
          store={this.props.store}
        />
      </form>
    );
  }
}

export default ConjointAnalysis;
