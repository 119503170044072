import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import NextExperiencesDetails from './NextExperiencesDetails';
import { NextExperiencesNode } from './Types';

interface Props {
  node: NextExperiencesNode;
}

const NextExperiences: React.FC<Props> = ({ node }) => {
  return (
    <>
      {fromEmpty(node.nextExperiences.programs).cata({
        Just: () => <NextExperiencesDetails node={node} />,
        Nothing: () => <></>,
      })}
    </>
  );
};

export default observer(NextExperiences);
