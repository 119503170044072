import { observer } from 'mobx-react';
import { Chip, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';

function ProgramEnrolled() {
  return (
    <Chip className="self-start" color="gray" variant="outlined">
      <Typography
        color="inherit"
        className="tracking-[0.2px]"
        variant="caption"
        fontWeight="medium"
        as="span"
      >
        <T kind="Enrolled" />
      </Typography>
    </Chip>
  );
}

export default observer(ProgramEnrolled);
