import { observer } from 'mobx-react';
import * as React from 'react';
import { getBackgroundImage } from '../../Native/AEP/DiscoveryPortal/ExperienceSelection';
import NotFoundContent from './NotFoundContent';

const NotFound: React.FC<{}> = () => (
  <div style={getBackgroundImage()} className="bg-chevrons bg-[length:100%] bg-repeat-y">
    <NotFoundContent />
  </div>
);

export default observer(NotFound);
