import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import TeamsStore from '../../../../TeamsStore';
import { TeamsResource } from '../../../../TeamsStore/Types';
import TeamBody from './TeamBody';
import TeamHeader from './TeamHeader';
import RenderContext from '../../../RenderContext';
import * as style from '../../style.module.css';

interface Props {
  store: TeamsStore;
}

const Teams: React.FC<Props> = ({ store }) =>
  store.teams
    .assign<'resource', TeamsResource>('resource', just)
    .assign('teams', ({ resource }) => fromArrayMaybe(resource.payload))
    .map(({ teams, resource }) => (
      <dl className={style.dl}>
        <RenderContext
          render={renderContext => <TeamHeader teams={teams} position={renderContext.position} />}
        />
        <TeamBody teamsResource={resource} />
      </dl>
    ))
    .getOrElseValue(<div />);

export default observer(Teams);
