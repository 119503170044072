import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import ProgramIcon from '../../Cutesies/ProgramIcon';
import ModalStore from '../../Modal/Store';
import * as style from '../style.module.css';
import DetailsModal from './DetailsModal';

interface Props {
  title: string;
  detail: Maybe<string>;
}

const ConferenceDetail: React.FC<Props> = ({ title, detail }) => {
  const modalStore: ModalStore = new ModalStore();

  return detail
    .map((detail) => {
      return (
        <dl className={style.dl}>
          <dt className={style.detailIcon}>
            <ProgramIcon />
          </dt>
          <dd className={style.detailValue} data-test-conference-detail={true}>
            <a
              className={style.hideOverflow}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                modalStore.open();
              }}
              data-test-show-details-link={true}
            >
              <NotTranslated text={detail} />
            </a>
            <DetailsModal store={modalStore} title={title} details={detail} />
          </dd>
          <div />
        </dl>
      );
    })
    .getOrElseValue(<></>);
};

export default observer(ConferenceDetail);
