import { observer } from 'mobx-react';
import * as React from 'react';
import CenteredLogo from '../../components/CenteredLogo';
import WithBackgroundImage from '../../components/WithBackgroundImage';
import { ExitStore } from '../AEP/Common/Experience/Actions';
import Exit from '../Exit';
import { UseCaseErrorMessage } from './Types';
import UseCaseMessaging from './UseCaseMessaging';

interface Props {
  message: UseCaseErrorMessage;
}

class ErrorMessage extends React.Component<Props> {
  exitStore = new ExitStore();

  render() {
    switch (this.exitStore.state.kind) {
      case 'ready':
        return (
          <>
            <WithBackgroundImage>
              <CenteredLogo />
              <UseCaseMessaging message={this.props.message} />
            </WithBackgroundImage>
          </>
        );
      case 'exiting':
        return (
          <>
            <UseCaseMessaging message={this.props.message} />
            <Exit exitContext={this.exitStore.state.exitContext} />
          </>
        );
    }
  }
}

export default observer(ErrorMessage);
