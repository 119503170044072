import * as React from 'react';
import { observer } from 'mobx-react';
import CommunitiesStore from '../../CommunitiesStore';
import ContentFaker from '../ContentFaker';
import EmptyState from './EmptyState';
import CommunitiesGroup from './CommunitiesGroup';

interface Props {
  communitiesStore: CommunitiesStore;
}

const CommunitiesImpl: React.FC<Props> = ({ communitiesStore }) => {
  switch (communitiesStore.state.kind) {
    case 'ready':
    case 'loading-more':
      return <CommunitiesGroup communitiesStore={communitiesStore} />;
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
    case 'error':
      return <EmptyState />;
  }
};

export default observer(CommunitiesImpl);
