import { find, findIndex } from '@execonline-inc/collections';
import { noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { action, observable } from 'mobx';
import { ConversationResource } from '../../../Conference/Types';
import ConversationStore from '../../../ConversationStore';
import {
  chatMessageReplyResourceDecoder,
  chatMessageResourceDecoder,
} from '../../../ConversationStore/Decoders';
import {
  ChatMessageReplyResource,
  ChatMessageResource,
  ChatablePersonResourceList,
} from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ProgramMembersStore from '../../../ProgramMembersStore';
import EventPollStore from '../../../components/Conference/EventPoll/EventPollStore';
import { EventPoll } from '../../../components/Conference/EventPoll/EventPollStore/Types';
import { PresenceMember, uniqMembers } from './Types';

class PresenceMembersStore {
  members = observable.array<PresenceMember>([]);

  conversationStore: ConversationStore;
  conversationResource: ConversationResource;
  eventPollStore: EventPollStore;
  programMembersStore: ProgramMembersStore;

  constructor(
    conversationResource: ConversationResource,
    chatablePersonResourceList: Maybe<ChatablePersonResourceList>,
    currentUserResource: CurrentUserResource
  ) {
    this.conversationResource = conversationResource;
    this.conversationStore = new ConversationStore(
      conversationResource,
      chatablePersonResourceList,
      currentUserResource
    );
    this.eventPollStore = new EventPollStore();
    this.programMembersStore = new ProgramMembersStore();
  }

  @action
  setMembers = (members: PresenceMember[]) => {
    this.members.replace(uniqMembers(members));
    this.programMembersStore.updateMembers();
  };

  @action
  addMember = (member: PresenceMember) => {
    find((p) => p.id === member.id, this.members).cata({
      Just: noop,
      Nothing: () => this.members.push(member),
    });
    this.programMembersStore.updateMembers();
  };

  @action
  removeMember = (memberId: number) => {
    findIndex((m) => m.id === memberId, this.members).do((idx) => this.members.splice(idx, 1));
    this.programMembersStore.updateMembers();
  };

  @action
  newMessage = (message: ChatMessageResource | ChatMessageReplyResource) => {
    switch (message.payload.kind) {
      case 'chat-message':
        chatMessageResourceDecoder.decodeAny(message).map(this.conversationStore.newMessage);
        break;
      case 'chat-message-reply':
        chatMessageReplyResourceDecoder.decodeAny(message).map(this.conversationStore.newReply);
        break;
    }
  };

  @action
  updatedMessage = (message: ChatMessageResource | ChatMessageReplyResource) => {
    switch (message.payload.kind) {
      case 'chat-message':
        chatMessageResourceDecoder.decodeAny(message).map(this.conversationStore.updateMessage);
        break;
      case 'chat-message-reply':
        chatMessageReplyResourceDecoder.decodeAny(message).map(this.conversationStore.updateReply);
        break;
    }
  };

  @action
  deletedMessage = (id: number) => {
    this.conversationStore.deleteMessage(id);
  };

  @action
  launchPoll = (poll: EventPoll) => {
    this.eventPollStore.launchPoll(poll);
  };

  @action
  closePoll = () => {
    this.eventPollStore.ready();
  };
}

export default PresenceMembersStore;
