import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../../../../Translations';
import { Typography, cn } from '@execonline-inc/execonline-ui';

interface Props {
  text?: TPlainTextKey;
  className?: string;
}

function Loading({ text, className }: Props) {
  return (
    <div
      className={cn('container relative mx-auto items-center justify-center bg-white', className)}
    >
      <Typography color="black" className="mb-4.5 text-center" variant="bodyMedium">
        <T kind={fromNullable<TPlainTextKey>(text).getOrElseValue('Loading...')} />
      </Typography>
    </div>
  );
}

export default observer(Loading);
