import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import TileViewProgramExperiencePreview from '../../../../ProgramExperience/TileViewProgramExperiencePreview';
import { useEnrollmentModalContext } from '../../../Common';
import PaymentOrderSummary from './PaymentOrderSummary';

function PaymentOrderDetails() {
  const { invitationUuid, experienceResource } = useEnrollmentModalContext();

  return (
    <div className="flex flex-col rounded-md bg-[#F7F7F7] md:basis-2/5 md:flex-row">
      <div className="px-6 py-12 md:py-12 md:pl-12 md:pr-8">
        <Typography
          className="p-0 tracking-normal"
          variant="subtitleLarge"
          fontWeight="bold"
          as="h2"
        >
          <T kind="Order Details" />
        </Typography>
        <div className="mt-7 flex justify-center">
          <TileViewProgramExperiencePreview
            invitationUuid={invitationUuid}
            experienceResource={experienceResource}
          />
        </div>
        <PaymentOrderSummary experienceResource={experienceResource} />
      </div>
      <div className="border-b border-r-[#F4F4F4] md:border-r" />
    </div>
  );
}

export default observer(PaymentOrderDetails);
