import React, { CSSProperties } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import ThemeContext, { LightnessTheme } from '../../../Themes/ThemeContext';
import {
  ButtonColor,
  ButtonProps,
  ButtonVariant,
  buttonSizeClassNameMapping,
  buttonVariantClassNameMapping,
} from './Types';

const getButtonStyle = (
  theme: LightnessTheme,
  color: ButtonColor,
  variant: ButtonVariant,
): CSSProperties => {
  const borderColor = theme.colors[`button-${color}-${variant}-color`];
  const textColor = theme.colors[`button-${color}-${variant}-text-color`];

  switch (variant) {
    case 'outlined':
      return { borderColor: borderColor, color: textColor };
    case 'solid':
      return { borderColor: borderColor, backgroundColor: borderColor, color: textColor };
    case 'text':
      return { color: textColor };
  }
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      style,
      color = 'primary',
      variant = 'solid',
      shape = 'rounded-sm',
      size = 'md',
      type = 'button',
      children,
      ...others
    },
    ref,
  ) => {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <button
            className={twMerge(
              clsx(
                'select-none appearance-none bg-transparent inline-flex items-center justify-center',
                'active:opacity-50 disabled:opacity-50',
                'disabled:cursor-default disabled:pointer-events-none',
                shape,
                buttonVariantClassNameMapping[variant],
                buttonSizeClassNameMapping[size],
                className,
              ),
            )}
            style={{ ...getButtonStyle(theme, color, variant), ...style }}
            type={type}
            {...others}
            ref={ref}
          >
            {children}
          </button>
        )}
      </ThemeContext.Consumer>
    );
  },
);

export default observer(Button);
