import { cn, Modal, ModalBody, useOpenable } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { VideoSourceFile } from '../../../../components/JWPlayer/Types';
import { OverviewVideoResource } from '../../../../components/ReqHlsVideo/Types';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceResource } from '../Experience/Types';
import ExperienceVideoDetailsModalBody from './ExperienceVideoDetailsModalBody';
import ExperienceVideoPreviewModalBody from './ExperienceVideoPreviewModalBody';
import ExperienceVideoThumbnail from './ExperienceVideoThumbnail';

interface Props {
  className?: string;
  experienceResource: ExperienceResource;
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  video: OverviewVideoResource;
  thumbnail: string;
  sources: VideoSourceFile[];
}

function ExperienceVideoDetails({
  className,
  experienceResource,
  invitationUuid,
  experiencesStore,
  thumbnail,
  sources,
  video,
}: Props) {
  const { isOpen, onOpen, onClose } = useOpenable();

  return (
    <>
      <ExperienceVideoThumbnail
        className={className}
        src={thumbnail}
        alt={experienceResource.payload.title.text}
        onClick={onOpen}
      />
      <Modal
        className="h-full rounded-none sm:h-150 sm:rounded"
        classNames={{
          closeButton: 'z-1 text-white left-5 right-auto sm:left-auto sm:right-5 sm:text-gray-600',
        }}
        open={isOpen}
        size="5xl"
        onClose={onClose}
      >
        <ModalBody
          className={cn(
            'w-full px-0 pb-0 pt-14 sm:px-0 sm:pb-0 sm:pt-14',
            'grid grid-rows-[1fr_2fr] sm:grid-cols-[auto_19.875rem] sm:grid-rows-none',
          )}
        >
          <div className="-mt-14 flex-grow">
            <ExperienceVideoPreviewModalBody sources={sources} video={video} />
          </div>
          <div className="relative sm:w-[19.875rem]">
            <ExperienceVideoDetailsModalBody
              invitationUuid={invitationUuid}
              experienceResource={experienceResource}
              experiencesStore={experiencesStore}
              onClose={onClose}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default observer(ExperienceVideoDetails);
