import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceParticipants } from '../../../ConferenceRosterStore/Types';
import ProgramParticipants from './ProgramParticipants';
import SupportStaff from './SupportStaff';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const Arrived: React.FC<Props> = ({ conferenceRosterStore }) => (
  <>
    {conferenceRosterStore.presentParticipantsSorted
      .map((participants: ConferenceParticipants) => (
        <div data-test-participants-arrived={true}>
          <SupportStaff participants={participants} />
          <ProgramParticipants
            conferenceRosterStore={conferenceRosterStore}
            participants={participants}
          />
        </div>
      ))
      .getOrElse(() => (
        <></>
      ))}
  </>
);

export default observer(Arrived);
