import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ChatReactionIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 17 16">
      <path
        fill={defaultTheme.variables['icon-standard-on-light']}
        d="M7.129 1.677c.462 0 .917.047 1.355.13-.262.337-.46.717-.594 1.114a5.875 5.875 0 0 0-.761-.055 5.946 5.946 0 0 0-5.94 5.94 5.946 5.946 0 0 0 5.94 5.941 5.946 5.946 0 0 0 5.94-5.94h1.19a7.137 7.137 0 0 1-7.13 7.128A7.137 7.137 0 0 1 0 8.806a7.137 7.137 0 0 1 7.129-7.129Zm2.15 4.753a.891.891 0 1 1-.001 1.783.891.891 0 0 1 0-1.783Zm-4.194 0a.891.891 0 1 1 0 1.783.891.891 0 0 1 0-1.783Zm5.014 3.215.899.605c-.723 1.445-2.068 2.421-3.612 2.421s-2.889-.976-3.612-2.42l.899-.606c.543 1.085 1.549 1.816 2.713 1.816 1.164 0 2.17-.73 2.713-1.816ZM14.055 0v2.72h2.72v1.27h-2.72v2.72h-1.271V3.99h-2.72V2.72h2.72V0h1.27Z"
      />
    </SVGIcon>
  );
};

export default observer(ChatReactionIcon);
