import * as React from 'react';
import TeamsStore from '../../../../TeamsStore';
import { observer } from 'mobx-react';
import UnreadMessageNotification from './UnreadMessageNotification';

interface Props {
  teamsStore: TeamsStore;
}

class UnreadMessageNotifications extends React.Component<Props> {
  componentDidMount() {
    if (this.props.teamsStore.state.kind === 'ready') {
      this.props.teamsStore.loading();
    }
  }

  render() {
    const { teamsStore } = this.props;
    return (
      <>
        {teamsStore.unreadTeamMessageNotifications.map(notification => (
          <UnreadMessageNotification key={notification.teamLink} notification={notification} />
        ))}
      </>
    );
  }
}

export default observer(UnreadMessageNotifications);
