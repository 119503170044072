import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { EnrolledIcon } from './Icons';
import ExperienceAlert from './ExperienceAlert';

function BeginnableAlert() {
  return (
    <ExperienceAlert icon={<EnrolledIcon />}>
      <Typography variant="caption">
        <T kind="You are already enrolled." />
      </Typography>
    </ExperienceAlert>
  );
}

export default observer(BeginnableAlert);
