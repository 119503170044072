import { map } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import ProgramDashboard from '.';
import { findLink } from '../../../LinkyLinky';
import { ProgramResource } from '../../../ProgramsStore/Types';
import ProgramNotReady from '../ProgramNotReady';

interface Props {
  programs: NonEmptyList<ProgramResource>;
}

const dashboard = (programResource: ProgramResource): React.ReactNode => {
  const { payload } = programResource;
  return findLink('self', programResource.links)
    .map(() => <ProgramDashboard key={payload.id} programResource={programResource} />)
    .getOrElse(() => <ProgramNotReady key={payload.id} programResource={programResource} />);
};

const renderDashboards = map(dashboard);

const ProgramDashboards: React.FC<Props> = ({ programs }) => {
  return <>{renderDashboards(programs.toArray())}</>;
};

export default observer(ProgramDashboards);
