import { Kettle } from 'kettle-corn';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Video as TVideoResource } from '../../../../ResourceStore/Types';
import { L, NotTranslated, T } from '../../../../Translations';
import Video from '../../../Video';
import * as style from '../../style.module.css';

interface Props {
  resource: TVideoResource;
}

const VideoResource: React.FC<Props> = ({ resource }) => {
  return (
    <>
      <h1>
        <NotTranslated text={resource.title} />
      </h1>
      <div className={style.date}>
        <T
          kind="This video was added on <date/>"
          date={
            <span className={style.timestamp}>
              <L localizeable={resource.createdAt} format="short-month-day-year" />
            </span>
          }
        />
      </div>
      <div className={style.video}>
        <Video
          id={resource.videoAssetResource.payload.uuid}
          className="embedded-video"
          videoResource={resource.videoAssetResource}
          kettle={new Kettle()}
          width={640}
          height={320}
          dynamicallySetAspectRatio={false}
        >
          {{ playButton: nothing() }}
        </Video>
      </div>
    </>
  );
};

export default observer(VideoResource);
