import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fillColor: string;
}

const FlagIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SVGIcon viewBox="0 0 15 16">
      <path
        fill={fillColor}
        d="M0 0v15.273h1.282v-5.091h12.822V0H0Zm1.282 1.273h1.923v1.909H5.13v-1.91h1.923v1.91h1.923v-1.91h1.923v1.91h1.923V5.09h-1.923V7h1.923v1.91h-1.923V7H8.975v1.91H7.052V7H5.129v1.91H3.205V7H1.282V5.09h1.923V3.183H1.282v-1.91ZM3.205 5.09V7H5.13V5.09H3.205Zm1.924 0h1.923V3.18H5.129v1.91Zm1.923 0V7h1.923V5.09H7.052Zm1.923 0h1.923V3.18H8.975v1.91Z"
      />
    </SVGIcon>
  );
};

export default observer(FlagIcon);
