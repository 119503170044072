import { observer } from 'mobx-react';
import { Chip, CloseIcon } from '@execonline-inc/execonline-ui';

interface Props {
  value: number;
  onClear: () => void;
}

function FilterChipButton({ value, onClear }: Props) {
  function handleClear(e: React.KeyboardEvent | React.MouseEvent) {
    e.stopPropagation();
    onClear();
  }

  return (
    <Chip radius="full" size="sm" color="blue" className="gap-x-1.5 px-1.5 py-0.5">
      <span className="font-normal">{value}</span>
      <span className="flex text-xs" onClick={handleClear}>
        <CloseIcon size="xs" color="inherit" />
      </span>
    </Chip>
  );
}

export default observer(FilterChipButton);
