import { readVarM } from '@execonline-inc/environment';
import { useMessageBusHost } from '@execonline-inc/message-bus.private';

export function Host() {
  useMessageBusHost({
    adminstudio: readVarM('REACT_APP_ADMINSTUDIO_APP_URL'),
    enterprise: readVarM('REACT_APP_ENTERPRISE_APP_URL'),
    learning: readVarM('REACT_APP_LEARNING_APP_URL'),
  });
  return <></>;
}
