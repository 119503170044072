import { observer } from 'mobx-react';
import { EventResource } from '../../EventsStore/Types';
import { T } from '../../Translations';
import Event from '../Event';
import AsModal from '../Modal/AsModal';
import { CloseAction, OpenableState } from '../Openable';
import * as style from './style.module.css';

interface Props {
  modalState: OpenableState;
  close: CloseAction;
  event: EventResource;
}

function EventDetails({ modalState, close, event }: Props) {
  return (
    <AsModal state={modalState} close={close} title={<T kind="Event Details" />}>
      <div className={style.event}>
        <Event eventResource={event} />
      </div>
    </AsModal>
  );
}

export default observer(EventDetails);
