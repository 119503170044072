import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption } from '../../../Types';
import PersonaTrait from './PersonaTrait';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
  traits: AlreadyTranslatedText[];
}

const PersonaTraits = ({ option, traits }: Props) => (
  <div className="flex max-w-72 flex-wrap gap-3">
    {traits.map((trait) => (
      <PersonaTrait key={trait.text} option={option} trait={trait} />
    ))}
  </div>
);

export default observer(PersonaTraits);
