import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import { TeamMemberResource } from '../../../TeamsSummaryStore/Types';
import { NotTranslated } from '../../../Translations';
import TeamMember from './TeamMember';
import * as style from './style.module.css';

interface Props {
  teamMemberResource: TeamMemberResource;
}

const TeamMemberDetail: React.FC<Props> = ({ teamMemberResource }) => {
  return (
    <>
      <Route
        exact={true}
        path="/"
        render={() => {
          return <TeamMember teamMemberResource={teamMemberResource} globalPresence={nothing()} />;
        }}
      />
      <Route
        exact={true}
        path="/communities"
        render={() => {
          return (
            <div className={style.memberFull}>
              <TeamMember teamMemberResource={teamMemberResource} globalPresence={nothing()} />
              <div className={style.memberDetails}>
                {teamMemberResource.payload.profile.payload.name
                  .map((name) => (
                    <span className={style.titleOnLight}>
                      <NotTranslated text={name} />
                    </span>
                  ))
                  .getOrElseValue(<span />)}
                {teamMemberResource.payload.profile.payload.position
                  .map((position) => (
                    <p className={style.subtitleOnLight}>
                      <NotTranslated text={position} />
                    </p>
                  ))
                  .getOrElseValue(<span />)}
              </div>
            </div>
          );
        }}
      />
      <Route
        exact={true}
        path="/teams/:teamId"
        render={() => {
          return (
            <div className={style.memberFull}>
              <TeamMember teamMemberResource={teamMemberResource} globalPresence={nothing()} />
              <div className={style.memberDetails}>
                {teamMemberResource.payload.profile.payload.name
                  .map((name) => (
                    <span className={style.titleOnBody}>
                      <NotTranslated text={name} />
                    </span>
                  ))
                  .getOrElseValue(<span />)}
                {teamMemberResource.payload.profile.payload.organization
                  .map((organization) => (
                    <span className={style.subtitleOnBody}>
                      <NotTranslated text={organization} />
                    </span>
                  ))
                  .getOrElseValue(<span />)}
              </div>
            </div>
          );
        }}
      />
      <Route
        exact={true}
        path="/team"
        render={() => {
          return (
            <div className={style.memberFull}>
              <TeamMember teamMemberResource={teamMemberResource} globalPresence={nothing()} />
              <div className={style.memberDetails}>
                {teamMemberResource.payload.profile.payload.name
                  .map((name) => (
                    <span className={style.titleOnBody}>
                      <NotTranslated text={name} />
                    </span>
                  ))
                  .getOrElseValue(<span />)}
                {teamMemberResource.payload.profile.payload.organization
                  .map((organization) => (
                    <span className={style.subtitleOnBody}>
                      <NotTranslated text={organization} />
                    </span>
                  ))
                  .getOrElseValue(<span />)}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default observer(TeamMemberDetail);
