import * as React from 'react';
import { observer } from 'mobx-react';
import ProgramMembersStore from '../../ProgramMembersStore';
import LoadMoreParticipantsButton from './LoadMoreParticipantsButton';

interface Props {
  programMembersStore: ProgramMembersStore;
}

const LoadMoreButtonImpl: React.FC<Props> = ({ programMembersStore }) => {
  switch (programMembersStore.state.kind) {
    case 'ready':
    case 'loading-more':
      return <LoadMoreParticipantsButton programMembersStore={programMembersStore} />;
    case 'all-members-loaded':
    case 'update-members':
    case 'error':
      return <></>;
  }
};

export default observer(LoadMoreButtonImpl);
