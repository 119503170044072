import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { targetedInvitationRoute } from '../../../ClientRoutes';
import WhenUserDataLoaded from '../../../CurrentUser/Context/WhenUserDataLoaded';
import { currentUserStore } from '../../../CurrentUser/Store';
import { discoveryStore } from '../../../DiscoveryStore';
import Exit from '../../Exit';
import PageLayoutWithAuthenticationWrapper from '../../PageLayoutWithAuthenticationWrapper';
import { exitToDashboard } from '../Common/Experience/Actions/Exitable/Types';

const Explore: React.FC = () => {
  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
      return (
        <PageLayoutWithAuthenticationWrapper>
          <WhenUserDataLoaded
            currentUserResource={currentUserStore.state.currentUserResource}
            contingencies={[discoveryStore]}
            children={discoveryStore.resource
              .andThen(({ payload }) => payload.learningCollections)
              .map((list) => list.first.payload)
              .cata({
                Just: ({ uuid }) => <Redirect to={targetedInvitationRoute(uuid)} />,
                Nothing: () => <Exit exitContext={exitToDashboard()} />,
              })}
          />
        </PageLayoutWithAuthenticationWrapper>
      );
    case 'logging-out':
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      return <PageLayoutWithAuthenticationWrapper />;
  }
};

export default observer(Explore);
