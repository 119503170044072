import * as React from 'react';
import { observer } from 'mobx-react';
import Label from '../Label';
import { StringFormField } from '../../Types';
import SegmentStore from '../../../../SegmentStore';
import StringFieldStore from './Store';
import AutoSaveReactions from '../../Reactions/AutoSaveReactions';
import StringFieldReactions from './Reactions';
import * as fieldStyle from '../../FormField/style.module.css';
import * as style from './style.module.css';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: StringFormField;
  uuid: string;
}

class StringField extends React.Component<Props> {
  stringFieldStore: StringFieldStore = new StringFieldStore(this.props.uuid);

  componentDidMount() {
    this.stringFieldStore.initialize(this.props.segmentStore, this.props.fieldDescription);
  }

  render() {
    const { fieldDescription } = this.props;
    switch (this.stringFieldStore.state.kind) {
      case 'waiting':
      case 'initializing':
        return <StringFieldReactions store={this.stringFieldStore} fireImmediately={true} />;
      case 'ready':
      case 'auto-saving':
        return (
          <div className={fieldStyle.field}>
            <Label label={fieldDescription.label} />
            <textarea
              name={fieldDescription.name}
              value={this.stringFieldStore.value}
              required={this.stringFieldStore.isRequired}
              minLength={fieldDescription.minlength.getOrElseValue(0)}
              maxLength={fieldDescription.maxlength}
              onChange={(e) => this.stringFieldStore.setValue(e.currentTarget.value)}
              onKeyUp={this.stringFieldStore.autoSave}
              className={style.textArea}
            />
            <AutoSaveReactions
              store={this.stringFieldStore}
              segmentStore={this.props.segmentStore}
              debounceDelay={3000}
            />
            <StringFieldReactions store={this.stringFieldStore} />
          </div>
        );
    }
  }
}

export default observer(StringField);
