import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Experience/Types';
import { ProgramOverviewItems } from '../../../Experience/ExperienceBody/ExperienceOverview';
import { ProgramDetailsItems } from '../../../Experience/ExperienceBody/ExperienceDetails';
import { getPrimaryCompetencies } from '../../../Experience/ExperienceCompetencies';
import ExperienceProductDetails from './ExperienceProductDetails';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceBodyContent({ experienceResource }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite': {
      const competencies = getPrimaryCompetencies(experienceResource);

      return (
        <>
          <ProgramOverviewItems
            competencies={competencies}
            experience={experienceResource.payload}
          />
          <ProgramDetailsItems experience={experienceResource.payload} />
        </>
      );
    }
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceBodyContent);
