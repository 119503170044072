import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import { Link as LinkT } from '../../Resource/Types';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import TeamStore from '../../TeamStore';
import TeamBreadCrumb from './TeamBreadCrumb';
import TeamMeetingContent from './TeamMeetingContent';
import TeamPageContent from './TeamPageContent';
import TeamProfileHeader from './TeamProfileHeader';
import * as style from './style.module.css';

interface Props {
  teamStore: TeamStore;
  presenceMembersStore: PresenceMembersStore;
  presenceStore: GlobalPresenceMembersStore;
  links: ReadonlyArray<LinkT>;
}

class TeamProfile extends React.Component<Props> {
  componentDidMount() {
    const { presenceStore, presenceMembersStore } = this.props;
    presenceStore.addPresenceStore(presenceMembersStore);
  }

  componentWillUnmount() {
    const { presenceStore, presenceMembersStore } = this.props;
    presenceStore.removePresenceStore(presenceMembersStore);
  }

  render() {
    const { teamStore, presenceStore, presenceMembersStore, links } = this.props;
    return teamStore.team
      .map((teamResource) => (
        <>
          <TeamBreadCrumb teamResource={teamResource} links={links} />
          <div className={style.team}>
            <div className={style.members} data-test-team-member-list={true}>
              <TeamProfileHeader teamId={teamResource.payload.id} />
              <TeamMeetingContent
                teamResource={teamResource}
                presenceMembersStore={presenceMembersStore}
                presenceStore={presenceStore}
                teamStore={teamStore}
              />
            </div>
            <div className={style.content}>
              <TeamPageContent
                teamStore={teamStore}
                conversationStore={presenceMembersStore.conversationStore}
                presenceStore={just(presenceStore)}
              />
            </div>
          </div>
        </>
      ))
      .getOrElseValue(<></>);
  }
}

export default observer(TeamProfile);
