import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { Link } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';
import { CourseDocument, CourseDocumentResource } from '../Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  link: Link;
}

interface Ready {
  kind: 'ready';
  courseDocument: CourseDocumentResource;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (link: Link): Loading => ({ kind: 'loading', link });

export const ready = (courseDocument: CourseDocumentResource): Ready => ({
  kind: 'ready',
  courseDocument,
});

export const error = (message: TPlainTextKey): Error => ({ kind: 'error', message });

export type State = Waiting | Loading | Ready | Error;

class CourseDocumentStore {
  @observable
  state: State = waiting();

  @action
  load = (link: Link) => {
    this.state = loading(link);
  };

  @action
  ready = (courseDocument: CourseDocumentResource) => {
    this.state = ready(courseDocument);
  };

  @action
  error = (message: TPlainTextKey) => {
    this.state = error(message);
  };

  @computed
  get courseDocument(): Maybe<CourseDocument> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.courseDocument.payload);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
      case 'ready':
        return this.state.courseDocument.links;
    }
  }
}

export default CourseDocumentStore;
