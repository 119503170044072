import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Typography } from '../UI';

interface ModalTitleProps {
  className?: string;
}

const ModalTitle: React.FC<ModalTitleProps> = ({ className, children }) => {
  return (
    <Typography
      data-test-modal-title={true}
      className={clsx(
        'flex flex-1 justify-center text-center normal-case tracking-normal',
        className,
      )}
      color="black"
      component="h2"
      variant="h4"
    >
      {children}
    </Typography>
  );
};

export default observer(ModalTitle);
