import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import BroadcastMessageStore from '../../../BroadcastMessageStore';
import { T } from '../../../Translations';
import Textarea from '../../Form/Textarea';
import Modal from '../../Modal';
import ModalStore from '../../Modal/Store';
import * as style from './style.module.css';

interface Props {
  store: BroadcastMessageStore;
  modalStore: ModalStore;
}

const BroadcastMessageModal: React.FC<Props> = ({ store, modalStore }) => {
  const setBroadcastContent = (event: React.FormEvent<HTMLTextAreaElement>) => {
    store.setMessage(event.currentTarget.value);
  };

  const submitBroadcastMessage = () => {
    store.message.andThen(fromEmpty).do(() => store.updating());
    modalStore.close();
  };

  const submitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitBroadcastMessage();
    }
  };

  const submit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    submitBroadcastMessage();
  };

  switch (modalStore.state.kind) {
    case 'open':
      return (
        <Modal close={modalStore.close} title={<T kind="Broadcast Message" />}>
          <div className={style.contentWrapper}>
            <p className={style.subTitle}>
              <T kind="Immediately send a message to all breakout rooms" />
            </p>
            <Textarea
              name="broadcastMessage"
              className={style.textarea}
              placeholder="Broadcast a message to everyone..."
              onChange={setBroadcastContent}
              title="Broadcast Message"
              onKeyDown={submitOnEnter}
              disabled={store.state.kind === 'updating'}
              value={store.message.getOrElseValue('')}
            />
            <button
              type="submit"
              className={style.submitButton}
              onClick={submit}
              disabled={store.state.kind === 'updating'}
            >
              <T kind="Send Message" />
            </button>
          </div>
        </Modal>
      );
    case 'closed':
      return <></>;
  }
};

export default observer(BroadcastMessageModal);
