import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import { programsStore } from '../../ProgramsStore';
import { findProgramFromCommunityTeam } from '../../ProgramsStore/Functions';
import { T } from '../../Translations';
import TeamSummary from '../TeamSummary';

interface PresenceTeamProps {
  communityTeamResource: CommunityTeamResource;
  communitiesStore: CommunitiesStore;
}

const PresenceTeam: React.FC<PresenceTeamProps> = ({ communityTeamResource, communitiesStore }) =>
  programsStore.resource
    .map(({ payload }) => payload.programs)
    .andThen(findProgramFromCommunityTeam(communityTeamResource))
    .map(() => (
      <TeamSummary
        communityTeamResource={communityTeamResource}
        communitiesStore={communitiesStore}
      />
    ))
    .getOrElse(() => <T kind="Could not identify a program for this team" />);

export default observer(PresenceTeam);
