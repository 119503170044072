import { find } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { MissingLinkError } from '../Links';
import { Link, Rel } from '../Resource/Types';

export type { MissingLinkError };

/**
 * @deprecated Use the `useFindLinkBy` hook or `findLinkBy` from the root `Fetch` or `Links` module.
 */
export function findLink(rel: Rel, links: ReadonlyArray<Link>): Maybe<Link>;
export function findLink(rel: Rel): (links: ReadonlyArray<Link>) => Maybe<Link>;
export function findLink(rel: Rel, links?: ReadonlyArray<Link>) {
  const finder = find<Link>((l) => l.rel === rel);
  return typeof links === 'undefined' ? finder : finder(links);
}

/**
 * @deprecated Use the `useFindLinkBy` hook or `findLinkBy` from the root `Fetch` or `Links` module.
 */
export const findSelfLink = findLink('self');

/**
 * @deprecated Use the `useFindLinkByT` hook or `findLinkByT` from the root `Fetch` or `Links` module.
 */
export function findLinkT(rel: Rel, links: ReadonlyArray<Link>): Task<MissingLinkError, Link>;
export function findLinkT(rel: Rel): (links: ReadonlyArray<Link>) => Task<MissingLinkError, Link>;
export function findLinkT(rel: Rel, links?: ReadonlyArray<Link>) {
  const doit = (links: ReadonlyArray<Link>) =>
    toTask<MissingLinkError, Link>({ kind: 'missing-link-error', rel }, findLink(rel, links));
  return typeof links === 'undefined' ? doit : doit(links);
}
