import { MissingVarError } from '@execonline-inc/environment';
import { Stripe } from '@stripe/stripe-js';
import { Maybe } from 'maybeasy';
import { CloseAction } from '../../../../../../components/Openable';
import { AppyError } from '../../../../../../Appy';
import { MissingLinkError } from '../../../../../../LinkyLinky';
import { ProgramResource } from '../../../../../../ProgramsStore/Types';
import { Resource } from '../../../../../../Resource/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../../../Translations';
import { DiscoveryContextState } from '../../../../DiscoveryContext';
import { AvailabilityResource, CommerceType, UseCase } from '../../../../Common/Experience/Types';
import { RegisterResource } from '../../../RegisterResourceStore/Types';

interface UserNotFoundError {
  kind: 'user-not-found';
}

export type LoadingError = MissingLinkError | AppyError;

interface StripeLoadingError {
  kind: 'stripe-loading-error';
}
export interface PaymentParams {
  paymentIntentResource: PaymentIntentResource;
  stripe: Stripe;
}

export interface PaymentParams {
  paymentIntentResource: PaymentIntentResource;
  stripe: Stripe;
}

export type CommerceLoadingError =
  | LoadingError
  | UserNotFoundError
  | StripeLoadingError
  | MissingVarError;

export interface EnrollmentPayload {
  programId: number;
  moduleId: number;
  segmentId: number;
  program: ProgramResource;
}

export type EnrollmentResource = Resource<EnrollmentPayload>;

export type EnrollableResource = AvailabilityResource | RegisterResource;

export type EnrollmentError = ProgramConflictEnrollmentError | ExpiredAccessEnrollmentError;

export interface ProgramConflictEnrollmentError {
  message: AlreadyTranslatedText;
  kind: 'program-conflict';
}

export type PaymentIntentStatus =
  | 'requires_payment_method'
  | 'requires_confirmation'
  | 'requires_action'
  | 'processing'
  | 'requires_capture'
  | 'canceled'
  | 'succeeded';

export interface BillingInformation {
  zipCode: Maybe<number>;
  country: Maybe<string>;
}

export interface PaymentIntent {
  id: number;
  status: PaymentIntentStatus;
  clientSecret: Maybe<string>;
  stripePaymentIntentId: Maybe<string>;
}

export type PaymentIntentResource = Resource<PaymentIntent>;

export interface ExpiredAccessEnrollmentError {
  message: AlreadyTranslatedText;
  kind: 'access-expired';
}

interface Waiting {
  kind: 'waiting';
}
interface SettingUp {
  kind: 'setting-up';
  useCase: Maybe<UseCase>;
}

interface WaitingCommerce {
  kind: 'waiting-commerce';
  commerceType: CommerceType;
  useCase: Maybe<UseCase>;
}

export interface InitializingPayment {
  kind: 'initializing-payment';
  enrollableResource: EnrollableResource;
  discoveryContextState: DiscoveryContextState;
  action: Maybe<CloseAction>;
  useCase: Maybe<UseCase>;
}

interface ReadyForPayment {
  kind: 'ready-for-payment';
  enrollableResource: EnrollableResource;
  paymentIntentResource: PaymentIntentResource;
  discoveryContextState: DiscoveryContextState;
  action: Maybe<CloseAction>;
  stripe: Stripe;
  useCase: Maybe<UseCase>;
}

export interface Creating {
  kind: 'creating';
  enrollableResource: EnrollableResource;
  discoveryContextState: DiscoveryContextState;
  action: Maybe<CloseAction>;
}

interface Created {
  kind: 'created';
  enrollmentResource: EnrollmentResource;
}

interface Exiting {
  kind: 'exiting';
  enrollmentResource: EnrollmentResource;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

interface ServerError {
  kind: 'server-error';
  message: AlreadyTranslatedText;
}

export interface PaymentLoadingError {
  kind: 'payment-loading-error';
  message: TPlainTextKey;
}

export interface UnauthorizedUserError {
  kind: 'unauthorized-user-error';
}

export type RegisterResourceError = UnauthorizedUserError | MissingLinkError;

export type State =
  | Waiting
  | SettingUp
  | WaitingCommerce
  | InitializingPayment
  | ReadyForPayment
  | Creating
  | Created
  | Exiting
  | Error
  | ServerError
  | PaymentLoadingError;

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const settingUp = (useCase: Maybe<UseCase>): SettingUp => ({ kind: 'setting-up', useCase });

export const creating = (
  enrollableResource: EnrollableResource,
  discoveryContextState: DiscoveryContextState,
  action: Maybe<CloseAction>,
): Creating => ({
  kind: 'creating',
  enrollableResource,
  discoveryContextState,
  action,
});

export const waitingCommerce = (
  commerceType: CommerceType,
  useCase: Maybe<UseCase>,
): WaitingCommerce => ({
  kind: 'waiting-commerce',
  commerceType,
  useCase,
});

export const initializingPayment = (
  enrollableResource: EnrollableResource,
  discoveryContextState: DiscoveryContextState,
  action: Maybe<CloseAction>,
  useCase: Maybe<UseCase>,
): InitializingPayment => ({
  kind: 'initializing-payment',
  enrollableResource,
  discoveryContextState,
  action,
  useCase,
});

export const readyForPayment = (
  paymentIntentResource: PaymentIntentResource,
  enrollableResource: EnrollableResource,
  discoveryContextState: DiscoveryContextState,
  action: Maybe<CloseAction>,
  stripe: Stripe,
  useCase: Maybe<UseCase>,
): ReadyForPayment => ({
  kind: 'ready-for-payment',
  paymentIntentResource,
  enrollableResource,
  discoveryContextState,
  action,
  stripe,
  useCase,
});

export const created = (enrollmentResource: EnrollmentResource): Created => ({
  kind: 'created',
  enrollmentResource,
});

export const exiting = (enrollmentResource: EnrollmentResource): Exiting => ({
  kind: 'exiting',
  enrollmentResource,
});

export const error = (message: TPlainTextKey): Error => ({ kind: 'error', message });

export const serverError = (message: AlreadyTranslatedText): ServerError => ({
  kind: 'server-error',
  message,
});

export const paymentLoadingError = (message: TPlainTextKey): PaymentLoadingError => ({
  kind: 'payment-loading-error',
  message,
});
