import * as React from 'react';
import { DayModule, DisplayModule } from '../../DayModules';
import { NotTranslated } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  md: DayModule;
}

const moduleClass = (md: DisplayModule): string => {
  switch (md.displayType) {
    case 'start':
      return style.moduleStart;
    case 'middle':
      return style.module;
    case 'end':
      return style.moduleEnd;
  }
};

const ShowModule: React.FC<Props> = ({ md }) => {
  switch (md.kind) {
    case 'empty':
      return <div className={style.moduleEmpty} />;
    case 'display':
      return (
        <div className={moduleClass(md)} data-test-module={md.name.getOrElseValue('true')}>
          {md.name
            .map((name) => (
              <span className={style.moduleLabel}>
                <NotTranslated text={name} />
              </span>
            ))
            .getOrElse(() => (
              <></>
            ))}
        </div>
      );
  }
};

export default ShowModule;
