import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../../Experience/Types';
import {
  GroupCoachingOverview,
  LeadershipCoachingOverview,
  LearningJourneyOverview,
} from '../ExperienceOverview';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return experienceResource.payload.productDetails
    .map((productDetails) => {
      {
        switch (productDetails.kind) {
          case 'group-coaching':
            return (
              <GroupCoachingOverview
                experience={experienceResource.payload}
                productDetails={productDetails}
              />
            );
          case 'leadership-coaching':
            return <LeadershipCoachingOverview experience={experienceResource.payload} />;
          case 'program-sequence':
            return <LearningJourneyOverview experienceResource={experienceResource} />;
        }
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
