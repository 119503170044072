import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamMemberResource } from '../../../../../TeamsStore/Types';
import TeamMemberImpl from './TeamMemberImpl';

interface Props {
  teamMemberResource: TeamMemberResource;
}

const TeamMember: React.FC<Props> = ({ teamMemberResource }) => (
  <TeamMemberImpl teamMemberResource={teamMemberResource} />
);

export default observer(TeamMember);
