import { Maybe } from 'maybeasy';
import { Error } from '../../../../ErrorHandling';
import { Resource } from '../../../../Resource/Types';

export interface Option {
  value: number;
  label: string;
}

export type Options = Option[];

export interface TeamsProgramsOptions {
  programOptions: Options;
  teamOptions: Options;
}

export type TeamsProgramsOptionsResource = Resource<TeamsProgramsOptions>;

interface Waiting {
  kind: 'waiting';
  searchParameters: SearchParameters;
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  teamsProgramsOptionsResource: TeamsProgramsOptionsResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
  searchParameters: [],
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (teamsProgramsOptionsResource: TeamsProgramsOptionsResource): Ready => ({
  kind: 'ready',
  teamsProgramsOptionsResource,
});

export interface Program {
  id: Maybe<number>;
  title: Maybe<string>;
}

export type CoachTeamsState = Waiting | Loading | Ready | Error;

export interface SearchParameter {
  key: string;
  value: string;
}

export type SearchParameters = SearchParameter[];
