import { CardBody, CardFooter, CardHeader, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import {
  ExperienceFooter,
  ExperienceHeaderEndIcon,
  ExperiencesHeaderLogo,
  getExperienceHeaderBackgroundByType,
} from '../../Experience';
import { ExperienceContextProvider } from '../../Experience/ExperienceContext';
import { ExperienceResource } from '../../Experience/Types';
import { ProgramExperienceCard } from '../Common';
import ExperienceBody from './ExperienceBody';

interface Props {
  className?: string;
  invitationUuid: string;
  experienceResource: ExperienceResource;
}

function TileViewProgramExperiencePreview({
  className,
  invitationUuid,
  experienceResource,
}: Props) {
  return (
    <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
      <ProgramExperienceCard
        className={cn(
          'pointer-events-none h-full min-h-[14rem] w-[20rem]',
          'bg-white text-gray-800 shadow-tile-preview',
          className,
        )}
      >
        <CardHeader
          className={cn(
            'h-[2.375rem] rounded-t py-1.5',
            getExperienceHeaderBackgroundByType(experienceResource.payload),
          )}
          endContent={<ExperienceHeaderEndIcon />}
        >
          <ExperiencesHeaderLogo />
        </CardHeader>
        <CardBody>
          <ExperienceBody />
        </CardBody>
        <CardFooter>
          <ExperienceFooter />
        </CardFooter>
      </ProgramExperienceCard>
    </ExperienceContextProvider>
  );
}

export default observer(TileViewProgramExperiencePreview);
