import { observer } from 'mobx-react';
import * as React from 'react';
import ReplyNotificationStore from '../ReplyNotificationsStore/ReplyNotificationStore';
import MessageReplyNotification from './MessageReplyNotification';

interface Props {
  store: ReplyNotificationStore;
}

const ReplyNotification: React.FC<Props> = ({ store }) => {
  switch (store.state.notification.kind) {
    case 'team-message-reply-notification':
      return <MessageReplyNotification store={store} chatLink="teams" />;
    case 'program-chat-message-reply-notification':
      return <MessageReplyNotification store={store} chatLink="program_chats" />;
  }
};

export default observer(ReplyNotification);
