import { findPayloadByUuid } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../SegmentStore';
import FormField from './FormField';
import { FormFieldAssetResource } from './Types';

interface Props {
  uuid: string;
  embeddedFormFieldAssets: FormFieldAssetResource[];
  segmentStore: Maybe<SegmentStore>;
}

const EmbeddedFormFieldAsset: React.FC<Props> = ({ uuid, embeddedFormFieldAssets, segmentStore }) =>
  findPayloadByUuid(uuid, embeddedFormFieldAssets)
    .map((formFieldAssetResource) => (
      <FormField segmentStore={segmentStore} formFieldAssetResource={formFieldAssetResource} />
    ))
    .getOrElse(() => <></>);

export default observer(EmbeddedFormFieldAsset);
