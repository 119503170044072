import { observer } from 'mobx-react';
import { AvailabilityResource, ExperienceResource } from '../../../../../../Experience/Types';
import ScheduledDateTitle from './ScheduledDateTitle';
import NextAvailableTitle from './NextAvailableTitle';

interface Props {
  experienceResource: ExperienceResource;
  availability: AvailabilityResource;
}

function AvailabilityTitle({ experienceResource, availability }: Props) {
  return (
    <div className="mt-4 flex flex-wrap items-center gap-x-3.5">
      <ScheduledDateTitle availability={availability} />
      <NextAvailableTitle experienceResource={experienceResource} />
    </div>
  );
}

export default observer(AvailabilityTitle);
