import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamResource } from '../../../TeamsStore/Types';
import PinnedMessage from './PinnedMessage';
import * as style from './style.module.css';

interface Props {
  teamResource: TeamResource;
}

const TeamChatTitle: React.FC<Props> = ({ teamResource }) => (
  <div className={style.header}>
    <PinnedMessage teamResource={teamResource} />
  </div>
);

export default observer(TeamChatTitle);
