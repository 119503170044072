import { useCountdownSecondsUntil } from '@execonline-inc/react-hooks.private';
import { Link } from 'react-router-dom';
import { T } from '../Translations';
import Modal from '../components/Modal';
import * as style from './style.module.css';

interface Props {
  expiration: Date;
  reset: () => void;
}

export function WarningModal({ expiration, reset }: Props) {
  return (
    <Modal close={reset} title={<Title />}>
      <SecondsRemaining expiration={expiration} />
      <div className={style.actions}>
        <LogoutButton />
        <ResumeActivityButton reset={reset} />
      </div>
    </Modal>
  );
}

function Title() {
  return <T kind="Logging Out Soon Due to Inactivity" />;
}

function SecondsRemaining({ expiration }: { expiration: Date }) {
  const countdown = useCountdownSecondsUntil(expiration);

  return (
    <p>
      <T
        kind="You will automatically be logged out in {{count}} second"
        count={countdown.seconds}
      />
    </p>
  );
}

function LogoutButton() {
  return (
    <Link to="/logout" className={style.logoutButton}>
      <T kind="Log out" />
    </Link>
  );
}

function ResumeActivityButton({ reset }: { reset: Props['reset'] }) {
  return (
    <button className={style.continueButton} onClick={reset} data-test-resume-button={true}>
      <T kind="Stay Logged In" />
    </button>
  );
}
