import { mapMaybe } from '@execonline-inc/collections';
import { always, identity } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { whenInternetExplorer } from '../UserAgent';
import * as style from './style.module.css';

type Props = JSX.IntrinsicElements['main'];
type GenericAttributes = React.HTMLAttributes<HTMLElement>;
type ProvidedAttributes = Pick<GenericAttributes, 'className'>;

const browserDependentAttributes = (attrs: ProvidedAttributes): Partial<GenericAttributes> => ({
  role: whenInternetExplorer()
    .map<GenericAttributes['role']>(always('main'))
    .getOrElseValue(undefined),
  className: mapMaybe(identity, [
    whenInternetExplorer().map(always(style.main)),
    fromNullable(attrs.className),
  ]).join(' '),
});

const Main: React.FC<Props> = ({ className, ...rest }) => {
  const attrs: GenericAttributes = {
    ...browserDependentAttributes({ className }),
    ...rest,
  };
  return whenInternetExplorer()
    .map(() => <div {...attrs} />)
    .getOrElse(() => <main {...attrs} />);
};

export default observer(Main);
