import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import EventsStore from '../../../EventsStore';
import UpcomingEvents from '../../UpcomingEvents';
import Events from './Events';
import TeamEvents from './TeamEvents';

interface Props {
  eventsStore: EventsStore;
  currentUserResource: CurrentUserResource;
}

class SupplementalCalendarRouter extends React.Component<Props> {
  render() {
    return (
      <Switch>
        <Route
          path="/resources"
          exact={true}
          render={() => (
            <Events
              eventsStore={this.props.eventsStore}
              headingLevel={3}
              header="Events Calendar"
              linkable={this.props.currentUserResource}
            />
          )}
        />
        <Route
          path="/events/:eventId/dashboard"
          exact={true}
          render={() => (
            <Events
              eventsStore={this.props.eventsStore}
              headingLevel={3}
              header="Events Calendar"
              linkable={this.props.currentUserResource}
            />
          )}
        />
        <Route
          path="/teams/:teamsId"
          exact={true}
          render={({ match }) => (
            <TeamEvents
              eventsStore={this.props.eventsStore}
              header="Team Events Calendar"
              headingLevel={3}
              match={match}
            />
          )}
        />
        <Route
          path="/team"
          exact={true}
          render={({ match }) => (
            <TeamEvents
              eventsStore={this.props.eventsStore}
              header="Team Events Calendar"
              headingLevel={3}
              match={match}
            />
          )}
        />

        <Route
          exact={true}
          path="/calendar"
          render={() => (
            <UpcomingEvents eventsStore={this.props.eventsStore} limit={10} viewAllButton={false} />
          )}
        />
        <Route
          exact={true}
          path="/communities"
          render={() => (
            <UpcomingEvents eventsStore={this.props.eventsStore} limit={3} viewAllButton={false} />
          )}
        />
        <Route
          exact={true}
          path="/events/:eventId/dashboard"
          render={() => (
            <UpcomingEvents eventsStore={this.props.eventsStore} limit={3} viewAllButton={false} />
          )}
        />
        <Route
          path="/index.html"
          exact={true}
          render={() => (
            <Events
              eventsStore={this.props.eventsStore}
              headingLevel={3}
              header="Events Calendar"
              linkable={this.props.currentUserResource}
            />
          )}
        />
        <Route
          path="/"
          exact={true}
          render={() => (
            <Events
              eventsStore={this.props.eventsStore}
              headingLevel={3}
              header="Events Calendar"
              linkable={this.props.currentUserResource}
            />
          )}
        />
      </Switch>
    );
  }
}

export default observer(SupplementalCalendarRouter);
