import * as React from 'react';
import ThemeContext from '../../../Themes/ThemeContext';
import SVGIcon from '../SVGIcon';

const LogoGraphic: React.FC = () => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        const fill = 'black';
        return (
          <SVGIcon viewBox="0 0 103 17">
            <g id="logo-execonline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                id="Group"
                transform="translate(49.897156, 8.500000) scale(1, -1) translate(-49.897156, -8.500000) "
                fillRule="nonzero"
              >
                <path
                  d="M90.1458729,8.7782143 C92.1626193,8.63324785 98.4849266,8.70855829 98.9397393,8.89482666 C98.9208912,9.0751128 98.9258081,9.2740012 98.8750002,9.45748332 C98.4562447,10.9769677 97.4999086,11.9800143 95.9699348,12.4031131 C94.981639,12.6762466 93.9892457,12.6392879 93.0099642,12.3565665 C91.3234692,11.8699578 90.1827496,10.4519253 90.1458729,8.7782143 M99.7805283,7.91366029 L90.1139131,7.91366029 C90.1196495,6.94355694 90.3581189,6.10907794 90.961258,5.4350536 C92.0913243,4.17141163 93.5475447,3.71750029 95.2094551,3.92056805 C96.4018021,4.06635398 97.4310721,4.54804578 98.1841765,5.5218368 C98.3251275,5.70458139 98.4529667,5.89748756 98.6201412,6.13169565 C98.8381235,5.99353091 99.0290629,5.87265724 99.256879,5.72793664 C98.9651433,5.00080984 98.4726343,4.4835525 97.8801485,4.06201072 C95.8765138,2.63635706 92.7985377,2.74583992 90.8932409,4.30179129 C90.2024172,4.86559523 89.7639941,5.60288361 89.5115935,6.44621303 C89.1534796,7.64052678 89.1649524,8.84680498 89.5099545,10.0395617 C89.8287332,11.1426671 90.5359466,11.9492837 91.4955605,12.5734015 C93.3320208,13.7678792 96.4026216,13.6597075 97.9891397,12.3309167 C99.3396468,11.1996211 99.8911585,9.75028441 99.7805283,7.91366029"
                  id="path40"
                  fill={fill}
                />
                <path
                  d="M46.2896283,8.20449462 C46.2888099,6.51832748 46.9132556,5.17527766 48.483384,4.41487987 C50.2477298,3.5605694 51.9784767,3.66226717 53.6158027,4.76258629 C54.5123166,5.36531565 55.0236736,6.23413096 55.1351232,7.30298195 C55.1933065,7.86162315 55.2064182,8.43821102 55.1367622,8.99382014 C54.9482812,10.5144518 54.1247013,11.5918254 52.7201084,12.2173364 C52.0423964,12.5189879 51.3237103,12.5952817 50.5919124,12.5807769 C48.0244738,12.5300509 46.2879905,10.7618536 46.2896283,8.20449462 M55.9980383,8.21744244 C56.0480267,7.42254444 55.919368,6.65534495 55.6005893,5.92444854 C54.9810605,4.50846477 53.8559111,3.67226486 52.408705,3.27825768 C50.9664159,2.88580753 49.5429748,2.99168466 48.1687027,3.63760075 C46.4215661,4.45839439 45.6348629,5.8982251 45.4799807,7.74378157 C45.4095052,8.58858606 45.4914535,9.42847364 45.7790918,10.2340249 C46.3240477,11.7588359 47.4606699,12.6797704 48.9545864,13.1364679 C50.6640269,13.6591339 52.3324932,13.4837646 53.8747592,12.5310343 C55.2121546,11.7051598 55.8415171,10.4470084 55.9947603,8.92178763 C56.0185253,8.6892185 55.9980383,8.45222417 55.9980383,8.21744244"
                  id="path42"
                  fill={fill}
                />
                <path
                  d="M67.1503752,11.8344742 C66.8914188,12.1676758 66.5611673,12.4607227 66.156343,12.7075508 C65.0475832,13.383378 63.844583,13.551208 62.5891358,13.3752651 C61.1271791,13.1701487 59.9077892,12.5274286 59.0629027,11.2719814 C58.9711207,11.1357834 58.7490409,10.8782201 58.6498836,10.7467751 L58.767889,10.7920105 L58.767889,13.0972967 L57.9418507,13.0972967 L57.9418507,3.37012167 L58.767889,3.37012167 L58.767889,8.40526783 C58.8072242,9.39200667 59.1800887,10.2904873 59.8127292,11.058916 C61.1730701,12.7099273 63.5200679,12.9483148 65.1909927,12.2516727 C66.134217,11.8581572 66.7668574,11.1593026 67.0028684,10.1535517 C67.0954699,9.75692222 67.1462778,9.34193629 67.1503752,8.9346535 L67.1503752,3.38388897 L67.3454121,3.38388897 L67.9764136,3.38388897 L67.9764136,9.81526937 C67.9403564,10.5881233 67.5896179,11.269523 67.1503752,11.8344742"
                  id="path44"
                  fill={fill}
                />
                <path
                  d="M86.6778231,11.8344742 C86.4188666,12.1676758 86.0886152,12.4607227 85.6829714,12.7075508 C84.5742116,13.383378 83.3720308,13.551208 82.1165836,13.3752651 C80.6546269,13.1701487 79.4344175,12.5274286 78.5903506,11.2719814 C78.4985685,11.1357834 78.2764888,10.8782201 78.1773314,10.7467751 L78.2953369,10.7920105 L78.2953369,13.0972967 L77.4692985,13.0972967 L77.4692985,3.37012167 L78.2953369,3.37012167 L78.2953369,8.40526783 C78.334672,9.39200667 78.7067171,10.2904873 79.340177,11.058916 C80.7005179,12.7099273 83.0475157,12.9483148 84.717621,12.2516727 C85.6616648,11.8581572 86.2943053,11.1593026 86.5294968,10.1535517 C86.6229178,9.75692222 86.6737257,9.34193629 86.6778231,8.9346535 L86.6778231,3.38388897 L86.8728599,3.38388897 L87.5038614,3.38388897 L87.5038614,9.81526937 C87.4669847,10.5881233 87.1170657,11.269523 86.6778231,11.8344742"
                  id="path46"
                  fill={fill}
                />
                <polyline
                  id="path48"
                  fill={fill}
                  points="71.3780853 17 70.5528665 17 70.5528665 3.38397092 71.3780853 3.38397092 71.3780853 17"
                ></polyline>
                <polyline
                  id="path50"
                  fill={fill}
                  points="74.7920493 13.1399098 73.966011 13.1399098 73.966011 3.38397092 74.7920493 3.38397092 74.7920493 13.1399098"
                ></polyline>
                <path
                  d="M75.1157449,15.1185502 C75.1157449,14.7115132 74.7863129,14.3815077 74.3790301,14.3815077 C73.9717473,14.3815077 73.6423154,14.7115132 73.6423154,15.1185502 C73.6423154,15.5255871 73.9717473,15.8554288 74.3790301,15.8554288 C74.7863129,15.8554288 75.1157449,15.5255871 75.1157449,15.1185502"
                  id="path52"
                  fill={fill}
                />
                <path
                  d="M24.4422267,9.09674714 L24.5102437,9.29022695 C24.9265408,10.4656107 26.1188878,11.2471511 27.4866041,11.2471511 C27.5029937,11.2471511 27.5193834,11.2471511 27.5365925,11.2468233 C28.9411855,11.2275655 30.0892804,10.4577436 30.4596865,9.28563785 L30.5195087,9.09674714 L24.4422267,9.09674714 Z M27.5570796,13.2938086 C26.3680105,13.2938086 25.1592739,12.9803565 24.1316428,12.3391934 C22.7368837,11.4689031 21.9108453,9.90828063 21.9223181,8.16425803 C21.9329714,6.42638155 22.7753993,4.88206683 24.1750754,4.03349273 C25.8460002,3.0204485 27.6914747,2.82721453 29.6631495,3.45936331 C30.7792847,3.8173952 31.5889333,4.3505505 32.1978088,5.13168119 L30.3302083,6.41408931 C28.848584,5.1128331 27.4857846,4.8065925 26.0500513,5.4493126 C25.1387868,5.85733292 24.6208739,6.46776542 24.4184617,7.36976977 L24.3791265,7.54645019 L32.9836925,7.54645019 C33.1779099,9.32423547 32.7116243,10.7212892 31.5618904,11.8147247 C30.5350789,12.7910561 29.0624689,13.2938086 27.5570796,13.2938086"
                  id="path54"
                  fill={fill}
                />
                <path
                  d="M2.6308812,9.30997648 C2.93081179,10.4329133 4.17314722,11.2459219 5.58839345,11.2459219 L5.59904672,11.2459219 C7.00527864,11.2417425 8.21073736,10.4465167 8.53115501,9.31194323 L8.58360189,9.12747773 L2.58253174,9.12747773 L2.6308812,9.30997648 Z M4.42308937,5.33950195 C3.41922334,5.66483649 2.78576339,6.32116 2.48747177,7.34584089 L2.43338593,7.53137172 L11.0576195,7.53137172 C11.3329656,10.1548629 10.1045614,12.1743955 7.75264666,12.947987 C7.00773709,13.1929303 6.27839769,13.3151971 5.56626742,13.3151971 C4.39768542,13.3151971 3.27663339,12.985929 2.21540359,12.3287041 C0.809171668,11.4569386 -0.0193251118,9.88705603 0.00034246751,8.12934808 C0.0191905644,6.39499537 0.868174406,4.86600497 2.27030892,4.0395569 C4.59190276,2.67102117 6.98315262,2.74797058 9.3776804,4.26860225 C9.62516411,4.42577899 9.84396593,4.64654757 10.0758795,4.88026397 C10.1520913,4.95696753 10.2299422,5.03547395 10.3118904,5.11447206 L8.41069108,6.42400505 C7.20523237,5.21576009 5.89979679,4.86084224 4.42308937,5.33950195"
                  id="path56"
                  fill={fill}
                />
                <path
                  d="M39.3633636,5.26115942 C38.0923462,5.3625294 37.1745259,5.93387258 36.7090598,6.91339998 C36.2149119,7.9551261 36.3075134,9.01979773 36.9704747,9.91131271 C37.517889,10.6468802 38.433251,11.0948912 39.4821885,11.1406184 C40.55653,11.1868372 41.5767857,10.7900438 42.2102457,10.0776677 L42.4888697,9.76610042 L44.0409695,11.4659529 C43.970494,11.5508513 43.8836289,11.6552534 43.7877494,11.7430199 L43.7484143,11.779405 C43.5615723,11.9505948 43.3689939,12.1278489 43.1592064,12.2570813 C42.0283206,12.9554443 40.8703918,13.3050355 39.6960734,13.3050355 C38.7479322,13.3050355 37.7883182,13.0773013 36.8229679,12.6214232 C35.0266623,11.7733408 34.1162173,10.2904873 34.1153972,8.21408257 C34.1145783,6.16045949 35.016009,4.66777216 36.794286,3.77740446 C38.1882257,3.07912344 39.6886981,2.91694786 41.2539096,3.29530292 C42.6478493,3.63235606 43.668105,4.30539702 44.3687625,5.35081081 L42.5036204,6.64796961 C41.7300289,5.62287899 40.6745355,5.15610177 39.3633636,5.26115942"
                  id="path58"
                  fill={fill}
                />
                <g id="Group-2" transform="translate(10.879792, 0.000000)">
                  <path
                    d="M0.281082488,12.9747021 L4.30474143,8.34011897 L0,3.39372276 L2.90916278,3.39372276 L5.43890517,6.28297212 C6.11907562,7.0598415 6.77875901,7.81401122 7.23930816,8.33962728 C6.77138367,8.87171725 6.05105858,9.69341233 5.329914,10.5151074 L3.17057769,12.9747021 L0.281082488,12.9747021"
                    id="path60"
                    fill={theme.colors['logo-primary-color']}
                  />
                  <path
                    d="M11.2957464,3.68357371 C10.1115942,5.04571748 8.69798697,6.66681771 8.03748409,7.42803498 C7.72362231,7.07049477 7.38189811,6.67984748 7.03443755,6.28305407 L6.59683391,5.78235028 C7.08442598,5.21903802 8.30053797,3.77986291 9.27490263,2.66282635 C10.2664764,1.52653195 11.2342852,0.416460993 11.5981354,0 L14.4966449,0 C13.7877926,0.816614284 12.5307065,2.26250916 11.2957464,3.68357371"
                    id="path62"
                    fill={fill}
                  />
                  <path
                    d="M8.03420616,9.25171127 L11.2867321,13.0002699 L8.39313949,13.0002699 L6.5689715,10.9218166 L6.92544638,10.5151074 C7.30814469,10.0790608 7.68920404,9.64448922 8.03420616,9.25171127"
                    id="path64"
                    fill={fill}
                  />
                </g>
              </g>
            </g>
          </SVGIcon>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default LogoGraphic;
