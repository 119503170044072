import { observer } from 'mobx-react';
import { ArrowRightIcon, Button } from '@execonline-inc/execonline-ui';
import { experienceDetailsRoute } from '../../../../../../../ClientRoutes';
import { T } from '../../../../../../../Translations';
import NativeLink from '../../../../../../NativeLink';
import { useDiscoveryContext } from '../../../../../DiscoveryContext';
import { useEnrollmentModalContext } from '../../../Common';

function AvailabilityViewMoreDates() {
  const discoveryContextState = useDiscoveryContext();
  const { invitationUuid, experienceResource } = useEnrollmentModalContext();

  return (
    <NativeLink
      to={experienceDetailsRoute(
        invitationUuid,
        experienceResource.payload.experienceId,
        discoveryContextState,
      )}
    >
      {(onClick) => (
        <Button className="mt-8 gap-x-2.5 p-0 font-normal" variant="link" onClick={onClick}>
          <T kind="View more dates" />
          <ArrowRightIcon color="teal" size="sm" />
        </Button>
      )}
    </NativeLink>
  );
}

export default observer(AvailabilityViewMoreDates);
