import { Maybe, nothing } from 'maybeasy';
import { State } from '../../Experiences/ExperiencesStore/Types';

export const whenSearchValue = (state: State): Maybe<string> => {
  switch (state.kind) {
    case 'error':
    case 'loading':
    case 'waiting':
    case 'product-collections':
      return nothing();
    case 'experiences':
    case 'product-collections-and-experiences':
    case 'searching':
      return state.search;
  }
};
