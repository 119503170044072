import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import Decoder, { array, boolean, field, number, string, succeed } from 'jsonous';
import {
  HighProgressiveStream,
  HighProgressiveStreamResource,
  LowProgressiveStream,
  LowProgressiveStreamResource,
  MediumProgressiveStream,
  MediumProgressiveStreamResource,
  ReqHlsVideoAsset,
  ReqHlsVideoAssetResource,
  Subtitle,
  SubtitleResource,
} from '../../components/ReqHlsVideo/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import { preferredVideoPlaybackDecoder, subtitleKindDecoder } from '../Shared/Decoders';
import { surveyCompletionTrackingResourceDecoder } from '../Legacy/Decoders';

export const highProgressiveStreamDecoder: Decoder<HighProgressiveStream> = succeed({})
  .assign('label', field('label', stringLiteral('High')))
  .assign('default', field('default', boolean));

export const highProgressiveStreamResourceDecoder: Decoder<HighProgressiveStreamResource> = resourceDecoder(
  highProgressiveStreamDecoder
);

export const mediumProgressiveStreamDecoder: Decoder<MediumProgressiveStream> = succeed({})
  .assign('label', field('label', stringLiteral('Medium')))
  .assign('default', field('default', boolean));

export const mediumProgressiveStreamResourceDecoder: Decoder<MediumProgressiveStreamResource> = resourceDecoder(
  mediumProgressiveStreamDecoder
);

export const lowProgressiveStreamDecoder: Decoder<LowProgressiveStream> = succeed({})
  .assign('label', field('label', stringLiteral('Low')))
  .assign('default', field('default', boolean));

export const lowProgressiveStreamResourceDecoder: Decoder<LowProgressiveStreamResource> = resourceDecoder(
  lowProgressiveStreamDecoder
);

export const subtitleDecoder: Decoder<Subtitle> = succeed({})
  .assign('label', field('label', string))
  .assign('kind', field('kind', subtitleKindDecoder));

export const subtitleResourceDecoder: Decoder<SubtitleResource> = resourceDecoder(subtitleDecoder);

const reqHlsVideoAssetDecoder: Decoder<ReqHlsVideoAsset> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('kind', field('kind', stringLiteral('req-hls-video-asset')))
  .assign('title', field('title', explicitMaybe(string)))
  .assign('vimeoId', field('vimeo_id', explicitMaybe(number)))
  .assign('id', field('id', number))
  .assign(
    'surveyCompletionTracking',
    field(
      'survey_completion_tracking',
      explicitMaybe(surveyCompletionTrackingResourceDecoder)
    )
  )
  .assign('courseRegProgramId', field('course_reg_program_id', explicitMaybe(number)))
  .assign('mediaId', field('media_id', explicitMaybe(string)))
  .assign(
    'preferredVideoPlayback',
    field('preferred_video_playback', preferredVideoPlaybackDecoder)
  )
  .assign(
    'highProgressiveStream',
    field('high_progressive_stream', explicitMaybe(highProgressiveStreamResourceDecoder))
  )
  .assign(
    'mediumProgressiveStream',
    field('medium_progressive_stream', explicitMaybe(mediumProgressiveStreamResourceDecoder))
  )
  .assign(
    'lowProgressiveStream',
    field('low_progressive_stream', explicitMaybe(lowProgressiveStreamResourceDecoder))
  )
  .assign('subtitles', field('subtitles', array(subtitleResourceDecoder)));

export const reqHlsVideoAssetResourceDecoder: Decoder<ReqHlsVideoAssetResource> = resourceDecoder(
  reqHlsVideoAssetDecoder
);
