import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import PersonPhoto from '../../PersonPhoto';
import * as style from '../style.module.css';

interface Props {
  store: PersonStoreContract;
}

class PersonTooltip extends React.Component<Props> {
  render() {
    const { store } = this.props;
    return (
      <div className={style.person} data-test-person={true}>
        <div className={style.tooltipAvatar} data-test-person-avatar={true}>
          <PersonPhoto avatar={store.avatar} />
        </div>
      </div>
    );
  }
}

export default observer(PersonTooltip);
