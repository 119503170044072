import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { CurrentUserResource } from '../../CurrentUser/Types';
import DailyEmbed from './DailyEmbed';

const EmbedByProvider: React.FC<{
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
  currentUserResource: CurrentUserResource;
}> = ({ conferenceRoomResource, conferenceRosterStore, currentUserResource }) => {
  switch (conferenceRoomResource.payload.kind) {
    case 'daily-live-meeting':
      return (
        <DailyEmbed
          conferenceRoomResource={conferenceRoomResource}
          conferenceRosterStore={conferenceRosterStore}
          currentUserResource={currentUserResource}
        />
      );
  }
};

export default observer(EmbedByProvider);
