import { Window } from '@execonline-inc/browser-window.private';
import { warn } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { always, assertNever, noop } from '@kofno/piper';
import { toJS } from 'mobx';
import { findLinkBy } from '../Links';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { toRel } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import { UnifiedLoginError, invalidRelError, unknownAppError } from './Errors';
import { State } from './States';
import { UnifiedLoginStore } from './Store';

interface Props extends RCProps<UnifiedLoginStore> {}

export class UnifiedLoginReactions extends ReactionComponent<UnifiedLoginStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'identifying-app':
        toRel(state.appLinkRel)
          .mapError<UnifiedLoginError>(always(invalidRelError(state.appLinkRel)))
          .map((rel) => findLinkBy({ rel, type: 'text/html' }, rootResourceStore.links))
          .andThen(toResult(unknownAppError(state.appLinkRel)))
          .map((link) => link.href)
          .cata({ Err: store.errored, Ok: store.redirecting });
        break;
      case 'redirecting':
        redirectToClientApp(state.href).fork(store.errored, noop);
        break;
      case 'errored':
        warn('Unified Login error:', toJS(state.error));
        break;
      default:
        assertNever(state);
    }
  };
}

const redirectToClientApp = Window.locationReplace;
