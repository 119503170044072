import { Maybe, nothing } from 'maybeasy';
import { useEffect, useState } from 'react';
import { State } from '../../../Experiences/ExperiencesStore/Types';
import { whenSearchValue } from '../Functions';

function useSearchBox(state: State) {
  const [value, onChange] = useState<Maybe<string>>(nothing());

  useEffect(() => {
    onChange(whenSearchValue(state));
  }, [whenSearchValue(state)]);

  return { value, onChange } as const;
}

export default useSearchBox;
