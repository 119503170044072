import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramResource } from '../../../ProgramChatStore/Types';
import PinnedMessage from './PinnedMessage';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}

const ProgramChatTitle: React.FC<Props> = ({ programResource }) => (
  <div className={style.header}>
    <PinnedMessage programResource={programResource} />
  </div>
);

export default observer(ProgramChatTitle);
