import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import { lightTheme } from '../../Native/styles';
import Notifications from '../../Notifications';
import ThemeContext from '../../Themes/ThemeContext';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import * as platformStyle from '../Platform/style.module.css';
import CoachCommunities from './CoachCommunities';

@observer
class Communities extends React.Component {
  render() {
    return (
      <>
        <I18nTitle title="Teams" />
        <div className={platformStyle.container}>
          <Notifications />
          <AutoLaunchable />
          <Main className={platformStyle.content} id="main">
            <ThemeContext.Provider value={lightTheme}>
              <h1 data-test-page-header="Teams">
                <T kind="Teams" />
              </h1>
              <CoachCommunities />
            </ThemeContext.Provider>
          </Main>
        </div>
      </>
    );
  }
}

export default Communities;
