import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ResourcesImportantIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 26 24">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="m12.619 0-.798 1.814L8.962 8.22l-6.983.742L0 9.182l1.485 1.32 5.195 4.7-1.457 6.874L4.811 24l1.732-.99 6.076-3.519 6.075 3.52 1.732.989-.412-1.924-1.457-6.873 5.196-4.701 1.484-1.32-1.98-.22-6.982-.742-2.86-6.406L12.62 0Zm0 4.316 2.254 5.086.22.467.495.055 5.525.578-4.123 3.711-.385.357.11.495 1.154 5.416-4.81-2.749-.44-.275-.44.275-4.811 2.75 1.154-5.417.11-.495-.385-.357-4.123-3.711 5.525-.578.495-.055.22-.467 2.255-5.086Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourcesImportantIcon);
