import { AppyError } from '../../../Appy';
import { handleAppyError } from '../../../ErrorHandling';
import { Resource } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  viewableAsset: ViewableAssetResource;
}

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export type State = Loading | Ready | Error;

export const ready = (viewableAsset: ViewableAssetResource): State => ({
  kind: 'ready',
  viewableAsset,
});

export const boxError = (baseError: AppyError): State => {
  const message = handleAppyError(baseError);
  return {
    kind: 'error',
    message,
  };
};

export interface ViewableAsset {
  filename: string;
}

export type ViewableAssetResource = Resource<ViewableAsset>;
