import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { AppyError } from '../../../../Appy';
import { MissingLinkError } from '../../../../LinkyLinky';
import { AssertionBackendResource } from '../../../../Login/Types';
import { Link, Resource } from '../../../../Resource/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';
import { InstantSsoReady } from '../SharedInvitationResourceStore/Types';

export const validationError = (message: AlreadyTranslatedText): ValidationError => ({
  kind: 'validation-error',
  message,
});

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const requestingEmailAddress = (
  state: Omit<RequestingEmailAddress, 'kind'>,
): RequestingEmailAddress => ({
  ...state,
  kind: 'requesting-email-address',
});

export const authenticatingEmailDomain = (emailAddress: string): AuthenticatingEmailDomain => ({
  kind: 'authenticating-email-domain',
  emailAddress,
});

export const pendingEmailConfirmation = (
  state: Omit<PendingEmailConfirmation, 'kind'>,
): PendingEmailConfirmation => ({
  ...state,
  kind: 'pending-email-confirmation',
});

export const validatingEmailFromIdentifiedUser = (
  emailAddress: string,
): ValidatingEmailFromIdentifiedUser => ({
  kind: 'validating-email-from-identified-user',
  emailAddress,
});

export const requestingProfileInfo = (
  state: Omit<RequestingProfileInfo, 'kind'>,
): RequestingProfileInfo => ({
  ...state,
  kind: 'requesting-profile-info',
});

export const requestingProfileInfoLockedAccess = (
  state: OmitRequestingProfileInfoLockedAccess,
): RequestingProfileInfoLockedAccess => ({
  ...state,
  kind: 'requesting-profile-info-locked-access',
});

export const requestingProfileInfoPasswordBackend = (
  state: OmitRequestingProfileInfoPasswordBackend,
): RequestingProfileInfoPasswordBackend => ({
  ...state,
  kind: 'requesting-profile-info-password-backend',
});

export const registering = (state: Omit<Registering, 'kind'>): Registering => ({
  ...state,
  kind: 'registering',
});

export const registered = (): Registered => ({ kind: 'registered' });

export const authenticatingUser = (
  uuid: string,
  experienceId: Maybe<string>,
): AuthenticatingUser => ({
  kind: 'authenticating-user',
  uuid,
  experienceId,
});

export const instantSso = (instantSsoReady: InstantSsoReady): InstantSso => ({
  kind: 'instant-sso',
  instantSsoReady,
});

export const error = (message: TPlainTextKey): Error => ({ kind: 'error', message });

interface Waiting {
  kind: 'waiting';
}

export interface RequestingEmailAddress {
  kind: 'requesting-email-address';
  emailAddress: string;
  alertMessage: Maybe<AlreadyTranslatedText>;
}

export interface AuthenticatingEmailDomain {
  kind: 'authenticating-email-domain';
  emailAddress: string;
}

export interface PendingEmailConfirmation {
  kind: 'pending-email-confirmation';
  emailAddress: string;
}

export interface ValidatingEmailFromIdentifiedUser {
  kind: 'validating-email-from-identified-user';
  emailAddress: string;
}

export interface RequestingProfileInfoBase {
  createLink: Link;
  emailAddress: string;
  firstName: string;
  lastName: string;
  termsAccepted: boolean;
  alertMessage: Maybe<AlreadyTranslatedText>;
}

export interface RequestingProfileInfo extends RequestingProfileInfoBase {
  kind: 'requesting-profile-info';
}

export interface RequestingProfileInfoLockedAccess extends RequestingProfileInfoBase {
  kind: 'requesting-profile-info-locked-access';
}

export interface RequestingProfileInfoPasswordBackend extends RequestingProfileInfoBase {
  kind: 'requesting-profile-info-password-backend';
}

export type OmitRequestingProfileInfoPasswordBackend = Omit<
  RequestingProfileInfoPasswordBackend,
  'kind'
>;

export type OmitRequestingProfileInfoLockedAccess = Omit<RequestingProfileInfoLockedAccess, 'kind'>;

export interface Registering {
  kind: 'registering';
  createLink: Link;
  emailAddress: string;
  firstName: string;
  lastName: string;
  termsAccepted: boolean;
}

interface Registered {
  kind: 'registered';
}

interface AuthenticatingUser {
  kind: 'authenticating-user';
  uuid: string;
  experienceId: Maybe<string>;
}

export interface InstantSso {
  kind: 'instant-sso';
  instantSsoReady: InstantSsoReady;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export type State =
  | Waiting
  | RequestingEmailAddress
  | AuthenticatingEmailDomain
  | PendingEmailConfirmation
  | ValidatingEmailFromIdentifiedUser
  | Registered
  | AuthenticatingUser
  | InstantSso
  | Error
  | LockedAccessState;

export type FormState =
  | RequestingEmailAddress
  | AuthenticatingEmailDomain
  | PendingEmailConfirmation
  | Registered
  | LockedAccessState;

export type LockedAccessState =
  | RequestingProfileInfo
  | Registering
  | RequestingProfileInfoLockedAccess
  | RequestingProfileInfoPasswordBackend;

export type LoadingError = MissingLinkError | AppyError;

export type FormErrors = NonEmptyList<TPlainTextKey>;

export interface ValidationError {
  kind: 'validation-error';
  message: AlreadyTranslatedText;
}

export type InvitationError = ValidationError | LoadingError;

export type RegistrationError = ValidationError | AppyError;

export interface PendingUser {
  kind: 'pending-user';
}

export interface InvalidUser {
  kind: 'invalid-user';
}

export interface OutsideOrgUser {
  kind: 'outside-org-user';
  errorMessage: Maybe<AlreadyTranslatedText>;
}

export interface ValidUser {
  kind: 'valid-user';
  uuid: string;
  experienceId: Maybe<string>;
}

export interface NewUser {
  kind: 'new-user';
  assertionBackend: AssertionBackendResource;
  username: string;
}

export type User = PendingUser | InvalidUser | ValidUser | NewUser | OutsideOrgUser;

export type UserResource = Resource<User>;

export interface RegisterUserPayloadOut {
  user: {
    email: string;
    first_name: string;
    last_name: string;
  };
}
