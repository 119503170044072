import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';
import ExperienceTimeCommitmentItems from './ExperienceTimeCommitmentItems';

function ExperienceTimeCommitment() {
  const { experienceResource } = useExperiencePopoverContext();

  switch (experienceResource.payload.kind) {
    case 'beginnable':
    case 'returnable':
    case 'resumable':
    case 'upcoming':
    case 'graduated':
      return emptyFragment();
    case 'enrollable':
    case 'commerce-enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return <ExperienceTimeCommitmentItems experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceTimeCommitment);
