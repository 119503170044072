import { observer } from 'mobx-react';
import * as Pusher from 'pusher-js';
import * as React from 'react';

import ProgramStore from '../../../ProgramStore';

interface Props {
  store: ProgramStore;
  channel: Pusher.Channel;
}

class UpcomingSessionsBinding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel, store } = this.props;

    channel.bind('refresh-calendar-events', () => {
      switch (store.state.kind) {
        case 'ready':
          store.reloading(store.state.programDetailResource);
          break;
        case 'error':
        case 'loading':
        case 'waiting':
        case 'reloading':
        case 'ready':
        case 'course-not-ready':
        case 'cancelling-registration':
        case 'registration-cancelled':
          break;
      }
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(UpcomingSessionsBinding);
