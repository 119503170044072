import * as React from 'react';
import { ExpandableItem } from '../../../../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../../../../ProgramStore/Types';
import { AlreadyTranslated } from '../../../../../../Translations';
import LockIcon from '../../../../../Cutesies/LockIcon';
import * as style from '../../style.module.css';

interface Props {
  expandableModule: ExpandableItem<ActiveProgramModule>;
}

const ToggleGate: React.FC<Props> = ({ expandableModule }) => {
  return (
    <div className={style.gatedInfo} data-test-toggle-gated={true}>
      <div className={style.gatedIcon} data-test-lock-icon={true}>
        <LockIcon />
      </div>
      <div className={style.gatedTooltip} data-test-gated-message={true}>
        {expandableModule.item.gatedMessage
          .map((m) => <AlreadyTranslated content={m} />)
          .getOrElseValue(<></>)}
      </div>
    </div>
  );
};

export default ToggleGate;
