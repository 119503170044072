import * as React from 'react';
import { observer } from 'mobx-react';
import CommunitiesStore from '../../CommunitiesStore';
import CommunityGroup from './CommunityGroup';
import LoadMoreCommunities from './LoadMoreCommunities';

interface Props {
  communitiesStore: CommunitiesStore;
}

const CommunitiesGroup: React.FC<Props> = ({ communitiesStore }) => (
  <>
    <CommunityGroup communitiesStore={communitiesStore} />
    <LoadMoreCommunities communitiesStore={communitiesStore} />
  </>
);

export default observer(CommunitiesGroup);
