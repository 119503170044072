export function truncate(length: number, str: string): string;
export function truncate(length: number): (str: string) => string;
export function truncate(length: number, str?: string) {
  const elipses = '…';
  const endIndex = Math.max(0, length - elipses.length);
  const doit = (str: string) =>
    str.length > length ? `${str.substring(0, endIndex)}${elipses}` : str;
  return typeof str === 'undefined' ? doit : doit(str);
}

export const removeLeadingChar = (char: string, str: string): string =>
  str.charAt(0) === char ? str.slice(char.length) : str;
