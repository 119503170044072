import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { T, whenTPlainTextKey } from '../../../Translations';

interface Props {
  param: string;
  message: string;
}

const ErrorMessage: React.FC<Props> = ({ param, message }) => (
  <div key={param} data-test-error={param}>
    <li data-test-error-message={message}>
      {whenTPlainTextKey(message)
        .map((key) => <T kind={key} />)
        .getOrElse(() => (
          <NotTranslated text={message} />
        ))}
    </li>
  </div>
);

export default observer(ErrorMessage);
