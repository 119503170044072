import * as React from 'react';
import { observer } from 'mobx-react';
import CoachChatRoom from '../CoachChatRoom';
import ConversationStore from '../../../ConversationStore';
import { ChatablePersonResourceList } from '../../../ConversationStore/Types';
import ConversationAPIReactions from '../../../ConversationStore/ConversationAPIReactions';
import MainChatTitle from './MainChatTitle';
import { LocalConferenceRoomResource } from '../../../Conference/Types';
import { EventPollsResource } from '../../../EventPolls/Types';
import { EventResource } from '../../../EventsStore/Types';
import PollsModalStore from '../../../EventPolls/PollsModal/PollsModalStore';

interface Props {
  conversationStore: ConversationStore;
  chatablePersonResourceList: ChatablePersonResourceList;
  eventResource: EventResource;
  conferenceRoomResource: LocalConferenceRoomResource;
  polls: EventPollsResource;
}

class MainChat extends React.Component<Props> {
  pollsModalStore = new PollsModalStore(this.props.eventResource.payload.polls.payload);

  componentDidMount() {
    this.props.conversationStore.loading();
  }

  render() {
    return (
      <>
        <MainChatTitle
          conferenceRoomResource={this.props.conferenceRoomResource}
          eventResource={this.props.eventResource}
          pollsModalStore={this.pollsModalStore}
        />
        <CoachChatRoom
          conversationStore={this.props.conversationStore}
          chatablePersonResourceList={this.props.chatablePersonResourceList}
        />
        <ConversationAPIReactions store={this.props.conversationStore} />
      </>
    );
  }
}
export default observer(MainChat);
