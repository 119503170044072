import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { Link } from '../../../../Resource/Types';
import { TPlainTextKey } from '../../../../Translations';
import ProgramResourcesStore from '../../ProgramResourceList/ProgramResourcesStore';
import { CrossProgramResourcesState, loading, ready, waiting } from './Types';

class ProgramsResourcesStore {
  @observable
  state: CrossProgramResourcesState;

  constructor() {
    this.state = waiting();
  }

  @action
  load = (links: ReadonlyArray<Link>) => {
    this.state = loading(links);
  };

  @action
  ready = (programResourcesStores: ProgramResourcesStore[]) => {
    this.state = ready(programResourcesStores);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get programResourcesStores(): ProgramResourcesStore[] {
    switch (this.state.kind) {
      case 'ready':
        return this.state.programResourcesStores;
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default ProgramsResourcesStore;
