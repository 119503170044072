import * as React from 'react';
import { Assignment } from '../../../TeamResourceStore/Types';
import DocumentResource from './DocumentResource';

const assignmentRenderer = (assignment: Assignment) =>
  assignment.resources.map(
    (resource): React.ReactElement<any> => {
      switch (resource.payload.kind) {
        case 'document':
          return (
            <DocumentResource
              key={resource.payload.id}
              assignment={assignment}
              resource={resource.payload}
            />
          );
      }
    }
  );

export default assignmentRenderer;
