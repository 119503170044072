import * as React from 'react';
import TableHeader from './TableHeader';
import { observer } from 'mobx-react';
import { LearningPartnersNode } from './Types';
import LearningPartnersDetails from './LearningPartnersDetails';
import { fromEmpty } from 'maybeasy';

interface Props {
  node: LearningPartnersNode;
}

const LearningPartners: React.FC<Props> = ({ node }) => (
  <>
    {fromEmpty(node.learningPartners.payload).cata({
      Just: () => (
        <table className="p3-table">
          <TableHeader />
          <LearningPartnersDetails node={node} />
        </table>
      ),
      Nothing: () => <></>,
    })}
  </>
);

export default observer(LearningPartners);
