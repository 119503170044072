import { Experience } from '../../../Common/Experience/Types';

export const getHeroSectionBackgroundColor = (experience: Experience) => {
  switch (experience.offeringType) {
    case 'coaching':
      return 'bg-leadership-coaching-program-hero';
    case 'group-coaching':
      return 'bg-group-coaching-program-hero';
    case 'program-sequence':
      return 'bg-multi-step-learning-journey-hero';
    case 'aep':
    case 'epc':
    case 'msuite':
      return undefined;
  }
};
