import { emptyFragment } from '@execonline-inc/execonline-ui';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import NavigationBar from '../../../../../components/Platform/NavigationBar';
import { PreviewModeContext } from '../../PreviewMode/PreviewModeContext';

function Header() {
  return (
    <div className="bg-white sm:px-0">
      <PreviewModeContext.Consumer>
        {(previewMode) => {
          switch (previewMode) {
            case 'preview':
              return emptyFragment();
            case 'default':
              return <NavigationBar className="mx-auto box-border xl:container" />;
            default:
              assertNever(previewMode);
          }
        }}
      </PreviewModeContext.Consumer>
    </div>
  );
}

export default observer(Header);
