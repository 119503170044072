import { SvgIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function LeadershipPersonaDarcyIcon() {
  return (
    <SvgIcon size="inherit" viewBox="0 0 40 40">
      <path
        fill="#C07C15"
        fill-opacity=".3"
        d="M20.333 40c11.046 0 20-8.954 20-20s-8.954-20-20-20c-11.045 0-20 8.954-20 20s8.955 20 20 20Z"
      />
      <mask id="a" width="41" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M20.333 40c11.046 0 20-8.954 20-20s-8.954-20-20-20c-11.045 0-20 8.954-20 20s8.955 20 20 20Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#A25A34"
          d="M16.674 27.29h6.967s3.578-3.529 3.44-8.109c-.139-4.58-4.514-7.389-8.428-7.389-3.914 0-6.815 9.63-1.98 15.498Z"
        />
        <path
          fill="#F1C9A5"
          d="M29.911 32.31c-1.289-2.44-9.578-3.972-9.578-3.972s-8.289 1.531-9.578 3.972C9.802 35.697 9.005 40 9.005 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E4B692"
          d="M20.333 28.338s8.29 1.531 9.578 3.972c1.212 3.076 1.75 7.69 1.75 7.69H20.333V28.338Z"
        />
        <path fill="#F1C9A5" d="M22.59 23.376h-4.513v7.553h4.513v-7.553Z" />
        <path fill="#E4B692" d="M22.59 23.376h-2.257v7.553h2.257v-7.553Z" />
        <path
          fill="#DDAC8C"
          d="M18.077 26.876c.405 1.165 2.043 1.833 3.246 1.833.436 0 .859-.073 1.267-.207v-5.126h-4.513v3.5Z"
          opacity=".1"
        />
        <path
          fill="#F1C9A5"
          d="M26.135 18.61c0-4.717-2.597-7.4-5.802-7.4-3.204 0-5.802 2.682-5.802 7.4 0 4.717 2.598 8.542 5.802 8.542 3.204 0 5.802-3.824 5.802-8.542Z"
        />
        <path
          fill="#E4B692"
          d="M25.252 21.881c-1.052-.117-1.762-1.321-1.584-2.689.176-1.368 1.172-2.384 2.225-2.268 1.051.117 1.76 1.32 1.583 2.689-.177 1.369-1.172 2.384-2.224 2.268Z"
        />
        <path
          fill="#F1C9A5"
          d="M13.232 19.613c-.178-1.369.531-2.572 1.582-2.689 1.053-.116 2.05.9 2.226 2.268.177 1.368-.534 2.572-1.584 2.69-1.053.115-2.048-.9-2.224-2.269Z"
        />
        <path
          fill="#E4B692"
          d="M26.135 18.61c0-4.717-2.597-7.4-5.802-7.4v15.942c3.204 0 5.802-3.824 5.802-8.542Z"
        />
        <path
          fill="#01561E"
          d="M29.911 32.31a2.178 2.178 0 0 0-.304-.428c-1.407 1.852-5.027 3.17-9.273 3.17h-.001c-4.246 0-7.867-1.318-9.274-3.17a2.203 2.203 0 0 0-.304.428C9.802 35.697 9.005 40 9.005 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#D1874A"
          d="M17.909 13.597c.963 1.738 3.377 2.728 5.118 4.034 3.05 2.286 1.671 7.34.614 9.66 3.832-2.227 6.641-5.964 5.231-12.019-1.41-6.055-6.22-8.632-11.198-6.485-4.096-1.074-8.3 5.147-7.816 10.772.484 5.626 6.815 7.732 6.815 7.732s-3.776-8.381 1.236-13.694Z"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(LeadershipPersonaDarcyIcon);
