import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const UserFolderIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 32 32">
      <path
        fill={defaultTheme.variables['icon-standard-on-light']}
        d="M6 3v26h20V13.437l1.719-1.718.281-.313V3Zm2 2h14v6.406l.281.313L24 13.438V27H8Zm16 0h2v5.563l-1 1-1-1Zm-8 6c-2.2 0-4 1.8-4 4 0 1.113.477 2.117 1.219 2.844A5.036 5.036 0 0 0 11 22h2c0-1.668 1.332-3 3-3s3 1.332 3 3h2a5.036 5.036 0 0 0-2.219-4.156C19.523 17.117 20 16.114 20 15c0-2.2-1.8-4-4-4Zm0 2c1.117 0 2 .883 2 2s-.883 2-2 2-2-.883-2-2 .883-2 2-2Z"
      />
    </SVGIcon>
  );
};

export default observer(UserFolderIcon);
