import { observer } from 'mobx-react';
import { Link } from '../../../Resource/Types';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import WhenLink from '../../../WhenLink';
import Image from '../../Image';
import * as style from '../style.module.css';

interface Props {
  links: ReadonlyArray<Link>;
}

function UniversityLogoOnWhite({ links }: Props) {
  const { translate } = useTranslationsContext();
  return (
    <WhenLink rel="logo-on-white" links={links}>
      {({ href }) => (
        <div className={style.universityLogo} data-test-university-logo={true}>
          <>
            <Image src={href} alt={translate('University partner logo')} />{' '}
          </>
        </div>
      )}
    </WhenLink>
  );
}

export default observer(UniversityLogoOnWhite);
