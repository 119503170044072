import { Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../../Translations';
import Exit from '../../../../../../../Exit';
import { useDiscoveryContext } from '../../../../../../DiscoveryContext';
import { exitToDashboard } from '../../../../../Experience/Actions/Exitable/Types';
import { useEnrollmentModalContext } from '../../../../Common';

function EnrollNowButton() {
  const discoveryContextState = useDiscoveryContext();
  const { availability, enrollmentStore, onClose } = useEnrollmentModalContext();

  const handleSettingUpClick = () => {
    enrollmentStore.creating(availability, discoveryContextState, just(onClose));
  };

  const handleWaitingCommerceClick = () => {
    enrollmentStore.initializingPayment(availability, discoveryContextState, just(onClose));
  };

  switch (enrollmentStore.state.kind) {
    case 'waiting':
    case 'setting-up':
      return (
        <Button color="gray" onClick={handleSettingUpClick}>
          <T kind="Enroll Now" />
        </Button>
      );
    case 'waiting-commerce':
      return (
        <Button color="gray" onClick={handleWaitingCommerceClick}>
          <T kind="Enroll Now" />
        </Button>
      );

    case 'creating':
    case 'created':
    case 'initializing-payment':
      return (
        <Button color="gray" isLoading disabled>
          <T kind="Loading..." />
        </Button>
      );
    case 'exiting':
      return (
        <Button color="gray" isLoading disabled>
          <T kind="Loading..." />
          <Exit exitContext={exitToDashboard()} />
        </Button>
      );
    case 'payment-loading-error':
    case 'server-error':
    case 'error':
      return (
        <Button color="gray" disabled>
          <T kind="Enroll Now" />
        </Button>
      );
    case 'ready-for-payment':
      return emptyFragment();
  }
}

export default observer(EnrollNowButton);
