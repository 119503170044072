import * as React from 'react';
import { fromNullable } from 'maybeasy';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils, DraftHandleValue } from 'draft-js';
import { SyntheticKeyboardEvent, KeyCommand } from '../../../../ChatMessageStore/Types';
import { TPlainTextKey, TranslationsContext, translation } from '../../../../Translations';
import ChatMessageStore from '../../../../ChatMessageStore';
import ChatReplyStore from '../../../../ChatReplyStore';

interface Props {
  editorState: EditorState;
  placeholder: TPlainTextKey;
  submitChatMessage: () => void;
  onChange: (value: EditorState) => void;
  store: ChatMessageStore | ChatReplyStore;
  readOnly?: boolean;
}

const ChatEditor: React.FC<Props> = ({
  editorState,
  placeholder,
  store,
  readOnly,
  submitChatMessage,
  onChange,
}) => {
  const myKeyBindingFn = (e: SyntheticKeyboardEvent): KeyCommand | null => {
    if (e.key === 'Enter' && !e.nativeEvent.shiftKey && !e.nativeEvent.ctrlKey) {
      return 'submit-chat';
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command: KeyCommand): DraftHandleValue => {
    switch (command) {
      case 'submit-chat':
        submitChatMessage();
        return 'handled';
      default:
        const editor = store.message.getOrElse(EditorState.createEmpty);
        return fromNullable(RichUtils.handleKeyCommand(editor, command))
          .do(onChange)
          .map<DraftHandleValue>(() => 'handled')
          .getOrElseValue('not-handled');
    }
  };

  const wrappedOnChange = (value: EditorState): void => {
    const currentContentTextLength = store.currentMessageContent
      .map((contentState) => contentState.getPlainText().length)
      .getOrElseValue(0);
    const newContentTextLength = value.getCurrentContent().getPlainText().length;

    if (currentContentTextLength === 0 && newContentTextLength === 1) {
      onChange(EditorState.moveFocusToEnd(value));
    } else {
      onChange(value);
    }
  };

  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <Editor
          editorState={editorState}
          onChange={wrappedOnChange}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={myKeyBindingFn}
          placeholder={translation(placeholder)(ts)}
          readOnly={readOnly}
          stripPastedStyles={true}
          ariaLabel={translation('New chat message')(ts)}
        />
      )}
    </TranslationsContext.Consumer>
  );
};

export default ChatEditor;
