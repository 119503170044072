import { AppyError } from '../Appy';
import { MissingLinkError } from '../LinkyLinky';

export interface PreferredLanguageNotEstablished {
  kind: 'preferred-language-not-established';
}

export interface UnsupportedBrowserLanguage {
  kind: 'unsupported-browser-language';
}

export type DetectionError = PreferredLanguageNotEstablished | UnsupportedBrowserLanguage;

export type PreferenceUpdateError = MissingLinkError | AppyError;

export const preferredLanguageNotEstablished = (): PreferredLanguageNotEstablished => ({
  kind: 'preferred-language-not-established',
});

export const unsupportedBrowserLanguage = (): UnsupportedBrowserLanguage => ({
  kind: 'unsupported-browser-language',
});
