import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  currentUserResource: CurrentUserResource;
}

class NoConference extends React.Component<Props> {
  componentDidMount() {
    warn('ConferenceRoomUnreachable', 'Failed to reach conference room', {
      currentUser: this.props.currentUserResource.payload.id,
    });
  }

  render() {
    return (
      <div className={style.conferenceMain} data-test-no-conference={true}>
        <T kind="No conference room could be located." />
      </div>
    );
  }
}

export default observer(NoConference);
