import { explicitMaybe, nullableBlankString, stringLiteral } from '@execonline-inc/decoders';
import Decoder, {
  array,
  boolean,
  date,
  dateISO,
  field,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import { eventPollsResourceDecoder } from '../../EventPolls/decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { documentAssetResourceDecoder } from '../../SegmentStore/Decoders';
import { maybeConferenceRoomDecoder } from '../../TeamsStore/Decoders/ConferenceRoomDecoders';
import { Event, EventResource, EventsResource, EventType } from '../Types';

const eventTypeDecoder: Decoder<EventType> = oneOf([
  stringLiteral<EventType>('Coaching'),
  stringLiteral<EventType>('Course'),
  stringLiteral<EventType>('Team'),
  stringLiteral<EventType>('EnterpriseGroup'),
  stringLiteral<EventType>('Section'),
  stringLiteral<EventType>('SegmentSession'),
]);

export const eventDecoder: Decoder<Event> = succeed({})
  .assign('id', field('id', number))
  .assign('uuid', field('uuid', string))
  .assign('title', field('title', string))
  .assign('agenda', field('agenda', nullableBlankString))
  .assign('startAt', field('start_at', date))
  .assign('notificationsActiveAt', field('notifications_active_at', dateISO))
  .assign('earlyAccessAt', field('early_access_at', explicitMaybe(date)))
  .assign('endAt', field('end_at', date))
  .assign('live', field('live', boolean))
  .assign('type', field('type', eventTypeDecoder))
  .assign('description', field('description', string))
  .assign('conferenceRoom', field('conference_room', maybeConferenceRoomDecoder))
  .assign('actionLabel', field('action_label', nullableBlankString))
  .assign(
    'embeddedDocumentAssets',
    field('embedded_document_assets', array(documentAssetResourceDecoder))
  )
  .assign('polls', field('polls', eventPollsResourceDecoder));

export const eventResourceDecoder: Decoder<EventResource> = resourceDecoder(eventDecoder);

export const eventsResourceDecoder: Decoder<EventsResource> = resourceDecoder(
  array(eventResourceDecoder)
);
