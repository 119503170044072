import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import BreadCrumb from '../BreadCrumb';

const SelectProgramBreadCrumb: React.FC = () => (
  <BreadCrumb path="/resources/programs" title={<T kind="Select Program" />} />
);

export default observer(SelectProgramBreadCrumb);
