import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import ConversationStore from '../../../ConversationStore';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { ProgramResource } from '../../../ProgramChatStore/Types';
import ScrollStore from '../../../ScrollStore';
import { scrollEvent } from '../../../ScrollStore/Types';
import NewChatMessage from '../../NewChatMessage';
import MoreMessages from '../../TeamProfile/TeamChat/MoreMessages';
import TeamChatMessageForm from '../../TeamProfile/TeamChat/TeamChatMessageForm';
import MessageConversation from './MessageConversation';
import ProgramChatFooter from './ProgramChatFooter';
import ProgramChatTitle from './ProgramChatTitle';
import ScrollPosition from './ScrollPosition';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
  messageListEl: React.RefObject<HTMLDivElement>;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  conversationStore: ConversationStore;
  lastReadMessageStore: LastReadMessageStore;
  scrollStore: ScrollStore;
}

const onScroll = (conversationStore: ConversationStore, scrollStore: ScrollStore) => (
  divElement: React.UIEvent<HTMLDivElement>
) => {
  scrollStore.scrolling(scrollEvent(divElement));
  conversationStore.unseenMessage
    .andThen(() => scrollStore.scrollPosition)
    .andThen((pos) => {
      switch (pos) {
        case 'middle':
        case 'near-bottom':
        case 'top':
        case 'near-top':
          return nothing();
        case 'bottom':
          return just(pos);
      }
    })
    .do(conversationStore.clearUnseenMessage);
};

const ProgramChatArea: React.FC<Props> = ({
  programResource,
  messageListEl,
  presenceStore,
  conversationStore,
  lastReadMessageStore,
  scrollStore,
}) => {
  return (
    <div className={style.chatbox}>
      <ProgramChatTitle programResource={programResource} />
      <div
        className={style.scroller}
        ref={messageListEl}
        onScroll={() => onScroll(conversationStore, scrollStore)}
      >
        <MoreMessages store={conversationStore} />
        <MessageConversation
          conversationStore={conversationStore}
          presenceStore={presenceStore}
          lastReadMessageStore={lastReadMessageStore}
        />
        <MoreMessages store={conversationStore} />
      </div>
      <ScrollPosition
        messageListEl={messageListEl}
        conversationStore={conversationStore}
        scrollStore={scrollStore}
      />
      <NewChatMessage store={conversationStore.chatMessageStore}>
        {{
          form: (
            <TeamChatMessageForm
              store={conversationStore.chatMessageStore}
              placeholder="Send a message..."
            />
          ),
        }}
      </NewChatMessage>
      <ProgramChatFooter />
    </div>
  );
};

export default observer(ProgramChatArea);
