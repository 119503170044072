import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import {
  CommunitiesResource,
  CommunitiesState,
  loading,
  loadingMore,
  ready,
  waiting,
} from './Types';

class CommunitiesStore {
  @observable
  state: CommunitiesState = waiting();

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  loadingMore = (communitiesResource: CommunitiesResource) => {
    this.state = loadingMore(communitiesResource);
  };

  @action
  ready = (communitiesResource: CommunitiesResource) => {
    this.state = ready(communitiesResource);
  };

  @action
  moreLoaded = (communitiesResource: CommunitiesResource) => {
    switch (this.state.kind) {
      case 'loading-more':
        const oldResource = this.state.communitiesResource.payload;
        const updatedResource = {
          ...communitiesResource,
          payload: oldResource.concat(communitiesResource.payload),
        };
        this.state = ready(updatedResource);
        break;
      case 'ready':
      case 'waiting':
      case 'error':
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'loading-more':
      case 'waiting':
        return nothing();
      default:
        return assertNever(this.state);
    }
  }

  @computed
  get communitiesResource(): Maybe<CommunitiesResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'loading-more':
        return just(this.state.communitiesResource);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }
}

export default CommunitiesStore;
