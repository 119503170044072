import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SelectFormFieldOptionImage } from '../../../../Types';
import * as style from './style.module.css';

interface Props {
  image: Maybe<SelectFormFieldOptionImage>;
}

const ProfileImage: React.FC<Props> = ({ image }) =>
  image
    .map((i) => <div className={style.avatar} style={{ backgroundImage: `url(${i.src})` }} />)
    .getOrElse(() => <></>);

export default observer(ProfileImage);
