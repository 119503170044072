import { observer } from 'mobx-react';
import { NonEmptyList, fromArrayMaybe } from 'nonempty-list';
import { mapMaybe } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import OnDemandAction from './OnDemandAction';
import { scheduledAvailability } from '../../../../Common/Experience/Availability';
import { AvailabilityResource, OfferingType } from '../../../../Common/Experience/Types';
import ScheduledAction from '../ScheduledAction';

interface Props {
  availabilities: NonEmptyList<AvailabilityResource>;
  offeringType: OfferingType;
  onClick: () => void;
}

function GetStartedButton({ availabilities, offeringType, onClick }: Props) {
  const collection = mapMaybe(scheduledAvailability, availabilities.toArray());

  return fromArrayMaybe(collection)
    .map((availabilities) => <ScheduledAction availabilities={availabilities} />)
    .getOrElse(() => {
      switch (offeringType) {
        case 'aep':
        case 'epc':
        case 'msuite':
          return <OnDemandAction value="Get Started" onClick={onClick} />;
        case 'coaching':
        case 'group-coaching':
          return emptyFragment();
        case 'program-sequence':
          return (
            <OnDemandAction
              value="Enroll Now"
              color="white"
              className="!mt-0 bg-white text-black"
              onClick={onClick}
            />
          );
      }
    });
}

export default observer(GetStartedButton);
