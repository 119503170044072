import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  Skeleton,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

interface Props {
  className?: string;
}

function SkeletonTileViewProgramExperience({ className }: Props) {
  return (
    <Card
      className={cn(
        'box-border flex-grow rounded',
        'bg-white text-gray-800 shadow-card',
        'min-h-[214px] w-full',
        'sm:max-w-full xl:min-w-[17rem] xl:max-w-[26.625rem]',
        className,
      )}
    >
      <CardHeader className="h-6">
        <Skeleton className="w-8/12" size="md" />
      </CardHeader>
      <CardBody className="gap-6 px-4 py-2.5">
        <div className="flex flex-col gap-2">
          <Skeleton size="md" />
          <Skeleton className="w-6/12" size="md" />
        </div>
        <div className="flex gap-2">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="w-4/12" />
          ))}
        </div>
        <div className="flex gap-2">
          <Skeleton className="w-6/12" variant="rectangle" />
          <Skeleton className="w-6/12" variant="rectangle" />
        </div>
      </CardBody>
      <CardFooter className="px-4 py-3">
        <Skeleton className="w-5/12" variant="rectangle" />
      </CardFooter>
    </Card>
  );
}

export default observer(SkeletonTileViewProgramExperience);
