import { warn } from '@execonline-inc/logging';
import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever, noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { currentUserStore } from '../../../../../CurrentUser/Store';
import { MissingLinkError, findLinkT } from '../../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import RegisterResourceStore from '../../RegisterResourceStore';
import { RegisterResource } from '../../RegisterResourceStore/Types';
import EnrollmentStore from '../Store';
import { State } from '../Store/Types';
import {
  createPaymentIntent,
  enrollInExperience,
  setWaitingState,
} from './StateTransitionHandling';

interface Props extends RCProps<EnrollmentStore> {
  registerResourceStore: RegisterResourceStore;
  registerResource: RegisterResource;
}

interface MissingGuidError {
  kind: 'missing-guid';
}

const loadRegisterResourceStore = (
  registerResource: RegisterResource,
  registerResourceStore: RegisterResourceStore,
) =>
  Task.succeed<MissingLinkError | MissingGuidError, {}>({})
    .andThen(() => findLinkT('enroll', registerResource.links))
    .andThen(() => findLinkT('direct-enrollment-discovery-portal', registerResource.links))
    .andThen(() => toTask({ kind: 'missing-guid' }, registerResource.payload.guid))
    .fork(noop, registerResourceStore.loading);

class EnrollmentReactions extends ReactionComponent<EnrollmentStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store, registerResourceStore, registerResource } = this.props;
    switch (state.kind) {
      case 'waiting':
        break;
      case 'setting-up':
        setWaitingState(state.useCase, store);
        break;
      case 'waiting-commerce':
        break;
      case 'initializing-payment':
        createPaymentIntent(state, store);
        break;
      case 'payment-loading-error':
      case 'ready-for-payment':
        break;
      case 'creating':
        enrollInExperience(state, store);
        break;
      case 'created':
        currentUserStore.refreshing();
        store.exiting(state.enrollmentResource);
        break;
      case 'exiting':
        // Exit procedure is handled by the `Exit` component.
        loadRegisterResourceStore(registerResource, registerResourceStore);
        break;
      case 'server-error':
      case 'error':
        warn('Unable to fulfill enrollment request', state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(EnrollmentReactions);
