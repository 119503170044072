import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const ToggleOpenIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 32 32">
      <path
        fill="#3d404c"
        d="m19.031 4.281-11 11-.687.719.687.719 11 11 1.438-1.438L10.187 16 20.47 5.719Z"
      />
    </SVGIcon>
  );
};

export default observer(ToggleOpenIcon);
