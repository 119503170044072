import { AppyError } from '../Appy';
import { MissingLinkError } from '../LinkyLinky';
import { RootResourceError } from '../RootResourceStore/Types';
import { TPlainTextKey } from '../Translations';

import { SSO } from '../SingleSignOn';

export interface LoginError {
  kind: 'login-error';
  message: TPlainTextKey;
  sourceError: SourceLoginError;
}

interface MissingUsernameError {
  kind: 'missing-username-error';
}

interface MissingPasswordError {
  kind: 'missing-password-error';
}

interface PasswordSubmissionFailure {
  kind: 'password-submission-failure';
  error: RootResourceError;
}

interface AssertionBackendIdentificationFailure {
  kind: 'assertion-backend-identification-failure';
  error: RootResourceError;
}

interface MissingSsoLink {
  kind: 'missing-sso-link';
  error: MissingLinkError;
}

export interface SsoFlowFailure {
  kind: 'sso-flow-failure';
  error: SSO.Error;
}

export type PasswordSubmissionError = MissingLinkError | AppyError;

export type SourceLoginError =
  | MissingUsernameError
  | MissingPasswordError
  | PasswordSubmissionFailure
  | AssertionBackendIdentificationFailure
  | MissingSsoLink
  | SsoFlowFailure;

export const loginError = ({ message, sourceError }: Omit<LoginError, 'kind'>): LoginError => ({
  kind: 'login-error',
  message,
  sourceError,
});

export const missingUsernameError = (): MissingUsernameError => ({
  kind: 'missing-username-error',
});

export const missingPasswordError = (): MissingPasswordError => ({
  kind: 'missing-password-error',
});

export const passwordSubmissionFailure = (error: RootResourceError): PasswordSubmissionFailure => ({
  kind: 'password-submission-failure',
  error,
});

export const assertionBackendIdentificationFailure = (
  error: RootResourceError
): AssertionBackendIdentificationFailure => ({
  kind: 'assertion-backend-identification-failure',
  error,
});

export const missingSsoLink = (error: MissingLinkError): MissingSsoLink => ({
  kind: 'missing-sso-link',
  error,
});

export const ssoFlowFailure = (error: SsoFlowFailure['error']): SsoFlowFailure => ({
  kind: 'sso-flow-failure',
  error,
});
