import { LightnessTheme } from '../../Themes/ThemeContext';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl';

export const modalBodySizeMapping: Record<ModalSize, string> = {
  sm: 'max-w-[300px]',
  md: 'max-w-[600px]',
  lg: 'max-w-[900px]',
  xl: 'max-w-[1280px]',
};

export const modalBodyClassNames: Record<LightnessTheme['kind'], string> = {
  light: 'bg-white border border-solid border-gray-400-old',
};
