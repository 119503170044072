import { CloseIcon, IconButton, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';

interface Props {
  onClose: () => void;
}

function SidebarHeader({ onClose }: Props) {
  const { translate } = useTranslationsContext();

  return (
    <>
      <Typography fontWeight="medium" as="h5">
        <T kind="Filters" />
      </Typography>
      <IconButton
        aria-label={translate('Close')}
        className="absolute right-6 top-1/2 -translate-y-1/2"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
}

export default observer(SidebarHeader);
