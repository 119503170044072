import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import { ProgramResource } from '../../../../../ProgramsStore/Types';
import { NotTranslated } from '../../../../../Translations';
import ProgramNotReady from '../../../ProgramNotReady';
import ProgramOverviewFaker from './ProgramOverviewFaker';
import ProgramOverviewWrapper from './ProgramOverviewWrapper';

interface Props {
  programResource: ProgramResource;
  store: ProgramStore;
}

const ProgramOverviewRenderer: React.FC<Props> = ({ programResource, store }) => {
  switch (store.state.kind) {
    case 'reloading':
    case 'ready':
      return (
        <ProgramOverviewWrapper
          programDetailResource={store.state.programDetailResource}
          store={store}
        />
      );
    case 'course-not-ready':
      return <ProgramNotReady programResource={programResource} />;
    case 'waiting':
    case 'loading':
      return <ProgramOverviewFaker />;
    case 'cancelling-registration':
    case 'registration-cancelled':
      return <></>;
    case 'error':
      return (
        <div>
          <NotTranslated text={store.state.message} />
        </div>
      );
  }
};

export default observer(ProgramOverviewRenderer);
