import { observer } from 'mobx-react';
import * as React from 'react';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { T } from '../../../Translations';
import InputWithLabel from '../../Form/InputWithLabel';
import LanguageSwitcher from './LanguageSwitcher';
import SalutationSelect from './SalutationSelect';
import { setValue } from './SetValueHelper';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
}

const Personal: React.FC<Props> = ({ profileStore }) => (
  <fieldset className={style.fieldset} data-test-personal={true}>
    <h2 className={style.fieldHeader} data-test-personal-header="Personal">
      <T kind="Personal" />
    </h2>
    <div className={style.inputSet}>
      <div className={'mb-5 mr-7'}>
        <SalutationSelect profileStore={profileStore} />
      </div>
      <div className={style.labelAndInput}>
        <InputWithLabel
          labelClassName={style.label}
          className={style.input}
          labelText="First Name"
          type="text"
          onChange={setValue(profileStore.setFirstName)}
          value={profileStore.firstName.getOrElseValue('')}
          name="first_name"
          id="first_name"
          required={true}
        />
      </div>
      <div className={style.labelAndInput}>
        <InputWithLabel
          labelClassName={style.label}
          className={style.input}
          labelText="Last Name"
          type="text"
          onChange={setValue(profileStore.setLastName)}
          value={profileStore.lastName.getOrElseValue('')}
          name="last_name"
          id="last_name"
          required={true}
        />
      </div>
    </div>
    <div className={style.inputSet}>
      <div className={style.labelAndInput}>
        <InputWithLabel
          labelClassName={style.label}
          className={`${style.input} ${style.phone}`}
          labelText="Mobile Phone"
          type="text"
          onChange={setValue(profileStore.setCellPhone)}
          value={profileStore.cellPhone.getOrElseValue('')}
          name="mobile_phone"
          id="mobile_phone"
        />
      </div>
      <div className={style.labelAndInput}>
        <div className={`${style.inputSet} ${style.inputSetMobile}`}>
          <div className={style.workPhone}>
            <InputWithLabel
              labelClassName={style.label}
              className={style.input}
              labelText="Work Phone"
              type="text"
              onChange={setValue(profileStore.setWorkNumber)}
              value={profileStore.workPhone.getOrElseValue('')}
              name="work_phone"
              id="work_phone"
            />
          </div>
          <div className={style.extension}>
            <InputWithLabel
              labelClassName={style.label}
              labelText="Extension"
              type="text"
              onChange={setValue(profileStore.setExtension)}
              value={profileStore.extension.getOrElseValue('')}
              hint="Ex: 123"
              name="extension"
              id="extension"
            />
          </div>
        </div>
      </div>
    </div>
    <LanguageSwitcher profileStore={profileStore} />
  </fieldset>
);

export default observer(Personal);
