import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { succeed } from 'jsonous';
import { Task } from 'taskarian';
import { callApi } from '../../Appy';
import { dashboardUrl } from '../../ClientRoutes';
import { CurrentUserResource } from '../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../../ErrorActionableReaction';
import { warnAndNotify } from '../../Honeybadger';
import { findLink, findLinkT, findSelfLink } from '../../LinkyLinky';
import { logout } from '../../Logout';
import { nav } from '../../Navigation';
import ProgramStore from '../../ProgramStore';
import { ProgramError, State, getProgram } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';

export interface Props extends EAProps<ProgramStore> {
  store: ProgramStore;
  currentUserResource: CurrentUserResource;
}

const handleBadStatus = (store: ProgramStore, error: BadStatus) => {
  switch (error.response.status) {
    case 404:
      // We really shouldn't have students with no course maps, so we will alert if this happens.
      warnAndNotify('Missing Course Map', "Expected a course map but didn't find one", {
        programUrl: findSelfLink(store.programLinks)
          .map((l) => l.href)
          .getOrElseValue('not found'),
      });
      store.courseNotReady();
      break;
    default:
      handleError(store, error);
  }
};

const handleProgramError = (store: ProgramStore) => (error: ProgramError) => {
  switch (error.kind) {
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    case 'missing-link-error':
      nav(dashboardUrl);
      break;
    default:
      handleError(store, error);
  }
};

class ProgramReactions extends ErrorActionableReaction<ProgramStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    switch (state.kind) {
      case 'reloading':
      case 'loading':
        Task.succeed<ProgramError, Link[]>(this.props.store.programLinks)
          .andThen(findLinkT('self'))
          .andThen(getProgram)
          .fork(handleProgramError(this.props.store), this.props.store.ready);
        break;
      case 'cancelling-registration':
        callApi(
          succeed({}),
          {},
        )(state.cancelLink).fork(
          handleProgramError(this.props.store),
          this.props.store.registrationCancelled,
        );
        break;
      case 'registration-cancelled':
        // Logout if the user doesn't have access to the dashboard. Otherwise,
        // the page redirects them to the dashboard.
        findLink('dashboard', this.props.currentUserResource.links).elseDo(() =>
          logout('Registration Cancelled'),
        );
        break;
      case 'error':
      case 'ready':
      case 'waiting':
      case 'course-not-ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramReactions;
