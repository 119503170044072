import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import LearningPartnersRenderer from '../LearningPartnersSummary/LearningPartnersRenderer';
import TeamsRenderer from '../TeamsSummary/TeamsRenderer';

interface Props {
  programDetailResource: ProgramDetailResource;
}

const PresenceProgram: React.FC<Props> = ({ programDetailResource }) => (
  <>
    <TeamsRenderer programDetailResource={programDetailResource} />
    <LearningPartnersRenderer programDetailResource={programDetailResource} />
  </>
);

export default observer(PresenceProgram);
