import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { HtmlNode } from '../../../SegmentStore/Types';
import ContentEmbed from '../../../components/ContentEmbed';
import { nodeDecoder } from '../../../components/ContentEmbed/Types';
import { AnnouncementResource } from '../../Decoders/Types';
import * as style from '../../style.module.css';
import { embeddable, whenAnnouncementEmbeddableNode } from './Types';

interface Props {
  announcement: AnnouncementResource;
}

const transform = (announcement: AnnouncementResource) => (
  htmlNode: HtmlNode,
  key: number
): React.ReactNode =>
  nodeDecoder('announcement')
    .decodeAny(htmlNode)
    .andThen(whenAnnouncementEmbeddableNode)
    .andThen((node) => embeddable(node, announcement))
    .cata({
      Err: () => undefined,
      Ok: (embeddable) => <ContentEmbed key={key} embeddable={embeddable} />,
    });

const Content: React.FC<Props> = ({ announcement }) => {
  return (
    <div data-test-announcement-content className={style.content}>
      {announcement.payload.descriptionHtml
        .map((html) => ReactHtmlParser(html, { transform: transform(announcement) }))
        .getOrElseValue(<span>{announcement.payload.description}</span>)}
    </div>
  );
};

export default observer(Content);
