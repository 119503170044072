import { observer } from 'mobx-react';
import * as React from 'react';
import DynamicHeading from '../../Accessibility/DynamicHeading';
import { CalendarWeek } from '../../Calendaring';
import { L, localizer, TranslationsContext } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  week: CalendarWeek;
  subHeadingLevel: 3 | null;
}

const Weekdays: React.FC<Props> = ({ week, subHeadingLevel }) => (
  <div className={style.dayNames} data-test-weekdays={true}>
    <ol>
      <TranslationsContext.Consumer>
        {(ts) =>
          week.map((day) => (
            <li key={day.date.valueOf()} data-test-weekday={day.date.getDay()}>
              <DynamicHeading level={subHeadingLevel} className={style.weekdays}>
                <abbr
                  className={style.abbreviation}
                  data-test-weekday-narrow={true}
                  aria-label={localizer(day.date, 'long-weekday')(ts)}
                  role="text"
                >
                  <L localizeable={day.date} format={'narrow-weekday'} />
                </abbr>
                <span className={style.fullName} data-test-weekday-long={true}>
                  <L localizeable={day.date} format={'long-weekday'} />
                </span>
              </DynamicHeading>
            </li>
          ))
        }
      </TranslationsContext.Consumer>
    </ol>
  </div>
);

export default observer(Weekdays);
