import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachProfileResource } from '../../../../EmbeddedFormFieldAsset/Types';
import CoachLabelName from './CoachLabelName';
import * as style from './style.module.css';
import { findLink } from '../../../../../LinkyLinky';

interface Props {
  coachProfile: CoachProfileResource;
}

const CoachLabel: React.FC<Props> = ({ coachProfile, children }) =>
  findLink('avatar', coachProfile.links)
    .map((avatar) => (
      <div className={style.coachLabel}>
        <div className={style.coachAvatar} style={{ backgroundImage: `url(${avatar.href})` }} />
        <CoachLabelName coachName={coachProfile.payload.name} children={children} />
      </div>
    ))
    .getOrElse(() => (
      <div className={style.coachLabel}>
        <CoachLabelName coachName={coachProfile.payload.name} children={children} />
      </div>
    ));

export default observer(CoachLabel);
