import { assertNever } from '@kofno/piper';
import BroadcastMessageStore from '.';
import { AppyError, postToApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { Link } from '../Resource/Types';
import { BroadcastMessageState } from './Types';

export interface Props extends EAProps<BroadcastMessageStore> {
  store: BroadcastMessageStore;
  broadcastLink: Link;
}

const saveMessage = (content: string, link: Link) => {
  return postToApi({
    broadcast_message: { content },
  })(link);
};

// Leaving room for more robust error handling later
const handleChatError = (store: BroadcastMessageStore) => (error: AppyError) => {
  handleError(store, error);
};

class BroadcastMessageReactions extends ErrorActionableReaction<
  BroadcastMessageStore,
  BroadcastMessageState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: BroadcastMessageState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'broadcasting':
        // When we get a message from pusher for this message
        // we decode it and add it
        store.reset();
        break;
      case 'ready':
        break;
      case 'waiting':
        store.ready();
        break;
      case 'updating':
        saveMessage(state.message, this.props.broadcastLink).fork(
          handleChatError(this.props.store),
          store.broadcasting
        );
        break;
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default BroadcastMessageReactions;
