import { Kettle } from 'kettle-corn';
import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { just, Maybe, nothing } from 'maybeasy';
import { VideoState } from 'kettle-corn/Kettle/VideoState';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import SurveyModalStore from './Modal/Store';
import { LaunchPosition, TopicViewStatus } from '../ReqHlsVideo/Types';

export interface Props extends RCProps<Kettle> {
  store: Kettle;
  surveyModalStore: SurveyModalStore;
  launchPosition: LaunchPosition;
}

const launchSurvey = (
  surveyModalStore: SurveyModalStore,
  topicViewStatus: Maybe<TopicViewStatus>
) => surveyModalStore.open(topicViewStatus);

const launchAtStartPosition =
  (surveyModalStore: SurveyModalStore, launchPosition: LaunchPosition) => () => {
    switch (launchPosition) {
      case 'launch-at-start':
        launchSurvey(surveyModalStore, nothing());
        break;
      case 'launch-at-end':
      case 'never-launch':
      case 'pause-launch':
        noop;
        break;
    }
  };

const launchAtEndPosition =
  (surveyModalStore: SurveyModalStore, launchPosition: LaunchPosition) => () => {
    switch (launchPosition) {
      case 'launch-at-end':
        launchSurvey(surveyModalStore, just('seen'));
        break;
      case 'launch-at-start':
      case 'never-launch':
      case 'pause-launch':
        noop;
        break;
    }
  };
class SurveyVideoReactions extends ReactionComponent<Kettle, VideoState, Props> {
  tester = () => this.props.store.videoState;

  effect = (state: VideoState) => {
    const { surveyModalStore, launchPosition } = this.props;

    state.cata({
      initialized: noop,
      ready: noop,
      playing: launchAtStartPosition(surveyModalStore, launchPosition),
      paused: noop,
      buffering: noop,
      ended: launchAtEndPosition(surveyModalStore, launchPosition),
    });
  };
}

export default observer(SurveyVideoReactions);
