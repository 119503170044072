import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated, T } from '../../Translations';
import CountryAndRegion from './CountryAndRegion';
import { LearningPartnersNode } from './Types';

interface Props {
  node: LearningPartnersNode;
}

const LearningPartnersDetails: React.FC<Props> = ({ node }) => (
  <tbody>
    {node.learningPartners.payload.map((learningPartner) => (
      <tr key={learningPartner.payload.id}>
        {learningPartner.payload.profile.payload.name
          .map((n) => (
            <td>
              <NotTranslated text={n} />
            </td>
          ))
          .getOrElseValue(<></>)}
        {learningPartner.payload.profile.payload.organization
          .map((org) => (
            <td>
              <NotTranslated text={org} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="None" />
            </td>
          )}
        {learningPartner.payload.profile.payload.division
          .map((div) => (
            <td>
              <NotTranslated text={div} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        {learningPartner.payload.profile.payload.position
          .map((pos) => (
            <td>
              <NotTranslated text={pos} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        {learningPartner.payload.profile.payload.country
          .map((country) => (
            <CountryAndRegion country={country.name} region={learningPartner.payload.profile.payload.region} />
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        {learningPartner.payload.profile.payload.timeZone
          .map((tz) => (
            <td>
              <NotTranslated text={tz.code} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        <td>
          <a href={`mailto: ${learningPartner.payload.email}`}>
            <NotTranslated text={learningPartner.payload.email} />
          </a>
        </td>
      </tr>
    ))}
  </tbody>
);

export default observer(LearningPartnersDetails);
