import { Maybe } from 'maybeasy';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { DisplayModule, ModuleOrder } from '../../DayModules';
import { T } from '../../Translations';
import ExpectedModuleStatement from './ExpectedModuleStatement';
import * as style from './style.module.css';

interface Props {
  modules: ModuleOrder;
}

const displayModules = (modules: ModuleOrder): Maybe<NonEmptyList<DisplayModule>> =>
  fromArrayMaybe(modules.filter((mod) => mod.kind === 'display') as DisplayModule[]);

const ModuleOnTrackStatus: React.FC<Props> = ({ modules }) =>
  displayModules(modules)
    .map((dms) => (
      <div className={style.moduleStatus} data-test-module-status="on-time">
        <h3 className={style.moduleStatusHeader}>
          <T kind="Stay On Track" />
        </h3>
        {dms
          .map((mod) => (
            <ExpectedModuleStatement
              key={mod.id}
              label={mod.programModule.label.getOrElseValue(mod.programModule.title.text)}
              date={mod.date}
            />
          ))
          .toArray()}
      </div>
    ))
    .getOrElse(() => <></>);

export default ModuleOnTrackStatus;
