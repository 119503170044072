import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../../Experience/Types';
import {
  GroupCoachingDetailsItems,
  GroupCoachingOverviewItems,
  LeadershipCoachingOverviewItems,
  ProgramOverviewItems,
} from '../../../../Experience/ExperienceBody';
import { getPrimaryCompetencies } from '../../../../Experience/ExperienceCompetencies';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return experienceResource.payload.productDetails
    .map((productDetails) => {
      {
        switch (productDetails.kind) {
          case 'group-coaching':
            return (
              <>
                <GroupCoachingOverviewItems productDetails={productDetails} />
                <GroupCoachingDetailsItems sessions={productDetails.groupCoachingSessions} />
              </>
            );
          case 'leadership-coaching':
            return <LeadershipCoachingOverviewItems />;
          case 'program-sequence': {
            const competencies = getPrimaryCompetencies(experienceResource);

            return (
              <ProgramOverviewItems
                competencies={competencies}
                experience={experienceResource.payload}
              />
            );
          }
        }
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
