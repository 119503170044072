import { emptyFragment } from '@execonline-inc/execonline-ui';
import { toTask, when } from '@execonline-inc/maybe-adapter';
import { noop } from '@kofno/piper';
import { Maybe, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Task } from 'taskarian';
import { ImageSize, ImageStore } from './ImageStore';

interface Props {
  src: string;
}

const getDesiredDimension = (screenWidth: number): Maybe<number> =>
  when(screenWidth >= 1300, screenWidth / 4)
    .orElse(() => when(isMediumScreen(screenWidth), screenWidth / 3))
    .orElse(() => just(screenWidth));

const isMediumScreen = (innerWidth: number): boolean => {
  const mediumScreenWidthLowerBound = 1140;
  const mediumScreenWidthUpperBound = 1300;
  return mediumScreenWidthLowerBound < innerWidth && innerWidth < mediumScreenWidthUpperBound;
};

const whenTallImageT = (image: HTMLImageElement): Task<string, ImageSize> =>
  toTask(
    'image-too-tall-error',
    when(image.height / window.innerHeight > 0.4, {
      width: image.width / (4 * (image.height / window.innerHeight)),
      height: image.height / (4 * (image.height / window.innerHeight)),
    }),
  );

const whenSmallImageT = (image: HTMLImageElement): Task<string, ImageSize> =>
  toTask(
    'image-too-small-error',
    when(window.innerWidth / image.width > 4, { width: image.width, height: image.height }),
  );

const whenLargerImageT = (image: HTMLImageElement): Task<string, ImageSize> =>
  toTask(
    'new-dimensions-error',
    just({})
      .assign('width', getDesiredDimension(window.innerWidth))
      .assign(
        'height',
        getDesiredDimension(window.innerWidth).andThen((convertedWidth) =>
          just(convertedWidth / (image.width / image.height)),
        ),
      ),
  );

const setImageSizeDepending = (image: HTMLImageElement, store: ImageStore) =>
  whenTallImageT(image)
    .orElse(() => whenSmallImageT(image))
    .orElse(() => whenLargerImageT(image))
    .fork(noop, ({ width, height }) => store.setImageSize(width, height));

const HtmlImageView: React.FC<Props> = ({ src }) => {
  const store = React.useMemo(() => new ImageStore(), [src]);

  React.useEffect(() => {
    setImageData(src);
  }, []);

  const setImageData = (src: string) => {
    const image = new Image();
    image.src = src;

    image.onload = () => setImageSizeDepending(image, store);

    image.onerror = () => store.setError();
  };

  const { state } = store;

  switch (state.kind) {
    case 'waiting':
    case 'error':
      return emptyFragment();
    case 'ready':
      return (
        <img
          src={src}
          style={{
            height: state.size.height,
            width: state.size.width,
          }}
        />
      );
  }
};

export default observer(HtmlImageView);
