import * as React from 'react';
import { CoachingEvent as TCoachingEvent } from '../../../EventsStore/Types';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';
import { observer } from 'mobx-react';

interface Props {
  event: TCoachingEvent;
}

const CoachingEvent: React.FC<Props> = ({ event: { title } }) => (
  <a href="#" className={style.liveEvent} data-test-live-event={title}>
    <span className={style.eventLabel} data-test-event-title={title}>
      <NotTranslated text={title} />
    </span>
  </a>
);

export default observer(CoachingEvent);