import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { ProgramSegment } from '../../../../ProgramStore/Types';
import Segment from './Segment/Segment';
import ScrollStore from '../Modules/Store';
import style from './style.module.css';

interface Props {
  segments: ProgramSegment[];
  isModuleOpened: boolean;
  scrollStore: ScrollStore;
}

const Segments: React.FC<Props> = ({ scrollStore, isModuleOpened, segments = [] }): JSX.Element => {
  return (
    <div className={clsx(style.segmentContainer, { [style.isModuleOpened]: isModuleOpened })}>
      {segments.map((segment) => (
        <Segment scrollStore={scrollStore} segment={segment} />
      ))}
    </div>
  );
};
export default observer(Segments);
