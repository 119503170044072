import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalBody, OpenableState } from '@execonline-inc/execonline-ui';
import WithPusherSettings from '../../../../../ToolingsStore/WithPusherSettings';
import Socket from '../../../../../Socket';
import { EnrollmentModalContext, EnrollmentModalContextState } from '../Common';
import { PaymentWizardReactions, PaymentWizardStore } from './Common';
import ExperiencePaymentWizard from './ExperiencePaymentWizard';

interface Props extends EnrollmentModalContextState {
  isOpen: OpenableState;
}

function ExperiencePaymentContent({
  isOpen,
  availability,
  invitationUuid,
  experienceResource,
  enrollmentStore,
  options,
  onClose,
  onOpen,
}: Props) {
  const paymentWizardStore = useMemo(() => {
    return new PaymentWizardStore();
  }, []);

  return (
    <EnrollmentModalContext.Provider
      value={{
        availability,
        invitationUuid,
        experienceResource,
        enrollmentStore,
        options,
        onClose,
        onOpen,
      }}
    >
      <Modal
        className="h-full rounded-none sm:h-auto"
        open={isOpen.kind === 'open'}
        size="4xl"
        onClose={onClose}
      >
        <ModalBody className="flex min-h-[35.875rem] flex-col p-0 sm:p-0 md:flex-row">
          <WithPusherSettings>
            {(pusherSettingsResource) => (
              <Socket pusherSettingsResource={pusherSettingsResource}>
                <ExperiencePaymentWizard store={paymentWizardStore} />
                <PaymentWizardReactions store={paymentWizardStore} fireImmediately={true} />
              </Socket>
            )}
          </WithPusherSettings>
        </ModalBody>
      </Modal>
    </EnrollmentModalContext.Provider>
  );
}

export default observer(ExperiencePaymentContent);
