import { observer } from 'mobx-react';
import * as React from 'react';
import { ok } from 'resulty';
import { calendarMonth, weekKey } from '../../Calendaring';
import CalendarStore from '../../CalendarStore';
import { CalendarResource } from '../../CalendarStore/Types';
import { monthModuleSchedule } from '../../DayModules';
import { NotTranslated, T, translation, TranslationsContext } from '../../Translations';
import Day from './Day';
import Header from './Header';
import * as style from './style.module.css';
import Weekdays from './Weekdays';

interface Props {
  calendarResource: CalendarResource;
  store: CalendarStore;
  headingLevel: Heading;
}

type Heading = 2 | 3;
type SubHeading = 3 | null;

const startOfWeek = 'monday';

const CalendarImpl: React.FC<Props> = ({ calendarResource, store, children, headingLevel }) => {
  const subHeadingLevel = (): SubHeading => {
    switch (headingLevel) {
      case 2:
        return 3;
      case 3:
        return null;
    }
  };

  const { firstDayOfMonth, activeProgramResources } = calendarResource.payload;
  const month = calendarMonth(startOfWeek, firstDayOfMonth);
  return month
    .map((cal) => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <div
            className={style.calendar}
            data-test-calendar={true}
            aria-label={translation('Calendar')(ts)}
          >
            <Header headingLevel={headingLevel} calendarResource={calendarResource} store={store} />

            <div className={style.dates} data-test-calendar-body={true}>
              <Weekdays week={cal.weeks.first} subHeadingLevel={subHeadingLevel()} />

              <div className={style.weeks} data-test-calendar-weeks={true}>
                {cal.weeks
                  .map((week) => (
                    <ol key={weekKey(week)} data-test-calendar-week={weekKey(week)}>
                      {week.map((day) => (
                        <Day
                          key={day.date.getTime()}
                          day={day}
                          store={store}
                          moduleSchedule={monthModuleSchedule(activeProgramResources, cal)}
                        />
                      ))}
                    </ol>
                  ))
                  .toArray()}
              </div>
            </div>
            {children}
          </div>
        )}
      </TranslationsContext.Consumer>
    ))
    .orElse((err) =>
      ok(
        <div>
          <T kind="Developer error: <message/>" message={<NotTranslated text={err.message} />} />
        </div>
      )
    )
    .getOrElseValue(<></>);
};

export default observer(CalendarImpl);
