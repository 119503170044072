import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const ProcessingIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 34 34">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        d="M17 0c-1.699 0-3.398.282-4.958.708L14.609 3.1c.853-.144 1.539-.266 2.391-.266 4.001 0 7.537 1.677 10.094 4.25h-4.427v2.834h8.5v-8.5h-2.834v2.922A16.843 16.843 0 0 0 17 0ZM1.417 2.833v2.834h3.01C1.727 8.666 0 12.595 0 17c0 1.56.293 3.099.576 4.516l2.39-2.391c-.144-.708-.133-1.417-.133-2.125 0-3.99 1.672-7.482 4.25-10.094v4.427h2.834v-8.5h-8.5Zm31.875 9.651-2.258 2.524c.144.708.133 1.284.133 1.992 0 3.984-1.638 7.515-4.25 10.094v-4.427h-2.834v8.5h8.5v-2.834h-2.877C32.417 25.312 34 21.355 34 17c0-1.56-.282-3.099-.708-4.516Zm-30.459 11.6v8.5h2.834v-3.011C8.677 32.307 12.617 34 17 34c1.417 0 2.844-.15 4.117-.576l-2.7-2.257H17c-4.001 0-7.537-1.677-10.094-4.25h4.427v-2.834h-8.5Z"
      />
    </SVGIcon>
  );
};

export default observer(ProcessingIcon);
