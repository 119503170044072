import { assertNever } from '@kofno/piper';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { State } from '../../States';
import { CurrentUserStore } from '../../Store';
import { Props as ComponentProps, contingencyState } from './Types';

interface Props extends RCProps<CurrentUserStore>, ComponentProps {}

export class WhenUserDataLoadedReactions extends ReactionComponent<CurrentUserStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    switch (state.kind) {
      case 'ready':
        handleLoadingUnresolvedContingencies(this.props);
        break;
      case 'refreshing':
        handleLoadingResolvedContingencies(this.props);
        handleLoadingUnresolvedContingencies(this.props);
        break;
      case 'anonymous':
      case 'errored':
      case 'loading':
      case 'logging-out':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

const handleLoadingUnresolvedContingencies = ({
  contingencies,
  currentUserResource,
}: Props): void =>
  contingencies
    .filter((store) => contingencyState(store) === 'unresolved')
    .forEach((store) => store.loading(currentUserResource));

const handleLoadingResolvedContingencies = ({ contingencies, currentUserResource }: Props): void =>
  contingencies
    .filter((store) => contingencyState(store) === 'resolved')
    .forEach((store) => store.refreshing(currentUserResource));
