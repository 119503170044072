import { always } from '@kofno/piper';
import 'intersection-observer';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { InView } from 'react-intersection-observer';
import ChatReplyStore from '../../../ChatReplyStore';
import {
  ChatMessage,
  ChatMessageReply,
  ChatMessageReplyResource,
  ChatMessageResource,
} from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { findLink } from '../../../LinkyLinky';
import { PersonStore } from '../../../Person';
import { L, NotTranslated, T } from '../../../Translations';
import ChatPerson from '../../ChatMessage/ChatPerson';
import FlashMessage from '../../FlashMessage';
import LinkifyWithCharEntities from '../../LinkifyWithCharEntities';
import ActionMenu from './ActionMenu';
import ActiveMessageReactionList from './ActiveMessageReactionList';
import MessageReactionReactions from './MessageReactionReactions';
import MessageReactionStore from './MessageReactionStore';
import * as style from './style.module.css';

interface Props {
  personStore: PersonStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  message: ChatMessage | ChatMessageReply;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
  chatReplyStore: ChatReplyStore;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
  currentUserResource: CurrentUserResource;
}

class MessageContent extends React.Component<Props> {
  reactionStore = new MessageReactionStore(
    this.props.messageResource.payload.reactions.links,
    this.props.currentUserResource
  );

  updateLastReadMessageId = (inView: Boolean) => {
    if (inView) {
      this.props.lastReadMessageStore.map((lastReadMessageStore) => {
        lastReadMessageStore.updateLastReadMessage(this.props.messageResource);
      });
    }
  };

  render() {
    const { personStore, presenceStore, message, messageResource, chatReplyStore } = this.props;
    return (
      <InView onChange={(inView) => this.updateLastReadMessageId(inView)} threshold={0.8}>
        <div
          className={findLink('coach', messageResource.embedded.author.links)
            .map(always(style.coachChat))
            .getOrElseValue(style.chat)}
        >
          <div className={style.person}>
            <div className={style.avatar}>
              <ChatPerson personStore={personStore} presenceStore={presenceStore} />
            </div>
          </div>
          <div className={style.chatContent}>
            <div className={style.chatInfo}>
              {personStore.name
                .map((name) => (
                  <div className={style.name}>
                    <NotTranslated text={name} />
                    {findLink('coach', messageResource.embedded.author.links)
                      .map(() => (
                        <span className={style.coachIdentifier}>
                          {' - '}
                          <T kind="ExecOnline Coach" />
                        </span>
                      ))
                      .getOrElse(() => (
                        <></>
                      ))}
                  </div>
                ))
                .getOrElse(() => (
                  <></>
                ))}
              <div className={style.timestamp}>
                <L localizeable={message.createdAt} format="time-of-day" />
              </div>
            </div>
            {this.reactionStore.notification
              .map((alert) => <FlashMessage alert={alert} />)
              .getOrElse(() => (
                <></>
              ))}

            <div className={style.message}>
              <div className={style.content}>
                <LinkifyWithCharEntities content={message.content} />
              </div>
              <ActiveMessageReactionList
                reactionsResource={message.reactions}
                reactionStore={this.reactionStore}
              />
            </div>
          </div>
          <ActionMenu
            reactionStore={this.reactionStore}
            messageResource={messageResource}
            chatReplyStore={chatReplyStore}
          />
          <MessageReactionReactions
            store={this.reactionStore}
            currentUserResource={this.props.currentUserResource}
          />
        </div>
      </InView>
    );
  }
}

export default observer(MessageContent);
