import { Maybe } from 'maybeasy';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  name: Maybe<string>;
}

const Name: React.FC<Props> = ({ name }) => (
  <p className={style.name} data-test-popout-name={true}>
    <NotTranslated text={name.getOrElseValue('')} />
  </p>
);
export default Name;
