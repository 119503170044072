import { observer } from 'mobx-react';
import EventsStore from '../../EventsStore';
import UpdateEventsState from './UpdateEventsState';
import * as style from './style.module.css';

interface Props {
  eventsStore: EventsStore;
}

function CurrentEvents({ eventsStore }: Props) {
  return (
    <div className={style.eventListing} data-test-current-events={true}>
      <UpdateEventsState state={eventsStore.state} />
    </div>
  );
}

export default observer(CurrentEvents);
