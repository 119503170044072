import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import FreeBusyTestStore from '../../FreeBusyTestStore';
import Confirmation from './Confirmation';
import Schedule from './Schedule';

interface Props {
  store: FreeBusyTestStore;
}

function FreeBusyTestView({ store }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'error':
      return emptyFragment();
    case 'loading':
    case 'initializing':
    case 'ready':
      return <Schedule />;
    case 'submitted':
      return <Confirmation />;
  }
}

export default observer(FreeBusyTestView);
