import { observer } from 'mobx-react';
import * as React from 'react';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { Link } from '../../../Resource/Types';
import PhotoDisplay from './PhotoDisplay';
import PhotoUpload from './PhotoUpload';

interface Props {
  profileStore: ProfileFormStore;
  fileUploadStore: FileUploadStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
}

class AvatarUploadSection extends React.Component<Props> {
  componentDidMount() {
    this.props.fileUploadStore.ready();
  }

  render() {
    const { profileStore, fileUploadStore, countryRegionOptionsStore } = this.props;

    return profileStore.photo
      .map((avatarLink: Link) => (
        <PhotoDisplay
          avatarUrl={avatarLink.href}
          fileUploadStore={fileUploadStore}
          store={profileStore}
          countryRegionOptionsStore={countryRegionOptionsStore}
        />
      ))
      .getOrElse(() => (
        <PhotoUpload
          fileUploadStore={fileUploadStore}
          store={profileStore}
          countryRegionOptionsStore={countryRegionOptionsStore}
        />
      ));
  }
}

export default observer(AvatarUploadSection);
