import { createInactivityTracker } from '@execonline-inc/inactivity-tracking.private';
import { saveApplicationLocation } from '@execonline-inc/navigation.private';
import history from '../History';
import { logout } from '../Logout';
import { MessageBus } from '../MessageBus';

export const InactivityLogoutTracker = createInactivityTracker({
  name: 'user-activity',
  onTimeout: () => {
    const onFork = () => logout('Your session has expired, please log in again.');
    saveApplicationLocation(history.location).fork(onFork, onFork);
  },
  onLocalActivity: (event, expiration) => {
    MessageBus.broadcast({ kind: 'user-activity', event, expiration: expiration.valueOf() });
  },
});
