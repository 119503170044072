import * as React from 'react';
import Loader from '../Loader';
import * as style from './style.module.css';

interface Props {}

const SmallContentFaker: React.FC<Props> = () => (
  <div className={style.smallFakeContainer}>
    <Loader />
  </div>
);

export default SmallContentFaker;
