import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useLinkableContext } from '../../../../../../components/Linkable/hooks';
import { LinkableDiscoveryPortalWizardScopeKind } from '../../../../../../components/Linkable/Types';
import { getFirstLinkableDiscoveryPortalWizard } from './Functions';
import UpdateFocusButtonView from './UpdateFocusButtonView';

interface Props {
  productCollectionId: number;
  scopeType: LinkableDiscoveryPortalWizardScopeKind;
}

function UpdateFocusButton({ productCollectionId, scopeType }: Props) {
  const linkables = useLinkableContext();

  return linkables
    .andThen(getFirstLinkableDiscoveryPortalWizard)
    .map((linkable) => (
      <UpdateFocusButtonView
        linkable={linkable}
        productCollectionId={productCollectionId}
        scopeType={scopeType}
      />
    ))
    .getOrElse(emptyFragment);
}

export default observer(UpdateFocusButton);
