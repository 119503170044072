import { observer } from 'mobx-react';
import * as React from 'react';
import {
  LearningPartnerResource,
  LearningPartnersResource,
} from '../../../../LearningPartnersStore/Types';
import * as style from '../../style.module.css';
import LearningPartner from './LearningPartner';

interface Props {
  learningPartnersResource: LearningPartnersResource;
}

const LearningPartnerBody: React.FC<Props> = ({ learningPartnersResource }) => {
  return (
    <dd className={style.learningPartners}>
      {learningPartnersResource.payload.map((learningPartnerResource: LearningPartnerResource) => (
        <LearningPartner
          key={learningPartnerResource.payload.id}
          learningPartnerResource={learningPartnerResource}
        />
      ))}
    </dd>
  );
};

export default observer(LearningPartnerBody);
