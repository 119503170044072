import React, { useRef } from 'react';
import ArrowRightIcon from '../../../Cutesies/ArrowRightIcon';
import { observer } from 'mobx-react';
import { AlreadyTranslated } from '../../../../Translations';
import Segments from '../Segments';
import { ActiveProgramModule, ProgramSegment } from '../../../../ProgramStore/Types';
import { when } from '@execonline-inc/maybe-adapter';
import clsx from 'clsx';
import ScrollStore from '../Modules/Store';
import ModuleToggleStore from './Store';
import style from './style.module.css';

const moduleMinWidth = '50%';
const moduleMargin = '25px';

const getModuleWidth = (segments: ProgramSegment[]): string => {
  return when(segments.length > 3, {})
    .map(() => `${segments.length * 10}%`)
    .getOrElseValue(`calc(${moduleMinWidth} - ${moduleMargin})`);
};

interface Props {
  sequenceModule: ActiveProgramModule;
  segments: ProgramSegment[];
  scrollStore: ScrollStore;
}

const Module: React.FC<Props> = ({ scrollStore, sequenceModule, segments }) => {
  const store = useRef(new ModuleToggleStore());

  return (
    <div
      className={style.moduleWrapper}
      key={sequenceModule.id}
      style={{ width: getModuleWidth(segments) }}
    >
      <div
        className={clsx(style.step, { [style.current]: sequenceModule.isCurrentModule })}
        onClick={store.current.toggle}
      >
        <div className={clsx(style.arrowBtn, { [style.isOpened]: store.current.isOpen })}>
          <ArrowRightIcon color="text-black-color" />
        </div>
        <p className={style.moduleTitle}>
          <AlreadyTranslated content={sequenceModule.title} />
        </p>
      </div>
      <Segments
        scrollStore={scrollStore}
        isModuleOpened={store.current.isOpen}
        segments={segments}
      />
    </div>
  );
};

export default observer(Module);
