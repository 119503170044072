import { observer } from 'mobx-react';
import * as React from 'react';
import ProfessorsStore from '../../ProfessorsStore';
import ProfessorsReactions from '../../ProfessorsStore/ProfessorsReactions';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import Professors from './Professors';

interface Props {
  programDetailResource: ProgramDetailResource;
}

@observer
class ProfessorsSummary extends React.Component<Props, {}> {
  private professorsStore: ProfessorsStore;

  constructor(props: Props) {
    super(props);
    this.professorsStore = new ProfessorsStore();
  }

  componentDidMount() {
    this.professorsStore.loading(this.props.programDetailResource);
  }

  render() {
    return (
      <>
        <Professors professorsStore={this.professorsStore} />
        <ProfessorsReactions store={this.professorsStore} />
      </>
    );
  }
}

export default ProfessorsSummary;
