import { observer } from 'mobx-react';
import * as React from 'react';
import { Program } from '../../../../../ProgramStore/Types';
import { AlreadyTranslated } from '../../../../../Translations';

interface Props {
  programDetail: Program;
}

const CohortProgramLabel: React.FC<Props> = ({ programDetail }) => {
  switch (programDetail.kind) {
    case 'active':
    case 'completed':
      return (
        <strong>
          <AlreadyTranslated
            content={programDetail.currentChapterName.getOrElseValue(programDetail.title)}
          />
        </strong>
      );
    case 'upcoming':
    case 'expired':
    case 'inactive':
      return <></>;
  }
};
export default observer(CohortProgramLabel);
