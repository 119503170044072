import { observer } from 'mobx-react';
import { ComponentProps } from 'react';

type AnchorProps = Omit<ComponentProps<'a'>, 'target' | 'rel'>;

function Anchor(props: AnchorProps) {
  return <a {...props} target="_blank" rel="noopener noreferrer" />;
}

export default observer(Anchor);
