import { observer } from 'mobx-react';
import { SvgIcon } from '@execonline-inc/execonline-ui';

function AmericanExpressCardIcon() {
  return (
    <SvgIcon>
      <path
        fill="#016DD1"
        d="M1.93 4.12c0-1.25 1-2.26 2.24-2.26h15.66c1.24 0 2.24 1.01 2.24 2.26v1.4l-.24.28.24.29v5.63l-.2.28.2.28-.17.42.17.36v4.05l-.18.36.18.44-.23.3.23.27v1.12c0 1.25-1 2.54-2.24 2.54H4.17c-1.24 0-2.24-1.01-2.24-2.26V4.12zm0 0"
      />
      <path
        fill="#016DD1"
        d="M18.17 9.87 16.5 6.09h-2.16v5.42l-2.4-5.42H10.1l-2.45 5.63H9.1l.54-1.26h2.75l.54 1.26h2.78V7.53l1.84 4.19h1.25L20.7 7.6v4.1h1.38V6.1h-2.2l-1.7 3.78zm-8.01-.62.82-1.97.85 1.97h-1.67zM22.07 12.28h-2.02l-1.85 1.8-1.84-1.8H9.33v5.63h7.03l1.84-1.87 1.86 1.87h2l-2.82-2.9 2.83-2.73zm-7.7 5.62v-1.17H10.7v-1.11h3.47v-1.07h-3.47V13.5h3.65v-1.2l2.8 2.73-2.8 2.87zm0 0"
      />
      <path
        fill="#FFF"
        d="M22.07 17.91v-.8l-2.04-2.09 2.04-1.96v-.78l-2.83 2.73 2.83 2.9zM14.36 13.5h-3.65v1.05h3.47v1.07h-3.47v1.1h3.65v1.18l2.8-2.87-2.8-2.74v1.21zM11.83 9.25l-.85-1.97-.82 1.97h1.67zm0 0"
      />
      <path
        fill="#FFF"
        d="m20.06 17.91-1.86-1.87-1.84 1.87H9.33v-5.63h7.03l1.84 1.8 1.85-1.8h2.02v-.56h-1.38V7.6l-1.89 4.1h-1.25l-1.84-4.18v4.19h-2.78l-.54-1.26H9.64l-.54 1.26H7.65l2.45-5.63h1.83l2.4 5.42V6.1h2.16l1.68 3.78 1.7-3.78h2.2v-.57H19.5l-.15.33-1.18 2.64L17 5.86l-.15-.34h-3.08v3.35l-1.33-3.01-.15-.34H9.74l-.15.34-2.46 5.63-.34.8h1.98v6.19h7.82l.16-.17 1.45-1.47 1.46 1.47.17.17h2.24v-.57h-2zm0 0"
      />
    </SvgIcon>
  );
}

export default observer(AmericanExpressCardIcon);
