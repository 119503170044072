import { missingLinkError } from '@execonline-inc/links.private';
import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import FreebusyStore, { State } from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { handleError } from '../ErrorActionableReaction';
import { warnAndNotify } from '../Honeybadger';
import { findLinkByT } from '../Links';
import { MissingLinkError } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import SegmentStore from '../SegmentStore';
import { TranslationsState, translation } from '../Translations';
import { eventDetailsDecoder } from '../components/Freebusy/Decoders';
import { loadFreebusy } from '../components/Freebusy/Loader';
import {
  FreebusyInitializationError,
  FreebusyKind,
  PickATime,
  Proposal,
  ProposerParticipant,
} from '../components/Freebusy/Types';
import { successfulLoading } from './SuccessfulLoading';

interface Props {
  store: FreebusyStore;
  links: Maybe<ReadonlyArray<Link>>;
  translationsState: TranslationsState;
  segmentStore: Maybe<SegmentStore>;
}

const initializeError =
  (store: FreebusyStore) =>
  (err: FreebusyInitializationError): void => {
    switch (err.kind) {
      case 'missing-var-error':
        warnAndNotify(
          'MissingVarError',
          `Unable to load Freebusy: Missing environment variable ${err.key}`,
          err,
        );
        store.error('Unable to load Freebusy');
        break;
      case 'freebusy-load-error':
        warnAndNotify('FreebusyLoadError', err.key, err);
        store.error('Unable to load Freebusy');
        break;
      case 'missing-link-error':
        warnAndNotify('MissingLinkError', `Unable to load Freebusy: Missing freebusy js link`, err);
        store.error('Unable to load Freebusy');
        break;
      default:
        assertNever(err);
    }
  };

export type FreebusyError = AppyError | MissingLinkError;

const handleFreebusyError = (store: FreebusyStore) => (error: FreebusyError) => {
  switch (error.kind) {
    case 'missing-link-error':
      warnAndNotify('FreebusyReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

interface FreebusyApiData {
  time: string;
  duration: number;
  title: string;
  location: string;
  external_id: string;
  participants: ProposerParticipant[];
  event_uuid: string;
  description: string;
  agenda: string;
  action_label: string;
  action_type: string;
}

const freebusyApiData = (
  freebusyKind: FreebusyKind,
  proposal: Proposal,
  eventUuid: string,
  ts: TranslationsState,
): FreebusyApiData => {
  switch (freebusyKind) {
    case 'freebusy-coaching':
      return {
        time: proposal.startTime,
        duration: proposal.durationInMin,
        location: proposal.location,
        external_id: proposal.id,
        participants: proposal.participants,
        event_uuid: eventUuid,
        title: translation('ExecOnline Coaching Session')(ts),
        description: translation('In this 30-minute session, you will ...')(ts),
        agenda: translation('Welcome to Your ExecOnline Coaching Session')(ts),
        action_label: translation('Join Coaching Session')(ts),
        action_type: 'freebusy_coaching',
      };
    case 'freebusy-program':
      return {
        time: proposal.startTime,
        duration: proposal.durationInMin,
        location: proposal.location,
        external_id: proposal.id,
        participants: proposal.participants,
        event_uuid: eventUuid,
        title: 'Live Project Feedback Session',
        description:
          'In this 30-minute session, you will partner with your Project Feedback Expert to review your assignments submitted to date and discuss how your learnings can be applied for maximum impact.',
        agenda: 'Welcome to Your Live Project Feedback Session',
        action_label: 'Join Live Project Feedback Session',
        action_type: 'freebusy_program',
      };
  }
};

class FreebusyReactions extends ErrorActionableReaction<FreebusyStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
        Task.succeed<FreebusyInitializationError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkByT({ rel: 'calendaring-app' }))
          .andThen((t) => loadFreebusy(t.href))
          .fork(
            initializeError(this.props.store),
            successfulLoading(state.freebusySettings, this.props.store),
          );
        break;
      case 'initializing':
        Task.succeed<FreebusyInitializationError, PickATime>(state.freebusy.pickatime)
          .do((pickatime) => pickatime.onerror(initializeError(this.props.store)))
          .do((pickatime) => pickatime.init(state.freebusySettings.initSettings))
          .do((pickatime) =>
            pickatime.onclick(() => {
              const settings = state.freebusySettings;
              pickatime.open(toJS(settings.availabilityRequest), toJS(settings.availabilityQuery));
            }),
          )
          .do((pickatime) => {
            pickatime.onselect((response, proposal) => {
              if (response.statusCode === 200) {
                this.props.store.submitting(response, proposal);
              } else {
                warnAndNotify('FreebusyReactions', `pickatime.onselect failed`, {
                  response,
                  proposal,
                });
                this.props.store.error('Freebusy failed to schedule meeting');
              }
            });
          })
          .fork(initializeError(this.props.store), this.props.store.ready);
        break;
      case 'submitting':
        toTask<FreebusyError, ReadonlyArray<Link>>(
          missingLinkError({ rel: 'freebusy' }),
          this.props.links,
        )
          .andThen(findLinkByT({ rel: 'freebusy' }))
          .andThen(
            callApi(eventDetailsDecoder, {
              freebusy: freebusyApiData(
                this.props.store.freebusyKind,
                state.freebusyProposal,
                state.eventUuid,
                this.props.translationsState,
              ),
            }),
          )
          .fork(handleFreebusyError(this.props.store), this.props.store.submitted);
        break;
      case 'submitted':
        this.props.segmentStore.map((store) => store.scheduleSession());
        break;
      case 'ready':
      case 'error':
        break;
    }
  };
}

export default observer(FreebusyReactions);
