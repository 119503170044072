import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ConferenceableResource,
  ConversationResource,
  LocalConferenceRoom,
  whenLocalConferenceRoomResource,
} from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import ConferenceRosterReactions from '../../ConferenceRosterStore/ConferenceRosterReactions';
import ConferenceRosterRefreshReactions from '../../ConferenceRosterStore/ConferenceRosterRefreshReactions';
import ConversationPresence from '../../ConversationPresence';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { EventResource } from '../../EventsStore/Types';
import handRaisers from '../../HandRaisers';
import NotificationSource from '../../NotificationSource';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import BreakoutRedirect from '../ConferencePanel/BreakoutRedirect';
import StaffPresenceStore from '../EventDashboard/StaffPresenceStore';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import Toasts from '../Toasts';
import ConferenceMainPanel from './ConferenceMainPanel';
import ConferenceStore from './ConferenceStore';
import ConferenceSupPanel from './ConferenceSupPanel';
import EventPoll from './EventPoll';
import NoConference from './NoConference';
import { RoomConfiguration } from './Types';

interface Props {
  title: string;
  detail: Maybe<string>;
  agenda: Maybe<string>;
  eventResource: Maybe<EventResource>;
  conversationResource: ConversationResource;
  conferenceableResource: ConferenceableResource;
  roomConfig: RoomConfiguration;
  currentUserResource: CurrentUserResource;
  conferenceRoom: LocalConferenceRoom;
}

@observer
class Conference extends React.Component<Props> {
  private conferenceRosterStore: ConferenceRosterStore = new ConferenceRosterStore(
    this.props.currentUserResource,
    this.props.conferenceRoom,
  );
  private conferenceStore: ConferenceStore = new ConferenceStore(this.props.conferenceableResource);

  constructor(props: Props) {
    super(props);
    this.conferenceRosterStore.loading();
  }

  componentDidMount() {
    this.props.conferenceableResource.payload.conferenceRoom
      .andThen(whenLocalConferenceRoomResource)
      .do((resource) => {
        handRaisers.enable(resource);
        handRaisers.addHandRaiser(this.props.currentUserResource.payload.id);
      });
  }

  componentWillUnmount() {
    this.props.conferenceableResource.payload.conferenceRoom
      .andThen(whenLocalConferenceRoomResource)
      .do(() => {
        handRaisers.disable();
      });
  }

  render() {
    const { state } = this.conferenceStore;
    switch (state.kind) {
      case 'not-present':
        return <NoConference currentUserResource={this.props.currentUserResource} />;
      case 'redirecting':
        return <BreakoutRedirect breakoutStore={state.breakoutStore} />;
      case 'present':
        return (
          <ConversationPresence
            conversationResource={this.props.conversationResource}
            currentUserResource={this.props.currentUserResource}
            channelName={this.props.conversationResource.payload.channelName}
            chatablePersonResourceList={nothing()}
            client={(
              presenceMembersStore: PresenceMembersStore,
              staffPresenceStore: StaffPresenceStore,
              staffRequestStore: StaffRequestStore,
            ) => (
              <>
                <div className="flex">
                  <Toasts teamsStore={nothing()} />
                  <ConferenceMainPanel
                    title={this.props.title}
                    detail={this.props.detail}
                    conferenceRoomResource={state.conferenceRoomResource}
                    conferenceRosterStore={this.conferenceRosterStore}
                  />
                  <ConferenceSupPanel
                    presenceMembersStore={presenceMembersStore}
                    conferenceRoomResource={state.conferenceRoomResource}
                    agenda={this.props.agenda}
                    eventResource={this.props.eventResource}
                    conferenceRosterStore={this.conferenceRosterStore}
                    roomConfig={this.props.roomConfig}
                    breakoutStores={state.breakoutStores}
                    staffRequestStore={staffRequestStore}
                  />
                  <ConferenceRosterReactions
                    store={this.conferenceRosterStore}
                    conferenceableResource={this.props.conferenceableResource}
                    fireImmediately={true}
                  />
                  <ConferenceRosterRefreshReactions store={this.conferenceRosterStore} />
                  <NotificationSource store={this.conferenceRosterStore} />
                  <EventPoll eventPollStore={presenceMembersStore.eventPollStore} />
                </div>
              </>
            )}
          />
        );
    }
  }
}

export default Conference;
