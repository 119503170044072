import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { whenGt } from '@execonline-inc/numbers';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';

interface Props {
  remainingSeats: Maybe<number>;
}

function GroupCoachingSeatsRemaining({ remainingSeats }: Props) {
  return remainingSeats
    .andThen(whenGt(0))
    .map((count) => (
      <Typography className="text-amber-600" fontWeight="medium" variant="bodySmall">
        <T kind="Only {{count}} seat remaining" count={count} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachingSeatsRemaining);
