import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { lightTheme } from '../../Native/styles';
import Notifications from '../../Notifications';
import ThemeContext from '../../Themes/ThemeContext';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import Calendar from '../Calendar';
import * as platformStyle from '../Platform/style.module.css';

const Events: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <>
        <I18nTitle title="Calendar" />
        <div className={platformStyle.container} data-test-calendar-page={true}>
          <Notifications />
          <AutoLaunchable />
          <Main id="main">
            <ThemeContext.Provider value={lightTheme}>
              <h1 className={platformStyle.screenReaderOnly}>
                <T kind="Calendar" />
              </h1>
              <Calendar headingLevel={2} linkable={currentUserResource} />
            </ThemeContext.Provider>
          </Main>
        </div>
      </>
    )}
  />
);

export default observer(Events);
