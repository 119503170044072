import * as React from 'react';
import { observer } from 'mobx-react';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import CallToAction from '../../CallToAction';
import { AlreadyTranslated } from '@execonline-inc/translations';
import ExperienceInfo from './ExperienceInfo';
import * as styles from '../style.module.css';
import Store from '../../DetailViewStore';
import Button from '../../../../common/Button';
import { T } from '../../../../../Translations';
import { bgImageStyle } from '../../ImageStyles';

interface Props {
  externalProgramResource: ExternalProgramResource;
  store: Store;
}

const Body: React.FC<Props> = ({ externalProgramResource, store }) => {
  const secondaryColor = externalProgramResource.payload.secondaryColor;
  return (
    <div className="m-1 px-5 py-5 h-[411px] relative" style={{ backgroundColor: secondaryColor }}>
      <ExperienceInfo externalProgramResource={externalProgramResource} />
      <div className={styles.cardDescription}>
        <AlreadyTranslated content={externalProgramResource.payload.description} />
      </div>
      <div className={styles.callToAction}>
        <div style={bgImageStyle(externalProgramResource, 'center bottom')}>
          <Button
            className="mr-2 inline-block"
            onClick={() => store.openInfo(externalProgramResource)}
          >
            <T kind="Learn More" />
          </Button>
          <CallToAction externalProgramResource={externalProgramResource} />
        </div>
      </div>
    </div>
  );
};

export default observer(Body);
