import * as React from 'react';
import * as style from './style.module.css';
import { ExpandableItem } from '../../../ExpandableItemStore';
import { ProgramResourcesModule } from './ProgramResourcesStore/Types';
import ToggleOpenIcon from '../../Cutesies/ToggleOpenIcon';

interface Props {
  expandableModule: ExpandableItem<ProgramResourcesModule>;
}

const ExpandModuleIcon: React.FC<Props> = ({ expandableModule }) => {
  return (
    <div
      className={`${style.revealToggle} ${expandableModule.open ? style.revealToggleOpen : ''}`}
      data-test-toggle-icon={true}
    >
      <ToggleOpenIcon />
    </div>
  );
};

export default ExpandModuleIcon;
