import { observer } from 'mobx-react';
import { ComponentProps } from 'react';
import { WithOpenableLogic } from '../Openable';
import Modal from '.';

type AsModalProps = ComponentProps<typeof WithOpenableLogic> & ComponentProps<typeof Modal>;

function AsModal({ size, state, close, title, children }: AsModalProps) {
  return (
    <WithOpenableLogic state={state}>
      <Modal size={size} close={close} title={title}>
        {children}
      </Modal>
    </WithOpenableLogic>
  );
}

export default observer(AsModal);
