import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import BreakoutChannelImpl from '../../BreakoutChannelImpl';
import { eventDashboardUrl } from '../../ClientRoutes';
import { ConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { findLink } from '../../LinkyLinky';
import { Link as DashboardLink } from '../../Resource/Types';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';
import BreakoutButton from './BreakoutButton';
import BreakoutTimeRemaining from './BreakoutTimeRemaining';

interface Props {
  conferenceResource: ConferenceRoomResource;
  breakoutStore: BreakoutChannelStore;
  conferenceRosterStore: ConferenceRosterStore;
  eventId: Maybe<number>;
}

const renderDashboardButton = (
  conferenceRosterStore: ConferenceRosterStore,
  eventId: Maybe<number>
) => (link: DashboardLink) => (
  <>
    {
      <span className={style.participantCount}>
        <T
          kind="{{count}} participant has their hand raised"
          count={conferenceRosterStore.participantsWithHandRaisedCount}
        />
      </span>
    }
    {eventId
      .map((id) => (
        <Link
          to={eventDashboardUrl(id)}
          className={style.joinDashboardButton}
          data-test-event-dashboard={true}
          target="_blank"
        >
          <T kind="Go To Event Dashboard" />
        </Link>
      ))
      .getOrElseValue(<span />)}
  </>
);

const renderGoButton = (breakoutStore: BreakoutChannelStore) => () => (
  <div className={style.actions}>
    <BreakoutButton breakoutStore={breakoutStore} />
    <BreakoutTimeRemaining breakoutTimeRemainingStore={breakoutStore.timeRemainingStore} />
  </div>
);

const BreakoutAction: React.FC<Props> = ({
  breakoutStore,
  conferenceResource,
  conferenceRosterStore,
  eventId,
}) => {
  const { breakout } = breakoutStore;
  return (
    <BreakoutChannelImpl channelName={breakout.eventChannelName} publicChannelStore={breakoutStore}>
      {findLink('dashboard', conferenceResource.links)
        .map(renderDashboardButton(conferenceRosterStore, eventId))
        .orElse(() =>
          breakoutStore.timeRemainingStore.timeRemaining.map(renderGoButton(breakoutStore))
        )
        .getOrElse(() => (
          <span />
        ))}
    </BreakoutChannelImpl>
  );
};
export default observer(BreakoutAction);
