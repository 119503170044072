import { Error } from '../../../../ErrorHandling';
import { Link } from '../../../../Resource/Types';
import { ResourcesListResource } from '../../Shared/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  links: ReadonlyArray<Link>;
}

interface Ready {
  kind: 'ready';
  liveMeetingRecordingResourcesListResource: ResourcesListResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (links: ReadonlyArray<Link>): Loading => ({
  kind: 'loading',
  links,
});

export const ready = (liveMeetingRecordingResourcesListResource: ResourcesListResource): Ready => ({
  kind: 'ready',
  liveMeetingRecordingResourcesListResource,
});

export type LiveMeetingRecordingsState = Waiting | Loading | Ready | Error;
