import { stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import Decoder, { array, field, number, oneOf, succeed } from 'jsonous';
import { programResourceDecoder } from '../../../../../../ProgramsStore/Decoders';
import { resourceDecoder } from '../../../../../../Resource/Decoders';
import { Resource } from '../../../../../../Resource/Types';
import {
  EnrollmentError,
  EnrollmentPayload,
  EnrollmentResource,
  ExpiredAccessEnrollmentError,
  ProgramConflictEnrollmentError,
} from '../Types';

const enrollmentPayloadDecoder: Decoder<EnrollmentPayload> = succeed({})
  .assign('programId', field('program_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('segmentId', field('segment_id', number))
  .assign('program', field('program', programResourceDecoder));

export const enrollmentResourceDecoder: Decoder<EnrollmentResource> = resourceDecoder(
  enrollmentPayloadDecoder
);

const programConflictEnrollmentErrorDecoder: Decoder<ProgramConflictEnrollmentError> = succeed({})
  .assign('message', field('message', alreadyTranslatedText))
  .assign('kind', field('kind', stringLiteral('program-conflict')));

const expiredAccessEnrollmentErrorDecoder: Decoder<ExpiredAccessEnrollmentError> = succeed({})
  .assign('message', field('message', alreadyTranslatedText))
  .assign('kind', field('kind', stringLiteral('access-expired')));

const enrollmentErrorDecoder: Decoder<EnrollmentError> = oneOf([
  programConflictEnrollmentErrorDecoder.map<EnrollmentError>(identity),
  expiredAccessEnrollmentErrorDecoder.map<EnrollmentError>(identity),
]);

export const errorResourceDecoder: Decoder<
  Resource<{}> & { errors: ReadonlyArray<EnrollmentError> }
> = resourceDecoder(succeed({}))
  .assign('errors', field('errors', array(enrollmentErrorDecoder)))
  .map<Resource<{}> & { errors: ReadonlyArray<EnrollmentError> }>(identity);
