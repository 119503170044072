import * as React from 'react';
import { observer } from 'mobx-react';
import { EventResource } from '../../../EventsStore/Types';
import PollsModalStore from '../../../EventPolls/PollsModal/PollsModalStore';
import { just } from 'maybeasy';
import { findLink } from '../../../LinkyLinky';
import PollLauncherButton from '../../../EventPolls/PollLauncherButton';
import PollsModal from '../../../EventPolls/PollsModal';
import LaunchPollsModalReactions from '../../../EventPolls/PollsModal/PollsModalStore/LaunchPollsModalReactions';
import { LocalConferenceRoomResource } from '../../../Conference/Types';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
  eventResource: EventResource;
  pollsModalStore: PollsModalStore;
}

const PollLauncher: React.FC<Props> = ({
  conferenceRoomResource,
  eventResource,
  pollsModalStore,
}) => {
  return just({})
    .assign('launchLink', findLink('launch-poll', conferenceRoomResource.links))
    .assign('launchCloseLink', findLink('launch-poll-close', conferenceRoomResource.links))
    .map(({ launchLink, launchCloseLink }) => (
      <>
        <PollLauncherButton store={pollsModalStore} />
        <PollsModal store={pollsModalStore} pollsResource={eventResource.payload.polls} />
        <LaunchPollsModalReactions
          store={pollsModalStore}
          launchLink={launchLink}
          launchCloseLink={launchCloseLink}
          messagingChannel={conferenceRoomResource.payload.messagingChannel}
        />
      </>
    ))
    .getOrElseValue(<></>);
};

export default observer(PollLauncher);
