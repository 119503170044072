import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { CompetencyResource, Experience } from '../../../Types';
import { ProgramHours } from '../Common';
import ProgramCompetencies from './ProgramCompetencies';
import ProgramUpcomingDates from './ProgramUpcomingDates';

interface Props {
  competencies: Maybe<CompetencyResource[]>;
  experience: Experience;
}

function ProgramOverviewItems({ competencies, experience }: Props) {
  return (
    <div className="flex flex-wrap gap-x-3 gap-y-1 empty:hidden sm:gap-x-6">
      <ProgramCompetencies competencies={competencies} />
      <ProgramHours hours={experience.totalHours} />
      <ProgramUpcomingDates availabilities={experience.availabilities} />
    </div>
  );
}

export default observer(ProgramOverviewItems);
