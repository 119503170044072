import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const FacebookIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 15">
      <path
        fill={defaultTheme.variables['icon-standard-on-light']}
        d="M1.41 0C.64 0 0 .618 0 1.364v12.272C0 14.382.64 15 1.41 15h12.697c.771 0 1.41-.618 1.41-1.364V1.364c0-.746-.639-1.364-1.41-1.364H1.41Zm0 1.364h12.697v12.272h-3.66V9.034h1.83l.265-2.045h-2.094V5.668c0-.597.151-1.002 1.036-1.002h1.146V2.812a18.252 18.252 0 0 0-1.653-.063c-1.626 0-2.733.945-2.733 2.706v1.534H6.392v2.045h1.852v4.602H1.41V1.364Z"
      />
    </SVGIcon>
  );
};

export default observer(FacebookIcon);
