import { assertNever, noop } from '@kofno/piper';
import { Maybe, fromEmpty, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { SSO } from '../SingleSignOn';
import { LoginError } from './Errors';
import { State } from './States';

export class LoginStore {
  @observable state: State = { kind: 'awaiting-username', username: nothing(), error: nothing() };

  @action awaitingUsername = (params: {
    username: Maybe<string>;
    error: Maybe<LoginError>;
  }): void => {
    this.state = {
      kind: 'awaiting-username',
      username: params.username.andThen(fromEmpty),
      error: params.error,
    };
  };

  @action identifyingAssertionBackend = (): void => {
    switch (this.state.kind) {
      case 'awaiting-username': {
        const previous = this.state;
        previous.username.andThen(fromEmpty).cata({
          Nothing: noop,
          Just: (username) => {
            this.state = { kind: 'identifying-assertion-backend', username };
          },
        });
        break;
      }
      case 'awaiting-password':
      case 'submitting-password':
      case 'identifying-assertion-backend':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action awaitingSsoFlow = (params: { ssoUrls: SSO.URLs }): void => {
    switch (this.state.kind) {
      case 'identifying-assertion-backend':
        this.state = {
          kind: 'awaiting-sso-flow',
          username: this.state.username,
          ssoUrls: params.ssoUrls,
        };
        break;
      case 'awaiting-password':
      case 'submitting-password':
      case 'awaiting-username':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action awaitingPassword = (params: {
    password: Maybe<string>;
    error: Maybe<LoginError>;
  }): void => {
    switch (this.state.kind) {
      case 'identifying-assertion-backend':
      case 'awaiting-password':
      case 'submitting-password':
        this.state = {
          kind: 'awaiting-password',
          username: this.state.username,
          password: params.password.andThen(fromEmpty),
          error: params.error,
        };
        break;
      case 'awaiting-username':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action submittingPassword = (): void => {
    switch (this.state.kind) {
      case 'awaiting-password': {
        const previous = this.state;
        previous.password.andThen(fromEmpty).cata({
          Nothing: noop,
          Just: (password) => {
            this.state = { kind: 'submitting-password', username: previous.username, password };
          },
        });
        break;
      }
      case 'awaiting-username':
      case 'identifying-assertion-backend':
      case 'submitting-password':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action successfulLogin = (): void => {
    switch (this.state.kind) {
      case 'submitting-password':
      case 'awaiting-sso-flow':
        this.state = { kind: 'successful-login' };
        break;
      case 'awaiting-username':
      case 'identifying-assertion-backend':
      case 'awaiting-password':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action lockedOut = (): void => {
    switch (this.state.kind) {
      case 'identifying-assertion-backend':
      case 'submitting-password':
        this.state = { kind: 'locked-out' };
        break;
      case 'awaiting-username':
      case 'awaiting-password':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed get formShouldBeDisabled(): boolean {
    switch (this.state.kind) {
      case 'awaiting-username':
      case 'awaiting-password':
        return false;
      case 'identifying-assertion-backend':
      case 'submitting-password':
      case 'awaiting-sso-flow':
      case 'successful-login':
      case 'locked-out':
        return true;
    }
  }

  @computed get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'awaiting-username':
      case 'awaiting-password':
        return this.state.error
          .map((e) => e.message)
          .map(error)
          .map(errorAlert);
      case 'awaiting-sso-flow':
      case 'identifying-assertion-backend':
      case 'locked-out':
      case 'submitting-password':
      case 'successful-login':
        return nothing();
    }
  }
}
