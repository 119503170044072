import {
  Accordion,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { MenuFilter } from '../CommonFilter';
import { allCompetencyOptions } from '../CompetencyFilter/Types';
import { allDurationOptions } from '../DurationFilter/Types';
import FilterStore from '../FilterStore';
import { allLanguageOptions } from '../LanguageFilter/Types';
import { allSchoolPartnerOptions } from '../SchoolPartnerFilter/Types';
import FiltersButton from './FiltersButton';
import SidebarFooter from './SidebarFooter';
import SidebarHeader from './SidebarHeader';

export interface Props {
  filterStore: FilterStore;
  options: ExperienceFiltersResource;
}

function SidebarFilters({ filterStore, options }: Props) {
  const {
    competencyFilterStore,
    durationFilterStore,
    schoolPartnerFilterStore,
    languageFilterStore,
    appliedCount,
    clearAll,
    apply,
  } = filterStore;

  return (
    <>
      <FiltersButton value={appliedCount()} onClear={clearAll}>
        {({ isOpen, onClose }) => (
          <Drawer open={isOpen} onClose={onClose}>
            <DrawerHeader>
              <SidebarHeader onClose={onClose} />
            </DrawerHeader>
            <DrawerBody>
              <Accordion className="px-4">
                <MenuFilter
                  title="Competency"
                  store={competencyFilterStore}
                  filterOptions={allCompetencyOptions(options)}
                />
                <MenuFilter
                  title="Time Commitment"
                  store={durationFilterStore}
                  filterOptions={allDurationOptions(options)}
                />
                <MenuFilter
                  title="Partner"
                  store={schoolPartnerFilterStore}
                  filterOptions={allSchoolPartnerOptions(options)}
                />
                <MenuFilter
                  title="Language"
                  store={languageFilterStore}
                  filterOptions={allLanguageOptions(options)}
                />
              </Accordion>
            </DrawerBody>
            <DrawerFooter>
              <SidebarFooter onClear={clearAll} onApply={apply} />
            </DrawerFooter>
          </Drawer>
        )}
      </FiltersButton>
    </>
  );
}

export default observer(SidebarFilters);
