import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../SegmentStore';
import _Video from '../../../Video';
import ReqHlsVideo from '../../../ReqHlsVideo';
import * as style from '../style.module.css';
import LectureVideoInitialPosition from './LectureVideoInitialPosition';
import LectureVideoReactions from './LectureVideoReactions';
import PlayButton from './PlayButton';
import { LectureSegment } from '../../../../LectureStore/Types';
import { videoResourceCata } from './Context';

interface Props {
  segmentStore: SegmentStore;
  segment: LectureSegment;
  refEl: React.RefObject<HTMLDivElement>;
}

@observer
class Video extends React.Component<Props, {}> {
  render() {
    const { segmentStore, segment, refEl } = this.props;
    return videoResourceCata(
      {
        legacy: video => (
          <div
            className={style.video}
            ref={refEl}
            data-test-lecture-video={segment.video.payload.title}
          >
            <_Video
              id="lecture-segment-video"
              className={style.videoPlayer}
              videoResource={video}
              kettle={segmentStore.kettle}
              width={320}
              height={186}
              dynamicallySetAspectRatio={false}
            >
              {{ playButton: just(<PlayButton kettle={this.props.segmentStore.kettle} />) }}
            </_Video>
            <LectureVideoInitialPosition kettle={segmentStore.kettle} />
            <LectureVideoReactions
              store={segmentStore}
              segment={segment}
              kettle={segmentStore.kettle}
            />
          </div>
        ),
        hls: video => (
          <div
            className={style.video}
            ref={refEl}
            data-test-lecture-video={segment.video.payload.title}
          >
            <ReqHlsVideo
              id="lecture-segment-video"
              className={style.videoPlayer}
              videoResource={video}
              kettle={segmentStore.kettle}
              width={320}
              height={186}
              dynamicallySetAspectRatio={false}
            >
              {{ playButton: just(<PlayButton kettle={this.props.segmentStore.kettle} />) }}
            </ReqHlsVideo>
            <LectureVideoInitialPosition kettle={segmentStore.kettle} />
            <LectureVideoReactions
              store={segmentStore}
              segment={segment}
              kettle={segmentStore.kettle}
            />
          </div>
        ),
      },
      segment.video
    );
  }
}

export default Video;
