import { Button } from '@execonline-inc/execonline-ui';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { EmailField } from '../../Native/Form';
import { T } from '../../Translations';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';

interface Props {
  store: LoginStore;
}

function EmailForm({ store }: Props) {
  return (
    <LoginFormWrapper store={store}>
      <form
        data-testid="login-email-form"
        name="email_login_form"
        className="mx-auto w-80"
        onSubmit={submit(store)}
      >
        <EmailField
          data-testid="login-email-form_email-text-field"
          id="login_email"
          autoComplete="username"
          disabled={store.formShouldBeDisabled}
          onChange={setUsername(store)}
        />
        <Button
          data-testid="login-email-form_next-button"
          className="mt-7 w-full sm:w-auto"
          color="green"
          type="submit"
          radius="sm"
          disabled={store.formShouldBeDisabled}
        >
          <T kind="Next" />
        </Button>
      </form>
    </LoginFormWrapper>
  );
}

export default observer(EmailForm);

const setUsername =
  (store: LoginStore) =>
  (value: string): void => {
    store.awaitingUsername({ username: just(value), error: nothing() });
  };

const submit =
  (store: LoginStore) =>
  (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    store.identifyingAssertionBackend();
  };
