import { observer } from 'mobx-react';
import { ButtonProps } from '@execonline-inc/execonline-ui';
import { ReturnableExperience } from '../../../../Common/Experience/Types';
import {
  exitToDashboard,
  exitToProgram,
} from '../../../../Common/Experience/Actions/Exitable/Types';
import { Exitable } from '../../../../Common/Experience/Actions';
import { T } from '../../../../../../Translations';

interface Props {
  experience: ReturnableExperience;
}

const buttonProps: ButtonProps = {
  variant: 'text',
  color: 'primary',
  className: 'p-0 text-sm font-bold hover:bg-transparent',
};

function ReturnableCardFooter({ experience }: Props) {
  const exitContext = () => {
    switch (experience.offeringType) {
      case 'aep':
      case 'epc':
      case 'msuite':
        return exitToProgram(experience.programId);
      case 'coaching':
      case 'program-sequence':
      case 'group-coaching':
        return exitToDashboard();
    }
  };
  return (
    <Exitable buttonProps={buttonProps} exitTo={exitContext}>
      <T kind="Return to Experience" />
    </Exitable>
  );
}

export default observer(ReturnableCardFooter);
