import { observer } from 'mobx-react';
import * as React from 'react';

import ProgramStore from '../ProgramStore';
import WithPusherSettings from '../ToolingsStore/WithPusherSettings';
import Refresh from './Refresh';

interface Props {
  programStore: ProgramStore;
}

const UpcomingSessionsRefresh: React.FC<Props> = ({ programStore }) => (
  <WithPusherSettings
    children={({ payload }) => (
      <Refresh programStore={programStore} channelName={payload.channels.calendarEventsChannel} />
    )}
  />
);

export default observer(UpcomingSessionsRefresh);
