import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import MeetingDetails from './MeetingDetails';
import * as style from './style.module.css';
import { EventDetails } from './Types';

interface Props {
  eventDetails: EventDetails;
}

const CoachingSubmittedButton: React.FC<Props> = ({ eventDetails }) => (
  <div className={style.success}>
    <h1>
      <T kind="Your meeting was successfully scheduled" />
    </h1>
    <MeetingDetails eventDetails={eventDetails} />
    <p>
      <T kind="Please view the invite sent to your calendar for full details" />
    </p>
    <p>
      <T kind="Please note, you may come back to this segment ..." />
    </p>
  </div>
);

export default observer(CoachingSubmittedButton);
