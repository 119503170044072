import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import { programsStore } from '../../../ProgramsStore';
import { completedProgramResource, visibleProgramResource } from '../../../ProgramsStore/Functions';
import { T } from '../../../Translations';
import ProgramDashboards from '../ProgramDashboard/ProgramDashboards';
import * as style from '../style.module.css';

const CompletedPrograms: React.FC<{}> = () => {
  return (
    <div className={style.active} data-test-completed-programs={true}>
      {programsStore.resource
        .map(({ payload }) =>
          payload.programs.filter(completedProgramResource).filter(visibleProgramResource),
        )
        .andThen(fromArrayMaybe)
        .map((completedPrograms) => (
          <>
            <h2 className="ml-2" data-test-completed-programs-title="Completed">
              <T kind="Completed" />
            </h2>
            <ProgramDashboards programs={completedPrograms} />
          </>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
};

export default observer(CompletedPrograms);
