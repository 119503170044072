import * as React from 'react';
import { replyNotificationsStore } from '../ReplyNotificationsStore';
import { observer } from 'mobx-react';
import ReplyNotification from './ReplyNotification';
import ReplyNotificationStore from '../ReplyNotificationsStore/ReplyNotificationStore';
import { NonEmptyList } from 'nonempty-list';

interface Props {}

const renderNotifications = (notificationStores: NonEmptyList<ReplyNotificationStore>) =>
  notificationStores
    .toArray()
    .map((store) => <ReplyNotification store={store} key={store.state.notification.uuid} />);

const ReplyNotifications: React.FC<Props> = () => (
  <>{replyNotificationsStore.getNotifications.map(renderNotifications).getOrElseValue([])}</>
);

export default observer(ReplyNotifications);
