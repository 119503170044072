import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import ProgramChatStore from '../../ProgramChatStore';
import CommunitiesChat from '../Communities/CommunitiesChat';

interface Props {
  programChatStore: ProgramChatStore;
  conversationStore: ConversationStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const ProgramPageContent: React.FC<Props> = ({
  programChatStore,
  conversationStore,
  presenceStore,
}) => (
  <CommunitiesChat
    conversationStore={conversationStore}
    programChatStore={just(programChatStore)}
    teamStore={nothing()}
    presenceStore={presenceStore}
    kind="program-chat"
  />
);

export default observer(ProgramPageContent);
