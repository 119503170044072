import * as React from 'react';
import { observer } from 'mobx-react';

import ScheduleSessionStore from './Store';
import ProgramStore from '../../../../../ProgramStore';
import ScheduleSessionReactions from './Store/Reactions';
import ScheduleSessionStates from './ScheduleSessionStates';

interface Props {
  programStore: ProgramStore;
}

class FreebusyScheduleSessionButton extends React.Component<Props> {
  store = new ScheduleSessionStore(this.props.programStore);

  componentDidMount() {
    this.store.loading();
  }

  render() {
    return (
      <>
        <ScheduleSessionStates store={this.store} />
        <ScheduleSessionReactions store={this.store} fireImmediately={true} />
      </>
    );
  }
}

export default observer(FreebusyScheduleSessionButton);
