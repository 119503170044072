import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { CoachingProductDetails } from '../../../../Common/Experience/Types';
import {
  ExperienceDetailsSection,
  ExperienceDetailsSectionHeader,
  ExperienceHtmlView,
} from '../../../Common';

interface Props {
  productDetails: CoachingProductDetails;
}

function StudentTestimonial({ productDetails }: Props) {
  return productDetails.pullQuoteReviewsHtml
    .map((data) => (
      <ExperienceDetailsSection className="border-none !p-0 xl:w-3/5">
        <ExperienceDetailsSectionHeader title="Student Testimonial" />
        <div className="rounded-md bg-[#F4F4F4] p-4 sm:p-6">
          <ExperienceHtmlView data={data} />
        </div>
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(StudentTestimonial);
