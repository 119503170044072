import { useContext } from 'react';
import { AutoLaunchableContext } from '../AutoLaunchableContext';

function useAutoLaunchableContext() {
  const context = useContext(AutoLaunchableContext);

  if (!context) {
    throw new Error(
      'useAutoLaunchableContext has to be used within the AutoLaunchableContext.Provider.',
    );
  }

  return context;
}

export default useAutoLaunchableContext;
