import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import ClassicCopyrightNotice from '../../../components/Footer/ClassicFooter/ClassicCopyrightNotice';
import ClassicDivider from '../../../components/Footer/ClassicFooter/ClassicDivider';
import FooterLanguageSwitcherImpl from '../../../components/Footer/Footer/FooterLanguageSwitcherImpl';
import { Typography } from '../../../components/UI';
import { findLinkBy } from '../../../Links';
import { rootResourceStore } from '../../../RootResourceStore';
import ThemeContext from '../../../Themes/ThemeContext';
import { NotTranslated, T } from '../../../Translations';
import MailToLink from '../MailToLink';
import * as style from './style.module.css';

interface Props {
  allowLanguagePicker: boolean;
}

function RegistrationFooter({ allowLanguagePicker }: Props) {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={clsx(style.footer, 'flex flex-col')}>
            <div className="flex flex-wrap items-baseline justify-center" data-test-footer={true}>
              <Typography variant="body2" component="span">
                <T
                  kind="Having trouble accessing your account? Contact ..."
                  emailAddress={(() => {
                    const email = 'support@execonline.com';
                    return (
                      <MailToLink email={email}>
                        <Typography className="underline" component="span">
                          <NotTranslated text={email} />
                        </Typography>
                      </MailToLink>
                    );
                  })()}
                />
              </Typography>
              <ClassicDivider />
              <Typography variant="body2" component="span">
                <T
                  kind="Use of this site constitutes acceptance of ..."
                  link1={(content) => (
                    <a
                      style={{ color: theme.colors['text-primary-color'] }}
                      href={termsLink.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-test-terms-of-use-link={true}
                    >
                      {content}
                    </a>
                  )}
                  link2={(content) => (
                    <a
                      style={{ color: theme.colors['text-primary-color'] }}
                      href={privacyLink.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-test-privacy-policy-link={true}
                    >
                      {content}
                    </a>
                  )}
                />
              </Typography>
            </div>
            <div className="flex flex-wrap items-baseline justify-center">
              <Typography variant="body2" component="span">
                <ClassicCopyrightNotice />
                <ClassicDivider />
                <T
                  kind="<link1>Sitemap</link1>."
                  link1={(content) => (
                    <a
                      href="/p3/sitemap"
                      data-test-sitemap-link={true}
                      style={{ color: theme.colors['text-primary-color'] }}
                    >
                      {content}
                    </a>
                  )}
                />
              </Typography>
              {allowLanguagePicker && <FooterLanguageSwitcherImpl />}
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    ))
    .getOrElse(emptyFragment);
}

export default observer(RegistrationFooter);
