import * as React from 'react';
import { Link } from 'react-router-dom';
import { segmentRoute } from '../../../../../../../ClientRoutes';
import { ProgramSegment } from '../../../../../../../ProgramStore/Types';
import { NotTranslated } from '../../../../../../../Translations';
import UnstartedSegmentIcon from '../../../../../../Cutesies/UnstartedSegmentIcon';
import SegmentInfo from '../SegmentInfo';
import * as style from '../style.module.css';

interface Props {
  segment: ProgramSegment;
}

const UnstartedSegment: React.FC<Props> = ({ segment }) => (
  <li
    className={style.segment}
    key={`segment-${segment.id}`}
    data-test-segment={segment.id}
    data-test-segment-type="unstarted"
  >
    <div className={style.unstarted}>
      <Link to={segmentRoute(segment)} data-test-segment-link={segment.id}>
        <div className={style.iconStatus}>
          <UnstartedSegmentIcon />
          <div className={style.line} />
        </div>
        <div className={style.title} data-test-segment-long-title={segment.longTitle}>
          <NotTranslated text={segment.longTitle} />
        </div>
        <div className={style.stats}>
          <div className={style.statsContent}>
            <SegmentInfo segment={segment} />
          </div>
        </div>
      </Link>
    </div>
  </li>
);

export default UnstartedSegment;
