import { Button } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import EmailField from '../../../Form/EmailField';
import { InvitationStore } from '../InvitationStore';
import {
  AuthenticatingEmailDomain,
  PendingEmailConfirmation,
  RequestingEmailAddress,
} from '../InvitationStore/Types';

interface Props {
  store: InvitationStore;
  state: RequestingEmailAddress | AuthenticatingEmailDomain | PendingEmailConfirmation;
}

function EmailForm({ store, state }: Props) {
  const submit = (store: InvitationStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.authenticatingEmailDomain();
  };

  return (
    <form
      data-testid="shared-form"
      className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
      onSubmit={submit(store)}
    >
      <div className="flex sm:gap-6">
        <div className="basis-full sm:basis-1/2">
          <EmailField
            data-testid="shared-form_email-text-field"
            value={state.emailAddress}
            disabled={!store.emailInputEnabled}
            onChange={store.setEmailAddress}
          />
        </div>
        <div className="sm:basis-1/2" />
      </div>
      <div className="mt-6">
        <Button
          data-testid="shared-form_get-access-button"
          type="submit"
          size="lg"
          radius="sm"
          color="success"
        >
          <T kind="Get Access" />
        </Button>
      </div>
    </form>
  );
}

export default observer(EmailForm);
