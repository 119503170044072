import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../ProgramStore/Types';
import * as style from '../../../ProgramSummary/style.module.css';
import ProgramModuleProgressContent from './ProgramModuleProgressContent';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
}

const ProgramModuleProgress: React.FC<Props> = ({ programModule, programDetail }) => {
  const percentComplete = programModule.percentComplete;
  const expectedPercentComplete = programDetail.daysBehind.cata({
    Just: () => programModule.expectedPercentComplete.getOrElseValue(0) - percentComplete,
    Nothing: () => 0,
  });
  const percentIncomplete = 100 - percentComplete - expectedPercentComplete;
  const userAtEndOfModule = percentComplete >= 100;
  const cohortAtEndOfModule = expectedPercentComplete >= 100;
  return (
    <li
      className={style.module}
      data-test-module-progress={programModule.id}
      data-user-complete={userAtEndOfModule}
      data-cohort-complete={cohortAtEndOfModule}
    >
      <ProgramModuleProgressContent
        key={programModule.id}
        expectedPercentComplete={expectedPercentComplete}
        percentComplete={percentComplete}
        percentIncomplete={percentIncomplete}
        programModule={programModule}
        programDetail={programDetail}
      />
    </li>
  );
};

export default ProgramModuleProgress;
