import { observer } from 'mobx-react';
import { handleEnterOrSpaceKeyPress } from '../../../../../Accessibility/KeyboardEvents';
import { ActiveProgramModule, Program } from '../../../../../ProgramStore/Types';
import { T } from '../../../../../Translations';
import CohortGroupIcon from '../../../../Cutesies/CohortGroupIcon';
import CohortPositionIcon from '../../../../Cutesies/CohortPositionIcon';
import * as style from '../../../../ProgramSummary/style.module.css';
import ProgramModal from './ProgramModal';
import CohortPositionWarningMessage from './CohortPositionWarningMessage';

interface Props {
  onToggle: () => void;
  programDetail: Program;
  programModule: ActiveProgramModule;
  isModalOpen: boolean;
}

const CohortPosition = observer(
  ({ onToggle, programDetail, programModule, isModalOpen }: Props) => {
    const daysBehind =
      programDetail.kind == 'active' ? programDetail.daysBehind.getOrElseValue(0) : 0;
    const cohortStyle =
      daysBehind > 13
        ? {
            line: style.cohortProgressLineDanger,
            tooltip: style.cohortProgressDangerTooltip,
            arrow: style.cohortProgressDangerTooltipArrow,
          }
        : {
            line: style.cohortProgressLine,
            tooltip: style.cohortProgressTooltip,
            arrow: style.cohortProgressTooltipArrow,
          };

    return (
      <>
        <div className={cohortStyle.line} data-current={true}>
          <div className={style.cohortCurrentPosition}>
            <CohortPositionIcon />
          </div>
          <div
            className={cohortStyle.tooltip}
            data-test-progress-tooltip={true}
            onClick={onToggle}
            tabIndex={0}
            onKeyPress={handleEnterOrSpaceKeyPress(onToggle)}
          >
            <span className={style.cohortGroupIcon}>
              <CohortGroupIcon />
            </span>
            <span>
              <T kind="Your Cohort" />
            </span>
            <div className={cohortStyle.arrow} />
          </div>
        </div>
        <CohortPositionWarningMessage program={programDetail} />
        {isModalOpen && (
          <ProgramModal
            programDetail={programDetail}
            programModule={programModule}
            onClose={onToggle}
          />
        )}
      </>
    );
  },
);

export default CohortPosition;
