import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../ProgramStore';
import { ActiveProgram, ProgramDetailResource } from '../../ProgramStore/Types';
import ActiveProgramFullOverview from './ActiveProgramFullOverview';
import ActiveProgramStreamlinedOverview from './ActiveProgramStreamlinedOverview';

interface Props {
  activeProgram: ActiveProgram;
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
}

const ActiveProgramOverview: React.FC<Props> = ({
  activeProgram,
  programDetailResource,
  store,
}) => {
  const productDetails = programDetailResource.payload.productDetails.payload;
  return programDetailResource.payload.presentationStyle
    .map((presentationStyle) => {
      switch (presentationStyle) {
        case 'Full':
          return (
            <ActiveProgramFullOverview
              productDetails={productDetails}
              store={store}
              programDetailResource={programDetailResource}
              activeProgram={activeProgram}
            />
          );
        case 'Embedded Streamlined':
        case 'Streamlined':
          return (
            <ActiveProgramStreamlinedOverview
              productDetails={productDetails}
              store={store}
              activeProgram={activeProgram}
              programDetailResource={programDetailResource}
            />
          );
      }
    })
    .getOrElse(() => <></>);
};

export default observer(ActiveProgramOverview);
