import { observer } from 'mobx-react';
import { NonEmptyList, fromArrayMaybe } from 'nonempty-list';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { AvailabilityResource, AvailabilityResources } from '../../../../Types';
import { getScheduledAvailabilities } from '../../../../Availability';

interface Props {
  availabilities: AvailabilityResources;
}

function ScheduledAvailability({ availabilities }: Props) {
  const filtered = getScheduledAvailabilities(availabilities);

  return fromArrayMaybe(filtered)
    .map((list: NonEmptyList<AvailabilityResource>) => (
      <T kind="{{count}} Date" count={list.length} />
    ))
    .getOrElse(emptyFragment);
}

export default observer(ScheduledAvailability);
