import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import ProfessorsStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { professorsResourceDecoder } from './Decoders';
import { ProfessorsState } from './Types';

const professorsEndPoint = callApi(professorsResourceDecoder, {});

type ProfessorsReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<ProfessorsStore> {
  store: ProfessorsStore;
}

type ProfessorRequestError = MissingLinkError | AppyError;

const handleProfessorRequestError = (store: ProfessorsStore) => (error: ProfessorRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Professors link in unavailable');
      break;
    default:
      handleError(store, error);
  }
};

class ProfessorsReactions extends ErrorActionableReaction<ProfessorsStore, ProfessorsState, Props> {
  tester = () => this.props.store.state;

  effect = (state: ProfessorsState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        Task.succeed<ProfessorsReactionError, ReadonlyArray<Link>>(
          state.programDetailResource.links,
        )
          .andThen(findLinkT('professors'))
          .andThen(professorsEndPoint)
          .fork(handleProfessorRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProfessorsReactions;
