import { just, Maybe, nothing } from 'maybeasy';
import { Link, Resource } from '../../Resource/Types';
import {
  ProgressiveLow,
  VideoPlaybackQuality,
  VideoPlaybackSource,
  VideoPlaybackType,
  VideoSourceFile,
} from '../JWPlayer/Types';

export interface Vimeo {
  kind: 'vimeo';
  vimeoId: number;
}

export interface MissingProgressiveLow {
  kind: 'missing-progressive-low';
  label: 'Low';
}

export interface MissingProgressiveMedium {
  kind: 'missing-progressive-medium';
  label: 'Medium';
}

export interface MissingProgressiveHigh {
  kind: 'missing-progressive-high';
  label: 'High';
}

export interface MissingVimeoId {
  kind: 'missing-vimeo-id';
}

export interface MissingAdaptiveSources {
  kind: 'missing-adaptive-sources';
}

export interface ProgressivePlayer {
  kind: 'progressive';
  sources: VideoSourceFile[];
}

export interface Adaptive {
  kind: 'adaptive';
  file: string;
  fallbackSources: VideoSourceFile[];
}

export interface NoSelection {
  kind: 'no-selection';
}

export interface Undetermined {
  kind: 'undetermined';
}

export type SelectedPlayer =
  | Undetermined
  | Vimeo
  | ProgressiveLow
  | ProgressivePlayer
  | Adaptive
  | NoSelection;

export type VideoSelectionFailure =
  | MissingVimeoId
  | MissingAdaptiveSources
  | MissingProgressiveLow
  | MissingProgressiveMedium
  | MissingProgressiveHigh;

export interface PreferredVideoPlayback {
  type: VideoPlaybackType;
  qualities: VideoPlaybackQuality[];
}

export type SubtitleKind = 'auto-load-on' | 'auto-load-off';

export type LaunchPosition = 'launch-at-start' | 'launch-at-end' | 'pause-launch' | 'never-launch';

export type SurveyStatus = 'pending' | 'dismissed' | 'completed';

export type TopicViewStatus = 'seen' | 'not_seen';

export const isAutoLoadOn = <T extends { kind: SubtitleKind }>({ kind }: T): boolean =>
  kind === 'auto-load-on';

export const autoLoadIndexT =
  <S extends { kind: SubtitleKind }, R>(subtitles: (resource: R) => ReadonlyArray<S>) =>
  (resource: R): Maybe<number> => {
    const index = subtitles(resource).findIndex(isAutoLoadOn);

    const realIndex = index + 1;
    return index === -1 ? nothing() : just(realIndex);
  };

export interface Subtitle {
  label: string;
  kind: SubtitleKind;
}

export interface VideoPlaybackAdaptiveSources {
  file: string;
  fallbackSources: VideoPlaybackSource[];
  thumbnails: Maybe<string>;
}

export interface LowProgressiveStream {
  label: 'Low';
  default: boolean;
}

export interface MediumProgressiveStream {
  label: 'Medium';
  default: boolean;
}

export interface HighProgressiveStream {
  label: 'High';
  default: boolean;
}

export interface VideoPlaybackSubtitles {
  file: string;
  label: string;
  kind: SubtitleKind;
}

export interface SurveyCompletionTracking {
  id: number;
  surveyStatus: SurveyStatus;
  launchPosition: LaunchPosition;
}

export interface ReqHlsVideoAsset {
  uuid: string;
  kind: 'req-hls-video-asset';
  title: Maybe<string>;
  id: number;
  surveyCompletionTracking: Maybe<SurveyCompletionTrackingResource>;
  courseRegProgramId: Maybe<number>;
  vimeoId: Maybe<number>;
  mediaId: Maybe<string>;
  preferredVideoPlayback: PreferredVideoPlayback;
  lowProgressiveStream: Maybe<LowProgressiveStreamResource>;
  mediumProgressiveStream: Maybe<MediumProgressiveStreamResource>;
  highProgressiveStream: Maybe<HighProgressiveStreamResource>;
  subtitles: SubtitleResource[];
}

export interface OverviewVideo {
  uuid: string;
  id: number;
  title: Maybe<string>;
  vimeoId: Maybe<number>;
  mediaId: Maybe<string>;
  highProgressiveStream: HighProgressiveStreamResource;
  lowProgressiveStream: LowProgressiveStreamResource;
  mediumProgressiveStream: MediumProgressiveStreamResource;
  subtitles: SubtitleResource[];
  preferredVideoPlayback: PreferredVideoPlayback;
}

export type OverviewVideoResource = Resource<OverviewVideo>;

export type ReqHlsVideoAssetResource = Resource<ReqHlsVideoAsset>;

export type LowProgressiveStreamResource = Resource<LowProgressiveStream>;

export type MediumProgressiveStreamResource = Resource<MediumProgressiveStream>;

export type HighProgressiveStreamResource = Resource<HighProgressiveStream>;

export type SubtitleResource = Resource<Subtitle>;

export type SurveyCompletionTrackingResource = Resource<SurveyCompletionTracking>;

export const progressiveLow = (sources: VideoSourceFile[]): ProgressiveLow => ({
  kind: 'progressive-low',
  sources: sources,
});

export const vimeo = (vimeoId: number): Vimeo => ({ kind: 'vimeo', vimeoId });

type AdaptiveParams = {
  file: Link;
  fallbackSources: VideoSourceFile[];
};

export const adaptive = ({ file, fallbackSources }: AdaptiveParams): Adaptive => ({
  kind: 'adaptive',
  file: file.href,
  fallbackSources: fallbackSources,
});

export const noSelection = (): NoSelection => ({ kind: 'no-selection' });

export const undetermined = (): Undetermined => ({ kind: 'undetermined' });

export const missingVimeoId = (): MissingVimeoId => ({ kind: 'missing-vimeo-id' });

export const missingAdaptiveSources = (): MissingAdaptiveSources => ({
  kind: 'missing-adaptive-sources',
});

export const missingProgressiveLow = (): MissingProgressiveLow => ({
  kind: 'missing-progressive-low',
  label: 'Low',
});

export const missingProgressiveMedium = (): MissingProgressiveMedium => ({
  kind: 'missing-progressive-medium',
  label: 'Medium',
});

export const missingProgressiveHigh = (): MissingProgressiveHigh => ({
  kind: 'missing-progressive-high',
  label: 'High',
});

export const progressivePlayer = (sources: VideoSourceFile[]): ProgressivePlayer => ({
  kind: 'progressive',
  sources,
});
