import { cn, emptyFragment, Typography, useMediaQuery } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { fromNullable, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { getTitleVariant } from './Types';

interface Props {
  title: Maybe<AlreadyTranslatedText>;
  className?: string;
}

function ExperienceTitle({ title, className }: Props) {
  const breakPoint = useMediaQuery();

  const handleElementRef = (element: HTMLElement | null) => {
    fromNullable(element).do((element) => element.focus());
  };

  return title
    .map((title) => (
      <Typography
        tabIndex={-1}
        ref={handleElementRef}
        className={cn('mb-4 leading-normal text-black sm:mb-8', className)}
        variant={getTitleVariant(breakPoint)}
        as="h3"
      >
        <AlreadyTranslated content={title} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceTitle);
