import { always } from '@kofno/piper';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReactions from '../../ChatMessageStore/ChatReactions';
import ConversationStore from '../../ConversationStore';
import ConversationAPIReactions from '../../ConversationStore/ConversationAPIReactions';
import ConversationUIReactions from '../../ConversationStore/ConversationUIReactions';
import {
  authoredBy,
  ChatablePersonResourceList,
  MessageGroup,
  whenFirstLoaded,
} from '../../ConversationStore/Types';
import { CurrentUserResource } from '../../CurrentUser/Types';
import ScrollStore from '../../ScrollStore';
import { scrollEvent, whenAtBottom } from '../../ScrollStore/Types';
import NewChatMessage from '../NewChatMessage';
import ChatScrollReactions from '../TeamProfile/TeamChat/ChatScrollReaction';
import MoreMessages from '../TeamProfile/TeamChat/MoreMessages';
import ScrollToNewMessage from '../TeamProfile/TeamChat/ScrollToNewMessage';
import TeamChatMessageGroup from '../TeamProfile/TeamChat/TeamChatMessageGroup';
import CoachChatMessageForm from './CoachChatMessageForm';
import * as style from './style.module.css';

interface Props {
  conversationStore: ConversationStore;
  chatablePersonResourceList: ChatablePersonResourceList;
  currentUserResource: CurrentUserResource;
}

interface State {
  showScrollToBottomButton: boolean;
}

@observer
class CoachChatImpl extends React.Component<Props, State> {
  messageListEl: React.RefObject<HTMLDivElement> = React.createRef();
  scrollObserver: Maybe<MutationObserver> = nothing();
  scrollStore = new ScrollStore(20);

  constructor(props: Props) {
    super(props);
    this.scrollObserver = nothing();
  }

  componentDidMount() {
    this.scrollToBottomObserver();
    this.scrollObserver = just(new MutationObserver(this.scrollToBottomObserver));
    const el = this.messageListEl.current;
    if (el) {
      this.scrollObserver.do((observer) => observer.observe(el, { childList: true }));
    }
  }

  componentWillUnmount() {
    this.scrollObserver.do((observer) => observer.disconnect());
  }

  shouldScrollToLatestUnseen = (): Maybe<'bottom'> => {
    const { conversationStore } = this.props;
    const { scrollPosition } = this.scrollStore;

    return conversationStore.unseenMessage
      .andThen((message) =>
        authoredBy(this.props.currentUserResource.payload.id)(message).orElse(() =>
          scrollPosition.andThen(whenAtBottom).map(always(message))
        )
      )
      .map(always('bottom'));
  };

  scrollToBottomObserver = () => {
    const { conversationStore } = this.props;

    this.shouldScrollToLatestUnseen()
      .orElse(whenFirstLoaded(conversationStore))
      .do(this.scrollToBottom);
  };

  scrollToBottom = () => {
    fromNullable(this.messageListEl.current).map((el) => (el.scrollTop = el.scrollHeight));
  };

  onScroll = (divElement: React.UIEvent<HTMLDivElement>) => {
    const { conversationStore } = this.props;
    this.scrollStore.scrolling(scrollEvent(divElement));
    conversationStore.unseenMessage
      .andThen(() => this.scrollStore.scrollPosition)
      .andThen((pos) => {
        switch (pos) {
          case 'middle':
          case 'near-bottom':
          case 'top':
          case 'near-top':
            return nothing();
          case 'bottom':
            return just(pos);
        }
      })
      .do(conversationStore.clearUnseenMessage);
  };

  render() {
    const { conversationStore } = this.props;

    return (
      <>
        <div className={style.scroller} ref={this.messageListEl} onScroll={this.onScroll}>
          <MoreMessages store={conversationStore} />
          {conversationStore.messageResourceGroups.map((group: MessageGroup) => (
            <TeamChatMessageGroup
              group={group}
              presenceStore={nothing()}
              key={group.dateStamp}
              conversationStore={conversationStore}
              lastReadMessageStore={nothing()}
            />
          ))}
          <MoreMessages store={conversationStore} />
        </div>
        {this.scrollStore.scrollPosition
          .map((scrollPosition) => (
            <ScrollToNewMessage
              scrollPosition={scrollPosition}
              conversationStore={conversationStore}
              scrollToBottom={this.scrollToBottom}
              className={style.scrollToNewMessageButton}
              currentUserResource={this.props.currentUserResource}
            />
          ))
          .getOrElseValue(<span />)}
        <NewChatMessage store={conversationStore.chatMessageStore}>
          {{
            form: <CoachChatMessageForm store={conversationStore.chatMessageStore} />,
          }}
        </NewChatMessage>

        <ChatReactions
          store={conversationStore.chatMessageStore}
          conversationStore={conversationStore}
          lastReadMessageStore={nothing()}
        />
        <ConversationUIReactions store={conversationStore} messageListRef={this.messageListEl} />
        <ConversationAPIReactions store={conversationStore} />
        <ChatScrollReactions conversationStore={conversationStore} store={this.scrollStore} />
      </>
    );
  }
}
export default CoachChatImpl;
