import { observer } from 'mobx-react';
import Login from '.';
import { Typography } from '../../components/UI';
import { T } from '../../Translations';
import MailToLink from '../AEP/MailToLink';
import { ForgotPasswordField } from '../Form';

const supportEmail = 'support@execonline.com';

function LockoutNotice() {
  return (
    <Login>
      <Typography className="mb-10">
        <T
          kind="If this email address is in our database you ..."
          emailAddress={
            <MailToLink email={supportEmail}>
              <Typography
                className="hover:underline"
                component="span"
                color="inherit"
                fontWeight="medium"
              >
                {supportEmail}
              </Typography>
            </MailToLink>
          }
        />
      </Typography>
      <div className="my-5">
        <ForgotPasswordField />
      </div>
    </Login>
  );
}

export default observer(LockoutNotice);
