import { when } from '@execonline-inc/maybe-adapter';
import { fromEmpty } from 'maybeasy';
import { CarouselPage } from '../Types';
import { VisibilityNavigationButton } from './Types';

export function getNavigationButtonStatus<T>(items: ReadonlyArray<CarouselPage<T>>) {
  return fromEmpty(items).isNothing();
}

export function visibilityNavigationButton(
  perPageCount: number,
  totalCount: number,
): VisibilityNavigationButton {
  return when<VisibilityNavigationButton>(totalCount <= perPageCount, 'hide').getOrElseValue(
    'show',
  );
}
