import * as React from 'react';

export interface SelectOption {
  value: string;
  content: string;
}

type Props = SelectOption;

const Option: React.FC<Props> = (props: Props) => {
  return <option value={props.value}>{props.content}</option>;
};

export default Option;
