import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import { VideoSourceFile } from '../JWPlayer/Types';
import JWPlayer from './JWPlayer';
import { playerOptions } from './JWPlayer/Types';
import { ReqHlsVideoAssetResource } from './Types';

interface Props {
  id: string;
  className: string;
  videoResource: ReqHlsVideoAssetResource;
  kettle: Kettle;
  width: string | number;
  height: string | number;
  dynamicallySetAspectRatio?: boolean;
  adaptiveFile: string;
  fallbackSources: VideoSourceFile[];
}

/**
 * AdaptivePlayer wraps JWPlayer and defaults to attempting to play the
 * adaptive stream specified by the file property on the top-level of the
 * adaptive sources.
 *
 * By passing along fallback sources, JWPlayer will attempt those progressive
 * sources if the adaptive stream fails.
 */
const ReqHlsAdaptivePlayer: React.FC<Props> = ({
  videoResource,
  id,
  className,
  kettle,
  width,
  height,
  dynamicallySetAspectRatio,
  adaptiveFile,
  fallbackSources,
}) => {
  const options = playerOptions(videoResource, {
    width: width,
    height: height,
    dynamicallySetAspectRatio,
    sources: [],
    file: adaptiveFile,
  });

  return (
    <JWPlayer
      id={id}
      className={className}
      kettle={kettle}
      options={options}
      fallbackSources={fallbackSources}
    />
  );
};

export default observer(ReqHlsAdaptivePlayer);
