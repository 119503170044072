import SegmentStore from '../../../../../../SegmentStore';
import { CoachSelectFormField, SelectFieldValue } from '../../../../Types';

interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
  segmentStore: SegmentStore;
  formField: CoachSelectFormField;
}

interface Ready {
  kind: 'ready';
  formField: CoachSelectFormField;
  value: SelectFieldValue[];
}

interface AutoSaving {
  kind: 'auto-saving';
  formField: CoachSelectFormField;
  value: SelectFieldValue[];
}

export type State = Waiting | Initializing | Ready | AutoSaving;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const initializing = (
  segmentStore: SegmentStore,
  formField: CoachSelectFormField,
): Initializing => ({
  kind: 'initializing',
  segmentStore,
  formField,
});

export const ready = (formField: CoachSelectFormField, value: SelectFieldValue[]): Ready => ({
  kind: 'ready',
  formField,
  value,
});

export const autoSaving = (
  formField: CoachSelectFormField,
  value: SelectFieldValue[],
): AutoSaving => ({
  kind: 'auto-saving',
  formField,
  value,
});
