import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { CurrentUserResource } from '../../CurrentUser/Types';
import history from '../../History';
import UnifiedLogin from '../../UnifiedLogin';
import Platform from '../Platform';

interface Props {
  currentUserResource: CurrentUserResource;
}

const AuthenticatedRoutes: React.FC<Props> = ({ currentUserResource }) => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/unified-login/:appLinkRel"
        render={(props) => <UnifiedLogin appLinkRel={props.match.params.appLinkRel} />}
      />
      <Route path="*" render={() => <Platform currentUserResource={currentUserResource} />} />
    </Switch>
  </Router>
);

export default observer(AuthenticatedRoutes);
