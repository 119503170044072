import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Channel } from 'pusher-js';
import { assertNever } from '@kofno/piper';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import PaymentWizardStore from '../PaymentWizardStore';

interface Props {
  channel: Channel;
  store: PaymentWizardStore;
}

function PresenceBinding({ channel, store }: Props) {
  useEffect(() => {
    channel.bind('succeeded', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'message-received':
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('succeeded', responseBody, store.state.paymentIntentId);
          break;
        case 'initializing':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
        case 'payment-still-processing':
          break;
        default:
          assertNever(store.state);
      }
    });

    channel.bind('processing', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('processing', responseBody, store.state.paymentIntentId);
          break;
        case 'initializing':
        case 'message-received':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
        case 'payment-still-processing':
          break;
        default:
          assertNever(store.state);
      }
    });

    channel.bind('processing-timed-out', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
          store.messageReceived('processing-timed-out', responseBody, store.state.paymentIntentId);
          break;
        case 'initializing':
        case 'message-received':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
        case 'payment-still-processing':
          break;
        default:
          assertNever(store.state);
      }
    });

    channel.bind('server-error', (responseBody: unknown) => {
      switch (store.state.kind) {
        case 'waiting-for-message':
        case 'request-payment-status':
        case 'payment-status-requested':
        case 'message-received':
          store.messageReceived('error', responseBody, store.state.paymentIntentId);
          break;
        case 'initializing':
        case 'paid-and-enrolled':
        case 'ready-to-redirect':
        case 'error':
        case 'pusher-error':
        case 'enrollment-error':
        case 'payment-error':
        case 'payment-still-processing':
          break;
        default:
          assertNever(store.state);
      }
    });

    return () => {
      channel.unbind_all();
    };
  }, []);

  return emptyFragment();
}

export default observer(PresenceBinding);
