import * as React from 'react';
import { T, TPlainTextKey } from '../../Translations';
import * as style from '../Conference/style.module.css';
import LowerHandIcon from '../Cutesies/LowerHandIcon';
import RaiseHandIcon from '../Cutesies/RaiseHandIcon';

type HandState = 'raised' | 'lowered';

interface Props {
  handState: HandState;
  disabled: boolean;
  onClick: () => void;
}

interface ButtonProps {
  text: TPlainTextKey;
  style: string;
  icon: JSX.Element;
}

const buttonProps = (handState: HandState): ButtonProps => {
  switch (handState) {
    case 'raised':
      return {
        text: 'Lower Hand',
        style: style.lowerHandButton,
        icon: <LowerHandIcon />,
      };
    case 'lowered':
      return {
        text: 'Raise Hand',
        style: style.raiseHandButton,
        icon: <RaiseHandIcon fill="#FFFFFF" />,
      };
  }
};

const HandButton: React.FC<Props> = ({ handState, disabled, onClick }) => {
  const bp = buttonProps(handState);
  return (
    <div className={style.handButtonWrapper}>
      <button
        type="button"
        className={bp.style}
        onClick={onClick}
        data-test-hand={handState}
        disabled={disabled}
      >
        <T kind={bp.text} />
        <div className={style.buttonIcon}>{bp.icon}</div>
      </button>
    </div>
  );
};
export default HandButton;
