import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { eventConferenceUrl } from '../../../ClientRoutes';
import { LocalConferenceRoomResource } from '../../../Conference/Types';
import PollsModalStore from '../../../EventPolls/PollsModal/PollsModalStore';
import { EventResource } from '../../../EventsStore/Types';
import { T } from '../../../Translations';
import ConferenceLink from '../../EventConference/ConferenceLink';
import * as style from '../style.module.css';
import PollLauncher from './PollLauncher';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
  eventResource: EventResource;
  pollsModalStore: PollsModalStore;
}

const MainChatTitle: React.FC<Props> = ({
  conferenceRoomResource,
  eventResource,
  pollsModalStore,
}) => (
  <div className={style.roomHeader}>
    <div className={style.roomDetails}>
      <h3>
        <T kind="Main Room" />
      </h3>
      <p>
        <T kind="Room: {{roomId}}" roomId={conferenceRoomResource.payload.conferenceId} />
      </p>
      {conferenceRoomResource.payload.conferencePin
        .map((pin) => (
          <p>
            <T kind="Pin: {{pin}}" pin={pin} />
          </p>
        ))
        .getOrElse(emptyFragment)}
    </div>
    <div className={style.roomActions}>
      <div className={style.roomPollButtonContainer}>
        <PollLauncher
          eventResource={eventResource}
          conferenceRoomResource={conferenceRoomResource}
          pollsModalStore={pollsModalStore}
        />
      </div>
      <ConferenceLink to={eventConferenceUrl(eventResource.payload.id)}>
        <button className={style.joinButton}>
          <T kind="Join Main Room" />
        </button>
      </ConferenceLink>
    </div>
  </div>
);

export default observer(MainChatTitle);
