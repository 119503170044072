import { warn } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { Task } from 'taskarian';
import SetPasswordStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps } from '../../../../ErrorActionableReaction';
import { handleAppyError } from '../../../../ErrorHandling';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import { establishSession } from '../../../../Session';
import { sessionResourceDecoder } from '../../../../Session/Reactions';
import { validationErrorsDecoder } from './Decoders';
import { State } from './Types';
export interface Props extends EAProps<SetPasswordStore> {
  store: SetPasswordStore;
  links: ReadonlyArray<Link>;
}

type CreateUserAccountError = MissingLinkError | AppyError;

const handleCreateUserAccountError = (store: SetPasswordStore) => (err: CreateUserAccountError) => {
  switch (err.kind) {
    case 'missing-link-error':
      store.error('Missing Link');
      break;
    case 'bad-status':
      validationErrorsDecoder
        .decodeJson(err.response.body)
        .map((b) => fromArrayMaybe(b.errors).map(({ first }) => first.detail))
        .andThen(toResult('Account creation failed for an unknown reason'))
        .do((err) => {
          warn(`Account creation failed: ${err}`);
          store.serverError({ kind: 'already-translated-text', text: err });
        })
        .elseDo(() => store.error(handleAppyError(err)));
      break;
    default:
      store.error(handleAppyError(err));
  }
};

class SetPasswordStoreReactions extends ErrorActionableReaction<SetPasswordStore, State, Props> {
  tester = (): State => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'creating':
        Task.succeed<CreateUserAccountError, ReadonlyArray<Link>>(this.props.links)
          .andThen(findLinkT('update'))
          .andThen(callApi(sessionResourceDecoder, { password: state.password }))
          .map(({ payload }) => payload)
          .do(establishSession)
          .fork(handleCreateUserAccountError(this.props.store), this.props.store.created);
        break;
      case 'error':
        this.props.store.readyWithClientErrors(state.termsAcceptance, state.message);
        break;
      case 'server-error':
        this.props.store.readyWithServerErrors(state.termsAcceptance, state.message);
        break;
      case 'waiting':
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'created':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(SetPasswordStoreReactions);
