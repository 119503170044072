import * as React from 'react';
import { ProgramSegment } from '../../../ProgramStore/Types';
import { NotTranslated } from '../../../Translations';
import CompleteSegmentIcon from '../../Cutesies/CompleteSegmentIcon';
import CurrentSegmentIcon from '../../Cutesies/CurrentSegmentIcon';
import UnstartedSegmentIcon from '../../Cutesies/UnstartedSegmentIcon';
import TimeToComplete from '../../TimeToComplete';
import * as style from '../style.module.css';

interface Props {
  segment: ProgramSegment;
}

type RenderStatus = 'Current' | ProgramSegment['status'];

const OutlineSegment: React.FC<Props> = ({ segment }) => {
  const renderStatus = (): RenderStatus => (segment.isCurrentSegment ? 'Current' : segment.status);

  const segmentClass = () => {
    const status = renderStatus();
    switch (status) {
      case 'Current':
        return style.current;
      case 'Complete':
        return style.complete;
      case 'Inaccessible':
      case 'Accessible':
      case 'Started':
        return style.inaccessible;
    }
  };

  const SegmentIcon: React.FC = () => {
    const status = renderStatus();
    switch (status) {
      case 'Current':
        return <CurrentSegmentIcon />;
      case 'Complete':
        return <CompleteSegmentIcon />;
      case 'Inaccessible':
      case 'Accessible':
      case 'Started':
        return <UnstartedSegmentIcon />;
    }
  };

  return (
    <li className={style.segment} data-test-outline-segment={segment.id}>
      <div className={segmentClass()}>
        <div className={style.segmentIcon} data-test-segment-icon={renderStatus()}>
          <SegmentIcon />
          <div className={style.segmentLine} />
        </div>
        <div className={style.segmentLabel} data-test-segment-title={segment.title}>
          <NotTranslated text={segment.title} />
          <TimeToComplete ttc={segment.timeToComplete} />
        </div>
      </div>
    </li>
  );
};
export default OutlineSegment;
