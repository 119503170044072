import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { T } from '../../../../Translations';
import SupportLink from '../../../AEP/SupportLink/SupportLink';
import { ExperienceResource } from '../../Common/Experience/Types';
import LmsContent from './LmsContent';
import Store from './Store';

interface Props {
  experienceResource: ExperienceResource;
  store: Store;
}

const DegreedContent: React.FC<Props> = ({ store, experienceResource }) => (
  <LmsContent experienceResource={experienceResource} store={store}>
    <Typography color="white" className="px-4">
      <T
        kind='Welcome Degreed learners! Enroll in an Experience below to begin. When you complete your Experience, your status will be automatically reported to Degreed as a "Verified Completion." Check out our <link>Degreed Learner Guide</link> for a full overview.'
        link={(content) => (
          <SupportLink link="https://support.execonline.com/s/article/Degreed-Learning-Management-System">
            <Typography className="underline" as="p" color="white">
              {content}
            </Typography>
          </SupportLink>
        )}
      />
    </Typography>
  </LmsContent>
);

export default observer(DegreedContent);
