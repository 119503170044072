import { MissingVarError, readVarT } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { Task } from 'taskarian';
import { errorMessage } from '../../../ExceptionHandling';
import { detectBrowser } from '../../../UserAgent';
import { JWPlayerFn, JWPlayerLoadError, jwPlayerLoadError } from '../../JWPlayer/Types';

export const jwPlayerUrl = (): Task<MissingVarError, string> => {
  const browserDetected = detectBrowser(window.navigator.userAgent);
  switch (browserDetected.kind) {
    case 'trident':
      return browserDetected.versionNumber === 11
        ? readVarT('REACT_APP_JW_PLAYER')
        : readVarT('REACT_APP_JW_PLAYER_8');
    case 'electron':
    case 'msie':
    case 'edge':
    case 'safari':
    case 'other':
      return readVarT('REACT_APP_JW_PLAYER_8');
  }
};

export const jwPlayerKey = (): Task<MissingVarError, string> => {
  const browserDetected = detectBrowser(window.navigator.userAgent);
  switch (browserDetected.kind) {
    case 'trident':
      return browserDetected.versionNumber === 11
        ? readVarT('REACT_APP_JW_PLAYER_KEY')
        : readVarT('REACT_APP_JW_PLAYER_KEY_8');
    case 'electron':
    case 'msie':
    case 'edge':
    case 'safari':
    case 'other':
      return readVarT('REACT_APP_JW_PLAYER_KEY_8');
  }
};

export const loadJWPlayer = (url: string, key: string): Task<JWPlayerLoadError, JWPlayerFn> => {
  return new Task<JWPlayerLoadError, JWPlayerFn>((reject, resolve) => {
    try {
      if (window.jwplayer) {
        resolve(window.jwplayer);
      } else {
        const script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.onload = () => {
          if (window.jwplayer) {
            window.jwplayer.key = key;
            resolve(window.jwplayer);
          } else {
            reject(jwPlayerLoadError('`window.jwplayer` not loaded by script'));
          }
        };
        if (document.head) {
          document.head.appendChild(script);
        }
      }
    } catch (e) {
      reject(jwPlayerLoadError(errorMessage(e)));
    }

    return () => {
      warn('Loading the JWPlayer API cannot be canceled');
    };
  });
};
