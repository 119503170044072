import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useEnrollmentModalContext } from '../../Common';
import EnrollableFooter from './EnrollableFooter';

function EnrollmentModalFooter() {
  const { experienceResource } = useEnrollmentModalContext();

  switch (experienceResource.payload.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return <EnrollableFooter />;
    case 'beginnable':
    case 'graduated':
    case 'resumable':
    case 'returnable':
    case 'upcoming':
      return emptyFragment();
  }
}

export default observer(EnrollmentModalFooter);
