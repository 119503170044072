import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DateCheckIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 22 23">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M4 0v1H0v22h22V1h-4V0h-2v1H6V0H4ZM2 3h2v1h2V3h10v1h2V3h2v2H2V3Zm0 4h18v14H2V7Zm13.281 2.281L10 14.562l-2.281-2.28-1.438 1.437 3 3 .719.687.719-.687 6-6L15.28 9.28Z"
      />
    </SVGIcon>
  );
};

export default observer(DateCheckIcon);
