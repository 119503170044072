import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, date, field, number, oneOf, string, succeed } from 'jsonous';
import { Maybe, nothing } from 'maybeasy';
import { profileResourceDecoder } from '../ProfileStore/Decoders';
import { resourceDecoder } from '../Resource/Decoders';
import { sanitizedHtmlDecoder } from '../components/LinkifyWithCharEntities';
import {
  Author,
  AuthorResource,
  ChatMessage,
  ChatMessageOffensiveReport,
  ChatMessageOffensiveReportsResource,
  ChatMessageReaction,
  ChatMessageReactionsResource,
  ChatMessageRepliesResource,
  ChatMessageReply,
  ChatMessageReplyResource,
  ChatMessageResource,
  ChatMessagesResource,
  MessageEmbedded,
  MessageReplyEmbedded,
  MessageSubscriptionStatus,
  Subscription,
  SubscriptionResource,
} from './Types';

export const subscriptionStatusDecoder: Decoder<MessageSubscriptionStatus> = oneOf([
  stringLiteral<MessageSubscriptionStatus>('subscribed'),
  stringLiteral<MessageSubscriptionStatus>('unsubscribed'),
  stringLiteral<MessageSubscriptionStatus>('ineligible'),
]);

export const subscriptionDecoder: Decoder<Subscription> = succeed({})
  .assign('messageId', field('message_id', number))
  .assign('status', field('status', subscriptionStatusDecoder));

export const subscriptionResourceDecoder: Decoder<SubscriptionResource> = resourceDecoder(
  subscriptionDecoder
);

export const chatMessageReactionDecoder: Decoder<ChatMessageReaction> = succeed({})
  .assign('userId', field('user_id', number))
  .assign('emoji', field('reaction', string));

export const chatMessageReactionsResourceDecoder: Decoder<ChatMessageReactionsResource> = resourceDecoder(
  array(resourceDecoder(chatMessageReactionDecoder))
);

export const chatMessageOffensiveReportDecoder: Decoder<ChatMessageOffensiveReport> = succeed({});

export const chatMessageOffensiveReportsResourceDecoder: Decoder<ChatMessageOffensiveReportsResource> = resourceDecoder(
  array(resourceDecoder(chatMessageOffensiveReportDecoder))
);

export const alwaysEmptyRepliesDecoder: Decoder<Maybe<ChatMessageRepliesResource>> = succeed(
  nothing<ChatMessageRepliesResource>()
);

export const chatMessageReplyDecoder: Decoder<ChatMessageReply> = succeed({})
  .assign('userId', field('user_id', number))
  .assign('kind', field('kind', stringLiteral('chat-message-reply')))
  .assign('content', field('sanitized_content', sanitizedHtmlDecoder))
  .assign('id', field('id', number))
  .assign('createdAt', field('created_at', date))
  .assign('age', field('age', string))
  .assign('reactions', field('reactions', chatMessageReactionsResourceDecoder))
  .assign(
    'offensiveMessageReports',
    field('offensive_message_reports', chatMessageOffensiveReportsResourceDecoder)
  )
  .assign('subscription', field('subscription', subscriptionResourceDecoder))
  .map<ChatMessageReply>(identity);

export const authorDecoder: Decoder<Author> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const authorResourceDecoder: Decoder<AuthorResource> = resourceDecoder(authorDecoder);

export const MessageReplyEmbeddedDecoder: Decoder<MessageReplyEmbedded> = succeed({})
  .assign('author', field('author', authorResourceDecoder))
  .assign(
    'offensiveMessageReports',
    field('offensive_message_reports', chatMessageOffensiveReportsResourceDecoder)
  )
  .assign('subscription', field('subscription', subscriptionResourceDecoder))
  .assign('reactions', field('reactions', chatMessageReactionsResourceDecoder));

export const chatMessageReplyResourceDecoder: Decoder<ChatMessageReplyResource> = resourceDecoder(
  chatMessageReplyDecoder
).assign('embedded', field('embedded', MessageReplyEmbeddedDecoder));

export const chatMessageRepliesResourceDecoder: Decoder<ChatMessageRepliesResource> = resourceDecoder(
  array(chatMessageReplyResourceDecoder)
);

export const chatMessageDecoder: Decoder<ChatMessage> = succeed({})
  .assign('age', field('age', string))
  .assign('kind', field('kind', stringLiteral('chat-message')))
  .assign('id', field('id', number))
  .assign('userId', field('user_id', number))
  .assign('content', field('sanitized_content', sanitizedHtmlDecoder))
  .assign('createdAt', field('created_at', date))
  .assign('reactions', field('reactions', chatMessageReactionsResourceDecoder))
  .assign('replies', field('replies', explicitMaybe(chatMessageRepliesResourceDecoder)))
  .assign(
    'offensiveMessageReports',
    field('offensive_message_reports', chatMessageOffensiveReportsResourceDecoder)
  )
  .assign('subscription', field('subscription', subscriptionResourceDecoder));

export const MessageEmbeddedDecoder: Decoder<MessageEmbedded> = succeed({})
  .assign('author', field('author', authorResourceDecoder))
  .assign(
    'offensiveMessageReports',
    field('offensive_message_reports', chatMessageOffensiveReportsResourceDecoder)
  )
  .assign('subscription', field('subscription', subscriptionResourceDecoder))
  .assign('replies', field('replies', explicitMaybe(chatMessageRepliesResourceDecoder)))
  .assign('reactions', field('reactions', chatMessageReactionsResourceDecoder));

export const chatMessageResourceDecoder: Decoder<ChatMessageResource> = resourceDecoder(
  chatMessageDecoder
).assign('embedded', field('embedded', MessageEmbeddedDecoder));

export const messagesResourceDecoder: Decoder<ChatMessagesResource> = resourceDecoder(
  array(chatMessageResourceDecoder)
);
