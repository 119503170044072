import * as React from 'react';
import * as style from './style.module.css';

export type OnlineStatusBorder = 'dark' | 'light';

interface Props {
  kind: OnlineStatusBorder;
}

const Icon: React.FC<Props> = ({ kind }) => {
  switch (kind) {
    case 'dark':
      return <span className={style.onlineDark} data-test-online="dark" />;
    case 'light':
      return <span className={style.onlineLight} data-test-online="light" />;
  }
};

export default Icon;
