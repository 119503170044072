interface Initializing {
  kind: 'initializing';
}

export const initializing = (): Initializing => ({ kind: 'initializing' });

interface Ready {
  kind: 'ready';
}

export const ready = (): Ready => ({ kind: 'ready' });

interface Loading {
  kind: 'loading';
}

export const loading = (): Loading => ({ kind: 'loading' });

interface NewMessage {
  kind: 'new-message';
}

export const newMessage = (): NewMessage => ({
  kind: 'new-message',
});

export type State = Initializing | Ready | Loading | NewMessage;
