import { observer } from 'mobx-react';
import * as React from 'react';
import { L, NotTranslated } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  date: Date;
}

const CalendarDateIcon: React.FC<Props> = ({ date }) => (
  <div className={style.date}>
    <div className={style.month}>
      <L localizeable={date} format="short-month" />
    </div>
    <div className={style.day}>
      <NotTranslated text={date.getDate().toString()} />
    </div>
  </div>
);

export default observer(CalendarDateIcon);
