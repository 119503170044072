import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../../../../Fetch';
import EnrollmentStore from '../../Enrollment/Store';
import { promotableProductCollectionResourceDecoder } from '../Decoders';
import { PromotableGroupMemberResource } from '../Types';
import PromotableProductCollectionView from './PromotableProductCollectionView';
import SkeletonPromotableProductCollection from './SkeletonPromotableProductCollection';

interface Props {
  invitationUuid: string;
  data: PromotableGroupMemberResource;
  enrollmentStore: EnrollmentStore;
}

function PromotableProductCollection({ data, invitationUuid, enrollmentStore }: Props) {
  const link = useFindLinkByR({ rel: 'self', method: 'get' }, data.links);
  const state = useFetch(link, promotableProductCollectionResourceDecoder);

  switch (state.kind) {
    case 'loading':
      return <SkeletonPromotableProductCollection />;
    case 'errored':
      warn('Failed to fetch promotable product collection resource.', toJS(state.error));
      return emptyFragment();
    case 'ready':
      return (
        <PromotableProductCollectionView
          data={state.data}
          invitationUuid={invitationUuid}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(PromotableProductCollection);
