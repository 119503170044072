import { observer } from 'mobx-react';
import * as React from 'react';
import { L, T } from '../../Translations';

interface Props {
  label: string;
  date: Date;
}

const ExpectedModuleStatement: React.FC<Props> = ({ label, date }) => (
  <p>
    <T
      kind="You should be in {{name}} by <date/>"
      name={label}
      date={<L localizeable={date} format={'short-month-day-year'} />}
    />
  </p>
);

export default observer(ExpectedModuleStatement);
