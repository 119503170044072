import { mapMaybe } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { RequiredFieldErrors, UserInputErrorMessage } from '../../../ProfileFormStore/Types';
import ErrorHeader from './ErrorHeader';
import RequiredFieldErrorMessage from './RequiredFieldErrorMessage';
import * as style from './style.module.css';

interface Props {
  errors: RequiredFieldErrors;
}

const errorMessage = (err: Maybe<UserInputErrorMessage>) => {
  return err.map((requiredFieldError) => (
    <RequiredFieldErrorMessage requiredFieldError={requiredFieldError} key={requiredFieldError} />
  ));
};

const RequiredFieldErrorMessageContainer: React.FC<Props> = ({ errors }) => (
  <div className={style.error} role="alert">
    <ErrorHeader />
    {mapMaybe(errorMessage, errors)}
  </div>
);

export default observer(RequiredFieldErrorMessageContainer);
