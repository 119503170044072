import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fill: string;
}

const RaiseHandIcon: React.FC<Props> = ({ fill }) => {
  return (
    <SVGIcon viewBox="0 0 14 17">
      <path
        fill={fill}
        d="M7.583 0c-.807 0-1.477.565-1.677 1.313a1.714 1.714 0 0 0-.657-.146c-.96 0-1.75.79-1.75 1.75v6.27l-.51-.51a1.763 1.763 0 0 0-2.48 0c-.679.68-.679 1.8 0 2.48l3.956 3.955c.686.684 1.634 1.221 2.753 1.221h2.698A4.091 4.091 0 0 0 14 12.25v-7c0-.96-.79-1.75-1.75-1.75-.205 0-.399.043-.584.11v-.693c0-.96-.79-1.75-1.75-1.75-.234 0-.45.061-.656.145C9.06.566 8.39 0 7.583 0Zm0 1.167c.33 0 .583.253.583.583v5.833h1.167V2.917c0-.33.253-.584.583-.584.33 0 .584.253.584.584v4.666h1.166V5.25c0-.33.253-.583.584-.583.33 0 .583.253.583.583v7a2.908 2.908 0 0 1-2.917 2.917H7.218c-.743 0-1.38-.363-1.914-.894L1.33 10.336a.591.591 0 0 1 0-.839.591.591 0 0 1 .838 0l1.495 1.513 1.003 1.003V2.917c0-.33.253-.584.583-.584.33 0 .584.253.584.584v4.666h1.166V1.75c0-.33.253-.583.584-.583Z"
      />
    </SVGIcon>
  );
};

export default observer(RaiseHandIcon);
