import { always } from '@kofno/piper';
import { CalendarDay } from '../../Calendaring';
import CalendarStore from '../../CalendarStore';
import { findLiveEventResource } from '../../EventsStore/Types';
import * as style from './style.module.css';

export const numberStyle = (store: CalendarStore, day: CalendarDay): string => {
  return store
    .events(day)
    .andThen((resources) => findLiveEventResource(resources))
    .map(always(style.liveEventNumber))
    .getOrElseValue(style.number);
};
