import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import UpdateFsProgramField from './FullStoryTooling/UpdateFsProgramField';
import UpdateGtmProgramField from './GoogleTagManagerTooling/UpdateGtmProgramField';

interface Props {
  title: string;
  sfProgramUid: Maybe<string>;
  registrationType: Maybe<string>;
  contactUID: Maybe<string>;
}

const UpdateTooling: React.FC<Props> = ({ title, sfProgramUid, registrationType, contactUID }) => (
  <>
    <UpdateFsProgramField title={title} sfProgramUid={sfProgramUid} />
    <UpdateGtmProgramField
      title={title}
      sfProgramUid={sfProgramUid}
      registrationType={registrationType}
    />
  </>
);

export default observer(UpdateTooling);
