import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { ExperienceResource } from '../../../Experience/Types';

interface Props {
  experienceResource: ExperienceResource;
}

function ProgramTimeCommitment({ experienceResource }: Props) {
  const { hoursPerWeek, duration, totalHours } = experienceResource.payload;

  return (
    <div className="flex flex-col gap-y-1">
      {duration
        .map((duration) => (
          <Typography variant="caption" as="p">
            <T kind="{{count}} week" count={duration} />
          </Typography>
        ))
        .getOrElse(emptyFragment)}
      {hoursPerWeek
        .map((count) => (
          <Typography variant="caption" as="p">
            <T kind="{{count}} hour per week" count={count} />
          </Typography>
        ))
        .getOrElse(emptyFragment)}
      {totalHours
        .map((totalHours) => (
          <Typography variant="caption" as="p">
            <T kind="{{count}} total hour" count={totalHours} />
          </Typography>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(ProgramTimeCommitment);
