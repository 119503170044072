import { observer } from 'mobx-react';
import React from 'react';
import { T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  id?: string;
}

const SkipNavLink: React.FC<Props> = ({ id = 'main' }) => {
  return (
    <a className={style.skipLink} href={`#${id}`}>
      <T kind="Skip to Content" />
    </a>
  );
};

export default observer(SkipNavLink);
