import * as React from 'react';
import { L, LocalizationFormat, T, TPlainTextKey } from '../../../Translations';

interface Props {
  title: TPlainTextKey;
  date: Date;
  format: LocalizationFormat;
}

const EventTimeEntry: React.FC<Props> = ({ title, date, format }) => (
  <dl>
    <dt>
      <T kind={title} />:
    </dt>
    <dd data-test-event-date={title}>
      <L localizeable={date} format={format} />
    </dd>
  </dl>
);

export default EventTimeEntry;
