import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { useEnrollmentModalContext } from '../../../../Common';

function CancelButton() {
  const { onClose } = useEnrollmentModalContext();

  return (
    <Button variant="text" color="gray" onClick={onClose}>
      <T kind="Cancel" />
    </Button>
  );
}

export default observer(CancelButton);
