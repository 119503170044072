import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../Translations';

const PageHeader = () => (
  <Typography variant="h5">
    <T kind="Notes" />
  </Typography>
);

export default observer(PageHeader);
