import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { L } from '../../../../../../../Translations';

interface Props {
  startTime: Maybe<Date>;
}

function GroupCoachSessionDateTime({ startTime }: Props) {
  return startTime
    .map((value) => (
      <Typography variant="bodyMedium" as="span">
        {<L localizeable={value} format="time-of-day" />}
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachSessionDateTime);
