import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const UploadIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 12 13">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M6.667 11.053V2.082l3.52 2.86.959-.778L6.479.372 6 0l-.48.372L.855 4.164l.959.779 3.52-2.861v8.971h1.334ZM0 11.917V13h12v-1.083H0Z"
      />
    </SVGIcon>
  );
};

export default observer(UploadIcon);
