import { observer } from 'mobx-react';
import { when } from '@execonline-inc/maybe-adapter';
import { take } from '@execonline-inc/collections';
import { Button, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { experienceDetailsRoute } from '../../../../../../../../ClientRoutes';
import { useDiscoveryContext } from '../../../../../../DiscoveryContext';
import NativeLink from '../../../../../../../NativeLink';
import { useExperienceContext } from '../../../../ExperienceContext';
import { GroupCoachingSessionResources } from '../../../../Types';

interface Props {
  sessions: GroupCoachingSessionResources;
}

const MAX_SESSION_COUNT = 3;

const getSessions = (sessions: GroupCoachingSessionResources) => {
  return [...take(MAX_SESSION_COUNT, sessions)];
};

function GroupCoachingSeeMoreSessions({ sessions }: Props) {
  const discoveryState = useDiscoveryContext();
  const { invitationUuid, experienceResource } = useExperienceContext();

  const collection = getSessions(sessions);
  const restCount = sessions.length - collection.length;

  return when(restCount > 0, true)
    .map(() => (
      <NativeLink
        to={experienceDetailsRoute(
          invitationUuid,
          experienceResource.payload.experienceId,
          discoveryState,
        )}
      >
        {(onClick) => (
          <div className="text-center">
            <Button className="p-0" color="gray" variant="link" size="sm" onClick={onClick}>
              <Typography variant="bodySmall" fontWeight="medium">
                <T kind="See +{{count}} more" count={restCount} />
              </Typography>
            </Button>
          </div>
        )}
      </NativeLink>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachingSeeMoreSessions);
