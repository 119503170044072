import { ArrowLeftIcon, Button } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../Translations';
import { useExperiencesContext } from '../../ExperiencesContext';

function GoBackButton() {
  const { state, ready } = useExperiencesContext();

  const handleGoBack = () => {
    switch (state.kind) {
      case 'error':
      case 'loading':
      case 'product-collections':
      case 'waiting':
        break;
      case 'product-collections-and-experiences':
      case 'searching':
      case 'experiences':
        ready({
          ...state.resource,
          payload: { ...state.resource.payload, experiences: state.allExperiences },
        });
    }
  };

  return (
    <Button
      className="justify-start"
      startContent={<ArrowLeftIcon color="white" size="md" />}
      size="sm"
      onClick={handleGoBack}
    >
      <T kind="Go Back" />
    </Button>
  );
}

export default observer(GoBackButton);
