import { warn } from '@execonline-inc/logging';
import { nothing } from 'maybeasy';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { PropsWithChildren, useMemo } from 'react';
import { useCurrentUserContext } from '../../../CurrentUser';
import { useFetch, useFindLinkByR } from '../../../Fetch';
import { linkablesResourcesDecoder } from '../Decoders';
import { Linkables } from '../Types';
import { LinkableContext } from './Types';

function LinkableProvider({ children }: PropsWithChildren<{}>) {
  const currentUser = useCurrentUserContext();
  const link = useFindLinkByR({ rel: 'linkables' }, currentUser.links);
  const state = useFetch(link, linkablesResourcesDecoder);

  const resource: Linkables = useMemo(() => {
    switch (state.kind) {
      case 'loading':
        return nothing();
      case 'errored':
        warn('Failed to fetch program progress resource.', toJS(state.error));
        return nothing();
      case 'ready':
        return state.data.payload;
    }
  }, [state.kind]);

  return <LinkableContext.Provider value={resource}>{children}</LinkableContext.Provider>;
}

export default observer(LinkableProvider);
