import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { ExperienceOverviewChip } from '../../../Common';

interface Props {
  duration: Maybe<number>;
}

function ExperienceDuration({ duration }: Props) {
  return duration
    .map((week) => (
      <ExperienceOverviewChip>
        <T kind="{{count}} week" count={week} />
      </ExperienceOverviewChip>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceDuration);
