import { observer } from 'mobx-react';
import { CloseAction } from '../Openable';
import * as style from './style.module.css';

interface ModalShadeProps {
  className?: string;
  close: CloseAction;
}

function ModalShade({ className, close }: ModalShadeProps) {
  return <div className={className || style.shade} onClick={close} data-test-modal-shade={true} />;
}

export default observer(ModalShade);
