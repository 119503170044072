import { useContext } from 'react';
import { LinkableContext } from '../Context';

function useLinkableContext() {
  const context = useContext(LinkableContext);

  if (!context) {
    throw new Error('useLinkableContext has to be used within the LinkableContext.Provider.');
  }

  return context;
}

export default useLinkableContext;
