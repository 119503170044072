import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import CommentariesStore from '../../../../LectureCommentariesStore';
import { LectureSegment } from '../../../../LectureStore/Types';
import { T } from '../../../../Translations';
import * as style from '../style.module.css';
import Commentary from './Commentary';

interface Props {
  segment: LectureSegment;
  kettle: Kettle;
}

@observer
class Commentaries extends React.Component<Props, {}> {
  private commentariesStore: CommentariesStore;

  constructor(props: Props) {
    super(props);
    this.commentariesStore = new CommentariesStore(props.segment, props.kettle);
  }

  render() {
    return (
      <div className={style.commentaries} data-test-commentaries={true}>
        <h4 className={style.commentaryTitle} data-test-commentary-title="Commentary">
          <T kind="Commentary" />
        </h4>
        {this.commentariesStore.current.map(c => (
          <Commentary key={c.payload.id} commentary={c} />
        ))}
      </div>
    );
  }
}

export default Commentaries;
