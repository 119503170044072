import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Types';
import ExperienceProductLabel from '../ExperienceProductLabel';
import FormattedAvailability from './FormattedAvailability';

interface Props {
  experienceResource: ExperienceResource;
}

function ProgramProductBadge({ experienceResource }: Props) {
  return fromArrayMaybe(experienceResource.payload.availabilities)
    .map((availabilities) => availabilities.first)
    .map((availability) => (
      <ExperienceProductLabel>
        <FormattedAvailability availability={availability} />
      </ExperienceProductLabel>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramProductBadge);
