import { warn } from '@execonline-inc/logging';
import { TranslationsContext } from '@execonline-inc/translations';
import { noop } from '@kofno/piper';
import FocusTrap from 'focus-trap-react';
import { fromNullable, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuid } from 'uuid';
import { handleEscKeyDown } from '../../Accessibility/KeyboardEvents';
import { translation } from '../../Translations';
import CloseIcon from '../Cutesies/CloseIcon';
import ModalStore from '../Modal/Store';
import * as style from './style.module.css';

interface Props {
  modalStore: ModalStore;
}

class PortalModal extends React.Component<Props> {
  root: Maybe<HTMLElement> = fromNullable(document.getElementById('root'));
  portal: HTMLElement = document.createElement('div');
  id: string = uuid();

  componentDidMount(): void {
    this.root.cata({
      Just: (root) => root.appendChild(this.portal),
      Nothing: () => warn(`Could not mount portal modal because the root element was not found.`),
    });
  }

  componentWillUnmount(): void {
    this.root.cata({
      Just: (root) => root.removeChild(this.portal),
      Nothing: noop,
    });
  }

  render() {
    const { modalStore, children } = this.props;
    return ReactDOM.createPortal(
      <TranslationsContext.Consumer>
        {(ts) => (
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              allowOutsideClick: true,
              fallbackFocus: `#${this.id}`,
            }}
          >
            <div
              className={style.modal}
              data-test-modal={true}
              aria-modal={true}
              onKeyDown={handleEscKeyDown(modalStore.close)}
              id={this.id}
              tabIndex={-1}
            >
              <div className={style.body} data-test-modal-body={true}>
                <div className={style.alignedRight}>
                  <button
                    className={style.closeButton}
                    onClick={modalStore.close}
                    data-test-close-button={true}
                    aria-label={translation('Close')(ts)}
                    role="button"
                    autoFocus
                  >
                    <div className="w-3.5 h-3.5">
                      <CloseIcon fillVariable={'content-text'} />
                    </div>
                  </button>
                </div>
                {children}
              </div>
              <div
                className={style.shade}
                onClick={modalStore.close}
                data-test-modal-shade={true}
              />
            </div>
          </FocusTrap>
        )}
      </TranslationsContext.Consumer>,
      this.portal
    );
  }
}

export default observer(PortalModal);
