import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import ConversationStore from '../../../ConversationStore';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import ScrollStore from '../../../ScrollStore';
import { ScrollPosition } from '../../../ScrollStore/Types';

interface Props extends RCProps<ScrollStore> {
  conversationStore: ConversationStore;
}

class ChatScrollReactions extends ReactionComponent<ScrollStore, Maybe<ScrollPosition>, Props> {
  tester = () => this.props.store.scrollPosition;

  effect = (scrollPosition: Maybe<ScrollPosition>) => {
    scrollPosition.map((sp) => {
      switch (sp) {
        case 'bottom':
          this.props.conversationStore.previousLink.map((link) => {
            this.props.conversationStore.loadingPrevious(link);
          });

          break;
        case 'top':
          this.props.conversationStore.nextLink.map((link) => {
            this.props.conversationStore.loadingNext(link);
          });
          break;
        case 'middle':
        case 'near-bottom':
        case 'near-top':
          break;
        default:
          assertNever(sp);
      }
    });
  };
}

export default ChatScrollReactions;
