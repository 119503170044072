import { fromNullable, Maybe } from 'maybeasy';
import { Link } from '../Resource/Types';

const removeCacheBuster = (href: string): string | null | undefined => {
  const match = /^https:\/\/.*&asOf=/.exec(href);
  return match == null ? null : match[0];
};

const normalizeUrl = (link: Link): Maybe<string> => fromNullable(removeCacheBuster(link.href));

export const urlsEq = (a: Maybe<Link>, b: Maybe<Link>): boolean =>
  a
    .andThen(normalizeUrl)
    .andThen(aUrl => b.andThen(normalizeUrl).map(bUrl => aUrl === bUrl))
    .getOrElseValue(false);
