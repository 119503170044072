import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../Accessibility/KeyboardEvents';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import AgendaIcon from '../../Cutesies/AgendaIcon';
import * as style from '../../SegmentPanel/style.module.css';

interface Props {
  tabStore: TabStore;
}

const AgendaTab: React.FC<Props> = ({ tabStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('agenda');
  };

  return (
    <div
      className={`${style.tab} ${tabStore.activeTab === 'agenda' ? style.active : ''}`}
      onClick={handleClick}
      tabIndex={0}
      onKeyPress={handleEnterKeyPress(handleClick)}
    >
      <div className={style.tabIcon}>
        <AgendaIcon />
      </div>
      <div className={style.tabLabel}>
        <T kind="Agenda" />
      </div>
    </div>
  );
};
export default observer(AgendaTab);
