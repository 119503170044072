import * as React from 'react';
import { Link } from 'react-router-dom';
import { noteContextRoute } from '../../ClientRoutes';
import NoteStore from '../../NoteStore';
import { NoteResource } from '../../NoteStore/Types';
import { NotTranslated } from '../../Translations';

interface Props {
  noteStore: NoteStore;
}

const linkedContext = (cxt: string) => (link: string) => (
  <Link to={link} data-test-context-linked={cxt}>
    <NotTranslated text={cxt} />
  </Link>
);
const unlinkedContext = (cxt: string) => (
  <span data-test-context-unlinked={cxt}>
    <NotTranslated text={cxt} />
  </span>
);

const Context: React.StatelessComponent<Props> = ({ noteStore }) => {
  return noteStore.context
    .map((cxt: string) =>
      noteStore.noteResource
        .map((noteResource: NoteResource) =>
          noteContextRoute(noteResource.payload)
            .map(linkedContext(cxt))
            .getOrElseValue(unlinkedContext(cxt))
        )
        .getOrElseValue(unlinkedContext(cxt))
    )
    .getOrElseValue(<span />);
};
export default Context;
