import { observer } from 'mobx-react';
import * as Pusher from 'pusher-js';
import * as React from 'react';
import EventsStore from '../../../EventsStore';

interface Props {
  channel: Pusher.Channel;
  store: EventsStore;
}

class EventsBinding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel, store } = this.props;

    channel.bind('refresh-calendar-events', () => {
      switch (store.state.kind) {
        case 'ready':
          store.refresh();
          break;
        case 'error':
        case 'loading':
        case 'waiting':
        case 'refresh':
          break;
      }
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(EventsBinding);
