import * as React from 'react';
import { lightTheme } from '../../Native/styles';
import { LightTheme } from './Types';
import { SharedInvitationResource } from '../../Native/AEP/SharedOpenEnrollment/SharedInvitationResourceStore/Types';

export type LightnessTheme = LightTheme;

export const orgSpecificLoginPageTheme = (
  sharedInvitationResource: SharedInvitationResource,
): LightnessTheme => lightTheme;

export const currentTheme = () => lightTheme;

export const currentGlobalTheme = () => lightTheme;

const ThemeContext = React.createContext<LightnessTheme>(currentTheme());

export default ThemeContext;
