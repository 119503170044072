import { Time } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import produce from 'immer';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { ready, SocketAvailability, SocketState, unmounted, unmounting, waiting } from './Types';

export default class Store {
  @observable
  state: SocketState;

  constructor() {
    this.state = waiting();
  }

  @action
  ready = (socket: SocketAvailability) => {
    this.state = ready(socket);
  };

  @action
  error = (message: TPlainTextKey) => {
    this.state = error(message);
  };

  @action
  disconnect = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = unmounting(this.state.socket);
        break;
      case 'error':
      case 'unmounted':
      case 'unmounting':
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  unmounted = () => {
    switch (this.state.kind) {
      case 'unmounting':
        this.state = unmounted(this.state.socket);
        break;
      case 'error':
      case 'ready':
      case 'waiting':
      case 'unmounted':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  connectingIn = (delay: Time) => {
    this.state = produce<SocketState>(this.state, (draft) => {
      switch (draft.kind) {
        case 'error':
        case 'unmounted':
        case 'unmounting':
        case 'waiting':
          break;
        case 'ready':
          switch (draft.socket.kind) {
            case 'connecting':
            case 'unavailable':
              draft.socket.connectingIn = just(delay);
              break;
            case 'connected':
            case 'disconnected':
            case 'failed':
            case 'initialized':
              break;
            default:
              assertNever(draft.socket);
          }
          break;
        default:
          assertNever(draft);
      }
    });
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'ready':
      case 'waiting':
      case 'unmounting':
      case 'unmounted':
        return nothing();
      case 'error':
        return just(this.state).map(errorAlert);
    }
  }
}
