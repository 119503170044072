import { cn, TextField } from '@execonline-inc/execonline-ui';
import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { fromNullableT } from '../../TaskExt';
import { FormTextFieldProps } from './Types';

const FormTextField = ({ disabled, onChange, ...others }: FormTextFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    fromNullableT(onChange).fork(noop, (onChange) => onChange(e.target.value));
  };

  return (
    <div className="relative flex flex-col sm:w-full">
      <TextField
        className={cn({ 'border-zinc-200 bg-zinc-200': disabled })}
        labelProps={{
          className: 'uppercase tracking-wider text-xs font-medium',
        }}
        helperTextProps={{ className: 'font-medium' }}
        disabled={disabled}
        onChange={handleChange}
        {...others}
      />
    </div>
  );
};

export default observer(FormTextField);
