import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { NotEnrollableAlertResource } from '../../../Common/Experience/Types';
import EnrollmentStore from '../../Enrollment/Store';
import { ExperienceEnrolledModal } from '../../../Common/ProgramExperienceEnrollment';

interface Props {
  invitationUuid: string;
  notEnrollableAlert: Maybe<NotEnrollableAlertResource>;
  enrollmentStore: EnrollmentStore;
}

function EnrollableExperience({ invitationUuid, notEnrollableAlert, enrollmentStore }: Props) {
  return notEnrollableAlert
    .map(({ payload }) => (
      <ExperienceEnrolledModal
        invitationUuid={invitationUuid}
        notEnrollableAlert={payload}
        enrollmentStore={enrollmentStore}
      />
    ))
    .getOrElse(emptyFragment);
}

export default observer(EnrollableExperience);
