import { observer } from 'mobx-react';
import { AlreadyTranslated, AlreadyTranslatedText } from '../../../../../../Translations';
import { Typography } from '@execonline-inc/execonline-ui';

interface Props {
  programs: AlreadyTranslatedText[];
}

function IncludedPrograms({ programs }: Props) {
  return (
    <ul className="flex flex-col md:flex-row md:flex-wrap">
      {programs.map((program, idx) => {
        return (
          <li key={`${program}${idx}`} className="box-border flex basis-1/3 items-center py-2 pl-2">
            <span className="mr-2.5 flex h-2 w-2">
              <span className="h-2 w-2 rounded-full bg-primary-500" />
            </span>
            <Typography variant="bodyMedium">
              <AlreadyTranslated content={program} />
            </Typography>
          </li>
        );
      })}
    </ul>
  );
}

export default observer(IncludedPrograms);
