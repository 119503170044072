import { observer } from 'mobx-react';
import { log } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { warnAndNotify } from '../../../../../../../Honeybadger';
import ReactionComponent, { RCProps } from '../../../../../../../ReactionComponent';
import { currentUserStore } from '../../../../../../../CurrentUser/Store';
import PaymentWizardStore from '../PaymentWizardStore';
import { EventState } from '../PaymentWizardStore/Types';
import {
  fetchContextAfterTimeout,
  handleMessageReceived,
  raiseAfterProcessingTimeout,
  raisePusherErrorAfterTimeout,
  requestPaymentIntentStatus,
  setPaymentStateProcessing,
} from './Helpers';

interface Props extends RCProps<PaymentWizardStore> {}

class PaymentWizardReactions extends ReactionComponent<PaymentWizardStore, EventState, Props> {
  tester = () => this.props.store.state;
  effect = (state: EventState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'initializing':
        break;
      case 'waiting-for-message':
        setPaymentStateProcessing(state.paymentIntentId).fork(log, () =>
          fetchContextAfterTimeout(store, state.paymentIntentId),
        );
        break;
      case 'request-payment-status':
        requestPaymentIntentStatus(store, state.paymentIntentId);
        break;
      case 'payment-status-requested':
        raisePusherErrorAfterTimeout(store, state.paymentIntentId);
        break;
      case 'message-received':
        handleMessageReceived(state, store);
        break;
      case 'payment-still-processing':
        raiseAfterProcessingTimeout(store);
        break;
      case 'paid-and-enrolled':
        currentUserStore.refreshing();
        break;
      case 'ready-to-redirect':
        //handled by exit component
        break;
      case 'pusher-error':
        warnAndNotify(
          'PusherConnectionFailed',
          `Not receiving messages for payment confirmation on channel: presence-stripe-payment-intent-${state.paymentIntentId}, see documentation: https://execonline.atlassian.net/wiki/spaces/TEC/pages/3906502688/`,
          {},
        );
        break;
      case 'enrollment-error':
      case 'payment-error':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(PaymentWizardReactions);
