import { observer } from 'mobx-react';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import ToastReactions from '../../ToastsStore/ToastReactions';
import ReminderToast from './ReminderToast';

interface Props {
  store: ScheduledToastStore;
}

const ReminderNotificationToast: React.FC<Props> = ({ store }) => {
  return (
    <>
      <ReminderToast store={store} />
      <ToastReactions store={store} />
    </>
  );
};

export default observer(ReminderNotificationToast);
