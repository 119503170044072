import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { pendingSurveyStore } from '../../PendingSurveyStore';
import PendingSurveyReactions from '../../PendingSurveyStore/PendingSurveyReactions';
import CoachingSurveyModal from '../CoachingSurveyModal';

interface Props {
  currentUserResource: CurrentUserResource;
}

const PendingSurvey: React.FC<Props> = ({ currentUserResource }) => (
  <>
    {pendingSurveyStore.surveyModalStore
      .map((surveyModalStore) => (
        <CoachingSurveyModal surveyModalStore={surveyModalStore} surveyLaunchkind="auto-launch" />
      ))
      .getOrElse(() => (
        <></>
      ))}
    <PendingSurveyReactions
      store={pendingSurveyStore}
      currentUserResource={currentUserResource}
      fireImmediately
    />
  </>
);

export default observer(PendingSurvey);
