import { observer } from 'mobx-react';
import ArrowLeftIcon from '../../Cutesies/ArrowLeftIcon';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import ArrowDownIcon from '../../Cutesies/ArrowDownIcon';
import * as styles from './style.module.css';
import { buttonColor, arrowButtonStyle, arrowButtonText, ButtonProps } from './Types';
import { assertNever } from '@kofno/piper';
import clsx from 'clsx';

function Arrow({ direction }: { direction: ButtonProps['arrowDirection'] }) {
  switch (direction) {
    case 'left':
      return <ArrowLeftIcon />;
    case 'right':
      return <ArrowRightIcon />;
    case 'down':
      return <ArrowDownIcon />;
    default:
      assertNever(direction);
  }
}

const arrowPositioning = (position: ButtonProps['arrowPosition']) => {
  switch (position) {
    case 'start':
      return 'flex-row';
    case 'end':
      return 'flex-row-reverse';
  }
};

const ArrowButton = ({
  onClick,
  children,
  arrowPosition,
  arrowDirection = 'left',
  color = 'primary',
  className,
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        className,
        arrowButtonStyle,
        buttonColor[color],
        arrowPositioning(arrowPosition)
      )}
    >
      <div className={styles.iconButton}>
        <Arrow direction={arrowDirection} />
      </div>
      <div className={arrowButtonText}>{children}</div>
    </button>
  );
};

export default observer(ArrowButton);
