import * as React from 'react';
import BoldIcon from '../../../Cutesies/BoldIcon';
import ItalicIcon from '../../../Cutesies/ItalicIcon';
import UnderlineIcon from '../../../Cutesies/UnderlineIcon';
import BulletedListIcon from '../../../Cutesies/BulletedListIcon';
import NumberedListIcon from '../../../Cutesies/NumberedListIcon';
import * as style from '../style.module.css';
import { handleEnterOrSpaceKeyPress } from '../../../../Accessibility/KeyboardEvents';

interface StyleProps {
  onToggle: (style: string) => void;
  style: string;
  active: boolean;
  label: string;
}

const StyleButton: React.FC<StyleProps> = (props) => {
  const onToggle = (e: any) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  let className = style.richEditorStyleButton;
  if (props.active) {
    className += ` ${style.activeButton}`;
  }

  switch (props.label) {
    case 'Bold':
      return (
        <span
          className={`${className} ${style.boldIcon}`}
          onMouseDown={onToggle}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => onToggle(event))}
          aria-label={props.label}
          role="button"
        >
          <BoldIcon />
        </span>
      );
    case 'Italic':
      return (
        <span
          className={`${className} ${style.italicIcon}`}
          onMouseDown={onToggle}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => onToggle(event))}
          aria-label={props.label}
          role="button"
        >
          <ItalicIcon />
        </span>
      );
    case 'Underline':
      return (
        <span
          className={`${className} ${style.underlineIcon}`}
          onMouseDown={onToggle}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => onToggle(event))}
          aria-label={props.label}
          role="button"
        >
          <UnderlineIcon active={props.active} />
        </span>
      );
    case 'UL':
      return (
        <span
          className={`${className} ${style.bulletListIcon}`}
          onMouseDown={onToggle}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => onToggle(event))}
          aria-label={props.label}
          role="button"
        >
          <BulletedListIcon />
        </span>
      );
    case 'OL':
      return (
        <span
          className={`${className} ${style.numberedListIcon}`}
          onMouseDown={onToggle}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => onToggle(event))}
          aria-label={props.label}
          role="button"
        >
          <NumberedListIcon />
        </span>
      );
    default:
      return (
        <span className={className} onMouseDown={onToggle}>
          {props.label}
        </span>
      );
  }
};

export default StyleButton;
