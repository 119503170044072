import { Chip, cn, TriangleExclamationSolidIcon, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../../../../../Translations';

interface Props {
  className: string;
  value: TPlainTextKey;
}

function ConflictingAvailability({ className, value }: Props) {
  return (
    <Chip
      className={cn('w-fit gap-x-2 bg-white p-3 sm:gap-x-4 sm:px-6 sm:py-4', className)}
      color="white"
      radius="xl"
    >
      <TriangleExclamationSolidIcon color="teal" size="xl" />
      <Typography variant="bodySmall" color="black">
        <T kind={value} />
      </Typography>
    </Chip>
  );
}

export default observer(ConflictingAvailability);
