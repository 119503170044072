import { TranslationsContext } from '@execonline-inc/translations';
import { useContext } from 'react';
import { localize, t9n } from '.';

export function useTranslationsContext() {
  const ts = useContext(TranslationsContext);
  return {
    ts,
    translate: t9n(ts),
    localize: localize(ts),
  };
}
