import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { teamUrl } from '../../ClientRoutes';
import { NotTranslated, T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const TeamSummaryHeader: React.FC<Props> = ({ communityTeamResource }) => (
  <div className={style.header}>
    <Link
      to={teamUrl(communityTeamResource.payload.id)}
      className={style.nameWrapper}
      data-test-team-link={true}
    >
      <h2 className={style.cardHeading} data-test-team-name={communityTeamResource.payload.name}>
        <NotTranslated text={communityTeamResource.payload.name} />
      </h2>
      <div className={style.profileButton}>
        <T kind="Chat" />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </div>
    </Link>
  </div>
);

export default observer(TeamSummaryHeader);
