import { observer } from 'mobx-react';
import * as React from 'react';
import NoteStore from '../../../../NoteStore';
import { T } from '../../../../Translations';
import Button from '../../../Form/Button';
import * as style from '../../style.module.css';
interface Props {
  store: NoteStore;
}

class Confirmation extends React.Component<Props> {
  confirm = () => {
    this.props.store.deleting();
  };

  cancel = () => {
    this.props.store.noteResource.map(this.props.store.ready);
  };

  render() {
    return (
      <div className={style.note}>
        <div className={style.confirmation}>
          <p className={style.confirmationMessage} data-test-confirmation-message={true}>
            <T kind="Are you sure you want to delete this note?" />
          </p>
          <Button
            type="button"
            value="Delete"
            onClick={this.confirm}
            className={style.buttonDanger}
            dataTestDeleteButton={true}
          />
          <button onClick={this.cancel} className={style.buttonLink} data-test-cancel-button={true}>
            <T kind="Cancel" />
          </button>
        </div>
      </div>
    );
  }
}
export default observer(Confirmation);
