import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { NotTranslated } from '@execonline-inc/translations';
import { useExperienceContext } from '../../../Experience/ExperienceContext';

function ExperienceHeaderText() {
  const { experienceResource } = useExperienceContext();

  return (
    <Typography variant="caption">
      <NotTranslated text={experienceResource.payload.schoolPartner.payload.name.text} />
    </Typography>
  );
}

export default observer(ExperienceHeaderText);
