import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../Translations';

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  emailAddress: string;
  password: string;
  termsAcceptance: boolean;
  message: Maybe<TPlainTextKey>;
}

export interface Creating {
  kind: 'creating';
  emailAddress: string;
  password: string;
}

export interface Created {
  kind: 'created';
}

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
  emailAddress: string;
  termsAcceptance: boolean;
}

export type State = Waiting | Ready | Creating | Created | Error;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (
  emailAddress: string,
  termsAcceptance: boolean,
  message: Maybe<TPlainTextKey>
): Ready => ({
  kind: 'ready',
  emailAddress,
  password: '',
  termsAcceptance,
  message,
});

export const creating = (emailAddress: string, password: string): Creating => ({
  kind: 'creating',
  emailAddress,
  password,
});

export const created = (): Created => ({
  kind: 'created',
});

export const error = (
  message: TPlainTextKey,
  emailAddress: string,
  termsAcceptance: boolean
): Error => ({
  kind: 'error',
  message,
  emailAddress,
  termsAcceptance,
});
