import { observer } from 'mobx-react';
import { handleEscKeyDown } from '../../Accessibility/KeyboardEvents';
import { CloseAction } from '../Openable';
import * as style from './style.module.css';

interface ModalContainerProps {
  onEscPressed?: CloseAction;
  id?: string;
  className?: string;
  children: React.ReactNode;
}

function ModalContainer({ onEscPressed, id, className, children }: ModalContainerProps) {
  return (
    <div
      className={className || style.modal}
      data-test-modal={true}
      aria-modal={true}
      onKeyDown={onEscPressed ? handleEscKeyDown(onEscPressed) : undefined}
      id={id || 'modal-container'}
      tabIndex={-1}
    >
      {children}
    </div>
  );
}

export default observer(ModalContainer);
