import { warn } from '@execonline-inc/logging';
import { InvalidUrlError, putQueryParam, toUrlT } from '@execonline-inc/url';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../../Appy';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../LinkyLinky';
import { Link } from '../../../Resource/Types';
import { AutoLaunchableSource } from '../AutoLaunchableContext';
import { AutoLaunchableStore } from '../AutoLaunchableStore';
import { autoLaunchableDecoder } from '../AutoLaunchableStore/Decoders';
import { State } from '../AutoLaunchableStore/Types';

interface Props extends EAProps<AutoLaunchableStore> {
  currentUserResource: CurrentUserResource;
  autoLaunchableMode: AutoLaunchableSource;
}

type AutoLaunchableError = MissingLinkError | AppyError | InvalidUrlError;

const handleBadStatus = (store: AutoLaunchableStore, error: BadStatus) => {
  switch (error.response.status) {
    case 404:
      store.nothingToLaunch();
      break;
    default:
      warn(error);
      handleError(store, error);
  }
};

const handleAutoLaunchableError = (store: AutoLaunchableStore) => (error: AutoLaunchableError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Link not available');
      warnAndNotify('AutoLaunchableReactions', `Missing link: ${error.rel}`, error);
      break;
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    case 'invalid-url-error':
      store.error('An error occurred, please try again later');
      warn('AutoLaunchableReactions', error.kind);
      break;
    default:
      warn(error);
      handleError(store, error);
  }
};
const assignAutoLaunchableParameter = (value: string) => (link: Link) =>
  toUrlT(link.href)
    .map(putQueryParam('source', value))
    .map((urlParser) => ({ ...link, href: urlParser.href }));

class AutoLaunchableReactions extends ErrorActionableReaction<AutoLaunchableStore, State, Props> {
  tester = (): State => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'loading':
        Task.succeed<AutoLaunchableError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('auto-launchables'))
          .andThen(assignAutoLaunchableParameter(this.props.autoLaunchableMode))
          .andThen(callApi(autoLaunchableDecoder, {}))
          .fork(handleAutoLaunchableError(this.props.store), (m) => this.props.store.ready(m));
        break;
      case 'ready':
      case 'nothing-to-launch':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default AutoLaunchableReactions;
