import { observer } from 'mobx-react';
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { programRoute } from '../../../ClientRoutes';
import { Link } from '../../../Resource/Types';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import { AlreadyTranslated, AlreadyTranslatedText, T } from '../../../Translations';
import UniversityLogoOnWhite from '../UniversityLogoOnWhite';
import * as style from '../style.module.css';

interface Props {
  id: number;
  title: AlreadyTranslatedText;
  links: ReadonlyArray<Link>;
}

const ProgramOverviewHeader: React.FC<Props> = ({ id, title, links }) => (
  <>
    <ReactRouterLink to={programRoute(id)} data-test-program-overview-link={true}>
      <h3 className={style.title} data-test-program-title={title}>
        <AlreadyTranslated content={title} />
      </h3>
      <div className={style.overviewButton}>
        <T kind="Overview" />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </div>
    </ReactRouterLink>
    <UniversityLogoOnWhite links={links} />
  </>
);

export default observer(ProgramOverviewHeader);
