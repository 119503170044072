import { observer } from 'mobx-react';
import * as React from 'react';
import { programDashboardUrl } from '../../../../../ClientRoutes';
import { Link } from 'react-router-dom';
import { StartedExternalProgram } from '../../../../../SegmentStore/Types';
import clsx from 'clsx';
import { percentage } from '@execonline-inc/numbers';
import { T } from '../../../../../Translations';

interface Props {
  startedExternalProgram: StartedExternalProgram;
}

const Started: React.FC<Props> = ({ startedExternalProgram }) => (
  <div
    data-test-external-program-segment={startedExternalProgram.id}
    className="inline-flex items-center flex-col"
  >
    <div
      className={clsx(
        'text-sm font-semibold flex items-center justify-center bg-teal-600',
        'border-teal-600 border-2 rounded-md max-w-xs p-3 text-white hover:text-white',
        'm-x5 whitespace-nowrap'
      )}
    >
      <Link
        to={programDashboardUrl(startedExternalProgram.id)}
        className="text-white hover:text-white"
      >
        <span className="mb-2">{percentage(startedExternalProgram.percentComplete)} - </span>
        <T kind="Resume Experience" />
      </Link>
    </div>
  </div>
);

export default observer(Started);
