import { identity } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Result } from 'resulty';
import ConvertedPptStore from '../../../../../LectureConvertedPptStore';
import {
  IncompleteLectureMissingPpt,
  LecturePptAssetResource,
} from '../../../../../LectureStore/Types';
import { MissingLinkError, findLinkByR } from '../../../../../Links';
import { Link } from '../../../../../Resource/Types';
import { T } from '../../../../../Translations';
import FlashWarning from '../../../../FlashMessage/FlashWarning';

interface Props {
  pptResult: Result<IncompleteLectureMissingPpt, LecturePptAssetResource>;
  refEl: React.RefObject<HTMLIFrameElement>;
  store: ConvertedPptStore;
}

type PptContentError = IncompleteLectureMissingPpt | MissingLinkError;

const PptContent: React.FC<Props> = ({ pptResult, refEl, store }) =>
  pptResult
    .map((resource) => resource.links)
    .mapError<PptContentError>(identity)
    .andThen(findLinkByR({ rel: 'enclosure' }))
    .cata({
      Err: (err: PptContentError): JSX.Element => {
        switch (err.kind) {
          case 'missing-link-error':
            return <></>;
          case 'incomplete-lecture-missing-ppt':
            return (
              <FlashWarning>
                <T kind="This lecture segment is still a work in progress." />
              </FlashWarning>
            );
        }
      },
      Ok: (link: Link) => (
        <iframe
          src={link.href}
          scrolling="no"
          className="slides-iframe"
          ref={refEl}
          onLoad={() => store.playerReady(refEl)}
        />
      ),
    });

export default observer(PptContent);
