import clsx from 'clsx';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../../Person/types';
import PersonPhoto from '../../../PersonPhoto';
import * as style from '../../style.module.css';

interface Props {
  store: PersonStoreContract;
  children: {
    onlineStatus: Maybe<JSX.Element>;
    details: Maybe<JSX.Element>;
  };
}

const PersonBase: React.FC<Props> = ({ store, children }) => {
  const { onlineStatus, details } = children;
  return (
    <div className={style.person} data-test-person={true}>
      <div
        className={clsx(
          style.avatar,
          'bg-primary group-hover:bg-primary-300 group-focus:bg-primary-300 rounded-full'
        )}
        data-test-person-avatar={true}
      >
        <PersonPhoto avatar={store.avatar} />
        {onlineStatus.getOrElseValue(<></>)}
      </div>
      {details.getOrElseValue(<></>)}
    </div>
  );
};

export default observer(PersonBase);
