import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const TeamArrowIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 32 32">
      <path
        fill={defaultTheme.variables['icon-standard-on-dark']}
        d="M12.969 4.281 11.53 5.72 21.812 16l-10.28 10.281 1.437 1.438 11-11 .687-.719-.687-.719Z"
      />
    </SVGIcon>
  );
};

export default observer(TeamArrowIcon);
