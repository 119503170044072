import * as React from 'react';
import ProgramChatStore from '../../ProgramChatStore';
import { T } from '../../Translations';
import PresenceProgramProfile from './PresenceProgramProfile';

interface Props {
  programChatStore: ProgramChatStore;
}

const SingularProgramProfile: React.FC<Props> = ({ programChatStore }) => {
  return programChatStore.program
    .map(() => <PresenceProgramProfile programChatStore={programChatStore} />)
    .getOrElse(() => (
      <div>
        <T kind="You do not belong to any programs." />
      </div>
    ));
};
export default SingularProgramProfile;
