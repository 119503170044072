import { warn } from '@execonline-inc/logging';
import { noop } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getDocumentElementWidth } from '../../Document';
import { windowAddListener, windowScrollY } from '../../Window';
import * as style from './style.module.css';

interface Props {
  personRef: React.RefObject<HTMLDivElement>;
}

class PopoutImpl extends React.Component<Props> {
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  getTriggerElRect = () => {
    return fromNullable(this.props.personRef.current)
      .map((ref) => ref.getBoundingClientRect())
      .getOrElse(() => {
        warn('Unable to access profile popout trigger element ref.');
        return document.createElement('div').getBoundingClientRect();
      });
  };

  outsideWindowAdjustments = (width: number, popoutBaseEl: HTMLDivElement): number => {
    const popoutBaseElRect = popoutBaseEl.getBoundingClientRect();
    const triggerElRect = this.getTriggerElRect();

    if (popoutBaseElRect.right > width) {
      return popoutBaseElRect.right - width + triggerElRect.width + 10;
    } else {
      return 0;
    }
  };

  setPosition = (): void => {
    fromNullable(this.ref.current).cata({
      Nothing: noop,
      Just: (popoutBaseEl) => {
        windowScrollY().fork(
          (e) => warn('Unable to access window.scrollY when setting popout top position', e),
          (windowScrollY) => {
            popoutBaseEl.style.top = `${this.getTriggerElRect().bottom + windowScrollY}px`;
          }
        );
        getDocumentElementWidth().fork(
          (e) =>
            warn(
              'Unable to access document.documentElement.getBoundingClientRect().width when setting popout left position',
              e.message
            ),
          (width) => {
            popoutBaseEl.style.left = `${
              this.getTriggerElRect().right - this.outsideWindowAdjustments(width, popoutBaseEl)
            }px`;
          }
        );
      },
    });
  };

  componentDidMount() {
    windowAddListener('resize', this.setPosition).fork(
      (e) => warn('Unable to add window resize listener for popout', e),
      noop
    );
    this.setPosition();
  }

  componentWillUnmount() {
    windowAddListener('resize', this.setPosition).fork(
      (e) => warn('Unable to remove window resize listener for popout', e),
      noop
    );
  }

  render() {
    return (
      <div ref={this.ref} className={style.popout}>
        {this.props.children}
      </div>
    );
  }
}

export default observer(PopoutImpl);
