import { Seconds } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated, T } from '../../../Translations';
import * as style from '../../Conference/style.module.css';

interface Props {
  secondsRemaining: Seconds;
}

const timeString = (totalSeconds: Seconds) => {
  const minutes = Math.floor(totalSeconds.seconds / 60);
  const seconds = (totalSeconds.seconds - minutes * 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
};

const Timer: React.FC<Props> = ({ secondsRemaining }) => (
  <T
    kind="<time/> <fragment>remaining</fragment>"
    time={
      <div className={style.timerValue} data-test-time-remaining={secondsRemaining}>
        <NotTranslated text={timeString(secondsRemaining)} />
      </div>
    }
    fragment={(content) => <div className={style.timerLabel}>{content}</div>}
  />
);

export default observer(Timer);
