import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Task } from 'taskarian';
import { callApi } from '../../Appy';
import { LocaleChangeError, changeLocaleT } from '../../ChangeLocale';
import { currentUserStore } from '../../CurrentUser/Store';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { handleError } from '../../ErrorActionableReaction';
import { findLinkT } from '../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import { Link } from '../../Resource/Types';
import SubscriptionStore from '../Unsubscribe/Store';
import { subscriptionResourceDecoder } from '../Unsubscribe/Store/Decoders';
import { LoadingError, State, SubscriptionResource } from '../Unsubscribe/Store/Types';

const getRegisterResource = callApi(subscriptionResourceDecoder, {});

const processTemplateLink =
  (id: string) =>
  (link: Link): Link => ({
    ...link,
    href: link.href.replace('{id}', id),
  });

const handleBadStatus = (store: SubscriptionStore, error: BadStatus) => {
  switch (error.response.status) {
    case 404:
      store.error(
        'Unable to unsubscribe. Course Registration with the requested ID could not be found.',
      );
      break;
    case 403:
      store.error('You are not permitted to unsubscribe from this email');
      break;
    default:
      handleError(store, error);
  }
};

const handleUnsubscribingError = (store: SubscriptionStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Unsubscribe link is unavailable');
      break;
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    default:
      handleError(store, error);
  }
};

const handlePreferredLanguageChangeError = (err: LocaleChangeError) => {
  switch (err.kind) {
    case 'locale-change-skipped':
      break;
    case 'i18next-interaction-error':
    case 'could-not-reload-error':
      warn(err);
      break;
    default:
      assertNever(err);
  }
};

const handleUnsubscribe = (resource: SubscriptionResource): void => {
  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
      break;
    case 'logging-out':
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      changeLocaleT(resource.payload.preferredLanguage.getOrElseValue('en')).fork(
        handlePreferredLanguageChangeError,
        noop,
      );
      break;
    default:
      assertNever(currentUserStore.state);
  }
};

interface Props extends RCProps<SubscriptionStore> {
  currentUserResource: CurrentUserResource;
}

class SubscriptionReactions extends ReactionComponent<SubscriptionStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        store.unsubscribing(store.guid);
        break;
      case 'unsubscribing':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('unsubscribe-progress-email-template'))
          .map(processTemplateLink(state.guid))
          .andThen(getRegisterResource)
          .fork(handleUnsubscribingError(store), store.unsubscribed);
        break;
      case 'unsubscribed':
        handleUnsubscribe(state.resource);
        break;
      case 'error':
        warn(state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default SubscriptionReactions;
