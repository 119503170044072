import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { CompetencyResource } from '../../../../../Experience/Types';
import { ExperienceCompetencyItems } from '../../../../../Experience/ExperienceCompetencies';

interface Props {
  competencies: Maybe<CompetencyResource[]>;
}

function ProgramCompetencies({ competencies }: Props) {
  return competencies
    .map((competencies) => <ExperienceCompetencyItems competencies={competencies} />)
    .getOrElse(emptyFragment);
}

export default observer(ProgramCompetencies);
