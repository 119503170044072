import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { segmentResourceRoute } from '../../../../ClientRoutes';
import { NotTranslated } from '../../../../Translations';
import VideoIcon from '../../../Cutesies/VideoIcon';
import ImportantIcon from '../../ImportantIcon';
import { Video } from '../../Types';
import * as style from './style.module.css';
import { CirclePlaySolidIcon } from '@execonline-inc/execonline-ui';

interface Props {
  video: Video;
  moduleId: number;
  segmentId: number;
}

const ProgramResourcesSegmentVideoResource: React.FC<Props> = ({ video, moduleId, segmentId }) => {
  return (
    <div className={style.resource} data-test-video-resource={true}>
      <Link
        to={segmentResourceRoute(video.programId, moduleId, segmentId, video.id)}
        data-test-resource-link={true}
        target="_blank"
        className={style.content}
      >
        <ImportantIcon important={video.important} className={style.importantIcon} />
        <div className={style.icon}>
          <VideoIcon />
        </div>
        <div className={style.resourceDetails}>
          <span className={style.name} data-test-resource-title={true}>
            <NotTranslated text={video.title} />{' '}
            {video.assetFileExtension
              .map((assetFileExtension) => (
                <span className={style.metaData}>
                  <NotTranslated text={assetFileExtension} />
                </span>
              ))
              .getOrElseValue(<></>)}
          </span>
          {video.description
            .map((description) => (
              <div className={style.description}>{ReactHtmlParser(description)}</div>
            ))
            .getOrElseValue(<></>)}
        </div>
        <div className={style.play}>
          <CirclePlaySolidIcon color="black" size="md" />
        </div>
      </Link>
    </div>
  );
};

export default observer(ProgramResourcesSegmentVideoResource);
