import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { TeamResource } from '../../../../TeamsStore/Types';
import { T } from '../../../../Translations';
import { Position } from '../../../PositionContext';
import * as style from '../../style.module.css';

interface Props {
  teams: NonEmptyList<TeamResource>;
  position: Position;
}

const TeamHeader: React.FC<Props> = ({ teams, position }) => {
  switch (position.kind) {
    case 'supPanel':
      return (
        <h2 className={style.supPanelHeader} data-test-professors-title={true}>
          <T kind="Team" count={teams.length} />
        </h2>
      );
    case 'overview':
    case 'nav':
    case 'dashboard':
      return (
        <dt data-test-teams-title={true}>
          <h4 className={style.header}>
            <T kind="Team" count={teams.length} />
          </h4>
        </dt>
      );
    case 'tooltip':
    case 'chat':
    case 'participant':
    case 'footer':
    case 'none':
      return (
        <dt data-test-teams-title={true}>
          <T kind="Team" count={teams.length} />
        </dt>
      );
  }
};

export default observer(TeamHeader);
