import { observer } from 'mobx-react';
import { SvgIcon } from '@execonline-inc/execonline-ui';

function VisaCardIcon() {
  return (
    <SvgIcon>
      <path
        fill="none"
        d="M23.85 18.87c0 1.38-1.01 2.5-2.26 2.5H2.41c-1.25 0-2.26-1.12-2.26-2.5V5.13c0-1.38 1.01-2.5 2.26-2.5h19.18c1.25 0 2.26 1.12 2.26 2.5v13.74zm0 0"
      />
      <path
        fill="#2100C4"
        d="m7.03 8.88-1.48 4.89-.42-2.33C4.3 9.3 3.04 9.43 3.04 9.43l1.47 6.32H6.3l2.46-6.87H7.03zm1.4 6.87h1.63l.98-6.87H9.4l-.96 6.87zM19.9 8.88h-1.7l-2.66 6.87h1.61l.33-.98h2.03l.17.98h1.48L19.9 8.88zm-1.97 4.57.88-2.6.46 2.6h-1.34zm-4.6-2.57c0-.38.29-.66 1.1-.66.52 0 1.12.42 1.12.42l.26-1.44s-.77-.32-1.52-.32c-1.7 0-2.58.9-2.58 2.04 0 2.07 2.24 1.78 2.24 2.84 0 .18-.13.6-1.06.6a3.2 3.2 0 0 1-1.56-.37l-.28 1.38s.6.38 1.76.38c1.16 0 2.77-.96 2.77-2.35 0-1.66-2.24-1.78-2.24-2.52zm0 0"
      />
      <path fill="#F5BC00" d="M5.35 12.59 4.8 9.62S4.56 9 3.9 9h-2.5s3.2 1.04 3.94 3.6zm0 0" />
    </SvgIcon>
  );
}

export default observer(VisaCardIcon);
