import { observer } from 'mobx-react';
import * as React from 'react';
import { Assignment, Document } from '../../../../TeamResourceStore/Types';
import CompletedResource from './CompletedResource';
import IncompleteResource from './IncompleteResource';

interface Props {
  assignment: Assignment;
  resource: Document;
}

const DocumentResource: React.FC<Props> = ({ assignment, resource }) => {
  switch (resource.processingStatus) {
    case 'completed':
      return <CompletedResource assignment={assignment} resource={resource} />;
    case 'processing':
      return <IncompleteResource resource={resource} />;
  }
};

export default observer(DocumentResource);
