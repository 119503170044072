import { observer } from 'mobx-react';
import RegisterResourceStore from '.';
import Loader from '../../../../components/Loader';
import ErrorMessage from '../../../ErrorMessage';
import { RegisterResource } from './Types';

interface Props {
  store: RegisterResourceStore;
  children: (registerResource: RegisterResource) => React.ReactElement;
}

function WhenRegisterResource({ store, children }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'loading-register-resource':
    case 'loading-preview-resource':
      return <Loader delay={0} />;
    case 'register-resource-error':
      return <ErrorMessage message="Unable to load the invitation resource." />;
    case 'preview-resource-error':
      return <ErrorMessage message="Unable to load the learning collection resource." />;
    case 'ready':
      return children(store.state.resource);
  }
}

export default observer(WhenRegisterResource);
