import { parseIntM } from '@execonline-inc/numbers';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import EventsStore from '../../../EventsStore';
import TeamStore from '../../../TeamStore';
import TeamReactions from '../../../TeamStore/TeamReactions';
import { TPlainTextKey } from '../../../Translations';
import Events from './Events';

interface Params {
  teamsId: string;
}

interface Props {
  eventsStore: EventsStore;
  header: TPlainTextKey;
  headingLevel: 2 | 3;
  match: { params: Params };
}

class TeamEvents extends React.Component<Props, {}> {
  teamStore: TeamStore;
  teamId: Maybe<number>;

  constructor(props: Props) {
    super(props);
    this.teamStore = new TeamStore();
    this.teamId = parseIntM(this.props.match.params.teamsId);
  }

  componentDidMount() {
    this.teamStore.loading();
  }

  render() {
    const { headingLevel, header } = this.props;
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <Events
              eventsStore={this.props.eventsStore}
              header={header}
              headingLevel={headingLevel}
              linkable={this.teamStore}
            />
            <TeamReactions
              store={this.teamStore}
              teamId={this.teamId}
              currentUserResource={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default observer(TeamEvents);
