import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { useEffect, useState } from 'react';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../Appy';
import { Link } from '../../Resource/Types';
import { T } from '../../Translations';
import { viewableAssetResourceDecoder } from './Decoders';
import { State, ViewableAssetResource, boxError, ready } from './Types';
import ViewFile from './ViewFile';
import { ViewHeader } from './ViewHeader';
import { ViewLoader } from './ViewLoader';
import Title from '../../Title';

interface Props {
  link: Link;
}

function BoxViewer({ link }: Props) {
  const [boxState, setBoxState] = useState<State>({ kind: 'loading' });

  useEffect(() => {
    Task.succeed<AppyError, Link>(link)
      .andThen<ViewableAssetResource>(callApi(viewableAssetResourceDecoder, {}))
      .map(ready)
      .mapError(boxError)
      .elseDo(warn)
      .fork(setBoxState, setBoxState);
  }, [link]);

  switch (boxState.kind) {
    case 'error':
      return (
        <p className="text-black">
          <T kind="Oops! We were unable to find your file." />
        </p>
      );
    case 'loading':
      return <ViewLoader />;
    case 'ready':
      const { filename } = boxState.viewableAsset.payload;
      return (
        <>
          <Title title={`Resources - ${filename}`} />
          <ViewHeader filename={filename} />
          <ViewFile resource={boxState.viewableAsset} />
        </>
      );

    default:
      assertNever(boxState);
  }
}

export default BoxViewer;
