import { observer } from 'mobx-react';
import * as React from 'react';
import IFrameContent from '../IFrameContent';
import { findLink } from '../../LinkyLinky';
import WindowMessageReactions from './Modal/Store/WindowMessageReactions';
import SurveyModalStore from './Modal/Store';

interface Props {
  store: SurveyModalStore;
}

const SurveyModalContent: React.FC<Props> = ({ store }) => {
  const iFrameRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  return findLink('survey', store.links)
    .map((link) => {
      return (
        <div data-test-survey-segment={link.href}>
          <WindowMessageReactions iFrameRef={iFrameRef} store={store} />
          <IFrameContent
            source={link.href}
            style={{ border: 0 }}
            seamless={true}
            width="100%"
            allowFullScreen={true}
            height="600px"
            scrollbars="on"
            iFrameRef={iFrameRef}
          />
        </div>
      );
    })
    .getOrElseValue(<></>);
};
export default observer(SurveyModalContent);
