import {
  ChevronLeftIcon,
  DrawerBody,
  DrawerHeader,
  IconButton,
  Typography,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import UseCaseMenuList from './UseCaseMenuList';

interface Props {
  onSubMenuClose: () => void;
  onClose: () => void;
}

function UseCaseNavigation({ onSubMenuClose, onClose }: Props) {
  const { translate } = useTranslationsContext();

  return (
    <>
      <DrawerHeader className="items-center justify-between">
        <IconButton aria-label={translate('Go Back')} className="p-1" onClick={onSubMenuClose}>
          <ChevronLeftIcon size="sm" />
        </IconButton>
        <Typography className="w-full text-center" variant="bodyMedium">
          <T kind="Learning Collections" />
        </Typography>
      </DrawerHeader>
      <DrawerBody>
        <UseCaseMenuList onClick={onClose} />
      </DrawerBody>
    </>
  );
}

export default observer(UseCaseNavigation);
