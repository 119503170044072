import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';

function validateEventTarget(eventTarget: EventTarget) {
  return fromNullable(eventTarget).andThen<HTMLElement>((target) =>
    target instanceof HTMLElement ? just(target) : nothing(),
  );
}

function whenContainsElement(rootElement: HTMLElement, element: HTMLElement): Maybe<{}> {
  return when(equals(element, rootElement) || rootElement.contains(element), {});
}

function whenContainsEventTarget(rootElement: EventTarget, element: EventTarget): Maybe<{}> {
  return just({})
    .assign('rootElement', validateEventTarget(rootElement))
    .assign('element', validateEventTarget(element))
    .andThen(({ element, rootElement }) => whenContainsElement(rootElement, element));
}

export function handleMouseDown(action: () => void) {
  return (e: React.MouseEvent) => {
    whenContainsEventTarget(e.currentTarget, e.target).do(() => {
      e.stopPropagation();
      action();
    });
  };
}

export function handleEnterKeyDown(action: () => void) {
  return (e: React.KeyboardEvent) => {
    whenContainsEventTarget(e.currentTarget, e.target)
      .andThen(() => when(equals(e.key, 'Enter'), {}))
      .do(() => {
        e.stopPropagation();
        action();
      });
  };
}
