import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import CountryRegionOptionsStore from '../../../../CountryRegionOptionsStore';
import ProfileFormStore from '../../../../ProfileFormStore';
import RegionSelector from './RegionSelector';

interface Props {
  countryRegionOptionsStore: CountryRegionOptionsStore;
  profileStore: ProfileFormStore;
}

const RegionSelect: React.FC<Props> = ({ profileStore, countryRegionOptionsStore }) => {
  switch (countryRegionOptionsStore.state.kind) {
    case 'loading':
    case 'ready-with-regions':
      return fromEmpty(countryRegionOptionsStore.countryRegionOptions)
        .map(() => (
          <RegionSelector
            countryRegionOptionsStore={countryRegionOptionsStore}
            profileStore={profileStore}
          />
        ))
        .getOrElse(() => <></>);
    case 'ready-without-regions':
    case 'waiting':
    case 'error':
    case 'ready-with-errors':
      return <></>;
  }
};

export default observer(RegionSelect);
