import { NotTranslated } from '@execonline-inc/translations';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import { TeamMemberResource } from '../../TeamsStore/Types';
import * as style from '../TeamProfile/style.module.css';
import TeamMember from '../TeamSummary/TeamMemberList/TeamMember';

interface Props {
  teamMember: TeamMemberResource;
  globalPresence: GlobalPresenceMembersStore;
}

const MemberListItem: React.FC<Props> = ({ teamMember, globalPresence }) => {
  return (
    <div key={teamMember.payload.id}>
      <div className={style.memberFull}>
        <TeamMember teamMemberResource={teamMember} globalPresence={just(globalPresence)} />
        <div className={style.memberDetails}>
          {teamMember.payload.profile.payload.name
            .map((name) => (
              <p className={style.titleOnBody}>
                <NotTranslated text={name} />
              </p>
            ))
            .getOrElse(() => (
              <></>
            ))}
          {teamMember.payload.profile.payload.organization
            .map((organization) => (
              <p className={style.subtitleOnBody}>
                <NotTranslated text={organization} />
              </p>
            ))
            .getOrElse(() => (
              <></>
            ))}
        </div>
      </div>
    </div>
  );
};

export default observer(MemberListItem);
