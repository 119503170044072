import { Maybe } from 'maybeasy';
import { AppyError } from '../../../../Appy';
import { MissingLinkError } from '../../../../LinkyLinky';
import { Resource } from '../../../../Resource/Types';
import { SSO } from '../../../../SingleSignOn';
import { SupportedLanguageCode } from '../../../../SupportedLanguages/Types';
import { TPlainTextKey } from '../../../../Translations';
import LockedAccessStore from '../../RequireAuthentication/LockedAccessStore';
import SetPasswordStore from '../../RequireAuthentication/SetPasswordStore';
import SsoLoginStore from '../../RequireAuthentication/SsoLoginStore';
import UsePasswordStore from '../../RequireAuthentication/UsePasswordStore';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { InvalidUrlError } from '@execonline-inc/url';

interface Waiting {
  kind: 'waiting';
}

interface LoadingRegisterResource {
  kind: 'loading-register-resource';
  guid: string;
}
interface LoadingPreviewResource {
  kind: 'loading-preview-resource';
  id: string;
}

interface Error {
  message: TPlainTextKey;
}

interface RegisterResourceError extends Error {
  kind: 'register-resource-error';
}

interface PreviewResourceError extends Error {
  kind: 'preview-resource-error';
}

interface Ready {
  kind: 'ready';
  resource: RegisterResource;
}

type Loading = LoadingRegisterResource | LoadingPreviewResource;
type ErrorState = RegisterResourceError | PreviewResourceError;

export type State = Waiting | Loading | ErrorState | Ready;

export interface Organization {
  id: number;
  name: string;
}

export type OrganizationResource = Resource<Organization>;

export interface SsoAuth extends Pick<SSO.State, 'urls'> {
  kind: 'sso-auth';
  termsAccepted: boolean;
  store: SsoLoginStore;
}

export interface LockedAccessAuth {
  kind: 'locked-access-auth';
  store: LockedAccessStore;
}

export interface ResetSpecific {
  kind: 'reset-password';
  store: SetPasswordStore;
}

export interface PasswordSetSpecific {
  kind: 'password-set';
  store: UsePasswordStore;
}

export type PasswordState = ResetSpecific | PasswordSetSpecific;

export interface PasswordAuth {
  kind: 'password-auth';
  passwordState: PasswordState;
  termsAccepted: boolean;
}

export type Auth = SsoAuth | PasswordAuth | LockedAccessAuth;

export interface CommerceDirectEnrollment {
  courseRegistrationId: number;
  availabilityId: number;
  experienceId: string;
}

export type CommerceDirectEnrollmentResource = Resource<CommerceDirectEnrollment>;

export interface RegisterInfo {
  userId: number;
  emailAddress: string;
  sharedInvitation: SharedInvitationResource;
  auth: Auth;
  preferredLanguage: Maybe<SupportedLanguageCode>;
  commerceDirectEnrollmentResource: Maybe<CommerceDirectEnrollmentResource>;
  guid: Maybe<string>;
  autoEnrollProgramId: Maybe<number>;
}

export type RegisterResource = Resource<RegisterInfo>;

export type InvalidParamsError = { kind: 'invalid-params-error' };

export type LoadingError = MissingLinkError | AppyError | InvalidUrlError | InvalidParamsError;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loadingRegisterResource = (guid: string): LoadingRegisterResource => ({
  kind: 'loading-register-resource',
  guid,
});

export const loadingPreviewResource = (id: string): LoadingPreviewResource => ({
  kind: 'loading-preview-resource',
  id,
});

export const registrationResourceError = (message: TPlainTextKey): RegisterResourceError => ({
  kind: 'register-resource-error',
  message,
});

export const previewResourceError = (message: TPlainTextKey): PreviewResourceError => ({
  kind: 'preview-resource-error',
  message,
});

export const ready = (resource: RegisterResource): Ready => ({
  kind: 'ready',
  resource,
});

const lockedAccessAuth = (message: Maybe<TPlainTextKey>): LockedAccessAuth => {
  return { kind: 'locked-access-auth', store: new LockedAccessStore(message) };
};

export const readyLockedAccess = (
  resource: RegisterResource,
  message: Maybe<TPlainTextKey>,
): Ready => {
  switch (resource.payload.auth.kind) {
    case 'password-auth':
      const updatedPayload = { ...resource.payload, auth: lockedAccessAuth(message) };
      const currentResource = { ...resource, payload: updatedPayload };
      return {
        kind: 'ready',
        resource: currentResource,
      };
    case 'locked-access-auth':
    case 'sso-auth':
      return {
        kind: 'ready',
        resource,
      };
  }
};
