import { ListItem, ListItemLink } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import History from '../../../../../History';
import { T } from '../../../../../Translations';
import { MenuItemContent, menuItems } from '../../Types';

export interface Props {
  user: CurrentUserResource;
  onClick: () => void;
}

function MainListMenu({ user, onClick }: Props) {
  const handleClick = (href: string) => () => {
    onClick();
    History.push(href);
  };

  return (
    <>
      {menuItems(user.links).map((mi: MenuItemContent) => (
        <ListItem key={mi.rel} role="menuitem" className="p-0" onClick={handleClick(mi.to)}>
          <ListItemLink
            data-testid={mi.rel}
            render={({ className, activeClassName }) => (
              <NavLink
                to={mi.to}
                tabIndex={-1}
                className={className}
                activeClassName={activeClassName}
                data-testid={mi.rel}
                exact={mi.exact}
              >
                <T kind={mi.content} />
              </NavLink>
            )}
          />
        </ListItem>
      ))}
    </>
  );
}

export default observer(MainListMenu);
