import * as React from 'react';
import { Assignment } from '../../../../TeamResourceStore/Types';
import * as style from '../../style.module.css';
import Attachment from './Attachment';
import AttachmentsHeader from './AttachmentsHeader';

interface Props {
  assignment: Assignment;
}

const Attachments: React.FC<Props> = ({ assignment }) => (
  <ol className={style.attachments}>
    <strong>
      <AttachmentsHeader assignment={assignment} />
    </strong>
    {assignment.resources.map((resource, index) => (
      <Attachment key={resource.payload.id} index={index} attachment={resource} />
    ))}
  </ol>
);

export default Attachments;
