import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const NotesTabIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 13">
      <path
        fill="#000000"
        d="M.5 0v12h8.403l.17-.153 3.274-3.273.153-.17V0H.5Zm1.09 1.09h9.82v6.546H8.135v3.273H1.591V1.091Zm7.637 7.637h1.398l-1.398 1.398V8.727Z"
      />
    </SVGIcon>
  );
};

export default observer(NotesTabIcon);
