import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ProfileDefaultIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 62 62">
      <path
        fill={defaultTheme.variables['icon-standard-on-dark']}
        d="M31 0C13.907 0 0 13.907 0 31s13.907 31 31 31 31-13.907 31-31S48.093 0 31 0Zm0 5c14.385 0 26 11.615 26 26S45.385 57 31 57 5 45.385 5 31 16.615 5 31 5Zm.5 7c-6.482 0-11.786 5.4-11.786 12 0 3.637 1.667 6.872 4.199 9.075C18.609 35.878 15 41.522 15 48h4.714c0-6.656 5.249-12 11.786-12s11.786 5.344 11.786 12H48c0-6.478-3.61-12.122-8.913-14.925 2.532-2.203 4.199-5.438 4.199-9.075 0-6.6-5.304-12-11.786-12Zm-.5 5c3.892 0 7 3.108 7 7s-3.108 7-7 7-7-3.108-7-7 3.108-7 7-7Z"
      />
    </SVGIcon>
  );
};

export default observer(ProfileDefaultIcon);
