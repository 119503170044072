import { Maybe } from 'maybeasy';
import * as React from 'react';
import { translation, TranslationsContext } from '../../../Translations';
import TwitterIcon from '../../Cutesies/TwitterIcon';
import * as style from '../style.module.css';

interface Props {
  twitterPage: Maybe<string>;
}

const Twitter: React.FC<Props> = ({ twitterPage }) =>
  twitterPage
    .map((twitter) => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <a
            data-test-profile-popout-twitter={true}
            title={translation('Twitter Profile')(ts)}
            className={style.twitter}
            href={twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon />
          </a>
        )}
      </TranslationsContext.Consumer>
    ))
    .getOrElseValue(<div />);

export default Twitter;
