import { Button, ButtonColor, cn, useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../../../../../Translations';
import { getButtonSize } from '../Types';

interface Props {
  value: TPlainTextKey;
  color?: ButtonColor;
  className?: string;
  onClick: () => void;
}

function OnDemandAction({ color = 'gray', value, className, onClick }: Props) {
  const breakPoint = useMediaQuery();

  return (
    <Button
      className={cn('mt-20 min-w-full sm:mt-10 sm:min-w-48', className)}
      color={color}
      size={getButtonSize(breakPoint)}
      onClick={onClick}
    >
      <T kind={value} />
    </Button>
  );
}

export default observer(OnDemandAction);
