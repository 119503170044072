import { now } from 'mobx-utils';
import ConferenceRosterStore from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';

export interface Props extends EAProps<ConferenceRosterStore> {
  store: ConferenceRosterStore;
}

const rosterPollingInterval: number = 30000;

class ConferenceRosterRefreshReactions extends ErrorActionableReaction<
  ConferenceRosterStore,
  number,
  Props
> {
  tester = () => now(rosterPollingInterval);
  effect = () => {
    this.props.store.polling();
  };
}

export default ConferenceRosterRefreshReactions;
