import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import ProgramChatStore from '../../ProgramChatStore';
import { Link } from '../../Resource/Types';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import ResumeProgram from '../TeamProfile/TeamChat/ResumeProgram';
import * as style from '../TeamProfile/style.module.css';
import ProgramBreadCrumb from './ProgramBreadCrumb';
import ProgramMemberContent from './ProgramMemberContent';
import ProgramPageContent from './ProgramPageContent';

interface Props {
  programChatStore: ProgramChatStore;
  presenceMembersStore: PresenceMembersStore;
  presenceStore: GlobalPresenceMembersStore;
  links: ReadonlyArray<Link>;
}

class ProgramProfile extends React.Component<Props> {
  componentDidMount() {
    const { presenceStore, presenceMembersStore } = this.props;
    presenceStore.addPresenceStore(presenceMembersStore);
  }

  componentWillUnmount() {
    const { presenceStore, presenceMembersStore } = this.props;
    presenceStore.removePresenceStore(presenceMembersStore);
  }

  render() {
    const { programChatStore, presenceStore, presenceMembersStore, links } = this.props;
    return programChatStore.program
      .map((programResource) => {
        return (
          <>
            <ProgramBreadCrumb programResource={programResource} links={links} />
            <div className={style.resumeProgramButton}>
              <ResumeProgram courseId={programResource.payload.id} />
            </div>
            <div className={style.team}>
              <div className={style.members} data-test-team-member-list={true}>
                <ProgramMemberContent presenceMembersStore={presenceMembersStore} />
              </div>
              <div className={style.content}>
                <ProgramPageContent
                  programChatStore={programChatStore}
                  conversationStore={presenceMembersStore.conversationStore}
                  presenceStore={just(presenceStore)}
                />
              </div>
            </div>
          </>
        );
      })
      .getOrElse(() => <></>);
  }
}

export default observer(ProgramProfile);
