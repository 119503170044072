import * as React from 'react';
import { handleEnterOrSpaceKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { ExpandableItem } from '../../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../../ProgramStore/Types';
import { AlreadyTranslated, NotTranslated } from '../../../../Translations';
import TimeToComplete from '../../../TimeToComplete';
import ExpandModule from './ExpandModule';
import ModuleIcon from './ModuleIcon';
import Segments from './Segments';
import * as style from './style.module.css';

interface Props {
  expandableModule: ExpandableItem<ActiveProgramModule>;
  programId: string;
  toggle: (expandableModule: ExpandableItem<ActiveProgramModule>) => void;
}
const Module: React.StatelessComponent<Props> = ({ expandableModule, programId, toggle }) => {
  const module = expandableModule.item;

  const onToggle = () => {
    module.isGated ? false : toggle(expandableModule);
  };

  return (
    <li className={style.module} data-test-module={module.id}>
      <div
        className={style.details}
        onClick={onToggle}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(onToggle)}
      >
        <div className={style.progress}>
          <div className={style.iconStatus}>
            <ModuleIcon module={module} />
          </div>
        </div>
        <div className={style.title}>
          <h3 data-test-module-title={module.title}>
            <span className={style.label} data-test-module-label={module.label.getOrElseValue('')}>
              <NotTranslated text={module.label.getOrElseValue('')} />:
            </span>
            <AlreadyTranslated content={module.title} />
          </h3>
          <TimeToComplete ttc={module.timeToComplete} />
        </div>
        <ExpandModule expandableModule={expandableModule} />
      </div>
      {!module.isGated && <Segments expandableModule={expandableModule} />}
    </li>
  );
};
export default Module;
