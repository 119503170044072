import { parseIntM } from '@execonline-inc/numbers';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ProgramChatStore from '../../ProgramChatStore';
import ProgramChatReactions from '../../ProgramChatStore/ProgramChatReactions';
import I18nTitle from '../../Title/I18nTitle';
import AutoLaunchable from '../AutoLaunchable';
import Footer from '../Footer';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import ProgramView from './ProgramView';
import NavigationBar from '../Platform/NavigationBar';

interface Params {
  programsId: string;
}
interface Props {
  match: { params: Params };
}

@observer
class Program extends React.Component<Props, {}> {
  programChatStore: ProgramChatStore;
  programId: Maybe<number>;

  constructor(props: Props) {
    super(props);
    this.programChatStore = new ProgramChatStore();
    this.programId = parseIntM(this.props.match.params.programsId);
  }

  componentDidMount() {
    this.programChatStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <I18nTitle title="Teams" />
            <div className={platformStyle.teamContainer}>
              <SkipNavLink />
              <NavigationBar />
              <Notifications />
              <AutoLaunchable />
              <Main id="main" className="mx-8">
                <ProgramView programId={this.programId} programChatStore={this.programChatStore} />
                <NotificationSource store={this.programChatStore} />
                <ProgramChatReactions
                  store={this.programChatStore}
                  programId={this.programId}
                  currentUserResource={currentUserResource}
                />
              </Main>
              <Footer />
            </div>
          </>
        )}
      />
    );
  }
}

export default Program;
