import { Button } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import EmailField from '../../../Form/EmailField';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import SsoLoginStore from '../SsoLoginStore';

interface Props {
  ssoLoginStore: SsoLoginStore;
  registerResource: RegisterResource;
}

function SsoForm({ ssoLoginStore, registerResource }: Props) {
  const submit = (store: SsoLoginStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.created();
  };

  return (
    <form
      data-testid="sign-in-sso-form"
      className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
      onSubmit={submit(ssoLoginStore)}
    >
      <div className="flex sm:gap-6">
        <div className="basis-full sm:basis-1/2">
          <EmailField
            data-testid="sign-in-sso-form_email-text-field"
            value={registerResource.payload.emailAddress}
            disabled
          />
        </div>
        <div className="sm:basis-1/2" />
      </div>
      <div className="mt-6">
        <Button
          data-testid="sign-in-sso-form_continue-button"
          type="submit"
          size="lg"
          color="success"
          disabled={ssoLoginStore.formDisabled}
        >
          <T kind="Continue with SSO" />
        </Button>
      </div>
    </form>
  );
}

export default observer(SsoForm);
