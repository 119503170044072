import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ChatMessageReactionsResource } from '../../../../ConversationStore/Types';
import { NotTranslated } from '../../../../Translations';
import MessageReactionStore from '../MessageReactionStore';
import * as style from '../style.module.css';
import ActionMenuStore from './ActionMenuStore';

interface Props {
  actionMenuStore: ActionMenuStore;
  reactionsResource: ChatMessageReactionsResource;
}

class ActionMenuReactionList extends React.Component<Props> {
  portal: HTMLElement;
  listRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.portal = document.createElement('div');
    this.portal.id = 'reaction portal';
    const root = document.getElementById('root');
    if (root) {
      root.appendChild(this.portal);
    }
  }

  componentDidMount() {
    const listEl = this.listRef.current;
    const menuStoreState = this.props.actionMenuStore.state;
    if (listEl && menuStoreState.kind === 'reaction-menu-open') {
      listEl.style.top = `${menuStoreState.mouseY}px`;
      listEl.style.left = `${menuStoreState.mouseX - 150}px`;
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (root && this.portal) {
      root.removeChild(this.portal);
    }
  }

  discardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  render() {
    const { actionMenuStore, reactionsResource } = this.props;
    return ReactDOM.createPortal(
      <div
        className={style.actionMenuReactionListWrapper}
        onClick={actionMenuStore.toggleReactionMenu}
        data-test-reaction-menu-overlay
      >
        <div
          className={style.actionMenuReactionList}
          onClick={this.discardClick}
          ref={this.listRef}
        >
          {MessageReactionStore.emojiList.map((emoji: string) => (
            <div
              key={emoji}
              className={style.actionMenuReaction}
              onClick={() => actionMenuStore.selectReaction(emoji, reactionsResource)}
              data-test-reaction-menu-item
            >
              <NotTranslated text={emoji} />
            </div>
          ))}
        </div>
      </div>,
      this.portal
    );
  }
}

export default observer(ActionMenuReactionList);
