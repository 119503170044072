import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import { Experience } from '../../../../Experience/Types';

interface Props {
  experience: Experience;
}

function ExperiencesHeaderLogoSuffix({ experience }: Props) {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
      return (
        <Typography
          className="lowercase text-[#824A7B]"
          fontWeight="semibold"
          variant="bodySmall"
          as="span"
        >
          <T kind="Coaching" />
        </Typography>
      );
    case 'program-sequence':
      return (
        <Typography className="text-[#006177]" fontWeight="semibold" variant="bodySmall" as="span">
          <T kind="Learning Journey" />
        </Typography>
      );
    case 'aep':
    case 'epc':
    case 'msuite':
      return emptyFragment();
  }
}

export default observer(ExperiencesHeaderLogoSuffix);
