import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import FreebusyProgramAdReactions from './FreebusyProgramAdReactions';
import FreebusyProgramAdStates from './FreebusyProgramAdStates';
import FreebusyProgramAdStore from './FreebusyProgramAdStore';

interface Props {
  currentUserResource: CurrentUserResource;
}

class FreebusyProgramAd extends React.Component<Props> {
  store = new FreebusyProgramAdStore(this.props.currentUserResource);

  render() {
    return (
      <>
        <FreebusyProgramAdStates store={this.store} />
        <FreebusyProgramAdReactions
          store={this.store}
          currentUserResource={this.props.currentUserResource}
          fireImmediately
        />
      </>
    );
  }
}

export default observer(FreebusyProgramAd);
