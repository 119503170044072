import * as React from 'react';
import { observer } from 'mobx-react';
import { PersonStore } from '../../../Person';
import * as style from './style.module.css';
import PersonPhoto from '../../PersonPhoto';

interface Props {
  personStore: PersonStore;
}

const MessageAvatar: React.FC<Props> = ({ personStore }) => (
  <div className={style.avatarReply}>
    <PersonPhoto avatar={personStore.avatar} />
  </div>
);

export default observer(MessageAvatar);
