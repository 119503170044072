import { assertNever } from '@kofno/piper';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import ConvertedPptStore from './index';

export interface Props extends EAProps<ConvertedPptStore> {
  store: ConvertedPptStore;
}

/*
 * This reaction monitors the state of the current step number of the
 * presentation (which is derived from the video position) and advances the
 * presentation through its steps.
 *
 * This reaction will only fire once the store has loaded up iSpring and a
 * video position has become available on the kettle. Before then, the
 * currentStepNumber reamins stuck at zero.
 */
class ConvertedPptStepReactions extends ErrorActionableReaction<ConvertedPptStore, number, Props> {
  tester = () => this.props.store.currentStepNumber;

  effect = (step: number) => {
    const state = this.props.store.state;
    switch (state.kind) {
      case 'waiting':
      case 'loading':
      case 'player-ready':
      case 'error':
        break;
      case 'waiting-for-video': {
        this.props.store.ready();
        break;
      }
      case 'advancing':
      case 'ready': {
        this.props.store.advance(step);
        break;
      }
      default:
        assertNever(state);
    }
  };
}

export default ConvertedPptStepReactions;
