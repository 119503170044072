import { observer } from 'mobx-react';
import {
  CalendarOneIcon,
  CalendarThreeIcon,
  CalendarTwoIcon,
  SvgIcon,
} from '@execonline-inc/execonline-ui';
import { GroupCoachingSessionDetails } from '../../../../Types';
import GroupCoachSessionTitle from '../GroupCoachSessionTitle';
import GroupCoachSessionDateTime from '../GroupCoachSessionDateTime';

interface Props {
  order: number;
  session: GroupCoachingSessionDetails;
}

const icons: Record<number, typeof SvgIcon> = {
  0: CalendarOneIcon,
  1: CalendarTwoIcon,
  2: CalendarThreeIcon,
};

function GroupCoachingSessionListItem({ order, session }: Props) {
  const { title, startTime } = session;
  const IconComponent = icons[order % 3];

  return (
    <li className="flex">
      <IconComponent className="mr-2" color="black" />
      <div>
        <GroupCoachSessionTitle title={title} />
        <GroupCoachSessionDateTime date={startTime} />
      </div>
    </li>
  );
}

export default observer(GroupCoachingSessionListItem);
