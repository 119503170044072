import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { GlobalPresenceContext } from '../../../../../GlobalPresence/GlobalPresenceContext';
import { LearningPartnerResource } from '../../../../../LearningPartnersStore/Types';
import { learningPartnerStore } from '../../../../../People';
import Person from '../../../../Person';
import * as style from '../../../style.module.css';

interface Props {
  learningPartnerResource: LearningPartnerResource;
}

const LearningPartnerImpl: React.FC<Props> = ({ learningPartnerResource }) => {
  const personStore = learningPartnerStore(learningPartnerResource);
  return (
    <div className={style.learningPartner}>
      <GlobalPresenceContext.Consumer>
        {(value) => <Person personStore={personStore} presenceStore={just(value)} />}
      </GlobalPresenceContext.Consumer>
    </div>
  );
};

export default observer(LearningPartnerImpl);
