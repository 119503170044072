import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const AgendaIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 13">
      <path
        fill="#000000"
        d="M3.289 0 1.49 1.798l-.702-.702L0 1.884 1.096 2.98l.394.377.394-.377L4.076.788 3.29 0Zm2.586.942v1.096H13V.942H5.875ZM3.289 4.385 1.49 6.183l-.702-.702L0 6.269l1.096 1.096.394.377.394-.377 2.192-2.192-.787-.788Zm2.586.942v1.096H13V5.327H5.875ZM3.289 8.769 1.49 10.568l-.702-.702-.788.787 1.096 1.097.394.376.394-.376 2.192-2.193-.787-.788Zm2.586.942v1.097H13V9.71H5.875Z"
      />
    </SVGIcon>
  );
};

export default observer(AgendaIcon);
