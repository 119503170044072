import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { BeginnableAction } from '../../../../Common/Experience/ExperienceActions';
import { Experience, GroupCoachingProductDetails } from '../../../../Common/Experience/Types';
import EnrollableAction from './EnrollableAction';

interface Props {
  experience: Experience;
  productDetails: GroupCoachingProductDetails;
}

const beginnableButtonProps: ButtonProps = {
  className: 'min-w-full bg-white sm:min-w-48',
  radius: 'md',
  variant: 'text',
  size: 'lg',
};

function ExperienceActions({ experience, productDetails }: Props) {
  switch (experience.kind) {
    case 'enrollable':
    case 'commerce-enrollable':
      return <EnrollableAction productDetails={productDetails} />;
    case 'beginnable':
      return (
        <div className="mt-10">
          <BeginnableAction experience={experience} buttonProps={beginnableButtonProps} />
        </div>
      );
    case 'returnable':
    case 'resumable':
    case 'not-enrollable':
    case 'not-licensed':
    case 'graduated':
    case 'upcoming':
      return emptyFragment();
  }
}
export default observer(ExperienceActions);
