import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import { T } from '../../../Translations';
import Profile from '../../ProfilePopout/Details/Profile';
import * as style from './style.module.css';

interface Props {
  store: PersonStoreContract;
}
class Preview extends React.Component<Props, {}> {
  render() {
    const { store } = this.props;
    return (
      <>
        <h2 className={style.title} data-test-preview-title="Profile Preview">
          <T kind="Profile Preview" />
        </h2>
        <Profile store={store} />
      </>
    );
  }
}
export default observer(Preview);
