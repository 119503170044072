import { observer } from 'mobx-react';
import * as React from 'react';
import RegistrationView from '../../RegistrationView';
import { InvitationStore } from '../InvitationStore';
import { FormState } from '../InvitationStore/Types';
import { SharedInvitationResource } from '../SharedInvitationResourceStore/Types';
import FormBody from './FormBody';
import FormHeader from './FormHeader';
import Instructions from './Instructions';

interface Props {
  store: InvitationStore;
  state: FormState;
  sharedInvitationResource: SharedInvitationResource;
}

const Form: React.FC<Props> = ({ store, state, sharedInvitationResource }) => {
  return (
    <RegistrationView allowLanguagePicker={true}>
      <FormHeader
        notifiableStore={store}
        invitationStore={store}
        sharedInvitationResource={sharedInvitationResource}
      >
        <Instructions store={store} sharedInvitationResource={sharedInvitationResource} />
      </FormHeader>
      <FormBody store={store} state={state} />
    </RegistrationView>
  );
};

export default observer(Form);
