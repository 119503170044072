import { assertNever, noop } from '@kofno/piper';
import { Task } from 'taskarian';
import { loopTaskWithLimit } from '../../../../TaskExt';
import ViewportStore from '../../../../ViewportStore';
import { State as SwitcherooState } from '../Stores/SwitcherooStore';
import { ResizableProps, VideoType } from './LectureSizingReactions';

export interface VideoInitializationTimeout {
  kind: 'video-initialization-timeout';
}

type VideoWrapperEl = React.RefObject<HTMLDivElement>;

interface Video {
  kind: VideoType;
  height: number;
  width: number;
}

const getVideoInitializerEl = (videoWrapperEl: HTMLDivElement) =>
  videoWrapperEl.querySelector('#lecture-segment-video');

const getVimeoPlayerEl = (videoWrapperEl: VideoWrapperEl) => {
  if (videoWrapperEl.current) {
    const videoInitializerEl = getVideoInitializerEl(videoWrapperEl.current);
    if (videoInitializerEl) {
      const attrs = videoInitializerEl.attributes;
      for (let i = attrs.length - 1; i >= 0; i--) {
        if (attrs[i].name === 'data-vimeo-initialized') {
          const iframeEls = videoInitializerEl.getElementsByTagName('iframe');
          return iframeEls.length ? iframeEls[0] : null;
        }
      }
    }
  }
  return null;
};

const getJWPlayerEl = (videoWrapperEl: VideoWrapperEl) => {
  if (videoWrapperEl.current) {
    const videoInitializerEl = getVideoInitializerEl(videoWrapperEl.current);
    if (videoInitializerEl && videoInitializerEl.classList.contains('jwplayer')) {
      const videoEls = videoInitializerEl.getElementsByTagName('video');
      return videoEls.length ? videoEls[0] : null;
    }
  }
  return null;
};

type VideoDimensions = { kind: VideoType; height: number; width: number };

const getVideoElement = (
  videoWrapperEl: VideoWrapperEl,
): Task<VideoInitializationTimeout, VideoDimensions> => {
  const task = new Task<string, VideoDimensions>((reject, resolve) => {
    const jwPlayer = getJWPlayerEl(videoWrapperEl);
    const vimeoPlayer = getVimeoPlayerEl(videoWrapperEl);
    if (vimeoPlayer) {
      const vimeoPlayerHeight = vimeoPlayer.getBoundingClientRect().height;
      const vimeoPlayerWidth = vimeoPlayer.getBoundingClientRect().width;
      resolve({ kind: 'vimeo', height: vimeoPlayerHeight, width: vimeoPlayerWidth });
    } else if (jwPlayer && jwPlayer.readyState >= jwPlayer.HAVE_METADATA) {
      resolve({
        kind: 'jw-player',
        height: jwPlayer.getBoundingClientRect().height,
        width: jwPlayer.getBoundingClientRect().width,
      });
    } else {
      reject('no video element found');
    }

    return noop;
  });
  const timeoutDelay = 50;
  const iterationLimit = 30000 / timeoutDelay;
  const rejectWith: VideoInitializationTimeout = {
    kind: 'video-initialization-timeout',
  };
  return loopTaskWithLimit(timeoutDelay, iterationLimit, rejectWith, task);
};

const getAspectRatio = (video: Video): Task<never, ResizableProps> => {
  return new Task((_, resolve: (a: ResizableProps) => void) => {
    resolve({
      kind: video.kind,
      aspectRatio: video.width / video.height,
    });
    return noop;
  });
};

const getVideoHeight = (el: HTMLElement, aspectRatio: number) => {
  return `${el.getBoundingClientRect().width / aspectRatio}px`;
};

const getVideoWidth = (switcherooState: SwitcherooState) => {
  if (ViewportStore.viewport === 'mobile' || switcherooState.kind === 'video-only') {
    return '100%';
  } else if (switcherooState.kind === 'slides-only') {
    return '320px';
  }
  return '';
};

const setVideoDimensions = (
  switcherooState: SwitcherooState,
  aspectRatio: number,
  videoWrapperEl: HTMLElement,
  videoEl: HTMLElement,
) => {
  videoWrapperEl.style.width = getVideoWidth(switcherooState);
  videoWrapperEl.style.height = getVideoHeight(videoWrapperEl, aspectRatio);
  videoEl.style.width = videoWrapperEl.style.width;
  videoEl.style.height = videoWrapperEl.style.height;
  //IE11 / Edge return an incorrect width intially, this is needed to set the real dimensions
  window.requestAnimationFrame(() => {
    videoWrapperEl.style.width = getVideoWidth(switcherooState);
    videoWrapperEl.style.height = getVideoHeight(videoWrapperEl, aspectRatio);
    videoEl.style.width = videoWrapperEl.style.width;
    videoEl.style.height = videoWrapperEl.style.height;
  });
};

const resizeVimeo = (
  switcherooState: SwitcherooState,
  videoWrapperElRef: VideoWrapperEl,
  videoProps: ResizableProps,
) => {
  const vimeoPlayerEl = getVimeoPlayerEl(videoWrapperElRef);
  const videoWrapperEl = videoWrapperElRef.current;
  if (!vimeoPlayerEl || !videoWrapperEl) {
    return;
  }
  setVideoDimensions(switcherooState, videoProps.aspectRatio, videoWrapperEl, vimeoPlayerEl);
};

const resizeJwPlayer = (
  switcherooState: SwitcherooState,
  videoWrapperElRef: VideoWrapperEl,
  videoProps: ResizableProps,
) => {
  const videoEl = document.getElementById('lecture-segment-video');
  const videoWrapperEl = videoWrapperElRef.current;
  const jwPlayerEl = getJWPlayerEl(videoWrapperElRef);
  if (!videoEl || !videoWrapperEl || !jwPlayerEl) {
    return;
  }
  setVideoDimensions(switcherooState, videoProps.aspectRatio, videoWrapperEl, videoEl);
  //Remove inline styles jwplayer sets that break Edge
  jwPlayerEl.removeAttribute('style');
};

export const getVideoAspectRatio = (videoWrapperEl: VideoWrapperEl) => {
  return getVideoElement(videoWrapperEl).andThen(getAspectRatio);
};

export const resizeVideo = (
  switcherooState: SwitcherooState,
  videoWrapperEl: VideoWrapperEl,
  videoProps: ResizableProps,
) => {
  switch (videoProps.kind) {
    case 'vimeo':
      resizeVimeo(switcherooState, videoWrapperEl, videoProps);
      break;
    case 'jw-player':
      resizeJwPlayer(switcherooState, videoWrapperEl, videoProps);
      break;
    case 'unknown':
    case 'legacy':
    case 'ppt':
      break;
    default:
      assertNever(videoProps);
  }
};
