import { assertNever } from '@kofno/piper';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { ChangeEvent } from 'react';
import ProfileFormStore from '../../../../ProfileFormStore';
import { salutationDecoder } from '../../../../ProfileFormStore/Decoders';
import { Salutation } from '../../../../ProfileFormStore/Types';
import { fromDecoderAny } from '../../../../TaskExt';
import { TPlainTextKey } from '../../../../Translations';
import Label from '../../../Form/Label';
import TranslatableSelect, { TranslatableSelectOption } from '../../../Form/TranslatableSelect';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
}

const handleSalutationSelect = (profileStore: ProfileFormStore) => (
  event: ChangeEvent<HTMLSelectElement>
) => {
  stringToSalutationDecoder(event.target.value).fork(
    () => profileStore.setSalutation(nothing()),
    (salutation) => profileStore.setSalutation(just(salutation))
  );
};

const stringToSalutationDecoder = fromDecoderAny(salutationDecoder);

export const convertStringToTPlainTextKey = (string: Salutation): TPlainTextKey => {
  switch (string) {
    case 'Mr.':
      return 'Mr.';
    case 'Ms.':
      return 'Ms.';
    case 'Dr.':
      return 'Dr.';
    case 'Mx.':
      return 'Mx.';
    default:
      assertNever(string);
  }
};

export const convertStringToOptions = (salutations: Salutation[]): TranslatableSelectOption[] =>
  salutations.map((salutation) => ({
    value: salutation,
    content: convertStringToTPlainTextKey(salutation),
  }));

const salutationSelector: React.FC<Props> = ({ profileStore }) => (
  <>
    <Label
      className={style.salutationLabel}
      labelText="Salutation"
      htmlFor="salutation"
      required={false}
    />
    <div className={style.input}>
      <TranslatableSelect
        placeholder={just('Prefer not to answer')}
        className={just(style.fakeSelect)}
        value={profileStore.salutation}
        onChange={handleSalutationSelect(profileStore)}
        name="salutation"
        id="salutation"
        options={convertStringToOptions(profileStore.salutationOptions)}
      />
    </div>
  </>
);

export default observer(salutationSelector);
