import { assertNever } from '@kofno/piper';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Maybe } from 'maybeasy';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import ChatMessageStore from '.';
import { AppyError, callApi } from '../Appy';
import ConversationStore from '../ConversationStore';
import { chatMessageResourceDecoder } from '../ConversationStore/Decoders';
import { ChatMessageResource } from '../ConversationStore/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { warnAndNotify } from '../Honeybadger';
import LastReadMessageStore from '../LastReadMessageStore';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { ChatMessageState } from './Types';

export interface Props extends EAProps<ChatMessageStore> {
  store: ChatMessageStore;
  conversationStore: ConversationStore;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

// TODO Also, the chatMessageStore should probably have a reference to the conversation store
const saveMessage = (content: EditorState) => {
  const contentToHtml = stateToHTML(content.getCurrentContent());
  return callApi(chatMessageResourceDecoder, {
    message: {
      content: contentToHtml,
    },
  });
};

type ChatError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleChatError = (store: ChatMessageStore) => (error: ChatError) => {
  switch (error.kind) {
    case 'missing-link-error':
      // Probably need to do something in UI for this too. Maybe disable chat or sonething.
      warnAndNotify('ChatReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

const handleMessageResponse =
  (lastReadMessageStore: Maybe<LastReadMessageStore>, store: ChatMessageStore) =>
  (messageResource: ChatMessageResource) => {
    lastReadMessageStore.do((lastReadMessageStore) => {
      lastReadMessageStore.updateLastReadMessage(messageResource);
    });
    store.broadcasting();
  };

class ChatReactions extends ErrorActionableReaction<ChatMessageStore, ChatMessageState, Props> {
  tester = () => this.props.store.state;
  effect = (state: ChatMessageState) => {
    const { store, lastReadMessageStore } = this.props;
    switch (state.kind) {
      case 'broadcasting':
        // When we get a message from pusher for this message
        // we decode it and add it
        store.reset();
        break;
      case 'ready':
        break;
      case 'waiting':
        store.ready();
        break;
      case 'updating':
        Task.succeed<ChatError, ReadonlyArray<Link>>(
          this.props.conversationStore.conversationResource.links,
        )
          .andThen(findLinkT('create'))
          .andThen(saveMessage(state.message))
          .fork(
            handleChatError(this.props.store),
            handleMessageResponse(lastReadMessageStore, store),
          );
        store.broadcasting();
        break;
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ChatReactions;
