import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { whenLocalConferenceRoom } from '../../Conference/Types';
import ConversationPresence from '../../ConversationPresence';
import { ChatablePersonResourceList } from '../../ConversationStore/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import TeamStore from '../../TeamStore';
import Loader from '../Loader';
import BreakoutChat from './BreakoutChat';

interface Props {
  teamStore: TeamStore;
  eventId: number;
  roomId: string;
  chatablePersonResourceList: ChatablePersonResourceList;
}
const Conversation: React.FC<Props> = ({
  teamStore,
  chatablePersonResourceList,
  eventId,
  roomId,
}) => (
  <>
    {just({})
      .assign('teamResource', teamStore.team)
      .assign(
        'conferenceRoom',
        teamStore.team.andThen((team) => team.payload.conferenceRoom)
      )
      .map(({ teamResource, conferenceRoom }) => {
        const conversationResource =
          teamResource.payload.teamChat.payload.breakoutConversationResource;

        return (
          <WithCurrentUser
            children={(currentUserResource) => (
              <ConversationPresence
                conversationResource={conversationResource}
                currentUserResource={currentUserResource}
                channelName={
                  teamResource.payload.teamChat.payload.breakoutConversationResource.payload
                    .channelName
                }
                chatablePersonResourceList={nothing()}
                client={(presenceMembersStore, staffPresenceStore, staffRequestStore) => {
                  return (
                    <BreakoutChat
                      conversationStore={presenceMembersStore.conversationStore}
                      teamResource={teamResource}
                      eventId={eventId}
                      roomId={teamResource.payload.conferenceRoom
                        .map((conferenceRoomResource) => conferenceRoomResource.payload)
                        .andThen((cr) => whenLocalConferenceRoom(cr))
                        .map((cr) => cr.conferenceId)
                        .getOrElseValue(roomId)}
                      chatablePersonResourceList={chatablePersonResourceList}
                      staffPresenceStore={staffPresenceStore}
                      staffRequestStore={staffRequestStore}
                      currentUserResource={currentUserResource}
                      conferenceRoom={conferenceRoom.payload}
                    />
                  );
                }}
              />
            )}
          />
        );
      })
      .getOrElse(() => (
        <Loader />
      ))}
  </>
);

export default observer(Conversation);
