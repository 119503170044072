import { observer } from 'mobx-react';
import { PlayRectangleIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { Maybe } from 'maybeasy';

interface Props {
  totalHours: Maybe<number>;
}

function TotalHour({ totalHours }: Props) {
  return totalHours
    .map((count) => (
      <div className="flex items-center gap-x-2.5">
        <PlayRectangleIcon color="black" size="md" />
        <Typography className="self-center text-gray-900" variant="bodyMedium">
          <T kind="{{count}} total hour" count={count} />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(TotalHour);
