import { when } from '@execonline-inc/maybe-adapter';
import { SessionData } from '@execonline-inc/unified-auth.private';
import { always, pipe } from '@kofno/piper';
import { just } from 'maybeasy';
import { currentUserStore } from '../CurrentUser/Store';
import { sessionStore } from './Store';

export const discardSession = () => sessionStore.absent();

export const establishSession = pipe(sessionStore.present, currentUserStore.loading);

export const handleRecovery = (onMatchingUser: (sessionData: SessionData) => void) => (
  sessionData: SessionData
): void =>
  just({})
    .assign('oldUserId', always(sessionStore.userId))
    .assign('newUserId', always(sessionData.userId))
    .andThen(({ oldUserId, newUserId }) => when(oldUserId === newUserId, sessionData))
    .cata({ Just: onMatchingUser, Nothing: () => window.location.reload() });
