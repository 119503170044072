import { Maybe } from 'maybeasy';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  organization: Maybe<string>;
}

const Organization: React.FC<Props> = ({ organization }) =>
  organization
    .map((organization) => (
      <div className={style.organization} data-test-popout-organization={true}>
        <NotTranslated text={organization} />
      </div>
    ))
    .getOrElseValue(<div />);

export default Organization;
