import { stringLiteral } from '@execonline-inc/decoders';
import Decoder, { field, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import {
  AcceptedFileExtensions,
  Extension,
  SimpleFileUploadResource,
  SimpleFileUploadResponse,
} from '../Types';

export const simpleFileUploadDecoder: Decoder<SimpleFileUploadResponse> = succeed({})
  .assign('signedUrl', field('signed_url', string))
  .assign('objName', field('object_name', string))
  .assign('folderName', field('folder_name', string))
  .assign('fileName', field('file_name', string))
  .assign('tempFilePath', field('temp_file_path', string));

export const simpleFileUploadResourceDecoder: Decoder<SimpleFileUploadResource> = resourceDecoder(
  simpleFileUploadDecoder
);

export const extensionTypeDecoder: Decoder<AcceptedFileExtensions> = oneOf([
  stringLiteral<AcceptedFileExtensions>('doc'),
  stringLiteral<AcceptedFileExtensions>('docx'),
  stringLiteral<AcceptedFileExtensions>('xls'),
  stringLiteral<AcceptedFileExtensions>('xlsx'),
  stringLiteral<AcceptedFileExtensions>('csv'),
  stringLiteral<AcceptedFileExtensions>('zip'),
  stringLiteral<AcceptedFileExtensions>('pdf'),
  stringLiteral<AcceptedFileExtensions>('ppt'),
  stringLiteral<AcceptedFileExtensions>('pptx'),
  stringLiteral<AcceptedFileExtensions>('pwptx'),
  stringLiteral<AcceptedFileExtensions>('odt'),
  stringLiteral<AcceptedFileExtensions>('pages'),
  stringLiteral<AcceptedFileExtensions>('rtf'),
  stringLiteral<AcceptedFileExtensions>('pps'),
  stringLiteral<AcceptedFileExtensions>('jpg'),
  stringLiteral<AcceptedFileExtensions>('jpeg'),
  stringLiteral<AcceptedFileExtensions>('gif'),
  stringLiteral<AcceptedFileExtensions>('png'),
  stringLiteral<AcceptedFileExtensions>('vsd'),
  stringLiteral<AcceptedFileExtensions>('vsdx'),
  stringLiteral<AcceptedFileExtensions>('vdx'),
  stringLiteral<AcceptedFileExtensions>('vss'),
  stringLiteral<AcceptedFileExtensions>('vsx'),
  stringLiteral<AcceptedFileExtensions>('vst'),
  stringLiteral<AcceptedFileExtensions>('vtx'),
]);

export const extensionDecoder: Decoder<Extension> = succeed({}).assign(
  'extension',
  extensionTypeDecoder
);
