import { Maybe, nothing } from 'maybeasy';
import EditPasswordStore from '.';
import { Error as ClientError, handleAppyError } from '../ErrorHandling';
import { Resource, ServerError } from '../Resource/Types';
import { RootResourceError } from '../RootResourceStore/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../Translations';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  password: Maybe<string>;
  passwordConfirmation: Maybe<string>;
}

interface ReadyWithClientError {
  kind: 'ready-with-client-error';
  clientError: TPlainTextKey;
  password: Maybe<string>;
  passwordConfirmation: Maybe<string>;
}

interface ReadyWithServerError {
  kind: 'ready-with-server-error';
  serverError: AlreadyTranslatedText;
  password: Maybe<string>;
  passwordConfirmation: Maybe<string>;
}

interface Creating {
  kind: 'creating';
  password: string;
  passwordConfirmation: string;
}

export interface ResetPasswordFailed {
  kind: 'failed';
  errors: ServerError[];
}

export interface ResetPasswordSucceeded {
  kind: 'success';
}

export type ResetPassword = ResetPasswordFailed | ResetPasswordSucceeded;
export type ResetPasswordResource = Resource<ResetPassword>;

export interface MissingTokenError {
  kind: 'missing-token-error';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (): Ready => ({
  kind: 'ready',
  password: nothing(),
  passwordConfirmation: nothing(),
});

export const readyWithClientError = (
  clientError: TPlainTextKey,
  password: Maybe<string>,
  passwordConfirmation: Maybe<string>
): ReadyWithClientError => ({
  kind: 'ready-with-client-error',
  clientError,
  password,
  passwordConfirmation,
});

export const readyWithServerError = (
  serverError: AlreadyTranslatedText,
  password: Maybe<string>,
  passwordConfirmation: Maybe<string>
): ReadyWithServerError => ({
  kind: 'ready-with-server-error',
  serverError,
  password,
  passwordConfirmation,
});

export const creating = (password: string, passwordConfirmation: string): Creating => ({
  kind: 'creating',
  password: password,
  passwordConfirmation: passwordConfirmation,
});

export type EditPasswordState =
  | Waiting
  | Loading
  | Ready
  | ReadyWithClientError
  | ReadyWithServerError
  | Creating
  | ClientError;

type ResourceRequestError = RootResourceError | MissingTokenError;

export const handleResetPasswordRequestError = (store: EditPasswordStore) => (
  error: ResourceRequestError
) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.clientError('Error resetting password');
      break;
    case 'missing-token-error':
      store.clientError(missingTokenError());
      break;
    case 'bad-status':
      if (error.response.status == 403) {
        store.clientError('Reset password token is invalid.');
      } else {
        store.clientError(handleAppyError(error));
      }
      break;
    default:
      store.clientError(handleAppyError(error));
  }
};

export const handleUpdatePasswordError = (store: EditPasswordStore) => (
  error: RootResourceError
) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.clientError('Error resetting password');
      break;
    default:
      store.clientError(handleAppyError(error));
  }
};

export const missingTokenError = (): TPlainTextKey =>
  'Please provide the full URL if you are coming from a reset password email';

export const combineServerErrors = (errors: ServerError[]): AlreadyTranslatedText => ({
  kind: 'already-translated-text',
  text: errors.map(({ message }) => message).join(' '),
});

export const updatePasswordError = (errors: ServerError[]): AlreadyTranslatedText =>
  combineServerErrors(errors);
