import { CoachingSession } from '../../../../../ProgramStore/Types';

export interface VisibleCoachingSession extends CoachingSession {
  kind: 'visible';
}

export interface HiddenCoachingSession extends CoachingSession {
  kind: 'hidden';
}

export const hiddenCoachingSession = (session: CoachingSession): HiddenCoachingSession => ({
  ...session,
  kind: 'hidden',
});

export const visibleCoachingSession = (session: CoachingSession): VisibleCoachingSession => ({
  ...session,
  kind: 'visible',
});

export type TimeOrientedCoachingSession = HiddenCoachingSession | VisibleCoachingSession;
