export interface Showing {
  kind: 'showing';
}

export interface Hiding {
  kind: 'hiding';
}

export const showing = (): Showing => ({ kind: 'showing' });

export const hiding = (): Hiding => ({ kind: 'hiding' });

export type State = Showing | Hiding;
