import { observer } from 'mobx-react';
import * as React from 'react';
import { L, NotTranslated, T } from '../../Translations';
import * as style from './style.module.css';
import { EventDetails } from './Types';

interface Props {
  eventDetails: EventDetails;
}

const MeetingDetails: React.FC<Props> = ({ eventDetails }) => (
  <div className={style.meetingDetails}>
    <dl>
      <dt>
        <T kind="Title" />:
      </dt>{' '}
      <dd>
        <NotTranslated text={eventDetails.title} />
      </dd>
    </dl>
    <dl>
      <dt>
        <T kind="Time" />:
      </dt>
      <dd>
        <L localizeable={eventDetails.date} format={'time-of-day'} />
      </dd>
    </dl>
    <dl>
      <dt>
        <T kind="Date" />:
      </dt>
      <dd>
        <L localizeable={eventDetails.date} format={'short-month-day-year'} />
      </dd>
    </dl>
  </div>
);

export default observer(MeetingDetails);
