import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ExperienceFiltersSelectionContent from './ExperienceFiltersSelectionContent';
import ExperiencesStore from './Experiences/ExperiencesStore';
import SkeletonFilterExperiences from './Filters/SkeletonFilterExperiences';

interface Props {
  experiencesStore: ExperiencesStore;
}

function ExperienceFiltersSelectionView({ experiencesStore }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return <SkeletonFilterExperiences />;
    case 'searching':
    case 'product-collections':
    case 'experiences':
    case 'product-collections-and-experiences':
      const { experienceFilters } = experiencesStore.state.resource.payload;

      return (
        <ExperienceFiltersSelectionContent
          experienceFilters={experienceFilters}
          experiencesStore={experiencesStore}
        />
      );
    case 'error':
      return emptyFragment();
  }
}

export default observer(ExperienceFiltersSelectionView);
