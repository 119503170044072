import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import FreebusyCoachingAdReactions from './FreebusyCoachingAdReactions';
import FreebusyCoachingAdStates from './FreebusyCoachingAdStates';
import FreebusyCoachingAdStore from './FreebusyCoachingAdStore';

interface Props {
  currentUserResource: CurrentUserResource;
}

class FreebusyCoachingAd extends React.Component<Props> {
  store = new FreebusyCoachingAdStore(this.props.currentUserResource);

  render() {
    return (
      <>
        <FreebusyCoachingAdStates store={this.store} />
        <FreebusyCoachingAdReactions
          store={this.store}
          currentUserResource={this.props.currentUserResource}
          fireImmediately
        />
      </>
    );
  }
}

export default observer(FreebusyCoachingAd);
