import { mapMaybe } from '@execonline-inc/collections';
import { action, computed, observable } from 'mobx';
import { NotifiableStore } from '../../ErrorActionableReaction';
import { FlashAlert } from '../Types';

class NotificationsStore {
  @observable
  stores: NotifiableStore[] = [];

  @computed
  get notifications(): ReadonlyArray<FlashAlert> {
    return mapMaybe((s: NotifiableStore) => s.notification, this.stores);
  }

  @action
  addStore = (store: NotifiableStore): void => {
    this.stores.push(store);
  };

  @action
  removeStore = (store: NotifiableStore): void => {
    this.stores = this.stores.filter((other) => other !== store);
  };
}

export default NotificationsStore;
