import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { LearningPartnerResource } from '../../../../LearningPartnersStore/Types';
import { T } from '../../../../Translations';

interface Props {
  learningPartners: NonEmptyList<LearningPartnerResource>;
}

const LearningPartnersDashboardHeader: React.FC<Props> = ({ learningPartners }) => (
  <dt data-test-learning-partner-label={true}>
    <T kind="Learning Partner" count={learningPartners.length} />
  </dt>
);

export default observer(LearningPartnersDashboardHeader);
