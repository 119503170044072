import { Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';
import { Resource, ResourceWithErrors } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';

export interface Loading {
  kind: 'loading';
  countryId: Maybe<string>;
}

export interface Waiting {
  kind: 'waiting';
}

export interface ReadyWithRegions {
  kind: 'ready-with-regions';
  countryRegionOptionsResource: CountryRegionOptionsResource;
  flashMessage: Maybe<TPlainTextKey>;
  region: Maybe<CountryRegionOption>;
}

export interface ReadyWithNoRegions {
  kind: 'ready-without-regions';
  countryRegionOptionsResource: CountryRegionOptionsResource;
  flashMessage: Maybe<TPlainTextKey>;
}

export interface ReadyWithErrors {
  kind: 'ready-with-errors';
  countryRegionOptionsResource: CountryRegionOptionsResourceWithErrors;
  region: Maybe<CountryRegionOption>;
}

export const loading = (countryId: Maybe<string>): Loading => ({
  kind: 'loading',
  countryId,
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const readyWithRegions = (
  countryRegionOptionsResource: CountryRegionOptionsResource,
  flashMessage: Maybe<TPlainTextKey>,
  region: Maybe<CountryRegionOption>
): ReadyWithRegions => ({
  kind: 'ready-with-regions',
  countryRegionOptionsResource,
  flashMessage,
  region,
});

export const readyWithNoRegions = (
  countryRegionOptionsResource: CountryRegionOptionsResource,
  flashMessage: Maybe<TPlainTextKey>
): ReadyWithNoRegions => ({
  kind: 'ready-without-regions',
  countryRegionOptionsResource,
  flashMessage,
});

export const readyWithErrors = (
  countryRegionOptionsResource: CountryRegionOptionsResourceWithErrors,
  region: Maybe<CountryRegionOption>
): ReadyWithErrors => ({
  kind: 'ready-with-errors',
  countryRegionOptionsResource,
  region,
});

export type CountryRegionOptionsState =
  | Waiting
  | Loading
  | ReadyWithRegions
  | ReadyWithNoRegions
  | ReadyWithErrors
  | Error;

export interface CountryRegionOption {
  id: string;
  name: string;
}

export interface CountryRegionOptions {
  countryRegionOptions: CountryRegionOption[];
}

export type CountryRegionOptionResource = Resource<CountryRegionOption>;

export type CountryRegionOptionsResource = Resource<CountryRegionOptions>;
export type CountryRegionOptionsResourceWithErrors = ResourceWithErrors<CountryRegionOptions>;
