import { Error } from '../ErrorHandling';
import { Resource, ResourceWithMetadata } from '../Resource/Types';
import { TeamSummary } from '../TeamsSummaryStore/Types';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

export interface LoadingMore {
  kind: 'loading-more';
  communitiesResource: CommunitiesResource;
}

interface Ready {
  kind: 'ready';
  communitiesResource: CommunitiesResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const loadingMore = (communitiesResource: CommunitiesResource): LoadingMore => ({
  kind: 'loading-more',
  communitiesResource,
});

export const ready = (communitiesResource: CommunitiesResource): Ready => ({
  kind: 'ready',
  communitiesResource,
});

export type CommunitiesState = Waiting | Loading | LoadingMore | Ready | Error;

export interface CommunityTeam extends TeamSummary {
  kind: 'team';
}

export type CommunityTeamResource = Resource<CommunityTeam>;

export interface CommunitiesMetadata {
  currentPage: number;
  pageCount: number;
  perPage: number;
  resultsCount: number;
}

export interface CommunityProgram {
  id: number;
  kind: 'program';
  name: string;
}

export type CommunityProgramResource = Resource<CommunityProgram>;

export type Community = CommunityProgram | CommunityTeam;

export type CommunityResource = Resource<Community>;

export type CommunitiesResource = ResourceWithMetadata<CommunityResource[], CommunitiesMetadata>;
