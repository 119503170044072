import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { TPlainTextKey } from '../../../../Translations';
import {
  loading,
  ready,
  waiting,
  CoachTeamsState,
  TeamsProgramsOptionsResource,
  Options,
} from './Types';

class CommunityProgramOptionsStore {
  @observable
  state: CoachTeamsState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (teamsProgramsOptionsResource: TeamsProgramsOptionsResource) => {
    this.state = ready(teamsProgramsOptionsResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get programOptions(): Maybe<Options> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.teamsProgramsOptionsResource.payload.programOptions);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamOptions(): Maybe<Options> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.teamsProgramsOptionsResource.payload.teamOptions);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }
}

export default CommunityProgramOptionsStore;
