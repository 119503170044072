import * as React from 'react';
import { Link, Route } from 'react-router-dom';
import { T } from '../../Translations';
import BreadCrumbSeparatorIcon from '../Cutesies/BreadCrumbSeparatorIcon';
import * as style from './style.module.css';

interface Props {
  path: string;
  title: React.ReactElement;
}

const BreadCrumb: React.FC<Props> = ({ path, title }) => (
  <h1 className={style.breadcrumb}>
    <Route
      exact={true}
      path={path}
      render={() => {
        return (
          <>
            <Link to="/resources" data-test-resources-dashboard-link={true}>
              <T kind="Resources" />
            </Link>
            <span className={style.breadcrumbSeparator}>
              <BreadCrumbSeparatorIcon />
            </span>
          </>
        );
      }}
    />
    <span>{title}</span>
  </h1>
);

export default BreadCrumb;
