import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { LastReadMessage } from '../Conference/Types';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { LastReadMessageState, ready, updateLastReadMessage } from './Types';

class LastReadMessageStore {
  @observable
  state: LastReadMessageState;

  constructor(lastReadMsg: Maybe<LastReadMessage>) {
    this.state = ready(lastReadMsg);
  }

  updateLastReadState = (lastSeenMessage: LastReadMessage, message: LastReadMessage) => {
    if (lastSeenMessage.payload.createdAt < message.payload.createdAt) {
      this.state = updateLastReadMessage(message);
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'error':
        break;
      case 'last-seen-message':
        this.state = ready(just(this.state.message));
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  updateLastReadMessage = (message: LastReadMessage) => {
    switch (this.state.kind) {
      case 'ready':
        this.state.message
          .do((lastSeenMessage) => {
            this.updateLastReadState(lastSeenMessage, message);
          })
          .elseDo(() => {
            this.state = updateLastReadMessage(message);
          });
        break;
      case 'last-seen-message':
        this.updateLastReadState(this.state.message, message);
        break;
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'last-seen-message':
        return nothing();
    }
  }
}

export default LastReadMessageStore;
