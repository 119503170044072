import { find } from '@execonline-inc/collections';
import { assertNever, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import PrivacyModalStore from '.';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { getItem, setItem } from '../../../Storage';
import { State } from './Types';

interface Props extends RCProps<PrivacyModalStore> {}

const getPrivacyConfirmationProgramIds = () =>
  getItem('privacyConfirmationProgramIds', localStorage)
    .map((programIds) => programIds.map((programIds) => programIds.split(',')))
    .map((ids) => ids.getOrElseValue([]));

const initializeModal = (store: PrivacyModalStore) => (privacyConfirmationProgramIds: string[]) =>
  store.stringifiedProgramId
    .andThen((programId) =>
      find((confirmationId) => confirmationId === programId, privacyConfirmationProgramIds)
    )
    .do(store.close)
    .elseDo(store.open);

const saveId = (stringifiedProgramId: Maybe<string>) => (privacyConfirmationProgramIds: string[]) =>
  stringifiedProgramId.do((programId) => {
    const newIdList = privacyConfirmationProgramIds.concat([programId]).join(',');
    setItem('privacyConfirmationProgramIds', newIdList, localStorage).fork(noop, noop);
  });

class PrivacyModalReactions extends ReactionComponent<PrivacyModalStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        getPrivacyConfirmationProgramIds().fork(store.open, initializeModal(store));
        break;
      case 'confirming':
        getPrivacyConfirmationProgramIds().fork(noop, saveId(store.stringifiedProgramId));
        store.close();
        break;
      case 'waiting':
      case 'open':
      case 'reopened':
      case 'closed':
        break;
      default:
        assertNever(state);
    }
  };
}

export default PrivacyModalReactions;
