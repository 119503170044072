import { observer } from 'mobx-react';
import * as React from 'react';
import { InvitationStore } from '../InvitationStore';
import { SharedInvitationResource } from '../SharedInvitationResourceStore/Types';
import ProgramFamilyInstructions from './ProgramFamilyInstructions';
import UseCaseInstructions from './UseCaseInstructions';

interface Props {
  store: InvitationStore;
  sharedInvitationResource: SharedInvitationResource;
}

const Instructions: React.FC<Props> = ({ store, sharedInvitationResource }) => {
  switch (sharedInvitationResource.payload.kind) {
    case 'use-case':
      return <UseCaseInstructions store={store} />;
    case 'use-cases-program-family':
      return <ProgramFamilyInstructions store={store} />;
    case 'preview-only':
      return <></>;
  }
};

export default observer(Instructions);
