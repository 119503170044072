import { Kettle } from 'kettle-corn';
import { Error } from '../ErrorHandling';
import { LectureSegment } from '../LectureStore/Types';

interface Waiting {
  kind: 'waiting';
  segment: LectureSegment;
  kettle: Kettle;
}

interface Loading {
  kind: 'loading';
  segment: LectureSegment;
  kettle: Kettle;
}

interface PlayerReady {
  kind: 'player-ready';
  segment: LectureSegment;
  kettle: Kettle;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

interface WaitingForVideo {
  kind: 'waiting-for-video';
  segment: LectureSegment;
  kettle: Kettle;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

interface Ready {
  kind: 'ready';
  segment: LectureSegment;
  kettle: Kettle;
  iframeRef: React.RefObject<HTMLIFrameElement>;
}

interface Advancing {
  kind: 'advancing';
  segment: LectureSegment;
  kettle: Kettle;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  advancingToStep: number;
}

export const waiting = (segment: LectureSegment, kettle: Kettle): Waiting => ({
  kind: 'waiting',
  segment,
  kettle,
});

export const loading = (segment: LectureSegment, kettle: Kettle): Loading => ({
  kind: 'loading',
  segment,
  kettle,
});

export const playerReady = (
  segment: LectureSegment,
  kettle: Kettle,
  iframeRef: React.RefObject<HTMLIFrameElement>
): PlayerReady => ({
  kind: 'player-ready',
  segment,
  kettle,
  iframeRef,
});

export const waitingForVideo = (
  segment: LectureSegment,
  kettle: Kettle,
  iframeRef: React.RefObject<HTMLIFrameElement>
): WaitingForVideo => ({
  kind: 'waiting-for-video',
  segment,
  kettle,
  iframeRef,
});

export const ready = (
  segment: LectureSegment,
  kettle: Kettle,
  iframeRef: React.RefObject<HTMLIFrameElement>
): Ready => ({
  kind: 'ready',
  segment,
  kettle,
  iframeRef,
});

export const advancing = (
  step: number,
  segment: LectureSegment,
  kettle: Kettle,
  iframeRef: React.RefObject<HTMLIFrameElement>
): Advancing => ({
  kind: 'advancing',
  segment,
  kettle,
  iframeRef,
  advancingToStep: step,
});

export type ConvertedPptState =
  | Waiting
  | Loading
  | PlayerReady
  | WaitingForVideo
  | Ready
  | Error
  | Advancing;
