import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramResource } from '../../../ProgramsStore/Types';
import CoachProgramDashboard from './CoachProgramDashboard';
import StudentProgramDashboard from './StudentProgramDashboard';

interface Props {
  programResource: ProgramResource;
}

const ProgramDashboard: React.FC<Props> = ({ programResource }) => {
  const { registrationType } = programResource.payload;
  switch (registrationType) {
    case 'Coach':
      return <CoachProgramDashboard programResource={programResource} />;
    case 'Auditor':
    case 'Professor':
    case 'Student':
      return <StudentProgramDashboard programResource={programResource} />;
  }
};

export default observer(ProgramDashboard);
