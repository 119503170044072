import { observer } from 'mobx-react';
import * as React from 'react';
import SVGIcon from '../SVGIcon';

const LighOnEmptyIcon: React.FC = () => (
  <SVGIcon style={{ height: 21, width: 22 }} viewBox="0 0 22 21">
    <path
      fill="white"
      d="M4.11.805 3.054 1.859l1.57 1.57L5.68 2.376 4.11.805Zm13.78 0-1.57 1.57 1.055 1.055 1.57-1.57L17.891.804ZM11 1.273a7.26 7.26 0 0 0-.75.047h-.023c-3.041.349-5.461 2.807-5.883 5.836-.337 2.438.668 4.647 2.343 6.07A3.932 3.932 0 0 1 8 15.5V20h1.71c.262.448.736.75 1.29.75s1.028-.302 1.29-.75H14v-3h.07v-.89c0-1.1.572-2.21 1.5-3.07 1.242-1.243 2.18-3.01 2.18-5.04 0-3.706-3.05-6.762-6.75-6.727Zm0 1.5c2.898-.04 5.25 2.332 5.25 5.227 0 1.57-.727 2.953-1.734 3.96l.023.024a5.833 5.833 0 0 0-1.828 3.516H9.43c-.164-1.286-.718-2.528-1.758-3.422C6.348 10.95 5.557 9.257 5.82 7.344c.328-2.365 2.244-4.263 4.594-4.524.196-.023.393-.044.586-.047ZM.5 8v1.5h2.25V8H.5Zm18.75 0v1.5h2.25V8h-2.25ZM4.625 14.07l-1.57 1.57 1.054 1.055 1.57-1.57-1.054-1.055Zm12.75 0-1.055 1.055 1.57 1.57 1.055-1.054-1.57-1.57ZM9.5 17h3v1.5h-3V17Z"
    />
  </SVGIcon>
);

export default observer(LighOnEmptyIcon);
