import { warn } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { always, assertNever, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Result, ok } from 'resulty';
import { Task } from 'taskarian';
import { CurrentUserResource } from '../CurrentUser/Types';
import { sessionStore } from '../Session/Store';
import { fromResult } from '../TaskExt';
import { dataLayer } from '../components/Tooling/GoogleTagManagerTooling/Loader';
import {
  AnalyticsError,
  ContingentResources,
  MissingValue,
  UserDataLayer,
  missingValue,
} from './Types';

export const impersonatingUserId = (): string => {
  switch (sessionStore.state.kind) {
    case 'requesting':
    case 'absent':
    case 'errored':
      return '';
    case 'present':
      return sessionStore.state.sessionData.impersonatingUserId.map(String).getOrElseValue('');
  }
};

export const identifyUser =
  (currentUserResource: CurrentUserResource, pageTitle: string = document.title) =>
  ({ organizationResource }: ContingentResources): Task<AnalyticsError, void> => {
    const valuesResult = ok<AnalyticsError, {}>({})
      .map(always({ platformUserId: currentUserResource.payload.id.toString() }))
      .andThen(assign('salesforceContactId', currentUserResource.payload.contactUid))
      .andThen(
        assign(
          'salesforceAccountId',
          organizationResource.andThen(({ payload }) => payload.accountUid),
        ),
      )
      .andThen(
        assign(
          'platformOrgId',
          organizationResource.map(({ payload }) => payload.id.toString()),
        ),
      )
      .andThen(
        assign(
          'platformOrgName',
          organizationResource.map(({ payload }) => payload.name),
        ),
      );
    return fromResult(valuesResult)
      .map(
        (properties): UserDataLayer => ({
          ...properties,
          impersonatingUserId: impersonatingUserId(),
          pageTitle,
          event: 'user-data',
        }),
      )
      .andThen(setUserProperties);
  };

const assign =
  <K extends string>(key: K, value: Maybe<string>) =>
  <A>(prev: A): Result<MissingValue, A & { [k in K]: string }> =>
    ok<MissingValue, A>(prev).assign(key, toResult(missingValue(key), value));

const setUserProperties = (properties: UserDataLayer): Task<AnalyticsError, void> => {
  return new Task<AnalyticsError, void>((reject, resolve) => {
    try {
      dataLayer(properties);
      resolve();
    } catch (e: unknown) {
      reject({ kind: 'analytics-operation-error', error: e });
    }
    return noop;
  });
};

export const handleAnalyticsError = (e: AnalyticsError) => {
  switch (e.kind) {
    case 'analytics-operation-error':
      warn(`An error occurred during an analytics operation:`, e.error);
      break;
    case 'missing-value':
      warn(`Analytics cannot properly identify current user without value for ${e.name}`);
      break;
    default:
      assertNever(e);
  }
};
