import { byPayloadId, find } from '@execonline-inc/collections';
import { toResult } from '@execonline-inc/maybe-adapter';
import { parseIntM } from '@execonline-inc/numbers';
import { just } from 'maybeasy';
import { Result, err, ok } from 'resulty';
import { programsStore } from '../../ProgramsStore';
import { ProgramResource } from '../../ProgramsStore/Types';

export const findProgramResource = (programId: string): Result<ProgramNotFound, ProgramResource> =>
  toResult(
    programNotFound(),
    just({})
      .assign(
        'programs',
        programsStore.resource.map(({ payload }) => payload.programs)
      )
      .assign('programId', parseIntM(programId))
      .andThen(({ programs, programId }) => find(byPayloadId(programId), programs))
  );

export const whenViewable = (
  resource: ProgramResource
): Result<ProgramNotViewable, ProgramResource> => {
  switch (resource.payload.kind) {
    case 'active':
    case 'completed':
      return ok(resource);
    case 'inactive':
    case 'upcoming':
    case 'expired':
      return err(programNotViewable());
  }
};

const programNotFound = (): ProgramNotFound => ({ kind: 'program-not-found' });

const programNotViewable = (): ProgramNotViewable => ({ kind: 'program-not-viewable' });

interface ProgramNotFound {
  kind: 'program-not-found';
}

interface ProgramNotViewable {
  kind: 'program-not-viewable';
}

export type ProgramSummaryError = ProgramNotFound | ProgramNotViewable;
