import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamsResource } from '../../../../TeamsStore/Types';
import TeamMemberList from '../../../TeamMemberList';

interface Props {
  teamsResource: TeamsResource;
}

const TeamMembersBody: React.FC<Props> = ({ teamsResource }) => {
  return (
    <dd>
      {teamsResource.payload.map((teamResource) => (
        <TeamMemberList
          key={teamResource.payload.id}
          teamMembers={teamResource.payload.teamMembers}
          presenceMembersStore={nothing()}
        />
      ))}
    </dd>
  );
};

export default observer(TeamMembersBody);
