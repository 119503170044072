import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import React from 'react';
import { ProductCollection } from '../../../../Common/Experience/Types';
import EnrollmentStore from '../../../Enrollment/Store';
import ProductCollectionView from './ProductCollectionView';

interface Props {
  invitationUuid: string;
  searchValue: Maybe<string>;
  productCollectionsList: NonEmptyList<ProductCollection>;
  enrollmentStore: EnrollmentStore;
}

function ProductCollectionsView({
  productCollectionsList,
  invitationUuid,
  enrollmentStore,
  searchValue,
}: Props) {
  return (
    <div className="flex flex-grow flex-col gap-y-8">
      {productCollectionsList.map((productCollection) => (
        <React.Fragment key={productCollection.id}>
          <div className="bg-white shadow-section">
            <ProductCollectionView
              productCollection={productCollection}
              invitationUuid={invitationUuid}
              enrollmentStore={enrollmentStore}
              searchValue={searchValue}
            />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

export default observer(ProductCollectionsView);
