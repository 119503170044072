import { ButtonHTMLAttributes } from 'react';

export type ButtonColor = 'primary' | 'secondary' | 'dark' | 'gray' | 'black' | 'white';

export type ButtonVariant = 'solid' | 'outlined' | 'text';

export type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';

export type ButtonShape =
  | 'rounded-none'
  | 'rounded'
  | 'rounded-sm'
  | 'rounded-md'
  | 'rounded-lg'
  | 'rounded-xl'
  | 'rounded-full';

export const buttonVariantClassNameMapping: Record<ButtonVariant, string> = {
  solid: 'btn-solid border',
  outlined: 'btn-outlibed border-solid border',
  text: 'btn-text border',
};

export const buttonSizeClassNameMapping: Record<ButtonSize, string> = {
  sm: 'text-font-size-sm px-2 py-[5px] leading-4',
  md: 'text-font-size-md px-3 py-[5px] leading-5',
  lg: 'text-font-size-lg px-6 py-[7px] font-medium leading-6',
  xl: 'text-font-size-lg px-6 py-[11px] font-medium tracking-wide leading-7',
};

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  variant?: ButtonVariant;
  shape?: ButtonShape;
  size?: ButtonSize;
}
