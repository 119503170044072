import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import { T } from '../../../../Translations';
import Modal from '../../../Modal';
import ActionMenuStore from '../ActionMenu/ActionMenuStore';
import * as style from './style.module.css';

interface Props {
  actionMenuStore: ActionMenuStore;
  links: ReadonlyArray<Link>;
}

const DeleteMessageModal: React.FC<Props> = ({ actionMenuStore, links }) => {
  return (
    <Modal
      close={actionMenuStore.ready}
      title={<T kind="Please confirm you want to delete this message" />}
    >
      <div className={style.buttons}>
        <button
          className={style.noButton}
          onClick={actionMenuStore.ready}
          data-test-delete-message-no-button
        >
          <T kind="Cancel" />
        </button>
        <button
          className={style.yesButton}
          onClick={() => actionMenuStore.deleteMessage(links)}
          data-test-delete-message-yes-button
        >
          <T kind="Delete" />
        </button>
      </div>
    </Modal>
  );
};
export default DeleteMessageModal;
