import { Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function SkeletonFilterExperiences() {
  return (
    <div className="mx-auto box-border w-full px-6 pb-8 pt-11 xl:container md:px-16">
      <div className="flex flex-col gap-4">
        <Skeleton className="w-8/12 md:w-4/12" size="lg" />
        <Skeleton className="md:w-8/12" />
        <Skeleton className="md:w-8/12" />
        <Skeleton className="w-6/12 md:w-4/12" />
      </div>
      <div className="mt-11 flex flex-wrap items-center justify-between gap-2 lg:flex-nowrap">
        <div className="flex w-full gap-2 lg:w-5/12">
          <Skeleton className="w-9/12 md:w-10/12" variant="rectangle" size="md" />
          <Skeleton className="w-3/12 md:w-2/12" variant="rectangle" size="md" />
        </div>
        <div className="hidden w-full gap-2 md:flex lg:w-7/12">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} className="w-3/12" variant="rectangle" size="md" />
          ))}
        </div>
      </div>
    </div>
  );
}

export default observer(SkeletonFilterExperiences);
