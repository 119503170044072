import { SessionStore as _SessionStore } from '@execonline-inc/unified-auth.private';
import { action, computed, decorate, observable } from 'mobx';
import { now } from 'mobx-utils';
import { RootResourceError } from '../RootResourceStore/Types';
import { TPlainTextKey } from '../Translations';

decorate(_SessionStore, {
  state: observable,
  requesting: action,
  present: action,
  absent: action,
  errored: action,
  userId: computed,
  sessionToken: computed,
  impersonatingBy: computed,
  expiryTimeLeft: computed,
  timeoutState: computed,
});

export const sessionStore = new _SessionStore<RootResourceError, TPlainTextKey>(now);

export type SessionStore = typeof sessionStore;
