import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

function ResumableCardBody() {
  return (
    <Typography className="text-gray-700" variant="subtitleSmall" fontWeight="medium" as="p">
      <T kind="Pick up where you left off with your experience:" />
    </Typography>
  );
}

export default observer(ResumableCardBody);
