import { State, closed, open } from './Types';
import { action, observable, computed } from 'mobx';

class ModuleToggleStore {
  @observable
  state: State = closed();

  @action
  toggle = () => {
    this.state = this.isOpen ? closed() : open();
  };

  @computed
  get isOpen(): boolean {
    return this.state.kind === 'open';
  }
}

export default ModuleToggleStore;
