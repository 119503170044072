import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../../Translations';
import Currency from '../../../../../../../../Currency';

interface Props {
  price: Maybe<number>;
}

function PaymentCardCharge({ price }: Props) {
  return price
    .map((price) => (
      <Typography variant="bodyMedium">
        <T
          kind="Your card will be charged <price/>"
          price={
            <Currency
              priceInDollars={price / 100}
              options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            />
          }
        />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PaymentCardCharge);
