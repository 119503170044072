import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from '../../TeamSummary/style.module.css';
import Label from '../../Form/Label';
import { SelectOption } from '../../Form/Select/Option';
import { just, Maybe } from 'maybeasy';
import Select from '../../Form/Select';
import InputWithLabel from '../../Form/InputWithLabel';
import { Options } from './CommunityProgramOptionsStore/Types';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import CommunityProgramOptionsStore from './CommunityProgramOptionsStore';
import { find } from '@execonline-inc/collections';
import { SearchParameter } from './CoachCommunitySummaryStore/Types';
import { T } from '../../../Translations';
import ProgramTypeFilter from './ProgramTypeFilter';

interface Props {
  coachTeamsSummaryStore: coachTeamsSummaryStore;
  optionsStore: CommunityProgramOptionsStore;
}

const Filters: React.FC<Props> = ({ coachTeamsSummaryStore, optionsStore }) => {
  const convertsToOptions = (options: Maybe<Options>): SelectOption[] =>
    options
      .map((options) =>
        [{ label: 'Select One', value: '' }, ...options].map((option) => ({
          content: option.label,
          value: option.value.toString(),
        }))
      )
      .getOrElseValue([]);

  const setSelectedProgram = (event: React.ChangeEvent<HTMLSelectElement>) => {
    coachTeamsSummaryStore.addFilter('program_id', event.target.value);
    coachTeamsSummaryStore.loading();
  };

  const setSelectedTeam = (event: React.ChangeEvent<HTMLSelectElement>) => {
    coachTeamsSummaryStore.addFilter('team_id', event.target.value);
    coachTeamsSummaryStore.loading();
  };

  const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    coachTeamsSummaryStore.addFilter('query', event.currentTarget.value);
    coachTeamsSummaryStore.loading();
  };

  const onSearch = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    coachTeamsSummaryStore.loading();
  };

  const valueFor = (key: string): string =>
    find(
      (parameter: SearchParameter) => parameter.key === key,
      coachTeamsSummaryStore.searchParameters
    )
      .andThen((parameter) => just(parameter.value))
      .getOrElseValue('');

  return (
    <form className={style.optionsFilter}>
      <div className={style.searchBox}>
        <div>
          <InputWithLabel
            labelText="Search"
            type="text"
            onChange={onInputChange}
            name="query"
            id="Search"
          />
        </div>
        <button onClick={onSearch}>
          <T kind="Search" />
        </button>
      </div>
      <div className={style.selectBox}>
        <div className={style.searchBoxItems}>
          <Label labelText="Program Type" htmlFor="programType" />
          <div>
            <ProgramTypeFilter coachTeamsSummaryStore={coachTeamsSummaryStore} />
          </div>
        </div>
        <div className={style.searchBoxItems}>
          <Label labelText="Program Name" htmlFor="programName" />
          <div>
            <Select
              className={style.filterSelect}
              placeholder="Select One"
              name="programName"
              id="programName"
              options={convertsToOptions(optionsStore.programOptions)}
              value={valueFor('program_id')}
              onChange={setSelectedProgram}
            />
          </div>
        </div>
        <div className={style.searchBoxItems}>
          <Label labelText="Team Name" htmlFor="teamName" />
          <div>
            <Select
              className={style.filterSelect}
              placeholder="Select One"
              name="TeamName"
              id="TeamName"
              options={convertsToOptions(optionsStore.teamOptions)}
              value={valueFor('team_id')}
              onChange={setSelectedTeam}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default observer(Filters);
