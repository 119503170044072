import { Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';
import { Resource, ResourceWithErrors } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';

export interface Loading {
  kind: 'loading';
}

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  countryOptionsResource: CountryOptionsResource;
  flashMessage: Maybe<TPlainTextKey>;
}

export interface ReadyWithErrors {
  kind: 'ready-with-errors';
  countryOptionsResource: CountryOptionsResourceWithErrors;
}

export const loading = (): Loading => ({
  kind: 'loading',
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (
  countryOptionsResource: CountryOptionsResource,
  flashMessage: Maybe<TPlainTextKey>
): Ready => ({
  kind: 'ready',
  countryOptionsResource,
  flashMessage,
});

export const readyWithErrors = (
  countryOptionsResource: CountryOptionsResourceWithErrors
): ReadyWithErrors => ({
  kind: 'ready-with-errors',
  countryOptionsResource,
});

export type CountryOptionsState = Waiting | Loading | Ready | ReadyWithErrors | Error;

export interface CountryOption {
  id: string;
  name: string;
}

export interface CountryOptions {
  countryOptions: CountryOption[];
}

export type CountryOptionResource = Resource<CountryOption>;

export type CountryOptionsResource = Resource<CountryOptions>;
export type CountryOptionsResourceWithErrors = ResourceWithErrors<CountryOptions>;
