import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { CompetencyResource } from '../../../../Types';

interface Props {
  competencies: Maybe<CompetencyResource[]>;
}

function ProgramCompetencies({ competencies }: Props) {
  return competencies
    .map((competencies) => (
      <Typography className="text-gray-900" variant="caption">
        <T kind={`{{count}} Competency`} count={competencies.length} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramCompetencies);
