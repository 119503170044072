import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DownloadIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 12 13">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M5.333 0v8.971l-3.52-2.86-.959.778 4.667 3.792.479.372.48-.372 4.666-3.792-.959-.778-3.52 2.86V0H5.333ZM0 11.917V13h12v-1.083H0Z"
      />
    </SVGIcon>
  );
};

export default observer(DownloadIcon);
