import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { CalendarDay } from '../../Calendaring';
import { ModuleOrder } from '../../DayModules';
import { EventResource } from '../../EventsStore/Types';
import { L, T } from '../../Translations';
import Event from '../Event';
import AsModal from '../Modal/AsModal';
import { CloseAction, OpenableState } from '../Openable';
import ModuleOnTrackStatus from './ModuleOnTrackStatus';
import * as style from './style.module.css';

type Events = Maybe<NonEmptyList<EventResource>>;

interface Props {
  modalState: OpenableState;
  close: CloseAction;
  day: CalendarDay;
  events: Events;
  modules: ModuleOrder;
}

const Title = observer(({ events, day }: Pick<Props, 'events' | 'day'>) => {
  const date = <L localizeable={day.date} format="long-weekday-month-year" />;
  return events.map(() => <T kind="Your Items for <date/>" date={date} />).getOrElseValue(date);
});

const EventMap = observer(({ events }: { events: NonEmptyList<EventResource> }) => (
  <>
    {events
      .map((resource) => <Event key={resource.payload.id} eventResource={resource} />)
      .toArray()}
  </>
));

const Events = observer(({ events }: Pick<Props, 'events'>) =>
  events
    .map((events) => <EventMap events={events} />)
    .getOrElse(() => (
      <div>
        <T kind="No events on this day" />
      </div>
    ))
);

const DayEvents: React.FC<Props> = ({ close, day, events, modules, modalState }) => (
  <AsModal close={close} title={<Title events={events} day={day} />} state={modalState}>
    <ModuleOnTrackStatus modules={modules} />
    <div className={style.events} data-test-day-events-list={true}>
      <Events events={events} />
    </div>
  </AsModal>
);

export default observer(DayEvents);
