import * as React from 'react';
import { CalendarEvent } from '../../../EventsStore/Types';
import EventTimeEntry from './EventTimeEntry';
import EventEndOn from './EventEndOn';
import EventStartOn from './EventStartOn';

interface Props {
  event: CalendarEvent;
}

const EventTimes: React.FC<Props> = ({ event }) => {
  switch (event.span) {
    case 'single-day':
      return (
        <>
          <EventTimeEntry title="Date" date={event.startAt} format="long-month-day-year" />
          <EventStartOn event={event} />
          <EventEndOn event={event} />
        </>
      );
    case 'multi-day':
      return (
        <>
          <EventStartOn event={event} />
          <EventEndOn event={event} />
        </>
      );
  }
};

export default EventTimes;
