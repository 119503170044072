import { MediaQueryBreakPoint, TypographyVariant } from '@execonline-inc/execonline-ui';
import { always, identity } from '@kofno/piper';
import { whenAny } from '../../../../../Collections';
import { Experience } from '../../../Common/Experience/Types';

export const getTimeCommitment = (experience: Experience) => {
  const { duration, hoursPerWeek, totalHours } = experience;

  return whenAny(identity, [duration, hoursPerWeek, totalHours]).map(
    always({ duration, hoursPerWeek, totalHours }),
  );
};

export const getTitleVariant = (breakPoint: MediaQueryBreakPoint): TypographyVariant => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'h4';
    case 'xs':
      return 'h5';
  }
};
