import { first } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { toTask } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../Links';
import { UponCompletion } from '../../SegmentStore/Types';
import { AutoLaunchableStore } from '../AutoLaunchable/AutoLaunchableStore';
import AutoLaunchableModalReactions from './AutoLaunchableModalReactions';
import AutoLaunchableModalStore from './AutoLaunchableModalStore';
import AutoLaunchableModalView from './AutoLaunchableModalView';
import ModalWrapping from './ModalWrapping';

interface Props {
  autoLaunchableStore: AutoLaunchableStore;
}

function AutoLaunchableModal({ autoLaunchableStore }: Props) {
  const autoLaunchableModalStore = useConst(() => new AutoLaunchableModalStore());

  const handleClose = (uponCompletion: Maybe<UponCompletion>) => {
    toTask('upon-completion', uponCompletion).fork(autoLaunchableStore.load, () => {
      autoLaunchableStore.load();
      window.location.reload();
    });
  };

  switch (autoLaunchableStore.state.kind) {
    case 'ready':
      const { autoLaunchableResource } = autoLaunchableStore.state;
      const { segments, title } = autoLaunchableResource.payload;
      const currentSegment = first(segments.filter((s) => s.isCurrentSegment));

      return currentSegment
        .map((currentSegment) => (
          <ModalWrapping
            title={title.text}
            closeM={findLinkBy({ rel: 'participant-postponables' }, currentSegment.links).map(
              (link) => () => autoLaunchableModalStore.postpone(link),
            )}
          >
            <AutoLaunchableModalView currentSegment={currentSegment} onClose={handleClose} />
            <AutoLaunchableModalReactions store={autoLaunchableModalStore} fireImmediately />
          </ModalWrapping>
        ))
        .getOrElse(emptyFragment);
    case 'nothing-to-launch':
    case 'loading':
    case 'error':
      return emptyFragment();
  }
}

export default observer(AutoLaunchableModal);
