import { when } from '@execonline-inc/maybe-adapter';
import { always } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import { Link as LinkType, Rel } from '../../Resource/Types';
import { TeamResource } from '../../TeamsStore/Types';
import { TPlainTextKey } from '../../Translations';
import TeamArrowIcon from '../Cutesies/TeamArrowIcon';
import * as style from '../TeamProfile/style.module.css';
import TeamBreadCrumbLinkContent from './TeamBreadCrumbLinkContent';

export interface MenuItemContent {
  content: TPlainTextKey;
  to: string;
  rel: Rel;
  exact: boolean;
}

interface Props {
  teamResource: TeamResource;
  teamId: number;
}

export const setTeamLink = (config: { menuItemContent: MenuItemContent }): Maybe<TPlainTextKey> =>
  when<TPlainTextKey>(config.menuItemContent.content === 'Team', 'Team').orElse(() =>
    just('Teams')
  );

const toMenuItem = (content: MenuItemContent) => (
  links: ReadonlyArray<LinkType>
): Maybe<MenuItemContent> => just(links).andThen(findLink(content.rel)).map(always(content));

const toCommunitiesMenuItem = toMenuItem({
  content: 'Communities',
  to: '/communities',
  rel: 'communities',
  exact: false,
});

const toTeamMenuItem = toMenuItem({
  content: 'Team',
  to: '/team',
  rel: 'team',
  exact: false,
});

export const teamOrCommunitiesMenuItem = (links: ReadonlyArray<LinkType>): Maybe<MenuItemContent> =>
  toTeamMenuItem(links).orElse(() => toCommunitiesMenuItem(links));

const TeamBreadCrumbLink: React.FC<Props> = ({ teamResource, teamId }) => (
  <WithCurrentUser
    children={(currentUserResource) =>
      just({})
        .assign('menuItemContent', teamOrCommunitiesMenuItem(currentUserResource.links))
        .assign('teamLink', setTeamLink)
        .map(({ menuItemContent, teamLink }) => (
          <TeamBreadCrumbLinkContent
            menuItemContent={menuItemContent}
            teamLink={teamLink}
            teamId={teamId}
            teamName={teamResource.payload.name}
          />
        ))
        .getOrElse(() => (
          <div className={style.newButton}>
            <div className={style.buttonIcon}>
              <TeamArrowIcon />
            </div>
          </div>
        ))
    }
  />
);

export default observer(TeamBreadCrumbLink);
