import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const LearningPartnerAssignmentAnnouncementIcon: React.FC = () => (
  <SVGIcon viewBox="0 0 25 25">
    <path
      fill={defaultTheme.variables['icon-accent-on-light']}
      d="M7.292 0v7.292c.724 0 1.424.093 2.083.293V2.083h8.333v5.209h5.209v13.541h-7.26c.33.655.615 1.351.782 2.084H25V6.347L18.652 0H7.292Zm0 9.375c-2.865 0-5.209 2.344-5.209 5.208 0 1.58.737 2.983 1.856 3.94A7.304 7.304 0 0 0 0 25h2.083a5.192 5.192 0 0 1 5.209-5.208A5.192 5.192 0 0 1 12.5 25h2.083a7.297 7.297 0 0 0-3.938-6.478c1.119-.956 1.855-2.36 1.855-3.939 0-2.864-2.344-5.208-5.208-5.208Zm0 2.083a3.11 3.11 0 0 1 3.125 3.125 3.11 3.11 0 0 1-3.125 3.125 3.11 3.11 0 0 1-3.125-3.125 3.11 3.11 0 0 1 3.125-3.125Z"
    />
  </SVGIcon>
);

export default observer(LearningPartnerAssignmentAnnouncementIcon);
