import { LocalConferenceRoomResource } from '../Conference/Types';
import HandRaiser from '../HandRaiser';

export interface Ready {
  kind: 'ready';
  conferenceRoom: LocalConferenceRoomResource;
  hands: HandRaiser[];
}

export interface Pending {
  kind: 'pending';
}

export type HandRaisersState = Ready | Pending;

export const pending = (): Pending => ({
  kind: 'pending',
});

export const ready = (conferenceRoom: LocalConferenceRoomResource): Ready => ({
  kind: 'ready',
  conferenceRoom,
  hands: [],
});
