import { observer } from 'mobx-react';
import * as React from 'react';
import { EventResource } from '../../../EventsStore/Types';
import { findLink } from '../../../LinkyLinky';
import { T } from '../../../Translations';
import ExternalIcon from '../../Cutesies/ExternalIcon';
import * as style from '../style.module.css';

interface Props {
  eventResource: EventResource;
}

const CancelButton: React.FC<Props> = ({ eventResource }) => {
  return findLink('cancel')(eventResource.links)
    .map((l) => (
      <a
        href={l.href}
        target="_blank"
        rel="noopener noreferrer"
        className={style.cancelButtonCallToAction}
      >
        <T kind="Cancel Session" />
        <div className={style.buttonIcon}>
          <ExternalIcon />
        </div>
      </a>
    ))
    .getOrElse(() => <></>);
};

export default observer(CancelButton);
