import { Card, CardBody, CardHeader, cn, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

interface Props {
  className?: string;
}

function SkeletonListViewProgramExperience({ className }: Props) {
  return (
    <Card className={cn('min-h-46 w-full bg-white sm:flex-row', className)}>
      <CardHeader className="items-start p-0 shadow-none sm:py-6 sm:pl-6 sm:pr-2">
        <Skeleton className="h-[11.625rem] w-full sm:h-35 sm:w-[15.5rem] sm:rounded-md" />
      </CardHeader>
      <CardBody className="gap-5 p-6">
        <div className="flex justify-between">
          <Skeleton className="w-6/12 md:w-4/12" size="md" />
          <Skeleton className="w-2/12" size="md" />
        </div>
        <div className="flex w-full gap-6 lg:w-6/12">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="w-4/12" />
          ))}
        </div>
        <div className="flex w-full gap-2 lg:w-6/12">
          {Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} className="w-6/12" variant="rectangle" />
          ))}
        </div>
        <Skeleton className="w-4/12 md:w-2/12" variant="rectangle" />
      </CardBody>
    </Card>
  );
}

export default observer(SkeletonListViewProgramExperience);
