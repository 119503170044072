import { byPayloadId } from '@execonline-inc/collections';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link, Linkable } from '../Resource/Types';
import PresenceMembersStore from '../Socket/PresenceChannel/PresenceMembersStore';
import { isLargeTeam, TRUNCATED_DISPLAY_COUNT, truncateTeamMembers } from '../TeamMembers';
import { TeamMemberResource, TeamResource } from '../TeamsStore/Types';
import { TPlainTextKey } from '../Translations';
import { loading, ready, TeamState, waiting } from './Types';

class TeamStore {
  @observable
  state: TeamState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (teamResource: TeamResource) => {
    this.state = ready(teamResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get team(): Maybe<TeamResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.teamResource);
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get allMembers(): TeamMemberResource[] {
    switch (this.state.kind) {
      case 'ready':
        return this.state.teamResource.payload.teamMembers.concat(
          this.state.teamResource.payload.coaches
        );
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.teamResource.links;
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get whenLinks(): Maybe<Linkable> {
    return this.team;
  }

  @computed
  get isLargeTeam(): boolean {
    switch (this.state.kind) {
      case 'ready':
        return isLargeTeam(this.state.teamResource);
      case 'error':
      case 'loading':
      case 'waiting':
        return false;
    }
  }

  @computed
  get truncatedTeamMembers(): ReadonlyArray<TeamMemberResource> {
    switch (this.state.kind) {
      case 'ready':
        return truncateTeamMembers(this.state.teamResource, TRUNCATED_DISPLAY_COUNT);
      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }

  @computed
  get overflowTeamMemberCount(): Maybe<number> {
    switch (this.state.kind) {
      case 'ready':
        return this.isLargeTeam
          ? just(
              this.state.teamResource.payload.teamMembers.length - this.truncatedTeamMembers.length
            )
          : nothing();
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  onlineTeamMembers = createTransformer(
    (presenceStore: PresenceMembersStore): Maybe<TeamMemberResource[]> => {
      switch (this.state.kind) {
        case 'ready':
          return fromEmpty(
            this.allMembers.filter((teamMemberResource) => {
              return presenceStore.members.find((presenceMember) =>
                byPayloadId(presenceMember.id)(teamMemberResource)
              );
            })
          );
        case 'error':
        case 'loading':
        case 'waiting':
          return nothing();
      }
    }
  );
}

export default TeamStore;
