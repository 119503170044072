import { Experience } from '../../../../Common/Experience/Types';
import { localPublicUrl } from '../../../../../MobileImageBackground/Types';

export const learnMoreBackgroundUrl = (experience: Experience) => {
  switch (experience.offeringType) {
    case 'coaching':
      return `url("${localPublicUrl('learn-more-program.svg')}"),
              url("${localPublicUrl('learn-more-leadership-coaching.svg')}")`;
    case 'group-coaching':
      return `url("${localPublicUrl('learn-more-program.svg')}")`;
    case 'program-sequence':
      return `url("${localPublicUrl('learn-more-learning-journey.svg')}")`;
    case 'aep':
    case 'epc':
    case 'msuite':
      return programDetailsBackgroundUrl(experience);
  }
};

export const learnMoreBackgroundClassName = (experience: Experience) => {
  switch (experience.offeringType) {
    case 'coaching':
      return 'bg-[length:200%] sm:bg-auto bg-[position:top_left,bottom_right]';
    case 'group-coaching':
      return 'bg-[length:200%] sm:bg-auto';
    case 'program-sequence':
      return 'bg-[length:200%] sm:bg-auto';
    case 'aep':
    case 'epc':
    case 'msuite':
      return programDetailsBackgroundClassName(experience);
  }
};

const programDetailsBackgroundUrl = (experience: Experience) => {
  switch (experience.kind) {
    case 'beginnable':
    case 'commerce-enrollable':
    case 'enrollable':
    case 'upcoming':
    case 'not-enrollable':
    case 'not-licensed':
    case 'resumable':
      return `url("${localPublicUrl('learn-more-program.svg')}")`;
    case 'graduated':
    case 'returnable':
      return `url("${localPublicUrl('learn-more-program.svg')}"),
              url("${localPublicUrl('learn-more-graduated.svg')}")`;
  }
};

const programDetailsBackgroundClassName = (experience: Experience) => {
  switch (experience.kind) {
    case 'beginnable':
    case 'commerce-enrollable':
    case 'enrollable':
    case 'upcoming':
    case 'not-enrollable':
    case 'not-licensed':
    case 'resumable':
      return 'bg-[length:200%] sm:bg-auto';
    case 'graduated':
    case 'returnable':
      return 'bg-[length:200%] sm:bg-auto bg-[position:top_left,bottom_right]';
  }
};
