import { minutes, Time } from '@execonline-inc/time';
import { Error } from '../ErrorHandling';

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  duration: Time;
}

interface Updating {
  kind: 'updating';
  duration: Time;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const reset = (): Ready => ({
  kind: 'ready',
  duration: minutes(20),
});

export const ready = (duration: Time): Ready => ({
  kind: 'ready',
  duration,
});

export const updating = (duration: Time): Updating => ({
  kind: 'updating',
  duration,
});

export type BreakoutTimerState = Ready | Updating | Waiting | Error;
