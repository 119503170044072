import { findPayloadByUuid } from '@execonline-inc/collections';
import { Kettle } from 'kettle-corn';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import AnnouncementVideo from './AnnouncementVideo';
import { AnnouncementVideoAssetResource } from './Types';
import VideoAssetUnavailable from './VideoAssetUnavailable';

export interface Props {
  uuid: string;
  embeddedVideoAssets: AnnouncementVideoAssetResource[];
  height: number | string;
  width: number | string;
  dynamicallySetAspectRatio: boolean;
}

@observer
class AnnouncementEmbeddedVideoPlayer extends React.Component<Props, {}> {
  render() {
    return just(this.props.embeddedVideoAssets)
      .andThen(findPayloadByUuid(this.props.uuid))
      .map(videoAssetResource => (
        <AnnouncementVideo
          id={videoAssetResource.payload.uuid}
          className="embedded-video"
          videoResource={videoAssetResource}
          kettle={new Kettle()}
          width={this.props.width}
          height={this.props.height}
          dynamicallySetAspectRatio={this.props.dynamicallySetAspectRatio}
        >
          {{
            playButton: nothing(),
          }}
        </AnnouncementVideo>
      ))
      .getOrElseValue(<VideoAssetUnavailable />);
  }
}

export default AnnouncementEmbeddedVideoPlayer;
