import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption } from '../../../Types';
import {
  LeadershipPersonaAlexIcon,
  LeadershipPersonaCharlieIcon,
  LeadershipPersonaDarcyIcon,
  LeadershipPersonaElliottIcon,
  LeadershipPersonaVinnyIcon,
} from '../Icons';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
}

const PersonaIcon = ({ option }: Props) =>
  option.resource
    .map((resource) => {
      switch (resource.payload.kind) {
        case 'adaptive-alex':
          return <LeadershipPersonaAlexIcon />;
        case 'collaborative-charlie':
          return <LeadershipPersonaCharlieIcon />;
        case 'decisive-darcy':
          return <LeadershipPersonaDarcyIcon />;
        case 'empathetic-elliot':
          return <LeadershipPersonaElliottIcon />;
        case 'visionary-vinny':
          return <LeadershipPersonaVinnyIcon />;
      }
    })
    .getOrElse(emptyFragment);

export default observer(PersonaIcon);
