import { observer } from 'mobx-react';
import { assertNever } from '@kofno/piper';
import { PaymentIntent } from '@stripe/stripe-js';
import ReactionComponent, { RCProps } from '../../../../../../../ReactionComponent';
import PaymentCheckoutFormStore from '../PaymentCheckoutFormStore';
import { PaymentCheckoutFormState } from '../PaymentCheckoutFormStore/Types';
import { confirmPayment } from './StripePayment';

interface Props extends RCProps<PaymentCheckoutFormStore> {
  onSuccess: (data: PaymentIntent) => void;
}

class PaymentCheckoutFormReactions extends ReactionComponent<
  PaymentCheckoutFormStore,
  PaymentCheckoutFormState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: PaymentCheckoutFormState) => {
    const { store, onSuccess } = this.props;
    switch (state.kind) {
      case 'waiting':
      case 'ready-for-payment-confirmation':
        break;
      case 'confirming-payment':
        confirmPayment(state.stripe, state.elements, store, onSuccess);
        break;
      case 'payment-declined':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(PaymentCheckoutFormReactions);
