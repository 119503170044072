import { observer } from 'mobx-react';
import * as React from 'react';
import { EnrollmentStatusType } from '../../../../DiscoveryStore/Types';
import { L, T } from '../../../../Translations';

interface Props {
  status: EnrollmentStatusType;
}

const EnrollmentStatus: React.FC<Props> = ({ status }) => {
  switch (status.kind) {
    case 'currently-enrolled':
      return (
        <div>
          <span className="mx-1">&#x2022;</span>
          <T kind="Currently Enrolled" />
        </div>
      );
    case 'future-enrolled':
      return (
        <div>
          <span className="mx-1">&#x2022;</span>
          <T
            kind="Next Enrollment: <date/>"
            date={<L localizeable={status.date} format="long-month-day-year" />}
          />
        </div>
      );
    case 'past-enrolled':
      return <></>;
  }
};

export default observer(EnrollmentStatus);
