import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { eventConferenceUrl } from '../../../ClientRoutes';
import ScheduledToastStore from '../../../ToastsStore/ScheduledToastStore';
import { T } from '../../../Translations';
import * as style from '../style.module.css';
import DismissButton from './DismissButton';

interface Props {
  store: ScheduledToastStore;
}

const EmbeddedConferenceTemplate: React.FC<Props> = ({ store }) => {
  return (
    <div className={style.actions}>
      <DismissButton store={store} />
      <Link
        className={style.button}
        to={eventConferenceUrl(store.scheduledToast.id)}
        data-test-toast-join={true}
      >
        <T kind="Join Meeting" />
      </Link>
    </div>
  );
};

export default observer(EmbeddedConferenceTemplate);
