import { observer } from 'mobx-react';
import { Button, ButtonProps } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../Translations';
import { experienceDetailsRoute } from '../../../../../ClientRoutes';
import { UpcomingExperience } from '../../../Common/Experience/Types';
import DiscoveryContext from '../../../DiscoveryContext';
import NativeLink from '../../../../NativeLink';

interface Props {
  invitationUuid: string;
  experience: UpcomingExperience;
  buttonProps?: ButtonProps;
}

function UpcomingAction({ invitationUuid, experience, buttonProps }: Props) {
  return (
    <DiscoveryContext.Consumer>
      {(discoveryContextState) => (
        <NativeLink
          to={experienceDetailsRoute(
            invitationUuid,
            experience.experienceId,
            discoveryContextState,
          )}
        >
          {(onClick) => (
            <Button color="gray" fullWidth {...buttonProps} onClick={onClick}>
              <T
                kind="Enrolled Starts <date/>"
                date={<L localizeable={experience.startOn} format="long-month-day-year" />}
              />
            </Button>
          )}
        </NativeLink>
      )}
    </DiscoveryContext.Consumer>
  );
}

export default observer(UpcomingAction);
