import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import PersonalStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import { resourcesListResourceDecoder } from '../../Shared/Decoders';
import { PersonalResourcesState } from './Types';

export interface Props extends EAProps<PersonalStore> {
  store: PersonalStore;
}

type ProgramResourcesError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleProgramResourcesError = (store: PersonalStore) => (error: ProgramResourcesError) => {
  switch (error.kind) {
    case 'missing-link-error':
      warnAndNotify('ProgramResourcesReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

class ProgramResourcesReactions extends ErrorActionableReaction<
  PersonalStore,
  PersonalResourcesState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: PersonalResourcesState) => {
    const { store } = this.props;

    switch (state.kind) {
      case 'loading':
        Task.succeed<ProgramResourcesError, ReadonlyArray<Link>>(state.links)
          .andThen(findLinkT('my-personalized-resources'))
          .andThen(callApi(resourcesListResourceDecoder, {}))
          .fork(handleProgramResourcesError(this.props.store), store.ready);
        break;
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramResourcesReactions;
