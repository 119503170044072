import type { FetchError } from '@execonline-inc/fetch.private';
import { AppyError } from '../Appy';
import { MissingLinkError } from '../LinkyLinky';
import type { ResourceFor } from '../Resourceable';

export type RootResource = ResourceFor<'/roots', 'get', 200>;

export interface Loading {
  kind: 'loading';
}

export interface Ready {
  kind: 'ready';
  resource: RootResource;
}

export interface Error {
  kind: 'error';
  error: FetchError;
}

export type State = Loading | Ready | Error;

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (resource: Ready['resource']): Ready => ({
  kind: 'ready',
  resource,
});

export const error = (error: Error['error']): Error => ({
  kind: 'error',
  error,
});

export type RootResourceError = MissingLinkError | AppyError;
