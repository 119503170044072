import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import ResourceView from './ResourceView';
import * as style from './style.module.css';
import { Resources } from './Types';

interface Props {
  resources: Resources;
}

const ResourcesList: React.FC<Props> = ({ resources }) => {
  return (
    <div className={style.segments}>
      <h2 className={style.segmentTitle}>{resources.title}</h2>
      {resources.resources
        .map((r) =>
          r
            .toArray()
            .map((resource) => <ResourceView key={resource.payload.title} resource={resource} />)
        )
        .andThen<React.ReactNode>(fromEmpty)
        .getOrElseValue(
          <p className={style.emptyText}>
            <T kind="No resources to display" />
          </p>
        )}
    </div>
  );
};

export default observer(ResourcesList);
