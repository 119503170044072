import { Error } from '../ErrorHandling';
import { EditorState } from 'draft-js';

interface Ready {
  kind: 'ready';
  reply: EditorState;
}

interface Updating {
  kind: 'updating';
  reply: EditorState;
}

interface Broadcasting {
  kind: 'broadcasting';
  reply: EditorState;
}

interface Hidden {
  kind: 'hidden';
  reply: EditorState;
}

export const hidden = (reply: EditorState): Hidden => ({
  kind: 'hidden',
  reply: reply,
});

export const reset = (): Hidden => ({
  kind: 'hidden',
  reply: EditorState.createEmpty(),
});

export const ready = (reply: EditorState): Ready => ({
  kind: 'ready',
  reply: reply,
});

export const updating = (reply: EditorState): Updating => ({
  kind: 'updating',
  reply: reply,
});

export const broadcasting = (reply: EditorState): Broadcasting => ({
  kind: 'broadcasting',
  reply: reply,
});

export type ChatReplyState = Ready | Updating | Broadcasting | Error | Hidden;
