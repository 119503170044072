import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import {
  CarouselDotProps,
  CarouselStore,
  CarouselWithButtons,
} from '../../../Common/Experience/Carousel';
import { PreviewModeContext } from '../../../DiscoveryPortal/PreviewMode/PreviewModeContext';
import ExperienceList from './ExperienceList';
import MobileExperienceList from './MobileExperienceList';

interface Props<T> {
  carouselStore: CarouselStore<T>;
  children: (props: { items: ReadonlyArray<T> }) => ReactNode;
}

const dotProps: CarouselDotProps = {
  className: 'absolute bottom-0 mb-10',
};

function PromotableExperienceCarousel<T>({ carouselStore, children }: Props<T>) {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
    case 'lg':
      return (
        <CarouselWithButtons carouselStore={carouselStore} dotProps={dotProps}>
          <ExperienceList page={carouselStore.currentPage} children={children} />
        </CarouselWithButtons>
      );
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <PreviewModeContext.Consumer>
          {(preview) => {
            switch (preview) {
              case 'default':
                return <MobileExperienceList items={carouselStore.contents} children={children} />;
              case 'preview':
                return (
                  <CarouselWithButtons carouselStore={carouselStore} dotProps={dotProps}>
                    <ExperienceList page={carouselStore.currentPage} children={children} />
                  </CarouselWithButtons>
                );
            }
          }}
        </PreviewModeContext.Consumer>
      );
  }
}

export default observer(PromotableExperienceCarousel);
