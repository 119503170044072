import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { TPlainTextKey } from '../../Translations';
import Input from '../Form/Input';
import { ConjointInputType } from './Types';

interface Props<T extends ConjointInputType> {
  name: keyof T;
  onChange: (name: keyof T, value: string) => void;
  hint: TPlainTextKey;
  labelText: TPlainTextKey;
  required: boolean;
}

function ConjointInput<T extends ConjointInputType>({
  name,
  onChange,
  hint,
  labelText,
  required,
}: Props<T>): JSX.Element {
  const nameAttr = String(name);
  return (
    <>
      <label htmlFor={nameAttr}>{labelText} *</label>
      <Input
        onChange={(evt) => (evt ? onChange(name, evt.currentTarget.value) : noop())}
        type="text"
        id={nameAttr}
        name={nameAttr}
        hint={hint}
        required={required}
      />
    </>
  );
}

export default observer(ConjointInput);
