import { map, toPairs } from '@execonline-inc/collections';
import { fromEmpty } from 'maybeasy';
import * as React from 'react';
import { AssignmentResource } from '../../../TeamResourceStore/Types';
import { groupedAssignments } from '../../../TeamsStore/Types';
import FileGroup from './FileGroup';

type RenderGroupArgs = [string, AssignmentResource[]];

const renderGroups = map(([label, assignments]: RenderGroupArgs) => (
  <FileGroup label={label} assignments={assignments} />
));

export const teamFiles = (assignments: AssignmentResource[]) =>
  fromEmpty(toPairs(groupedAssignments(assignments)))
    .map<React.ReactNode>(renderGroups)
    .getOrElse(() => <span />);

interface Props {
  assignments: AssignmentResource[];
}
const FileGroups: React.FC<Props> = ({ assignments }) =>
  fromEmpty(toPairs(groupedAssignments(assignments)))
    .map((foo) => <>{renderGroups(foo)}</>)
    .getOrElse(() => <span />);

export default FileGroups;
