import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import { T } from '../../../Translations';
import ModuleEndOn from '../ModuleEndOn';
import * as style from '../style.module.css';
import ProgressMessage from './ProgressMessage';

interface Props {
  programStore: ProgramStore;
}
const ProgressSection: React.FC<Props> = ({ programStore }) => {
  return (
    <section className={style.section}>
      <h3 className={style.supPanelHeader} data-test-section-header="Progress">
        <T kind="Progress" />
      </h3>
      <div className={style.progress}>
        <div className={style.progressBar}>
          <div
            className={style.progressFill}
            data-complete={Math.round(programStore.percentComplete.getOrElseValue(0))}
            data-test-progress-bar={true}
          />
        </div>
      </div>
      <div className={style.progressDesc} data-test-module-completion-count={true}>
        <ProgressMessage programStore={programStore} />
        <ModuleEndOn endOn={programStore.currentModuleEndsOn} />
      </div>
    </section>
  );
};

export default observer(ProgressSection);
