import { ButtonSize, MediaQueryBreakPoint } from '@execonline-inc/execonline-ui';

export const getButtonSize = (breakPoint: MediaQueryBreakPoint): ButtonSize => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'lg';
    case 'xs':
      return 'md';
  }
};
