import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const ChatReplyIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 512 512">
      <path
        fill="#808080"
        d="M161 17.5c-5 2.3-108.6 105.8-111.1 111-2.4 5.1-2.4 8.9.1 13.9 1.3 2.7 19.8 21.9 55.2 57.2 42 41.8 54.1 53.4 57.1 54.4 7.3 2.3 15.7-1.1 19.2-8.1 1.8-3.3 1.9-9.6.4-13.6-.5-1.5-18.6-20.5-40.2-42.1l-39.2-39.3 11.5.6c74.3 3.9 135.3 26.2 192 70.1 12.6 9.7 42.2 38.6 51.7 50.4 15.6 19.5 27.9 38.6 38.8 60 22 43.6 32.9 86 35.3 137.7.8 17.6 1.3 19 7.6 23.7 3.9 2.9 13.3 2.9 17.2 0 6.6-4.9 6.9-6 6.8-23.7-.3-43.7-11.1-91.8-30.3-135.4-44.8-101.5-135.9-178-243.5-204.4-23.3-5.7-45.4-8.8-76-10.5l-10.9-.6 37.5-37.2c20.7-20.4 38.7-38.9 40.2-41 8.6-12.8-5.3-29.3-19.4-23.1z"
      />
    </SVGIcon>
  );
};

export default observer(ChatReplyIcon);
