import { warn } from '@execonline-inc/logging';
import { always, assertNever, noop } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { SharedInvitationResourceStore } from '.';
import { callApi } from '../../../../Appy';
import { changeLocaleT } from '../../../../ChangeLocale';
import { handleError } from '../../../../ErrorActionableReaction';
import { findLinkT } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { warnAndNotifyDecoderError } from '../../../../Resource/Decoders';
import { Link } from '../../../../Resource/Types';
import { rootResourceStore } from '../../../../RootResourceStore';
import { notYetTranslatedText } from '../../../../Translations';
import { sharedInvitationErrorResourceDecoder, sharedInvitationResourceDecoder } from './Decoders';
import { LoadingError, State } from './Types';

const getSharedInvitationResource = callApi(sharedInvitationResourceDecoder, {});

const processTemplateLink =
  (opaqueUseCaseIdentifier: string) =>
  (link: Link): Link => ({
    ...link,
    href: link.href.replace('{uuid}', opaqueUseCaseIdentifier),
  });

const errorResourceDecoderErrorMsg =
  'Error occurred when decoding shared invitation resource error details';

const handleSharedInvitationLinkInvalid = (
  store: SharedInvitationResourceStore,
  error: BadStatus,
): void => {
  sharedInvitationErrorResourceDecoder
    .decodeJson(error.response.body)
    .elseDo(warnAndNotifyDecoderError(errorResourceDecoderErrorMsg))
    .mapError(always(errorResourceDecoderErrorMsg))
    .cata({
      Ok: ({ payload }) => store.sharedInvitationLinkInvalid(payload.error),
      Err: (err) => {
        warn(`Failed to open shared invitation link: ${err}`);
        store.error('Failed to open shared invitation link');
      },
    });
};

const handleBadStatus = (store: SharedInvitationResourceStore, error: BadStatus): void => {
  switch (error.response.status) {
    case 410:
      handleSharedInvitationLinkInvalid(store, error);
      break;
    case 404:
      store.sharedInvitationLinkInvalid(
        notYetTranslatedText("We're sorry, but the URL you are trying to reach is invalid."),
      );
      break;
    default:
      handleError(store, error);
  }
};

const handleLoadingError = (store: SharedInvitationResourceStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    case 'missing-link-error':
      store.error('Registration link is unavailable');
      break;
    case 'bad-payload':
    case 'bad-url':
    case 'missing-application-id':
    case 'missing-api-compatibility':
    case 'network-error':
    case 'timeout':
      handleError(store, error);
      break;
    default:
      assertNever(error);
  }
};

interface Props extends RCProps<SharedInvitationResourceStore> {}

class SharedInvitationResourceReactions extends ReactionComponent<
  SharedInvitationResourceStore,
  State,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'loading':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkT('shared-invitation-template'))
          .map(processTemplateLink(state.opaqueUseCaseIdentifier))
          .andThen(getSharedInvitationResource)
          .fork(handleLoadingError(store), store.ready);
        break;
      case 'ready':
        changeLocaleT(state.resource.payload.locale)
          .elseDo(() => {
            // Only trigger SSO when not changing the locale. Otherwise, two
            // SSO tabs will open (one before & one after refreshing the page).
            store.instantSso.do(store.invitationStore.instantSso);
          })
          .fork((err) => {
            switch (err.kind) {
              case 'locale-change-skipped':
                break;
              case 'i18next-interaction-error':
              case 'could-not-reload-error':
                warn(err);
                break;
              default:
                assertNever(err);
            }
          }, noop);
        break;
      case 'waiting':
      case 'shared-invitation-link-invalid':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(SharedInvitationResourceReactions);
