import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const MenuDotsIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 24 24" width="24" height="24">
      <path
        stroke="#001A72"
        d="M12 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM12 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      />
    </SVGIcon>
  );
};

export default observer(MenuDotsIcon);
