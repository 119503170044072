import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, CompletedProgram, Program, whenActive } from '../../../ProgramStore/Types';
import ChapterOutline from './ChapterOutline';
import ModuleOutline from './ModuleOutline';

interface Props {
  programDetail: Program;
}

const ModuleOrChapterOutline: React.FC<{
  programDetail: ActiveProgram | CompletedProgram;
}> = ({ programDetail }) => {
  switch (programDetail.programFormat) {
    case 'modules':
      return <ModuleOutline programDetail={programDetail} />;
    case 'chapters':
      return <ChapterOutline programDetail={programDetail} />;
  }
};

const ProgramContent: React.FC<Props> = ({ programDetail }) => {
  return whenActive(programDetail)
    .map((activeProgramDetail) => <ModuleOrChapterOutline programDetail={activeProgramDetail} />)
    .getOrElseValue(<div />);
};

export default observer(ProgramContent);
