import { emptyFragment } from '@execonline-inc/execonline-ui';
import { TranslationsContext } from '@execonline-inc/translations';
import FocusTrap from 'focus-trap-react';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { translation } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  closeM: Maybe<() => void>;
  title: React.ReactNode;
  children: React.ReactNode;
}

class ModalWrapping extends React.Component<Props> {
  portal: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.portal = document.createElement('div');
    this.portal.id = `${this.props.title} portal`;
    const root = document.getElementById('root');
    if (root) {
      root.appendChild(this.portal);
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (root && this.portal) {
      root.removeChild(this.portal);
    }
  }

  render() {
    const { closeM, title, children } = this.props;
    return ReactDOM.createPortal(
      <TranslationsContext.Consumer>
        {(ts) => (
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              fallbackFocus: '#auto-launchable-modal-container',
              allowOutsideClick: true,
            }}
          >
            <div
              className={style.modal}
              data-test-modal={true}
              aria-modal={true}
              id="auto-launchable-modal-container"
              tabIndex={-1}
            >
              <div className={style.body} data-test-modal-body={true}>
                {closeM
                  .map((close) => (
                    <button
                      className={style.closeButton}
                      onClick={close}
                      data-test-close-button={true}
                      aria-label={translation('Close')(ts)}
                      role="button"
                      autoFocus
                    >
                      &#x2715;
                    </button>
                  ))
                  .getOrElse(emptyFragment)}
                <h1 className={style.heading} data-test-modal-title={true}>
                  {title}
                </h1>
                {children}
              </div>
              <div className={style.shade} data-test-modal-shade={true} />
            </div>
          </FocusTrap>
        )}
      </TranslationsContext.Consumer>,
      this.portal,
    );
  }
}

export default ModalWrapping;
