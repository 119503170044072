import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { programsStore } from '../ProgramsStore';
import { findProgram, whenActiveProgram } from '../ProgramsStore/Functions';
import { ProgramResource, RegistrationType } from '../ProgramsStore/Types';
import { Link } from '../Resource/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../Translations';
import {
  ActiveProgramModule,
  cancellingRegistration,
  CoachingProductDetails,
  courseNotReady,
  currentModule,
  currentSegment,
  loading,
  modulesCompletedCount,
  modulesRemainingCount,
  NavigationType,
  Program,
  ProgramDetailResource,
  programEndOnDate,
  ProgramModule,
  programModules,
  ProgramSegment,
  Progress,
  progress,
  ready,
  registrationCancelled,
  reloading,
  remainingDays,
  sfProgramUid,
  State,
  waiting,
} from './Types';

class ProgramStore {
  @observable
  state: State;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = (programResource: ProgramResource) => {
    this.state = loading(programResource);
  };

  @action
  ready = (programDetailResource: ProgramDetailResource) => {
    this.state = ready(programDetailResource);
  };

  @action
  cancellingRegistration = (cancelLink: Link): void => {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        this.state = cancellingRegistration(cancelLink);
        break;
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  registrationCancelled = (): void => {
    switch (this.state.kind) {
      case 'cancelling-registration':
        this.state = registrationCancelled();
        break;
      case 'reloading':
      case 'ready':
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'registration-cancelled':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  reloading = (programDetailResource: ProgramDetailResource) => {
    this.state = reloading(programDetailResource);
  };

  @action
  courseNotReady = () => {
    this.state = courseNotReady();
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get programDetailResource(): Maybe<ProgramDetailResource> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready': {
        return just(this.state.programDetailResource);
      }
      case 'error':
      case 'course-not-ready':
      case 'loading':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'waiting': {
        return nothing();
      }
    }
  }

  @computed
  get programLinks(): Array<Link> {
    switch (this.state.kind) {
      case 'loading': {
        return this.state.programResource.links.slice();
      }
      case 'reloading':
      case 'ready':
        return this.state.programDetailResource.links.slice();
      case 'error':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'waiting': {
        return [];
      }
    }
  }

  @computed
  get productDetailsLinks(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'loading':
        return [];
      case 'ready':
      case 'reloading':
        return this.state.programDetailResource.payload.productDetails.links;
      case 'error':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'waiting': {
        return [];
      }
    }
  }

  @computed
  get title(): Maybe<AlreadyTranslatedText> {
    switch (this.state.kind) {
      case 'ready':
      case 'reloading':
        return just(this.state.programDetailResource.payload.title);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get registrationType(): Maybe<RegistrationType> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready': {
        return programsStore.resource
          .map(({ payload }) => payload.programs)
          .andThen(findProgram(this.state.programDetailResource.payload.id))
          .map((programDetailsResource) => programDetailsResource.payload)
          .map((program) => whenActiveProgram(program).map((p) => p.registrationType))
          .getOrElseValue(nothing());
      }
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get programNavigationType(): Maybe<NavigationType> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.programDetailResource.payload.navigation);
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'error':
      case 'cancelling-registration':
      case 'course-not-ready':
      case 'registration-cancelled':
        return nothing();
    }
  }

  @computed
  get sfProgramUid(): Maybe<string> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return sfProgramUid(this.state.programDetailResource);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get modulesCompletedCount(): Maybe<number> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return modulesCompletedCount(this.state.programDetailResource, this.currentModule);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get modulesRemainingCount(): Maybe<number> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return modulesRemainingCount(
          this.state.programDetailResource.payload,
          this.state.programDetailResource,
          this.currentModule,
        );
      case 'loading':
      case 'course-not-ready':
      case 'waiting':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get currentModuleEndsOn(): Maybe<Date> {
    return this.currentModule.andThen((cm) => cm.endsOn);
  }

  @computed
  get currentModule(): Maybe<ActiveProgramModule> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return currentModule(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get percentComplete(): Maybe<number> {
    return this.progress.map(({ completedSegments, totalSegments }) =>
      totalSegments === 0 ? 0 : (100 * completedSegments) / totalSegments,
    );
  }

  @computed
  get progress(): Maybe<Progress> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return progress(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get remainingDays(): Maybe<number> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return remainingDays(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get programEndsOn(): Maybe<Date> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return programEndOnDate(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get whenRegistration(): Maybe<ProgramStore> {
    return this.progress.andThen((p) => {
      switch (p.kind) {
        case 'program-progress':
        case 'module-progress':
          return nothing();
        case 'registration-segment-progress':
          return just(this);
      }
    });
  }

  @computed
  get currentSegment(): Maybe<ProgramSegment> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return currentSegment(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get programModules(): Maybe<ProgramModule[]> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return programModules(this.state.programDetailResource.payload);
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return just([]);
    }
  }

  @computed
  get directRegistrationGuid(): Maybe<string> {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return this.state.programDetailResource.payload.directRegistrationGuid;
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return nothing();
    }
  }

  @computed
  get experienceOrigin(): Program['experienceOrigin'] {
    switch (this.state.kind) {
      case 'reloading':
      case 'ready':
        return this.state.programDetailResource.payload.experienceOrigin;
      case 'loading':
      case 'waiting':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'error':
        return 'unavailable';
    }
  }

  @computed
  get coachingProductDetails(): Maybe<CoachingProductDetails> {
    return this.programDetailResource.andThen((programDetailResource) => {
      switch (programDetailResource.payload.productDetails.payload.kind) {
        case 'coaching':
          return just(programDetailResource.payload.productDetails.payload);
        case 'program':
        case 'program-sequence':
        case 'group-coaching':
          return nothing();
      }
    });
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'reloading':
      case 'ready':
      case 'loading':
      case 'course-not-ready':
      case 'cancelling-registration':
      case 'registration-cancelled':
      case 'waiting':
        return nothing();
    }
  }
}

export default ProgramStore;
