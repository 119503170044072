import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import { UseCaseErrorMessage } from './Types';

interface Props {
  message: UseCaseErrorMessage;
}

const TranslatedMessaging: React.FC<Props> = ({ message }) => {
  switch (message.kind) {
    case 'already-translated-text':
      return <>{message.text}</>;
    case 'not-yet-translated-text':
      return <T kind={message.translationKey} />;
  }
};

export default observer(TranslatedMessaging);
