import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import { ChatablePersonResourceList } from '../../ConversationStore/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import CoachChatImpl from './CoachChatImpl';

interface Props {
  conversationStore: ConversationStore;
  chatablePersonResourceList: ChatablePersonResourceList;
}

const CoachChatState: React.FC<Props> = ({ conversationStore, chatablePersonResourceList }) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (conversationStore.state.kind) {
        case 'ready':
        case 'loading-next':
        case 'load-next-success':
        case 'loading-previous':
        case 'waiting':
        case 'loading':
        case 'load-new-message':
        case 'load-new-reply':
        case 'update-message':
        case 'update-reply':
        case 'reloading':
        case 'first-loaded':
          return (
            <CoachChatImpl
              conversationStore={conversationStore}
              chatablePersonResourceList={chatablePersonResourceList}
              currentUserResource={currentUserResource}
            />
          );
        case 'error':
          return <span />;
      }
    }}
  />
);

export default observer(CoachChatState);
