import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { field, oneOf, string, succeed } from 'jsonous';
import { assertionBackendResourceDecoder } from '../../../../Login/Decoders';
import { resourceDecoder } from '../../../../Resource/Decoders';
import {
  InvalidUser,
  NewUser,
  OutsideOrgUser,
  PendingUser,
  User,
  UserResource,
  ValidUser,
} from './Types';
import { alreadyTranslatedText } from '@execonline-inc/translations';

const pendingUserDecoder: Decoder<PendingUser> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('pending-user'))
);

const invalidUserDecoder: Decoder<InvalidUser> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('invalid-user'))
);

const outsideOrgUserDecoder: Decoder<OutsideOrgUser> = succeed({})
  .assign('kind', field('kind', stringLiteral('outside-org-user')))
  .assign('errorMessage', field('error_message', explicitMaybe(alreadyTranslatedText)));

const validUserDecoder: Decoder<ValidUser> = succeed({})
  .assign('kind', field('kind', stringLiteral('valid-user')))
  .assign('uuid', field('targeted_invitation_uuid', string))
  .assign('experienceId', field('experience_id', explicitMaybe(string)));

const newUserDecoder: Decoder<NewUser> = succeed({})
  .assign('kind', field('kind', stringLiteral('new-user')))
  .assign('assertionBackend', field('assertion_backend', assertionBackendResourceDecoder))
  .assign('username', field('username', string));

const userDecoder: Decoder<User> = oneOf<User>([
  pendingUserDecoder.map<User>(identity),
  invalidUserDecoder.map<User>(identity),
  validUserDecoder.map<User>(identity),
  newUserDecoder.map<User>(identity),
  outsideOrgUserDecoder.map<User>(identity),
]);

export const userResourceDecoder: Decoder<UserResource> = resourceDecoder(userDecoder);
