import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import * as style from '../style.module.css';

const NoSupportLink: React.FC = () => {
  return (
    <p className="text-black">
      <T
        kind="If you need additional help, you can ..."
        emailAddress={
          <a className={style.mailToLinkLightTheme} href="mailto:support@execonline.com">
            support@execonline.com
          </a>
        }
      />
    </p>
  );
};

export default observer(NoSupportLink);
