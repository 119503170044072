import { observer } from 'mobx-react';
import * as React from 'react';
import { FlashAlert } from '../../Notifications/Types';
import { AlreadyTranslated, NotTranslated, T, whenTPlainTextKey } from '../../Translations';
import FlashError from './FlashError';
import FlashInfo from './FlashInfo';
import FlashServerError from './FlashServerError';
import FlashSuccess from './FlashSuccess';
import FlashWarning from './FlashWarning';

export interface Props {
  alert: FlashAlert;
}

const Message: React.FC<Props> = ({ alert }) => {
  switch (alert.kind) {
    case 'warning-alert':
      return (
        <FlashWarning>
          <T kind={alert.message} />
        </FlashWarning>
      );
    case 'success-alert':
      return (
        <FlashSuccess>
          {whenTPlainTextKey(alert.message)
            .map((key) => <T kind={key} />)
            .getOrElse(() => (
              <NotTranslated text={alert.message} />
            ))}
        </FlashSuccess>
      );
    case 'error-alert':
      return (
        <FlashError>
          {whenTPlainTextKey(alert.error.message)
            .map((key) => <T kind={key} />)
            .getOrElse(() => (
              <NotTranslated text={alert.error.message} />
            ))}
        </FlashError>
      );
    case 'server-error-alert':
      return (
        <FlashServerError>
          <AlreadyTranslated content={alert.serverError.message} />
        </FlashServerError>
      );
    case 'payment-loading-error-alert':
      return (
        <FlashServerError>
          <NotTranslated text={alert.paymentLoadingError.message} />
        </FlashServerError>
      );
    case 'info-alert':
      return (
        <FlashInfo>
          <T kind={alert.message} />
        </FlashInfo>
      );
  }
};

export default observer(Message);
