import { mapMaybe } from '@execonline-inc/collections';
import { asTask, runResult } from '@execonline-inc/error-handling';
import { always, identity, noop, pipe } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import {
  WindowInteractionError,
  WindowPropertyError,
  windowInteractionError,
  windowPropertyError,
} from './Errors';
import { EventType, WindowOpenOptions } from './Types';

export const addEventListener = (
  eventType: EventType,
  callback: () => void,
): Task<WindowInteractionError, void> =>
  new Task<WindowInteractionError, void>((reject, resolve) => {
    try {
      window.addEventListener(eventType, callback);
      resolve();
    } catch (e) {
      reject(windowInteractionError('add-event-listener', e));
    }
    return noop;
  });

export const removeEventListener = (
  eventType: EventType,
  callback: () => void,
): Task<WindowInteractionError, void> =>
  new Task<WindowInteractionError, void>((reject, resolve) => {
    try {
      window.removeEventListener(eventType, callback);
      resolve();
    } catch (e) {
      reject(windowInteractionError('remove-event-listener', e));
    }
    return noop;
  });

const openOptionsToFeaturesList = (opts: WindowOpenOptions): string =>
  mapMaybe(identity, [
    fromNullable(opts.noopener).map(always('noopener')),
    fromNullable(opts.noreferrer).map(always('noreferrer')),
  ]).join(',');

export const open = (
  url: string,
  windowName: string,
  opts: WindowOpenOptions,
): Task<WindowInteractionError, Window> =>
  new Task<WindowInteractionError, Window>((reject, resolve) => {
    try {
      const w = window.open(url, windowName, openOptionsToFeaturesList(opts));
      w ? resolve(w) : reject(windowInteractionError('open', 'Window could not be opened'));
    } catch (e) {
      reject(windowInteractionError('open', e));
    }
    return noop;
  });

export const locationReplace = (url: string): Task<WindowInteractionError, void> =>
  new Task((reject, resolve) => {
    try {
      window.location.replace(url);
      resolve();
    } catch (e) {
      reject(windowInteractionError('location-replace', e));
    }
    return noop;
  });

export const locationRedirect = (url: string): Task<WindowInteractionError, void> =>
  new Task((reject, resolve) => {
    try {
      window.location.href = url;
      resolve();
    } catch (e) {
      reject(windowInteractionError('location-redirect', e));
    }
    return noop;
  });

export const locationReload = (): Task<WindowInteractionError, void> =>
  new Task((reject, resolve) => {
    try {
      window.location.reload();
      resolve();
    } catch (e) {
      reject(windowInteractionError('location-reload', e));
    }
    return noop;
  });

export const read = <P extends keyof Window>(property: P): Result<WindowPropertyError, Window[P]> =>
  runResult(() => window[property]).mapError((error) => windowPropertyError({ property, error }));

export const readT = pipe(read, asTask);
