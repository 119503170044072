import { observer } from 'mobx-react';
import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  store: ConjointAnalysisStore;
}

const SubmissionButton: React.FC<Props> = ({ store }) => {
  const handleSubmission = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    store.updating();
  };
  switch (store.state.kind) {
    case 'ready':
    case 'ready-with-errors':
      return store.validForm
        .map((_) => (
          <button onClick={handleSubmission} className={style.submissionButton} type="button">
            <T kind="SUBMIT AND CONTINUE" />
          </button>
        ))
        .getOrElse(() => (
          <button
            disabled
            onClick={handleSubmission}
            className={style.submissionButton}
            type="button"
          >
            <T kind="SUBMIT AND CONTINUE" />
          </button>
        ));
    case 'waiting':
    case 'loading':
    case 'error':
    case 'updating':
      return (
        <button
          disabled
          onClick={handleSubmission}
          className={style.submissionButton}
          type="button"
        >
          <T kind="SUBMIT AND CONTINUE" />
        </button>
      );
  }
};

export default observer(SubmissionButton);
