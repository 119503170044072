import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const ResourcesTabIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 13">
      <path
        fill="#000000"
        d="M2.136 0A1.64 1.64 0 0 0 .5 1.625v7.583c0 .891.74 1.625 1.636 1.625h2.182v.542c0 .89.74 1.625 1.637 1.625H12.5V2.167H8.682V0H2.136Zm0 1.083h5.455v1.084H5.955c-.48 0-.91.211-1.21.541H2.681v1.084h1.636v3.791H2.136c-.191 0-.373.04-.545.102v-6.06c0-.307.236-.542.545-.542ZM5.955 3.25h5.454v6.5H5.955c-.192 0-.373.04-.546.102v-6.06c0-.307.237-.542.546-.542ZM6.5 4.875v1.083h3.818V4.875H6.5ZM2.136 8.667h2.182V9.75H2.136a.534.534 0 0 1-.545-.542c0-.306.236-.541.545-.541Zm3.819 2.166h5.454v1.084H5.955a.534.534 0 0 1-.546-.542c0-.307.237-.542.546-.542Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourcesTabIcon);
