import { assertNever } from '@kofno/piper';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SimpleFileUploadStore from '../../../../SimpleFileUploadStore';
import Loader from '../../../Loader';

interface Props {
  store: SimpleFileUploadStore;
}

const UploadsInProgress: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'upload-in-progress':
      return (
        <div data-test-uploads-in-progress={true}>
          <Loader color="black" delay={0} text={just('uploading file')} type="spin" />
        </div>
      );
    case 'error':
    case 'loading':
    case 'ready-without-upload':
    case 'upload-successful':
    case 'waiting':
    case 'ready-with-errors':
    case 'ready':
      return <></>;
    default:
      return assertNever(store.state);
  }
};
export default observer(UploadsInProgress);
