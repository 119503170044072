import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../../ChannelAvailabilityStore';
import EventsBinding from './EventsBinding';
import EventsStore from '../../../EventsStore';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
  eventsStore: EventsStore;
}

const EventsHandler: React.FC<Props> = ({ eventsStore, channelAvailabilityStore }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <EventsBinding channel={channel} store={eventsStore} />;
    }
    case 'not-available': {
      return <span />;
    }
  }
};

export default observer(EventsHandler);
