import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { callApi } from '../Appy';
import { Errored } from '../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import { findLinkT } from '../LinkyLinky';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { Link } from '../Resource/Types';
import { organizationResourceDecoder } from './Decoders';
import { State } from './States';
import { OrganizationStore } from './Store';
import { getTheme, setTheme } from '../Themes';
import { just } from 'maybeasy';

interface Props extends RCProps<OrganizationStore> {}

export class OrganizationReactions extends ReactionComponent<OrganizationStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
      case 'refreshing':
        Task.succeed<Errored['error'], ReadonlyArray<Link>>(state.currentUserResource.links)
          .andThen(findLinkT('organization'))
          .andThen(callApi(organizationResourceDecoder, {}))
          .fork(store.errored, store.ready);
        break;
      case 'ready':
        setTheme(getTheme(just(state.resource.payload.id))).fork(warn, noop);
        break;
      case 'errored':
        warn('Organization resource failed to load.', toJS(state.error));
        break;
      default:
        assertNever(state);
    }
  };
}
