import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import { ClockThreeIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';

interface Props {
  duration: Maybe<number>;
  hoursPerWeek: Maybe<number>;
}

function CourseAvailabilityPerWeek({ duration, hoursPerWeek }: Props) {
  return (
    <>
      {duration
        .map((week) => {
          return (
            <div className="flex gap-x-1">
              <div className="flex items-center gap-2.5">
                <ClockThreeIcon color="black" size="md" />
                <Typography className="text-gray-900" variant="bodyMedium">
                  <T kind="{{count}} week" count={week} />
                </Typography>
              </div>
            </div>
          );
        })
        .getOrElse(() => (
          <>
            {hoursPerWeek
              .map((hoursPerWeek) => (
                <Typography className="text-gray-900" variant="bodyMedium">
                  <T kind="at {{count}} hour per week" count={hoursPerWeek} />
                </Typography>
              ))
              .getOrElse(emptyFragment)}
          </>
        ))}
    </>
  );
}

export default observer(CourseAvailabilityPerWeek);
