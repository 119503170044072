import * as React from 'react';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import RenderContext from '../../RenderContext';
import LearningPartnersRendererImpl from './LearningPartnersRendererImpl';

interface Props {
  programDetailResource: ProgramDetailResource;
}

const LearningPartnersRenderer: React.FC<Props> = ({ programDetailResource }) => (
  <RenderContext
    render={(renderContext) => (
      <LearningPartnersRendererImpl
        programDetailResource={programDetailResource}
        position={renderContext.position}
      />
    )}
  />
);

export default LearningPartnersRenderer;
