import { observer } from 'mobx-react';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import ToastReactions from '../../ToastsStore/ToastReactions';
import EventToast from './EventToast';

interface Props {
  store: ScheduledToastStore;
}

const EventNotificationToast: React.FC<Props> = ({ store }) => {
  return (
    <>
      <EventToast store={store} />
      <ToastReactions store={store} fireImmediately={true} />
    </>
  );
};

export default observer(EventNotificationToast);
