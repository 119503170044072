import { warn } from '@execonline-inc/logging';
import { MissingVarError } from '@execonline-inc/environment';
import { assertNever } from '@kofno/piper';

interface OneTrustLoadError {
  kind: 'one-trust-load-error';
  key: string;
}

export const oneTrustLoadError = (key: string): OneTrustLoadError => ({
  kind: 'one-trust-load-error',
  key,
});

export const handleOneTrustLoadError = (err: OneTrustError): void => {
  switch (err.kind) {
    case 'missing-var-error':
      warn(
        `Unable to load One Trust cookie consent module: Missing environment variable ${err.key}`
      );
      break;
    case 'one-trust-load-error':
      warn('One Trust load error', err.key, err);
      break;
    default:
      assertNever(err);
  }
};

export type OneTrustError = OneTrustLoadError | MissingVarError;
