import { Channel } from 'pusher-js';
export type ChannelAvailability = Available | NotAvailable;

export interface Available {
  kind: 'available';
  channel: Channel;
}

export interface NotAvailable {
  kind: 'not-available';
}

export const available = (channel: Channel): Available => ({ kind: 'available', channel });
export const notAvailable = (): NotAvailable => ({ kind: 'not-available' });
