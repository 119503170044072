import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import ConjointAnalysisStore, { ConjointAnalysisState } from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link, Resource } from '../Resource/Types';
import SegmentStore from '../SegmentStore';
import { AnyAdvancer } from '../SegmentStore/Types';
import {
  conjointAnalysisResourceDecoder,
  conjointAnalysisResourceDecoderWithErrors,
} from '../components/ConjointAnalysis/Decoders';
import {
  Conjoint,
  ConjointAnalysisNode,
  ConjointAnalysisResource,
  SerializedConjointForm,
  Updating,
} from '../components/ConjointAnalysis/Types';

interface Props {
  store: ConjointAnalysisStore;
  node: ConjointAnalysisNode;
  segmentStore: Maybe<SegmentStore>;
}

interface InvalidForm {
  kind: 'invalid-form';
  message: string;
}

const invalidForm = (): InvalidForm => ({
  kind: 'invalid-form',
  message: `Form wasn't completed and is not ready for submission`,
});

type SubmissionError = MissingLinkError | AppyError | InvalidForm;

const handleMissingLink = (store: ConjointAnalysisStore, error: MissingLinkError) => {
  switch (error.rel) {
    case 'create':
      store.error(`You do not have access to your conjoint analysis at this time.`);
      break;
    default:
      store.error('You cannot perform this operation at this time.');
      break;
  }
};

const handleSubmissionError =
  (store: ConjointAnalysisStore, state: Updating) => (error: SubmissionError) => {
    switch (error.kind) {
      case 'bad-status':
      case 'bad-payload':
        conjointAnalysisResourceDecoderWithErrors.decodeJson(error.response.body).cata({
          Ok: store.readyWithErrors,
          Err: noop,
        });
        break;
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        handleError(store, error);
        break;
      case 'missing-link-error':
        handleMissingLink(store, error);
        break;
      case 'invalid-form':
        store.ready(state.description, state.productAttributes, state.customerCharacteristics);
        break;
      default:
        assertNever(error);
    }
  };

const handleSegmentTransition = (advancer: AnyAdvancer) => {
  switch (advancer.kind) {
    case 'advancer':
      return advancer.advance();
    case 'complete-and-advancer':
      return advancer.completeAndAdvance();
    case 'disabled-advancer':
    case 'no-advancer':
      return;
  }
};

const successfulUpdate =
  (store: ConjointAnalysisStore, segmentStore: Maybe<SegmentStore>) =>
  (resource: ConjointAnalysisResource) => {
    segmentStore.do((s) => handleSegmentTransition(s.segmentAdvancer));
  };

const sendRequest =
  (links: ReadonlyArray<Link>) => (fn: (link: Link) => Task<SubmissionError, Resource<Conjoint>>) =>
    Task.succeed<SubmissionError, ReadonlyArray<Link>>(links)
      .andThen(findLinkT('create'))
      .andThen(fn);

class ConjointAnalysisStoreReactions extends ErrorActionableReaction<
  ConjointAnalysisStore,
  ConjointAnalysisState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ConjointAnalysisState) => {
    const { store, node, segmentStore } = this.props;
    switch (state.kind) {
      case 'waiting':
      case 'loading':
        break;
      case 'updating':
        toTask<SubmissionError, SerializedConjointForm>(invalidForm(), store.validForm)
          .map((formData) => callApi(conjointAnalysisResourceDecoder, formData))
          .andThen(sendRequest(node.resource.links))
          .fork(handleSubmissionError(store, state), successfulUpdate(store, segmentStore));
        break;
      case 'ready':
      case 'ready-with-errors':
      case 'error':
        break;
    }
  };
}

export default observer(ConjointAnalysisStoreReactions);
