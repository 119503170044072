import { readVarM } from '@execonline-inc/environment';
import { toResult } from '@execonline-inc/maybe-adapter';
import { noop, pipe } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { missingValue } from '../../../Analytics/Types';
import { errorMessage } from '../../../ExceptionHandling';
import {
  AnalyticsEvent,
  DataLayer,
  GtmDataLayer,
  GtmLoadError,
  gtmLoadError,
  handleProgramUpdateError,
} from './Types';

declare global {
  export interface Window {
    dataLayer?: DataLayer[];
    ga?: (eventType: string, fieldsObject: AnalyticsEvent) => void;
  }
}

const setDefaultConsent = (): HTMLScriptElement => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('consent', 'default', {'ad_storage': 'denied', 'analytics_storage': 'denied', 'functionality_storage': 'denied', 'personalization_storage': 'denied' })`;
  return script;
};

const iframe = (
  token: string,
) => `<iframe title="Tagging" src="//www.googletagmanager.com/ns.html?id=${token}" height="0" width="0" style="display:none;visibility:hidden">
    </iframe>`;

const gtmScript = (token: string) =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${token}')`;

const dataScript = (dataLayer: string) => {
  const script = document.createElement('script');
  script.innerHTML = dataLayer;
  return script;
};

export const loadGoogleTagManager = (
  token: string,
  newToken: string,
): Task<GtmLoadError, GtmDataLayer> => {
  return new Task<GtmLoadError, GtmDataLayer>((reject, resolve) => {
    const rejectException = pipe(errorMessage, gtmLoadError, reject);
    try {
      if (window.dataLayer) {
        resolve({ dataLayer: window.dataLayer });
      } else {
        const noScript = () => {
          const noscript = document.createElement('noscript');
          noscript.innerHTML = iframe(token);
          return noscript;
        };

        const script = () => {
          const script = document.createElement('script');
          script.innerHTML = gtmScript(token);
          return script;
        };

        const newNoScript = () => {
          const noscript = document.createElement('noscript');
          noscript.innerHTML = iframe(newToken);
          return noscript;
        };

        const newScript = () => {
          const script = document.createElement('script');
          script.innerHTML = gtmScript(newToken);
          return script;
        };

        if (document.body) {
          document.body.appendChild(setDefaultConsent());
          document.body.appendChild(noScript());
          document.body.appendChild(script());
          document.body.appendChild(newNoScript());
          document.body.appendChild(newScript());
          if (window.dataLayer) {
            resolve({ dataLayer: window.dataLayer });
          } else {
            reject(gtmLoadError('window.dataLayer not loaded by script'));
          }
        }
      }
    } catch (e) {
      rejectException(e);
    }

    return noop;
  });
};

export const dataLayerUpdate = (dataLayer: DataLayer) => {
  setTimeout(() => {
    const addData = `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(${JSON.stringify(dataLayer)})`;
    const data = dataScript(addData);
    if (document.body) {
      document.body.appendChild(data);
    }
  });
};

export const dataLayer = (dataLayer: DataLayer) => {
  const addData = `
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(function() {
      this.reset();
    });
    window.dataLayer.push(${JSON.stringify(dataLayer)})`;
  const data = dataScript(addData);
  if (document.body) {
    document.body.appendChild(data);
  }
};

export const updateGtmProgramFields = (
  title: string,
  sfProgramUid: Maybe<string>,
  registrationType: Maybe<string>,
): void => {
  toResult(
    missingValue('sfProgramUid'),
    sfProgramUid.map((sfProgramUid) => ({ sfProgramUid })),
  )
    .assign('registrationType', toResult(missingValue('registrationType'), registrationType))
    .assign('token', toResult(missingValue('token'), readVarM('REACT_APP_GTM_KEY')))
    .assign('newToken', toResult(missingValue('newToken'), readVarM('REACT_APP_NEW_GTM_KEY')))
    .map(({ sfProgramUid, registrationType, token }) => {
      dataLayerUpdate({
        course: `${title}: ${sfProgramUid}`,
        userType: registrationType,
      });
    })
    .mapError(handleProgramUpdateError(title));
};

export const triggerAnalyticsEvent = (
  hitType: string,
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
) => {
  if (window.ga) {
    window.ga('send', {
      hitType,
      eventCategory,
      eventAction,
      eventLabel,
    });
  }
};
