import { ScrollBox } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';

interface Props<T> {
  items: ReadonlyArray<T>;
  children: (props: { items: ReadonlyArray<T> }) => ReactNode;
}

function MobileExperienceList<T>({ items, children }: Props<T>) {
  return (
    <ScrollBox
      className="-mb-4 flex flex-shrink-0 snap-x snap-proximity gap-x-8 pb-4"
      orientation="horizontal"
    >
      {children({ items })}
    </ScrollBox>
  );
}
export default observer(MobileExperienceList);
