import { Error } from '../ErrorHandling';
import { Link } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  link: Link;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (link: Link): Ready => ({
  kind: 'ready',
  link,
});

export type ViewableAttachmentState = Waiting | Loading | Ready | Error;
