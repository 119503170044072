import { warn } from '@execonline-inc/logging';
import {
  linksDecoder as linksDecoderR,
  resourceDecoder as resourceDecoderR,
  ResourceWithErrors,
  resourceWithErrorsDecoder as resourceWithErrorsDecoderR,
} from '@execonline-inc/resource';
import Decoder from 'jsonous';
import { warnAndNotify } from '../../Honeybadger';
import { Rel, Resource, toRel } from '../Types';

export const linksDecoder = linksDecoderR(toRel);

export const resourceDecoder: <T>(
  payloadDecoder: Decoder<T>
) => Decoder<Resource<T>> = resourceDecoderR(toRel);

export const resourceWithErrorsDecoder: <T>(
  payloadDecoder: Decoder<T>
) => Decoder<ResourceWithErrors<T, Rel>> = resourceWithErrorsDecoderR(toRel);

export { errorDecoder } from '@execonline-inc/resource';

export const warnAndNotifyDecoderError = (msg: string) => (error: unknown): void => {
  warn('DecoderError', error);
  warnAndNotify('DecoderError', msg, { error });
};
