import { log } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import Pusher from 'pusher-js';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import ChannelAvailabilityStore from '../ChannelAvailabilityStore';
import Store from '../Store';
import { SocketAvailability, SocketState } from '../Types';

interface Props extends RCProps<Store> {
  availabilityStore: ChannelAvailabilityStore;
  name: string;
}

const doAThingWithPusher = (
  fn: (pusher: Pusher) => void,
  availability: SocketAvailability
) => {
  switch (availability.kind) {
    case 'connected':
      fn(availability.pusher);
      break;
    case 'disconnected':
    case 'connecting':
    case 'initialized':
    case 'unavailable':
    case 'failed':
      break;
    default:
      assertNever(availability);
  }
};

class ChannelReactions extends ReactionComponent<Store, SocketState, Props> {
  tester = () => this.props.store.state;

  effect = (state: SocketState) => {
    const { name, availabilityStore } = this.props;
    switch (state.kind) {
      case 'ready':
        doAThingWithPusher((p) => {
          log('[CHANNEL]', `Subscribing to channel ${name}`);
          const channel = p.subscribe(name);
          availabilityStore.available(channel);
        }, state.socket);
        break;
      case 'unmounting':
      case 'error':
      case 'unmounted':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };

  componentWillUnmount() {
    super.componentWillUnmount();
    const { name, store } = this.props;
    switch (store.state.kind) {
      case 'ready':
        doAThingWithPusher((p) => {
          log('[CHANNEL]', `Unsubscribing from channel ${name}`);
          p.unsubscribe(name);
        }, store.state.socket);
        break;
      case 'unmounting':
      case 'error':
      case 'unmounted':
      case 'waiting':
        break;
      default:
        assertNever(store.state);
    }
  }
}

export default ChannelReactions;
