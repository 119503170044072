import { Button, cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import EditPasswordStore from '../../../EditPasswordStore';
import { PasswordField } from '../../../Native/Form';
import { T } from '../../../Translations';

interface Props {
  store: EditPasswordStore;
}

function Form({ store }: Props) {
  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'ready':
    case 'ready-with-client-error':
    case 'ready-with-server-error':
      return (
        <form data-testid="reset-password-form" className="mx-auto w-80" onSubmit={submit}>
          <div className="flex flex-col gap-y-8">
            <PasswordField
              data-testid="reset-password-form_password-text-field"
              id="password"
              name="password"
              autoFocus
              value={store.password.getOrElseValue('')}
              onChange={store.setPassword}
            />
            <PasswordField
              data-testid="reset-password-form_confirm-password-text-field"
              id="passwordConfirmation"
              name="passwordConfirmation"
              value={store.passwordConfirmation.getOrElseValue('')}
              label={<T kind="Confirm Password" />}
              onChange={store.setPasswordConfirmation}
            />
          </div>
          <div className="mt-7 flex flex-col justify-center gap-3 sm:flex-row">
            <Link
              data-testid="reset-password-form_sign-in-link"
              to={dashboardUrl}
              className={cn(
                'inline-flex cursor-pointer justify-center whitespace-nowrap rounded-sm px-4 py-2 text-center text-sm font-medium',
                'border border-solid border-gray-300 bg-transparent text-gray-600-old hover:border-gray-800 hover:bg-gray-50 hover:text-gray-600-old',
                'box-border w-full sm:w-auto',
              )}
              data-test-login-link={true}
            >
              <T kind="Sign In" />
            </Link>
            <Button
              data-testid="reset-password-form_set-new-password-button"
              className="w-full sm:w-auto"
              type="submit"
              color="green"
              radius="sm"
              data-test-action-reset-password={true}
            >
              <T kind="Set New Password" />
            </Button>
          </div>
        </form>
      );
    case 'loading':
    case 'waiting':
    case 'error':
      return emptyFragment();
  }
}

export default observer(Form);
