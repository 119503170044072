import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import { T } from '../../../../../Translations';
import RemainingDaysMessage from '../RemainingDaysMessage';
import FormattedDayCount from './FormattedDayCount';
import * as style from './style.module.css';

interface Props {
  programStore: ProgramStore;
}

const RemainingDaysIndicator: React.FC<Props> = ({ programStore }) =>
  programStore.remainingDays
    .map((remainingDays) => (
      <>
        <div className={style.daysIndicator}>
          <T
            kind="<dayCount/> <text>day remaining</text>"
            dayCount={<FormattedDayCount remainingDays={remainingDays} />}
            text={(translated) => <div className={style.daysIndicatorText}>{translated}</div>}
            count={remainingDays}
          />
        </div>
        <RemainingDaysMessage programStore={programStore} />
      </>
    ))
    .getOrElse(() => <></>);
export default observer(RemainingDaysIndicator);
