import { observer } from 'mobx-react';
import { Experience, GroupCoachingProductDetails } from '../../../Common/Experience/Types';
import { ExperienceTitle, ExperiencesLogo } from '../../Common';
import ExperienceActions from './ExperienceActions';
import ExperienceDescription from './ExperienceDescription';

interface Props {
  experience: Experience;
  productDetails: GroupCoachingProductDetails;
}

function LearnMoreGroupCoachingHero({ experience, productDetails }: Props) {
  const { title, description } = productDetails;

  return (
    <>
      <ExperiencesLogo experience={experience} />
      <ExperienceTitle className="text-white" title={title} />
      <ExperienceDescription description={description} />
      <ExperienceActions experience={experience} productDetails={productDetails} />
    </>
  );
}

export default observer(LearnMoreGroupCoachingHero);
