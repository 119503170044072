import { Error } from '../ErrorHandling';
import { TeamResource } from '../TeamsStore/Types';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  teamResource: TeamResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (teamResource: TeamResource): Ready => ({
  kind: 'ready',
  teamResource,
});

export type TeamState = Waiting | Loading | Ready | Error;
