import * as React from 'react';
import FreebusyStore from '../../FreebusyStore';
import { observer } from 'mobx-react';
import Loader from '../Loader';
import SegmentStore from '../../SegmentStore';
import { Maybe } from 'maybeasy';
import CoachingReadyButton from './CoachingReadyButton';
import CoachingSubmittedButton from './CoachingSubmittedButton';

interface Props {
  store: FreebusyStore;
  segmentStore: Maybe<SegmentStore>;
}

const CoachingSchedulingButton: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'waiting':
    case 'error':
      return <></>;
    case 'submitting':
      return <Loader />;
    case 'loading':
    case 'initializing':
    case 'initializing-store':
    case 'ready':
      return <CoachingReadyButton freebusyStore={store} />;
    case 'submitted':
      return <CoachingSubmittedButton eventDetails={store.state.eventDetails} />;
  }
};

export default observer(CoachingSchedulingButton);
