import { MenuItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useCaseLink } from '../../../../../../ClientRoutes';
import History from '../../../../../../History';
import { UseCaseItem } from '../../../../../Dropdown/Types';
import { NavigationMenuItemProps } from '../Types';
import UseCaseInfo from './UseCaseInfo';

function UseCaseMenuItem({ item }: NavigationMenuItemProps<UseCaseItem>) {
  const handleMenuItemClick = (item: UseCaseItem) => () => {
    History.push(useCaseLink(item));
  };

  return (
    <MenuItem className="group" onClick={handleMenuItemClick(item)}>
      <UseCaseInfo data={item.content} />
    </MenuItem>
  );
}

export default observer(UseCaseMenuItem);
