import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import EnrollmentStore from '../Enrollment/Store';
import ExperiencesStore from './Experiences/ExperiencesStore';
import { SkeletonListViewExperiences } from './Experiences/ListViewExperiences';
import { SkeletonProductCollections } from './Experiences/ProductCollections';
import ProductCollectionsAndExperiences from './Experiences/ProductCollectionsAndExperiences';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
}

function ExperienceSelectionView({ invitationUuid, enrollmentStore, experiencesStore }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return <SkeletonProductCollections />;
    case 'searching':
      return <SkeletonListViewExperiences />;
    case 'product-collections':
    case 'experiences':
    case 'product-collections-and-experiences':
      return (
        <ProductCollectionsAndExperiences
          invitationUuid={invitationUuid}
          enrollmentStore={enrollmentStore}
          experiencesStore={experiencesStore}
        />
      );
    case 'error':
      return (
        <div className="flex flex-grow flex-col items-center justify-center">
          <Typography>
            <T kind="Your request failed." />
          </Typography>
        </div>
      );
  }
}

export default observer(ExperienceSelectionView);
