import { warn } from '@execonline-inc/logging';
import { toTask } from '@execonline-inc/maybe-adapter';
import { InvalidUrlError, putQueryParam, toUrlT } from '@execonline-inc/url';
import { assertNever, noop } from '@kofno/piper';
import { Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import RegisterResourceStore from '.';
import { callApi } from '../../../../Appy';
import { LocaleChangeError, changeLocaleT } from '../../../../ChangeLocale';
import { handleError } from '../../../../ErrorActionableReaction';
import { findLinkT } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { rootResourceStore } from '../../../../RootResourceStore';
import { fromNullableT } from '../../../../TaskExt';
import { registerResourceDecoder } from './Decoders';
import { InvalidParamsError, LoadingError, RegisterResource, State } from './Types';

const getRegisterResource = callApi(registerResourceDecoder, {});
const getPreviewResource = callApi(registerResourceDecoder, {});

const processTemplateLinkWithQuery =
  (guid: string, link: Link) =>
  (dp: string): Task<InvalidUrlError, Link> =>
    toUrlT(link.href)
      .map(putQueryParam('dp', dp))
      .map((url): Link => ({ ...link, href: url.href.replace('{guid}', guid) }));

const processTemplateLink = (guid: string, link: Link): Task<InvalidUrlError, Link> =>
  toUrlT(link.href).map((url) => ({
    ...link,
    href: url.href.replace('{guid}', guid),
  }));

const processPreviewLink =
  (id: string) =>
  (link: Link): Link => ({
    ...link,
    href: link.href.replace('{id}', id),
  });

const handleLoadingError = (store: RegisterResourceStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
    case 'invalid-url-error':
    case 'invalid-params-error':
      store.error('Registration link is unavailable');
      break;
    default:
      handleError(store, error);
  }
};

const handleLoadingPreviewError = (store: RegisterResourceStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
    case 'invalid-url-error':
    case 'invalid-params-error':
      store.error('Unable to load the learning collection resource.');
      break;
    default:
      handleError(store, error);
  }
};

const handleReady = (payload: RegisterResource['payload']): void => {
  switch (payload.auth.kind) {
    case 'sso-auth':
      payload.auth.store.ready(payload.auth.termsAccepted, payload.emailAddress);
      break;
    case 'locked-access-auth':
      payload.auth.store.ready();
      break;
    case 'password-auth':
      switch (payload.auth.passwordState.kind) {
        case 'reset-password':
          payload.auth.passwordState.store.ready(payload.auth.termsAccepted, nothing());
          break;
        case 'password-set':
          payload.auth.passwordState.store.ready(
            payload.emailAddress,
            payload.auth.termsAccepted,
            nothing(),
          );
          break;
        default:
          assertNever(payload.auth.passwordState);
      }
      break;
    default:
      assertNever(payload.auth);
  }
};

const handlepreferredLanguageChangeError = (err: LocaleChangeError) => {
  switch (err.kind) {
    case 'locale-change-skipped':
      break;
    case 'i18next-interaction-error':
    case 'could-not-reload-error':
      warn(err);
      break;
    default:
      assertNever(err);
  }
};

const getParam = (param: URLSearchParams): Task<InvalidParamsError, string> =>
  fromNullableT(param.get('dp')).orElse(() => Task.fail({ kind: 'invalid-params-error' }));

const configureUrl =
  (guid: string, params: Maybe<URLSearchParams>) =>
  (link: Link): Task<LoadingError, Link> =>
    Task.succeed<LoadingError, Maybe<URLSearchParams>>(params)
      .andThen(toTask({ kind: 'invalid-params-error' }))
      .andThen(getParam)
      .andThen(processTemplateLinkWithQuery(guid, link))
      .orElse(() => processTemplateLink(guid, link));

interface Props extends RCProps<RegisterResourceStore> {
  params: Maybe<URLSearchParams>;
}

class RegisterResourceReactions extends ReactionComponent<RegisterResourceStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store, params } = this.props;
    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading-register-resource':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkT('targeted-invitation-template'))
          .andThen(configureUrl(state.guid, params))
          .andThen(getRegisterResource)
          .fork(handleLoadingError(store), store.ready);
        break;
      case 'loading-preview-resource':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkT('preview'))
          .map(processPreviewLink(state.id))
          .andThen(getPreviewResource)
          .fork(handleLoadingPreviewError(store), store.ready);
        break;
      case 'ready':
        changeLocaleT(state.resource.payload.preferredLanguage.getOrElseValue('en')).fork(
          handlepreferredLanguageChangeError,
          noop,
        );
        handleReady(state.resource.payload);
        break;
      case 'preview-resource-error':
      case 'register-resource-error':
        warn(state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default RegisterResourceReactions;
