import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ResourceLiveMeetingRecordingIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 28 23">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M0 0v19.385h28V0H0Zm2.154 2.154h23.692V17.23H2.154V2.154ZM14 4.308a3.248 3.248 0 0 0-3.23 3.23c0 .732.256 1.41.672 1.952-1.047.787-1.75 2.032-1.75 3.433h2.154c0-1.203.95-2.154 2.154-2.154 1.203 0 2.154.951 2.154 2.154h2.154c0-1.4-.703-2.646-1.75-3.433.416-.542.673-1.22.673-1.952 0-1.77-1.46-3.23-3.231-3.23Zm0 2.154c.606 0 1.077.47 1.077 1.076 0 .606-.471 1.077-1.077 1.077a1.063 1.063 0 0 1-1.077-1.077c0-.605.471-1.076 1.077-1.076ZM6.462 14v2.154h4.307V14H6.462Zm5.384 0v2.154h4.308V14h-4.308Zm5.385 0v2.154h4.307V14h-4.307Zm-9.693 6.462v2.153h12.924v-2.153H7.538Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourceLiveMeetingRecordingIcon);
