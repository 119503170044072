import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import { ActiveProgram } from '../../../../../ProgramStore/Types';
import ModalStore from '../../../../Modal/Store';
import * as style from '../../style.module.css';
import OrientationBeginButton from '../OrientationBeginButton';
import OrientationModalGraphic from '../OrientationModalGraphic';
import ProgramTitle from '../ProgramTitle';
import OrientationCommencementStore from './OrientationCommencementStore';
import OrientationCommencementReactions from './OrientationCommencementStore/Reactions';

interface Props {
  programStore: ProgramStore;
  modalStore: ModalStore;
  activeProgram: ActiveProgram;
}
class UnstartedOrientation extends React.Component<Props, {}> {
  orientationCommencementStore = new OrientationCommencementStore(this.props.programStore);

  componentDidMount() {
    this.orientationCommencementStore.ready();
  }

  render() {
    const { activeProgram } = this.props;

    return (
      <>
        <div className={style.programTile}>
          <OrientationModalGraphic />
          <div className={style.footer}>
            <ProgramTitle title={activeProgram.title} />
            <OrientationBeginButton
              orientationCommencementStore={this.orientationCommencementStore}
            />
          </div>
        </div>
        <OrientationCommencementReactions
          store={this.orientationCommencementStore}
          modalStore={this.props.modalStore}
        />
      </>
    );
  }
}

export default observer(UnstartedOrientation);
