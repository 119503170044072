import { Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SkeletonListViewProgramExperience } from '../../../../Common/ProgramExperience';

function SkeletonListViewExperiences() {
  return (
    <div className="mx-auto flex w-full flex-col px-0 pb-10 pt-0 xl:container sm:pt-10">
      <div className="flex flex-col gap-4 px-6 md:px-16">
        <Skeleton className="w-4/12" size="lg" />
        <Skeleton />
        <Skeleton className="w-8/12" />
      </div>
      <div className="mt-14 flex flex-col gap-4 px-6 md:px-16">
        {Array.from({ length: 5 }, (_, index) => (
          <SkeletonListViewProgramExperience key={index} />
        ))}
      </div>
    </div>
  );
}

export default observer(SkeletonListViewExperiences);
