import * as React from 'react';
import { PastEvent as TPastEvent } from '../../../EventsStore/Types';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  event: TPastEvent;
}

const PastEvent: React.FC<Props> = ({ event: { title } }) => (
  <a href="#" className={style.pastEvent} data-test-past-event={title}>
    <span className={style.eventLabel} data-test-event-title={title}>
      <NotTranslated text={title} />
    </span>
  </a>
);

export default PastEvent;
