import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../../../CurrentUser/Context/WithCurrentUser';
import FreebusyStore from '../../../../../FreebusyStore';
import DisplayButton from './Button';
import ScheduleSessionReady from './ScheduleSessionReady';
import ScheduleSessionStore from './Store';

interface Props {
  store: ScheduleSessionStore;
}

class ScheduleSessionStates extends React.Component<Props> {
  freebusyStore = new FreebusyStore('freebusy-coaching');

  render() {
    const { store } = this.props;

    switch (store.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading':
        return <></>;
      case 'reloading':
        return <DisplayButton freebusyStore={this.freebusyStore} />;
      case 'ready':
        return just(store.state.scheduleSessionResource)
          .map((scheduleSessionResource) => (
            <WithCurrentUser
              children={(currentUserResource) => (
                <ScheduleSessionReady
                  scheduleSessionStore={store}
                  scheduleSessionResource={scheduleSessionResource}
                  currentUserResource={currentUserResource}
                />
              )}
            />
          ))
          .getOrElse(emptyFragment);
    }
  }
}

export default observer(ScheduleSessionStates);
