import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { programRoute } from '../../ClientRoutes';
import { CommunityProgramResource } from '../../CommunitiesStore/Types';
import { NotTranslated, T } from '../../Translations';
import * as style from '../TeamSummary/style.module.css';

interface Props {
  communityProgramResource: CommunityProgramResource;
}

const ProgramChatOverview: React.FC<Props> = ({ communityProgramResource }) => (
  <div className={style.overview}>
    <dl className={style.dl}>
      <dt>
        <h3 className={style.programWrapper}>
          <T kind="Program" />
        </h3>
      </dt>
      <dd>
        <Link
          to={programRoute(communityProgramResource.payload.id)}
          className={style.programChatLink}
        >
          <NotTranslated text={communityProgramResource.payload.name} />
        </Link>
      </dd>
    </dl>
  </div>
);

export default observer(ProgramChatOverview);
