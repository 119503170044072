import { toResult } from '@execonline-inc/maybe-adapter';
import { just, Maybe, nothing } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { Result } from 'resulty';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';

export type BreakoutStores = NonEmptyList<BreakoutChannelStore>;

const whenStudent = (breakoutStore: BreakoutChannelStore): Maybe<BreakoutChannelStore> => {
  switch (breakoutStore.breakout.participantType) {
    case 'Student':
      return just(breakoutStore);
    case 'Auditor':
    case 'Coach':
    case 'Professor':
      return nothing();
  }
};

const whenBreakoutActive = (breakoutStore: BreakoutChannelStore): Maybe<BreakoutChannelStore> => {
  switch (breakoutStore.timeRemainingStore.state.kind) {
    case 'active':
      return just(breakoutStore);
    case 'waiting':
    case 'expiring':
    case 'expired':
      return nothing();
  }
};

export const whenShouldRedirect = (
  breakoutStores: Maybe<BreakoutStores>
): Result<Maybe<BreakoutStores>, BreakoutChannelStore> =>
  toResult(
    breakoutStores,
    breakoutStores
      .map(stores => stores.first)
      .andThen(whenStudent)
      .andThen(whenBreakoutActive)
  );

export type RoomConfiguration =
  | BreakoutRoomConfig
  | EventRoomConfig
  | TeamRoomConfig
  | RoomConfigError;

export const breakoutRoomConfig = (
  returnToEventId: number,
  teamId: number
): BreakoutRoomConfig => ({
  kind: 'breakout-room',
  returnToEventId,
  teamId,
});

export const teamRoomConfig = (teamId: number): TeamRoomConfig => ({
  kind: 'team-room',
  teamId,
});

export const eventRoomConfig = (eventId: number): EventRoomConfig => ({
  kind: 'event-room',
  eventId,
});

export const roomConfigError = (): RoomConfigError => ({
  kind: 'error',
});

interface BreakoutRoomConfig {
  kind: 'breakout-room';
  returnToEventId: number;
  teamId: number;
}

interface EventRoomConfig {
  kind: 'event-room';
  eventId: number;
}

interface TeamRoomConfig {
  kind: 'team-room';
  teamId: number;
}

interface RoomConfigError {
  kind: 'error';
}
