import { Button, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T, translation, TranslationsContext } from '../../../Translations';
import Image from '../../Image';

function SelectTime() {
  return (
    <>
      <Button
        id="freebusy-test-pickatime-button"
        className="mb-4 bg-green-primary hover:bg-[#187723]"
        color="success"
        radius="sm"
        data-test-button={true}
      >
        <T kind="Select a time" />
      </Button>
      <TranslationsContext.Consumer>
        {(ts) => (
          <Image
            className="w-full"
            src="https://media.execonline.com/resources/test-page-pickatime.png"
            alt={translation('Pick-a-Time grid')(ts)}
          />
        )}
      </TranslationsContext.Consumer>
      <Typography>
        <T
          kind="If you experience any errors during these tests ..."
          emailAddress={
            <a className="underline hover:text-teal-600" href="mailto:support@execonline.com">
              support@execonline.com
            </a>
          }
        />
      </Typography>
    </>
  );
}

export default observer(SelectTime);
