import { equals } from '@execonline-inc/collections';
import { Button, useMediaQuery } from '@execonline-inc/execonline-ui';
import { fromNullable, just } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { useEffect } from 'react';
import { T } from '../../../../../../Translations';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import { AvailabilityResource } from '../../../../Common/Experience/Types';
import { useExperienceEnrollmentContext } from '../../../../Common/ProgramExperienceEnrollment';
import { getButtonSize } from '../Types';
import ScheduledSelect from './ScheduledSelect';
import { getScheduledOptions, getSelectedScheduledOption } from './Types';

interface Props {
  availabilities: NonEmptyList<AvailabilityResource>;
}

function ScheduledAction({ availabilities }: Props) {
  const breakPoint = useMediaQuery();
  const { localize } = useTranslationsContext();
  const { availability, onOpen, assignAvailability, resetAvailability } =
    useExperienceEnrollmentContext();
  const options = getScheduledOptions(availabilities, localize);
  const selectedOption = getSelectedScheduledOption(availability, localize);

  useEffect(() => () => resetAvailability(), []);

  function handleSelectChange(value: string) {
    const selectedAvailability = options.find((item) => equals(item.label, value));

    fromNullable(selectedAvailability).do((selectedAvailability) =>
      assignAvailability(just(selectedAvailability.value)),
    );
  }

  return (
    <div className="mt-16 sm:mt-7">
      <ScheduledSelect value={selectedOption} options={options} onChange={handleSelectChange} />
      <Button
        className="mt-6 min-w-full sm:min-w-fit"
        color="gray"
        size={getButtonSize(breakPoint)}
        onClick={onOpen}
      >
        <T kind="Enroll Now" />
      </Button>
    </div>
  );
}

export default observer(ScheduledAction);
