import { SvgIcon, SvgIconProps } from '@execonline-inc/execonline-ui';

function EnrolledIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g strokeWidth="1.5" clipPath="url(#a)" fill="none">
        <path
          stroke="url(#b)"
          d="M8.2 5.75h.0004a2.9498 2.9498 0 0 0 2.0784-.8582l.0015-.0015.7-.7.0015-.0015a1.451 1.451 0 0 1 1.0282-.4276 1.4503 1.4503 0 0 1 1.0282.4276l.0015.0015.7.7.0013.0013a2.9486 2.9486 0 0 0 2.079.8584h1a1.4499 1.4499 0 0 1 1.45 1.45v1c0 .7782.3085 1.5264.8584 2.079l.0013.0013.7.7.0015.0015a1.4493 1.4493 0 0 1 .4275 1.0282 1.449 1.449 0 0 1-.4275 1.0282l-.0015.0014-.7.7-.0015.0015a2.9497 2.9497 0 0 0-.8582 2.0784V16.82a1.4503 1.4503 0 0 1-1.45 1.45h-1.0004a2.9494 2.9494 0 0 0-2.0784.8582l-.0015.0014-.7.7-.0015.0015a1.4517 1.4517 0 0 1-.4714.3165 1.4518 1.4518 0 0 1-1.1136 0 1.4517 1.4517 0 0 1-.4714-.3165l-.0015-.0015-.7-.7-.0015-.0014a2.9494 2.9494 0 0 0-2.0784-.8582H7.2a1.4501 1.4501 0 0 1-1.45-1.45v-1.0005a2.9501 2.9501 0 0 0-.8582-2.0784l-.0015-.0015-.7-.7-.0015-.0014a1.4504 1.4504 0 0 1 0-2.0564l.0015-.0015.7-.7.0015-.0015A2.95 2.95 0 0 0 5.75 8.2004V7.2A1.45 1.45 0 0 1 7.2 5.75h1Z"
        />
        <path stroke="url(#c)" strokeLinecap="round" strokeLinejoin="round" d="m9 12 2 2 4-4" />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="24.6082"
          x2="3.6112"
          y1="-1.1882"
          y2="25.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0C4" />
          <stop offset="1" stopColor="#006177" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="16.2"
          x2="12.2649"
          y1="9.0667"
          y2="16.6559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0C4" />
          <stop offset="1" stopColor="#006177" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EnrolledIcon;
