import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Experience } from '../../../../../Experience/Types';
import {
  LeadershipCoachingOverviewItems,
  ProgramEnrolled,
  ProgramGraduated,
} from '../../../../../Experience/ExperienceBody';

interface Props {
  experience: Experience;
}

function LeadershipCoachingOverview({ experience }: Props) {
  switch (experience.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return <LeadershipCoachingOverviewItems />;
    case 'beginnable':
    case 'resumable':
      return <ProgramEnrolled />;
    case 'graduated':
    case 'returnable':
      return <ProgramGraduated />;
    case 'upcoming':
      return emptyFragment();
  }
}

export default observer(LeadershipCoachingOverview);
