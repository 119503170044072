import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { Resource } from '../../../Resource/Types';
import { CurrentUserResource } from '../../Types';

export type ContingencyResolution = 'waiting' | 'loading' | 'refreshing' | 'finished';

export interface ContingentStore {
  loading: (currentUserResource: CurrentUserResource) => void;
  refreshing: (currentUserResource: CurrentUserResource) => void;
  resolution: ContingencyResolution;
}

export interface ContingentResourceStore<T> extends ContingentStore {
  resource: Maybe<Resource<T>>;
}

export interface Props {
  currentUserResource: CurrentUserResource;
  contingencies: ReadonlyArray<ContingentStore>;
}

export const contingencyState = (
  contingency: ContingentStore
): 'resolved' | 'unresolved' | 'loading' => {
  switch (contingency.resolution) {
    case 'waiting':
      return 'unresolved';
    case 'loading':
      return 'loading';
    case 'finished':
    case 'refreshing':
      return 'resolved';
  }
};

export const resourceFromContingentStore = <T>(store: ContingentResourceStore<T>) =>
  when(contingencyState(store) === 'resolved', store.resource);
