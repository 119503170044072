import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';

interface Props {
  organizationName: string;
}

const Instructions: React.FC<Props> = ({ organizationName }) => (
  <Typography className="mb-1.5">
    <T
      kind="Your {{organizationName}} account has been locked ..."
      organizationName={organizationName}
    />
  </Typography>
);

export default observer(Instructions);
