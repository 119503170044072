import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import ConversationStore from '../../../ConversationStore';
import { MessageGroup } from '../../../ConversationStore/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import TeamChatMessageGroup from '../../TeamProfile/TeamChat/TeamChatMessageGroup';

interface Props {
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  conversationStore: ConversationStore;
  lastReadMessageStore: LastReadMessageStore;
}

const MessageConversation: React.FC<Props> = ({
  presenceStore,
  conversationStore,
  lastReadMessageStore,
}) => (
  <>
    {conversationStore.messageResourceGroups.map((group: MessageGroup) => (
      <TeamChatMessageGroup
        group={group}
        presenceStore={presenceStore}
        key={group.dateStamp}
        conversationStore={conversationStore}
        lastReadMessageStore={just(lastReadMessageStore)}
      />
    ))}
  </>
);

export default observer(MessageConversation);
