import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const InfoIcon: React.FC = () => (
  <SVGIcon width="14" height="14" viewBox="0 0 14 14">
    <path
      fill="#187A90"
      d="M7 .333A6.674 6.674 0 0 0 .333 7 6.674 6.674 0 0 0 7 13.667 6.674 6.674 0 0 0 13.667 7 6.674 6.674 0 0 0 7 .333Zm.5 9.834a.5.5 0 0 1-1 0V6.5a.5.5 0 0 1 1 0v3.667ZM7 5a.666.666 0 1 1 0-1.333A.666.666 0 0 1 7 5Z"
    />
  </SVGIcon>
);

export default observer(InfoIcon);
