import { observer } from 'mobx-react';
import { ButtonProps } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { Exitable, exitToDashboard, exitToSegment } from '../Actions';
import { BeginnableExperience } from '../Types';

interface Props extends ButtonProps {
  experience: BeginnableExperience;
  buttonProps?: ButtonProps;
}

function BeginnableAction({ experience, buttonProps }: Props) {
  const exitContext = () => {
    switch (experience.offeringType) {
      case 'aep':
      case 'epc':
      case 'msuite':
        return exitToSegment(experience);
      case 'program-sequence':
      case 'coaching':
      case 'group-coaching':
        return exitToDashboard();
    }
  };

  return (
    <Exitable buttonProps={{ fullWidth: true, ...buttonProps }} exitTo={exitContext}>
      <T kind="Return to Program" />
    </Exitable>
  );
}

export default observer(BeginnableAction);
