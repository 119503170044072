import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Link } from '../../Resource/Types';
import { NotTranslated } from '../../Translations';
import { LinkedDocumentNode } from '../ContentEmbed/Types';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import LinkedCourseDocumentReactions from './Reactions';
import LinkedCourseDocumentStore from './Store';
import * as style from './style.module.css';

interface Props {
  node: LinkedDocumentNode;
  links: ReadonlyArray<Link>;
}

function LinkedCourseDocument({ node, links }: Props) {
  const store = useMemo(() => new LinkedCourseDocumentStore(node.uuid, links), [node, links]);
  const { openableState, close, open } = useOpenable();

  const { state } = store;
  switch (state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <>
          <span className={style.link} onClick={store.load}>
            <NotTranslated text={node.description} />
          </span>
          <LinkedCourseDocumentReactions store={store} open={open} />
        </>
      );
    case 'ready':
      return (
        <>
          <span className={style.link} onClick={store.load}>
            <NotTranslated text={node.description} />
          </span>
          <DownloadViewModal resource={state.resource} close={close} modalState={openableState} />
          <LinkedCourseDocumentReactions store={store} open={open} />
        </>
      );
    case 'error':
      return (
        <>
          <span className={style.link} onClick={store.load}>
            <NotTranslated text={node.description} />
          </span>
          <NotTranslated text={state.message} />
        </>
      );
  }
}

export default observer(LinkedCourseDocument);
