import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { ExternalProgramSegment as segment } from '../../../SegmentStore/Types';
import { T } from '../../../Translations';
import ArrowButton from '../../common/ArrowButton';
import WhenOneOrAll from '../../WhenOneOrAll';
import DetailViewStore from './DetailViewStore';
import ExternalProgramDetailView from './ExternalProgramDetailView';
import ExternalProgramSummaryView from './ExternalProgramSummaryView';
import * as style from './ExternalProgramSummaryView/style.module.css';

interface Props {
  segment: segment;
  detailViewStore: DetailViewStore;
}

function ExternalProgramSegment({ segment, detailViewStore }: Props) {
  return segment.externalProgramResources
    .map((resources) => {
      switch (detailViewStore.state.kind) {
        case 'close': {
          return (
            <WhenOneOrAll
              values={resources}
              hasOne={(resource) => (
                <>
                  <ExternalProgramDetailView
                    externalProgramResource={resource}
                    detailViewStore={detailViewStore}
                  />
                </>
              )}
              hasMany={(resource) => (
                <ExternalProgramSummaryView
                  externalProgramResource={resource}
                  key={resource.payload.id}
                  detailViewStore={detailViewStore}
                />
              )}
              hasManyDecorator={(children) => (
                <div className={clsx(style.cardContainer, 'bg-neutral-800')}>{children}</div>
              )}
            />
          );
        }
        case 'open': {
          return (
            <>
              <div className={clsx(style.backButton, 'bg-neutral-800')}>
                <ArrowButton
                  arrowDirection="left"
                  arrowPosition="start"
                  color="secondary"
                  onClick={detailViewStore.closeInfo}
                >
                  <T kind="Back to all programs" />
                </ArrowButton>
              </div>
              <ExternalProgramDetailView
                externalProgramResource={detailViewStore.state.resource}
                detailViewStore={detailViewStore}
              />
            </>
          );
        }
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExternalProgramSegment);
