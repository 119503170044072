import * as React from 'react';
import OrientationCommencementStore from '../UnstartedOrientation/OrientationCommencementStore';

import { observer } from 'mobx-react';
import BeginButton from './BeginButton';

interface Props {
  orientationCommencementStore: OrientationCommencementStore;
}

const OrientationBeginButton: React.FC<Props> = ({ orientationCommencementStore }) => {
  switch (orientationCommencementStore.state.kind) {
    case 'waiting':
    case 'error':
    case 'done':
      return <></>;
    case 'creating':
      return <BeginButton disabled orientationCommencementStore={orientationCommencementStore} />;
    case 'ready':
      return (
        <BeginButton disabled={false} orientationCommencementStore={orientationCommencementStore} />
      );
  }
};

export default observer(OrientationBeginButton);
