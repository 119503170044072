import * as React from 'react';
import { observer } from 'mobx-react';
import TeamMember from '../../TeamsSummary/TeamsRenderer/TeamsSummary/TeamMember';
import { teamMembersCountAfterTruncate, TruncatedTeamMembers } from '../../../TeamMembers';
import { TeamSummaryResource } from '../../../TeamsSummaryStore/Types';
import * as style from '../../TeamSummary/style.module.css';
import { fromArrayMaybe } from 'nonempty-list';
import { T } from '../../../Translations';
import CommunityMembersCountAfterTruncate from './CommunityMembersCountAfterTruncate';

interface Props {
  teamSummaryResource: TeamSummaryResource;
}

export const TRUNCATED_TEAM_MEMBER_COUNT = 5;

const CommunityMembers: React.FC<Props> = ({ teamSummaryResource }) => {
  const {
    truncatedMembers,
    overflowedMembers,
  }: TruncatedTeamMembers = teamMembersCountAfterTruncate(
    teamSummaryResource,
    TRUNCATED_TEAM_MEMBER_COUNT
  );
  return (
    <div className={style.coachTeamMembers}>
      {fromArrayMaybe(teamSummaryResource.payload.teamMembers)
        .map(() => (
          <>
            {truncatedMembers.map((teamMemberResource) => (
              <TeamMember
                key={teamMemberResource.payload.id}
                teamMemberResource={teamMemberResource}
              />
            ))}
            <CommunityMembersCountAfterTruncate
              teamSummaryResource={teamSummaryResource}
              overflowedMembers={overflowedMembers}
            />
          </>
        ))
        .getOrElse(() => (
          <div className={style.noMembers}>
            <T kind="No team members" />
          </div>
        ))}
    </div>
  );
};

export default observer(CommunityMembers);
