import * as React from 'react';
import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import { findLink } from '../../LinkyLinky';
import PollLauncherButton from '../../EventPolls/PollLauncherButton';
import PollsModal from '../../EventPolls/PollsModal';
import BroadcastPollsModalReactions from '../../EventPolls/PollsModal/PollsModalStore/BroadcastPollsModalReactions';
import PollsModalStore from '../../EventPolls/PollsModal/PollsModalStore';
import { EventResource } from '../../EventsStore/Types';

interface Props {
  eventResource: EventResource;
  pollsModalStore: PollsModalStore;
}

const PollBroadcaster: React.FC<Props> = ({ eventResource, pollsModalStore }) => {
  return just({})
    .assign(
      'broadcastLink',
      eventResource.payload.conferenceRoom.andThen((conferenceRoom) =>
        findLink('broadcast-poll', conferenceRoom.links)
      )
    )
    .assign(
      'broadcastCloseLink',
      eventResource.payload.conferenceRoom.andThen((conferenceRoom) =>
        findLink('broadcast-poll-close', conferenceRoom.links)
      )
    )
    .map(({ broadcastLink, broadcastCloseLink }) => (
      <>
        <PollLauncherButton store={pollsModalStore} />
        <PollsModal store={pollsModalStore} pollsResource={eventResource.payload.polls} />
        <BroadcastPollsModalReactions
          store={pollsModalStore}
          broadcastLink={broadcastLink}
          broadcastCloseLink={broadcastCloseLink}
        />
      </>
    ))
    .getOrElseValue(<></>);
};

export default observer(PollBroadcaster);
