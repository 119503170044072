import { noop } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { warnAndNotify } from '../Honeybadger';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { setItem } from '../Storage';
import ScheduledToastStore from './ScheduledToastStore';

interface Props extends RCProps<ScheduledToastStore> {}

const handleStorageError = (msg: string) => {
  warnAndNotify('StorageError', `Failed to save dismissed toast UUID to local storage: ${msg}`, {});
};

class ToastReactions extends ReactionComponent<ScheduledToastStore, boolean, Props> {
  tester = () => this.props.store.dismissed;

  effect = (dismissed: boolean) => {
    const { store } = this.props;
    if (dismissed) {
      const dismissedToasts = fromNullable(localStorage.getItem('dismissedToasts')).getOrElseValue(
        ''
      );
      if (dismissedToasts.indexOf(store.scheduledToast.uuid) < 0) {
        setItem(
          'dismissedToasts',
          dismissedToasts + '|' + store.scheduledToast.uuid,
          localStorage
        ).fork((err) => handleStorageError(err), noop);
      }
    }
  };
}

export default ToastReactions;
