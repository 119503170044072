import { observer } from 'mobx-react';
import { ExperienceSection, ExperienceSectionBody } from '../../Common/ExperienceSection';
import { SkeletonPromotableExperienceCarousel } from '../PromotableExperienceCarousel';
import { PromotableSection } from '../PromotableSection';

function SkeletonPromotableExperiences() {
  return (
    <PromotableSection>
      <ExperienceSection data-testid="skeleton-promotables-section" className="p-0">
        <ExperienceSectionBody
          data-testid="skeleton-promotables-section-body"
          className="pb-10 pt-6 lg:py-0"
        >
          <SkeletonPromotableExperienceCarousel />
        </ExperienceSectionBody>
      </ExperienceSection>
    </PromotableSection>
  );
}

export default observer(SkeletonPromotableExperiences);
