import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Assignment } from '../../../../TeamResourceStore/Types';
import { NotTranslated } from '../../../../Translations';

interface Props {
  assignment: Assignment;
}

const Content: React.FC<Props> = ({ assignment }) => {
  switch (assignment.kind) {
    case 'user-segment':
      return <p>{ReactHtmlParser(assignment.content)}</p>;
    case 'team-discussion':
      return (
        <p>
          <NotTranslated text={assignment.content} />
        </p>
      );
  }
};

export default Content;
