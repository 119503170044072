import { observer } from 'mobx-react';
import Main from '../../../BrowserSupport/Main';
import * as segmentStyle from '../../Segment/style.module.css';
import * as style from './style.module.css';
interface Props {
  children: JSX.Element;
}

function SegmentPreviewContainer({ children }: Props) {
  return (
    <>
      <h1>Platform Preview</h1>
      <Main id="main">
        <div className={`${segmentStyle.content} ${style.previewContent}`}>{children}</div>
      </Main>
    </>
  );
}

export default observer(SegmentPreviewContainer);
