import { Button, cn, TextField } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import ResetPasswordStore from '../../../ResetPasswordStore';
import { T } from '../../../Translations';
import Loader from '../../Loader';

interface Props {
  store: ResetPasswordStore;
}

const Form: React.FC<Props> = ({ store }) => {
  const setEmail = (event: React.FormEvent<HTMLInputElement>) => {
    store.setEmail(event.currentTarget.value);
  };

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'requested':
    case 'error':
    case 'ready': {
      return (
        <form data-testid="reset-password-form" className="mx-auto w-80" onSubmit={submit}>
          <TextField
            data-testid="reset-password-form_email-text-field"
            autoFocus
            id="login_email"
            onChange={setEmail}
            data-test-input-email={true}
            value={store.email.getOrElseValue('')}
            label={<T kind="Email Address" />}
            labelProps={{ className: 'uppercase tracking-wider' }}
            helperText="email@domain.com"
            helperTextProps={{ className: 'font-medium' }}
          />
          <div className="mt-7 flex flex-col justify-center gap-3 sm:flex-row">
            <Link
              data-testid="reset-password-form_sign-in-link"
              to={dashboardUrl}
              className={cn(
                'inline-flex cursor-pointer justify-center whitespace-nowrap rounded-sm px-4 py-2 text-center text-sm font-medium',
                'border border-solid border-gray-300 bg-transparent text-gray-600-old hover:border-gray-800 hover:bg-gray-50 hover:text-gray-600-old',
                'box-border w-full sm:w-auto',
              )}
              data-test-login-link={true}
            >
              <T kind="Sign In" />
            </Link>
            <Button
              data-testid="reset-password-form_confirm-reset-password-button"
              className="w-full sm:w-auto"
              type="submit"
              color="green"
              radius="sm"
              data-test-action-reset-password={true}
            >
              <T kind="Confirm Reset Password" />
            </Button>
          </div>
        </form>
      );
    }
    case 'loading':
    case 'waiting':
      return <Loader />;
  }
};
export default observer(Form);
