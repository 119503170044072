import { parseIntM } from '@execonline-inc/numbers';
import { minutes, toMinutes } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import { T } from '../../Translations';
import Input from '../Form/Input';
import * as style from './style.module.css';
interface Props {
  store: BreakoutTimerStore;
}

const TimerForm: React.FC<Props> = ({ store }) => {
  const setDuration = (event: React.FormEvent<HTMLInputElement>) => {
    parseIntM(event.currentTarget.value).map((val) => {
      store.setDuration(minutes(val));
    });
  };

  const submitDuration = () => {
    store.duration.do(() => store.updating());
  };

  const submitOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitDuration();
    }
  };

  return (
    <>
      <div className={style.timerSet}>
        <button className={style.timerButton} onClick={submitDuration}>
          <T kind="Set Timer" />
        </button>
        <span className={style.timerInputContainer}>
          <Input
            type="text"
            name="timerTime"
            id="timerTime"
            className={style.timerInput}
            onChange={setDuration}
            onKeyDown={submitOnEnter}
            disabled={store.state.kind === 'updating'}
            value={store.duration
              .map((dur) => toMinutes(dur).minutes)
              .getOrElseValue(20)
              .toString()}
          />
          <span className={style.timerInputLabel}> mins</span>
        </span>
      </div>
    </>
  );
};

export default observer(TimerForm);
