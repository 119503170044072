import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamMemberResource } from '../../../TeamsSummaryStore/Types';
import TeamMemberDetail from './TeamMemberDetail';
import CommunitiesStore from '../../../CommunitiesStore';
import {
  CommunityResource,
  CommunityTeam,
  CommunityTeamResource,
} from '../../../CommunitiesStore/Types';
import { Maybe } from 'maybeasy';
import { findPayload } from '@execonline-inc/collections';
import { TRUNCATED_DISPLAY_COUNT, truncateTeamMembers } from '../../../TeamMembers';
import { resource } from '../../../Resource/Types';

interface Props {
  communityTeamResource: CommunityTeamResource;
  communitiesStore: CommunitiesStore;
}

const OnTruncateTeamMembers = (communityResource: CommunityResource) => {
  switch (communityResource.payload.kind) {
    case 'team':
      return truncateTeamMembers(
        resource<CommunityTeam>(communityResource.links, communityResource.payload),
        TRUNCATED_DISPLAY_COUNT
      );
    case 'program':
      return [];
  }
};

const truncatedTeamMembers = (
  id: number,
  communitiesStore: CommunitiesStore
): Maybe<ReadonlyArray<TeamMemberResource>> => {
  return communitiesStore.communitiesResource.map((communitiesResource) =>
    findPayload(id, communitiesResource.payload)
      .map((communityResource) => OnTruncateTeamMembers(communityResource))
      .getOrElseValue([])
  );
};

const TeamMemberList: React.FC<Props> = ({ communityTeamResource, communitiesStore }) => (
  <>
    {truncatedTeamMembers(communityTeamResource.payload.id, communitiesStore)
      .getOrElseValue([])
      .map((teamMemberResource: TeamMemberResource) => (
        <TeamMemberDetail
          key={teamMemberResource.payload.id}
          teamMemberResource={teamMemberResource}
        />
      ))}
  </>
);

export default observer(TeamMemberList);
