import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../ClientRoutes';
import { L, T } from '../../Translations';
import CenteredLogo from '../CenteredLogo';
import ArrowLeftIcon from '../Cutesies/ArrowLeftIcon';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import NeedAssistance from '../NeedAssistance';
import WithBackgroundImage from '../WithBackgroundImage';
import * as style from './style.module.css';
import { EventResource } from '../../EventsStore/Types';
import { distanceOfTimeInWords } from '../../Time';
import { now } from 'mobx-utils';

interface Props {
  eventResource: EventResource;
}

const NotStarted: React.FC<Props> = ({ eventResource }) => {
  return (
    <WithBackgroundImage>
      <CenteredLogo size="large" />
      <h1 className={style.title} data-test-title={true}>
        <T
          kind="Your event starts in <expectedDelay/> at <time/>. Please come back at that time."
          expectedDelay={
            <T {...distanceOfTimeInWords(new Date(now()), eventResource.payload.startAt)} />
          }
          time={<L localizeable={eventResource.payload.startAt} format="time-of-day" />}
        />
      </h1>
      <NeedAssistance />
      <div className={style.actions}>
        <a href="javascript:history.back();" className={style.backButton}>
          <div className={style.buttonIcon}>
            <ArrowLeftIcon />
          </div>
          <T kind="Back to previous page" />
        </a>
        <Link to={dashboardUrl} className={style.dashboardButton}>
          <T kind="Go to dashboard" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </Link>
      </div>
    </WithBackgroundImage>
  );
};

export default observer(NotStarted);
