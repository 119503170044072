import * as React from 'react';
import { TeamResource } from '../../../TeamsStore/Types';
import { T } from '../../../Translations';
import LinkifyWithCharEntities from '../../LinkifyWithCharEntities';
import * as style from './style.module.css';

interface Props {
  teamResource: TeamResource;
}

const PinnedMessage: React.FC<Props> = ({ teamResource }) =>
  teamResource.payload.teamChat.payload.pinnedMessage
    .map((pinnedMessage) => (
      <div className={style.topic}>
        <div className={style.topicText}>
          <span className={style.topicTitle}>
            <T kind="Current topic:" />
          </span>{' '}
          <LinkifyWithCharEntities content={pinnedMessage} />
        </div>
      </div>
    ))
    .getOrElseValue(<span />);

export default PinnedMessage;
