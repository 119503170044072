import { assertNever } from '@kofno/piper';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceRosterState } from '../../../ConferenceRosterStore/Types';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import StaffPresenceStore from './';

export interface Props extends RCProps<ConferenceRosterStore> {
  store: ConferenceRosterStore;
  staffPresenceStore: StaffPresenceStore;
}

class StaffPresenceConferenceRosterReactions extends ReactionComponent<
  ConferenceRosterStore,
  ConferenceRosterState,
  Props
> {
  tester = () => this.props.store.conferenceRosterState;
  effect = (state: ConferenceRosterState) => {
    switch (state.kind) {
      case 'roster-loaded':
        this.props.store.polling();
        break;
      case 'polling-complete':
        this.props.staffPresenceStore.setStaffList(this.props.store.presentParticipants);
        break;
      case 'waiting':
      case 'loading':
      case 'error':
      case 'polling':
      case 'updating':
      case 'ready':
      case 'stopped':
        break;
      default:
        assertNever(state);
    }
  };
}

export default StaffPresenceConferenceRosterReactions;
