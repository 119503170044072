import { Maybe } from 'maybeasy';
import { err, ok, Result } from 'resulty';

interface MissingEmailError {
  kind: 'missing-email-error';
}
interface InvalidEmailError {
  kind: 'invalid-email-error';
}
const invalidEmailError = (): InvalidEmailError => ({
  kind: 'invalid-email-error',
});
const missingEmailError = (): MissingEmailError => ({
  kind: 'missing-email-error',
});
type EmailError = MissingEmailError | InvalidEmailError;
type EmailAddress = string;

interface EAddress {
  recipient: string;
  domain: string;
}

const recipient = (candidate: string): Result<InvalidEmailError, [string, string]> => {
  const parts = candidate.split('@');
  return parts.length !== 2 ? err(invalidEmailError()) : ok(parts as [string, string]);
};

const domain = (candidate: string): Result<InvalidEmailError, string> => {
  return candidate.split('.').length < 2 ? err(invalidEmailError()) : ok(candidate);
};

const eAddress = (candidate: string): Result<EmailError, EAddress> => {
  return recipient(candidate)
    .map(([recipient, domain]) => [{ recipient }, domain] as [{ recipient: string }, string])
    .andThen(([partial, candidate]) => domain(candidate).map(d => ({ ...partial, domain: d })));
};

const toEmailString = ({ recipient, domain }: EAddress): string => {
  return `${recipient}@${domain}`;
};

export const validateEmail = (email: Maybe<string>): Result<EmailError, EmailAddress> => {
  return email
    .map<Result<EmailError, EmailAddress>>(ok)
    .getOrElse(() => err(missingEmailError()))
    .andThen(eAddress)
    .map(toEmailString);
};
