import { observer } from 'mobx-react';
import { GroupCoachingProductDetails } from '../../../Types';
import GroupCoachingNoAvailability from './GroupCoachingNoAvailability';
import GroupCoachingSeatsRemaining from './GroupCoachingSeatsRemaining';
import GroupCoachingSessionDetails from './GroupCoachingSessionDetails';

interface Props {
  productDetails: GroupCoachingProductDetails;
}

function GroupCoachingOverviewItems({ productDetails }: Props) {
  const { groupCoachingSessions, sessionDurationInMinutes, remainingSeats } = productDetails;

  return (
    <div className="flex flex-col gap-y-3 empty:hidden">
      <GroupCoachingSessionDetails
        sessions={groupCoachingSessions}
        durationInMinutes={sessionDurationInMinutes}
      />
      <GroupCoachingSeatsRemaining remainingSeats={remainingSeats} />
      <GroupCoachingNoAvailability remainingSeats={remainingSeats} />
    </div>
  );
}

export default observer(GroupCoachingOverviewItems);
