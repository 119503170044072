import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import ProgramStore from '../../ProgramStore';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import * as style from '../Dashboard/style.module.css';
import ModalStore from '../Modal/Store';
import OrientationModal from './ActiveCoachingProductOverview/Orientation/OrientationModal';
import StreamlinedOverview from './StreamlinedOverview';

interface Props {
  store: ProgramStore;
  programDetailResource: ProgramDetailResource;
}

class ActiveStreamlinedProductOverview extends React.Component<Props, {}> {
  modalStore = new ModalStore();

  render() {
    const { store, programDetailResource } = this.props;

    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <div className={style.program} data-test-active-program={true}>
            <OrientationModal modalStore={this.modalStore} programStore={store} />
            <StreamlinedOverview
              modalStore={this.modalStore}
              programDetailResource={programDetailResource}
              store={store}
              currentUserResource={currentUserResource}
            />
          </div>
        )}
      />
    );
  }
}

export default observer(ActiveStreamlinedProductOverview);
