import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../Links';
import { getBackgroundImage } from '../../Native/AEP/DiscoveryPortal/ExperienceSelection';
import { rootResourceStore } from '../../RootResourceStore';
import TermsAndConditionsStore from '../../TermsAndConditionsStore';
import Loader from '../Loader';
import Content from './Content';

interface Props {
  store: TermsAndConditionsStore;
}

function TermsAndConditionsView({ store }: Props) {
  const { state } = store;

  switch (state.kind) {
    case 'updating':
    case 'loading':
    case 'waiting':
    case 'updated':
      return <Loader />;
    case 'ready':
    case 'error':
      return just({})
        .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
        .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
        .map(({ privacyLink, termsLink }) => {
          return (
            <div
              style={getBackgroundImage('learn-more-program.svg')}
              className="h-[100vh] bg-repeat-y"
            >
              <Content privacyLink={privacyLink} store={store} termsLink={termsLink} />
            </div>
          );
        })
        .getOrElse(() => <></>);
  }
}

export default observer(TermsAndConditionsView);
