import { observer } from 'mobx-react';
import * as React from 'react';
import EventsStore from '../../../EventsStore';
import { Linkable } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';
import CalendarPanel from '../../CalendarPanel';
import UpcomingEvents from '../../UpcomingEvents';

interface Props {
  eventsStore: EventsStore;
  header: TPlainTextKey;
  linkable: Linkable;
  headingLevel: 2 | 3;
}

const Events: React.FC<Props> = ({ eventsStore, header, linkable, headingLevel }) => (
  <>
    <CalendarPanel header={header} headingLevel={headingLevel} linkable={linkable} />
    <UpcomingEvents eventsStore={eventsStore} limit={3} viewAllButton={false} />
  </>
);

export default observer(Events);
