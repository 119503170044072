import * as React from 'react';
import { T } from '../../Translations';

const VideoAssetUnavailable: React.FC = () => (
  <div data-test-video-asset-unavailable={true}>
    <T kind="Video asset is not available" />
  </div>
);

export default VideoAssetUnavailable;
