import { observer } from 'mobx-react';
import * as React from 'react';
import { CommunityProgramResource } from '../../CommunitiesStore/Types';
import * as style from '../TeamSummary/style.module.css';
import ProgramChatHeader from './ProgramChatHeader';
import ProgramChatOverview from './ProgramChatOverview';

interface Props {
  communityProgramResource: CommunityProgramResource;
}

const ProgramChatSummary: React.FC<Props> = ({ communityProgramResource }) => (
  <div className={style.main}>
    <div className={style.team}>
      <ProgramChatHeader communityProgramResource={communityProgramResource} />
      <ProgramChatOverview communityProgramResource={communityProgramResource} />
    </div>
  </div>
);

export default observer(ProgramChatSummary);
