import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import ProgramChatStore from '../../ProgramChatStore';
import TeamStore from '../../TeamStore';
import CommunitiesChatImpl from './CommunitiesChatImpl';

interface Props {
  conversationStore: ConversationStore;
  programChatStore: Maybe<ProgramChatStore>;
  teamStore: Maybe<TeamStore>;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  kind: 'team-chat' | 'program-chat';
}

const ProgramChats: React.FC<Props> = ({
  conversationStore,
  programChatStore,
  teamStore,
  presenceStore,
  kind,
}) => {
  switch (conversationStore.state.kind) {
    case 'ready':
    case 'loading-next':
    case 'load-next-success':
    case 'loading-previous':
    case 'waiting':
    case 'loading':
    case 'reloading':
    case 'load-new-message':
    case 'load-new-reply':
    case 'update-message':
    case 'first-loaded':
    case 'update-reply':
      return (
        <CommunitiesChatImpl
          conversationStore={conversationStore}
          programChatStore={programChatStore}
          teamStore={teamStore}
          presenceStore={presenceStore}
          kind={kind}
        />
      );
    case 'error':
      return <></>;
  }
};

export default observer(ProgramChats);
