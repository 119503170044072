import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';
import { toCalendarMenuItem } from './Types';

const ViewAllButton: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) =>
      toCalendarMenuItem(currentUserResource.links)
        .map((content) => (
          <Link
            to={content.to}
            className={style.buttonViewAll}
            data-test-view-all-events-link="View Events Calendar"
          >
            <T kind="View Events Calendar" />
            <div className={style.buttonIcon}>
              <ArrowRightIcon />
            </div>
          </Link>
        ))
        .getOrElse(emptyFragment)
    }
  />
);

export default observer(ViewAllButton);
