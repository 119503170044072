import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { T } from '../../../../Translations';
import { ExperienceResource } from '../../Common/Experience/Types';
import LmsContent from './LmsContent';
import Store from './Store';

interface Props {
  experienceResource: ExperienceResource;
  store: Store;
}

const WorkdayContent: React.FC<Props> = ({ store, experienceResource }) => (
  <LmsContent experienceResource={experienceResource} store={store}>
    <Typography className="basis-4/5 px-4" color="white">
      <T kind="Welcome Workday learners! Enroll in an Experience below to begin. When you complete your Experience, your status will be automatically reported to Workday." />
    </Typography>
  </LmsContent>
);

export default observer(WorkdayContent);
