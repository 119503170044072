import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import SimpleFileUploadStore from '../../../../SimpleFileUploadStore';
import { NotTranslated } from '../../../../Translations';
import * as style from '../style.module.css';

interface Props {
  store: SimpleFileUploadStore;
}

const Error: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'ready-with-errors':
      return (
        <div className={style.uploadError}>
          <NotTranslated text={store.state.error} />
        </div>
      );
    case 'upload-in-progress':
    case 'error':
    case 'loading':
    case 'ready-without-upload':
    case 'upload-successful':
    case 'waiting':
    case 'ready':
      return <></>;
    default:
      return assertNever(store.state);
  }
};
export default observer(Error);
