import { observer } from 'mobx-react';
import { ButtonExperience } from '../Common';
import { ResumableExperience } from '../../../../Common/Experience/Types';
import { exitToSegment } from '../../../../Common/Experience/Actions/Exitable/Types';

interface Props {
  experience: ResumableExperience;
}

function ResumableCardFooter({ experience }: Props) {
  const { programId, moduleId, segmentId } = experience;

  return (
    <ButtonExperience
      intent="Resume Experience"
      exitContext={exitToSegment({ programId, moduleId, segmentId })}
    />
  );
}

export default observer(ResumableCardFooter);
