import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import * as style from '../../style.module.css';

const NoticeBox: React.FC = () => (
  <div className={style.alertBox}>
    <div>
      <div className={style.boldText}>
        <T kind="Notice: no upcoming sessions scheduled!" />
      </div>
      <T kind="As a reminder, you have been individually selected to have access to Leadership Coaching. Please schedule your next session as soon as possible." />
    </div>
  </div>
);

export default observer(NoticeBox);
