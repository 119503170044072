import * as React from 'react';
import { CalendarEvent, isCurrent } from '../../EventsStore/Types';
import EventRange from './EventRange';
import Now from './Now';

interface Props {
  event: CalendarEvent;
}

const MultiDayTimes: React.FC<Props> = ({ event }) =>
  isCurrent(event) ? <Now event={event} /> : <EventRange event={event} />;

export default MultiDayTimes;
