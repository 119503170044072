import { InteractionType } from './Types';

export interface WindowInteractionError {
  kind: 'window-interaction-error';
  interaction: InteractionType;
  error: unknown;
}

export interface WindowPropertyError {
  kind: 'window-property-error';
  property: keyof Window;
  error: unknown;
}

export const windowInteractionError = (
  interaction: InteractionType,
  error: unknown,
): WindowInteractionError => ({
  kind: 'window-interaction-error',
  interaction,
  error,
});

export const windowPropertyError = ({
  property,
  error,
}: Omit<WindowPropertyError, 'kind'>): WindowPropertyError => ({
  kind: 'window-property-error',
  property,
  error,
});
