import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import EventType from '.';
import { calendarEventStore } from '../../../EventsStore/CalendarEventStore';
import { EventResource } from '../../../EventsStore/Types';
import * as style from '../style.module.css';

interface Props {
  events: NonEmptyList<EventResource>;
}

const ManyEvents: React.FC<Props> = ({ events }) => (
  <div className={style.events} data-test-many-events={true}>
    {events.map((e) => <EventType key={e.payload.id} store={calendarEventStore(e)} />).toArray()}
  </div>
);

export default observer(ManyEvents);
