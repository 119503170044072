import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import PollsModalStore from '.';
import { AppyError, callApi, postToApi } from '../../../Appy';
import { handleError } from '../../../ErrorActionableReaction';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { Link } from '../../../Resource/Types';
import { State } from './Types';

interface Props extends RCProps<PollsModalStore> {
  launchLink: Link;
  launchCloseLink: Link;
  messagingChannel: string;
}

const launchPoll = (poll_url: string, messaging_channel: string, link: Link) => {
  return postToApi({
    poll: {
      poll_url,
      messaging_channel,
    },
  })(link);
};

const closePoll = (messaging_channel: string, link: Link) => {
  return callApi(succeed({}), {
    messaging_channel,
  })(link);
};

const handleLaunchError = (store: PollsModalStore) => (error: AppyError) => {
  handleError(store, error);
};

class LaunchPollsModalReactions extends ReactionComponent<PollsModalStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'closed':
      case 'open':
      case 'poll-selection-ready':
      case 'error':
      case 'poll-launch-active':
        break;
      case 'poll-selected':
        find(
          (pollResource) => pollResource.payload.name === state.selectionEvent.currentTarget.value,
          store.pollResources
        )
          .do((pollResource) => store.pollSelectionReady(pollResource.payload))
          .elseDo(store.open);
        break;
      case 'poll-launch-request':
        launchPoll(state.selectedPoll.url, this.props.messagingChannel, this.props.launchLink).fork(
          handleLaunchError(store),
          store.pollLaunchActive
        );
        break;
      case 'poll-closed':
        closePoll(this.props.messagingChannel, this.props.launchCloseLink).fork(
          handleLaunchError(store),
          store.close
        );
        break;
      default:
        assertNever(state);
    }
  };
}

export default LaunchPollsModalReactions;
