import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import { NotTranslated, translation, TranslationsContext } from '../../../Translations';
import Image from '../../Image';
import * as style from '../style.module.css';
import clsx from 'clsx';

interface Props {
  store: PersonStoreContract;
}

const Avatar: React.FC<Props> = ({ store }) => {
  const avatar = store.avatar;
  switch (avatar.kind) {
    case 'avatar-link':
      return (
        <TranslationsContext.Consumer>
          {(ts) => <Image src={avatar.link.href} alt={translation('User Photo')(ts)} />}
        </TranslationsContext.Consumer>
      );
    case 'avatar-initials':
      return (
        <span className={clsx(style.profileInitials, 'bg-primary-500')}>
          <NotTranslated text={avatar.initials} />
        </span>
      );
  }
};

export default observer(Avatar);
