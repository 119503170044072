import { mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, dateISO, field, number, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { alreadyTranslatedText } from '../Translations';
import {
  ActiveProgramParts,
  CompletedProgramParts,
  DashboardPresence,
  ExpiredProgramParts,
  InactiveProgramParts,
  ProductFamilyKind,
  Program,
  ProgramBase,
  ProgramParts,
  ProgramResource,
  ProgramsPayload,
  ProgramsResource,
  RegistrationStatus,
  RegistrationType,
  UpcomingProgramParts,
  UserRegistrationsState,
} from './Types';

const userRegistrationsStateDecoder: Decoder<UserRegistrationsState> = oneOf([
  stringLiteral<UserRegistrationsState>('default'),
  stringLiteral<UserRegistrationsState>('no-upcoming-one-or-less-active'),
]);

const dashboardPresenceDecoder: Decoder<DashboardPresence> = oneOf([
  stringLiteral<DashboardPresence>('hidden'),
  stringLiteral<DashboardPresence>('visible'),
]);

export const registrationTypeDecoder: Decoder<RegistrationType> = oneOf([
  stringLiteral<RegistrationType>('Student'),
  stringLiteral<RegistrationType>('Auditor'),
  stringLiteral<RegistrationType>('Coach'),
  stringLiteral<RegistrationType>('Professor'),
]);

export const productFamilyKindDecoder: Decoder<ProductFamilyKind> = oneOf([
  stringLiteral<ProductFamilyKind>('program'),
  stringLiteral<ProductFamilyKind>('program-sequence'),
  stringLiteral<ProductFamilyKind>('coaching'),
  stringLiteral<ProductFamilyKind>('group-coaching'),
]);

export const registrationStatusDecoder: Decoder<RegistrationStatus> = oneOf([
  stringLiteral<RegistrationStatus>('active_completed'),
  stringLiteral<RegistrationStatus>('active_in_course'),
  stringLiteral<RegistrationStatus>('active_ignoring_time_gating'),
  stringLiteral<RegistrationStatus>('active_testing'),
  stringLiteral<RegistrationStatus>('active_post_graduation'),
  stringLiteral<RegistrationStatus>('no_course'),
  stringLiteral<RegistrationStatus>('no_course_registration'),
  stringLiteral<RegistrationStatus>('draft_course'),
  stringLiteral<RegistrationStatus>('no_content_available'),
  stringLiteral<RegistrationStatus>('course_closed'),
  stringLiteral<RegistrationStatus>('expired_graduated_access'),
  stringLiteral<RegistrationStatus>('inactive_course'),
  stringLiteral<RegistrationStatus>('expired_course'),
  stringLiteral<RegistrationStatus>('expired_non_student'),
  stringLiteral<RegistrationStatus>('registering'),
  stringLiteral<RegistrationStatus>('registered'),
]);

const baseProgramPayloadDecoder: Decoder<ProgramBase> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('registrationStatus', field('registration_status', registrationStatusDecoder))
  .assign('registrationType', field('registration_type', registrationTypeDecoder))
  .assign('productFamilyKind', field('product_family_kind', productFamilyKindDecoder))
  .assign('dashboardPresence', field('dashboard_presence', dashboardPresenceDecoder));

const activeProgramPartsDecoder: Decoder<ActiveProgramParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('active')))
  .assign('percentComplete', field('percent_complete', number));

const completedProgramPartsDecoder: Decoder<CompletedProgramParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('completed')))
  .assign('percentComplete', field('percent_complete', number));

const upcomingProgramPartsDecoder: Decoder<UpcomingProgramParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('upcoming')))
  .assign('startsOn', field('starts_on', dateISO));

const inactiveProgramPartsDecoder: Decoder<InactiveProgramParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('inactive')),
);

const expiredProgramPartsDecoder: Decoder<ExpiredProgramParts> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('expired')),
);

const programPartsDecoder: Decoder<ProgramParts> = oneOf<ProgramParts>([
  activeProgramPartsDecoder.map<ProgramParts>(identity),
  completedProgramPartsDecoder.map<ProgramParts>(identity),
  upcomingProgramPartsDecoder.map<ProgramParts>(identity),
  inactiveProgramPartsDecoder.map<ProgramParts>(identity),
  expiredProgramPartsDecoder.map<ProgramParts>(identity),
]);

const programDecoder: Decoder<Program> = mergeObjectDecoders(
  baseProgramPayloadDecoder,
  programPartsDecoder,
);

export const programResourceDecoder: Decoder<ProgramResource> = resourceDecoder(programDecoder);

const programsPayloadDecoder: Decoder<ProgramsPayload> = succeed({})
  .assign('programs', field('programs', array(programResourceDecoder)))
  .assign(
    'userRegistrationsState',
    field('user_registrations_state', userRegistrationsStateDecoder),
  );

export const programsResourceDecoder: Decoder<ProgramsResource> =
  resourceDecoder(programsPayloadDecoder);
