import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { CalendarDayIcon, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { FormattedAvailability } from '../../Experience/Availability';
import { AvailabilityResource } from '../../Experience/Types';

export interface Props {
  availabilities: NonEmptyList<AvailabilityResource>;
}

function UpcomingDatesInfo({ availabilities }: Props) {
  return (
    <>
      <div className="flex flex-col gap-y-4">
        <Typography className="tracking-[0.2px]" variant="caption" fontWeight="medium">
          <T kind="Upcoming Start Dates" />
        </Typography>
        <div className="flex flex-col gap-y-4">
          {availabilities.toArray().map((item, index) => (
            <div key={index} className="flex items-center gap-x-1.5">
              <CalendarDayIcon size="md" />
              <Typography
                key={item.payload.programId}
                className="tracking-[0.2px]"
                variant="caption"
                fontWeight="medium"
              >
                <FormattedAvailability availability={item} />
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default observer(UpcomingDatesInfo);
