import { observer } from 'mobx-react';
import { PromotableExperienceResource } from '../Types';
import PromotableExperienceTileDescription from './PromotableExperienceTileDescription';
import PromotableExperienceTileFooter from './PromotableExperienceTileFooter';
import PromotableExperienceTileImage from './PromotableExperienceTileImage';
import PromotableExperienceTileTitle from './PromotableExperienceTileTitle';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function PromotableLeadershipCoachingTile({ experienceResource }: Props) {
  return (
    <section className="box-border flex w-full grid-cols-2 gap-x-8">
      <div className="mt-16 flex w-6/12 flex-col">
        <PromotableExperienceTileTitle experienceResource={experienceResource} />
        <PromotableExperienceTileDescription experienceResource={experienceResource} />
        <PromotableExperienceTileFooter />
      </div>
      <div className="relative w-6/12">
        <span className="pointer-events-none absolute inset-0 right-[-1000px] bg-promotable-carousel [clip-path:ellipse(100%_250%_at_100%_54%)]" />
        <PromotableExperienceTileImage experienceResource={experienceResource} />
      </div>
    </section>
  );
}

export default observer(PromotableLeadershipCoachingTile);
