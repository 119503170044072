import { Maybe } from 'maybeasy';
import { Resource } from '../../Resource/Types';

export interface ConjointResults {
  attributeLabels: AttributeLabel[];
  columnLabels: ColumnLabel;
  respondents: Respondent[];
}

export type ConjointResultsInitializationError = ConjointResultsLoadError;

export interface ConjointResultsLoadError {
  kind: 'conjoint-results-load-error';
  message: string;
}

export const conjointResultsLoadError = (message: string): ConjointResultsLoadError => ({
  kind: 'conjoint-results-load-error',
  message,
});

export interface Respondent {
  name: string;
  characteristics: string[];
  response: ReadonlyArray<number[]>;
}

export interface ColumnLabel {
  name: string;
  characteristics: ReadonlyArray<string>;
}

export interface AttributeLabel {
  name: string;
  options: string[];
}

export type ConjointAnalysisResultsResource = Resource<ConjointResults>;
export type ConjointAnalysisResultsMaybeResource = Maybe<ConjointAnalysisResultsResource>;

export interface ConjointAnalysisResultsNode {
  kind: 'conjoint-analysis-results';
  resource: ConjointAnalysisResultsMaybeResource;
}

export interface ConjointAnalysisResultsEmbeddable {
  kind: 'conjoint-analysis-results-embeddable';
  node: ConjointAnalysisResultsNode;
}
