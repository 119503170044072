import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { eventConferenceUrl } from '../../../../../ClientRoutes';
import { T } from '../../../../../Translations';
import * as style from '../../style.module.css';

interface Props {
  sessionId: number;
}

const JoinLink: React.FC<Props> = ({ sessionId }) => (
  <Link to={eventConferenceUrl(sessionId)} className={style.joinButton}>
    <T kind="Join Now" />
  </Link>
);

export default observer(JoinLink);
