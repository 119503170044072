import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import Loading from '../../../../../components/Loading';
import { Link } from '../../../../../Resource/Types';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import Filter from './Filter';
import FilterStore from './FilterStore';

interface Props {
  experiencesStore: ExperiencesStore;
  filterStore: FilterStore;
  link: Maybe<Link>;
}

function Filters({ experiencesStore, filterStore, link }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'error':
      return <Loading />;
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
    case 'searching':
      return <Filter experiencesStore={experiencesStore} filterStore={filterStore} link={link} />;
  }
}

export default observer(Filters);
