import * as React from 'react';
import { Assignment } from '../../../../TeamResourceStore/Types';
import { NotTranslated } from '../../../../Translations';
import * as style from '../../style.module.css';

interface Props {
  assignment: Assignment;
}

const StudentDetails: React.FC<Props> = ({ assignment }) => (
  <span className={style.fileUser}>
    {assignment.studentName
      .map((studentName) => <NotTranslated text={studentName} />)
      .getOrElseValue(<></>)}
  </span>
);

export default StudentDetails;
