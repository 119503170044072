import { observer } from 'mobx-react';
import * as React from 'react';
import Store from '../Store';
import SocketAvailabilityMessage from './SocketAvailabilityMessage';

interface Props {
  store: Store;
}

const StatusBanner: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'ready':
      return <SocketAvailabilityMessage socket={store.state.socket} />;
    case 'error':
    case 'unmounted':
    case 'unmounting':
    case 'waiting':
      return <span />;
  }
};

export default observer(StatusBanner);
