import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const CohortPositionIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 10 12">
      <path
        fill="#D99F23"
        stroke={defaultTheme.variables['icon-on-dark']}
        d="m8.193 7.468-4.24 2.753A1.75 1.75 0 0 1 1.25 8.753V3.247a1.75 1.75 0 0 1 2.703-1.468l4.24 2.753a1.75 1.75 0 0 1 0 2.936Z"
      />
    </SVGIcon>
  );
};

export default observer(CohortPositionIcon);
