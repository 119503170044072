import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../ProgramStore/Types';
import StartIcon from '../../../../Cutesies/StartIcon';
import * as style from '../../../../ProgramSummary/style.module.css';
import ProgramModuleProgress from '../../ProgramModuleProgress';

interface Props {
  programDetail: ActiveProgram;
}

const ModuleProgress: React.FC<Props> = ({ programDetail }) => (
  <dd>
    <ol className={style.progressBar} data-test-progress-bar={true}>
      <li className={style.module} data-test-module-icon={true}>
        <div className={style.moduleIcon}>
          <StartIcon fillColor="#2AAB39" />
        </div>
      </li>
      {programDetail.modules.map((programModule: ActiveProgramModule) => {
        return (
          <ProgramModuleProgress
            programModule={programModule}
            key={programModule.id}
            programDetail={programDetail}
          />
        );
      })}
    </ol>
  </dd>
);

export default observer(ModuleProgress);
