import { observer } from 'mobx-react';
import * as React from 'react';
import OnEventsReady from '../../EventsStore/OnEventsReady';
import { EventsState, onlyCurrent } from '../../EventsStore/Types';
import CurrentEvents from './CurrentEvents';

interface Props {
  state: EventsState;
}

const UpdateEventsState: React.FC<Props> = ({ state }) => (
  <OnEventsReady
    state={state}
    filter={onlyCurrent}
    renderWithEvents={(events) => <CurrentEvents events={events} />}
    renderWithoutEvents={() => <></>}
  />
);

export default observer(UpdateEventsState);
