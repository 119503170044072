import { log } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { warnAndNotify } from '../../Honeybadger';
import SurveyModal from '../SurveyModal';
import VideoEngagement from '../Video/VideoEngagement';
import { selectPlayer } from './PlayerSelection';
import ReqHlsRenderedPlayer from './ReqHlsRenderedPlayer';
import {
  ReqHlsVideoAssetResource,
  SelectedPlayer,
  undetermined,
  VideoSelectionFailure,
} from './Types';

export interface Props {
  id: string;
  className: string;
  videoResource: ReqHlsVideoAssetResource;
  kettle: Kettle;
  width: string | number;
  height: string | number;
  dynamicallySetAspectRatio?: boolean;
  children: {
    playButton: Maybe<JSX.Element>;
  };
}

interface State {
  playerSelection: SelectedPlayer;
}

const logFailure = (videoResource: ReqHlsVideoAssetResource) => (
  failure: VideoSelectionFailure
): void => {
  switch (failure.kind) {
    case 'missing-vimeo-id':
      warnAndNotify(
        'VimeoPlayerRenderingFailure',
        `Vimeo player applies for a user, but Vimeo ID is missing from the video asset ${videoResource.payload.uuid}`,
        videoResource
      );
      break;
    case 'missing-progressive-low':
      warnAndNotify(
        'ProgressiveLowRenderingFailure',
        `Low progressive stream is missing from the video asset ${videoResource.payload.uuid}.`,
        videoResource
      );
      break;
    case 'missing-progressive-medium':
      warnAndNotify(
        'ProgressiveMediumRenderingFailure',
        `Medium progressive stream is missing from the video asset ${videoResource.payload.uuid}.`,
        videoResource
      );
      break;
    case 'missing-progressive-high':
      warnAndNotify(
        'ProgressiveHighRenderingFailure',
        `High progressive stream is missing from the video asset ${videoResource.payload.uuid}.`,
        videoResource
      );
      break;
    case 'missing-adaptive-sources':
      warnAndNotify(
        'AdaptivePlayerRenderingFailure',
        `Adaptive player applies for a user, but adaptive sources are missing from the video asset ${videoResource.payload.uuid}. Falling back to ProgressivePlayer.`,
        videoResource
      );
      break;
    default:
      assertNever(failure);
  }
};

/**
 * Video will take the user's context (browser and server) into account in
 * order to determine the proper video player to render, which will be either:
 *  - VimeoPlayer playing a video uploaded to Vimeo,
 *  - JWPlayer playing a progressive source,
 *  - JWPlayer playing an adaptvie source, falling back to a progressive source
 *
 * NoPlayer is rendered in the case that no appropriate player can be
 * determined by the user's context. This should not happen. The rendering of
 * NoPlayer will trigger an alert.
 */
@observer
class ReqHlsVideo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playerSelection: undetermined(),
    };
  }

  componentDidMount() {
    selectPlayer(window.navigator.userAgent, this.props.videoResource).fork(
      noop,
      ({ errors, result }) => {
        errors.forEach(logFailure(this.props.videoResource));
        this.setState({ ...this.state, playerSelection: result });
      }
    );
  }

  render() {
    const forwardProps = { ...this.props, selectedPlayer: this.state.playerSelection };
    const { playButton } = forwardProps.children;
    log(
      'Player kind',
      forwardProps.selectedPlayer.kind,
      'for',
      forwardProps.videoResource.payload.uuid
    );
    return (
      <div data-test-video={this.props.id}>
        {playButton.getOrElseValue(<></>)}
        <ReqHlsRenderedPlayer {...forwardProps} />
        {forwardProps.videoResource.payload.courseRegProgramId
          .map((courseRegProgramId) => (
            <VideoEngagement
              kettle={this.props.kettle}
              assetId={forwardProps.videoResource.payload.id}
              courseRegProgramId={courseRegProgramId}
            />
          ))
          .getOrElse(() => (
            <></>
          ))}
        {forwardProps.videoResource.payload.surveyCompletionTracking
          .map((surveyTracking) => (
            <SurveyModal kettle={this.props.kettle} surveyTrackingResource={surveyTracking} />
          ))
          .getOrElse(() => (
            <></>
          ))}
      </div>
    );
  }
}

export default ReqHlsVideo;
