import { AlreadyTranslated, NotTranslated } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../../../Translations';
import ModalStore from '../../../../../../Modal/Store';
import PortalModal from '../../../../../../PortalModal';
import { CoachProfileResource, SelectFormFieldOptionImage } from '../../../../../Types';
import ProfileImage from '../ProfileImage';
import CoachModalIntroductoryVideo from './CoachModalIntroductoryVideo';
import * as style from './style.module.css';

interface Props {
  modalStore: ModalStore;
  coachProfileResource: CoachProfileResource;
  image: Maybe<SelectFormFieldOptionImage>;
  onSelect: () => void;
}

const CoachModal: React.FC<Props> = ({ modalStore, coachProfileResource, image, onSelect }) => {
  const handleSelect = () => {
    onSelect();
    modalStore.close();
  };

  return (
    <PortalModal modalStore={modalStore}>
      <div>
        <div className={style.coachHeading}>
          <ProfileImage image={image} />
          <p className={style.coachName}>
            <NotTranslated text={coachProfileResource.payload.name} />
          </p>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.description}>
            <div className={style.descriptionHeading}>
              <T kind="Coaching Style" />
            </div>
            <div>
              <AlreadyTranslated content={coachProfileResource.payload.coachingStyle} />
            </div>
            <div className={style.descriptionHeading}>
              <T kind="Leadership Expertise" />
            </div>
            <div>
              <AlreadyTranslated content={coachProfileResource.payload.coachLeadershipExperience} />
            </div>
          </div>
          <div className={style.videoSection}>
            <div className={style.descriptionHeading}>
              <T kind="Introductory Video" />
            </div>
            <CoachModalIntroductoryVideo video={coachProfileResource.payload.introductoryVideo} />
          </div>
        </div>
      </div>
      <div className={style.buttonWrapper}>
        <button className={style.submitButton} onClick={handleSelect}>
          <T kind="Select this Coach" />
        </button>
      </div>
    </PortalModal>
  );
};

export default observer(CoachModal);
