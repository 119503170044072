import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { IconListItemTitle } from '../../../FreeBusyTestStore/Types';
import { T } from '../../../Translations';
import CompleteSegmentIcon from '../../Cutesies/CompleteSegmentIcon';

interface Props {
  title: IconListItemTitle;
}

function ToolLibraryListItem({ title }: Props) {
  return (
    <div className="flex">
      <div className="mr-2.5 h-5 w-5">
        <CompleteSegmentIcon />
      </div>
      <Typography>
        <T kind={title} />
      </Typography>
    </div>
  );
}

export default observer(ToolLibraryListItem);
