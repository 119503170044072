import * as React from 'react';

const LearningPlatformGraphic: React.FC = () => {
  return (
    <svg width="49" height="43" viewBox="0 0 49 43" fill="none">
      <path
        d="M28.2751 14.8041L20.5658 11.505C20.4342 11.4572 20.3205 11.3701 20.2401 11.2555C20.1598 11.1409 20.1167 11.0043 20.1167 10.8643C20.1167 10.7243 20.1598 10.5877 20.2401 10.4731C20.3205 10.3584 20.4342 10.2713 20.5658 10.2236L28.2751 6.94487C28.448 6.86883 28.6348 6.82959 28.8238 6.82959C29.0127 6.82959 29.1995 6.86883 29.3725 6.94487L38.5677 10.8916L29.3657 14.8041C29.1937 14.8792 29.008 14.918 28.8204 14.918C28.6327 14.918 28.447 14.8792 28.2751 14.8041Z"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4104 12.6775V17.4489C34.4119 17.5778 34.3769 17.7045 34.3093 17.8143C34.2417 17.9241 34.1444 18.0125 34.0287 18.0692L29.4617 20.3595C29.2697 20.4576 29.0571 20.5088 28.8414 20.5088C28.6258 20.5088 28.4132 20.4576 28.2211 20.3595L23.661 18.076C23.544 18.0203 23.4452 17.9323 23.3764 17.8224C23.3076 17.7126 23.2716 17.5854 23.2725 17.4557V12.6843"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5679 10.8916V15.7653"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5682 15.7653L40.4767 20.8639C40.4931 20.906 40.5011 20.9509 40.5 20.996C40.4989 21.0411 40.4889 21.0856 40.4705 21.1268C40.4521 21.168 40.4257 21.2051 40.3929 21.2361C40.36 21.267 40.3213 21.2911 40.2791 21.307C40.2365 21.3171 40.1921 21.3171 40.1495 21.307H36.98C36.8878 21.307 36.7993 21.2704 36.7341 21.2052C36.6689 21.14 36.6323 21.0516 36.6323 20.9594C36.6258 20.9187 36.6258 20.8773 36.6323 20.8366L38.5477 15.738"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2649 24.7151C32.7335 27.5783 31.1262 30.1295 28.7729 31.845V38.1501"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0763 38.1707V34.6602H15.9107C14.9802 34.6584 14.0884 34.288 13.4304 33.6301C12.7725 32.9721 12.4021 32.0803 12.4003 31.1498V27.6394H9.11478C9.03779 27.6422 8.96101 27.6297 8.88884 27.6027C8.81667 27.5758 8.75052 27.5348 8.69418 27.4823C8.63784 27.4298 8.59241 27.3666 8.56049 27.2965C8.52856 27.2264 8.51077 27.1507 8.50813 27.0737C8.49729 27.0128 8.49729 26.9505 8.50813 26.8896C10.4644 20.8231 11.9163 14.3612 17.3694 12.105"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LearningPlatformGraphic;
