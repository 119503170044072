import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import TeamsStore from '../../TeamsStore';
import ReplyNotificationsRouter from '../Platform/SupplementalPanel/ReplyNotificationsRouter';
import UnreadMessageNotificationsRouter from '../Platform/SupplementalPanel/UnreadMessageNotificationsRouter';

interface Props {
  teamsStore: Maybe<TeamsStore>;
}

const MessageNotifications: React.FC<Props> = ({ teamsStore }) => {
  return teamsStore
    .map((store) => (
      <>
        <UnreadMessageNotificationsRouter teamsStore={store} />
        <ReplyNotificationsRouter />
      </>
    ))
    .getOrElse(() => <></>);
};

export default observer(MessageNotifications);
