import { action, observable } from 'mobx';
import { OpenableState } from '../../Openable';

/**
 * @deprecated Use useModal hook in place of this store
 */
class ModalStore {
  @observable
  state: OpenableState = { kind: 'closed' };

  @action
  close = () => {
    this.state = { kind: 'closed' };
  };

  @action
  open = () => {
    this.state = { kind: 'open' };
  };
}

export default ModalStore;
