import * as React from 'react';
import { ProgramSegment } from '../../../../../../../ProgramStore/Types';
import TimeToComplete from '../../../../../../TimeToComplete';

interface Props {
  segment: ProgramSegment;
}

const SegmentInfo: React.FC<Props> = ({ segment }) => (
  <div>
    <TimeToComplete ttc={segment.timeToComplete} />
  </div>
);

export default SegmentInfo;
