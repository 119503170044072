import { Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';
import { EventsResource } from '../EventsStore/Types';
import { ProgramDetailResource } from '../ProgramStore/Types';
import { Linkable, Resource } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
  linkable: Linkable;
}

interface Loading {
  kind: 'loading';
  linkable: Linkable;
}

interface Ready {
  kind: 'ready';
  calendarResource: CalendarResource;
}

interface Refresh {
  kind: 'refresh';
  calendarResource: CalendarResource;
}

export type Navigable = 'prev' | 'self' | 'next';

interface Navigating {
  kind: 'navigating';
  calendarResource: CalendarResource;
  nav: Navigable;
}

export const waiting = (linkable: Linkable): Waiting => ({
  kind: 'waiting',
  linkable,
});

export const loading = (linkable: Linkable): Loading => ({
  kind: 'loading',
  linkable,
});

export const navigating = (calendarResource: CalendarResource, nav: Navigable): Navigating => ({
  kind: 'navigating',
  calendarResource,
  nav,
});

export const ready = (calendarResource: CalendarResource): Ready => ({
  kind: 'ready',
  calendarResource,
});

export const refresh = (calendarResource: CalendarResource): Refresh => ({
  kind: 'refresh',
  calendarResource,
});

export type CalendarState = Waiting | Loading | Navigating | Ready | Refresh | Error;

export interface CurrentModule {
  courseId: number;
  currentModuleId: Maybe<number>;
}

export type MonthProgramDetailResources = ReadonlyArray<ProgramDetailResource>;

export interface Calendar {
  eventsResource: EventsResource;
  firstDayOfMonth: Date;
  activeProgramResources: MonthProgramDetailResources;
}

export type CalendarResource = Resource<Calendar>;
