import { observer } from 'mobx-react';
import { ButtonProps } from '@execonline-inc/execonline-ui';
import { Exitable } from '../../../Common/Experience/Actions';
import { exitToDashboard, exitToProgram } from '../../../Common/Experience/Actions/Exitable/Types';
import { ReturnableExperience } from '../../../Common/Experience/Types';
import { T } from '../../../../../Translations';

interface Props {
  experience: ReturnableExperience;
  buttonProps?: ButtonProps;
}

function ReturnableAction({ experience, buttonProps }: Props) {
  const exitContext = () => {
    switch (experience.offeringType) {
      case 'aep':
      case 'epc':
      case 'msuite':
        return exitToProgram(experience.programId);
      case 'coaching':
      case 'program-sequence':
      case 'group-coaching':
        return exitToDashboard();
    }
  };

  return (
    <Exitable buttonProps={{ fullWidth: true, ...buttonProps }} exitTo={exitContext}>
      <T kind="Return to Program" />
    </Exitable>
  );
}

export default observer(ReturnableAction);
