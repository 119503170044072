import { CommentLanguageIcon, Typography, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import FormattedLanguages from './FormattedLanguages';
import { LanguageAvailabilityProps, formatLanguagesList } from './Types';

function ExperienceLanguageAvailability({ experience, className }: LanguageAvailabilityProps) {
  const languagesList = experience.availableLanguages.payload.map(
    ({ payload }) => payload.englishName,
  );

  const formattedLanguages = formatLanguagesList(languagesList);
  return (
    <div className={cn('flex items-center gap-x-2.5', className)}>
      <CommentLanguageIcon size="md" color="inherit" />
      <Typography variant="bodyMedium" color="inherit">
        <T
          kind="Available in <span>{{languages}}</span>"
          languages={formattedLanguages.join(', ')}
          span={() => <FormattedLanguages languages={formattedLanguages} />}
        />
      </Typography>
    </div>
  );
}

export default observer(ExperienceLanguageAvailability);
