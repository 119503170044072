import { cn, Typography, TypographyProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function ExperienceSectionDescription({ className, children, ...others }: TypographyProps) {
  return (
    <Typography
      data-testid="learning-collection-experience-section-description"
      className={cn('mt-4 !text-sm leading-normal text-black sm:!text-lg', className)}
      variant="bodyLarge"
      as="p"
      {...others}
    >
      {children}
    </Typography>
  );
}

export default observer(ExperienceSectionDescription);
