import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConvertedPptStore from '../../../../../LectureConvertedPptStore';
import ConvertedPptReactions from '../../../../../LectureConvertedPptStore/ConvertedPptReactions';
import ConvertedPptVideoReactions from '../../../../../LectureConvertedPptStore/ConvertedPptStepReactions';
import { LectureSegment } from '../../../../../LectureStore/Types';
import * as style from '../../style.module.css';
import PptContent from './PptContent';

interface Props {
  segment: LectureSegment;
  kettle: Kettle;
  refEl: React.RefObject<HTMLIFrameElement>;
  refWrapperEl: React.RefObject<HTMLDivElement>;
  pptSizerEl: React.RefObject<HTMLDivElement>;
}

@observer
class CurrentSlidePpt extends React.Component<Props, {}> {
  private convertedPptStore: ConvertedPptStore;

  constructor(props: Props) {
    super(props);
    this.convertedPptStore = new ConvertedPptStore(this.props.segment, this.props.kettle);
  }

  componentDidMount() {
    this.convertedPptStore.loading();
  }

  render() {
    const { segment, refWrapperEl, refEl, pptSizerEl } = this.props;

    return (
      <div
        className={style.currentSlide}
        ref={refWrapperEl}
        data-test-current-slide-ppt={segment.id}
      >
        <PptContent pptResult={segment.ppt} refEl={refEl} store={this.convertedPptStore} />
        <ConvertedPptReactions store={this.convertedPptStore} />
        <ConvertedPptVideoReactions store={this.convertedPptStore} />
        <div ref={pptSizerEl} />
      </div>
    );
  }
}

export default CurrentSlidePpt;
