import { when } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import ProgramMembersStore from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { PresenceMember } from '../Socket/PresenceChannel/PresenceMembersStore/Types';
import { ProgramMembersState } from './Types';

export interface Props extends EAProps<ProgramMembersStore> {
  store: ProgramMembersStore;
  programMembers: ReadonlyArray<PresenceMember>;
}

const updateLoadedMemberStatus = (
  programMembers: number,
  shownMembers: Maybe<number>,
  store: ProgramMembersStore
) => {
  shownMembers.do((members) =>
    when(members >= programMembers, members).cata({
      Just: store.allMembersLoaded,
      Nothing: () => store.ready(members),
    })
  );
};

class ProgramMembersReactions extends ErrorActionableReaction<
  ProgramMembersStore,
  ProgramMembersState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ProgramMembersState) => {
    const { programMembers, store } = this.props;
    switch (state.kind) {
      case 'error':
      case 'ready':
      case 'all-members-loaded':
        break;
      case 'update-members':
      case 'loading-more':
        updateLoadedMemberStatus(programMembers.length, store.shownMembers, store);
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramMembersReactions;
