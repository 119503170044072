import { observer } from 'mobx-react';
import React from 'react';
import { T } from '../../../../../../Translations';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { AlreadyTranslated } from '@execonline-inc/translations';
import * as style from '../../style.module.css';
import LighOnEmptyIcon from '../../../../../Cutesies/LighOnEmptyIcon';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const Competencies: React.FC<Props> = ({ externalProgramResource }) => {
  const resources = externalProgramResource.payload.primaryCompetencies;
  return (
    <div>
      <div className={`${style.competenciesText} ${style.competenciesQuantity}`}>
        <LighOnEmptyIcon />
        <T kind="{{count}} Competency" count={resources.length} />
      </div>
      <div className="inline-flex">
        {resources.map((competency) => {
          return (
            <div
              className={`${style.competenciesText} ${style.competenciesList}`}
              key={competency.payload.id}
            >
              <AlreadyTranslated content={competency.payload.name} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Competencies);
