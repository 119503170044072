import { warn } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { always, assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Maybe } from 'maybeasy';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { CloseAction } from '../../../../../components/Openable';
import { handleError } from '../../../../../ErrorActionableReaction';
import { warnAndNotifyDecoderError } from '../../../../../Resource/Decoders';
import { expiredAccessModalStore } from '../../ExpiredAccessModal/Modal/Store';
import EnrollmentStore from '../Store';
import { errorResourceDecoder } from '../Store/Decoders';
import { CommerceLoadingError, EnrollmentError, LoadingError } from '../Store/Types';

const errorResourceDecoderErrorMsg =
  'Error occurred when decoding enrollment resource error details';

const joinEnrollmentErrors = (errors: NonEmptyList<EnrollmentError>): string =>
  errors
    .map((error) => error.message.text)
    .toArray()
    .join(', ');

const alreadyTranslatedErrors = (errors: NonEmptyList<EnrollmentError>): AlreadyTranslatedText => ({
  kind: 'already-translated-text',
  text: joinEnrollmentErrors(errors),
});

const handleServerErrorKind = (
  errors: NonEmptyList<EnrollmentError>,
  action: Maybe<CloseAction>,
) => {
  errors.map((error) => {
    switch (error.kind) {
      case 'access-expired':
        action.map((action) => action());
        expiredAccessModalStore.open();
        break;
      case 'program-conflict':
        break;
      default:
        assertNever(error);
    }
  });
};

const handleValidationError = (
  store: EnrollmentStore,
  error: BadStatus,
  action: Maybe<CloseAction>,
): void => {
  errorResourceDecoder
    .decodeJson(error.response.body)
    .elseDo(warnAndNotifyDecoderError(errorResourceDecoderErrorMsg))
    .mapError(always(errorResourceDecoderErrorMsg))
    .andThen(({ errors }) => toResult('Enrollment validation error', fromArrayMaybe(errors)))
    .do((errors) => {
      warn(`Failed to complete enrollment: ${joinEnrollmentErrors(errors)}`);
      store.serverError(alreadyTranslatedErrors(errors));
      handleServerErrorKind(errors, action);
    })
    .elseDo((err) => {
      warn(`Failed to complete enrollment: ${err}`);
      store.error('Failed to complete enrollment');
    });
};

export const handleEnrollError =
  (store: EnrollmentStore, action: Maybe<CloseAction>) => (error: LoadingError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Discovery portal link is unavailable');
        break;
      case 'bad-status':
        handleEnrollBadStatus(store, error, action);
        break;
      default:
        handleError(store, error);
    }
  };

const handleEnrollBadStatus = (
  store: EnrollmentStore,
  error: BadStatus,
  action: Maybe<CloseAction>,
): void => {
  switch (error.response.status) {
    case 403:
      handleValidationError(store, error, action);
      break;
    default:
      handleError(store, error);
  }
};

export const handleCreatePaymentIntentError =
  (store: EnrollmentStore) => (error: CommerceLoadingError) => {
    switch (error.kind) {
      case 'missing-var-error':
      case 'stripe-loading-error':
        store.paymentLoadingError(
          'There was a problem loading the payment form. We are investigating. Please try again later.',
        );
        break;
      case 'missing-link-error':
        store.error('Discovery portal link is unavailable');
        break;
      case 'bad-status':
        warn(`Failed to create payment intent: ${error.response}`);
        store.paymentLoadingError(
          'There was a problem loading the payment form. We are investigating. Please try again later.',
        );
        break;
      case 'user-not-found':
        store.error('There was a problem setting up payment!');
        break;
      default:
        handleError(store, error);
    }
  };
