import { just } from 'maybeasy';
import * as React from 'react';
import { TPlainTextKey } from '../../../../../Translations';
import Loader from '../../../../Loader';
import Faker from '../../../../ProgramOverview/Faker';
import * as style from '../../../style.module.css';

interface Props {
  text?: TPlainTextKey;
}

const ProgramOverviewFaker: React.FC<Props> = ({ text = 'loading content' }) => (
  <div className={style.loading}>
    <Loader text={just(text)} />
    <Faker />
  </div>
);

export default ProgramOverviewFaker;
