import { observer } from 'mobx-react';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  onError: (error: unknown, errorInfo: React.ErrorInfo) => void;
}

/**
 * In some cases it can be very useful to determine the component stack trace for an error.
 *
 * Unfortunately, the only way to get a component stack trace from React is to throw an error
 * and catch it.
 *
 * This component implements the error boundary pattern as described here:
 *   https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * The error must be thrown AFTER a successful render of this component in order to be caught.
 */
class ErrorBoundary extends React.Component<Props> {
  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo): void {
    this.props.onError(error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default observer(ErrorBoundary);
