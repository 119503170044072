import { mapMaybe } from '@execonline-inc/collections';
import { warn } from '@execonline-inc/logging';
import { fromBool, toResult } from '@execonline-inc/maybe-adapter';
import { assertNever, noop } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Maybe, fromEmpty, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import ParsedURL from 'url-parse';
import { InvitationStore } from '.';
import { callApi } from '../../../../Appy';
import { handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { assertionBackendToUrls } from '../../../../SingleSignOn/Functions';
import {
  AlreadyTranslatedText,
  TPlainTextKey,
  TranslationsState,
  translation,
} from '../../../../Translations';
import { SharedInvitationResource } from '../SharedInvitationResourceStore/Types';
import { userResourceDecoder } from './Decoders';
import {
  AuthenticatingEmailDomain,
  FormErrors,
  InvitationError,
  LoadingError,
  NewUser,
  RegisterUserPayloadOut,
  Registering,
  RegistrationError,
  State,
  UserResource,
  ValidatingEmailFromIdentifiedUser,
  ValidationError,
  validationError,
} from './Types';

const processUserLink =
  (email: string) =>
  (link: Link): Link => {
    const url = new ParsedURL(link.href, true);
    return {
      ...link,
      href: url.set('query', { ...url.query, email }).toString(),
    };
  };

const getUser = callApi(userResourceDecoder, {});

const registerUser = (link: Link, data: RegisterUserPayloadOut) => callApi(succeed({}), data)(link);

const handleEmailVerificationError = (
  store: InvitationStore,
  state: AuthenticatingEmailDomain | ValidatingEmailFromIdentifiedUser,
  error: InvitationError,
): void => {
  switch (error.kind) {
    case 'validation-error':
      store.requestingEmailAddress({ ...state, alertMessage: just(error.message) });
      break;
    case 'missing-link-error':
    case 'bad-payload':
    case 'bad-status':
    case 'bad-url':
    case 'missing-application-id':
    case 'missing-api-compatibility':
    case 'network-error':
    case 'timeout':
      handleLoadingError(store, error);
      break;
    default:
      assertNever(error);
  }
};

const handleRegistrationError = (
  store: InvitationStore,
  state: Registering,
  error: RegistrationError,
): void => {
  switch (error.kind) {
    case 'validation-error':
      store.requestingProfileInfoPasswordBackend({
        ...state,
        alertMessage: just(error.message),
      });
      break;
    case 'bad-payload':
    case 'bad-status':
    case 'bad-url':
    case 'missing-application-id':
    case 'missing-api-compatibility':
    case 'network-error':
    case 'timeout':
      handleError(store, error);
      break;
    default:
      assertNever(error);
  }
};

const handleLoadingError = (store: InvitationStore, error: LoadingError): void => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('An unexpected error occurred.');
      warnAndNotify(`Missing link in SharedInvitationResource`, 'MissingLinkError', { error });
      break;
    case 'bad-payload':
    case 'bad-status':
    case 'bad-url':
    case 'missing-application-id':
    case 'missing-api-compatibility':
    case 'network-error':
    case 'timeout':
      handleError(store, error);
      break;
    default:
      assertNever(error);
  }
};

const handleNewUserResponse = (
  store: InvitationStore,
  state: AuthenticatingEmailDomain | ValidatingEmailFromIdentifiedUser,
  userResource: UserResource,
  newUser: NewUser,
  opaqueUseCaseIdentifier: string,
) => {
  switch (newUser.assertionBackend.payload.kind) {
    case 'locked-access':
      Task.succeed<MissingLinkError, ReadonlyArray<Link>>(userResource.links)
        .andThen(findLinkT('create'))
        .fork(warn, (createLink) =>
          store.requestingProfileInfoLockedAccess({
            createLink,
            emailAddress: state.emailAddress,
            firstName: '',
            lastName: '',
            termsAccepted: false,
            alertMessage: nothing(),
          }),
        );
      break;
    case 'password-backend':
      Task.succeed<MissingLinkError, ReadonlyArray<Link>>(userResource.links)
        .andThen(findLinkT('create'))
        .fork(warn, (createLink) =>
          store.requestingProfileInfoPasswordBackend({
            createLink,
            emailAddress: state.emailAddress,
            firstName: '',
            lastName: '',
            termsAccepted: false,
            alertMessage: nothing(),
          }),
        );
      break;
    case 'third-party-backend':
      assertionBackendToUrls(newUser.assertionBackend).cata({
        Err: (err) => warn(`Missing SSO link: ${err.rel}`),
        Ok: (urls) =>
          store.instantSso({
            urls,
            opaqueUseCaseIdentifier,
            username: just(newUser.username),
          }),
      });
      break;
    default:
      assertNever(newUser.assertionBackend.payload);
  }
};

const handleUserVerificationResponse =
  (
    store: InvitationStore,
    state: AuthenticatingEmailDomain | ValidatingEmailFromIdentifiedUser,
    ts: TranslationsState,
    opaqueUseCaseIdentifier: string,
  ) =>
  (userResource: UserResource): void => {
    switch (userResource.payload.kind) {
      case 'pending-user':
        store.pendingEmailConfirmation({
          emailAddress: state.emailAddress,
        });
        break;
      case 'invalid-user':
        store.requestingEmailAddress({
          emailAddress: state.emailAddress,
          alertMessage: just({
            kind: 'already-translated-text',
            text: translation('We are unable to proceed at this time...')(ts),
          }),
        });
        break;
      case 'outside-org-user':
        store.requestingEmailAddress({
          emailAddress: state.emailAddress,
          alertMessage: just({
            kind: 'already-translated-text',
            text: userResource.payload.errorMessage
              .map((message) => message.text)
              .getOrElseValue(translation('We are unable to proceed at this time...')(ts)),
          }),
        });
        break;
      case 'valid-user':
        store.authenticatingUser(userResource.payload.uuid, userResource.payload.experienceId);
        break;
      case 'new-user':
        handleNewUserResponse(
          store,
          state,
          userResource,
          userResource.payload,
          opaqueUseCaseIdentifier,
        );
        break;
      default:
        assertNever(userResource.payload);
    }
  };

const formErrors = (results: ReadonlyArray<Result<TPlainTextKey, unknown>>): Maybe<FormErrors> =>
  fromArrayMaybe(
    mapMaybe((r) => r.cata({ Err: (err) => just(err), Ok: () => nothing() }), results),
  );

const formErrorsMessage = (errors: FormErrors, ts: TranslationsState): AlreadyTranslatedText => {
  const msg = errors
    .toArray()
    .map((err) => translation(err)(ts))
    .join(', ');
  return { kind: 'already-translated-text', text: msg };
};

const validateEmailForm = (
  state: AuthenticatingEmailDomain,
  ts: TranslationsState,
): Task<ValidationError, AuthenticatingEmailDomain> =>
  new Task((reject, resolve) => {
    formErrors([
      toResult<TPlainTextKey, string>(
        'Email address must be provided',
        fromEmpty(state.emailAddress),
      ),
    ])
      .map((formErrors) => formErrorsMessage(formErrors, ts))
      .map(validationError)
      .cata({ Just: reject, Nothing: () => resolve(state) });
    return noop;
  });

const validateRegistrationForm = (
  state: Registering,
  ts: TranslationsState,
): Task<ValidationError, Registering> =>
  new Task((reject, resolve) => {
    formErrors([
      toResult<TPlainTextKey, string>('First name must be provided', fromEmpty(state.firstName)),
      toResult<TPlainTextKey, string>('Last name must be provided', fromEmpty(state.lastName)),
      toResult<TPlainTextKey, boolean>(
        'Terms and conditions must be accepted',
        fromBool(state.termsAccepted, true),
      ),
    ])
      .map((formErrors) => formErrorsMessage(formErrors, ts))
      .map(validationError)
      .cata({ Just: reject, Nothing: () => resolve(state) });
    return noop;
  });

interface Props extends RCProps<InvitationStore> {
  sharedInvitationResource: SharedInvitationResource;
  ts: TranslationsState;
  opaqueUseCaseIdentifier: string;
}

class InvitationReactions extends ReactionComponent<InvitationStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store, sharedInvitationResource, ts } = this.props;

    switch (state.kind) {
      case 'authenticating-email-domain':
        Task.succeed<InvitationError, AuthenticatingEmailDomain>(state)
          .andThen((state) => validateEmailForm(state, ts))
          .map(() => sharedInvitationResource.links)
          .andThen(findLinkT('user'))
          .map(processUserLink(state.emailAddress))
          .andThen(getUser)
          .fork(
            (err) => handleEmailVerificationError(store, state, err),
            handleUserVerificationResponse(store, state, ts, this.props.opaqueUseCaseIdentifier),
          );
        break;
      case 'validating-email-from-identified-user':
        Task.succeed<InvitationError, ValidatingEmailFromIdentifiedUser>(state)
          .map(() => sharedInvitationResource.links)
          .andThen(findLinkT('user'))
          .map(processUserLink(state.emailAddress))
          .andThen(getUser)
          .fork(
            (err) => handleEmailVerificationError(store, state, err),
            handleUserVerificationResponse(store, state, ts, this.props.opaqueUseCaseIdentifier),
          );
        break;
      case 'registering':
        Task.succeed<RegistrationError, Registering>(state)
          .andThen((state) => validateRegistrationForm(state, ts))
          .andThen(() =>
            registerUser(state.createLink, {
              user: {
                email: state.emailAddress,
                first_name: state.firstName,
                last_name: state.lastName,
              },
            }),
          )
          .fork((err) => handleRegistrationError(store, state, err), store.registered);
        break;
      case 'waiting':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'requesting-profile-info':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(InvitationReactions);
