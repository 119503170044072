import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const RevealIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 8 5">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        stroke={defaultTheme.variables['icon-accent-on-light']}
        d="M7.972.902 7.514.5 4.236 3.375.958.5.5.902l3.507 3.076.229.192.229-.192z"
      />
    </SVGIcon>
  );
};

export default observer(RevealIcon);
