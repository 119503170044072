import * as React from 'react';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import { Position } from '../../PositionContext';
import LearningPartnersDashboard from './LearningPartnersDashboard';
import LearningPartnersPanel from './LearningPartnersPanel';

interface Props {
  programDetailResource: ProgramDetailResource;
  position: Position;
}

const LearningPartnersRendererImpl: React.FC<Props> = ({ programDetailResource, position }) => {
  switch (position.kind) {
    case 'supPanel':
      return <LearningPartnersPanel programDetailResource={programDetailResource} />;
    case 'overview':
    case 'nav':
    case 'dashboard':
    case 'tooltip':
    case 'chat':
    case 'participant':
    case 'footer':
    case 'none':
      return <LearningPartnersDashboard programDetailResource={programDetailResource} />;
  }
};

export default LearningPartnersRendererImpl;
