import { readVarM } from '@execonline-inc/environment';
import { initializeMessageBus } from '@execonline-inc/message-bus.private';
import { assertNever } from '../ClickOutsideStore';
import { InactivityLogoutTracker } from '../Inactivity/Core';

export { Host } from './Host';

export const { MessageBus, useMessageBus } = initializeMessageBus({
  whoami: 'learning',
  portalHost: readVarM('REACT_APP_MESSAGE_BUS_PORTAL_HOST'),
  onMessage: (message) => {
    switch (message.kind) {
      case 'user-activity':
        InactivityLogoutTracker.activeExternally(message.expiration);
        break;
      default:
        assertNever(message.kind);
    }
  },
});
