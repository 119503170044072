import { observer } from 'mobx-react';
import { SvgIcon, SvgIconProps } from '@execonline-inc/execonline-ui';

function ScheduledProgramImage(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g fill="none">
        <defs>
          <linearGradient
            id="linear0"
            gradientUnits="userSpaceOnUse"
            x1="22.0002"
            y1="0.000245488"
            x2="4.00018"
            y2="18.0002"
            gradientTransform="matrix(1.2,0,0,1.2,0,0)"
          >
            <stop stopColor="#FA04FF" />
            <stop offset="1" stopColor="#A000A4" />
          </linearGradient>
        </defs>

        <path
          fill="url(#linear0)"
          d="M 12 2 C 6.484375 2 2 6.484375 2 12 C 2 17.515625 6.484375 22 12 22 C 17.515625 22 22 17.515625 22 12 C 22 6.484375 17.515625 2 12 2 Z M 14.28125 15.28125 C 14.132812 15.425781 13.941406 15.5 13.75 15.5 C 13.558594 15.5 13.367188 15.425781 13.21875 15.28125 L 10.71875 12.78125 C 10.578125 12.640625 10.5 12.449219 10.5 12.25 L 10.5 6.75 C 10.5 6.335938 10.835938 6 11.25 6 C 11.664062 6 12 6.335938 12 6.75 L 12 11.941406 L 14.28125 14.21875 C 14.574219 14.511719 14.574219 14.988281 14.28125 15.28125 Z M 14.28125 15.28125 "
        />
      </g>
    </SvgIcon>
  );
}

export default observer(ScheduledProgramImage);
