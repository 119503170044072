import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../../Translations';
import { AvailabilityResource } from '../../../../../../Experience/Types';

interface Props {
  availability: AvailabilityResource;
}

const headerText = (kind: 'on-demand' | 'scheduled'): React.ReactElement => {
  switch (kind) {
    case 'on-demand':
      return <T kind="Start Now" />;
    case 'scheduled':
      return <T kind="Enroll Now" />;
  }
};

function AvailabilityHeader({ availability }: Props) {
  const { kind } = availability.payload;

  return <Typography>{headerText(kind)}</Typography>;
}

export default observer(AvailabilityHeader);
