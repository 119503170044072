import { assertNever } from '@kofno/piper';
import anime from 'animejs';
import LectureSlideStore, { Direction, State } from '../../../../../LectureSlideStore';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';

interface Props extends RCProps<LectureSlideStore> {
  elementToScroll: HTMLDivElement | null;
  distanceToScroll: number;
}

class ScrollReactions extends ReactionComponent<LectureSlideStore, State, Props> {
  tester = () => this.props.store.state;

  scroll(direction: Direction) {
    if (!this.props.elementToScroll) {
      return;
    }
    const el = this.props.elementToScroll;
    const scrollDistance = this.props.distanceToScroll;

    const distance = direction === 'left' ? scrollDistance * -1 : scrollDistance;

    anime({
      targets: el,
      scrollLeft: el.scrollLeft + distance,
      duration: 300,
      easing: 'easeOutQuad',
      complete: () => {
        this.props.store.ready();
      },
    }).play();
  }

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
        this.props.store.ready();
        break;
      case 'ready':
        break;
      case 'scrolling':
        this.scroll(state.direction);
        break;
      default:
        assertNever(state);
    }
  };
}

export default ScrollReactions;
