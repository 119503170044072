import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import {
  ExperienceCompetencyItems,
  getPrimaryCompetencies,
} from '../../../Experience/ExperienceCompetencies';
import { ExperienceResource } from '../../../Experience/Types';
import CompetenciesSection from '../CompetenciesSection';

interface Props {
  experienceResource: ExperienceResource;
}

function ProgramCompetencies({ experienceResource }: Props) {
  return getPrimaryCompetencies(experienceResource)
    .map((competencies) => (
      <CompetenciesSection>
        <ExperienceCompetencyItems competencies={competencies} />
      </CompetenciesSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramCompetencies);
