import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TPlainTextKey, translation, TranslationsContext } from '../../Translations';
import MenuDotsIcon from '../Cutesies/MenuDotsIcon';
import Dropdown from '../Dropdown';
import { DropdownListItem } from '../Dropdown/Types';
import * as style from './style.module.css';

interface Props {
  menuItems: DropdownListItem[];
  title: TPlainTextKey;
  ariaLabelText: TPlainTextKey;
}

const KebabMenu: React.FC<Props> = ({ menuItems, title, ariaLabelText }) =>
  fromEmpty(menuItems)
    .map(() => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <div className={style.user}>
            <Dropdown
              header={
                <button
                  title={translation(title)(ts)}
                  className={style.account}
                  id="menu"
                  role="button"
                  aria-label={translation(ariaLabelText)(ts)}
                  tabIndex={-1}
                  aria-haspopup="listbox"
                >
                  <MenuDotsIcon />
                </button>
              }
              list={menuItems}
              menuAlignment="left"
              aria-labelledby="menu"
              styleKind="default"
            />
          </div>
        )}
      </TranslationsContext.Consumer>
    ))
    .getOrElse(() => <></>);

export default observer(KebabMenu);
