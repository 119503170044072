import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
  viewBox: string;
  width?: string;
  height?: string;
  style?: React.CSSProperties;
}

const SVGIcon: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      focusable={false}
      aria-hidden="true"
      {...props}
    />
  );
};

export default observer(SVGIcon);
