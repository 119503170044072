import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Experience/Types';
import ExperienceProductDetails from './ExperienceProductDetails';
import ExperienceProgramDetails from './ExperienceProgramDetails';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceBodyContent({ experienceResource }: Props) {
  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return <ExperienceProgramDetails experienceResource={experienceResource} />;
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceBodyContent);
