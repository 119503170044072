import * as React from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import * as style from './style.module.css';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';

interface Props {
  label: Maybe<AlreadyTranslatedText>;
}

const Legend: React.FC<Props> = ({ label }) => {
  return label
    .map((l) => (
      <legend className={style.legend}>
        <AlreadyTranslated content={l} />
      </legend>
    ))
    .getOrElseValue(<></>);
};

export default observer(Legend);
