import * as React from 'react';
import { Assignment } from '../../../../TeamResourceStore/Types';
import { T } from '../../../../Translations';

interface Props {
  assignment: Assignment;
}

const AttachmentsHeader: React.FC<Props> = ({ assignment }) => {
  switch (assignment.kind) {
    case 'user-segment':
      return (
        <p>
          <T kind="Deliverable file(s) submitted" />
        </p>
      );
    case 'team-discussion':
      return (
        <p>
          <T kind="Attachments" />
        </p>
      );
  }
};

export default AttachmentsHeader;
