import {
  AppsIcon,
  IconButton,
  Menu,
  MenuSection,
  useMediaQuery,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { TranslationsContext } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { T, translation } from '../../../../Translations';
import { NavigationMenuItems } from '../Common';
import { NavigationBarMenuItemProps } from '../Types';
import { dropdownMenuItems, getIconSize } from './Types';

const menuId = 'applicationNavigationMenu';

function ApplicationDropdownMenu({ user }: NavigationBarMenuItemProps) {
  const anchorElementRef = useRef<HTMLButtonElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();
  const breakPoint = useMediaQuery();

  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <>
          <IconButton
            id={menuId}
            aria-haspopup="menu"
            aria-expanded={isOpen}
            ref={anchorElementRef}
            className="mr-4"
            title={translation('Platform Navigation Dropdown Menu')(ts)}
            aria-label={translation('Platform Navigation Dropdown Menu')(ts)}
            onClick={onOpenChange}
          >
            <AppsIcon size={getIconSize(breakPoint)} color="black" />
          </IconButton>
          <Menu
            aria-labelledby={menuId}
            open={isOpen}
            anchorElement={anchorElementRef.current}
            onClose={onClose}
          >
            <MenuSection title={<T kind="Switch to" />}>
              <NavigationMenuItems items={dropdownMenuItems(user)} />
            </MenuSection>
          </Menu>
        </>
      )}
    </TranslationsContext.Consumer>
  );
}

export default observer(ApplicationDropdownMenu);
