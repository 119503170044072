import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';

interface Props {
  staffRequestStatusStore: StaffRequestStore;
}

const CoachMessage: React.FC<Props> = ({ staffRequestStatusStore }) => {
  switch (staffRequestStatusStore.state.kind) {
    case 'ready':
      return <></>;
    case 'requested':
      return (
        <div className={style.coachMessage}>
          <T kind="Your request has been sent. A coach will be arriving soon!" />
        </div>
      );
    default:
      return assertNever(staffRequestStatusStore.state);
  }
};

export default observer(CoachMessage);
