import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { programUrl } from '../../ClientRoutes';
import { CommunityProgramResource } from '../../CommunitiesStore/Types';
import { NotTranslated, T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from '../TeamSummary/style.module.css';

interface Props {
  communityProgramResource: CommunityProgramResource;
}

const ProgramChatHeader: React.FC<Props> = ({ communityProgramResource }) => (
  <div className={style.header}>
    <Link to={programUrl(communityProgramResource.payload.id)} className={style.nameWrapper}>
      <h2 className={style.cardHeading}>
        <NotTranslated text={communityProgramResource.payload.name} />
      </h2>
      <div className={style.profileButton}>
        <T kind="Chat" />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </div>
    </Link>
  </div>
);

export default observer(ProgramChatHeader);
