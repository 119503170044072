import { always } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../../CommunitiesStore';
import { findLink } from '../../../LinkyLinky';
import LoadCommunities from './LoadCommunities';

interface Props {
  communitiesStore: CommunitiesStore;
}

const LoadMoreCommunities: React.FC<Props> = ({ communitiesStore }) =>
  communitiesStore.communitiesResource
    .andThen((resource) => findLink('next', resource.links).map(always(resource)))
    .map((resource) => (
      <LoadCommunities communitiesStore={communitiesStore} communitiesResource={resource} />
    ))
    .getOrElseValue(<></>);

export default observer(LoadMoreCommunities);
