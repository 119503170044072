import * as React from 'react';

export type Position =
  | None
  | Nav
  | SupPanel
  | Footer
  | Overview
  | Dashboard
  | Participant
  | Chat
  | Tooltip;

export interface None {
  kind: 'none';
}

export interface Nav {
  kind: 'nav';
}

export interface SupPanel {
  kind: 'supPanel';
}

export interface Footer {
  kind: 'footer';
}

export interface Overview {
  kind: 'overview';
}

export interface Dashboard {
  kind: 'dashboard';
}

export interface Participant {
  kind: 'participant';
}

export interface Chat {
  kind: 'chat';
}

export interface Tooltip {
  kind: 'tooltip';
}

const PositionContext = React.createContext<Position>({ kind: 'none' });

export default PositionContext;
