import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { AppyError, deleteToApi, postToApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import ActionMenuStore from './ActionMenuStore';
import { State } from './Types';

export interface Props extends EAProps<ActionMenuStore> {
  store: ActionMenuStore;
}

type ApiError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleApiError = (store: ActionMenuStore) => (error: ApiError) => {
  switch (error.kind) {
    case 'missing-link-error':
      warnAndNotify('MessageReactionReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

class ActionMenuReactions extends ErrorActionableReaction<ActionMenuStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'reaction-selected':
        store.reactionStore.updateReaction(state.emoji, state.reactionsResource);
        store.ready();
        break;
      case 'deleting-message':
        Task.succeed<ApiError, ReadonlyArray<Link>>(state.links)
          .andThen(findLinkT('delete'))
          .andThen(deleteToApi)
          .fork(handleApiError(store), store.ready);
        break;
      case 'reporting-message':
        Task.succeed<ApiError, ReadonlyArray<Link>>(state.links)
          .andThen(findLinkT('create'))
          .andThen(postToApi({}))
          .fork(handleApiError(store), store.messageReportSuccess);
        break;
      case 'message-report-requested':
      case 'message-delete-requested':
      case 'ready':
      case 'reaction-menu-open':
      case 'message-report-success':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ActionMenuReactions;
