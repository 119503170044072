import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../../SegmentStore';
import SegmentDetail from '../../../../../Segment/SegmentContent/SegmentDetail';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import * as style from '../style.module.css';

interface Props {
  store: SegmentStore;
  segmentResource: SegmentResource;
}

const SurveySegmentContent: React.FC<Props> = ({ store, segmentResource }) => (
  <div className={style.surveySegmentContent}>
    <SegmentDetail segmentResource={segmentResource} store={store} />
  </div>
);

export default observer(SurveySegmentContent);
