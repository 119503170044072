import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import {
  errorAlert,
  FlashAlert,
  paymentLoadingErrorAlert,
  serverErrorAlert,
} from '../../../../../Notifications/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../../Translations';
import { CloseAction } from '../../../../../components/Openable';
import { DiscoveryContextState } from '../../../DiscoveryContext';
import { CommerceType, UseCase } from '../../../Common/Experience/Types';
import {
  created,
  creating,
  EnrollableResource,
  EnrollmentResource,
  error,
  exiting,
  initializingPayment,
  PaymentIntentResource,
  paymentLoadingError,
  PaymentParams,
  readyForPayment,
  serverError,
  settingUp,
  State,
  waiting,
  waitingCommerce,
} from './Types';

class EnrollmentStore {
  @observable
  state: State = waiting();

  @action
  waiting = (): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'waiting-commerce':
      case 'creating':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'initializing-payment':
      case 'ready-for-payment':
        break;
      case 'created':
      case 'setting-up':
        this.state = waiting();
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  settingUp = (useCase: Maybe<UseCase>): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready-for-payment':
        this.state = settingUp(useCase);
        break;
      case 'setting-up':
      case 'waiting-commerce':
      case 'initializing-payment':
      case 'creating':
      case 'created':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  reset = (): void => {
    this.state = waiting();
  };

  @action
  waitingCommerce = (commerceType: CommerceType): void => {
    switch (this.state.kind) {
      case 'waiting':
        break;
      case 'setting-up':
        this.state = waitingCommerce(commerceType, this.state.useCase);
        break;
      case 'waiting-commerce':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  initializingPayment = (
    enrollableResource: EnrollableResource,
    discoveryContextState: DiscoveryContextState,
    action: Maybe<CloseAction>,
  ): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
        break;
      case 'waiting-commerce':
        this.state = initializingPayment(
          enrollableResource,
          discoveryContextState,
          action,
          this.state.useCase,
        );
        break;
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  resetPayment = (
    enrollableResource: EnrollableResource,
    discoveryContextState: DiscoveryContextState,
    action: Maybe<CloseAction>,
  ): void => {
    switch (this.state.kind) {
      case 'ready-for-payment':
        this.state = initializingPayment(
          enrollableResource,
          discoveryContextState,
          action,
          this.state.useCase,
        );
        break;
      case 'creating':
      case 'created':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'waiting':
      case 'setting-up':
      case 'initializing-payment':
      case 'waiting-commerce':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyForPayment =
    (
      enrollableResource: EnrollableResource,
      discoveryContextState: DiscoveryContextState,
      action: Maybe<CloseAction>,
    ) =>
    (paymentParams: PaymentParams): void => {
      switch (this.state.kind) {
        case 'waiting':
        case 'setting-up':
        case 'waiting-commerce':
          break;
        case 'initializing-payment':
          this.state = readyForPayment(
            paymentParams.paymentIntentResource,
            enrollableResource,
            discoveryContextState,
            action,
            paymentParams.stripe,
            this.state.useCase,
          );
          break;
        case 'ready-for-payment':
        case 'creating':
        case 'created':
        case 'exiting':
        case 'error':
        case 'server-error':
        case 'payment-loading-error':
          break;
        default:
          assertNever(this.state);
      }
    };

  @action
  creating = (
    enrollableResource: EnrollableResource,
    discoveryContextState: DiscoveryContextState,
    action: Maybe<CloseAction>,
  ): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = creating(enrollableResource, discoveryContextState, action);
        break;
      case 'setting-up':
      case 'waiting-commerce':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
      case 'exiting':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  created = (enrollmentResource: EnrollmentResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'created':
      case 'exiting':
      case 'initializing-payment':
      case 'ready-for-payment':
        break;
      case 'creating':
        this.state = created(enrollmentResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  exiting = (enrollmentResource: EnrollmentResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'exiting':
        break;
      case 'created':
        this.state = exiting(enrollmentResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'created':
      case 'exiting':
      case 'initializing-payment':
        this.state = error(message);
        break;
      case 'ready-for-payment':
        break;
      case 'creating':
        this.state = error(message);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  serverError = (message: AlreadyTranslatedText): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'created':
      case 'exiting':
      case 'initializing-payment':
      case 'ready-for-payment':
        break;
      case 'creating':
        this.state = serverError(message);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentLoadingError = (message: TPlainTextKey): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'created':
      case 'exiting':
        break;
      case 'initializing-payment':
        this.state = paymentLoadingError(message);
        break;
      case 'ready-for-payment':
      case 'creating':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'server-error':
        return just(this.state).map(serverErrorAlert);
      case 'payment-loading-error':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'exiting':
        return nothing();
    }
  }

  @computed
  get modalNotification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
      case 'server-error':
        return nothing();
      case 'payment-loading-error':
        return just(this.state).map(paymentLoadingErrorAlert);
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'creating':
      case 'created':
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'exiting':
        return nothing();
    }
  }

  @computed
  get paymentIntentResource(): Maybe<PaymentIntentResource> {
    switch (this.state.kind) {
      case 'error':
      case 'server-error':
      case 'payment-loading-error':
      case 'initializing-payment':
        return nothing();
      case 'ready-for-payment':
        return just(this.state.paymentIntentResource);
      case 'creating':
      case 'created':
      case 'waiting':
      case 'setting-up':
      case 'waiting-commerce':
      case 'exiting':
        return nothing();
    }
  }

  @computed
  get commerce(): boolean {
    switch (this.state.kind) {
      case 'payment-loading-error':
      case 'initializing-payment':
      case 'ready-for-payment':
      case 'waiting-commerce':
        return true;
      case 'error':
      case 'server-error':
      case 'creating':
      case 'created':
      case 'waiting':
      case 'setting-up':
      case 'exiting':
        return false;
    }
  }
}

export default EnrollmentStore;
