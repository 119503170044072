import { assertNever } from '@kofno/piper';
import PopoutStore from '../../../../Popout/PopoutStore';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import FocusTrapStore, { State } from './index';

interface Props extends RCProps<FocusTrapStore> {
  popoutStore: PopoutStore;
}

class FocusTrapReactions extends ReactionComponent<FocusTrapStore, State, Props> {
  tester = () => this.props.store.state;

  effect = () => {
    switch (this.props.popoutStore.state.kind) {
      case 'open':
        this.props.popoutStore.closed();
        break;
      case 'closed':
        break;
      default:
        assertNever(this.props.popoutStore.state);
    }
  };
}

export default FocusTrapReactions;
