import { action, computed, observable } from "mobx";
import { TPlainTextKey } from "../../../../../Translations";
import { Error, error } from "../../../../../ErrorHandling";
import { Maybe, just, nothing } from "maybeasy";
import { FlashAlert, errorAlert } from "../../../../../Notifications/Types";
import { Link } from "../../../../../Resource/Types";
import { ExternalProgramResource } from "../../../../../SegmentStore/Types";

interface Unenrolled {
  kind: 'unenrolled';
}

interface Redirecting {
  kind: 'redirecting';
  to: string;
}

interface Enrolling {
  kind: 'enrolling';
}

const unenrolled = (): Unenrolled => ({
  kind: 'unenrolled',
});

const redirecting = (to: string): Redirecting => ({
  kind: 'redirecting',
  to
});

const enrolling = (): Enrolling => ({
  kind: 'enrolling',
});

export type EnrollmentState = Unenrolled | Enrolling | Error | Redirecting

class EnrollmentStore {
  @observable
  state: EnrollmentState
  externalProgramResource: ExternalProgramResource

  constructor(externalProgramResource: ExternalProgramResource) {
    this.state = unenrolled();
    this.externalProgramResource = externalProgramResource
  }

  @action
  redirecting = (to: string) => {
    this.state = redirecting(to)
  };

  @action
  unenrolled = () => {
    this.state = unenrolled()
  };

  @action
  enrolling = () => {
    this.state = enrolling()
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get links(): ReadonlyArray<Link> {
    return this.externalProgramResource.links
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'enrolling':
      case 'redirecting':
      case 'unenrolled':
        return nothing();
    }
  }

}

export default EnrollmentStore

