import { useContext } from 'react';
import { EnrolledModalContext } from './Types';

export function useEnrolledModalContext() {
  const context = useContext(EnrolledModalContext);

  if (!context) {
    throw new Error(
      'useEnrolledModalContext has to be used within the EnrolledModalContextProvider.',
    );
  }

  return context;
}
