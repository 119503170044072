import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../../ConversationStore/Types';
import { findLink } from '../../../../LinkyLinky';
import ChatDeleteIcon from '../../../Cutesies/ChatDeleteIcon';
import * as style from './../style.module.css';
import ActionMenuButton from './ActionMenuButton';
import ActionMenuStore from './ActionMenuStore';

interface Props {
  store: ActionMenuStore;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
}

const DeleteThisMessage: React.FC<Props> = ({ messageResource, store }) =>
  just(messageResource.links)
    .andThen(findLink('delete'))
    .map(() => (
      <ActionMenuButton tooltipText="Delete this message">
        <button
          className={style.actionMenuButton}
          onClick={store.requestMessageDelete}
          data-test-action-menu-delete-message
        >
          <ChatDeleteIcon />
        </button>
      </ActionMenuButton>
    ))
    .getOrElse(() => <></>);

export default observer(DeleteThisMessage);
