import { observable, action } from 'mobx';
import { State, waiting, initializing, ready, selected } from './Types';

class CoachSelectOptionStore {
  @observable
  state: State = waiting();

  constructor(public id: string) {}

  @action
  initialize = () => {
    this.state = initializing();
  };

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  select = () => {
    this.state = selected();
  };
}
export default CoachSelectOptionStore;
