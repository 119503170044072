import { observer } from 'mobx-react';
import * as React from 'react';
import NoteStore from '../../../../NoteStore';
import { T } from '../../../../Translations';
import Button from '../../../Form/Button';
import Textarea from '../../../Form/Textarea';
import * as style from '../../style.module.css';

interface Props {
  store: NoteStore;
}

class NoteForm extends React.Component<Props> {
  setNoteContent = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.props.store.setContent(event.currentTarget.value);
  };
  submit = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    this.props.store.save();
  };

  cancel = () => {
    this.props.store.cancelEditing();
  };

  render() {
    return (
      <form className={style.form} data-test-note-form={true}>
        <Textarea
          onChange={this.setNoteContent}
          placeholder="add a note"
          name="content"
          title="Add Note"
          className={style.textarea}
          value={this.props.store.content.getOrElseValue('')}
          data-test-input-note={true}
        />
        <button
          className={style.buttonLink}
          onClick={this.cancel}
          type="button"
          data-test-cancel-button={true}
        >
          <T kind="Cancel" />
        </button>
        <Button
          type="submit"
          value="Save"
          onClick={this.submit}
          className={style.button}
          dataTestSaveButton={true}
        />
      </form>
    );
  }
}
export default observer(NoteForm);
