import { Maybe } from 'maybeasy';
import React from 'react';
import EnrollmentStore from '../../../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { AvailabilityResource, ExperienceResource } from '../../../Experience/Types';
import { EnrollmentModalContextOptions } from '../EnrollmentModalContext/Types';

export type ExperienceEnrollmentContextValue = {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  options?: EnrollmentModalContextOptions;
};

export type ExperienceEnrollmentContextState = {
  availability: Maybe<AvailabilityResource>;
  assignAvailability: (availability: Maybe<AvailabilityResource>) => void;
  resetAvailability: () => void;
  onOpen: () => void;
  onClose: () => void;
};

export const ExperienceEnrollmentContext =
  React.createContext<ExperienceEnrollmentContextState | null>(null);
