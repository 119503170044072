import * as React from 'react';
import _ProcessingIcon from '../../../Cutesies/ProcessingIcon';
import * as style from '../../style.module.css';

const ProcessingIcon: React.FC = () => (
  <div className={style.processingIcon}>
    <_ProcessingIcon />
  </div>
);

export default ProcessingIcon;
