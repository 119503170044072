import { byPayloadId, find } from '@execonline-inc/collections';
import { parseIntM } from '@execonline-inc/numbers';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { segmentRoute } from '../../ClientRoutes';
import { nav } from '../../Navigation';
import ProgramStore from '../../ProgramStore';
import { programsStore } from '../../ProgramsStore';
import SegmentStore from '../../SegmentStore';
import { ModulePresentationStyle, SegmentResource } from '../../SegmentStore/Types';
import EmbeddedStreamlinedSegment from './EmbeddedStreamlinedSegment';
import FullSegment from './FullSegment';

export interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
}

export interface Props {
  match: { params: Params };
  presentationStyle: ModulePresentationStyle;
}

class Segment extends React.Component<Props, {}> {
  segmentStore = new SegmentStore(this.props.presentationStyle);
  programIdM = parseIntM(this.props.match.params.programId);

  programStore = new ProgramStore();

  componentDidMount() {
    just({})
      .assign(
        'programs',
        programsStore.resource.map(({ payload }) => payload.programs),
      )
      .assign('programId', this.programIdM)
      .andThen(({ programs, programId }) => find(byPayloadId(programId), programs))
      .do(this.programStore.loading);
  }

  handleSegmentPathMismatch = (): void => {
    this.segmentStore.segmentResource.do((segmentResource) => {
      if (window.location.pathname.split('/').pop() !== segmentResource.payload.id.toString()) {
        nav(this.redirectPath());
      }
    });
  };

  redirectPath = (): string =>
    this.segmentStore.segmentResource
      .map((segmentResource: SegmentResource) => segmentRoute(segmentResource.payload))
      .getOrElseValue('/');

  componentDidUpdate() {
    this.handleSegmentPathMismatch();
  }

  render() {
    switch (this.segmentStore.presentationStyle) {
      case 'Streamlined':
      case 'Full':
        return (
          <FullSegment
            segmentStore={this.segmentStore}
            programStore={this.programStore}
            params={this.props.match.params}
            programIdM={this.programIdM}
          />
        );

      case 'Embedded Streamlined': {
        return (
          <EmbeddedStreamlinedSegment
            segmentStore={this.segmentStore}
            programStore={this.programStore}
            params={this.props.match.params}
          />
        );
      }
    }
  }
}

export default observer(Segment);
