import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import Main from '../../BrowserSupport/Main';
import { segmentRoute } from '../../ClientRoutes';
import NotificationSource from '../../NotificationSource';
import SegmentStore from '../../SegmentStore';
import { SegmentResource } from '../../SegmentStore/Types';
import SegmentContent from '../Segment/SegmentContent';
import * as style from '../Segment/style.module.css';
import SegmentReactions from '../SegmentReactions';

interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
}

interface Props {
  match: { params: Params };
}

@observer
class SegmentStub extends React.Component<Props, {}> {
  params = this.props.match.params;
  segmentStore = new SegmentStore('Full');

  isSegmentPathMismatch = (): boolean =>
    this.segmentStore.segmentResource
      .map((segmentResource) =>
        window.location.pathname.split('/').pop() !== segmentResource.payload.id.toString()
          ? true
          : false,
      )
      .getOrElseValue(false);

  redirectPath = (): string =>
    this.segmentStore.segmentResource
      .map((segmentResource: SegmentResource) => segmentRoute(segmentResource.payload))
      .getOrElseValue('/');

  render() {
    return (
      <Main className={style.content}>
        {this.isSegmentPathMismatch() && <Redirect to={this.redirectPath()} />}
        <SegmentContent store={this.segmentStore} />
        <SegmentReactions
          store={this.segmentStore}
          params={this.props.match.params}
          advanceHook={() => {}}
          fireImmediately={true}
        />
        <NotificationSource store={this.segmentStore} />
      </Main>
    );
  }
}

export default SegmentStub;
