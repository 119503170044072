import { assertNever } from '@kofno/piper';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import FileUploadStore from '../../FileUploadStore';
import Loader from '../Loader';
import * as style from './style.module.css';

interface Props {
  store: FileUploadStore;
}

const UploadInProgress: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'upload-in-progress':
      return (
        <div data-test-uploads-in-progress={true} className={style.uploading}>
          <Loader color="black" delay={0} text={just('uploading file')} />
        </div>
      );
    case 'error':
    case 'removing':
    case 'upload-successful':
    case 'waiting':
    case 'ready':
      return <></>;
    default:
      return assertNever(store.state);
  }
};
export default observer(UploadInProgress);
