import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { findLink } from '../../../../../../LinkyLinky';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { T } from '../../../../../../Translations';
import CertificateOfCompletion from './CertificateOfCompletion';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const Certificate: React.FC<Props> = ({ externalProgramResource }) =>
  findLink('download-digital-cert-preview', externalProgramResource.links)
    .map((certLink) => (
      <div className="mb-8">
        <div className="text-lg font-semibold uppercase">
          <T kind="Certificate" />
        </div>
        <div className="mb-5 mt-4 text-base font-light leading-normal">
          <CertificateOfCompletion
            externalProgramResource={externalProgramResource}
            certLink={certLink}
          />
        </div>
      </div>
    ))
    .getOrElse(emptyFragment);

export default observer(Certificate);
