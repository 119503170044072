import { equals, findItem, first, mapMaybe } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe, just, nothing } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import {
  LinkableDiscoveryPortalWizardScopeKind,
  LinkableDiscoveryPortalWizardType,
  LinkableResource,
} from '../../../../../../components/Linkable/Types';

const getLinkableDiscoveryPortalWizard = (linkable: LinkableResource): Maybe<LinkableResource> => {
  switch (linkable.payload.kind) {
    case 'discovery-portal-wizard':
      return just(linkable);
    case 'resource':
      return nothing();
  }
};

export const getFirstLinkableDiscoveryPortalWizard = (
  linkables: NonEmptyList<LinkableResource>,
): Maybe<LinkableResource> => {
  return first(mapMaybe(getLinkableDiscoveryPortalWizard, linkables.toArray()));
};

export const validateLinkableResource = (
  item: LinkableDiscoveryPortalWizardType,
  productId: number,
  scopeType: LinkableDiscoveryPortalWizardScopeKind,
) => findItem(productId, item.scope).andThen((scope) => when(equals(scope.kind, scopeType), false));
