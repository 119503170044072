import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../../Types';
import GroupCoachingProductBadge from '../GroupCoachingProductBadge';
import LeadershipCoachingProductBadge from '../LeadershipCoachingProductBadge';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return experienceResource.payload.productDetails
    .map((productDetails) => {
      {
        switch (productDetails.kind) {
          case 'group-coaching':
            return <GroupCoachingProductBadge />;
          case 'leadership-coaching':
            return <LeadershipCoachingProductBadge />;
          case 'program-sequence':
            return emptyFragment();
        }
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
