import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../ClientRoutes';
import { ProgramResource } from '../../ProgramsStore/Types';
import { AlreadyTranslated, T } from '../../Translations';
import BreadCrumbSeparatorIcon from '../Cutesies/BreadCrumbSeparatorIcon';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}

const ProgramHeader: React.FC<Props> = ({ programResource }) => (
  <h1 className={style.breadcrumb} data-test-page-title={programResource.payload.title}>
    <Link to={dashboardUrl} data-test-dashboard-link="Dashboard">
      <T kind="Dashboard" />
    </Link>
    <span className={style.breadcrumbSeparator}>
      <BreadCrumbSeparatorIcon />
    </span>
    <AlreadyTranslated content={programResource.payload.title} />
  </h1>
);

export default observer(ProgramHeader);
