import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../CurrentUser/Types';
import FreebusyStore, { FreebusySettings } from '../../FreebusyStore';
import FreebusyReactions from '../../FreebusyStore/FreebusyReactions';
import { Link } from '../../Resource/Types';
import SegmentStore from '../../SegmentStore';
import { SupportedLanguageCode } from '../../SupportedLanguages/Types';
import { TranslationsContext } from '../../Translations';
import SchedulingButton from './SchedulingButton';
import { AvailabilityQuery, AvailabilityRequest, FreebusyConfig, InitSettings } from './Types';

interface Props {
  node: FreebusyConfig;
  links: Maybe<ReadonlyArray<Link>>;
  segmentStore: Maybe<SegmentStore>;
  currentUserResource: CurrentUserResource;
}

class Freebusy extends React.Component<Props, {}> {
  store = new FreebusyStore(this.props.node.kind);

  initSettings: InitSettings = {
    buttonType: 'custom',
    buttonId: 'freebusy-pickatime-button',
  };

  freebusyLanguage = (): SupportedLanguageCode => {
    switch (this.store.freebusyKind) {
      case 'freebusy-coaching':
        return this.props.currentUserResource.payload.preferredLanguage.getOrElseValue('en');
      case 'freebusy-program':
        return 'en';
    }
  };

  availabilityRequest: AvailabilityRequest = {
    link: this.props.node.link,
    display: 'modal',
    language: this.freebusyLanguage(),
  };

  availabilityQuery: AvailabilityQuery = {
    management: {
      canProposeNewTime: false,
    },
    agenda: this.props.node.agenda,
    participants: [
      {
        email: this.props.node.proposerParticipant.email,
        name: this.props.node.proposerParticipant.name,
        timeZone: this.props.node.proposerParticipant.timeZone,
        role: this.props.node.proposerParticipant.role,
      },
    ],
  };

  freebusySettings: FreebusySettings = {
    availabilityRequest: this.availabilityRequest,
    availabilityQuery: this.availabilityQuery,
    initSettings: this.initSettings,
  };

  componentDidMount() {
    this.store.initializingStore(this.freebusySettings, this.props.node.eventUuid);
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => (
            <FreebusyReactions
              links={this.props.links}
              store={this.store}
              translationsState={ts}
              segmentStore={this.props.segmentStore}
            />
          )}
        </TranslationsContext.Consumer>
        <SchedulingButton
          kind={this.props.node.kind}
          segmentStore={this.props.segmentStore}
          store={this.store}
        />
      </>
    );
  }
}

export default observer(Freebusy);
