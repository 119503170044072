import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from '../../style.module.css';
import { T } from '../../../../../Translations';

type SectionTitle =
  | 'Your coach'
  | 'Upcoming coaching sessions'
  | 'Access Days Remaining'
  | 'Session history';

interface Props {
  text: SectionTitle;
}

const SectionTitle: React.FC<Props> = ({ text }) => (
  <div className={style.secondaryTitle}>
    <T kind={text} />
  </div>
);

export default observer(SectionTitle);
