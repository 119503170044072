import { warn } from '@execonline-inc/logging';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import SmallContentFaker from '../components/ContentFaker/SmallContentFaker';
import { EventResource, EventsState } from '../EventsStore/Types';

interface Props {
  state: EventsState;
  filter: (eventResource: EventResource[]) => Maybe<NonEmptyList<EventResource>>;
  renderWithEvents: (events: EventResource[]) => JSX.Element;
  renderWithoutEvents: () => JSX.Element;
}

const OnEventsReady: React.FC<Props> = ({
  state,
  filter,
  renderWithEvents,
  renderWithoutEvents,
}) => {
  switch (state.kind) {
    case 'waiting':
    case 'loading':
      return <SmallContentFaker />;
    case 'ready':
    case 'refresh':
      return filter(state.eventsResource.payload)
        .map((nonEmptyList) => renderWithEvents(nonEmptyList.toArray()))
        .getOrElse(renderWithoutEvents);
    case 'error':
      warn(state.message);
      return renderWithoutEvents();
  }
};

export default observer(OnEventsReady);
