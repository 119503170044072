import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import ConversationPresence from '../../ConversationPresence';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import { programsStore } from '../../ProgramsStore';
import { findProgramFromTeam } from '../../ProgramsStore/Functions';
import TeamStore from '../../TeamStore';
import { TeamResource } from '../../TeamsStore/Types';
import { T } from '../../Translations';
import TeamProfile from '../TeamProfile';

interface Props {
  teamStore: TeamStore;
  teamResource: TeamResource;
}

const PresenceTeamProfileContext: React.FC<Props> = ({ teamStore, teamResource }) => (
  <WithCurrentUser
    children={(currentUserResource) =>
      programsStore.resource
        .map(({ payload }) => payload.programs)
        .andThen(findProgramFromTeam(teamResource))
        .map(() => (
          <GlobalPresenceContext.Consumer>
            {(presenceStore) => (
              <ConversationPresence
                conversationResource={teamResource.payload.teamChat.payload.conversationResource}
                currentUserResource={currentUserResource}
                channelName={teamResource.payload.teamChat.payload.messagingChannel}
                chatablePersonResourceList={just(teamStore.allMembers)}
                client={(presenceMembersStore) => (
                  <TeamProfile
                    teamStore={teamStore}
                    presenceStore={presenceStore}
                    presenceMembersStore={presenceMembersStore}
                    links={currentUserResource.links}
                  />
                )}
              />
            )}
          </GlobalPresenceContext.Consumer>
        ))
        .getOrElse(() => (
          <div>
            <T kind="Could not identify a program for this team" />
          </div>
        ))
    }
  />
);

export default observer(PresenceTeamProfileContext);
