import { useState } from 'react';
import { CloseAction, OpenAction, OpenableState, OpenableStateResult } from './Types';

function useOpenable(): OpenableStateResult {
  const [openableState, setOpenableState] = useState<OpenableState>({ kind: 'closed' });
  const close: CloseAction = () => {
    setOpenableState({ kind: 'closed' });
  };
  const open: OpenAction = () => {
    setOpenableState({ kind: 'open' });
  };

  return { openableState, close, open };
}

export default useOpenable;
