import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../Common/Experience/Types';
import { findLink } from '../../../../../LinkyLinky';
import { T } from '../../../../../Translations';
import ExternalLink from '../../../ExternalLink';
import { CloudArrowDownIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  experienceResource: ExperienceResource;
}

function DownloadOverview({ experienceResource }: Props) {
  return findLink('download-overview-pdf', experienceResource.links)
    .map((pdfLink) => (
      <div className="flex items-center gap-x-2.5">
        <CloudArrowDownIcon color="black" size="md" />
        <ExternalLink href={pdfLink.href}>
          <Typography color="teal" variant="bodyMedium">
            <T kind="Download Program Overview" />
          </Typography>
        </ExternalLink>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(DownloadOverview);
