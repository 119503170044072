import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';

interface Props {
  children?: React.ReactNode;
}

function EnrollmentStatusItem({ children }: Props) {
  return (
    <>
      <span className="hidden lg:block">|</span>
      <Typography
        className="line-clamp-2 text-wrap tracking-[0.07px] text-gray-500"
        variant="bodyMedium"
        fontWeight="medium"
        as="span"
      >
        {children}
      </Typography>
    </>
  );
}

export default observer(EnrollmentStatusItem);
