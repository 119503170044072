import * as React from 'react';

const EnterprisePortalAppGraphic: React.FC = () => {
  return (
    <svg width="49" height="43" viewBox="0 0 49 43" fill="none">
      <path
        d="M33.1948 7.93628C31.7175 9.41359 28.9691 9.26928 26.5916 6.89185L24.5303 8.95321L35.6548 20.0846L40.5264 15.2198L33.1948 7.93628Z"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3864 18.6347L19.1093 13.3576C19.5748 12.3275 19.7157 11.18 19.513 10.0679C19.3103 8.95575 18.7738 7.93169 17.9748 7.13198C17.1758 6.33226 16.1523 5.79481 15.0404 5.59111C13.9284 5.38741 12.7808 5.52712 11.7503 5.99167L14.7324 8.97379V11.7223H11.9839L8.96743 8.80201C8.50192 9.83215 8.36115 10.9796 8.56381 12.0917C8.76648 13.2039 9.30298 14.2279 10.102 15.0276C10.9009 15.8273 11.9245 16.3648 13.0364 16.5685C14.1483 16.7722 15.2959 16.6325 16.3265 16.1679L21.6036 21.4382"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5581 24.3654L29.8283 29.6425C29.3628 30.6726 29.222 31.8201 29.4247 32.9322C29.6274 34.0443 30.1639 35.0684 30.9628 35.8681C31.7618 36.6678 32.7853 37.2053 33.8973 37.409C35.0092 37.6127 36.1568 37.4729 37.1874 37.0084L34.2053 34.0194V31.2366H36.9537L39.9359 34.2255C40.4014 33.1954 40.5422 32.0479 40.3395 30.9358C40.1369 29.8237 39.6003 28.7997 38.8014 27.9999C38.0024 27.2002 36.9788 26.6627 35.8669 26.459C34.755 26.2553 33.6074 26.3951 32.5768 26.8596L27.2997 21.5825"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2511 13.7632L9.09097 33.9371C8.70007 34.328 8.48047 34.8581 8.48047 35.4109C8.48047 35.9637 8.70007 36.4939 9.09097 36.8848C9.48186 37.2757 10.012 37.4953 10.5649 37.4953C11.1177 37.4953 11.6478 37.2757 12.0387 36.8848L32.2057 16.7178"
        stroke="#187A90"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EnterprisePortalAppGraphic;
