import { throttle } from '@execonline-inc/rate-limiting.private';
import {
  useInterval,
  useIntervalForDuration,
  useSetupEffect,
} from '@execonline-inc/react-hooks.private';
import { Time, seconds } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import { useAtom } from '@xoid/react';
import { useEffect } from 'react';
import { handleInactivityTimeout, isDocumentHidden, userActivityDetected } from './Core';
import { State, nextState } from './States';
import { Atom } from './Types';

export const useInactivityTracking = (atom: Atom) => (): State => {
  const { config, state } = useAtom(atom);

  useSetupEffect(() => {
    atom.actions.activeNow('inactivity:initialized');
    return () => atom.set({ config, state: { kind: 'uninitialized' } });
  });

  useInterval(seconds(1), () => {
    atom.update(nextState);
  });

  useTimeoutHandling(atom);
  useActivityHandling(atom);

  return state;
};

const useTimeoutHandling = (atom: Atom) =>
  useEffect(() => {
    const unsubscribe = atom.watch(handleInactivityTimeout);
    return () => {
      unsubscribe();
    };
  }, []);

const useActivityHandling = (atom: Atom) =>
  useEffect(() => {
    type Handler = () => void;
    const { config } = atom.value;

    const activityHandlers = config.activityEvents.map((event): readonly [string, Handler] => [
      event,
      throttle(userActivityDetected(event, atom), seconds(1)),
    ]);

    activityHandlers.forEach(([e, handler]) => window.addEventListener(e, handler));

    return () => {
      activityHandlers.forEach(([e, handler]) => window.removeEventListener(e, handler));
    };
  }, []);

export const useInactivityExtension =
  (atom: Atom) =>
  (duration: Time): void => {
    const { state } = useAtom(atom);

    useIntervalForDuration({ interval: seconds(1), duration }, () => {
      /**
       * If the page is not visible to the user, then the timer should not be actively
       * auto-extended in order to limit inconsistent behavior with slept browser windows.
       */
      if (isDocumentHidden()) return;

      switch (state.kind) {
        case 'active':
          atom.actions.activeNow('inactivity:extension');
          break;
        case 'uninitialized':
        case 'inactive':
        case 'timed-out':
          break;
        default:
          assertNever(state);
      }
    });
  };
