import { assertNever } from '@kofno/piper';
import ConversationStore from '../../../../ConversationStore';
import { ConversationState } from '../../../../ConversationStore/Types';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';

export interface Props extends RCProps<ConversationStore> {
  store: ConversationStore;
}

class GroupChatReactions extends ReactionComponent<ConversationStore, ConversationState, Props> {
  tester = () => this.props.store.state;

  effect = (state: ConversationState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'load-new-message':
      case 'load-new-reply':
        store.reloading();
        break;
      case 'ready':
        store.messageActionQueueHead.map((i) => i.action());
        break;
      case 'reloading':
      case 'loading':
      case 'loading-previous':
      case 'error':
      case 'waiting':
      case 'update-message':
      case 'update-reply':
      case 'loading-next':
      case 'load-next-success':
      case 'first-loaded':
        break;
      default:
        assertNever(state);
    }
  };
}

export default GroupChatReactions;
