import { useConst } from '@execonline-inc/react-hooks.private';
import { noop } from '@kofno/piper';
import { useAtom } from '@xoid/react';
import { useEffect } from 'react';
import { err, ok } from 'resulty';
import * as rxjs from 'rxjs';
import * as xoid from 'xoid';
import { getItem } from '../Storage';
import {
  Actions,
  Data,
  StorageError,
  StorageValue,
  StorageWatcher,
  storageError,
  whenDifferentValue,
} from './Types';

const createStorageWatcher = (data: Data): StorageWatcher =>
  xoid.atom<Data, Actions>(data, (watcher) => ({
    setValue: (newValue: StorageValue) => {
      whenDifferentValue(watcher.value.state, newValue).cata({
        Ok: (newValue) => watcher.focus(({ state }) => state).set(ok(newValue)),
        Err: noop,
      });
    },
    error: (e: StorageError) => {
      watcher.focus(({ state }) => state).set(err(e));
    },
  }));

export const useStorageWatching = (data: Data) => {
  const watcher = useConst(() => createStorageWatcher(data));

  useAtom(watcher);

  useEffect(() => {
    const subscription = rxjs.fromEvent(window, 'storage').subscribe(() => {
      getItem(watcher.value.config.storageKey, watcher.value.config.storage)
        .mapError(storageError)
        .fork(watcher.actions.error, watcher.actions.setValue);
    });

    return () => subscription.unsubscribe();
  }, [watcher]);

  return watcher;
};
