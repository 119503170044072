import ReactionComponent, { RCProps } from '../ReactionComponent';
import ToastsStore from '.';
import { now } from 'mobx-utils';

interface Props extends RCProps<ToastsStore> {}

class ToastsRefreshReactions extends ReactionComponent<ToastsStore, number, Props> {
  tester = () => now(30000);

  effect = (now: number) => {
    const { store } = this.props;
    switch (store.state.kind) {
      case 'ready':
        const { toastsResource } = store.state;
        if (toastsResource.payload.expiresAt.getTime() < now) {
          store.reloading(toastsResource);
        }
        break;
      case 'error':
      case 'loading':
      case 'reloading':
      case 'waiting':
        break;
    }
  };
}

export default ToastsRefreshReactions;
