import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { Experience } from '../../../../Common/Experience/Types';
import { ExperienceOverviewChip } from '../../../Common';

interface Props {
  experience: Experience;
}

function ExperienceTimeCommitment({ experience }: Props) {
  switch (experience.offeringType) {
    case 'coaching':
      return (
        <ExperienceOverviewChip>
          <T kind="Unlimited sessions" />
        </ExperienceOverviewChip>
      );
    case 'aep':
    case 'epc':
    case 'group-coaching':
    case 'msuite':
    case 'program-sequence':
      return emptyFragment();
  }
}

export default observer(ExperienceTimeCommitment);
