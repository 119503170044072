import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import SegmentStore from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import ProgramStore from '../ProgramStore';
import { windowScrollTopOffset } from '../Window';
import { SegmentState } from './Types';

interface Props extends EAProps<SegmentStore> {
  programStore: ProgramStore;
}

class ProgramSegmentReactions extends ErrorActionableReaction<SegmentStore, SegmentState, Props> {
  tester = () => this.props.store.state;

  effect = (state: SegmentState) => {
    const { programStore } = this.props;
    switch (state.kind) {
      case 'advancing-to':
        windowScrollTopOffset(0).fork((e) => warn('Unable to scroll to top of window', e), noop);
        programStore.programDetailResource.map(programStore.reloading);
        break;
      case 'loading':
      case 'reporting-results':
      case 'auto-saving-form-fields':
      case 'completing':
      case 'advancing':
      case 'loaded':
      case 'completing-and-advancing':
      case 'submitting-and-advancing':
      case 'previous-advancing':
      case 'ready':
      case 'processing-request':
      case 'waiting':
      case 'error':
      case 'schedule-session':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramSegmentReactions;
