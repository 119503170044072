import * as React from 'react';
import { T } from '../../../../Translations';
import UploadIcon from '../../../Cutesies/UploadIcon';
import * as style from '../style.module.css';

const DisabledButton: React.FC = () => (
  <button
    type="submit"
    className={style.submitButton}
    disabled={true}
    data-test-submit-button={true}
  >
    <T kind="Upload and Submit Assignment" />
    <div className={style.buttonIcon}>
      <UploadIcon />
    </div>
  </button>
);

export default DisabledButton;
