import * as React from 'react';
import OrientationModalGraphicSVG from './OrientationModalGraphicSVG';
import * as style from '../../style.module.css';
import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';

const OrientationModalGraphic: React.FC = () => (
  <div className={style.content}>
    <OrientationModalGraphicSVG />
    <p className={style.primaryText}>
      <T kind="Welcome to ExecOnline Leadership Coaching" />
    </p>
    <p className={style.secondaryText}>
      <T kind="We're ready to transform your leadership. Let's get started!" />
    </p>
  </div>
);

export default observer(OrientationModalGraphic);
