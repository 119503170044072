import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Button, CircleInfoIcon, Typography } from '@execonline-inc/execonline-ui';
import { T, TPlainTextKey } from '../../../../../../Translations';
import { PaymentIntentId } from '../Common';

interface Props {
  message: TPlainTextKey;
  description: TPlainTextKey;
  paymentIntentId: Maybe<string>;
  onBackToPayment: () => void;
}

function PaymentErrorModalBody({ message, description, paymentIntentId, onBackToPayment }: Props) {
  return (
    <div className="my-auto box-border flex h-full w-full flex-col items-center justify-center self-center px-6 md:px-12 lg:px-52">
      <CircleInfoIcon className="h-[8.375rem] w-[8.375rem]" color="red" />
      <Typography
        className="mt-6 text-center sm:mt-9"
        color="black"
        variant="h5"
        as="p"
        fontWeight="medium"
      >
        <T kind={message} />
      </Typography>
      <div className="mt-3 text-center empty:hidden">
        <PaymentIntentId value={paymentIntentId} />
      </div>
      <Typography className="mt-10 text-center" variant="bodyMedium" as="p">
        <T kind={description} />
      </Typography>
      <div className="mt-11 w-full text-center">
        <Button className="w-full sm:w-80" size="md" variant="text" onClick={onBackToPayment}>
          <T kind="Back to Payment Method" />
        </Button>
      </div>
    </div>
  );
}

export default observer(PaymentErrorModalBody);
