import { Experience } from '../../../../Common/Experience/Types';

export const learnMoreBreadcrumbsColor = (experience: Experience) => {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return 'text-white';
    case 'aep':
    case 'epc':
    case 'msuite':
      return 'text-black';
  }
};
