import { findPayload } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import ConversationStore from '.';
import { AppyError, callApi } from '../Appy';
import EvictingDeque from '../Collections/EvictingDeque';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { findLink, findLinkT, findSelfLink, MissingLinkError } from '../LinkyLinky';
import { Link, resource, resourceWithEmbedded } from '../Resource/Types';
import { messagesResourceDecoder } from './Decoders';
import {
  ChatMessage,
  ChatMessageRepliesResource,
  ChatMessageReplyResource,
  ChatMessageResource,
  ChatMessagesResource,
  ConversationState,
  MessageEmbedded,
} from './Types';

export interface Props extends EAProps<ConversationStore> {
  store: ConversationStore;
}

const messagesEndPoint = callApi(messagesResourceDecoder, {});

type ConversationReactionsError = AppyError | MissingLinkError;

const handleMessagesError = (store: ConversationStore) => (error: ConversationReactionsError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Messages are not available');
      break;
    default:
      handleError(store, error);
  }
};

const handleNextMessagesSuccess =
  (store: ConversationStore) => (messagesResource: ChatMessagesResource) => {
    store.addNextPage(messagesResource);
  };

const handlePreviousMessagesSuccess =
  (store: ConversationStore) => (messagesResource: ChatMessagesResource) => {
    store.addPreviousPage(messagesResource);
  };

const loadMessages = (store: ConversationStore) => {
  Task.succeed<ConversationReactionsError, ReadonlyArray<Link>>(store.conversationResource.links)
    .andThen(findLinkT('messages'))
    .andThen(messagesEndPoint)
    .fork(handleMessagesError(store), (messagesResource) => store.addFirstPage(messagesResource));
};

const loadNextMessages = (store: ConversationStore, link: Link) => {
  messagesEndPoint(link).fork(handleMessagesError(store), handleNextMessagesSuccess(store));
};

const loadPreviousMessages = (store: ConversationStore, link: Link) => {
  messagesEndPoint(link).fork(handleMessagesError(store), handlePreviousMessagesSuccess(store));
};

const whenIdsMatch =
  (reply: ChatMessageReplyResource) =>
  ({ links, payload, embedded }: ChatMessageReplyResource): ChatMessageReplyResource =>
    reply.payload.id === payload.id ? reply : { links, payload, embedded };

const updateReply =
  (reply: ChatMessageReplyResource) =>
  ({ links, payload }: ChatMessageRepliesResource): ChatMessageRepliesResource =>
    resource(links, [...payload.map(whenIdsMatch(reply))]);

const updateMessageReply =
  (reply: ChatMessageReplyResource) =>
  ({ links, payload, embedded }: ChatMessageResource) =>
    resourceWithEmbedded<ChatMessage, MessageEmbedded>(
      links,
      {
        ...payload,
        replies: payload.replies.map(updateReply(reply)),
      },
      embedded,
    );

const updateChatReplyReaction =
  (reply: ChatMessageReplyResource) => (chatMessagesResource: ChatMessagesResource) =>
    resource<ChatMessageResource[]>(
      chatMessagesResource.links,
      chatMessagesResource.payload.map(updateMessageReply(reply)),
    );

const addReply =
  (reply: ChatMessageReplyResource) =>
  ({ links, payload }: ChatMessageRepliesResource): ChatMessageRepliesResource =>
    resource(links, [...payload, reply]);

const whenHrefsMatch =
  (reply: ChatMessageReplyResource) =>
  (compareLinks: { inReplyToLink: Link; messageLink: Link }): Maybe<ChatMessageReplyResource> =>
    compareLinks.inReplyToLink.href === compareLinks.messageLink.href ? just(reply) : nothing();

const addReplyToResource =
  ({ links, payload, embedded }: ChatMessageResource) =>
  (reply: ChatMessageReplyResource) =>
    resourceWithEmbedded<ChatMessage, MessageEmbedded>(
      links,
      {
        ...payload,
        replies: payload.replies.map(addReply(reply)),
      },
      embedded,
    );

const addChatMessageReply =
  (reply: ChatMessageReplyResource) =>
  (chatMessagesResource: ChatMessagesResource): ChatMessagesResource =>
    resource<ChatMessageResource[]>(
      chatMessagesResource.links,
      chatMessagesResource.payload.map(({ links, payload, embedded }) => {
        return just({})
          .assign('messageLink', findSelfLink(links))
          .assign('inReplyToLink', findLink('in-reply-to', reply.links))
          .andThen(whenHrefsMatch(reply))
          .map(addReplyToResource({ links, payload, embedded }))
          .getOrElseValue({ links, payload, embedded });
      }),
    );
class ConversationAPIReactions extends ErrorActionableReaction<
  ConversationStore,
  ConversationState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ConversationState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'reloading':
      case 'loading':
        loadMessages(store);
        break;
      case 'ready':
        store.messageActionQueueHead.map((i) => i.action());
        store.chatMessageStore.ready();
        break;
      case 'loading-next':
        loadNextMessages(store, state.link);
        break;
      case 'loading-previous':
        loadPreviousMessages(store, state.link);
        break;
      case 'load-new-message':
        state.deque.peekBack.do((chatMessagesResource) => {
          const { payload } = chatMessagesResource;
          chatMessagesResource.payload = [...payload, state.message];
          store.ready(state.deque, just(state.message));
          store.removeMessageActionQueueHead();
        });
        break;
      case 'load-new-reply':
        const loadDeque = new EvictingDeque<ChatMessagesResource>(state.deque.items.length);
        loadDeque.items = state.deque.items.map(addChatMessageReply(state.reply));
        store.ready(loadDeque, nothing());
        store.removeMessageActionQueueHead();
        break;
      case 'update-message':
        state.deque.items.forEach((chatMessagesResource) => {
          findPayload(state.message.payload.id, chatMessagesResource.payload).map((resource) => {
            resource.links = state.message.links;
            resource.payload = state.message.payload;
          });
        });
        store.ready(state.deque, nothing());
        break;
      case 'first-loaded':
        store.chatMessageStore.ready();
        break;
      case 'update-reply':
        const updateDeque = new EvictingDeque<ChatMessagesResource>(state.deque.items.length);
        updateDeque.items = state.deque.items.map(updateChatReplyReaction(state.reply));
        store.ready(updateDeque, nothing());
        break;
      case 'load-next-success':
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ConversationAPIReactions;
