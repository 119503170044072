import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import { observer } from 'mobx-react';
import CoachChatState from './CoachChatState';
import { ChatablePersonResourceList } from '../../ConversationStore/Types';

interface Props {
  conversationStore: ConversationStore;
  chatablePersonResourceList: ChatablePersonResourceList;
}

@observer
class CoachChatRoom extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount() {
    this.props.conversationStore.loading();
  }

  render() {
    return (
      <CoachChatState
        conversationStore={this.props.conversationStore}
        chatablePersonResourceList={this.props.chatablePersonResourceList}
      />
    );
  }
}

export default CoachChatRoom;
