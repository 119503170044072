import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import EnrollmentStore from '../../Enrollment/Store';
import { PromotableProductCollectionResource } from '../Types';
import PromotableProductCollection from './PromotableProductCollection';

interface Props {
  data: NonEmptyList<PromotableProductCollectionResource>;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotableProductCollections({ data, invitationUuid, enrollmentStore }: Props) {
  return (
    <>
      {data.map((item) => (
        <PromotableProductCollection
          key={item.payload.id}
          data={item}
          invitationUuid={invitationUuid}
          enrollmentStore={enrollmentStore}
        />
      ))}
    </>
  );
}

export default observer(PromotableProductCollections);
