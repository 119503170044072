import { observer } from 'mobx-react';
import ProgramStore from '../../../ProgramStore';
import { Link } from '../../../Resource/Types';
import { T } from '../../../Translations';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import Confirm from '../../Confirm';
import ArrowLeftIcon from '../../Cutesies/ArrowLeftIcon';
import { useOpenable } from '../../Openable';
import * as style from '../style.module.css';

interface Props {
  cancelLink: Link;
  programStore: ProgramStore;
}

function CancelRegistration({ cancelLink, programStore }: Props) {
  const { translate } = useTranslationsContext();
  const { openableState, open, close } = useOpenable();
  return (
    <>
      <button
        className={style.cancelButton}
        onClick={open}
        title={translate('Exit Course Registration')}
        role="button"
        data-test-cancel-button="Cancel"
      >
        <div className={style.buttonIcon}>
          <ArrowLeftIcon />
        </div>
        <T kind="Exit" />
      </button>
      <Confirm
        state={openableState}
        close={close}
        title={<T kind="Are you sure you want to exit registration?" />}
        content={<></>}
        confirmText={<T kind="Confirm" />}
        onConfirm={() => programStore.cancellingRegistration(cancelLink)}
      />
    </>
  );
}

export default observer(CancelRegistration);
