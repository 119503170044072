import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Program } from '../../ProgramStore/Types';
import Linkable from '../Linkable';

interface Props {
  programDetail: Program;
}

function ActionPlanDownLoadLink({ programDetail }: Props) {
  return programDetail.linkables
    .map((l) => l.first)
    .map((linkable) => <Linkable linkable={linkable} />)
    .getOrElse(emptyFragment);
}

export default ActionPlanDownLoadLink;
