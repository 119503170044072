import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChatablePersonResourceList } from '../../ConversationStore/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import TeamStore from '../../TeamStore';
import TeamReactions from '../../TeamStore/TeamReactions';
import { Breakout } from '../../TeamsStore/Types';
import Conversation from './Conversation';
import * as style from './style.module.css';

interface Props {
  breakoutRoom: Breakout;
  roomId: string;
  chatablePersonResourceList: ChatablePersonResourceList;
}

class BreakoutConversation extends React.Component<Props> {
  breakoutRoom = this.props.breakoutRoom;

  teamStore: TeamStore = new TeamStore();
  teamId = this.breakoutRoom.teamId;
  eventId = this.breakoutRoom.eventId;

  componentDidMount() {
    this.teamStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <div className={style.room}>
            <Conversation
              roomId={this.props.roomId}
              eventId={this.eventId}
              teamStore={this.teamStore}
              chatablePersonResourceList={this.props.chatablePersonResourceList}
            />
            <TeamReactions
              store={this.teamStore}
              teamId={just(this.teamId)}
              currentUserResource={currentUserResource}
            />
          </div>
        )}
      />
    );
  }
}

export default observer(BreakoutConversation);
