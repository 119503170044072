import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../Store';
import { WhenUserDataLoadedReactions } from './Reactions';
import { Props } from './Types';
import WhenUserDataLoadedView from './View';

class WhenUserDataLoaded extends React.Component<Props> {
  render() {
    return (
      <>
        <WhenUserDataLoadedView {...this.props} />
        <WhenUserDataLoadedReactions {...this.props} store={currentUserStore} fireImmediately />
      </>
    );
  }
}

export default observer(WhenUserDataLoaded);
