import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { T } from '../../../../Translations';

interface Props {
  sharedInvitationResource: SharedInvitationResource;
}

const NonSSOInstructions: React.FC<Props> = ({ sharedInvitationResource }) => {
  switch (sharedInvitationResource.payload.kind) {
    case 'use-case':
      return (
        <Typography>
          <T kind="Set your ExecOnline password..." />
        </Typography>
      );
    case 'use-cases-program-family':
      return (
        <Typography>
          <T kind="Set your ExecOnline password to continue." />
        </Typography>
      );
    case 'preview-only':
      return <></>;
  }
};

export default observer(NonSSOInstructions);
