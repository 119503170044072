import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import Loader from '../Loader';
import DocumentRenderer from './DocumentRenderer';
import CourseDocumentStore from './Store';
import * as style from './style.module.css';

interface Props {
  store: CourseDocumentStore;
}

const Viewer: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <div className={style.loader}>
          <Loader text={just('Locating document...')} />
        </div>
      );
    case 'ready':
      return store.courseDocument
        .map((document) => <DocumentRenderer courseDocument={document} />)
        .getOrElseValue(
          <div className="text-black">
            <T kind="Oops! We were unable to find your file." />
          </div>,
        );
    case 'error':
      return (
        <div className="text-black">
          <T kind="Oops! We were unable to find your file." />
        </div>
      );
  }
};

export default observer(Viewer);
