import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated, T } from '../../Translations';
import CountryAndRegion from './CountryAndRegion';
import { TeamMembersNode } from './Types';

interface Props {
  node: TeamMembersNode;
}

const TeamMembersDetails: React.FC<Props> = ({ node }) => (
  <tbody>
    {node.teamMembers.payload.map((teamMember) => (
      <tr key={teamMember.payload.id}>
        {teamMember.payload.profile.payload.name
          .map((n) => (
            <td>
              <NotTranslated text={n} />
            </td>
          ))
          .getOrElseValue(<td />)}
        {teamMember.payload.profile.payload.position
          .map((pos) => (
            <td>
              <NotTranslated text={pos} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        {teamMember.payload.profile.payload.organization
          .map((org) => (
            <td>
              <NotTranslated text={org} />
            </td>
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
        {teamMember.payload.profile.payload.country
          .map((country) => (
            <CountryAndRegion
              country={country.name}
              region={teamMember.payload.profile.payload.region}
            />
          ))
          .getOrElseValue(
            <td>
              <T kind="Not specified" />
            </td>
          )}
      </tr>
    ))}
  </tbody>
);

export default observer(TeamMembersDetails);
