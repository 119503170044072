import { assertNever } from '@kofno/piper';
import { AjaxResponse } from 'ajaxian';
import { nothing } from 'maybeasy';
import { Task } from 'taskarian';
import CountryOptionsStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import ProfileFormStore from '../ProfileFormStore';
import { Link } from '../Resource/Types';
import { countryOptionsResourceDecoder, countryOptionsResourceDecoderWithErrors } from './Decoders';
import { CountryOptionsResource, CountryOptionsState } from './Types';

interface Props {
  store: CountryOptionsStore;
  profileStore: ProfileFormStore;
}

type SubmissionError = MissingLinkError | AppyError;
type CountryOptionRetrievalError = MissingLinkError | AppyError;

const handleMissingLink = (store: CountryOptionsStore) => {
  store.error('You cannot perform this operation at this time.');
};

const handleBadStatus = (response: AjaxResponse, error: AppyError, store: CountryOptionsStore) => {
  countryOptionsResourceDecoderWithErrors.decodeJson(response.body).cata({
    Ok: (resource) => store.readyWithErrors(resource),
    Err: () => handleError(store, error),
  });
};

export const handleServerErrors =
  (store: CountryOptionsStore) =>
  (error: SubmissionError): void => {
    switch (error.kind) {
      case 'bad-status':
        handleBadStatus(error.response, error, store);
        break;
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'bad-payload':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        handleError(store, error);
        break;
      case 'missing-link-error':
        handleMissingLink(store);
        break;
      default:
        assertNever(error);
    }
  };

export const successfullyLoad =
  (store: CountryOptionsStore) => (countryOptionsResource: CountryOptionsResource) => {
    store.ready(countryOptionsResource, nothing());
  };

const loadCountryOptions = (store: CountryOptionsStore, profileStore: ProfileFormStore) => {
  Task.succeed<CountryOptionRetrievalError, ReadonlyArray<Link>>(profileStore.links)
    .andThen(findLinkT('country-options'))
    .andThen(callApi(countryOptionsResourceDecoder, {}))
    .fork(handleServerErrors(store), (countryOptionsResource: CountryOptionsResource) =>
      store.ready(countryOptionsResource, nothing()),
    );
};

class CountryOptionsStoreReactions extends ErrorActionableReaction<
  CountryOptionsStore,
  CountryOptionsState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: CountryOptionsState): void => {
    const { store, profileStore } = this.props;
    switch (state.kind) {
      case 'loading':
        loadCountryOptions(store, profileStore);
        break;
      case 'ready':
        profileStore.profileFormResource.do((resource) =>
          profileStore.countriesLoaded(resource, profileStore.avatarLink),
        );
        break;
      case 'ready-with-errors':
      case 'error':
        profileStore.profileFormResource.do((resource) =>
          profileStore.readyWithNoCountries(resource, profileStore.avatarLink),
        );
        break;
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default CountryOptionsStoreReactions;
