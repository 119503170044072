import { equals } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import { useOpenable } from '../../../../../../components/Openable';
import AlertPreviewModal from '../../../../DiscoveryPortal/PreviewMode/AlertPreviewModal';
import { usePreviewContext } from '../../../../DiscoveryPortal/PreviewMode/usePreviewContext';
import ExperienceEnrollmentModal from '../../ExperienceEnrollmentModal';
import { AvailabilityViewMoreDates } from '../EnrollmentModalBody';
import { EnrollmentModalContextOptions } from '../EnrollmentModalContext/Types';
import { LearnMoreButton } from '../EnrollmentModalFooter';
import { useExperienceEnrollmentAvailability } from './hooks';
import {
  ExperienceEnrollmentContext,
  ExperienceEnrollmentContextState,
  ExperienceEnrollmentContextValue,
} from './Types';

interface Props {
  value: ExperienceEnrollmentContextValue;
  children: ((props: ExperienceEnrollmentContextState) => React.ReactNode) | React.ReactNode;
}

const defaultOptions: EnrollmentModalContextOptions = {
  programFooterButtons: just(<LearnMoreButton />),
  scheduledExperienceOptions: just(<AvailabilityViewMoreDates />),
};

function ExperienceEnrollmentContextProvider({ value, children }: Props) {
  const {
    invitationUuid,
    experienceResource,
    enrollmentStore,
    experiencesStore,
    options = defaultOptions,
  } = value;
  const { openableState: isOpen, open: onOpen, close: onClose } = useOpenable();
  const { availability, assignAvailability, resetAvailability } =
    useExperienceEnrollmentAvailability(experienceResource.payload.availabilities);

  const previewMode = usePreviewContext();

  const handleModalClose = () => {
    enrollmentStore.reset();
    enrollmentStore.settingUp(experiencesStore.useCase);
    onClose();
  };

  const props: ExperienceEnrollmentContextState = {
    availability,
    assignAvailability,
    resetAvailability,
    onOpen,
    onClose: handleModalClose,
  };

  return (
    <ExperienceEnrollmentContext.Provider value={props}>
      {when(equals(previewMode, 'preview'), {})
        .map(() => <AlertPreviewModal modalState={isOpen} onClose={onClose} />)
        .getOrElse(emptyFragment)}
      {availability
        .map((availability) => (
          <ExperienceEnrollmentModal
            isOpen={isOpen}
            invitationUuid={invitationUuid}
            availability={availability}
            experienceResource={experienceResource}
            enrollmentStore={enrollmentStore}
            options={options}
            onOpen={onOpen}
            onClose={handleModalClose}
          />
        ))
        .getOrElse(emptyFragment)}
      {typeof children === 'function' ? children(props) : children}
    </ExperienceEnrollmentContext.Provider>
  );
}

export default observer(ExperienceEnrollmentContextProvider);
