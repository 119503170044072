import * as React from 'react';
import { observer } from 'mobx-react';
import ActionMenuStore from './ActionMenuStore';
import ActionMenuReactions from './ActionMenuReactions';
import MessageReactionStore from '../MessageReactionStore';
import { ChatMessageResource, ChatMessageReplyResource } from '../../../../ConversationStore/Types';
import ActionMenuStates from './ActionMenuStates';
import ChatReplyStore from '../../../../ChatReplyStore';
import * as style from '../style.module.css';
import SubscriptionStore from '../SubscriptionStore';
import SubscriptionReactions from '../SubscriptionStore/Reactions';

interface Props {
  reactionStore: MessageReactionStore;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
  chatReplyStore: ChatReplyStore;
}

class ActionMenu extends React.Component<Props> {
  store = new ActionMenuStore({
    reactionStore: this.props.reactionStore,
    messageLinks: this.props.messageResource.links,
  });
  subscriptionStore = new SubscriptionStore(this.props.messageResource.payload.subscription);
  render() {
    return (
      <div className={style.actionWrapper}>
        <ActionMenuStates
          store={this.store}
          messageResource={this.props.messageResource}
          chatReplyStore={this.props.chatReplyStore}
          subscriptionStore={this.subscriptionStore}
        />
        <ActionMenuReactions store={this.store} />
        <SubscriptionReactions store={this.subscriptionStore} fireImmediately={true} />
      </div>
    );
  }
}

export default observer(ActionMenu);
