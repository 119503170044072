import { observer } from 'mobx-react';
import { T } from '../../../../../../../Translations';
import { AvailabilityResource } from '../../../Types';
import { OnDemandProgramIcon, ScheduledProgramIcon } from '../Icons';

interface Props {
  availability: AvailabilityResource;
}

function FormattedAvailability({ availability }: Props) {
  const { payload } = availability;

  switch (payload.kind) {
    case 'on-demand':
      return (
        <>
          <OnDemandProgramIcon size="md" />
          <T kind="On-Demand Program" />
        </>
      );
    case 'scheduled':
      return (
        <>
          <ScheduledProgramIcon size="md" />
          <T kind="Scheduled Program" />
        </>
      );
  }
}

export default observer(FormattedAvailability);
