import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from './style.module.css';
import { CoachProfileResource } from '../../../../../EmbeddedFormFieldAsset/Types';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { T } from '../../../../../../Translations';
import CoachLabel from '../../CoachLabel';
import CoachModalIntroductoryVideo from '../../../../../EmbeddedFormFieldAsset/FormField/SelectField/CoachSelect/CoachSelectOption/CoachModal/CoachModalIntroductoryVideo';

interface Props {
  coachProfile: CoachProfileResource;
}

const CoachProfileContent: React.FC<Props> = ({ coachProfile }) => (
  <div>
    <CoachLabel coachProfile={coachProfile} />
    <div className={style.contentWrapper}>
      <div className={style.description}>
        <div className={style.descriptionHeading}>
          <T kind="Coaching Style" />
        </div>
        <div>
          <AlreadyTranslated content={coachProfile.payload.coachingStyle} />
        </div>
        <div className={style.descriptionHeading}>
          <T kind="Leadership Expertise" />
        </div>
        <div>
          <AlreadyTranslated content={coachProfile.payload.coachLeadershipExperience} />
        </div>
      </div>
      <div className={style.videoSection}>
        <div className={style.descriptionHeading}>
          <T kind="Introductory Video" />
        </div>
        <CoachModalIntroductoryVideo video={coachProfile.payload.introductoryVideo} />
      </div>
    </div>
  </div>
);

export default observer(CoachProfileContent);
