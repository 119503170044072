import { putQueryParam, toUrl } from '@execonline-inc/url';
import { Maybe } from 'maybeasy';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';

export const createLanguageUrl = (
  link: string,
  preferredLanguage: Maybe<SupportedLanguageCode>
) => {
  const language = preferredLanguage.getOrElseValue('en');
  return toUrl(link).map(putQueryParam('language', language));
};
