import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { Experience } from '../../../Common/Experience/Types';
import {
  EnrollmentCard,
  ExperienceActions,
  ExperienceCompetencies,
  ExperienceDescription,
  ExperienceLanguageAvailability,
  ExperienceTitle,
  ExperiencesLogo,
} from '../../Common';

interface Props {
  experience: Experience;
}

function LearningJourneyHero({ experience }: Props) {
  return (
    <>
      <div className="flex w-full flex-col gap-x-28 lg:flex-row">
        <div className="w-full lg:w-3/5">
          <ExperiencesLogo experience={experience} />
          <ExperienceTitle className="text-white" title={just(experience.title)} />
          <ExperienceCompetencies
            className="mb-4 text-white sm:mb-7"
            textColor="white"
            chipColor="white"
            competencies={experience.primaryCompetencies}
          />
          <ExperienceDescription
            className="mb-10 text-white sm:mb-12"
            description={experience.publicDetailedDescriptionHtml}
          />
          <ExperienceLanguageAvailability className="text-white sm:mb-8" experience={experience} />
        </div>
        <div className="mx-auto mt-20 empty:hidden lg:mt-0">
          <EnrollmentCard experience={experience} />
        </div>
      </div>
      <ExperienceActions experience={experience} />
    </>
  );
}
export default observer(LearningJourneyHero);
