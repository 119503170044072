import { map, toPairs } from '@execonline-inc/collections';
import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import NotesStore from '../../../NotesStore';
import NoteStore from '../../../NoteStore';
import NotificationSource from '../../../NotificationSource';
import { NotTranslated } from '../../../Translations';
import ContentFaker from '../../ContentFaker';
import NoteReactions from '../../NoteReactions';
import NoteItem from '../NoteItem';
import EmptyState from './EmptyState';
import * as style from '../style.module.css';

interface Props {
  store: NotesStore;
}
const renderNotes = map((noteStore: NoteStore) => (
  <div
    key={noteStore.noteResource.map((noteResource) => noteResource.payload.id).getOrElseValue(0)}
  >
    <NoteItem noteStore={noteStore} hideContext={false} />
    <NoteReactions store={noteStore} contextResource={nothing()} kettle={nothing()} />
    <NotificationSource store={noteStore} />
  </div>
));

const renderGroups = map(([programTitle, noteStores]: [string, NoteStore[]]) => (
  <div key={programTitle}>
    <h2 data-test-notes-program-title={programTitle} className={style.noteGroupTitle}>
      {programTitle}
    </h2>
    {renderNotes(noteStores)}
  </div>
));

const NoteGroups: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'loading':
    case 'waiting':
      return <ContentFaker />;
    case 'ready':
      return (
        <>
          {fromEmpty(toPairs(store.noteStoresByProgram))
            .map<React.ReactNode>(renderGroups)
            .getOrElse(() => (
              <EmptyState />
            ))}
        </>
      );
    case 'error':
      return (
        <div>
          <NotTranslated text={store.state.message} />
        </div>
      );
  }
};

export default observer(NoteGroups);
