import { observer } from 'mobx-react';
import Notifications from '../../Notifications';
import AutoLaunchable from '../AutoLaunchable';
import * as platformStyle from '../Platform/style.module.css';
import { View } from './View';
import * as style from './style.module.css';

function Resources() {
  return (
    <div className={platformStyle.container}>
      <Notifications />
      <AutoLaunchable />
      <div className={style.resources} data-test-resources={true}>
        <View />
      </div>
    </div>
  );
}

export default observer(Resources);
