import {
  Card,
  CardBody,
  CardFooter,
  Chip,
  Typography,
  WysiwygIcon,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { AlreadyTranslated } from '@execonline-inc/translations';
import IncludedPrograms from '../IncludedPrograms';
import { T } from '../../../../../../Translations';
import { ProgramSequenceProductDetailsSections } from '../../../../Common/Experience/Types';

interface Props {
  section: ProgramSequenceProductDetailsSections;
}

function JourneyCard({ section }: Props) {
  return (
    <Card className="flex min-h-[183px] p-2 sm:w-full" radius="none">
      <CardBody className="pb-0">
        <div className="flex items-baseline">
          <Typography variant="bodyLarge" fontWeight="bold" className="mr-1 whitespace-nowrap">
            <AlreadyTranslated content={section.label} />:
          </Typography>
          <Typography variant="bodyMedium">
            <AlreadyTranslated content={section.title} />
          </Typography>
        </div>
        <Typography variant="bodyMedium" as="p" className="my-2 w-full">
          <AlreadyTranslated content={section.description} />
        </Typography>
        <Typography variant="bodyMedium" fontWeight="bold" className="pt-2 sm:pt-0">
          <T kind="Included Programs" />
        </Typography>
        <IncludedPrograms programs={section.programs} />
      </CardBody>
      <CardFooter>
        <Chip radius="full" color="light-gray">
          <WysiwygIcon size="sm" />
          <Typography
            fontWeight="medium"
            variant="bodyMedium"
            className="ml-3 whitespace-nowrap leading-5 decoration-[#2B2B2B]"
          >
            <T kind="{{count}} Program" count={section.programs.length} />
          </Typography>
        </Chip>
      </CardFooter>
    </Card>
  );
}
export default observer(JourneyCard);
