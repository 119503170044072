import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ProgramChatLinkNode } from '../../ContentEmbed/Types';
import { programUrl } from '../../../ClientRoutes';
import * as style from './style.module.css';
import { T } from '../../../Translations';
import MessagesIcon from '../../Cutesies/MessagesIcon';

interface Props {
  node: ProgramChatLinkNode;
}
const ProgramChatLinkEmbeddable: React.FC<Props> = ({ node }) => {
  return (
    <Link to={programUrl(node.programId)}>
      <button className={style.programChatLinkButton}>
        <T kind="Message other participants" />
        <div className={style.programChatLinkButtonIcon}>
          <MessagesIcon />
        </div>
      </button>
    </Link>
  );
};

export default observer(ProgramChatLinkEmbeddable);
