import { Kettle } from 'kettle-corn';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SourceFile } from '../JWPlayer/Types';
import JWPlayer from './JWPlayer';
import { videoPlayerOptions } from './JWPlayer/Types';
import { subtitleTracks } from './Resource';
import { VideoAssetResource } from './Types';

interface Props {
  id: string;
  className: string;
  videoResource: VideoAssetResource;
  kettle: Kettle;
  width: number | string;
  height: number | string;
  dynamicallySetAspectRatio?: boolean;
  sources: SourceFile[];
}

/**
 * ProgressivePlayer wraps JWPlayer. Video sources must be explicitly passed
 * to the component since sources will vary depending on the user's situation
 * (browser and/or server's context).
 */
const ProgressivePlayer: React.FC<Props> = ({
  id,
  className,
  videoResource,
  kettle,
  width,
  height,
  dynamicallySetAspectRatio,
  sources,
}) => {
  const options = videoPlayerOptions(videoResource, {
    width,
    height,
    dynamicallySetAspectRatio: fromNullable(dynamicallySetAspectRatio).getOrElseValue(false),
    sources,
    tracks: subtitleTracks(videoResource.payload.subtitles),
  });

  return <JWPlayer id={id} className={className} kettle={kettle} options={options} />;
};

export default observer(ProgressivePlayer);
