import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../ConversationStore';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import TeamStore from '../../TeamStore';
import CommunitiesChat from '../Communities/CommunitiesChat';
import TeamFiles from './TeamFiles';

interface Props {
  teamStore: TeamStore;
  conversationStore: ConversationStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const TeamPageContent: React.FC<Props> = ({ teamStore, conversationStore, presenceStore }) => {
  return teamStore.team
    .map((teamResource) => {
      switch (teamResource.payload.teamPageContent) {
        case 'Chat':
          return (
            <CommunitiesChat
              conversationStore={conversationStore}
              programChatStore={nothing()}
              teamStore={just(teamStore)}
              presenceStore={presenceStore}
              kind="team-chat"
            />
          );
        case 'Files':
          return <TeamFiles teamResource={teamResource} />;
      }
    })
    .getOrElseValue(<></>);
};

export default observer(TeamPageContent);
