import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import FreebusyStore from '../../FreebusyStore';
import SegmentStore from '../../SegmentStore';
import CoachingSchedulingButton from './CoachingSchedulingButton';
import ProgramSchedulingButton from './ProgramSchedulingButton';
import { FreebusyKind } from './Types';

interface Props {
  kind: FreebusyKind;
  store: FreebusyStore;
  segmentStore: Maybe<SegmentStore>;
}

const SchedulingButton: React.FC<Props> = ({ kind, store, segmentStore }) => {
  switch (kind) {
    case 'freebusy-coaching':
      return <CoachingSchedulingButton store={store} segmentStore={segmentStore} />;
    case 'freebusy-program':
      return <ProgramSchedulingButton store={store} segmentStore={segmentStore} />;
    default:
      return assertNever(kind);
  }
};

export default observer(SchedulingButton);
