import { WindowInteractionError } from '@execonline-inc/browser-window.private';

export interface InvalidRelError {
  kind: 'invalid-rel-error';
  invalidRel: string;
}

export interface UnknownAppError {
  kind: 'unknown-app-error';
  appRel: string;
}

export type UnifiedLoginError = InvalidRelError | UnknownAppError | WindowInteractionError;

export const invalidRelError = (invalidRel: string): InvalidRelError => ({
  kind: 'invalid-rel-error',
  invalidRel,
});

export const unknownAppError = (appRel: string): UnknownAppError => ({
  kind: 'unknown-app-error',
  appRel,
});
