import React from 'react';
import { observer } from 'mobx-react';
import { useExperienceContext } from '../Experience/ExperienceContext';
import ProgramActions from './ProgramActions';
import ExperienceProductDetails from './ExperienceProductDetails';

interface Props {
  children: (onClick: () => void) => React.ReactNode;
}

function ProgramExperienceActions({ children }: Props) {
  const { experienceResource } = useExperienceContext();
  const { offeringType } = experienceResource.payload;

  switch (offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return <ProgramActions>{children}</ProgramActions>;
    case 'coaching':
    case 'program-sequence':
    case 'group-coaching':
      return <ExperienceProductDetails>{children}</ExperienceProductDetails>;
  }
}

export default observer(ProgramExperienceActions);
