/**
 * This file is generated by buildcss/build.js
 * Do not edit this file directly. Update  platform3/buildcss/tailwind/compile-theme.js
 * and run 'node buildcss/build.js' to regenerate this file.
 */
export const tailwindTheme = {
  colors: {
    primary: {
      '50': '#f2f8f9',
      '100': '#c4f1e6',
      '200': '#85dfd4',
      '300': '#5cc4bc',
      '400': '#38a3a1',
      '500': '#187a90',
      '600': '#1f606a',
      '700': '#194951',
      '800': '#143439',
      '900': '#102228',
      DEFAULT: '#187a90',
    },
    logo: {
      '50': '#f0f6f7',
      '100': '#d3f0f7',
      '200': '#a2e3ed',
      '300': '#58c5e0',
      '400': '#32a7b6',
      '500': '#248997',
      '600': '#20707d',
      '700': '#1d5662',
      '800': '#153a47',
      '900': '#0e2432',
      DEFAULT: '#58C5E0',
      dark: '#32a7b6',
      light: '#a2e3ed',
      foreground: '#0e2432',
    },
    inherit: 'inherit',
    current: 'currentColor',
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    slate: {
      '50': '#f8fafc',
      '100': '#f1f5f9',
      '200': '#e2e8f0',
      '300': '#cbd5e1',
      '400': '#94a3b8',
      '500': '#64748b',
      '600': '#475569',
      '700': '#334155',
      '800': '#1e293b',
      '900': '#0f172a',
      DEFAULT: '#1e293b',
      dark: '#0f172a',
      light: '#475569',
      foreground: '#94a3b8',
    },
    gray: {
      '50': '#f9fafb',
      '100': '#f3f4f6',
      '200': '#e5e7eb',
      '300': '#d1d5db',
      '400': '#9ca3af',
      '500': '#6b7280',
      '600': '#4b5563',
      '700': '#374151',
      '800': '#1f2937',
      '900': '#111827',
      darkest: '#808080',
    },
    zinc: {
      '50': '#fafafa',
      '100': '#f4f4f5',
      '200': '#e4e4e7',
      '300': '#d4d4d8',
      '400': '#a1a1aa',
      '500': '#71717a',
      '600': '#52525b',
      '700': '#3f3f46',
      '800': '#27272a',
      '900': '#18181b',
    },
    neutral: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#e5e5e5',
      '300': '#d4d4d4',
      '400': '#a3a3a3',
      '500': '#737373',
      '600': '#525252',
      '700': '#404040',
      '800': '#262626',
      '900': '#171717',
    },
    stone: {
      '50': '#fafaf9',
      '100': '#f5f5f4',
      '200': '#e7e5e4',
      '300': '#d6d3d1',
      '400': '#a8a29e',
      '500': '#78716c',
      '600': '#57534e',
      '700': '#44403c',
      '800': '#292524',
      '900': '#1c1917',
    },
    red: {
      '50': '#fdfcfa',
      '100': '#fbf0ea',
      '200': '#f8d0d3',
      '300': '#eea3aa',
      '400': '#ea737e',
      '500': '#d4453b',
      '600': '#c5353f',
      '700': '#9d282e',
      '800': '#711c1f',
      '850': '#611521',
      '900': '#451111',
      default: '#d4453b',
      dark: '#611521',
      light: '#c5353f',
    },
    amber: {
      '50': '#fbfaf4',
      '100': '#f9f0bd',
      '200': '#f1dd81',
      '300': '#dcb84f',
      '400': '#bd8e2a',
      '500': '#9e6f15',
      '600': '#80550d',
      '700': '#62400c',
      '800': '#432c0b',
      '900': '#2c1b08',
    },
    yellow: {
      '50': '#faf9f3',
      '100': '#f8f0b5',
      '200': '#eedf76',
      '300': '#d4bd46',
      '400': '#ac9424',
      '500': '#897611',
      '600': '#6e5d0b',
      '700': '#54450a',
      '800': '#392f09',
      '900': '#271d08',
    },
    teal: {
      '50': '#edf9f9',
      '100': '#c1f4f7',
      '200': '#81e7ed',
      '300': '#48cfdb',
      '400': '#19b2c2',
      '500': '#0e93a3',
      '600': '#187a90',
      '700': '#0e5c6a',
      '800': '#0c4451',
      '900': '#0a313b',
      DEFAULT: '#187a90',
      light: '#0e93a3',
      dark: '#0e5c6a',
      foreground: '#edf9f9',
    },
    cyan: {
      '50': '#ecfeff',
      '300': '#67e8f9',
      '500': '#06b6d4',
      '600': '#0891b2',
      '700': '#0e7490',
      '800': '#155e75',
      '900': '#164e63',
    },
    partnerblue: {
      '50': '#f7f9fa',
      '400': '#6698c9',
      '500': '#4d75b1',
      '600': '#3f5995',
      '700': '#314373',
      '800': '#15284b',
      '900': '#141b32',
      '950': '#131a43',
      default: '#141b32',
      dark: '#131a43',
      light: '#314373',
    },
    sky: {
      '50': '#f3f8f9',
      '100': '#d9f1fa',
      '200': '#ade2f4',
      '300': '#79c3e4',
      '400': '#42a0cf',
      '500': '#007bc2',
      '600': '#2965a1',
      '700': '#234c7f',
      '800': '#19335a',
      '900': '#0f1f3c',
      default: '#007bc2',
    },
    indigo: {
      '50': '#eef2ff',
      '100': '#e0e7ff',
      '200': '#c7d2fe',
      '300': '#a5b4fc',
      '400': '#818cf8',
      '500': '#6366f1',
      '600': '#4f46e5',
      '700': '#4338ca',
      '800': '#3730a3',
      '900': '#312e81',
    },
    purple: {
      '600': '#9333ea',
      '900': '#581c87',
    },
    fuchsia: {
      '300': '#f0abfc',
      '600': '#c026d3',
      '900': '#701a75',
    },
    rose: {
      '50': '#fdfcfb',
      '100': '#fbf1ee',
      '200': '#f8d0db',
      '600': '#c7334b',
      '700': '#a31f34',
      '800': '#611521',
      '900': '#451113',
      default: '#a31f34',
      dark: '#611521',
    },
    blue: {
      '50': '#f3f8f9',
      '100': '#daf1fa',
      '200': '#afe0f5',
      '300': '#7cc2e7',
      '400': '#479ed3',
      '500': '#357dc0',
      '600': '#2d62a9',
      '700': '#254a87',
      '800': '#1b3260',
      '900': '#101f3f',
    },
    pink: {
      '50': '#fdfcfb',
      '100': '#faeff2',
      '200': '#f6cbe6',
      '300': '#ec9ecb',
      '400': '#ea6fac',
      '500': '#df4b91',
      '600': '#c93270',
      '700': '#a22652',
      '800': '#771b35',
      '900': '#49111c',
    },
    green: {
      '50': '#f8fcf3',
      '100': '#e1f5ad',
      '200': '#c0e672',
      '300': '#94ce4e',
      '400': '#68b12d',
      '500': '#29a637',
      '600': '#337d13',
      '700': '#276112',
      '800': '#1c460f',
      '900': '#142e0b',
      DEFAULT: '#29a637',
      dark: '#337d13',
      light: '#68b12d',
      foreground: '#f8fcf3',
    },
  },
} as const;
