import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../../LinkyLinky';
import ScheduledToastStore from '../../../ToastsStore/ScheduledToastStore';
import { conferenceRoomProviderCata } from '../Context';
import * as style from '../style.module.css';
import ActiveTemplate from './ActiveTemplate';
import EmbeddedConferenceTemplate from './EmbeddedConferenceTemplate';
import ExternalTemplate from './ExternalTemplate';
import StartingTemplate from './StartingTemplate';

interface Props {
  store: ScheduledToastStore;
}
const EventToast: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'starting': {
      return conferenceRoomProviderCata(
        {
          gtw: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <StartingTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          external: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <StartingTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          zoom: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <StartingTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          embedToast: () => (
            <div className={style.toast} data-test-toast={true}>
              <StartingTemplate store={store} />
              <EmbeddedConferenceTemplate store={store} />
            </div>
          ),
        },
        store.scheduledToast.conferenceRoom,
      );
    }
    case 'active': {
      return conferenceRoomProviderCata(
        {
          gtw: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <ActiveTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          zoom: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <ActiveTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          external: (conferenceRoom) =>
            findLink('external-conference-url', conferenceRoom.links)
              .map((link) => (
                <div className={style.toast} data-test-toast={true}>
                  <ActiveTemplate store={store} />
                  <ExternalTemplate store={store} link={link} />
                </div>
              ))
              .getOrElseValue(<></>),
          embedToast: () => (
            <div className={style.toast} data-test-toast={true}>
              <ActiveTemplate store={store} />
              <EmbeddedConferenceTemplate store={store} />
            </div>
          ),
        },
        store.scheduledToast.conferenceRoom,
      );
    }
    case 'inactive':
    case 'dismissed':
      return <span />;
  }
};

export default observer(EventToast);
