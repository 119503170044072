import * as React from 'react';
import AnnouncementsIcon from '../../components/Cutesies/AnnouncementsIcon';
import CompletedBadgeGraphic from '../../components/Cutesies/CompletedBadgeGraphic';
import ExpertFeedbackAnnouncementIcon from '../../components/Cutesies/ExpertFeedbackAnnouncementIcon';
import LearningPartnerAssignmentAnnouncementIcon from '../../components/Cutesies/LearningPartnerAssignmentAnnouncementIcon';
import ShareFileIcon from '../../components/Cutesies/ShareFileIcon';
import { Announcement } from '../Decoders/Types';
import * as style from '../style.module.css';

interface Props {
  announcement: Announcement;
}

const AnnouncementIcon: React.FC<Props> = ({ announcement }) => {
  switch (announcement.kind) {
    case 'learning-partner-assignment-announcement':
      return <LearningPartnerAssignmentAnnouncementIcon />;
    case 'expert-feedback-announcement':
      return <ExpertFeedbackAnnouncementIcon />;
    case 'embeddable-assets-announcement':
    case 'upload-manifest-announcement':
      return <AnnouncementsIcon />;
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'coaching-survey-announcement':
      return <ShareFileIcon />;
    case 'digital-certificate-announcement':
      return <CompletedBadgeGraphic />;
  }
};

const Icon: React.FC<Props> = ({ announcement }) => {
  const iconClassName = (announcement: Announcement): string => {
    switch (announcement.kind) {
      case 'learning-partner-assignment-announcement':
      case 'expert-feedback-announcement':
      case 'embeddable-assets-announcement':
      case 'upload-manifest-announcement':
      case 'shared-to-personalized-resources-announcement':
      case 'shared-to-live-meeting-recordings-announcement':
      case 'coaching-survey-announcement':
        return style.icon;
      case 'digital-certificate-announcement':
        return style.badgeIcon;
    }
  };

  return (
    <div className={iconClassName(announcement)}>
      <AnnouncementIcon announcement={announcement} />
    </div>
  );
};

export default Icon;
