import * as React from 'react';
import Loader from '../Loader';
import * as style from './style.module.css';

interface Props {}

const ContentFaker: React.FC<Props> = () => (
  <div className={style.fakeContainer}>
    <Loader />
  </div>
);

export default ContentFaker;
