import { take } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import ChatReplyStore from '../../../ChatReplyStore';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { personStoreFromResource } from '../../../People';
import { PersonStore } from '../../../Person';
import { messageAuthor, teamMemberPerson } from '../../../Person/types';
import { T } from '../../../Translations';
import MessageAvatar from './MessageAvatar';
import MessageContent from './MessageContent';
import MessageReplyStore from './MessageReplyStore';
import * as style from './style.module.css';

interface Props {
  messageReplyStore: MessageReplyStore;
  chatReplyStore: ChatReplyStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
  currentUserResource: CurrentUserResource;
}

const getChatableResource = (
  messageResource: ChatMessageResource | ChatMessageReplyResource
): PersonStore => {
  return personStoreFromResource(messageResource.embedded.author, messageAuthor);
};

const TRUNCATED_REPLIERS_COUNT: number = 3;

const ShowReplies: React.FC<Props> = ({
  messageReplyStore,
  chatReplyStore,
  presenceStore,
  lastReadMessageStore,
  currentUserResource,
}) => {
  const { repliesState } = messageReplyStore;
  switch (repliesState.kind) {
    case 'empty-replies':
      return <></>;
    case 'lone-reply': {
      const reply = repliesState.reply;
      return (
        <>
          <div className={style.reply}></div>
          <div key={reply.payload.id}>
            <MessageContent
              personStore={getChatableResource(reply)}
              presenceStore={presenceStore}
              message={reply.payload}
              messageResource={reply}
              chatReplyStore={chatReplyStore}
              lastReadMessageStore={lastReadMessageStore}
              currentUserResource={currentUserResource}
            />
          </div>
        </>
      );
    }
    case 'abridged-replies': {
      const { uniqueRepliers, totalReplies } = repliesState;
      const truncatedRepliers = take(TRUNCATED_REPLIERS_COUNT, [...uniqueRepliers]);
      return (
        <a
          className={style.repliesToggle}
          onClick={() => messageReplyStore.expand()}
          data-test-replies-toggle
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => messageReplyStore.expand())}
        >
          <T kind="show {{count}} replies from" count={totalReplies} />
          {truncatedRepliers.map((chatablePersonResource) => {
            const personStore = personStoreFromResource(chatablePersonResource, teamMemberPerson);
            return (
              <MessageAvatar key={chatablePersonResource.payload.id} personStore={personStore} />
            );
          })}
          <>{uniqueRepliers.length > 2 ? <T kind="and others..." /> : ''}</>
        </a>
      );
    }
    case 'expanded-replies': {
      const replies = repliesState.replies;
      return (
        <>
          <a
            className={style.hideReplies}
            onClick={() => messageReplyStore.abridge()}
            data-test-replies-toggle
            tabIndex={0}
            onKeyPress={handleEnterOrSpaceKeyPress(() => messageReplyStore.abridge())}
          >
            <T kind="hide replies" />
          </a>
          {replies
            .map((t) => {
              return (
                <>
                  <div className={style.reply}></div>
                  <div key={t.payload.id}>
                    <MessageContent
                      personStore={getChatableResource(t)}
                      presenceStore={presenceStore}
                      message={t.payload}
                      messageResource={t}
                      chatReplyStore={chatReplyStore}
                      lastReadMessageStore={lastReadMessageStore}
                      currentUserResource={currentUserResource}
                    />
                  </div>
                </>
              );
            })
            .toArray()}
        </>
      );
    }
  }
};

export default observer(ShowReplies);
