import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';
import { programDashboardUrl } from '../../../../../ClientRoutes';
import { T } from '../../../../../Translations';
import Loader from '../../../../Loader';
import EnrollmentStore from './EnrollmentStore';
import RedirectToProgram from './RedirectToProgram';

interface Props {
  enrollmentStore: EnrollmentStore;
}

class Enrollment extends React.Component<Props, {}> {
  render() {
    switch (this.props.enrollmentStore.state.kind) {
      case 'unenrolled':
        return (
          <a
            className={clsx(
              'flex items-center justify-center bg-teal-600 text-sm font-semibold',
              'max-w-xs rounded-md border-2 border-teal-600 p-3 text-white hover:text-white',
              'whitespace-nowrap',
            )}
            onClick={this.props.enrollmentStore.enrolling}
          >
            <T kind="Get Started" />
          </a>
        );
      case 'redirecting':
        return (
          <RedirectToProgram
            to={programDashboardUrl(this.props.enrollmentStore.externalProgramResource.payload.id)}
          />
        );
      case 'enrolling':
        return <Loader color="black" type="spin" />;
      case 'error':
        return emptyFragment();
    }
  }
}

export default observer(Enrollment);
