import { action, observable } from 'mobx';
import { closed, open, State } from './Types';

class PopoutStore {
  @observable
  state: State = closed();

  @action
  open = (): void => {
    this.state = open();
  };

  @action
  closed = (): void => {
    this.state = closed();
  };
}

export default PopoutStore;
