import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../../../Notifications/Types';
import ProgramStore from '../../../../../../ProgramStore';
import { TPlainTextKey } from '../../../../../../Translations';
import { creating, done, OrientationCommencementState, ready, waiting } from './Types';

class OrientationCommencementStore {
  @observable
  programStore: ProgramStore;

  @observable
  state: OrientationCommencementState = waiting();

  constructor(programStore: ProgramStore) {
    this.programStore = programStore;
  }

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = ready();
        break;
      case 'ready':
      case 'creating':
      case 'done':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  creating = () => {
    switch (this.state.kind) {
      case 'waiting':
        break;
      case 'ready':
        this.state = creating();
        break;
      case 'creating':
      case 'done':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  done = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
        break;
      case 'creating':
        this.state = done();
        break;
      case 'done':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'creating':
      case 'done':
        return nothing();
      case 'error':
        return just(this.state).map(errorAlert);
    }
  }
}

export default OrientationCommencementStore;
