import { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../../../Fetch';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { useExperienceContext } from '../Experience/ExperienceContext';
import { ExperienceResource } from '../Experience/Types';
import { overviewVideoResourceDecoder } from './Decoders';
import ExperienceVideoDetails from './ExperienceVideoDetails';
import { getVideoDetails } from './Types';

interface Props {
  className?: string;
  experiencesStore: ExperiencesStore;
  children?: (props: { experienceResource: ExperienceResource }) => ReactNode;
}

function ExperienceVideoPreview({ className, experiencesStore, children = () => <></> }: Props) {
  const { invitationUuid, experienceResource } = useExperienceContext();
  const link = useFindLinkByR({ rel: 'overview-video', method: 'get' }, experienceResource.links);
  const state = useFetch(link, overviewVideoResourceDecoder);

  switch (state.kind) {
    case 'ready':
      return getVideoDetails(state.data)
        .map(({ video, sources, thumbnail }) => (
          <ExperienceVideoDetails
            className={className}
            video={video}
            sources={sources.toArray()}
            experienceResource={experienceResource}
            invitationUuid={invitationUuid}
            experiencesStore={experiencesStore}
            thumbnail={thumbnail.href}
          />
        ))
        .getOrElse(() => <>{children({ experienceResource })}</>);
    case 'loading':
    case 'errored':
      return <>{children({ experienceResource })}</>;
  }
}

export default observer(ExperienceVideoPreview);
