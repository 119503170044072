import { useRef } from 'react';

/**
 * Alternative to `useMemo` that ensures that the value is evaluated exactly once for the lifetime
 * of the component.  The `useMemo` hook does not guarantee that the value won't be forgotten
 * and need re-evaluation in the future, and this can lead to unexpected behavior.
 */
export const useConst = <T>(toValue: () => T): T => {
  const ref = useRef<T | undefined>(undefined);

  if (ref.current === undefined) {
    ref.current = toValue();
  }

  return ref.current;
};
