import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { createLanguageUrl } from '../../../CreateLanguageUrl';
import { currentUserStore } from '../../../CurrentUser/Store';
import { SupportedLanguageCode } from '../../../SupportedLanguages/Types';
import ExternalLink from '../ExternalLink';

interface Props {
  link: string;
}

const SupportLink: React.FC<Props> = ({ link, children }) =>
  createLanguageUrl(link, preferredLanguage())
    .map((l) => <ExternalLink href={l.href}>{children}</ExternalLink>)
    .getOrElse(emptyFragment);

export default observer(SupportLink);

const preferredLanguage = (): Maybe<SupportedLanguageCode> => {
  switch (currentUserStore.state.kind) {
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      return nothing();
    case 'logging-out':
    case 'ready':
    case 'refreshing':
      return currentUserStore.state.currentUserResource.payload.preferredLanguage;
  }
};
