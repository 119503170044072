import * as React from 'react';
import { Maybe } from 'maybeasy';
import { updateGtmProgramFields } from './Loader';

interface Props {
  title: string;
  sfProgramUid: Maybe<string>;
  registrationType: Maybe<string>;
}

class UpdateGtmProgramField extends React.Component<Props, {}> {
  componentDidMount() {
    const { title, sfProgramUid, registrationType } = this.props;
    updateGtmProgramFields(title, sfProgramUid, registrationType);
  }

  render() {
    return <></>;
  }
}

export default UpdateGtmProgramField;
