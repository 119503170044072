import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../Accessibility/KeyboardEvents';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceParticipants } from '../../../ConferenceRosterStore/Types';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import UserIcon from '../../Cutesies/UserIcon';
import Badge from '../../SegmentPanel/Badge';
import * as style from '../../SegmentPanel/style.module.css';

interface Props {
  tabStore: TabStore;
  conferenceRosterStore: ConferenceRosterStore;
}

const ParticipantsTab: React.FC<Props> = ({ tabStore, conferenceRosterStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('participants');
  };

  return (
    <div
      className={`${style.tab} ${tabStore.activeTab === 'participants' ? style.active : ''}`}
      onClick={handleClick}
      tabIndex={0}
      onKeyPress={handleEnterKeyPress(handleClick)}
    >
      <div className={style.tabIcon}>
        <UserIcon />
      </div>
      <div className={style.tabLabel}>
        <T kind="Participants" />
      </div>
      <Badge
        count={conferenceRosterStore.presentParticipants
          .map((participants: ConferenceParticipants) => participants.length)
          .getOrElseValue(0)}
      />
    </div>
  );
};
export default observer(ParticipantsTab);
