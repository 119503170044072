import { warn } from '@execonline-inc/logging';
import { pipe } from '@kofno/piper';
import { Task } from 'taskarian';
import { errorMessage } from '../../ExceptionHandling';
import {
  Freebusy,
  FreebusyInitializationError,
  FreebusyLoadError,
  freebusyLoadError,
} from './Types';

declare global {
  interface Window {
    freebusy?: Freebusy;
  }
}

export const loadFreebusy = (url: string): Task<FreebusyInitializationError, Freebusy> => {
  return new Task<FreebusyLoadError, Freebusy>((reject, resolve) => {
    const rejectException = pipe(errorMessage, freebusyLoadError, reject);
    try {
      if (window.freebusy) {
        resolve(window.freebusy);
      } else {
        const script = document.createElement('script');
        script.src = url;
        script.type = 'text/javascript';
        script.onload = () => {
          if (window.freebusy) {
            resolve(window.freebusy);
          } else {
            reject(freebusyLoadError('`window.freebusy` not loaded by script'));
          }
        };
        if (document.head) {
          document.head.appendChild(script);
        }
      }
    } catch (e) {
      rejectException(e);
    }

    return () => {
      warn('Loading Freebusy cannot be canceled');
    };
  });
};
