import * as React from 'react';

export function handleEnterOrSpaceKeyPress(action: () => void, event: React.KeyboardEvent): void;
export function handleEnterOrSpaceKeyPress(
  action: () => void
): (event: React.KeyboardEvent) => void;
export function handleEnterOrSpaceKeyPress(action: () => void, event?: React.KeyboardEvent) {
  const doit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      action();
    }
  };

  return typeof event === 'undefined' ? doit : doit(event);
}

export function handleEnterKeyPress(action: () => void, event: React.KeyboardEvent): void;
export function handleEnterKeyPress(action: () => void): (event: React.KeyboardEvent) => void;
export function handleEnterKeyPress(action: () => void, event?: React.KeyboardEvent) {
  const doit = (e: React.KeyboardEvent) => {
    e.key === 'Enter' && action();
  };

  return typeof event === 'undefined' ? doit : doit(event);
}

export function handleEscKeyDown(action: () => void, event: React.KeyboardEvent): void;
export function handleEscKeyDown(action: () => void): (event: React.KeyboardEvent) => void;
export function handleEscKeyDown(action: () => void, event?: React.KeyboardEvent) {
  const doit = (e: React.KeyboardEvent) => {
    e.keyCode === 27 && action();
  };

  return typeof event === 'undefined' ? doit : doit(event);
}
