import { warnAndNotify } from '../../../Honeybadger';
import { errorMessage, errorName } from './Types';

export const onError = (error: unknown, errorInfo: React.ErrorInfo): void => {
  if (error instanceof Error && error.name === errorName) {
    reportImproperUse(error, errorInfo);
  } else {
    throw error;
  }
};

const reportImproperUse = (error: Error, { componentStack }: React.ErrorInfo): void =>
  warnAndNotify(error.name, error.message, { componentStack });

/**
 * Unfortunately, there doesn't seem to be a way to get the component stack trace other than
 * throwing an error and using `componentDidCatch`.
 */
export const throwMisuseError = (): never => {
  const err = new Error(errorMessage);
  err.name = errorName;
  throw err;
};
