import { observer } from 'mobx-react';
import * as React from 'react';
import SizedImageStore from './SizedImageStore';
import { SizedImageProps } from './Types';

interface ImplProps extends SizedImageProps {
  store: SizedImageStore;
}

const SizedImageImpl: React.FC<ImplProps> = ({ store, uri, children }) => {
  const state = store.state;

  switch (state.kind) {
    case 'waiting':
    case 'loading':
    case 'error':
      return <></>;
    case 'ready':
      return children(uri, state.dimensions);
  }
};

export default observer(SizedImageImpl);
