import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import ContentFaker from '../../ContentFaker';
import FlashMessage from '../../FlashMessage';
import BreadCrumb from '../BreadCrumb';
import ResourcesList from '../Shared/ResourcesList';
import * as style from '../style.module.css';
import PersonalResourcesStore from './PersonalResourcesStore';

interface Props {
  store: PersonalResourcesStore;
}

const PersonalResourcesView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'ready':
    case 'error':
      return (
        <>
          <section className={style.section}>
            <BreadCrumb
              path="/resources/personalized_to_you"
              title={<T kind="Personalized To You" />}
            />
            {store.notification
              .map((alert) => <FlashMessage alert={alert} />)
              .getOrElseValue(<></>)}
          </section>
          <section className={style.section}>
            {store.personalResourcesResources.map((personalResourcesResource) => (
              <ResourcesList
                key={personalResourcesResource.payload.id}
                resources={personalResourcesResource.payload}
              />
            ))}
          </section>
        </>
      );
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
  }
};

export default observer(PersonalResourcesView);
