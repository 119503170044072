import { observer } from 'mobx-react';
import { useExperienceContext } from '../../Experience/ExperienceContext';
import { SchoolPartnerLogo } from '../../Experience/SchoolPartner';

function ExperiencesHeaderLogo() {
  const { experienceResource } = useExperienceContext();

  return <SchoolPartnerLogo height={38} data={experienceResource.payload.schoolPartner} />;
}

export default observer(ExperiencesHeaderLogo);
