import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../../Notifications/Types';
import { Link } from '../../../../../Resource/Types';
import { TPlainTextKey } from '../../../../../Translations';
import {
  loading,
  ready,
  State,
  TermsAndConditionsResource,
  updated,
  updating,
  waiting,
} from './Types';

class TermsAndConditionsStore {
  @observable
  state: State;

  constructor() {
    this.state = waiting();
  }

  @action
  ready = (termsResource: TermsAndConditionsResource): void => {
    this.state = ready(termsResource, nothing());
  };

  @action
  loading = (): void => {
    this.state = loading();
  };

  @action
  error = (msg: TPlainTextKey): void => {
    this.state = error(msg);
  };

  @action
  updating = (): void => {
    switch (this.state.kind) {
      case 'ready':
        if (this.state.termsAccepted) {
          this.state = updating(this.state.termsResource);
        } else {
          this.state = ready(
            this.state.termsResource,
            just('Please accept the Terms of Use and Privacy Policy in order to continue'),
          );
        }
        break;
      case 'updating':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'updated':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  updated = (): void => {
    switch (this.state.kind) {
      case 'updating':
        this.state = updated();
        break;
      case 'ready':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'updated':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTermsAcceptance = (termsAccepted: boolean): void => {
    switch (this.state.kind) {
      case 'ready':
        this.state.termsAccepted = termsAccepted;
        break;
      case 'updating':
      case 'waiting':
      case 'error':
      case 'loading':
      case 'updated':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get formInputsEnabled(): boolean {
    switch (this.state.kind) {
      case 'ready':
        return true;
      case 'waiting':
      case 'loading':
      case 'updating':
      case 'error':
      case 'updated':
        return false;
    }
  }

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'updating':
      case 'ready':
        return this.state.termsResource.links;
      case 'waiting':
      case 'loading':
      case 'error':
      case 'updated':
        return [];
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
        return this.state.message.map(error).map(errorAlert);
      case 'updating':
      case 'waiting':
      case 'loading':
      case 'updated':
        return nothing();
    }
  }
}

export default TermsAndConditionsStore;
