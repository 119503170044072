import { just } from 'maybeasy';
import { computed } from 'mobx';
import { every10Sec, isBefore } from '../../../../../Date';
import { CoachingSession } from '../../../../../ProgramStore/Types';
import {
  hiddenCoachingSession,
  TimeOrientedCoachingSession,
  visibleCoachingSession,
} from '../Types';

class SessionStore {
  constructor(public session: CoachingSession) {}

  @computed
  get coachingSession(): TimeOrientedCoachingSession {
    const now = every10Sec.get();
    const { startAt, endAt, accessAt } = this.session;

    return just({})
      .assign('startAt', startAt)
      .assign('endAt', endAt)
      .assign('accessAt', accessAt)
      .map(({ startAt, endAt, accessAt }) => {
        if (isBefore(endAt, now)) {
          return hiddenCoachingSession(this.session);
        } else if (isBefore(accessAt, now)) {
          return visibleCoachingSession(this.session);
        } else if (isBefore(startAt, now)) {
          return visibleCoachingSession(this.session);
        } else {
          return hiddenCoachingSession(this.session);
        }
      })
      .getOrElseValue(hiddenCoachingSession(this.session));
  }
}

export const sessionStore = (session: CoachingSession): SessionStore => new SessionStore(session);

export default SessionStore;
