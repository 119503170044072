import * as React from 'react';
import SVGIcon from '../../../../../Cutesies/SVGIcon';

const OrientationModalGraphicSVG: React.FC = () => (
  <SVGIcon viewBox="0 0 389 242" width="389" height="242">
    <g xmlns="http://www.w3.org/2000/svg" opacity="0.8">
      <path
        d="M263.633 123.231H274.202C275.022 123.231 275.686 122.545 275.686 121.699C275.686 120.852 275.022 120.166 274.202 120.166H263.633C262.069 120.192 260.507 120.041 258.975 119.714H258.856C258.619 119.668 256.401 119.216 256.401 117.071C256.455 115.985 256.622 114.909 256.898 113.861C258.433 107.026 260.035 101.149 261.096 97.2646L261.208 96.8665C261.327 96.4301 261.46 95.9244 261.6 95.3879C262.342 92.5299 264.308 84.9447 266.436 84.9447C267.255 84.9447 267.919 84.2588 267.919 83.4123C267.919 82.5658 267.255 81.8799 266.436 81.8799C262.03 81.8799 260.124 89.2276 258.73 94.591C258.596 95.1197 258.47 95.6023 258.352 96.0311L258.24 96.4369C257.172 100.345 255.555 106.259 254.005 113.163C253.678 114.451 253.486 115.77 253.433 117.101C253.433 120.932 256.6 122.465 258.336 122.764C260.08 123.126 261.856 123.282 263.633 123.231Z"
        fill="#187A90"
      />
      <path
        d="M262.972 123.667C263.196 123.667 263.421 123.663 263.645 123.657H274.203C275.249 123.657 276.1 122.778 276.1 121.698C276.1 120.618 275.249 119.739 274.203 119.739L263.287 119.741C261.871 119.741 260.448 119.591 259.059 119.295C259.032 119.289 259.004 119.287 258.976 119.287H258.857C258.58 119.226 256.815 118.797 256.815 117.071C256.867 116.04 257.029 114.99 257.297 113.973C258.845 107.082 260.439 101.244 261.494 97.3811L261.605 96.9856C261.725 96.5448 261.858 96.0375 262 95.5002C264.173 87.1275 265.611 85.3721 266.437 85.3721C267.483 85.3721 268.334 84.4932 268.334 83.4126C268.334 82.3321 267.483 81.4531 266.437 81.4531C261.718 81.4531 259.763 88.9734 258.335 94.4641C258.197 95.0082 258.072 95.4874 257.955 95.9145L257.844 96.321C256.775 100.232 255.158 106.143 253.603 113.067C253.272 114.37 253.076 115.726 253.022 117.085C253.022 121.198 256.455 122.872 258.27 123.186C259.806 123.504 261.393 123.667 262.972 123.667ZM263.64 120.593H274.203C274.793 120.593 275.273 121.088 275.273 121.698C275.273 122.308 274.793 122.803 274.203 122.803H263.634C263.405 122.81 263.189 122.813 262.972 122.813C261.447 122.813 259.915 122.655 258.419 122.345C256.713 122.051 253.849 120.618 253.849 117.101C253.9 115.82 254.088 114.526 254.406 113.271C255.96 106.355 257.572 100.456 258.64 96.5525L258.751 96.1468C258.871 95.7163 258.997 95.2303 259.131 94.6981C260.493 89.4611 262.353 82.3065 266.437 82.3065C267.027 82.3065 267.507 82.8027 267.507 83.4118C267.507 84.0208 267.027 84.5171 266.437 84.5171C264.284 84.5171 262.584 89.9505 261.205 95.2653C261.062 95.8102 260.929 96.3151 260.811 96.7498L260.7 97.1453C259.642 101.019 258.045 106.869 256.497 113.763C256.217 114.825 256.045 115.936 255.99 117.049C255.99 118.933 257.493 119.885 258.781 120.133L258.924 120.138C260.357 120.441 261.825 120.595 263.287 120.595L263.64 120.593Z"
        fill="#187A90"
      />
      <path
        d="M262.506 52.3043L263.248 51.9746L275.686 114.802H274.899C273.016 114.818 271.376 113.475 270.961 111.577L260.191 56.8862C259.813 54.9848 260.779 53.0705 262.506 52.3043Z"
        fill="#187A90"
      />
      <path
        d="M274.867 115.229C274.868 115.229 274.902 115.229 274.903 115.229H275.686C275.81 115.229 275.927 115.172 276.006 115.072C276.085 114.973 276.116 114.842 276.091 114.717L263.652 51.889C263.628 51.7634 263.549 51.6558 263.439 51.596C263.329 51.537 263.199 51.5319 263.084 51.5823L262.343 51.912C260.446 52.7551 259.371 54.8837 259.787 56.9722L270.557 111.661C271.017 113.766 272.789 115.229 274.867 115.229ZM275.179 114.375H274.9H274.867C273.178 114.375 271.737 113.185 271.365 111.482L260.597 56.8005C260.26 55.105 261.131 53.3795 262.67 52.6962L262.945 52.574L275.179 114.375Z"
        fill="#187A90"
      />
      <path
        d="M312.122 208.358C312.732 208.358 313.291 207.952 313.462 207.335L326.814 159.001C327.02 158.26 326.586 157.491 325.845 157.286C325.104 157.082 324.339 157.515 324.133 158.257L310.781 206.591C310.576 207.333 311.01 208.101 311.75 208.307C311.875 208.341 311.999 208.358 312.122 208.358Z"
        fill="white"
      />
      <path
        d="M312.123 208.637C312.87 208.637 313.531 208.132 313.731 207.41L327.083 159.077C327.202 158.646 327.147 158.195 326.927 157.805C326.708 157.417 326.35 157.137 325.92 157.018C325.045 156.776 324.106 157.316 323.866 158.184L310.514 206.517C310.395 206.948 310.451 207.399 310.67 207.788C310.89 208.177 311.247 208.457 311.677 208.576C311.824 208.617 311.975 208.637 312.123 208.637ZM325.474 157.514C325.574 157.514 325.675 157.528 325.772 157.555C326.058 157.634 326.297 157.821 326.443 158.08C326.589 158.339 326.626 158.64 326.547 158.927L313.195 207.261C313.035 207.838 312.423 208.205 311.825 208.038C311.539 207.959 311.301 207.772 311.154 207.513C311.007 207.254 310.97 206.953 311.05 206.666L324.402 158.333C324.535 157.851 324.976 157.514 325.474 157.514Z"
        fill="white"
      />
      <path d="M323.805 156.016H361.637V158.63H323.805V156.016Z" fill="white" />
      <path
        d="M374.989 208.358C375.112 208.358 375.237 208.341 375.361 208.307C376.102 208.102 376.535 207.333 376.331 206.591L363.104 158.711L387.964 96.4867C388.25 95.7717 387.904 94.9601 387.191 94.674C386.477 94.3867 385.668 94.7348 385.382 95.4498L360.172 158.548L373.65 207.335C373.82 207.953 374.38 208.358 374.989 208.358Z"
        fill="white"
      />
      <path
        d="M374.989 208.637C375.138 208.637 375.288 208.617 375.436 208.576C375.866 208.457 376.223 208.177 376.443 207.788C376.663 207.4 376.718 206.948 376.599 206.517L363.397 158.728L388.223 96.5907C388.389 96.1757 388.383 95.7206 388.208 95.31C388.033 94.8995 387.709 94.5811 387.294 94.4154C387.095 94.3356 386.886 94.2949 386.673 94.2949C385.987 94.2949 385.379 94.7077 385.124 95.3463L359.878 158.532L359.904 158.623L373.382 207.41C373.581 208.132 374.242 208.637 374.989 208.637ZM386.673 94.8527C386.815 94.8527 386.954 94.88 387.087 94.933C387.363 95.044 387.58 95.2554 387.696 95.5298C387.813 95.8037 387.817 96.1071 387.706 96.3837L362.81 158.695L362.835 158.786L376.062 206.666C376.142 206.953 376.104 207.254 375.958 207.513C375.812 207.773 375.574 207.96 375.287 208.039C374.691 208.203 374.078 207.839 373.918 207.261L360.465 158.564L385.641 95.5538C385.811 95.1282 386.217 94.8527 386.673 94.8527Z"
        fill="white"
      />
      <path
        d="M286.523 206.814L293.881 205.944L301.298 197.497C302.021 196.674 302.418 195.615 302.416 194.517L302.29 145.19L302.347 145.168C307.728 143.103 313.638 146.521 314.57 152.233C314.963 154.644 315.344 157.061 315.807 159.461C316.261 161.814 316.769 164.188 316.735 166.598C316.685 170.04 315.843 173.437 315.327 176.826C314.766 180.511 314.204 184.196 313.642 187.881C313.641 187.887 313.64 187.894 313.639 187.9L312.232 197.131L314.014 201.127C314.96 203.247 313.416 205.643 311.103 205.643H307.35C307.35 205.643 297.521 210.425 296.575 210.455C295.275 210.497 283.705 210.455 283.705 210.455V209.998C283.705 208.372 284.916 207.004 286.523 206.814Z"
        fill="#C7C7C7"
      />
      <path
        d="M342.482 93.5137H335.873V64.1074C339.523 64.1074 342.482 67.0811 342.482 70.7491V93.5137Z"
        fill="#494949"
      />
      <path
        d="M327.186 81.9934H317.606V67.0439H333.189V75.9605C333.189 79.2923 330.501 81.9934 327.186 81.9934Z"
        fill="#C7C7C7"
      />
      <path
        d="M328.419 99.9326L335.383 91.7496L330.263 78.6875L325.254 80.6852L321.28 82.3104L328.419 99.9326Z"
        fill="#C7C7C7"
      />
      <path
        d="M315.327 67.5498V71.3569C315.327 71.4949 315.354 71.6324 315.405 71.7606L315.907 73.0156C316.076 73.4372 315.964 73.9194 315.628 74.2232L315.327 74.4952V81.8588C315.327 83.0566 316.296 84.0268 317.489 84.0216L331.086 83.9662L331.03 79.3124L315.327 67.5498Z"
        fill="#C7C7C7"
      />
      <path
        d="M317.219 78.8835L314.243 77.3311C313.57 76.9799 313.459 76.0588 314.03 75.5569L317.219 72.7559V78.8835Z"
        fill="#C7C7C7"
      />
      <path
        opacity="0.07"
        d="M321.941 83.8958L323.018 83.879C323.724 83.8681 324.273 84.4935 324.174 85.1958L323.728 88.3595"
        fill="#494949"
      />
      <path
        opacity="0.07"
        d="M315.678 71.6211H317.582C317.754 71.6211 317.918 71.6892 318.039 71.8111L318.653 72.4279C319.06 72.8368 318.772 73.5356 318.196 73.5356H315.982"
        fill="#494949"
      />
      <path
        opacity="0.07"
        d="M317.244 78.7223L316.999 79.2045C316.864 79.4684 316.627 79.6654 316.344 79.7491L315.37 80.0367V78.6963L317.244 78.7223Z"
        fill="#494949"
      />
      <path
        d="M321.994 59.6387H322.316C323.677 59.6387 324.781 60.7481 324.781 62.1163V65.0698V65.898V70.7204H313.364V68.3103C313.364 63.5208 317.227 59.6387 321.994 59.6387Z"
        fill="#494949"
      />
      <path
        d="M323.465 70.5972H336.295V67.7246C336.295 63.1355 332.592 59.415 328.026 59.415H325.324C324.298 59.415 323.465 60.2513 323.465 61.2834V70.5972Z"
        fill="#494949"
      />
      <path
        d="M340.716 134.526L363.359 138.673C363.921 134.028 362.661 129.346 359.847 125.618L352.784 116.268C350.625 113.41 349.068 110.14 348.207 106.657L342.009 92.1065C340.834 89.3482 338.349 87.376 335.407 86.8671C330.999 86.1054 326.812 89.0774 326.054 93.5059L323.928 105.923C323.418 108.904 324.652 111.915 327.103 113.67L333.204 118.039L340.716 134.526Z"
        fill="#757575"
      />
      <path
        d="M314.182 138.424L329.8 135.896L354.581 132.027L358.23 131.713C362.295 131.364 365.787 134.586 365.787 138.685V141.296C365.787 149.621 359.071 156.37 350.787 156.37H318.264L314.182 138.424Z"
        fill="#535353"
      />
      <path
        d="M360.587 126.679L347.015 138.11L365.791 138.836C365.773 134.567 364.255 130.333 361.238 127.327L360.587 126.679Z"
        fill="#535353"
      />
      <path
        d="M312.595 121.742L330.87 100.749C332.811 98.5188 332.606 95.1333 330.409 93.157C328.045 91.0299 324.372 91.4088 322.487 93.9736L306.175 116.169L309.356 121.457C310.061 122.628 311.697 122.773 312.595 121.742Z"
        fill="#757575"
      />
      <path
        d="M302.473 115.277L302.381 91.2036C302.38 90.8923 302.63 90.6387 302.94 90.6376L308.544 90.6162L312.005 109.395C312.141 110.381 312.21 111.376 312.214 112.371L312.228 116.311C312.238 118.721 310.301 120.683 307.902 120.692C304.915 120.703 302.484 118.279 302.473 115.277Z"
        fill="#C7C7C7"
      />
      <path
        d="M313.805 89.879L312.887 91.6306C312.396 92.5674 311.592 93.3003 310.617 93.7017L308.662 94.5056L312.163 87.79L313.348 88.4149C313.876 88.6933 314.081 89.3482 313.805 89.879Z"
        fill="#C7C7C7"
      />
      <path
        d="M309.491 90.3546C310.463 90.8526 310.794 92.1546 310.231 93.2627C309.669 94.3708 308.425 94.8654 307.454 94.3675C306.483 93.8695 306.151 92.5675 306.714 91.4594C307.277 90.3513 308.52 89.8567 309.491 90.3546Z"
        fill="#C7C7C7"
      />
      <path
        d="M308.272 92.5127L312.026 85.1191C312.278 84.6219 312.885 84.4244 313.38 84.6779L313.615 84.7986C314.166 85.081 314.385 85.7596 314.104 86.314L310.402 93.6048L308.272 92.5127Z"
        fill="#C7C7C7"
      />
      <path
        d="M302.568 90.7731L304.884 86.2112C305.278 85.4367 306.071 84.9493 306.937 84.9522L312.135 84.9678L307.82 93.4662L302.568 90.7731Z"
        fill="#C7C7C7"
      />
      <path
        d="M338.178 156.369H312.837L294.481 146.344L301.92 141.309C303.457 140.269 305.215 139.601 307.053 139.36L337.778 135.316L338.178 156.369Z"
        fill="#535353"
      />
      <path
        d="M312.138 196.921L314.014 201.126C314.96 203.247 313.416 205.643 311.103 205.643H307.349C307.349 205.643 297.521 210.418 296.575 210.455C295.488 210.496 283.705 210.455 283.705 210.455V209.997C283.705 208.372 284.916 207.003 286.523 206.814L293.881 205.944L307.078 202.674L312.138 196.921Z"
        fill="#666666"
      />
      <path d="M306.626 204.28H313.738L312.865 210.322H306.626V204.28Z" fill="#666666" />
      <path
        d="M326.543 80.7286H329.504C331.993 80.7286 334.011 78.7009 334.011 76.2002V72.2857C334.011 71.0116 335.039 69.9784 336.307 69.9784H336.879V67.5967H321.009L326.543 80.7286Z"
        fill="#494949"
      />
      <path
        d="M335.154 63.3379V70.5571"
        stroke="#666666"
        strokeWidth="6.59061"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M324.424 77.1986H322.51L322.692 73.172C322.751 71.8708 323.818 70.8457 325.115 70.8457H325.254C326.586 70.8457 327.524 72.1619 327.094 73.4296L326.264 75.8754C325.995 76.6667 325.256 77.1986 324.424 77.1986Z"
        fill="#C7C7C7"
      />
      <path
        d="M311.192 103.16L312.94 111.801C313.088 112.787 313.165 113.782 313.169 114.777L313.186 119.872C313.195 122.282 311.07 123.089 308.438 123.098C305.16 123.109 302.492 120.685 302.479 117.683L302.419 103.16H311.192V103.16Z"
        fill="#757575"
      />
      <path d="M300.103 103.16H309.936V109.48H300.103V103.16Z" fill="#757575" />
      <path
        d="M321.237 93.9344L337.91 90.2255L334.869 83.8154L323.774 85.4897L321.237 93.9344Z"
        fill="#757575"
      />
      <path
        d="M211.537 168.105L211.53 209.596H218.342L218.35 168.105L218.342 126.235H211.53L211.537 168.105Z"
        fill="#E6E7EB"
      />
      <path
        d="M211.517 209.949C211.522 209.949 211.526 209.949 211.531 209.949H218.343C218.537 209.949 218.695 209.791 218.695 209.596L218.703 168.105L218.695 126.235C218.695 126.041 218.537 125.883 218.343 125.883H211.531C211.437 125.883 211.347 125.92 211.281 125.986C211.215 126.051 211.178 126.142 211.178 126.235L211.186 168.105L211.178 209.497C211.169 209.528 211.164 209.562 211.164 209.596C211.164 209.792 211.322 209.949 211.517 209.949ZM217.99 209.244H211.883L211.891 168.105L211.883 126.588H217.99L217.998 168.105L217.99 209.244Z"
        fill="#E6E7EB"
      />
      <path
        d="M244.121 167.727L244.114 209.596H250.927L250.934 167.727L250.927 126.235H244.114L244.121 167.727Z"
        fill="#E6E7EB"
      />
      <path
        d="M244.101 209.949C244.106 209.949 244.11 209.949 244.115 209.949H250.927C251.121 209.949 251.279 209.791 251.279 209.596L251.287 167.726L251.279 126.235C251.279 126.041 251.121 125.883 250.927 125.883H244.115C244.021 125.883 243.931 125.92 243.865 125.986C243.799 126.051 243.762 126.142 243.762 126.235L243.77 167.726L243.762 209.497C243.753 209.528 243.748 209.562 243.748 209.596C243.748 209.792 243.906 209.949 244.101 209.949ZM250.574 209.244H244.467L244.475 167.726L244.467 126.588H250.574L250.582 167.726L250.574 209.244Z"
        fill="#E6E7EB"
      />
      <path d="M200.923 122.446H322.166V130.783H200.923V122.446Z" fill="#E6E7EB" />
      <path
        d="M200.922 131.135H322.165C322.36 131.135 322.518 130.977 322.518 130.782V122.446C322.518 122.252 322.36 122.094 322.165 122.094H200.922C200.728 122.094 200.57 122.252 200.57 122.446V130.782C200.57 130.977 200.727 131.135 200.922 131.135ZM321.813 130.43H201.275V122.799H321.813V130.43Z"
        fill="#E6E7EB"
      />
      <path d="M200.921 202.018H281.244V209.596H200.921V202.018Z" fill="#E6E7EB" />
      <path
        d="M200.922 209.948H281.245C281.44 209.948 281.598 209.79 281.598 209.596V202.018C281.598 201.823 281.44 201.665 281.245 201.665H200.922C200.727 201.665 200.569 201.823 200.569 202.018V209.596C200.569 209.791 200.727 209.948 200.922 209.948ZM280.893 209.243H201.274V202.37H280.893V209.243Z"
        fill="#E6E7EB"
      />
      <path
        d="M325.267 73.0383C324.953 73.0383 324.698 72.7765 324.698 72.454V62.7663C324.698 60.5665 322.956 58.7769 320.814 58.7769C319.864 58.7769 318.951 59.1316 318.241 59.7766C318.138 59.8713 318.004 59.9238 317.864 59.9238C317.702 59.9238 317.546 59.852 317.438 59.7264C317.337 59.6095 317.287 59.4594 317.296 59.3034C317.305 59.1474 317.373 59.0048 317.487 58.9014C318.405 58.0676 319.586 57.6084 320.814 57.6084C323.583 57.6084 325.836 59.9221 325.836 62.7663V72.454C325.836 72.7765 325.581 73.0383 325.267 73.0383Z"
        fill="white"
      />
      <path
        d="M320.813 57.9007C323.425 57.9007 325.55 60.0835 325.55 62.7664V72.4542C325.55 72.6154 325.423 72.7463 325.266 72.7463C325.109 72.7463 324.981 72.6154 324.981 72.4542V62.7664C324.981 60.4054 323.112 58.4849 320.813 58.4849C319.794 58.4849 318.813 58.8659 318.051 59.5582C317.997 59.6079 317.93 59.6319 317.863 59.6319C317.784 59.6319 317.706 59.5985 317.65 59.5331C317.546 59.4122 317.557 59.2275 317.675 59.1212C318.541 58.3336 319.655 57.9007 320.813 57.9007ZM320.813 57.3164C319.516 57.3164 318.267 57.8019 317.298 58.6836C317.127 58.8378 317.025 59.0523 317.011 59.286C316.997 59.5191 317.073 59.744 317.223 59.9193C317.386 60.1086 317.619 60.2161 317.863 60.2161C318.072 60.2161 318.273 60.1372 318.43 59.9947C319.086 59.3981 319.933 59.0692 320.813 59.0692C322.798 59.0692 324.413 60.7279 324.413 62.7664V72.4542C324.413 72.9373 324.795 73.3306 325.266 73.3306C325.736 73.3306 326.119 72.9373 326.119 72.4542V62.7664C326.119 59.7616 323.739 57.3164 320.813 57.3164Z"
        fill="white"
      />
      <path
        d="M325.357 77.4801C327.687 77.4801 329.575 75.5404 329.575 73.1478C329.575 70.7551 327.687 68.8154 325.357 68.8154C323.027 68.8154 321.139 70.7551 321.139 73.1478C321.139 75.5404 323.027 77.4801 325.357 77.4801Z"
        fill="white"
        stroke="white"
        strokeWidth="2.19834"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M325.358 76.0051C326.894 76.0051 328.14 74.726 328.14 73.1481C328.14 71.5702 326.894 70.291 325.358 70.291C323.822 70.291 322.576 71.5702 322.576 73.1481C322.576 74.726 323.822 76.0051 325.358 76.0051Z"
        fill="white"
        stroke="white"
        strokeWidth="2.19834"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M105.43 23.4863H200.578V174.903H105.43V23.4863Z" fill="#F3F4F6" />
      <path d="M0.00132751 23.4863H95.3887V174.903H0.00132751V23.4863Z" fill="#F3F4F6" />
      <path
        d="M153.96 161.507V125.379H161.61V102.646H166.87V86.408H174.041L178.823 53.9326L184.082 86.408H190.776V102.646H196.036V125.379H200.578V174.904H147.266V161.507H153.96Z"
        fill="#E8E9EC"
      />
      <path
        d="M15.1234 122.537V103.458L31.803 97.791L48.1144 103.458V122.537H48.3535V174.904H8.42955V122.537H15.1234Z"
        fill="#E6E7EB"
      />
      <path d="M200.578 135.675V150.277L156.738 174.903H130.742L200.578 135.675Z" fill="#FCFCFD" />
      <path
        d="M105.428 30.7564L118.357 23.4863H160.767L161.035 23.8274L105.428 55.0976V30.7564Z"
        fill="#FCFCFD"
      />
      <path
        d="M95.3887 36.4023V60.7439L0.00132827 114.385V90.0402L95.3887 36.4023Z"
        fill="#FCFCFD"
      />
      <path d="M200.578 159.271V169.001L190.045 174.903H172.679L200.578 159.271Z" fill="#FCFCFD" />
      <path d="M105.429 64.3525L178.249 23.4863H195.586L105.429 74.0824V64.3525Z" fill="#FCFCFD" />
      <path
        d="M95.3887 69.9873V79.7173L0.00132827 133.249V123.519L95.3887 69.9873Z"
        fill="#FCFCFD"
      />
      <g clipPath="url(#clip0_850_34251)">
        <path
          d="M92.2461 189.863L90.2331 207.562L89.5467 213.592L89.1711 216.885L89.0605 217.859L88.7028 221.009L88.5255 222.563L88.255 224.926L88.2406 225.061C88.2406 225.061 89.8555 225.106 91.7271 225.158C92.552 225.182 93.4267 225.207 94.2327 225.233C94.6895 225.245 95.1209 225.259 95.5134 225.271C96.5192 225.302 97.2444 225.328 97.3361 225.336L97.4218 225.043L97.6654 224.214L99.5842 217.694L101.735 210.381L110.719 187.749L114.475 167.226L101.055 166.82L93.1727 186.578C92.6815 187.679 92.3687 188.789 92.2461 189.863V189.863Z"
          fill="#B3B3B3"
        />
        <path
          d="M109.998 228.526L112.189 227.218C112.793 226.857 113.214 226.24 113.307 225.578C113.443 224.605 112.845 223.78 111.903 223.642L107.748 223.035C105.121 222.651 102.955 221.12 101.81 218.837L100.44 216.105L98.6167 217.133C98.1773 217.381 97.9507 217.894 98.0901 218.325L98.4892 219.559L95.9895 220.543C94.2963 221.209 92.5005 220.902 91.3638 219.753L89.6083 217.978C89.301 217.667 88.7142 217.9 88.624 218.369L87.0902 226.356L87.3064 228.235L109.998 228.526Z"
          fill="#666666"
        />
        <path
          d="M88.921 216.351H103.811L110.719 187.749L114.475 167.224L101.055 166.819L93.1741 186.576C92.681 187.679 92.3672 188.787 92.2476 189.861L88.921 216.351Z"
          fill="#757575"
        />
        <path
          d="M86.403 226.881L86.6636 227.671C87.1328 229.093 88.4329 230 90.0011 230H106.675C106.996 230 107.322 229.917 107.624 229.76L110.637 228.186C110.886 228.056 110.793 227.678 110.513 227.678H97.8787C97.6356 227.678 97.4154 227.583 97.265 227.413C96.5098 226.559 95.4039 226.081 94.1825 226.081H87.0217C86.6108 226.081 86.28 226.509 86.403 226.881Z"
          fill="#545454"
        />
        <path
          d="M135.538 114.887C135.95 117.243 134.38 119.488 132.029 119.902L112.555 123.329C109.209 123.917 106.019 121.676 105.432 118.322L105.272 117.408C104.905 115.316 106.3 113.322 108.388 112.955L134.398 108.378L135.538 114.887Z"
          fill="#545454"
        />
        <path
          d="M118.64 125.952L118.658 134.918L111.502 183.654C111.502 183.654 97.678 178.739 97.7005 178.477L99.6034 125.858L118.64 125.952Z"
          fill="#757575"
        />
        <path
          d="M90.9444 137.764L90.9618 139.341L106.098 181.213C106.098 181.213 118.945 174.581 118.883 174.364L108.942 130.27L94.2137 133.02C92.0084 133.432 90.5479 135.551 90.9444 137.764V137.764Z"
          fill="#757575"
        />
        <path
          d="M101.707 209.316L102.07 215.374L102.269 218.683L102.328 219.662L102.519 222.826L102.612 224.387L102.753 226.762L102.762 226.898C102.762 226.898 104.36 226.662 106.212 226.389C107.029 226.27 107.894 226.142 108.693 226.028C109.145 225.961 109.572 225.9 109.961 225.844C110.957 225.7 111.676 225.6 111.768 225.592L111.801 225.289L111.899 224.429L112.664 217.675L113.521 210.1L101.707 209.316Z"
          fill="#B3B3B3"
        />
        <path
          d="M123.308 228.526L125.5 227.218C126.104 226.857 126.524 226.24 126.617 225.578C126.754 224.605 126.156 223.78 125.214 223.642L121.058 223.035C118.432 222.651 116.266 221.12 115.121 218.837L113.75 216.105L111.927 217.133C111.488 217.381 111.261 217.894 111.401 218.325L111.8 219.559L109.3 220.543C107.607 221.209 105.811 220.902 104.674 219.753L102.919 217.978C102.612 217.667 102.025 217.9 101.935 218.369L100.401 226.356L100.617 228.235L123.308 228.526Z"
          fill="#666666"
        />
        <path
          d="M99.7136 226.881L99.9741 227.671C100.443 229.093 101.743 230 103.312 230H119.986C120.306 230 120.633 229.917 120.935 229.76L123.947 228.186C124.196 228.056 124.104 227.678 123.823 227.678H111.189C110.946 227.678 110.726 227.583 110.576 227.413C109.82 226.559 108.714 226.081 107.493 226.081H100.332C99.9213 226.081 99.5905 226.509 99.7136 226.881V226.881Z"
          fill="#545454"
        />
        <path
          d="M107.502 142.957H96.4054C93.1794 142.957 90.5642 140.336 90.5642 137.103V125.302H107.502V142.957Z"
          fill="#757575"
        />
        <path
          d="M118.809 135.135L90.5805 135.468L90.9128 107.509H112.651C115.706 107.509 118.203 109.952 118.277 113.014L118.809 135.135Z"
          fill="#545454"
        />
        <path
          d="M120.443 113.766L110.528 90.9058L98.4722 96.1588L108.388 119.019L120.443 113.766Z"
          fill="#545454"
        />
        <path
          d="M116.136 115.855L116.547 125.128L97.1672 129.61L89.9606 119.27C87.0301 115.064 85.0236 110.284 84.0736 105.243L81.4743 89.8954C81.2915 88.9263 82.8706 86.3736 83.6686 85.7969L87.2174 83.303L89.0735 79.4656C96.9271 74.9023 107.011 79.0133 110.376 89.3163L115.732 112.865C115.957 113.847 116.092 114.848 116.136 115.855V115.855Z"
          fill="#545454"
        />
        <path
          d="M100.253 85.4698C100.253 85.4698 93.5537 77.5833 93.5402 77.5674C93.2279 77.1999 89.5083 78.7656 89.0829 78.9628C82.0496 82.2211 79.003 91.2025 80.8924 98.4005L83.3971 107.945L106.953 93.3114L100.253 85.4698Z"
          fill="#545454"
        />
        <path
          opacity="0.07"
          d="M89.4659 103.059L94.9858 103.822C95.8889 104.087 96.6207 103.065 96.0822 102.292L91.2094 95.2979L89.4659 103.059Z"
          fill="#494949"
        />
        <path
          d="M83.1116 123.653C81.4155 123.607 80.0381 121.923 80.0351 119.892L79.9992 94.687C79.9933 90.6139 82.746 87.3876 86.1468 87.4795L87.0738 87.5044C89.1954 87.5623 90.9185 89.6683 90.922 92.2096L90.9668 123.866L83.1116 123.653Z"
          fill="#545454"
        />
        <path
          d="M103.314 77.0711L91.8864 72.7369C90.3427 72.1512 89.5646 70.4222 90.1489 68.875L92.8901 61.6141C94.4643 57.4456 99.1114 55.3457 103.27 56.9229L109.963 59.4617L103.314 77.0711Z"
          fill="#7B7B7B"
        />
        <path
          d="M111.438 68.9209L107.597 79.2748C107.105 80.4846 105.579 80.863 104.581 80.0227L95.3252 72.2286L111.438 68.9209Z"
          fill="#B3B3B3"
        />
        <path
          d="M108.228 74.8793L110.07 75.5279C110.837 75.798 111.558 75.0231 111.236 74.2747L109.823 71.001L108.228 74.8793Z"
          fill="#B3B3B3"
        />
        <path
          d="M107.835 60.1941L107.603 60.0987C106.62 59.6978 105.499 60.1706 105.098 61.1557L102.581 67.3497L110.821 70.7143L111.527 68.9793C112.928 65.5318 111.275 61.5986 107.835 60.1941V60.1941Z"
          fill="#B3B3B3"
        />
        <path
          d="M94.1367 66.2373L104.5 67.0047L103.565 79.6847C103.339 82.7587 99.6068 84.1357 97.4453 81.9429L92.6851 78.0092L94.1367 66.2373Z"
          fill="#B3B3B3"
        />
        <path
          d="M103.475 74.0127L108.64 76.1219L111.315 69.5405L106.15 67.4312L103.475 74.0127Z"
          fill="#B3B3B3"
        />
        <path
          d="M106.107 58.0573L103.638 57.1207C100.94 56.0971 97.9243 57.4597 96.9032 60.1642L94.0134 67.8171C93.0565 70.3519 94.3308 73.1838 96.8593 74.1434L107.985 78.3632L111.296 69.5925C113.042 64.9707 110.719 59.8062 106.107 58.0573Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M110.783 70.85L109.588 70.3966C109.449 70.3442 109.295 70.3487 109.16 70.4096L108.474 70.7196C108.019 70.9253 108.039 71.5789 108.506 71.7556L110.211 72.4022"
          fill="#1D2445"
        />
        <path
          d="M103.532 70.1885L102.832 72.0433C102.186 73.7533 102.618 75.684 103.929 76.9542C104.436 75.6131 105.931 74.9371 107.269 75.4449L108.859 76.048L107.415 79.7801C106.91 81.0857 105.255 81.4781 104.22 80.538L101.054 77.6651C100.137 76.8334 99.8266 75.5218 100.272 74.3659L102.642 68.2119C103.433 68.5115 103.831 69.3967 103.532 70.1885V70.1885Z"
          fill="#7B7B7B"
        />
        <path
          d="M105.988 78.1224L107.084 78.8558C107.376 79.051 107.773 78.9232 107.897 78.5947L108.447 77.1388L107.296 76.7019C106.679 76.4678 105.989 76.7793 105.756 77.3974C105.656 77.664 105.752 77.9646 105.988 78.1224Z"
          fill="#B3B3B3"
        />
        <path
          d="M90.443 68.0928L96.7693 70.4923C99.3856 71.4844 102.309 70.1633 103.299 67.5416L107.101 57.4718C107.296 56.9561 107.037 56.3799 106.522 56.1847L103.254 54.945C99.4638 53.5071 95.2276 55.4213 93.7935 59.2208L90.443 68.0928Z"
          fill="#7B7B7B"
        />
        <path
          d="M110.323 66.1994L110.571 66.2938C112.419 66.9948 114.484 66.0616 115.183 64.2088L115.942 62.1998C116.281 61.3026 115.83 60.3 114.935 59.9605L112.465 59.0234L104.285 55.9209L102.901 68.0612L105.134 63.6806C105.495 62.9736 106.34 62.6676 107.068 62.9796C107.374 63.1104 107.627 63.3396 107.789 63.6302L108.202 64.3681C108.672 65.2094 109.423 65.8579 110.323 66.1994V66.1994Z"
          fill="#7B7B7B"
        />
        <path
          d="M99.9105 70.4217L101.409 71.1346L102.755 67.9218C103.191 66.8833 102.734 65.6856 101.719 65.2028L101.61 65.1508C100.567 64.6546 99.3461 65.3336 99.2141 66.4834L98.959 68.7027C98.8763 69.4211 99.2589 70.1121 99.9105 70.4217V70.4217Z"
          fill="#B3B3B3"
        />
        <path
          d="M128.838 128.681L146.512 102.766L131.448 99.3398L113.774 125.255L128.838 128.681Z"
          fill="#187A90"
        />
        <path
          d="M134.356 111.628C134.356 111.632 134.355 111.637 134.358 111.64C134.512 112.202 135.096 112.627 135.814 112.617L141.4 112.113C141.588 112.096 141.755 111.984 141.844 111.817L142.165 111.211C142.406 110.754 142.233 110.189 141.778 109.947L141.541 109.821C141.423 109.759 141.288 109.74 141.158 109.769L134.669 111.211C134.467 111.239 134.327 111.426 134.356 111.628V111.628Z"
          fill="#B3B3B3"
        />
        <path
          d="M134.194 113.879C134.194 113.883 134.194 113.888 134.196 113.891C134.35 114.454 134.934 114.878 135.653 114.869L141.238 114.365C141.426 114.348 141.593 114.236 141.682 114.069L142.003 113.463C142.244 113.006 142.071 112.441 141.615 112.199L141.379 112.073C141.262 112.011 141.126 111.992 140.996 112.021L134.507 113.462C134.305 113.49 134.166 113.678 134.194 113.879V113.879Z"
          fill="#B3B3B3"
        />
        <path
          d="M134.458 116.269C134.458 116.273 134.459 116.277 134.461 116.28C134.665 116.789 135.256 117.123 135.926 117.037L141.092 115.97C141.266 115.934 141.411 115.812 141.476 115.646L141.711 115.045C141.888 114.593 141.665 114.083 141.215 113.905L140.981 113.814C140.864 113.768 140.735 113.765 140.617 113.806L134.707 115.846C134.52 115.893 134.409 116.083 134.458 116.269V116.269Z"
          fill="#B3B3B3"
        />
        <path
          d="M134.549 117.981C134.549 117.985 134.549 117.988 134.551 117.991C134.723 118.466 135.258 118.792 135.879 118.735L139.914 118.123C140.076 118.096 140.213 117.988 140.278 117.837L140.514 117.29C140.691 116.879 140.502 116.402 140.092 116.225L139.879 116.132C139.773 116.087 139.654 116.08 139.544 116.114L134.792 117.598C134.619 117.637 134.511 117.809 134.549 117.981V117.981Z"
          fill="#B3B3B3"
        />
        <path
          d="M130.385 123.231C130.254 123.711 129.745 124.065 129.134 124.051L119.906 123.109L118.874 120.224L130.123 122.861C130.296 122.888 130.414 123.051 130.387 123.224C130.387 123.223 130.386 123.229 130.385 123.231V123.231Z"
          fill="#B3B3B3"
        />
        <path
          d="M127.148 124.172C127.106 124.667 126.669 125.108 126.066 125.205L116.821 125.955L115.284 123.305L126.824 123.855C126.998 123.851 127.143 123.989 127.148 124.164V124.172Z"
          fill="#B3B3B3"
        />
        <path
          d="M125.357 125.714C125.295 126.208 124.842 126.631 124.236 126.705L114.969 127.097L113.534 124.39L125.045 125.385C125.219 125.387 125.359 125.531 125.357 125.706C125.357 125.709 125.357 125.712 125.357 125.714V125.714Z"
          fill="#B3B3B3"
        />
        <path
          d="M122.763 127.084C122.701 127.578 122.248 128.001 121.642 128.075L114.513 128.376C113.206 128.432 111.984 127.73 111.371 126.573L110.94 125.76L122.451 126.755C122.626 126.757 122.766 126.901 122.764 127.076C122.764 127.079 122.764 127.082 122.763 127.084V127.084Z"
          fill="#B3B3B3"
        />
        <path
          d="M116.964 125.89L117.652 123.083C118.053 121.45 117.047 119.785 115.418 119.383L112.939 118.773C111.31 118.371 109.649 119.379 109.248 121.012L109.077 121.712C108.392 124.505 110.111 127.352 112.898 128.038L113.273 128.13C114.902 128.532 116.563 127.523 116.964 125.89V125.89Z"
          fill="#B3B3B3"
        />
        <path
          d="M110.79 119.608L118.74 121.567L118.142 121.386C118.085 121.368 118.029 121.346 117.975 121.319C117.414 121.044 117.25 120.586 117.522 120.023L117.457 119.782C117.63 119.43 118.016 119.39 118.394 119.289L120.207 118.815C120.666 118.7 121.088 118.462 121.423 118.127C121.645 117.891 121.751 117.4 121.817 117.128C121.853 116.982 121.859 116.837 121.825 116.694C121.82 116.675 121.814 116.655 121.807 116.638C121.713 116.409 121.451 116.299 121.223 116.393L115.705 118.061L114.334 118.159C113.492 118.215 112.676 118.472 111.953 118.908L110.867 119.567C110.842 119.581 110.817 119.595 110.79 119.608V119.608Z"
          fill="#B3B3B3"
        />
        <path
          d="M114.846 127.433C117.453 128.076 120.046 126.637 120.639 124.221C120.672 124.086 120.698 123.952 120.717 123.818C120.729 123.734 120.829 123.58 120.746 123.568C120.489 123.529 120.53 123.193 120.76 123.2C120.836 123.203 120.758 123.049 120.753 122.974C120.705 122.141 120.411 121.339 119.924 120.652C119.871 120.578 119.848 120.455 119.759 120.433C118.286 120.074 117.961 119.44 118.475 119.269C118.595 119.229 118.255 119.171 118.141 119.112C117.785 118.928 117.401 118.783 116.992 118.682C114.386 118.04 111.792 119.479 111.2 121.895C110.607 124.312 112.24 126.791 114.846 127.433V127.433Z"
          fill="#B3B3B3"
        />
        <path
          d="M109.877 128.925L84.3032 123.922C79.9136 123.195 80.6583 120.623 81.2844 117.276L81.4548 116.364C81.8454 114.276 83.8509 112.901 85.9341 113.292L111.889 118.172L109.877 128.925Z"
          fill="#545454"
        />
        <path
          d="M115.294 217.613L119.019 176.27C119.22 174.043 117.905 171.958 115.812 171.184L105.339 167.312L100.984 188.137C100.689 189.309 100.571 190.455 100.638 191.533L100.968 213.796L115.294 217.613Z"
          fill="#757575"
        />
        <path
          opacity="0.2"
          d="M113.465 151.193L110.106 138.716C109.839 137.725 110.624 136.764 111.646 136.831L116.564 137.155L113.465 151.193Z"
          fill="#1D2445"
        />
      </g>
      <line x1="201.34" y1="-4.37114e-08" x2="201.34" y2="242" stroke="#E3E3E3" strokeWidth="2" />
    </g>
  </SVGIcon>
);

export default OrientationModalGraphicSVG;
