import { fromArrayMaybe } from 'nonempty-list';
import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { Experience } from '../Types';

function getClassNameByAvailability(experience: Experience) {
  return fromArrayMaybe(experience.availabilities)
    .map((availabilities) => availabilities.first)
    .andThen(({ payload }) =>
      when(equals(payload.kind, 'scheduled'), 'bg-[#F4F4F4] bg-scheduled-program-header'),
    )
    .getOrElseValue('bg-[#F4F4F4] bg-on-demand-program-header');
}

export function getExperienceHeaderBackgroundByType(experience: Experience) {
  switch (experience.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
      return getClassNameByAvailability(experience);
    case 'group-coaching':
      return 'bg-[#F4F4F4] bg-group-coaching-program-header';
    case 'coaching':
      return 'bg-[#F4F4F4] bg-leadership-coaching-header';
    case 'program-sequence':
      return 'bg-[#F4F4F4] bg-program-sequence-header';
  }
}
