import { useContext } from 'react';
import { ExperiencePopoverContext } from './Types';

export function useExperiencePopoverContext() {
  const context = useContext(ExperiencePopoverContext);

  if (!context) {
    throw new Error(
      'useExperiencePopoverContext has to be used within the ExperiencePopoverContextProvider.',
    );
  }

  return context;
}
