import { formatISO } from 'date-fns';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import { L, T } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  endOn: Maybe<Date>;
}

const ModuleEndOn: React.FC<Props> = ({ endOn }) => {
  return endOn
    .map((d) => {
      return (
        <div className={style.due} data-test-module-due={formatISO(d, { representation: 'date' })}>
          <T
            kind="Current Module Due on <date/>"
            date={<L localizeable={d} format="long-month-day-year" />}
          />
        </div>
      );
    })
    .getOrElseValue(<></>);
};
export default ModuleEndOn;
