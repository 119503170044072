import { assertNever } from '@kofno/piper';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { warnAndNotify } from '../../../Honeybadger';
import { profileStore } from '../../../ProfileStore';
import { TranslationsContext } from '../../../Translations';
import { InvitationStore } from './InvitationStore';
import InvitationReactions from './InvitationStore/Reactions';
import { SharedInvitationResource } from './SharedInvitationResourceStore/Types';
import { UserState } from './Types';
import SharedOpenEnrollmentView from './View';

interface Props {
  currentUserState: UserState;
  invitationStore: InvitationStore;
  sharedInvitationResource: SharedInvitationResource;
  opaqueUseCaseIdentifier: string;
}

class SharedOpenEnrollmentImplementation extends React.Component<Props> {
  componentDidMount(): void {
    const { currentUserState } = this.props;

    switch (currentUserState.kind) {
      case 'anonymous':
        this.props.invitationStore.requestingEmailAddress({
          emailAddress: '',
          alertMessage: nothing(),
        });
        break;
      case 'ready':
      case 'refreshing':
        profileStore.resource
          .map(({ payload }) => payload.email)
          .cata({
            Just: (email) => this.props.invitationStore.validatingEmailFromIdentifiedUser(email),
            Nothing: () => {
              warnAndNotify('Identified user has no email address', 'MissingEmailAddress', {});
              this.props.invitationStore.requestingEmailAddress({
                emailAddress: '',
                alertMessage: nothing(),
              });
            },
          });
        break;
      default:
        assertNever(currentUserState);
    }
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => (
            <InvitationReactions
              store={this.props.invitationStore}
              sharedInvitationResource={this.props.sharedInvitationResource}
              fireImmediately={true}
              ts={ts}
              opaqueUseCaseIdentifier={this.props.opaqueUseCaseIdentifier}
            />
          )}
        </TranslationsContext.Consumer>
        <SharedOpenEnrollmentView
          currentUserState={this.props.currentUserState}
          sharedInvitationResource={this.props.sharedInvitationResource}
          invitationStore={this.props.invitationStore}
        />
      </>
    );
  }
}

export default observer(SharedOpenEnrollmentImplementation);
