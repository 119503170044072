import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import * as React from 'react';
import { T } from '../../../Translations';
import TopLevelResources from '../TopLevelResources';
import { ResourceResource } from '../Types';
import * as style from '../style.module.css';

interface Props {
  resources: Maybe<NonEmptyList<ResourceResource>>;
}

const ProgramWideResources: React.FC<Props> = ({ resources }) => (
  <>
    {resources
      .map((resources) => (
        <>
          <h2 className={style.header}>
            <T kind="PROGRAM-WIDE RESOURCES" />
          </h2>
          <TopLevelResources resources={resources.toArray()} />
        </>
      ))
      .getOrElseValue(<></>)}
  </>
);

export default ProgramWideResources;
