import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import { ScheduledToast } from '../../ToastsStore/Types';
import EventNotificationsToast from './EventNotificationsToast';
import ReminderNotificationsToast from './ReminderNotificationsToast';

interface Props {
  scheduledToastStores: NonEmptyList<ScheduledToastStore>;
}

const byKind = (kind: ScheduledToast['kind']) => (toast: ScheduledToastStore): boolean =>
  toast.scheduledToast.kind === kind;

const Notifications: React.FC<Props> = ({ scheduledToastStores }) => {
  const eventNotifications = scheduledToastStores.filter(byKind('event-notification'));
  const reminderNotifications = scheduledToastStores.filter(byKind('reminder-notification'));
  return (
    <>
      <EventNotificationsToast eventNotifications={eventNotifications} />
      <ReminderNotificationsToast reminderNotifications={reminderNotifications} />
    </>
  );
};

export default observer(Notifications);
