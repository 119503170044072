import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterKeyPress } from '../../../Accessibility/KeyboardEvents';
import ConversationStore from '../../../ConversationStore';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import ChatIcon from '../../Cutesies/ChatIcon';
import Badge from '../../SegmentPanel/Badge';
import * as style from '../../SegmentPanel/style.module.css';

interface Props {
  tabStore: TabStore;
  conversationStore: ConversationStore;
}

const tabStyle = (tabStore: TabStore) =>
  `${style.tab} ${tabStore.activeTab === 'group chat' ? style.active : ''}`;

const GroupChatTab: React.FC<Props> = ({ tabStore, conversationStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('group chat');
  };

  return (
    <div
      className={tabStyle(tabStore)}
      onClick={handleClick}
      tabIndex={0}
      onKeyPress={handleEnterKeyPress(handleClick)}
    >
      <div className={style.tabIcon}>
        <ChatIcon fill="#000000" />
      </div>
      <div className={style.tabLabel}>
        <T kind="Group Chat" />
      </div>
      <Badge count={conversationStore.messages.length} />
    </div>
  );
};
export default observer(GroupChatTab);
