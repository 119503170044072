import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import React from 'react';
import { TimeCommitment } from '../../../../../SegmentStore/Types';
import { T } from '../../../../../Translations';

interface Props {
  timeCommitment: TimeCommitment;
}

const TimeCommitments: React.FC<Props> = ({ timeCommitment }) => {
  return (
    <div className="mx-16 flex flex-col">
      <div className="text-lg font-semibold uppercase">
        <T kind="Time Commitment" />
      </div>
      <div className="mb-5 mt-4 text-base font-light leading-normal">
        <div>
          {timeCommitment.duration
            .map((duration) => <T kind="{{count}} week" count={duration} />)
            .getOrElse(emptyFragment)}
        </div>

        <div>
          {timeCommitment.hoursPerWeek
            .map((hoursPerWeek) => <T kind="{{count}} hour per week" count={hoursPerWeek} />)
            .getOrElse(emptyFragment)}
        </div>
        <div>
          {timeCommitment.totalHours
            .map((totalHours) => <T kind="{{count}} total hour" count={totalHours} />)
            .getOrElse(emptyFragment)}
        </div>
      </div>
    </div>
  );
};

export default observer(TimeCommitments);
