import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import {
  LearningJourneyOverviewItems,
  ProgramEnrolled,
  ProgramGraduated,
} from '../../../../../Experience/ExperienceBody';
import { getPrimaryCompetencies } from '../../../../../Experience/ExperienceCompetencies';
import { ExperienceResource } from '../../../../../Experience/Types';
import { ProgramCompetencies } from '../../Common';

interface Props {
  experienceResource: ExperienceResource;
}

function LearningJourneyOverview({ experienceResource }: Props) {
  switch (experienceResource.payload.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed': {
      const competencies = getPrimaryCompetencies(experienceResource);

      return (
        <>
          <LearningJourneyOverviewItems experience={experienceResource.payload} />
          <ProgramCompetencies competencies={competencies} />
        </>
      );
    }
    case 'beginnable':
    case 'resumable':
      return <ProgramEnrolled />;
    case 'graduated':
    case 'returnable':
      return <ProgramGraduated />;
    case 'upcoming':
      return emptyFragment();
  }
}

export default observer(LearningJourneyOverview);
