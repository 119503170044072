import { observer } from 'mobx-react';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { Typography, ChevronRightIcon, cn } from '@execonline-inc/execonline-ui';
import { UseCaseExplorePayload } from '../../../../../../DiscoveryStore/Types';
import { T } from '../../../../../../Translations';
import EnrollmentStatus from './EnrollmentStatus';
import RegistrationType from './RegistrationType';

interface Props {
  data: UseCaseExplorePayload;
}

function UseCaseInfo({ data }: Props) {
  return (
    <div className="flex w-full flex-col items-start gap-y-2">
      <Typography
        className={cn(
          'flex w-full items-center justify-between text-wrap text-left tracking-[0.07px]',
          'group-hover:text-teal-600',
        )}
        variant="bodyMedium"
        fontWeight="medium"
      >
        <AlreadyTranslated content={data.name} />
        <ChevronRightIcon className="lg:hidden" size="sm" />
      </Typography>
      <RegistrationType registrationType={data.registrationType} />
      <div className={cn('flex flex-col items-start gap-1 lg:flex-row')}>
        <Typography
          className="tracking-[0.07px] text-gray-500"
          variant="bodyMedium"
          fontWeight="medium"
        >
          <T kind="{{count}} Program" count={data.programsCount} />
        </Typography>
        <EnrollmentStatus status={data.enrollmentStatus} />
      </div>
    </div>
  );
}

export default observer(UseCaseInfo);
