import { observer } from 'mobx-react';
import * as React from 'react';
import HeroSection from './HeroSection';
import ExternalProgramDetailViewImpl from './ExternalProgramDetailViewImpl';
import { ExternalProgramResource } from '../../../../SegmentStore/Types';
import * as style from './style.module.css';
import DetailViewStore from '../DetailViewStore';

interface Props {
  externalProgramResource: ExternalProgramResource;
  detailViewStore: DetailViewStore;
}

const ExperienceDetailView: React.FC<Props> = ({ externalProgramResource }) => {
  return (
    <div className={`${style.wideContent} overflow-hidden`}>
      <div style={{ zIndex: 0 }}>
        <HeroSection externalProgramResource={externalProgramResource} />
        <ExternalProgramDetailViewImpl externalProgramResource={externalProgramResource} />
      </div>
    </div>
  );
};

export default observer(ExperienceDetailView);
