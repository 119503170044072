import { observer } from 'mobx-react';
import * as React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import { AlreadyTranslated, L, T, translation, TranslationsContext } from '../../Translations';
import EventAlertIcon from '../Cutesies/EventAlertIcon';
import * as style from './style.module.css';

interface Props {
  store: ScheduledToastStore;
}

const ReminderToast: React.FC<Props> = ({ store }) => {
  const dismiss = () => {
    store.dismiss();
  };

  const toast = store.scheduledToast;

  switch (store.state.kind) {
    case 'starting':
    case 'active': {
      return (
        <TranslationsContext.Consumer>
          {(ts) => (
            <div className={style.toast} data-test-toast={true}>
              <div className={style.content}>
                <div className={style.icon}>
                  <EventAlertIcon />
                </div>
                <div className={style.details}>
                  <h2 className={style.title}>
                    <AlreadyTranslated content={toast.title} />
                  </h2>
                  <p className={style.description} data-test-toast-description={toast.title.text}>
                    <T
                      kind="{{title}} at <time/>."
                      title={toast.title.text}
                      time={<L localizeable={toast.startAt} format="time-of-day" />}
                    />
                  </p>
                </div>
              </div>
              <div className={style.actions}>
                <button
                  className={style.button}
                  onClick={dismiss}
                  data-test-toast-dismiss={true}
                  aria-label={translation('Dismiss dropdown notification.')(ts)}
                >
                  <T kind="Dismiss" />
                </button>
              </div>
            </div>
          )}
        </TranslationsContext.Consumer>
      );
    }
    case 'inactive':
    case 'dismissed':
      return <span />;
  }
};

export default observer(ReminderToast);
