import { observer } from 'mobx-react';
import { Chip, Typography } from '@execonline-inc/execonline-ui';
import { MultiStepLearningIcon } from '../Icons';
import { T } from '../../../../../../../Translations';

function ProgramSequenceProductBadge() {
  return (
    <Chip className="bg-[#F9F9F9]">
      <Typography className="flex items-center gap-x-2 text-black" variant="caption">
        <MultiStepLearningIcon size="md" />
        <T kind="Multi Step Learning Journey" />
      </Typography>
    </Chip>
  );
}

export default observer(ProgramSequenceProductBadge);
