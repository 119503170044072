import { warnAndNotify } from '@execonline-inc/error-reporting.private';
import { observer } from 'mobx-react';
import * as React from 'react';
import { EventResource } from '../../EventsStore/Types';
import LocalOrExternalConference from './LocalOrExternalConference';

interface Props {
  eventId: number;
  eventResource: EventResource;
}

const Conference: React.FC<Props> = ({ eventId, eventResource }) =>
  eventResource.payload.conferenceRoom
    .map((conferenceRoomResource) => (
      <LocalOrExternalConference
        eventId={eventId}
        eventResource={eventResource}
        conferenceRoomResource={conferenceRoomResource}
      />
    ))
    .getOrElse(() => {
      warnAndNotify('ConferenceRoomRender', 'There is no conference room to render', eventResource);
      return <></>;
    });

export default observer(Conference);
