import * as React from 'react';
import { observer } from 'mobx-react';

import Button from './Button';
import ScheduleSessionStore from './Store';
import FreebusyButton from './FreebusyActiveButton';
import ConfirmationModal from './ConfirmationModal';
import FreebusyStore from '../../../../../FreebusyStore';

interface Props {
  freebusyStore: FreebusyStore;
  scheduleSessionStore: ScheduleSessionStore;
}

const FreebusyDisplayStates: React.FC<Props> = ({ freebusyStore, scheduleSessionStore }) => {
  switch (freebusyStore.state.kind) {
    case 'waiting':
    case 'error':
      return <Button freebusyStore={freebusyStore} />;
    case 'initializing-store':
    case 'submitting':
    case 'loading':
    case 'initializing':
    case 'ready':
      return <FreebusyButton freebusyStore={freebusyStore} />;
    case 'submitted':
      return (
        <>
          <Button freebusyStore={freebusyStore} />
          <ConfirmationModal
            eventDetails={freebusyStore.state.eventDetails}
            scheduleSessionStore={scheduleSessionStore}
          />
        </>
      );
  }
};

export default observer(FreebusyDisplayStates);
