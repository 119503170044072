import * as React from 'react';
import { ProgramResource } from '../../../ProgramChatStore/Types';
import { T } from '../../../Translations';
import LinkifyWithCharEntities from '../../LinkifyWithCharEntities';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}

const PinnedMessage: React.FC<Props> = ({ programResource }) =>
  programResource.payload.programChat.payload.pinnedMessage
    .map((pinnedMessage) => (
      <div className={style.topic}>
        <div className={style.topicText}>
          <span className={style.topicTitle}>
            <T kind="Current topic:" />
          </span>{' '}
          <LinkifyWithCharEntities content={pinnedMessage} />
        </div>
      </div>
    ))
    .getOrElse(() => <></>);

export default PinnedMessage;
