import { observer } from 'mobx-react';
import * as React from 'react';
import EventsStore from '../../EventsStore';
import UpdateEventsState from './UpdateEventsState';
import * as style from './style.module.css';

interface Props {
  eventsStore: EventsStore;
  limit: number;
  viewAllButton: boolean;
}

class UpcomingEvents extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className={style.eventListing} data-test-upcoming-events={true}>
        <UpdateEventsState state={this.props.eventsStore.state} {...this.props} />
      </div>
    );
  }
}

export default observer(UpcomingEvents);
