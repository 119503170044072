import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { MouseEventHandler, useMemo } from 'react';
import { T, TPlainTextKey } from '../Translations';
import ArrowDownIcon from '../components/Cutesies/ArrowDownIcon';
import {
  LoadingMoreAnnouncements,
  OnLoadMore,
  SomeAnnouncementsLoaded,
} from './UseAnnouncementsLoading';
import * as style from './style.module.css';

interface LoadMoreAnnouncementsButtonProps {
  state: SomeAnnouncementsLoaded | LoadingMoreAnnouncements;
  onLoadMore: OnLoadMore;
}

interface ButtonOptions {
  loadMoreAction: MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
  text: TPlainTextKey;
}

function buttonOptions(
  state: LoadMoreAnnouncementsButtonProps['state'],
  onLoadMore: OnLoadMore
): ButtonOptions {
  switch (state.kind) {
    case 'loading-more-announcements':
      return {
        loadMoreAction: noop,
        disabled: true,
        text: 'Loading...',
      };
    case 'some-announcements-loaded':
      return {
        loadMoreAction: () => onLoadMore(state),
        disabled: false,
        text: 'Load Older Announcements',
      };
  }
}

function LoadMoreAnnouncementsButton({ state, onLoadMore }: LoadMoreAnnouncementsButtonProps) {
  const { loadMoreAction, disabled, text } = useMemo(
    () => buttonOptions(state, onLoadMore),
    [state, onLoadMore]
  );

  return (
    <button
      className={style.loadMoreAnnouncementsButton}
      onClick={loadMoreAction}
      disabled={disabled}
    >
      <T kind={text} />
      <div className={style.buttonIcon}>
        <ArrowDownIcon />
      </div>
    </button>
  );
}

export default observer(LoadMoreAnnouncementsButton);
