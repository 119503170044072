import { readVarT } from '@execonline-inc/environment';
import Intercom, { shutdown } from '@intercom/messenger-js-sdk';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { impersonatingUserId } from '../../../Analytics';
import { ContingentResource } from '../../../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { organizationStore } from '../../../Organization/Store';
import { Profile } from '../../../ProfileStore/Types';
import { sessionStore } from '../../../Session/Store';
import { CompanyRepresentation, IntercomInitData } from './Types';
import UserProfileReady from './UserProfileReady';

function IntercomTooling({ currentUserResource }: { currentUserResource: CurrentUserResource }) {
  return (
    <UserProfileReady
      children={(profileResource) => (
        <_IntercomTooling
          currentUserResource={currentUserResource}
          profileResource={profileResource}
        />
      )}
    />
  );
}

interface Props {
  currentUserResource: CurrentUserResource;
  profileResource: ContingentResource<Profile>;
}

function decorateWithImpersonation(name: string): string {
  return sessionStore.impersonatingBy
    .map((impersonator) => `${name} (Impersonated by ${impersonator})`)
    .getOrElseValue(name);
}

function shortName(profile: Profile): string {
  return profile.shortName
    .orElse(() => just(profile.email))
    .map(decorateWithImpersonation)
    .map(intercomString)
    .getOrElseValue('');
}

function companyMappingData(profile: Profile): CompanyRepresentation {
  return organizationStore.resource
    .map<CompanyRepresentation>((r) => ({
      id: fromNumber(r.payload.id),
      name: r.payload.name,
      plan: fromArray(r.payload.plans),
      'Progress Emails Communication Preference': r.payload.progressEmailsCommunicationPreference,
      'Registration Invitation Emails Communication Preference':
        r.payload.registrationInvitationCommunicationPreference,
      'Chat Preference': r.payload.chatPreference,
      'Active Integrations': fromArray(r.payload.activeIntegrations),
      University: r.payload.university,
      created_at: asUnixTimestamp(r.payload.createdAt),
    }))
    .orElse(() => profile.organizationName.map(intercomString))
    .getOrElseValue('');
}

function asUnixTimestamp(date: Date): number {
  return date.getTime() / 1000;
}

function intercomString(value: string): string {
  const MAX_LENGTH = 252; // 255 - 3 for ellipsis
  return value.length > MAX_LENGTH ? `${value.slice(0, MAX_LENGTH)}...` : value;
}

function maybeIntercomString(value: Maybe<string>): string {
  return value.map(intercomString).getOrElseValue('');
}

function fromNumber(value: number): string {
  return intercomString(value.toString());
}

function fromArray(value: string[], join = ','): string {
  return intercomString(value.join(join));
}

function _IntercomTooling({ profileResource, currentUserResource }: Props) {
  const profile = profileResource.payload;
  const user = currentUserResource.payload;

  useEffect(() => {
    readVarT('REACT_APP_INTERCOM_APP_ID')
      .map<IntercomInitData>((appId) => ({
        app_id: appId,
        name: shortName(profile),
        email: intercomString(profile.email),
        user_id: fromNumber(profile.userId),
        created_at: asUnixTimestamp(profile.createdAt),
        'Salesforce Contact ID': maybeIntercomString(user.contactUid),
        'Organization ID': maybeIntercomString(profile.organizationID.map(fromNumber)),
        'Organization Name': maybeIntercomString(profile.organizationName),
        company: companyMappingData(profile),
        'Salesforce Account ID': maybeIntercomString(profile.organizationAccountUID),
        'Salesforce Platform Org ID': maybeIntercomString(profile.organizationPlatformOrgUID),
        'Product License IDs': profile.productLicenseIds.join(','),
        'Product License Type IDs': fromArray(profile.productLicenseTypeIds.map(String)),
        'Admin Privilege IDs': fromArray(user.epPrivilegeIds.map(String)),
        'Impersonator ID': intercomString(impersonatingUserId()),
      }))
      .fork(() => {
        console.warn(
          'Unable to load Intercom: Missing environment variable REACT_APP_INTERCOM_APP_ID',
        );
      }, Intercom);

    return shutdown;
  }, [profile, user]);

  return <></>;
}

export default observer(IntercomTooling);
