import { equals } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { pick } from '@kofno/piper';
import { Maybe, fromNullable, just, nothing } from 'maybeasy';
import * as xoid from 'xoid';
import { Payload } from './Payload';
import { Actions, App, Atom, Config, Message, State } from './Types';

export const toUrl = (url: string): Maybe<URL> => {
  try {
    return just(new URL(url));
  } catch {
    return nothing();
  }
};

export const createIframe = (
  config: Config,
  atom: Atom,
  portalHost: Maybe<URL>,
): HTMLIFrameElement => {
  const iframe = document.createElement('iframe');

  iframe.id = 'message-bus-portal';
  iframe.width = '0';
  iframe.height = '0';
  iframe.tabIndex = -1;
  iframe.setAttribute('aria-hidden', 'true');
  iframe.style.position = 'fixed';

  portalHost.cata({
    Just: (url) => {
      url.searchParams.set('for', config.whoami);
      iframe.src = url.toString();
    },
    Nothing: () => {
      console.error('Invalid URL for message bus portal.');
    },
  });

  iframe.onload = () => {
    fromNullable(iframe.contentWindow).cata({
      Nothing: () => {
        console.warn('Failed to initialize message bus portal.');
      },
      Just: (socket) => {
        atom.focus(pick('socket')).set(just(socket));
      },
    });
  };

  return iframe;
};

export const unauthorizedMessageOrigin = (messageOrigin: string, portalHost: Maybe<URL>): boolean =>
  portalHost
    .map(pick('origin'))
    .andThen(when(equals(messageOrigin)))
    .isNothing();

export const toMessage = (
  sender: string,
  recipients: readonly App[],
  payload: Payload,
): Message => ({
  kind: 'MessageBus.Message',
  sender,
  recipients,
  payload,
});

export const initialState: State = {
  socket: nothing(),
  queuedMessages: [],
};

export const actions =
  (sender: string) =>
  (state: xoid.Atom<State>): Actions => ({
    broadcast: (payload: Payload): void => {
      const message: Message = toMessage(
        sender,
        ['adminstudio', 'enterprise', 'learning'],
        payload,
      );
      state.focus(pick('queuedMessages')).update((messages) => [...messages, message]);
    },
  });
