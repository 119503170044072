import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { field, oneOf, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import {
  AssertionBackend,
  AssertionBackendResource,
  LockedAccess,
  PasswordBackend,
  ThirdPartyBackend,
} from './Types';

export const passwordBackendDecoder: Decoder<PasswordBackend> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('password-backend'))
);

export const thirdPartyBackendDecoder: Decoder<ThirdPartyBackend> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('third-party-backend'))
);

export const lockedAccessDecoder: Decoder<LockedAccess> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('locked-access'))
);

export const assertionBackendDecoder: Decoder<AssertionBackend> = oneOf<AssertionBackend>([
  passwordBackendDecoder.map<AssertionBackend>(identity),
  thirdPartyBackendDecoder.map<AssertionBackend>(identity),
  lockedAccessDecoder.map<AssertionBackend>(identity),
]);

export const assertionBackendResourceDecoder: Decoder<AssertionBackendResource> =
  resourceDecoder(assertionBackendDecoder);
