import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const OverviewTabIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 13">
      <path
        fill="#00000"
        d="M0 1v1.53h13.01V1H0Zm0 3.061v1.53h1.53v-1.53H0Zm3.061 0v1.53h9.949v-1.53H3.061ZM0 7.122v1.53h1.53v-1.53H0Zm3.061 0v1.53h9.949v-1.53H3.061ZM0 10.184v1.53h1.53v-1.53H0Zm3.061 0v1.53h9.949v-1.53H3.061Z"
      />
    </SVGIcon>
  );
};

export default observer(OverviewTabIcon);
