import { Time, toMilliseconds } from '@execonline-inc/time';
import { noop } from '@kofno/piper';
import Decoder from 'jsonous';
import { fromNullable } from 'maybeasy';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import { Error, error } from '../ErrorHandling';

export const loopTask = <T, E>(interval: number, task: Task<E, T>): Task<never, T> => {
  return new Task((_, resolve) => {
    let timeout: number | undefined;
    const loop = () => {
      task.fork(
        () => {
          timeout = window.setTimeout(loop, interval);
        },
        (result) => resolve(result),
      );
    };

    loop();

    return () => {
      clearTimeout(timeout);
    };
  });
};

export const loopTaskWithLimit = <TaskResult, TaskFailure, LoopFailure>(
  interval: number,
  iterationLimit: number,
  rejectWith: LoopFailure,
  task: Task<TaskFailure, TaskResult>,
): Task<LoopFailure, TaskResult> => {
  return new Task((reject, resolve) => {
    let currentIteration = 0;
    let timeout: number | undefined;
    let cancelProvidedTask: () => void | undefined;

    const loop = () => {
      if (currentIteration < iterationLimit) {
        currentIteration++;
        cancelProvidedTask = task.fork(
          () => {
            timeout = window.setTimeout(loop, interval);
          },
          (result) => resolve(result),
        );
      } else {
        reject(rejectWith);
      }
    };

    loop();

    return () => {
      if (cancelProvidedTask) {
        cancelProvidedTask();
      }
      clearTimeout(timeout);
    };
  });
};

export const toPromise = <E, T>(t: Task<E, T>): Promise<T> =>
  new Promise((resolve, reject) => t.fork(reject, resolve));

export interface TimedOut {
  kind: 'timed-out';
}

export const cancelAfter = (howLong: Time): Task<TimedOut, never> => {
  return new Task<TimedOut, never>((reject, _) => {
    const handle = setTimeout(
      () => reject({ kind: 'timed-out' }),
      toMilliseconds(howLong).milliseconds,
    );

    return () => clearTimeout(handle);
  });
};

export const fromNullableT = <Thing>(thing: Thing | null | undefined): Task<Error, Thing> =>
  fromNullable(thing).cata({
    Just: (t) => Task.succeed(t),
    Nothing: () => Task.fail(error('value was null or undefined')),
  });

export const fromResult = <E, T>(result: Result<E, T>): Task<E, T> =>
  new Task((reject, resolve) => {
    result.cata({
      Ok: resolve,
      Err: reject,
    });
    return noop;
  });

export const fromDecoderAny =
  <T>(decoder: Decoder<T>) =>
  (value: unknown): Task<string, T> =>
    new Task((reject, resolve) => {
      decoder.decodeAny(value).cata({ Ok: resolve, Err: reject });
      return noop;
    });
