import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource } from '../../Common/Experience/Types';
import { LearnMoreLeadershipDetails } from '../LearnMoreLeadershipCoaching';
import { LearnMoreGroupCoachingDetails } from '../LearnMoreGroupCoaching';
import { LearningJourneyDetails } from '../LearnMoreLearningJourney';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return experienceResource.payload.productDetails
    .map((productDetails) => {
      switch (productDetails.kind) {
        case 'leadership-coaching':
          return <LearnMoreLeadershipDetails productDetails={productDetails} />;
        case 'group-coaching':
          return <LearnMoreGroupCoachingDetails productDetails={productDetails} />;
        case 'program-sequence':
          return (
            <LearningJourneyDetails
              experienceResource={experienceResource}
              productDetails={productDetails}
            />
          );
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
