import { identity } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import ProfileFormStore from '../../../ProfileFormStore';
import { landingPageDecoder } from '../../../ProfileFormStore/Decoders';
import { LandingPage } from '../../../ProfileFormStore/Types';
import { profileStore } from '../../../ProfileStore';
import { NotTranslated } from '../../../Translations';
import Label from '../../Form/Label';
import DefaultLandingPageSelect from './DefaultLandingPageSelect';
import PasswordReset from './PasswordReset';
import * as style from './style.module.css';

interface Props {
  profileFormStore: ProfileFormStore;
}

export const setDefaultLandingPageValue = (
  fn: (defaultLandingPage: Maybe<LandingPage>) => void
) => (event?: ChangeEvent<HTMLSelectElement>) => {
  if (event) {
    landingPageDecoder.decodeAny(event.currentTarget.value).cata({
      Ok: (landingPage) => fn(just(landingPage)),
      Err: () => fn(nothing()),
    });
  }
};

const defaultLandingPageClass = (store: ProfileFormStore) =>
  disableLandingPageDropdown(store) ? `${style.input} ${style.readOnly}` : style.input;

const disableLandingPageDropdown = (store: ProfileFormStore) =>
  store.defaultLandingPages.length <= 1 ? true : false;

const Account: React.FC<Props> = ({ profileFormStore }) => (
  <div className={style.column} data-test-account={true}>
    <div className={style.labelAndInput}>
      <Label className={style.label} labelText="Email Address" />
      <span className={style.email}>
        <NotTranslated
          text={profileStore.resource.map(({ payload }) => payload.email).getOrElseValue('')}
        />
      </span>
    </div>
    <PasswordReset />
    <div className={style.labelAndInput}>
      <Label
        className={style.label}
        labelText="Default Landing Page"
        htmlFor="defaultLandingPage"
      />
      <div className={defaultLandingPageClass(profileFormStore)}>
        <DefaultLandingPageSelect
          className={style.fakeSelect}
          value={profileFormStore.defaultLandingPage.map<string>(identity).getOrElseValue('')}
          onChange={setDefaultLandingPageValue(profileFormStore.setDefaultLandingPage)}
          name="defaultLandingPage"
          id="defaultLandingPage"
          placeholder={disableLandingPageDropdown(profileFormStore) ? undefined : 'No Preference'}
          disabled={disableLandingPageDropdown(profileFormStore)}
          options={profileFormStore.defaultLandingPages}
          hidden={false}
        />
      </div>
    </div>
  </div>
);

export default observer(Account);
