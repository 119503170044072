import { useConst } from '@execonline-inc/react-hooks.private';
import { noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { ProgramSegment } from '../../ProgramStore/Types';
import SegmentStore from '../../SegmentStore';
import { SegmentResource, UponCompletion } from '../../SegmentStore/Types';
import SegmentReactions from '../SegmentReactions';
import AutoLaunchableModalContent from './AutoLaunchableModalContent';
import { whenPresentationStyleT, whenSegmentT } from './Functions';

interface Props {
  currentSegment: ProgramSegment;
  onClose: (uponCompletion: Maybe<UponCompletion>) => void;
}

function AutoLaunchableModalView({ currentSegment, onClose }: Props) {
  const { id, moduleId, programId } = currentSegment;
  const segmentStore = useConst(() => new SegmentStore('Streamlined'));

  const params = useMemo(
    () => ({
      programId: String(programId),
      moduleId: String(moduleId),
      segmentId: String(id),
    }),
    [programId, moduleId, id],
  );

  const handleClose = () => {
    onClose(segmentStore.uponCompletion);
  };

  const handleAdvanceHook = (nextSegment: Maybe<SegmentResource>) => {
    whenSegmentT(nextSegment).andThen(whenPresentationStyleT).fork(handleClose, noop);
  };

  return (
    <>
      <AutoLaunchableModalContent programId={programId} segmentStore={segmentStore} />
      <SegmentReactions
        params={params}
        store={segmentStore}
        fireImmediately
        advanceHook={handleAdvanceHook}
      />
    </>
  );
}

export default observer(AutoLaunchableModalView);
