import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Alert, AlertStatus, emptyFragment } from '@execonline-inc/execonline-ui';
import { FlashAlert } from '../../../../../../../Notifications/Types';
import AlertMessage from '../../../../../../FlashMessage/AlertMessage';

interface Props {
  notification: Maybe<FlashAlert>;
}

const alertStatus = (alert: FlashAlert): AlertStatus => {
  switch (alert.kind) {
    case 'error-alert':
    case 'server-error-alert':
    case 'payment-loading-error-alert':
      return 'error';
    case 'info-alert':
      return 'info';
    case 'success-alert':
      return 'success';
    case 'warning-alert':
      return 'warning';
  }
};

function ExperienceErrorNotification({ notification }: Props) {
  return notification
    .map((alert) => (
      <Alert className="mb-3 sm:mb-6" status={alertStatus(alert)}>
        <AlertMessage alert={alert} />
      </Alert>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceErrorNotification);
