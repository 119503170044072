import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../../../../Translations';

export interface Waiting {
  kind: 'waiting';
  message: Maybe<TPlainTextKey>;
}

export interface Ready {
  kind: 'ready';
  message: Maybe<TPlainTextKey>;
}

export type State = Waiting | Ready;

export const waiting = (message: Maybe<TPlainTextKey>): Waiting => ({
  kind: 'waiting',
  message,
});

export const ready = (message: Maybe<TPlainTextKey>): Ready => ({
  kind: 'ready',
  message,
});
