import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { Task } from 'taskarian';
import SubscriptionStore from '.';
import { callApi } from '../../../Appy';
import { LocaleChangeError, changeLocaleT } from '../../../ChangeLocale';
import { currentUserStore } from '../../../CurrentUser/Store';
import { handleError } from '../../../ErrorActionableReaction';
import { findLinkByT } from '../../../Links';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { Link } from '../../../Resource/Types';
import { rootResourceStore } from '../../../RootResourceStore';
import { subscriptionResourceDecoder } from './Decoders';
import { LoadingError, State, SubscriptionResource } from './Types';

const getRegisterResource = callApi(subscriptionResourceDecoder, {});

const processTemplateLink =
  (guid: string) =>
  (link: Link): Link => ({
    ...link,
    href: link.href.replace('{guid}', guid),
  });

const handleUnsubscribingError = (store: SubscriptionStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Unsubscribe link is unavailable');
      break;
    default:
      handleError(store, error);
  }
};

const handlePreferredLanguageChangeError = (err: LocaleChangeError) => {
  switch (err.kind) {
    case 'locale-change-skipped':
      break;
    case 'i18next-interaction-error':
    case 'could-not-reload-error':
      warn(err);
      break;
    default:
      assertNever(err);
  }
};

const handleUnsubscribe = (resource: SubscriptionResource): void => {
  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
      break;
    case 'logging-out':
    case 'anonymous':
    case 'errored':
    case 'loading':
    case 'waiting':
      changeLocaleT(resource.payload.preferredLanguage.getOrElseValue('en')).fork(
        handlePreferredLanguageChangeError,
        noop,
      );
      break;
    default:
      assertNever(currentUserStore.state);
  }
};

interface Props extends RCProps<SubscriptionStore> {}

class SubscriptionReactions extends ReactionComponent<SubscriptionStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        store.unsubscribing(store.guid);
        break;
      case 'unsubscribing':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkByT({ rel: 'unsubscribe-invitation-template' }))
          .map(processTemplateLink(state.guid))
          .andThen(getRegisterResource)
          .fork(handleUnsubscribingError(store), store.unsubscribed);
        break;
      case 'unsubscribed':
        handleUnsubscribe(state.resource);
        break;
      case 'error':
        warn(state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default SubscriptionReactions;
