import { T } from '../../../Translations';
import EventPlaceholderIcon from '../../Cutesies/EventPlaceholderIcon';
import style from './styles.module.css';

const UpcomingSessionPlaceholder = () => {
  return (
    <div className={style.blockWrapper}>
      <p className={style.programSecondaryTitle}>
        <T kind="Upcoming session" />
      </p>
      <div className={style.sessionBlock}>
        <div className={style.upcomingSessionPlaceholder}>
          <EventPlaceholderIcon />
        </div>
        <div className={style.eventContainer}>
          <p className={style.coachTitle}>
            <T kind="No further group coaching sessions scheduled" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default UpcomingSessionPlaceholder;
