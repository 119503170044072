import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../../../ProgramStore/Types';
import OnDemandCohortPosition from './OnDemandCohortPosition';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
}

const OnDemandCurrentCohortModule: React.FC<Props> = ({ programModule, programDetail }) => {
  return programModule.expectedModule
    .andThen((expected) => (expected ? just(expected) : nothing<boolean>()))
    .andThen(() => programModule.expectedPercentComplete)
    .map(() => <OnDemandCohortPosition programDetail={programDetail} />)
    .getOrElse(() => <></>);
};

export default observer(OnDemandCurrentCohortModule);
