import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { AnyAdvancer } from '../../../../../SegmentStore/Types';
import AdvanceButton from '../AdvanceButton';

interface Props extends ButtonProps {
  advancer: AnyAdvancer;
}

function TransitionButton({ advancer, ...others }: Props) {
  switch (advancer.kind) {
    case 'advancer':
    case 'complete-and-advancer':
    case 'disabled-advancer':
    case 'submit-and-advancer':
      return <AdvanceButton {...others} disabled />;
    case 'no-advancer':
      return emptyFragment();
  }
}

export default observer(TransitionButton);
