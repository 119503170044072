import * as React from 'react';
import { Document } from '../../../../TeamResourceStore/Types';
import { L, T } from '../../../../Translations';
import * as style from '../../style.module.css';

interface Props {
  resource: Document;
}

const DocumentAddedOn: React.FC<Props> = ({ resource }) => (
  <T
    kind="<fragment>added a document on</fragment> <date/>"
    fragment={(content) => <span className={style.fileDescription}>{content}</span>}
    date={
      <span className={style.fileTimestamp}>
        <L localizeable={resource.createdAt} format="short-month-day-year" />
      </span>
    }
  />
);

export default DocumentAddedOn;
