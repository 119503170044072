import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import { CountryOption } from '../../../../CountryOptionsStore/Types';
import ProfileFormStore from '../../../../ProfileFormStore';
import Label from '../../../Form/Label';
import Select from '../../../Form/Select';
import { convertArrayToOptions } from '../SelectOptionHelpers';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
  countries: NonEmptyList<CountryOption>;
}

const CountrySelector: React.FC<Props> = ({ profileStore, countries }) => (
  <>
    <Label className={style.label} labelText="Country" htmlFor="country" />
    <div className={style.input}>
      <Select
        className={style.fakeSelect}
        value={profileStore.country.map((c) => c.id).getOrElseValue('')}
        onChange={profileStore.selectingCountry(
          profileStore.profileFormResource,
          profileStore.avatarLink,
          countries
        )}
        name="country"
        id="country"
        options={convertArrayToOptions(countries.toArray())}
      />
    </div>
  </>
);

export default observer(CountrySelector);
