import { HTMLAttributes } from 'react';

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'button';

export type TypographyFontWeight =
  | 'thin'
  | 'extralight'
  | 'light'
  | 'normal'
  | 'medium'
  | 'semibold'
  | 'bold'
  | 'extrabold'
  | 'black';

export type TypographyColor = 'primary' | 'secondary' | 'white' | 'black' | 'dark' | 'inherit';

export const variantMapping: Record<TypographyVariant, keyof JSX.IntrinsicElements> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  caption: 'span',
  button: 'span',
};

export const typographyVariantClassNameMapping: Record<TypographyVariant, string> = {
  h1: 'text-font-size-5xl',
  h2: 'text-font-size-4xl',
  h3: 'text-font-size-3xl',
  h4: 'text-font-size-2xl',
  h5: 'text-font-size-xl',
  h6: 'text-font-size-lg',
  subtitle1: 'text-font-size-md',
  subtitle2: 'text-font-size-sm',
  body1: 'text-font-size-md leading-5',
  body2: 'text-font-size-sm',
  caption: 'text-font-size-xs leading-4',
  button: 'text-font-size-sm font-bold',
};

export const typographyFontWeightClassNameMapping: Record<TypographyFontWeight, string> = {
  thin: 'font-thin',
  extralight: 'font-extralight',
  light: 'font-light',
  normal: 'font-normal',
  medium: 'font-medium',
  semibold: 'font-semibold',
  bold: 'font-bold',
  extrabold: 'font-extrabold',
  black: 'font-black',
};

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  color?: TypographyColor;
  fontWeight?: TypographyFontWeight;
  variant?: TypographyVariant;
  component?: React.ElementType;
}
