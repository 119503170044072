import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { Link } from '../../../../Resource/Types';
import { SurveyCompletionTrackingResource, TopicViewStatus } from '../../../ReqHlsVideo/Types';
import {
  State,
  neverShowSurvey,
  showingSurvey,
  surveyClosedAndComplete,
  surveyClosedAndDismiss,
  surveyDismissed,
  surveyUndoDismiss,
  waiting,
} from './Types';

class SurveyModalStore {
  @observable
  state: State;

  constructor(surveyTrackingResource: SurveyCompletionTrackingResource) {
    switch (surveyTrackingResource.payload.launchPosition) {
      case 'launch-at-end':
      case 'launch-at-start':
        this.state = waiting(surveyTrackingResource);
        break;
      case 'pause-launch':
        this.state = surveyDismissed(surveyTrackingResource);
        break;
      case 'never-launch':
        this.state = neverShowSurvey();
        break;
    }
  }

  @action
  waiting = (surveyTrackingResource: SurveyCompletionTrackingResource) => {
    switch (this.state.kind) {
      case 'survey-undo-dismiss':
        this.state = waiting(surveyTrackingResource);
        break;
      case 'waiting':
      case 'never-show-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'showing-survey':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  open = (topicViewStatus: Maybe<TopicViewStatus>) => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = showingSurvey(this.state.surveyTrackingResource, topicViewStatus);
        break;
      case 'never-show-survey':
      case 'showing-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  closeAndComplete = () => {
    switch (this.state.kind) {
      case 'showing-survey':
        this.state = surveyClosedAndComplete(this.state.surveyTrackingResource);
        break;
      case 'waiting':
      case 'never-show-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  closeAndDismiss = () => {
    switch (this.state.kind) {
      case 'showing-survey':
        this.state = surveyClosedAndDismiss(this.state.surveyTrackingResource);
        break;
      case 'waiting':
      case 'never-show-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  exit = () => {
    switch (this.state.kind) {
      case 'survey-closed-and-complete':
        this.state = neverShowSurvey();
        break;
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
      case 'showing-survey':
      case 'waiting':
      case 'never-show-survey':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  surveyDismissed = (surveyTrackingResource: SurveyCompletionTrackingResource) => {
    switch (this.state.kind) {
      case 'survey-closed-and-dismissed':
        this.state = surveyDismissed(surveyTrackingResource);
        break;
      case 'survey-closed-and-complete':
      case 'survey-undo-dismiss':
      case 'showing-survey':
      case 'waiting':
      case 'never-show-survey':
      case 'survey-dismissed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  reopenSurvey = () => {
    switch (this.state.kind) {
      case 'survey-dismissed':
      case 'waiting':
        this.state = surveyUndoDismiss(this.state.surveyTrackingResource);
        break;
      case 'never-show-survey':
      case 'showing-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get links(): ReadonlyArray<Link> {
    switch (this.state.kind) {
      case 'waiting':
      case 'showing-survey':
      case 'survey-closed-and-complete':
      case 'survey-closed-and-dismissed':
      case 'survey-undo-dismiss':
      case 'survey-dismissed':
        return this.state.surveyTrackingResource.links;
      case 'never-show-survey':
      default:
        return [];
    }
  }
}

export default SurveyModalStore;
