import { FetchError } from '@execonline-inc/fetch.private';
import { log, warn } from '@execonline-inc/logging';
import { useAbortable } from '@execonline-inc/react-hooks.private';
import { putQueryParam, toUrl } from '@execonline-inc/url';
import { assertNever } from '@kofno/piper';
import { Maybe, just } from 'maybeasy';
import { findLinkBy, useFetchCore } from '../Fetch';
import { Link } from '../Resource/Types';
import { announcementsResourceDecoder } from './Decoders';
import { AnnouncementResource, AnnouncementsResource } from './Decoders/Types';

export interface InitialLoad {
  kind: 'initial-load';
  perPage: number;
  links: readonly Link[];
  link: Link;
}

export interface AllAnnouncementsLoaded {
  kind: 'all-announcements-loaded';
  announcements: readonly AnnouncementResource[];
}

export interface InitialLoadFailed {
  kind: 'initial-load-failed';
  message: string;
}

export interface LoadingMoreAnnouncements {
  kind: 'loading-more-announcements';
  announcements: readonly AnnouncementResource[];
  next: Link;
}

export interface SomeAnnouncementsLoaded {
  kind: 'some-announcements-loaded';
  announcements: readonly AnnouncementResource[];
  next: Link;
}

export interface LoadingMoreFailed {
  kind: 'loading-more-failed';
  announcements: readonly AnnouncementResource[];
  next: Link;
  message: string;
}

export type AnnouncementsState =
  | InitialLoad
  | AllAnnouncementsLoaded
  | InitialLoadFailed
  | LoadingMoreAnnouncements
  | SomeAnnouncementsLoaded
  | LoadingMoreFailed;

const loadingComplete = (
  announcements: AllAnnouncementsLoaded['announcements'],
): AllAnnouncementsLoaded => ({
  kind: 'all-announcements-loaded',
  announcements,
});

const partialLoadComplete = ({
  announcements,
  next,
}: Omit<SomeAnnouncementsLoaded, 'kind'>): SomeAnnouncementsLoaded => ({
  kind: 'some-announcements-loaded',
  announcements,
  next,
});

const initialLoad =
  ({ perPage, links }: Omit<InitialLoad, 'kind' | 'link'>) =>
  (link: Link): AnnouncementsState => ({
    kind: 'initial-load',
    perPage,
    links,
    link,
  });

function paginateHref(perPage: number): (link: Link) => Maybe<Link>;
function paginateHref(perPage: number, link: Link): Maybe<Link>;
function paginateHref(perPage: number, link?: Link) {
  const doit = (link: Link) =>
    toUrl(link.href)
      .map(putQueryParam('page', '1'))
      .map(putQueryParam('per_page', perPage.toString()))
      .map((url) => url.toString())
      .map((href) => ({
        ...link,
        href,
      }));

  return typeof link === 'undefined' ? doit : doit(link);
}

function requestErrorMessage(error: FetchError): string {
  switch (error.kind) {
    case 'http-error':
      return error.response.response.statusText;
    case 'decoder-error':
      warn('Decoder error', error.error, error.response);
      return 'Decoder error';
    case 'network-error':
      return 'Network error';
  }
}

const loadError = (message: string): InitialLoadFailed => ({
  kind: 'initial-load-failed',
  message,
});

const partialLoadError =
  ({ next, announcements }: LoadingMoreAnnouncements) =>
  (message: string): LoadingMoreFailed => ({
    kind: 'loading-more-failed',
    next,
    announcements,
    message,
  });

const loadSuccess =
  (previous: readonly AnnouncementResource[]) =>
  ({ payload, links }: AnnouncementsResource): AllAnnouncementsLoaded | SomeAnnouncementsLoaded => {
    const announcements = payload.map((as) => as.toArray()).map((as) => [...previous, ...as]);
    return just({})
      .assign('announcements', announcements)
      .assign('next', findLinkBy({ rel: 'next', method: 'get' }, links))
      .map<AllAnnouncementsLoaded | SomeAnnouncementsLoaded>(partialLoadComplete)
      .getOrElse(() => loadingComplete(announcements.getOrElseValue([])));
  };

export type OnLoadMore = (state: SomeAnnouncementsLoaded) => void;

const initialAnnouncementsState = (
  options: Omit<InitialLoad, 'kind' | 'link'>,
): AnnouncementsState =>
  findLinkBy({ rel: 'announcements', method: 'get' }, options.links)
    .andThen(paginateHref(5))
    .map(initialLoad(options))
    .getOrElse(() => loadError('The link for getting `announcements` was missing'));

export function useAnnouncementsLoading(options: Omit<InitialLoad, 'kind' | 'link'>) {
  const { useAbortableState, useAbortableEffect } = useAbortable();
  const { get } = useFetchCore();

  const [state, setState] = useAbortableState<AnnouncementsState>(() =>
    initialAnnouncementsState(options),
  );

  useAbortableEffect(() => {
    switch (state.kind) {
      case 'initial-load':
        get(state.link.href, announcementsResourceDecoder)
          .map(loadSuccess([]))
          .mapError(requestErrorMessage)
          .elseDo(warn)
          .mapError(loadError)
          .fork(setState, setState);
        break;
      case 'loading-more-announcements':
        get(state.next.href, announcementsResourceDecoder)
          .map(loadSuccess(state.announcements))
          .mapError(requestErrorMessage)
          .elseDo(warn)
          .mapError(partialLoadError(state))
          .fork(setState, setState);
        break;
      case 'initial-load-failed':
        warn('Initial announcements load failed:', state);
        break;
      case 'loading-more-failed':
        warn('Loading more announcements failed:', state);
        break;
      case 'all-announcements-loaded':
        log('All of the announcements have been loaded');
        break;
      case 'some-announcements-loaded':
        log('Another batch of announcements have been loaded');
        break;
      default:
        assertNever(state);
    }
  }, [state]);

  const loadMoreAnnouncements = ({ announcements, next }: SomeAnnouncementsLoaded): void => {
    setState({
      kind: 'loading-more-announcements',
      announcements,
      next,
    });
  };

  return { state, loadMoreAnnouncements };
}
