import { ModalBody, ModalFooter, ModalHeader } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ContentStates from '../../../../../../components/ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/ModalSegment/ContentStates';
import SubmitAndAdvanceButton from '../../../../../../components/Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import PreviousAdvanceButton from '../../../../../../components/Segment/SegmentNavigation/PreviousAdvanceButton';
import { findLinkBy } from '../../../../../../Links';
import { ProgramModuleProgress } from '../../../../../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../../../../../ProgramModuleProgress/Context';
import SegmentStore from '../../../../../../SegmentStore';
import { T } from '../../../../../../Translations';
import UpdateFocusModalHeader from './UpdateFocusModalHeader';

interface Props {
  programTitle: string;
  segmentStore: SegmentStore;
}

function UpdateFocusModalContent({ segmentStore, programTitle }: Props) {
  return (
    <ProgramModuleProgressProvider store={segmentStore}>
      <ModalHeader className="flex-col pb-0 sm:pb-0 sm:pt-10">
        <UpdateFocusModalHeader>{programTitle}</UpdateFocusModalHeader>
      </ModalHeader>
      <ModalBody className="min-h-115">
        <ProgramModuleProgress />
        <ContentStates store={segmentStore} />
      </ModalBody>
      <ModalFooter className="flex-row justify-between sm:pb-9">
        <PreviousAdvanceButton data-testid="previous-segment-button" store={segmentStore}>
          <T kind="Previous" />
        </PreviousAdvanceButton>
        <SubmitAndAdvanceButton
          data-testid="next-segment-button"
          className="ml-auto"
          store={segmentStore}
        >
          {findLinkBy({ rel: 'next' }, segmentStore.links)
            .map(() => <T kind="Next" />)
            .getOrElse(() => (
              <T kind="Finish" />
            ))}
        </SubmitAndAdvanceButton>
      </ModalFooter>
    </ProgramModuleProgressProvider>
  );
}

export default observer(UpdateFocusModalContent);
