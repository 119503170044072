import { always } from '@kofno/piper';
import anime from 'animejs';
import { fromNullable, just, Maybe } from 'maybeasy';
import LectureSlideStore from '../../../../../LectureSlideStore';
import { VisibleSlide } from '../../../../../LectureStore/Types';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import { Resource } from '../../../../../Resource/Types';

interface Props extends RCProps<LectureSlideStore> {
  elementToScroll: HTMLDivElement | null;
  slideWidth: number;
}

const animateScroll = (el: HTMLDivElement) => (dist: number): void => {
  anime({ targets: el, scrollLeft: dist, duration: 300, easing: 'easeOutQuad' }).play();
};

const scroll = (slide: Maybe<Resource<VisibleSlide>>, slideWidth: number) => (
  el: HTMLDivElement
): void => {
  just({})
    .assign('slideWidth', just(slideWidth))
    .assign('offsetWidth', just(el.offsetWidth))
    .assign('centerAdj', (x) => just(centerAdjustment(x)))
    .assign('position', just(slide.map((s) => s.payload.visiblePosition).getOrElseValue(0)))
    .map(distance)
    .map(animateScroll(el));
};

const centerAdjustment = ({
  offsetWidth,
  slideWidth,
}: {
  offsetWidth: number;
  slideWidth: number;
}): number => offsetWidth / 2 + slideWidth / 2;

const distance = ({
  slideWidth,
  centerAdj,
  position,
}: {
  slideWidth: number;
  centerAdj: number;
  position: number;
}): number => slideWidth * position - centerAdj;

class VideoPositionReactions extends ReactionComponent<
  LectureSlideStore,
  Maybe<Resource<VisibleSlide>>,
  Props
> {
  tester = () => this.props.store.current;

  effect = (slide: Maybe<Resource<VisibleSlide>>) => {
    fromNullable(this.props.elementToScroll).map(scroll(slide, this.props.slideWidth));
  };

  comparer = (
    prev: Maybe<Resource<VisibleSlide>>,
    current: Maybe<Resource<VisibleSlide>>
  ): boolean =>
    prev
      .map((s) => s.payload.visiblePosition)
      .cata({
        Nothing: () => current.cata({ Nothing: always(true), Just: always(false) }),
        Just: (pos) => current.map((s) => s.payload.visiblePosition === pos).getOrElseValue(false),
      });
}

export default VideoPositionReactions;
