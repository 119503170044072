import { observer } from 'mobx-react';
import { UserMessageIcon } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import ExperienceProductLabel from '../ExperienceProductLabel';

function LeadershipCoachingCompetencies() {
  return (
    <ExperienceProductLabel>
      <UserMessageIcon className="text-[#3E5B67]" size="md" />
      <T kind="Leadership Coaching" />
    </ExperienceProductLabel>
  );
}

export default observer(LeadershipCoachingCompetencies);
