import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';
import PresenceBinding from './PresenceBinding';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
}

const PresenceHandler: React.SFC<Props> = ({ channelAvailabilityStore }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <PresenceBinding channel={channel} />;
    }
    case 'not-available': {
      return <span />;
    }
  }
};

export default observer(PresenceHandler);
