import * as React from 'react';
import { Link } from 'react-router-dom';
import { T } from '../../../../Translations';
import ArrowLeftIcon from '../../../Cutesies/ArrowLeftIcon';
import * as style from '../../style.module.css';

interface Props {
  programId: number;
}

const ExitButton: React.FC<Props> = ({ programId }) => {
  return (
    <Link
      className={style.exitButtonLight}
      to={`/programs/${programId.toString()}`}
      data-test-exit-button
    >
      <div className={style.buttonIcon}>
        <ArrowLeftIcon />
      </div>
      <span className={style.desktopOnly}>
        <T kind="Back to Overview" />
      </span>
      <span className={style.mobileOnly}>
        <T kind="Experience" />
      </span>
    </Link>
  );
};
export default ExitButton;
