import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const EventAlertIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 26 27">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        d="M4.696 0v1.174H0V27h25.826V1.174H21.13V0h-2.347v1.174H7.043V0H4.696ZM2.348 3.522h2.348v1.174h2.347V3.522h11.74v1.174h2.347V3.522h2.348V5.87H2.348V3.522Zm0 4.695h21.13v16.435H2.348V8.217Zm9.391 2.348v7.044h2.348v-7.044h-2.348Zm0 9.392v2.347h2.348v-2.347h-2.348Z"
      />
    </SVGIcon>
  );
};

export default observer(EventAlertIcon);
