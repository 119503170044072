import { always } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { findLink } from '../../LinkyLinky';
import { Link, Rel } from '../../Resource/Types';
import { TPlainTextKey } from '../../Translations';

export interface MenuItemContent {
  content: TPlainTextKey;
  to: string;
  rel: Rel;
  exact: boolean;
}

const toMenuItem =
  (content: MenuItemContent) =>
  (links: ReadonlyArray<Link>): Maybe<MenuItemContent> =>
    just(links).andThen(findLink(content.rel)).map(always(content));

export const toCalendarMenuItem = toMenuItem({
  content: 'Calendar',
  to: '/calendar',
  rel: 'calendar',
  exact: false,
});
