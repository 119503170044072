import { assertNever } from '@kofno/piper';
import Decoder, { field, number, succeed } from 'jsonous';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../../../../Appy';
import { programDashboardUrl } from '../../../../../ClientRoutes';
import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import { handleError } from '../../../../../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../../../../../LinkyLinky';
import { programsStore } from '../../../../../ProgramsStore';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import { Link, Rel } from '../../../../../Resource/Types';
import EnrollmentStore, { EnrollmentState } from './EnrollmentStore';

interface Props extends RCProps<EnrollmentStore> {
  currentUserResource: CurrentUserResource;
}
interface ProgramId {
  id: number;
}
const programIdDecoder: Decoder<ProgramId> = succeed({}).assign('id', field('program_id', number));

const enrollmentEndpoint = callApi(programIdDecoder, {});

const makeRequest = (
  links: ReadonlyArray<Link>,
  nav: Rel,
  store: EnrollmentStore,
  currentUserResource: CurrentUserResource,
) =>
  Task.succeed<EnrollmentError, ReadonlyArray<Link>>(links)
    .andThen(findLinkT(nav))
    .andThen(enrollmentEndpoint)
    .fork(handleRequestError(store), (programId: ProgramId) => {
      programsStore.refreshing(currentUserResource);
      store.redirecting(programDashboardUrl(programId.id));
    });

type EnrollmentError = MissingLinkError | AppyError;

const handleRequestError = (store: EnrollmentStore) => (error: EnrollmentError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error("Announcements aren't available");
      break;
    default:
      handleError(store, error);
  }
};

export class EnrollmentReactions extends ReactionComponent<
  EnrollmentStore,
  EnrollmentState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: EnrollmentState): void => {
    switch (state.kind) {
      case 'enrolling':
        makeRequest(
          this.props.store.links,
          'enroll',
          this.props.store,
          this.props.currentUserResource,
        );
        break;
      case 'redirecting':
      case 'unenrolled':
        break;
      case 'error':
        console.log('Enrollment failed');
        break;
      default:
        assertNever(state);
    }
  };
}
