import { observer } from 'mobx-react';
import { SkeletonCarouselExperiences } from '../TileViewCarouselExperiences';
import { SkeletonTileViewExperiences } from '../TileViewExperiences';

function SkeletonProductCollections() {
  return (
    <div className="flex flex-1 flex-col gap-y-8">
      <SkeletonCarouselExperiences />
      <SkeletonTileViewExperiences />
    </div>
  );
}

export default observer(SkeletonProductCollections);
