import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const AnnouncementsIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 18 15">
      <path
        fill={defaultTheme.variables['info-bg-danger']}
        d="m18 0-.824.241-14.725 4.48H0v5.143h2.45l1.267.382-.02.06v.02c-.412 1.358.351 2.868 1.767 3.295.005.002.015-.003.02 0 1.364.414 2.876-.34 3.295-1.768l.02-.06 8.377 2.55.824.242V0Zm-1.286 1.728v11.13L2.752 8.597l-.08-.02H1.286V6.007h1.386l.08-.02 13.962-4.26Zm-11.772 8.9 2.632.783-.02.06v.02c-.221.75-1.02 1.12-1.708.904-.75-.22-1.14-1-.924-1.687v-.02l.02-.06Z"
      />
    </SVGIcon>
  );
};

export default observer(AnnouncementsIcon);
