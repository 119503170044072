import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';

function LeadershipCoachingOverviewDetails() {
  return (
    <div className="flex w-full flex-wrap gap-x-3 gap-y-1 sm:gap-x-6">
      <Typography className="text-gray-900" variant="caption">
        <T kind="Unlimited sessions" />
      </Typography>
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} min sessions" count={30} />
      </Typography>
    </div>
  );
}

export default observer(LeadershipCoachingOverviewDetails);
