import { assertNever } from '@kofno/piper';
import { fromNullable, just } from 'maybeasy';
import { Task } from 'taskarian';
import EditPasswordStore from '.';
import { callApi, postToApi } from '../Appy';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { nav } from '../Navigation';
import { rootResourceStore } from '../RootResourceStore';
import { RootResourceError } from '../RootResourceStore/Types';
import { resetPasswordResourceDecoder } from './Decoders';
import {
  EditPasswordState,
  ResetPasswordResource,
  handleResetPasswordRequestError,
  handleUpdatePasswordError,
  missingTokenError,
  updatePasswordError,
} from './Types';

interface Params {
  token: string;
}

export interface Props extends EAProps<EditPasswordStore> {
  store: EditPasswordStore;
  params: Params;
}

const validateResetPasswordToken = (token: string): Task<RootResourceError, string> =>
  rootResourceStore
    .findLinkT('validate-token')
    .andThen(postToApi({ password_request_origin: 'p3', token }));

const updatePassword = (
  token: string,
  password: string,
  passwordConfirmation: string,
): Task<RootResourceError, ResetPasswordResource> =>
  rootResourceStore.findLinkT('update-password').andThen(
    callApi(resetPasswordResourceDecoder, {
      token,
      password,
      password_confirmation: passwordConfirmation,
    }),
  );

const handleUpdatePasswordResource = (
  store: EditPasswordStore,
  resource: ResetPasswordResource,
  password: string,
  passwordConfirmation: string,
) => {
  switch (resource.payload.kind) {
    case 'success':
      nav('/');
      break;
    case 'failed': {
      const serverError = updatePasswordError(resource.payload.errors);
      store.readyWithServerErrors(serverError, just(password), just(passwordConfirmation));
      break;
    }
    default:
      assertNever(resource.payload);
  }
};

class EditPasswordReactions extends ErrorActionableReaction<
  EditPasswordStore,
  EditPasswordState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: EditPasswordState) => {
    switch (state.kind) {
      case 'loading':
        fromNullable(this.props.params.token)
          .map(validateResetPasswordToken)
          .cata({
            Just: (task) => {
              task.fork(handleResetPasswordRequestError(this.props.store), this.props.store.ready);
            },
            Nothing: () => {
              this.props.store.clientError(missingTokenError());
            },
          });
        break;
      case 'creating':
        fromNullable(this.props.params.token).cata({
          Just: (token) =>
            updatePassword(token, state.password, state.passwordConfirmation).fork(
              handleUpdatePasswordError(this.props.store),
              (resource) =>
                handleUpdatePasswordResource(
                  this.props.store,
                  resource,
                  state.password,
                  state.passwordConfirmation,
                ),
            ),
          Nothing: () => this.props.store.clientError(missingTokenError()),
        });
        break;
      case 'ready':
      case 'ready-with-client-error':
      case 'ready-with-server-error':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default EditPasswordReactions;
