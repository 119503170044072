import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import TeamsStore from '../../TeamsStore';
import PlatformWrapperContext from '../PlatformWrapperContext';
import ToastsList from './ToastsList';

interface Props {
  teamsStore: Maybe<TeamsStore>;
}

class Toasts extends React.Component<Props> {
  render() {
    return (
      <PlatformWrapperContext.Consumer>
        {(ref) =>
          ref
            .map((platformWrapper) => (
              <WithCurrentUser
                children={(currentUserResource) => (
                  <ToastsList
                    teamsStore={this.props.teamsStore}
                    platformWrapper={platformWrapper}
                    currentUserResource={currentUserResource}
                  />
                )}
              />
            ))
            .getOrElse(emptyFragment)
        }
      </PlatformWrapperContext.Consumer>
    );
  }
}

export default observer(Toasts);
