import { ScrollBox, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import {
  SkeletonMobilePromotableExperienceTileView,
  SkeletonPromotableExperienceCarouselTile,
} from '../PromotableExperienceCarouselTile';

function SkeletonPromotableExperienceCarousel() {
  return (
    <>
      <div className="hidden py-10 lg:block">
        <Skeleton className="w-4/12" size="lg" />
        <SkeletonPromotableExperienceCarouselTile />
        <div className="hidden items-center justify-center gap-3 pt-6 lg:flex">
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton key={index} className="size-3" variant="circle" />
          ))}
        </div>
      </div>
      <ScrollBox
        className="-mb-4 flex flex-shrink-0 snap-x snap-proximity gap-x-8 pb-4 lg:hidden"
        orientation="horizontal"
      >
        {Array.from({ length: 4 }, (_, index) => (
          <SkeletonMobilePromotableExperienceTileView key={index} />
        ))}
      </ScrollBox>
    </>
  );
}

export default observer(SkeletonPromotableExperienceCarousel);
