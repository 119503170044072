import { observer } from 'mobx-react';
import * as React from 'react';
import { L, T, TPlainTextKey } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  date: Date;
  title: TPlainTextKey;
}

const CompletedProgramOverviewDate: React.FC<Props> = ({ date, title }) => (
  <dl className={style.dl}>
    <dt>
      <T kind={title} />
    </dt>
    <dd className={style.date}>
      <strong data-test-completed-program-overview-date={title}>
        <L localizeable={date} format="long-month-day-year" />
      </strong>
    </dd>
  </dl>
);

export default observer(CompletedProgramOverviewDate);
