import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { eventBreakoutUrl } from '../../ClientRoutes';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { TeamResource } from '../../TeamsStore/Types';
import { NotTranslated, T } from '../../Translations';
import ConferenceLink from '../EventConference/ConferenceLink';
import PresentParticipants from './PresentParticipants';
import StaffPresenceStore from './StaffPresenceStore';
import StaffRequest from './StaffRequest';
import StaffRequestStore from './StaffRequestStore';
import * as dashboardStyle from './style.module.css';

interface Props {
  teamResource: TeamResource;
  roomId: string;
  eventId: number;
  staffPresenceStore: StaffPresenceStore;
  staffRequestStore: StaffRequestStore;
  conferenceRosterStore: ConferenceRosterStore;
}

const TeamChatCoachTitle: React.FC<Props> = ({
  teamResource,
  roomId,
  eventId,
  staffPresenceStore,
  staffRequestStore,
  conferenceRosterStore,
}) => {
  return (
    <div className={dashboardStyle.roomHeader}>
      <div className={dashboardStyle.roomDetails}>
        <h3>
          <NotTranslated text={teamResource.payload.name} />
        </h3>
        <p>
          <T kind="Room: {{roomId}}" roomId={roomId} />
        </p>
        {teamResource.payload.conferenceRoom
          .andThen(({ payload }) => payload.conferencePin)
          .map((pin) => (
            <p>
              <T kind="Pin: {{pin}}" pin={pin} />
            </p>
          ))
          .getOrElse(emptyFragment)}
      </div>
      <div className={dashboardStyle.roomActions}>
        <StaffRequest
          staffRequestStore={staffRequestStore}
          staffPresenceStore={staffPresenceStore}
        />
        <div className={clsx('flex justify-center')}>
          <PresentParticipants conferenceRosterStore={conferenceRosterStore} />
          <ConferenceLink to={eventBreakoutUrl(eventId, teamResource.payload.id)}>
            <button className={dashboardStyle.joinButton}>
              <T kind="Join" />
            </button>
          </ConferenceLink>
        </div>
      </div>
    </div>
  );
};

export default observer(TeamChatCoachTitle);
