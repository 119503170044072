import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography } from '@execonline-inc/execonline-ui';
import { Resource } from '../../../../../../../../Resource/Types';
import { T } from '../../../../../../../../Translations';
import { GroupCoachingSessionResources } from '../../../../Types';

interface Props {
  sessions: Maybe<Resource<GroupCoachingSessionResources>>;
  durationInMinutes: number;
}

const getSessionCount = (sessions: Maybe<Resource<GroupCoachingSessionResources>>) => {
  return sessions.map((sessions) => sessions.payload.length).getOrElseValue(0);
};

function GroupCoachingSessionDetails({ sessions, durationInMinutes }: Props) {
  return (
    <div className="flex gap-x-6">
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} session" count={getSessionCount(sessions)} />
      </Typography>
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} min sessions" count={durationInMinutes} />
      </Typography>
    </div>
  );
}

export default observer(GroupCoachingSessionDetails);
