import { cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import ReactHtmlParser from 'react-html-parser';
import { HtmlParserNode } from '../../../../../SegmentStore/Types';
import { htmlANodeDecoder, htmlImgNodeDecoder } from './HtmlDecoders';
import HtmlImageView from './HtmlImageView';
import './styles.css';

interface Props {
  className?: string;
  description: AlreadyTranslatedText;
}

function HtmlView({ description, className }: Props) {
  const transform = (node: HtmlParserNode, index: number) => {
    switch (node.name) {
      case 'img':
        return htmlImgNodeDecoder.decodeAny(node).cata({
          Err: emptyFragment,
          Ok: (data) => {
            return <HtmlImageView key={index} src={data.attribs.src} />;
          },
        });
      case 'a':
        return htmlANodeDecoder.decodeAny(node).cata({
          Err: emptyFragment,
          Ok: (data) => (
            <a key={index} className="htmlParserLink" href={data.attribs.href} target="_blank">
              {fromArrayMaybe(node.children)
                .map((children) => children.first.data)
                .getOrElseValue(data.attribs.href)}
            </a>
          ),
        });
    }
  };

  return fromEmpty(description.text)
    .map((text) => (
      <div
        className={cn(
          'htmlParserWrapper',
          'text-font-size-md text-neutral-900 xl:text-font-size-lg',
          className,
        )}
      >
        {ReactHtmlParser(text, { transform })}
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(HtmlView);
