import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../../Person/types';
import { Position } from '../../PositionContext';
import PersonDefault from './PersonDefault';
import PersonNav from './PersonNav';
import PersonParticipant from './PersonParticipant';
import PersonSupPanel from './PersonSupPanel';
import PersonTooltip from './PersonTooltip';

interface Props {
  personStore: PersonStoreContract;
  position: Position;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const PersonRenderer: React.FC<Props> = ({ personStore, position, presenceStore }) => {
  switch (position.kind) {
    case 'nav':
      return <PersonNav store={personStore} position={position} />;
    case 'supPanel':
      return (
        <PersonSupPanel store={personStore} presenceStore={presenceStore} position={position} />
      );
    case 'participant':
      return (
        <PersonParticipant store={personStore} presenceStore={presenceStore} position={position} />
      );
    case 'tooltip':
      return <PersonTooltip store={personStore} />;
    case 'overview':
    case 'dashboard':
    case 'chat':
    case 'footer':
    case 'none':
      return <PersonDefault store={personStore} presenceStore={presenceStore} />;
  }
};

export default observer(PersonRenderer);
