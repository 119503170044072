import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import LearningPartnersStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link, Linkable } from '../Resource/Types';
import { learningPartnersResourceDecoder } from './Decoders';
import { LearningPartnersState } from './Types';

const learningPartnersEndPoint = callApi(learningPartnersResourceDecoder, {});

type LearningPartnersReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<LearningPartnersStore> {
  store: LearningPartnersStore;
  resourceWithLearningPartners: Linkable;
}

type LearningPartnerRequestError = MissingLinkError | AppyError;

const handleLearningPartnerRequestError =
  (store: LearningPartnersStore) => (error: LearningPartnerRequestError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Learning Partners link is unavailable');
        break;
      default:
        handleError(store, error);
    }
  };

class LearningPartnersReactions extends ErrorActionableReaction<
  LearningPartnersStore,
  LearningPartnersState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: LearningPartnersState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        Task.succeed<LearningPartnersReactionError, ReadonlyArray<Link>>(
          this.props.resourceWithLearningPartners.links,
        )
          .andThen(findLinkT('learning-partners'))
          .andThen(learningPartnersEndPoint)
          .fork(handleLearningPartnerRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default LearningPartnersReactions;
