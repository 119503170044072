import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ActiveProgram } from '../../../../../ProgramStore/Types';
import CurrentDangerRemainderMessage from './CurrentDangerRemainderMessage';
import CurrentUnlockedRemainderMessage from './CurrentUnlockedRemainderMessage';
import CurrentWarningRemainderMessage from './CurrentWarningRemainderMessage';
import EndedDangerRemainderMessage from './EndedDangerRemainderMessage';
import EndedUnlockedOrWarningRemainderMessage from './EndedUnlockedOrWarningRemainderMessage';

interface Props {
  activeProgram: ActiveProgram;
}

const ReminderMessage = ({ activeProgram }: Props) => {
  switch (activeProgram.accessKind) {
    case 'current-unlocked':
      return <CurrentUnlockedRemainderMessage />;
    case 'ended-unlocked':
    case 'ended-warning':
      return activeProgram.daysSinceProgramEnd
        .map(() => <EndedUnlockedOrWarningRemainderMessage />)
        .getOrElse(emptyFragment);
    case 'current-warning':
      return <CurrentWarningRemainderMessage />;
    case 'current-danger':
      return <CurrentDangerRemainderMessage />;
    case 'ended-danger':
      return activeProgram.daysSinceProgramEnd
        .map((days) => <EndedDangerRemainderMessage days={days} />)
        .getOrElse(emptyFragment);
  }
};

export default observer(ReminderMessage);
