import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { GlobalPresenceContext } from '../../../GlobalPresence/GlobalPresenceContext';
import { personStoreFromResource } from '../../../People';
import { professorPerson } from '../../../Person/types';
import { ProfessorResource } from '../../../ProfessorsStore/Types';
import Person from '../../../components/Person';
import * as style from '../style.module.css';

interface Props {
  professorResource: ProfessorResource;
}

const ProfessorImpl: React.FC<Props> = ({ professorResource }) => {
  const personStore = personStoreFromResource(professorResource, professorPerson);
  return (
    <div className={style.professor} data-test-professor={true}>
      <GlobalPresenceContext.Consumer>
        {(value) => <Person personStore={personStore} presenceStore={just(value)} />}
      </GlobalPresenceContext.Consumer>
    </div>
  );
};

export default observer(ProfessorImpl);
