import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';

interface Props {
  value: Maybe<string>;
}

function PaymentIntentId({ value }: Props) {
  return value
    .map((id) => (
      <Typography className="text-gray-450" variant="bodyMedium">
        <T kind="Transaction number: {{id}}" id={id} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PaymentIntentId);
