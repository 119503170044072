import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { eventBreakoutUrl } from '../../ClientRoutes';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';

interface Props {
  breakoutStore: BreakoutChannelStore;
}

const BreakoutRedirect: React.FC<Props> = ({ breakoutStore }) => {
  const { eventId, teamId } = breakoutStore.breakout;
  return <Redirect to={eventBreakoutUrl(eventId, teamId)} />;
};

export default observer(BreakoutRedirect);
