import { OfferingType } from '../../Experience/Types';

export const getExperienceClassNameByType = (type: OfferingType) => {
  switch (type) {
    case 'aep':
    case 'epc':
    case 'coaching':
    case 'msuite':
    case 'program-sequence':
      return 'min-h-[20.625rem]';
    case 'group-coaching':
      return 'min-h-[13.625rem]';
  }
};
