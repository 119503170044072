import { just, Maybe, nothing } from 'maybeasy';

export type UserAgent = string;

interface MSIE {
  kind: 'msie';
  versionNumber: number;
}

interface Trident {
  kind: 'trident';
  versionNumber: number;
}

interface Edge {
  kind: 'edge';
  versionNumber: number;
}

interface Safari {
  kind: 'safari';
  versionNumber: number;
}

interface Electron {
  kind: 'electron';
}

interface Other {
  kind: 'other';
}

export type Browser = MSIE | Trident | Edge | Safari | Electron | Other;

export type InternetExplorer = MSIE | Trident;

const msie = (ua: string, msieIndex: number): MSIE => ({
  kind: 'msie',
  versionNumber: parseInt(ua.substring(msieIndex + 5, ua.indexOf('.', msieIndex)), 10),
});

const trident = (ua: string, rvIndex: number): Trident => ({
  kind: 'trident',
  versionNumber: parseInt(ua.substring(rvIndex + 3, ua.indexOf('.', rvIndex)), 10),
});

const edge = (ua: string, edgeIndex: number): Edge => ({
  kind: 'edge',
  versionNumber: parseInt(ua.substring(edgeIndex + 5, ua.indexOf('.', edgeIndex)), 10),
});

const safari = (ua: string, safariIndex: number): Safari => ({
  kind: 'safari',
  versionNumber: parseInt(ua.substring(safariIndex + 5, ua.indexOf('.', safariIndex)), 10),
});

const electron = (): Electron => ({ kind: 'electron' });

const other = (): Other => ({ kind: 'other' });

export const detectBrowser = (ua: UserAgent): Browser => {
  if (ua.indexOf('Electron/') > 0) {
    return electron();
  }

  const msieIndex: number = ua.indexOf('MSIE ');
  if (msieIndex > 0) {
    return msie(ua, msieIndex);
  }

  if (ua.indexOf('Trident/') > 0) {
    return trident(ua, ua.indexOf('rv:'));
  }

  const edgeIndex: number = ua.indexOf('Edge/');
  if (edgeIndex > 0) {
    return edge(ua, edgeIndex);
  }

  const chromeIndex: number = ua.indexOf('Chrome/');
  const safariIndex: number = ua.indexOf('Safari/');

  if (safariIndex > 0 && chromeIndex < 0) {
    return safari(ua, safariIndex);
  }

  return other();
};

const userAgent = (): string => {
  return window.navigator.userAgent;
};

export const whenInternetExplorer = (): Maybe<InternetExplorer> => {
  const browser = detectBrowser(userAgent());
  switch (browser.kind) {
    case 'msie':
    case 'trident':
      return just(browser);
    case 'edge':
    case 'electron':
    case 'other':
    case 'safari':
      return nothing();
  }
};
