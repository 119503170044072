import { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { fromNullable, Maybe } from 'maybeasy';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { StripeContext } from '../StripeContext';

interface Props {
  stripe: Stripe;
  options: Maybe<StripeElementsOptions>;
}

function StripeClient({ children, stripe, options }: PropsWithChildren<Props>) {
  return options
    .map((options) => (
      <Elements stripe={stripe} options={options}>
        <ElementsConsumer>
          {({ elements }) => (
            <StripeContext.Provider value={fromNullable(elements)}>
              {children}
            </StripeContext.Provider>
          )}
        </ElementsConsumer>
      </Elements>
    ))
    .getOrElse(emptyFragment);
}

export default observer(StripeClient);
