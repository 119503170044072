import { Maybe } from 'maybeasy';
import * as React from 'react';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import { AlreadyTranslated } from '../../../Translations';
import UniversityLogoOnDark from '../../Dashboard/UniversityLogoOnDark';
import * as style from '../style.module.css';

interface Props {
  resource: Maybe<ProgramDetailResource>;
}

const TitleSection: React.FC<Props> = ({ resource }) => {
  return (
    <section className={style.section}>
      {resource.map((res) => <UniversityLogoOnDark links={res.links} />).getOrElseValue(<span />)}
      {resource
        .map((res) => (
          <div className={style.program} data-test current-program-title={res.payload.title}>
            <h2 className={style.programTitle}>
              <AlreadyTranslated content={res.payload.title} />
            </h2>
          </div>
        ))
        .getOrElseValue(<div className={style.program} />)}
    </section>
  );
};

export default TitleSection;
