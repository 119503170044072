import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ProgramStore from '../../../ProgramStore';
import Title from '../../../Title';
import Loader from '../../Loader';
import PositionContext from '../../PositionContext';
import ProgramOverview from '../../ProgramOverview';
import UpdateTooling from '../../Tooling/UpdateTooling';
import ProgramContent from '../ProgramContent';
import * as style from '../style.module.css';

interface Props {
  store: ProgramStore;
  currentUserResource: CurrentUserResource;
}

const ProgramDetails: React.FC<Props> = ({ store, currentUserResource }) => {
  // TODO: correct flipped contexts, ProgramOverview component not rendering correct context
  switch (store.state.kind) {
    case 'reloading':
    case 'ready':
      return (
        <>
          <PositionContext.Provider value={{ kind: 'dashboard' }}>
            <Title title={store.state.programDetailResource.payload.title.text} />
            <ProgramOverview
              programDetailResource={store.state.programDetailResource}
              currentSegment={store.currentSegment}
              programStore={store}
            />
          </PositionContext.Provider>
          <PositionContext.Provider value={{ kind: 'overview' }}>
            <ProgramContent programDetail={store.state.programDetailResource.payload} />
          </PositionContext.Provider>
          {store.title
            .map((title) => (
              <UpdateTooling
                title={title.text}
                sfProgramUid={store.sfProgramUid}
                registrationType={store.registrationType}
                contactUID={currentUserResource.payload.contactUid}
              />
            ))
            .getOrElse(() => (
              <></>
            ))}
        </>
      );
    case 'course-not-ready':
    case 'registration-cancelled':
      return <Redirect to={dashboardUrl} />;
    case 'waiting':
    case 'loading':
    case 'cancelling-registration':
    case 'error':
      return (
        <div className={style.contentLoader}>
          <Loader />
        </div>
      );
  }
};

export default observer(ProgramDetails);
