import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { AppyError } from '../../../../Appy';
import { MissingLinkError } from '../../../../LinkyLinky';
import { AssertionBackendResource } from '../../../../Login/Types';
import { Resource } from '../../../../Resource/Types';
import { SSO } from '../../../../SingleSignOn';
import { SupportedLanguageCode } from '../../../../SupportedLanguages/Types';
import { TPlainTextKey } from '../../../../Translations';
import { UseCaseErrorMessage } from '../../../ErrorMessage/Types';
import { CommerceType, LoginMessageType } from '../../Common/Experience/Types';
import { OrganizationResource } from '../../Types';

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (opaqueUseCaseIdentifier: string): Loading => ({
  kind: 'loading',
  opaqueUseCaseIdentifier,
});

export const ready = (
  opaqueUseCaseIdentifier: string,
  resource: SharedInvitationResource,
): Ready => ({
  kind: 'ready',
  opaqueUseCaseIdentifier,
  resource,
});

export const sharedInvitationLinkInvalid = (
  message: UseCaseErrorMessage,
): SharedInvitationLinkInvalid => ({
  kind: 'shared-invitation-link-invalid',
  message,
});

export const error = (message: TPlainTextKey): Error => ({ kind: 'error', message });

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  opaqueUseCaseIdentifier: string;
}

export interface Ready {
  kind: 'ready';
  opaqueUseCaseIdentifier: string;
  resource: SharedInvitationResource;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

interface SharedInvitationLinkInvalid {
  kind: 'shared-invitation-link-invalid';
  message: UseCaseErrorMessage;
}

export type State = Waiting | Loading | Ready | Error | SharedInvitationLinkInvalid;

export type LoadingError = MissingLinkError | AppyError;

interface CommonInvitation {
  name: Maybe<AlreadyTranslatedText>;
  description: Maybe<AlreadyTranslatedText>;
  loginMessage: Maybe<AlreadyTranslatedText>;
  organization: OrganizationResource;
  assertionBackend: AssertionBackendResource;
  locale: SupportedLanguageCode;
  commerceType: CommerceType;
  loginMessageType: LoginMessageType;
}

export interface PreviewOnly extends CommonInvitation {
  kind: 'preview-only';
}

export type PreviewOnlyInvitationResource = Resource<PreviewOnly>;

export interface UseCase extends CommonInvitation {
  kind: 'use-case';
  uuid: string;
}

export type UseCaseResource = Resource<UseCase>;

export interface UseCasesProgramFamily extends CommonInvitation {
  kind: 'use-cases-program-family';
  id: number;
  useCaseUuid: string;
  programFamilyId: number;
}

export type UseCasesProgramFamilyResource = Resource<UseCasesProgramFamily>;

export type SharedInvitationResource =
  | UseCaseResource
  | UseCasesProgramFamilyResource
  | PreviewOnlyInvitationResource;

export interface SharedInvitationError {
  error: AlreadyTranslatedText;
}

export type SharedInvitationErrorResource = Resource<SharedInvitationError>;

export interface InstantSsoReady {
  opaqueUseCaseIdentifier: string;
  urls: SSO.URLs;
  username: Maybe<string>;
}
