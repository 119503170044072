import { fromEmpty } from 'maybeasy';
import { CompetencyResource, ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { FilterOption } from '../CommonFilter/Types';

export type CompetencyFilterOptions = SomeCompetencies | NoCompetencies;

export type SomeCompetencies = FilterOption & {
  kind: 'some-competencies';
};

export type NoCompetencies = FilterOption & {
  kind: 'no-competencies';
  value: 'None';
  label: 'None';
};

const noCompetencies = (): CompetencyFilterOptions => ({
  kind: 'no-competencies',
  label: 'None',
  value: 'None',
});

const someCompetencies = (resource: CompetencyResource): CompetencyFilterOptions => ({
  kind: 'some-competencies',
  label: resource.payload.name.text,
  value: resource.payload.name.text,
});

const competencyFilterObject = (resource: CompetencyResource): CompetencyFilterOptions =>
  fromEmpty(resource.payload.name.text)
    .map(() => someCompetencies(resource))
    .getOrElse(noCompetencies);

const getCompetencyOptions = (options: ExperienceFiltersResource): CompetencyFilterOptions[] =>
  options.payload.competencies.map(competencyFilterObject);

export const allCompetencyOptions = (options: ExperienceFiltersResource) => [
  ...getCompetencyOptions(options),
];
