import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../../../../../Translations';
import GoBackButton from '../ExperienceActions/GoBackButton';

interface Props {
  searchValue: Maybe<string>;
}

function NoExperiences({ searchValue }: Props) {
  return (
    <div className="mt-8 flex flex-col items-center gap-y-2" data-testid="no-experiences-found">
      <Typography variant="subtitleMedium" as="p">
        <T kind="No matching experiences found" />
      </Typography>
      {searchValue.map(() => <GoBackButton />).getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(NoExperiences);
