import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachProfileResource } from '../../../../../EmbeddedFormFieldAsset/Types';
import ModalStore from '../../../../../Modal/Store';
import CoachModal from './CoachModal';

interface Props {
  modalStore: ModalStore;
  coachProfile: CoachProfileResource;
}

const CoachViewProfileModal: React.FC<Props> = ({ modalStore, coachProfile }) => {
  switch (modalStore.state.kind) {
    case 'open':
      return <CoachModal coachProfile={coachProfile} modalStore={modalStore} />;
    case 'closed':
      return <></>;
  }
};

export default observer(CoachViewProfileModal);
