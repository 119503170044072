import { observer } from 'mobx-react';
import React from 'react';
import NotSupportedInLanguageStore from '../../../NotSupportedInLanguageStore';
import LanguageNotAvailableMessage from './LanguageNotAvailableMessage';

interface Props {
  programNotSupportedInLanguageStore: NotSupportedInLanguageStore;
}

const ProgramLanguageNotAvailableMessage: React.FC<Props> = (props: Props) => {
  const { programNotSupportedInLanguageStore } = props;

  switch (programNotSupportedInLanguageStore.state.kind) {
    case 'ready':
      return <LanguageNotAvailableMessage />;
    case 'hidden':
      return <></>;
  }
};

export default observer(ProgramLanguageNotAvailableMessage);
