import { useContext } from 'react';
import { ExperienceContext } from './Types';

export function useExperienceContext() {
  const context = useContext(ExperienceContext);

  if (!context) {
    throw new Error('useExperienceContext has to be used within the ExperienceContextProvider.');
  }

  return context;
}
