import { observer } from 'mobx-react';
import React from 'react';
import HeroSchoolPartnerLogo from './HeroSchoolPartnerLogo';
import ExperienceDescription from '../ExperienceDescription';
import DownloadProgramOverviewLink from '../DownloadProgramOverviewLink';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import Competencies from '../Body/Competencies';
import CallToAction from '../../../CallToAction';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const ExperienceBody: React.FC<Props> = ({ externalProgramResource }) => {
  return (
    <div className="flex-row justify-between">
      <div style={{ flex: 0.9, marginBottom: 20 }}>
        <HeroSchoolPartnerLogo
          schoolPartnerResource={externalProgramResource.payload.schoolPartner}
        />
        <Competencies externalProgramResource={externalProgramResource} />
        <div className="flex flex-row items-center">
          <ExperienceDescription
            publicDetailedDescriptionHtml={
              externalProgramResource.payload.publicDetailedDescriptionHtml
            }
          />
          <CallToAction externalProgramResource={externalProgramResource} />
        </div>
      </div>

      <DownloadProgramOverviewLink externalProgramResource={externalProgramResource} />
    </div>
  );
};

export default observer(ExperienceBody);
