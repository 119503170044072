import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { noop } from '@kofno/piper';
import { Redirect } from 'react-router-dom';
import { useOpenable } from '../../components/Openable';
import AlertPreviewModal from '../AEP/DiscoveryPortal/PreviewMode/AlertPreviewModal';
import { PreviewModeContext } from '../AEP/DiscoveryPortal/PreviewMode/PreviewModeContext';
import Reactions from './Reactions';
import Store from './Store';

interface Props {
  to: string;
  children: (onClick: () => void) => React.ReactNode;
}

const NativeLink = ({ to, children }: Props) => {
  const store = useRef(new Store());
  const { openableState, open, close } = useOpenable();

  useEffect(() => {
    store.current.ready(to);
  }, [to]);

  switch (store.current.state.kind) {
    case 'waiting':
    case 'error':
      return (
        <>
          {children(noop)}
          <Reactions store={store.current} />
        </>
      );
    case 'ready':
      return (
        <PreviewModeContext.Consumer>
          {(previewMode) => {
            switch (previewMode) {
              case 'default':
                return (
                  <>
                    {children(store.current.navigate)}
                    <Reactions store={store.current} />
                  </>
                );
              case 'preview':
                return (
                  <>
                    <AlertPreviewModal modalState={openableState} onClose={close} />
                    {children(open)}
                  </>
                );
            }
          }}
        </PreviewModeContext.Consumer>
      );
    case 'navigate':
      return (
        <>
          {children(noop)}
          <Redirect to={store.current.state.to} push={true} />
          <Reactions store={store.current} />
        </>
      );
  }
};

export default observer(NativeLink);
