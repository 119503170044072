import { AttachmentResource } from '../../SegmentStore/Types';
import { NotTranslated } from '../../Translations';
import AttachmentSlidesIcon from '../Cutesies/AttachmentSlidesIcon';
import DownloadAttachmentIcon from '../Cutesies/DownloadAttachmentIcon';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import * as style from './style.module.css';

interface Props {
  attachmentResource: AttachmentResource;
}

function DocumentAttachment({ attachmentResource }: Props) {
  const { openableState, open, close } = useOpenable();

  return (
    <li className={style.attachment} data-test-document-attachment={true}>
      <button className={style.button} onClick={open}>
        <div className={style.leftIconWrapper}>
          <AttachmentSlidesIcon />
        </div>
        <p className={style.title} data-test-attachment-title={attachmentResource.payload.title}>
          <NotTranslated text={attachmentResource.payload.title} />
        </p>
        <div className={style.downloadIconWrapper}>
          <DownloadAttachmentIcon />
        </div>
      </button>
      <DownloadViewModal resource={attachmentResource} close={close} modalState={openableState} />
    </li>
  );
}

export default DocumentAttachment;
