import { identity } from '@kofno/piper';
import Decoder, { oneOf } from 'jsonous';
import { Maybe } from 'maybeasy';
import {
  AnnouncementEmbeddable,
  AnnouncementEmbeddableNode,
  DigitalCertificateResourceNode,
  ExpertFeedbackNode,
  LearningPartnerAssignmentNode,
  SharedResourceNode,
} from '../../Announcements/Announcement/Content/Types';
import {
  DigitalCertificateAnnouncement,
  SharedResourceAnnouncement,
} from '../../Announcements/Decoders/Types';
import { Link, Resource } from '../../Resource/Types';
import SegmentStore from '../../SegmentStore';
import { DocumentAssetResource } from '../../SegmentStore/Types';
import { teamLinkDecoder } from '../../TeamsStore/Decoders';
import { TeamLinkNode } from '../../TeamsStore/Types';
import { EventEmbeddable, EventEmbeddableNode } from '../ConferencePanel/Agenda/Types';
import { conjointAnalysisDecoder } from '../ConjointAnalysis/Decoders';
import { conjointAnalysisResultsDecoder } from '../ConjointAnalysisResults/Decoders';
import { dpResourceDecoder } from '../DiscoveryPortalOpenInviteUuid/Decoders';
import { coachingSurveyButtonNodeDecoder } from '../Embeddables/CoachingSurveyButtonEmbeddable/Decoders';
import { CoachProfileResource, FormFieldAssetResource } from '../EmbeddedFormFieldAsset/Types';
import { ExpertFeedbackResource } from '../ExpertFeedback/Types';
import { freebusyDecoder } from '../Freebusy/Decoders';
import { FreebusyConfig } from '../Freebusy/Types';
import { LearningPartnerAssignment } from '../LearningPartnerAssignment/Types';
import { learningPartnersDecoder } from '../LearningPartners/Decoders';
import { LearningPartnersNode } from '../LearningPartners/Types';
import { liveForumDatesDecoder } from '../LiveForumDates/Decoders';
import { chooseNextExperienceResourceDecoder } from '../NextExperiences/Decoders';
import { sectionEventDatesDecoder } from '../SectionEventDates/Decoders';
import { SegmentEmbeddable, SegmentEmbeddableNode } from '../SegmentEmbed/Types';
import { teamMembersDecoder } from '../TeamMembers/Decoders';
import { TeamMembersNode } from '../TeamMembers/Types';
import {
  alertDecoder,
  coachingSelectedCoachNameNodeDecoder,
  coachingSelectedCoachProfileNodeDecoder,
  digitalCertificateResourceDecoder,
  displayAssignedCoachNodeDecoder,
  embeddedDocumentNodeDecoder,
  embeddedFormFieldNodeDecoder,
  embeddedTabularFormFieldGroupNodeDecoder,
  expertFeedbackDecoder,
  learningPartnerAssignmentDecoder,
  linkedDocumentNodeDecoder,
  programChatLinkNodeDecoder,
  scriptTagDecoder,
  sharedResourceDecoder,
  videoNodeDecoder,
} from './Decoders';
import { AlreadyTranslatedText } from '@execonline-inc/translations';

export interface AlertNode {
  kind: 'alert-node';
  content: string;
}

export interface EmbeddedDocumentNode {
  kind: 'embedded-document-node';
  uuid: string;
}

export interface EmbeddedFormFieldNode {
  kind: 'embedded-form-field-node';
  uuid: string;
}

export interface GroupTitle {
  kind: 'group-title';
  content: AlreadyTranslatedText;
}

export interface TabularOption {
  kind: 'tabular-option';
  content: Maybe<AlreadyTranslatedText>;
}

export interface Junk {
  kind: 'junk';
}

export type GroupChild = EmbeddedFormFieldNode | GroupTitle | TabularOption | Junk;

export interface EmbeddedTabularFormFieldGroupNode {
  kind: 'embedded-tabular-form-field-group-node';
  title: Maybe<GroupTitle>;
  options: TabularOption[];
  fields: EmbeddedFormFieldNode[];
}

export interface CoachingSelectedCoachNameNode {
  kind: 'coaching-selected-coach-name-node';
  name: string;
}

export interface CoachingSelectedCoach {
  id: number;
  name: string;
  initials: Maybe<string>;
  coachAvatar: Maybe<CoachingSelectedCoachAvatar>;
}

export type ImageType = 'image/jpeg' | 'image/png';

export interface CoachingSelectedCoachAvatar {
  type: ImageType;
  data: string;
  src: string;
  alt: string;
}

export type CoachingSelectedCoachResource = Resource<CoachingSelectedCoach>;

export interface CoachingSelectedCoachProfileNode {
  kind: 'coaching-selected-coach-profile-node';
  coach: CoachingSelectedCoachResource;
}

export interface DisplayAssignedCoachNode {
  kind: 'display-assigned-coach-node';
  coach: CoachProfileResource;
}

export interface AnnouncementVideoNode {
  kind: 'announcement-video-node';
  uuid: string;
}

export interface SegmentVideoNode {
  kind: 'segment-video-node';
  uuid: string;
}
export interface LinkedDocumentNode {
  kind: 'linked-document-node';
  uuid: string;
  href: string;
  description: string;
}

export interface ProgramChatLinkNode {
  kind: 'program-chat-link-node';
  programId: number;
}

export interface ScriptTag {
  kind: 'script-tag';
  content: string;
}

export type EmbeddableNode =
  | SegmentEmbeddableNode
  | AnnouncementEmbeddableNode
  | EventEmbeddableNode;

export interface AlertEmbeddable {
  kind: 'alert-embeddable';
  node: AlertNode;
}

export interface EmbeddedDocumentEmbeddable {
  kind: 'embedded-document-embeddable';
  node: EmbeddedDocumentNode;
  assets: DocumentAssetResource[];
}

export interface EmbeddedTabularFormFieldGroupEmbeddable {
  kind: 'embedded-tabular-form-field-group-embeddable';
  node: EmbeddedTabularFormFieldGroupNode;
  assets: FormFieldAssetResource[];
  segmentStore: Maybe<SegmentStore>;
  title: Maybe<GroupTitle>;
  options: TabularOption[];
  fields: EmbeddedFormFieldNode[];
}

export interface EmbeddedFormFieldEmbeddable {
  kind: 'embedded-form-field-embeddable';
  node: EmbeddedFormFieldNode;
  assets: FormFieldAssetResource[];
  segmentStore: Maybe<SegmentStore>;
}

export interface CoachingSelectedCoachNameEmbeddable {
  kind: 'coaching-selected-coach-name-embeddable';
  node: CoachingSelectedCoachNameNode;
}

export interface CoachingSelectedCoachProfileEmbeddable {
  kind: 'coaching-selected-coach-profile-embeddable';
  node: CoachingSelectedCoachProfileNode;
}

export interface DisplayAssignedCoachEmbeddable {
  kind: 'display-assigned-coach-embeddable';
  node: DisplayAssignedCoachNode;
}

export interface FreebusyEmbeddable {
  kind: 'freebusy-embeddable';
  node: FreebusyConfig;
  segmentStore: Maybe<SegmentStore>;
  links: Maybe<ReadonlyArray<Link>>;
}

export interface LearningPartnerAssignmentEmbeddable {
  kind: 'learning-partner-assignment-embeddable';
  node: LearningPartnerAssignmentNode;
  assignment: LearningPartnerAssignment;
}

export interface ExpertFeedbackNodeEmbeddable {
  kind: 'expert-feedback-embeddable';
  node: ExpertFeedbackNode;
  feedback: ExpertFeedbackResource;
}

export interface LearningPartnersEmebeddable {
  kind: 'learning-partners-embeddable';
  node: LearningPartnersNode;
}

export interface LinkedDocumentEmbeddable {
  kind: 'linked-document-embeddable';
  node: LinkedDocumentNode;
  links: ReadonlyArray<Link>;
}

export interface ScriptEmbeddable {
  kind: 'script-embeddable';
  node: ScriptTag;
}

export interface SharedResourceEmbeddable {
  kind: 'shared-resource-embeddable';
  node: SharedResourceNode;
  sharedResourceAnnouncement: SharedResourceAnnouncement;
}

export interface DigitalCertificateResourceEmbeddable {
  kind: 'digital-certificate-resource-embeddable';
  node: DigitalCertificateResourceNode;
  digitalCertificateAnnouncement: DigitalCertificateAnnouncement;
}

export interface TeamLinkEmbeddable {
  kind: 'team-link-embeddable';
  node: TeamLinkNode;
}

export interface TeamMembersEmebeddable {
  kind: 'team-members-embeddable';
  node: TeamMembersNode;
}

export interface ProgramChatLinkEmbeddable {
  kind: 'program-chat-link-embeddable';
  node: ProgramChatLinkNode;
}

export type Embeddable = EventEmbeddable | SegmentEmbeddable | AnnouncementEmbeddable;

export type NodeContext = 'overview' | 'announcement' | 'assignment-due' | 'agenda';

export const nodeDecoder = (context: NodeContext): Decoder<EmbeddableNode> =>
  oneOf<EmbeddableNode>([
    alertDecoder.map<EmbeddableNode>(identity),
    scriptTagDecoder.map<EmbeddableNode>(identity),
    freebusyDecoder.map<EmbeddableNode>(identity),
    teamLinkDecoder.map<EmbeddableNode>(identity),
    sectionEventDatesDecoder.map<EmbeddableNode>(identity),
    learningPartnersDecoder.map<EmbeddableNode>(identity),
    teamMembersDecoder.map<EmbeddableNode>(identity),
    chooseNextExperienceResourceDecoder.map<EmbeddableNode>(identity),
    dpResourceDecoder.map<EmbeddableNode>(identity),
    videoNodeDecoder(context).map<EmbeddableNode>(identity),
    conjointAnalysisDecoder.map<EmbeddableNode>(identity),
    conjointAnalysisResultsDecoder.map<EmbeddableNode>(identity),
    linkedDocumentNodeDecoder.map<EmbeddableNode>(identity),
    embeddedDocumentNodeDecoder.map<EmbeddableNode>(identity),
    liveForumDatesDecoder.map<EmbeddableNode>(identity),
    learningPartnerAssignmentDecoder.map<EmbeddableNode>(identity),
    sharedResourceDecoder.map<EmbeddableNode>(identity),
    expertFeedbackDecoder.map<EmbeddableNode>(identity),
    digitalCertificateResourceDecoder.map<EmbeddableNode>(identity),
    embeddedTabularFormFieldGroupNodeDecoder.map<EmbeddableNode>(identity),
    embeddedFormFieldNodeDecoder.map<EmbeddableNode>(identity),
    coachingSelectedCoachNameNodeDecoder.map<EmbeddableNode>(identity),
    coachingSelectedCoachProfileNodeDecoder.map<EmbeddableNode>(identity),
    displayAssignedCoachNodeDecoder.map<EmbeddableNode>(identity),
    coachingSurveyButtonNodeDecoder.map<EmbeddableNode>(identity),
    programChatLinkNodeDecoder.map<EmbeddableNode>(identity),
  ]);
