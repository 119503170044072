import { observer } from 'mobx-react';
import { PromotableExperienceResource } from '../Types';
import PromotableExperienceTileDescription from './PromotableExperienceTileDescription';
import PromotableExperienceTileFooter from './PromotableExperienceTileFooter';
import PromotableExperienceTileImage from './PromotableExperienceTileImage';
import PromotableExperienceTileTitle from './PromotableExperienceTileTitle';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function MobilePromotableExperienceTileView({ experienceResource }: Props) {
  return (
    <section className="flex w-full min-w-[20.4375rem] flex-shrink-0 snap-start flex-col">
      <div className="relative mb-8 h-52 md:mb-0">
        <span className="absolute inset-0 bg-promotable-carousel-mobile [clip-path:ellipse(110%_75%_at_70%_75%)]" />
        <PromotableExperienceTileImage experienceResource={experienceResource} />
      </div>
      <PromotableExperienceTileTitle experienceResource={experienceResource} />
      <PromotableExperienceTileDescription experienceResource={experienceResource} />
      <PromotableExperienceTileFooter />
    </section>
  );
}

export default observer(MobilePromotableExperienceTileView);
