import { observer } from 'mobx-react';
import * as React from 'react';
import { sessionStore } from '../../../Session/Store';
import ImpersonationMessage from '../../ImpersonationMessage';
import CurrentUserStateView from './CurrentUserStateView';

const AppView: React.FC = () => (
  <>
    <ImpersonationMessage impersonatingBy={sessionStore.impersonatingBy} />
    <CurrentUserStateView />
  </>
);

export default observer(AppView);
