import { observer } from 'mobx-react';
import * as React from 'react';
import { AnnouncementVideoEmbeddable } from '../../Announcements/Announcement/Content/Types';
import * as announcementStyle from '../../Announcements/Announcement/Content/style.module.css';
import AnnouncementEmbeddedVideoPlayer from './AnnouncementEmbeddedVideoPlayer';

interface Props {
  embeddable: AnnouncementVideoEmbeddable;
}

const AnnouncementEmbeddableVideoWrapper: React.FC<Props> = ({ embeddable }) => {
  const className = (): string => {
    return embeddable.kind === 'announcement-video-embeddable'
      ? announcementStyle.videoWrapper
      : '';
  };

  return (
    <div className={className()}>
      <AnnouncementEmbeddedVideoPlayer
        uuid={embeddable.node.uuid}
        embeddedVideoAssets={embeddable.assets}
        width="100%"
        height={320}
        dynamicallySetAspectRatio={true}
      />
    </div>
  );
};

export default observer(AnnouncementEmbeddableVideoWrapper);
