import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const CohortGroupIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 23 16">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M7.6 0a2.811 2.811 0 0 0-2.8 2.8c0 1.538 1.263 2.8 2.8 2.8s2.8-1.262 2.8-2.8C10.4 1.263 9.137 0 7.6 0Zm7.2 0A2.811 2.811 0 0 0 12 2.8c0 1.538 1.262 2.8 2.8 2.8 1.537 0 2.8-1.262 2.8-2.8 0-1.537-1.263-2.8-2.8-2.8ZM7.6 1.6c.672 0 1.2.528 1.2 1.2 0 .672-.528 1.2-1.2 1.2-.672 0-1.2-.528-1.2-1.2 0-.672.528-1.2 1.2-1.2Zm7.2 0c.672 0 1.2.528 1.2 1.2 0 .672-.528 1.2-1.2 1.2-.672 0-1.2-.528-1.2-1.2 0-.672.528-1.2 1.2-1.2ZM4 4.8A3.21 3.21 0 0 0 .8 8c0 .89.381 1.694.975 2.275A4.029 4.029 0 0 0 0 13.6h1.6c0-1.334 1.066-2.4 2.4-2.4 1.334 0 2.4 1.066 2.4 2.4H8c0-1.375-.71-2.603-1.775-3.325A3.183 3.183 0 0 0 7.2 8c0-1.76-1.44-3.2-3.2-3.2Zm4 8.8c-.5.669-.8 1.51-.8 2.4h1.6c0-1.334 1.066-2.4 2.4-2.4 1.334 0 2.4 1.066 2.4 2.4h1.6a4.02 4.02 0 0 0-1.775-3.325A3.183 3.183 0 0 0 14.4 10.4c0-1.76-1.44-3.2-3.2-3.2A3.21 3.21 0 0 0 8 10.4c0 .89.381 1.694.975 2.275A4.033 4.033 0 0 0 8 13.6Zm6.4 0H16c0-1.334 1.066-2.4 2.4-2.4 1.334 0 2.4 1.066 2.4 2.4h1.6c0-1.375-.71-2.603-1.775-3.325A3.183 3.183 0 0 0 21.6 8c0-1.76-1.44-3.2-3.2-3.2A3.21 3.21 0 0 0 15.2 8c0 .89.381 1.694.975 2.275A4.029 4.029 0 0 0 14.4 13.6ZM4 6.4c.894 0 1.6.706 1.6 1.6 0 .894-.706 1.6-1.6 1.6-.894 0-1.6-.706-1.6-1.6 0-.894.706-1.6 1.6-1.6Zm14.4 0c.894 0 1.6.706 1.6 1.6 0 .894-.706 1.6-1.6 1.6-.894 0-1.6-.706-1.6-1.6 0-.894.706-1.6 1.6-1.6Zm-7.2 2.4c.894 0 1.6.706 1.6 1.6 0 .894-.706 1.6-1.6 1.6-.894 0-1.6-.706-1.6-1.6 0-.894.706-1.6 1.6-1.6Z"
      />
    </SVGIcon>
  );
};

export default observer(CohortGroupIcon);
