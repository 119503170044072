import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import HelpIcon from '../../Cutesies/HelpIcon';
import ModalStore from '../../Modal/Store';
import * as style from '../style.module.css';
import TroubleshootingModal from './TroubleshootingModal';

const Troubleshooting: React.FC = () => {
  const modalStore: ModalStore = new ModalStore();

  return (
    <dl className={style.dl}>
      <dt className={style.detailIcon}>
        <HelpIcon />
      </dt>
      <dd className={style.detailValue}>
        <a href="#" onClick={modalStore.open} data-test-conference-dial-in-info-link={true}>
          <T kind="Troubleshooting Meeting Issues" />
        </a>
        <TroubleshootingModal store={modalStore} />
      </dd>
    </dl>
  );
};

export default observer(Troubleshooting);
