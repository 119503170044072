import { observer } from 'mobx-react';
import { PaymentProcessing } from '../../Common';

function PaymentCheckoutInitialization() {
  return (
    <div className="flex h-full w-full flex-col items-center self-center py-32 md:basis-3/5 md:py-0">
      <PaymentProcessing />
    </div>
  );
}

export default observer(PaymentCheckoutInitialization);
