import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { discoveryPortalRoute } from '../../ClientRoutes';
import { T } from '../../Translations';
import DiscoveryPortalIcon from '../Cutesies/DiscoveryPortalIcon';
import * as style from './style.module.css';
import { DiscoveryPortalOpenInviteUuidNode } from './Types';

interface Props {
  node: DiscoveryPortalOpenInviteUuidNode;
}

const DiscoveryPortalOpenInviteUuid: React.FC<Props> = ({ node }) =>
  node.openInviteUuid.uuid
    .map(uuid => {
      return (
        <div className={style.redButtonContainer}>
          <Link to={discoveryPortalRoute(uuid)} className={style.dpContentButton}>
            <div className={style.dpContentButtonIcon}>
              <DiscoveryPortalIcon />
            </div>
            <T kind="Go To Discovery Portal" />
          </Link>
        </div>
      );
    })
    .getOrElse(() => <></>);

export default observer(DiscoveryPortalOpenInviteUuid);
