import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import LogoGraphic from '../../components/Cutesies/LogoGraphic';
import LanguageSelector from '../../components/LanguageSelector';
import * as style from './style.module.css';

const PreferredLanguageSelector: React.FC = () => (
  <div>
    <div className={style.logo}>
      <LogoGraphic />
    </div>
    <div className={style.languageSelector}>
      <div className={style.languageSelectorText}>
        <T kind="Please select your preferred language to continue." />
      </div>
      <div className={style.languageSelectorLink}>
        <LanguageSelector separator={'|'} />
      </div>
    </div>
  </div>
);

export default observer(PreferredLanguageSelector);
