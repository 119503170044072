import { observer } from 'mobx-react';
import * as React from 'react';
import ProfileFormStore from '../../../ProfileFormStore';
import { T } from '../../../Translations';
import InputWithLabel from '../../Form/InputWithLabel';
import { setValue } from './SetValueHelper';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
}

const Social: React.FC<Props> = ({ profileStore }) => (
  <fieldset className={style.fieldset} data-test-social={true}>
    <h2 className={style.fieldHeader} data-test-social-header="Social">
      <T kind="Social" />
    </h2>
    <div className={style.labelAndInput}>
      <InputWithLabel
        className={style.input}
        labelText="LinkedIn URL"
        labelClassName={style.label}
        type="text"
        onChange={setValue(profileStore.setLinkedInPage)}
        value={profileStore.linkedInPage.getOrElseValue('')}
        hint="https://www.linkedin.com/in/johndoe"
        name="linked_in_url"
        id="linked_in_url"
      />
    </div>
    <div className={style.labelAndInput}>
      <InputWithLabel
        className={style.input}
        labelText="Facebook URL"
        labelClassName={style.label}
        type="text"
        onChange={setValue(profileStore.setFacebookPage)}
        value={profileStore.facebookPage.getOrElseValue('')}
        hint="https://www.facebook.com/johndoe"
        name="facebook_url"
        id="facebook_url"
      />
    </div>
    <div className={style.labelAndInput}>
      <InputWithLabel
        className={style.input}
        labelText="Twitter URL"
        labelClassName={style.label}
        type="text"
        onChange={setValue(profileStore.setTwitterPage)}
        value={profileStore.twitterPage.getOrElseValue('')}
        hint="https://www.twitter.com/johndoe"
        name="twitter_url"
        id="twitter_url"
      />
    </div>
  </fieldset>
);

export default observer(Social);
