import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import CoachRequestStore from '.';
import { postToApi } from '../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { Link } from '../../../Resource/Types';
import { State } from './Types';

interface Props extends EAProps<CoachRequestStore> {
  requestCoachLink: Link;
}

class CoachRequestReactions extends ErrorActionableReaction<CoachRequestStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      case 'requested':
        postToApi({})(this.props.requestCoachLink).fork(
          (err) => handleError(this.props.store, err),
          this.props.store.waiting
        );
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(CoachRequestReactions);
