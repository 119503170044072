import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DiscoveryPortalIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 31 31">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M31 9.964V31H14.393V13.286h6.643V9.964H31Zm-2.214 2.215H23.25v16.607h5.536V26.57h-4.429v-2.214h4.429v-6.643h-4.429V15.5h4.429v-3.321Zm-7.75 3.321h-4.429v13.286h4.429V26.57h-3.322v-2.214h3.322V19.93h-3.322v-2.215h3.322V15.5ZM26.57 0v8.857H2.214v15.5h11.072v2.214H0V0h26.571Zm-2.214 2.214H2.214v4.429h22.143V2.214ZM5.536 3.321v2.215H3.32V3.32h2.215Zm3.321 0v2.215H6.643V3.32h2.214Zm14.393 0v2.215H9.964V3.32H23.25Z"
      />
    </SVGIcon>
  );
};

export default observer(DiscoveryPortalIcon);
