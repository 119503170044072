import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import { alreadyTranslatedText } from '@execonline-inc/translations';
import { identity } from '@kofno/piper';
import Decoder, { field, number, oneOf, string, succeed } from 'jsonous';
import { assertionBackendResourceDecoder } from '../../../../Login/Decoders';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { supportedLanguageCodeDecoder } from '../../../../SupportedLanguages/Decoders';
import { organizationResourceDecoder } from '../../Decoders';
import { CommerceType, LoginMessageType } from '../../Common/Experience/Types';
import {
  PreviewOnly,
  PreviewOnlyInvitationResource,
  SharedInvitationError,
  SharedInvitationErrorResource,
  SharedInvitationResource,
  UseCase,
  UseCaseResource,
  UseCasesProgramFamily,
  UseCasesProgramFamilyResource,
} from './Types';

export const commerceTypeDecoder: Decoder<CommerceType> = oneOf([
  stringLiteral<CommerceType>('cc-only'),
  stringLiteral<CommerceType>('cc-or-direct-bill'),
  stringLiteral<CommerceType>('not-available'),
]);

export const loginMessageType: Decoder<LoginMessageType> = oneOf([
  stringLiteral<LoginMessageType>('use-case-description'),
  stringLiteral<LoginMessageType>('use-case-login-message'),
]);

export const useCaseDecoder: Decoder<UseCase> = succeed({})
  .assign('kind', field('kind', stringLiteral('use-case')))
  .assign('uuid', field('uuid', string))
  .assign('name', field('name', explicitMaybe(alreadyTranslatedText)))
  .assign('commerceType', field('commerce_type', commerceTypeDecoder))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign('loginMessage', field('login_message', explicitMaybe(alreadyTranslatedText)))
  .assign('loginMessageType', field('login_message_type', loginMessageType))
  .assign('organization', field('organization', organizationResourceDecoder))
  .assign('assertionBackend', field('login', assertionBackendResourceDecoder))
  .assign('locale', field('locale', supportedLanguageCodeDecoder));

export const useCaseResourceDecoder: Decoder<UseCaseResource> = resourceDecoder(useCaseDecoder);

export const useCasesProgramFamilyDecoder: Decoder<UseCasesProgramFamily> = succeed({})
  .assign('kind', field('kind', stringLiteral('use-cases-program-family')))
  .assign('useCaseUuid', field('use_case_uuid', string))
  .assign('name', field('name', explicitMaybe(alreadyTranslatedText)))
  .assign('commerceType', field('commerce_type', commerceTypeDecoder))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign('loginMessageType', field('login_message_type', loginMessageType))
  .assign('loginMessage', field('login_message', explicitMaybe(alreadyTranslatedText)))
  .assign('organization', field('organization', organizationResourceDecoder))
  .assign('programFamilyId', field('program_family_id', number))
  .assign('id', field('id', number))
  .assign('assertionBackend', field('login', assertionBackendResourceDecoder))
  .assign('locale', field('locale', supportedLanguageCodeDecoder));

export const useCasesProgramFamilyResourceDecoder: Decoder<UseCasesProgramFamilyResource> =
  resourceDecoder(useCasesProgramFamilyDecoder);

export const previewOnlyDecoder: Decoder<PreviewOnly> = succeed({})
  .assign('kind', field('kind', stringLiteral('preview-only')))
  .assign('name', field('name', explicitMaybe(alreadyTranslatedText)))
  .assign('commerceType', field('commerce_type', commerceTypeDecoder))
  .assign('description', field('description', explicitMaybe(alreadyTranslatedText)))
  .assign('organization', field('organization', organizationResourceDecoder))
  .assign('assertionBackend', field('login', assertionBackendResourceDecoder))
  .assign('loginMessageType', field('login_message_type', loginMessageType))
  .assign('loginMessage', field('login_message', explicitMaybe(alreadyTranslatedText)))
  .assign('locale', field('locale', supportedLanguageCodeDecoder));

export const previewOnlyInvitationResourceDecoder: Decoder<PreviewOnlyInvitationResource> =
  resourceDecoder(previewOnlyDecoder);

export const sharedInvitationResourceDecoder: Decoder<SharedInvitationResource> =
  oneOf<SharedInvitationResource>([
    useCaseResourceDecoder.map<SharedInvitationResource>(identity),
    useCasesProgramFamilyResourceDecoder.map<SharedInvitationResource>(identity),
    previewOnlyInvitationResourceDecoder.map<SharedInvitationResource>(identity),
  ]);

export const sharedInvitationErrorDecoder: Decoder<SharedInvitationError> = succeed({}).assign(
  'error',
  field('error', alreadyTranslatedText),
);

export const sharedInvitationErrorResourceDecoder: Decoder<SharedInvitationErrorResource> =
  resourceDecoder(sharedInvitationErrorDecoder);
