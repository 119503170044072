interface Ready {
  kind: 'ready';
}

interface PollLaunched {
  kind: 'poll-launched';
  poll: EventPoll;
}

export const pollLaunched = (poll: EventPoll): PollLaunched => ({ kind: 'poll-launched', poll });

export const ready = (): Ready => ({ kind: 'ready' });

export type State = Ready | PollLaunched;

export interface EventPoll {
  pollUrl: string;
}
