import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { T } from '../../../../../../../Translations';
import { CoachingProfileResource } from '../../../../../Common/Experience/Types';
import GroupCoachProfileAvatar from '../GroupCoachProfileAvatar';

interface Props {
  profile: Maybe<CoachingProfileResource>;
}

function GroupCoachSessionProfile({ profile }: Props) {
  return profile
    .map((profile) => (
      <div className="flex items-center">
        <Typography variant="bodySmall">
          <T kind="Led by:" />
        </Typography>
        <GroupCoachProfileAvatar profile={profile} />
        <Typography variant="bodySmall">
          <AlreadyTranslated content={profile.payload.name} />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachSessionProfile);
