import { observer } from 'mobx-react';
import * as React from 'react';
import CalendarEventStore from '../../EventsStore/CalendarEventStore';
import { L, T } from '../../Translations';
import MultiDayTimes from './MultiDayTimes';

interface Props {
  store: CalendarEventStore;
}

const EventTimes: React.FC<Props> = ({ store }) => {
  const event = store.event;
  switch (event.span) {
    case 'single-day':
      return (
        <>
          <T
            kind="<startDate/>, <startTime/>"
            startDate={<L localizeable={event.startAt} format="short-month-day-year" />}
            startTime={<L localizeable={event.startAt} format="time-of-day" />}
          />
        </>
      );
    case 'multi-day':
      return <MultiDayTimes event={event} />;
  }
};

export default observer(EventTimes);
