import { assertNever, noop } from '@kofno/piper';

import ReactionComponent, { RCProps } from '../../../../../../../ReactionComponent';
import { EnrolledModalState } from '../EnrolledModalStore/Types';
import EnrolledModalStore from '../EnrolledModalStore';
import { getNotEnrollableConfirmationProgramIds, initializeModal, saveId } from './Types';

interface Props extends RCProps<EnrolledModalStore> {}

class EnrolledModalReactions extends ReactionComponent<
  EnrolledModalStore,
  EnrolledModalState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: EnrolledModalState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        getNotEnrollableConfirmationProgramIds()
          .andThen((notEnrollableProgramIds) => initializeModal(store, notEnrollableProgramIds))
          .fork(store.open, store.close);
        break;
      case 'confirming':
        getNotEnrollableConfirmationProgramIds().fork(noop, saveId(store.stringifiedProgramId));
        store.close();
        break;
      case 'confirm-and-routing':
        getNotEnrollableConfirmationProgramIds().fork(noop, saveId(store.stringifiedProgramId));
        store.closeAndRoute();
        break;
      case 'waiting':
      case 'open':
      case 'reopened':
      case 'closed':
      case 'closed-and-routing':
        break;
      default:
        assertNever(state);
    }
  };
}

export default EnrolledModalReactions;
