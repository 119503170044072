import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';
import {
  ExperienceDisplayTypeKind,
  ExperienceResources,
  ProductCollection,
} from '../../../../Common/Experience/Types';
import {
  ExperienceSection,
  ExperienceSectionBody,
  ExperienceSectionDescription,
  ExperienceSectionHeader,
  ExperienceSectionTitle,
} from '../../../Common/ExperienceSection';
import EnrollmentStore from '../../../Enrollment/Store';
import { useExperienceFilters } from '../../Filters/FiltersContext';
import { ExpandExperiences } from '../ExpandExperiences';
import TileExperiences from './TileExperiences';

interface Props {
  kind: ExperienceDisplayTypeKind;
  invitationUuid: string;
  productCollection: Maybe<ProductCollection>;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
}

function TileViewExperiences({
  invitationUuid,
  experienceResources,
  enrollmentStore,
  kind,
  productCollection,
}: Props) {
  const { filterExperienceResources } = useExperienceFilters();

  const filteredExperienceResources = filterExperienceResources(experienceResources);

  switch (kind) {
    case 'experiences':
      return (
        <ExperienceSection className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8">
          <ExperienceSectionHeader>
            <ExperienceSectionTitle>
              <T kind="All Programs in this Catalog" />
            </ExperienceSectionTitle>
          </ExperienceSectionHeader>
          <ExperienceSectionBody>
            <TileExperiences
              invitationUuid={invitationUuid}
              experienceResources={filteredExperienceResources}
              enrollmentStore={enrollmentStore}
            />
          </ExperienceSectionBody>
        </ExperienceSection>
      );
    case 'product-collection':
      return productCollection
        .map((pc) => (
          <ExperienceSection className="bg-inherit pb-6 pt-0 !shadow-none sm:py-8">
            <ExperienceSectionHeader>
              <ExperienceSectionTitle>
                <AlreadyTranslated content={pc.name} />
              </ExperienceSectionTitle>
              {pc.description
                .map((description) => (
                  <ExperienceSectionDescription>
                    <AlreadyTranslated content={description} />
                  </ExperienceSectionDescription>
                ))
                .getOrElse(emptyFragment)}
            </ExperienceSectionHeader>
            <ExperienceSectionBody>
              <ExpandExperiences productCollection={pc}>
                <TileExperiences
                  invitationUuid={invitationUuid}
                  experienceResources={filteredExperienceResources}
                  enrollmentStore={enrollmentStore}
                />
              </ExpandExperiences>
            </ExperienceSectionBody>
          </ExperienceSection>
        ))
        .getOrElse(emptyFragment);
  }
}

export default observer(TileViewExperiences);
