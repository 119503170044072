import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import PresenceMembersStore from '../../../Socket/PresenceChannel/PresenceMembersStore';
import TabStore from '../../../TabStore';
import * as style from '../../SegmentPanel/style.module.css';
import AgendaTab from '../AgendaTab';
import GroupChatTab from '../GroupChatTab';
import ParticipantsTab from '../ParticipantsTab';

interface Props {
  tabStore: TabStore;
  presenceMembersStore: PresenceMembersStore;
  agenda: Maybe<string>;
  conferenceRosterStore: ConferenceRosterStore;
}

const Tabs: React.FC<Props> = ({
  tabStore,
  presenceMembersStore,
  agenda,
  conferenceRosterStore,
}) => (
  <div className={style.tabs}>
    <GroupChatTab tabStore={tabStore} conversationStore={presenceMembersStore.conversationStore} />
    <ParticipantsTab tabStore={tabStore} conferenceRosterStore={conferenceRosterStore} />
    {agenda.map(() => <AgendaTab tabStore={tabStore} />).getOrElseValue(<span />)}
  </div>
);
export default observer(Tabs);
