import { observer } from 'mobx-react';
import VisaCardIcon from './VisaCardIcon';
import MasterCardIcon from './MasterCardIcon';
import AmericanExpressCardIcon from './AmericanExpressCardIcon';

function PaymentCardIcons() {
  return (
    <div className="flex gap-x-1.5">
      <VisaCardIcon />
      <MasterCardIcon />
      <AmericanExpressCardIcon />
    </div>
  );
}

export default observer(PaymentCardIcons);
