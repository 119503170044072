import * as React from 'react';
import SVGIcon from '../SVGIcon';

const path =
  'M8 0V2H0V46H44V2H36V0H32V2H12V0H8ZM4 6H8V8H12V6H32V8H36V6H40V10H4V6ZM4 14H40V42H4V14ZM30.5625 18.5625L20 29.125L15.4375 24.5625L12.5625 27.4375L18.5625 33.4375L20 34.8125L21.4375 33.4375L33.4375 21.4375L30.5625 18.5625Z';

const EventPlaceholderIcon: React.StatelessComponent = () => {
  return (
    <SVGIcon viewBox="0 0 44 46">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d={path} fill="#BEC0C5" fillRule="nonzero" />
      </g>
    </SVGIcon>
  );
};

export default EventPlaceholderIcon;
