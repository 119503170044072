import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

interface Props {
  onApply: () => void;
  onClear: () => void;
}

function SidebarFooter({ onApply, onClear }: Props) {
  return (
    <>
      <Button className="w-full" variant="outlined" color="gray" size="md" onClick={onClear}>
        <T kind="Clear All" />
      </Button>
      <Button className="w-full" color="gray" size="md" onClick={onApply}>
        <T kind="Apply" />
      </Button>
    </>
  );
}

export default observer(SidebarFooter);
