import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const CompletedModuleIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 22 22">
      <g fill="none">
        <circle className="circle" cx="11" cy="11" r="11" fill="#2AAB39" />
        <path
          fill={defaultTheme.variables['icon-on-dark']}
          stroke={defaultTheme.variables['icon-on-dark']}
          d="m16.234 7-6.802 7.134-2.866-3.006-.566.593 3.149 3.303.283.284.283-.284L16.8 7.593z"
        />
      </g>
    </SVGIcon>
  );
};

export default observer(CompletedModuleIcon);
