import { observer } from 'mobx-react';
import { SvgIcon } from '@execonline-inc/execonline-ui';

function MasterCardIcon() {
  return (
    <SvgIcon>
      <path
        d="M16 5a7.02 7.02 0 0 0-7 7 7.02 7.02 0 0 0 7 7 7.02 7.02 0 0 0 7-7 7.02 7.02 0 0 0-7-7Zm0 0"
        fill="#FF9800"
      />
      <path
        d="M8 5a7.02 7.02 0 0 0-7 7 7.02 7.02 0 0 0 7 7 7.02 7.02 0 0 0 7-7 7.02 7.02 0 0 0-7-7Zm0 0"
        fill="#D50000"
      />
      <path
        d="M9 12a6.98 6.98 0 0 0 3 5.74 6.98 6.98 0 0 0 0-11.48A6.98 6.98 0 0 0 9 12Zm0 0"
        fill="#FF3D00"
      />
    </SvgIcon>
  );
}

export default observer(MasterCardIcon);
