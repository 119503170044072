import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import ExperienceTimeCommitment from './ExperienceTimeCommitment';

interface Props {
  experience: Experience;
  className?: string;
}

function ExperienceOverview({ experience, className }: Props) {
  return (
    <div className={cn('flex gap-4', className)}>
      <ExperienceTimeCommitment experience={experience} />
    </div>
  );
}

export default observer(ExperienceOverview);
