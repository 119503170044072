import { find } from '@execonline-inc/collections';
import { log, warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Maybe, fromNullable, just } from 'maybeasy';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { LectureSegment, LectureStepResource } from '../LectureStore/Types';
import { ConvertedPptState } from './Types';
import * as iSpringCommands from './iSpringCommands';
import ConvertedPptStore from './index';

export interface Props extends EAProps<ConvertedPptStore> {
  store: ConvertedPptStore;
}

const waitForVideo = (store: ConvertedPptStore) => {
  store.waitForVideo();
};

const findStep = (segment: LectureSegment, advancingToStep: number): Maybe<LectureStepResource> =>
  find(({ payload }) => payload.step === advancingToStep, segment.steps);

const playerWindow = (iframeRef: React.RefObject<HTMLIFrameElement>) =>
  fromNullable(iframeRef.current)
    .andThen((iframe) => fromNullable(iframe.contentWindow))
    .elseDo(() => warn('PPT advancement attempted before player was ready!'));

const advance = (store: ConvertedPptStore) => {
  switch (store.state.kind) {
    case 'advancing': {
      const { segment, advancingToStep, iframeRef } = store.state;
      just({})
        .assign('player', playerWindow(iframeRef))
        .assign('step', findStep(segment, advancingToStep))
        .do(({ step }) => log('Slide', step.payload.slide, 'Slide step', step.payload.slideStep))
        .do(({ player, step }) =>
          player.postMessage(
            iSpringCommands.gotoTimestamp({
              slide: step.payload.slide - 1,
              slideStep: step.payload.slideStep - 1,
              timeOffset: 0,
              autostart: true,
            }),
            '*'
          )
        )
        .do(store.ready);
      break;
    }
    case 'ready':
    case 'player-ready':
    case 'loading':
    case 'waiting-for-video':
    case 'error':
    case 'waiting':
      break;
    default:
      assertNever(store.state);
  }
};

class ConvertedPptReactions extends ErrorActionableReaction<
  ConvertedPptStore,
  ConvertedPptState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ConvertedPptState) => {
    const { store } = this.props;
    log('ConvertedPptState', state.kind);
    switch (state.kind) {
      case 'player-ready':
        waitForVideo(store);
        break;
      case 'advancing':
        advance(store);
        break;
      case 'loading':
      case 'ready':
      case 'waiting-for-video':
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ConvertedPptReactions;
