import { find } from '@execonline-inc/collections';
import { always, assertNever } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { LectureSegment, LectureStep } from '../LectureStore/Types';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { Resource } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import {
  ConvertedPptState,
  advancing,
  loading,
  playerReady,
  ready,
  waiting,
  waitingForVideo,
} from './Types';

/*
 * ConvertedPptStore manages the integration between our application and the converted PPT
 * presentation. This includes the loading and initialization of the iSpring player, and the calls
 * to it for advancing through slides and integration.
 *
 * Once the connection between the application and iSpring is established, this store observes the
 * position of the video, and as it passes through steps in the presentation, issues calls to the
 * presentation player.
 */
class ConvertedPptStore {
  @observable
  state: ConvertedPptState;

  constructor(segment: LectureSegment, kettle: Kettle) {
    this.state = waiting(segment, kettle);
  }

  @computed
  get currentStepNumber(): number {
    switch (this.state.kind) {
      case 'waiting-for-video':
      case 'ready':
      case 'advancing': {
        const segment = this.state.segment;
        return this.state.kettle.videoState.position
          .map((pos) => find((step: Resource<LectureStep>) => step.payload.seconds <= pos))
          .andThen((fn) => fn(segment.steps.reverse()))
          .cata({
            Just: (value) => value.payload.step,
            Nothing: always(0),
          });
      }
      case 'loading':
      case 'error':
      case 'waiting':
      case 'player-ready':
        return 0;
    }
  }

  @computed
  get advanceToStep(): Maybe<number> {
    switch (this.state.kind) {
      case 'advancing':
        return just(this.state.advancingToStep);
      case 'ready':
      case 'waiting-for-video':
      case 'loading':
      case 'error':
      case 'waiting':
      case 'player-ready':
        return nothing();
    }
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = loading(this.state.segment, this.state.kettle);
        break;
      case 'advancing':
      case 'ready':
      case 'waiting-for-video':
      case 'loading':
      case 'error':
      case 'player-ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  playerReady = (iframeRef: React.RefObject<HTMLIFrameElement>) => {
    switch (this.state.kind) {
      case 'loading':
        this.state = playerReady(this.state.segment, this.state.kettle, iframeRef);
        break;
      case 'advancing':
      case 'ready':
      case 'waiting-for-video':
      case 'waiting':
      case 'error':
      case 'waiting':
      case 'player-ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  waitForVideo = () => {
    switch (this.state.kind) {
      case 'player-ready':
        this.state = waitingForVideo(this.state.segment, this.state.kettle, this.state.iframeRef);
        break;
      case 'ready':
      case 'advancing':
      case 'loading':
      case 'ready':
      case 'waiting-for-video':
      case 'waiting':
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting-for-video':
        this.state = ready(this.state.segment, this.state.kettle, this.state.iframeRef);
        break;
      case 'ready':
      case 'advancing':
      case 'loading':
      case 'waiting':
      case 'error':
      case 'player-ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  advance = (stepNumber: number) => {
    switch (this.state.kind) {
      case 'ready':
      case 'advancing': {
        const segment = this.state.segment;
        const kettle = this.state.kettle;
        this.state = advancing(stepNumber, segment, kettle, this.state.iframeRef);
        break;
      }
      case 'loading':
      case 'waiting-for-video':
      case 'waiting':
      case 'error':
      case 'player-ready':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'advancing':
      case 'loading':
      case 'waiting-for-video':
      case 'waiting':
      case 'player-ready':
        return nothing();
    }
  }
}

export default ConvertedPptStore;
