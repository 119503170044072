import { Route, Switch } from 'react-router-dom';
import * as MessageBus from '../MessageBus';

export function Routes() {
  return (
    <Switch>
      <Route exact path="/service/message-bus-host" component={MessageBus.Host} />
    </Switch>
  );
}
