import { Maybe } from 'maybeasy';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: Maybe<TPlainTextKey>;
}

export interface ReadyWithClientErrors {
  kind: 'ready-with-client-errors';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: TPlainTextKey;
}

export interface ReadyWithServerErrors {
  kind: 'ready-with-server-errors';
  password: string;
  passwordConfirmation: string;
  termsAcceptance: boolean;
  message: AlreadyTranslatedText;
}

export interface Creating {
  kind: 'creating';
  password: string;
}

export interface Created {
  kind: 'created';
}

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
  termsAcceptance: boolean;
  password: string;
  passwordConfirmation: string;
}

export interface ServerError {
  kind: 'server-error';
  message: AlreadyTranslatedText;
  termsAcceptance: boolean;
  password: string;
  passwordConfirmation: string;
}

export type State =
  | Waiting
  | Ready
  | ReadyWithClientErrors
  | ReadyWithServerErrors
  | Creating
  | Created
  | Error
  | ServerError;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (termsAcceptance: boolean, message: Maybe<TPlainTextKey>): Ready => ({
  kind: 'ready',
  password: '',
  passwordConfirmation: '',
  termsAcceptance,
  message,
});

export const readyWithClientErrors = (
  termsAcceptance: boolean,
  message: TPlainTextKey,
  state: Waiting | Error | ServerError,
): ReadyWithClientErrors => {
  const common = {
    termsAcceptance,
    message,
  };

  switch (state.kind) {
    case 'waiting':
      return {
        kind: 'ready-with-client-errors',
        ...common,
        password: '',
        passwordConfirmation: '',
      };
    case 'error':
    case 'server-error':
      return {
        kind: 'ready-with-client-errors',
        ...common,
        password: state.password,
        passwordConfirmation: state.passwordConfirmation,
      };
  }
};

export const readyWithServerErrors = (
  termsAcceptance: boolean,
  message: AlreadyTranslatedText,
  state: Waiting | Error | ServerError,
): ReadyWithServerErrors => {
  const common = {
    termsAcceptance,
    message,
  };

  switch (state.kind) {
    case 'waiting':
      return {
        kind: 'ready-with-server-errors',
        ...common,
        password: '',
        passwordConfirmation: '',
      };
    case 'error':
    case 'server-error':
      return {
        kind: 'ready-with-server-errors',
        ...common,
        password: state.password,
        passwordConfirmation: state.passwordConfirmation,
      };
  }
};

export const creating = (password: string): Creating => ({
  kind: 'creating',
  password,
});

export const created = (): Created => ({
  kind: 'created',
});

export const error = (
  message: TPlainTextKey,
  termsAcceptance: boolean,
  state: Ready | ReadyWithClientErrors | ReadyWithServerErrors | Creating,
): Error => {
  const common = {
    message,
    termsAcceptance,
    password: state.password,
  };

  switch (state.kind) {
    case 'ready':
    case 'ready-with-client-errors':
    case 'ready-with-server-errors':
      return {
        kind: 'error',
        ...common,
        passwordConfirmation: state.passwordConfirmation,
      };
    case 'creating':
      return {
        kind: 'error',
        ...common,
        passwordConfirmation: state.password,
      };
  }
};

export const serverError = (
  message: AlreadyTranslatedText,
  termsAcceptance: boolean,
  password: string,
): ServerError => ({
  kind: 'server-error',
  message,
  termsAcceptance,
  password,
  passwordConfirmation: password,
});
