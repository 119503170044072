import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../Appy';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { resumerResourceDecoder } from './Decoders';
import { ResumerRedirectState } from './States';
import ResumerRedirectStore from './Store';

const resumerEndPoint = callApi(resumerResourceDecoder, {});

export interface Props extends EAProps<ResumerRedirectStore> {
  store: ResumerRedirectStore;
  currentUserResource: CurrentUserResource;
}

type ResumerRedirectError = MissingLinkError | AppyError;

const handleResumerRedirectError =
  (store: ResumerRedirectStore) =>
  (error: ResumerRedirectError): void => {
    switch (error.kind) {
      case 'missing-link-error':
        warn(`Did not find the resumer link on the current user resource.`);
        store.error('Missing Link');
        break;
      case 'bad-url':
      case 'timeout':
      case 'bad-status':
      case 'bad-payload':
      case 'network-error':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        handleError(store, error);
        break;
      default:
        assertNever(error);
    }
  };

class ResumerRedirectReactions extends ErrorActionableReaction<
  ResumerRedirectStore,
  ResumerRedirectState,
  Props
> {
  tester = (): ResumerRedirectState => this.props.store.state;

  effect = (state: ResumerRedirectState) => {
    switch (state.kind) {
      case 'waiting':
        Task.succeed<ResumerRedirectError, ReadonlyArray<Link>>(
          this.props.currentUserResource.links,
        )
          .andThen(findLinkT('resume'))
          .fork(handleResumerRedirectError(this.props.store), this.props.store.loading);
        break;
      case 'loading':
        Task.succeed<ResumerRedirectError, Link>(state.link)
          .andThen(resumerEndPoint)
          .fork(handleResumerRedirectError(this.props.store), this.props.store.ready);
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(ResumerRedirectReactions);
