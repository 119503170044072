import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../components/Modal/Store';
import { CurrentUserResource } from '../../CurrentUser/Types';
import NotSupportedInLanguageStore from '../../NotSupportedInLanguageStore';
import ProgramStore from '../../ProgramStore';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import StreamlinedOverviewContent from './StreamlinedOverviewContent';
import StreamlinedOverviewHeader from './StreamlinedOverviewHeader';

interface Props {
  modalStore: ModalStore;
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
  currentUserResource: CurrentUserResource;
}

const StreamlinedOverview: React.FC<Props> = ({
  modalStore,
  programDetailResource,
  store,
  currentUserResource,
}) => {
  const programNotSupportedInLanguageStore = new NotSupportedInLanguageStore(
    programDetailResource.payload.availableLanguages,
    currentUserResource
  );
  return (
    <>
      <StreamlinedOverviewHeader modalStore={modalStore} store={store} />
      <StreamlinedOverviewContent
        modalStore={modalStore}
        programDetailResource={programDetailResource}
        store={store}
        programNotSupportedInLanguageStore={programNotSupportedInLanguageStore}
      />
    </>
  );
};

export default observer(StreamlinedOverview);
