import * as React from 'react';
import { Link } from 'react-router-dom';
import { T, TPlainTextKey } from '../../../../Translations';
import * as style from './style.module.css';

interface Props {
  description: TPlainTextKey;
  href: string;
  children: {
    icon: JSX.Element;
  };
}

const CategoryButton: React.FC<Props> = ({ description, href, children }) => {
  return (
    <div className={style.categoryButton}>
      <Link className={style.content} to={href}>
        <div className={style.buttonIconBackground}>
          <div className={style.buttonIcon}>{children.icon}</div>
        </div>
        <p className={style.buttonText}>
          <T kind={description} />
        </p>
      </Link>
    </div>
  );
};

export default CategoryButton;
