import { toSeconds } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import { Connecting, Unavailable } from '../Types';
import { Typography } from '@execonline-inc/execonline-ui';

interface Props {
  socket: Connecting | Unavailable;
}

const UnavailableMessage: React.FC<Props> = ({ socket }) => (
  <>
    <Typography color="black">
      <T kind="Realtime connection not available." />
    </Typography>
    {socket.connectingIn
      .map((time) => (
        <Typography color="black">
          <T kind="Retrying in {{count}} second" count={toSeconds(time).seconds} />
        </Typography>
      ))
      .getOrElseValue(<span />)}
  </>
);

export default observer(UnavailableMessage);
