import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import CoachRequestStore from '../../Socket/BreakoutChannel/CoachRequestStore';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import CoachMessage from './CoachMessage';

interface Props {
  coachRequestStore: CoachRequestStore;
  staffRequestStatusStore: StaffRequestStore;
}

const RequestCoachButton: React.FC<{
  disabled: boolean;
  store: CoachRequestStore;
}> = ({ disabled, store }) => (
  <button
    className={style.requestCoachButton}
    data-test-request-coach={true}
    onClick={store.requestCoach}
    disabled={disabled}
  >
    <T kind="Request a Coach" />
  </button>
);

const CoachRequest: React.FC<Props> = ({ coachRequestStore, staffRequestStatusStore }) => {
  switch (coachRequestStore.state.kind) {
    case 'ready':
      return <RequestCoachButton disabled={false} store={coachRequestStore} />;
    case 'requested':
      return <RequestCoachButton disabled={true} store={coachRequestStore} />;
    case 'waiting':
      return <CoachMessage staffRequestStatusStore={staffRequestStatusStore} />;
    case 'error':
      return (
        <div className="error">
          <T kind="Your coach request could not be sent." />{' '}
          <a href="#" onClick={coachRequestStore.requestCoach}>
            <T kind="Try again." />
          </a>
        </div>
      );
    default:
      return assertNever(coachRequestStore.state);
  }
};

export default observer(CoachRequest);
