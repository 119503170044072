import * as React from 'react';
import SVGIcon from '../SVGIcon';

const ClientPortalGraphic: React.FC = () => {
  return (
    <SVGIcon width="49" height="43" viewBox="0 0 49 43">
      <g fill="none">
        <path
          d="M22.399 17.5882C22.4003 18.5606 22.1132 19.5115 21.5739 20.3207C21.0346 21.1299 20.2675 21.7609 19.3695 22.134C18.4715 22.507 17.483 22.6053 16.5292 22.4164C15.5753 22.2275 14.6989 21.76 14.0108 21.0729C13.3227 20.3857 12.854 19.51 12.6638 18.5564C12.4736 17.6027 12.5705 16.6142 12.9423 15.7156C13.3142 14.8171 13.9441 14.0491 14.7526 13.5087C15.561 12.9684 16.5115 12.6799 17.4839 12.6799C18.7858 12.6817 20.0338 13.1992 20.955 14.1191C21.8761 15.039 22.3954 16.2864 22.399 17.5882V17.5882Z"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4819 12.6799V17.5882H22.397"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.2896 37.2347C21.5614 35.6297 22.2987 33.6667 22.3979 31.6213"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.7104 37.2347C27.4386 35.6297 26.7013 33.6667 26.6021 31.6213"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.187 37.2349H30.8102"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.36523 27.4321H40.6351"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.2318 7.77167C39.604 7.77167 39.961 7.91952 40.2242 8.1827C40.4873 8.44587 40.6351 8.80282 40.6351 9.17501V30.225C40.6351 30.5972 40.4873 30.9541 40.2242 31.2173C39.961 31.4805 39.604 31.6284 39.2318 31.6284H9.76862C9.39643 31.6284 9.03946 31.4805 8.77628 31.2173C8.51311 30.9541 8.36523 30.5972 8.36523 30.225V9.16822C8.36523 8.79603 8.51311 8.43909 8.77628 8.17591C9.03946 7.91274 9.39643 7.76489 9.76862 7.76489L39.2318 7.77167Z"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6021 15.4871L28.7104 12.6804L32.9204 16.1854L36.4254 11.9753"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.0225 23.2019V21.1003"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.2158 23.2022V20.3955"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.4092 23.2019V18.9919"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6021 23.2019V21.1003"
          stroke="#187A90"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SVGIcon>
  );
};

export default ClientPortalGraphic;
