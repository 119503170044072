import { observer } from 'mobx-react';
import { Button, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { PaymentCheckoutFormStore } from '../../../Common';
import PaymentCardIcons from './PaymentCardIcons';

interface Props {
  store: PaymentCheckoutFormStore;
}

function PaymentCardSelect({ store }: Props) {
  return (
    <div className="flex w-full flex-col gap-y-3">
      <Typography variant="subtitleSmall" as="p">
        <T kind="How would you like to pay?" />
      </Typography>
      <Button
        className="justify-between"
        color="gray"
        size="md"
        variant="outlined"
        endContent={<PaymentCardIcons />}
        onClick={store.readyForPaymentConfirmation}
      >
        <T kind="Credit / Debit Card" />
      </Button>
    </div>
  );
}

export default observer(PaymentCardSelect);
