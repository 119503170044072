import { assertNever } from '@kofno/piper';
import { default as TeamDiscussionStore } from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { TeamDiscussionState } from './Types';

export interface Props extends EAProps<TeamDiscussionStore> {
  store: TeamDiscussionStore;
}

class TeamDiscussionReactions extends ErrorActionableReaction<
  TeamDiscussionStore,
  TeamDiscussionState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: TeamDiscussionState) => {
    const teamDiscussionStore = this.props.store;

    switch (state.kind) {
      case 'loading':
        teamDiscussionStore.ready();
        break;
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default TeamDiscussionReactions;
