import * as React from 'react';
import OrientationCommencementStore from '../UnstartedOrientation/OrientationCommencementStore';

import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';
import * as style from '../../style.module.css';

interface Props {
  orientationCommencementStore: OrientationCommencementStore;
  disabled: boolean;
}

const BeginButton: React.FC<Props> = ({ orientationCommencementStore, disabled }) => (
  <button
    className={style.continueButton}
    data-test-coaching-orientation-button={'Begin Your Experience'}
    onClick={orientationCommencementStore.creating}
    disabled={disabled}
  >
    <T kind={'Begin Your Experience'} />
    <div className={style.buttonIcon}>
      <ArrowRightIcon />
    </div>
  </button>
);

export default observer(BeginButton);
