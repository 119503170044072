import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import * as style from '../../../../ProgramSummary/style.module.css';

const CurrentUnlockedRemainderMessage = () => {
  return (
    <div className={style.onDemandProgressLine} data-current={true}>
      <div className={style.scheduledCohortProgressUnlockTooltip} data-test-progress-tooltip={true}>
        <span>
          <T kind="Expected progress" />
        </span>
        <div className={style.scheduledCohortProgressUnlockTooltipArrow} />
      </div>
    </div>
  );
};

export default observer(CurrentUnlockedRemainderMessage);
