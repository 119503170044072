import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import { ImageDimensions, State, error, loading, ready, waiting } from './Types';

class SizedImageStore {
  @observable
  state: State = waiting();

  @action
  loading = (uri: string): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = loading(uri);
        break;
      case 'loading':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (dimensions: ImageDimensions): void => {
    switch (this.state.kind) {
      case 'loading':
        this.state = ready(dimensions);
        break;
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: string): void => {
    switch (this.state.kind) {
      case 'loading':
        this.state = error(message);
        break;
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default SizedImageStore;
