import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { PaymentIntent } from '@stripe/stripe-js';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useEnrollmentModalContext } from '../../Common';
import {
  PaymentCheckoutFormReactions,
  PaymentCheckoutFormStore,
  PaymentOrderDetails,
} from '../Common';
import PaymentCheckoutForm from './PaymentCheckoutForm';
import PaymentCheckoutInitialization from './PaymentCheckoutInitialization';

interface Props {
  onSuccess: (data: PaymentIntent) => void;
}

function PaymentCheckoutModalBody({ onSuccess }: Props) {
  const { experienceResource, enrollmentStore } = useEnrollmentModalContext();
  const store: PaymentCheckoutFormStore = useMemo(() => new PaymentCheckoutFormStore(), []);

  switch (enrollmentStore.state.kind) {
    case 'waiting':
    case 'setting-up':
    case 'waiting-commerce':
    case 'payment-loading-error':
    case 'created':
    case 'creating':
    case 'error':
    case 'exiting':
    case 'server-error':
      return emptyFragment();
    case 'initializing-payment':
      return (
        <>
          <PaymentOrderDetails />
          <PaymentCheckoutInitialization />
        </>
      );
    case 'ready-for-payment': {
      const { stripe } = enrollmentStore.state;

      return (
        <>
          <PaymentOrderDetails />
          <PaymentCheckoutForm
            price={experienceResource.payload.price}
            store={store}
            stripe={stripe}
            paymentIntentResource={enrollmentStore.paymentIntentResource}
          />
          <PaymentCheckoutFormReactions store={store} onSuccess={onSuccess} />
        </>
      );
    }
  }
}

export default observer(PaymentCheckoutModalBody);
