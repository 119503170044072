import { observer } from 'mobx-react';
import * as React from 'react';
import CountryOptionsStore from '../../../CountryOptionsStore';
import CountryOptionsStoreReactions from '../../../CountryOptionsStore/CountryOptionsStoreReactions';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import CountryRegionOptionsStoreReactions from '../../../CountryRegionOptionsStore/CountryRegionOptionsStoreReactions';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import ProfileFormReactions from '../../../ProfileFormStore/Reactions';
import ProfileEditView from './View';

interface Props {
  currentUserResource: CurrentUserResource;
}

class ProfileEdit extends React.Component<Props> {
  private profileStore: ProfileFormStore = new ProfileFormStore(this.props.currentUserResource);
  private countryRegionOptionsStore: CountryRegionOptionsStore = new CountryRegionOptionsStore();
  private countryOptionsStore: CountryOptionsStore = new CountryOptionsStore();
  private fileUploadStore: FileUploadStore = new FileUploadStore();

  componentDidMount() {
    this.profileStore.loading();
  }

  render() {
    return (
      <>
        <ProfileEditView
          profileFormStore={this.profileStore}
          countryRegionOptionsStore={this.countryRegionOptionsStore}
          countryOptionsStore={this.countryOptionsStore}
          fileUploadStore={this.fileUploadStore}
        />
        <ProfileFormReactions
          store={this.profileStore}
          countryRegionOptionsStore={this.countryRegionOptionsStore}
          countryOptionsStore={this.countryOptionsStore}
          currentUserResource={this.props.currentUserResource}
        />
        <CountryRegionOptionsStoreReactions
          store={this.countryRegionOptionsStore}
          profileStore={this.profileStore}
        />
        <CountryOptionsStoreReactions
          store={this.countryOptionsStore}
          profileStore={this.profileStore}
        />
      </>
    );
  }
}

export default observer(ProfileEdit);
