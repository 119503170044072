import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import { detectBrowser } from '../../../UserAgent';
import LogoGraphic from '../../Cutesies/LogoGraphic';
import * as style from './style.module.css';

interface Props {
  pollUrl: string;
}

const Poll: React.FC<Props> = ({ pollUrl }) => {
  const browserDetected = detectBrowser(window.navigator.userAgent);

  switch (browserDetected.kind) {
    case 'other':
    case 'msie':
    case 'edge':
    case 'trident':
      return <iframe className={style.iframe} src={pollUrl} />;
    case 'safari':
    case 'electron':
      return (
        <div className={style.unsupported}>
          <div className={style.logoWrapper}>
            <LogoGraphic />
          </div>
          <p>
            <T kind="Looks like your browser doesn't support ..." />
          </p>
          <a target="_blank" rel="noopener noreferrer" href={pollUrl}>
            <button className={style.openPollBtn}>
              <T kind="Open Poll" />
            </button>
          </a>
        </div>
      );
    default:
      return assertNever(browserDetected);
  }
};

export default observer(Poll);
