import {
  Button,
  ButtonProps,
  MagnifyingGlassIcon,
  useMediaQuery,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';

function SearchBoxButton({ disabled }: ButtonProps) {
  const breakPoint = useMediaQuery();

  const buttonProps: ButtonProps = {
    type: 'submit',
    disabled,
  };

  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
      return (
        <Button {...buttonProps}>
          <T kind="Search" />
        </Button>
      );
    case 'sm':
    case 'xs':
      return (
        <Button
          className="px-2"
          startContent={<MagnifyingGlassIcon color="white" size="md" />}
          {...buttonProps}
        />
      );
  }
}

export default observer(SearchBoxButton);
