import { Maybe } from 'maybeasy';
import { SurveyCompletionTrackingResource, TopicViewStatus } from '../../../ReqHlsVideo/Types';

export interface Waiting {
  kind: 'waiting';
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

export interface NeverShowSurvey {
  kind: 'never-show-survey';
}

export interface SurveyDismissed {
  kind: 'survey-dismissed';
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

export interface ShowingSurvey {
  kind: 'showing-survey';
  surveyTrackingResource: SurveyCompletionTrackingResource;
  topicViewStatus: Maybe<TopicViewStatus>;
}

export interface SurveyClosedAndComplete {
  kind: 'survey-closed-and-complete';
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

export interface SurveyClosedAndDismiss {
  kind: 'survey-closed-and-dismissed';
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

export interface SurveyUndoDismiss {
  kind: 'survey-undo-dismiss';
  surveyTrackingResource: SurveyCompletionTrackingResource;
}

export const neverShowSurvey = (): NeverShowSurvey => ({ kind: 'never-show-survey' });

export const waiting = (surveyTrackingResource: SurveyCompletionTrackingResource): Waiting => ({
  kind: 'waiting',
  surveyTrackingResource,
});

export const showingSurvey = (
  surveyTrackingResource: SurveyCompletionTrackingResource,
  topicViewStatus: Maybe<TopicViewStatus>
): ShowingSurvey => ({ kind: 'showing-survey', surveyTrackingResource, topicViewStatus });

export const surveyClosedAndComplete = (
  surveyTrackingResource: SurveyCompletionTrackingResource
): SurveyClosedAndComplete => ({
  kind: 'survey-closed-and-complete',
  surveyTrackingResource,
});

export const surveyClosedAndDismiss = (
  surveyTrackingResource: SurveyCompletionTrackingResource
): SurveyClosedAndDismiss => ({
  kind: 'survey-closed-and-dismissed',
  surveyTrackingResource,
});

export const surveyUndoDismiss = (
  surveyTrackingResource: SurveyCompletionTrackingResource
): SurveyUndoDismiss => ({ kind: 'survey-undo-dismiss', surveyTrackingResource });

export const surveyDismissed = (
  surveyTrackingResource: SurveyCompletionTrackingResource
): SurveyDismissed => ({ kind: 'survey-dismissed', surveyTrackingResource });

export type State =
  | NeverShowSurvey
  | Waiting
  | ShowingSurvey
  | SurveyClosedAndComplete
  | SurveyClosedAndDismiss
  | SurveyUndoDismiss
  | SurveyDismissed;
