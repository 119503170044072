import { Task } from 'taskarian';
import { errorMessage } from '../../ExceptionHandling';
import {
  ConjointResults,
  ConjointResultsInitializationError,
  ConjointResultsLoadError,
} from './Types';

declare global {
  interface Window {
    conjointSurvey?: ConjointResults;
  }
}

export const loadConjointResults = (
  url: string,
): Task<ConjointResultsInitializationError, Window> => {
  return new Task<ConjointResultsLoadError, Window>((reject, resolve) => {
    try {
      const script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      if (document.head) {
        document.head.appendChild(script);
        resolve(window);
      }
    } catch (e) {
      reject({ kind: 'conjoint-results-load-error', message: errorMessage(e) });
    }

    return () => {
      console.warn();
      ('Loading Conjoint Results cannot be canceled');
    };
  });
};
