import { nullableBlankString, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, boolean, field, number, oneOf, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { resourceDecoder } from '../../../Resource/Decoders';
import { videoAssetResourceDecoder } from '../../../VideoStore/Legacy/Decoders';
import {
  Document,
  DocumentResource,
  ResourceResource,
  Resources,
  ResourcesListResource,
  ResourcesResource,
  Video,
  VideoResource,
} from './Types';

const videoDecoder: Decoder<Video> = succeed({})
  .assign('title', field('title', string))
  .assign('videoAssetResource', field('video_asset_resource', videoAssetResourceDecoder))
  .assign('kind', field('kind', stringLiteral('video')))
  .assign('id', field('id', number))
  .assign('important', field('important', boolean))
  .assign('assetFileExtension', field('asset_file_extension', nullableBlankString))
  .assign('description', field('description', nullableBlankString))
  .assign('programId', field('program_id', number));

const videoResourceDecoder: Decoder<VideoResource> = resourceDecoder(videoDecoder);

const documentDecoder: Decoder<Document> = succeed({})
  .assign('title', field('title', string))
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('assetFileExtension', field('asset_file_extension', nullableBlankString))
  .assign('description', field('description', nullableBlankString));

const documentResourceDecoder: Decoder<DocumentResource> = resourceDecoder(documentDecoder);

const resourceItemResourceDecoder: Decoder<ResourceResource> = oneOf<ResourceResource>([
  videoResourceDecoder.map<ResourceResource>(identity),
  documentResourceDecoder.map<ResourceResource>(identity),
]);

const resourcesDecoder: Decoder<Resources> = succeed({})
  .assign('id', field('id', number))
  .assign('resources', field('resources', array(resourceItemResourceDecoder).map(fromArrayMaybe)))
  .assign('title', field('title', string));

export const resourcesResourceDecoder: Decoder<ResourcesResource> = resourceDecoder(
  resourcesDecoder
);

export const resourcesListResourceDecoder: Decoder<ResourcesListResource> = resourceDecoder(
  array(resourcesResourceDecoder)
);
