import { NamedChannel } from '@execonline-inc/broadcast-channel.private';
import { equals, find } from '@execonline-inc/collections';
import { asMaybe } from '@execonline-inc/error-handling';
import { when } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { noop, pick } from '@kofno/piper';
import { useAtom } from '@xoid/react';
import { Maybe, fromNullable, just } from 'maybeasy';
import { useEffect } from 'react';
import { createIframe, toUrl, unauthorizedMessageOrigin } from './Core';
import { appDecoder, messageDecoder } from './Decoders';
import { App, Atom, Config, Message, State } from './Types';

export const useMessageBus = (sender: string, atom: Atom, config: Config) => (): State => {
  const state = useAtom(atom);
  const portalHost = useConst(() => config.portalHost.andThen(toUrl));
  const portal = useCreatePortal(config, atom, portalHost);
  useMessageDispatching(atom, portalHost);
  useMessageHandling(sender, config, portal, portalHost);
  return state;
};

export const useCreatePortal = (
  config: Config,
  state: Atom,
  portalHost: Maybe<URL>,
): HTMLIFrameElement => {
  const iframe = useConst(() => createIframe(config, state, portalHost));

  useEffect(() => {
    document.body.appendChild(iframe);
    return () => {
      document.body.removeChild(iframe);
    };
  }, []);

  return iframe;
};

export const useMessageDispatching = (state: Atom, portalHost: Maybe<URL>) =>
  useEffect((): void => {
    just({})
      .assign('socket', state.value.socket)
      .assign('targetOrigin', portalHost.map(pick('origin')))
      .assign(
        'message',
        /**
         * The array is mutated here to prevent a possible race condition.
         * This must be done within a function so that it isn't mutated if the socket isn't
         * yet available.
         */
        () => fromNullable(state.value.queuedMessages.shift()),
      )
      .cata({
        Nothing: noop,
        Just: ({ message, socket, targetOrigin }) => {
          socket.postMessage(message, { targetOrigin });

          /**
           * We shifted the message off already, so we just need to trigger the state change by
           * re-setting the array as-is.
           */
          state.focus(pick('queuedMessages')).update((messages) => [...messages]);
        },
      });
  }, [state.value.socket, state.value.queuedMessages]);

export const useMessageHandling = (
  sender: string,
  config: Config,
  portal: HTMLIFrameElement,
  portalHost: Maybe<URL>,
) =>
  useEffect(() => {
    const handler = ({ data, source, origin }: MessageEvent): void => {
      /**
       * Verify that the message is coming from the message bus portal.
       */
      if (source !== portal.contentWindow) return;
      if (unauthorizedMessageOrigin(origin, portalHost)) return;

      messageDecoder.decodeAny(data).cata({
        Err: (e) => console.warn('Invalid message received by message bus:', e),
        Ok: (message) => {
          if (message.sender === sender) return;
          if (!message.recipients.includes(config.whoami)) return;

          config.onMessage(message.payload);
        },
      });
    };

    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

export const useMessageBusHost = (urls: { [app in App]: Maybe<string> }) =>
  useEffect(() => {
    const hosting = just({})
      .assign('app', () =>
        asMaybe(appDecoder.decodeAny(new URLSearchParams(window.location.search).get('for'))),
      )
      .assign('origin', ({ app }) => urls[app].andThen(toUrl).map(pick('origin')));

    const isBusMessage = (data: any): data is { kind: 'MessageBus.Message' } =>
      typeof data === 'object' && data.kind === 'MessageBus.Message';

    const windowHasParent = (): boolean =>
      fromNullable(window.parent)
        .andThen(when(window.parent !== window))
        .isJust();

    const whenApplicableRecipient = (message: Message): typeof hosting =>
      hosting.assign('recipient', ({ app }) => find(equals(app), message.recipients));

    const isApplicableOrigin = (suspectOrigin: string): boolean =>
      hosting.andThen(({ origin }) => when(suspectOrigin === origin, origin)).isJust();

    const relayMessageToHostingApp = (message: Message): void =>
      whenApplicableRecipient(message).cata({
        Nothing: noop,
        Just: ({ origin }) => {
          window.parent.postMessage(message, { targetOrigin: origin });
        },
      });

    const relayMessage = (message: Message): void => {
      relayMessageToHostingApp(message);
      relayMessageToOtherHosts(message);
    };

    const channel = new NamedChannel<Message>('MessageBus.RelayChannel', relayMessageToHostingApp);

    const relayMessageToOtherHosts = channel.postMessage;

    const handler = ({ data, origin }: MessageEvent): void => {
      if (!windowHasParent()) return;
      if (!isApplicableOrigin(origin)) return;
      if (!isBusMessage(data)) return;

      messageDecoder.decodeAny(data).cata({
        Err: (e) => console.warn('Message bus received an invalid message:', e),
        Ok: relayMessage,
      });
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
      channel.close();
    };
  }, []);
