import { MissingVarError } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { warnAndNotify } from '../../../Honeybadger';

export interface FSLoadError {
  kind: 'fs-load-error';
  key: string;
}

export interface MissingValue {
  kind: 'missing-value';
  value: string;
}

export type IdentifyToFsError = MissingVarError | MissingValue;

export type FSInitializationError = MissingVarError | FSLoadError;

export const fsLoadError = (key: string): FSLoadError => ({
  kind: 'fs-load-error',
  key,
});

export const missingValue = (value: string): MissingValue => ({
  kind: 'missing-value',
  value,
});

export const handleProgramUpdateError = (title: string) => (error: IdentifyToFsError): void => {
  switch (error.kind) {
    case 'missing-var-error':
      warnAndNotify('FullStoryTooling', "FullStory isn't configured properly.", title);
      break;
    case 'missing-value':
      warn(`FullStory cannot initialize without value for ${error.value}`);
      break;
    default:
      assertNever(error);
  }
};
