import * as React from 'react';
import { Link, Route } from 'react-router-dom';
import { Link as CurrentUserLinks } from '../../Resource/Types';
import { TeamResource } from '../../TeamsStore/Types';
import BreadCrumbSeparatorIcon from '../Cutesies/BreadCrumbSeparatorIcon';
import TeamLabelBreadCrumb from '../TeamMembersList/TeamLabelBreadCrumb';
import * as style from './style.module.css';

interface Props {
  teamResource: TeamResource;
  links: ReadonlyArray<CurrentUserLinks>;
}

const TeamBreadCrumb: React.FC<Props> = ({ teamResource, links }) => (
  <h1 className={style.breadcrumb} data-test-page-header="Epcot Squad">
    <Route
      exact={true}
      path="/teams/:teamId"
      render={() => {
        return (
          <>
            <Link to="/communities" data-test-communities-link="Communities">
              <TeamLabelBreadCrumb links={links} />
            </Link>
            <span className={style.breadcrumbSeparator}>
              <BreadCrumbSeparatorIcon />
            </span>
          </>
        );
      }}
    />
    <span data-test-team-name={teamResource.payload.name}>{teamResource.payload.name}</span>
  </h1>
);

export default TeamBreadCrumb;
