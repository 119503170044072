import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import EnrollmentStore from '../../Enrollment/Store';
import { PromotableExperiences } from '../PromotableExperiences';
import PromotableProductCollections from '../PromotableProductCollections';
import { PromotableGroupResource } from '../Types';

interface Props {
  data: PromotableGroupResource;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotableGroupView({ data, invitationUuid, enrollmentStore }: Props) {
  const { programFamilies, productCollections } = data.payload;

  return (
    <>
      {programFamilies
        .map((data) => (
          <PromotableExperiences
            data={data}
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
          />
        ))
        .getOrElse(emptyFragment)}
      {productCollections
        .map((data) => (
          <PromotableProductCollections
            data={data}
            invitationUuid={invitationUuid}
            enrollmentStore={enrollmentStore}
          />
        ))
        .getOrElse(emptyFragment)}
    </>
  );
}

export default observer(PromotableGroupView);
