import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunitiesResource } from '../../CommunitiesStore/Types';
import EmptyState from './EmptyState';
import CommunitiesContent from './CommunitiesContent';

interface Props {
  communitiesStore: CommunitiesStore;
}

const whenCommunitiesResourceLength = (communitiesResource: CommunitiesResource): boolean => {
  return communitiesResource.payload.length > 0;
};

const CommunityGroup: React.FC<Props> = ({ communitiesStore }) =>
  communitiesStore.communitiesResource
    .andThen((communitiesResource) =>
      when(whenCommunitiesResourceLength(communitiesResource), communitiesResource)
    )
    .map((communitiesResource) => (
      <CommunitiesContent
        communitiesStore={communitiesStore}
        communitiesResource={communitiesResource.payload}
      />
    ))
    .getOrElse(() => <EmptyState />);

export default observer(CommunityGroup);
