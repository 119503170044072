import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import * as style from '../style.module.css';
import Facebook from './Facebook';
import LinkedIn from './LinkedIn';
import Twitter from './Twitter';

interface Props {
  store: PersonStoreContract;
}

const Social: React.FC<Props> = ({ store }) => {
  const { linkedInPage, facebookPage, twitterPage } = store;
  return (
    <div className={style.social} data-test-popout-social={true}>
      <LinkedIn linkedInPage={linkedInPage} />
      <Facebook facebookPage={facebookPage} />
      <Twitter twitterPage={twitterPage} />
    </div>
  );
};

export default observer(Social);
