import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const HelpIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 24 24">
      <path
        fill={defaultTheme.variables['icon-accent-on-dark']}
        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0Zm0 2c5.535 0 10 4.465 10 10s-4.465 10-10 10S2 17.535 2 12 6.465 2 12 2Zm0 4c-2.2 0-4 1.8-4 4h2c0-1.117.883-2 2-2s2 .883 2 2a1.78 1.78 0 0 1-1.219 1.688l-.406.124A2.02 2.02 0 0 0 11 13.72V15h2v-1.281l.406-.125A3.807 3.807 0 0 0 16 10c0-2.2-1.8-4-4-4Zm-1 10v2h2v-2h-2Z"
      />
    </SVGIcon>
  );
};

export default observer(HelpIcon);
