import { FocusTarget } from 'focus-trap';
import FocusTrap from 'focus-trap-react';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';

interface ModalFocusTrapProps {
  children: React.ReactNode;
  fallbackFocus?: FocusTarget;
}

const Children = forwardRef(({ children }: ModalFocusTrapProps, ref: unknown) => <>{children}</>);

function ModalFocusTrap({ children, fallbackFocus }: ModalFocusTrapProps) {
  return (
    <FocusTrap
      focusTrapOptions={{
        escapeDeactivates: false,
        fallbackFocus: fallbackFocus || '#modal-container',
        allowOutsideClick: true,
      }}
    >
      <Children children={children} />
    </FocusTrap>
  );
}

export default observer(ModalFocusTrap);
