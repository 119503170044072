import * as React from 'react';
import { ActiveProgramModule } from '../../../ProgramStore/Types';
import OutlineSegment from '../OutlineSegment';
import * as style from '../style.module.css';

interface Props {
  programModule: ActiveProgramModule;
}

const OutlineSegments: React.FC<Props> = ({ programModule }) => {
  return (
    <li className={style.segments} data-test-program-segments={programModule.id}>
      <ol>
        {programModule.segments.map((segment) => {
          return <OutlineSegment key={segment.id} segment={segment} />;
        })}
      </ol>
    </li>
  );
};
export default OutlineSegments;
