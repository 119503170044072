import { observer } from 'mobx-react';
import React from 'react';
import { CurrentUserResource } from '../../../../../../CurrentUser/Types';
import HeroBody from '../Body';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';
import { AlreadyTranslated } from '@execonline-inc/translations';
import * as style from './../../style.module.css';
import { bgImageStyle } from '../../../ImageStyles';

interface Props {
  currentUserResource: CurrentUserResource;
  externalProgramResource: ExternalProgramResource;
}

class Hero extends React.Component<Props> {
  render() {
    const { externalProgramResource } = this.props;
    return (
      <div className="relative object-cover text-white">
        <div
          className="bg-slate-800 px-5 py-6"
          style={bgImageStyle(externalProgramResource, 'center')}
        >
          <div className={style.heroTitle}>
            <AlreadyTranslated content={externalProgramResource.payload.title} />
          </div>
          <div className="max-w-screen-xl w-full mx-auto">
            <HeroBody externalProgramResource={externalProgramResource} />
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Hero);
