import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const PhoneIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 13">
      <path
        fill={defaultTheme.variables['icon-accent-on-dark']}
        d="M2.836 0c-.261 0-.518.094-.733.265l-.03.016-.016.016-1.56 1.607.016.016a1.567 1.567 0 0 0-.421 1.686c.002.004-.002.011 0 .015.423 1.212 1.505 3.551 3.617 5.666 2.12 2.123 4.489 3.163 5.66 3.622h.016a1.79 1.79 0 0 0 1.73-.344l1.575-1.576a1.118 1.118 0 0 0 0-1.546l-2.027-2.029-.015-.03c-.414-.415-1.146-.415-1.56 0l-.997.998a8.07 8.07 0 0 1-2.043-1.405C5.231 6.197 4.814 5.3 4.66 4.948l.998-.999a1.067 1.067 0 0 0-.015-1.53l.015-.015-.047-.047L3.616.297 3.6.28 3.57.265A1.175 1.175 0 0 0 2.836 0Zm-.004 1c.037 0 .074.018.109.047L4.926 3.09l.046.047c-.003-.004.03.049-.03.109L3.7 4.494l-.233.218.109.312s.57 1.534 1.768 2.683l.108.093c1.154 1.06 2.513 1.638 2.513 1.638l.31.14 1.473-1.481c.086-.086.07-.086.155 0l2.032 2.043c.085.086.085.055 0 .14l-1.52 1.529c-.229.197-.47.238-.76.14-1.124-.444-3.309-1.41-5.257-3.369-1.964-1.975-2.99-4.215-3.366-5.303-.075-.203-.021-.503.155-.655l.031-.031 1.505-1.544A.173.173 0 0 1 2.832 1Z"
      />
    </SVGIcon>
  );
};

export default observer(PhoneIcon);
