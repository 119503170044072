import { observer } from 'mobx-react';
import { equals } from '@execonline-inc/collections';
import { useDeviceDetect } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { useExperienceContext } from '../../Experience/ExperienceContext';
import { groupCoachingExperience } from '../../Experience/Types';
import GroupCoachingActions from '../GroupCoachingActions';
import ProgramActions from '../ProgramActions';

interface Props {
  children: (onClick: () => void) => React.ReactNode;
}

function ExperienceProductDetails({ children }: Props) {
  const { experienceResource } = useExperienceContext();
  const device = useDeviceDetect();

  return groupCoachingExperience(experienceResource.payload)
    .andThen((productDetails) => when(equals('desktop', device), productDetails))
    .map((productDetails) => (
      <GroupCoachingActions productDetails={productDetails}>{children}</GroupCoachingActions>
    ))
    .getOrElse(() => <ProgramActions>{children}</ProgramActions>);
}

export default observer(ExperienceProductDetails);
