import { observer } from 'mobx-react';
import * as React from 'react';
import { ConferenceRoomResource } from '../../Conference/Types';
import { findLink } from '../../LinkyLinky';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import CoachRequestReactions from '../../Socket/BreakoutChannel/CoachRequestStore/CoachRequestReactions';
import * as style from '../Conference/style.module.css';
import CoachRequest from './CoachRequest';
import ReturnToMainButton from './ReturnToMainButton';
import BreakoutTimeRemaining from './BreakoutTimeRemaining';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';

interface Props {
  breakoutStore: BreakoutChannelStore;
  eventId: number;
  conferenceRoomResource: ConferenceRoomResource;
  staffRequestStore: StaffRequestStore;
}

const ReturnActionImpl: React.FC<Props> = ({
  breakoutStore,
  eventId,
  conferenceRoomResource,
  staffRequestStore,
}) => {
  switch (breakoutStore.timeRemainingStore.state.kind) {
    case 'waiting':
    case 'active':
      return findLink('request-coach', conferenceRoomResource.links)
        .map((l) => (
          <div className={style.actions}>
            <CoachRequest
              coachRequestStore={breakoutStore.coachRequestStore}
              staffRequestStatusStore={staffRequestStore}
            />
            <CoachRequestReactions store={breakoutStore.coachRequestStore} requestCoachLink={l} />
            <BreakoutTimeRemaining breakoutTimeRemainingStore={breakoutStore.timeRemainingStore} />
          </div>
        ))
        .orElse(() =>
          findLink('dashboard', conferenceRoomResource.links).map(() => (
            <ReturnToMainButton eventId={eventId} />
          ))
        )
        .getOrElse(() => <></>);
    case 'expiring':
      return (
        <div className={`${style.actions} ${style.expiring} `}>
          <ReturnToMainButton eventId={eventId} />
          <BreakoutTimeRemaining breakoutTimeRemainingStore={breakoutStore.timeRemainingStore} />
        </div>
      );
    case 'expired':
      return (
        <div className={style.actions}>
          <ReturnToMainButton eventId={eventId} />
          <BreakoutTimeRemaining breakoutTimeRemainingStore={breakoutStore.timeRemainingStore} />
        </div>
      );
  }
};

export default observer(ReturnActionImpl);
