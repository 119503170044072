import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import ModulesCompleted from './ModulesCompleted';
import SegmentsCompleted from './SegmentsCompleted';

interface Props {
  programStore: ProgramStore;
}

const ProgressMessage: React.FC<Props> = ({ programStore }) => {
  return programStore.progress
    .map((progress) => {
      switch (progress.kind) {
        case 'program-progress':
        case 'module-progress':
          return programStore.modulesCompletedCount
            .map((completedCount) => (
              <ModulesCompleted
                completedCount={completedCount}
                remainingCount={programStore.modulesRemainingCount.getOrElseValue(0)}
              />
            ))
            .getOrElseValue(<></>);
        case 'registration-segment-progress':
          return (
            <SegmentsCompleted
              completedCount={progress.completedSegments}
              remainingCount={progress.totalSegments - progress.completedSegments}
            />
          );
      }
    })
    .getOrElseValue(<></>);
};

export default observer(ProgressMessage);
