import { observer } from 'mobx-react';
import { useContext } from 'react';
import { NotifiableStore } from '../ErrorActionableReaction';
import { NotificationsContext } from '../Notifications/NotificationsContext';
import MountNotifier from './MountNotifier';

interface Props {
  store: NotifiableStore;
}

function NotificationSource({ store }: Props) {
  const notificationsStore = useContext(NotificationsContext);

  return <MountNotifier notifiableStore={store} notificationsStore={notificationsStore} />;
}

export default observer(NotificationSource);
