import * as React from 'react';
import {
  T,
  TPlainTextKey,
  translation,
  TranslationsContext,
  TranslationsState,
} from '../../../Translations';
import * as style from './style.module.css';

type ButtonType = 'submit' | 'reset' | 'button';

interface Props {
  type: ButtonType;
  value: TPlainTextKey;
  name?: TPlainTextKey;
  className?: string;
  onClick(event: React.FormEvent<EventTarget>): void;
  dataTestDeleteButton?: boolean;
  dataTestSaveButton?: boolean;
  id?: string;
  disabled?: boolean;
}

const buttonImpl = (props: Props) => (ts: TranslationsState) => (
  <button
    type={props.type}
    name={props.name ? translation(props.name)(ts) : undefined}
    className={props.className ? props.className : style.button}
    data-test-delete-button={props.dataTestDeleteButton}
    data-test-save-button={props.dataTestSaveButton}
    data-test-button={props.value}
    id={props.id}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    <T kind={props.value} />
  </button>
);

class Button extends React.PureComponent<Props> {
  render() {
    return <TranslationsContext.Consumer>{buttonImpl(this.props)}</TranslationsContext.Consumer>;
  }
}

export default Button;
