import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import DateCheckIcon from '../Cutesies/DateCheckIcon';
import FreebusyStore from '../../FreebusyStore';
import * as style from './style.module.css';

interface Props {
  freebusyStore: FreebusyStore;
}

class CoachingReadyButton extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.freebusyStore.loading();
  }

  render() {
    return (
      <div className={style.ready}>
        <p>
          <T kind="Click the “Choose Day and Time” button ..." />
        </p>
        <div className={style.buttonContainer}>
          <button id="freebusy-pickatime-button" className={style.button}>
            <T
              kind="Choose <fragment>Day</fragment> and Time"
              fragment={(content) => <span className={style.desktopOnly}>{content}</span>}
            />
            <div className={style.buttonIcon}>
              <DateCheckIcon />
            </div>
          </button>
        </div>
        <div className={style.notice}>
          <p>
            <T
              kind="Questions? Email us at <emailAddress/>."
              emailAddress={
                <a className={style.mailToLink} href="mailto:coaching@execonline.com">
                  coaching@execonline.com
                </a>
              }
            />
          </p>
          <p>
            <T kind="If you need to modify or cancel the time of the session, we ..." />
          </p>
        </div>
      </div>
    );
  }
}

export default observer(CoachingReadyButton);
