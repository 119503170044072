import { toResult } from '@execonline-inc/maybe-adapter';
import { identity, pipe } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import { fromArray } from 'nonempty-list';
import { Result, err, ok } from 'resulty';
import { Listener } from '.';
import {
  ClickState,
  InsideClick,
  InvalidTarget,
  ListenerRef,
  NoElement,
  NoListener,
  OutsideClick,
  ValidEvent,
} from './Types';

const invalidTarget = (): InvalidTarget => ({ kind: 'invalid-target' });

const noListener = (): NoListener => ({ kind: 'no-listener' });

const noElement = (): NoElement => ({ kind: 'no-element' });

const insideClick = (): InsideClick => ({ kind: 'inside-click' });

const outsideClick = (listener: Listener): OutsideClick => ({ kind: 'outside-click', listener });

const fromNullableR = pipe(fromNullable, toResult('Value was null or undefined'));

const firstListener = (listeners: Listener[]): Result<ClickState, Listener> =>
  fromArray(listeners)
    .map(({ first }) => first)
    .andThen(fromNullableR)
    .mapError(noListener);

const listenerRef = (listener: Listener): Result<ClickState, ListenerRef> =>
  fromNullableR(listener.ref.current)
    .mapError(noElement)
    .map((element) => ({ listener, element }));

const validateTarget =
  (event: MouseEvent | TouchEvent) =>
  (listenerRef: ListenerRef): Result<ClickState, ValidEvent> =>
    fromNullableR(event.target)
      .andThen<ValidEvent>((target) =>
        target instanceof Node ? ok({ ...listenerRef, target }) : err('Invalid Target')
      )
      .mapError(invalidTarget);

const determineClickBounds = ({ element, target, listener }: ValidEvent): ClickState =>
  element.contains(target) ? insideClick() : outsideClick(listener);

const clickState = (listeners: Listener[], e: MouseEvent | TouchEvent): ClickState =>
  firstListener(listeners).andThen(listenerRef).andThen(validateTarget(e)).cata({
    Err: identity,
    Ok: determineClickBounds,
  });

export default clickState;
