import { just, Maybe, nothing } from 'maybeasy';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { resourceRoute, segmentResourceRoute } from '../../../ClientRoutes';
import { NotTranslated } from '../../../Translations';
import PlayIcon from '../../Cutesies/PlayIcon';
import VideoIcon from '../../Cutesies/VideoIcon';
import ImportantIcon from '../ImportantIcon';
import { Video } from '../Types';
import * as style from './style.module.css';

interface Props {
  video: Video;
}

const TopLevelVideoResource: React.FC<Props> = ({ video }) => {
  const whenSegmentResourceParent = (video: Video): Maybe<Video> =>
    video.segmentId ? just(video) : nothing();

  const resourceRouting = (video: Video) =>
    whenSegmentResourceParent(video)
      .map(() => ({}))
      .assign('moduleId', video.moduleId)
      .assign('segmentId', video.segmentId)
      .map(({ moduleId, segmentId }) =>
        segmentResourceRoute(video.programId, moduleId, segmentId, video.id),
      )
      .getOrElse(() => resourceRoute(video.programId, video.id));

  return (
    <div className={style.resource} data-test-video-resource={true}>
      <Link
        to={resourceRouting(video)}
        data-test-resource-link={true}
        target="_blank"
        className={style.content}
      >
        <div className={style.icon}>
          <VideoIcon />
        </div>
        <div className={style.details}>
          <span
            className={style.name}
            data-test-video-resource-title={true}
            data-testid={video.title}
          >
            <ImportantIcon important={video.important} className={style.importantIcon} />
            <NotTranslated text={video.title} />
          </span>
          {video.description
            .map((description) => (
              <span className={style.description}>{ReactHtmlParser(description)}</span>
            ))
            .getOrElseValue(<></>)}
        </div>
        <div className={style.rightSideWrapper}>
          <span className={style.fileExtension}>
            {video.assetFileExtension
              .map((ext) => <NotTranslated text={ext} />)
              .getOrElseValue(<></>)}
          </span>
          <div className={style.play}>
            <div className={style.playIcon}>
              <PlayIcon />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TopLevelVideoResource;
