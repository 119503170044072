import { v4 as uuid } from 'uuid';
import * as xoid from 'xoid';
import { actions, initialState } from './Core';
import { useMessageBus } from './Hooks';
import { Actions, Config, State } from './Types';

export { useMessageBusHost } from './Hooks';

export const initializeMessageBus = (config: Config) => {
  const sender = uuid();
  const atom = xoid.atom<State, Actions>(initialState, actions(sender));

  return {
    MessageBus: atom.actions,
    config,
    useMessageBus: useMessageBus(sender, atom, config),
  } as const;
};
