import React from 'react';
import { Maybe } from 'maybeasy';
import EnrollmentStore from '../../../../DiscoveryPortal/Enrollment/Store';
import { AvailabilityResource, ExperienceResource } from '../../../Experience/Types';

export type EnrollmentModalContextOptions = {
  programFooterButtons: Maybe<JSX.Element>;
  scheduledExperienceOptions: Maybe<JSX.Element>;
};

export type EnrollmentModalContextState = {
  invitationUuid: string;
  availability: AvailabilityResource;
  experienceResource: ExperienceResource;
  enrollmentStore: EnrollmentStore;
  options: EnrollmentModalContextOptions;
  onOpen: () => void;
  onClose: () => void;
};

export const EnrollmentModalContext = React.createContext<EnrollmentModalContextState | null>(null);
