import * as React from 'react';
import ProgramMembersStore from '../../ProgramMembersStore';
import { T } from '../../Translations';
import ArrowDownIcon from '../Cutesies/ArrowDownIcon';
import * as style from '../ProgramProfile/style.module.css';

interface Props {
  programMembersStore: ProgramMembersStore;
}

const LoadMoreParticipantsButton: React.FC<Props> = ({ programMembersStore }) => {
  return (
    <button className={style.loadMoreParticipantsButton} onClick={programMembersStore.loadingMore}>
      <T kind="Load more participants" />
      <div className={style.participantButtonIcon}>
        <ArrowDownIcon />
      </div>
    </button>
  );
};

export default LoadMoreParticipantsButton;
