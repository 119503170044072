import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import TeamsRenderer from './TeamsRenderer';

interface Props {
  programDetailResource: ProgramDetailResource;
}

class TeamsSummary extends React.Component<Props> {
  render() {
    const { programDetailResource } = this.props;
    return <TeamsRenderer programDetailResource={programDetailResource} />;
  }
}

export default observer(TeamsSummary);
