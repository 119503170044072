import * as React from 'react';

import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';
import * as style from '../../style.module.css';
import ModalStore from '../../../../Modal/Store';

interface Props {
  modalStore: ModalStore;
}

const ResumeButton: React.FC<Props> = ({ modalStore }) => (
  <button
    className={style.continueButton}
    data-test-coaching-orientation-button={'Resume Your Experience'}
    onClick={modalStore.open}
  >
    <T kind={'Resume Your Experience'} />
    <div className={style.buttonIcon}>
      <ArrowRightIcon />
    </div>
  </button>
);

export default observer(ResumeButton);
