import { observer } from 'mobx-react';
import * as React from 'react';
import ErrorMessage from './Messaging/ErrorMessage';
import LoadingMessage from './Messaging/LoadingMessage';
import { UnifiedLoginStore } from './Store';

interface Props {
  store: UnifiedLoginStore;
}

const UnifiedLoginView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'identifying-app':
    case 'redirecting':
      return <LoadingMessage message="Signing you in..." />;
    case 'errored':
      return <ErrorMessage error={store.state.error} />;
  }
};

export default observer(UnifiedLoginView);
