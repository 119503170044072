import { assertNever } from '@kofno/piper';
import { State } from '../../CurrentUser/States';
import { CurrentUserStore } from '../../CurrentUser/Store';
import { Honeybadger } from '../../Honeybadger';
import ReactionComponent, { RCProps } from '../../ReactionComponent';

const setCurrentHoneybadgerUser = (user_id: number): void => {
  Honeybadger.setContext({ user_id });
};

const clearCurrentHoneybadgerUser = (): void => {
  Honeybadger.setContext({ user_id: null });
};

interface Props extends RCProps<CurrentUserStore> {}

class CurrentHoneybadgerUserReactions extends ReactionComponent<CurrentUserStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'ready':
      case 'refreshing':
        setCurrentHoneybadgerUser(state.currentUserResource.payload.id);
        break;
      case 'waiting':
      case 'loading':
      case 'logging-out':
      case 'anonymous':
      case 'errored':
        clearCurrentHoneybadgerUser();
        break;
      default:
        assertNever(state);
    }
  };
}

export default CurrentHoneybadgerUserReactions;
