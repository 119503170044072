import { emptyFragment } from '@execonline-inc/execonline-ui';
import { whenGt } from '@execonline-inc/numbers';
import { observer } from 'mobx-react';
import ProgressBar from '../components/ProgressBar';
import useProgramModuleProgressContext from './hooks/useProgramModuleProgressContext';
import { ProgramModuleProgressResource } from './Types';

function ProgramModuleProgress() {
  const progress = useProgramModuleProgressContext();

  const validateTotalCount = ({ payload }: ProgramModuleProgressResource) => {
    return whenGt(1)(payload.totalCount).map(() => payload);
  };

  return progress
    .andThen(validateTotalCount)
    .map((payload) => (
      <ProgressBar total={payload.totalCount} currentStep={payload.currentPosition} />
    ))
    .getOrElse(emptyFragment);
}

export default observer(ProgramModuleProgress);
