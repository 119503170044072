import Decoder, { string, succeed } from 'jsonous';
import linkifyHtml from 'linkifyjs/html';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Props {
  content: SanitizedHtml;
}

export const sanitizedHtmlDecoder: Decoder<SanitizedHtml> = succeed({}).assign('html', string);

export interface SanitizedHtml {
  html: string;
}

export interface HtmlNode {
  type: string;
  data: string;
}

export const transformer = (node: HtmlNode): React.ReactNode => {
  if (node.type === 'text') {
    const linkifyed = linkifyHtml(node.data);
    const parsed = ReactHtmlParser(linkifyed);
    return parsed;
  }
  return undefined;
};

const parse = (content: SanitizedHtml): React.ReactElement => {
  const elements: React.ReactElement[] = ReactHtmlParser(content.html, {
    decodeEntities: false,
    transform: transformer,
  });
  return <>{elements}</>;
};

const LinkifyWithCharEntities: React.FC<Props> = ({ content }) => {
  return parse(content);
};

export default observer(LinkifyWithCharEntities);
