import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import CommunitiesStore from '.';
import { AppyError, callApi } from '../Appy';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { communitiesResourceDecoder } from './Decoders';
import { CommunitiesState } from './Types';

export const communitiesEndPoint = callApi(communitiesResourceDecoder, {});

export type CommunitiesReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<CommunitiesStore> {
  store: CommunitiesStore;
  resourceWithCommunities: CurrentUserResource;
}

type CommunityRequestError = MissingLinkError | AppyError;

export const handleCommunityRequestError =
  (store: CommunitiesStore) => (error: CommunityRequestError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Community link is unavailable');
        break;
      case 'bad-payload':
      case 'bad-status':
      case 'bad-url':
      case 'network-error':
      case 'timeout':
      case 'missing-application-id':
        handleError(store, error);
    }
  };

class CommunitiesReactions extends ErrorActionableReaction<
  CommunitiesStore,
  CommunitiesState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: CommunitiesState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading-more':
        Task.succeed<CommunitiesReactionError, ReadonlyArray<Link>>(state.communitiesResource.links)
          .andThen(findLinkT('next'))
          .andThen(communitiesEndPoint)
          .fork(handleCommunityRequestError(this.props.store), this.props.store.moreLoaded);
        break;
      case 'loading':
        Task.succeed<CommunitiesReactionError, ReadonlyArray<Link>>(
          this.props.resourceWithCommunities.links,
        )
          .andThen(findLinkT('communities'))
          .andThen(communitiesEndPoint)
          .fork(handleCommunityRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default CommunitiesReactions;
