import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import Modal from '../../../../components/Modal';
import ModalStore from '../../../../components/Modal/Store';
import { T } from '../../../../Translations';
import MailToLink from '../../MailToLink';
import EnrollmentStore from '../Enrollment/Store';
import { handleModalClose } from './handleModalClose';

interface Props {
  modalStore: ModalStore;
  enrollmentStore: EnrollmentStore;
  message: string;
}

const supportEmail = 'support@execonline.com';

const ExpiredAccessModalContentInfo: React.FC<Props> = ({
  modalStore,
  enrollmentStore,
  message,
}) => (
  <Modal
    size="lg"
    title={<T kind="Your Access Has Expired" />}
    close={() => handleModalClose(enrollmentStore, modalStore)}
  >
    <div className="flex flex-col text-center gap-y-4">
      <Typography component="strong" fontWeight="bold">
        {message}
      </Typography>
      <Typography>
        <T
          kind="To re-enroll in this experience ..."
          emailAddress={
            <MailToLink email={supportEmail}>
              <Typography
                className="hover:underline"
                component="span"
                color="inherit"
                fontWeight="medium"
              >
                {supportEmail}
              </Typography>
            </MailToLink>
          }
        />
      </Typography>
    </div>
  </Modal>
);

export default observer(ExpiredAccessModalContentInfo);
