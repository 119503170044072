import { observer } from 'mobx-react';
import { UserNetworkIcon } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import ExperienceProductLabel from '../ExperienceProductLabel';

function GroupCoachingProductBadge() {
  return (
    <ExperienceProductLabel>
      <UserNetworkIcon color="amber" size="md" />
      <T kind="Group Coaching" />
    </ExperienceProductLabel>
  );
}

export default observer(GroupCoachingProductBadge);
