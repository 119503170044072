import { observer } from 'mobx-react';
import * as React from 'react';
import { ProfessorResource, ProfessorsResource } from '../../ProfessorsStore/Types';
import Professor from './Professor';
import * as style from './style.module.css';

interface Props {
  professorsResource: ProfessorsResource;
}

const ProfessorBody: React.FC<Props> = ({ professorsResource }) => {
  return (
    <div className={style.professors} data-test-professors-list={true}>
      {professorsResource.payload.map((professorResource: ProfessorResource) => (
        <Professor key={professorResource.payload.id} professorResource={professorResource} />
      ))}
    </div>
  );
};

export default observer(ProfessorBody);
