import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useExperienceContext } from '../../Common/Experience/ExperienceContext';
import Content from './Content';

function DeliveryChannelSection() {
  const { experienceResource } = useExperienceContext();

  switch (experienceResource.payload.registrationInvitationKind) {
    case 'new-program-family-shared-open-enrollment':
      return experienceResource.payload.deliveryChannel
        .map((deliveryChannel) => (
          <Content deliveryChannel={deliveryChannel} experienceResource={experienceResource} />
        ))
        .getOrElse(emptyFragment);
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
    case 'open-enrollment':
    case 'direct-enrollment':
    case 'preview-only':
      return emptyFragment();
  }
}

export default observer(DeliveryChannelSection);
