import { observer } from 'mobx-react';
import { EnvelopeCheckIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import { Experience } from '../../../Types';

interface Props {
  experience: Experience;
}

function ProgramDetailsItems({ experience }: Props) {
  const { registrationInvitationKind } = experience;

  switch (registrationInvitationKind) {
    case 'direct-enrollment':
      return (
        <div className="flex gap-x-1 text-[#006177]">
          <EnvelopeCheckIcon size="md" color="inherit" />
          <Typography color="inherit" variant="bodySmall" fontWeight="medium" as="span">
            <T kind="Invited" />
          </Typography>
        </div>
      );
    case 'open-enrollment':
    case 'new-program-family-shared-open-enrollment':
    case 'preview-only':
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
      return emptyFragment();
  }
}

export default observer(ProgramDetailsItems);
