import { parseIntM } from '@execonline-inc/numbers';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import Main from '../../BrowserSupport/Main';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import { TeamsStoreContext } from '../../TeamsStore/TeamsStoreContext';
import AutoLaunchable from '../AutoLaunchable';
import Footer from '../Footer';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import TeamMembersListView from './TeamMembersListView';
import NavigationBar from '../Platform/NavigationBar';
interface Params {
  teamId: string;
}

interface Props {
  match: { params: Params };
}

function TeamMemberLists({ match }: Props) {
  const teamsStore = useContext(TeamsStoreContext);
  const teamId = parseIntM(match.params.teamId);
  return (
    <>
      <div className={platformStyle.containerFull}>
        <SkipNavLink />
        <NavigationBar />
      </div>
      <div className={platformStyle.container}>
        <Notifications />
        <AutoLaunchable />
        <Main id="main">
          <TeamMembersListView teamId={teamId} />
          <NotificationSource store={teamsStore} />
        </Main>
        <Footer />
      </div>
    </>
  );
}

export default observer(TeamMemberLists);
