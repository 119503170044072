import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../../../Conference/Types';
import style from './../../style.module.css';
import { NotTranslated, T } from '../../../../Translations';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
}

const DailyDialInDetails: React.FC<Props> = ({ conferenceRoomResource }) => {
  return (
    <div className={style.dialInContent}>
      <p>
        <T kind="You may call into the meeting using the details below:" />
      </p>
      <dl className={style.dl}>
        <dt>
          <T kind="Number:" />
        </dt>
        <dd>
          <NotTranslated text="US: +1 (209) 503-8039 / CA: +1 (226) 807-8039" />
        </dd>
      </dl>
      <dl className={style.dl}>
        <dt>
          <T kind="Meeting ID:" />
        </dt>
        <dd>
          {conferenceRoomResource.payload.conferencePin
            .map((pin) => <NotTranslated text={pin} />)
            .getOrElse(() => (
              <T kind="Dial-in support is not yet available for this meeting" />
            ))}
        </dd>
      </dl>
      <br />
      <p>
        <T
          kind="If you are calling from outside of the United States ..."
          link={(content) => (
            <a href="https://www.execonline.com/numbers/" target="_blank">
              {content}
            </a>
          )}
        />
      </p>
    </div>
  );
};

export default observer(DailyDialInDetails);
