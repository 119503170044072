import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DownArrowIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 8 12">
      <path
        stroke={defaultTheme.variables['icon-on-dark']}
        fill={defaultTheme.variables['icon-on-dark']}
        d="M3.464 1v8.392L.606 6.556 0 7.157l3.582 3.555.303.288.303-.288L7.77 7.157l-.606-.601-2.858 2.836V1z"
      />
    </SVGIcon>
  );
};

export default observer(DownArrowIcon);
