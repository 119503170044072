import { Maybe } from 'maybeasy';
import { TPlainTextKey } from '../Translations';
import { FormFailure } from './Form/Errors';

export interface URLs {
  start: string;
  success: string;
  error: string;
}

interface Standard {
  kind: 'standard';
  username: string;
}

interface Streamlined {
  kind: 'streamlined';
  opaqueUseCaseIdentifier: string;
  username: Maybe<string>;
}

export type UserExperience = Standard | Streamlined;

export interface State {
  urls: URLs;
  userExperience: UserExperience;
}

export const loadingText: TPlainTextKey =
  'Attempting to sign in to your corporate system. Please be patient.';

export type SsoError = FormFailure;
