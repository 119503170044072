import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConversationResource } from '../Conference/Types';
import { ChatablePersonResourceList } from '../ConversationStore/Types';
import { CurrentUserResource } from '../CurrentUser/Types';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';
import PresenceChannel from '../Socket/PresenceChannel';
import PresenceHandler from '../Socket/PresenceChannel/PresenceHandler';
import PresenceMembersStore from '../Socket/PresenceChannel/PresenceMembersStore';
import StaffPresenceStore from '../components/EventDashboard/StaffPresenceStore';
import StaffRequestStore from '../components/EventDashboard/StaffRequestStore';

interface Props {
  conversationResource: ConversationResource;
  client: (
    presenceMembersStore: PresenceMembersStore,
    staffPresenceStore: StaffPresenceStore,
    staffRequestStore: StaffRequestStore
  ) => JSX.Element;
  channelName: string;
  chatablePersonResourceList: Maybe<ChatablePersonResourceList>;
  currentUserResource: CurrentUserResource;
}

class ConversationPresence extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();
  private presenceMembersStore: PresenceMembersStore = new PresenceMembersStore(
    this.props.conversationResource,
    this.props.chatablePersonResourceList,
    this.props.currentUserResource
  );
  private staffPresenceStore: StaffPresenceStore = new StaffPresenceStore();
  private staffRequestStore: StaffRequestStore = new StaffRequestStore();

  render() {
    const { client } = this.props;
    return (
      <>
        <PresenceChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <PresenceHandler
          presenceMembersStore={this.presenceMembersStore}
          channelAvailabilityStore={this.channelAvailabilityStore}
          staffPresenceStore={this.staffPresenceStore}
          staffRequestStore={this.staffRequestStore}
        />
        {client(this.presenceMembersStore, this.staffPresenceStore, this.staffRequestStore)}
      </>
    );
  }
}

export default observer(ConversationPresence);
