import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link as LocalLink } from 'react-router-dom';
import { T } from '../../../Translations';
import { LinkListItem } from '../Types';

interface Props {
  item: LinkListItem;
}

const LinkListDropdownItem: React.FC<Props> = ({ item }) => {
  const commonAttrs = {
    className: clsx(
      'py-3 px-6',
      'flex justify-around',
      'font-bold text-xs leading-6 text-gray-900-old',
    ),
    'data-test-dropdown-item-link': item.name,
    tabIndex: 0,
    children: <T kind={item.name} />,
  };

  switch (item.destination) {
    case 'local':
      return <LocalLink {...commonAttrs} to={item.href} />;
    case 'remote':
      return <a {...commonAttrs} href={item.href} />;
  }
};

export default observer(LinkListDropdownItem);
