import { findPayloadT, PayloadNotFound } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { always, noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useCurrentUserContext } from '../../CurrentUser';
import { findLinkBy } from '../../Links';
import { ProgramModuleProgress } from '../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../ProgramModuleProgress/Context';
import { programsStore } from '../../ProgramsStore';
import { ProgramsResource } from '../../ProgramsStore/Types';
import ProgramStore from '../../ProgramStore';
import SegmentStore from '../../SegmentStore';
import { SegmentResource } from '../../SegmentStore/Types';
import { T } from '../../Translations';
import ContentStates from '../ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/OrientationModal/ModalSegment/ContentStates';
import ProgramReactions from '../ProgramReactions';
import SubmitAndAdvanceButton from '../Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import PreviousAdvanceButton from '../Segment/SegmentNavigation/PreviousAdvanceButton';

interface Props {
  programId: number;
  segmentStore: SegmentStore;
}

function AutoLaunchableModalContent({ programId, segmentStore }: Props) {
  const currentUser = useCurrentUserContext();
  const programStore = useConst(() => new ProgramStore());

  useEffect(() => {
    toTask<PayloadNotFound | string, ProgramsResource>('programs-resource-error')(
      programsStore.resource,
    )
      .map(({ payload }) => payload.programs)
      .andThen(findPayloadT(programId))
      .fork(noop, programStore.loading);
  }, [programId]);

  const nextSegmentPresent = (resource: SegmentResource) =>
    findLinkBy({ rel: 'next' }, resource.links);

  return (
    <ProgramModuleProgressProvider store={segmentStore}>
      <div className="mt-8">
        <ProgramModuleProgress />
      </div>
      <ContentStates store={segmentStore} />
      <div className="mt-5 flex justify-between">
        <PreviousAdvanceButton data-testid="previous-segment-button" store={segmentStore}>
          <T kind="Previous" />
        </PreviousAdvanceButton>
        <SubmitAndAdvanceButton
          data-testid="next-segment-button"
          className="ml-auto"
          store={segmentStore}
        >
          {segmentStore.segmentResource
            .andThen(nextSegmentPresent)
            .map(always(<T kind="Next" />))
            .getOrElse(always(<T kind="Finish" />))}
        </SubmitAndAdvanceButton>
      </div>

      <ProgramReactions store={programStore} currentUserResource={currentUser} fireImmediately />
    </ProgramModuleProgressProvider>
  );
}

export default observer(AutoLaunchableModalContent);
