import { assertNever } from '@kofno/piper';
import ModalStore from '../../../../components/Modal/Store';
import EnrollmentStore from '../Enrollment/Store';

export const handleModalClose = (enrollmentStore: EnrollmentStore, modalStore: ModalStore) => {
  switch (enrollmentStore.state.kind) {
    case 'waiting':
    case 'setting-up':
    case 'waiting-commerce':
    case 'error':
    case 'server-error':
    case 'payment-loading-error':
      modalStore.close();
      break;
    case 'initializing-payment':
    case 'ready-for-payment':
    case 'creating':
    case 'created':
    case 'exiting':
      break;
    default:
      assertNever(enrollmentStore.state);
  }
};
