import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography, TypographyProps } from '@execonline-inc/execonline-ui';
import { T } from '../../../Translations';

const CopyrightNotice: React.FC<TypographyProps> = (props) => {
  const currentYear: number = new Date().getFullYear();
  return (
    <Typography variant="bodyMedium" {...props}>
      <NotTranslated text={`© ${currentYear} ExecOnline.`} /> <T kind="All rights reserved" />
    </Typography>
  );
};

export default observer(CopyrightNotice);
