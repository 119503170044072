import * as React from 'react';
import { observer } from 'mobx-react';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import HeroSchoolPartnerLogo from '../../ExternalProgramDetailView/HeroSection/Body/HeroSchoolPartnerLogo';
import { AlreadyTranslated } from '@execonline-inc/translations';
import * as styles from '../style.module.css';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const Hero: React.FC<Props> = ({ externalProgramResource }) => (
  <div className={styles.cardHeader}>
    <div className="text-xl h-12 mb-5 font-normal leading-7 text-left text-white">
      <AlreadyTranslated content={externalProgramResource.payload.title} />
    </div>
    <div className={styles.partnerLogo}>
      <HeroSchoolPartnerLogo
        schoolPartnerResource={externalProgramResource.payload.schoolPartner}
      />
    </div>
  </div>
);

export default observer(Hero);
