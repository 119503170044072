import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import PollsModalStore from '.';
import { AppyError, deleteToApi, postToApi } from '../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { Link } from '../../../Resource/Types';
import { State } from './Types';

interface Props extends EAProps<PollsModalStore> {
  broadcastLink: Link;
  broadcastCloseLink: Link;
}

const broadcastPoll = (poll_url: string, link: Link) => {
  return postToApi({
    poll_url,
  })(link);
};

const handleBroadcastError = (store: PollsModalStore) => (error: AppyError) => {
  handleError(store, error);
};

class BroadcastPollsModalReactions extends ErrorActionableReaction<PollsModalStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'closed':
      case 'open':
      case 'poll-selection-ready':
      case 'error':
      case 'poll-launch-active':
        break;
      case 'poll-selected':
        find(
          (pollResource) => pollResource.payload.name === state.selectionEvent.currentTarget.value,
          store.pollResources
        )
          .do((pollResource) => store.pollSelectionReady(pollResource.payload))
          .elseDo(store.open);
        break;
      case 'poll-launch-request':
        broadcastPoll(state.selectedPoll.url, this.props.broadcastLink).fork(
          handleBroadcastError(store),
          store.pollLaunchActive
        );
        break;
      case 'poll-closed':
        deleteToApi(this.props.broadcastCloseLink).fork(handleBroadcastError(store), store.close);
        break;

      default:
        assertNever(state);
    }
  };
}

export default BroadcastPollsModalReactions;
