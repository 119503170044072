import { observer } from 'mobx-react';
import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { T } from '../../Translations';
import ConjointInput from './ConjointInput';
import * as style from './style.module.css';
import { ProductAttributeList } from './Types';
interface Props {
  store: ConjointAnalysisStore;
}

const ProductAttributes: React.FC<Props> = ({ store }) => {
  return (
    <>
      <br />
      <h2>
        <T kind="STEP 3: ENTER PRODUCT OR SERVICE ATTRIBUTES" />
      </h2>
      <div className={style.stepContent}>
        <p>
          <T kind="Which attributes do you want customers to consider? ..." />
        </p>
        <fieldset className={style.attributeSection}>
          <h2>Attribute 1</h2>
          <ConjointInput<ProductAttributeList>
            name="pa1"
            hint="e.g. Location"
            onChange={store.setProductAtributeValue}
            labelText="Attribute"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa1_o1"
            hint="e.g. Uptown"
            onChange={store.setProductAtributeValue}
            labelText="Option 1"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa1_o2"
            hint="e.g. Downtown"
            onChange={store.setProductAtributeValue}
            labelText="Option 2"
            required
          />
          <br />
          <ConjointInput<ProductAttributeList>
            name="pa1_desc"
            hint="e.g. Uptown = north of 35th St; Downtown = south of 35th St."
            onChange={store.setProductAtributeValue}
            labelText="Attribute/Options Description"
            required
          />
          <br />
          <h2>Attribute 2</h2>
          <ConjointInput<ProductAttributeList>
            name="pa2"
            hint="e.g. Size"
            onChange={store.setProductAtributeValue}
            labelText="Attribute"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa2_o1"
            hint="e.g. Efficiency"
            onChange={store.setProductAtributeValue}
            labelText="Option 1"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa2_o2"
            hint="e.g. One bedroom"
            onChange={store.setProductAtributeValue}
            labelText="Option 2"
            required
          />
          <br />
          <ConjointInput<ProductAttributeList>
            name="pa2_desc"
            hint="e.g. Efficiency = ~500 sq feet; One bedroom= ~800 sq fee"
            onChange={store.setProductAtributeValue}
            labelText="Attribute/Options Description"
            required
          />
          <h2>Attribute 3</h2>
          <ConjointInput<ProductAttributeList>
            name="pa3"
            hint="e.g. Finish"
            onChange={store.setProductAtributeValue}
            labelText="Attribute"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa3_o1"
            hint="e.g. Basic"
            onChange={store.setProductAtributeValue}
            labelText="Option 1"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa3_o2"
            hint="e.g. Luxury"
            onChange={store.setProductAtributeValue}
            labelText="Option 2"
            required
          />
          <br />
          <ConjointInput<ProductAttributeList>
            name="pa3_desc"
            hint="e.g. Basic = No furniture or air ..."
            onChange={store.setProductAtributeValue}
            labelText="Attribute/Options Description"
            required
          />
          <h2>Attribute 4</h2>
          <ConjointInput<ProductAttributeList>
            name="pa4"
            hint="e.g. Rent"
            onChange={store.setProductAtributeValue}
            labelText="Attribute"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa4_o1"
            hint="e.g. $800 per month"
            onChange={store.setProductAtributeValue}
            labelText="Option 1"
            required
          />
          <ConjointInput<ProductAttributeList>
            name="pa4_o2"
            hint="e.g. $1,600 per month"
            onChange={store.setProductAtributeValue}
            labelText="Option 2"
            required
          />
          <br />
          <ConjointInput<ProductAttributeList>
            name="pa4_desc"
            hint="e.g. Price refers to cost per unit"
            onChange={store.setProductAtributeValue}
            labelText="Attribute/Options Description"
            required
          />
        </fieldset>
      </div>
    </>
  );
};

export default observer(ProductAttributes);
