import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const DeleteIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 20">
      <path
        fill={defaultTheme.variables['icon-standard-on-light']}
        d="M6.4 0c-.419 0-.847.147-1.15.45-.303.303-.45.731-.45 1.15v.8H0V4h.8v12.8c0 1.316 1.084 2.4 2.4 2.4h9.6c1.316 0 2.4-1.084 2.4-2.4V4h.8V2.4h-4.8v-.8c0-.419-.147-.847-.45-1.15C10.447.147 10.019 0 9.6 0H6.4Zm0 1.6h3.2v.8H6.4v-.8ZM2.4 4h11.2v12.8c0 .444-.356.8-.8.8H3.2a.798.798 0 0 1-.8-.8V4ZM4 6.4v8.8h1.6V6.4H4Zm3.2 0v8.8h1.6V6.4H7.2Zm3.2 0v8.8H12V6.4h-1.6Z"
      />
    </SVGIcon>
  );
};

export default observer(DeleteIcon);
