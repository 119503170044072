import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import TeamsStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link, Linkable } from '../Resource/Types';
import { teamsResourceDecoder } from './Decoders';
import { TeamsState } from './Types';

export const teamsEndPoint = callApi(teamsResourceDecoder, {});

export type TeamsReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<TeamsStore> {
  store: TeamsStore;
  resourceWithTeams: Linkable;
}

type TeamRequestError = MissingLinkError | AppyError;

export const handleTeamRequestError = (store: TeamsStore) => (error: TeamRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Team link is unavailable');
      break;
    default:
      handleError(store, error);
  }
};

class TeamsReactions extends ErrorActionableReaction<TeamsStore, TeamsState, Props> {
  tester = () => this.props.store.state;

  effect = (state: TeamsState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading-more':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(state.teamsResource.links)
          .andThen(findLinkT('next'))
          .andThen(teamsEndPoint)
          .fork(handleTeamRequestError(this.props.store), this.props.store.moreLoaded);
        break;
      case 'loading':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(this.props.resourceWithTeams.links)
          .andThen(findLinkT('team-details'))
          .andThen(teamsEndPoint)
          .fork(handleTeamRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default TeamsReactions;
