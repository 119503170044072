import { observer } from 'mobx-react';
import * as React from 'react';
import ThemeContext from '../../../Themes/ThemeContext';
import { lightTheme } from '../../../Native/styles';
import SegmentStore from '../../../SegmentStore';
import { T } from '../../../Translations';
import AttachmentListItem from '../../AttachmentListItem';
import * as style from '../style.module.css';

interface Props {
  store: SegmentStore;
}
const ResourceTabPane: React.FC<Props> = ({ store }) => {
  return (
    <ThemeContext.Provider value={lightTheme}>
      <div className={style.tabContent} data-test-resource-tab-pane={true}>
        <h3 className={style.supPanelHeader} data-test-resources-title="Resources">
          <T kind="Resources" />
        </h3>
        <ul>
          {store.attachments.map((attachmentResource) => (
            <AttachmentListItem
              key={attachmentResource.payload.title}
              attachmentResource={attachmentResource}
              links={store.links}
            />
          ))}
        </ul>
      </div>
    </ThemeContext.Provider>
  );
};
export default observer(ResourceTabPane);
