import { computed } from 'mobx';
import { every10Sec, isBefore } from '../../Date';
import {
  CalendarEvent,
  currentEvent,
  EventResource,
  liveEvent,
  otherEvent,
  pastEvent,
  upcomingEvent,
} from '../Types';

class CalendarEventStore {
  constructor(public resource: EventResource) {}

  @computed
  get event(): CalendarEvent {
    const now = every10Sec.get();
    const { startAt, endAt, live, earlyAccessAt } = this.resource.payload;

    return earlyAccessAt
      .map((date) => {
        if (isBefore(endAt, now)) {
          return pastEvent(this.resource);
        } else if (isBefore(startAt, now)) {
          return currentEvent(this.resource);
        } else if (isBefore(date, now)) {
          return currentEvent(this.resource);
        } else if (isBefore(now, date)) {
          return upcomingEvent(this.resource);
        } else if (live) {
          return liveEvent(this.resource);
        } else {
          return otherEvent(this.resource);
        }
      })
      .getOrElseValue(upcomingEvent(this.resource));
  }
}

export const calendarEventStore = (resource: EventResource): CalendarEventStore =>
  new CalendarEventStore(resource);

export default CalendarEventStore;
