import {
  CloseIcon,
  DrawerBody,
  DrawerHeader,
  IconButton,
  List,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import ApplicationBrand from '../../ApplicationBrand';
import ApplicationMenu from '../../ApplicationMenu';
import LearningMenu from './LearningMenu';
import MainListMenu from './MainListMenu';
import SupportMenu from './SupportMenu';

interface Props {
  user: CurrentUserResource;
  onSubMenuOpen: () => void;
  onClose: () => void;
}

function MainNavigation({ user, onSubMenuOpen, onClose }: Props) {
  const { translate } = useTranslationsContext();

  return (
    <>
      <DrawerHeader>
        <ApplicationMenu user={user} />
        <div className="flex w-full justify-center">
          <ApplicationBrand user={user} />
        </div>
        <IconButton aria-label={translate('Close Menu')} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerBody>
        <List role="menubar" aria-label={translate('Sidebar')}>
          <LearningMenu onClick={onSubMenuOpen} />
          <MainListMenu user={user} onClick={onClose} />
          <SupportMenu user={user} onClick={onClose} />
        </List>
      </DrawerBody>
    </>
  );
}

export default observer(MainNavigation);
