import { Time } from '@execonline-inc/time';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import { Position } from '../PositionContext';
import TimeToCompleteWithIcon from './TimeToCompleteWithIcon';
import TimeToCompleteWithMutedIcon from './TimeToCompleteWithMutedIcon';

interface Props {
  position: Position;
  ttc: Maybe<Time>;
}

const TimeToCompleteRenderer: React.FC<Props> = ({ ttc, position }) => {
  switch (position.kind) {
    case 'nav':
    case 'overview':
    case 'dashboard':
    case 'tooltip':
      return <TimeToCompleteWithIcon ttc={ttc} />;
    case 'supPanel':
    case 'chat':
    case 'participant':
    case 'footer':
    case 'none':
      return <TimeToCompleteWithMutedIcon ttc={ttc} />;
  }
};

export default TimeToCompleteRenderer;
