import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import { CourseDocument } from './Types';
import VideoResource from './VideoResource';

interface Props {
  courseDocument: CourseDocument;
}

const DocumentRenderer: React.FC<Props> = ({ courseDocument }) => {
  switch (courseDocument.kind) {
    case 'document':
      return (
        <div className="mt-4 text-black">
          <T kind="Document downloading, please check your browser downloads for progress." />
        </div>
      );

    case 'video':
      return <VideoResource videoResource={courseDocument} />;
  }
};

export default observer(DocumentRenderer);
