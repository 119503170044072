import { DraftEditorCommand, EditorState } from 'draft-js';
import { Error } from '../ErrorHandling';

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  message: EditorState;
}

interface Updating {
  kind: 'updating';
  message: EditorState;
}

interface Broadcasting {
  kind: 'broadcasting';
  message: EditorState;
}

export type SyntheticKeyboardEvent = React.KeyboardEvent;
export type KeyCommand = DraftEditorCommand | 'submit-chat';

export interface StyleControlsProps {
  editorState: EditorState;
  onToggle: (style: string) => void;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const reset = (): Ready => ({
  kind: 'ready',
  message: EditorState.createEmpty(),
});

export const ready = (message: EditorState): Ready => ({
  kind: 'ready',
  message: message,
});

export const updating = (message: EditorState): Updating => ({
  kind: 'updating',
  message: message,
});

export const broadcasting = (message: EditorState): Broadcasting => ({
  kind: 'broadcasting',
  message: message,
});

export type ChatMessageState = Ready | Updating | Waiting | Broadcasting | Error;
