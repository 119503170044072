import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArray } from 'nonempty-list';
import { Task } from 'taskarian';
import SetPasswordStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps } from '../../../../ErrorActionableReaction';
import { handleAppyError } from '../../../../ErrorHandling';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import { establishSession } from '../../../../Session';
import { sessionResourceDecoder } from '../../../../Session/Reactions';
import RegisterResourceStore from '../../DiscoveryPortal/RegisterResourceStore';
import { validationErrorsDecoder } from '../SetPasswordStore/Decoders';
import { State } from './Types';

export interface Props extends EAProps<SetPasswordStore> {
  store: SetPasswordStore;
  registerResourceStore: RegisterResourceStore;
  links: ReadonlyArray<Link>;
}

type UsePasswordError = MissingLinkError | AppyError;

const handleBadStatusRest = (
  store: SetPasswordStore,
  registerResourceStore: RegisterResourceStore,
  err: BadStatus,
) => {
  switch (err.response.status) {
    case 403:
      registerResourceStore.readyLockedAccess(
        just('Login failed; the email address or password is invalid.'),
      );
      break;
    case 400:
      store.error('Please try again; we did not recognize your email address and/or password.');
      break;
    default:
      validationErrorsDecoder
        .decodeJson(err.response.body)
        .andThen((b) => fromArray(b.errors).map(({ first }) => first.detail))
        .do((err) => {
          warn(`Login failed: ${err}`);
          store.error('Login failed');
        })
        .elseDo(() => store.error(handleAppyError(err)));
  }
};

const handleUsePasswordError =
  (store: SetPasswordStore, registerResourceStore: RegisterResourceStore) =>
  (err: UsePasswordError) => {
    switch (err.kind) {
      case 'missing-link-error':
        store.error('Missing Link');
        break;
      case 'bad-status':
        handleBadStatusRest(store, registerResourceStore, err);
        break;
      default:
        store.error(handleAppyError(err));
    }
  };

class UsePasswordStoreReactions extends ErrorActionableReaction<SetPasswordStore, State, Props> {
  tester = (): State => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
        break;
      case 'ready':
        break;
      case 'creating':
        Task.succeed<UsePasswordError, ReadonlyArray<Link>>(this.props.links)
          .andThen(findLinkT('update'))
          .andThen(
            callApi(sessionResourceDecoder, {
              username: state.emailAddress,
              password: state.password,
            }),
          )
          .map(({ payload }) => payload)
          .do(establishSession)
          .fork(
            handleUsePasswordError(this.props.store, this.props.registerResourceStore),
            this.props.store.created,
          );
        break;
      case 'created':
        break;
      case 'error':
        this.props.store.ready(state.emailAddress, state.termsAcceptance, just(state.message));
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(UsePasswordStoreReactions);
