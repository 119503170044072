import { observer } from 'mobx-react';
import * as React from 'react';
import ChatMessageStore from '../../ChatMessageStore';
import Loader from '../Loader';
import ChatReplyStore from '../../ChatReplyStore';

interface Props {
  store: ChatMessageStore | ChatReplyStore;
  children: {
    form: JSX.Element;
  };
}
const NewChatMessage: React.FC<Props> = ({ store, children }) => {
  switch (store.state.kind) {
    case 'broadcasting':
    case 'updating':
    case 'ready':
      return children.form;
    case 'waiting':
      return <Loader />;
    case 'hidden':
    case 'error':
      return <></>;
  }
};

export default observer(NewChatMessage);
