import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import StaffPresenceStore from '../../../components/EventDashboard/StaffPresenceStore';
import StaffRequestStore from '../../../components/EventDashboard/StaffRequestStore';
import ChannelAvailabilityStore from '../../ChannelAvailabilityStore';
import PresenceMembersStore from '../PresenceMembersStore';
import PresenceBinding from './PresenceBinding';

interface Props {
  presenceMembersStore: PresenceMembersStore;
  channelAvailabilityStore: ChannelAvailabilityStore;
  staffPresenceStore: StaffPresenceStore;
  staffRequestStore: StaffRequestStore;
}

const PresenceHandler: React.FC<Props> = ({
  presenceMembersStore,
  channelAvailabilityStore,
  staffPresenceStore,
  staffRequestStore,
}) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (channelAvailabilityStore.channelAvailability.kind) {
        case 'available': {
          const channel = channelAvailabilityStore.channelAvailability.channel;
          return (
            <PresenceBinding
              channel={channel}
              store={presenceMembersStore}
              staffPresenceStore={staffPresenceStore}
              staffRequestStore={staffRequestStore}
              currentUserResource={currentUserResource}
            />
          );
        }
        case 'not-available': {
          return <span />;
        }
      }
    }}
  />
);

export default observer(PresenceHandler);
