import { ButtonProps } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../../SegmentStore';
import { AdvanceButton } from '../../Common';
import StateDependentButton from './StateDependentButton';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function FormFieldButton({ store, ...others }: Props) {
  return when(store.allFieldsAreComplete, {})
    .map(() => <StateDependentButton storeState={store.state.kind} {...others} />)
    .getOrElse(() => <AdvanceButton {...others} disabled />);
}

export default observer(FormFieldButton);
