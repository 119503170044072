import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceParticipant } from '../../../ConferenceRosterStore/Types';
import { T } from '../../../Translations';
import Participant from '../Participant';

interface Props {
  participants: ConferenceParticipant[];
  conferenceRosterStore: ConferenceRosterStore;
}

const NotArrivedParticipants: React.FC<Props> = ({ conferenceRosterStore, participants }) => (
  <>
    <div data-test-not-arrived-participants={true}>
      <T kind="Not Yet Arrived" />
    </div>
    <ul>
      {participants.map((participant: ConferenceParticipant) => (
        <Participant
          conferenceRosterStore={just(conferenceRosterStore)}
          key={participant.id}
          participant={participant}
        />
      ))}
    </ul>
  </>
);

export default observer(NotArrivedParticipants);
