import { just, Maybe } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { findLink } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import {
  captionSourceFile,
  SourceFile,
  thumbnailsSourceFile,
  videoSourceFile,
} from './../JWPlayer/Types';
import {
  AnnouncementVideoAsset,
  VideoAsset,
  VideoPlaybackAdaptiveSources,
  VideoPlaybackSource,
  VideoPlaybackSourceLabel,
  VideoPlaybackSubtitles,
} from './Types';

export const announcementAdaptiveTracks = (videoAsset: AnnouncementVideoAsset): SourceFile[] =>
  videoAsset.adaptiveSources
    .map((adaptiveSources: VideoPlaybackAdaptiveSources) =>
      thumbnailTrack(adaptiveSources.thumbnails).cata({
        Just: (thumbnailTrack: SourceFile) =>
          subtitleTracks(videoAsset.subtitles).concat([thumbnailTrack]),
        Nothing: () => subtitleTracks(videoAsset.subtitles),
      })
    )
    .getOrElseValue([]);

export const adaptiveTracks = (videoAsset: VideoAsset): SourceFile[] => {
  const thumbTrack = videoAsset.adaptiveSources
    .andThen(({ thumbnails }) => thumbnailTrack(thumbnails))
    .map(track => [track])
    .getOrElseValue([]);

  const subTracks = subtitleTracks(videoAsset.subtitles);

  return [...subTracks, ...thumbTrack];
};

export const subtitleTracks = (subtitles: VideoPlaybackSubtitles[]): SourceFile[] =>
  subtitles.map(videoPlaybackSubtitles =>
    captionSourceFile(videoPlaybackSubtitles.file, videoPlaybackSubtitles.label)
  );

export const thumbnailTrack = (thumbnails: Maybe<string>): Maybe<SourceFile> =>
  thumbnails.andThen((thumbnails: string) => just(thumbnailsSourceFile(thumbnails)));

export const thumbnail = (links: ReadonlyArray<Link>): Maybe<string> =>
  findLink('thumbnail', links).map((thumbLink: Link) => thumbLink.href);

export const videoSourcesByLabel = (
  sources: VideoPlaybackSource[],
  labels: VideoPlaybackSourceLabel[]
) =>
  fromArrayMaybe(sources.filter(source => labels.indexOf(source.label) >= 0).map(videoSourceFile));
