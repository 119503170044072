import { Seconds } from '@execonline-inc/time';
import { TProps } from '../Translations';

export const addSeconds = (date: Date) => ({ seconds }: Seconds): Date => {
  const d = new Date(date.getTime());
  d.setSeconds(d.getSeconds() + seconds);
  return d;
};

export const distanceOfTimeInWords = (to: Date, from: Date): TProps => {
  type Tense = 'future' | 'past';

  const words = (mins: number, tense: Tense): TProps => {
    if (mins == 0) {
      switch (tense) {
        case 'future':
          return { kind: 'less than a minute from now' };
        case 'past':
          return { kind: 'less than a minute ago' };
      }
    }
    if (mins == 1) {
      switch (tense) {
        case 'future':
          return { kind: 'a minute from now' };
        case 'past':
          return { kind: 'a minute ago' };
      }
    }
    if (mins < 45) {
      switch (tense) {
        case 'future':
          return { kind: '{{count}} minute from now', count: mins };
        case 'past':
          return { kind: '{{count}} minute ago', count: mins };
      }
    }
    if (mins < 90) {
      switch (tense) {
        case 'future':
          return { kind: 'about an hour from now' };
        case 'past':
          return { kind: 'about an hour ago' };
      }
    }
    if (mins < 1440) {
      const count = Math.floor(mins / 60);
      switch (tense) {
        case 'future':
          return { kind: 'about {{count}} hour from now', count };
        case 'past':
          return { kind: 'about {{count}} hour ago', count };
      }
    }
    if (mins < 2880) {
      switch (tense) {
        case 'future':
          return { kind: 'a day from now' };
        case 'past':
          return { kind: 'a day ago' };
      }
    }
    if (mins < 43200) {
      const count = Math.floor(mins / 1440);
      switch (tense) {
        case 'future':
          return { kind: '{{count}} day from now', count };
        case 'past':
          return { kind: '{{count}} day ago', count };
      }
    }
    if (mins < 86400) {
      switch (tense) {
        case 'future':
          return { kind: 'about a month from now' };
        case 'past':
          return { kind: 'about a month ago' };
      }
    }
    if (mins < 525960) {
      const count = Math.floor(mins / 43200);
      switch (tense) {
        case 'future':
          return { kind: '{{count}} month from now', count };
        case 'past':
          return { kind: '{{count}} month ago', count };
      }
    }
    if (mins < 1051199) {
      switch (tense) {
        case 'future':
          return { kind: 'about a year from now' };
        case 'past':
          return { kind: 'about a year ago' };
      }
    }

    const count = Math.floor(mins / 525960);
    switch (tense) {
      case 'future':
        return { kind: 'over {{count}} year from now', count };
      case 'past':
        return { kind: 'over {{count}} year ago', count };
    }
  };

  const distanceInSeconds = (to.valueOf() - from.valueOf()) / 1000;
  const distanceInMinutes = Math.abs(Math.floor(distanceInSeconds / 60));
  const tense: Tense = distanceInSeconds < 0 ? 'future' : 'past';

  return words(distanceInMinutes, tense);
};
