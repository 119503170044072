import { observer } from 'mobx-react';
import { ServerError } from '../../../Resource/Types';
import ErrorHeader from './ErrorHeader';
import ErrorMessage from './ErrorMessage';
import * as style from './style.module.css';

interface Props {
  serverErrors: ReadonlyArray<ServerError>;
}

const ErrorMessageContainer: React.FC<Props> = ({ serverErrors }) => (
  <div className={style.error} role="alert">
    <ErrorHeader />
    {serverErrors.map((err: ServerError) => (
      <ErrorMessage param={err.param} key={err.param} message={err.message} />
    ))}
  </div>
);

export default observer(ErrorMessageContainer);
