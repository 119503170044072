import { observer } from 'mobx-react';
import * as React from 'react';
import { RegisterResource } from '../DiscoveryPortal/RegisterResourceStore/Types';
import RegisterResourceStore from '../DiscoveryPortal/RegisterResourceStore';
import SignIn from './SignIn';
import SignUp from './SignUp';
import LockedAccess from './LockedAccess';
import { authenticationFormKind } from './Types';

interface Props {
  registerResource: RegisterResource;
  registerResourceStore: RegisterResourceStore;
}

const RequireAuthentication: React.FC<Props> = ({ registerResource, registerResourceStore }) => {
  const authForm = authenticationFormKind(registerResource.payload.auth);

  switch (authForm.kind) {
    case 'sso-sign-in':
    case 'password-sign-in':
      return (
        <SignIn
          authForm={authForm}
          registerResource={registerResource}
          registerResourceStore={registerResourceStore}
        />
      );
    case 'sso-sign-up':
    case 'password-sign-up':
      return <SignUp authForm={authForm} registerResource={registerResource} />;
    case 'locked-access':
      return <LockedAccess store={authForm.store} registerResource={registerResource} />;
  }
};

export default observer(RequireAuthentication);
