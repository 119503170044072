import { observer } from 'mobx-react';
import * as React from 'react';
import EventsStore from '../EventsStore';
import { PusherSettingsResource } from '../ToolingsStore/Types';
import Refresh from './Refresh';

interface Props {
  eventsStore: EventsStore;
  pusherSettingsResource: PusherSettingsResource;
}

const EventsRefresh: React.FC<Props> = ({ eventsStore, pusherSettingsResource }) => {
  return (
    <Refresh
      eventsStore={eventsStore}
      channelName={pusherSettingsResource.payload.channels.calendarEventsChannel}
    />
  );
};

export default observer(EventsRefresh);
