import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { teamUrl } from '../../ClientRoutes';
import { TeamLinkNode } from '../../TeamsStore/Types';
import { T } from '../../Translations';
import MessagesIcon from '../Cutesies/MessagesIcon';
import * as style from './style.module.css';

interface Props {
  node: TeamLinkNode;
}
const TeamLink: React.FC<Props> = ({ node }) => {
  return (
    <Link to={teamUrl(parseInt(node.teamId))}>
      <button className={style.teamLinkButton}>
        <T kind="Message Your Team" />
        <div className={style.teamLinkButtonIcon}>
          <MessagesIcon />
        </div>
      </button>
    </Link>
  );
};

export default observer(TeamLink);
