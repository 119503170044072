import { Typography } from '@execonline-inc/execonline-ui';
import { T, TranslationsContext, translation } from '../../Translations';
import AppStoreGraphic from '../Cutesies/AppStoreGraphic';

function LinkedMobileApp() {
  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <div className="mt-4 flex flex-col items-center sm:mt-7">
          <Typography className="mb-5" variant="subtitleMedium" as="p" fontWeight="medium">
            <T kind="Learn on the go with our mobile app" />
          </Typography>
          <a
            className="inline-block h-9 w-[120px]"
            href="https://itunes.apple.com/us/app/apple-store/id1437096431?mt=8"
            aria-label={translation('Download on the App Store')(ts)}
          >
            <AppStoreGraphic />
          </a>
        </div>
      )}
    </TranslationsContext.Consumer>
  );
}

export default LinkedMobileApp;
