import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../../ChannelAvailabilityStore';
import PublicChannelStore from '../BreakoutChannelStore';
import PublicChannelBinding from './Binding';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
  publicChannelStore: PublicChannelStore;
}

const Handler: React.FC<Props> = ({ channelAvailabilityStore, publicChannelStore }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <PublicChannelBinding channel={channel} store={publicChannelStore} />;
    }
    case 'not-available': {
      return <span />;
    }
  }
};

export default observer(Handler);
