import { observer } from 'mobx-react';
import * as React from 'react';
import { Document } from '../../../../TeamResourceStore/Types';
import { T } from '../../../../Translations';
import * as style from '../../style.module.css';
import ProcessingIcon from './ProcessingIcon';

interface Props {
  resource: Document;
}

const IncompleteResource: React.FC<Props> = ({ resource }) => (
  <div className={style.file}>
    <ProcessingIcon />
    <div className={style.details}>
      <p className={style.incompleteTitle} data-test-team-file={resource.title}>
        <T kind="This file is processing" />
      </p>
    </div>
  </div>
);

export default observer(IncompleteResource);
