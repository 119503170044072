import { ChevronRightIcon, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import FreebusyStore from '../../FreebusyStore';
import SegmentStore from '../../SegmentStore';
import { T } from '../../Translations';
import DateCheckIcon from '../Cutesies/DateCheckIcon';
import TokenAdvanceButton from '../Segment/SegmentNavigation/TokenAdvanceButton';
import * as style from './style.module.css';

interface Props {
  segmentStore: Maybe<SegmentStore>;
  freebusyStore: FreebusyStore;
}
class ReadyButton extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.freebusyStore.loading();
  }

  render() {
    const { segmentStore } = this.props;

    return (
      <div className={style.ready}>
        <p>
          <T kind="Click the button below to schedule your feedback session:" />
        </p>
        <div className={style.buttonContainer}>
          <button id="freebusy-pickatime-button" className={style.button}>
            <T
              kind="Choose <fragment>Day</fragment> and Time"
              fragment={(content) => <span className={style.desktopOnly}>{content}</span>}
            />
            <div className={style.buttonIcon}>
              <DateCheckIcon />
            </div>
          </button>
          <div className={style.desktopSkipButton}>
            {segmentStore
              .map((ss) => (
                <TokenAdvanceButton
                  data-testid="schedule-later-go-to-next-segment-button"
                  store={ss}
                  endContent={<ChevronRightIcon color="inherit" size="sm" />}
                >
                  <T kind="Schedule Later - Go To Next Segment" />
                </TokenAdvanceButton>
              ))
              .getOrElse(emptyFragment)}
          </div>
          <div className={style.mobileSkipButton}>
            {segmentStore
              .map((ss) => (
                <TokenAdvanceButton
                  data-testid="schedule-later-button"
                  store={ss}
                  endContent={<ChevronRightIcon color="inherit" size="sm" />}
                >
                  <T kind="Schedule Later" />
                </TokenAdvanceButton>
              ))
              .getOrElse(emptyFragment)}
          </div>
        </div>
        <div className={style.notice}>
          <p>
            <T
              kind="Questions? Email us at <emailAddress/>."
              emailAddress={
                <a className={style.mailToLink} href="mailto:coaching@execonline.com">
                  coaching@execonline.com
                </a>
              }
            />
          </p>
          <p>
            <u>
              <T kind="If you need to cancel or modify the time of the session, ..." />
            </u>
            <T kind="Live Project Feedback is an added experience that your ..." />
          </p>
        </div>
      </div>
    );
  }
}

export default observer(ReadyButton);
