import { observer } from 'mobx-react';
import { Chip, GraduationCapIcon, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';

function GraduatedAction() {
  return (
    <Chip
      className="inline-flex items-center justify-start gap-x-2"
      color="blue"
      variant="outlined"
    >
      <GraduationCapIcon color="inherit" size="sm" />
      <Typography
        color="inherit"
        className="tracking-[0.2px]"
        variant="caption"
        as="span"
        fontWeight="medium"
      >
        <T kind="Graduated" />
      </Typography>
    </Chip>
  );
}

export default observer(GraduatedAction);
