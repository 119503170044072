import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import EditPasswordStore from '../../EditPasswordStore';
import { T } from '../../Translations';
import Loader from '../Loader';
import Form from './Form';

interface Props {
  store: EditPasswordStore;
}

const EditPasswordRenderer: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'creating':
    case 'ready':
    case 'ready-with-client-error':
    case 'ready-with-server-error':
      return (
        <>
          <Typography data-test-title={true} className="mb-10">
            <T kind="Reset Your Password" />
          </Typography>
          <Typography>
            <T kind="Enter a new password for your account." />
          </Typography>
          <Typography className="mb-8">
            <T kind="Be sure to choose a secure password that only you will know." />
          </Typography>
          <Form store={store} />
        </>
      );
    case 'loading':
      return <Loader />;
    case 'waiting':
    case 'error':
      return <></>;
  }
};

export default observer(EditPasswordRenderer);
