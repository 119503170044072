import { observer } from 'mobx-react';
import * as React from 'react';
import CalendarEventStore from '../../../EventsStore/CalendarEventStore';
import CoachingEvent from './CoachingEvent';
import LiveEvent from './LiveEvent';
import OtherEvent from './OtherEvent';
import PastEvent from './PastEvent';

interface Props {
  store: CalendarEventStore;
}

const EventType: React.FC<Props> = ({ store }) => {
  const event = store.event;
  switch (event.kind) {
    case 'past-event':
      return <PastEvent event={event} />;
    case 'upcoming-event':
    case 'current-coaching-event':
      return <CoachingEvent event={event} />;
    case 'live-event':
      return <LiveEvent event={event} />;
    case 'other-event':
      return <OtherEvent event={event} />;
  }
};

export default observer(EventType);
