import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram } from '../../../../../../ProgramStore/Types';
import { L, T } from '../../../../../../Translations';
import * as style from '../../../style.module.css';

interface Props {
  activeProgram: ActiveProgram;
}

const AccessUntilInfo: React.FC<Props> = ({ activeProgram }) => {
  const accessUntilDate = activeProgram.userProductLicenseEndsOn.orElse(() => activeProgram.endsOn);

  return accessUntilDate
    .map((endsOn) => (
      <p className={style.ternaryText}>
        <T
          kind="Access Until: You may schedule sessions with your coach until: <date />"
          date={<L localizeable={endsOn} format={'long-month-day-year'} />}
        />
      </p>
    ))
    .getOrElse(() => <></>);
};

export default observer(AccessUntilInfo);
