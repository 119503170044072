import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../ProgramStore/Types';
import PositionContext from '../../../../PositionContext';
import * as style from '../../../../ProgramSummary/style.module.css';
import CurrentModuleTooltip from './CurrentModuleTooltip';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
}

const CurrentModule: React.FC<Props> = ({ programModule, programDetail }) => {
  if (programModule.isCurrentModule) {
    return (
      <PositionContext.Provider value={{ kind: 'tooltip' }}>
        <div className={style.progressLine} data-current={true}>
          <CurrentModuleTooltip programModule={programModule} programDetail={programDetail} />
        </div>
      </PositionContext.Provider>
    );
  }
  return <span />;
};

export default CurrentModule;
