import { observer } from 'mobx-react';
import * as React from 'react';

interface Props {
  email: string;
}

const MailToLink: React.FC<Props> = ({ children, email }) => {
  return <a href={`mailto:${email}`} target="_blank" rel="noreferrer" children={children} />;
};

export default observer(MailToLink);
