import { observer } from 'mobx-react';
import * as React from 'react';
import { AnnouncementResource } from '../../Decoders/Types';
import DefaultDetails from './DefaultDetails';
import DigitalCertificateDetails from './DigitalCertificateDetails';

interface Props {
  announcement: AnnouncementResource;
}

const Details: React.FC<Props> = ({ announcement }) => {
  const { payload } = announcement;

  switch (payload.kind) {
    case 'learning-partner-assignment-announcement':
    case 'expert-feedback-announcement':
    case 'embeddable-assets-announcement':
    case 'upload-manifest-announcement':
    case 'shared-to-personalized-resources-announcement':
    case 'shared-to-live-meeting-recordings-announcement':
    case 'coaching-survey-announcement':
      return <DefaultDetails announcement={announcement} />;
    case 'digital-certificate-announcement':
      return <DigitalCertificateDetails announcement={announcement} />;
  }
};

export default observer(Details);
