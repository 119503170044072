import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import CalendarStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link, Linkable, Rel } from '../Resource/Types';
import { calendarResourceDecoder } from './Decoders';
import { CalendarState } from './Types';

const calendarEndPoint = callApi(calendarResourceDecoder, {});

type CalendarReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<CalendarStore> {
  store: CalendarStore;
}

interface CalendarStateWithLinkable {
  state: CalendarState;
  whenLinks: Maybe<Linkable>;
}

type CalendarRequestError = MissingLinkError | AppyError;

const handleCalendarRequestError = (store: CalendarStore) => (error: CalendarRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error("Events aren't available");
      break;
    default:
      handleError(store, error);
  }
};

const makeRequest = (links: ReadonlyArray<Link>, nav: Rel, store: CalendarStore) =>
  Task.succeed<CalendarReactionError, ReadonlyArray<Link>>(links)
    .andThen(findLinkT(nav))
    .andThen(calendarEndPoint)
    .fork(handleCalendarRequestError(store), store.ready);

class CalendarReactions extends ErrorActionableReaction<
  CalendarStore,
  CalendarStateWithLinkable,
  Props
> {
  tester = (): CalendarStateWithLinkable => ({
    state: this.props.store.state,
    whenLinks: this.props.store.whenLinks,
  });

  effect = ({ state, whenLinks }: CalendarStateWithLinkable) => {
    switch (state.kind) {
      case 'waiting':
        whenLinks.do(this.props.store.loading);
        break;
      case 'ready':
      case 'error':
        break;
      case 'loading':
        makeRequest(state.linkable.links, 'calendar', this.props.store);
        break;
      case 'navigating':
        makeRequest(state.calendarResource.links, state.nav, this.props.store);
        break;
      case 'refresh':
        makeRequest(state.calendarResource.links, 'self', this.props.store);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(CalendarReactions);
