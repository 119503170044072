interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  uri: string;
}

interface Ready {
  kind: 'ready';
  dimensions: ImageDimensions;
}

interface Error {
  kind: 'error';
  uri: string;
}

export type State = Waiting | Loading | Ready | Error;

export interface ImageDimensions {
  width: number;
  height: number;
}

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (uri: string): Loading => ({ kind: 'loading', uri });

export const ready = (dimensions: ImageDimensions): Ready => ({ kind: 'ready', dimensions });

export const error = (uri: string): Error => ({ kind: 'error', uri });

export const proportionalImageWidth = (dimensions: ImageDimensions, targetHeight: number): number =>
  (targetHeight / dimensions.height) * dimensions.width;
