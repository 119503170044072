import { observer } from 'mobx-react';
import React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import SingletonConferenceEmbed from './SingletonConferenceEmbed';

const MaybeConferenceEmbed: React.FC<{
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
}> = ({ conferenceRoomResource, conferenceRosterStore }) => (
  <SingletonConferenceEmbed
    conferenceRoomResource={conferenceRoomResource}
    conferenceRosterStore={conferenceRosterStore}
  />
);

export default observer(MaybeConferenceEmbed);
