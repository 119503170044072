import { stringLiteral } from '@execonline-inc/decoders';
import Decoder, { date, field, oneOf, string, succeed } from 'jsonous';
import { EventDetails, FreebusyConfig, FreebusyKind, ProposerParticipant } from './Types';

export const eventDetailsDecoder: Decoder<EventDetails> = succeed({})
  .assign('date', field('date', date))
  .assign('title', field('title', string));

export const freebusyProposerParticipantDecoder: Decoder<ProposerParticipant> = succeed({})
  .assign('email', field('data-attr-proposer-participant-email', string))
  .assign('name', field('data-attr-proposer-participant-name', string))
  .assign('timeZone', field('data-attr-proposer-participant-time-zone', string))
  .assign('role', field('data-attr-proposer-participant-role', stringLiteral('proposer')));

export const freebusyAttribsDecoder: Decoder<FreebusyConfig> = succeed({})
  .assign(
    'kind',
    field(
      'data-attr-kind',
      oneOf([
        stringLiteral<FreebusyKind>('freebusy-program'),
        stringLiteral<FreebusyKind>('freebusy-coaching'),
      ])
    )
  )
  .assign('link', field('data-attr-link', string))
  .assign('agenda', field('data-attr-agenda', string))
  .assign('proposerParticipant', freebusyProposerParticipantDecoder)
  .assign('eventUuid', field('data-attr-event-uuid', string));

export const freebusyDecoder: Decoder<FreebusyConfig> = field('attribs', freebusyAttribsDecoder);
