import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { Typography, TypographyProps } from '../../UI';
import { T } from '../../../Translations';

function ClassicCopyrightNotice(props: TypographyProps) {
  const currentYear: number = new Date().getFullYear();
  return (
    <Typography variant="body2" component="span" {...props}>
      <NotTranslated text={`© ${currentYear} ExecOnline.`} /> <T kind="All rights reserved" />
    </Typography>
  );
}

export default observer(ClassicCopyrightNotice);
