import { byPayloadId, find } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { programUrl, teamUrl } from '../../../ClientRoutes';
import { programsStore } from '../../../ProgramsStore';
import { TeamSummaryResource } from '../../../TeamsSummaryStore/Types';
import { AlreadyTranslated, NotTranslated, T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import StartDate from '../../TeamSummary/StartDate';
import * as style from '../../TeamSummary/style.module.css';
import CommunityMembers from './CommunityMembers';

interface Props {
  teamSummaryResource: TeamSummaryResource;
}

const CommunitySummaryContent: React.FC<Props> = ({ teamSummaryResource }) => (
  <div className={style.background}>
    <div className={style.wrapper}>
      <div className={style.teamName}>
        <Link to={teamUrl(teamSummaryResource.payload.id)} data-test-team-link={true}>
          <h2 className={style.name} data-test-team-name={teamSummaryResource.payload.name}>
            <NotTranslated text={teamSummaryResource.payload.name} />
          </h2>
        </Link>
      </div>
      <div className={style.programName}>
        <Link to={programUrl(teamSummaryResource.payload.courseId)} className={style.programLink}>
          {programsStore.resource
            .map(({ payload }) => payload.programs)
            .andThen(find(byPayloadId(teamSummaryResource.payload.courseId)))
            .map((p) => <AlreadyTranslated content={p.payload.title} />)
            .getOrElse(() => (
              <></>
            ))}
        </Link>
      </div>
      <div className={style.date}>
        <StartDate startDate={teamSummaryResource.payload.startDate} />
      </div>
      <CommunityMembers teamSummaryResource={teamSummaryResource} />
      <div className={style.messageRoomLink}>
        <Link to={teamUrl(teamSummaryResource.payload.id)} data-test-team-link={true}>
          <div className={style.newProfileButton}>
            <T kind="Chat" />
            <div className={style.buttonIcon}>
              <ArrowRightIcon />
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

export default observer(CommunitySummaryContent);
