import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { LectureCommentaryResource } from '../../../../../LectureStore/Types';
import * as style from '../../style.module.css';

interface Props {
  commentary: LectureCommentaryResource;
}

const Commentary: React.FC<Props> = ({ commentary }) => {
  return (
    <div className={style.commentary} data-test-commentary={commentary.payload.id}>
      {ReactHtmlParser(commentary.payload.content)}
    </div>
  );
};

export default observer(Commentary);
