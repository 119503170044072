import * as React from 'react';
import DownArrowIcon from '../../../Cutesies/DownArrowIcon';
import * as style from '../../style.module.css';

const DownloadIcon: React.FC = () => (
  <div className={style.download}>
    <div className={style.downloadIcon}>
      <DownArrowIcon />
    </div>
  </div>
);

export default DownloadIcon;
