import { Maybe } from 'maybeasy';
import { MissingCookieError } from '../../Cookies';
import { MissingParamError } from '../../QueryString';
import { Props } from './Types';

export interface SessionAlreadyEstablished {
  kind: 'session-already-established';
}

export interface MissingIdentificationParam {
  kind: 'missing-identification-param';
  username: Maybe<string>;
  opaqueUseCaseIdentifier: Maybe<string>;
}

export interface FormBuildError {
  kind: 'form-build-error';
  error: unknown;
}

export type FormFailure =
  | SessionAlreadyEstablished
  | MissingIdentificationParam
  | MissingParamError
  | MissingCookieError
  | FormBuildError;

export const sessionAlreadyEstablished = (): SessionAlreadyEstablished => ({
  kind: 'session-already-established',
});

export const missingIdentificationParam = ({
  username,
  opaqueUseCaseIdentifier,
}: Props): MissingIdentificationParam => ({
  kind: 'missing-identification-param',
  username,
  opaqueUseCaseIdentifier,
});

export const formBuildError = (error: unknown): FormBuildError => ({
  kind: 'form-build-error',
  error,
});
