import { log, warn } from '@execonline-inc/logging';
import { Maybe } from 'maybeasy';
import * as React from 'react';
import { Task } from 'taskarian';
import { errorMessage } from '../../ExceptionHandling';
import { warnAndNotify } from '../../Honeybadger';
import { T } from '../../Translations';
import { loadConjointResults } from './Loader';
import {
  ConjointAnalysisResultsMaybeResource,
  ConjointAnalysisResultsNode,
  ConjointResults,
  ConjointResultsLoadError,
  conjointResultsLoadError,
} from './Types';
import * as style from './style.module.css';
interface Props {
  node: ConjointAnalysisResultsNode;
}

declare global {
  export interface Window {
    thing?: Maybe<ConjointResults>;
  }
}

const successfulLoad = () => {
  log('Successfully loaded ConjointResults');
};

const initializeError = (err: ConjointResultsLoadError): void => {
  switch (err.kind) {
    case 'conjoint-results-load-error':
      warnAndNotify('ConjointResultsLoadError', err.message, err);
      break;
    default:
      warnAndNotify('ConjointResultsError', err.message, err);
  }
};

const loadResults = (): Task<ConjointResultsLoadError, {}> =>
  loadConjointResults('https://media.execonline.com/conjoint-production/conjoint-analysis.js');

const attachConjointToWindow = (
  resource: ConjointAnalysisResultsMaybeResource,
): Task<ConjointResultsLoadError, {}> => {
  return new Task<ConjointResultsLoadError, ConjointResults>((reject, resolve) => {
    try {
      resource
        .map((resource) => {
          window.conjointSurvey = resource.payload;
          resolve(window.conjointSurvey);
        })
        .getOrElse(() => reject(conjointResultsLoadError(`Could not load conjoint results`)));
    } catch (e) {
      reject({ kind: 'conjoint-results-load-error', message: errorMessage(e) });
    }
    return () => {
      warn('Loading Conjoint Results cannot be canceled');
    };
  });
};

class ConjointAnalysisResults extends React.PureComponent<Props> {
  componentDidMount() {
    Task.succeed<ConjointResultsLoadError, ConjointAnalysisResultsMaybeResource>(
      this.props.node.resource,
    )
      .andThen(loadResults)
      .andThen(() => attachConjointToWindow(this.props.node.resource))
      .fork(initializeError, successfulLoad);
  }

  render() {
    return (
      <div className="p3-content p3-conjoint">
        <div className="p3-section">
          <div id="conjoint_introduction">
            <div id="conjoint_rl" className={style.conjointHidden}>
              <p>
                <T kind="The following people have completed your survey." />
              </p>
              <ul />
            </div>
            <div id="conjoint_ns" className={style.conjointHidden}>
              <p>
                <T kind="At this time, no survey responses have been received." />
              </p>
              <p>
                <T kind={`You may want to contact the customers you invited to ...`} />
              </p>
            </div>
            <div id="conjoint_snc" className={style.conjointHidden}>
              <p>
                <T kind="No survey was created in the previous module. If you ..." />
              </p>
            </div>
            <div id="conjoint_nes" className={style.conjointHidden}>
              <p>
                <T
                  kind="Although you can run the analysis with as little ..."
                  emphasis={(content) => <strong>{content}</strong>}
                  surveyCount={<span id="conjoint_required" />}
                />{' '}
                <span className="warning">
                  <T
                    kind="As of now, only <surveyCount/> been returned."
                    surveyCount={<span id="conjoint_returned" />}
                  />
                </span>{' '}
                <T kind="Consider following up with the customers to whom ..." />
              </p>
              <p>
                <T kind="You can return to this segment as often as necessary ..." />
                <T kind={'If you choose to wait to run your analysis, feel free ...'} />
              </p>
            </div>
            <div id="conjoint_es" className={style.conjointHidden}>
              <p>
                <T kind="You can return to this segment as often as necessary ..." />
              </p>
            </div>
            <div id="conjoint_ar" className={style.conjointHidden}>
              <p>
                <strong>
                  <T kind={'The table below has one row for each completed survey ...'} />
                </strong>
              </p>
            </div>
            <div id="conjoint_launch" className={`${style.textCenter} ${style.conjointHidden}`}>
              <button className="btn btn-primary">
                <T kind="Run Analysis" />
              </button>
            </div>
          </div>
          <div
            id="conjoint_analysis"
            className={`${style.conjointAnalysis} ${style.conjointHidden}`}
          />
          <div
            id="conjoint_download"
            className={`${style.conjointHidden} ${style.textCenter} ${style.mt20}`}
          >
            <button>
              <T kind="Download Results as .XLSX" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConjointAnalysisResults;
