import * as React from 'react';
import { TPlainTextKey, translation, TranslationsContext } from '../../../Translations';

interface Props {
  value?: string;
  name: string;
  className?: string;
  onChange(event?: React.FormEvent<HTMLTextAreaElement>): void;
  autoFocus?: boolean;
  placeholder?: TPlainTextKey;
  id?: string;
  title: TPlainTextKey;
  disabled?: boolean;
  onKeyDown?(event: React.KeyboardEvent): void;
}

class Textarea extends React.PureComponent<Props> {
  render() {
    return (
      <TranslationsContext.Consumer>
        {(ts) => (
          <textarea
            value={this.props.value}
            name={this.props.name}
            className={this.props.className ? this.props.className : undefined}
            autoFocus={this.props.autoFocus}
            placeholder={
              this.props.placeholder ? translation(this.props.placeholder)(ts) : undefined
            }
            id={this.props.id}
            title={translation(this.props.title)(ts)}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            disabled={this.props.disabled}
            data-test-textarea={this.props.id}
          />
        )}
      </TranslationsContext.Consumer>
    );
  }
}

export default Textarea;
