import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import { programsStore } from '../../../ProgramsStore';
import EmptyState from './EmptyState';

const NoProgram: React.FC<{}> = () => {
  return (
    <div>
      {programsStore.resource
        .map(({ payload }) => payload.programs)
        .andThen(fromArrayMaybe)
        .map(() => <></>)
        .getOrElseValue(<EmptyState />)}
    </div>
  );
};

export default observer(NoProgram);
