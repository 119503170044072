import { observer } from 'mobx-react';
import * as React from 'react';
import PopoutImpl from './PopoutImpl';
import PopoutStore from './PopoutStore';

interface Props {
  store: PopoutStore;
  personRef: React.RefObject<HTMLDivElement>;
}

const Popout: React.FC<Props> = ({ store, children, personRef }) => {
  const { state } = store;

  switch (state.kind) {
    case 'open':
      return <PopoutImpl personRef={personRef}>{children}</PopoutImpl>;
    case 'closed':
      return <></>;
  }
};

export default observer(Popout);
