import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import Currency from '../../../../../../../../Currency';
import { T } from '../../../../../../../../Translations';
import { ExperienceResource } from '../../../../../Experience/Types';

interface Props {
  experienceResource: ExperienceResource;
}

const currencyOptions: Intl.NumberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

function PaymentOrderSummary({ experienceResource }: Props) {
  return (
    <div className="mt-12">
      {experienceResource.payload.price
        .map((price) => (
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between">
              <Typography className="leading-normal">
                <T kind="Subtotal" />
              </Typography>
              <Typography className="leading-normal">
                <Currency priceInDollars={price / 100} options={currencyOptions} />
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography className="leading-normal">
                <T kind="Tax" />
              </Typography>
              <Typography className="leading-normal">
                <T kind="Included" />
              </Typography>
            </div>
            <div className="my-1.5 flex w-full border-b border-gray-300" />
            <div className="flex justify-between">
              <Typography className="leading-normal">
                <T kind="Total" />
              </Typography>
              <Typography className="leading-normal" fontWeight="bold">
                <Currency priceInDollars={price / 100} options={currencyOptions} />
              </Typography>
            </div>
          </div>
        ))
        .getOrElse(() => (
          <T kind="Something went wrong" />
        ))}
    </div>
  );
}

export default observer(PaymentOrderSummary);
