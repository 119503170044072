import { useContext } from 'react';
import { EnrollmentModalContext } from './Types';

export function useEnrollmentModalContext() {
  const context = useContext(EnrollmentModalContext);

  if (!context) {
    throw new Error(
      'useEnrollmentModalContext has to be used within the EnrollmentModalContextProvider.',
    );
  }

  return context;
}
