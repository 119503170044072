import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../SegmentStore';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import ResourcesTabIcon from '../../Cutesies/ResourcesTabIcon';
import Badge from '../Badge';
import * as style from '../style.module.css';

interface Props {
  tabStore: TabStore;
  segmentStore: SegmentStore;
}

const ResourcesTab: React.FC<Props> = ({ tabStore, segmentStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('resources');
  };

  return (
    <button
      className={`${style.tab} ${tabStore.activeTab === 'resources' ? style.active : ''}`}
      onClick={handleClick}
      data-test-resources-tab={true}
    >
      <span className={style.tabIcon}>
        <ResourcesTabIcon />
      </span>
      <span className={style.tabLabel} data-test-tab-title="Resources">
        <T kind="Resources" />
      </span>
      <Badge count={segmentStore.attachments.length} />
    </button>
  );
};
export default observer(ResourcesTab);
