import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import ConversationStore from '../../../ConversationStore';
import GroupChatImpl from './GroupChatImpl';
import ScrollToBottomStore from './GroupChatImpl/ScrollToBottomStore';

interface Props {
  conversationStore: ConversationStore;
  conferenceRosterStore: ConferenceRosterStore;
  scrollToBottomStore: ScrollToBottomStore;
}

const GroupChat: React.FC<Props> = ({
  conversationStore,
  conferenceRosterStore,
  scrollToBottomStore,
}) => {
  switch (conversationStore.state.kind) {
    case 'loading-next':
    case 'loading-previous':
    case 'load-next-success':
    case 'reloading':
    case 'ready':
    case 'loading':
    case 'waiting':
    case 'load-new-message':
    case 'load-new-reply':
    case 'update-message':
    case 'first-loaded':
    case 'update-reply':
      return (
        <GroupChatImpl
          conversationStore={conversationStore}
          conferenceRosterStore={conferenceRosterStore}
          scrollToBottomStore={scrollToBottomStore}
        />
      );
    case 'error':
      return <span />;
  }
};

export default observer(GroupChat);
