import { observer } from 'mobx-react';
import React from 'react';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { T } from '../../../../../../Translations';
import { Link } from '../../../../../../Resource/Types';
import { ExternalProgramResource } from '../../../../../../SegmentStore/Types';

interface Props {
  externalProgramResource: ExternalProgramResource;
  certLink: Link;
}

const CertificateOfCompletion: React.FC<Props> = ({ externalProgramResource, certLink }) => {
  const schoolPartnerResource = externalProgramResource.payload.schoolPartner;
  return (
    <>
      <div>
        <AlreadyTranslated content={schoolPartnerResource.payload.name} />
      </div>
      <div style={{ marginBottom: 20 }}>
        <T kind="Certificate of Completion" />
      </div>
      <div>
        <a href={certLink.href}>
          <T kind="view sample" />
        </a>
      </div>
    </>
  );
};

export default observer(CertificateOfCompletion);
