import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../../../Translations';
import * as style from './style.module.css';

interface Props {
  remainingDays: number;
}

const FormattedDayCount: React.FC<Props> = ({ remainingDays }) => {
  const digits = remainingDays.toString().padStart(2, '0').split('');
  return (
    <div className={style.daysCounter}>
      {digits.map((digit, i) => (
        <div key={i} className={style.digitWindow}>
          <NotTranslated text={digit} />
        </div>
      ))}
    </div>
  );
};

export default observer(FormattedDayCount);
