import { always } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import AttachmentUploadStore from '../../../../AttachmentUploadStore';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import SimpleFileUploadStore from '../../../../SimpleFileUploadStore';
import SimpleFileUploadReactions from '../../../../SimpleFileUploadStore/SimpleFileUploadReactions';
import { SimpleFileUploadResponse } from '../../../../SimpleFileUploadStore/Types';
import {
  getSignedUrl,
  onUploadError,
  onUploadFinish,
  onUploadStart,
  scrubFilename,
} from '../../../../SimpleFileUploadStore/UploadActions';
import { T } from '../../../../Translations';
import * as style from '../style.module.css';
import Error from './Error';
import UploadsInProgress from './UploadsInProgress';

interface Props {
  store: AttachmentUploadStore;
  currentUserResource: CurrentUserResource;
}

@observer
class SimpleFileUpload extends React.Component<Props, {}> {
  simpleFileUploadStore = new SimpleFileUploadStore();
  uploaderRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    this.simpleFileUploadStore.loading();
  }

  getSignedUrl = (file: File, callback: (payload: SimpleFileUploadResponse) => void) => {
    const { name, type } = file;

    const scrubedName = scrubFilename(name);

    getSignedUrl(scrubedName, type, this.props.currentUserResource, callback, this.props.store);
  };

  onUploadError = (err: string, file: File) => {
    onUploadError(err, file, this.simpleFileUploadStore);
  };

  onUploadFinish = (res: SimpleFileUploadResponse) => {
    onUploadFinish(res, this.simpleFileUploadStore);
    if (this.uploaderRef.current) {
      this.uploaderRef.current.value = '';
    }
  };

  onUploadStart = (file: File, next: (file: File) => void) => {
    onUploadStart(file, next, this.simpleFileUploadStore);
  };

  render() {
    return (
      <div>
        <ReactS3Uploader<SimpleFileUploadResponse>
          inputRef={always(this.uploaderRef)}
          getSignedUrl={this.getSignedUrl}
          onError={this.onUploadError}
          onFinish={this.onUploadFinish}
          preprocess={this.onUploadStart}
          signingUrlWithCredentials={true}
          uploadRequestHeaders={{ 'x-amz-acl': 'bucket-owner-full-control' }}
          contentDisposition="auto"
          autoUpload={true}
          server={process.env.REACT_APP_API_ROOT}
          id="fileInput"
          className={style.fileInput}
        />
        <label htmlFor="fileInput" className={`${style.pickFileButton} ${style.fileInputLabel}`}>
          <T kind="Pick file" />
        </label>
        <SimpleFileUploadReactions
          store={this.simpleFileUploadStore}
          attachmentStore={this.props.store}
        />

        <UploadsInProgress store={this.simpleFileUploadStore} />
        <Error store={this.simpleFileUploadStore} />
      </div>
    );
  }
}

export default SimpleFileUpload;
