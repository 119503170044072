import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../components/UI';
import { T } from '../../Translations';
import TranslatedMessaging from './TranslatedMessaging';
import { UseCaseErrorMessage } from './Types';

interface Props {
  message: UseCaseErrorMessage;
}

const Messaging: React.FC<Props> = ({ message }) => (
  <div className="flex flex-col flex-1 justify-center content-center mx-6">
    <Typography className="text-center" variant="h4" component="p">
      <TranslatedMessaging message={message} />
    </Typography>
    <Typography className="my-6 text-center">
      <T kind="Contact your learning and development team for details to access ExecOnline going forward." />
    </Typography>
  </div>
);

export default observer(Messaging);
