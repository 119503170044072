import { assertNever } from '@kofno/piper';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SurveyModalStore from '../SurveyModal/Modal/Store';
import SurveyModalReactions from '../SurveyModal/Modal/Store/SurveyModalReactions';
import SurveyModalImpl from '../SurveyModal/SurveyModalImpl';
import { SurveyLaunchKind } from './Types';

export interface Props {
  surveyModalStore: SurveyModalStore;
  surveyLaunchkind: SurveyLaunchKind;
}

class CoachingSurveyModal extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): void {
    switch (this.props.surveyLaunchkind) {
      case 'auto-launch':
        this.props.surveyModalStore.open(nothing());
        break;
      case 'manual-launch':
        break;
      default:
        assertNever(this.props.surveyLaunchkind);
    }
  }

  render() {
    const { surveyModalStore } = this.props;
    return (
      <>
        <SurveyModalImpl store={surveyModalStore} />
        <SurveyModalReactions store={surveyModalStore} fireImmediately={true} kettle={nothing()} />
      </>
    );
  }
}

export default observer(CoachingSurveyModal);
