import SurveyModalStore from '../components/SurveyModal/Modal/Store';
import { Error } from '../ErrorHandling';
import { Linkable } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  linkable: Linkable;
}

interface Ready {
  kind: 'ready';
  surveyModalStore: SurveyModalStore;
}
export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (linkable: Linkable): Loading => ({
  kind: 'loading',
  linkable,
});

export const ready = (surveyModalStore: SurveyModalStore): Ready => ({
  kind: 'ready',
  surveyModalStore,
});

export type State = Waiting | Loading | Ready | Error;
