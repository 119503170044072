import { SvgIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function LeadershipPersonaVinnyIcon() {
  return (
    <SvgIcon size="inherit" viewBox="0 0 40 40">
      <path
        fill="#156082"
        fill-opacity=".3"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      />
      <mask id="a" width="40" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#F1C9A5"
          d="M29.578 32.31C28.29 29.87 20 28.338 20 28.338s-8.289 1.531-9.578 3.972C9.47 35.697 8.672 40 8.672 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E4B692"
          d="M20 28.338s8.29 1.531 9.578 3.972c1.212 3.076 1.75 7.69 1.75 7.69H20V28.338Z"
        />
        <path fill="#F1C9A5" d="M22.257 23.377h-4.513v7.552h4.513v-7.552Z" />
        <path fill="#E4B692" d="M22.257 23.377H20v7.552h2.257v-7.552Z" />
        <path
          fill="#DDAC8C"
          d="M17.744 26.876c.405 1.165 2.043 1.833 3.246 1.833a4.07 4.07 0 0 0 1.267-.206v-5.127h-4.513v3.5Z"
          opacity=".1"
        />
        <path
          fill="#F1C9A5"
          d="M25.802 18.61c0-4.717-2.597-7.4-5.802-7.4-3.204 0-5.802 2.683-5.802 7.4 0 6.346 2.598 8.543 5.802 8.543 3.204 0 5.802-2.462 5.802-8.543Z"
        />
        <path
          fill="#E4B692"
          d="M24.919 21.882c-1.052-.11-1.762-1.244-1.584-2.53.176-1.287 1.172-2.243 2.225-2.134 1.051.11 1.76 1.242 1.583 2.53-.177 1.288-1.172 2.243-2.224 2.134Z"
        />
        <path
          fill="#F1C9A5"
          d="M12.899 19.748c-.178-1.288.531-2.42 1.582-2.53 1.053-.11 2.05.846 2.226 2.134.177 1.287-.534 2.42-1.585 2.53-1.052.108-2.047-.847-2.223-2.134Z"
        />
        <path
          fill="#E4B692"
          d="M25.802 18.61c0-4.717-2.597-7.4-5.802-7.4v15.943c3.204 0 5.802-2.462 5.802-8.543Z"
        />
        <path
          fill="#303030"
          d="M25.22 22.565c-.313.877-.84 1.932-1.795 1.932-1.192 0-2.045-.969-3.413-.969h-.024c-1.367 0-2.22.97-3.413.97-.954 0-1.482-1.056-1.794-1.933l-.27-1.69v2.022s.453 2.383 1.323 2.958c.71.63 3.023 1.708 4.154 1.708h.024c1.132 0 3.445-1.078 4.154-1.708.87-.576 1.323-2.958 1.323-2.958v-2.022l-.27 1.69Zm-8.03-10.452s1.078 4.362 7.083 6.569c-.565-.975-.668-2.156-.668-2.156s.976 1.027 2.566 1.848c.873-1.745 1.9-4.362-.41-5.799-2.31-1.437-7.8-4.003-8.57-.462Z"
        />
        <path
          fill="#303030"
          d="M15.19 18.323s.445-4.98 2.3-6.082c-1.582-.69-3.994 1.412-3.738 2.694.257 1.284 1.437 3.388 1.437 3.388Z"
        />
        <path
          fill="#FB621E"
          d="M29.578 32.31c-.753-1.425-3.89-2.54-6.372-3.22A3.212 3.212 0 0 1 20 32.118a3.212 3.212 0 0 1-3.205-3.028c-2.482.68-5.62 1.795-6.373 3.22C9.47 35.697 8.672 40 8.672 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E53D0C"
          d="M16.61 29.919c.102 1.688 1.578 3.028 3.39 3.028s3.288-1.34 3.39-3.028c2.275.59 5.068 1.505 6.292 2.666a14.432 14.432 0 0 0-.104-.275c-.753-1.425-3.891-2.54-6.373-3.22A3.212 3.212 0 0 1 20 32.118a3.212 3.212 0 0 1-3.205-3.028c-2.482.68-5.62 1.795-6.373 3.22l-.068.242c1.244-1.145 4.002-2.05 6.255-2.633Z"
          opacity=".2"
        />
        <path
          fill="#E53D0C"
          d="M15.851 29.362a4.202 4.202 0 0 0 4.15 3.552 4.2 4.2 0 0 0 4.148-3.552c-.32-.097-.637-.188-.944-.272A3.212 3.212 0 0 1 20 32.118a3.212 3.212 0 0 1-3.205-3.028c-.307.084-.623.175-.944.272Z"
        />
        <path
          fill="#fff"
          d="M20.944 25.048s-.138-.615-.944-.615c-.737 0-.944.615-.944.615h1.888Z"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(LeadershipPersonaVinnyIcon);
