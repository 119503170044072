import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { Error } from '../../../../ErrorHandling';
import { Resource } from '../../../../Resource/Types';
import { ProgramResource, ResourceResource } from '../../Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  programResource: ProgramResource;
}

interface Ready {
  kind: 'ready';
  programResourcesResource: ProgramResourcesResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (programResource: ProgramResource): Loading => ({
  kind: 'loading',
  programResource,
});

export const ready = (programResourcesResource: ProgramResourcesResource): Ready => ({
  kind: 'ready',
  programResourcesResource,
});

export type ProgramResourcesState = Waiting | Loading | Ready | Error;

export interface ProgramResourcesSegment {
  id: number;
  longTitle: string;
  resources: Maybe<NonEmptyList<ResourceResource>>;
}

export interface ProgramResourcesModule {
  id: number;
  label: string;
  segments: ProgramResourcesSegment[];
  title: string;
}

export interface ProgramResources {
  id: number;
  modules: ProgramResourcesModule[];
  resources: Maybe<NonEmptyList<ResourceResource>>;
  title: string;
}

export type ProgramResourcesResource = Resource<ProgramResources>;
