import * as React from 'react';
import { observer } from 'mobx-react';
import { Maybe, nothing } from 'maybeasy';
import { ReqHlsVideoAssetResource } from '../../../../../ReqHlsVideo/Types';
import * as style from './style.module.css';
import ModalStore from '../../../../../Modal/Store';
import { Kettle } from 'kettle-corn';
import ReqHlsVideo from '../../../../../ReqHlsVideo';

interface Props {
  modalStore: ModalStore;
  video: Maybe<ReqHlsVideoAssetResource>;
}
const IntroductoryVideo: React.FC<Props> = ({ modalStore, video }) => {
  switch (modalStore.state.kind) {
    case 'closed':
      return video
        .map((v) => (
          <div className={style.videoWrapper}>
            <ReqHlsVideo
              id={v.payload.uuid}
              className="embedded-video"
              videoResource={v}
              kettle={new Kettle()}
              width={'100%'}
              height={170}
              dynamicallySetAspectRatio={true}
            >
              {{
                playButton: nothing(),
              }}
            </ReqHlsVideo>
          </div>
        ))
        .getOrElse(() => <></>);
    case 'open':
      return <></>;
  }
};

export default observer(IntroductoryVideo);
