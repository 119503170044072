import { observer } from 'mobx-react';
import { L, T } from '../../../../../Translations';
import { AvailabilityResource } from '../../Experience/Types';

interface Props {
  availability: AvailabilityResource;
}

function FormattedAvailability({ availability }: Props) {
  switch (availability.payload.kind) {
    case 'scheduled':
      return <L localizeable={availability.payload.date} format={'long-weekday-month-year'} />;
    case 'on-demand':
      return <T kind="On Demand" />;
  }
}

export default observer(FormattedAvailability);
