import { observer } from 'mobx-react';
import * as React from 'react';
import ChannelAvailabilityStore from '../../ChannelAvailabilityStore';
import EventsBinding from './CalendarBinding';
import CalendarStore from '../../../CalendarStore';

interface Props {
  channelAvailabilityStore: ChannelAvailabilityStore;
  calenderStore: CalendarStore;
}

const CalendarHandler: React.FC<Props> = ({ calenderStore, channelAvailabilityStore }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <EventsBinding channel={channel} store={calenderStore} />;
    }
    case 'not-available': {
      return <span />;
    }
  }
};

export default observer(CalendarHandler);
