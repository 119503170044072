import { fromEmpty } from 'maybeasy';
import { DurationResource, ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { FilterOption } from '../CommonFilter/Types';

export type DurationFilterOptions = SomeDuration | NoDuration;

export type SomeDuration = FilterOption & {
  kind: 'some-duration';
};

export type NoDuration = FilterOption & {
  kind: 'no-duration';
  value: 'None';
  label: 'None';
};

const noDuration = (): DurationFilterOptions => ({
  kind: 'no-duration',
  value: 'None',
  label: 'None',
});

const someDuration = (resource: DurationResource): DurationFilterOptions => ({
  kind: 'some-duration',
  value: resource.payload.value.toString(),
  label: resource.payload.label,
});

const durationFilterObject = (resource: DurationResource): DurationFilterOptions =>
  fromEmpty(resource.payload.label)
    .map(() => someDuration(resource))
    .getOrElse(noDuration);

const getDurationOptions = (options: ExperienceFiltersResource): DurationFilterOptions[] =>
  options.payload.durations.map(durationFilterObject);

export const allDurationOptions = (options: ExperienceFiltersResource): DurationFilterOptions[] => [
  ...getDurationOptions(options),
];
