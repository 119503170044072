import { observer } from 'mobx-react';
import { Chip, emptyFragment } from '@execonline-inc/execonline-ui';
import { RegistrationType } from '../../../../../../../ProgramsStore/Types';
import { T } from '../../../../../../../Translations';

interface Props {
  type: RegistrationType;
}

function RegistrationTypeBadge({ type }: Props) {
  switch (type) {
    case 'Auditor':
      return (
        <Chip className="uppercase" size="sm" color="blue" radius="sm">
          <T kind={type} />
        </Chip>
      );
    case 'Coach':
    case 'Professor':
    case 'Student':
      return emptyFragment();
  }
}

export default observer(RegistrationTypeBadge);
