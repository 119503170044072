import { find } from '@execonline-inc/collections';
import { always } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../../../../Person/types';
import {
  PresenceMember,
  OnlineStatus as TOnlineStatus,
} from '../../../../../Socket/PresenceChannel/PresenceMembersStore/Types';
import Icon, { OnlineStatusBorder } from './Icon';

interface Props {
  personStore: PersonStoreContract;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  kind: OnlineStatusBorder;
}

const findMember = ({
  memberId,
  members,
}: {
  memberId: number;
  members: ReadonlyArray<PresenceMember>;
}) => just(members).andThen(find((member) => member.id === memberId));

const onlineStatus = (memberId: number, members: ReadonlyArray<PresenceMember>): TOnlineStatus => {
  return just({ memberId })
    .assign('members', just(members))
    .andThen(findMember)
    .map<TOnlineStatus>(always('online'))
    .getOrElseValue('offline');
};

const OnlineStatus: React.FC<Props> = ({ personStore, presenceStore, kind }) => {
  return presenceStore
    .map((store) => {
      switch (onlineStatus(personStore.id, store.members)) {
        case 'offline':
        case 'not-applicable':
          return null;
        case 'online':
          return <Icon kind={kind} />;
      }
    })
    .getOrElseValue(null);
};

export default observer(OnlineStatus);
