import { map, take } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import FreebusyStore from '../../../FreebusyStore';
import AdDisplayContent from './AdDisplayContent';
import FreebusyCoachingAdReady from './FreebusyCoachingAdReady';
import FreebusyCoachingAdStore from './FreebusyCoachingAdStore';

interface Props {
  store: FreebusyCoachingAdStore;
}

class FreebusyCoachingAdStates extends React.Component<Props> {
  freebusyStore = new FreebusyStore('freebusy-coaching');

  render() {
    const { store } = this.props;
    switch (store.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading':
        return <></>;
      case 'reloading':
        return <AdDisplayContent freebusyStore={this.freebusyStore} />;
      case 'ready':
        return just(store.state.freebusyCoachingAdsResource.payload)
          .map(take(1))
          .map(
            map((freebusyCoachingAdResource) => (
              <WithCurrentUser
                children={(currentUserResource) => (
                  <FreebusyCoachingAdReady
                    freebusyCoachingAdStore={store}
                    freebusyCoachingAdResource={freebusyCoachingAdResource}
                    currentUserResource={currentUserResource}
                  />
                )}
              />
            )),
          )
          .map((ad) => <>{ad}</>)
          .getOrElse(emptyFragment);
    }
  }
}

export default observer(FreebusyCoachingAdStates);
