import { Kettle } from 'kettle-corn';
import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import { VideoState } from 'kettle-corn/Kettle/VideoState';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import EngagementStore from './EngagementStore';
import { videoAssetEndedEngagement } from './EngagementStore/Types';

export interface Props extends RCProps<Kettle> {
  store: Kettle;
  engagementStore: EngagementStore;
  assetId: number;
  programId:number;
}

const ready = (engagementStore: EngagementStore) => () => {
  engagementStore.ready();
};

const createEngagement =
  (engagementStore: EngagementStore, assetId: number, programId: number) => () => {
    const engagement = videoAssetEndedEngagement(assetId, programId);
    engagementStore.create(engagement);
  };

class VideoReactions extends ReactionComponent<Kettle, VideoState, Props> {
  tester = () => this.props.store.videoState;

  effect = (state: VideoState) => {
    const { engagementStore, assetId, programId } = this.props;

    state.cata({
      initialized: noop,
      ready: noop,
      playing: ready(engagementStore),
      paused: noop,
      buffering: noop,
      ended: createEngagement(engagementStore, assetId, programId),
    });
  };
}

export default observer(VideoReactions);
