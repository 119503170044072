import { toTask } from '@execonline-inc/maybe-adapter';
import { noop } from '@kofno/piper';
import { Task } from 'taskarian';
import { handleAnalyticsError, identifyUser } from '.';
import { resourceFromContingentStore } from '../CurrentUser/Context/WhenUserDataLoaded/Types';
import { CurrentUserStore } from '../CurrentUser/Store';
import { organizationStore } from '../Organization/Store';
import { AnalyticsError, ContingentResources, missingValue } from './Types';

const contingentResources = (): Task<AnalyticsError, ContingentResources> =>
  Task.succeed<AnalyticsError, {}>({}).assign(
    'organizationResource',
    toTask(missingValue('organization-resource'), resourceFromContingentStore(organizationStore)),
  );

export const sendUserDataToGtmWithPageTitle = (
  currentUserStore: CurrentUserStore,
  pageTitle: string,
) => {
  switch (currentUserStore.state.kind) {
    case 'ready':
    case 'refreshing':
    case 'logging-out':
      contingentResources()
        .andThen(identifyUser(currentUserStore.state.currentUserResource, pageTitle))
        .fork(handleAnalyticsError, noop);
      break;
    case 'waiting':
    case 'loading':
    case 'anonymous':
    case 'errored':
  }
};
