import { Maybe } from 'maybeasy';
import { AppyError } from '../../../Appy';
import { MissingLinkError } from '../../../LinkyLinky';
import { Resource } from '../../../Resource/Types';
import { SupportedLanguageCode } from '../../../SupportedLanguages/Types';
import { TPlainTextKey } from '../../../Translations';

interface Waiting {
  kind: 'waiting';
}

interface Unsubscribing {
  kind: 'unsubscribing';
  guid: string;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

interface Unsubscribed {
  kind: 'unsubscribed';
  resource: SubscriptionResource;
}

export interface SubscriptionInfo {
  preferredLanguage: Maybe<SupportedLanguageCode>;
}

export type SubscriptionResource = Resource<SubscriptionInfo>;

export type State = Waiting | Unsubscribing | Unsubscribed | Error;

export interface Organization {
  id: number;
  name: string;
}

export type LoadingError = MissingLinkError | AppyError;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const unsubscribing = (guid: string): Unsubscribing => ({
  kind: 'unsubscribing',
  guid,
});

export const error = (message: TPlainTextKey): Error => ({
  kind: 'error',
  message,
});

export const unsubscribed = (resource: SubscriptionResource): Unsubscribed => ({
  kind: 'unsubscribed',
  resource,
});
