import { computed } from 'mobx';
import { now } from 'mobx-utils';
import { err, ok, Result } from 'resulty';

export const everyMinute = computed(() => new Date(now(60 * 1000)));
export const every10Sec = computed(() => new Date(now(10 * 1000)));

export type WeekStarter = 'sunday' | 'monday';

export const startOfDay = (aDate: Date): Date => {
  const workingDate = new Date(aDate.getTime());
  workingDate.setHours(0, 0, 0, 0);
  return workingDate;
};

export const endOfDay = (aDate: Date): Date => {
  const workingDate = new Date(aDate.getTime());
  workingDate.setHours(23, 59, 59, 999);
  return workingDate;
};

export const isSameDay = (leftDate: Date, rightDate: Date): boolean => {
  const left = startOfDay(leftDate);
  const right = startOfDay(rightDate);
  return left.getTime() === right.getTime();
};

export const isBefore = (leftDate: Date, rightDate: Date): boolean =>
  leftDate.getTime() < rightDate.getTime();

export const isAfter = (leftDate: Date, rightDate: Date): boolean =>
  leftDate.getTime() > rightDate.getTime();

export const addMinutes = (aDate: Date, minutes: number): Date => {
  const workingDate = new Date(aDate.getTime());
  workingDate.setMinutes(workingDate.getMinutes() + minutes);
  return workingDate;
};

export type DayOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export const firstDayOfMonth = (aDate: Date): Date =>
  new Date(aDate.getFullYear(), aDate.getMonth(), 1);

export const lastDayOfMonth = (aDate: Date): Date =>
  new Date(aDate.getFullYear(), aDate.getMonth() + 1, 0);

const valueOfWeekStartsOn = (weekStartsOn: WeekStarter): number => {
  switch (weekStartsOn) {
    case 'monday':
      return 1;
    case 'sunday':
      return 0;
  }
};

export const startOfWeek = (weekStartsOn: WeekStarter, aDate: Date): Date => {
  const workingDate = startOfDay(aDate);
  workingDate.setDate(
    workingDate.getDate() - ((7 + workingDate.getDay() - valueOfWeekStartsOn(weekStartsOn)) % 7)
  );
  return workingDate;
};

export const endOfWeek = (weekStartsOn: WeekStarter, aDate: Date): Date => {
  const workingDate = startOfWeek(weekStartsOn, aDate);
  workingDate.setDate(workingDate.getDate() + 6);
  return workingDate;
};

export interface InvalidDateRange {
  kind: 'invalid-date-range';
  message: string;
}

export interface DateRange {
  beginning: Date;
  end: Date;
}

export const dateRange = (beginning: Date, end: Date): Result<InvalidDateRange, DateRange> => {
  if (beginning.getTime() > end.getTime()) {
    return err<InvalidDateRange, DateRange>({
      kind: 'invalid-date-range',
      message: 'The beginning date in a range must come before the end date',
    });
  }
  return ok({ beginning, end });
};

export const maxDate = (dates: Date[]): Date =>
  new Date(Math.max.apply(null, dates.map(d => d.valueOf())));

export const minDate = (dates: Date[]): Date =>
  new Date(Math.min.apply(null, dates.map(d => d.valueOf())));

export const rangeIntersection = (
  left: DateRange,
  right: DateRange
): Result<InvalidDateRange, DateRange> => {
  const beginning = maxDate([left.beginning, right.beginning]);
  const end = minDate([left.end, right.end]);

  return dateRange(beginning, end);
};

export const rangeCenter = (range: DateRange): Date =>
  new Date((range.beginning.getTime() + range.end.getTime()) / 2);

export const eachDay = ({ beginning, end }: DateRange): ReadonlyArray<Date> => {
  const days = [];
  const workingDate = startOfDay(beginning);
  const workingEnd = endOfDay(end);

  while (workingDate.getTime() < workingEnd.getTime()) {
    days.push(new Date(workingDate.getTime()));
    workingDate.setDate(workingDate.getDate() + 1);
  }

  return days;
};

export const between = (date: Date) => ({ beginning, end }: DateRange): boolean => {
  return isBefore(beginning, date) && isBefore(date, end);
};

export interface NotDivisibleIntoWeeks {
  kind: 'not-divisible-into-weeks';
  message: string;
}

export type Week = [Date, Date, Date, Date, Date, Date, Date];

type Weeks = ReadonlyArray<Week>;

export const asWeeks = (days: ReadonlyArray<Date>): Result<NotDivisibleIntoWeeks, Weeks> => {
  if (days.length % 7 !== 0) {
    return err<NotDivisibleIntoWeeks, Weeks>({
      kind: 'not-divisible-into-weeks',
      message: `Cannot break ${days.length} days evenly into weeks`,
    });
  } else {
    const weeks = [];
    for (let i = 0; i < days.length; ) {
      weeks.push(days.slice(i, (i += 7)) as Week);
    }
    return ok(weeks);
  }
};
