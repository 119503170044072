import { observer } from 'mobx-react';
import { DiscoveryStateProvider } from '../Common';
import UseCaseDropdownMenu from './UseCaseDropdownMenu';

function UseCaseMenu() {
  return (
    <DiscoveryStateProvider>
      {({ items }): React.ReactElement => {
        return <UseCaseDropdownMenu items={items} />;
      }}
    </DiscoveryStateProvider>
  );
}

export default observer(UseCaseMenu);
