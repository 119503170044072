import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';
import { fromBool } from '@execonline-inc/maybe-adapter';

interface Props {
  active: boolean;
}

const UnderlineIcon: React.FC<Props> = ({ active }) => {
  const accentColor = fromBool(active, {})
    .map(() => 'currentColor')
    .getOrElseValue(defaultTheme.variables['icon-accent-on-light']);

  return (
    <SVGIcon viewBox="0 0 10 14">
      <g fill="none">
        <path
          fill="currentColor"
          d="M4.2 0 0 11.2h1.4l1.313-3.5h4.374L8.4 11.2h1.4L5.6 0H4.2Zm.7 1.866L6.562 6.3H3.237L4.9 1.866Z"
        />
        <path fill={accentColor} d="M0 12.6V14h9.8v-1.4z" />
      </g>
    </SVGIcon>
  );
};

export default observer(UnderlineIcon);
