import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import EngagementStore from '../../EngagementStore';
import EngagementReactions from '../../EngagementStore/Reactions';
import { Engagement } from '../../EngagementStore/Types';

interface Props {
  engagement: Engagement;
}

class EngageableView extends React.Component<Props> {
  engagementStore = new EngagementStore();

  componentDidMount() {
    this.engagementStore.create(this.props.engagement);
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            {this.props.children}
            <EngagementReactions
              store={this.engagementStore}
              currentUserResource={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default observer(EngageableView);
