import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { ExperienceResources } from '../../../../Common/Experience/Types';
import { ListViewProgramExperience } from '../../../../Common/ProgramExperience';
import EnrollmentStore from '../../../Enrollment/Store';
import NoExperiences from '../Common/NoExperiences';
import { useExperiencesContext } from '../ExperiencesContext';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
}

function ListExperiences({ experienceResources, invitationUuid, enrollmentStore }: Props) {
  const experiencesStore = useExperiencesContext();

  return fromEmpty(experienceResources)
    .map((experienceResources) => (
      <div className="mt-6 flex flex-col gap-3 sm:mt-14">
        {experienceResources.map((experienceResource) => (
          <ListViewProgramExperience
            key={experienceResource.payload.experienceId}
            invitationUuid={invitationUuid}
            experienceResource={experienceResource}
            experiencesStore={experiencesStore}
            enrollmentStore={enrollmentStore}
          />
        ))}
      </div>
    ))
    .getOrElse(() => <NoExperiences searchValue={nothing()} />);
}

export default observer(ListExperiences);
