import * as React from 'react';
import { observer } from 'mobx-react';
import SelectField from './SelectField';
import { FormFieldAssetResource } from '../Types';
import SegmentStore from '../../../SegmentStore';
import { Maybe } from 'maybeasy';
import StringField from './StringField';

interface Props {
  segmentStore: Maybe<SegmentStore>;
  formFieldAssetResource: FormFieldAssetResource;
}

const FormField: React.FC<Props> = ({ segmentStore, formFieldAssetResource }) =>
  segmentStore
    .map((segmentStore) => {
      switch (formFieldAssetResource.payload.fieldDescription.kind) {
        case 'string':
          return (
            <StringField
              segmentStore={segmentStore}
              fieldDescription={formFieldAssetResource.payload.fieldDescription}
              uuid={formFieldAssetResource.payload.uuid}
            />
          );
        case 'multiple-selection':
          return (
            <SelectField
              segmentStore={segmentStore}
              fieldDescription={formFieldAssetResource.payload.fieldDescription}
              uuid={formFieldAssetResource.payload.uuid}
            />
          );
      }
    })
    .getOrElse(() => <></>);

export default observer(FormField);
