import * as React from 'react';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';

interface Props {}

const SurveyCompleted: React.FC<Props> = () => (
  <div data-test-survey-segment={true}>
    <h3>
      <T kind="Thank you!" />
    </h3>
    <p data-test-survey-segment-complete={true}>
      <T kind="This segment has been completed." />
    </p>
  </div>
);

export default observer(SurveyCompleted);
