import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';
import Exit from '../../../../../../Exit';
import { ExitStore } from '../../../../../Common/Experience/Actions';
import { ExitContext } from '../../../../../Common/Experience/Actions/Exitable/Types';
import { ExperienceIntent } from './Types';

interface Props {
  intent: ExperienceIntent;
  exitContext: ExitContext;
}

function ButtonExperience({ exitContext, intent }: Props) {
  const exitStore = useMemo(() => new ExitStore(), []);

  switch (exitStore.state.kind) {
    case 'ready':
      return (
        <Button
          className="p-0 text-sm font-semibold hover:bg-transparent"
          data-testid={intent}
          variant="text"
          color="primary"
          onClick={() => exitStore.exit(exitContext)}
        >
          <T kind={intent} />
        </Button>
      );
    case 'exiting':
      return <Exit exitContext={exitStore.state.exitContext} />;
  }
}

export default observer(ButtonExperience);
