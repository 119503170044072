import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import DynamicHeading from '../../../Accessibility/DynamicHeading';
import { ExpandableItem } from '../../../ExpandableItemStore';
import { NotTranslated } from '../../../Translations';
import ExpandModuleIcon from './ExpandModuleIcon';
import ProgramResourcesStore from './ProgramResourcesStore';
import { ProgramResourcesModule as ProgramResourcesModuleType } from './ProgramResourcesStore/Types';
import ResourceCount from './ResourceCount';
import * as style from './style.module.css';

interface Props {
  expandableModule: ExpandableItem<ProgramResourcesModuleType>;
  store: ProgramResourcesStore;
  toggle: (expandableModule: ExpandableItem<ProgramResourcesModuleType>) => void;
  children: React.ReactNode;
  headingLevel: 3 | 4;
}

class ProgramResourcesModule extends React.Component<Props> {
  render() {
    const { expandableModule, store, toggle, children, headingLevel } = this.props;
    const module = expandableModule.item;

    const moduleExpandedClass = (isModuleExpanded: boolean) =>
      isModuleExpanded ? style.moduleExpanded : '';

    return (
      <div className={style.module} data-test-module={module.id}>
        <div
          className={`${style.details} ${moduleExpandedClass(expandableModule.open)}`}
          onClick={() => toggle(expandableModule)}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(() => toggle(expandableModule))}
        >
          <div className={style.leftDetailsWrapper}>
            <ExpandModuleIcon expandableModule={expandableModule} />
            <DynamicHeading level={headingLevel} className={style.title}>
              <span className={style.label}>
                <NotTranslated text={module.label} />:
              </span>{' '}
              <NotTranslated text={module.title} />
            </DynamicHeading>
          </div>
          <ResourceCount store={store} module={module} />
        </div>
        {expandableModule.open && children}
      </div>
    );
  }
}

export default observer(ProgramResourcesModule);
