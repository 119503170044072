import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, ButtonProps } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import Exit from '../../../../../Exit';
import ExitStore from './ExitStore';
import { ExitContext } from './Types';

interface Props {
  exitTo: () => ExitContext;
  buttonProps: ButtonProps;
  children?: React.ReactNode;
}

function Exitable({ exitTo, children, buttonProps }: Props) {
  const { current: exitStore } = React.useRef(new ExitStore());

  switch (exitStore.state.kind) {
    case 'ready':
      return (
        <Button color="gray" {...buttonProps} onClick={() => exitStore.exit(exitTo())}>
          {children}
        </Button>
      );
    case 'exiting':
      return (
        <Button color="gray" {...buttonProps} onClick={() => exitStore.exit(exitTo())}>
          <T kind="Loading..." />
          <Exit exitContext={exitStore.state.exitContext} />
        </Button>
      );
  }
}

export default observer(Exitable);
