import { Typography } from '@execonline-inc/execonline-ui';
import * as React from 'react';
import { T, TPlainTextKey } from '../../Translations';

interface Props {
  title: TPlainTextKey;
  description: TPlainTextKey;
}

const EmptyResourcePage: React.FC<Props> = ({ title, description }) => (
  <div className="mt-6" data-test-empty-resource={true}>
    <Typography fontWeight="bold" data-test-empty-resource-title={title}>
      <T kind={title} />
    </Typography>
    <Typography className="my-6" data-test-empty-resource-description={description}>
      <T kind={description} />
    </Typography>
    <Typography fontWeight="bold">
      <T
        kind="Need help or have a question? Contact ..."
        emailAddress={
          <a className="underline" href="mailto:support@execonline.com">
            support@execonline.com
          </a>
        }
      />
    </Typography>
  </div>
);

export default EmptyResourcePage;
