import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, field, oneOf, succeed } from 'jsonous';
import { errorDecoder, resourceDecoder } from '../../Resource/Decoders';
import {
  ResetPassword,
  ResetPasswordFailed,
  ResetPasswordResource,
  ResetPasswordSucceeded,
} from '../Types';

const passwordFailedDecoder: Decoder<ResetPasswordFailed> = succeed({})
  .assign('kind', field('kind', stringLiteral('failed')))
  .assign('errors', field('errors', array(errorDecoder)));

const passwordSucceededDecoder: Decoder<ResetPasswordSucceeded> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('success'))
);

export const resetPasswordDecoder = oneOf<ResetPassword>([
  passwordFailedDecoder.map<ResetPassword>(identity),
  passwordSucceededDecoder.map<ResetPassword>(identity),
]);

export const resetPasswordResourceDecoder: Decoder<ResetPasswordResource> = resourceDecoder(
  resetPasswordDecoder
);
