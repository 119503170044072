import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import BreakoutChannelImpl from '../../BreakoutChannelImpl';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import BroadcastMessageStore from '../../BroadcastMessageStore';
import ChatablePersonResourcesStore from '../../ChatableResourcesStore';
import { EventResource } from '../../EventsStore/Types';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import { Breakout } from '../../TeamsStore/Types';
import EventReady from './EventReady';

interface Props {
  breakoutRoom: Breakout;
  broadcastMessageStore: BroadcastMessageStore;
  eventResource: EventResource;
  timerStore: BreakoutTimerStore;
  chatablePersonResourcesStore: ChatablePersonResourcesStore;
}

function BreakoutWithTimer({
  breakoutRoom,
  broadcastMessageStore,
  eventResource,
  timerStore,
  chatablePersonResourcesStore,
}: Props) {
  const breakoutStore = useConst(() => new BreakoutChannelStore(breakoutRoom));

  return (
    <BreakoutChannelImpl
      channelName={`event-${breakoutStore.breakout.eventId}`}
      publicChannelStore={breakoutStore}
    >
      <EventReady
        eventResource={eventResource}
        broadcastMessageStore={broadcastMessageStore}
        timerStore={timerStore}
        chatablePersonResourcesStore={chatablePersonResourcesStore}
        breakoutStore={breakoutStore}
      />
    </BreakoutChannelImpl>
  );
}

export default observer(BreakoutWithTimer);
