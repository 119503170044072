import { Kettle } from 'kettle-corn';
import { computed } from 'mobx';
import { LectureCommentaryResource } from '../LectureStore/Types';
import { LectureSegment } from '../LectureStore/Types';

type Seconds = number;

const isCurrent = (time: Seconds) => (c: LectureCommentaryResource): boolean =>
  c.payload.startAt <= time && c.payload.endAt > time;

const revSort = (c1: LectureCommentaryResource, c2: LectureCommentaryResource): number => {
  if (c1.payload.startAt > c2.payload.startAt) {
    return -1;
  }
  if (c1.payload.startAt < c2.payload.startAt) {
    return 1;
  }
  return 0;
};

class CommentariesStore {
  constructor(private segment: LectureSegment, private kettle: Kettle) {}

  @computed
  get current(): LectureCommentaryResource[] {
    return this.kettle.videoState.position
      .map(pos => isCurrent(pos))
      .map(fn => this.segment.commentaries.filter(fn).sort(revSort))
      .getOrElseValue([]);
  }
}

export default CommentariesStore;
