import { SvgIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function LeadershipPersonaCharlieIcon() {
  return (
    <SvgIcon size="inherit" viewBox="0 0 40 40">
      <path
        fill="#C00000"
        fill-opacity=".3"
        d="M20.667 40c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20Z"
      />
      <mask id="a" width="41" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M20.667 40c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#F1C9A5"
          d="M30.244 32.31c-1.289-2.441-9.578-3.973-9.578-3.973s-8.288 1.532-9.578 3.972c-.952 3.387-1.75 7.69-1.75 7.69h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E4B692"
          d="M20.666 28.337s8.29 1.532 9.578 3.972c1.212 3.076 1.75 7.69 1.75 7.69H20.667V28.337Z"
        />
        <path fill="#F1C9A5" d="M22.923 23.376H18.41v7.553h4.513v-7.553Z" />
        <path fill="#E4B692" d="M22.923 23.376h-2.256v7.553h2.256v-7.553Z" />
        <path
          fill="#DDAC8C"
          d="M18.41 26.876c.406 1.165 2.044 1.833 3.247 1.833.435 0 .858-.073 1.266-.207v-5.126H18.41v3.5Z"
          opacity=".1"
        />
        <path
          fill="#fff"
          d="M30.244 32.31c-.753-1.426-3.89-2.54-6.372-3.221a3.212 3.212 0 0 1-3.206 3.028 3.212 3.212 0 0 1-3.205-3.028c-2.482.68-5.62 1.795-6.373 3.22-.953 3.387-1.75 7.69-1.75 7.69h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#F1C9A5"
          d="M26.469 18.61c0-4.717-2.598-7.4-5.803-7.4-3.204 0-5.802 2.682-5.802 7.4 0 1.976.605 6.352 1.637 7.244.709.63 3.022 1.709 4.154 1.709l.012-.001h.011c1.132 0 3.446-1.078 4.154-1.708 1.073-1.133 1.637-5.43 1.637-7.244Z"
        />
        <path
          fill="#E4B692"
          d="M20.667 27.562h.011c1.132 0 3.446-1.078 4.154-1.708 1.073-1.133 1.637-5.43 1.637-7.244 0-4.717-2.598-7.4-5.803-7.4v16.352Z"
        />
        <path
          fill="#E4B692"
          d="M25.585 21.881c-1.051-.11-1.762-1.243-1.583-2.53.176-1.287 1.172-2.243 2.224-2.133 1.052.109 1.76 1.242 1.583 2.53-.177 1.287-1.172 2.242-2.224 2.133Z"
        />
        <path
          fill="#F1C9A5"
          d="M13.565 19.747c-.178-1.287.532-2.42 1.582-2.53 1.053-.11 2.05.847 2.226 2.135.178 1.286-.533 2.42-1.584 2.53-1.052.108-2.048-.847-2.224-2.135Z"
        />
        <path
          fill="#FB6020"
          d="M11.088 32.31c-.952 3.386-1.75 7.69-1.75 7.69h8.228c-1.397-6.163-1.45-10.506-1.45-10.514-2.161.684-4.406 1.648-5.028 2.824ZM30.244 32.31c1.29 3.186 1.75 7.69 1.75 7.69h-8.227c1.396-6.163 1.45-10.506 1.45-10.514 2.161.684 4.406 1.648 5.027 2.824Z"
        />
        <path
          fill="#915F2C"
          d="M15.22 21.751s.847 2.854 1.764 3.339h.741l.918-1.3 2.011-.56v4.333h-2.611l-2.612-2.345-.212-3.467ZM26.102 21.751s-.848 2.854-1.765 3.339h-.741l-.918-1.3-2.012-.56v4.333h2.612l2.612-2.345.212-3.467Z"
        />
        <path
          fill="#fff"
          d="M21.597 24.657s-.138-.616-.944-.616c-.737 0-.944.616-.944.616h1.888Z"
        />
        <path
          fill="#915F2C"
          d="M14.873 17.231s.77.16 1.266.738c-.087-1.01-.282-4.367.945-5.21 1.227-.841 1.8.037 3.582.037v-2.454H17.77c-.754 0-3.15.36-3.15 2.622s.253 4.267.253 4.267ZM26.46 17.231s-.77.16-1.266.738c.087-1.01.281-4.367-.945-5.21-1.227-.841-1.8.037-3.583.037v-2.454h2.896c.755 0 3.15.36 3.15 2.622s-.253 4.267-.253 4.267Z"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(LeadershipPersonaCharlieIcon);
