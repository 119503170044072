import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Loader from '../../components/Loader';
import { TPlainTextKey } from '../../Translations';

interface Props {
  message: TPlainTextKey;
}

const LoadingMessage: React.FC<Props> = ({ message }) => (
  <div className="flex items-center justify-center h-screen">
    <Loader text={just(message)} />
  </div>
);

export default observer(LoadingMessage);
