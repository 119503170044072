import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgramModule, Program } from '../../../../../ProgramStore/Types';
import CohortPosition from './CohortPosition';

type State = {
  modalIsOpen: boolean;
};

interface Props {
  programModule: ActiveProgramModule;
  programDetail: Program;
}

@observer
class CurrentCohortModule extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  toggleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen,
    });
  };

  render() {
    const { programModule, programDetail } = this.props;
    return programModule.expectedModule
      .andThen((expected) => (expected ? just(expected) : nothing<boolean>()))
      .andThen(() => programModule.expectedPercentComplete)
      .map(() => (
        <CohortPosition
          onToggle={this.toggleModal}
          isModalOpen={this.state.modalIsOpen}
          programDetail={programDetail}
          programModule={programModule}
        />
      ))
      .getOrElse(() => <span />);
  }
}

export default CurrentCohortModule;
