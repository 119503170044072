import { cn, Typography, TypographyProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function ExperienceSectionTitle({ className, children, ...others }: TypographyProps) {
  return (
    <Typography
      data-testid="learning-collection-experience-section-title"
      className={cn('!p-0 tracking-normal text-black', className)}
      variant="h5"
      as="h2"
      {...others}
    >
      {children}
    </Typography>
  );
}

export default observer(ExperienceSectionTitle);
