import { observer } from 'mobx-react';
import * as React from 'react';
import { LoginStore } from '../../Login/Store';
import LockoutNotice from './LockoutNotice';
import Loading from '../../components/Loading';

interface Props {
  store: LoginStore;
}

const LoginView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'locked-out':
      return <LockoutNotice />;
    case 'successful-login':
      return <Loading />;
    default:
      // As a part of deleting react native completely from the project this default case is just a temporary
      return <></>;
  }
};

export default observer(LoginView);
