import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';

import { assertNever } from '@kofno/piper';
import ScheduleSessionStore from '.';
import { AppyError, callApi } from '../../../../../../Appy';
import ErrorActionableReaction, {
  EAProps,
  handleError,
} from '../../../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../../../LinkyLinky';
import { Link } from '../../../../../../Resource/Types';
import { freebusyAdResourceDecoder } from '../../../../../Dashboard/FreebusyCoachingAd/Decoders';
import { State } from './Types';

interface Props extends EAProps<ScheduleSessionStore> {
  store: ScheduleSessionStore;
}

type ScheduleSessionError = AppyError | MissingLinkError;

const handleFreebusyCoachingError =
  (store: ScheduleSessionStore) => (error: ScheduleSessionError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify('ScheduleSessionReactions', `Missing link: ${error.rel}`, toJS(store.state));
        break;
      default:
        handleError(store, error);
    }
  };

class ScheduleSessionReactions extends ErrorActionableReaction<ScheduleSessionStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
      case 'reloading':
        Task.succeed<ScheduleSessionError, ReadonlyArray<Link>>(
          store.programStore.productDetailsLinks,
        )
          .andThen(findLinkT('freebusy-coaching'))
          .andThen(callApi(freebusyAdResourceDecoder, {}))
          .fork(handleFreebusyCoachingError(this.props.store), store.ready);
        break;
      case 'error':
      case 'ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(ScheduleSessionReactions);
