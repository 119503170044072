import { observer } from 'mobx-react';
import { ExternalProgramResource } from '../../../../SegmentStore/Types';
import Completed from './Completed';
import Started from './Started';
import Unstarted from './Unstarted';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

function CallToAction({ externalProgramResource }: Props) {
  switch (externalProgramResource.payload.kind) {
    case 'completed':
      return <Completed completedExternalProgram={externalProgramResource.payload} />;
    case 'started':
      return <Started startedExternalProgram={externalProgramResource.payload} />;
    case 'unstarted':
      return <Unstarted externalProgramResource={externalProgramResource} />;
  }
}

export default observer(CallToAction);
