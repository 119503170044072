import { NotTranslated } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import * as style from '../style.module.css';
import { observer } from 'mobx-react';
import { DisplayItemDivProps } from './DisplayItemDiv';

interface DisplayItemSpanProps extends DisplayItemDivProps {
  separator: Maybe<string>;
  className: Maybe<string>;
}

const DisplayItemSpan: React.FC<DisplayItemSpanProps> = ({ item, separator, className }) => (
  <span className={`${style.ellipsesText} ${className.map((c) => c).getOrElseValue('')}`}>
    <NotTranslated text={item} />
    {separator.map((c) => <NotTranslated text={c} />).getOrElseValue(<></>)}
  </span>
);

export default observer(DisplayItemSpan);
