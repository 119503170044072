import { observer } from 'mobx-react';
import * as React from 'react';
import { InvitationActivation } from '../../../../DiscoveryStore/Types';
import RegistrationTypeBadge from './RegistrationTypeBadge';

interface Props {
  registrationType: InvitationActivation;
}

const RegistrationType: React.FC<Props> = ({ registrationType }) => {
  switch (registrationType.kind) {
    case 'active-invitation':
      return <RegistrationTypeBadge type={registrationType.type} />;
    case 'inactive-invitation':
      return <></>;
  }
};

export default observer(RegistrationType);
