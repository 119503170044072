import { observer } from 'mobx-react';
import * as React from 'react';
import { ResourcesDashboardResource } from '../Types';
import LiveMeetingRecordingsResourcesView from './LiveMeetingRecordingsResourcesView';
import LiveMeetingRecordingsStore from './LiveMeetingRecordingsStore';
import LiveMeetingRecordingsResourcesReactions from './LiveMeetingRecordingsStore/LiveMeetingRecordingsReactions';

interface Props {
  resource: ResourcesDashboardResource;
}

class LiveMeetingRecordings extends React.Component<Props> {
  store = new LiveMeetingRecordingsStore();

  componentDidMount() {
    this.store.load(this.props.resource.links);
  }

  render() {
    return (
      <>
        <LiveMeetingRecordingsResourcesView store={this.store} />
        <LiveMeetingRecordingsResourcesReactions store={this.store} />
      </>
    );
  }
}

export default observer(LiveMeetingRecordings);
