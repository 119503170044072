import { groupBy, toPairs } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ChatMessageReactionResource,
  ChatMessageReactionsResource,
} from '../../../ConversationStore/Types';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import ActiveMessageReaction from './ActiveMessageReaction';
import MessageReactionStore from './MessageReactionStore';

interface Props {
  reactionsResource: ChatMessageReactionsResource;
  reactionStore: MessageReactionStore;
}

const ActiveMessageReactionList: React.FC<Props> = ({ reactionsResource, reactionStore }) => {
  return (
    <WithCurrentUser
      children={(currentUserResource) =>
        toPairs(
          groupBy((reaction: ChatMessageReactionResource) => reaction.payload.emoji)(
            reactionsResource.payload
          )
        )
          .map(([emoji, chatMessageReactionResources]) => ({ emoji, chatMessageReactionResources }))
          .map((reaction) => (
            <ActiveMessageReaction
              key={reaction.emoji}
              reactionListItem={reaction}
              reactionStore={reactionStore}
              currentUserResource={currentUserResource}
            />
          ))
      }
    />
  );
};

export default observer(ActiveMessageReactionList);
