import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import { T } from '../../../../../Translations';
import Certificate from './Certificate';
import TimeCommitments from './TimeCommitments';
import WhoShouldAttend from './WhoShouldAttend';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const AtAGlanceSection: React.FC<Props> = ({ externalProgramResource }) => (
  <div>
    <div className="mb-8 text-3xl font-light">
      <T kind="At a Glance" />
    </div>
    <div className="flex justify-between">
      {externalProgramResource.payload.whoShouldAttendHtml
        .map((whoShouldAttendHtml) => <WhoShouldAttend whoShouldAttendHtml={whoShouldAttendHtml} />)
        .getOrElse(emptyFragment)}
      {externalProgramResource.payload.timeCommitment
        .map((timeCommitment) => <TimeCommitments timeCommitment={timeCommitment} />)
        .getOrElse(emptyFragment)}
      <Certificate externalProgramResource={externalProgramResource} />
    </div>
  </div>
);

export default observer(AtAGlanceSection);
