import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import { T } from '../../Translations';
import LinkToSupport from './LinkToSupport';
import NoSupportLink from './NoSupportLink';
import * as style from './style.module.css';

const NeedAssistance: React.FC = () => {
  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <div className={style.content}>
          <h2 className={style.subtitleLightTheme}>
            <T kind="Need Assistance?" />
          </h2>
          {findLink('support', currentUserResource.links)
            .map((l) => <LinkToSupport link={l} />)
            .getOrElse(() => (
              <NoSupportLink />
            ))}
        </div>
      )}
    />
  );
};

export default observer(NeedAssistance);
