import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import EmbedByProvider from './EmbedByProvider';
import * as style from './style.module.css';

const ConferenceEmbed: React.FC<{
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
}> = ({ conferenceRoomResource, conferenceRosterStore }) => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <div className={style.conference}>
        <div id="video-container">
          <EmbedByProvider
            conferenceRoomResource={conferenceRoomResource}
            conferenceRosterStore={conferenceRosterStore}
            currentUserResource={currentUserResource}
          />
        </div>
      </div>
    )}
  />
);

export default observer(ConferenceEmbed);
