import * as React from 'react';
import { observer } from 'mobx-react';

import * as style from '../../style.module.css';
import { T } from '../../../../../Translations';
import FreebusyStore from '../../../../../FreebusyStore';
import ArrowRightIcon from '../../../../Cutesies/ArrowRightIcon';

interface Props {
  freebusyStore: FreebusyStore;
}

class Button extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.freebusyStore.loading();
  }

  render() {
    return (
      <button
        className={style.continueButton}
        data-test-coaching-orientation-button={'Schedule a Session'}
        id="freebusy-leadership-coaching-button"
        disabled={this.props.freebusyStore.schedulingDisabled}
      >
        <T kind={'Schedule a Session'} />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </button>
    );
  }
}

export default observer(Button);
