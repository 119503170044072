import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';

interface Props {
  completedCount: number;
  remainingCount: number;
}
const SegmentsCompletedSome: React.FC<Props> = ({ completedCount, remainingCount }) => {
  return (
    <span data-test-segments-completed={completedCount}>
      <T
        kind="{{count}} step completed, {{remainingCount}} remaining"
        count={completedCount}
        remainingCount={remainingCount}
      />
    </span>
  );
};

export default observer(SegmentsCompletedSome);
