import { observer } from 'mobx-react';
import * as React from 'react';
import { TPlainTextKey } from '../../Translations';
import { ExitStore } from '../AEP/Common/Experience/Actions';
import Exit from '../Exit';
import Messaging from './Messaging';

interface Props {
  message: TPlainTextKey;
}

class ErrorMessage extends React.Component<Props> {
  exitStore = new ExitStore();

  render() {
    switch (this.exitStore.state.kind) {
      case 'ready':
        return <Messaging message={this.props.message} exitStore={this.exitStore} />;
      case 'exiting':
        return (
          <>
            <Messaging message={this.props.message} exitStore={this.exitStore} />
            <Exit exitContext={this.exitStore.state.exitContext} />
          </>
        );
    }
  }
}

export default observer(ErrorMessage);
