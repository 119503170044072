import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import { VideoSourceFile } from '../JWPlayer/Types';
import JWPlayer from './JWPlayer';
import { playerOptions } from './JWPlayer/Types';
import { ReqHlsVideoAssetResource } from './Types';

interface Props {
  id: string;
  className: string;
  videoResource: ReqHlsVideoAssetResource;
  kettle: Kettle;
  width: string | number;
  height: string | number;
  dynamicallySetAspectRatio?: boolean;
  sources: VideoSourceFile[];
}

/**
 * ProgressivePlayer wraps JWPlayer. Video sources must be explicitly passed
 * to the component since sources will vary depending on the user's situation
 * (browser and/or server's context).
 */
const ReqHlsProgressivePlayer: React.FC<Props> = ({
  id,
  className,
  videoResource,
  kettle,
  width,
  height,
  dynamicallySetAspectRatio,
  sources,
}) => {
  const options = playerOptions(videoResource, {
    width,
    height,
    dynamicallySetAspectRatio,
    sources,
    file: '',
  });

  return <JWPlayer id={id} className={className} kettle={kettle} options={options} />;
};

export default observer(ReqHlsProgressivePlayer);
