import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { callApi } from '../Appy';
import { LocaleChangeError } from '../ChangeLocale';
import { CurrentUserResource } from '../CurrentUser/Types';
import { handleAppyError } from '../ErrorHandling';
import { warnAndNotify } from '../Honeybadger';
import { findLinkT } from '../LinkyLinky';
import { changeLocale } from '../LocaleChanger';
import { locationReload } from '../Location';
import { languageResourceDecoder } from '../ProfileFormStore/Decoders';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { Link } from '../Resource/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import {
  DetectionError,
  PreferenceUpdateError,
  PreferredLanguageNotEstablished,
  UnsupportedBrowserLanguage,
} from './Errors';
import {
  whenBrowserLanguageChangeNeeded,
  whenBrowserPreferredLanguageValid,
  whenUserPreferredLanguagePresent,
} from './Functions';
import { State } from './States';
import { PreferredLanguageSelectionStore } from './Store';

interface Props extends RCProps<PreferredLanguageSelectionStore> {}

export class PreferredLanguageSelectionReactions extends ReactionComponent<
  PreferredLanguageSelectionStore,
  State,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'detecting-preferred-language':
        Task.succeed<DetectionError, {}>({})
          .andThen(() => handleUserPreferredLanguage(state.currentUserResource, store))
          .orElse(() => handleBrowserPreferredLanguage(state.currentUserResource, store))
          .fork(store.requiresUserSelection, noop);
        break;
      case 'requires-user-selection':
        break;
      case 'updating-preference':
        Task.succeed<PreferenceUpdateError, ReadonlyArray<Link>>(state.currentUserResource.links)
          .andThen(findLinkT('preferred-language'))
          .andThen(callApi(languageResourceDecoder, { code: state.language }))
          .map(({ payload }) => payload.code)
          .fork(
            handlePreferenceUpdateError(store, state.currentUserResource),
            store.preferenceIdentified,
          );
        break;
      case 'preference-identified':
        whenBrowserLanguageChangeNeeded(state).do(store.changingBrowserLanguage);
        break;
      case 'changing-browser-language':
        changeBrowserLanguage(state.language, store);
        break;
      case 'errored':
        warn(state.debugMessage, toJS(state.error));
        break;
      default:
        assertNever(state);
    }
  };
}

const handleUserPreferredLanguage = (
  currentUserResource: CurrentUserResource,
  store: PreferredLanguageSelectionStore,
): Task<PreferredLanguageNotEstablished, SupportedLanguageCode> =>
  whenUserPreferredLanguagePresent(currentUserResource).do(store.preferenceIdentified);

const handleBrowserPreferredLanguage = (
  currentUserResource: CurrentUserResource,
  store: PreferredLanguageSelectionStore,
): Task<UnsupportedBrowserLanguage, SupportedLanguageCode> =>
  whenBrowserPreferredLanguageValid(currentUserResource).do((language) =>
    store.updatingPreference(language, currentUserResource),
  );

const changeBrowserLanguage = (
  language: SupportedLanguageCode,
  store: PreferredLanguageSelectionStore,
): void => {
  Task.succeed<LocaleChangeError, SupportedLanguageCode>(language)
    .andThen(changeLocale)
    .andThen(locationReload)
    .fork(
      (error) =>
        store.errored({
          error,
          debugMessage: 'Failed to change the browser language.',
          userMessage: 'Something went wrong',
        }),
      noop,
    );
};

const handlePreferenceUpdateError =
  (store: PreferredLanguageSelectionStore, currentUserResource: CurrentUserResource) =>
  (error: PreferenceUpdateError): void => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify('MissingLinkError', `Client application did not receive link: ${error.rel}`, {
          links: currentUserResource.links,
        });
        store.errored({
          error,
          debugMessage: 'Failed to update the user language preference.',
          userMessage: 'An error occurred, please refresh the page and try again',
        });
        break;
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'bad-status':
      case 'bad-payload':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        store.errored({
          error,
          debugMessage: 'Failed to update the user language preference.',
          userMessage: handleAppyError(error),
        });
        break;
      default:
        assertNever(error);
    }
  };
