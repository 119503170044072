import { observer } from 'mobx-react';
import { ProfileResource } from '../../../ProfileStore/Types';
import { T } from '../../../Translations';
import MembersList from './TeamMembersList';
import style from './styles.module.css';
interface Props {
  members: ProfileResource[];
}

const YourTeam: React.FC<Props> = ({ members }) => {
  return (
    <div className={style.blockWrapperTeam}>
      <p className={style.programSecondaryTitle}>
        <T kind="Your Team" />
      </p>
      <div className={style.sessionBlockMembers}>
        <div className={style.membersWrapper}>
          <MembersList members={members} />
        </div>
      </div>
    </div>
  );
};

export default observer(YourTeam);
