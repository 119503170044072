import { useContext } from 'react';
import { ExperienceFiltersContext } from '../Context';

export function useExperienceFilters() {
  const context = useContext(ExperienceFiltersContext);

  if (!context) {
    throw new Error('useExperienceFilters has to be used within the ExperienceFiltersProvider.');
  }

  return context;
}
