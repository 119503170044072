import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { HTMLAttributes } from 'react';

function ExperienceSectionHeader({
  className,
  children,
  ...others
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      data-testid="learning-collection-experience-section-header"
      className={cn('flex flex-col px-6 md:px-16', className)}
      {...others}
    >
      {children}
    </div>
  );
}

export default observer(ExperienceSectionHeader);
