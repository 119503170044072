import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

function LeadershipCoachingTimeCommitment() {
  return (
    <div className="flex gap-x-3.5">
      <Typography className="text-gray-900" variant="caption">
        <T kind="Unlimited sessions" />
      </Typography>
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} min sessions" count={30} />
      </Typography>
    </div>
  );
}

export default observer(LeadershipCoachingTimeCommitment);
