import { equals } from '@execonline-inc/collections';
import { CircleSolidIcon, cn, IconButton } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import { NavDotPages } from '../CarouselStore/Types';
import { CarouselPage } from '../Types';

interface Props<T> {
  className?: string;
  dots: Maybe<NavDotPages<T>>;
  onDotClick: (page: CarouselPage<T>) => void;
}

function NavigationDots<T>({ className, dots, onDotClick }: Props<T>) {
  const { translate } = useTranslationsContext();

  function handleDotClick(page: CarouselPage<T>) {
    return () => {
      onDotClick(page);
    };
  }

  return dots
    .map(({ pages, currentPageIndex }) => (
      <div className={cn('mt-6 flex w-full justify-center gap-3', className)}>
        {pages.map((currentPage, index) => (
          <Fragment key={index}>
            {when(!equals(index, currentPageIndex), true).cata({
              Just: () => (
                <IconButton
                  aria-label={translate('Go to page {{position}}', { position: index + 1 })}
                  className="size-auto rounded-full"
                  onClick={handleDotClick(currentPage)}
                >
                  <CircleSolidIcon className="text-gray-100" size="xs" />
                </IconButton>
              ),
              Nothing: () => (
                <IconButton
                  aria-current="true"
                  aria-label={translate('Current page {{current}}', { current: index + 1 })}
                  className="pointer-events-none size-auto cursor-default rounded-full"
                  tabIndex={-1}
                >
                  <CircleSolidIcon className="text-gray-450" size="xs" />
                </IconButton>
              ),
            })}
          </Fragment>
        ))}
      </div>
    ))
    .getOrElse(() => <div className="h-8 empty:hidden" />);
}

export default observer(NavigationDots);
