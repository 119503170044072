import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';
import {
  TeamChatResource as ProgramChatResource,
} from '../TeamsStore/Types';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  programResource: ProgramResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (programResource: ProgramResource): Ready => ({
  kind: 'ready',
  programResource,
});

export type ProgramState = Waiting | Loading | Ready | Error;

export interface Program {
  name: string;
  id: number;
  programChat: ProgramChatResource;
}

export type ProgramResource = Resource<Program>;
