import { InvalidUrlError, toUrlT, URLParser } from '@execonline-inc/url';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import coachTeamsSummaryStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import { communitiesResourceDecoder } from '../../../../CommunitiesStore/Decoders';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import { handleError } from '../../../../ErrorActionableReaction';
import { findLinkT, MissingLinkError } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { CoachTeamsState, replaceSearchParameter } from './Types';

interface Props extends RCProps<coachTeamsSummaryStore> {
  store: coachTeamsSummaryStore;
  currentUserResource: CurrentUserResource;
}

export type TeamsReactionError = MissingLinkError | AppyError | InvalidUrlError;

export const handleTeamRequestError =
  (store: coachTeamsSummaryStore) => (error: TeamsReactionError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Team link is unavailable');
        break;
      case 'invalid-url-error':
        store.error('URL is not valid');
        break;
      default:
        handleError(store, error);
    }
  };

export const teamsEndPoint = callApi(communitiesResourceDecoder, {});

const setQueryParams = (
  link: string,
  store: coachTeamsSummaryStore,
): Task<InvalidUrlError, URLParser> =>
  toUrlT(link).map((url) =>
    url.set(
      'query',
      store.searchParameters.reduce(
        (obj, param) => Object.assign(obj, { [`communities_filter[${param.key}]`]: param.value }),
        url.query,
      ),
    ),
  );

const getTeamsSummaryLink =
  (store: coachTeamsSummaryStore) =>
  (link: Link): Task<InvalidUrlError, Link> =>
    setQueryParams(link.href, store).map((parsedUrl) => ({
      ...link,
      href: parsedUrl.href,
    }));

class CoachCommunitySummaryReactions extends ReactionComponent<
  coachTeamsSummaryStore,
  CoachTeamsState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: CoachTeamsState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'load-all':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('coach-communities'))
          .andThen(getTeamsSummaryLink(store))
          .andThen(teamsEndPoint)
          .fork(handleTeamRequestError(this.props.store), (communities) =>
            this.props.store.ready(communities),
          );
        break;
      case 'loading':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('coach-communities'))
          .andThen(getTeamsSummaryLink(store))
          .andThen(teamsEndPoint)
          .fork(handleTeamRequestError(this.props.store), (communities) =>
            this.props.store.ready(communities),
          );
        break;
      case 'add-filter':
        state.searchParameters = replaceSearchParameter(
          state.filterType,
          state.filter,
          state.searchParameters,
        );
        break;
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(CoachCommunitySummaryReactions);
