import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { L } from '../../../../../../../Translations';

interface Props {
  startTime: Maybe<Date>;
}

function GroupCoachSessionDateCalendar({ startTime }: Props) {
  return startTime
    .map((value) => (
      <div className="flex min-w-16 flex-col items-center justify-center gap-y-1.5 rounded-[3px] bg-[#A0A0A0] px-4">
        <Typography variant="bodyMedium" color="white">
          <L localizeable={value} format="long-month" />
        </Typography>
        <Typography variant="h4" color="white" as="span">
          <L localizeable={value} format="day-of-month" />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachSessionDateCalendar);
