import { assertNever } from '@kofno/piper';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import SegmentStore from '../../../SegmentStore';
import { State as CoachSelectStoreState } from '../FormField/SelectField/CoachSelect/Store/Types';
import { State as DropdownFieldStoreState } from '../FormField/SelectField/Select/DropdownSelect/Store/Types';
import { State as StringFieldStoreState } from '../FormField/StringField/Store/Types';
import { FormFieldStore } from '../Types';

type FieldStoreState = CoachSelectStoreState | StringFieldStoreState | DropdownFieldStoreState;

interface Props extends RCProps<FormFieldStore> {
  segmentStore: SegmentStore;
}

class AutoSaveReactions extends ReactionComponent<FormFieldStore, FieldStoreState, Props> {
  tester = () => this.props.store.state;

  effect = (state: FieldStoreState) => {
    const { store, segmentStore } = this.props;
    switch (state.kind) {
      case 'initializing':
      case 'waiting':
      case 'ready':
        break;
      case 'auto-saving':
        segmentStore.autoSaveFormFields();
        store.autoSaveComplete();
        break;
      default:
        assertNever(state);
    }
  };
}

export default AutoSaveReactions;
