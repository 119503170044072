import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import { NotEnrollableAlert } from '../../Experience/Types';
import ExperienceEnrolledContent from '../ExperienceEnrolledContent';
import { EnrolledModalReactions, EnrolledModalStore } from '../ExperienceEnrolledContent/Common';

interface Props {
  invitationUuid: string;
  notEnrollableAlert: NotEnrollableAlert;
  enrollmentStore: EnrollmentStore;
}

function ExperienceEnrolledModal({ invitationUuid, notEnrollableAlert, enrollmentStore }: Props) {
  const modalStore = useMemo(() => new EnrolledModalStore(notEnrollableAlert.programId), []);

  useEffect(() => {
    modalStore.load();
  }, [modalStore]);

  return (
    <>
      <ExperienceEnrolledContent
        invitationUuid={invitationUuid}
        notEnrollableAlert={notEnrollableAlert}
        enrollmentStore={enrollmentStore}
        modalStore={modalStore}
      />
      <EnrolledModalReactions store={modalStore} />
    </>
  );
}

export default observer(ExperienceEnrolledModal);
