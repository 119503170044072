import { always, identity } from '@kofno/piper';
import { Maybe, isNothing, just } from 'maybeasy';
import { Result, err, ok } from 'resulty';
import * as xoid from 'xoid';

export type StreamEvent = Event | undefined;

export type StorageValueResult = Result<StorageError, StorageValue>;

export type StorageValue = Maybe<string>;

export type OnValueChange = (valueChangeResult: StorageValueResult) => void;

export interface StorageError {
  kind: 'storage-error';
  message: string;
}

export interface NoStorageValueChange {
  kind: 'no-storage-value-change';
}

export const storageError = (message: string): StorageError => ({
  kind: 'storage-error',
  message,
});

export const noStorageValueChange = (): NoStorageValueChange => ({
  kind: 'no-storage-value-change',
});

const maybesAreEqual = <T extends string | number>(a: Maybe<T>, b: Maybe<T>): boolean =>
  (isNothing(a) && isNothing(b)) ||
  just({})
    .assign('a', a)
    .assign('b', b)
    .map(({ a, b }) => a === b)
    .getOrElseValue(false);

export const whenDifferentValue = (
  currentValueResult: StorageValueResult,
  newValue: StorageValue,
): Result<NoStorageValueChange, StorageValue> =>
  currentValueResult
    .orElse<NoStorageValueChange>(always(ok(newValue)))
    .mapError<NoStorageValueChange>(identity)
    .andThen<StorageValue>((currentValue) =>
      maybesAreEqual(currentValue, newValue) ? err(noStorageValueChange()) : ok(newValue),
    );

export interface Config {
  storageKey: string;
  storage: Storage;
}

export type State = StorageValueResult;

export interface Data {
  config: Config;
  state: State;
}

export interface Actions {
  setValue: (v: StorageValue) => void;
  error: (e: StorageError) => void;
}

export type StorageWatcher = xoid.Atom<Data> & xoid.Actions<Actions>;
