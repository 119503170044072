import { noop } from '@kofno/piper';
import { field, string } from 'jsonous';
import { Maybe, fromEmpty, just, nothing } from 'maybeasy';
import * as qs from 'querystringify';
import { Task } from 'taskarian';

export const searchParam = (name: string): Maybe<string> =>
  field(name, string)
    .decodeAny(qs.parse(window.location.search))
    .map(just)
    .getOrElse(nothing)
    .andThen(fromEmpty);

export interface MissingParamError {
  kind: 'missing-param-error';
  param: string;
  message: string;
}

export const missingParamError = (param: string): MissingParamError => ({
  kind: 'missing-param-error',
  param,
  message: `Missing param "${param}"`,
});

export const searchParamT = (name: string): Task<MissingParamError, string> =>
  new Task((reject, resolve) => {
    searchParam(name).cata({
      Just: resolve,
      Nothing: () => reject(missingParamError(name)),
    });
    return noop;
  });
