import { observer } from 'mobx-react';
import { CurrentUserContext } from '..';
import ErrorBoundary from '../../../ErrorHandling/ErrorBoundary';
import Children from './Children';
import { onError } from './Functions';
import { Props } from './Types';

function WithCurrentUser({ children }: Props) {
  return (
    <ErrorBoundary onError={onError}>
      <CurrentUserContext.Consumer children={(ctx) => <Children ctx={ctx} children={children} />} />
    </ErrorBoundary>
  );
}

export default observer(WithCurrentUser);
