import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Anchor from '../../LinkyLinky/Anchor';
import { Link } from '../../Resource/Types';
import { T } from '../../Translations';
import ExternalIcon from '../Cutesies/ExternalIcon';
import * as style from './style.module.css';

interface Props {
  link: Maybe<Link>;
}

const ViewButton: React.FC<Props> = ({ link }) =>
  link
    .map((l) => {
      return (
        <Anchor className={style.viewButton} href={l.href} data-test-view-button>
          <T kind="View in Browser" />
          <div className={style.buttonIcon}>
            <ExternalIcon />
          </div>
        </Anchor>
      );
    })
    .getOrElse(emptyFragment);

export default observer(ViewButton);
