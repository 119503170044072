import { observer } from 'mobx-react';
import * as React from 'react';
import ContentEmbed from '../ContentEmbed';
import { embeddable, Props } from './Types';

const SegmentEmbed: React.FC<Props> = (props) => {
  return <ContentEmbed embeddable={embeddable(props)} />;
};

export default observer(SegmentEmbed);
