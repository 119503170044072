import * as React from 'react';
import { observer } from 'mobx-react';
import { Maybe, nothing } from 'maybeasy';
import * as style from './style.module.css';
import { ReqHlsVideoAssetResource } from '../../../../../../ReqHlsVideo/Types';
import ReqHlsVideo from '../../../../../../ReqHlsVideo';
import { Kettle } from 'kettle-corn';

interface Props {
  video: Maybe<ReqHlsVideoAssetResource>;
}

const IntroductoryVideo: React.FC<Props> = ({ video }) =>
  video
    .map((v) => (
      <div className={style.videoWrapper}>
        <ReqHlsVideo
          id={v.payload.uuid}
          className="embedded-video"
          videoResource={v}
          kettle={new Kettle()}
          width={'100%'}
          height={320}
          dynamicallySetAspectRatio={true}
        >
          {{
            playButton: nothing(),
          }}
        </ReqHlsVideo>
      </div>
    ))
    .getOrElse(() => <></>);

export default observer(IntroductoryVideo);
