import { observer } from 'mobx-react';
import * as React from 'react';
import { CompletedExternalProgram } from '../../../../../SegmentStore/Types';
import CompletedBadgeGraphic from '../../../../Cutesies/CompletedBadgeGraphic';
import * as styles from '../style.module.css';

interface Props {
  completedExternalProgram: CompletedExternalProgram;
}

const Completed: React.FC<Props> = ({ completedExternalProgram }) => (
  <div
    data-test-external-program-segment={completedExternalProgram.id}
    className={styles.badgeContainer}
  >
    <div className="w-16">
      <CompletedBadgeGraphic />
    </div>
  </div>
);

export default observer(Completed);
