import * as React from 'react';
import { Link } from 'react-router-dom';
import { discoveryPortalRoute } from '../../ClientRoutes';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';

interface Props {
  guid: string;
}

const EnrollNowButton: React.FC<Props> = ({ guid }) => (
  <Link
    to={discoveryPortalRoute(guid)}
    className={style.continueButton}
    data-test-continue-button={'Enroll Now'}
  >
    <T kind={'Enroll Now'} />
    <div className={style.buttonIcon}>
      <ArrowRightIcon />
    </div>
  </Link>
);

export default EnrollNowButton;
