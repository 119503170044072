import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Link } from '../../../../../Resource/Types';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import FilterStore from './FilterStore';
import InlineFilters from './InlineFilters';
import SidebarFilters from './SidebarFilters';

export interface Props {
  experiencesStore: ExperiencesStore;
  filterStore: FilterStore;
  link: Maybe<Link>;
}

function Filter({ experiencesStore, filterStore, link }: Props) {
  return experiencesStore.filterOptionsResource
    .map((options) => (
      <div className="mx-auto box-border flex gap-x-2 px-6 pb-8 pt-0 xl:container md:flex-wrap md:gap-x-0 md:px-16">
        <InlineFilters
          experiencesStore={experiencesStore}
          filterStore={filterStore}
          options={options}
          link={link}
        />
        <SidebarFilters filterStore={filterStore} options={options} />
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Filter);
