import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';

const FlashServerError: React.SFC = ({ children }) => (
  <div className={style.notice} data-test-flash-message="server-error">
    <div className={style.error}>{children}</div>
  </div>
);

export default observer(FlashServerError);
