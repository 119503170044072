import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { LanguageResource, LanguagesResource } from '../../../ProfileFormStore/Types';
import { Separator } from '../Types';

interface Props {
  languages: LanguagesResource;
  separator: Separator;
  onSelect: (languageResource: LanguageResource) => void;
}

const whenSeparatorRequired = (index: number): Maybe<number> => when(index !== 0, index);

function LanguageLinks({ languages, separator, onSelect }: Props) {
  return (
    <>
      {languages.payload.map((language, index) => (
        <React.Fragment key={language.payload.code}>
          {whenSeparatorRequired(index)
            .map(() => (
              <Typography className="mx-2 leading-none text-neutral-900" as="span">
                {separator}
              </Typography>
            ))
            .getOrElse(emptyFragment)}
          <Button className="!p-0 text-[#187a90]" variant="link" onClick={() => onSelect(language)}>
            <Typography
              className="capitalize text-neutral-900 underline hover:text-inherit"
              variant="bodyMedium"
              fontWeight="bold"
              as="a"
            >
              {language.payload.localName}
            </Typography>
          </Button>
        </React.Fragment>
      ))}
    </>
  );
}

export default observer(LanguageLinks);
