import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import {
  LinkableDiscoveryPortalWizardScopeKind,
  LinkableResource,
} from '../../../../../../components/Linkable/Types';
import { validateLinkableResource } from './Functions';
import UpdateFocusButtonContent from './UpdateFocusButtonContent';

interface Props {
  linkable: LinkableResource;
  productCollectionId: number;
  scopeType: LinkableDiscoveryPortalWizardScopeKind;
}

function UpdateFocusButtonView({ linkable, productCollectionId, scopeType }: Props) {
  switch (linkable.payload.kind) {
    case 'discovery-portal-wizard':
      const { links, payload } = linkable;

      return validateLinkableResource(payload, productCollectionId, scopeType)
        .map(() => <UpdateFocusButtonContent data={payload} links={links} />)
        .getOrElse(emptyFragment);
    case 'resource':
      return emptyFragment();
  }
}

export default observer(UpdateFocusButtonView);
