import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const ProgramIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 15 15">
      <path
        fill={defaultTheme.variables['icon-accent-on-dark']}
        d="M0 .417v11.252h5.001v3.048l.977-.645 1.524-1.016 1.523 1.016.977.645V8.543h-5v1.876H1.25V1.667h12.503v8.752h-3.126v1.25h4.376V.417H0Zm2.5 3.125v1.25h10.003v-1.25H2.5Zm0 2.5v1.251h10.003v-1.25H2.5Zm3.751 3.752h2.5v2.578l-.898-.605-.351-.215-.352.215-.899.605V9.794Z"
      />
    </SVGIcon>
  );
};

export default observer(ProgramIcon);
