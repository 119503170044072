import { assertNever } from '@kofno/piper';
import * as React from 'react';
import { LandingPage, LandingPageOption } from '../../../ProfileFormStore/Types';
import {
  TPlainTextKey,
  TranslationsContext,
  TranslationsState,
  translation,
} from '../../../Translations';
import Option from '../../Form/Select/Option';

interface Props {
  value: string;
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
  options: LandingPageOption[];
  className?: string;
  name: string;
  placeholder?: TPlainTextKey;
  id: string;
  disabled?: boolean;
  hidden?: boolean;
}

interface TranslatableSelectOption {
  value: string;
  content: TPlainTextKey;
}

export const convertStringToTPlainTextKey = (landingPage: LandingPage): TPlainTextKey => {
  switch (landingPage) {
    case 'learning-platform':
      return 'Learning';
    case 'enterprise-portal':
      return 'Enterprise Portal';
    case 'client-portal':
      return 'AdminStudio';
    default:
      assertNever(landingPage);
  }
};

const convertLandingPageOptionToTranslatedOption = (
  option: LandingPageOption
): TranslatableSelectOption => ({
  value: option.value,
  content: convertStringToTPlainTextKey(option.value),
});

const translateOptions = (options: LandingPageOption[]): TranslatableSelectOption[] =>
  options.map((option) => convertLandingPageOptionToTranslatedOption(option));

const renderOptions = (options: TranslatableSelectOption[], ts: TranslationsState) =>
  options.map((option: TranslatableSelectOption) => {
    return (
      <Option key={option.value} value={option.value} content={translation(option.content)(ts)} />
    );
  });

const DefaultLandingPageSelect: React.FC<Props> = ({
  className,
  name,
  value,
  onChange,
  id,
  disabled,
  placeholder,
  hidden,
  options,
}) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <select
        className={className}
        name={name}
        value={value}
        onChange={onChange}
        data-test-select={name}
        id={id}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" hidden={hidden === undefined ? true : hidden}>
            {placeholder ? translation(placeholder)(ts) : undefined}
          </option>
        )}
        {renderOptions(translateOptions(options), ts)}
      </select>
    )}
  </TranslationsContext.Consumer>
);

export default DefaultLandingPageSelect;
