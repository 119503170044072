import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import Currency from '../../../../../../Currency';
import { ExperienceResource } from '../../Types';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperiencePrice({ experienceResource }: Props) {
  return experienceResource.payload.price
    .map((price) => (
      <Typography className="text-[#0E0E0E]" as="span" fontWeight="semibold">
        <Currency
          priceInDollars={price / 100}
          options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
        />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperiencePrice);
