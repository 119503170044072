import { observer } from 'mobx-react';
import * as React from 'react';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import ProgramMemberList from '../ProgramMemberList';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

const OnlineProgramMembers: React.FC<Props> = ({ presenceMembersStore }) => (
  <ProgramMemberList presenceMembersStore={presenceMembersStore} />
);

export default observer(OnlineProgramMembers);
