import { Error } from '../ErrorHandling';
import { ProfileResource } from '../ProfileStore/Types';
import { ProgramDetailResource } from '../ProgramStore/Types';
import { Resource } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  programDetailResource: ProgramDetailResource;
}
interface Ready {
  kind: 'ready';
  professorsResource: ProfessorsResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (programDetailResource: ProgramDetailResource): Loading => ({
  kind: 'loading',
  programDetailResource,
});

export const ready = (professorsResource: ProfessorsResource): Ready => ({
  kind: 'ready',
  professorsResource,
});

export type ProfessorsState = Waiting | Loading | Ready | Error;

export interface Professor {
  id: number;
  email: string;
  profile: ProfileResource;
}

export type ProfessorsResource = Resource<ProfessorResource[]>;
export type ProfessorResource = Resource<Professor>;
