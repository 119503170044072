import Decoder, { array, field, number, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { resourceDecoder } from '../../../../Resource/Decoders';
import { resourceResourceDecoder } from '../../Decoders';
import {
  ProgramResources,
  ProgramResourcesModule,
  ProgramResourcesResource,
  ProgramResourcesSegment,
} from './Types';

const programResourcesSegmentDecoder: Decoder<ProgramResourcesSegment> = succeed({})
  .assign('id', field('id', number))
  .assign('longTitle', field('long_title', string))
  .assign('resources', field('resources', array(resourceResourceDecoder).map(fromArrayMaybe)));

const programResourcesModuleDecoder: Decoder<ProgramResourcesModule> = succeed({})
  .assign('id', field('id', number))
  .assign('label', field('label', string))
  .assign('segments', field('segments', array(programResourcesSegmentDecoder)))
  .assign('title', field('title', string));

const programResourcesDecoder: Decoder<ProgramResources> = succeed({})
  .assign('id', field('id', number))
  .assign('modules', field('modules', array(programResourcesModuleDecoder)))
  .assign('resources', field('resources', array(resourceResourceDecoder).map(fromArrayMaybe)))
  .assign('title', field('title', string));

export const programResourcesResourceDecoder: Decoder<ProgramResourcesResource> =
  resourceDecoder(programResourcesDecoder);
