import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import React from 'react';
import { T } from '../../../../../Translations';
import ReactHtmlParser from 'react-html-parser';
import * as style from '../style.module.css';

interface Props {
  description: AlreadyTranslatedText;
  header: 'Key Takeaways' | 'Program Structure' | 'How It Works';
}

const SectionContent: React.FC<Props> = ({ description, header }) => (
  <div>
    <div className="font-light text-3xl mt-8 mb-8">
      <T kind={header} />
    </div>
    <div className={`${style.sectionList} pl-5`}>{ReactHtmlParser(description.text)}</div>
  </div>
);

export default observer(SectionContent);
