import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import ExperienceDetailViewStore from '.';
import { callApi } from '../../../../Appy';
import { handleError } from '../../../../ErrorActionableReaction';
import { findLinkT } from '../../../../LinkyLinky';
import ReactionComponent from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { dataLayerUpdate } from '../../../../components/Tooling/GoogleTagManagerTooling/Loader';
import {
  DataLayer,
  ProgramDetailAndDiscoveryPortalDataLayer,
  ProgramDetailDataLayer,
} from '../../../../components/Tooling/GoogleTagManagerTooling/Types';
import { experienceResourceDecoder } from '../../DiscoveryPortal/ExperienceSelection/Experiences/Decoders';
import { DiscoveryPortalPayload } from '../../Common/Experience/Types';
import { LoadingError } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { State } from './Types';

const getExperience = callApi(experienceResourceDecoder, {});

const handleLoadingError = (store: ExperienceDetailViewStore) => (error: LoadingError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Experience link is unavailable');
      break;
    default:
      handleError(store, error);
  }
};

const processTemplateLink =
  (experienceId: string) =>
  (link: Link): Link => ({
    ...link,
    href: link.href.replace('{id}', experienceId),
  });

interface Props {
  store: ExperienceDetailViewStore;
  discoveryPortal: Maybe<DiscoveryPortalPayload>;
}

class ExperienceDetailViewReactions extends ReactionComponent<
  ExperienceDetailViewStore,
  State,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
        Task.succeed<LoadingError, ReadonlyArray<Link>>(state.registerResource.links)
          .andThen(findLinkT('experience-template-link'))
          .map(processTemplateLink(state.experienceId))
          .andThen(getExperience)
          .fork(handleLoadingError(store), store.ready);
        break;
      case 'ready':
        const payload = state.experienceResource.payload;
        const dataLayerProgramParams: DataLayer = {
          programFamilyId: payload.id,
          programFamilyName: payload.title.text,
          schoolPartnerId: payload.schoolPartner.payload.id,
          schoolPartnerName: payload.schoolPartner.payload.name.text,
          duration: payload.duration,
          event: 'program-detail-data',
        };
        const dataLayerParams: DataLayer = this.props.discoveryPortal
          .map<ProgramDetailAndDiscoveryPortalDataLayer | ProgramDetailDataLayer>((dp) => ({
            ...dataLayerProgramParams,
            learningCollectionId: dp.useCase.id,
            learningCollectionName: dp.useCase.name.text,
            productLicenseId: dp.useCase.productLicenseId,
            productLicenseType: dp.useCase.productLicenseType,
          }))
          .getOrElseValue(dataLayerProgramParams);
        dataLayerUpdate(dataLayerParams);
        break;
      case 'error':
        warn('Unable to load experience', state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(ExperienceDetailViewReactions);
