import clsx from 'clsx';
import { observer } from 'mobx-react';
import { ForgotPasswordField } from '../../Native/Form';
import { T } from '../../Translations';
import { LoginStore } from '../Store';
import LoginFormWrapper from './LoginFormWrapper';
import * as style from './style.module.css';

interface Props {
  store: LoginStore;
}

function LockoutNotice({ store }: Props) {
  return (
    <LoginFormWrapper store={store}>
      <p className={clsx(style.failedLogin, 'text-neutral-900')}>
        <T
          kind="If this email address is in our database you ..."
          emailAddress={
            <a
              className={clsx(style.mailToLink, 'text-neutral-900 hover:text-neutral-900')}
              href="mailto:support@execonline.com"
            >
              support@execonline.com
            </a>
          }
        />
      </p>
      <br />
      <ForgotPasswordField />
    </LoginFormWrapper>
  );
}

export default observer(LockoutNotice);
