import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { noop } from '@kofno/piper';
import { equals, find } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { getItem, setItem } from '../../../../../../../Storage';
import EnrolledModalStore from '../EnrolledModalStore';

export const getNotEnrollableConfirmationProgramIds = (): Task<string, string[]> =>
  getItem('notEnrollableConfirmationProgramIds', localStorage)
    .map((programIds) => programIds.map((programIds) => programIds.split(',')))
    .map((ids) => ids.getOrElseValue([]));

export const initializeModal = (
  store: EnrolledModalStore,
  notEnrollableProgramIds: string[],
): Task<string, string> =>
  toTask<string, string>(
    'Failed to initialize modal',
    store.stringifiedProgramId.andThen((programId) =>
      find((confirmationId) => equals(confirmationId, programId), notEnrollableProgramIds),
    ),
  );

export const saveId =
  (stringifiedProgramId: Maybe<string>) => (notEnrollableProgramIds: string[]) =>
    stringifiedProgramId.do((programId) => {
      const newIdList = notEnrollableProgramIds.concat([programId]).join(',');
      setItem('notEnrollableConfirmationProgramIds', newIdList, localStorage).fork(noop, noop);
    });
