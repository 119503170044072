type CompletionStatus = 'complete' | 'in-progress' | 'unstarted';

export const completionStatus = (n: number): CompletionStatus => {
  if (n >= 100) {
    return 'complete';
  }
  if (n < 100 && n > 0) {
    return 'in-progress';
  }
  return 'unstarted';
};
