import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import ProgramStore from '../../../ProgramStore';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import UniversityLogoOnDark from '../../../components/Dashboard/UniversityLogoOnDark';
import TeamsSummary from '../../../components/TeamsSummary';
import LearningPartnersSummary from '../../LearningPartnersSummary';
import PositionContext from '../../PositionContext';
import ProfessorsSummary from '../../ProfessorsSummary';

interface Props {
  store: ProgramStore;
}

const PresenceProgram = ({
  programDetailResource,
}: {
  programDetailResource: ProgramDetailResource;
}) => (
  <PositionContext.Provider value={{ kind: 'supPanel' }}>
    <UniversityLogoOnDark links={programDetailResource.links} />
    <ProfessorsSummary programDetailResource={programDetailResource} />
    <TeamsSummary programDetailResource={programDetailResource} />
    <LearningPartnersSummary programDetailResource={programDetailResource} />
  </PositionContext.Provider>
);

const ProgramSummaryPanel: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'reloading':
    case 'ready': {
      return <PresenceProgram programDetailResource={store.state.programDetailResource} />;
    }
    case 'course-not-ready':
    case 'registration-cancelled':
      return <Redirect to={dashboardUrl} />;
    case 'waiting':
    case 'loading':
    case 'error':
    case 'cancelling-registration':
      return <></>;
  }
};

export default observer(ProgramSummaryPanel);
