import { observer } from 'mobx-react';
import { T } from '../../Translations';
import WhenLink from '../../WhenLink';
import { ViewableAssetResource } from './Types';

interface ViewFileProps {
  resource: ViewableAssetResource;
}

function ViewFile({ resource }: ViewFileProps) {
  const { payload, links } = resource;
  return (
    <WhenLink
      rel="view"
      links={links}
      otherwise={() => (
        <span className="text-black">
          <T kind="Oops! We were unable to find your file." />
        </span>
      )}
    >
      {({ href }) => <iframe height="700px" width="100%" src={href} title={payload.filename} />}
    </WhenLink>
  );
}

export default observer(ViewFile);
