import { observer } from 'mobx-react';
import { findLink } from '../../../../../../../LinkyLinky';
import { Avatar, emptyFragment } from '@execonline-inc/execonline-ui';
import { CoachingProfileResource } from '../../../../../Common/Experience/Types';

interface Props {
  profile: CoachingProfileResource;
}

function GroupCoachProfileAvatar({ profile }: Props) {
  return findLink('avatar', profile.links)
    .map(({ href }) => <Avatar className="ml-3 mr-2 bg-primary" size="xs" src={href} />)
    .getOrElse(emptyFragment);
}
export default observer(GroupCoachProfileAvatar);
