import { observer } from 'mobx-react';
import * as React from 'react';
import PdfStore from '../../PdfStore';
import { Link } from '../../Resource/Types';
import { T, TPlainTextKey } from '../../Translations';
import ModalStore from '../Modal/Store';
import DownloadNotesModal from './DownloadNotesModal';

interface Props {
  pdfStore: PdfStore;
  links: ReadonlyArray<Link>;
  fileName: TPlainTextKey;
}

class DownloadNotes extends React.Component<Props> {
  modalStore: ModalStore = new ModalStore();
  render() {
    const { pdfStore, fileName } = this.props;
    switch (pdfStore.state.kind) {
      case 'ready':
        return (
          <>
            <a href="#" onClick={this.modalStore.open} data-test-open-notes-download-modal={true}>
              <T kind="Download Notes" />
            </a>
            <DownloadNotesModal
              pdfStore={pdfStore}
              modalStore={this.modalStore}
              fileName={fileName}
            />
          </>
        );
      case 'downloading':
        return (
          <span data-test-notes-downloading={true}>
            <T kind="downloading..." />
          </span>
        );
      case 'waiting':
      case 'errored':
        return <span />;
    }
  }
}

export default observer(DownloadNotes);
