import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import * as style from '../../style.module.css';
import RemainingDaysIndicator from '../RemainingDaysIndicator';
import SectionTitle from '../SectionTitle';

interface Props {
  programStore: ProgramStore;
}

const ProgramAccessUntil: React.FC<Props> = ({ programStore }) => {
  return (
    <div className={style.sessionAccess}>
      <SectionTitle text="Access Days Remaining" />
      <div className={style.contentBox}>
        <div className={style.flexRow}>
          <RemainingDaysIndicator programStore={programStore} />
        </div>
      </div>
    </div>
  );
};

export default observer(ProgramAccessUntil);
