import { Typography } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import TimeStamp from '../../TimeStamp';
import ToolLibraryListItem from './ToolLibraryListItem';

function NumberedInstructions() {
  return (
    <div className="mb-10 flex flex-col gap-x-4 lg:flex-row">
      <div className="basis-1/3">
        <Typography className="mb-2.5" as="h2" fontWeight="medium">
          <T kind="1. Schedule Test" />
        </Typography>
        <Typography>
          <T kind="Click on Select a time and the scheduling window ..." />
        </Typography>
      </div>
      <div className="basis-1/3">
        <Typography className="mb-2.5" as="h2" fontWeight="medium">
          <T kind="2. Date and Time Settings" />
        </Typography>
        <TimeStamp timestamp={just(new Date())} />
        <Typography className="mt-4 opacity-70">
          <T kind="The date and time above should match your computer's system" />
        </Typography>
      </div>
      <div className="basis-1/3">
        <Typography className="mb-2.5" as="h2" fontWeight="medium">
          <T kind="3. Tool Library Test" />
        </Typography>
        <div className="[&>div:not(:last-child)]:mb-1.5">
          <ToolLibraryListItem title="Scheduling library loaded" />
          <ToolLibraryListItem title="Web Worker library loaded" />
          <ToolLibraryListItem title="Cookies set correctly" />
        </div>
      </div>
    </div>
  );
}

export default observer(NumberedInstructions);
