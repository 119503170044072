import * as React from 'react';
import { observer } from 'mobx-react';
import PdfStore from '../../../../PdfStore';
import { T } from '../../../../Translations';

interface Props {
  pdfStore: PdfStore;
}

const ButtonText: React.FC<Props> = ({ pdfStore }) => {
  switch (pdfStore.state.kind) {
    case 'waiting':
      return <T kind="Loading..." />;
    case 'downloading':
      return <T kind="downloading..." />;
    case 'errored':
      return <T kind="Unable to download PDF" />;
    case 'ready':
      return <T kind="Download" />;
  }
};

export default observer(ButtonText);
