import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import StartDate from './StartDate';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import ProgramLink from './ProgramLink';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const ProgramOverview: React.FC<Props> = ({ communityTeamResource }) => (
  <dl className={style.dl} data-test-team-program={true}>
    <dt>
      <h3>
        <T kind="Program" />
      </h3>
    </dt>
    <dd>
      <ProgramLink communityTeamResource={communityTeamResource} />
    </dd>
    <br />
    <br />
    <dt>
      <h3>
        <T kind="Start Date" />
      </h3>
    </dt>
    <dd>
      <StartDate startDate={communityTeamResource.payload.startDate} />
    </dd>
  </dl>
);

export default observer(ProgramOverview);
