import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../Common/Experience/Types';
import {
  ExperienceDetailsSection,
  ExperienceDetailsSectionHeader,
} from '../ExperienceDetailsSection';
import ExperienceHtmlView from '../ExperienceHtmlView';

interface Props {
  experience: Experience;
}

function ExperienceWhoShouldAttend({ experience }: Props) {
  return experience.whoShouldAttendHtml
    .map((data) => (
      <ExperienceDetailsSection>
        <ExperienceDetailsSectionHeader title="Who should attend" />
        <ExperienceHtmlView
          className="!text-sm [&>ul]:grid [&>ul]:gap-3 sm:[&>ul]:grid-cols-2 sm:[&>ul]:gap-4"
          data={data}
        />
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperienceWhoShouldAttend);
