import { Error } from '../ErrorHandling';

export interface LoadingMore {
  kind: 'loading-more';
  loadMoreMembers: number;
}

interface Ready {
  kind: 'ready';
  currentShownMembers: number;
}

interface AllMembersLoaded {
  kind: 'all-members-loaded';
  allMembers: number;
}

interface UpdateMembers {
  kind: 'update-members';
  updatedProgramMembers: number;
}

export const loadingMore = (currentShownMember: number, loadMoreMembers: number): LoadingMore => ({
  kind: 'loading-more',
  loadMoreMembers: currentShownMember + loadMoreMembers,
});

export const ready = (currentShownMembers: number): Ready => ({
  kind: 'ready',
  currentShownMembers: currentShownMembers,
});

export const allMembersLoaded = (allMembers: number): AllMembersLoaded => ({
  kind: 'all-members-loaded',
  allMembers: allMembers,
});

export const updateMembers = (updatedProgramMembers: number): UpdateMembers => ({
  kind: 'update-members',
  updatedProgramMembers: updatedProgramMembers,
});

export type ProgramMembersState = LoadingMore | AllMembersLoaded | UpdateMembers | Ready | Error;
