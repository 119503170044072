import { find } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProfileFormStore from '../../../ProfileFormStore';
import { TimeZone } from '../../../ProfileStore/Types';
import Select from '../../Form/Select';
import { SelectOption } from '../../Form/Select/Option';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
}

export const setTimeZone = (store: ProfileFormStore) => (
  event?: React.FormEvent<HTMLSelectElement>
) => {
  if (event) {
    store.setTimeZone(find((tz) => tz.name === event.currentTarget.value, store.timeZoneOptions));
  }
};

export const convertToOptions = (profileStore: ProfileFormStore): SelectOption[] => {
  const options: TimeZone[] = profileStore.timeZone
    .map(() => profileStore.timeZoneOptions)
    .getOrElseValue([
      { fullName: 'Select One', name: '', code: '' },
      ...profileStore.timeZoneOptions,
    ]);
  return options.map((option) => ({
    content: option.fullName,
    value: option.name,
  }));
};

const TimeZoneSelect: React.FC<Props> = ({ profileStore }) => {
  return (
    <Select
      className={style.fakeSelect}
      value={profileStore.timeZone.map((timeZone) => timeZone.name).getOrElseValue('')}
      onChange={setTimeZone(profileStore)}
      name="timeZone"
      id="timeZone"
      options={convertToOptions(profileStore)}
    />
  );
};

export default observer(TimeZoneSelect);
