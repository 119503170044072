import { ExternalProgramResource } from '../../../SegmentStore/Types';

export const bgImageStyle = (
  externalProgramResource: ExternalProgramResource,
  position: string
): {} => {
  switch (externalProgramResource.payload.kind) {
    case 'started':
    case 'unstarted':
      return {};
    case 'completed':
      return {
        backgroundImage: `url("/p3/confetti-lg.svg")`,
        backgroundPosition: position,
        backgroundSize: 'cover',
      };
  }
};
