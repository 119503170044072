import { NotTranslated } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { CountryRegionOption } from '../../CountryRegionOptionsStore/Types';
import { T } from '../../Translations';
interface Props {
  country: string;
  region: Maybe<CountryRegionOption>;
}
const CountryAndRegion: React.FC<Props> = ({ country, region }) => (
  <td>
    {region
      .map((r) => (
        <>
          <NotTranslated text={r.name} />
          ,&nbsp;
        </>
      ))
      .getOrElseValue(<T kind="Not specified" />)}
    <NotTranslated text={country} />
  </td>
);
export default observer(CountryAndRegion);
