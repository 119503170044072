import { readVarM } from '@execonline-inc/environment';
import { toUrl } from '@execonline-inc/url';
import { just } from 'maybeasy';
import * as React from 'react';
import { findLink } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import { AttachmentResource } from '../../SegmentStore/Types';
import { NotTranslated } from '../../Translations';
import PlayIcon from '../Cutesies/PlayIcon';
import VideoIcon from '../Cutesies/VideoIcon';
import * as style from './style.module.css';

interface Props {
  attachmentResource: AttachmentResource;
  links: ReadonlyArray<Link>;
}

const VideoAttachment: React.FC<Props> = ({ attachmentResource, links }) => {
  let id = attachmentResource.payload.id;

  return just({})
    .assign('resourceTemplate', findLink('resource-template')(links))
    .assign('base', readVarM('REACT_APP_BASE_NAME'))
    .assign('origin', readVarM('REACT_APP_API_ROOT'))
    .andThen(({ resourceTemplate, base, origin }) =>
      toUrl(base + resourceTemplate.href.replace('{uuid}', `${id}`), origin)
    )
    .map((url) => (
      <li className={style.attachment} data-test-video-attachment={true}>
        <a className={style.link} href={url.href} target="_blank" rel="noopener noreferrer">
          <div className={style.leftIconWrapper}>
            <VideoIcon />
          </div>
          <p className={style.title} data-test-attachment-title={attachmentResource.payload.title}>
            <NotTranslated text={attachmentResource.payload.title} />
          </p>
          <div className={style.playIconWrapper}>
            <div className={style.playIcon}>
              <PlayIcon />
            </div>
          </div>
        </a>
      </li>
    ))
    .getOrElseValue(<></>);
};

export default VideoAttachment;
