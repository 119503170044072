import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../../Person/types';
import OnlineStatus from './SharedInternals/OnlineStatus';
import PersonBasePopoverable from './SharedInternals/PersonBasePopoverable';

interface Props {
  store: PersonStoreContract;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const PersonDefault: React.FC<Props> = ({ store, presenceStore }) => (
  <PersonBasePopoverable personStore={store}>
    {{
      onlineStatus: <OnlineStatus presenceStore={presenceStore} personStore={store} kind="light" />,
      details: nothing(),
    }}
  </PersonBasePopoverable>
);

export default observer(PersonDefault);
