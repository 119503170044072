import { observer } from 'mobx-react';
import { SharedInvitationResourceStore } from '.';
import Loader from '../../../../components/Loader';
import ErrorMessage from '../../../ErrorMessage';
import UseCaseError from '../../../ErrorMessage/UseCaseError';
import { SharedInvitationResource } from './Types';

interface Props {
  store: SharedInvitationResourceStore;
  children: (resource: SharedInvitationResource) => React.ReactElement;
}

function WhenSharedInvitationResource({ store, children }: Props) {
  switch (store.state.kind) {
    case 'waiting':
    case 'loading':
      return <Loader delay={0} />;
    case 'ready':
      return children(store.state.resource);
    case 'shared-invitation-link-invalid':
      return <UseCaseError message={store.state.message} />;
    case 'error':
      return <ErrorMessage message={store.state.message} />;
  }
}

export default observer(WhenSharedInvitationResource);
