import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import FreeBusyTestStore from '.';
import { AppyError } from '../Appy';
import ErrorActionableReaction from '../ErrorActionableReaction';
import { successfulLoading } from '../FreebusyStore/SuccessfulLoading';
import { warnAndNotify } from '../Honeybadger';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import { TranslationsState } from '../Translations';
import { loadFreebusy } from '../components/Freebusy/Loader';
import { FreebusyInitializationError, PickATime } from '../components/Freebusy/Types';
import { State } from './Types';

interface Props {
  store: FreeBusyTestStore;
  translationsState: TranslationsState;
}

const initializeError =
  (store: FreeBusyTestStore) =>
  (err: FreebusyInitializationError): void => {
    switch (err.kind) {
      case 'missing-var-error':
        warnAndNotify(
          'MissingVarError',
          `Unable to load Freebusy: Missing environment variable ${err.key}`,
          err,
        );
        store.error('Unable to load Freebusy');
        break;
      case 'freebusy-load-error':
        warnAndNotify('FreebusyLoadError', err.key, err);
        store.error('Unable to load Freebusy');
        break;
      case 'missing-link-error':
        warnAndNotify('MissingLinkError', `Unable to load Freebusy: Missing freebusy js link`, err);
        store.error('Unable to load Freebusy');
        break;
      default:
        assertNever(err);
    }
  };

export type FreebusyError = AppyError | MissingLinkError;

class FreeBusyTestReactions extends ErrorActionableReaction<FreeBusyTestStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
        Task.succeed<FreebusyInitializationError, ReadonlyArray<Link>>(rootResourceStore.links)
          .andThen(findLinkT('calendaring-app'))
          .andThen((t) => loadFreebusy(t.href))
          .fork(
            initializeError(this.props.store),
            successfulLoading(state.freebusySettings, this.props.store),
          );
        break;
      case 'initializing':
        Task.succeed<FreebusyInitializationError, PickATime>(state.freebusy.pickatime)
          .do((pickatime) => pickatime.onerror(initializeError(this.props.store)))
          .do((pickatime) => pickatime.init(state.freebusySettings.initSettings))
          .do((pickatime) =>
            pickatime.onclick(() => {
              const settings = state.freebusySettings;
              pickatime.open(toJS(settings.availabilityRequest), toJS(settings.availabilityQuery));
            }),
          )
          .do((pickatime) => {
            pickatime.onselect((response, proposal) => {
              if (response.statusCode === 200) {
                this.props.store.submitted();
              } else {
                warnAndNotify('FreeBusyTestReactions', `pickatime.onselect failed`, {
                  response,
                  proposal,
                });
                this.props.store.error('Freebusy failed to schedule meeting');
              }
            });
          })
          .fork(initializeError(this.props.store), this.props.store.ready);
        break;
      case 'submitted':
      case 'ready':
      case 'error':
        break;
    }
  };
}

export default observer(FreeBusyTestReactions);
