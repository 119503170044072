import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { startExperienceDetailsRoute, targetedInvitationRoute } from '../../../ClientRoutes';
import Loader from '../../../components/Loader';
import ErrorMessage from '../../ErrorMessage';
import PageLayoutWrapper from '../../PageLayoutWrapper';
import Form from './Form';
import { InvitationStore } from './InvitationStore';
import { SharedInvitationResource } from './SharedInvitationResourceStore/Types';
import SingleSignOn from './SingleSignOn';
import { UserState } from './Types';

interface Props {
  currentUserState: UserState;
  sharedInvitationResource: SharedInvitationResource;
  invitationStore: InvitationStore;
}

class SharedOpenEnrollmentView extends React.Component<Props> {
  render() {
    const { invitationStore, sharedInvitationResource, currentUserState } = this.props;
    const state = invitationStore.state;

    switch (state.kind) {
      case 'waiting':
      case 'validating-email-from-identified-user':
        return (
          <PageLayoutWrapper>
            <Loader />
          </PageLayoutWrapper>
        );
      case 'authenticating-user':
        return state.experienceId.cata({
          Nothing: () => <Redirect to={targetedInvitationRoute(state.uuid)} />,
          Just: (experienceId) => (
            <Redirect to={startExperienceDetailsRoute(state.uuid, experienceId)} />
          ),
        });
      case 'error':
        return (
          <PageLayoutWrapper>
            <ErrorMessage message={state.message} />
          </PageLayoutWrapper>
        );
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'registering':
      case 'registered':
        return (
          <PageLayoutWrapper>
            <Form
              store={invitationStore}
              state={state}
              sharedInvitationResource={sharedInvitationResource}
            />
          </PageLayoutWrapper>
        );
      case 'instant-sso':
        return (
          <SingleSignOn store={invitationStore} state={state} currentUserState={currentUserState} />
        );
    }
  }
}

export default observer(SharedOpenEnrollmentView);
