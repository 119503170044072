import * as React from 'react';
import * as style from '../../Conference/style.module.css';
import BreakoutTimeRemainingStore from './BreakoutTimeRemainingStore';
import Timer from './Timer';
import { observer } from 'mobx-react';

interface Props {
  breakoutTimeRemainingStore: BreakoutTimeRemainingStore;
}

const BreakoutTimeRemaining: React.FC<Props> = ({ breakoutTimeRemainingStore }) => {
  const { timeRemaining } = breakoutTimeRemainingStore;

  switch (breakoutTimeRemainingStore.state.kind) {
    case 'waiting':
    case 'active':
      return timeRemaining
        .map((secondsRemaining) => <Timer secondsRemaining={secondsRemaining} />)
        .getOrElseValue(<></>);
    case 'expiring':
      return timeRemaining
        .map((secondsRemaining) => (
          <div className={style.expiring}>
            <Timer secondsRemaining={secondsRemaining} />
          </div>
        ))
        .getOrElseValue(<></>);
    case 'expired':
      return <></>;
  }
};

export default observer(BreakoutTimeRemaining);
