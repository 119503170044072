import * as React from 'react';
import { Link } from '../../Resource/Types';
import { AttachmentResource } from '../../SegmentStore/Types';
import DocumentAttachment from './DocumentAttachment';
import VideoAttachment from './VideoAttachment';

interface Props {
  attachmentResource: AttachmentResource;
  links: ReadonlyArray<Link>;
}

const AttachmentListItem: React.FC<Props> = ({ attachmentResource, links }) => {
  switch (attachmentResource.payload.kind) {
    case 'document':
      return <DocumentAttachment attachmentResource={attachmentResource} />;
    case 'video':
      return <VideoAttachment attachmentResource={attachmentResource} links={links} />;
  }
};

export default AttachmentListItem;
