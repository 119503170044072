import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AnnouncementVideoAssetResource, VideoAssetResource } from '../Video/Types';
import { ReqHlsVideoAssetResource } from './Types';

interface Props {
  videoResource: ReqHlsVideoAssetResource | AnnouncementVideoAssetResource | VideoAssetResource;
}
@observer
class NoPlayer extends React.Component<Props, {}> {
  componentDidMount() {
    warn(
      'NoPlayerRendered',
      `NoPlayer was rendered for ${this.props.videoResource.payload.uuid}`,
      this.props.videoResource
    );
  }

  render() {
    return <div id="no-player" data-test-no-player={true} />;
  }
}

export default NoPlayer;
