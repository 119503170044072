import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../../ProgramStore/Types';
import * as style from '../../../../../ProgramSummary/style.module.css';
import CurrentModule from '../../CurrentModule';
import OnDemandCurrentCohortModule from './OnDemandCurrentCohortModule';
import OnDemandModuleIcon from './OnDemandModuleIcon';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
  percentIncomplete: number;
  percentComplete: number;
  expectedPercentComplete: number;
}

const OnDemandProgramModuleProgressContent: React.FC<Props> = ({
  programModule,
  programDetail,
  percentIncomplete,
  percentComplete,
  expectedPercentComplete,
}) => {
  const finalModule = programDetail.modules[programDetail.modules.length - 1];
  const isFinalModule = finalModule === programModule;
  return (
    <>
      <div className={style.progressLine}>
        <div className={style.progressLine} data-complete={percentComplete} />
        <CurrentModule programModule={programModule} programDetail={programDetail} />
        <div
          className={`${style.progressLine} ${style.onDemandProgressLineDanger}`}
          data-complete={expectedPercentComplete}
        />
        <OnDemandCurrentCohortModule programModule={programModule} programDetail={programDetail} />
        <div className={style.progressLine} data-incomplete={percentIncomplete} />
      </div>
      <div className={style.moduleIcon}>
        <OnDemandModuleIcon
          programModule={programModule}
          accessKind={programDetail.accessKind}
          isFinalModule={isFinalModule}
        />
      </div>
    </>
  );
};

export default observer(OnDemandProgramModuleProgressContent);
