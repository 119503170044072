import { just, Maybe, nothing } from 'maybeasy';
import { byPayloadId, find } from '@execonline-inc/collections';
import { when } from '@execonline-inc/maybe-adapter';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { InvalidUrlError } from '@execonline-inc/url';
import { always } from '@kofno/piper';
import { AppyError } from '../../../../Appy';
import { MissingLinkError } from '../../../../LinkyLinky';
import { LanguageResource, LanguagesResource } from '../../../../ProfileFormStore/Types';
import { Resource } from '../../../../Resource/Types';
import { SchoolPartner, SchoolPartnerResource } from '../../Common/Experience/SchoolPartner';

export type LoadingError = MissingLinkError | AppyError | InvalidUrlError;

export type ExperienceGroupTitle = 'Strategy & Innovation' | 'Execution';

export type CommerceType = 'cc-only' | 'cc-or-direct-bill' | 'not-available';

export type LoginMessageType = 'use-case-description' | 'use-case-login-message';

export type DeliveryChannel =
  | 'Email Campaign'
  | 'L&D Newsletter'
  | 'LMS (Degreed)'
  | 'LMS (Workday Learning)'
  | 'LMS (Docebo)'
  | 'LMS (Edcast)'
  | 'LMS (Gloat)'
  | 'LMS (Absorb)'
  | 'Testing'
  | 'Social Sharing'
  | 'Other';

export type RegistrationInvitationKind =
  | 'new-program-family-shared-open-enrollment'
  | 'program-family-shared-open-enrollment'
  | 'shared-open-enrollment'
  | 'open-enrollment'
  | 'direct-enrollment'
  | 'preview-only';

export interface ScheduledAvailability {
  kind: 'scheduled';
  date: Date;
  programId: number;
  conflictingProgram: Maybe<ConflictingProgram>;
}

export interface OnDemandAvailability {
  kind: 'on-demand';
  programId: number;
  conflictingProgram: Maybe<ConflictingProgram>;
}

export interface ConflictingProgram {
  programId: number;
  kind: ConflictingProgramKind;
  startOn: Maybe<Date>;
  endOn: Maybe<Date>;
}

export type ConflictingProgramKind =
  | 'enrolled-on-demand'
  | 'enrolled-scheduled'
  | 'overlaps-with-existing'
  | 'availability-in-progress'
  | 'experience-in-progress';

export type Availability = ScheduledAvailability | OnDemandAvailability;

export type AvailabilityResource = Resource<Availability>;

export type Availabilities = ReadonlyArray<Availability>;

export type AvailabilityResources = ReadonlyArray<AvailabilityResource>;

export type OfferingType =
  | 'epc'
  | 'aep'
  | 'msuite'
  | 'coaching'
  | 'group-coaching'
  | 'program-sequence';

export type ProductDetails =
  | CoachingProductDetails
  | ProgramSequenceProductDetails
  | GroupCoachingProductDetails;

export interface CoachingProductDetails {
  kind: 'leadership-coaching';
  keyBenefitsHtml: Maybe<AlreadyTranslatedText>;
  pullQuoteReviewsHtml: Maybe<AlreadyTranslatedText>;
  howBestToUseHtml: Maybe<AlreadyTranslatedText>;
}

export interface ProgramSequenceProductDetailsSections {
  title: AlreadyTranslatedText;
  label: AlreadyTranslatedText;
  description: AlreadyTranslatedText;
  programs: AlreadyTranslatedText[];
}
export interface ProgramSequenceProductDetails {
  kind: 'program-sequence';
  schoolPartnerResources: SchoolPartnerResource[];
  sections: ProgramSequenceProductDetailsSections[];
  sectionsCount: number;
}

export interface GroupCoachingSessionDetails {
  id: number;
  title: Maybe<AlreadyTranslatedText>;
  description: Maybe<AlreadyTranslatedText>;
  startTime: Maybe<Date>;
  groupCoachProfile: Maybe<CoachingProfileResource>;
  duration: Maybe<number>;
}

export type GroupCoachingSessionResource = Resource<GroupCoachingSessionDetails>;

export type GroupCoachingSessionResources = ReadonlyArray<GroupCoachingSessionResource>;

export interface GroupCoachingProductDetails {
  kind: 'group-coaching';
  id: number;
  title: Maybe<AlreadyTranslatedText>;
  description: Maybe<AlreadyTranslatedText>;
  groupCoachingSessions: Maybe<Resource<GroupCoachingSessionResources>>;
  remainingSeats: Maybe<number>;
  sessionDurationInMinutes: number;
}

export type GroupCoachingProductDetailsResource = Resource<GroupCoachingProductDetails>;

export interface ExperienceBase {
  id: number;
  experienceId: string;
  offeringType: OfferingType;
  primaryColor: string;
  secondaryColor: string;
  title: AlreadyTranslatedText;
  description: AlreadyTranslatedText;
  schoolPartner: Resource<SchoolPartner>;
  primaryCompetencies: CompetencyResource[];
  secondaryCompetencies: CompetencyResource[];
  availabilities: AvailabilityResources;
  facultyCount: Maybe<number>;
  duration: Maybe<number>;
  totalHours: Maybe<number>;
  hoursPerWeek: Maybe<number>;
  publicDetailedDescriptionHtml: Maybe<AlreadyTranslatedText>;
  whoShouldAttendHtml: Maybe<AlreadyTranslatedText>;
  keyTakeawaysHtml: Maybe<AlreadyTranslatedText>;
  programStructureAndFeaturesHtml: Maybe<AlreadyTranslatedText>;
  howItWorksHtml: Maybe<AlreadyTranslatedText>;
  deliveryChannel: Maybe<DeliveryChannel>;
  registrationInvitationKind: RegistrationInvitationKind;
  availableLanguages: LanguagesResource;
  price: Maybe<number>;
  productDetails: Maybe<ProductDetails>;
}

export interface NotEnrollableExperienceParts {
  kind: 'not-enrollable';
}

export interface NotEnrollableExperience extends ExperienceBase, NotEnrollableExperienceParts {}

export interface NotLicensedExperienceParts {
  kind: 'not-licensed';
}

export interface NotLicensedExperience extends ExperienceBase, NotLicensedExperienceParts {}

export interface CommerceEnrollableExperienceParts {
  kind: 'commerce-enrollable';
  programId: number;
}

export interface EnrollableExperienceParts {
  kind: 'enrollable';
}

export interface GraduatedExperience extends ExperienceBase, GraduatedExperienceParts {}

export interface GraduatedExperienceParts {
  kind: 'graduated';
  programId: number;
  courseCompletedAt: Date;
  courseCompletedDate: Maybe<Date>;
  startOn: Date;
}

export interface EnrollableExperience extends ExperienceBase, EnrollableExperienceParts {}

export interface CommerceEnrollableExperience
  extends ExperienceBase,
    CommerceEnrollableExperienceParts {}

export interface ResumableExperienceParts {
  kind: 'resumable';
  programId: number;
  moduleId: number;
  segmentId: number;
}

export interface ResumableExperience extends ExperienceBase, ResumableExperienceParts {}

export interface BeginnableExperienceParts {
  kind: 'beginnable';
  programId: number;
  moduleId: number;
  segmentId: number;
}

export interface BeginnableExperience extends ExperienceBase, BeginnableExperienceParts {}

export interface ReturnableExperienceParts {
  kind: 'returnable';
  programId: number;
  courseCompletedAt: Date;
  courseCompletedDate: Maybe<Date>;
  startOn: Date;
}

export interface ReturnableExperience extends ExperienceBase, ReturnableExperienceParts {}

export interface UpcomingExperienceParts {
  kind: 'upcoming';
  startOn: Date;
}

export interface UpcomingExperience extends ExperienceBase, UpcomingExperienceParts {}

export interface CoachingProfile {
  id: number;
  userId: number;
  name: AlreadyTranslatedText;
}

export type CoachingProfileResource = Resource<CoachingProfile>;

export type ExperienceParts =
  | CommerceEnrollableExperienceParts
  | NotEnrollableExperienceParts
  | EnrollableExperienceParts
  | ResumableExperienceParts
  | BeginnableExperienceParts
  | ReturnableExperienceParts
  | UpcomingExperienceParts
  | GraduatedExperienceParts
  | NotLicensedExperienceParts;

export type Experience =
  | CommerceEnrollableExperience
  | NotEnrollableExperience
  | EnrollableExperience
  | ResumableExperience
  | BeginnableExperience
  | ReturnableExperience
  | UpcomingExperience
  | GraduatedExperience
  | NotLicensedExperience;

export const whenMultipleAvailabilities = (experience: Experience): Maybe<Experience> =>
  when(experience.availabilities.length > 1, experience);

export interface NotEnrollableAlert {
  kind: string;
  programId: number;
  title: Maybe<string>;
  description: Maybe<string>;
  startOn: Maybe<Date>;
  endOn: Maybe<Date>;
  experience: Maybe<ExperienceResource>;
}

export type ExperienceResource = Resource<Experience>;

export type ExperienceResourceFilter = Resource<Array<ExperienceResource>>;

export type NotEnrollableAlertResource = Resource<NotEnrollableAlert>;

export type Experiences = ReadonlyArray<Experience>;

export type ExperienceResources = ReadonlyArray<ExperienceResource>;

export type ExperienceFilters = {
  availableLanguages: LanguageResource[];
  durations: DurationResource[];
  schoolPartners: SchoolPartnerResource[];
  competencies: CompetencyResource[];
};

export type DurationResource = Resource<Duration>;

export type Durations = ReadonlyArray<DurationResource>;

export type Duration = {
  value: number;
  label: string;
};

export type CompetencyResource = Resource<Competency>;

export type Competencies = ReadonlyArray<CompetencyResource>;

export type Competency = {
  id: number;
  name: AlreadyTranslatedText;
};

export type ExperienceFiltersResource = Resource<ExperienceFilters>;
export interface UseCase {
  id: number;
  name: AlreadyTranslatedText;
  description: AlreadyTranslatedText;
  commerceType: CommerceType;
  displayType: UseCaseDisplayType;
  productLicenseId: number;
  productLicenseType: string;
}

export type UseCaseDisplayType = 'list' | 'grid';

export type ProductCollectionDisplayType = 'list' | 'carousel' | 'grid';

export type ExperienceDisplayTypeKind = 'experiences' | 'product-collection';

export type PerPageCount = 1 | 2 | 3 | 4;

export type ProductCollectionKind =
  | 'recommendation-engine'
  | 'learning-design'
  | 'dynamic-recommendation'
  | 'group-coaching-groups-recommendation';

export interface ProductCollection {
  id: number;
  kind: ProductCollectionKind;
  name: AlreadyTranslatedText;
  shortName: string;
  description: Maybe<AlreadyTranslatedText>;
  experiences: ExperienceResources;
  displayPerPageCount: PerPageCount;
  displayType: ProductCollectionDisplayType;
  displayButtonLabel: Maybe<string>;
}

export function shortName(
  experience: ExperienceResource,
): (productCollections: ProductCollection) => Maybe<string>;
export function shortName(
  experience: ExperienceResource,
  productCollections: ProductCollection,
): Maybe<string>;
export function shortName(experience: ExperienceResource, productCollection?: ProductCollection) {
  const doit = ({ experiences, shortName }: ProductCollection) =>
    find(byPayloadId(experience.payload.id), experiences).map(always(shortName));

  return typeof productCollection === 'undefined' ? doit : doit(productCollection);
}

export type ProductCollections = ReadonlyArray<ProductCollection>;
export interface DiscoveryPortalPayload {
  notEnrollableAlert: Maybe<NotEnrollableAlertResource>;
  experiences: ExperienceResources;
  useCase: UseCase;
  productCollections: ProductCollections;
  experienceFilters: ExperienceFiltersResource;
}

export type DiscoveryPortalResource = Resource<DiscoveryPortalPayload>;

export interface ExperienceGroup {
  title: ExperienceGroupTitle;
  experiences: Experience[];
}

export type GroupedExperiences = ReadonlyArray<ExperienceGroup>;

export type TimeCommitment = Pick<Experience, 'duration' | 'hoursPerWeek' | 'totalHours'>;

export type KeyTakeaways = Pick<
  Experience,
  'keyTakeawaysHtml' | 'programStructureAndFeaturesHtml' | 'howItWorksHtml'
>;

export interface ThemedIconStyle {
  color?: string;
  backgroundColor?: string;
}

export function groupCoachingExperience(
  experience: Experience,
): Maybe<GroupCoachingProductDetails> {
  return experience.productDetails.andThen((productDetails) => {
    switch (productDetails.kind) {
      case 'leadership-coaching':
      case 'program-sequence':
        return nothing();
      case 'group-coaching':
        return just(productDetails);
    }
  });
}

export function learningJourneyExperience(
  experience: Experience,
): Maybe<ProgramSequenceProductDetails> {
  return experience.productDetails.andThen((productDetails) => {
    switch (productDetails.kind) {
      case 'group-coaching':
      case 'leadership-coaching':
        return nothing();
      case 'program-sequence':
        return just(productDetails);
    }
  });
}

export function leadershipCoachingExperience(
  experience: Experience,
): Maybe<CoachingProductDetails> {
  return experience.productDetails.andThen((productDetails) => {
    switch (productDetails.kind) {
      case 'leadership-coaching':
        return just(productDetails);
      case 'program-sequence':
      case 'group-coaching':
        return nothing();
    }
  });
}
