import { observer } from 'mobx-react';
import * as React from 'react';
import { calendarEventStore } from '../../EventsStore/CalendarEventStore';
import { CalendarEvent, EventResource } from '../../EventsStore/Types';
import { NotTranslated } from '../../Translations';
import CalendarIcon from '../Cutesies/CalendarIcon';
import ActionLabel from './ActionLabel';
import EventDetails from './EventDetails';
import FreebusyAction from './FreebusyAction';
import * as style from './style.module.css';

interface Props {
  eventResource: EventResource;
}

const eventStyle = (event: CalendarEvent): string => {
  switch (event.kind) {
    case 'past-event':
      return style.pastEvent;
    case 'upcoming-event':
    case 'current-coaching-event':
      return style.coachingEvent;
    case 'live-event':
      return style.liveMeetingEvent;
    case 'other-event':
      return style.otherEvent;
  }
};

const Event: React.FC<Props> = ({ eventResource }) => {
  const store = calendarEventStore(eventResource);
  const event = store.event;

  return (
    <div className={eventStyle(event)} data-test-event={event.id}>
      <div className={style.icon}>
        <CalendarIcon />
      </div>
      <h3 className={style.title} data-test-event-title={event.title}>
        <NotTranslated text={event.title} />
      </h3>
      <EventDetails event={event} />
      <div className={style.description} data-test-event-description={true}>
        <NotTranslated text={event.description} />
      </div>
      <ActionLabel store={store} />
      <FreebusyAction eventResource={eventResource} />
    </div>
  );
};

export default observer(Event);
