import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import ErrorActionableReaction, { EAProps } from '../../../../ErrorActionableReaction';
import LectureSizingStore, { State } from '../Stores/LectureSizingStore';
import { State as SwitcherooState } from '../Stores/SwitcherooStore';
import { getCurrentSlideAspectRatio, resizeCurrentSlide } from './CurrentSlideSizing';
import { VideoInitializationTimeout, getVideoAspectRatio, resizeVideo } from './VideoSizing';

export interface CurrentSlide {
  kind: 'legacy' | 'ppt';
  aspectRatio: number;
}

export type VideoType = 'vimeo' | 'jw-player';

interface Video {
  kind: VideoType;
  aspectRatio: number;
}

interface UnknownResizableProps {
  kind: 'unknown';
  aspectRatio: 1;
}

export type ResizableProps = UnknownResizableProps | CurrentSlide | Video;

export type SegmentType = 'lecture' | 'legacy-lecture';

interface Props extends EAProps<LectureSizingStore> {
  videoWrapperEl: React.RefObject<HTMLDivElement>;
  currentSlideWrapperEl: React.RefObject<HTMLDivElement>;
  lectureEl: React.RefObject<HTMLDivElement>;
  currentPptSlideEl: React.RefObject<HTMLIFrameElement>;
  currentPptSlideSizerEl: React.RefObject<HTMLDivElement>;
  switcherooState: SwitcherooState;
  segmentType: SegmentType;
}

class LectureSizingReactions extends ErrorActionableReaction<LectureSizingStore, State, Props> {
  tester = () => this.props.store.state;

  currentSlideResizingProps: ResizableProps = {
    kind: 'unknown',
    aspectRatio: 1,
  };

  videoResizingProps: ResizableProps = {
    kind: 'unknown',
    aspectRatio: 1,
  };

  setResizableProps = (resizableProps: ResizableProps[]) =>
    resizableProps.map((props) => {
      switch (props.kind) {
        case 'vimeo':
        case 'jw-player':
          this.videoResizingProps = props;
          break;
        case 'legacy':
        case 'ppt':
          this.currentSlideResizingProps = props;
          break;
        case 'unknown':
          break;
        default:
          assertNever(props);
      }
    });

  handleInitializationError =
    (context: { segmentId: number; videoUuid: string }) => (error: VideoInitializationTimeout) => {
      switch (error.kind) {
        case 'video-initialization-timeout':
          warn(
            'Lecture segment initialization failure',
            'Lecture segment video failed to initialize',
            context,
          );
          this.props.store.error('We were unable to load this lecture. Please try again later.');
          break;
        default:
          assertNever(error.kind);
      }
    };

  effect = (state: State) => {
    const {
      store,
      segmentType,
      switcherooState,
      currentPptSlideEl,
      currentPptSlideSizerEl,
      videoWrapperEl,
      lectureEl,
    } = this.props;
    switch (state.kind) {
      case 'waiting':
      case 'error':
        break;
      case 'initializing':
        const initializingTasks = [
          getCurrentSlideAspectRatio(segmentType),
          getVideoAspectRatio(videoWrapperEl),
        ];
        Task.all(initializingTasks)
          .do(this.setResizableProps)
          .fork(
            this.handleInitializationError({
              segmentId: state.segmentId,
              videoUuid: state.videoUuid,
            }),
            store.resize,
          );
        break;
      case 'ready':
        if (lectureEl.current) {
          lectureEl.current.style.opacity = '1';
        }
        break;
      case 'resizing':
        resizeCurrentSlide(
          currentPptSlideEl,
          currentPptSlideSizerEl,
          this.currentSlideResizingProps,
        );
        resizeVideo(switcherooState, videoWrapperEl, this.videoResizingProps);
        store.ready();
        break;
      default:
        assertNever(state);
    }
  };
}

export default LectureSizingReactions;
