import { Freebusy } from '../components/Freebusy/Types';
import { Error } from '../ErrorHandling';
import { FreebusySettings } from '../FreebusyStore';

export type IconListItemTitle =
  | 'Scheduling library loaded'
  | 'Web Worker library loaded'
  | 'Cookies set correctly';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  freebusySettings: FreebusySettings;
}

interface Initializing {
  kind: 'initializing';
  freebusy: Freebusy;
  freebusySettings: FreebusySettings;
}

interface Ready {
  kind: 'ready';
}

interface Submitted {
  kind: 'submitted';
}

export type State = Waiting | Loading | Initializing | Ready | Submitted | Error;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (freebusySettings: FreebusySettings): Loading => ({
  kind: 'loading',
  freebusySettings,
});

export const submitted = (): Submitted => ({
  kind: 'submitted',
});

export const ready = (): Ready => ({
  kind: 'ready',
});

export const initializing = (
  freebusy: Freebusy,
  freebusySettings: FreebusySettings,
  previousState: Loading
): Initializing => ({
  ...previousState,
  kind: 'initializing',
  freebusy,
  freebusySettings,
});
