import { readVarM } from '@execonline-inc/environment';
import { createBrowserHistory } from 'history';

const basename = readVarM('REACT_APP_BASE_NAME').getOrElseValue('');

const historySingleton = () => {
  return createBrowserHistory({ basename });
};

export default historySingleton();
