import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ExternalProgramResource } from '../../../../SegmentStore/Types';
import AtAGlanceSection from './AtAGlanceSection';
import KeyTakeawaysSection from './KeyTakeawaysSection';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const ExternalProgramDetailViewImpl: React.FC<Props> = ({ externalProgramResource }) => {
  switch (externalProgramResource.payload.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      // I don't think we should have program sequences nested inside program sequences.
      return emptyFragment();
    case 'epc':
    case 'aep':
    case 'msuite':
      return (
        <div className="relative pb-10">
          <div className="px-5 pt-6">
            <AtAGlanceSection externalProgramResource={externalProgramResource} />
            <KeyTakeawaysSection externalProgramResource={externalProgramResource} />
          </div>
        </div>
      );
  }
};

export default observer(ExternalProgramDetailViewImpl);
