import { ReactNode } from 'react';

type ButtonColor = {
  primary: string;
  secondary: string;
};

export const arrowButtonStyle: string = 'inline-flex p-0 items-center';
export const arrowButtonText: string = 'py-0 px-4';
export const buttonColor: ButtonColor = {
  primary: 'buttonPrimary',
  secondary: 'reversedButton',
};
export type ButtonProps = {
  children: ReactNode;
  className?: string;
  color?: keyof ButtonColor;
  arrowDirection: 'left' | 'right' | 'down';
  arrowPosition: 'start' | 'end';
  onClick: () => void;
};
