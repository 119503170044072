import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  emptyFragment,
} from '@execonline-inc/execonline-ui';
import { dashboardUrl } from '../../../../../ClientRoutes';
import EnrollmentStore from '../../../DiscoveryPortal/Enrollment/Store';
import { NotEnrollableAlert } from '../../Experience/Types';
import { EnrolledModalStore } from './Common';
import EnrolledModalBody from './EnrolledModalBody';
import EnrolledModalFooter from './EnrolledModalFooter';
import EnrolledModalHeader from './EnrolledModalHeader';
import EnrolledModalSubHeader from './EnrolledModalSubHeader';
import { EnrolledModalContext } from './EnrolledModalContext';

interface Props {
  invitationUuid: string;
  notEnrollableAlert: NotEnrollableAlert;
  enrollmentStore: EnrollmentStore;
  modalStore: EnrolledModalStore;
}

function ExperienceEnrolledContent({
  invitationUuid,
  notEnrollableAlert,
  modalStore,
  enrollmentStore,
}: Props) {
  switch (modalStore.state.kind) {
    case 'closed-and-routing':
      return <Redirect to={dashboardUrl} />;
    case 'waiting':
    case 'loading':
    case 'closed':
    case 'confirming':
    case 'confirm-and-routing':
      return emptyFragment();
    case 'reopened':
    case 'open':
      return (
        <EnrolledModalContext.Provider
          value={{
            invitationUuid,
            experienceResource: notEnrollableAlert.experience,
            enrollmentStore,
          }}
        >
          <Modal
            className="h-full max-w-[42.5rem] rounded-none sm:h-auto"
            open
            onClose={modalStore.close}
          >
            <ModalHeader className="flex-col gap-y-4 pt-12">
              <EnrolledModalHeader />
              <EnrolledModalSubHeader
                startOn={notEnrollableAlert.startOn}
                endOn={notEnrollableAlert.endOn}
              />
            </ModalHeader>
            <ModalBody className="pt-9">
              <EnrolledModalBody />
            </ModalBody>
            <ModalFooter className="pt-9">
              <EnrolledModalFooter
                onBrowseExperience={modalStore.close}
                onGoToDashboard={modalStore.closeAndRoute}
              />
            </ModalFooter>
          </Modal>
        </EnrolledModalContext.Provider>
      );
  }
}

export default observer(ExperienceEnrolledContent);
