import { observer } from 'mobx-react';
import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { T } from '../../Translations';
import * as style from './style.module.css';
import SubmissionButton from './SubmissionButton';

interface Props {
  store: ConjointAnalysisStore;
}

const Submission: React.FC<Props> = ({ store }) => {
  return (
    <>
      <br />
      <h3>
        <T kind="STEP 4: SUBMIT YOUR DESIGN" />
      </h3>
      <div className={style.stepContent}>
        <p>
          <T
            kind="Please review your choices carefully before ..."
            emphasis={(content) => <u>{content}</u>}
          />
        </p>
        <p>
          <T kind={`When you are satisfied, click the ...`} />
        </p>
        <p>
          <T
            kind="Shortly following submission, you will receive ..."
            emailAddress={
              <span className={style.mailToLink}>
                <a className={style.submissionLink} href="mailto:support@execonline.com">
                  support@execonline.com
                </a>
              </span>
            }
          />
        </p>
      </div>
      <div>
        <SubmissionButton store={store} />
      </div>
    </>
  );
};

export default observer(Submission);
