import { observer } from 'mobx-react';
import { SvgIcon, SvgIconProps } from '@execonline-inc/execonline-ui';

function GroupCoachingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 62 35">
      <path
        opacity="0.2"
        d="M61.8037 55.3166L25.8393 14.4229H0.0104761L37.012 55.3166L0 96.2515H25.8917L61.8037 55.3166Z"
        fill="#3E5B66"
      />
      <path
        opacity="0.6"
        d="M61.0717 20.1495L43.5726 0.251953H31.0051L49.0089 20.1495L31 40.0672H43.5981L61.0717 20.1495Z"
        fill="#3E5B66"
      />
    </SvgIcon>
  );
}

export default observer(GroupCoachingIcon);
