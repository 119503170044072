import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import OrientationCommencementStore from '.';
import { AppyError, putToApi } from '../../../../../../Appy';
import ErrorActionableReaction, {
  EAProps,
  handleError,
} from '../../../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../../../LinkyLinky';
import { Link } from '../../../../../../Resource/Types';
import ModalStore from '../../../../../Modal/Store';
import { OrientationCommencementState } from './Types';

interface Props extends EAProps<OrientationCommencementStore> {
  store: OrientationCommencementStore;
  modalStore: ModalStore;
}

type OrientationCommencementError = MissingLinkError | AppyError;

const orientationCommencementError =
  (store: OrientationCommencementStore) => (error: OrientationCommencementError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify(
          'OrientationCommencementReactions',
          `Missing link: ${error.rel}`,
          toJS(store.state),
        );
        break;
      default:
        handleError(store, error);
    }
  };

class OrientationCommencementReactions extends ErrorActionableReaction<
  OrientationCommencementStore,
  OrientationCommencementState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: OrientationCommencementState) => {
    const { store, modalStore } = this.props;

    switch (state.kind) {
      case 'waiting':
      case 'ready':
        break;
      case 'creating':
        Task.succeed<OrientationCommencementError, ReadonlyArray<Link>>(
          store.programStore.productDetailsLinks,
        )
          .andThen(findLinkT('orientation-commencement'))
          .andThen(putToApi({}))
          .fork(orientationCommencementError(this.props.store), () => {
            store.programStore.programDetailResource.map(store.programStore.reloading);
            modalStore.open();
            store.done();
          });
        break;
      case 'done':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default OrientationCommencementReactions;
