import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from './style.module.css';
import { NotTranslated } from '../../Translations';
import TeamMember from '../TeamsSummary/TeamsRenderer/TeamsSummary/TeamMember';
import { TeamMemberResource } from '../../TeamsStore/Types';

interface Props {
  teamMemberResource: TeamMemberResource;
}

const TeamMemberOnBody: React.FC<Props> = ({ teamMemberResource }) => {
  return (
    <div className={style.memberFull}>
      <TeamMember teamMemberResource={teamMemberResource} />
      <div className={style.memberDetails}>
        {teamMemberResource.payload.profile.payload.name
          .map((name) => (
            <p className={style.titleOnBody}>
              <NotTranslated text={name} />
            </p>
          ))
          .getOrElseValue(<></>)}
        {teamMemberResource.payload.profile.payload.organization
          .map((organization) => (
            <p className={style.subtitleOnBody}>
              <NotTranslated text={organization} />
            </p>
          ))
          .getOrElseValue(<></>)}
      </div>
    </div>
  );
};

export default observer(TeamMemberOnBody);
