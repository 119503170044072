import { assertNever } from '@kofno/piper';
import clickOutsideStore from '../../ClickOutsideStore';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import { triggerAnalyticsEvent } from '../../components/Tooling/GoogleTagManagerTooling/Loader';
import AnnouncementStore, { State } from './AnnouncementStore';

interface Props extends RCProps<AnnouncementStore> {
  announcementRef: React.RefObject<HTMLDivElement>;
}

const TRANSITION_DURATION = 300;

class AnnouncementReactions extends ReactionComponent<AnnouncementStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { announcementRef, store } = this.props;
    switch (state.kind) {
      case 'initializing':
        if (announcementRef.current) {
          announcementRef.current.style.transition = `${TRANSITION_DURATION}ms max-height ease`;
          if (announcementRef.current.scrollHeight > 145) {
            store.expandable();
          } else {
            store.nonExpandable();
          }
        }
        break;
      case 'collapse-start':
        triggerAnalyticsEvent('event', 'Announcements', 'collapse', 'Dashboard');
        if (announcementRef.current) {
          announcementRef.current.style.maxHeight = `${announcementRef.current.scrollHeight}px`;
        }
        requestAnimationFrame(() => {
          if (announcementRef.current) {
            store.collapsed();
          }
        });
        break;
      case 'expand-start':
        triggerAnalyticsEvent('event', 'Announcements', 'expand', 'Dashboard');
        clickOutsideStore.addListener({ ref: announcementRef, callback: store.collapse });
        if (announcementRef.current) {
          announcementRef.current.style.maxHeight = `${announcementRef.current.scrollHeight}px`;
        }
        setTimeout(() => {
          store.expanded();
        }, TRANSITION_DURATION);
        break;
      case 'collapsed':
      case 'expanded':
        //Clearing in collapsed state -- allows for slight expansion when hovered
        //Clearing in expanded state -- allows for resizing if user resizes browser
        if (announcementRef.current) {
          announcementRef.current.style.maxHeight = ``;
        }
        break;
      case 'waiting':
      case 'expandable':
      case 'non-expandable':
        break;
      default:
        assertNever(state);
    }
  };
}

export default AnnouncementReactions;
