import { observer } from 'mobx-react';
import * as React from 'react';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { AuthSignIn } from '../Types';
import PasswordForm from './PasswordForm';
import SsoForm from './SsoForm';

interface Props {
  authForm: AuthSignIn;
  registerResource: RegisterResource;
}

const Form: React.FC<Props> = ({ authForm, registerResource }) => {
  switch (authForm.kind) {
    case 'password-sign-in':
      return <PasswordForm usePasswordStore={authForm.store} registerResource={registerResource} />;
    case 'sso-sign-in':
      return <SsoForm ssoLoginStore={authForm.store} registerResource={registerResource} />;
  }
};

export default observer(Form);
