import * as React from 'react';
import * as style from '../style.module.css';
import ActionMenuButton from './ActionMenuButton';
import { observer } from 'mobx-react';
import SubscriptionStore from '../SubscriptionStore';
import { SubscriptionResource } from '../../../../ConversationStore/Types';

interface Props {
  store: SubscriptionStore;
  subscriptionResource: SubscriptionResource;
}

const ResubscribeButton: React.FC<Props> = ({ store, subscriptionResource }) => {
  switch (subscriptionResource.payload.status) {
    case 'subscribed':
      return (
        <>
          <ActionMenuButton tooltipText="You are currently subscribed to this thread. Click to unsubscribe">
            <button
              className={`${style.actionMenuButton} ${style.subscribe}`}
              data-test-action-menu-unsubscribe-menu-toggle
              onClick={() => store.unsubscribingFromMessage(subscriptionResource)}
            >
              <span>Unsubscribe</span>
            </button>
          </ActionMenuButton>
        </>
      );
    case 'unsubscribed':
      return (
        <>
          <ActionMenuButton tooltipText="Click to resubscribe to this thread and receive notifications">
            <button
              className={`${style.actionMenuButton} ${style.unsubscribe}`}
              data-test-action-menu-subscribe-menu-toggle
              onClick={() => store.subscribingToMessage(subscriptionResource)}
            >
              <span>Resubscribe</span>
            </button>
          </ActionMenuButton>
        </>
      );
    case 'ineligible':
    default:
      return <> </>;
  }
};

export default observer(ResubscribeButton);
