import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TeamResourceResource } from '../TeamResourceStore/Types';
import { TPlainTextKey } from '../Translations';
import {
  loading,
  ProgramResourceResource,
  ready,
  Resources,
  ResourcesResource,
  ResourcesState,
  waiting,
} from './Types';

class ResourcesStore {
  @observable
  state: ResourcesState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (resourcesResource: ResourcesResource) => {
    this.state = ready(resourcesResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get resources(): Maybe<Resources> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.resourcesResource.payload);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  @computed
  get programResources(): Maybe<ProgramResourceResource[]> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.resourcesResource.payload.programResources.payload);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  @computed
  get teamResources(): Maybe<TeamResourceResource[]> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.resourcesResource.payload.teamResources.payload);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default ResourcesStore;
