import { observer } from 'mobx-react';
import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { ServerError as TServerError } from '../../Resource/Types';
import { AlreadyTranslated, T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  store: ConjointAnalysisStore;
}

const ErrorHeader: React.FC = () => (
  <h2 data-test-error-header="Please fix the following errors:">
    <T kind="Please fix the following errors:" />
  </h2>
);

const ServerError: React.FC<{ error: TServerError }> = ({ error }) => (
  <div data-test-error={error.param}>
    <li data-test-error-message={error.message}>
      <AlreadyTranslated content={{ kind: 'already-translated-text', text: error.message }} />
    </li>
  </div>
);

const ServerErrors: React.FC<{ errors: TServerError[] }> = ({ errors }) => (
  <>
    {errors.map((err) => (
      <ServerError key={err.param} error={err} />
    ))}
  </>
);

const ConjointAnalysisErrorMessage: React.FC<Props> = ({ store }) =>
  store.serverErrors
    .map((serverErrors) => (
      <div className={style.error}>
        <ErrorHeader />
        <ServerErrors errors={serverErrors} />
      </div>
    ))
    .getOrElse(() => <></>);

export default observer(ConjointAnalysisErrorMessage);
