import { ButtonProps } from '@execonline-inc/execonline-ui';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../../SegmentStore';
import { T } from '../../../../../Translations';
import { AdvanceButton } from '../../Common';

interface Props extends ButtonProps {
  storeState: SegmentStore['state']['kind'];
}

function StateDependentButton({ storeState, children, ...others }: Props) {
  switch (storeState) {
    case 'waiting':
    case 'loading':
    case 'processing-request':
    case 'auto-saving-form-fields':
      return (
        <AdvanceButton {...others} disabled>
          <T kind="Saving..." />
        </AdvanceButton>
      );
    case 'reporting-results':
    case 'ready':
    case 'error':
    case 'completing':
    case 'advancing':
    case 'loaded':
    case 'completing-and-advancing':
    case 'submitting-and-advancing':
    case 'previous-advancing':
    case 'advancing-to':
    case 'schedule-session':
      return <AdvanceButton {...others}>{children}</AdvanceButton>;
    default:
      assertNever(storeState);
  }
}

export default observer(StateDependentButton);
