import { emptyFragment, SlantedStarIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption } from '../../../Types';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
}
const PersonaTraitIcon = ({ option }: Props) =>
  option.resource
    .map((resource) => {
      switch (resource.payload.kind) {
        case 'adaptive-alex':
          return <SlantedStarIcon className="text-[#002060]" size="sm" />;
        case 'collaborative-charlie':
          return <SlantedStarIcon color="red" size="sm" />;
        case 'decisive-darcy':
          return <SlantedStarIcon color="amber" size="sm" />;
        case 'empathetic-elliot':
          return <SlantedStarIcon color="green" size="sm" />;
        case 'visionary-vinny':
          return <SlantedStarIcon color="teal" size="sm" />;
      }
    })
    .getOrElse(emptyFragment);

export default observer(PersonaTraitIcon);
