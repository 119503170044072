import {
  PromotableGroupMemberResource,
  PromotableProductCollectionResource,
  PromotableProgramFamilyResource,
} from './Types';

export const isProgramFamily = (
  item: PromotableGroupMemberResource,
): item is PromotableProgramFamilyResource => {
  return item.payload.kind === 'program-family';
};

export const isProductCollection = (
  item: PromotableGroupMemberResource,
): item is PromotableProductCollectionResource => {
  return item.payload.kind === 'product-collection';
};
