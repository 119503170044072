import ChatablePersonResourcesStore from '../ChatableResourcesStore';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { callApi } from '../Appy';
import { conferenceRosterResourceDecoder } from '../ConferenceRosterStore/Decoders';
import { State } from '../ChatableResourcesStore';
import { ConferenceRosterResource } from '../ConferenceRosterStore/Types';

export interface Props extends EAProps<ChatablePersonResourcesStore> {
  store: ChatablePersonResourcesStore;
}

const pesonsResourcesEndPoint = callApi(conferenceRosterResourceDecoder, {});

class ChatablePersonResourcesReactions extends ErrorActionableReaction<
  ChatablePersonResourcesStore,
  State,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        pesonsResourcesEndPoint(state.link).fork(
          e => handleError(this.props.store, e),
          (conferenceRosterResource: ConferenceRosterResource) => {
            const people = conferenceRosterResource.payload.participants.map(p => ({
              payload: p,
              links: p.profile.links,
            }));
            this.props.store.ready(people);
          }
        );
        break;
    }
  };
}

export default ChatablePersonResourcesReactions;
