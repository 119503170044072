import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fill: string;
}

const ChatIcon: React.FC<Props> = ({ fill }) => {
  return (
    <SVGIcon viewBox="0 0 16 15">
      <path
        fill={fill}
        d="M0 .23v9.144h2.286v2.91l.928-.75 2.697-2.16h5.518V.23H0Zm1.143 1.144h9.143V8.23H5.518l-.16.125-1.93 1.535v-1.66H1.144V1.374ZM12.57 2.516V3.66h2.286v6.857h-2.286v1.661l-2.089-1.66H6.196l-1.428 1.142h5.321l3.625 2.911v-2.91H16V2.515h-3.429Z"
      />
    </SVGIcon>
  );
};

export default observer(ChatIcon);
