import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../../Conference/Types';
import { T } from '../../../Translations';
import PhoneIcon from '../../Cutesies/PhoneIcon';
import ModalStore from '../../Modal/Store';
import * as style from '../style.module.css';
import DetailsModal from './DetailsModal';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
}

const DialInDetails: React.FC<Props> = ({ conferenceRoomResource }) => {
  const modalStore: ModalStore = new ModalStore();

  return (
    <dl className={style.dl}>
      <dt className={style.detailIcon}>
        <PhoneIcon />
      </dt>
      <dd className={style.detailValue}>
        <a href="#" onClick={modalStore.open} data-test-conference-dial-in-info-link={true}>
          <T kind="View Dial-in Instructions" />
        </a>
        <DetailsModal store={modalStore} conferenceRoomResource={conferenceRoomResource} />
      </dd>
    </dl>
  );
};

export default observer(DialInDetails);
