import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../../CommunitiesStore';
import { CommunitiesResource } from '../../../CommunitiesStore/Types';
import { T } from '../../../Translations';
import SmallContentFaker from '../../ContentFaker/SmallContentFaker';

const loadingMore = (
  communitiesStore: CommunitiesStore,
  communitiesResource: CommunitiesResource
) => () => {
  communitiesStore.loadingMore(communitiesResource);
};

interface Props {
  communitiesStore: CommunitiesStore;
  communitiesResource: CommunitiesResource;
}

const LoadCommunities: React.FC<Props> = ({ communitiesStore, communitiesResource }) => {
  switch (communitiesStore.state.kind) {
    case 'loading-more':
      return <SmallContentFaker />;
    case 'ready':
      return (
        <button
          onClick={loadingMore(communitiesStore, communitiesResource)}
          data-test-load-more={true}
        >
          <span>
            <T kind="See More..." />
          </span>
        </button>
      );
    case 'error':
    case 'waiting':
    case 'loading':
      return <></>;
  }
};
export default observer(LoadCommunities);
