import { Maybe } from 'maybeasy';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { EventResource } from '../../../EventsStore/Types';
import { HtmlNode } from '../../../SegmentStore/Types';
import * as style from '../../Conference/style.module.css';
import ContentEmbed from '../../ContentEmbed';
import { nodeDecoder } from '../../ContentEmbed/Types';
import { embeddable, whenEventEmbeddable } from './Types';

interface Props {
  agenda: string;
  eventResource: Maybe<EventResource>;
}

const transform = (eventResource: EventResource) => (
  htmlNode: HtmlNode,
  key: number
): React.ReactNode =>
  nodeDecoder('agenda')
    .decodeAny(htmlNode)
    .andThen(whenEventEmbeddable)
    .andThen((node) => embeddable(node, eventResource))
    .cata({
      Err: () => undefined,
      Ok: (embeddable) => <ContentEmbed key={key} embeddable={embeddable} />,
    });

const Agenda: React.FC<Props> = ({ agenda, eventResource }) => {
  return (
    <div className={style.agenda}>
      {eventResource
        .map((resource) => ReactHtmlParser(agenda, { transform: transform(resource) }))
        .getOrElse(() => (
          <span>{ReactHtmlParser(agenda, { decodeEntities: false })}</span>
        ))}
    </div>
  );
};

export default Agenda;
