import React from 'react';
import { ExperienceResources } from '../../../../../Common/Experience/Types';
import FilterStore from '../../FilterStore';

export interface ExperienceFiltersContextType {
  initializeFilters: (filterStore: FilterStore) => void;
  filterExperienceResources: (experienceResources: ExperienceResources) => ExperienceResources;
}

export const ExperienceFiltersContext = React.createContext<ExperienceFiltersContextType | null>(
  null,
);
