import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { CoachingProductDetails } from '../../../../Common/Experience/Types';
import {
  ExperienceDetailsSection,
  ExperienceDetailsSectionHeader,
  ExperienceHtmlView,
} from '../../../Common';

interface Props {
  productDetails: CoachingProductDetails;
}

function HowBestToUse({ productDetails }: Props) {
  return productDetails.howBestToUseHtml
    .map((data) => (
      <ExperienceDetailsSection className="xl:w-[85%]">
        <ExperienceDetailsSectionHeader title="How to Best use your coaching" />
        <ExperienceHtmlView data={data} />
      </ExperienceDetailsSection>
    ))
    .getOrElse(emptyFragment);
}

export default observer(HowBestToUse);
