import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../CurrentUser/Store';
import FreeBusyTestStore from '../../FreeBusyTestStore';
import FreeBusyTestReactions from '../../FreeBusyTestStore/Reaction';
import { FreebusySettings } from '../../FreebusyStore';
import { SupportedLanguageCode } from '../../SupportedLanguages/Types';
import { TranslationsContext } from '../../Translations';
import { AvailabilityQuery, AvailabilityRequest, InitSettings } from '../Freebusy/Types';
import Concern from './FreeBusyTestView';

class FreeBusyTest extends React.Component {
  store = new FreeBusyTestStore();

  initSettings: InitSettings = {
    buttonType: 'custom',
    buttonId: 'freebusy-test-pickatime-button',
  };

  freebusyLanguage = (): SupportedLanguageCode => {
    const fallback: SupportedLanguageCode = 'en';

    switch (currentUserStore.state.kind) {
      case 'ready':
      case 'refreshing':
      case 'logging-out':
        return currentUserStore.state.currentUserResource.payload.preferredLanguage.getOrElseValue(
          fallback
        );
      case 'waiting':
      case 'loading':
      case 'anonymous':
      case 'errored':
        return fallback;
    }
  };

  availabilityRequest: AvailabilityRequest = {
    link: 'https://freebusy.io/freebusy1@execonline.com/15min',
    display: 'modal',
    language: this.freebusyLanguage(),
  };

  availabilityQuery: AvailabilityQuery = {
    management: {
      canProposeNewTime: true,
    },
    agenda: 'This is a test agenda',
    participants: [
      {
        email: 'freebusy2@execonline.com',
        role: 'proposer',
        name: 'Freebusy Test2',
        timeZone: 'America/New_York',
      },
    ],
  };

  freebusySettings: FreebusySettings = {
    availabilityRequest: this.availabilityRequest,
    availabilityQuery: this.availabilityQuery,
    initSettings: this.initSettings,
  };

  componentDidMount() {
    this.store.loading(this.freebusySettings);
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => <FreeBusyTestReactions store={this.store} translationsState={ts} />}
        </TranslationsContext.Consumer>
        <Concern store={this.store} />
      </>
    );
  }
}

export default observer(FreeBusyTest);
