import { profileStore } from '../../../../../../ProfileStore';
import { sessionStore } from '../../../../../../Session/Store';

const authenticationContext = (): AuthenticationContext => ({
  authToken: sessionStore.sessionToken.getOrElseValue(''),
  userEmail: profileStore.resource.map(({ payload }) => payload.email).getOrElseValue(''),
});

export const exitToProgram = (programId: number): ExitToProgram => ({
  kind: 'exit-to-program',
  ...authenticationContext(),
  programId,
});

export const exitToSegment = ({
  programId,
  moduleId,
  segmentId,
}: Pick<ExitToSegment, 'programId' | 'moduleId' | 'segmentId'>): ExitToSegment => ({
  kind: 'exit-to-segment',
  ...authenticationContext(),
  programId,
  moduleId,
  segmentId,
});

export const exitToDashboard = (): ExitToDashboard => ({
  kind: 'exit-to-dashboard',
  ...authenticationContext(),
});

export const exitToDiscoveryPortal = (guid: string): ExitToDiscoveryPortal => ({
  kind: 'exit-to-discovery-portal',
  guid: guid,
  ...authenticationContext(),
});

export const exitToCalendar = (): ExitToCalendar => ({
  kind: 'exit-to-calendar',
  ...authenticationContext(),
});

export const exitToResources = (): ExitToResources => ({
  kind: 'exit-to-resources',
  ...authenticationContext(),
});

export const exitToTeams = (): ExitToTeams => ({
  kind: 'exit-to-teams',
  ...authenticationContext(),
});

export const exitToProfile = (): ExitToProfile => ({
  kind: 'exit-to-profile',
  ...authenticationContext(),
});

export const authenticateWithSSO = (userEmail: string, url: string): AuthenticateWithSSO => ({
  kind: 'authenticate-with-sso',
  userEmail,
  url,
});

interface AuthenticationContext {
  authToken: string;
  userEmail: string;
}

interface ExitToProgram extends AuthenticationContext {
  kind: 'exit-to-program';
  programId: number;
}

interface ExitToSegment extends AuthenticationContext {
  kind: 'exit-to-segment';
  programId: number;
  moduleId: number;
  segmentId: number;
}

export interface ExitToDashboard extends AuthenticationContext {
  kind: 'exit-to-dashboard';
}

interface ExitToCalendar extends AuthenticationContext {
  kind: 'exit-to-calendar';
}

interface ExitToResources extends AuthenticationContext {
  kind: 'exit-to-resources';
}

interface ExitToTeams extends AuthenticationContext {
  kind: 'exit-to-teams';
}

interface ExitToProfile extends AuthenticationContext {
  kind: 'exit-to-profile';
}

export interface ExitToDiscoveryPortal extends AuthenticationContext {
  kind: 'exit-to-discovery-portal';
  guid: string;
}

interface AuthenticateWithSSO {
  kind: 'authenticate-with-sso';
  userEmail: string;
  url: string;
}

export type ExitContext =
  | ExitToProgram
  | ExitToSegment
  | ExitToDashboard
  | ExitToCalendar
  | ExitToResources
  | ExitToTeams
  | ExitToProfile
  | AuthenticateWithSSO
  | ExitToDiscoveryPortal;
