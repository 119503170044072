import { observer } from 'mobx-react';
import * as React from 'react';
import ExpandableItemStore, { ExpandableItem } from '../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../ProgramStore/Types';
import Module from './Module';

interface Props {
  programId: number;
  modules: ActiveProgramModule[];
}

class Modules extends React.Component<Props, {}> {
  expandableItemStore: ExpandableItemStore<ActiveProgramModule> = new ExpandableItemStore();

  toggle = (item: ExpandableItem<ActiveProgramModule>) => {
    this.expandableItemStore.toggle(item);
  };

  setExpandableItems = (items: ExpandableItem<ActiveProgramModule>[]) => {
    this.expandableItemStore.setExpandableItems(items);
  };

  componentDidMount() {
    this.setExpandableItems(this.props.modules.map(m => ({ item: m, open: m.isCurrentModule })));
  }

  render() {
    return (
      <ol>
        {this.expandableItemStore.expandableItems.map(m => {
          return (
            <Module
              expandableModule={m}
              programId={this.props.programId.toString()}
              key={`module-${m.item.id}${m.open}`}
              toggle={this.toggle}
            />
          );
        })}
      </ol>
    );
  }
}

export default observer(Modules);
