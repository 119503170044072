import { action, observable } from 'mobx';
import { ExternalProgramResource } from '../../../../SegmentStore/Types';

interface Open {
  kind: 'open';
  resource: ExternalProgramResource;
}

interface Close {
  kind: 'close';
}

const open = (resource: ExternalProgramResource): Open => {
  return {
    kind: 'open',
    resource,
  };
};

const close = (): Close => {
  return {
    kind: 'close',
  };
};

type State = Open | Close;

class DetailViewStore {
  @observable
  state: State = {
    kind: 'close',
  };

  @action
  openInfo = (resource: ExternalProgramResource) => {
    this.state = open(resource);
  };

  @action
  closeInfo = () => {
    this.state = close();
  };
}

export default DetailViewStore;
