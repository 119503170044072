import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSelectedCoachProfileNode } from '../../ContentEmbed/Types';
import * as avatarStyle from '../../Person/style.module.css';
import SelectedCoachName from './SelectedCoachName';

interface Props {
  node: CoachingSelectedCoachProfileNode;
}

const SelectedCoachProfileEmbeddable: React.FC<Props> = ({ node }) =>
  node.coach.payload.coachAvatar
    .map((avatar) => (
      <div className={avatarStyle.person}>
        <div
          className={avatarStyle.coachAvatar}
          style={{
            backgroundImage: `url(${avatar.src})`,
          }}
        />
        <SelectedCoachName name={node.coach.payload.name} />
      </div>
    ))
    .getOrElse(() => (
      <div className={avatarStyle.person}>
        <SelectedCoachName name={node.coach.payload.name} />
      </div>
    ));

export default observer(SelectedCoachProfileEmbeddable);
