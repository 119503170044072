import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChatMessageOffensiveReportsResource } from '../../../../ConversationStore/Types';
import { findLink } from '../../../../LinkyLinky';
import ChatReportIcon from '../../../Cutesies/ChatReportIcon';
import * as style from '../style.module.css';
import ActionMenuButton from './ActionMenuButton';
import ActionMenuStore from './ActionMenuStore';

interface Props {
  store: ActionMenuStore;
  offensiveMessageReports: ChatMessageOffensiveReportsResource;
}

const ReportThisMessage: React.FC<Props> = ({ store, offensiveMessageReports }) =>
  just(offensiveMessageReports.links)
    .andThen(findLink('create'))
    .map(() => (
      <ActionMenuButton tooltipText="Report this message">
        <button
          className={style.actionMenuButton}
          onClick={store.requestMessageReport}
          data-test-action-menu-report-message
        >
          <ChatReportIcon />
        </button>
      </ActionMenuButton>
    ))
    .getOrElse(() => <></>);

export default observer(ReportThisMessage);
