import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const ExpertFeedbackAnnouncementIcon: React.FC = () => (
  <SVGIcon viewBox="0 0 25 25">
    <path
      fill={defaultTheme.variables['icon-accent-on-light']}
      d="M0 0v25h20.833V0H0Zm2.083 2.083H18.75v20.834H2.083V2.083Zm2.084 3.125v2.084h12.5V5.208h-12.5Zm0 4.167v2.083h12.5V9.375h-12.5ZM7.813 12.5c-.904 0-1.705.614-2.084 1.367-.378.753-.52 1.644-.52 2.67 0 .573.203 1.24.325 1.887-.468.147-.883.456-1.335.456v2.084c.7 0 1.412-.11 2.084-.326.105.159.154.346.293.488.406.42 1.05.749 1.725.749 1.652 0 2.755-1.095 3.45-1.953.143-.175.208-.269.326-.423.028.126.008.211.065.358.081.207.203.443.456.65.252.208.63.326.944.326.675 0 1.021-.366 1.27-.618.247-.252.45-.5.65-.716.395-.432.68-.684 1.14-.684v-2.083c-1.27 0-2.145.76-2.67 1.334-.02-.126-.016-.207-.065-.358a1.617 1.617 0 0 0-.423-.683c-.24-.228-.618-.358-.944-.358-.814 0-1.188.488-1.497.846-.31.358-.59.753-.88 1.107-.573.704-1.074 1.172-1.822 1.172-.053 0-.11-.053-.163-.098.061-.04.138-.053.195-.098 1.18-.887 2.084-2.23 2.084-3.84 0-.798-.159-1.551-.586-2.182A2.498 2.498 0 0 0 7.813 12.5Zm-.098 2.083c.024-.02.032 0 .098 0 .22 0 .199.025.292.163.094.138.228.488.228 1.01 0 .662-.358 1.236-.879 1.757-.044-.346-.162-.68-.162-.977 0-.826.15-1.44.293-1.725.073-.142.105-.207.13-.228Z"
    />
  </SVGIcon>
);

export default observer(ExpertFeedbackAnnouncementIcon);
