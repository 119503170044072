import { Popover, PopoverBody, PopoverFooter, PopoverHeader } from '@execonline-inc/execonline-ui';
import { flip } from '@floating-ui/react';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useExperienceContext } from '../Experience/ExperienceContext';
import ExperienceActionHint from './ExperienceActionHint';
import ExperienceAlert from './ExperienceAlert';
import ExperienceAvailableLanguages from './ExperienceAvailableLanguages';
import ExperienceCompetencies from './ExperienceCompetencies';
import ExperienceDescription from './ExperienceDescription';
import ExperienceActions from './ExperienceDetailsActions';
import { ExperiencePopoverContext } from './ExperiencePopoverContext';
import ExperienceTimeCommitment from './ExperienceTimeCommitment';
import ExperienceTitle from './ExperienceTitle';
import ExperienceUpcomingDates from './ExperienceUpcomingDates';
import { calculatePlacementOffset, PopoverProgramExperienceProps } from './Types';
import { useProgramPopoverTrigger } from './hooks';

function ProgramExperiencePopover({
  experiencesStore,
  placement = 'right',
  children,
  onEnroll,
}: PopoverProgramExperienceProps) {
  const { invitationUuid, experienceResource } = useExperienceContext();
  const trigger = useProgramPopoverTrigger(experienceResource);
  const middleware = useMemo(() => [calculatePlacementOffset(placement), flip()], [placement]);

  return (
    <Popover
      className="max-w-[20.625rem] text-black"
      trigger={trigger}
      delay={{ open: 1000, close: 0 }}
      returnFocus={false}
      placement={placement}
      renderTrigger={children}
      middleware={middleware}
      onClose={({ refs }) => {
        fromNullable(refs.domReference.current).do((element) => {
          element.focus({ preventScroll: true });
        });
      }}
    >
      {({ onClose }) => (
        <ExperiencePopoverContext.Provider
          value={{
            invitationUuid,
            experienceResource,
            experiencesStore,
            onEnroll: () => {
              onEnroll();
              onClose();
            },
          }}
        >
          <PopoverHeader className="flex flex-col gap-y-3">
            <ExperienceAlert />
            <ExperienceTitle />
          </PopoverHeader>
          <PopoverBody className="-ml-2 max-h-[26.25rem] gap-y-4 pb-1 pl-2">
            <ExperienceTimeCommitment />
            <ExperienceAvailableLanguages />
            <ExperienceDescription />
            <ExperienceCompetencies />
            <ExperienceUpcomingDates />
          </PopoverBody>
          <PopoverFooter className="gap-y-2 empty:hidden">
            <ExperienceActions />
            <ExperienceActionHint />
          </PopoverFooter>
        </ExperiencePopoverContext.Provider>
      )}
    </Popover>
  );
}

export default observer(ProgramExperiencePopover);
