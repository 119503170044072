import { observer } from 'mobx-react';
import * as React from 'react';
import {
  SharedResourceAnnouncement,
  announcementSharedResourceCata,
} from '../../Announcements/Decoders/Types';
import AnnouncementSharedVideo from './AnnouncementSharedVideo';
import EmbeddedSharedDocument from './EmbeddedSharedDocument';

const SharedResourcePreview: React.FC<{
  sharedResourceAnnouncement: SharedResourceAnnouncement;
}> = ({ sharedResourceAnnouncement }) => {
  const announcementSharedResource = sharedResourceAnnouncement.announcementSharedResource;
  return announcementSharedResourceCata(
    {
      document: (r) => (
        <EmbeddedSharedDocument
          sharedResourceAnnouncement={sharedResourceAnnouncement}
          announcementSharedResource={r}
        />
      ),
      video: (r) => <AnnouncementSharedVideo announcementSharedResource={r} />,
    },
    announcementSharedResource
  );
};

export default observer(SharedResourcePreview);
