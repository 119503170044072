import { CheckBox, CheckBoxProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { noop } from '@kofno/piper';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { Typography } from '../../components/UI';
import { findLinkBy } from '../../Links';
import { rootResourceStore } from '../../RootResourceStore';
import { fromNullableT } from '../../TaskExt';
import { T } from '../../Translations';
import ExternalLink from '../AEP/ExternalLink';

interface Props extends Omit<CheckBoxProps, 'onChange'> {
  onChange: (value: boolean) => void;
}

function TermsAndConditionsField({ onChange, ...others }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    fromNullableT(onChange).fork(noop, (onChange) => onChange(e.target.checked));
  };

  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <div className="flex items-center gap-x-2.5">
        <CheckBox
          data-testid="terms-and-conditions_accept-checkbox"
          radius="sm"
          onChange={handleChange}
          {...others}
        />
        <Typography className="uppercase tracking-wider" fontWeight="medium">
          <T
            kind="I accept the <link1>terms of use</link1> and <link2>privacy policy</link2>"
            link1={(content) => (
              <ExternalLink
                data-testid="terms-and-conditions_terms-of-use-link"
                href={termsLink.href}
              >
                <Typography className="uppercase tracking-wider underline" component="span">
                  {content}
                </Typography>
              </ExternalLink>
            )}
            link2={(content) => (
              <ExternalLink
                data-testid="terms-and-conditions_privacy-policy-link"
                href={privacyLink.href}
              >
                <Typography className="uppercase tracking-wider underline" component="span">
                  {content}
                </Typography>
              </ExternalLink>
            )}
          />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(TermsAndConditionsField);
