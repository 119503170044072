import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import TeamFiles from './TeamFiles';
import TeamEvents from './TeamEvents';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const TeamEventsOverview: React.FC<Props> = ({ communityTeamResource }) => (
  <dl className={style.dl} data-test-within-team={true}>
    <dt>
      <h3>
        <T kind="Within This Team" />
      </h3>
    </dt>
    <dd>
      <TeamFiles communityTeamResource={communityTeamResource} />
      <TeamEvents communityTeamResource={communityTeamResource} />
    </dd>
  </dl>
);

export default observer(TeamEventsOverview);
