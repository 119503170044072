import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';
import { EnrollmentPayload } from '../../../../DiscoveryPortal/Enrollment/Store/Types';

interface Props {
  enrollment: EnrollmentPayload;
}

function RedirectText({ enrollment }: Props) {
  switch (enrollment.program.payload.kind) {
    case 'active':
    case 'completed':
    case 'expired':
    case 'inactive':
      return <T kind="Continue" />;
    case 'upcoming':
      return <T kind="Go to dashboard" />;
  }
}

export default observer(RedirectText);
