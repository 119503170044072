import { assertNever } from '@kofno/piper';
import { fromNullable } from 'maybeasy';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import ScrollToBottomStore from './ScrollToBottomStore';
import { State } from './ScrollToBottomStore/Types';

interface Props extends RCProps<ScrollToBottomStore> {
  messageListRef: React.RefObject<HTMLDivElement>;
}

const NEAR_BOTTOM_THRESHOLD = 250;

class ScrollToBottomReactions extends ReactionComponent<ScrollToBottomStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'loading':
        fromNullable(this.props.messageListRef.current)
          .map((el) => {
            el.scrollTop = el.scrollHeight;
            if (el.scrollTop > 0) {
              this.props.store.ready();
            } else {
              this.props.store.initializing();
            }
          })
          .getOrElse(() => this.props.store.initializing());
        break;
      case 'new-message':
        fromNullable(this.props.messageListRef.current).map((el) => {
          if (el.scrollTop > el.scrollHeight - el.clientHeight - NEAR_BOTTOM_THRESHOLD) {
            el.scrollTop = el.scrollHeight;
          }
        });
        this.props.store.ready();
        break;
      case 'initializing':
      case 'ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ScrollToBottomReactions;
