import { Kettle } from 'kettle-corn';
import { nothing } from 'maybeasy';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { NotTranslated } from '../../../Translations';
import Video from '../../Video';
import { VideoResource as TVideoResource } from '../Types';

interface Props {
  videoResource: TVideoResource;
}

const VideoResource: React.FC<Props> = ({ videoResource }) => {
  return (
    <>
      <h1>
        <NotTranslated text={videoResource.title} />
      </h1>
      {videoResource.description
        .map((description) => <div>{ReactHtmlParser(description)}</div>)
        .getOrElseValue(<></>)}
      <div>
        <Video
          id={videoResource.videoAssetResource.payload.uuid}
          className="embedded-video"
          videoResource={videoResource.videoAssetResource}
          kettle={new Kettle()}
          width={640}
          height={320}
          dynamicallySetAspectRatio={false}
        >
          {{ playButton: nothing() }}
        </Video>
      </div>
    </>
  );
};

export default VideoResource;
