import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentsCompletedNone from './SegmentsCompletedNone';
import SegmentsCompletedSome from './SegmentsCompletedSome';

interface Props {
  completedCount: number;
  remainingCount: number;
}

const SegmentsCompleted: React.FC<Props> = ({ completedCount, remainingCount }) =>
  completedCount > 0 ? (
    <SegmentsCompletedSome completedCount={completedCount} remainingCount={remainingCount} />
  ) : (
    <SegmentsCompletedNone remainingCount={remainingCount} />
  );

export default observer(SegmentsCompleted);
