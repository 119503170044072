import { Link } from '../../../Resource/Types';

interface Closed {
  kind: 'closed';
}

interface Open {
  kind: 'open';
}

interface Postponing {
  kind: 'postponing';
  link: Link;
}

export const postponing = (link: Link): Postponing => ({
  kind: 'postponing',
  link,
});

export const closed = (): Closed => ({ kind: 'closed' });

export const open = (): Open => ({ kind: 'open' });

export type State = Closed | Open | Postponing;
