import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import FlashError from '../components/FlashMessage/FlashError';
import Loader from '../components/Loader';
import { T } from '../Translations';
import { sessionStore } from './Store';

function WhenApplicationSession({ children }: PropsWithChildren<{}>) {
  switch (sessionStore.state.kind) {
    case 'requesting':
      return <Loader delay={0} />;
    case 'present':
    case 'absent':
      return <>{children}</>;
    case 'errored':
      return (
        <div className="flex h-screen items-center justify-center">
          <FlashError>
            <T kind="Unable to establish a session for this application." />
          </FlashError>
        </div>
      );
  }
}

export default observer(WhenApplicationSession);
