import { Error } from '../../../ErrorHandling';
import { ActiveProgramModule } from '../../../ProgramStore/Types';
import { Resource } from '../../../Resource/Types';

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  autoLaunchableResource: AutoLaunchableResource;
}

interface NothingToLaunch {
  kind: 'nothing-to-launch';
}

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (autoLaunchableResource: AutoLaunchableResource): Ready => ({
  kind: 'ready',
  autoLaunchableResource,
});

export const nothingToLaunch = (): NothingToLaunch => ({ kind: 'nothing-to-launch' });

export type State = Loading | Ready | NothingToLaunch | Error;

export type AutoLaunchableResource = Resource<ActiveProgramModule>;
