import { parseIntM } from '@execonline-inc/numbers';
import { Kettle } from 'kettle-corn';
import { IReactionDisposer, when } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { searchParam } from '../../../../QueryString';

interface Props {
  kettle: Kettle;
}

@observer
class LectureVideoInitialPosition extends React.Component<Props, {}> {
  private running?: IReactionDisposer;

  componentDidMount() {
    this.running = this.run();
  }

  componentWillUnmount() {
    if (this.running) {
      this.running();
    }
  }

  readyToAdvance = () => {
    const { kettle } = this.props;
    return kettle.videoState.duration
      .andThen(dur => kettle.videoState.position.map(pos => dur > 0 && pos === 0))
      .getOrElseValue(false);
  };

  handleInitialAdvancement = () => {
    searchParam('position')
      .andThen(parseIntM)
      .map(pos => this.props.kettle.seekTo(pos));
  };

  private run(): IReactionDisposer {
    return when(this.readyToAdvance, this.handleInitialAdvancement);
  }

  render() {
    return <></>;
  }
}

export default LectureVideoInitialPosition;
