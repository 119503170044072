import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import ToastsStore from '../../ToastsStore';
import { State } from '../../ToastsStore/Types';

interface Props extends RCProps<ToastsStore> {
  uuid: string;
}

class EventToastDismisserReaction extends ReactionComponent<ToastsStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (this.props.store.state.kind) {
      case 'error':
      case 'loading':
      case 'waiting':
      case 'reloading':
        break;
      case 'ready':
        this.props.store.dismiss(this.props.uuid);
        break;
      default:
        assertNever(this.props.store.state);
    }
  };
}

export default observer(EventToastDismisserReaction);
