import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TeamsStore from '../../../TeamsStore';
import UnreadMessagesNotifications from './UnreadMessagesNotifications';

interface Props {
  teamsStore: TeamsStore;
}

class UnreadMessageNotificationsRouter extends React.Component<Props> {
  render() {
    const { teamsStore } = this.props;
    return (
      <Switch>
        <Route
          path="/index.html"
          exact={true}
          render={() => <UnreadMessagesNotifications teamsStore={teamsStore} />}
        />
        <Route
          path="/"
          exact={true}
          render={() => <UnreadMessagesNotifications teamsStore={teamsStore} />}
        />
      </Switch>
    );
  }
}

export default UnreadMessageNotificationsRouter;
