import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fill: string;
}

const PollIcon: React.FC<Props> = ({ fill }) => {
  return (
    <SVGIcon viewBox="0 0 32 32">
      <path
        fill={fill}
        d="M5 5v22h22V5H5zm2 2h18v18H7V7zm8 3v12h2V10h-2zm-5 4v8h2v-8h-2zm10 2v6h2v-6h-2z"
      />
    </SVGIcon>
  );
};
export default observer(PollIcon);
