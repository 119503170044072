import { observer } from 'mobx-react';
import * as React from 'react';
import { ProfessorResource } from '../../../ProfessorsStore/Types';
import ProfessorImpl from './ProfessorImpl';

interface Props {
  professorResource: ProfessorResource;
}

const Professor: React.FC<Props> = ({ professorResource }) => (
  <ProfessorImpl professorResource={professorResource} />
);

export default observer(Professor);
