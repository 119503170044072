import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import LogoGraphic from '../../Cutesies/LogoGraphic';

function TechCheckOverview() {
  return (
    <>
      <div className="mx-auto my-12 w-[18.75rem] lg:w-[25rem]">
        <LogoGraphic />
      </div>
      <Typography className="mb-2.5" variant="h4" as="h1">
        <T kind="Let's do a Quick Tech Check" />
      </Typography>
      <Typography className="mb-5">
        <T kind="Let's make sure you can schedule a test session and that ..." />
      </Typography>
      <ol className="mb-7 list-decimal pl-7 [&>li:not(:last-child)]:pb-2.5">
        <li>
          <Typography>
            <T kind="Test whether your system can schedule a test session" />
          </Typography>
        </li>
        <li>
          <Typography>
            <T kind="Confirm your date and time settings" />
          </Typography>
        </li>
        <li>
          <Typography>
            <T kind="Check your browser's ability to run availability and scheduling features" />
          </Typography>
        </li>
      </ol>
    </>
  );
}

export default observer(TechCheckOverview);
