import { observer } from 'mobx-react';
import { useDiscoveryContext } from '../../../DiscoveryContext';
import { experienceDetailsRoute } from '../../../../../ClientRoutes';
import NativeLink from '../../../../NativeLink';
import { useExperienceContext } from '../../Experience/ExperienceContext';

interface Props {
  children: (onClick: () => void) => React.ReactNode;
}

function ProgramActions({ children }: Props) {
  const discoveryState = useDiscoveryContext();
  const { invitationUuid, experienceResource } = useExperienceContext();
  const { experienceId } = experienceResource.payload;

  return (
    <NativeLink to={experienceDetailsRoute(invitationUuid, experienceId, discoveryState)}>
      {children}
    </NativeLink>
  );
}

export default observer(ProgramActions);
