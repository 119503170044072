import { mergeObjectDecoders, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, date, field, maybe, number, oneOf, string, succeed } from 'jsonous';
import {
  ElementNoteParts,
  Note,
  NoteBase,
  NoteParts,
  NoteResource,
  PositionNoteParts,
  ProgramNoteParts,
  SegmentNoteParts,
  SlideNoteParts,
} from '../../NoteStore/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import { NotesResource } from '../Types';

const elementNotePartsDecoder: Decoder<ElementNoteParts> = succeed({})
  .assign('type', field('type', stringLiteral('element')))
  .assign('elementId', field('element_id', number));

const slideNotePartsDecoder: Decoder<SlideNoteParts> = succeed({})
  .assign('type', field('type', stringLiteral('slide')))
  .assign('segmentId', field('segment_id', number))
  .assign('lectureSlideNumber', field('lecture_slide_number', number));

const positionNotePartsDecoder: Decoder<PositionNoteParts> = succeed({})
  .assign('type', field('type', stringLiteral('position')))
  .assign('segmentId', field('segment_id', number))
  .assign('moduleId', field('module_id', number))
  .assign('position', field('position', number));

const segmentNotePartsDecoder: Decoder<SegmentNoteParts> = succeed({})
  .assign('type', field('type', stringLiteral('segment')))
  .assign('segmentId', field('segment_id', number))
  .assign('moduleId', field('module_id', number));

const programNotePartsDecoder: Decoder<ProgramNoteParts> = succeed({}).assign(
  'type',
  field('type', stringLiteral('program'))
);

export const partsDecoder: Decoder<NoteParts> = oneOf<NoteParts>([
  elementNotePartsDecoder.map<NoteParts>(identity),
  slideNotePartsDecoder.map<NoteParts>(identity),
  positionNotePartsDecoder.map<NoteParts>(identity),
  segmentNotePartsDecoder.map<NoteParts>(identity),
  programNotePartsDecoder.map<NoteParts>(identity),
]);

const baseNotePayloadDecoder: Decoder<NoteBase> = succeed({})
  .assign('id', field('id', number))
  .assign('programId', field('program_id', number))
  .assign('content', field('content', maybe(string)))
  .assign('context', field('context', maybe(string)))
  .assign('createdAt', field('created_at', date));

const noteDecoder: Decoder<Note> = mergeObjectDecoders(baseNotePayloadDecoder, partsDecoder);

export const noteResourceDecoder: Decoder<NoteResource> = resourceDecoder(noteDecoder);

export const notesResourceDecoder: Decoder<NotesResource> = resourceDecoder(
  array(noteResourceDecoder)
);
