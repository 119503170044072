import { observer } from 'mobx-react';
import * as React from 'react';
import { triggerAnalyticsEvent } from './Loader';

interface Props {
  hitType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

class AnalyticsEvent extends React.Component<Props, {}> {
  componentDidMount() {
    const { hitType, eventCategory, eventAction, eventLabel } = this.props;
    triggerAnalyticsEvent(hitType, eventCategory, eventAction, eventLabel);
  }

  render() {
    return <></>;
  }
}

export default observer(AnalyticsEvent);
