import { observer } from 'mobx-react';
import * as React from 'react';
import CalendarEventStore from '../../EventsStore/CalendarEventStore';
import NotStarted from '../NotStarted';
import Conference from './Conference';

interface Props {
  store: CalendarEventStore;
  eventId: number;
}

const Lobby: React.FC<Props> = ({ store, eventId }) => {
  switch (store.event.kind) {
    case 'upcoming-event':
      return <NotStarted eventResource={store.resource} />;
    case 'current-coaching-event':
    case 'past-event':
    case 'other-event':
    case 'live-event':
      return <Conference eventId={eventId} eventResource={store.resource} />;
  }
};

export default observer(Lobby);
