import * as React from 'react';
import FreebusyStore from '../../../FreebusyStore';
import { observer } from 'mobx-react';
import ConfirmationModal from './ConfirmationModal';
import FreebusyCoachingAdStore from './FreebusyCoachingAdStore';
import AdDisplay from './AdDisplay';
import AdDisplayContent from './AdDisplayContent';

interface Props {
  freebusyStore: FreebusyStore;
  freebusyCoachingAdStore: FreebusyCoachingAdStore;
}

const AdDisplayStates: React.FC<Props> = ({ freebusyStore, freebusyCoachingAdStore }) => {
  switch (freebusyStore.state.kind) {
    case 'waiting':
    case 'error':
      return <AdDisplayContent freebusyStore={freebusyStore} />;
    case 'initializing-store':
    case 'submitting':
    case 'loading':
    case 'initializing':
    case 'ready':
      return <AdDisplay freebusyStore={freebusyStore} />;
    case 'submitted':
      return (
        <>
          <AdDisplayContent freebusyStore={freebusyStore} />
          <ConfirmationModal
            eventDetails={freebusyStore.state.eventDetails}
            freebusyCoachingAdStore={freebusyCoachingAdStore}
          />
        </>
      );
  }
};

export default observer(AdDisplayStates);
