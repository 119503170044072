import { find } from '@execonline-inc/collections';
import { log } from '@execonline-inc/logging';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { LocalConferenceRoomResource } from '../Conference/Types';
import HandRaiser from '../HandRaiser';
import { HandRaiseStatus } from '../HandRaiser/types';
import { Link } from '../Resource/Types';
import { HandRaisersState, pending, ready, Ready } from './types';

export class HandRaisers {
  @observable
  state: HandRaisersState;

  constructor() {
    this.state = pending();
  }

  @computed
  get ready(): Maybe<Ready> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state);
      case 'pending':
        return nothing();
    }
  }

  @computed
  get hands(): HandRaiser[] {
    return this.ready.map(s => s.hands).getOrElseValue([]);
  }

  @computed
  get conferenceRoomLinks(): ReadonlyArray<Link> {
    return this.ready.map(({ conferenceRoom }) => conferenceRoom.links).getOrElse(() => []);
  }

  @computed
  get conferenceRoomId(): Maybe<number> {
    return this.ready.map(({ conferenceRoom }) => conferenceRoom.payload.id);
  }

  findHandUpStatus = (id: number): Maybe<HandRaiseStatus> => {
    return this.findHandRaiser(id).map(hr => hr.state.kind);
  };

  findHandRaiser = (id: number): Maybe<HandRaiser> => {
    return find(hr => id === hr.id, this.hands);
  };

  addHandRaiser = (id: number): Maybe<HandRaiser> => {
    log('addHandRaiser', id);
    return this.ready.map(({ hands, conferenceRoom }) => {
      const hr = new HandRaiser(id, conferenceRoom, 'hand-down');
      hands.push(hr);
      return hr;
    });
  };

  whenHandRaised = (id: number): Maybe<HandRaiser> => {
    return find(hr => id === hr.id && hr.isHandRaised, this.hands);
  };

  @action
  enable = (conferenceRoom: LocalConferenceRoomResource) => {
    this.state = ready(conferenceRoom);
  };

  @action
  disable = () => {
    this.state = pending();
  };
}

const handRaisers = new HandRaisers();

export default handRaisers;
