import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { T, whenTPlainTextKey } from '../../../Translations';

interface Props {
  requiredFieldError: string;
}

const RequiredFieldErrorMessage: React.FC<Props> = ({ requiredFieldError }) => (
  <div>
    <li data-test-error-message={requiredFieldError}>
      {whenTPlainTextKey(requiredFieldError)
        .map((key) => <T kind={key} />)
        .getOrElse(() => (
          <NotTranslated text={requiredFieldError} />
        ))}
    </li>
  </div>
);

export default observer(RequiredFieldErrorMessage);
