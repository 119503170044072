import { observer } from 'mobx-react';
import * as React from 'react';
import { Event } from '../../EventsStore/Types';
import { findLink } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import { NotTranslated, T } from '../../Translations';
import * as conferenceStyle from '../Conference/style.module.css';
import ActionLink from '../Event/ActionLabel/ActionLink';
import * as eventStyle from '../Event/style.module.css';
import * as style from './style.module.css';

interface Props {
  conferenceRoomLinks: ReadonlyArray<Link>;
  event: Event;
}

const link = (links: ReadonlyArray<Link>) =>
  findLink('external-conference-url', links)
    .map((link) => (
      <ActionLink
        to={link.href}
        external={true}
        label={<T kind="Join" />}
        className={eventStyle.buttonCallToAction}
      />
    ))
    .getOrElseValue(<></>);

const ExternalConference: React.FC<Props> = ({ conferenceRoomLinks, event }) => {
  return (
    <div className={conferenceStyle.conferenceMain}>
      <div className={style.details}>
        <p>
          <NotTranslated text={event.description} />
        </p>
        <div>{link(conferenceRoomLinks)}</div>
      </div>
    </div>
  );
};

export default observer(ExternalConference);
