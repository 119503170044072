import { observer } from 'mobx-react';
import * as React from 'react';
import RegistrationFooter from '../RegistrationFooter';

interface Props {
  allowLanguagePicker: boolean;
}

const RegistrationViewImpl: React.FC<Props> = ({ children, allowLanguagePicker }) => (
  <div className="flex flex-1 flex-col">
    <div className="flex-1 p-12">
      <div className="flex-1" children={children} />
    </div>
    <RegistrationFooter allowLanguagePicker={allowLanguagePicker} />
  </div>
);

export default observer(RegistrationViewImpl);
