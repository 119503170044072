import { observer } from 'mobx-react';
import * as React from 'react';
import HandRaiser from '../../HandRaiser';
import handRaisers from '../../HandRaisers';
import * as style from '../Conference/style.module.css';
import HandButton from '../HandButton';

interface Props {
  id: number;
}

const button = (hr: HandRaiser): JSX.Element => {
  switch (hr.state.kind) {
    case 'hand-up':
      return <HandButton disabled={false} onClick={hr.loweringHand} handState="raised" />;
    case 'raising-hand':
      return <HandButton disabled={true} onClick={hr.loweringHand} handState="raised" />;
    case 'hand-down':
      return <HandButton disabled={false} onClick={hr.raisingHand} handState="lowered" />;
    case 'lowering-hand':
    case 'other-lowering-hand':
      return <HandButton disabled={true} onClick={hr.raisingHand} handState="lowered" />;
  }
};

const HandAction: React.FC<Props> = ({ id }) =>
  handRaisers
    .findHandRaiser(id)
    .map(button)
    .getOrElse(() => <div className={style.empty} />);

export default observer(HandAction);
