import { CircleExclamationIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import { NotEnrollableAlertResource } from '../../Experience/Types';

interface Props {
  experience: Maybe<NotEnrollableAlertResource>;
}

function NotEnrollableAction({ experience }: Props) {
  return experience
    .andThen(({ payload }) => payload.title)
    .map((title) => (
      <div className="flex items-center gap-x-2">
        <CircleExclamationIcon size="md" color="amber" />
        <Typography color="amber" variant="caption">
          <T
            kind="You can't enroll until you graduate from <strong>{{name}}</strong>"
            name={title}
            strong={(value) => (
              <Typography color="amber" variant="caption" fontWeight="medium">
                {value}
              </Typography>
            )}
          />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(NotEnrollableAction);
