import { map } from '@execonline-inc/collections';
import * as React from 'react';
import { AssignmentResource } from '../../../TeamResourceStore/Types';
import { NotTranslated } from '../../../Translations';
import * as style from '../style.module.css';
import assignmentRenderer from './AssignmentRenderer';

interface Props {
  assignments: AssignmentResource[];
  label: string;
}

const renderAssignments = map((assignment: AssignmentResource) =>
  assignmentRenderer(assignment.payload)
);

const FileGroup: React.FC<Props> = ({ assignments, label }) => (
  <div key={label}>
    <h3 className={style.groupTitle}>
      <NotTranslated text={label} />
    </h3>
    <div className={style.fileList}>{renderAssignments(assignments)}</div>
  </div>
);

export default FileGroup;
