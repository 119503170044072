import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import ProgramStore from '../../ProgramStore';
import { ProgramDetailResource, ProgramSegment } from '../../ProgramStore/Types';
import { Position } from '../PositionContext';
import ProgramOverviewDashboard from './ProgramOverviewDashboard';

interface Props {
  programDetailResource: ProgramDetailResource;
  currentSegment: Maybe<ProgramSegment>;
  position: Position;
  programStore: ProgramStore;
}

const ProgramOverviewRenderer: React.FC<Props> = ({
  programDetailResource,
  position,
  currentSegment,
  programStore,
}) => {
  return (
    <WithCurrentUser
      children={(currentUserResource) => {
        switch (position.kind) {
          case 'dashboard':
          case 'tooltip':
          case 'overview':
          case 'nav':
          case 'supPanel':
          case 'chat':
          case 'participant':
          case 'footer':
          case 'none':
            return (
              <ProgramOverviewDashboard
                programDetailResource={programDetailResource}
                currentSegment={currentSegment}
                programStore={programStore}
                currentUserResource={currentUserResource}
              />
            );
        }
      }}
    />
  );
};

export default observer(ProgramOverviewRenderer);
