import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { IconButtonProps, buttonSizeClassNameMapping } from './Types';

const IconButton: React.FC<IconButtonProps> = ({ children, className, size = 'md', ...others }) => {
  return (
    <button
      className={twMerge(
        clsx(
          'appearance-none select-none flex flex-shrink-0 justify-center p-0 items-center bg-transparent outline-0',
          buttonSizeClassNameMapping[size],
          className
        )
      )}
      {...others}
    >
      {children}
    </button>
  );
};

export default observer(IconButton);
