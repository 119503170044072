import { observer } from 'mobx-react';
import Main from '../../../BrowserSupport/Main';
import I18nTitle from '../../../Title/I18nTitle';
import NumberedInstructions from './NumberedInstructions';
import SelectTime from './SelectTime';
import TechCheckOverview from './TechCheckOverview';

function Schedule() {
  return (
    <Main className="container mx-auto min-h-screen">
      <I18nTitle title="FreeBusy Requirements Check" />
      <div className="px-2 text-gray-600">
        <TechCheckOverview />
        <NumberedInstructions />
        <SelectTime />
      </div>
    </Main>
  );
}

export default observer(Schedule);
