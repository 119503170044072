import * as React from 'react';
import { observer } from 'mobx-react';
import CoachIcon from './CoachIcon';
import * as style from './style.module.css';
import StaffRequestStore from './StaffRequestStore';
import StaffPresenceStore from './StaffPresenceStore';

interface Props {
  staffRequestStore: StaffRequestStore;
  staffPresenceStore: StaffPresenceStore;
}

const StaffRequest: React.FC<Props> = ({ staffRequestStore, staffPresenceStore }) => {
  return staffPresenceStore.displayedPresentStaffList
    .map((displayedPresentStaffList) => (
      <div className={style.staffPresent}>
        <span className={style.staffNames}>{displayedPresentStaffList}</span>
        <div className={style.staffIconWrapper}>
          <CoachIcon />
        </div>
      </div>
    ))
    .getOrElse(() => {
      switch (staffRequestStore.state.kind) {
        case 'ready':
          return <></>;
        case 'requested':
          return (
            <div className={style.staffRequested}>
              <div className={style.staffIconWrapper}>
                <CoachIcon />
              </div>
            </div>
          );
      }
    });
};
export default observer(StaffRequest);
