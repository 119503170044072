import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { EnrollmentStatusType } from '../../../../../../../DiscoveryStore/Types';
import { L, T } from '../../../../../../../Translations';
import EnrollmentStatusItem from './EnrollmentStatusItem';

interface Props {
  status: EnrollmentStatusType;
}

function EnrollmentStatus({ status }: Props) {
  switch (status.kind) {
    case 'currently-enrolled':
      return (
        <EnrollmentStatusItem>
          <T kind="Currently Enrolled" />
        </EnrollmentStatusItem>
      );
    case 'future-enrolled':
      return (
        <EnrollmentStatusItem>
          <T
            kind="Next Enrollment: <date/>"
            date={<L localizeable={status.date} format="short-month-day-year" />}
          />
        </EnrollmentStatusItem>
      );
    case 'past-enrolled':
      return emptyFragment();
  }
}

export default observer(EnrollmentStatus);
