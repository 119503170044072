import { observer } from 'mobx-react';
import ModalContent from './ModalContent';
import ModalPortal from './ModalPortal';

type ModalProps = React.ComponentProps<typeof ModalContent>;

function Modal({ close, title, size, children }: ModalProps) {
  return (
    <ModalPortal>
      <ModalContent size={size} close={close} title={title}>
        {children}
      </ModalContent>
    </ModalPortal>
  );
}

export default observer(Modal);
