import * as React from 'react';
import { observer } from 'mobx-react';
import { SegmentResource } from '../../../../SegmentStore/Types';
import { findLink } from '../../../../LinkyLinky';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import PdfStore from '../../../../PdfStore';
import PdfReactions from '../../../../PdfStore/PdfReactions';
import * as style from './style.module.css';
import * as segmentStyle from '../../style.module.css';
import DownloadIcon from '../../../Cutesies/DownloadIcon';
import ButtonText from './ButtonText';
import { TranslationsContext, translation } from '../../../../Translations';

interface Props {
  segmentResource: SegmentResource;
}

class DownloadableButton extends React.Component<Props> {
  pdfStore = new PdfStore();

  render() {
    return findLink('download', this.props.segmentResource.links)
      .map((link) => (
        <div className={style.root}>
          <button className={style.downloadButton} onClick={this.pdfStore.downloading}>
            <ButtonText pdfStore={this.pdfStore} />
            <div className={segmentStyle.buttonIcon}>
              <DownloadIcon />
            </div>
          </button>
          <TranslationsContext.Consumer>
            {(ts) => (
              <PdfReactions
                store={this.pdfStore}
                fileName={translation('{{programTitle}} - My Action Plan.pdf', {
                  programTitle: this.props.segmentResource.payload.programTitle,
                })(ts)}
                link={link}
                fireImmediately={true}
              />
            )}
          </TranslationsContext.Consumer>
        </div>
      ))
      .getOrElse(emptyFragment);
  }
}

export default observer(DownloadableButton);
