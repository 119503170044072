import { observer } from 'mobx-react';
import { Button, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

interface Props {
  onBrowseExperience: () => void;
  onGoToDashboard: () => void;
}

function EnrolledModalFooter({ onBrowseExperience, onGoToDashboard }: Props) {
  return (
    <div className="flex flex-col gap-y-4 sm:gap-y-6">
      <div className="flex flex-col-reverse gap-x-4 gap-y-3 sm:flex-row">
        <Button variant="outlined" color="gray" onClick={onBrowseExperience}>
          <T kind="Browse Experiences" />
        </Button>
        <Button color="gray" onClick={onGoToDashboard}>
          <T kind="Go to dashboard" />
        </Button>
      </div>
      <Typography className="text-[#505050]" variant="caption">
        <T kind="Have questions or need support? Contact us at support@execonline.com." />
      </Typography>
    </div>
  );
}

export default observer(EnrolledModalFooter);
