import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramChatStore from '../../ProgramChatStore';
import ContentFaker from '../ContentFaker';
import ProgramProfileImpl from './ProgramProfileImpl';

interface Props {
  programId: Maybe<number>;
  programChatStore: ProgramChatStore;
}

const ProgramView: React.FC<Props> = ({ programChatStore, programId }) => {
  switch (programChatStore.state.kind) {
    case 'ready':
      return <ProgramProfileImpl programId={programId} programChatStore={programChatStore} />;
    case 'loading':
    case 'waiting':
      return <ContentFaker />;
    case 'error':
      return <></>;
  }
};
export default observer(ProgramView);
