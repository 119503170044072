import { observer } from 'mobx-react';
import * as React from 'react';
import NotesStore from '../../../NotesStore';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import NotesTabIcon from '../../Cutesies/NotesTabIcon';
import Badge from '../Badge';
import * as style from '../style.module.css';

interface Props {
  tabStore: TabStore;
  notesStore: NotesStore;
}

const NotesTab: React.FC<Props> = ({ tabStore, notesStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('notes');
  };

  return (
    <button
      className={`${style.tab} ${tabStore.activeTab === 'notes' ? style.active : ''}`}
      onClick={handleClick}
      data-test-notes-tab={true}
    >
      <span className={style.tabIcon}>
        <NotesTabIcon />
      </span>
      <span className={style.tabLabel} data-test-tab-title="Notes">
        <T kind="Notes" />
      </span>
      <Badge count={notesStore.noteStores.length} />
    </button>
  );
};
export default observer(NotesTab);
