import { observer } from 'mobx-react';
import * as React from 'react';
import { SocketAvailability } from '../Types';
import UnavailableMessage from './UnavailableMessage';

interface Props {
  socket: SocketAvailability;
}

const SocketAvailabilityMessage: React.FC<Props> = ({ socket }) => {
  switch (socket.kind) {
    case 'connected':
    case 'disconnected':
    case 'failed':
    case 'initialized':
      return <span />;
    case 'connecting':
    case 'unavailable':
      return <UnavailableMessage socket={socket} />;
  }
};

export default observer(SocketAvailabilityMessage);
