import { explicitMaybe, nullableBlankString, stringLiteral } from '@execonline-inc/decoders';
import Decoder, { array, field, maybe, oneOf, string, succeed } from 'jsonous';
import {
  countryDecoder,
  regionDecoder,
  timeZoneDecoder,
  workPhoneDecoder,
} from '../../ProfileStore/Decoders';
import { errorDecoder, linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource, ResourceWithErrors } from '../../Resource/Types';
import {
  supportedLanguageCodeDecoder,
  supportedLanguageEnglishNameDecoder,
  supportedLanguageLocalNameDecoder,
} from '../../SupportedLanguages/Decoders';
import { fromDecoderAny } from '../../TaskExt';
import {
  LandingPage,
  LandingPageOption,
  Language,
  LanguageResource,
  LanguagesResource,
  ProfileForm,
  Salutation,
  UserInputError,
} from '../Types';

export const parseSupportedLanguageCode = fromDecoderAny(supportedLanguageCodeDecoder);

export const languageDecoder: Decoder<Language> = succeed({})
  .assign('englishName', field('english_name', supportedLanguageEnglishNameDecoder))
  .assign('localName', field('local_name', supportedLanguageLocalNameDecoder))
  .assign('code', field('code', supportedLanguageCodeDecoder));

export const languageResourceDecoder: Decoder<LanguageResource> = resourceDecoder(languageDecoder);

export const languagesResourceDecoder: Decoder<LanguagesResource> = resourceDecoder(
  array(languageResourceDecoder)
);

export const landingPageDecoder: Decoder<LandingPage> = oneOf([
  stringLiteral<LandingPage>('learning-platform'),
  stringLiteral<LandingPage>('enterprise-portal'),
  stringLiteral<LandingPage>('client-portal'),
]);

export const landingPagesDecoder: Decoder<LandingPageOption> = succeed({}).assign(
  'value',
  field('value', landingPageDecoder)
);

export const salutationDecoder: Decoder<Salutation> = oneOf([
  stringLiteral<Salutation>('Mr.'),
  stringLiteral<Salutation>('Ms.'),
  stringLiteral<Salutation>('Dr.'),
  stringLiteral<Salutation>('Mx.'),
]);

const profileFormDecoder: Decoder<ProfileForm> = succeed({})
  .assign('firstName', field('first_name', nullableBlankString))
  .assign('lastName', field('last_name', nullableBlankString))
  .assign('shortName', field('short_name', nullableBlankString))
  .assign('defaultLandingPage', field('default_landing_page', explicitMaybe(landingPageDecoder)))
  .assign('division', field('division', nullableBlankString))
  .assign('cellPhone', field('cell_phone', maybe(string)))
  .assign('timeZone', field('time_zone', explicitMaybe(timeZoneDecoder)))
  .assign('currentPosition', field('current_position', nullableBlankString))
  .assign('country', field('country', explicitMaybe(countryDecoder)))
  .assign('region', field('region', explicitMaybe(regionDecoder)))
  .assign('salutation', field('salutation', explicitMaybe(salutationDecoder)))
  .assign('organization', field('organization', maybe(string)))
  .assign('linkedInPage', field('linked_in_page', nullableBlankString))
  .assign('facebookPage', field('facebook_page', nullableBlankString))
  .assign('twitterPage', field('twitter_page', nullableBlankString))
  .assign('workPhone', workPhoneDecoder)
  .assign('timeZoneOptions', field('time_zone_options', array(timeZoneDecoder)))
  .assign('salutationOptions', field('salutation_options', array(salutationDecoder)))
  .assign(
    'preferredLanguageResource',
    field('preferred_language_resource', explicitMaybe(languageResourceDecoder))
  )
  .assign(
    'availableLanguageResource',
    field('available_languages_resource', languagesResourceDecoder)
  )
  .assign('defaultLandingPages', field('default_landing_pages', array(landingPagesDecoder)));

export const profileFormResourceDecoderWithErrors: Decoder<
  ResourceWithErrors<ProfileForm>
> = succeed({})
  .assign('payload', field('payload', profileFormDecoder))
  .assign('links', field('links', linksDecoder))
  .assign('errors', field('errors', array(errorDecoder)));

export const profileFormResourceDecoder: Decoder<Resource<ProfileForm>> = resourceDecoder(
  profileFormDecoder
);

const avatarDecoder: Decoder<TemporaryAvatar> = succeed({});

export interface TemporaryAvatar {}

export const temporaryAvatarDecoder: Decoder<Resource<TemporaryAvatar>> = resourceDecoder(
  avatarDecoder
);

export const userInputErrorDecoder: Decoder<UserInputError> = field(
  'errors',
  oneOf<UserInputError['kind']>([
    stringLiteral<UserInputError['kind']>('region-id-missing-error'),
    stringLiteral<UserInputError['kind']>('region-missing-error'),
    stringLiteral<UserInputError['kind']>('region-invalid-error'),
    stringLiteral<UserInputError['kind']>('first-name-missing-error'),
    stringLiteral<UserInputError['kind']>('last-name-missing-error'),
    stringLiteral<UserInputError['kind']>('salutation-error'),
  ]).map<UserInputError>((kind) => ({ kind }))
);
