import { action, observable } from 'mobx';
import { DocumentAssetResource } from '../../../SegmentStore/Types';

interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
}

interface Ready {
  kind: 'ready';
  document: DocumentAssetResource;
}

export type State = Waiting | Initializing | Ready;

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const initializing = (): Initializing => ({
  kind: 'initializing',
});

const ready = (document: DocumentAssetResource): Ready => ({
  kind: 'ready',
  document,
});

class EmbeddedDocumentAssetStore {
  @observable
  state: State = waiting();

  constructor(public documentId: string, public embeddedDocumentAssets: DocumentAssetResource[]) {}

  @action
  initialize = () => {
    this.state = initializing();
  };

  @action
  ready = (document: DocumentAssetResource) => {
    this.state = ready(document);
  };
}

export default EmbeddedDocumentAssetStore;
