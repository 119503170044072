import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../../LinkyLinky';
import Image from '../../Image';
import * as style from '../style.module.css';
import { Link } from '../../../Resource/Types';
import { translation, TranslationsContext } from '../../../Translations';

interface Props {
  links: ReadonlyArray<Link>;
}

const UniversityLogoOnDark: React.FC<Props> = ({ links }) => {
  return findLink('logo-on-white', links)
    .map((url) => (
      <div className={style.universityLogoInSupPanel} data-test-university-logo={true}>
        <TranslationsContext.Consumer>
          {(ts) => (
            <>
              <Image src={url.href} alt={translation('University partner logo')(ts)} />{' '}
            </>
          )}
        </TranslationsContext.Consumer>
      </div>
    ))
    .getOrElse(() => <span />);
};

export default observer(UniversityLogoOnDark);
