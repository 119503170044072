import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import SubscriptionStore, { State } from '.';
import { AppyError, callApi } from '../../../../Appy';
import { subscriptionResourceDecoder } from '../../../../ConversationStore/Decoders';
import ErrorActionableReaction, { EAProps, handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';

export interface Props extends EAProps<SubscriptionStore> {
  store: SubscriptionStore;
}

type UpdateError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleUpdateError = (store: SubscriptionStore) => (error: UpdateError) => {
  switch (error.kind) {
    case 'missing-link-error':
      warnAndNotify('SubscriptionReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

class SubscriptionReactions extends ErrorActionableReaction<SubscriptionStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'subscribing-to-message':
        Task.succeed<UpdateError, ReadonlyArray<Link>>(state.subscriptionResource.links)
          .andThen(findLinkT('create'))
          .andThen(callApi(subscriptionResourceDecoder, {}))
          .fork(handleUpdateError(store), store.ready);
        break;
      case 'unsubscribing-from-message':
        Task.succeed<UpdateError, ReadonlyArray<Link>>(state.subscriptionResource.links)
          .andThen(findLinkT('delete'))
          .andThen(callApi(subscriptionResourceDecoder, {}))
          .fork(handleUpdateError(store), store.ready);
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default SubscriptionReactions;
