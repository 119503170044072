import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { replyNotificationsStore } from '../ReplyNotificationsStore';
import ReplyNotificationStore from '../ReplyNotificationsStore/ReplyNotificationStore';
import { ChatLink } from '../ReplyNotificationsStore/Types';
import { AlreadyTranslated, T } from '../Translations';
import ChatIcon from '../components/Cutesies/ChatIcon';
import * as style from './style.module.css';

interface Props {
  store: ReplyNotificationStore;
  chatLink: ChatLink;
}

const MessageReplyNotification: React.FC<Props> = ({ store, chatLink }) => {
  const dismissNotification = () => {
    store.dismiss();
    replyNotificationsStore.removeKindNotifications(store.state.notification);
  };

  switch (store.state.kind) {
    case 'starting':
      return (
        <>
          <div className={style.toast}>
            <div className={style.content}>
              <div className={style.icon}>
                <ChatIcon fill="#B3090F" />
              </div>
              <div className={style.details}>
                <h2 className={style.title}>
                  <AlreadyTranslated content={store.state.notification.title} />
                </h2>
              </div>
            </div>
            <div className={style.actions}>
              <button className={`${style.button} m-0`} onClick={dismissNotification}>
                <T kind="Dismiss" />
              </button>
              <Link
                className={style.button}
                to={`/${chatLink}/${store.state.notification.conversableId}`}
              >
                <T kind="View Messages" />
              </Link>
            </div>
          </div>
          );
        </>
      );
    case 'inactive':
    case 'dismissed':
      return <></>;
    default:
      return assertNever(store.state);
  }
};

export default observer(MessageReplyNotification);
