import { observer } from 'mobx-react';
import ProfileFormStore from '../../../ProfileFormStore';
import Button from '../../Form/Button';
import * as style from './style.module.css';

interface Props {
  profileStore: ProfileFormStore;
}

const submitProfile = (profileStore: ProfileFormStore) => (
  event: React.FormEvent<HTMLInputElement>
) => {
  event.preventDefault();
  profileStore.checkingRequiredFields();
};

const SubmitButton: React.FC<Props> = ({ profileStore }) => {
  switch (profileStore.state.kind) {
    case 'loaded':
    case 'countries-loaded':
    case 'country-selected':
    case 'selecting-country':
    case 'regions-loaded':
    case 'region-selected':
    case 'update-success':
    case 'ready-with-no-countries':
    case 'error':
      return (
        <Button
          className={style.submitButton}
          value="Save Profile"
          type="submit"
          onClick={submitProfile(profileStore)}
        />
      );
    case 'waiting':
    case 'loading':
    case 'loading-countries':
    case 'loading-regions':
    case 'checking-required-fields':
    case 'updating':
    case 'succeed':
    case 'ready-with-errors':
      return (
        <Button
          className={style.submitButton}
          value="Save Profile"
          type="submit"
          onClick={submitProfile(profileStore)}
          disabled
        />
      );
  }
};

export default observer(SubmitButton);
