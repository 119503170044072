import { uniqBy } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { TeamMemberResource as ChatMemberResource } from '../../../TeamsStore/Types';

export interface PresenceMember {
  id: number;
  name: string;
  member: Maybe<ChatMemberResource>;
}

export const member = (
  id: number,
  name: string,
  member: Maybe<ChatMemberResource>
): PresenceMember => ({
  id,
  name,
  member,
});

export interface PusherMembers {
  members: { [key: string]: PusherMember };
}

export interface PusherSubscriptionError {
  type: string;
  error: string;
}

interface PusherMember {
  id: string;
  full_name: string;
}

export const uniqMembers = uniqBy((m: PresenceMember) => m.id);

export type OnlineStatus = 'offline' | 'online' | 'not-applicable';
