import React, { useContext } from 'react';
import { ProductCollectionKind } from '../Common/Experience/Types';

export type DiscoveryContextState = None | ProductCollection | AllExperiences | LearnMore | Direct;

export type FromState = None | ProductCollection | AllExperiences;

export const allExperiences = (): AllExperiences => ({
  kind: 'all-experiences',
});

export const learningDesign = (productCollectionId: number): ProductCollection => ({
  kind: 'learning-design',
  productCollectionId,
});

export const recEngine = (productCollectionId: number): ProductCollection => ({
  kind: 'recommendation-engine',
  productCollectionId,
});

export const dynamicRecommendation = (productCollectionId: number): ProductCollection => ({
  kind: 'dynamic-recommendation',
  productCollectionId,
});

export const none = (): None => ({ kind: 'none' });

export const learnMore = (fromState: FromState): LearnMore => ({
  kind: 'learn-more',
  from: fromState,
});

export interface None {
  kind: 'none';
}

export interface ProductCollection {
  kind: ProductCollectionKind;
  productCollectionId: number;
}

export interface AllExperiences {
  kind: 'all-experiences';
}

export interface LearnMore {
  kind: 'learn-more';
  from: FromState;
}

export interface Direct {
  kind: 'direct';
}

const DiscoveryContext = React.createContext<DiscoveryContextState>(none());

export function useDiscoveryContext() {
  return useContext(DiscoveryContext);
}

export default DiscoveryContext;
