import { observer } from 'mobx-react';
import { T } from '../../Translations';
import FormTextField from './FormTextField';
import { FormTextFieldProps } from './Types';

function EmailField(props: FormTextFieldProps) {
  return (
    <FormTextField
      autoFocus
      type="email"
      name="email"
      label={<T kind="Email or Username" />}
      helperText="email@domain.com; username@id"
      {...props}
    />
  );
}

export default observer(EmailField);
