import { observer } from 'mobx-react';
import * as Pusher from 'pusher-js';
import * as React from 'react';
import { replyNotificationsStore } from '../ReplyNotificationsStore';
import { ReplyNotification } from '../ReplyNotificationsStore/Types';

interface Props {
  channel: Pusher.Channel;
}

class PresenceBinding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel } = this.props;
    channel.bind('new-reply', (replyNotification: ReplyNotification) => {
      replyNotificationsStore.newNotification(replyNotification);
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(PresenceBinding);
