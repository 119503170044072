import { observer } from 'mobx-react';
import { T } from '../../../../../../../../Translations';
import { AvailabilityResource, AvailabilityResources } from '../../../../Types';
import ScheduledAvailability from './ScheduledAvailability';

interface Props {
  availability: AvailabilityResource;
  availabilities: AvailabilityResources;
}

function FormattedUpcomingDates({ availability, availabilities }: Props) {
  switch (availability.payload.kind) {
    case 'on-demand':
      return <T kind="On Demand" />;
    case 'scheduled':
      return <ScheduledAvailability availabilities={availabilities} />;
  }
}

export default observer(FormattedUpcomingDates);
