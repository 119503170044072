import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { T } from '../../Translations';
import PresentParticipantsCounter from './PresentParticipantsCounter';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const PresentParticipants: React.FC<Props> = ({ conferenceRosterStore }) => (
  <div>
    <PresentParticipantsCounter conferenceRosterStore={conferenceRosterStore} />
    <p className={clsx('m-0 text-xs')}>
      <T kind="Current Users" />
    </p>
  </div>
);

export default observer(PresentParticipants);
