import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, field, number, oneOf, string, succeed } from 'jsonous';
import { participantTypeDecoder } from '../Person/Decoders';
import { profileResourceDecoder } from '../ProfileStore/Decoders';
import { resourceDecoder } from '../Resource/Decoders';
import {
  ConferenceEventType,
  ConferenceParticipant,
  ConferencePresentParticipantsResource,
  ConferenceRoster,
  ConferenceRosterResource,
  PresentParticipants,
  ProviderParticipant,
} from './Types';

const conferenceParticipantDecoder: Decoder<ConferenceParticipant> = succeed({})
  .assign('id', field('id', number))
  .assign('participantType', field('participant_type', participantTypeDecoder))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

const conferenceEventDecoder: Decoder<ConferenceEventType> = oneOf<ConferenceEventType>([
  stringLiteral<ConferenceEventType>('public'),
  stringLiteral<ConferenceEventType>('private'),
]);

export const conferenceRosterDecoder: Decoder<ConferenceRoster> = succeed({})
  .assign('kind', field('kind', conferenceEventDecoder))
  .assign('participants', field('participants', array(conferenceParticipantDecoder)))
  .map<ConferenceRoster>(identity);

export const conferenceRosterResourceDecoder: Decoder<ConferenceRosterResource> =
  resourceDecoder(conferenceRosterDecoder);

export const providerParticipantDecoder: Decoder<ProviderParticipant> = succeed({})
  .assign('uuid', field('id', string))
  .assign('name', field('name', string));

const conferencePresentParticipantsDecoder: Decoder<PresentParticipants> = array(
  providerParticipantDecoder,
);

export const conferencePresentParticipantsResourceDecoder: Decoder<ConferencePresentParticipantsResource> =
  resourceDecoder(conferencePresentParticipantsDecoder);
