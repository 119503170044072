import { emptyFragment } from '@execonline-inc/execonline-ui';
import { always } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import Filters from '.';
import { discoveryStore } from '../../../../../DiscoveryStore';
import { DiscoveryResource } from '../../../../../DiscoveryStore/Types';
import { findLinkBy } from '../../../../../Fetch';
import { Link } from '../../../../../Resource/Types';
import { RegisterResource } from '../../RegisterResourceStore/Types';
import ExperiencesStore from '../Experiences/ExperiencesStore';
import FilterStore from './FilterStore';
import SearchBox from './SearchBox';

interface Props {
  experiencesStore: ExperiencesStore;
  filterStore: FilterStore;
}

interface SearchAndFilter {
  kind: 'search-and-filter';
  link: Link;
}

interface Search {
  kind: 'search';
  link: Link;
}

interface Filter {
  kind: 'filter';
}

interface Disabled {
  kind: 'disabled';
}

type Exploration = SearchAndFilter | Search | Filter | Disabled;

const whenFilterableResource = (resource: Maybe<DiscoveryResource>) =>
  resource.andThen(whenFilterable);

const searchable =
  (resource: Maybe<DiscoveryResource>) =>
  (link: Link): Maybe<SearchAndFilter | Search> =>
    whenFilterableResource(resource)
      .map<SearchAndFilter | Search>(always({ kind: 'search-and-filter', link: link }))
      .orElse(() => just({ kind: 'search', link: link }));

const notSearchable = (resource: Maybe<DiscoveryResource>): Maybe<Filter | Disabled> =>
  whenFilterableResource(resource).map<Filter | Disabled>(always({ kind: 'filter' }));

const exploration = (
  registerResource: RegisterResource,
  resource: Maybe<DiscoveryResource>,
): Exploration =>
  findLinkBy({ rel: 'search-discovery-portal' }, registerResource.links)
    .andThen<Exploration>(searchable(resource))
    .orElse(() => notSearchable(resource))
    .getOrElseValue({ kind: 'disabled' });

function FilterSection(props: Props) {
  const handleSubmit = (link: Link) => (value: string) => {
    props.experiencesStore.searching(just(value), link);
  };
  const exploreObject = exploration(
    props.experiencesStore.registerResource,
    discoveryStore.resource,
  );
  switch (exploreObject.kind) {
    case 'search-and-filter':
      return (
        <div className="sm:px-0">
          <Filters {...props} link={just(exploreObject.link)} />
        </div>
      );
    case 'search':
      return (
        <div className="sm:px-0">
          <div className="mx-auto box-border flex px-6 pb-8 pt-0 xl:container md:px-16">
            <SearchBox
              className="basis-full lg:basis-6/12"
              state={props.experiencesStore.state}
              onSubmit={handleSubmit(exploreObject.link)}
            />
          </div>
        </div>
      );
    case 'filter':
      return (
        <div className="sm:px-0">
          <Filters {...props} link={nothing()} />
        </div>
      );
    case 'disabled':
      return emptyFragment();
  }
}

export default observer(FilterSection);

const whenFilterable = (discoveryResource: DiscoveryResource): Maybe<DiscoveryResource> => {
  switch (discoveryResource.payload.filtering) {
    case 'filterable':
      return just(discoveryResource);
    case 'non-filterable':
      return nothing();
  }
};
