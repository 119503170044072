import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { DiscoveryContextState } from '../Native/AEP/DiscoveryContext';
import { Note } from '../NoteStore/Types';
import { ProgramSegment } from '../ProgramStore/Types';
import { Segment } from '../SegmentStore/Types';
import { UseCaseItem } from '../components/Dropdown/Types';

export const programRoute = (programId: number) => `/programs/${programId}`;
export const resetPasswordRoute = `/reset_password`;
export const editPasswordRoute = '/edit_password/:token';
export const ssoLoginErrorRoute = `/sso_403`;
export const ssoAuthErrorRoute = `/sso_404`;
export const freebusyRoute = '/freebusy_test';
export const unsubscribeRoute = '/unsubscribe/:uuid';
export const unsubscribeProgressEmailRoute = '/unsubscribe_progress_email/:id';

interface SegmentRouteArgs {
  programId: number;
  moduleId: number;
  segmentId: number;
}

export const directSegmentRoute = ({ programId, moduleId, segmentId }: SegmentRouteArgs): string =>
  `/programs/${programId}/modules/${moduleId}/segments/${segmentId}`;

export const segmentRoute = (programSegment: ProgramSegment | Segment) =>
  directSegmentRoute({ ...programSegment, segmentId: programSegment.id });

export const programDashboardUrl = (programId: number) => `/programs/${programId}`;
export const dashboardUrl = '/';
export const teamUrl = (teamId: number) => `/teams/${teamId}`;
export const teamsUrl = `/teams`;
export const communitiesUrl = `/communities`;
export const programUrl = (programId: number) => `/program_chats/${programId}`;
export const eventConferenceUrl = (eventId: number) => `/events/${eventId}/conference`;
export const eventDashboardUrl = (eventId: number) => `/events/${eventId}/dashboard`;
export const eventBreakoutUrl = (eventId: number, teamId: number) =>
  `/events/${eventId}/teams/${teamId}`;
export const teamConferenceUrl = (teamId: number) => `/teams/${teamId}/conference`;
export const profileEditUrl = `/profile/edit`;

export const resourceRoute = (programId: number, resourceId: number) =>
  `/programs/${programId}/resources/${resourceId}`;

export const courseDocumentsRoute = (identifier: string) => `/course_documents/${identifier}`;
export const discoveryPortalRoute = (uuid: string) => `/register/${uuid}?dp=show`;

const experienceDetailsPath = (discoveryContextState: DiscoveryContextState) => {
  switch (discoveryContextState.kind) {
    case 'all-experiences':
      return just('/from/all');
    case 'learning-design':
      return just(`/from/learningDesign/${discoveryContextState.productCollectionId}`);
    case 'recommendation-engine':
      return just(`/from/rec/${discoveryContextState.productCollectionId}`);
    case 'dynamic-recommendation':
      return just(`/from/dynamicRecommendation/${discoveryContextState.productCollectionId}`);
    case 'group-coaching-groups-recommendation':
    case 'learn-more':
    case 'direct':
    case 'none':
      return nothing();
  }
};

export const experienceDetailsRoute = (
  invitationUuid: string,
  experienceId: string,
  discoveryContextState: DiscoveryContextState,
) =>
  `/register/${invitationUuid}/experience/${experienceId}${experienceDetailsPath(
    discoveryContextState,
  ).getOrElseValue('')}`;

export const startExperienceDetailsRoute = (invitationUuid: string, experienceId: string) =>
  `/register/${invitationUuid}/experience/${experienceId}/now`;

export const segmentResourceRoute = (
  programId: number,
  moduleId: number,
  segmentId: number,
  resourceId: number,
) => `/programs/${programId}/modules/${moduleId}/segments/${segmentId}/resources/${resourceId}`;

export const baseSegmentContextRoute = (
  programId: number,
  moduleId: number,
  segmentId: number,
): string => `/programs/${programId}/modules/${moduleId}/segments/${segmentId}`;

export const noteContextRoute = (note: Note): Maybe<string> => {
  switch (note.type) {
    case 'segment':
      return just(baseSegmentContextRoute(note.programId, note.moduleId, note.segmentId));
    case 'position':
      return just(
        `${baseSegmentContextRoute(note.programId, note.moduleId, note.segmentId)}?position=${
          note.position
        }`,
      );
    case 'element':
    case 'slide':
    case 'program':
      return nothing();
  }
};

export const targetedInvitationRoute = (uuid: string): string => `/register/${uuid}`;
export const directTargetedInvitationRoute = (uuid: string): string => `/register/${uuid}?dp=show`;

export const useCaseLink = (item: UseCaseItem) => {
  switch (item.content.source) {
    case 'direct-enrollment':
      return directTargetedInvitationRoute(item.content.uuid);
    case 'new-program-family-shared-open-enrollment':
    case 'open-enrollment':
    case 'preview-only':
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
      return targetedInvitationRoute(item.content.uuid);
    default:
      assertNever(item.content.source);
  }
};

export const exploreRoute = (): string => '/explore';

export const calendarRoute = '/calendar';

export const resourcesRoute = '/resources';
