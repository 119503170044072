import * as H from 'history';
import { TPlainTextKey } from '../../Translations';

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  to: H.LocationDescriptor;
}

export interface Navigate {
  kind: 'navigate';
  to: H.LocationDescriptor;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (to: H.LocationDescriptor): Ready => ({
  kind: 'ready',
  to,
});

export const navigate = (to: H.LocationDescriptor): Navigate => ({
  kind: 'navigate',
  to,
});

export const error = (message: TPlainTextKey): Error => ({
  kind: 'error',
  message,
});

export type State = Waiting | Ready | Navigate | Error;
