import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const BoldIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 12 14">
      <path
        fill="currentColor"
        stroke="currentColor"
        d="M1 1V13.444h5.877a3.813 3.813 0 0 0 3.802-3.802 3.81 3.81 0 0 0-2.16-3.423c.443-.489.777-1.19.777-2.108 0-1.44-.822-2.35-1.592-2.736C6.934.991 6.185 1 6.185 1H1Zm1.383 1.383h3.802s.46.01.9.23.829.519.829 1.498c0 .98-.388 1.279-.828 1.499-.44.22-.9.23-.9.23H2.382V2.383Zm0 4.84h4.494a2.41 2.41 0 0 1 2.42 2.419 2.41 2.41 0 0 1-2.42 2.42H2.383v-4.84Z"
      />
    </SVGIcon>
  );
};

export default observer(BoldIcon);
