import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, field, number, oneOf, string, succeed } from 'jsonous';
import { Payload } from './Payload';
import { App, Message } from './Types';

const userActivityDecoder: Decoder<Payload> = succeed({})
  .assign('kind', field('kind', stringLiteral('user-activity')))
  .assign('event', field('event', string))
  .assign('expiration', field('expiration', number));

const payloadDecoder: Decoder<Payload> = oneOf([userActivityDecoder]);

export const appDecoder: Decoder<App> = oneOf([
  stringLiteral<App>('learning'),
  stringLiteral<App>('adminstudio'),
  stringLiteral<App>('enterprise'),
]);

const recipientDecoder = appDecoder;

const recipientsDecoder: Decoder<readonly App[]> =
  array(recipientDecoder).map<readonly App[]>(identity);

export const messageDecoder: Decoder<Message> = succeed({})
  .assign('kind', field('kind', stringLiteral('MessageBus.Message')))
  .assign('sender', field('sender', string))
  .assign('recipients', field('recipients', recipientsDecoder))
  .assign('payload', field('payload', payloadDecoder));
