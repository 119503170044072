import { warn } from '@execonline-inc/logging';
import { assertNever, noop } from '@kofno/piper';
import { BadStatus, toHttpTask } from 'ajaxian';
import { just, nothing } from 'maybeasy';
import { toJS } from 'mobx';
import { callApi, request } from '../Appy';
import { assertionBackendResourceDecoder } from '../Login/Decoders';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { Resource } from '../Resource/Types';
import { rootResourceStore } from '../RootResourceStore';
import { reportMissingRootsLink } from '../RootResourceStore/Functions';
import { sessionStore } from '../Session/Store';
import { assertionBackendToUrls } from '../SingleSignOn/Functions';
import { TPlainTextKey } from '../Translations';
import { triggerAnalyticsEvent } from '../components/Tooling/GoogleTagManagerTooling/Loader';
import {
  PasswordSubmissionError,
  assertionBackendIdentificationFailure,
  loginError,
  missingSsoLink,
  passwordSubmissionFailure,
} from './Errors';
import { IdentifyingAssertionBackend, State, SubmittingPassword } from './States';
import { LoginStore } from './Store';
import { AssertionBackend } from './Types';

interface Props extends RCProps<LoginStore> {}

export class LoginReactions extends ReactionComponent<LoginStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State): void => {
    const { store } = this.props;
    switch (state.kind) {
      case 'awaiting-username':
      case 'awaiting-password':
        state.error.cata({
          Nothing: noop,
          Just: (error) => {
            warn(`A login form error occurred. Current state: ${state.kind}`, toJS(error));
          },
        });
        break;
      case 'identifying-assertion-backend':
        rootResourceStore
          .findLinkT('assertion-backend')
          .andThen(callApi(assertionBackendResourceDecoder, { email: state.username }))
          .fork(
            (error) =>
              store.awaitingUsername({
                username: just(state.username),
                error: just(
                  loginError({
                    sourceError: assertionBackendIdentificationFailure(error),
                    message: 'Something went wrong',
                  })
                ),
              }),
            handleBackendAssertion(store, state)
          );
        break;
      case 'submitting-password':
        rootResourceStore
          .findLinkT('unified-login')
          .andThen((link) =>
            request(link, {
              username: state.username,
              password: state.password,
            }).andThen(toHttpTask)
          )
          .do(() => triggerAnalyticsEvent('event', 'Authentication', 'click', 'Login'))
          .fork(handlePasswordSubmissionError(store, state), store.successfulLogin);
        break;
      case 'awaiting-sso-flow':
        break;
      case 'successful-login':
        sessionStore.requesting({ messageOnFailure: just('Something went wrong') });
        break;
      case 'locked-out':
        break;
      default:
        assertNever(state);
    }
  };
}

const handleBackendAssertion =
  (store: LoginStore, state: IdentifyingAssertionBackend) =>
  (resource: Resource<AssertionBackend>): void => {
    const { payload } = resource;
    switch (payload.kind) {
      case 'password-backend':
        store.awaitingPassword({ password: nothing(), error: nothing() });
        break;
      case 'third-party-backend':
        assertionBackendToUrls(resource).cata({
          Err: (error) =>
            store.awaitingUsername({
              username: just(state.username),
              error: just(
                loginError({
                  sourceError: missingSsoLink(error),
                  message: 'An error occurred, please refresh the page and try again',
                })
              ),
            }),
          Ok: (ssoUrls) => store.awaitingSsoFlow({ ssoUrls }),
        });
        break;
      case 'locked-access':
        store.lockedOut();
        break;
      default:
        assertNever(payload);
    }
  };

const handlePasswordSubmissionError =
  (store: LoginStore, state: SubmittingPassword) =>
  (error: PasswordSubmissionError): void => {
    switch (error.kind) {
      case 'missing-link-error':
        reportMissingRootsLink(error);
        break;
      case 'bad-status':
        handlePasswordSubmissionBadStatus(store, state, error);
        break;
      case 'bad-payload':
      case 'bad-url':
      case 'missing-api-compatibility':
      case 'missing-application-id':
      case 'network-error':
      case 'timeout':
        handlePasswordSubmissionErrorWithMessage(store, state, error, 'Something went wrong');
        break;
      default:
        assertNever(error);
    }
  };

const handlePasswordSubmissionBadStatus = (
  store: LoginStore,
  state: SubmittingPassword,
  error: BadStatus
): void => {
  switch (error.response.status) {
    case 403:
      store.lockedOut();
      break;
    case 400:
      handlePasswordSubmissionErrorWithMessage(
        store,
        state,
        error,
        'Please try again; we did not recognize your email address and/or password.'
      );
      break;
    default:
      handlePasswordSubmissionErrorWithMessage(store, state, error, 'Something went wrong');
  }
};

const handlePasswordSubmissionErrorWithMessage = (
  store: LoginStore,
  state: SubmittingPassword,
  error: PasswordSubmissionError,
  message: TPlainTextKey
): void =>
  store.awaitingPassword({
    password: just(state.password),
    error: just(loginError({ sourceError: passwordSubmissionFailure(error), message })),
  });
