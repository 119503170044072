import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { findLink } from '../../LinkyLinky';
import TermsAndConditions from '../TermsAndConditions';

interface Props {
  currentUserResource: CurrentUserResource;
}

const WhenTermsAccepted: React.FC<Props> = ({ currentUserResource, children }) =>
  findLink('terms_and_conditions', currentUserResource.links)
    .map(() => <TermsAndConditions currentUserResource={currentUserResource} />)
    .getOrElse(() => <>{children}</>);

export default observer(WhenTermsAccepted);
