import { observer } from 'mobx-react';
import { CommentLanguageIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

function ExperienceAvailableLanguages() {
  const { experienceResource } = useExperiencePopoverContext();
  const { kind, availableLanguages } = experienceResource.payload;

  switch (kind) {
    case 'beginnable':
    case 'returnable':
    case 'resumable':
    case 'upcoming':
    case 'graduated':
      return emptyFragment();
    case 'enrollable':
    case 'commerce-enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      const languagesList = availableLanguages.payload.map(({ payload }) => payload.englishName);

      return (
        <div className="flex items-center gap-x-2">
          <CommentLanguageIcon size="md" />
          <Typography variant="caption">
            <T
              kind="Available in <span>{{languages}}</span>"
              languages={languagesList.join(', ')}
              span={(languages) => <>{languages}</>}
            />
          </Typography>
        </div>
      );
  }
}

export default observer(ExperienceAvailableLanguages);
