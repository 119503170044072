import { observer } from 'mobx-react';
import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import ReactionComponent from '../../ReactionComponent';
import SizedImageStore from './SizedImageStore';
import { State } from './SizedImageStore/Types';

interface Props {
  store: SizedImageStore;
}

class SizedImageReactions extends ReactionComponent<SizedImageStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;
    const image = new Image();

    switch (state.kind) {
      case 'loading':
        image.src = state.uri;
        image.onload = () => {
          store.ready({ width: image.width, height: image.height });
        };

        image.onerror = () => {
          store.error(state.uri);
        };
        break;
      case 'waiting':
      case 'ready':
        break;
      case 'error':
        warn(`Unable to preload image at "${state.uri}"`);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(SizedImageReactions);
