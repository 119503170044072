import { Error } from '../ErrorHandling';

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  message: string;
}

interface Updating {
  kind: 'updating';
  message: string;
}

interface Broadcasting {
  kind: 'broadcasting';
  message: string;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const reset = (): Ready => ({
  kind: 'ready',
  message: '',
});

export const ready = (message: string): Ready => ({
  kind: 'ready',
  message: message,
});

export const updating = (message: string): Updating => ({
  kind: 'updating',
  message: message,
});

export const broadcasting = (message: string): Broadcasting => ({
  kind: 'broadcasting',
  message: message,
});

export type BroadcastMessageState = Ready | Updating | Waiting | Broadcasting | Error;
