import * as React from 'react';
import { observer } from 'mobx-react';
import { fromBool } from '@execonline-inc/maybe-adapter';
import { EditorState, RichUtils } from 'draft-js';
import ChatMessageStore from '../../../ChatMessageStore';
import * as style from './style.module.css';
import Button from '../../Form/Button';
import InlineStyleControls from './StyleControls/InlineStyleControls';
import BlockStyleControls from './StyleControls/BlockStyleControls';
import ChatEditor from './StyleControls/ChatEditor';
import 'draft-js/dist/Draft.css';
import { TPlainTextKey } from '../../../Translations';

interface Props {
  store: ChatMessageStore;
  placeholder: TPlainTextKey;
}

const TeamChatMessageEditor: React.FC<Props> = ({ store, placeholder }) => {
  let editorClass = style.textarea;

  const shouldHidePlaceholder = () => {
    return store.currentMessageContent
      .map(
        (contentState) =>
          contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled'
      )
      .getOrElseValue(false);
  };

  if (shouldHidePlaceholder()) {
    editorClass += ` ${style.editorHidePlaceholder}`;
  }

  const submitChatMessage = () =>
    store.message.andThen((m) => fromBool(m.getCurrentContent().hasText(), m)).do(store.updating);

  const onChange = (value: EditorState) => {
    store.setEditorMessage(value);
  };

  const toggleBlockType = (blockType: string) => {
    onChange(
      RichUtils.toggleBlockType(store.message.getOrElse(EditorState.createEmpty), blockType)
    );
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    onChange(
      RichUtils.toggleInlineStyle(store.message.getOrElse(EditorState.createEmpty), inlineStyle)
    );
  };

  const submit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    submitChatMessage();
  };

  return (
    <div className={style.formWrapper}>
      <form className={style.form}>
        <div className={editorClass}>
          <ChatEditor
            editorState={store.message.getOrElse(EditorState.createEmpty)}
            submitChatMessage={submitChatMessage}
            store={store}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
        <div className={style.editorControls}>
          <InlineStyleControls
            editorState={store.message.getOrElse(EditorState.createEmpty)}
            onToggle={toggleInlineStyle}
          />
          <BlockStyleControls
            editorState={store.message.getOrElse(EditorState.createEmpty)}
            onToggle={toggleBlockType}
          />
          <Button
            type="submit"
            className={style.submitButton}
            onClick={submit}
            disabled={store.state.kind === 'updating'}
            value="Send"
          />
        </div>
      </form>
    </div>
  );
};
export default observer(TeamChatMessageEditor);
