import * as React from 'react';
import { observer } from 'mobx-react';
import { fromBool, toTask } from '@execonline-inc/maybe-adapter';
import { EditorState, RichUtils } from 'draft-js';
import * as style from './style.module.css';
import Button from '../../Form/Button';
import InlineStyleControls from './StyleControls/InlineStyleControls';
import BlockStyleControls from './StyleControls/BlockStyleControls';
import ChatEditor from './StyleControls/ChatEditor';
import 'draft-js/dist/Draft.css';
import { TPlainTextKey } from '../../../Translations';
import ChatReplyStore from '../../../ChatReplyStore';
import { warn } from '@execonline-inc/logging';
import { error } from '../../../ErrorHandling';

interface Props {
  store: ChatReplyStore;
  placeholder: TPlainTextKey;
}

const TeamChatReplyEditor: React.FC<Props> = ({ store, placeholder }) => {
  const shouldHidePlaceholder = () => {
    return store.currentMessageContent
      .map(
        (contentState) =>
          contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled'
      )
      .getOrElseValue(false);
  };

  const submitChatMessage = () =>
    toTask(error('Message not available'), store.message)
      .map((m) => fromBool(m.getCurrentContent().hasText(), m))
      .andThen(toTask(error('Message not available')))
      .fork((err) => warn(`Could not submit chat message: ${err.message}`), store.updating);

  const onChange = (value: EditorState) => {
    store.setEditorMessage(value);
  };

  const toggleBlockType = (blockType: string) => {
    onChange(
      RichUtils.toggleBlockType(store.message.getOrElse(EditorState.createEmpty), blockType)
    );
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    onChange(
      RichUtils.toggleInlineStyle(store.message.getOrElse(EditorState.createEmpty), inlineStyle)
    );
  };

  const submit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    submitChatMessage();
  };

  const disableSubmitButton = (): boolean => {
    switch (store.state.kind) {
      case 'updating':
        return true;
      case 'ready':
      case 'broadcasting':
      case 'hidden':
      case 'error':
      default:
        return false;
    }
  };

  return (
    <div className={style.replyField}>
      <div className={style.formWrapper}>
        <form className={style.form}>
          <div
            className={`${style.textarea} ${
              shouldHidePlaceholder() ? style.editorHidePlaceholder : ''
            }`}
          >
            <ChatEditor
              editorState={store.message.getOrElse(EditorState.createEmpty)}
              submitChatMessage={submitChatMessage}
              store={store}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
          <div className={style.editorControls}>
            <InlineStyleControls
              editorState={store.message.getOrElse(EditorState.createEmpty)}
              onToggle={toggleInlineStyle}
            />
            <BlockStyleControls
              editorState={store.message.getOrElse(EditorState.createEmpty)}
              onToggle={toggleBlockType}
            />
            <Button
              type="submit"
              className={style.submitButton}
              onClick={submit}
              disabled={disableSubmitButton()}
              value="Send"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default observer(TeamChatReplyEditor);
