import { observer } from 'mobx-react';
import * as React from 'react';
import PdfStore from '../../PdfStore';
import { T, TPlainTextKey } from '../../Translations';
import DownloadIcon from '../Cutesies/DownloadIcon';
import * as style from '../DownloadViewModal/style.module.css';
import Modal from '../Modal';
import ModalStore from '../Modal/Store';

interface Props {
  pdfStore: PdfStore;
  modalStore: ModalStore;
  fileName: TPlainTextKey;
}

const downloadPdf = (pdfStore: PdfStore, modalStore: ModalStore) => {
  modalStore.close();
  pdfStore.downloading();
};

const DownloadNotesModal: React.FC<Props> = ({ pdfStore, modalStore, fileName }) => {
  switch (modalStore.state.kind) {
    case 'open':
      return (
        <Modal close={modalStore.close} title={<T kind="Download Notes" />}>
          <div className={style.content}>
            <h1 className={style.fileTitle}>
              <T kind={fileName}></T>
            </h1>
            <div className={style.buttons}>
              <button
                className={style.downloadButton}
                onClick={() => {
                  downloadPdf(pdfStore, modalStore);
                }}
                data-test-notes-download-button={true}
              >
                <T kind="Download File" />
                <div className={style.buttonIcon}>
                  <DownloadIcon />
                </div>
              </button>
            </div>
          </div>
        </Modal>
      );
    case 'closed':
      return <></>;
  }
};

export default observer(DownloadNotesModal);
