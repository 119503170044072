import { take } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import { PresenceMember } from '../../Socket/PresenceChannel/PresenceMembersStore/Types';
import LoadMember from './LoadMember';

interface Props {
  presenceMembersStore: PresenceMembersStore;
  shownMembers: number;
}

const LoadingMembers: React.FC<Props> = ({ presenceMembersStore, shownMembers }) => (
  <>
    {take(shownMembers, presenceMembersStore.members).map((presenceMember: PresenceMember) => (
      <LoadMember presenceMember={presenceMember} />
    ))}
  </>
);

export default observer(LoadingMembers);
