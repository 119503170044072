import * as React from 'react';
import { observer } from 'mobx-react';
import EventsStore from '../EventsStore';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';
import EventsHandler from '../Socket/EventsChannel/EventsHandler';
import EventsChannel from '../Socket/EventsChannel';

interface Props {
  eventsStore: EventsStore;
  channelName: string;
}

class Refresh extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();

  render() {
    return (
      <>
        <EventsChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <EventsHandler
          eventsStore={this.props.eventsStore}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
      </>
    );
  }
}

export default observer(Refresh);
