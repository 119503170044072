import { NotTranslated } from '@execonline-inc/translations';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { programRoute } from '../../ClientRoutes';
import { findLink } from '../../LinkyLinky';
import * as style from './style.module.css';
import { NextExperiencesNode } from './Types';

interface Props {
  node: NextExperiencesNode;
}

const NextExperiencesDetails: React.FC<Props> = ({ node }) => (
  <div className={style.nextExperienceContainer}>
    {node.nextExperiences.programs.map(program =>
      just({})
        .assign('title', program.payload.title)
        .assign('logo', findLink('logo-on-dark', program.links).map(l => l.href))
        .assign('description', program.payload.description)
        .map(({ title, logo, description }) => (
          <Link
            to={programRoute(program.payload.id)}
            key={program.payload.id}
            className={style.nextExperience}
          >
            <div className={style.nextExperienceTitleContainer}>
              <div className={style.nextExperienceTitle}>
                <NotTranslated text={title} />
              </div>
              <img className={style.nextExperienceLogo} src={logo} alt="school logo" />
            </div>
            <div className={style.nextExperienceDescriptionContainer}>
              <NotTranslated text={description} />
            </div>
          </Link>
        ))
        .getOrElse(() => <div key={Math.random()} />)
    )}
  </div>
);

export default observer(NextExperiencesDetails);
