import { observer } from 'mobx-react';
import * as React from 'react';
import NoteStore from '../../../NoteStore';
import Loader from '../../Loader';
import Confirmation from './Confirmation';
import NoteDetail from './NoteDetail';
import NoteForm from './NoteForm';

interface Props {
  noteStore: NoteStore;
  hideContext: boolean;
}

const NoteItem: React.FC<Props> = ({ noteStore, hideContext }) => {
  switch (noteStore.state.kind) {
    case 'ready': {
      return <NoteDetail noteStore={noteStore} hideContext={hideContext} />;
    }
    case 'new':
    case 'editing':
    case 'error':
      return <NoteForm store={noteStore} />;
    case 'confirming':
      return <Confirmation store={noteStore} />;
    case 'loading':
    case 'waiting':
    case 'creating':
    case 'updating':
      return <Loader />;
    case 'deleting':
      return <span />;
  }
};
export default observer(NoteItem);
