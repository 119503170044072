import { observer } from 'mobx-react';
import * as React from 'react';
import { LearningPartnerResource } from '../../../../../LearningPartnersStore/Types';
import LearningPartnerImpl from './LearningPartnerImpl';

interface Props {
  learningPartnerResource: LearningPartnerResource;
}

const LearningPartner: React.FC<Props> = ({ learningPartnerResource }) => (
  <LearningPartnerImpl learningPartnerResource={learningPartnerResource} />
);

export default observer(LearningPartner);
