import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useExperienceContext } from '../ExperienceContext';
import { GroupCoachingIcon, LeadershipCoachingIcon } from './Icons';

function ExperienceHeaderEndIcon() {
  const { experienceResource } = useExperienceContext();

  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'program-sequence':
      return emptyFragment();
    case 'coaching':
      return <LeadershipCoachingIcon className="absolute bottom-0 right-4 h-[35px] w-[62px]" />;
    case 'group-coaching':
      return <GroupCoachingIcon className="absolute bottom-0 right-4 h-[35px] w-[62px]" />;
  }
}

export default observer(ExperienceHeaderEndIcon);
