import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, CompletedProgram } from '../../../ProgramStore/Types';
import { NotTranslated } from '../../../Translations';
import Modules from './Modules';
import * as style from './style.module.css';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const ChapterOutline: React.FC<Props> = ({ programDetail }) => (
  <>
    {programDetail.chapters.map((chapter) => (
      <div
        className={style.outline}
        data-test-program-content={programDetail.id}
        key={chapter.name}
      >
        <h2 data-test-chapter-outline-label="Chapter">
          <NotTranslated text={chapter.name} />
        </h2>
        <Modules programId={programDetail.id} modules={chapter.modules} />
      </div>
    ))}
  </>
);

export default observer(ChapterOutline);
