import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../ProgramStore';
import { ActiveProgram } from '../../ProgramStore/Types';
import BeginOrResumeButton from '../BeginOrResumeButton';
import OrientationModalGraphic from './ActiveCoachingProductOverview/Orientation/OrientationModalGraphic';
import ProgramTitle from './ActiveCoachingProductOverview/Orientation/ProgramTitle';
import AccessUntilInfo from './ActiveCoachingProductOverview/Orientation/StartedOrientation/AccessUntilInfo';
import * as style from '../ActiveProgramOverview/ActiveCoachingProductOverview/style.module.css';

interface Props {
  activeProgram: ActiveProgram;
  store: ProgramStore;
}

const ActiveFullCoachingProductOverview: React.FC<Props> = ({ activeProgram, store }) => (
  <div className={style.programTile}>
    <OrientationModalGraphic />
    <div className={style.footer}>
      <div className={style.footerContent}>
        <ProgramTitle title={activeProgram.title} />
        <AccessUntilInfo activeProgram={activeProgram} />
      </div>
      <BeginOrResumeButton programStore={store} />
    </div>
  </div>
);

export default observer(ActiveFullCoachingProductOverview);
