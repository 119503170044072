import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';

function EnrolledModalHeader() {
  return (
    <Typography className="tracking-normal text-gray-900" variant="h5" as="h2" fontWeight="bold">
      <T kind="You are currently enrolled in a program" />
    </Typography>
  );
}

export default observer(EnrolledModalHeader);
