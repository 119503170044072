import { observer } from 'mobx-react';
import { ExperienceResource } from '../../../../Experience/Types';
import {
  ProgramOverviewItems,
  ProgramDetailsItems,
  ProgramEnrolled,
  ProgramGraduated,
  ProgramUpcomingDates,
} from '../../../../Experience/ExperienceBody';
import { getPrimaryCompetencies } from '../../../../Experience/ExperienceCompetencies';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProgramDetails({ experienceResource }: Props) {
  switch (experienceResource.payload.kind) {
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed': {
      const competencies = getPrimaryCompetencies(experienceResource);

      return (
        <>
          <ProgramOverviewItems
            competencies={competencies}
            experience={experienceResource.payload}
          />
          <ProgramDetailsItems experience={experienceResource.payload} />
        </>
      );
    }
    case 'beginnable':
    case 'resumable':
      return <ProgramEnrolled />;
    case 'graduated':
    case 'returnable':
      return <ProgramGraduated />;
    case 'upcoming':
      return <ProgramUpcomingDates experience={experienceResource.payload} />;
  }
}

export default observer(ExperienceProgramDetails);
