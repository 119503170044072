import { ButtonProps } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import SegmentStore from '../../../../../SegmentStore';
import { AdvanceButton } from '../../Common';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function ScheduleSessionButton({ store, ...others }: Props) {
  return when(store.sessionScheduled, {})
    .map(() => <AdvanceButton {...others} />)
    .getOrElse(() => <AdvanceButton {...others} disabled />);
}

export default observer(ScheduleSessionButton);
