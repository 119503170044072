import { observer } from 'mobx-react';
import { just, Maybe } from 'maybeasy';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../../Translations';
import { experienceDescription } from './Types';

interface Props {
  startOn: Maybe<Date>;
  endOn: Maybe<Date>;
}

function EnrolledModalSubHeader({ startOn, endOn }: Props) {
  return just({})
    .assign('startOn', startOn)
    .assign('endOn', endOn)
    .map((dates) => ({ ...dates, description: experienceDescription(dates) }))
    .map(({ startOn, endOn, description }) => {
      return (
        <Typography variant="bodyMedium">
          <T
            kind={description}
            startOnDate={<L localizeable={startOn} format="long-month-day-year" />}
            endOnDate={<L localizeable={endOn} format="long-month-day-year" />}
          />
        </Typography>
      );
    })
    .orElse(() =>
      startOn.map((startOn) => {
        return (
          <Typography variant="bodyMedium">
            <T
              kind={'This experience began <startOnDate/>. '}
              startOnDate={<L localizeable={startOn} format="long-month-day-year" />}
            />
          </Typography>
        );
      }),
    )
    .getOrElse(emptyFragment);
}

export default observer(EnrolledModalSubHeader);
