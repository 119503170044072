import { Maybe } from 'maybeasy';
import * as React from 'react';
import { ProgramDetailResource, whenActive } from '../../../ProgramStore/Types';
import OutlineModule from '../OutlineModule';
import OutlineSegments from '../OutlineSegments';
import * as style from '../style.module.css';
import ProgramOverviewTitle from './ProgramOverviewTitle';

interface Props {
  programDetailResource: Maybe<ProgramDetailResource>;
}

const OutlineTabPane: React.FC<Props> = ({ programDetailResource }) => {
  return programDetailResource
    .map((pdr) => {
      return (
        <div className={style.tabContent} data-test-outline-tab-pane={true} tabIndex={0}>
          <ProgramOverviewTitle programDetailResource={pdr} />
          <div className={style.outline}>
            <ol>
              {whenActive(pdr.payload)
                .map((pdr) => pdr.modules)
                .map<React.ReactNode>((modules) =>
                  modules.map((m) => {
                    return m.isCurrentModule ? (
                      <li key={m.id}>
                        <ol>
                          <OutlineModule programModule={m} />
                          <OutlineSegments programModule={m} />
                        </ol>
                      </li>
                    ) : (
                      <OutlineModule key={m.id} programModule={m} />
                    );
                  })
                )
                .getOrElseValue(<span />)}
            </ol>
          </div>
        </div>
      );
    })
    .getOrElseValue(<span />);
};
export default OutlineTabPane;
