import React from 'react';
import { observer } from 'mobx-react';
import { Chip, Typography } from '@execonline-inc/execonline-ui';

interface Props {
  children: React.ReactElement;
}

function ExperienceOverviewChip({ children }: Props) {
  return (
    <Chip variant="solid" radius="full" size="lg" className="justify-center bg-white px-3 py-2">
      <Typography className="text-gray-900" variant="bodyMedium" as="span" fontWeight="semibold">
        {children}
      </Typography>
    </Chip>
  );
}

export default observer(ExperienceOverviewChip);
