import { observer } from 'mobx-react';
import { useMemo } from 'react';
import WithCurrentUser from '../../../../../CurrentUser/Context/WithCurrentUser';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';
import Enrollment from './Enrollment';
import { EnrollmentReactions } from './EnrollmentReactions';
import EnrollmentStore from './EnrollmentStore';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

function Unstarted({ externalProgramResource }: Props) {
  const enrollmentStore: EnrollmentStore = useMemo(
    () => new EnrollmentStore(externalProgramResource),
    [externalProgramResource]
  );

  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <div
          className="inline-block"
          data-test-external-program-segment={externalProgramResource.payload.id}
        >
          <EnrollmentReactions
            store={enrollmentStore}
            currentUserResource={currentUserResource}
            fireImmediately
          />
          <div className="h-8 flex items-center justify-center">
            <Enrollment enrollmentStore={enrollmentStore} />
          </div>
        </div>
      )}
    />
  );
}

export default observer(Unstarted);
