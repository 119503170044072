import { Link } from '../../../../../../Resource/Types';
import {
  AllExperiences,
  DiscoveryContextState,
  LearnMore,
  None,
  ProductCollection,
} from '../../../../DiscoveryContext';

interface AllOut {
  kind: 'all';
}

interface LearningDesignOut {
  kind: 'learning-design';
  product_collection_id: number;
}

interface RecEngineOut {
  kind: 'recommendation-engine';
  product_collection_id: number;
}

interface DynamicRecommendationOut {
  kind: 'dynamic-recommendation';
  product_collection_id: number;
}

interface LearnMoreOut {
  kind: 'learn-more';
  from: AllOut | LearningDesignOut | RecEngineOut | NoContextOut | DynamicRecommendationOut;
}

interface NoContextOut {}

type DiscoveryContextStateOut =
  | AllOut
  | LearningDesignOut
  | RecEngineOut
  | LearnMoreOut
  | NoContextOut
  | DynamicRecommendationOut;

interface EnrollmentOut {
  enrollment: {
    context: DiscoveryContextStateOut;
  };
}

interface PaidEnrollmentOut {
  enrollment: {
    context: DiscoveryContextStateOut;
  };
  enrollment_href: string;
}

const allEncoder = () => ({ kind: 'all' });

const recEngineEncoder = (discoveryContextState: ProductCollection) => ({
  kind: 'recommendation-engine',
  product_collection_id: discoveryContextState.productCollectionId,
});

const learningDesignEncoder = (discoveryContextState: ProductCollection) => ({
  kind: 'learning-design',
  product_collection_id: discoveryContextState.productCollectionId,
});

const dynamicRecommendationEncoder = (discoveryContextState: ProductCollection) => ({
  kind: 'dynamic-recommendation',
  product_collection_id: discoveryContextState.productCollectionId,
});

const groupCoachingGroupsEncoder = (discoveryContextState: ProductCollection) => ({
  kind: 'group-coaching-groups-recommendation',
  product_collection_id: discoveryContextState.productCollectionId,
});

const fromEncoder = (discoveryContextState: AllExperiences | ProductCollection | None) => {
  switch (discoveryContextState.kind) {
    case 'all-experiences':
      return allEncoder();
    case 'recommendation-engine':
      return recEngineEncoder(discoveryContextState);
    case 'learning-design':
      return learningDesignEncoder(discoveryContextState);
    case 'dynamic-recommendation':
      return dynamicRecommendationEncoder(discoveryContextState);
    case 'group-coaching-groups-recommendation':
      return groupCoachingGroupsEncoder(discoveryContextState);
    case 'none':
      return discoveryContextState;
  }
};

const learnMoreEncoder = (discoveryContextState: LearnMore) => ({
  kind: 'learn-more',
  from: fromEncoder(discoveryContextState.from),
});

const discoveryContextStateEncoder = (
  discoveryContextState: DiscoveryContextState
): DiscoveryContextStateOut => {
  switch (discoveryContextState.kind) {
    case 'all-experiences':
      return allEncoder();
    case 'recommendation-engine':
      return recEngineEncoder(discoveryContextState);
    case 'learning-design':
      return learningDesignEncoder(discoveryContextState);
    case 'learn-more':
      return learnMoreEncoder(discoveryContextState);
    case 'dynamic-recommendation':
      return dynamicRecommendationEncoder(discoveryContextState);
    case 'group-coaching-groups-recommendation':
      return groupCoachingGroupsEncoder(discoveryContextState);
    case 'direct':
    case 'none':
      return discoveryContextState;
  }
};

export const enrollmentEncoder = (discoveryContextState: DiscoveryContextState): EnrollmentOut => ({
  enrollment: {
    context: discoveryContextStateEncoder(discoveryContextState),
  },
});

export const paidEnrollmentEncoder = (
  discoveryContextState: DiscoveryContextState,
  enrollLink: Link
): PaidEnrollmentOut => ({
  enrollment: {
    context: discoveryContextStateEncoder(discoveryContextState),
  },
  enrollment_href: enrollLink.href,
});
