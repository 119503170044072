import { Error } from '../../../../ErrorHandling';
import { Link, Resource } from '../../../../Resource/Types';
import ProgramResourcesStore from '../../ProgramResourceList/ProgramResourcesStore';
import { ProgramResourcesResource } from '../../ProgramResourceList/ProgramResourcesStore/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  links: ReadonlyArray<Link>;
}

interface Ready {
  kind: 'ready';
  programResourcesStores: ProgramResourcesStore[];
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (links: ReadonlyArray<Link>): Loading => ({
  kind: 'loading',
  links,
});

export const ready = (programResourcesStores: ProgramResourcesStore[]): Ready => ({
  kind: 'ready',
  programResourcesStores,
});

export type CrossProgramResourcesState = Waiting | Loading | Ready | Error;

export type CrossProgramResourcesResource = Resource<ProgramResourcesResource[]>;
