import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '@execonline-inc/execonline-ui';

const Divider: React.FC = () => (
  <Typography className="mx-2 leading-none text-neutral-900" variant="bodyMedium">
    <NotTranslated text="|" />
  </Typography>
);

export default observer(Divider);
