import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';
import ContentFaker from '../../ContentFaker';
import FlashMessage from '../../FlashMessage';
import BreadCrumb from '../BreadCrumb';
import ResourcesList from '../Shared/ResourcesList';
import * as style from '../style.module.css';
import LiveMeetingRecordingsStore from './LiveMeetingRecordingsStore';

interface Props {
  store: LiveMeetingRecordingsStore;
}

const LiveMeetingRecordingsResourcesView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'ready':
    case 'error':
      return (
        <>
          <section className={style.section}>
            <BreadCrumb
              path="/resources/live_meeting_recordings"
              title={<T kind="Live Meeting Recordings" />}
            />
            {store.notification
              .map((alert) => <FlashMessage alert={alert} />)
              .getOrElseValue(<></>)}
          </section>
          <section className={style.section}>
            {store.liveMeetingRecordingResourcesResources.map(
              (liveMeetingRecordingResourcesResource) => (
                <ResourcesList
                  key={liveMeetingRecordingResourcesResource.payload.id}
                  resources={liveMeetingRecordingResourcesResource.payload}
                />
              )
            )}
          </section>
        </>
      );
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
  }
};

export default observer(LiveMeetingRecordingsResourcesView);
