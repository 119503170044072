import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';

interface Props {
  children: React.ReactNode;
}

function CompetenciesSection({ children }: Props) {
  return (
    <div className="flex flex-col gap-2">
      <Typography className="tracking-[0.2px]" variant="caption" fontWeight="medium">
        <T kind="Develop these Competencies" />
      </Typography>
      {children}
    </div>
  );
}

export default observer(CompetenciesSection);
