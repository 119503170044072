import { observer } from 'mobx-react';
import * as React from 'react';
import SubscriptionStore from '../Store';
import { T } from '../../../Translations';
import FlashMessage from '../../FlashMessage';
import Loader from '../../Loader';
import { errorAlert } from '../../../Notifications/Types';
import * as style from '../style.module.css';

interface Props {
  store: SubscriptionStore;
}

const Details: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'waiting':
    case 'unsubscribing':
      return <Loader />;
    case 'unsubscribed':
      return (
        <>
          <h1>
            <T kind={'You have unsubscribed from...'} />
          </h1>
          <T
            kind="Contact <link1>ExecOnline Support</link1> with any questions."
            link1={(content) => (
              <a className={style.mailToLink} href="mailto:support@execonline.com">
                {content}
              </a>
            )}
          />
        </>
      );
    case 'error':
      const alert = errorAlert(store.state);
      return <FlashMessage alert={alert} />;
  }
};

export default observer(Details);
