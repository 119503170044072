import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../Person/types';
import RenderContext from '../RenderContext';
import PersonRenderer from './PersonRenderer';

interface Props {
  personStore: PersonStoreContract;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const Person: React.FC<Props> = ({ personStore, presenceStore }) => {
  return (
    <RenderContext
      render={(renderContext) => (
        <PersonRenderer
          personStore={personStore}
          position={renderContext.position}
          presenceStore={presenceStore}
        />
      )}
    />
  );
};

export default observer(Person);
