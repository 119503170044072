import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import { TeamMemberResource } from '../../TeamsStore/Types';
import TeamMemberRoutes from './TeamMemberRoutes';

interface Props {
  teamMembers: TeamMemberResource[];
  presenceMembersStore: Maybe<PresenceMembersStore>;
}

function TeamMemberList({ presenceMembersStore, teamMembers }: Props) {
  const presenceStore = useContext(GlobalPresenceContext);

  useEffect(() => {
    presenceMembersStore.do((presenceMembersStore) => {
      presenceStore.addPresenceStore(presenceMembersStore);
    });

    return () => {
      presenceMembersStore.do((presenceMembersStore) => {
        presenceStore.removePresenceStore(presenceMembersStore);
      });
    };
  });

  return <TeamMemberRoutes teamMembers={teamMembers} />;
}

export default observer(TeamMemberList);
