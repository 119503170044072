import React, { HTMLAttributes } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { just } from 'maybeasy';
import { when } from '@execonline-inc/maybe-adapter';
import { ColorPalette } from '../../../Themes/ThemeContext/Types';
import ThemeContext, { LightnessTheme } from '../../../Themes/ThemeContext';
import { IconColor, IconSize, fontSizeIconClassNameMapping } from './Types';

export interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  color?: IconColor;
  size?: IconSize;
  disabled?: boolean;
}

type IconColorConfig = {
  color: IconColor;
  disabled: boolean;
};

const getIconColorName = ({ color, disabled }: IconColorConfig): keyof ColorPalette => {
  return when<keyof ColorPalette>(disabled, 'icon-disabled-color').getOrElseValue(
    `icon-${color}-color`,
  );
};

const getIconColorValue = (theme: LightnessTheme, color: IconColor, disabled: boolean): string => {
  return just({})
    .assign('color', just(color))
    .assign('disabled', just(disabled))
    .map(getIconColorName)
    .map((color) => theme.colors[color])
    .getOrElseValue(theme.colors['icon-primary-color']);
};

const SvgIcon: React.FC<Props> = ({
  className,
  color = 'primary',
  size = 'md',
  disabled = false,
  style,
  children,
}) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={twMerge(
            clsx(
              'inline-block h-[1em] w-[1em] flex-shrink-0 select-none fill-current',
              fontSizeIconClassNameMapping[size],
              className,
            ),
          )}
          focusable={false}
          aria-hidden={true}
          viewBox="0 0 24 24"
          style={{
            color: getIconColorValue(theme, color, disabled),
            ...style,
          }}
        >
          {children}
        </svg>
      )}
    </ThemeContext.Consumer>
  );
};

export function createSvgIcon(path: React.ReactNode) {
  function Component(props: Props) {
    return <SvgIcon {...props}>{path}</SvgIcon>;
  }

  return Component;
}

export default observer(SvgIcon);
