import { observer } from 'mobx-react';
import * as React from 'react';
import RescheduleButton from './RescheduleButton';
import { T } from '../../../Translations';
import CancelButton from './CancelButton';
import { EventResource } from '../../../EventsStore/Types';
import { findLink } from '../../../LinkyLinky';
import * as style from '../style.module.css';

interface Props {
  eventResource: EventResource;
}

const FreebusyAction: React.FC<Props> = ({ eventResource }) => {
  return findLink('reschedule')(eventResource.links)
    .map((_reschedule) => (
      <div className={style.freebusyAction}>
        <div className={style.actionPending} data-test-action-pending={true}>
          <T kind="Need to make changes or cancel your session?" />
        </div>
        <RescheduleButton eventResource={eventResource} />
        <CancelButton eventResource={eventResource} />
      </div>
    ))
    .getOrElse(() => <></>);
};

export default observer(FreebusyAction);
