import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from '../../style.module.css';

interface Props {
  title: AlreadyTranslatedText;
}

const ProgramTitle: React.FC<Props> = ({ title }) => (
  <p className={style.title} data-test-program-title={title}>
    <AlreadyTranslated content={title} />
  </p>
);

export default observer(ProgramTitle);
