import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram } from '../../../../ProgramStore/Types';
import { T } from '../../../../Translations';
import * as style from '../../../ProgramSummary/style.module.css';
import ChapterProgress from './ChapterProgress';
import ModuleProgress from './ModuleProgress';

interface Props {
  programDetail: ActiveProgram;
}

const ProgressForFormat: React.FC<{
  programDetail: ActiveProgram;
}> = ({ programDetail }) => {
  switch (programDetail.programFormat) {
    case 'modules':
      return <ModuleProgress programDetail={programDetail} />;
    case 'chapters':
      return <ChapterProgress programDetail={programDetail} />;
  }
};

const Progress: React.FC<Props> = ({ programDetail }) => (
  <div className={style.progress}>
    <dl className={style.dl}>
      <dt className={style.progressLabel} data-test-progress-label={true}>
        <T kind="Progress" />
      </dt>
      <ProgressForFormat programDetail={programDetail} />
    </dl>
  </div>
);

export default observer(Progress);
