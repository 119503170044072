import { observer } from 'mobx-react';
import * as React from 'react';
import { userLanguageStore } from '../../UserLanguageStore';
import SupportedLanguageReactions from '../../UserLanguageStore/SupportedLanguageReactions';
import { Separator } from '../LanguageSelector/Types';
import LoginLanguageSelectorStates from './LoginLanguageSelectorStates';

interface Props {
  separator: Separator;
}

const LoginLanguageSelector: React.FC<Props> = ({ separator }) => (
  <>
    <LoginLanguageSelectorStates userLanguageStore={userLanguageStore} separator={separator} />
    <SupportedLanguageReactions store={userLanguageStore} fireImmediately={true} />
  </>
);

export default observer(LoginLanguageSelector);
