import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import {
  baseSegmentContextRoute,
  calendarRoute,
  dashboardUrl,
  discoveryPortalRoute,
  profileEditUrl,
  programRoute,
  resourcesRoute,
  teamsUrl,
} from '../../ClientRoutes';
import { ExitContext } from '../AEP/Common/Experience/Actions/Exitable/Types';

interface Props {
  exitContext: ExitContext;
}

const Exit: React.FC<Props> = ({ exitContext }) => {
  switch (exitContext.kind) {
    case 'exit-to-program':
      return <Redirect to={programRoute(exitContext.programId)} />;
    case 'exit-to-segment': {
      const { programId, moduleId, segmentId } = exitContext;
      return <Redirect to={baseSegmentContextRoute(programId, moduleId, segmentId)} />;
    }
    case 'exit-to-dashboard':
    case 'authenticate-with-sso':
      return <Redirect to={dashboardUrl} />;
    case 'exit-to-calendar':
      return <Redirect to={calendarRoute} />;
    case 'exit-to-teams':
      return <Redirect to={teamsUrl} />;
    case 'exit-to-resources':
      return <Redirect to={resourcesRoute} />;
    case 'exit-to-profile':
      return <Redirect to={profileEditUrl} />;
    case 'exit-to-discovery-portal':
      return <Redirect to={discoveryPortalRoute(exitContext.guid)} />;
  }
};

export default observer(Exit);
