import { pick } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { Result, ok } from 'resulty';
import { SSO } from '.';
import { MissingLinkError, findLinkByR } from '../Links';
import { AssertionBackendResource } from '../Login/Types';
import { State } from './Types';

export const username = ({ userExperience }: State): Maybe<string> => {
  switch (userExperience.kind) {
    case 'streamlined':
      return userExperience.username;
    case 'standard':
      return just(userExperience.username);
  }
};

export const opaqueUseCaseIdentifier = ({ userExperience }: State): Maybe<string> => {
  switch (userExperience.kind) {
    case 'streamlined':
      return just(userExperience.opaqueUseCaseIdentifier);
    case 'standard':
      return nothing();
  }
};

export const assertionBackendToUrls = ({
  links,
}: AssertionBackendResource): Result<MissingLinkError, SSO.URLs> =>
  ok<MissingLinkError, {}>({})
    .assign('start', findLinkByR({ rel: 'sso' }, links).map(pick('href')))
    .assign('success', findLinkByR({ rel: 'success' }, links).map(pick('href')))
    .assign('error', findLinkByR({ rel: 'error' }, links).map(pick('href')));
