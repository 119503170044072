import { observer } from 'mobx-react';
import { buttonColor, buttonStyle, ButtonProps } from './Types';
import clsx from 'clsx';

const Button = ({ onClick, children, disabled, className, color = 'primary' }: ButtonProps) => {
  return (
    <button
      className={clsx(className, buttonStyle, buttonColor[color])}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default observer(Button);
