import { observer } from 'mobx-react';
import EnrollmentStore from '../Enrollment/Store';
import { ExperiencesContext } from '../ExperienceSelection/Experiences/ExperiencesContext';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import PromotablesView from './PromotablesView';

interface Props {
  invitationUuid: string;
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  registerResource: RegisterResource;
}

function Promotables({
  invitationUuid,
  enrollmentStore,
  experiencesStore,
  registerResource,
}: Props) {
  return (
    <ExperiencesContext.Provider value={experiencesStore}>
      <PromotablesView
        invitationUuid={invitationUuid}
        enrollmentStore={enrollmentStore}
        registerResource={registerResource}
      />
    </ExperiencesContext.Provider>
  );
}

export default observer(Promotables);
