import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import * as style from '../Resources/style.module.css';
import AnnouncementEmbeddedVideoPlayer from '../Video/AnnouncementEmbeddedVideoPlayer';
import { AnnouncementVideoAssetResource } from '../Video/Types';

const AnnouncementSharedVideo: React.FC<{
  announcementSharedResource: AnnouncementVideoAssetResource;
}> = ({ announcementSharedResource }) => (
  <div className={style.resource} data-test-document-resource={true}>
    {announcementSharedResource.payload.title
      .map((title) => (
        <T kind="A new resource, {{title}}, has been shared with you." title={title} />
      ))
      .getOrElseValue(<></>)}
    <AnnouncementEmbeddedVideoPlayer
      uuid={announcementSharedResource.payload.uuid}
      embeddedVideoAssets={[announcementSharedResource]}
      width="100%"
      height={320}
      dynamicallySetAspectRatio={true}
    />
  </div>
);

export default observer(AnnouncementSharedVideo);
