import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { GroupCoachingSessionResources } from '../../../Types';
import { Resource } from '../../../../../../../Resource/Types';
import GroupCoachingSessionList from './GroupCoachingSessionList';
import GroupCoachingSeeMoreSessions from './GroupCoachingSeeMoreSessions';

interface Props {
  sessions: Maybe<Resource<GroupCoachingSessionResources>>;
}

function GroupCoachingDetailsItems({ sessions }: Props) {
  return sessions
    .map(({ payload }) => (
      <div className="flex flex-col gap-y-1.5">
        <GroupCoachingSessionList sessions={payload} />
        <GroupCoachingSeeMoreSessions sessions={payload} />
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachingDetailsItems);
