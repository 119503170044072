import { assertNever } from '@kofno/piper';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Maybe } from 'maybeasy';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import ChatReplyStore from '.';
import { AppyError, callApi } from '../Appy';
import ConversationStore from '../ConversationStore';
import { chatMessageReplyResourceDecoder } from '../ConversationStore/Decoders';
import { ChatMessageReplyResource, ChatMessageResource } from '../ConversationStore/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { warnAndNotify } from '../Honeybadger';
import LastReadMessageStore from '../LastReadMessageStore';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { ChatReplyState } from './Types';

export interface Props extends EAProps<ChatReplyStore> {
  store: ChatReplyStore;
  conversationStore: ConversationStore;
  messageResource: ChatMessageResource;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

const saveMessage = (content: EditorState) => {
  const contentToHtml = stateToHTML(content.getCurrentContent());
  return callApi(chatMessageReplyResourceDecoder, {
    message: {
      content: contentToHtml,
    },
  });
};

type ChatError = AppyError | MissingLinkError;

const handleChatError = (store: ChatReplyStore) => (error: ChatError) => {
  switch (error.kind) {
    case 'missing-link-error':
      warnAndNotify('ChatReplyReactions', `Missing link: ${error.rel}`, toJS(store.state));
      break;
    default:
      handleError(store, error);
  }
};

const handleReplyResponse =
  (lastReadMessageStore: Maybe<LastReadMessageStore>, store: ChatReplyStore) =>
  (replyResource: ChatMessageReplyResource) => {
    lastReadMessageStore.do((lastReadMessageStore) => {
      lastReadMessageStore.updateLastReadMessage(replyResource);
    });
    store.broadcasting();
  };

class ChatReplyReactions extends ErrorActionableReaction<ChatReplyStore, ChatReplyState, Props> {
  tester = () => this.props.store.state;
  effect = (state: ChatReplyState) => {
    const { store, messageResource, lastReadMessageStore } = this.props;
    switch (state.kind) {
      case 'broadcasting':
        store.reset();
        break;
      case 'ready':
        break;
      case 'updating':
        Task.succeed<ChatError, ReadonlyArray<Link>>(messageResource.links)
          .andThen(findLinkT('replies'))
          .andThen(saveMessage(state.reply))
          .fork(handleChatError(store), handleReplyResponse(lastReadMessageStore, store));
        break;
      case 'error':
      case 'hidden':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ChatReplyReactions;
