import { Error } from '../../../../../../ErrorHandling';

export type OrientationCommencementState = Waiting | Ready | Creating | Done | Error;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (): Ready => ({
  kind: 'ready',
});

export const creating = (): Creating => ({
  kind: 'creating',
});

export const done = (): Done => ({
  kind: 'done',
});

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
}

export interface Creating {
  kind: 'creating';
}

export interface Done {
  kind: 'done';
}
