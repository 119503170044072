import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { whenGt } from '@execonline-inc/numbers';
import { Typography, BellRingIcon, Chip, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../Translations';

interface Props {
  count: Maybe<number>;
}

function GroupCoachingSeatsRemaining({ count }: Props) {
  return count
    .andThen(whenGt(0))
    .map((count) => (
      <Chip color="red">
        <BellRingIcon className="mr-2" color="inherit" size="sm" />
        <Typography color="white" fontWeight="medium" variant="bodyMedium">
          <T kind="Only {{count}} seat remaining" count={count} />
        </Typography>
      </Chip>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachingSeatsRemaining);
