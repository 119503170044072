import { observer } from 'mobx-react';
import * as React from 'react';
import NoteStore from '../../../../NoteStore';
import { NotTranslated, T, translation, TranslationsContext } from '../../../../Translations';
import Context from '../../../Context';
import MenuCircleIcon from '../../../Cutesies/MenuCircleIcon';
import DropDown from '../../../Dropdown';
import { DropdownListItem } from '../../../Dropdown/Types';
import TimeStamp from '../../../TimeStamp';
import * as style from '../../style.module.css';
import NoteDetailStore from './store';

interface Props {
  noteStore: NoteStore;
  hideContext: boolean;
}

@observer
class NoteDetail extends React.Component<Props, {}> {
  maxNoteHeight: number = 150;
  contentElement: React.RefObject<HTMLDivElement> = React.createRef();
  noteDetailStore: NoteDetailStore = new NoteDetailStore();

  componentDidMount() {
    if (this.contentElement.current && !this.isSmallNote(this.contentElement.current)) {
      this.noteDetailStore.collapse();
    }
  }

  isSmallNote = (el: HTMLDivElement) => {
    if (!el) {
      return false;
    }
    return el.getBoundingClientRect().height < this.maxNoteHeight;
  };

  noteStyle = () => {
    switch (this.noteDetailStore.state.kind) {
      case 'expanded':
        return style.expanded;
      case 'collapsed':
        return style.collapsed;
      case 'unset':
        return;
    }
  };

  render() {
    const dropdownItems: DropdownListItem[] = [
      {
        kind: 'action-list-item',
        name: 'Edit',
        action: this.props.noteStore.editing,
      },
      {
        kind: 'action-list-item',
        name: 'Delete',
        action: this.props.noteStore.confirming,
      },
    ];

    return (
      <TranslationsContext.Consumer>
        {(ts) => (
          <div className={style.note} data-test-note={true}>
            <div className={this.noteStyle()}>
              <div className={style.content} ref={this.contentElement}>
                <div
                  className={style.timestamp}
                  data-test-note-timestamp={this.props.noteStore.createdAt}
                >
                  <TimeStamp timestamp={this.props.noteStore.createdAt} />
                </div>
                {!this.props.hideContext && (
                  <div
                    className={style.context}
                    data-test-note-context={this.props.noteStore.context}
                  >
                    <Context noteStore={this.props.noteStore} />
                  </div>
                )}
                <p
                  className={style.body}
                  data-test-note-body={this.props.noteStore.content.getOrElseValue('')}
                >
                  <NotTranslated text={this.props.noteStore.content.getOrElseValue('')} />
                </p>
                <div className={style.shadow}>
                  <button
                    onClick={this.noteDetailStore.expand}
                    className={style.buttonShowMore}
                    data-test-note-show-more={true}
                  >
                    <T kind="show more" />
                  </button>
                </div>
                <button
                  onClick={this.noteDetailStore.collapse}
                  className={style.buttonShowLess}
                  data-test-note-show-less={true}
                >
                  <T kind="show less" />
                </button>
              </div>
            </div>
            <div className={style.menu}>
              <DropDown
                header={
                  <div
                    className={style.actionMenuIcon}
                    aria-label={translation('Note options dropdown menu')(ts)}
                    role="button"
                  >
                    <MenuCircleIcon />
                  </div>
                }
                list={dropdownItems}
                menuAlignment="left"
                styleKind="default"
              />
            </div>
          </div>
        )}
      </TranslationsContext.Consumer>
    );
  }
}

export default NoteDetail;
