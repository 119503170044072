import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { LanguagesResource } from '../ProfileFormStore/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { TPlainTextKey } from '../Translations';
import { loading, ready, State, switching, waiting } from './Types';

class UserLanguageStore {
  @observable
  state: State = waiting();

  @action
  load = () => {
    this.state = loading();
  };

  @action
  ready = (availableLanguages: LanguagesResource) => {
    switch (this.state.kind) {
      case 'switching':
      case 'loading':
        this.state = ready(availableLanguages);
        break;
      case 'waiting':
      case 'error':
      case 'ready':
        break;
    }
  };

  @action
  switching = (code: SupportedLanguageCode) => {
    this.state = switching(code);
  };

  @action
  error = (message: TPlainTextKey) => {
    this.state = error(message);
  };

  @computed
  get availableLanguages(): Maybe<LanguagesResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.availableLanguages);
      case 'error':
      case 'switching':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @action
  switch = (code: SupportedLanguageCode): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'ready':
        this.state = switching(code);
        break;
      case 'switching':
      case 'error':
        break;
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'switching':
      case 'ready':
        return nothing();
      case 'error':
        return just(this.state).map(errorAlert);
    }
  }
}

const userLanguageStore = new UserLanguageStore();

export { userLanguageStore, UserLanguageStore };
