import { findPayloadT, ItemNotFound, PayloadNotFound } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../Appy';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { eventResourceDecoder, eventsResourceDecoder } from '../EventsStore/Decoders';
import EventStore from '../EventStore';
import { findLinkT, MissingLinkError } from '../LinkyLinky';
import { nav } from '../Navigation';
import { Link } from '../Resource/Types';
import { EventState } from './Types';

const eventsEndPoint = callApi(eventsResourceDecoder, {});
const eventEndPoint = callApi(eventResourceDecoder, {});

export interface Props extends EAProps<EventStore> {
  store: EventStore;
  eventId: Maybe<number>;
  currentUserResource: CurrentUserResource;
}

interface BadEventId {
  kind: 'bad-event-id';
}

type EventRequestError = MissingLinkError | AppyError | ItemNotFound | PayloadNotFound | BadEventId;

const handleEventRequestError = (store: EventStore) => (error: EventRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Event link is unavailable');
      break;
    case 'item-not-found':
    case 'bad-event-id':
    case 'payload-not-found':
      nav('/404');
      break;
    default:
      handleError(store, error);
  }
};

class EventReactions extends ErrorActionableReaction<EventStore, EventState, Props> {
  tester = () => this.props.store.state;

  effect = (state: EventState) => {
    const { store, currentUserResource } = this.props;

    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        this.props.eventId.cata({
          Just: (eventId) =>
            Task.succeed<EventRequestError, ReadonlyArray<Link>>(currentUserResource.links)
              .andThen(findLinkT('events'))
              .andThen(eventsEndPoint)
              .map((s) => s.payload)
              .andThen(findPayloadT(eventId))
              .map((s) => s.links)
              .andThen(findLinkT('self'))
              .andThen(eventEndPoint)
              .fork(handleEventRequestError(store), store.ready),
          Nothing: () => handleEventRequestError(store)({ kind: 'bad-event-id' }),
        });

        break;
      default:
        assertNever(state);
    }
  };
}

export default EventReactions;
