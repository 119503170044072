import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ResourceUploadedByYouIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 23 23">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M11.5 0C5.16 0 0 5.16 0 11.5S5.16 23 11.5 23 23 17.84 23 11.5 17.84 0 11.5 0Zm0 1.77a9.719 9.719 0 0 1 9.73 9.73 9.719 9.719 0 0 1-9.73 9.73 9.719 9.719 0 0 1-9.73-9.73 9.719 9.719 0 0 1 9.73-9.73Zm0 2.653a4.436 4.436 0 0 0-4.423 4.423c0 1.34.625 2.533 1.576 3.345a6.196 6.196 0 0 0-3.345 5.501h1.769A4.41 4.41 0 0 1 11.5 13.27a4.41 4.41 0 0 1 4.423 4.423h1.77a6.196 6.196 0 0 0-3.346-5.5c.95-.813 1.576-2.005 1.576-3.346A4.436 4.436 0 0 0 11.5 4.423Zm0 1.77a2.641 2.641 0 0 1 2.654 2.653A2.641 2.641 0 0 1 11.5 11.5a2.641 2.641 0 0 1-2.654-2.654A2.641 2.641 0 0 1 11.5 6.192Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourceUploadedByYouIcon);
