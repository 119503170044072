import { toJS, type Time } from '@execonline-inc/time';

export function debounce<F extends (...args: Parameters<F>) => ReturnType<F>>(
  fn: F,
  timeout: Time,
): (...args: Parameters<F>) => void {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>): void => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), toJS(timeout));
  };
}

export function throttle<F extends (...args: Parameters<F>) => ReturnType<F>>(
  fn: F,
  timeout: Time,
): (...args: Parameters<F>) => void {
  let ready: boolean = true;
  return (...args: Parameters<F>): void => {
    if (!ready) return;

    ready = false;
    fn(...args);
    setTimeout(() => {
      ready = true;
    }, toJS(timeout));
  };
}
