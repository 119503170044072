import { observer } from 'mobx-react';
import * as React from 'react';
import { Rel } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';
import { ResourcesDashboardResource } from '../Types';
import CrossProgramResourcesReactions from './CrossProgramResourcesReactions';
import CrossProgramResourcesStore from './CrossProgramResourcesStore';
import CrossProgramResourcesView from './CrossProgramResourcesView';

interface Props {
  resource: ResourcesDashboardResource;
  breadCrumbPath: string;
  breadCrumbTitle: TPlainTextKey;
  rel: Rel;
}

class CrossProgramResources extends React.Component<Props> {
  crossProgramResourcesStore = new CrossProgramResourcesStore();

  componentDidMount() {
    this.crossProgramResourcesStore.load(this.props.resource.links);
  }

  render() {
    const { breadCrumbPath, breadCrumbTitle, rel } = this.props;
    return (
      <>
        <CrossProgramResourcesView
          store={this.crossProgramResourcesStore}
          breadCrumbPath={breadCrumbPath}
          breadCrumbTitle={breadCrumbTitle}
        />
        <CrossProgramResourcesReactions store={this.crossProgramResourcesStore} rel={rel} />
      </>
    );
  }
}

export default observer(CrossProgramResources);
