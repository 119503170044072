import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import Title from '../../Title';
import { NotTranslated } from '../../Translations';
import ConferenceDetail from './ConferenceDetail';
import DialInDetails from './DialInDetails';
import MaybeConferenceEmbed from './MaybeConferenceEmbed';
import PrivacyDisclaimer from './PrivacyDisclaimer';
import Troubleshooting from './Troubleshooting';
import * as style from './style.module.css';

const ConferenceMainPanel: React.FC<{
  title: string;
  detail: Maybe<string>;
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
}> = ({ title, detail, conferenceRoomResource, conferenceRosterStore }) => (
  <>
    <Title title={title} />
    <div className={style.conferenceMain}>
      <div className={style.details}>
        <h1 className={style.title} data-test-conference-title={true}>
          <NotTranslated text={title} />
        </h1>
        <ConferenceDetail title={title} detail={detail} />
        <DialInDetails conferenceRoomResource={conferenceRoomResource} />
        <Troubleshooting />
      </div>
      <MaybeConferenceEmbed
        conferenceRoomResource={conferenceRoomResource}
        conferenceRosterStore={conferenceRosterStore}
      />
      <PrivacyDisclaimer conferenceRoomResource={conferenceRoomResource} />
    </div>
  </>
);

export default observer(ConferenceMainPanel);
