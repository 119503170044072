import { findPayloadByUuid } from '@execonline-inc/collections';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import SegmentStore from '../../SegmentStore';
import { FormFieldAssetResource } from './Types';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { TabularOption } from '../ContentEmbed/Types';
import TabularRadioQuestion from './FormField/TabularRadioQuestion';

interface Props {
  uuid: string;
  embeddedFormFieldAssets: FormFieldAssetResource[];
  segmentStore: Maybe<SegmentStore>;
  options: TabularOption[];
}

function EmbeddedTabularFormFieldAsset({
  uuid,
  embeddedFormFieldAssets,
  segmentStore,
  options,
}: Props) {
  return just({})
    .assign('formFieldAssetResource', findPayloadByUuid(uuid, embeddedFormFieldAssets))
    .assign('segmentStore', segmentStore)
    .map(({ formFieldAssetResource, segmentStore }) => {
      const fieldDescription = formFieldAssetResource.payload.fieldDescription;
      switch (fieldDescription.kind) {
        case 'multiple-selection':
          return (
            <TabularRadioQuestion
              uuid={formFieldAssetResource.payload.uuid}
              selectField={fieldDescription}
              segmentStore={segmentStore}
              options={options}
            />
          );
        case 'string':
          return emptyFragment();
      }
    })
    .getOrElse(emptyFragment);
}

export default observer(EmbeddedTabularFormFieldAsset);
