import { CurrentUserResource } from '../../../CurrentUser/Types';

import { mapMaybe } from '@execonline-inc/collections';
import { always } from '@kofno/piper';
import { just, Maybe } from 'maybeasy';
import { findLink } from '../../../LinkyLinky';
import { Link, Rel } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';

export interface NavigationBarMenuItemProps {
  user: CurrentUserResource;
}

export interface MenuItemContent {
  content: TPlainTextKey;
  to: string;
  rel: Rel;
  exact: boolean;
}

type ToMenuItem = (links: ReadonlyArray<Link>) => Maybe<MenuItemContent>;

const toMenuItem =
  (content: MenuItemContent) =>
  (links: ReadonlyArray<Link>): Maybe<MenuItemContent> =>
    just(links).andThen(findLink(content.rel)).map(always(content));

const toDashboardMenuItem = toMenuItem({
  content: 'Dashboard',
  to: '/',
  rel: 'dashboard',
  exact: true,
});

const toCalendarMenuItem = toMenuItem({
  content: 'Calendar',
  to: '/calendar',
  rel: 'calendar',
  exact: false,
});

const toCommunitiesMenuItem = toMenuItem({
  content: 'Teams',
  to: '/communities',
  rel: 'communities',
  exact: false,
});

const toTeamMenuItem = toMenuItem({
  content: 'Team',
  to: '/team',
  rel: 'team',
  exact: false,
});

const toProgramMenuItem = toMenuItem({
  content: 'Team',
  to: '/program_chat',
  rel: 'program',
  exact: false,
});

const toResourcesMenuItem = toMenuItem({
  content: 'Resources',
  to: '/resources',
  rel: 'resources',
  exact: false,
});

const toCurrentEventsMenuItem = toMenuItem({
  content: 'Current Events',
  to: '/current_events',
  rel: 'current-events',
  exact: false,
});

const linksToMenuItems: ToMenuItem[] = [
  toDashboardMenuItem,
  toCalendarMenuItem,
  (links) =>
    toTeamMenuItem(links)
      .orElse(() => toProgramMenuItem(links))
      .orElse(() => toCommunitiesMenuItem(links)),
  toResourcesMenuItem,
  toCurrentEventsMenuItem,
];

const menuItems = (links: ReadonlyArray<Link>): ReadonlyArray<MenuItemContent> =>
  mapMaybe<ToMenuItem, MenuItemContent>((fn) => fn(links))(linksToMenuItems);

export { menuItems, toCalendarMenuItem };
