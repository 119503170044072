import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const AttachmentSlidesIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 26 26">
      <path
        fill={defaultTheme.variables['info-bg-danger']}
        d="m365.3 256-22.6 22.6-192 192-22.7 22.7L82.7 448l22.6-22.6L274.7 256 105.4 86.6 82.7 64 128 18.7l22.6 22.6 192 192 22.7 22.7zM0 0v26h26V0H0Zm2.167 2.167h21.666v2.166H2.167V2.167Zm0 4.333h21.666v13H2.167v-13Zm2.166 2.167v3.25h7.584v-3.25H4.333Zm9.75 0v3.25h7.584v-3.25h-7.584Zm-9.75 5.416v3.25h17.334v-3.25H4.333Zm-2.166 7.584h21.666v2.166H2.167v-2.166Z"
      />
    </SVGIcon>
  );
};

export default observer(AttachmentSlidesIcon);
