import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../../Conference/Types';
import { T } from '../../../Translations';
import Modal from '../../Modal';
import ModalStore from '../../Modal/Store';
import ProviderDialInDetails from './ProviderDialInDetails';

interface Props {
  store: ModalStore;
  conferenceRoomResource: LocalConferenceRoomResource;
}

const DetailsModal: React.FC<Props> = ({ store, conferenceRoomResource }) => {
  switch (store.state.kind) {
    case 'open':
      return (
        <Modal close={store.close} title={<T kind="Dial-in instructions for this meeting" />}>
          <ProviderDialInDetails conferenceRoomResource={conferenceRoomResource} />
        </Modal>
      );
    case 'closed':
      return <></>;
  }
};
export default observer(DetailsModal);
