import { observer } from 'mobx-react';
import * as React from 'react';
import CoachProfileContent from '../../ActiveProgramOverview/ActiveCoachingProductOverview/Orientation/CompletedOrientation/CoachViewProfileModal/CoachProfileContent';
import { DisplayAssignedCoachNode } from '../../ContentEmbed/Types';

interface Props {
  node: DisplayAssignedCoachNode;
}

const DisplayAssignedCoachEmbeddable: React.FC<Props> = ({ node }) => (
  <CoachProfileContent coachProfile={node.coach} />
);

export default observer(DisplayAssignedCoachEmbeddable);
