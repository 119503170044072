import { noop } from '@kofno/piper';
import { Task } from 'taskarian';
import { errorMessage } from '../ExceptionHandling';

export const windowScrollY = (): Task<string, number> =>
  new Task<string, number>((reject, resolve) => {
    try {
      // For IE11 compatibility, use `window.pageYOffset` instead of `window.scrollY`.
      const position = window.pageYOffset;
      resolve(position);
    } catch (e) {
      reject(errorMessage(e));
    }
    return noop;
  });

//Add to this as needed with accepted event types https://developer.mozilla.org/en-US/docs/Web/Events
type EventType = 'resize' | 'scroll';

export const windowAddListener = (eventType: EventType, callback: () => void): Task<string, void> =>
  new Task<string, void>((reject, resolve) => {
    try {
      window.addEventListener(eventType, callback);
      resolve();
    } catch (e) {
      reject(errorMessage(e));
    }
    return noop;
  });

export const windowRemoveListener = (
  eventType: EventType,
  callback: () => void,
): Task<string, void> =>
  new Task<string, void>((reject, resolve) => {
    try {
      window.removeEventListener(eventType, callback);
      resolve();
    } catch (e) {
      reject(errorMessage(e));
    }
    return noop;
  });

export const windowScrollTopOffset = (offset: number): Task<string, void> =>
  new Task<string, void>((reject, resolve) => {
    try {
      window.scrollTo({ top: offset });
      resolve();
    } catch (e) {
      reject(errorMessage(e));
    }
    return noop;
  });
