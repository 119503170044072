import { Maybe } from 'maybeasy';
import { OrganizationResource } from '../Organization/Types';
import { EventLayer } from '../components/Tooling/GoogleTagManagerTooling/Types';

export const missingValue = (name: string): MissingValue => ({ kind: 'missing-value', name });

export interface MissingValue {
  kind: 'missing-value';
  name: string;
}

export interface AnalyticsOperationError {
  kind: 'analytics-operation-error';
  error: unknown;
}

export type AnalyticsError = MissingValue | AnalyticsOperationError;

export interface UserDataLayer extends EventLayer {
  salesforceContactId: string;
  platformUserId: string;
  platformOrgId: string;
  platformOrgName: string;
  impersonatingUserId: string;
  pageTitle: string;
}

export interface ContingentResources {
  organizationResource: Maybe<OrganizationResource>;
}
