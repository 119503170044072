import { observer } from 'mobx-react';
import * as React from 'react';
import AttachmentUploadStore from '../../../AttachmentUploadStore';
import AttachmentUploadReactions from '../../../AttachmentUploadStore/AttachmentUploadReactions';
import NotificationSource from '../../../NotificationSource';
import { Link } from '../../../Resource/Types';
import SegmentStore from '../../../SegmentStore';
import { AssignmentDueSegment as segment } from '../../../SegmentStore/Types';
import { T } from '../../../Translations';
import AttachmentUploader from '../AttachmentUploads/AttachmentUploader';
import UploadedAttachments from '../AttachmentUploads/UploadedAttachments';
import UploadsToSubmit from '../AttachmentUploads/UploadsToSubmit';
import AssignmentContent from './AssignmentContent';
import AssignmentSubmissionButton from './AssignmentSubmissionButton';
import * as style from './style.module.css';

interface Props {
  segmentStore: SegmentStore;
  segment: segment;
  links: ReadonlyArray<Link>;
}

@observer
class AssignmentDueSegment extends React.Component<Props, {}> {
  private attachmentUploadStore: AttachmentUploadStore = new AttachmentUploadStore();

  componentDidMount() {
    this.attachmentUploadStore.loading();
  }

  render() {
    const { segmentStore, segment, links } = this.props;

    return (
      <div data-test-assignment-due-segment={segment.id}>
        <AssignmentContent segment={segment} segmentStore={segmentStore} links={links} />
        <div className={style.assignment}>
          <section className={style.section}>
            <h3 data-test-upload-title="Upload Your Assignment">
              <T kind="Upload Your Assignment" />
            </h3>
            <AttachmentUploader store={this.attachmentUploadStore} />
          </section>
          <section className={style.section}>
            <h3 data-test-submit-title="Files to be Submitted">
              <T kind="Files to be Submitted" />
            </h3>

            <ul className={style.items}>
              <UploadsToSubmit store={this.attachmentUploadStore} />
            </ul>
          </section>
          <section className={style.section}>
            <h3 data-test-submitted-assignment-title="Submitted Assignment">
              <T kind="Submitted Assignment" />
            </h3>
            <ul className={style.items}>
              <UploadedAttachments segment={segment} />
            </ul>
          </section>
        </div>
        <div className={style.submit}>
          <AssignmentSubmissionButton
            segmentStore={segmentStore}
            attachmentUploadStore={this.attachmentUploadStore}
          />
        </div>
        <AttachmentUploadReactions
          store={this.attachmentUploadStore}
          segmentStore={segmentStore}
          segment={segment}
        />
        <div className={style.error}>
          <NotificationSource store={this.attachmentUploadStore} />
        </div>
      </div>
    );
  }
}

export default AssignmentDueSegment;
