import { always } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { handleEnterOrSpaceKeyPress } from '../../Accessibility/KeyboardEvents';
import { whenInternetExplorer } from '../../UserAgent';
import { AnnouncementResource } from '../Decoders/Types';
import * as style from '../style.module.css';
import AnnouncementReactions from './AnnouncementReactions';
import AnnouncementStore from './AnnouncementStore';
import Details from './Details';
import Icon from './Icon';
import ViewAnnouncementButton from './ViewAnnouncementButton';

const styleOverride = () =>
  whenInternetExplorer()
    .map<React.CSSProperties>(always({ display: 'block' }))
    .getOrElseValue({});

interface Props {
  announcement: AnnouncementResource;
}

class Announcement extends React.Component<Props> {
  announcementStore: AnnouncementStore = new AnnouncementStore();
  announcementRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    this.announcementStore.initialize();
  }

  render() {
    const state = this.announcementStore.state;
    const { announcement } = this.props;
    switch (state.kind) {
      case 'waiting':
      case 'initializing':
      case 'non-expandable':
        return (
          <div
            className={`${style.announcement} ${style.collapsed}`}
            style={styleOverride()}
            ref={this.announcementRef}
          >
            <Icon announcement={announcement.payload} />
            <Details announcement={announcement} />
            <AnnouncementReactions
              store={this.announcementStore}
              announcementRef={this.announcementRef}
            />
          </div>
        );
      case 'collapse-start':
      case 'collapsed':
      case 'expandable':
        return (
          <div
            className={`${style.announcement} ${style.expandable} ${style.collapsed}`}
            style={styleOverride()}
            onClick={() => this.announcementStore.expand(this.announcementRef)}
            ref={this.announcementRef}
            tabIndex={0}
            onKeyPress={handleEnterOrSpaceKeyPress(() =>
              this.announcementStore.expand(this.announcementRef)
            )}
          >
            <Icon announcement={announcement.payload} />
            <Details announcement={announcement} />
            <ViewAnnouncementButton />
            <AnnouncementReactions
              store={this.announcementStore}
              announcementRef={this.announcementRef}
            />
          </div>
        );
      case 'expand-start':
      case 'expanded':
        return (
          <div
            className={`${style.announcement} ${style.expandable} ${style.expanded}`}
            style={styleOverride()}
            ref={this.announcementRef}
          >
            <Icon announcement={announcement.payload} />
            <Details announcement={announcement} />
            <ViewAnnouncementButton />
            <AnnouncementReactions
              store={this.announcementStore}
              announcementRef={this.announcementRef}
            />
          </div>
        );
    }
  }
}

export default observer(Announcement);
