import { Header, RequestBuilder, header } from 'ajaxian';
import { Maybe } from 'maybeasy';
import { SessionData } from './Types';

const authHeader = (token: string) => header('Authorization', `Bearer ${token}`);

export const appendAuthHeader =
  (token: Maybe<string>) =>
  (headers: Header[]): Header[] =>
    token.map((t) => [...headers, authHeader(t)]).getOrElseValue(headers);

export const withReplacedAuthHeader =
  (sessionData: SessionData) =>
  <T>(b: RequestBuilder<T>): RequestBuilder<T> =>
    new RequestBuilder({
      url: b.url,
      method: b.method,
      data: b.data,
      timeout: b.timeout,
      withCredentials: b.withCredentials,
      decoder: b.decoder,
      headers: [
        ...b.headers.filter((h) => h.field.toLowerCase() !== 'authorization'),
        authHeader(sessionData.sessionToken),
      ],
    });
