import * as React from 'react';
import { ExpandableItem } from '../../../../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../../../../ProgramStore/Types';
import ToggleOpenIcon from '../../../../../Cutesies/ToggleOpenIcon';
import * as style from '../../style.module.css';

interface Props {
  expandableModule: ExpandableItem<ActiveProgramModule>;
}

const Toggle: React.FC<Props> = ({ expandableModule }) => {
  return (
    <div
      className={`${style.revealToggle} ${expandableModule.open ? style.revealToggleOpen : ''}`}
      data-test-toggle-icon={true}
    >
      <ToggleOpenIcon />
    </div>
  );
};

export default Toggle;
