import { MissingVarError } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { MissingValue, UserDataLayer } from '../../../Analytics/Types';
import { RegistrationStatus, RegistrationType } from '../../../ProgramsStore/Types';
import { AlreadyTranslatedText } from '../../../Translations';

export interface EventLayer {
  event: string;
}

interface AnalyticsEventLayer {
  hitType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

export type DataLayer =
  | UserDataLayer
  | CourseLayer
  | EventLayer
  | AnalyticsEventLayer
  | DiscoveryPortalDataLayer
  | ProgramDetailDataLayer
  | ProgramDataLayer
  | ProgramDetailAndDiscoveryPortalDataLayer;

interface DiscoveryPortalDataLayer extends EventLayer {
  learningCollectionId: number;
  learningCollectionName: string;
  productLicenseId: number;
  productLicenseType: string;
  pageTitle: string;
}

export interface ProgramDetailDataLayer extends EventLayer {
  programFamilyId: number;
  programFamilyName: string;
  schoolPartnerId: number;
  schoolPartnerName: string;
  duration: Maybe<number>;
}

export interface ProgramDetailAndDiscoveryPortalDataLayer extends EventLayer {
  programFamilyId: number;
  programFamilyName: string;
  schoolPartnerId: number;
  schoolPartnerName: string;
  duration: Maybe<number>;
  learningCollectionId: number;
  learningCollectionName: string;
  productLicenseId: number;
  productLicenseType: string;
}

interface ProgramDataLayer extends EventLayer {
  studentId: number;
  studentStatus: RegistrationStatus;
  registrationType: RegistrationType;
  programId: number;
  programName: string;
  moduleId: number;
  segmentId: number;
  segmentName: string;
  learningCollectionId: Maybe<number>;
  learningCollectionName: Maybe<AlreadyTranslatedText>;
  productLicenseId: Maybe<number>;
  productLicenseType: Maybe<AlreadyTranslatedText>;
  programFamilyId: Maybe<number>;
  programFamilyName: Maybe<AlreadyTranslatedText>;
  schoolPartnerId: Maybe<number>;
  schoolPartnerName: Maybe<AlreadyTranslatedText>;
  enrollmentOpenDate: Maybe<Date>;
  duration: Maybe<number>;
  percentComplete: number;
  startsOn: Maybe<Date>;
  endsOn: Maybe<Date>;
  programTitle: string;
  programCreatedOn: Maybe<Date>;
}

interface CourseLayer {
  course: string;
  userType: string;
}

export interface GoogleTagManager {
  dataLayerFn: DataLayerFn;
}

export interface AnalyticsEvent {
  hitType: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}

interface DataLayerFn {
  set: (key: string, value: string) => void;
}

export interface GtmDataLayer {
  dataLayer: DataLayer[];
}

interface MissingGtmAPI {
  kind: 'missing-gtm-api';
}

export type IdentifyToGtmErrors = MissingGtmAPI | MissingVarError | MissingValue;

export type GtmInitializationError = MissingVarError | GtmLoadError;

export interface GtmLoadError {
  kind: 'gtm-load-error';
  key: string;
}

export const gtmLoadError = (key: string): GtmLoadError => ({
  kind: 'gtm-load-error',
  key,
});

export const missingGtmAPI = (): MissingGtmAPI => ({ kind: 'missing-gtm-api' });

export const handleProgramUpdateError =
  (title: string) =>
  (error: IdentifyToGtmErrors): void => {
    switch (error.kind) {
      case 'missing-gtm-api':
      case 'missing-var-error':
        warn("Google tag manager isn't configured properly.", title);
        break;
      case 'missing-value':
        warn(`Google tag manager cannot initialize without value for ${error.name}`);
        break;
      default:
        assertNever(error);
    }
  };
