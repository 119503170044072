import { action, observable } from 'mobx';

export interface ExpandableItem<T> {
  open: boolean;
  item: T;
}

class ExpandableItemStore<T> {
  @observable expandableItems: ExpandableItem<T>[] = [];

  @action
  setExpandableItems(expandableItems: ExpandableItem<T>[]) {
    this.expandableItems = expandableItems;
  }

  @action
  toggle(itemToToggle: ExpandableItem<T>) {
    this.expandableItems = this.expandableItems.map((expandableItem: ExpandableItem<T>) => {
      return expandableItem === itemToToggle
        ? { open: !expandableItem.open, item: expandableItem.item }
        : expandableItem;
    });
  }
}

export default ExpandableItemStore;
