import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../../../Fetch';
import EnrollmentStore from '../Enrollment/Store';
import { RegisterResource } from '../RegisterResourceStore/Types';
import { promotableGroupsResourceDecoder } from './Decoders';
import { SkeletonPromotableExperiences } from './PromotableExperiences';
import { PromotableGroup } from './PromotableGroup';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  registerResource: RegisterResource;
}

function PromotablesView({ invitationUuid, enrollmentStore, registerResource }: Props) {
  const link = useFindLinkByR({ rel: 'promotable-groups', method: 'get' }, registerResource.links);
  const state = useFetch(link, promotableGroupsResourceDecoder);

  switch (state.kind) {
    case 'loading':
      return <SkeletonPromotableExperiences />;
    case 'errored':
      warn('Failed to fetch promotable groups resource.', toJS(state.error));
      return emptyFragment();
    case 'ready':
      return (
        <>
          {state.data.payload.map((group, index) => (
            <PromotableGroup
              key={index}
              data={group}
              invitationUuid={invitationUuid}
              enrollmentStore={enrollmentStore}
            />
          ))}
        </>
      );
  }
}

export default observer(PromotablesView);
