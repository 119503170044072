import { parseIntM } from '@execonline-inc/numbers';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import TeamStore from '../../TeamStore';
import TeamReactions from '../../TeamStore/TeamReactions';
import I18nTitle from '../../Title/I18nTitle';
import AutoLaunchable from '../AutoLaunchable';
import Footer from '../Footer';
import * as platformStyle from '../Platform/style.module.css';
import SkipNavLink from '../SkipNavLink';
import TeamView from './TeamView';
import NavigationBar from '../Platform/NavigationBar';

interface Params {
  teamsId: string;
}
interface Props {
  match: { params: Params };
}

class Team extends React.Component<Props, {}> {
  teamStore: TeamStore;
  teamId: Maybe<number>;

  constructor(props: Props) {
    super(props);
    this.teamStore = new TeamStore();
    this.teamId = parseIntM(this.props.match.params.teamsId);
  }

  componentDidMount() {
    this.teamStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <div className={platformStyle.teamContainer}>
              <I18nTitle title="Teams" />
              <SkipNavLink />
              <NavigationBar />
            </div>
            <div className="flex">
              <div className={platformStyle.teamContainer}>
                <Notifications />
                <AutoLaunchable />
                <Main id="main">
                  <TeamView teamId={this.teamId} teamStore={this.teamStore} />
                  <NotificationSource store={this.teamStore} />
                  <TeamReactions
                    store={this.teamStore}
                    teamId={this.teamId}
                    currentUserResource={currentUserResource}
                  />
                </Main>
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    );
  }
}

export default observer(Team);
