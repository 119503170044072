import { Breadcrumbs, cn, emptyFragment, useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLink } from '../../../../../../LinkyLinky';
import { Experience } from '../../../../Common/Experience/Types';
import { RegisterInfo } from '../../../../DiscoveryPortal/RegisterResourceStore/Types';
import ExperienceCrump from './ExperienceCrump';
import ExploreCrumb from './ExploreCrump';
import { learnMoreBreadcrumbsColor } from './Types';

interface Props {
  invitationUuid: string;
  experience: Experience;
  registerInfo: RegisterInfo;
}

function HeroBreadcrumbs({ invitationUuid, experience, registerInfo }: Props) {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
      return (
        <Breadcrumbs
          aria-label="Breadcrumb"
          className={cn('mb-9', learnMoreBreadcrumbsColor(experience))}
        >
          {findLink('discovery-portal', registerInfo.sharedInvitation.links)
            .map(() => (
              <ExploreCrumb
                invitationUuid={invitationUuid}
                kind={experience.registrationInvitationKind}
                name={registerInfo.sharedInvitation.payload.name}
              />
            ))
            .getOrElse(emptyFragment)}
          <ExperienceCrump title={experience.title} />
        </Breadcrumbs>
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return emptyFragment();
  }
}

export default observer(HeroBreadcrumbs);
