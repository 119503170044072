import { ChevronLeftIcon, ChevronRightIcon, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import CarouselStore from '../CarouselStore';
import { getNavigationButtonStatus, visibilityNavigationButton } from './Functions';
import NavigationButton from './NavigationButton';
import NavigationDots from './NavigationDots';
import { CarouselDotProps } from './Types';

interface Props<T> {
  className?: string;
  carouselStore: CarouselStore<T>;
  dotProps?: CarouselDotProps;
}

function CarouselWithButtons<T>({
  className,
  carouselStore,
  children,
  dotProps = {},
}: PropsWithChildren<Props<T>>) {
  const { translate } = useTranslationsContext();

  const visibility = visibilityNavigationButton(
    carouselStore.perPageCount,
    carouselStore.totalCount,
  );

  return (
    <div className={cn('w-ful flex overflow-visible xl:-mx-16', className)}>
      <NavigationButton
        aria-label={translate('Previous page')}
        icon={ChevronLeftIcon}
        visibility={visibility}
        disabled={getNavigationButtonStatus(carouselStore.precedingPages)}
        onClick={carouselStore.retreat}
      />
      <div className="relative w-full flex-col justify-between">
        {children}
        <NavigationDots
          className={dotProps.className}
          dots={carouselStore.navDots}
          onDotClick={carouselStore.gotoPage}
        />
      </div>
      <NavigationButton
        aria-label={translate('Next page')}
        icon={ChevronRightIcon}
        visibility={visibility}
        disabled={getNavigationButtonStatus(carouselStore.succeedingPages)}
        onClick={carouselStore.advance}
      />
    </div>
  );
}

export default observer(CarouselWithButtons);
