import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../Appy';
import { currentUserStore } from '../../CurrentUser/Store';
import { CurrentUserResource } from '../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import TermsAndConditionsStore from '../../TermsAndConditionsStore';
import { termsResourceDecoder } from '../../TermsAndConditionsStore/Decoders';
import { State } from '../../TermsAndConditionsStore/Types';

type TermsError = MissingLinkError | AppyError;

export interface Props extends EAProps<TermsAndConditionsStore> {
  store: TermsAndConditionsStore;
  currentUserResource: CurrentUserResource;
}

const getTermsAndConditions = callApi(termsResourceDecoder, {});
const updateTerms = callApi(succeed({}), {});

const handleTermsError = (store: TermsAndConditionsStore) => (error: TermsError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Cannot find terms and conditions link');
      break;
    default:
      handleError(store, error);
  }
};

class TermsAndConditionsReactions extends ErrorActionableReaction<
  TermsAndConditionsStore,
  State,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        Task.succeed<TermsError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('terms_and_conditions'))
          .andThen(getTermsAndConditions)
          .fork(handleTermsError(store), store.ready);
        break;
      case 'updating':
        Task.succeed<TermsError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('update'))
          .andThen(updateTerms)
          .fork(handleTermsError(store), store.updated);
        break;
      case 'updated':
        currentUserStore.refreshing();
        break;
      case 'error':
      case 'ready':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default TermsAndConditionsReactions;
