import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../../Translations';
import { Experience } from '../../../../Common/Experience/Types';
import GraduatedCardHeader from './GraduatedCardHeader';
import ReturnableCardHeader from './ReturnableCardHeader';

interface Props {
  experience: Experience;
}

function EnrollmentCardHeader({ experience }: Props) {
  switch (experience.kind) {
    case 'beginnable':
      return (
        <Typography variant="h5" color="white">
          <T kind="Begin" />
        </Typography>
      );
    case 'resumable':
      return (
        <Typography variant="h5" color="white">
          <T kind="Resume" />
        </Typography>
      );
    case 'returnable':
      return <ReturnableCardHeader experience={experience} />;
    case 'graduated':
      return <GraduatedCardHeader experience={experience} />;
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(EnrollmentCardHeader);
