import { observer } from 'mobx-react';
import { ComponentProps } from 'react';
import WhenLink from '.';
import Anchor from '../LinkyLinky/Anchor';

type WhenLinkAnchorProps = ComponentProps<typeof WhenLink> &
  Omit<ComponentProps<typeof Anchor>, 'href'>;

function WhenLinkAnchor({ rel, links, ...anchorProps }: WhenLinkAnchorProps) {
  return (
    <WhenLink rel={rel} links={links}>
      {({ href }) => <Anchor href={href} {...anchorProps} />}
    </WhenLink>
  );
}

export default observer(WhenLinkAnchor);
