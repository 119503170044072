import { findPayload } from '@execonline-inc/collections';
import { warn } from '@execonline-inc/logging';
import { parseIntM } from '@execonline-inc/numbers';
import { useConst } from '@execonline-inc/react-hooks.private';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import ProgramResourcesStore from '../ProgramResourceList/ProgramResourcesStore';
import ProgramResourcesReactions from '../ProgramResourceList/ProgramResourcesStore/ProgramResourcesReactions';
import { ProgramResource } from '../Types';
import ByProgramsView from './ByProgramsView';

interface Params {
  programId: string;
}

interface Props {
  match: { params: Params };
  programResources: readonly ProgramResource[];
}

function ProgramResourceList({ match, programResources }: Props) {
  const programResourcesStore: ProgramResourcesStore = useConst(() => new ProgramResourcesStore());
  const programId: Maybe<number> = useConst(() => parseIntM(match.params.programId));

  useEffect(() => {
    programId
      .andThen((id) => findPayload(id, programResources))
      .cata({
        Just: programResourcesStore.load,
        Nothing: () => warn(`Missing program resource for ID: ${match.params.programId}`),
      });
  }, []);

  return (
    <>
      <ByProgramsView store={programResourcesStore} />
      <ProgramResourcesReactions store={programResourcesStore} />
    </>
  );
}

export default observer(ProgramResourceList);
