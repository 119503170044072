import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Task } from 'taskarian';
import EngagementStore from '.';
import { AppyError, callApi } from '../Appy';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { Engagement, EngagementState } from './Types';

export interface Props extends EAProps<EngagementStore> {
  store: EngagementStore;
  currentUserResource: CurrentUserResource;
}

export type EngagementCreationError = MissingLinkError | AppyError;

interface EngagementOutput {
  activity_type: string;
  engageable_type: string;
  engageable_id: string;
  program_id: string;
}

export const engagementEncoder = (engagement: Engagement): EngagementOutput => {
  return {
    activity_type: engagement.activityType,
    engageable_type: engagement.engageableType,
    engageable_id: engagement.engageableId.toString(),
    program_id: engagement.programId.map((programId) => programId.toString()).getOrElseValue(''),
  };
};

const createEngagement = (engagement: Engagement) =>
  callApi(succeed({}), engagementEncoder(engagement));

const handleEngagementCreationError =
  (store: EngagementStore) => (error: EngagementCreationError) => {
    warn('EngagementCreationError', `Unable to create engagement: ${error.kind}`, error);
    store.error('Failed to create engagement');
  };

class EngagementReactions extends ErrorActionableReaction<EngagementStore, EngagementState, Props> {
  tester = () => this.props.store.engagementState;

  effect = (state: EngagementState) => {
    const { store, currentUserResource } = this.props;
    switch (state.kind) {
      case 'ready':
        break;
      case 'creating':
        Task.succeed<EngagementCreationError, ReadonlyArray<Link>>(currentUserResource.links)
          .andThen(findLinkT('create-engagement'))
          .andThen(createEngagement(state.engagement))
          .fork(handleEngagementCreationError(store), store.ready);
        break;
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default EngagementReactions;
