import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import clsx from 'clsx';
import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import Loader from '../Loader';
import * as dashboardStyle from './style.module.css';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const PresentParticipantsCounter: React.FC<Props> = ({ conferenceRosterStore }) => {
  switch (conferenceRosterStore.conferenceRosterState.kind) {
    case 'waiting':
    case 'loading':
    case 'polling':
    case 'updating':
      return (
        <div className={clsx(dashboardStyle.counter, 'relative')}>
          <Loader type="spin" height={12} width={12} text={nothing()} />
        </div>
      );
    case 'error':
      warn(conferenceRosterStore.conferenceRosterState.message);
      return <></>;
    case 'roster-loaded':
    case 'stopped':
    case 'polling-complete':
    case 'ready':
      return conferenceRosterStore.presentParticipants
        .map((participants) => (
          <div className={dashboardStyle.counter}>
            <p>{participants.length}</p>
          </div>
        ))
        .getOrElse(emptyFragment);
  }
};

export default observer(PresentParticipantsCounter);
