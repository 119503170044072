import Decoder, { dateISO, field, nullable, number, string, succeed } from 'jsonous';
import { just } from 'maybeasy';
import { SessionData } from './Types';

export const sessionDataResponseDecoder: Decoder<SessionData> = succeed({})
  .assign('userId', field('user_id', number).map(just))
  .assign('sessionToken', field('session_token', string))
  .assign('expiresAt', field('expires_at', dateISO).map(just))
  .assign('impersonatingBy', field('impersonating_by', nullable(string)))
  .assign('impersonatingUserId', field('impersonating_user_id', nullable(number)));
