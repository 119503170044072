import { assertNever } from '@kofno/piper';
import { action, computed, observable } from 'mobx';
import { CurrentUserResource } from '../CurrentUser/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { Errored, State } from './States';

class PreferredLanguageSelectionStore {
  @observable state: State = { kind: 'waiting' };

  @action reset = (): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'detecting-preferred-language':
      case 'requires-user-selection':
      case 'updating-preference':
      case 'preference-identified':
      case 'changing-browser-language':
      case 'errored':
        this.state = { kind: 'waiting' };
        break;
      default:
        assertNever(this.state);
    }
  };

  @action detectingPreferredLanguage = (currentUserResource: CurrentUserResource): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = { kind: 'detecting-preferred-language', currentUserResource };
        break;
      case 'detecting-preferred-language':
      case 'requires-user-selection':
      case 'updating-preference':
      case 'preference-identified':
      case 'changing-browser-language':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action requiresUserSelection = (): void => {
    switch (this.state.kind) {
      case 'detecting-preferred-language':
      case 'errored':
        this.state = { kind: 'requires-user-selection' };
        break;
      case 'waiting':
      case 'requires-user-selection':
      case 'updating-preference':
      case 'preference-identified':
      case 'changing-browser-language':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action updatingPreference = (
    language: SupportedLanguageCode,
    currentUserResource: CurrentUserResource
  ): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'detecting-preferred-language':
      case 'requires-user-selection':
      case 'preference-identified':
      case 'errored':
        this.state = { kind: 'updating-preference', language, currentUserResource };
        break;
      case 'updating-preference':
      case 'changing-browser-language':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action preferenceIdentified = (language: SupportedLanguageCode): void => {
    switch (this.state.kind) {
      case 'updating-preference':
      case 'detecting-preferred-language':
        this.state = { kind: 'preference-identified', language };
        break;
      case 'waiting':
      case 'requires-user-selection':
      case 'preference-identified':
      case 'changing-browser-language':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action changingBrowserLanguage = (language: SupportedLanguageCode): void => {
    switch (this.state.kind) {
      case 'preference-identified':
        this.state = { kind: 'changing-browser-language', language };
        break;
      case 'waiting':
      case 'detecting-preferred-language':
      case 'requires-user-selection':
      case 'updating-preference':
      case 'changing-browser-language':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = ({ userMessage, debugMessage, error }: Omit<Errored, 'kind'>): void => {
    switch (this.state.kind) {
      case 'updating-preference':
      case 'changing-browser-language':
        this.state = { kind: 'errored', userMessage, debugMessage, error };
        break;
      case 'waiting':
      case 'detecting-preferred-language':
      case 'requires-user-selection':
      case 'preference-identified':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed get finished(): boolean {
    switch (this.state.kind) {
      case 'preference-identified':
      case 'requires-user-selection':
      case 'updating-preference':
      case 'changing-browser-language':
      case 'errored':
        return true;
      case 'waiting':
      case 'detecting-preferred-language':
        return false;
    }
  }
}

export type { PreferredLanguageSelectionStore };

export const preferredLanguageSelectionStore = new PreferredLanguageSelectionStore();
