import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../ProgramStore/Types';
import OnDemandProgramModuleProgressContent from './OnDemandProgramModuleProgressContent';
import ScheduledProgramModuleProgressContent from './ScheduledProgramModuleProgressContent';

interface Props {
  programDetail: ActiveProgram;
  programModule: ActiveProgramModule;
  percentIncomplete: number;
  percentComplete: number;
  expectedPercentComplete: number;
}

const ProgramModuleProgressContent: React.FC<Props> = ({
  programDetail,
  programModule,
  percentIncomplete,
  percentComplete,
  expectedPercentComplete,
}) => {
  switch (programDetail.programInstanceKind) {
    case 'scheduled':
      return (
        <ScheduledProgramModuleProgressContent
          programModule={programModule}
          key={programModule.id}
          programDetail={programDetail}
          expectedPercentComplete={expectedPercentComplete}
          percentComplete={percentComplete}
          percentIncomplete={percentIncomplete}
        />
      );
    case 'on-demand':
      return (
        <OnDemandProgramModuleProgressContent
          programModule={programModule}
          key={programModule.id}
          programDetail={programDetail}
          expectedPercentComplete={expectedPercentComplete}
          percentComplete={percentComplete}
          percentIncomplete={percentIncomplete}
        />
      );
  }
};

export default observer(ProgramModuleProgressContent);
