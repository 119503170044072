import { equals } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Link } from '../../../../../../Resource/Types';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import ExperiencesStore from '../../Experiences/ExperiencesStore';
import { DropdownFilter } from '../CommonFilter';
import { allCompetencyOptions } from '../CompetencyFilter';
import { allDurationOptions, DropdownDurationFilter } from '../DurationFilter';
import FilterStore from '../FilterStore';
import { allLanguageOptions } from '../LanguageFilter';
import { allSchoolPartnerOptions } from '../SchoolPartnerFilter';
import SearchBox from '../SearchBox';
import { useMobileFilterSwitcher } from '../hooks';

export interface Props {
  experiencesStore: ExperiencesStore;
  filterStore: FilterStore;
  options: ExperienceFiltersResource;
  link: Maybe<Link>;
}

function InlineFilters({ experiencesStore, filterStore, options, link }: Props) {
  const mode = useMobileFilterSwitcher();
  const {
    competencyFilterStore,
    durationFilterStore,
    schoolPartnerFilterStore,
    languageFilterStore,
  } = filterStore;

  const handleSubmit = (link: Link) => (value: string) => {
    experiencesStore.searching(just(value), link);
  };

  return (
    <div className="flex w-full flex-col gap-2 lg:flex-row">
      {link
        .map((l) => (
          <SearchBox
            className="basis-5/12"
            state={experiencesStore.state}
            onSubmit={handleSubmit(l)}
          />
        ))
        .getOrElse(emptyFragment)}
      {when(equals(mode, 'desktop'), true)
        .map(() => (
          <div className="flex basis-7/12 gap-2">
            <DropdownFilter
              label="Competency"
              store={competencyFilterStore}
              filterOptions={allCompetencyOptions(options)}
            />
            <DropdownDurationFilter
              store={durationFilterStore}
              filterOptions={allDurationOptions(options)}
            />
            <DropdownFilter
              label="Partner"
              store={schoolPartnerFilterStore}
              filterOptions={allSchoolPartnerOptions(options)}
            />
            <DropdownFilter
              label="Language"
              store={languageFilterStore}
              filterOptions={allLanguageOptions(options)}
            />
          </div>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(InlineFilters);
