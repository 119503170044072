import { Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function SkeletonPromotableExperienceCarouselTile() {
  return (
    <>
      <div className="box-border flex w-full grid-cols-2 gap-x-16">
        <div className="flex w-4/6 flex-col gap-y-10">
          <Skeleton className="mt-2 w-10/12" size="lg" />
          <Skeleton className="w-10/12" />
          <div className="flex w-full flex-col gap-y-2">
            <Skeleton className="w-full" />
            <Skeleton className="w-full" />
            <Skeleton className="w-8/12" />
          </div>
        </div>
        <div className="flex w-2/6">
          <Skeleton className="h-52 w-full" variant="rectangle" />
        </div>
      </div>
      <div className="flex flex-row items-center gap-4 pt-6">
        <Skeleton className="w-2/12" variant="rectangle" size="md" />
        <Skeleton className="w-2/12" variant="rectangle" size="md" />
      </div>
    </>
  );
}

export default observer(SkeletonPromotableExperienceCarouselTile);
