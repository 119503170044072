import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SchoolPartnerLogo } from '../../../Common/Experience/SchoolPartner';
import { Experience } from '../../../Common/Experience/Types';
import ExperiencesLogoSuffix from './ExperiencesLogoSuffix';
import { schoolPartnerLogoProps } from './Types';

interface Props {
  experience: Experience;
  className?: string;
}

function ExperiencesLogo({ experience, className }: Props) {
  return (
    <div className={cn('mb-4 flex gap-x-3 empty:hidden sm:mb-6', className)}>
      <SchoolPartnerLogo {...schoolPartnerLogoProps(experience)} data={experience.schoolPartner} />
      <ExperiencesLogoSuffix experience={experience} />
    </div>
  );
}

export default observer(ExperiencesLogo);
