import { observer } from 'mobx-react';
import * as React from 'react';
import ThemeContext from '../../../Themes/ThemeContext';
import { BackgroundImage } from '../../../components/UI';
import { localPublicUrl } from '../../MobileImageBackground/Types';
import RegistrationViewImpl from './RegistrationViewImpl';

interface Props {
  allowLanguagePicker: boolean;
}

const RegistrationView: React.FC<Props> = ({ children, allowLanguagePicker }) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <BackgroundImage src={localPublicUrl(theme.images['login-bg'])}>
        <RegistrationViewImpl children={children} allowLanguagePicker={allowLanguagePicker} />
      </BackgroundImage>
    )}
  </ThemeContext.Consumer>
);

export default observer(RegistrationView);
