import { observer } from 'mobx-react';
import Divider from '../Divider';
import { T } from '../../../../Translations';
import { Typography } from '@execonline-inc/execonline-ui';
import { Link } from '../../../../Resource/Types';

interface Props {
  privacyLink: Link;
  termsLink: Link;
}
function FooterLegalLinks({ privacyLink, termsLink }: Props) {
  return (
    <>
      <Divider />
      <T
        kind="<link1>Privacy ..."
        link1={(content) => (
          <a
            href={privacyLink.href}
            target="_blank"
            rel="noopener noreferrer"
            data-test-privacy-policy-link={true}
          >
            <Typography
              className="text-neutral-900 underline hover:text-inherit"
              variant="bodyMedium"
              fontWeight="semibold"
            >
              {content}
            </Typography>
          </a>
        )}
      />
      <Divider />
      <T
        kind="<link1>Terms ..."
        link1={(content) => (
          <a
            href={termsLink.href}
            target="_blank"
            rel="noopener noreferrer"
            data-test-terms-of-use-link={true}
          >
            <Typography
              className="text-neutral-900 underline hover:text-inherit"
              variant="bodyMedium"
              fontWeight="semibold"
            >
              {content}
            </Typography>
          </a>
        )}
      />
      <Divider />
      <T
        kind="<link1>Sitemap</link1>."
        link1={(content) => (
          <a href="/p3/sitemap" data-test-sitemap-link={true}>
            <Typography
              className="text-neutral-900 underline hover:text-inherit"
              variant="bodyMedium"
              fontWeight="semibold"
            >
              {content}
            </Typography>
          </a>
        )}
      />
    </>
  );
}

export default observer(FooterLegalLinks);
