import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import { Link } from '../../../../Resource/Types';
import ProgramResourcesStore from '../ProgramResourcesStore';
import { programResourcesResourceDecoder } from './Decoders';
import { ProgramResourcesState } from './Types';

export interface Props extends EAProps<ProgramResourcesStore> {
  store: ProgramResourcesStore;
}

type ProgramResourcesError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleProgramResourcesError =
  (store: ProgramResourcesStore) => (error: ProgramResourcesError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify('ProgramResourcesReactions', `Missing link: ${error.rel}`, toJS(store.state));
        break;
      default:
        handleError(store, error);
    }
  };

class ProgramResourcesReactions extends ErrorActionableReaction<
  ProgramResourcesStore,
  ProgramResourcesState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: ProgramResourcesState) => {
    const { store } = this.props;

    switch (state.kind) {
      case 'loading':
        Task.succeed<ProgramResourcesError, ReadonlyArray<Link>>(state.programResource.links)
          .andThen(findLinkT('self'))
          .andThen(callApi(programResourcesResourceDecoder, {}))
          .fork(handleProgramResourcesError(this.props.store), store.ready);
        break;
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramResourcesReactions;
