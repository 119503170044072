import { when } from '@execonline-inc/maybe-adapter';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { LanguageResource } from '../../../../../../ProfileFormStore/Types';
import { FilterOption } from '../CommonFilter/Types';

export type LanguageFilterValue = SomeLanguages | AllLanguages | NoLanguages;

export type SomeLanguages = FilterOption & {
  value: string;
  label: string;
};

export type AllLanguages = {
  value: 'All';
  label: 'All';
};

export type NoLanguages = {
  kind: 'no-language';
  value: 'None';
  label: 'None';
};

const noLanguage = () => ({
  kind: 'no-language',
  value: 'None',
  label: 'None',
});

const someLanguage = (resource: LanguageResource): LanguageFilterValue => ({
  value: resource.payload.code,
  label: resource.payload.englishName,
});

const hasLanguage = (resource: LanguageResource): boolean => resource.payload.code.length > 0;

const languageFilterObject = (resource: LanguageResource): LanguageFilterValue =>
  when(hasLanguage, resource).map(someLanguage).getOrElse(noLanguage);

const getLanguageOptions = (options: ExperienceFiltersResource): LanguageFilterValue[] =>
  options.payload.availableLanguages.map(languageFilterObject);

export const allLanguageOptions = (options: ExperienceFiltersResource): LanguageFilterValue[] => [
  ...getLanguageOptions(options),
];
