import { cn } from '@execonline-inc/execonline-ui';
import { Experience } from '../../../../Experience/Types';

const commonClassName =
  'bg-[length:100%_155px] bg-right-top bg-no-repeat sm:bg-[length:100%_100px]';

export function getExperienceHeaderBackgroundByType(experience: Experience) {
  switch (experience.offeringType) {
    case 'coaching':
      return cn('bg-leadership-coaching-list-view-header', commonClassName);
    case 'program-sequence':
      return cn('bg-learning-journey-list-view-header', commonClassName);
    case 'group-coaching':
    case 'aep':
    case 'epc':
    case 'msuite':
      return '';
  }
}
