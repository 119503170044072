import { base64ToUTF8Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import Decoder, { array, at, date, field, number, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { LiveForumDate, LiveForumDateResources, LiveForumDatesNode } from './Types';

const liveForumDateDecoder: Decoder<LiveForumDate> = succeed({})
  .assign('id', field('id', number))
  .assign('start_at', field('start_at', date));

const liveForumDateResourcesDecoder: Decoder<LiveForumDateResources> = array(
  resourceDecoder(liveForumDateDecoder)
);

export const liveForumDatesDecoder: Decoder<LiveForumDatesNode> = succeed({})
  .assign(
    'dates',
    at(
      ['attribs', 'data-attr-forum-dates'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), liveForumDateResourcesDecoder)
    )
  )
  .assign('kind', succeed<'live-forum-dates-node'>('live-forum-dates-node'));
