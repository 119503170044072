import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const PlayLectureIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 65 65">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M32.404 0C50.274 0 64.81 14.482 64.81 32.286c0 17.803-14.536 32.285-32.405 32.285C14.536 64.571 0 50.09 0 32.286 0 14.482 14.536 0 32.404 0ZM21.603 16.143V49.1l3.609-2.097 21.652-12.285 3.608-2.097-3.608-2.097L25.212 18.24l-3.609-2.097Z"
      />
    </SVGIcon>
  );
};

export default observer(PlayLectureIcon);
