import { Button } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { NotTranslated } from '../../../../../../Translations';

interface Props {
  displayButtonLabel: string;
  onShowExperiences: () => void;
}

function ExpandButton({ displayButtonLabel, onShowExperiences }: Props) {
  return (
    <div className="flex justify-center py-7">
      <Button color="primary" size="lg" onClick={onShowExperiences}>
        <NotTranslated text={displayButtonLabel} />
      </Button>
    </div>
  );
}

export default observer(ExpandButton);
