import { concat } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { Task } from 'taskarian';
import { findLinkT } from '../../LinkyLinky';
import { detectBrowser, UserAgent } from '../../UserAgent';
import { VideoPlaybackQuality } from '../JWPlayer/Types';
import { fallbackProgressiveSources, lowProgressiveSources, progressiveSources } from './Resource';
import {
  adaptive,
  missingVimeoId,
  noSelection,
  progressiveLow,
  ReqHlsVideoAsset,
  ReqHlsVideoAssetResource,
  SelectedPlayer,
  VideoSelectionFailure,
  Vimeo,
  vimeo,
} from './Types';

const onlyLow = (qualities: VideoPlaybackQuality[]): boolean =>
  qualities.length === 1 && qualities[0] === 'low';

const selectProgressiveLow = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  const preferredPlayback = reqHlsVideoAsset.preferredVideoPlayback;
  switch (preferredPlayback.type) {
    case 'progressive':
      return onlyLow(preferredPlayback.qualities)
        ? lowProgressiveSources(reqHlsVideoAsset).map(progressiveLow)
        : Task.fail([]);
    case 'adaptive':
    case 'vimeo':
      return Task.fail([]);
  }
};

const selectIeForMsie = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return progressiveSources(reqHlsVideoAsset);
    case 'progressive':
    case 'vimeo':
      return Task.fail([]);
  }
};

const selectIeForTrident11 = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return progressiveSources(reqHlsVideoAsset);
    case 'progressive':
    case 'vimeo':
      return Task.fail([]);
  }
};

const selectIE = (
  ua: UserAgent,
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  const browserDetected = detectBrowser(ua);
  switch (browserDetected.kind) {
    case 'msie':
      return selectIeForMsie(reqHlsVideoAsset);
    case 'trident':
      if (browserDetected.versionNumber === 11) {
        return selectIeForTrident11(reqHlsVideoAsset);
      } else {
        return Task.fail([]);
      }
    case 'electron':
    case 'safari':
    case 'edge':
    case 'other':
      return Task.fail([]);
  }
};

const selectSafariGivenSafariBrowser = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return progressiveSources(reqHlsVideoAsset);
    case 'progressive':
    case 'vimeo':
      return Task.fail([]);
  }
};

const selectSafari = (
  ua: UserAgent,
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  const browserDetected = detectBrowser(ua);
  switch (browserDetected.kind) {
    case 'safari':
      return selectSafariGivenSafariBrowser(reqHlsVideoAsset);
    case 'msie':
    case 'trident':
    case 'edge':
    case 'electron':
    case 'other':
      return Task.fail([]);
  }
};

const vimeoPlayer = (reqHlsVideoAsset: ReqHlsVideoAsset): Task<VideoSelectionFailure[], Vimeo> =>
  toTask([missingVimeoId()], reqHlsVideoAsset.vimeoId).map(vimeo);

const selectVimeo = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAsset.preferredVideoPlayback.type) {
    case 'vimeo':
      return vimeoPlayer(reqHlsVideoAsset);
    case 'progressive':
    case 'adaptive':
      return Task.fail([]);
  }
};

const selectAdaptive = (
  reqHlsVideoAssetResource: ReqHlsVideoAssetResource,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAssetResource.payload.preferredVideoPlayback.type) {
    case 'adaptive':
      return Task.succeed<VideoSelectionFailure[], {}>({})
        .assign(
          'file',
          findLinkT('adaptive', reqHlsVideoAssetResource.links).mapError(() => []),
        )
        .assign('fallbackSources', fallbackProgressiveSources(reqHlsVideoAssetResource.payload))
        .map(adaptive);
    case 'progressive':
    case 'vimeo':
      return Task.fail([]);
  }
};

/**
 * Select the Progressive player, either as an explicit choice or as the fallback from
 * a failed adaptive player selection.
 */
const selectProgressive = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> => {
  switch (reqHlsVideoAsset.preferredVideoPlayback.type) {
    case 'progressive':
    case 'adaptive':
      return progressiveSources(reqHlsVideoAsset);
    case 'vimeo':
      return Task.fail([]);
  }
};

export interface ErrorTrackedResult<E, T> {
  result: T;
  errors: E[];
}

type SelectionResults = ErrorTrackedResult<VideoSelectionFailure, SelectedPlayer>;

const failedSelectionResults = (errors: VideoSelectionFailure[]): SelectionResults => ({
  result: noSelection(),
  errors,
});

export const selectPlayer = (
  ua: UserAgent,
  resource: ReqHlsVideoAssetResource,
): Task<never, SelectionResults> => {
  const nothingSelected: Task<VideoSelectionFailure[], SelectionResults> = Task.fail([]);
  return nothingSelected
    .orElse(withErrorTracking(selectProgressiveLow(resource.payload)))
    .orElse(withErrorTracking(selectIE(ua, resource.payload)))
    .orElse(withErrorTracking(selectSafari(ua, resource.payload)))
    .orElse(withErrorTracking(selectVimeo(resource.payload)))
    .orElse(withErrorTracking(selectAdaptive(resource)))
    .orElse(withErrorTracking(selectProgressive(resource.payload)))
    .orElse((errs) => Task.succeed<never, SelectionResults>(failedSelectionResults(errs)));
};

const withErrorTracking =
  <E, T>(task: Task<E[], T>) =>
  (errs: E[]): Task<E[], ErrorTrackedResult<E, T>> =>
    task.map((r) => ({ result: r, errors: errs })).mapError(concat(errs));
