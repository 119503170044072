import * as React from 'react';
import { Assignment } from '../../../../TeamResourceStore/Types';
import UserFolderIcon from '../../../Cutesies/UserFolderIcon';
import Modal from '../../../Modal';
import { CloseAction } from '../../../Openable';
import * as style from '../../style.module.css';
import Content from '../AssignmentDetails/Content';
import Attachments from './Attachments';

interface Props {
  assignment: Assignment;
  close: CloseAction;
}

const AssignmentModal: React.FC<Props> = ({ close, assignment }) => (
  <Modal close={close} title={assignment.title}>
    <div className={style.modalContent}>
      <div className={style.inner}>
        <div className={style.innerText}>
          <Content assignment={assignment} />
        </div>
        <div className={style.icon}>
          <UserFolderIcon />
        </div>
      </div>
      <Attachments assignment={assignment} />
      <div />
    </div>
  </Modal>
);

export default AssignmentModal;
