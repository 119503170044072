import { always } from '@kofno/piper';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReactions from '../../../ChatMessageStore/ChatReactions';
import ConversationStore from '../../../ConversationStore';
import ConversationAPIReactions from '../../../ConversationStore/ConversationAPIReactions';
import ConversationUIReactions from '../../../ConversationStore/ConversationUIReactions';
import { authoredBy, MessageGroup, whenFirstLoaded } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import LastReadMessageAPIReactions from '../../../LastReadMessageStore/Reactions';
import ScrollStore from '../../../ScrollStore';
import { scrollEvent, whenAtBottom } from '../../../ScrollStore/Types';
import TeamStore from '../../../TeamStore';
import { T } from '../../../Translations';
import NewChatMessage from '../../NewChatMessage';
import * as platformStyle from '../../Platform/style.module.css';
import ChatScrollReactions from './ChatScrollReaction';
import MoreMessages from './MoreMessages';
import ResumeProgram from './ResumeProgram';
import ScrollToNewMessage from './ScrollToNewMessage';
import * as style from './style.module.css';
import TeamChatFooter from './TeamChatFooter';
import TeamChatMessageForm from './TeamChatMessageForm';
import TeamChatMessageGroup from './TeamChatMessageGroup';
import TeamChatTitle from './TeamChatTitle';

interface Props {
  conversationStore: ConversationStore;
  teamStore: TeamStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  currentUserResource: CurrentUserResource;
}

interface State {}

@observer
class TeamChatImpl extends React.Component<Props, State> {
  messageListEl: React.RefObject<HTMLDivElement> = React.createRef();
  scrollObserver: Maybe<MutationObserver>;
  scrollStore = new ScrollStore(20);

  constructor(props: Props) {
    super(props);
    this.scrollObserver = nothing();
  }

  lastReadMessageStore = new LastReadMessageStore(
    this.props.conversationStore.conversationResource.payload.lastReadMessage
  );

  componentDidMount() {
    this.scrollToBottomObserver();
    this.scrollObserver = just(new MutationObserver(this.scrollToBottomObserver));
    const el = this.messageListEl.current;
    if (el) {
      this.scrollObserver.do((observer) => observer.observe(el, { childList: true }));
    }
  }

  componentWillUnmount() {
    this.scrollObserver.do((observer) => observer.disconnect());
  }

  shouldScrollToLatestUnseen = (): Maybe<'bottom'> => {
    const { conversationStore } = this.props;
    const { scrollPosition } = this.scrollStore;

    return conversationStore.unseenMessage
      .andThen((message) =>
        authoredBy(this.props.currentUserResource.payload.id)(message).orElse(() =>
          scrollPosition.andThen(whenAtBottom).map(always(message))
        )
      )
      .map(always('bottom'));
  };

  scrollToBottomObserver = () => {
    const { conversationStore } = this.props;

    this.shouldScrollToLatestUnseen()
      .orElse(whenFirstLoaded(conversationStore))
      .do(this.scrollToBottom);
  };

  scrollToBottom = () => {
    fromNullable(this.messageListEl.current).map((el) => (el.scrollTop = el.scrollHeight));
  };

  onScroll = (divElement: React.UIEvent<HTMLDivElement>) => {
    const { conversationStore } = this.props;
    this.scrollStore.scrolling(scrollEvent(divElement));
    conversationStore.unseenMessage
      .andThen(() => this.scrollStore.scrollPosition)
      .andThen((pos) => {
        switch (pos) {
          case 'middle':
          case 'near-bottom':
          case 'top':
          case 'near-top':
            return nothing();
          case 'bottom':
            return just(pos);
        }
      })
      .do(conversationStore.clearUnseenMessage);
  };

  render() {
    const { conversationStore, teamStore, presenceStore } = this.props;

    return teamStore.team
      .map((teamResource) => (
        <>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Chat" />
          </h2>
          <ResumeProgram courseId={teamResource.payload.courseId} />
          <div className={style.chatbox}>
            <TeamChatTitle teamResource={teamResource} />
            <div className={style.scroller} ref={this.messageListEl} onScroll={this.onScroll}>
              <MoreMessages store={conversationStore} />
              {conversationStore.messageResourceGroups.map((group: MessageGroup) => (
                <TeamChatMessageGroup
                  group={group}
                  presenceStore={presenceStore}
                  key={group.dateStamp}
                  conversationStore={conversationStore}
                  lastReadMessageStore={just(this.lastReadMessageStore)}
                />
              ))}
              <MoreMessages store={conversationStore} />
            </div>
            {this.scrollStore.scrollPosition
              .map((scrollPosition) => (
                <div className={style.scrollToNewMessageWrapper}>
                  <ScrollToNewMessage
                    scrollPosition={scrollPosition}
                    conversationStore={conversationStore}
                    scrollToBottom={this.scrollToBottom}
                    className={style.scrollToNewMessageButton}
                    currentUserResource={this.props.currentUserResource}
                  />
                </div>
              ))
              .getOrElseValue(<span />)}
            <NewChatMessage store={conversationStore.chatMessageStore}>
              {{
                form: (
                  <TeamChatMessageForm
                    store={conversationStore.chatMessageStore}
                    placeholder="Send a message..."
                  />
                ),
              }}
            </NewChatMessage>
            <TeamChatFooter />
          </div>
          <ChatReactions
            store={conversationStore.chatMessageStore}
            conversationStore={conversationStore}
            lastReadMessageStore={just(this.lastReadMessageStore)}
          />
          <ConversationUIReactions store={conversationStore} messageListRef={this.messageListEl} />
          <ConversationAPIReactions store={conversationStore} />
          <LastReadMessageAPIReactions store={this.lastReadMessageStore} debounceDelay={3000} />
          <ChatScrollReactions conversationStore={conversationStore} store={this.scrollStore} />
        </>
      ))
      .getOrElseValue(<></>);
  }
}
export default TeamChatImpl;
