import * as React from 'react';
import { observer } from 'mobx-react';

import Modal from '../../../../Modal';
import ScheduleSessionStore from './Store';
import { T } from '../../../../../Translations';
import ModalStore from '../../../../Modal/Store';
import { EventDetails } from '../../../../Freebusy/Types';
import MeetingDetails from '../../../../Dashboard/FreebusyCoachingAd/MeetingDetails';

interface Props {
  eventDetails: EventDetails;
  scheduleSessionStore: ScheduleSessionStore;
}

class ConfirmationModal extends React.Component<Props, {}> {
  modalStore = new ModalStore();

  componentDidMount() {
    this.modalStore.open();
  }

  render() {
    switch (this.modalStore.state.kind) {
      case 'open':
        return (
          <Modal
            close={this.props.scheduleSessionStore.reloading}
            title={<T kind="Your meeting was successfully scheduled" />}
          >
            <MeetingDetails eventDetails={this.props.eventDetails} />
          </Modal>
        );
      case 'closed':
        return <></>;
    }
  }
}

export default observer(ConfirmationModal);
