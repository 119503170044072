import { nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../Appy';
import AttachmentUploadStore from '../AttachmentUploadStore';
import { CurrentUserResource } from '../CurrentUser/Types';
import { handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import SimpleFileUploadStore from '../SimpleFileUploadStore';
import { simpleFileUploadResourceDecoder } from './Decoders';
import { SimpleFileUploadResource, SimpleFileUploadResponse } from './Types';
import { validateFile } from './ValidateFile';

type FileUploadError = MissingLinkError | AppyError;

const handleRequestError = (store: AttachmentUploadStore) => (error: FileUploadError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Link not available');
      break;
    default:
      handleError(store, error);
  }
};

export const upload = (
  name: string,
  type: string,
  currentUserResource: CurrentUserResource,
): Task<FileUploadError, SimpleFileUploadResource> => {
  return Task.succeed<FileUploadError, ReadonlyArray<Link>>(currentUserResource.links)
    .andThen(findLinkT('simple-file-upload'))
    .map((link: Link) => ({
      ...link,
      href: `${link.href}?object_name=${name}&content_type=${type}`,
    }))
    .andThen(callApi(simpleFileUploadResourceDecoder, {}));
};

export const getSignedUrl = (
  name: string,
  type: string,
  currentUserResource: CurrentUserResource,
  callback: (payload: SimpleFileUploadResponse) => void,
  store: AttachmentUploadStore,
) => {
  upload(name, type, currentUserResource).fork(
    (err) => handleRequestError(store),
    (data) => {
      callback(data.payload);
    },
  );
};

export const onUploadFinish = (
  res: SimpleFileUploadResponse,
  simpleFileUploadStore: SimpleFileUploadStore,
) => {
  const upload = {
    handle: res.fileName,
    filename: res.objName,
    uploadFilePath: res.tempFilePath,
    temporaryCdnUrl: res.signedUrl,
  };
  simpleFileUploadStore.uploadSuccessful(upload);
};

export const onUploadError = (
  err: string,
  file: File,
  simpleFileUploadStore: SimpleFileUploadStore,
) => {
  simpleFileUploadStore.readyWithErrors(
    'Your upload could not be processed at this time',
    nothing(),
    file,
  );
};

export const scrubFilename = (filename: string) =>
  filename.replace(/\s/g, '_').replace(/[^\w.-]/gi, '');

export const onUploadStart = (
  file: File,
  next: (file: File) => void,
  simpleFileUploadStore: SimpleFileUploadStore,
) => {
  validateFile(file).cata({
    Err: () => simpleFileUploadStore.readyWithErrors('File format not supported', nothing(), file),
    Ok: () => {
      simpleFileUploadStore.uploadInProgess();
      next(file);
    },
  });
};
