import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PromotableExperienceResource } from '../Types';
import MobilePromotableExperienceTileView from './MobilePromotableExperienceTileView';
import PromotableExperienceTileView from './PromotableExperienceTileView';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function PromotableExperienceCarouselTile({ experienceResource }: Props) {
  const breakpoint = useMediaQuery();

  switch (breakpoint) {
    case 'xl':
    case 'lg':
      return <PromotableExperienceTileView experienceResource={experienceResource} />;
    case 'md':
    case 'sm':
    case 'xs':
      return <MobilePromotableExperienceTileView experienceResource={experienceResource} />;
  }
}

export default observer(PromotableExperienceCarouselTile);
