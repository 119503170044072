import { emptyFragment, Image } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../../../Links';
import { PromotableExperienceResource } from '../Types';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function PromotableExperienceTileImage({ experienceResource }: Props) {
  return findLinkBy({ rel: 'promotable-marketing-image', method: 'get' }, experienceResource.links)
    .map(({ href }) => (
      <Image
        className="absolute bottom-0 right-8 h-auto w-48 object-contain md:w-[12rem] lg:right-0 lg:h-auto lg:w-[23.375rem]"
        animate={false}
        src={href}
      />
    ))
    .getOrElse(emptyFragment);
}

export default observer(PromotableExperienceTileImage);
