import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fillColor: string;
}
const CircleIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SVGIcon viewBox="0 0 14 14">
      <circle className="circle" cx="7" cy="7" r="7" fill={fillColor} />
    </SVGIcon>
  );
};

export default observer(CircleIcon);
