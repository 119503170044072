import { concatAll, Group, groupBy } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { SanitizedHtml } from '../components/LinkifyWithCharEntities';
import { Conversation, LocalConferenceRoomResource } from '../Conference/Types';
import { Error } from '../ErrorHandling';
import { ProfileResource } from '../ProfileStore/Types';
import { RegistrationType } from '../ProgramsStore/Types';
import { Resource, ResourceWithMetadata } from '../Resource/Types';
import { AssignmentResource } from '../TeamResourceStore/Types';

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

export interface LoadingMore {
  kind: 'loading-more';
  teamsResource: TeamsResource;
}
interface Ready {
  kind: 'ready';
  teamsResource: TeamsResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const loadingMore = (teamsResource: TeamsResource): LoadingMore => ({
  kind: 'loading-more',
  teamsResource,
});

export const ready = (teamsResource: TeamsResource): Ready => ({
  kind: 'ready',
  teamsResource,
});

export type TeamsState = Waiting | Loading | LoadingMore | Ready | Error;

export type TeamPageContent = 'Chat' | 'Files';

export interface Team {
  kind: 'team';
  name: string;
  courseId: number;
  id: number;
  teamEventCount: number;
  conferenceRoom: Maybe<LocalConferenceRoomResource>;
  teamPageContent: TeamPageContent;
  coaches: CoachResource[];
  teamMembers: TeamMemberResource[];
  assignments: AssignmentResource[];
  teamChat: TeamChatResource;
  associatedEventIds: number[];
}

export interface UnreadTeamChatMessages {
  unreadCount: Maybe<number>;
  teamId: number;
  teamPageContent: TeamPageContent;
}

export interface TeamsMetadata {
  currentPage: number;
  pageCount: number;
  perPage: number;
  resultsCount: number;
  unreadTeamChatMessages: UnreadTeamChatMessages[];
}

export interface TeamMember {
  id: number;
  email: string;
  profile: ProfileResource;
}

export interface Coach extends TeamMember {
  participantType: 'Coach';
}

export interface Breakout {
  participantType: RegistrationType;
  endAt: Maybe<number>;
  eventChannelName: string;
  teamId: number;
  eventId: number;
}

export interface TeamLinkNode {
  kind: 'team-link-node';
  teamId: string;
}

export interface TeamChat {
  pinnedMessage: Maybe<SanitizedHtml>;
  messagingChannel: string;
  conversationResource: Resource<Conversation>;
  breakoutConversationResource: Resource<Conversation>;
}
export type TeamChatResource = Resource<TeamChat>;

export type TeamMemberResource = Resource<TeamMember>;
export type TeamMembersResource = Resource<TeamMemberResource[]>;

export type CoachResource = Resource<Coach>;

export type TeamResource = Resource<Team>;
export type TeamsResource = ResourceWithMetadata<TeamResource[], TeamsMetadata>;

export interface UnreadTeamMessageNotification {
  unreadCount: number;
  teamLink: string;
}

export const groupedAssignments = (assignments: AssignmentResource[]): Group<AssignmentResource> =>
  groupBy<AssignmentResource>((ns) => ns.payload.label)(assignments);

export const assignmentCount = (assignments: AssignmentResource[]): number =>
  concatAll(
    assignments.map((assignment) => assignment.payload.resources.map((resource) => resource))
  ).length;
