import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../../../LinkyLinky';
import Title from '../../../../Title';
import ExperiencesReactions from '../ExperienceSelection/Experiences/ExperiencesReactions';
import ExperiencesStore from '../ExperienceSelection/Experiences/ExperiencesStore';
import { expiredAccessModalStore } from '../ExpiredAccessModal/Modal/Store';
import ExpiredAccessModal from '../ExpiredAccessModal/index';
import RegisterResourceStore from '../RegisterResourceStore';
import { RegisterResource } from '../RegisterResourceStore/Types';
import EnrollmentImpl from './EnrollmentImpl';
import EnrollmentReactions from './Reactions';
import EnrollmentStore from './Store';
import TermsAndConditions from './TermsAndConditions';

interface Props {
  registerResource: RegisterResource;
  registerResourceStore: RegisterResourceStore;
}

class Enrollment extends React.Component<Props> {
  enrollmentStore = new EnrollmentStore();
  experiencesStore = new ExperiencesStore(this.props.registerResource);

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) =>
          findLink('terms_and_conditions', currentUserResource.links)
            .map(() => <TermsAndConditions registerResource={this.props.registerResource} />)
            .getOrElse(() => (
              <>
                {this.props.registerResource.payload.sharedInvitation.payload.name
                  .map((text) => <Title title={text.text} />)
                  .getOrElse(emptyFragment)}
                <EnrollmentImpl
                  {...this.props}
                  enrollmentStore={this.enrollmentStore}
                  experiencesStore={this.experiencesStore}
                />
                <EnrollmentReactions
                  store={this.enrollmentStore}
                  fireImmediately={true}
                  registerResourceStore={this.props.registerResourceStore}
                  registerResource={this.props.registerResource}
                />
                <ExperiencesReactions
                  store={this.experiencesStore}
                  registerResource={this.props.registerResource}
                  fireImmediately={true}
                />
                <ExpiredAccessModal
                  modalStore={expiredAccessModalStore}
                  enrollmentStore={this.enrollmentStore}
                />
              </>
            ))
        }
      />
    );
  }
}

export default observer(Enrollment);
