import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachProfileResource } from '../../../../../EmbeddedFormFieldAsset/Types';
import ModalStore from '../../../../../Modal/Store';
import PortalModal from '../../../../../PortalModal';
import CoachProfileContent from './CoachProfileContent';

interface Props {
  modalStore: ModalStore;
  coachProfile: CoachProfileResource;
}

const CoachModal: React.FC<Props> = ({ modalStore, coachProfile }) => {
  return (
    <PortalModal modalStore={modalStore}>
      <CoachProfileContent coachProfile={coachProfile} />
    </PortalModal>
  );
};

export default observer(CoachModal);
