import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ConflictingProgram } from '../../../../Common/Experience/Types';
import ConflictingAvailability from './ConflictingAvailability';

interface Props {
  className: string;
  conflict: ConflictingProgram;
}

function NoAvailability({ className, conflict }: Props) {
  switch (conflict.kind) {
    case 'availability-in-progress':
    case 'experience-in-progress':
      return emptyFragment();
    case 'enrolled-on-demand':
      return (
        <ConflictingAvailability
          className={className}
          value="You are currently enrolled in an On Demand experience. Please ..."
        />
      );
    case 'enrolled-scheduled':
    case 'overlaps-with-existing':
      return (
        <ConflictingAvailability
          className={className}
          value="This option conflicts with an existing enrollment. Choose another option."
        />
      );
  }
}

export default observer(NoAvailability);
