import { Maybe } from 'maybeasy';
import * as React from 'react';
import { translation, TranslationsContext } from '../../../Translations';
import LinkedInIcon from '../../Cutesies/LinkedInIcon';
import * as style from '../style.module.css';

interface Props {
  linkedInPage: Maybe<string>;
}

const LinkedIn: React.FC<Props> = ({ linkedInPage }) =>
  linkedInPage
    .map((linkedin) => (
      <TranslationsContext.Consumer>
        {(ts) => (
          <a
            data-test-profile-popout-linked-in={true}
            title={translation('LinkedIn Profile')(ts)}
            className={style.linkedin}
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
        )}
      </TranslationsContext.Consumer>
    ))
    .getOrElseValue(<div />);

export default LinkedIn;
