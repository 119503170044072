import { observer } from 'mobx-react';
import { useEnrollmentModalContext } from '../../../Common';
import ScheduledExperience from './ScheduledExperience';
import OnDemandExperience from './OnDemandExperience';

function ProgramExperienceInfo() {
  const { invitationUuid, availability, experienceResource, options } = useEnrollmentModalContext();

  switch (availability.payload.kind) {
    case 'scheduled':
      return (
        <ScheduledExperience
          availability={availability}
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
          options={options}
        />
      );
    case 'on-demand':
      return (
        <OnDemandExperience
          invitationUuid={invitationUuid}
          experienceResource={experienceResource}
        />
      );
  }
}

export default observer(ProgramExperienceInfo);
