import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../../../Conference/Types';
import DailyDialInDetails from './DailyDialInDetails';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
}

const ProviderDialInDetails: React.FC<Props> = ({ conferenceRoomResource }) => {
  switch (conferenceRoomResource.payload.kind) {
    case 'daily-live-meeting':
      return <DailyDialInDetails conferenceRoomResource={conferenceRoomResource} />;
  }
};

export default observer(ProviderDialInDetails);
