import { SessionTimeoutState } from '@execonline-inc/unified-auth.private';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { just } from 'maybeasy';
import { currentUserStore } from '../../CurrentUser/Store';
import ReactionComponent, { RCProps } from '../../ReactionComponent';
import { RootResourceError } from '../../RootResourceStore/Types';
import { handleRecovery } from '../../Session';
import { requestSessionData } from '../../Session/Reactions';
import { SessionStore, sessionStore } from '../../Session/Store';

interface Props extends RCProps<SessionStore> {}

class TimeOutReactions extends ReactionComponent<SessionStore, SessionTimeoutState, Props> {
  tester = () => this.props.store.timeoutState;

  effect = (state: SessionTimeoutState) => {
    switch (state) {
      case 'current':
      case 'not-applicable':
        break;
      case 'expired':
        currentUserStore.anonymous(just('Your session has expired. Please login.'));
        break;
      case 'expiring':
        requestSessionData().fork(handleRequestFailure, handleRequestSuccess);
        break;
      default:
        assertNever(state);
    }
  };
}

export default TimeOutReactions;

const handleRequestSuccess = handleRecovery(sessionStore.present);

const handleRequestFailure = (error: RootResourceError): void => {
  switch (error.kind) {
    case 'missing-link-error':
    case 'missing-application-id':
    case 'missing-api-compatibility':
    case 'bad-payload':
    case 'bad-url':
    case 'network-error':
    case 'timeout':
      sessionStore.errored(error);
      break;
    case 'bad-status':
      handleBadStatus(error);
      break;
    default:
      assertNever(error);
  }
};

const handleBadStatus = (error: BadStatus): void => {
  switch (error.response.status) {
    case 401:
    case 404:
      currentUserStore.anonymous(just('Your session has expired, please log in again.'));
      sessionStore.absent();
      break;
    default:
      sessionStore.errored(error);
  }
};
