import { first } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProfileFormStore from '../../../ProfileFormStore';
import ErrorMessageContainer from './ErrorMessageContainer';
import RequiredFieldErrorMessageContainer from './RequiredFieldErrorMessageContainer';

interface Props {
  store: ProfileFormStore;
}

const ProfileErrorMessage: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'error':
      return first(store.requiredFieldErrors)
        .map((_) => <RequiredFieldErrorMessageContainer errors={store.requiredFieldErrors} />)
        .getOrElse(() =>
          first(store.serverErrors)
            .map(() => <ErrorMessageContainer serverErrors={store.serverErrors} />)
            .getOrElse(() => <></>)
        );
      break;
    case 'waiting':
    case 'loading':
    case 'update-success':
    case 'loaded':
    case 'ready-with-errors':
    case 'ready-with-no-countries':
    case 'countries-loaded':
    case 'country-selected':
    case 'regions-loaded':
    case 'region-selected':
    case 'succeed':
    case 'checking-required-fields':
    case 'updating':
    case 'loading-countries':
    case 'loading-regions':
    case 'selecting-country':
      return <></>;
  }
};
export default observer(ProfileErrorMessage);
