import { observer } from 'mobx-react';
import ExperienceDetails from './ExperienceDetails';

function ExperienceDetailsBody() {
  return (
    <div className="bg-white">
      <div className="mx-auto xl:container">
        <ExperienceDetails />
      </div>
    </div>
  );
}

export default observer(ExperienceDetailsBody);
