import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../../../../Modal/Store';
import { CoachProfileResource, SelectFormFieldOptionImage } from '../../../../Types';
import CoachModal from './CoachModal';

interface Props {
  modalStore: ModalStore;
  coachProfileResource: CoachProfileResource;
  image: Maybe<SelectFormFieldOptionImage>;
  onSelect: () => void;
}

const CoachModalStates: React.FC<Props> = ({
  modalStore,
  coachProfileResource,
  image,
  onSelect,
}) => {
  switch (modalStore.state.kind) {
    case 'open':
      return (
        <CoachModal
          coachProfileResource={coachProfileResource}
          image={image}
          modalStore={modalStore}
          onSelect={onSelect}
        />
      );
    case 'closed':
      return <></>;
  }
};

export default observer(CoachModalStates);
