import { base64ToUTF8Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import Decoder, { array, at, date, field, number, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { SectionEventDate, SectionEventDateResources, SectionEventDatesNode } from './Types';

const sectionEventDateDecoder: Decoder<SectionEventDate> = succeed({})
  .assign('id', field('id', number))
  .assign('start_at', field('start_at', date));

const sectionEventDateResourcesDecoder: Decoder<SectionEventDateResources> = array(
  resourceDecoder(sectionEventDateDecoder)
);

export const sectionEventDatesDecoder: Decoder<SectionEventDatesNode> = succeed({})
  .assign(
    'sectionEvents',
    at(
      ['attribs', 'data-attr-section-event-dates'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), sectionEventDateResourcesDecoder)
    )
  )
  .assign('kind', succeed<'section-event-dates-node'>('section-event-dates-node'));
