import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../SegmentStore';
import { LectureSegment as Lecture } from '../../../LectureStore/Types';
import Commentaries from './Commentaries';
import CurrentSlide from './CurrentSlide';
import Notification from './Notification';
import LectureSizingReactions from './Reactions/LectureSizingReactions';
import SlideNavigation from './SlideNavigation';
import Slides from './Slides';
import LectureSizingStore from './Stores/LectureSizingStore';
import SwitcherooStore from './Stores/SwitcherooStore';
import * as style from './style.module.css';
import Video from './Video';

interface Props {
  segmentStore: SegmentStore;
  segment: Lecture;
}

@observer
class LectureSegment extends React.Component<Props, {}> {
  videoWrapperEl: React.RefObject<HTMLDivElement> = React.createRef();
  currentSlideWrapperEl: React.RefObject<HTMLDivElement> = React.createRef();
  currentPptSlideEl: React.RefObject<HTMLIFrameElement> = React.createRef();
  currentPptSlideSizerEl: React.RefObject<HTMLDivElement> = React.createRef();
  lectureEl: React.RefObject<HTMLDivElement> = React.createRef();
  lectureSizingStore: LectureSizingStore = new LectureSizingStore();
  switcherooStore: SwitcherooStore = new SwitcherooStore(
    this.props.segment,
    this.props.segmentStore.kettle
  );

  componentDidMount() {
    this.lectureSizingStore.initializing(
      this.props.segment.id,
      this.props.segment.video.payload.uuid
    );
    window.addEventListener('resize', this.lectureSizingStore.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.lectureSizingStore.resize);
  }

  //Switcheroo activates before the video can be resized, messing up the scroll bar
  //Here we capture the lecture height before switcheroo activates in order to set it
  //when switcheroo does activate and keep the lecture at a consistent height
  //https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
  getSnapshotBeforeUpdate() {
    if (this.lectureEl.current && this.switcherooStore.state.kind === 'video-only') {
      return this.lectureEl.current.getBoundingClientRect().height;
    }
    return null;
  }

  //The third argument is the value captured from the snapshot
  componentDidUpdate(prevProps: any, prevState: any, lectureHeight: number | null) {
    if (this.lectureEl.current && lectureHeight) {
      this.lectureEl.current.style.height = `${lectureHeight}px`;
    }
    // this has to happen after the component's style updates, it won't work as a reaction
    this.lectureSizingStore.resize();

    if (this.lectureEl.current) {
      this.lectureEl.current.style.height = 'auto';
    }
  }

  videoOnlyStyle = () => (this.switcherooStore.state.kind === 'video-only' ? style.videoonly : '');

  render() {
    const { segment, segmentStore } = this.props;
    return (
      <>
        <Notification store={this.lectureSizingStore} />
        <div
          className={`${style.lecture} ${this.videoOnlyStyle()}`}
          data-test-lecture-segment={segment.id}
          ref={this.lectureEl}
        >
          <Video segmentStore={segmentStore} segment={segment} refEl={this.videoWrapperEl} />
          <div className={style.extras}>
            <Commentaries segment={segment} kettle={this.props.segmentStore.kettle} />
            <Slides segment={segment} kettle={this.props.segmentStore.kettle} />
          </div>
          <CurrentSlide
            segment={segment}
            kettle={this.props.segmentStore.kettle}
            refWrapperEl={this.currentSlideWrapperEl}
            pptRefEl={this.currentPptSlideEl}
            pptSizerEl={this.currentPptSlideSizerEl}
          />
          <SlideNavigation segment={segment} kettle={this.props.segmentStore.kettle} />
          <LectureSizingReactions
            videoWrapperEl={this.videoWrapperEl}
            currentPptSlideEl={this.currentPptSlideEl}
            currentSlideWrapperEl={this.currentSlideWrapperEl}
            currentPptSlideSizerEl={this.currentPptSlideSizerEl}
            store={this.lectureSizingStore}
            switcherooState={this.switcherooStore.state}
            lectureEl={this.lectureEl}
            segmentType={segment.type}
          />
        </div>
      </>
    );
  }
}

export default LectureSegment;
