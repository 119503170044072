import { Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';
import { AssignmentDueSegment } from '../SegmentStore/Types';
import { TeamDiscussionSegment } from '../TeamDiscussionStore/Types';
import { Upload, Uploads } from '../Uploads';

export type AttachableSegment = AssignmentDueSegment | TeamDiscussionSegment;

export interface UploadedAttachment {
  id: number;
  filename: string;
  complete: boolean;
}

export type UploadedAttachments = UploadedAttachment[];

export type UploadedAttachmentResource = Resource<UploadedAttachment>;

export type UploadedAttachmentResources = UploadedAttachmentResource[];

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  segment: AttachableSegment;
  uploadsToSubmit: Uploads;
}

interface ReadyWithoutUpload {
  kind: 'ready-without-upload';
  segment: AttachableSegment;
}

interface UploadSuccessful {
  kind: 'upload-successful';
  segment: AttachableSegment;
  uploadsToSubmit: Maybe<Uploads>;
  newUploads: Uploads;
}

interface RemovingUpload {
  kind: 'removing-upload';
  segment: AttachableSegment;
  uploadsToSubmit: Uploads;
  uploadToRemove: Upload;
}

export type AttachmentUploadState =
  | Waiting
  | Loading
  | Ready
  | ReadyWithoutUpload
  | UploadSuccessful
  | RemovingUpload
  | Error;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (segment: AttachableSegment, uploadsToSubmit: Uploads): Ready => ({
  kind: 'ready',
  segment,
  uploadsToSubmit,
});

export const readyWithoutUpload = (segment: AttachableSegment): ReadyWithoutUpload => ({
  kind: 'ready-without-upload',
  segment,
});

export const uploadSuccessful = (
  segment: AttachableSegment,
  uploadsToSubmit: Maybe<Uploads>,
  newUploads: Uploads
): UploadSuccessful => ({
  kind: 'upload-successful',
  segment,
  uploadsToSubmit,
  newUploads,
});

export const removingUpload = (
  segment: AttachableSegment,
  uploadsToSubmit: Uploads,
  uploadToRemove: Upload
): RemovingUpload => ({
  kind: 'removing-upload',
  segment,
  uploadsToSubmit,
  uploadToRemove,
});
