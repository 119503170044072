import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import { T } from '../../../Translations';
import { ActionListItem } from '../Types';
import * as style from './style.module.css';

interface Props {
  item: ActionListItem;
}

const ActionListDropdownItem = ({ item }: Props) => {
  return (
    <li>
      <a
        className={style.root}
        onClick={item.action}
        data-test-dropdown-item-action={item.name}
        tabIndex={0}
        onKeyPress={handleEnterOrSpaceKeyPress(item.action)}
      >
        <T kind={item.name} />
      </a>
    </li>
  );
};

export default ActionListDropdownItem;
