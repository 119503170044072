import { parseIntM } from '@execonline-inc/numbers';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import ViewableAttachmentsStore from '../../ViewableAttachmentStore';
import ViewableAttachmentsReactions from '../../ViewableAttachmentStore/ViewableAttachmentReaction';
import ViewableAttachmentView from './ViewableAttachmentView';

interface Params {
  attachmentId: string;
}

interface Props {
  match: { params: Params };
}

class ViewableAttachment extends React.Component<Props> {
  viewableAttachmentsStore: ViewableAttachmentsStore;
  attachmentId: Maybe<number>;

  constructor(props: Props) {
    super(props);
    this.viewableAttachmentsStore = new ViewableAttachmentsStore();
    this.attachmentId = parseIntM(this.props.match.params.attachmentId);
  }

  componentDidMount() {
    this.viewableAttachmentsStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <ViewableAttachmentView viewableAttachmentsStore={this.viewableAttachmentsStore} />
            <ViewableAttachmentsReactions
              store={this.viewableAttachmentsStore}
              attachmentId={this.attachmentId}
              currentUserResource={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default observer(ViewableAttachment);
