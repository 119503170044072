import { observer } from 'mobx-react';
import * as React from 'react';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import { AuthSignUp } from '../Types';
import Form from './Form';
import UserAuthInstructions from './UserAuthInstructions';
import SignUpReactions from './SignUpReactions';

interface Props {
  authForm: AuthSignUp;
  registerResource: RegisterResource;
}

const SignUp: React.FC<Props> = ({ authForm, registerResource }) => {
  return (
    <>
      <RegistrationView allowLanguagePicker={true}>
        <RegistrationFormHeader
          notifiableStore={authForm.store}
          sharedInvitationResource={registerResource.payload.sharedInvitation}
        >
          <UserAuthInstructions
            sharedInvitationResource={registerResource.payload.sharedInvitation}
            authForm={authForm}
          />
        </RegistrationFormHeader>
        <Form registerResource={registerResource} authForm={authForm} />
      </RegistrationView>
      <SignUpReactions authForm={authForm} registerResource={registerResource} />
    </>
  );
};

export default observer(SignUp);
