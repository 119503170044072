import { observer } from 'mobx-react';
import { Link } from '../../../../../Resource/Types';
import { T } from '../../../../../Translations';
import WhenLinkAnchor from '../../../../../WhenLink/WhenLinkAnchor';

interface Props {
  links: ReadonlyArray<Link>;
}

function ChangeCoachLink({ links }: Props) {
  return (
    <WhenLinkAnchor rel="change-coach" links={links} className="text-xs text-teal-600">
      <T kind="change coach" />
    </WhenLinkAnchor>
  );
}

export default observer(ChangeCoachLink);
