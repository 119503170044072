import { assertNever } from '@kofno/piper';
import FocusTrap from 'focus-trap-react';
import { Maybe, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  handleEnterOrSpaceKeyPress,
  handleEscKeyDown,
} from '../../../../Accessibility/KeyboardEvents';
import { PersonStoreContract } from '../../../../Person/types';
import PopoutStore from '../../../Popout/PopoutStore';
import ProfilePopout from '../../../ProfilePopout';
import FocusTrapStore from './FocusTrapStore';
import FocusTrapReactions from './FocusTrapStore/FocusTrapReactions';
import PersonBase from './PersonBase';

interface Props {
  personStore: PersonStoreContract;
  children: {
    onlineStatus: JSX.Element;
    details: Maybe<JSX.Element>;
  };
}

class PersonBasePopoverable extends React.Component<Props> {
  popoutStore: PopoutStore = new PopoutStore();
  focusTrapStore: FocusTrapStore = new FocusTrapStore();
  personRef: React.RefObject<HTMLDivElement> = React.createRef();

  handleKeyboardToggle = (): void => {
    switch (this.popoutStore.state.kind) {
      case 'closed':
        this.focusTrapStore.activate();
        this.popoutStore.open();
        break;
      case 'open':
        this.focusTrapStore.deactivate();
        break;
      default:
        assertNever(this.popoutStore.state);
    }
  };

  render() {
    const { personStore, children } = this.props;
    const { onlineStatus, details } = children;
    let openTimeout: ReturnType<typeof setTimeout>;
    let closeTimeout: ReturnType<typeof setTimeout>;

    const handleOpenTimeout = () => {
      clearTimeout(closeTimeout);
      openTimeout = setTimeout(() => {
        this.popoutStore.open();
      }, 400);
    };
    const handleCloseTimeout = () => {
      clearTimeout(openTimeout);
      closeTimeout = setTimeout(() => {
        this.focusTrapStore.deactivate();
        this.popoutStore.closed();
      }, 600);
    };

    return (
      <>
        <FocusTrap
          active={this.focusTrapStore.state.kind === 'active'}
          focusTrapOptions={{
            escapeDeactivates: false,
            allowOutsideClick: true,
            fallbackFocus: '#person-base-container',
          }}
        >
          <div id="person-base-container" tabIndex={-1}>
            <div
              tabIndex={0}
              onMouseOver={handleOpenTimeout}
              onMouseOut={handleCloseTimeout}
              onKeyPress={(e) => handleEnterOrSpaceKeyPress(() => this.handleKeyboardToggle(), e)}
              onKeyDown={handleEscKeyDown(() => this.focusTrapStore.deactivate())}
              data-test-person-base-popoverable={true}
              ref={this.personRef}
            >
              <PersonBase store={personStore}>
                {{
                  onlineStatus: just(onlineStatus),
                  details,
                }}
              </PersonBase>
              <ProfilePopout
                popoutStore={this.popoutStore}
                personStore={personStore}
                personRef={this.personRef}
              />
            </div>
          </div>
        </FocusTrap>
        <FocusTrapReactions store={this.focusTrapStore} popoutStore={this.popoutStore} />
      </>
    );
  }
}

export default observer(PersonBasePopoverable);
