import { Time, toSeconds } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import BreakoutTimerStore from '.';
import { AppyError, postToApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { Link } from '../Resource/Types';
import { BreakoutTimerState } from './Types';

export interface Props extends EAProps<BreakoutTimerStore> {
  store: BreakoutTimerStore;
  timerLink: Link;
}

const startTimer = (duration: Time, link: Link) => {
  return postToApi({ duration: toSeconds(duration).seconds })(link);
};

// Leaving room for more robust error handling later
const handleTimerError = (store: BreakoutTimerStore) => (error: AppyError) => {
  handleError(store, error);
};

class BreakoutTimerReactions extends ErrorActionableReaction<
  BreakoutTimerStore,
  BreakoutTimerState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: BreakoutTimerState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'ready':
        break;
      case 'waiting':
        store.ready();
        break;
      case 'updating':
        startTimer(state.duration, this.props.timerLink).fork(
          handleTimerError(this.props.store),
          store.ready,
        );
        break;
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default BreakoutTimerReactions;
