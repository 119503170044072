import * as React from 'react';
import { observer } from 'mobx-react';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import * as style from '../../TeamSummary/style.module.css';
import ContentFaker from '../../ContentFaker';
import CommunitySummary from './CommunitySummary';
import { T } from '../../../Translations';

interface Props {
  coachTeamsSummaryStore: coachTeamsSummaryStore;
}

const CommunitiesSummaryContent: React.FC<Props> = ({ coachTeamsSummaryStore }) => {
  switch (coachTeamsSummaryStore.state.kind) {
    case 'loading':
    case 'load-all':
    case 'add-filter':
      return <ContentFaker />;
    case 'waiting':
      return (
        <div className={style.searchTeamPlaceholder}>
          <p className={style.searchTeam}>
            <T kind="Search for a team" />
          </p>
          <p>
            <T kind="Please search or select a program / name" />
          </p>
        </div>
      );
    case 'ready':
      return <CommunitySummary coachTeamsSummaryStore={coachTeamsSummaryStore} />;
    case 'error':
      return <></>;
  }
};

export default observer(CommunitiesSummaryContent);
