import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import NonSSOInstructions from './NonSSOInstructions';

interface Props {
  sharedInvitationResource: SharedInvitationResource;
}

const Instructions: React.FC<Props> = ({ sharedInvitationResource }) => {
  switch (sharedInvitationResource.payload.assertionBackend.payload.kind) {
    case 'third-party-backend':
      return (
        <Typography>
          <T kind="Continue to launch your corporate ..." />
        </Typography>
      );
    case 'password-backend':
      return <NonSSOInstructions sharedInvitationResource={sharedInvitationResource} />;
    case 'locked-access':
      return <></>;
  }
};

export default observer(Instructions);
