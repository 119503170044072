import { warn } from '@execonline-inc/logging';
import { just, Maybe, nothing } from 'maybeasy';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { PropsWithChildren, useMemo } from 'react';
import { useFetch, useFindLinkByR } from '../../Fetch';
import { SegmentResource } from '../../SegmentStore/Types';
import { programModuleProgressResourceDecoder } from '../Decoders';
import { ProgramModuleProgressResource } from '../Types';
import { ProgramModuleProgressContext } from './Types';

interface Props {
  resource: SegmentResource;
}

function ProgramModuleProgressProviderView({ resource, children }: PropsWithChildren<Props>) {
  const link = useFindLinkByR({ rel: 'progress', method: 'get' }, resource.links);
  const state = useFetch(link, programModuleProgressResourceDecoder);

  const value: Maybe<ProgramModuleProgressResource> = useMemo(() => {
    switch (state.kind) {
      case 'loading':
        return nothing();
      case 'errored':
        warn('Failed to fetch program progress resource.', toJS(state.error));
        return nothing();
      case 'ready':
        return just(state.data);
    }
  }, [state.kind]);

  return (
    <ProgramModuleProgressContext.Provider value={value}>
      {children}
    </ProgramModuleProgressContext.Provider>
  );
}

export default observer(ProgramModuleProgressProviderView);
