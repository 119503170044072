import { Modal, ModalBody, ModalHeader, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { CloseAction, OpenableState } from '../../../../../components/Openable';
import { T } from '../../../../../Translations';

interface Props {
  modalState: OpenableState;
  onClose: CloseAction;
}

function AlertPreviewModal({ modalState, onClose }: Props) {
  return (
    <Modal
      className="h-full rounded-none sm:h-auto"
      open={modalState.kind === 'open'}
      onClose={onClose}
    >
      <ModalHeader>
        <Typography fontWeight="bold">
          <T kind={'Not Available in Preview Mode'} />
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Typography>
          <T
            kind={
              "You are previewing a Learning Collection. The 'Enroll' and 'Learn More' features are not available in preview mode."
            }
          />
        </Typography>
      </ModalBody>
    </Modal>
  );
}

export default observer(AlertPreviewModal);
