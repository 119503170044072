import { base64ToUTF8Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import Decoder, { array, at, field, number, string, succeed } from 'jsonous';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { TeamMember, TeamMemberResource, TeamMembersResource } from '../../TeamsStore/Types';
import { TeamMembersNode } from './Types';

const teamMemberDecoder: Decoder<TeamMember> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

const teamMemberResourceDecoder: Decoder<TeamMemberResource> = resourceDecoder(teamMemberDecoder);

const teamMembersResourceDecoder: Decoder<TeamMembersResource> = resourceDecoder(
  array(teamMemberResourceDecoder)
);

export const teamMembersDecoder: Decoder<TeamMembersNode> = succeed({})
  .assign(
    'teamMembers',
    at(
      ['attribs', 'data-attr-team-members'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), teamMembersResourceDecoder)
    )
  )
  .assign('kind', succeed<'team-members'>('team-members'));
