import { ExperienceResource } from '../../Common/Experience/Types';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  registerResource: RegisterResource;
  experienceId: string;
}

interface Ready {
  kind: 'ready';
  experienceResource: ExperienceResource;
}

interface Error {
  kind: 'error';
  message: string;
}

export type State = Waiting | Loading | Ready | Error;

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (experienceId: string, registerResource: RegisterResource): Loading => ({
  kind: 'loading',
  experienceId,
  registerResource,
});

export const ready = (experienceResource: ExperienceResource): Ready => ({
  kind: 'ready',
  experienceResource,
});

export const error = (message: string): Error => ({ kind: 'error', message });
