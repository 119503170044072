import { observer } from 'mobx-react';
import * as React from 'react';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import SetPasswordStoreReactions from '../SetPasswordStore/Reactions';
import SsoLoginStoreReactions from '../SsoLoginStore/Reactions';
import { AuthSignUp } from '../Types';

interface Props {
  authForm: AuthSignUp;
  registerResource: RegisterResource;
}

const SignUpReactions: React.FC<Props> = ({ authForm, registerResource }) => {
  switch (authForm.kind) {
    case 'password-sign-up':
      return <SetPasswordStoreReactions store={authForm.store} links={registerResource.links} />;
    case 'sso-sign-up':
      return (
        <SsoLoginStoreReactions
          store={authForm.store}
          auth={authForm.auth}
          registerResource={registerResource}
          fireImmediately
        />
      );
  }
};

export default observer(SignUpReactions);
