import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const CalendarEditIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 18 19">
      <path
        fill="black"
        d="M2.625.5v.625h-2.5v13.75h8.148L7.625 18l3.313-.687 5.5-5.5A1.85 1.85 0 0 0 17 10.5a1.85 1.85 0 0 0-.563-1.312 1.85 1.85 0 0 0-1.312-.563 1.84 1.84 0 0 0-1.25.511V1.125h-2.5V.5h-1.25v.625h-6.25V.5h-1.25Zm-1.25 1.875h1.25V3h1.25v-.625h6.25V3h1.25v-.625h1.25v1.25H1.375v-1.25Zm0 2.5h11.25v5.5l-3.25 3.25h-8v-8.75Zm3.75 1.25v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm-7.5 2.5v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm5 1.25c.156 0 .312.063.437.188.25.25.25.624 0 .874l-5.249 5.25-1.063.188.188-1.063 5.25-5.249a.616.616 0 0 1 .437-.188Zm-12.5 1.25v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Zm2.5 0v1.25h1.25v-1.25h-1.25Z"
      />
    </SVGIcon>
  );
};

export default observer(CalendarEditIcon);
