import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../BrowserSupport/Main';
import NotificationSource from '../../NotificationSource';
import Notifications from '../../Notifications';
import ResetPasswordStore from '../../ResetPasswordStore';
import ResetPasswordReactions from '../../ResetPasswordStore/ResetPasswordReactions';
import { T } from '../../Translations';
import CenteredLogo from '../CenteredLogo';
import WithBackgroundImage from '../WithBackgroundImage';
import Form from './Form';

@observer
class ResetPassword extends React.Component<{}, {}> {
  private resetPasswordStore: ResetPasswordStore;

  constructor(props: {}) {
    super(props);
    this.resetPasswordStore = new ResetPasswordStore();
  }

  componentDidMount() {
    this.resetPasswordStore.loading();
  }

  render() {
    return (
      <>
        <WithBackgroundImage>
          <Main>
            <CenteredLogo />
            <Notifications />
            <Typography
              className="mb-8"
              variant="h5"
              as="h1"
              fontWeight="medium"
              data-test-title={true}
            >
              <T kind="Reset Your Password" />
            </Typography>
            <Form store={this.resetPasswordStore} />
          </Main>
        </WithBackgroundImage>
        <ResetPasswordReactions store={this.resetPasswordStore} />
        <NotificationSource store={this.resetPasswordStore} />
      </>
    );
  }
}

export default ResetPassword;
