import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../../ConversationStore';
import { MessageGroup } from '../../../ConversationStore/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import { NotTranslated } from '../../../Translations';
import TeamChatMessage from './TeamChatMessage';
import * as style from './style.module.css';
interface Props {
  group: MessageGroup;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  conversationStore: ConversationStore;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

const TeamChatMessageGroup: React.FC<Props> = ({
  group,
  conversationStore,
  presenceStore,
  lastReadMessageStore,
}) => (
  <>
    <div className={style.dateWrapper}>
      <div className={style.dateLine} />
      <div className={style.date}>
        <NotTranslated text={group.dateStamp} />
      </div>
      <div className={style.dateLine} />
    </div>
    {group.messageResources.map((messageResources) => (
      <TeamChatMessage
        messageResource={messageResources}
        presenceStore={presenceStore}
        key={messageResources.payload.id}
        conversationStore={conversationStore}
        lastReadMessageStore={lastReadMessageStore}
      />
    ))}
  </>
);

export default observer(TeamChatMessageGroup);
