import { fromNullable } from 'maybeasy';
import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

interface Props {
  fillVariable?: string;
}

const CloseIcon: React.FC<Props> = ({ fillVariable }) => {
  const fill = fromNullable(fillVariable).getOrElseValue(defaultTheme.variables['icon-on-dark']);

  return (
    <SVGIcon viewBox="0 0 32 32">
      <path
        fill={fill}
        d="m2.63229,0.44304l-2.19078,2.19078l13.36999,13.36694l-13.36694,13.36846l2.18773,2.18926l13.36847,-13.36999l13.36847,13.36999l2.18926,-2.18926l-13.36999,-13.36846l13.36999,-13.36846l-2.19078,-2.19078l-13.36694,13.36999l-13.36847,-13.36542l0,-0.00304z"
      />
    </SVGIcon>
  );
};

export default observer(CloseIcon);
