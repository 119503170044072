import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const BulletedListIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 12">
      <path
        fill="currentColor"
        d="M1.167 0a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333Zm3.11.389v1.555h11.667V.39H4.278Zm-3.11 4.278a1.167 1.167 0 1 0 0 2.333 1.167 1.167 0 0 0 0-2.333Zm3.11.389V6.61h11.667V5.056H4.278Zm-3.11 4.277a1.167 1.167 0 1 0 0 2.334 1.167 1.167 0 0 0 0-2.334Zm3.11.39v1.555h11.667V9.722H4.278Z"
      />
    </SVGIcon>
  );
};

export default observer(BulletedListIcon);
