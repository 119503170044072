import * as React from 'react';
import { ActiveProgram, CompletedProgram } from '../../ProgramStore/Types';
import * as style from './style.module.css';
import ProgramCompletionDetails from './ProgramCompletionDetails';
import ProgramEndingDetails from './ProgramEndingDetails';
import ProgramSessionsDetails from './ProgramSessionsDetails';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const CompletedProgramOverviewDates: React.FC<Props> = ({ programDetail }) => {
  return (
    <div className={style.dates}>
      <ProgramCompletionDetails programDetail={programDetail} />
      <ProgramEndingDetails programDetail={programDetail} />
      <ProgramSessionsDetails programDetail={programDetail} />
    </div>
  );
};

export default CompletedProgramOverviewDates;
