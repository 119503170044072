import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import * as style from '../style.module.css';
import DisplayItemDiv from './DisplayItemDiv';
import DisplayItemSpan from './DisplayItemSpan';

interface Props {
  store: PersonStoreContract;
}

const WorkPlace: React.FC<Props> = ({ store }) => (
  <div className={style.workplace} data-test-popout-workplace={true}>
    {store.position.map((position) => <DisplayItemDiv item={position} />).getOrElseValue(<></>)}
    {store.division.map((division) => <DisplayItemDiv item={division} />).getOrElseValue(<></>)}
    {store.region
      .map((r) => (
        <DisplayItemSpan item={r.name} separator={just(',')} className={just(style.regionText)} />
      ))
      .getOrElseValue(<></>)}
    {store.country
      .map((c) => <DisplayItemSpan item={c.name} separator={nothing()} className={nothing()} />)
      .getOrElseValue(<></>)}
  </div>
);
export default observer(WorkPlace);
