import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { UnifiedLoginReactions } from './Reactions';
import { UnifiedLoginStore } from './Store';
import UnifiedLoginView from './View';

interface Props {
  appLinkRel: string;
}

function UnifiedLogin({ appLinkRel }: Props) {
  const store = useConst(() => new UnifiedLoginStore(appLinkRel));

  return (
    <>
      <UnifiedLoginView store={store} />
      <UnifiedLoginReactions store={store} fireImmediately />
    </>
  );
}

export default observer(UnifiedLogin);
