import { MediaQueryBreakPoint, TypographyVariant } from '@execonline-inc/execonline-ui';

export const getTitleVariant = (breakPoint: MediaQueryBreakPoint): TypographyVariant => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'h5';
    case 'xs':
      return 'subtitleMedium';
  }
};

export const getDescriptionVariant = (breakPoint: MediaQueryBreakPoint): TypographyVariant => {
  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
    case 'sm':
      return 'bodyLarge';
    case 'xs':
      return 'bodyMedium';
  }
};
