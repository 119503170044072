import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import Store from './Store';
import { SocketAvailability, SocketState } from './Types';

interface Props extends EAProps<Store> {}

const runDisconnect = (store: Store, socket: SocketAvailability) => {
  switch (socket.kind) {
    case 'connected':
    case 'connecting':
    case 'initialized':
    case 'unavailable':
      socket.pusher.disconnect();
      store.unmounted();
      break;
    case 'failed':
    case 'disconnected':
      break;
    default:
      assertNever(socket);
  }
};

class Reactions extends ErrorActionableReaction<Store, SocketState, Props> {
  tester = () => this.props.store.state;

  effect = (state: SocketState) => {
    switch (state.kind) {
      case 'unmounting':
        runDisconnect(this.props.store, state.socket);
        break;
      case 'error':
      case 'ready':
      case 'unmounted':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(Reactions);
