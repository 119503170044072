import { just } from 'maybeasy';
import * as React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { segmentRoute } from '../../ClientRoutes';
import ProgramStore from '../../ProgramStore';
import { T, TPlainTextKey } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';

interface Props {
  programStore: ProgramStore;
  classname?: string;
}

const beginOrResumeText = (percentComplete: number): TPlainTextKey =>
  percentComplete === 0 ? 'Begin' : 'Resume';

const BeginOrResumeButton: React.FC<Props> = ({ programStore, classname = '' }) => {
  return just({})
    .assign('currentSegment', programStore.currentSegment)
    .assign('percentComplete', programStore.percentComplete)
    .map(({ currentSegment, percentComplete }) => (
      <Link
        to={segmentRoute(currentSegment)}
        className={clsx(style.continueButton, classname)}
        data-test-continue-button={beginOrResumeText(percentComplete)}
      >
        <T kind={beginOrResumeText(percentComplete)} />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </Link>
    ))
    .getOrElse(() => <span />);
};

export default BeginOrResumeButton;
