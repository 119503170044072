import { observer } from 'mobx-react';
import * as React from 'react';
import {
  AttachableSegment,
  UploadedAttachmentResource,
} from '../../../AttachmentUploadStore/Types';
import CompleteAttachment from './CompleteAttachment';
import IncompleteAttachment from './IncompleteAttachment';
import * as style from './style.module.css';

interface Props {
  segment: AttachableSegment;
}

const UploadedAttachments: React.FC<Props> = ({ segment }) => (
  <div data-test-uploaded-attachments={true}>
    {segment.uploadedAttachments.map((assignment: UploadedAttachmentResource) => (
      <li
        key={assignment.payload.id}
        className={style.item}
        data-test-attachment={assignment.payload.id}
      >
        <div key={assignment.payload.id}>
          {assignment.payload.complete ? (
            <CompleteAttachment assignment={assignment} />
          ) : (
            <IncompleteAttachment />
          )}
        </div>
      </li>
    ))}
  </div>
);

export default observer(UploadedAttachments);
