import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import { UnifiedLoginError } from './Errors';
import { State, errored, identifyingApp, redirecting } from './States';

export class UnifiedLoginStore {
  @observable state: State;

  constructor(appLinkRel: string) {
    this.state = identifyingApp(appLinkRel);
  }

  @action redirecting = (href: string): void => {
    switch (this.state.kind) {
      case 'identifying-app':
        this.state = redirecting(href);
        break;
      case 'redirecting':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = (error: UnifiedLoginError): void => {
    switch (this.state.kind) {
      case 'identifying-app':
        this.state = errored(error);
        break;
      case 'redirecting':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };
}
