import { observer } from 'mobx-react';
import { CoachingProductDetails } from '../../../Common/Experience/Types';
import HowBestToUse from './HowBestToUse';
import KeyBenefits from './KeyBenefits';
import StudentTestimonial from './StudentTestimonial';

interface Props {
  productDetails: CoachingProductDetails;
}

function LearnMoreLeadershipDetails({ productDetails }: Props) {
  return (
    <div className="box-border flex flex-col gap-y-12 bg-white px-4 py-12 sm:p-12">
      <KeyBenefits productDetails={productDetails} />
      <StudentTestimonial productDetails={productDetails} />
      <HowBestToUse productDetails={productDetails} />
    </div>
  );
}

export default observer(LearnMoreLeadershipDetails);
