import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import SupportLink from '../../../AEP/SupportLink/SupportLink';
import { InvitationStore } from '../InvitationStore';

interface Props {
  store: InvitationStore;
}

const UseCaseInstructions: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'requesting-email-address':
    case 'authenticating-email-domain':
      return (
        <Typography>
          <T kind="Enter your email address or username to continue." />
        </Typography>
      );
    case 'requesting-profile-info':
    case 'requesting-profile-info-locked-access':
    case 'requesting-profile-info-password-backend':
    case 'registering':
      return (
        <Typography>
          <T kind="Enter your name and accept our Terms of Use..." />
        </Typography>
      );
    case 'instant-sso':
      return (
        <Typography>
          <T
            kind="Please continue to your corporate single sign-on site to log in. To bypass ..."
            link1={(content) => (
              <SupportLink link="https://support.execonline.com/s/article/How-To-Allow-Pop-ups">
                {content}
              </SupportLink>
            )}
          />
        </Typography>
      );
    case 'waiting':
    case 'validating-email-from-identified-user':
    case 'pending-email-confirmation':
    case 'authenticating-user':
    case 'registered':
    case 'error':
      return <></>;
  }
};

export default observer(UseCaseInstructions);
