import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { eventConferenceUrl } from '../../ClientRoutes';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';

interface Props {
  eventId: number;
}
const ReturnToMainButton: React.FC<Props> = ({ eventId }) => (
  <Link
    to={eventConferenceUrl(eventId)}
    className={style.returnToMainButton}
    data-test-return-to-conference={eventId}
  >
    <T kind="Return to Main Room" />
  </Link>
);

export default observer(ReturnToMainButton);
