import { warn } from '@execonline-inc/logging';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import clickOutsideStore from '../../ClickOutsideStore';

interface ModalPortalProps {
  children: React.ReactNode;
  nodeId?: string;
}

function ModalPortal({ children, nodeId }: ModalPortalProps) {
  const [rootDiv, setRootDiv] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const id = nodeId || 'root';
    const root = document.getElementById(id);
    if (root) {
      setRootDiv(root);
      clickOutsideStore.disable();
    } else {
      warn(`Root node ${id} not found. Modals may not work`);
    }

    return () => {
      clickOutsideStore.enable();
    };
  });

  return rootDiv && createPortal(children, rootDiv);
}

export default observer(ModalPortal);
