import * as React from 'react';
import { ExpandableItem } from '../../../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../../../ProgramStore/Types';
import { T } from '../../../../../Translations';
import CompleteSegmentIcon from '../../../../Cutesies/CompleteSegmentIcon';
import CurrentSegmentIcon from '../../../../Cutesies/CurrentSegmentIcon';
import UnstartedSegmentIcon from '../../../../Cutesies/UnstartedSegmentIcon';
import * as style from '../style.module.css';
import Segment from './Segment';

interface Props {
  expandableModule: ExpandableItem<ActiveProgramModule>;
}

const Segments: React.FC<Props> = ({ expandableModule }) => {
  if (expandableModule.open) {
    return (
      <div className={style.segments} data-test-segments={expandableModule.item.id}>
        <div className={style.header}>
          <h4 data-test-segments-title="Segments">
            <T kind="Segments" />
          </h4>
          <ul className={style.legend} data-test-segments-legend={true}>
            <li className={style.legendItem}>
              <div className={style.legendIcon}>
                <CurrentSegmentIcon />
              </div>
              <div className={style.legendLabel}>
                <T kind="What's Next" />
              </div>
            </li>
            <li className={style.legendItem}>
              <div className={style.legendIcon}>
                <CompleteSegmentIcon />
              </div>
              <div className={style.legendLabel}>
                <T kind="Completed" />
              </div>
            </li>
            <li className={style.legendItem}>
              <div className={style.legendIcon}>
                <UnstartedSegmentIcon />
              </div>
              <div className={style.legendLabel}>
                <T kind="Not Started" />
              </div>
            </li>
          </ul>
        </div>
        <ol>
          {expandableModule.item.segments.map((segment) => {
            return <Segment segment={segment} key={`segment-${segment.id}`} />;
          })}
        </ol>
      </div>
    );
  }
  return <span />;
};

export default Segments;
