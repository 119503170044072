import { observer } from 'mobx-react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  OpenableState,
  cn,
} from '@execonline-inc/execonline-ui';
import { EnrollmentModalContext, EnrollmentModalContextState } from '../Common';
import EnrollmentModalHeader from './EnrollmentModalHeader';
import EnrollmentModalSubHeader from './EnrollmentModalSubHeader';
import EnrollmentModalBody from './EnrollmentModalBody';
import EnrollmentModalFooter from './EnrollmentModalFooter';
import { getModalSizeByOfferingType } from './Types';

interface Props extends EnrollmentModalContextState {
  isOpen: OpenableState;
}

function ExperienceEnrollmentContent({
  isOpen,
  availability,
  invitationUuid,
  experienceResource,
  enrollmentStore,
  options,
  onClose,
  onOpen,
}: Props) {
  return (
    <EnrollmentModalContext.Provider
      value={{
        availability,
        invitationUuid,
        experienceResource,
        enrollmentStore,
        options,
        onClose,
        onOpen,
      }}
    >
      <Modal
        className={cn(
          getModalSizeByOfferingType(availability, experienceResource),
          'h-full rounded-none sm:h-auto',
        )}
        open={isOpen.kind === 'open'}
        onClose={onClose}
      >
        <ModalHeader className="flex-col gap-y-4 pt-12">
          <EnrollmentModalHeader />
          <EnrollmentModalSubHeader />
        </ModalHeader>
        <ModalBody className="pt-9">
          <EnrollmentModalBody />
        </ModalBody>
        <ModalFooter className="flex-col-reverse gap-y-3 pt-9 sm:flex-row">
          <EnrollmentModalFooter />
        </ModalFooter>
      </Modal>
    </EnrollmentModalContext.Provider>
  );
}

export default observer(ExperienceEnrollmentContent);
