import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import TeamsStore from '../../../../TeamsStore';
import TeamsReactions from '../../../../TeamsStore/TeamsReactions';
import Teams from './Teams';

interface Props {
  programDetailResource: ProgramDetailResource;
}

@observer
class TeamsSummary extends React.Component<Props, {}> {
  private teamsStore: TeamsStore;

  constructor(props: Props) {
    super(props);
    this.teamsStore = new TeamsStore();
  }

  componentDidMount() {
    this.teamsStore.loading();
  }

  render() {
    return (
      <>
        <Teams store={this.teamsStore} />
        <TeamsReactions
          store={this.teamsStore}
          resourceWithTeams={this.props.programDetailResource}
        />
      </>
    );
  }
}

export default TeamsSummary;
