import { EyeIcon, EyeSlashIcon, IconButton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import FormTextField from './FormTextField';
import { FormTextFieldProps } from './Types';

function PasswordField(props: FormTextFieldProps) {
  const [visible, setVisible] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setVisible(!visible);
  };

  return (
    <FormTextField
      type={visible ? 'text' : 'password'}
      label={<T kind="Password" />}
      {...props}
      endContent={
        <IconButton
          className="h-full border-l border-solid border-gray-300 pl-2"
          radius="none"
          tabIndex={-1}
          onClick={handleClick}
        >
          {visible ? <EyeSlashIcon /> : <EyeIcon />}
        </IconButton>
      }
    />
  );
}

export default observer(PasswordField);
