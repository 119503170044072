import DailyIframe, { DailyCall, DailyCallOptions, DailyLanguage } from '@daily-co/daily-js';
import { log, warn } from '@execonline-inc/logging';
import { TranslationsContext } from '@execonline-inc/translations';
import { noop } from '@kofno/piper';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Task } from 'taskarian';
import { AppyError, deleteToApi, postToApi } from '../../Appy';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { MissingLinkError, findLink, findLinkT } from '../../LinkyLinky';
import { profileStore } from '../../ProfileStore';
import { Link } from '../../Resource/Types';
import { SupportedLanguageCode } from '../../SupportedLanguages/Types';
import { translation } from '../../Translations';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
  currentUserResource: CurrentUserResource;
}

const convertedLanguageCode = (languageCode: string) => {
  switch (languageCode) {
    case 'ja':
      return 'jp';
    default:
      return languageCode;
  }
};

const isDailyCompatible = (languageCode: string): languageCode is DailyLanguage =>
  [
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'it',
    'jp',
    'ka',
    'nl',
    'no',
    'pl',
    'pt',
    'pt-BR',
    'ru',
    'sv',
    'tr',
  ].includes(convertedLanguageCode(languageCode));

const dailyCompatibleLocale = (languageCode: SupportedLanguageCode): DailyLanguage => {
  const converted = convertedLanguageCode(languageCode);
  return isDailyCompatible(converted) ? converted : 'en';
};

class DailyEmbed extends React.Component<Props, {}> {
  iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  callFrame: DailyCall | undefined;

  updateParticipants = () => {
    if (this.callFrame) {
      this.props.conferenceRosterStore.updating(
        Object.entries(this.callFrame.participants()).map(([_, value]) => ({
          uuid: value.user_id,
          name: value.user_name,
        })),
      );
    }
  };

  handleAppyError = (error: AppyError | MissingLinkError) =>
    warn('Failed to update staff status in ConferenceEmbed', error);

  async setupCallFrame() {
    if (this.iframeRef.current) {
      try {
        this.callFrame = DailyIframe.wrap(this.iframeRef.current);
        this.callFrame.setTheme({
          colors: {
            accent: '#187a90',
            accentText: '#FFFFFF',
            background: '#FFFFFF',
            backgroundAccent: '#FFFFFF',
            baseText: '#212121',
            border: '#333',
            mainAreaBg: '#FFFFFF',
            mainAreaBgAccent: '#187a90',
            mainAreaText: '#FFFFFF',
            supportiveText: '#888888',
          },
        });

        this.callFrame
          .on('loading', (event) => {
            log('[DAILY] Loading', event);
          })
          .on('loaded', (event) => {
            log('[DAILY] Loaded', event);
          })
          .on('joining-meeting', (event) => {
            log('[DAILY] Joining meeting', event);
          })
          .on('joined-meeting', (event) => {
            log('[DAILY] Joined meeting', event);
            this.updateParticipants();
          })
          .on('participant-joined', (event) => {
            log('[DAILY] Participant joined', event);
            this.updateParticipants();

            Task.succeed<AppyError | MissingLinkError, ReadonlyArray<Link>>(
              this.props.conferenceRoomResource.links,
            )
              .andThen(findLinkT('staff-join'))
              .andThen(postToApi({}))
              .fork(this.handleAppyError, noop);
          })
          .on('participant-left', (event) => {
            log('[DAILY] Participant left', event);
            this.updateParticipants();
          })
          .on('left-meeting', (event) => {
            log('[DAILY] Left meeting', event);
            this.props.conferenceRosterStore.stop();
          });

        just({})
          .assign('link', findLink('conference-room', this.props.conferenceRoomResource.links))
          .assign(
            'name',
            profileStore.resource.andThen(({ payload }) => payload.name),
          )
          .do(({ link, name }) => {
            const props: DailyCallOptions = {
              userName: name,
              url: link.href,
              showLeaveButton: true,
              startAudioOff: true,
              lang: this.props.currentUserResource.payload.preferredLanguage
                .map(dailyCompatibleLocale)
                .getOrElseValue('en'),
            };

            if (this.callFrame) {
              const callFrame = this.callFrame;
              this.props.conferenceRoomResource.payload.token
                .do((token) => {
                  callFrame.join({ ...props, token });
                })
                .elseDo(() => {
                  callFrame.join(props);
                });

              callFrame.updateInputSettings({
                audio: {
                  processor: {
                    type: 'noise-cancellation',
                  },
                },
              });
            }
          });
      } catch (e) {
        log('[DAILY] Duplicate callFrame found');
        this.callFrame = DailyIframe.getCallInstance();
        await this.callFrame.destroy();
        this.setupCallFrame();
      }
    }
  }

  componentDidMount() {
    if (this.iframeRef.current) {
      this.iframeRef.current.setAttribute('allow', 'microphone; camera; autoplay; display-capture');
      this.setupCallFrame();
    }
  }

  componentWillUnmount(): void {
    log('[DAILY] Unmount');
    if (this.callFrame) {
      this.callFrame.destroy();
    }
    log('[DAILY] Call state', this.callFrame?.meetingState());

    Task.succeed<AppyError | MissingLinkError, ReadonlyArray<Link>>(
      this.props.conferenceRoomResource.links,
    )
      .andThen(findLinkT('staff-left'))
      .andThen(deleteToApi)
      .fork(this.handleAppyError, noop);

    this.props.conferenceRosterStore.stop();
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => (
            <iframe
              title={translation('Embedded Conference Room')(ts)}
              ref={this.iframeRef}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                margin: '0',
                padding: '0',
                overflow: 'hidden',
              }}
            />
          )}
        </TranslationsContext.Consumer>
      </>
    );
  }
}

export default observer(DailyEmbed);
