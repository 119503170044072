import {
  CaretDownIcon,
  Menu,
  NavBarItem,
  NavBarLink,
  useOpenable,
} from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { handleEnterKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { T } from '../../../../Translations';
import { NavigationMenuItems } from '../Common';
import { UseCaseMenuItemProps, dropdownMenuItems } from './Types';

const menuId = 'useCaseNavigationMenu';

function UseCaseDropdownMenu({ items }: UseCaseMenuItemProps) {
  const anchorElementRef = useRef<HTMLAnchorElement>(null);
  const { isOpen, onClose, onOpenChange } = useOpenable();

  return (
    <>
      <Route
        path="/register"
        children={({ match }: RouteComponentProps<{}>) => {
          return (
            <NavBarItem aria-haspopup="menu" aria-expanded={isOpen}>
              <NavBarLink
                id={menuId}
                ref={anchorElementRef}
                className="flex flex-shrink-0 items-center"
                active={fromNullable(match)
                  .map(() => true)
                  .getOrElseValue(false)}
                onClick={onOpenChange}
                onKeyDown={handleEnterKeyPress(onOpenChange)}
              >
                <T kind="Learning Collections" />
                <CaretDownIcon className="ml-2" size="xs" color="inherit" />
              </NavBarLink>
              <Menu
                aria-labelledby={menuId}
                className="max-h-72 max-w-[28rem]"
                open={isOpen}
                anchorElement={anchorElementRef.current}
                onClose={onClose}
              >
                <NavigationMenuItems items={dropdownMenuItems(items)} />
              </Menu>
            </NavBarItem>
          );
        }}
      />
    </>
  );
}

export default observer(UseCaseDropdownMenu);
