import { readVarR } from '@execonline-inc/environment';
import { warn } from '@execonline-inc/logging';
import { toResult } from '@execonline-inc/maybe-adapter';
import { always, assertNever, noop } from '@kofno/piper';
import { Maybe, just } from 'maybeasy';
import { toJS } from 'mobx';
import { Result, ok } from 'resulty';
import { resourceFromContingentStore } from '../../../CurrentUser/Context/WhenUserDataLoaded/Types';
import { State as CurrentUserState } from '../../../CurrentUser/States';
import { CurrentUserStore } from '../../../CurrentUser/Store';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { warnAndNotify } from '../../../Honeybadger';
import { organizationStore } from '../../../Organization/Store';
import { OrganizationResource } from '../../../Organization/Types';
import { profileStore } from '../../../ProfileStore';
import { ProfileResource } from '../../../ProfileStore/Types';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { fromResult } from '../../../TaskExt';
import { IdentifyToFsError, MissingValue, missingValue } from './Errors';
import { FSUser, FsFn } from './Types';

interface ContingentResources {
  organizationResource: Maybe<OrganizationResource>;
  profileResource: Maybe<ProfileResource>;
}

interface ReactionTrigger {
  currentUserState: CurrentUserState;
  contingentResources: Maybe<ContingentResources>;
}

interface Props extends RCProps<CurrentUserStore> {
  fs: FsFn;
}

export class Identification extends ReactionComponent<CurrentUserStore, ReactionTrigger, Props> {
  contingentResources = (): Maybe<ContingentResources> =>
    just({})
      .assign('organizationResource', resourceFromContingentStore(organizationStore))
      .assign('profileResource', resourceFromContingentStore(profileStore));

  tester = () => ({
    currentUserState: this.props.store.state,
    contingentResources: this.contingentResources(),
  });

  effect = ({ currentUserState, contingentResources }: ReactionTrigger): void => {
    switch (currentUserState.kind) {
      case 'ready':
      case 'refreshing':
      case 'logging-out':
        contingentResources.map(identifyUser(currentUserState.currentUserResource)).cata({
          Just: (task) =>
            task.fork(handleIdentifyToFsError, ({ contactUid, ...rest }) =>
              this.props.fs.identify(contactUid, rest)
            ),
          Nothing: noop,
        });
        break;
      case 'waiting':
      case 'loading':
      case 'anonymous':
      case 'errored':
        break;
      default:
        assertNever(currentUserState);
    }
  };
}

const identifyUser = (currentUserResource: CurrentUserResource) => ({
  organizationResource,
  profileResource,
}: ContingentResources) =>
  fromResult(
    ok<IdentifyToFsError, {}>({})
      .map(always({ platformUserId: currentUserResource.payload.id.toString() }))
      .assign('environment_str', readVarR('REACT_APP_FULLSTORY_ENVIRONMENT'))
      .andThen(
        assign(
          'account_str',
          organizationResource.andThen(({ payload }) => payload.accountUid)
        )
      )
      .andThen(
        assign(
          'platformOrgId',
          organizationResource.map(({ payload }) => payload.id.toString())
        )
      )
      .andThen(
        assign(
          'displayName',
          profileResource.andThen(({ payload }) => payload.shortName)
        )
      )
      .andThen(assign('contactUid', currentUserResource.payload.contactUid))
      .map((properties): FSUser & { contactUid: string } => ({ ...properties, platform: 'P3' }))
  );

const assign = <K extends string>(key: K, value: Maybe<string>) => <A>(
  prev: A
): Result<MissingValue, A & { [k in K]: string }> =>
  ok<MissingValue, A>(prev).assign(key, toResult(missingValue(key), value));

export const handleIdentifyToFsError = (error: IdentifyToFsError): void => {
  switch (error.kind) {
    case 'missing-var-error':
      warnAndNotify('FullStoryTooling', "FullStory isn't configured properly.", toJS(error));
      break;
    case 'missing-value':
      warn(`FullStory cannot initialize without value for ${error.value}`);
      break;
    default:
      assertNever(error);
  }
};
