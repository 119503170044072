import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import TileViewProgramExperiencePreview from '../../../ProgramExperience/TileViewProgramExperiencePreview';
import { useEnrolledModalContext } from '../EnrolledModalContext';

function EnrolledModalBody() {
  const { invitationUuid, experienceResource } = useEnrolledModalContext();

  return experienceResource
    .map((experienceResource) => (
      <div className="mx-auto flex flex-col gap-y-10">
        <div className="mx-auto">
          <TileViewProgramExperiencePreview
            invitationUuid={invitationUuid}
            experienceResource={experienceResource}
          />
        </div>
        <Typography className="text-black" variant="bodyMedium">
          <T kind="If Leadership Coaching is also available to you and you have not enrolled yet, you can do so from the Learning Collection at any time..." />
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(EnrolledModalBody);
