import { stringLiteral } from '@execonline-inc/decoders';
import Decoder, { array, boolean, field, oneOf, string, succeed } from 'jsonous';
import { VideoPlaybackSource, VideoPlaybackSourceLabel } from '../../components/JWPlayer/Types';
import {
  PreferredVideoPlayback,
  SubtitleKind,
  VideoPlaybackSubtitles,
} from '../../components/ReqHlsVideo/Types';
import { VideoPlaybackQuality, VideoPlaybackType } from '../../components/Video/Types';

const videoPlaybackTypeDecoder: Decoder<VideoPlaybackType> = oneOf([
  stringLiteral<VideoPlaybackType>('vimeo'),
  stringLiteral<VideoPlaybackType>('progressive'),
  stringLiteral<VideoPlaybackType>('adaptive'),
]);

const videoPlaybackQualityDecoder: Decoder<VideoPlaybackQuality> = oneOf([
  stringLiteral<VideoPlaybackQuality>('low'),
  stringLiteral<VideoPlaybackQuality>('medium'),
  stringLiteral<VideoPlaybackQuality>('high'),
]);

export const preferredVideoPlaybackDecoder: Decoder<PreferredVideoPlayback> = succeed({})
  .assign('type', field('type', videoPlaybackTypeDecoder))
  .assign('qualities', field('qualities', array(videoPlaybackQualityDecoder)));

const videoPlaybackSourceLabelDecoder: Decoder<VideoPlaybackSourceLabel> = oneOf([
  stringLiteral<VideoPlaybackSourceLabel>('Low'),
  stringLiteral<VideoPlaybackSourceLabel>('Medium'),
  stringLiteral<VideoPlaybackSourceLabel>('High'),
]);

export const videoPlaybackSourceDecoder: Decoder<VideoPlaybackSource> = succeed({})
  .assign('file', field('file', string))
  .assign('label', field('label', videoPlaybackSourceLabelDecoder))
  .assign('default', field('default', boolean));

export const subtitleKindDecoder: Decoder<SubtitleKind> = oneOf<SubtitleKind>([
  stringLiteral<SubtitleKind>('auto-load-on'),
  stringLiteral<SubtitleKind>('auto-load-off'),
]);

export const videoPlaybackSubtitlesDecoder: Decoder<VideoPlaybackSubtitles> = succeed({})
  .assign('file', field('file', string))
  .assign('label', field('label', string))
  .assign('kind', field('kind', subtitleKindDecoder));
