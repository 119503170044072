import { jsonValueDecoder, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { field, oneOf, succeed } from 'jsonous';
import { CompleteAdvance, CompleteDontAdvance, GetResults, Results, WindowMessage } from './Types';

const completeAdvanceDecoder: Decoder<CompleteAdvance> = succeed({}).assign(
  'kind',
  stringLiteral('complete-advance')
);

const getResultsDecoder: Decoder<GetResults> = succeed({}).assign(
  'kind',
  stringLiteral('get-results')
);

const completeDontAdvanceDecoder: Decoder<CompleteDontAdvance> = succeed({}).assign(
  'kind',
  stringLiteral('complete-dont-advance')
);

const resultsDecoder: Decoder<Results> = succeed({ kind: 'results' as 'results' }).assign(
  'segmentPayload',
  field('segment_payload', jsonValueDecoder)
);

export const windowMessageDecoder: Decoder<WindowMessage> = oneOf<WindowMessage>([
  completeAdvanceDecoder.map<WindowMessage>(identity),
  completeDontAdvanceDecoder.map<WindowMessage>(identity),
  resultsDecoder.map<WindowMessage>(identity),
  getResultsDecoder.map<WindowMessage>(identity),
]);
