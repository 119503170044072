import { useContext } from 'react';
import { ExperiencesContext } from './Types';

export function useExperiencesContext() {
  const context = useContext(ExperiencesContext);

  if (!context) {
    throw new Error('useExperiencesContext has to be used within the ExperiencesContextProvider.');
  }

  return context;
}
