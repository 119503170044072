import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { DocumentAssetResource } from '../../SegmentStore/Types';
import { NotTranslated } from '../../Translations';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import EmbeddedDocumentAssetReactions from './Reactions';
import EmbeddedDocumentAssetStore from './Store';
import * as style from './style.module.css';

interface Props {
  uuid: string;
  embeddedDocumentAssets: DocumentAssetResource[];
}

function EmbeddedDocumentAsset({ uuid, embeddedDocumentAssets }: Props) {
  const { openableState, open, close } = useOpenable();
  const store = useMemo(
    () => new EmbeddedDocumentAssetStore(uuid, embeddedDocumentAssets),
    [uuid, embeddedDocumentAssets],
  );

  useEffect(() => {
    store.initialize();
  }, [store]);

  const { state } = store;

  switch (state.kind) {
    case 'waiting':
    case 'initializing':
      return <EmbeddedDocumentAssetReactions store={store} />;
    case 'ready':
      return (
        <>
          <span className={style.link} onClick={open}>
            <NotTranslated text={state.document.payload.title} />
          </span>
          <DownloadViewModal resource={state.document} close={close} modalState={openableState} />
          <EmbeddedDocumentAssetReactions store={store} />
        </>
      );
  }
}

export default observer(EmbeddedDocumentAsset);
