import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import { AppyError, postToApi, putToApi } from '../../../../../../../Appy';
import { Link } from '../../../../../../../Resource/Types';
import { TPlainTextKey } from '../../../../../../../Translations';
import { enrollmentResourceDecoder } from '../../../../../DiscoveryPortal/Enrollment/Store/Decoders';
import { EnrollmentResource } from '../../../../../DiscoveryPortal/Enrollment/Store/Types';
import PaymentWizardStore from '../PaymentWizardStore';
import { MessageReceived, PaymentIntentError } from '../PaymentWizardStore/Types';
import { paymentIntentErrorDecoder } from './Decoders';

const WAIT_DURATION = 15000;

const paymentIntentStatusLink = (): Link => ({
  rel: 'view',
  href: `/aep/payment_intent_statuses`,
  method: 'get',
  type: 'text/html',
});

const paymentStateLink = (paymentIntentId: string): Link => ({
  rel: 'update',
  href: `/aep/payment_states/${paymentIntentId}`,
  method: 'put',
  type: 'text/html',
});

const decodeAndHandlePusherSuccessMessage = (
  store: PaymentWizardStore,
  responseBody: unknown,
  paymentIntentId: string,
): Result<string, EnrollmentResource> =>
  enrollmentResourceDecoder
    .decodeAny(responseBody)
    .do(store.paidAndEnrolled(paymentIntentId))
    .elseDo((error) => {
      warn('DecodePusherSuccessMessageFailed', error);
      store.error('We are experiencing an issue');
    });

const handlePusherErrorByType =
  (store: PaymentWizardStore) =>
  (body: PaymentIntentError): void => {
    switch (body.type) {
      case 'enrollment-error':
        return store.enrollmentError(
          'We are experiencing an issue',
          'Your payment was successful but there is a problem with your enrollment. Please contact support@execonline.com for assistance.',
        );
      case 'payment-declined-error':
        return store.paymentError(
          'We could not process your payment',
          'Your payment was declined by the credit card provider. Please go back to the previous page and try submitting payment again using a different credit card, or contact support@execonline.com for assistance.',
        );
      case 'internal-server-error':
        return store.error('We are experiencing an issue');
      default:
        assertNever(body.type);
    }
  };
const handlePusherErrorFailure =
  (store: PaymentWizardStore) =>
  (error: string): void => {
    warn('DecodePusherErrorMessageFailed', error);
    store.error('We are experiencing an issue');
  };

const decodeAndHandlePusherErrorMessage = (
  store: PaymentWizardStore,
  responseBody: unknown,
): Result<string, PaymentIntentError> =>
  paymentIntentErrorDecoder
    .decodeAny(responseBody)
    .do(handlePusherErrorByType(store))
    .elseDo(handlePusherErrorFailure(store));

const createPaymentIntentStatusRequest = (paymentIntentId: string): Task<AppyError, {}> =>
  postToApi({ payment_intent_id: paymentIntentId })(paymentIntentStatusLink());

export const setPaymentStateProcessing = (paymentIntentId: string) =>
  putToApi({ state: 'processing' }, paymentStateLink(paymentIntentId));

const handlePaymentIntentRequestError =
  (store: PaymentWizardStore) =>
  (error: AppyError): void => {
    warn('MissingLinkError', String(error.kind));
    store.error('We are experiencing an issue');
  };

const raisePusherError = (store: PaymentWizardStore, paymentIntentId: string) => {
  store.pusherError(
    <TPlainTextKey>'Payment Not Confirmed',
    <TPlainTextKey>(
      'Your payment could not be confirmed due to a service interruption. Please contact support@execonline.com for assistance.'
    ),
    paymentIntentId,
  );
};

export const raisePusherErrorAfterTimeout = (
  store: PaymentWizardStore,
  paymentIntentId: string,
): void => {
  setTimeout(() => raisePusherError(store, paymentIntentId), WAIT_DURATION);
};

export const raiseAfterProcessingTimeout = (store: PaymentWizardStore): void => {
  setTimeout(
    () =>
      store.paymentError(
        <TPlainTextKey>'Payment Not Confirmed',
        <TPlainTextKey>(
          'Your payment cannot be confirmed at this time. Please contact support@execonline.com for assistance.'
        ),
      ),
    WAIT_DURATION,
  );
};

export const requestPaymentIntentStatus = (
  store: PaymentWizardStore,
  paymentIntentId: string,
): void => {
  createPaymentIntentStatusRequest(paymentIntentId).fork(
    handlePaymentIntentRequestError(store),
    () => store.paymentStatusRequested(paymentIntentId),
  );
};

export const fetchContextAfterTimeout = (store: PaymentWizardStore, paymentIntentId: string) =>
  setTimeout(() => store.requestPaymentStatus(paymentIntentId), WAIT_DURATION);

export const handleMessageReceived = (state: MessageReceived, store: PaymentWizardStore): void => {
  switch (state.eventName) {
    case 'processing':
      store.paymentStillProcessing(state.paymentIntentId);
      break;
    case 'processing-timed-out':
      store.error('We are experiencing an issue');
      break;
    case 'succeeded':
      decodeAndHandlePusherSuccessMessage(store, state.responseBody, state.paymentIntentId);
      break;
    case 'error':
      decodeAndHandlePusherErrorMessage(store, state.responseBody);
      break;
    default:
      assertNever(state.eventName);
  }
};
