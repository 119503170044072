import { observer } from 'mobx-react';
import clsx from 'clsx';
import ModalBody from './ModalBody';
import ModalContainer from './ModalContainer';
import ModalFocusTrap from './ModalFocusTrap';
import ModalShade from './ModalShade';
import ModalHeader from './ModalHeader';
import ModalTitle from './ModalTitle';
import { CloseAction } from '../Openable';
import { ModalSize } from './Types';
import * as style from './style.module.css';

interface ModalContentProps {
  close: CloseAction;
  title: React.ReactNode;
  size?: ModalSize;
  children: React.ReactNode;
}

function ModalContent({ size, title, close, children }: ModalContentProps) {
  return (
    <ModalFocusTrap>
      <ModalContainer onEscPressed={close}>
        <ModalBody size={size}>
          <ModalHeader onClose={close}>
            <ModalTitle>{title}</ModalTitle>
          </ModalHeader>
          <div
            className={clsx('pt-5 px-4 sm:px-8 pb-8', style.content)}
            data-test-modal-content={true}
          >
            {children}
          </div>
        </ModalBody>
        <ModalShade close={close} />
      </ModalContainer>
    </ModalFocusTrap>
  );
}

export default observer(ModalContent);
