import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { InvitationActivation } from '../../../../../../../DiscoveryStore/Types';
import RegistrationTypeBadge from './RegistrationTypeBadge';

interface Props {
  registrationType: InvitationActivation;
}

function RegistrationType({ registrationType }: Props) {
  switch (registrationType.kind) {
    case 'active-invitation':
      return <RegistrationTypeBadge type={registrationType.type} />;
    case 'inactive-invitation':
      return emptyFragment();
  }
}

export default observer(RegistrationType);
