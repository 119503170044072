import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import {
  ConferenceParticipant,
  ConferenceParticipants,
} from '../../../ConferenceRosterStore/Types';
import { T } from '../../../Translations';
import Participant from '../Participant';
import * as style from '../style.module.css';

interface Props {
  participants: ConferenceParticipants;
}

const SupportStaff: React.FC<Props> = ({ participants }) => {
  return fromEmpty(
    participants.filter(
      (participant: ConferenceParticipant) =>
        participant.participantType === 'Coach' ||
        participant.participantType === 'Professor' ||
        participant.participantType === 'Observer'
    )
  ).cata({
    Just: (participants: ConferenceParticipants) => (
      <div className={style.supportGroup} data-test-support-staff={true}>
        <h2 className={style.supPanelHeader}>
          <T kind="Support Staff" />
        </h2>
        <ul>
          {participants.map((participant: ConferenceParticipant) => (
            <Participant
              conferenceRosterStore={nothing<ConferenceRosterStore>()}
              key={participant.id}
              participant={participant}
            />
          ))}
        </ul>
      </div>
    ),
    Nothing: () => <></>,
  });
};

export default observer(SupportStaff);
