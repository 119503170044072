import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from '../../ClientRoutes';
import { T } from '../../Translations';

function ForgotPasswordField() {
  return (
    <Link to={resetPasswordRoute} data-test-forgot-password-link={true}>
      <Typography
        className="text-neutral-900 underline opacity-70 hover:text-neutral-900 hover:opacity-100"
        variant="bodyMedium"
        as="span"
      >
        <T kind="I forgot my password" />
      </Typography>
    </Link>
  );
}

export default observer(ForgotPasswordField);
