import { Maybe } from 'maybeasy';
import * as React from 'react';
import { ActiveProgramModule } from '../../../../../ProgramStore/Types';
import { L, T } from '../../../../../Translations';
import CircleIcon from '../../../../Cutesies/CircleIcon';
import CompletedModuleIcon from '../../../../Cutesies/CompletedModuleIcon';
import FlagIcon from '../../../../Cutesies/FlagIcon';
import * as style from '../../../../ProgramSummary/style.module.css';

interface Props {
  programModule: ActiveProgramModule;
  daysBehind: number;
}

const fillColor = (percent: number, expectedPercentComplete: Maybe<number>, daysBehind: number) => {
  const color = daysBehind > 13 ? '#B3090F' : '#D99F23';
  if (percent >= 100) {
    return '#1A8927';
  }
  return expectedPercentComplete
    .map((epc) => {
      return epc >= 100 ? color : '#DEE6E7';
    })
    .getOrElseValue('#DEE6E7');
};

const ModuleIcon: React.FC<Props> = ({ programModule, daysBehind }) => {
  const color = fillColor(
    programModule.percentComplete,
    programModule.expectedPercentComplete,
    daysBehind
  );
  if (programModule.isMilestone) {
    return (
      <div className={style.moduleFinal}>
        <div className={style.programEndDateTooltip} data-test-progress-tooltip={true}>
          <T
            kind="Experience ends <date/>"
            date={programModule.endsOn
              .map((endsOn) => <L localizeable={endsOn} format="short-month-day-year" />)
              .getOrElseValue(<></>)}
          />
          <div className={style.programEndDateTooltipArrow} />
        </div>
        <FlagIcon fillColor={color} />
      </div>
    );
  } else if (programModule.percentComplete >= 100) {
    return <CompletedModuleIcon />;
  } else {
    return <CircleIcon fillColor={color} />;
  }
};

export default ModuleIcon;
