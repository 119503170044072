import { UnifiedLoginError } from './Errors';

export interface IdentifyingApp {
  kind: 'identifying-app';
  appLinkRel: string;
}

export interface Redirecting {
  kind: 'redirecting';
  href: string;
}

export interface Errored {
  kind: 'errored';
  error: UnifiedLoginError;
}

export type State = IdentifyingApp | Redirecting | Errored;

export const identifyingApp = (appLinkRel: string): IdentifyingApp => ({
  kind: 'identifying-app',
  appLinkRel,
});

export const redirecting = (href: string): Redirecting => ({ kind: 'redirecting', href });

export const errored = (error: UnifiedLoginError): Errored => ({
  kind: 'errored',
  error,
});
