import { observer } from 'mobx-react';
import { GroupCoachingSessionDetails } from '../../../../Common/Experience/Types';
import {
  GroupCoachSessionDateCalendar,
  GroupCoachSessionDescription,
  GroupCoachSessionProfile,
  GroupCoachSessionHeader,
} from '../Common';

interface Props {
  session: GroupCoachingSessionDetails;
}

function GroupCoachingSessionsListItem({ session }: Props) {
  const { title, startTime, groupCoachProfile, description } = session;

  return (
    <div className="flex">
      <GroupCoachSessionDateCalendar startTime={startTime} />
      <div className="flex flex-col gap-y-3 px-3 py-3 sm:gap-y-3.5 sm:px-5">
        <GroupCoachSessionHeader title={title} startTime={startTime} />
        <GroupCoachSessionDescription description={description} />
        <GroupCoachSessionProfile profile={groupCoachProfile} />
      </div>
    </div>
  );
}

export default observer(GroupCoachingSessionsListItem);
