import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

interface DownloadViewRoutingProps {
  uploadedByYou: () => JSX.Element;
  otherwise?: () => JSX.Element;
}

function DownloadViewRouting({ uploadedByYou, otherwise }: DownloadViewRoutingProps) {
  return (
    <Switch>
      <Route path="/resources/uploaded_by_you" exact={true}>
        {uploadedByYou()}
      </Route>
      <Route>{otherwise ? otherwise() : <></>}</Route>
    </Switch>
  );
}

export default observer(DownloadViewRouting);
