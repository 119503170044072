import { just, Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';

export interface Engagement {
  activityType: EngagementActivityType;
  engageableType: EngagementEngageableType;
  engageableId: number;
  programId: Maybe<number>;
}

export const programShowEngagement = (programId: number): Engagement => {
  return {
    activityType: 'show',
    engageableType: 'program',
    engageableId: programId,
    programId: just(programId),
  };
};

export type EngagementActivityType = 'show' | 'ended'; // | 'future activity type'
export type EngagementEngageableType = 'program' | 'video_asset'; // | 'future engageable type'

interface Ready {
  kind: 'ready';
}

interface Creating {
  kind: 'creating';
  engagement: Engagement;
}

export type EngagementState = Ready | Creating | Error;

export const ready = (): Ready => ({
  kind: 'ready',
});

export const creating = (engagement: Engagement): Creating => ({
  kind: 'creating',
  engagement,
});
