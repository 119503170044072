import { observer } from 'mobx-react';
import { just, nothing } from 'maybeasy';
import { PaymentIntent } from '@stripe/stripe-js';
import Exit from '../../../../Exit';
import { useDiscoveryContext } from '../../../DiscoveryContext';
import { EnrollmentResource } from '../../../DiscoveryPortal/Enrollment/Store/Types';
import { exitToDashboard } from '../../Experience/Actions/Exitable/Types';
import { useEnrollmentModalContext } from '../Common';
import { PaymentWizardStore } from './Common';
import PaymentCheckoutModalBody from './PaymentCheckoutModalBody';
import PaymentProcessingModalBody from './PaymentProcessingModalBody';
import PaymentSuccessfulModalBody from './PaymentSuccessfulModalBody';
import PaymentErrorModalBody from './PaymentErrorModalBody';

interface Props {
  store: PaymentWizardStore;
}

function ExperiencePaymentWizard({ store }: Props) {
  const discoveryContextState = useDiscoveryContext();
  const { availability, enrollmentStore } = useEnrollmentModalContext();

  function handlePaymentSuccess(data: PaymentIntent) {
    store.waitingForMessage(data.id);
  }

  function handleContinue(enrollmentResource: EnrollmentResource) {
    return (paymentIntentId: string) => {
      return store.readyToRedirect(enrollmentResource, paymentIntentId);
    };
  }

  function handleBackToPayment() {
    store.resetPayment();
    enrollmentStore.resetPayment(availability, discoveryContextState, nothing());
  }

  switch (store.state.kind) {
    case 'initializing':
      return <PaymentCheckoutModalBody onSuccess={handlePaymentSuccess} />;
    case 'waiting-for-message':
    case 'request-payment-status':
    case 'payment-status-requested':
    case 'message-received':
    case 'payment-still-processing':
      return <PaymentProcessingModalBody store={store} state={store.state} />;
    case 'paid-and-enrolled':
      const { paymentIntentId, enrollmentResource } = store.state;

      return (
        <PaymentSuccessfulModalBody
          paymentIntentId={paymentIntentId}
          enrollmentResource={enrollmentResource}
          onContinue={handleContinue(enrollmentResource)}
        />
      );
    case 'error':
    case 'payment-error':
    case 'enrollment-error':
      return (
        <PaymentErrorModalBody
          message="We are experiencing an issue"
          description="Your payment was declined by the credit card provider. Please go back to the previous page and try submitting payment again using a different credit card, or contact support@execonline.com for assistance."
          paymentIntentId={nothing()}
          onBackToPayment={handleBackToPayment}
        />
      );
    case 'pusher-error':
      const { message, detail } = store.state;
      return (
        <PaymentErrorModalBody
          message={message}
          description={detail}
          paymentIntentId={just(store.state.paymentIntentId)}
          onBackToPayment={handleBackToPayment}
        />
      );
    case 'ready-to-redirect':
      return <Exit exitContext={exitToDashboard()} />;
  }
}

export default observer(ExperiencePaymentWizard);
