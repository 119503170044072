import { observer } from 'mobx-react';
import ProgramStore from '../../ProgramStore';
import { ActiveProgram, ProductDetails, ProgramDetailResource } from '../../ProgramStore/Types';
import ActiveCoachingProductOverview from './ActiveCoachingProductOverview';
import ActiveStreamlinedProductOverview from './ActiveStreamlinedProductOverview';

interface Props {
  productDetails: ProductDetails;
  store: ProgramStore;
  activeProgram: ActiveProgram;
  programDetailResource: ProgramDetailResource;
}

const ActiveProgramStreamlinedOverview: React.FC<Props> = ({
  productDetails,
  store,
  activeProgram,
  programDetailResource,
}) => {
  switch (productDetails.kind) {
    case 'program-sequence':
    case 'program':
    case 'group-coaching':
      return (
        <ActiveStreamlinedProductOverview
          store={store}
          programDetailResource={programDetailResource}
        />
      );
    case 'coaching':
      return (
        <ActiveCoachingProductOverview
          store={store}
          coachingProductDetails={productDetails}
          activeProgram={activeProgram}
        />
      );
  }
};

export default observer(ActiveProgramStreamlinedOverview);
