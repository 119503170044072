import Decoder, { field, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { Program, ProgramResource } from '../Types';
import { teamChatResourceDecoder as programChatResourceDecoder } from '../../TeamsStore/Decoders';

const programDecoder: Decoder<Program> = succeed({})
  .assign('name', field('name', string))
  .assign('id', field('id', number))
  .assign('programChat', field('program_chat', programChatResourceDecoder));

export const programResourceDecoder: Decoder<ProgramResource> = resourceDecoder(programDecoder);
