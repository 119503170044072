import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const CheckmarkIcon: React.FC = () => {
  return (
    <SVGIcon width="17" height="13" viewBox="0 0 17 13">
      <path
        fill="#555"
        d="M2.207 5.793A1 1 0 0 0 .793 7.207l5 5a1 1 0 0 0 1.45-.038l9-10A1 1 0 1 0 14.757.831l-8.295 9.217-4.255-4.255Z"
      />
    </SVGIcon>
  );
};

export default observer(CheckmarkIcon);
