import { assertNever } from '@kofno/piper';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import ConversationStore from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import { ConversationState } from './Types';

export interface Props extends EAProps<ConversationStore> {
  store: ConversationStore;
  messageListRef: React.RefObject<HTMLDivElement>;
}

class ConversationUIReactions extends ErrorActionableReaction<
  ConversationStore,
  ConversationState,
  Props
> {
  scrollHeight: Maybe<number> = nothing();

  tester = () => this.props.store.state;

  effect = (state: ConversationState) => {
    const { store, messageListRef } = this.props;
    switch (state.kind) {
      case 'reloading':
      case 'loading':
      case 'ready':
      case 'loading-previous':
      case 'error':
      case 'waiting':
      case 'load-new-message':
      case 'load-new-reply':
      case 'update-message':
      case 'first-loaded':
      case 'update-reply':
        break;
      case 'loading-next':
        fromNullable(messageListRef.current).do((node) => {
          this.scrollHeight = just(node.scrollHeight);
        });
        break;
      case 'load-next-success':
        just({})
          .assign('node', fromNullable(messageListRef.current))
          .assign('scrollHeight', this.scrollHeight)
          .assign('heightDiff', ({ node, scrollHeight }) => just(node.scrollHeight - scrollHeight))
          .assign('newScrollTop', ({ node, heightDiff }) => just(node.scrollTop + heightDiff))
          .do(({ node, newScrollTop }) => {
            node.scrollTop = newScrollTop;
          })
          .do(() => store.ready(state.deque, nothing()));
        break;
      default:
        assertNever(state);
    }
  };
}

export default ConversationUIReactions;
