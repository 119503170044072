import { Error } from '../ErrorHandling';
import { Upload } from '../Uploads';

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
}

export interface Removing {
  kind: 'removing';
}

export interface UploadSuccessful {
  kind: 'upload-successful';
  upload: Upload;
}

interface UploadInProgress {
  kind: 'upload-in-progress';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (): Ready => ({
  kind: 'ready',
});

export const removing = (): Removing => ({
  kind: 'removing',
});

export const uploadSuccessful = (upload: Upload): UploadSuccessful => ({
  kind: 'upload-successful',
  upload,
});

export const uploadInProgess = (): UploadInProgress => ({
  kind: 'upload-in-progress',
});

export type FileUploadState =
  | Waiting
  | Ready
  | UploadSuccessful
  | Removing
  | Error
  | UploadInProgress;
