import { observer } from 'mobx-react';
import * as React from 'react';
import AttachmentUploadStore from '../../../AttachmentUploadStore';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import AdvancedFileUpload from '../AttachmentUploads/AdvancedFileUpload';
import SimpleFileUpload from '../AttachmentUploads/SimpleFileUpload';

interface Props {
  store: AttachmentUploadStore;
}

const AttachmentUploader: React.FC<Props> = ({ store }) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (currentUserResource.payload.fileUploadType) {
        case 'Advanced':
          return <AdvancedFileUpload store={store} currentUserResource={currentUserResource} />;
        case 'Simple':
          return <SimpleFileUpload store={store} currentUserResource={currentUserResource} />;
      }
    }}
  />
);

export default observer(AttachmentUploader);
