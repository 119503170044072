import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RegistrationType } from '../../../../ProgramsStore/Types';
import { T } from '../../../../Translations';

interface Props {
  type: RegistrationType;
}

const RegistrationTypeBadge: React.FC<Props> = ({ type }) => {
  switch (type) {
    case 'Auditor':
      return (
        <div
          className={clsx(
            'bg-primary',
            'flex justify-center',
            'uppercase leading-5 text-white',
            'mx-2 rounded-sm px-1',
            'text-4xs',
          )}
        >
          <T kind={type} />
        </div>
      );
    case 'Coach':
    case 'Professor':
    case 'Student':
      return <></>;
  }
};

export default observer(RegistrationTypeBadge);
