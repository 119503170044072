import { observer } from 'mobx-react';
import * as React from 'react';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { T } from '../../../Translations';
import ProfileDefaultIcon from '../../Cutesies/ProfileDefaultIcon';
import FileUpload from '../../FileUpload';
import UploadInProgress from '../../FileUpload/UploadInProgress';
import Label from '../../Form/Label';
import PhotoUploadReactions from './PhotoUploadReactions';
import * as style from './style.module.css';

interface Props {
  store: ProfileFormStore;
  fileUploadStore: FileUploadStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
}

const PhotoUpload: React.FC<Props> = ({ store, fileUploadStore, countryRegionOptionsStore }) => (
  <div data-test-photo-upload={true}>
    <Label className={style.label} labelText="Photo" />
    <div className={style.photoSection}>
      <div className={style.avatar} data-test-default-avatar={true}>
        <ProfileDefaultIcon />
      </div>
      <div className={style.fileStackSection}>
        <div className={style.photoNote} data-test-photo-note={true}>
          <T kind="Adding a photo enhances your ExecOnline experience ..." />
        </div>
        <div className={style.fileStackUploader}>
          <FileUpload store={fileUploadStore} buttonClass={style.fileStackButton} />
          <div className={style.inProgressWrapper}>
            <UploadInProgress store={fileUploadStore} />
          </div>
          <PhotoUploadReactions
            store={fileUploadStore}
            profileStore={store}
            countryRegionOptionsStore={countryRegionOptionsStore}
          />
        </div>
      </div>
    </div>
  </div>
);

export default observer(PhotoUpload);
