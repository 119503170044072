import { useContext } from 'react';
import { ProgramModuleProgressContext } from '../Context';

function useProgramModuleProgressContext() {
  const context = useContext(ProgramModuleProgressContext);

  if (!context) {
    throw new Error(
      'ProgramModuleProgressContext has to be used within the ProgramModuleProgressContext.Provider.',
    );
  }

  return context;
}

export default useProgramModuleProgressContext;
