import * as React from 'react';
import { observer } from 'mobx-react';
import { ProgramResourcesModule } from './ProgramResourcesStore/Types';
import * as style from './style.module.css';
import ProgramResourcesStore from './ProgramResourcesStore';
import DisplayedSegments from './DisplayedSegments';

interface Props {
  module: ProgramResourcesModule;
  store: ProgramResourcesStore;
}

const ProgramResourcesSegments: React.FC<Props> = ({ store, module }) => (
  <div className={style.segments}>
    {store
      .moduleResourceCount(module.id)
      .map((count) => <DisplayedSegments module={module} count={count} />)
      .getOrElseValue(<></>)}
  </div>
);

export default observer(ProgramResourcesSegments);
