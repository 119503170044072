import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const CurrentSegmentIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 16">
      <g fill="none">
        <circle cx="8" cy="8" r="8" fill={defaultTheme.variables['icon-accent-on-light']} />
        <path
          fill={defaultTheme.variables['icon-dark-reverse']}
          d="M9.381 4.5 13 8.119l-3.619 3.619V9.724H3.5v-3h5.881z"
        />
      </g>
    </SVGIcon>
  );
};

export default observer(CurrentSegmentIcon);
