import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated, T, TPlainTextKey } from '../../../Translations';
import ContentFaker from '../../ContentFaker';
import FlashMessage from '../../FlashMessage';
import BreadCrumb from '../BreadCrumb';
import ProgramResourcesModules from '../ProgramResourceList/ProgramResourcesModules';
import ProgramWideResources from '../ProgramResourceList/ProgramWideResources';
import * as style from '../style.module.css';
import ProgramsResourcesStore from './CrossProgramResourcesStore';

interface Props {
  store: ProgramsResourcesStore;
  breadCrumbPath: string;
  breadCrumbTitle: TPlainTextKey;
}

const CrossProgramResourcesView: React.FC<Props> = ({ store, breadCrumbPath, breadCrumbTitle }) => {
  switch (store.state.kind) {
    case 'error':
    case 'ready':
      return (
        <>
          <section className={style.section}>
            <BreadCrumb path={breadCrumbPath} title={<T kind={breadCrumbTitle} />} />
            {store.notification
              .map((alert) => <FlashMessage alert={alert} />)
              .getOrElseValue(<></>)}
          </section>
          <section className={style.section}>
            {store.programResourcesStores.map((s, i) => (
              <div key={i} className={style.program}>
                <h2 className={style.programNameHeader}>
                  {s.programResourcesResource
                    .map((resource) => <NotTranslated text={resource.payload.title} />)
                    .getOrElseValue(<></>)}
                </h2>
                <section className={style.section}>
                  {s.programResourcesResource
                    .map((resource) => (
                      <ProgramWideResources resources={resource.payload.resources} />
                    ))
                    .getOrElseValue(<></>)}
                </section>
                <ProgramResourcesModules headingLevel={3} store={s} />
              </div>
            ))}
          </section>
        </>
      );
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
  }
};

export default observer(CrossProgramResourcesView);
