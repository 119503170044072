import { Button, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import Loading from '../../../../../components/Loading';
import { profileStore } from '../../../../../ProfileStore';
import { T } from '../../../../../Translations';
import { EmailField, TermsAndConditionsField } from '../../../../Form';
import TermsAndConditionsStore from './Store';

interface Props {
  store: TermsAndConditionsStore;
}

const TermsAndConditionsView: React.FC<Props> = ({ store }) => {
  const { state } = store;

  const submit = (store: TermsAndConditionsStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.updating();
  };

  switch (state.kind) {
    case 'updating':
    case 'loading':
    case 'waiting':
    case 'updated':
      return <Loading />;
    case 'ready':
      return (
        <form
          data-testid="terms-and-conditions-form"
          className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
          onSubmit={submit(store)}
        >
          <div className="flex sm:gap-6">
            <div className="basis-full sm:basis-1/2">
              <EmailField
                data-testid="terms-and-conditions-form_email-text-field"
                value={profileStore.resource.map(({ payload }) => payload.email).getOrElseValue('')}
                disabled
              />
            </div>
            <div className="sm:basis-1/2" />
          </div>
          <TermsAndConditionsField
            checked={state.termsAccepted}
            disabled={!store.formInputsEnabled}
            onChange={store.setTermsAcceptance}
          />
          <div className="mt-6">
            <Button
              data-testid="terms-and-conditions-form_confirm-registration-button"
              type="submit"
              color="success"
              radius="sm"
              size="lg"
            >
              <T kind="Confirm Registration" />
            </Button>
          </div>
        </form>
      );
    case 'error':
      return (
        <Typography variant="bodyMedium" color="black">
          Error: {state.message}
        </Typography>
      );
  }
};

export default observer(TermsAndConditionsView);
