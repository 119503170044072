import { eql, stringToNumberDecoder, explicitMaybe } from '@execonline-inc/decoders';
import Decoder, { fail, field, keyValuePairs, oneOf, string, succeed } from 'jsonous';
import { teamMemberResourceDecoder as chatMemberResourceDecoder } from '../../../TeamsStore/Decoders';
import { PresenceMember } from './Types';

const adminMemberIdDecoder: Decoder<number> = string
  .map(s => s.split('-', 2))
  .andThen<number>(([admin, num]) =>
    eql('admin')
      .decodeAny(admin)
      .andThen(() => stringToNumberDecoder.decodeAny(num))
      .cata<Decoder<number>>({
        Ok: succeed,
        Err: fail,
      })
  );

const memberIdDecoder = oneOf([stringToNumberDecoder, adminMemberIdDecoder]);

export const memberDecoder: Decoder<PresenceMember> = succeed({})
  .assign('id', field('id', memberIdDecoder))
  .assign('name', field('full_name', string))
  .assign('member', field('member_resource', explicitMaybe(chatMemberResourceDecoder)));

export const membersDecoder: Decoder<[string, PresenceMember][]> = keyValuePairs(memberDecoder);
