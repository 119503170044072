import { observer } from 'mobx-react';
import Loading from '../../../../components/Loading';
import ErrorMessage from '../../../ErrorMessage';
import EnrollmentStore from '../../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import ExperienceHeroView from './ExperienceHeroView';

interface Props {
  experiencesStore: ExperiencesStore;
  enrollmentStore: EnrollmentStore;
  registerResource: RegisterResource;
}

function ExperienceHero({ experiencesStore, enrollmentStore, registerResource }: Props) {
  switch (experiencesStore.state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <div className="bg-white">
          <div className="mx-auto xl:container">
            <Loading />
          </div>
        </div>
      );
    case 'experiences':
    case 'product-collections':
    case 'product-collections-and-experiences':
    case 'searching':
      return (
        <ExperienceHeroView
          enrollmentStore={enrollmentStore}
          registerResource={registerResource}
          experiencesStore={experiencesStore}
        />
      );
    case 'error':
      return (
        <div className="bg-white">
          <div className="mx-auto xl:container">
            <ErrorMessage message="Unable to load your experience" />
          </div>
        </div>
      );
  }
}

export default observer(ExperienceHero);
