import { assertNever } from '@kofno/piper';
import { just, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../../Appy';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import ErrorActionableReaction, { EAProps } from '../../../ErrorActionableReaction';
import FileUploadStore from '../../../FileUploadStore';
import { FileUploadState } from '../../../FileUploadStore/Types';
import { MissingLinkError, findLinkT } from '../../../LinkyLinky';
import ProfileFormStore from '../../../ProfileFormStore';
import { TemporaryAvatar, temporaryAvatarDecoder } from '../../../ProfileFormStore/Decoders';
import { handleError } from '../../../ProfileFormStore/Reactions';
import { Link, Resource } from '../../../Resource/Types';
import { Upload } from '../../../Uploads';

interface Props extends EAProps<FileUploadStore> {
  profileStore: ProfileFormStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
}

type TemporaryAvatarError = AppyError | MissingLinkError;

const handleRequestError =
  (store: ProfileFormStore, countryRegionOptionsStore: CountryRegionOptionsStore) =>
  (error: TemporaryAvatarError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Profile picture uploading is not available', countryRegionOptionsStore);
        break;
      case 'bad-status':
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'bad-payload':
        handleError(store, error, countryRegionOptionsStore);
        break;
    }
  };

const setAvatar = (profileStore: ProfileFormStore, link: Link, upload: Upload) => {
  profileStore.setAvatar(
    just<Link>({
      rel: 'avatar',
      href: link.href,
      method: 'get',
      type: 'application/json',
    }),
  );
  profileStore.setAvatarPathToSubmit(just(upload.uploadFilePath));
};

const updateUserAvatar = (
  profileStore: ProfileFormStore,
  resource: Resource<TemporaryAvatar>,
  upload: Upload,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  findLinkT('resized-avatar')(resource.links).fork(
    handleRequestError(profileStore, countryRegionOptionsStore),
    (link: Link) => setAvatar(profileStore, link, upload),
  );
};

const successfulUpload = (
  upload: Upload,
  profileStore: ProfileFormStore,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  Task.succeed<TemporaryAvatarError, ReadonlyArray<Link>>(profileStore.links)
    .andThen(findLinkT('temporary-avatar'))
    .andThen(
      callApi(temporaryAvatarDecoder, {
        filestack_handle: upload.handle,
        file_path: upload.uploadFilePath,
      }),
    )
    .fork(
      handleRequestError(profileStore, countryRegionOptionsStore),
      (resource: Resource<TemporaryAvatar>) =>
        updateUserAvatar(profileStore, resource, upload, countryRegionOptionsStore),
    );
};

const removeUpload = (profileStore: ProfileFormStore) => {
  profileStore.setAvatar(nothing());
  profileStore.setAvatarPathToSubmit(just(''));
};

class PhotoUploadReactions extends ErrorActionableReaction<
  FileUploadStore,
  FileUploadState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: FileUploadState): void => {
    const { profileStore, countryRegionOptionsStore } = this.props;

    switch (state.kind) {
      case 'upload-successful':
        successfulUpload(state.upload, profileStore, countryRegionOptionsStore);
        break;
      case 'removing':
        removeUpload(profileStore);
        break;
      case 'waiting':
      case 'ready':
      case 'error':
      case 'upload-in-progress':
        break;
      default:
        assertNever(state);
    }
  };
}

export default PhotoUploadReactions;
