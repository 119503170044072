import { observer } from 'mobx-react';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import SegmentDetail from '../../../../../Segment/SegmentContent/SegmentDetail';
import * as style from '../style.module.css';

interface Props {
  store: SegmentStore;
  segmentResource: SegmentResource;
}

function DefaultSegmentContent({ store, segmentResource }: Props) {
  return (
    <>
      <h2 className={style.subheading}>{segmentResource.payload.longTitle}</h2>
      <div className={style.content}>
        <SegmentDetail segmentResource={segmentResource} store={store} />
      </div>
    </>
  );
}

export default observer(DefaultSegmentContent);
