import { PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { cn } from '@execonline-inc/execonline-ui';

interface Props {
  className?: string;
}

function ExperienceDetailsSection({ children, className }: PropsWithChildren<Props>) {
  return (
    <section
      className={cn(
        'box-border flex h-fit w-full flex-col',
        'rounded-md border border-solid border-gray-300',
        'gap-y-4 px-3 py-4 md:gap-y-6 md:p-8',
        className,
      )}
    >
      {children}
    </section>
  );
}

export default observer(ExperienceDetailsSection);
