import * as React from 'react';
import PositionContext, { Position } from '../PositionContext';

interface RenderProps {
  position: Position;
}

interface Props {
  render: (renderContext: RenderProps) => JSX.Element;
}

const RenderContext: React.FC<Props> = (props) => {
  return (
    <PositionContext.Consumer>{(position) => props.render({ position })}</PositionContext.Consumer>
  );
};

export default RenderContext;
