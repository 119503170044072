import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../../Translations';
import { EnrollmentPayload } from '../../../../DiscoveryPortal/Enrollment/Store/Types';

interface Props {
  enrollment: EnrollmentPayload;
}

function ExperienceDetailMessage({ enrollment }: Props) {
  switch (enrollment.program.payload.kind) {
    case 'active':
    case 'completed':
    case 'expired':
    case 'inactive':
      return (
        <Typography className="text-center" variant="bodyMedium" as="p">
          <T kind="Your experience is available now! Click the button below to get started." />
        </Typography>
      );
    case 'upcoming':
      return (
        <>
          <Typography className="text-center" variant="bodyMedium" as="p">
            <T
              kind="Your experience will start on <date/>."
              date={
                <Typography as="span">
                  <L
                    localizeable={enrollment.program.payload.startsOn}
                    format="long-month-day-year"
                  />
                </Typography>
              }
            />
          </Typography>
          <Typography className="text-center" variant="bodyMedium" as="p">
            <T kind="However, you may already have access to your team and resources." />
          </Typography>
        </>
      );
  }
}

export default observer(ExperienceDetailMessage);
