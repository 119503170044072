import { always } from '@kofno/piper';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReactions from '../../../ChatMessageStore/ChatReactions';
import ConversationStore from '../../../ConversationStore';
import ConversationAPIReactions from '../../../ConversationStore/ConversationAPIReactions';
import ConversationUIReactions from '../../../ConversationStore/ConversationUIReactions';
import { authoredBy, whenFirstLoaded } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import LastReadMessageAPIReactions from '../../../LastReadMessageStore/Reactions';
import ProgramChatStore from '../../../ProgramChatStore';
import ScrollStore from '../../../ScrollStore';
import { whenAtBottom } from '../../../ScrollStore/Types';
import { T } from '../../../Translations';
import * as platformStyle from '../../Platform/style.module.css';
import ChatScrollReactions from '../../TeamProfile/TeamChat/ChatScrollReaction';
import ProgramChatArea from './ProgramChatArea';
import ResumeProgram from './ResumeProgram';

interface Props {
  conversationStore: ConversationStore;
  programChatStore: ProgramChatStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  currentUserResource: CurrentUserResource;
}

interface State {}

export const scrollToBottom = (messageListEl: React.RefObject<HTMLDivElement>) => {
  fromNullable(messageListEl.current).do((el) => (el.scrollTop = el.scrollHeight));
};

@observer
class ProgramChatImpl extends React.Component<Props, State> {
  messageListEl: React.RefObject<HTMLDivElement> = React.createRef();
  scrollObserver: Maybe<MutationObserver>;
  scrollStore = new ScrollStore(20);

  constructor(props: Props) {
    super(props);
    this.scrollObserver = nothing();
  }

  lastReadMessageStore = new LastReadMessageStore(
    this.props.conversationStore.conversationResource.payload.lastReadMessage
  );

  componentDidMount() {
    this.scrollToBottomObserver();
    this.scrollObserver = just(new MutationObserver(this.scrollToBottomObserver));
    fromNullable(this.messageListEl.current).map((el) =>
      this.scrollObserver.do((observer) => observer.observe(el, { childList: true }))
    );
  }

  componentWillUnmount() {
    this.scrollObserver.do((observer) => observer.disconnect());
  }

  shouldScrollToLatestUnseen = (): Maybe<'bottom'> => {
    const { conversationStore } = this.props;
    const { scrollPosition } = this.scrollStore;

    return conversationStore.unseenMessage
      .andThen((message) =>
        authoredBy(this.props.currentUserResource.payload.id)(message).orElse(() =>
          scrollPosition.andThen(whenAtBottom).map(always(message))
        )
      )
      .map(always('bottom'));
  };

  scrollToBottomObserver = () => {
    const { conversationStore } = this.props;

    this.shouldScrollToLatestUnseen()
      .orElse(whenFirstLoaded(conversationStore))
      .do(() => scrollToBottom(this.messageListEl));
  };

  render() {
    const { conversationStore, programChatStore, presenceStore } = this.props;

    return programChatStore.program
      .map((programResource) => (
        <>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Chat" />
          </h2>
          <ResumeProgram programResource={programResource} />
          <ProgramChatArea
            programResource={programResource}
            presenceStore={presenceStore}
            messageListEl={this.messageListEl}
            conversationStore={conversationStore}
            scrollStore={this.scrollStore}
            lastReadMessageStore={this.lastReadMessageStore}
          />
          <ChatReactions
            store={conversationStore.chatMessageStore}
            conversationStore={conversationStore}
            lastReadMessageStore={just(this.lastReadMessageStore)}
          />
          <ConversationUIReactions store={conversationStore} messageListRef={this.messageListEl} />
          <ConversationAPIReactions store={conversationStore} />
          <LastReadMessageAPIReactions store={this.lastReadMessageStore} debounceDelay={3000} />
          <ChatScrollReactions conversationStore={conversationStore} store={this.scrollStore} />
        </>
      ))
      .getOrElseValue(<></>);
  }
}
export default ProgramChatImpl;
