import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { HTMLAttributes } from 'react';

function ExperienceSectionBody({ children, className, ...others }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      data-testid="learning-collection-experience-section-body"
      className={cn('px-6 md:px-16', className)}
      {...others}
    >
      {children}
    </div>
  );
}

export default observer(ExperienceSectionBody);
