import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import ConversationPresence from '../../ConversationPresence';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import ProgramChatStore from '../../ProgramChatStore';
import { ProgramResource } from '../../ProgramChatStore/Types';
import { programsStore } from '../../ProgramsStore';
import { findProgram } from '../../ProgramsStore/Functions';
import { T } from '../../Translations';
import ProgramProfile from '../ProgramProfile';

interface Props {
  programChatStore: ProgramChatStore;
  programResource: ProgramResource;
}

const PresenceProgramProfileContext: React.FC<Props> = ({ programChatStore, programResource }) => {
  return (
    <WithCurrentUser
      children={(currentUserResource) =>
        programsStore.resource
          .map(({ payload }) => payload.programs)
          .andThen(findProgram(programResource.payload.id))
          .map(() => (
            <GlobalPresenceContext.Consumer>
              {(presenceStore) => (
                <ConversationPresence
                  conversationResource={
                    programResource.payload.programChat.payload.conversationResource
                  }
                  currentUserResource={currentUserResource}
                  channelName={programResource.payload.programChat.payload.messagingChannel}
                  chatablePersonResourceList={nothing()}
                  client={(presenceMembersStore) => (
                    <ProgramProfile
                      programChatStore={programChatStore}
                      presenceStore={presenceStore}
                      presenceMembersStore={presenceMembersStore}
                      links={currentUserResource.links}
                    />
                  )}
                />
              )}
            </GlobalPresenceContext.Consumer>
          ))
          .getOrElse(() => (
            <div>
              <T kind="Could not identify a program" />
            </div>
          ))
      }
    />
  );
};

export default observer(PresenceProgramProfileContext);
