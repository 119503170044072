import { observer } from 'mobx-react';
import * as React from 'react';
import FlashMessage from '../../../FlashMessage';
import LectureSizingStore from './../Stores/LectureSizingStore';

interface Props {
  store: LectureSizingStore;
}

const Notification: React.FC<Props> = ({ store }) => {
  return store.notification.map((alert) => <FlashMessage alert={alert} />).getOrElseValue(<></>);
};

export default observer(Notification);
