import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import TeamsStore from '../../../../TeamsStore';
import TeamsReactions from '../../../../TeamsStore/TeamsReactions';
import { T } from '../../../../Translations';
import * as style from '../../../ProgramSummary/style.module.css';
import TeamMembersBody from '../TeamMembers/TeamMembersBody';

interface Props {
  programDetailResource: ProgramDetailResource;
}

@observer
class TeamMembers extends React.Component<Props, {}> {
  private teamsStore: TeamsStore;

  constructor(props: Props) {
    super(props);
    this.teamsStore = new TeamsStore();
  }

  componentDidMount() {
    this.teamsStore.loading();
  }

  render() {
    return (
      <>
        {this.teamsStore.teams
          .andThen((teamsResource) =>
            fromArrayMaybe(teamsResource.payload).map(() => (
              <dl className={style.dl} data-test-team-members={true}>
                <dt data-test-team-title="Team">
                  <T kind="Team" />
                </dt>
                <TeamMembersBody teamsResource={teamsResource} />
              </dl>
            ))
          )
          .getOrElseValue(<span />)}
        <TeamsReactions
          store={this.teamsStore}
          resourceWithTeams={this.props.programDetailResource}
        />
      </>
    );
  }
}

export default TeamMembers;
