import { observer } from 'mobx-react';
import * as React from 'react';
import FullStoryToolingReactions from './Reactions';
import { FullStoryToolingStore } from './Store';

class FullStoryTooling extends React.Component {
  store = new FullStoryToolingStore();

  componentDidMount() {
    this.store.loading();
  }

  render() {
    return <FullStoryToolingReactions store={this.store} />;
  }
}

export default observer(FullStoryTooling);
