import { observer } from 'mobx-react';
import * as React from 'react';
import AttachmentUploadStore from '../../../../AttachmentUploadStore';
import SegmentStore from '../../../../SegmentStore';
import ActiveButton from './ActiveButton';
import DisabledButton from './DisabledButton';

interface Props {
  attachmentUploadStore: AttachmentUploadStore;
  segmentStore: SegmentStore;
}

const AssignmentSubmissionButton: React.FC<Props> = ({ attachmentUploadStore, segmentStore }) => {
  switch (attachmentUploadStore.state.kind) {
    case 'ready':
      return (
        <ActiveButton attachmentUploadStore={attachmentUploadStore} segmentStore={segmentStore} />
      );
    case 'error':
    case 'loading':
    case 'ready-without-upload':
    case 'removing-upload':
    case 'upload-successful':
    case 'waiting':
      return <DisabledButton />;
  }
};

export default observer(AssignmentSubmissionButton);
