import { drop, find as findInArray, mapMaybe, take } from '@execonline-inc/collections';
import { identity } from '@kofno/piper';
import { fromNullable, just, Maybe } from 'maybeasy';
import { PerPageCount } from '../Types';

export interface CarouselState<T> {
  items: ReadonlyArray<T>;
  perPageCount: PerPageCount;
  carousel: Carousel<T>;
}

interface OneItemPage<T> {
  kind: 'carousel-one-item-page';
  contents: [T];
}

const oneItemPage = <T>(item: T): OneItemPage<T> => ({
  kind: 'carousel-one-item-page',
  contents: [item],
});

interface TwoItemPage<T> {
  kind: 'carousel-two-item-page';
  contents: [T, Maybe<T>];
}

const twoItemPage = <T>(first: T, second: Maybe<T>): TwoItemPage<T> => ({
  kind: 'carousel-two-item-page',
  contents: [first, second],
});

interface ThreeItemPage<T> {
  kind: 'carousel-three-item-page';
  contents: [T, Maybe<T>, Maybe<T>];
}

const threeItemPage = <T>(first: T, second: Maybe<T>, third: Maybe<T>): ThreeItemPage<T> => ({
  kind: 'carousel-three-item-page',
  contents: [first, second, third],
});

interface FourItemPage<T> {
  kind: 'carousel-four-item-page';
  contents: [T, Maybe<T>, Maybe<T>, Maybe<T>];
}

const fourthItemPage = <T>(
  first: T,
  second: Maybe<T>,
  third: Maybe<T>,
  fourth: Maybe<T>,
): FourItemPage<T> => ({
  kind: 'carousel-four-item-page',
  contents: [first, second, third, fourth],
});

export type CarouselPage<T> = OneItemPage<T> | TwoItemPage<T> | ThreeItemPage<T> | FourItemPage<T>;

export interface Carousel<T> {
  currentIdx: number;
  pages: ReadonlyArray<CarouselPage<T>>;
}

export function carousel<T>(pageSize: PerPageCount, items: ReadonlyArray<T>): Carousel<T> {
  return {
    currentIdx: 0,
    pages: pages(pageSize, items),
  };
}

export function pages<T>(
  pageSize: PerPageCount,
  items: ReadonlyArray<T>,
): ReadonlyArray<CarouselPage<T>> {
  switch (pageSize) {
    case 1:
      return oneItemPages([], items);
    case 2:
      return twoItemPages([], items);
    case 3:
      return threeItemPages([], items);
    case 4:
      return fourItemPages([], items);
  }
}

function oneItemPages<T>(
  pages: ReadonlyArray<OneItemPage<T>>,
  items: ReadonlyArray<T>,
): ReadonlyArray<OneItemPage<T>> {
  const [first, ...rest] = items;
  if (typeof first === 'undefined') {
    return pages;
  } else {
    const page = oneItemPage(first);
    return oneItemPages([...pages, page], rest);
  }
}

function twoItemPages<T>(
  pages: ReadonlyArray<TwoItemPage<T>>,
  items: ReadonlyArray<T>,
): ReadonlyArray<TwoItemPage<T>> {
  const [first, second, ...rest] = items;
  if (typeof first === 'undefined') {
    return pages;
  } else {
    const page = twoItemPage(first, fromNullable(second));
    return twoItemPages([...pages, page], rest);
  }
}

function threeItemPages<T>(
  pages: ReadonlyArray<ThreeItemPage<T>>,
  items: ReadonlyArray<T>,
): ReadonlyArray<ThreeItemPage<T>> {
  const [first, second, third, ...rest] = items;
  if (typeof first === 'undefined') {
    return pages;
  } else {
    const page = threeItemPage(first, fromNullable(second), fromNullable(third));
    return threeItemPages([...pages, page], rest);
  }
}

function fourItemPages<T>(
  pages: ReadonlyArray<FourItemPage<T>>,
  items: ReadonlyArray<T>,
): ReadonlyArray<FourItemPage<T>> {
  const [first, second, third, fourth, ...rest] = items;
  if (typeof first === 'undefined') {
    return pages;
  } else {
    const page = fourthItemPage(
      first,
      fromNullable(second),
      fromNullable(third),
      fromNullable(fourth),
    );
    return fourItemPages([...pages, page], rest);
  }
}

export function currentPage<T>(carousel: Carousel<T>): Maybe<CarouselPage<T>> {
  const page = carousel.pages[carousel.currentIdx];
  return fromNullable(page);
}

export function precedingPages<T>(carousel: Carousel<T>): ReadonlyArray<CarouselPage<T>> {
  return take(carousel.currentIdx, carousel.pages);
}

export function succeedingPages<T>(carousel: Carousel<T>): ReadonlyArray<CarouselPage<T>> {
  return drop(carousel.currentIdx + 1, carousel.pages);
}

export function map<T, S>(fn: (item: T) => S, carousel: Carousel<T>): Carousel<S> {
  const pages = carousel.pages.map<CarouselPage<S>>(pageMap(fn));
  return { ...carousel, pages };
}

export function pageMap<T, S>(fn: (t: T) => S): (page: CarouselPage<T>) => CarouselPage<S>;
export function pageMap<T, S>(fn: (t: T) => S, page: CarouselPage<T>): CarouselPage<S>;
export function pageMap<T, S>(fn: (t: T) => S, page?: CarouselPage<T>) {
  const doit = (page: CarouselPage<T>): CarouselPage<S> => {
    switch (page.kind) {
      case 'carousel-one-item-page':
        const [firstOf1] = page.contents;
        return { ...page, contents: [fn(firstOf1)] };
      case 'carousel-two-item-page':
        const [firstOf2, secondOf2] = page.contents;
        return { ...page, contents: [fn(firstOf2), secondOf2.map(fn)] };
      case 'carousel-three-item-page':
        const [firstOf3, secondOf3, thirdOf3] = page.contents;
        return { ...page, contents: [fn(firstOf3), secondOf3.map(fn), thirdOf3.map(fn)] };
      case 'carousel-four-item-page':
        const [firstOf4, secondOf4, thirdOf4, fourthOf4] = page.contents;
        return {
          ...page,
          contents: [fn(firstOf4), secondOf4.map(fn), thirdOf4.map(fn), fourthOf4.map(fn)],
        };
    }
  };

  return typeof page === 'undefined' ? doit : doit(page);
}

export const pageContentsArray = <T>(page: CarouselPage<T>): ReadonlyArray<T> => {
  const [first, ...rest] = page.contents;
  return mapMaybe(identity, [just(first), ...rest]);
};

export const contents = <T>(carousel: Carousel<T>): ReadonlyArray<T> =>
  carousel.pages.reduce<ReadonlyArray<T>>((memo, page) => {
    const pageContents = pageContentsArray(page);
    return [...memo, ...pageContents];
  }, []);

export function find<T>(fn: (t: T) => boolean): (carousel: Carousel<T>) => Maybe<T>;
export function find<T>(fn: (t: T) => boolean, carousel: Carousel<T>): Maybe<T>;
export function find<T>(fn: (t: T) => boolean, carousel?: Carousel<T>) {
  const doit = (carousel: Carousel<T>) => findInArray(fn, contents(carousel));
  return typeof carousel === 'undefined' ? doit : doit(carousel);
}

export const advance = <T>(carousel: Carousel<T>): Carousel<T> => ({
  ...carousel,
  currentIdx: Math.min(carousel.currentIdx + 1, carousel.pages.length - 1),
});

export const retreat = <T>(carousel: Carousel<T>): Carousel<T> => ({
  ...carousel,
  currentIdx: Math.max(carousel.currentIdx - 1, 0),
});

export const pageCount = <T>(carousel: Carousel<T>): number => carousel.pages.length;

const whenIndexInRange = <T>(carousel: Carousel<T>, idx: number): boolean =>
  idx >= 0 && idx < pageCount(carousel);

export function gotoPage<T>(carousel: Carousel<T>): (page: CarouselPage<T>) => Carousel<T>;
export function gotoPage<T>(carousel: Carousel<T>, page: CarouselPage<T>): Carousel<T>;
export function gotoPage<T>(carousel: Carousel<T>, page?: CarouselPage<T>) {
  const doit = (page: CarouselPage<T>) => {
    const idx = carousel.pages.indexOf(page);
    return whenIndexInRange(carousel, idx) ? { ...carousel, currentIdx: idx } : carousel;
  };
  return typeof page === 'undefined' ? doit : doit(page);
}
