import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const ChatReportIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 17 17">
      <path
        fill="#808080"
        d="M8.387 0 0 8.387l8.387 8.387 8.387-8.387L8.387 0Zm0 1.637 6.75 6.75-6.75 6.75-6.75-6.75 6.75-6.75Zm-.582 3.257v4.657h1.164V4.894H7.805Zm0 5.822v1.164h1.164v-1.164H7.805Z"
      />
    </SVGIcon>
  );
};

export default observer(ChatReportIcon);
