import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, CompletedProgram } from '../../../ProgramStore/Types';
import { T } from '../../../Translations';
import Modules from './Modules';
import * as style from './style.module.css';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
}

const ModuleOutline: React.FC<Props> = ({ programDetail }) => (
  <div className={style.outline} data-test-program-content={programDetail.id}>
    <h2 data-test-program-outline-label="Program Outline">
      <T kind="Program Outline" />
    </h2>
    <Modules programId={programDetail.id} modules={programDetail.modules} />
  </div>
);

export default observer(ModuleOutline);
