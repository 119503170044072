import { just, Maybe } from 'maybeasy';
import { EngagementActivityType, EngagementEngageableType } from '../../../EngagementStore/Types';
import { Error } from '../../../ErrorHandling';

export interface Engagement {
  activityType: EngagementActivityType;
  engageableType: EngagementEngageableType;
  engageableId: number;
  programId: Maybe<number>;
}

export const videoAssetEndedEngagement = (assetId: number, programId: number): Engagement => {
  return {
    activityType: 'ended',
    engageableType: 'video_asset',
    engageableId: assetId,
    programId: just(programId),
  };
};

interface Ready {
  kind: 'ready';
}

interface Waiting {
  kind: 'waiting';
}

interface Creating {
  kind: 'creating';
  engagement: Engagement;
}

export type EngagementState = Ready | Creating | Waiting | Error;

export const ready = (): Ready => ({
  kind: 'ready',
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const creating = (engagement: Engagement): Creating => ({
  kind: 'creating',
  engagement,
});
