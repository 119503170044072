import { observer } from 'mobx-react';
import Image from '../Image';
import SizedImage from '../SizedImage';
import { proportionalImageWidth } from '../SizedImage/SizedImageStore/Types';
import clsx from 'clsx';

interface Props {
  href: string;
  alt: string;
  height: number;
  className?: string;
}

const ProportionalImage: React.FC<Props> = ({ href, alt, height, className }) => (
  <SizedImage uri={href}>
    {(src, dimensions) => (
      <Image
        style={{ height, width: proportionalImageWidth(dimensions, height) }}
        className={clsx('max-w-full object-contain', className)}
        src={src}
        alt={alt}
      />
    )}
  </SizedImage>
);

export default observer(ProportionalImage);
