export type FilterOption = {
  value: string;
  label: string;
};

export type CommonFilter = {
  selectedValues: string[];
  appliedValues: string[];
};

export interface Blank {
  filter: CommonFilter;
}

export type State = {
  filter: CommonFilter;
};

export const blank = (filter: CommonFilter): State => ({
  filter,
});

export const applyFilterValues = (appliedValues: string[]): State => {
  return {
    filter: { selectedValues: [], appliedValues },
  };
};

export const isFilterItemSelected = (valuesList: string[], singleValue: string) => {
  return valuesList.indexOf(singleValue) > -1;
};
