import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../../GlobalPresence/GlobalPresenceStore';
import { TeamMemberResource } from '../../../../TeamsSummaryStore/Types';
import TeamMemberImpl from './TeamMemberImpl';

interface Props {
  teamMemberResource: TeamMemberResource;
  globalPresence: Maybe<GlobalPresenceMembersStore>;
}

const TeamMember: React.FC<Props> = ({ teamMemberResource, globalPresence }) => (
  <TeamMemberImpl teamMemberResource={teamMemberResource} globalPresence={globalPresence} />
);

export default observer(TeamMember);
