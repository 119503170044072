import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import { programsStore } from '../../../ProgramsStore';
import { expiredProgramResource, visibleProgramResource } from '../../../ProgramsStore/Functions';
import { T } from '../../../Translations';
import ProgramDashboards from '../ProgramDashboard/ProgramDashboards';
import * as style from '../style.module.css';

const ExpiredPrograms: React.FC<{}> = () => {
  return (
    <div className={clsx(style.active)} data-test-completed-programs={true}>
      {programsStore.resource
        .map(({ payload }) =>
          payload.programs.filter(expiredProgramResource).filter(visibleProgramResource),
        )
        .andThen(fromArrayMaybe)
        .map((expiredPrograms) => (
          <>
            <h2 data-test-expired-programs-title="Expired" className="ml-2">
              <T kind="Expired" />
            </h2>
            <ProgramDashboards programs={expiredPrograms} />
          </>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
};

export default observer(ExpiredPrograms);
