import { observer } from 'mobx-react';
import * as React from 'react';
import { SubDayProps } from '.';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import ManyEvents from '../EventType/ManyEvents';
import ShowModule from '../ShowModule';
import * as style from '../style.module.css';
import { TranslationsContext, localizer } from '../../../Translations';
import { numberStyle } from '../Types';

const NextMonthDay: React.FC<SubDayProps> = ({ day, store, modules }) => {
  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <li data-test-next-month-day={day.date.getDate()} className={style.past}>
          <div
            onClick={() => store.navigating('next')}
            className={style.content}
            tabIndex={0}
            onKeyPress={handleEnterOrSpaceKeyPress(() => store.navigating('next'))}
            role="button"
            aria-label={localizer(day.date, 'long-month-day')(ts)}
          >
            <div className={numberStyle(store, day)}>{day.date.getDate()}</div>
            {modules.map((md, i) => (
              <ShowModule key={i} md={md} />
            ))}
            {store
              .events(day)
              .map((evts) => <ManyEvents events={evts} />)
              .getOrElseValue(<></>)}
          </div>
        </li>
      )}
    </TranslationsContext.Consumer>
  );
};

export default observer(NextMonthDay);
