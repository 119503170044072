import * as React from 'react';
import { ActiveProgramModule } from '../../../../../ProgramStore/Types';
import { completionStatus } from '../../../../../Progresso';
import CompletedModuleIcon from '../../../../Cutesies/CompletedModuleIcon';
import StartedModuleGraphic from '../../../../Cutesies/StartedModuleGraphic';
import UnstartedModuleIcon from '../../../../Cutesies/UnstartedModuleIcon';

interface Props {
  module: ActiveProgramModule;
}

const ModuleIcon: React.FC<Props> = ({ module }) => {
  switch (completionStatus(module.percentComplete)) {
    case 'complete':
      return <CompletedModuleIcon />;
    case 'in-progress':
      return <StartedModuleGraphic percentComplete={module.percentComplete} />;
    case 'unstarted':
      return <UnstartedModuleIcon />;
  }
};

export default ModuleIcon;
