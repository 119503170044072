import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Task } from 'taskarian';
import EngagementStore from '.';
import { callApi } from '../../../Appy';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { EngagementCreationError, engagementEncoder } from '../../../EngagementStore/Reactions';
import ErrorActionableReaction, { EAProps } from '../../../ErrorActionableReaction';
import { findLinkT } from '../../../LinkyLinky';
import { Link } from '../../../Resource/Types';
import { Engagement, EngagementState } from './Types';

export interface Props extends EAProps<EngagementStore> {
  store: EngagementStore;
  currentUserResource: CurrentUserResource;
}

const createEngagement = (engagement: Engagement) =>
  callApi(succeed({}), engagementEncoder(engagement));

const handleEngagementCreationError =
  (store: EngagementStore) => (error: EngagementCreationError) => {
    warn('EngagementCreationError', `Unable to create engagement: ${error.kind}`, error);
    store.error('Failed to create engagement');
  };

class EngagementReactions extends ErrorActionableReaction<EngagementStore, EngagementState, Props> {
  tester = () => this.props.store.engagementState;

  effect = (state: EngagementState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'ready':
      case 'waiting':
        break;
      case 'creating':
        Task.succeed<EngagementCreationError, ReadonlyArray<Link>>(
          this.props.currentUserResource.links,
        )
          .andThen(findLinkT('create-engagement'))
          .andThen(createEngagement(state.engagement))
          .fork(handleEngagementCreationError(store), store.waiting);
        break;
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default EngagementReactions;
