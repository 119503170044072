import { asTask } from '@execonline-inc/error-handling.private';
import type { DecoderError, ReadResponse, RequestOptions } from '@execonline-inc/fetch.private';
import * as fetch from '@execonline-inc/fetch.private';
import type { Method } from '@execonline-inc/links.private';
import { toResult } from '@execonline-inc/maybe-adapter';
import { always, pipe } from '@kofno/piper';
import type { StaticDecode, TSchema } from '@sinclair/typebox/type';
import { FormatRegistry } from '@sinclair/typebox/type';
import { Value } from '@sinclair/typebox/value';
import { fromNullable } from 'maybeasy';
import { err, ok, type Result } from 'resulty';
import { Task } from 'taskarian';
import type { Callable, Link, ParsedResponse } from './Types';

export * from './Transforms';
export * from './Types';

type FetchModule<Rel extends string, Endpoint extends string> = ReturnType<
  typeof fetch.initialize<Rel, Endpoint>
>;

export const initialize = <Rel extends string, const EndpointSchemas>(
  schema: EndpointSchemas,
  FetchModule: FetchModule<Rel, Extract<keyof EndpointSchemas, string>>,
) => {
  /**
   * The server is already performing validation of these formats as necessary.
   * We only need the formats to be defined, otherwise decoding will always fail.
   */
  FormatRegistry.Set('uri', always(true));
  FormatRegistry.Set('uri-template', always(true));
  FormatRegistry.Set('mime-type', always(true));
  FormatRegistry.Set('date', always(true));
  FormatRegistry.Set('time', always(true));
  FormatRegistry.Set('date-time', always(true));

  const getSchemaFor = <E extends keyof EndpointSchemas, M extends keyof EndpointSchemas[E]>(
    callable: Callable<EndpointSchemas, E, M>,
    status: string,
  ): TSchema | undefined => {
    const schemas = schema[callable.endpoint][callable.method];

    /**
     * Even when I was prototyping this and testing with a concrete literal type, TypeScript
     * was't able to realize that `schemas` always has the same basic shape of a `string`
     * key and a `TSchema`, which is true regardless of what indexes are used to get there.
     * It just gives up and types `schemas` as `unknown`, disallowing further indexing.  Due
     * to this limitation, the only option here is to assert.
     */
    const s = (schemas as Record<string, TSchema | undefined>)[status];

    return s;
  };

  const decodeResponseFor =
    <E extends keyof EndpointSchemas, M extends keyof EndpointSchemas[E]>(
      callable: Callable<EndpointSchemas, E, M>,
    ) =>
    (response: ReadResponse<string>) => {
      const status = response.response.status.toString();

      return whenSchema(getSchemaFor(callable, status))
        .andThen(decode(response))
        .mapError<DecoderError>((error) => ({ kind: 'decoder-error', response, error }))
        .map(
          (resource) =>
            /**
             * Due to the typing issue noted above, we've lost the generics along the way, so
             * we have to restore them with type casting.
             */
            ({ status, resource }) as ParsedResponse<EndpointSchemas, E, M>,
        );
    };

  const whenSchema = <S extends TSchema>(schema: S | undefined): Result<unknown[], S> =>
    toResult(['returned HTTP status does not have a declared type'], fromNullable(schema));

  const whenHasErrorResponseSchema = <
    E extends keyof EndpointSchemas,
    M extends keyof EndpointSchemas[E],
  >(
    callable: Callable<EndpointSchemas, E, M>,
    response: ReadResponse<string>,
    error: fetch.FetchError,
  ): Task<fetch.FetchError, ReadResponse<string>> =>
    asTask(
      whenSchema(getSchemaFor(callable, response.response.status.toString()))
        .mapError(always(error))
        .map(always(response)),
    );

  const parse = (response: ReadResponse<string>): Result<unknown[], unknown> => {
    try {
      return ok(JSON.parse(response.data));
    } catch (e) {
      return err(['failed to parse response as JSON', e]);
    }
  };

  const decode =
    (response: ReadResponse<string>) =>
    <S extends TSchema>(schema: S): Result<unknown[], StaticDecode<S>> =>
      parse(response).andThen((data) => {
        try {
          return ok(Value.Decode(schema, data));
        } catch (e) {
          return err([...Value.Errors(schema, data)]);
        }
      });

  const whenErrorResponse = (
    error: fetch.FetchError,
  ): Task<fetch.FetchError, ReadResponse<string>> => {
    switch (error.kind) {
      case 'decoder-error':
      case 'network-error':
        return Task.fail(error);
      case 'http-error':
        return Task.succeed(error.response);
    }
  };

  const fetch = <E extends keyof EndpointSchemas, M extends keyof EndpointSchemas[E] & Method>(
    link: Link<EndpointSchemas, Rel, E, M>,
    options?: RequestOptions,
  ) =>
    FetchModule.withoutDecoder
      .fetch({ method: link.method, href: link.href, options })
      .orElse((e) => whenErrorResponse(e).andThen((r) => whenHasErrorResponseSchema(link, r, e)))
      .andThen(pipe(decodeResponseFor(link), asTask));

  return { fetch } as const;
};
