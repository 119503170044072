import * as React from 'react';
import { T } from '../../Translations';
import * as style from '../style.module.css';

const ViewAnnouncementButton: React.FC = () => {
  return (
    <span className={style.expandButton}>
      <T kind="view announcement" />
    </span>
  );
};

export default ViewAnnouncementButton;
