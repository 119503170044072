import { SessionStorage } from '@execonline-inc/browser-storage.private';
import { warn } from '@execonline-inc/logging';
import { always } from '@kofno/piper';
import type { Maybe } from 'maybeasy';
import { Task } from 'taskarian';

interface Location {
  pathname: string;
  search: string;
  hash: string;
}

export const saveApplicationLocation = (l: Location) =>
  SessionStorage.setItem('previousLocation', `${l.pathname}${l.search}${l.hash}`).elseDo((e) =>
    warn('Failed to write app location to session storage.', e),
  );

const readSavedApplicationLocation = () =>
  SessionStorage.getItem('previousLocation').elseDo((e) =>
    warn('Failed to read previous app location from session storage.', e),
  );

const removeSavedApplicationLocation = <T>(passthrough: T) =>
  SessionStorage.removeItem('previousLocation')
    .elseDo((e) => warn('Failed to remove previous app location from session storage.', e))
    .map(always(passthrough));

const whenLocationPresent = (l: Maybe<string>) =>
  l.cata<Task<unknown, string>>({ Nothing: () => Task.fail({}), Just: Task.succeed });

export const popSavedApplicationLocation = () =>
  Task.succeed<unknown, {}>({})
    .andThen(readSavedApplicationLocation)
    .andThen(removeSavedApplicationLocation)
    .andThen(whenLocationPresent);
