import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { findLink } from '../../../../LinkyLinky';
import { NavigationBarMenuItemProps } from '../Types';
import ApplicationDropdownMenu from './ApplicationDropdownMenu';

function ApplicationMenu({ user }: NavigationBarMenuItemProps) {
  return findLink('enterprise-portal-app', user.links)
    .orElse(() => findLink('client-portal-app', user.links))
    .map(() => <ApplicationDropdownMenu user={user} />)
    .getOrElse(emptyFragment);
}

export default observer(ApplicationMenu);
