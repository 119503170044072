import { observer } from 'mobx-react';
import * as React from 'react';
import { ExternalProgramResource } from '../../../../SegmentStore/Types';
import Hero from './Hero';
import Body from './Body';
import * as style from './style.module.css';
import DetailViewStore from '../DetailViewStore';

interface Props {
  externalProgramResource: ExternalProgramResource;
  detailViewStore: DetailViewStore;
}

const ExternalProgramSummaryView: React.FC<Props> = ({
  externalProgramResource,
  detailViewStore,
}) => {
  const { primaryColor } = externalProgramResource.payload;
  return (
    <div className={`${style.cardBody} text-white`} style={{ backgroundColor: primaryColor }}>
      <Hero externalProgramResource={externalProgramResource} />
      <Body externalProgramResource={externalProgramResource} store={detailViewStore} />
    </div>
  );
};

export default observer(ExternalProgramSummaryView);
