import { DependencyList, Dispatch, useEffect, useRef, useState } from 'react';
import { useConst } from './useConst';

type SetState<State> = Dispatch<State>;

type Effect = () => void | (() => void);

export const useAbortable = () => {
  const abortController = useRef(new AbortController());

  const signal = abortController.current.signal;

  useEffect(() => {
    return () => abortController.current.abort('An effect was aborted when a component unmounted.');
  }, []);

  const abortableDispatch =
    <T>(fn: Dispatch<T>) =>
    (value: T): void => {
      if (!signal.aborted) fn(value);
    };

  const useAbortableState = useConst(
    () =>
      <State>(initialState: () => State): [State, SetState<State>] => {
        const [state, _setState] = useState<State>(initialState);
        const setState = abortableDispatch(_setState);
        return [state, setState] as const;
      },
  );

  const useAbortableEffect = useConst(() => (effect: Effect, deps?: DependencyList) => {
    return useEffect(() => {
      if (!signal.aborted) return effect();
    }, deps);
  });

  return {
    signal,
    abortableDispatch,
    useAbortableState,
    useAbortableEffect,
  } as const;
};
