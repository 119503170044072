import { HttpError } from 'ajaxian';
import { AppyError } from '../Appy';
import { warnAndNotifyDecoderError } from '../Resource/Decoders';
import { AlreadyTranslatedText, TPlainTextKey } from '../Translations';

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export interface ServerError {
  kind: 'server-error';
  message: AlreadyTranslatedText;
}

export const error = (message: TPlainTextKey): Error => ({
  kind: 'error',
  message,
});

export const serverError = (message: AlreadyTranslatedText): ServerError => ({
  kind: 'server-error',
  message,
});

interface Messageable {
  message: TPlainTextKey;
}

export const generifyError = ({ message }: Messageable): Error => ({
  kind: 'error',
  message,
});

export const handleAppyError = (error: AppyError): TPlainTextKey => {
  switch (error.kind) {
    case 'missing-application-id':
      return 'You are missing the application id.';
    case 'missing-api-compatibility':
      return 'You are missing the API compatibility version';
    default:
      return handleHttpError(error);
  }
};

export const handleHttpError = (httpError: HttpError): TPlainTextKey => {
  switch (httpError.kind) {
    case 'bad-url':
      return 'Your request could not be completed because the url was malformed.';
    case 'timeout':
      return 'Your request took too long to complete.';
    case 'network-error':
      return 'You are disconnected from the server.';
    case 'bad-status':
      // TODO: find some examples of 400 and 404 so we can handle those differently.
      return 'Your request failed.';
    case 'bad-payload': {
      const msg: TPlainTextKey = 'The server responded with unexpected data.';
      warnAndNotifyDecoderError(msg)(httpError);
      return msg;
    }
  }
};
