import { parseIntM } from '@execonline-inc/numbers';
import { _VimeoPlayer } from 'kettle-corn/VimeoPlayer';
import * as React from 'react';
import NoPlayer from '../ReqHlsVideo/NoPlayer';
import AnnouncementAdaptivePlayer from './AnnouncementAdaptivePlayer';
import AnnouncementProgressivePlayer from './AnnouncementProgressivePlayer';
import { Props as VideoProps } from './AnnouncementVideo';
import { SelectedPlayer } from './PlayerSelection';

interface Props extends VideoProps {
  selectedPlayer: SelectedPlayer;
}

const AnnouncementRenderedPlayer: React.FC<Props> = (props) => {
  switch (props.selectedPlayer.kind) {
    case 'undetermined':
      return <></>;
    case 'vimeo':
      const vimeoId = props.selectedPlayer.vimeoId;
      return parseIntM(props.height.toString())
        .map((height) => (
          <_VimeoPlayer
            id={props.id}
            className={props.className}
            videoId={vimeoId}
            kettle={props.kettle}
            height={height}
          />
        ))
        .getOrElse(() => <span>Invalid height specified</span>);

    case 'progressive-low':
    case 'edge':
    case 'progressive':
      return (
        <AnnouncementProgressivePlayer
          id={props.id}
          className={props.className}
          videoResource={props.videoResource}
          kettle={props.kettle}
          width={props.width}
          height={props.height}
          dynamicallySetAspectRatio={props.dynamicallySetAspectRatio}
          sources={props.selectedPlayer.sources.toArray()}
        />
      );
    case 'adaptive':
      return (
        <AnnouncementAdaptivePlayer
          id={props.id}
          className={props.className}
          videoResource={props.videoResource}
          kettle={props.kettle}
          width={props.width}
          height={props.height}
          dynamicallySetAspectRatio={props.dynamicallySetAspectRatio}
        />
      );
    case 'no-selection':
      return <NoPlayer videoResource={props.videoResource} />;
  }
};

export default AnnouncementRenderedPlayer;
