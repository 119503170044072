import { useMediaQuery } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { PreviewModeContext } from '../../DiscoveryPortal/PreviewMode/PreviewModeContext';
import { CarouselStore, CarouselWithButtons } from '../Experience/Carousel';
import { ExperienceList, MobileExperienceList } from './ProgramExperienceItems';
import { ProgramExperienceCarouselProps } from './Types';

interface Props<T> {
  carouselStore: CarouselStore<T>;
  children: (props: ProgramExperienceCarouselProps<T>) => ReactNode;
}

function ProgramExperienceCarousel<T>({ carouselStore, children }: Props<T>) {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
      return (
        <CarouselWithButtons className="mt-8" carouselStore={carouselStore}>
          <ExperienceList
            page={carouselStore.currentPage}
            perPageCount={carouselStore.perPageCount}
            children={children}
          />
        </CarouselWithButtons>
      );
    case 'lg':
    case 'md':
    case 'sm':
    case 'xs':
      return (
        <PreviewModeContext.Consumer>
          {(preview) => {
            switch (preview) {
              case 'default':
                return <MobileExperienceList items={carouselStore.contents} children={children} />;
              case 'preview':
                return (
                  <CarouselWithButtons className="mt-8" carouselStore={carouselStore}>
                    <ExperienceList
                      page={carouselStore.currentPage}
                      perPageCount={carouselStore.perPageCount}
                      children={children}
                    />
                  </CarouselWithButtons>
                );
            }
          }}
        </PreviewModeContext.Consumer>
      );
  }
}

export default observer(ProgramExperienceCarousel);
