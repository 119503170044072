import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ConversationStore from '../../../ConversationStore';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import ScrollStore from '../../../ScrollStore';
import ScrollToNewMessage from '../../TeamProfile/TeamChat/ScrollToNewMessage';
import { scrollToBottom } from './ProgramChatImpl';
import * as style from './style.module.css';

interface Props {
  scrollStore: ScrollStore;
  conversationStore: ConversationStore;
  messageListEl: React.RefObject<HTMLDivElement>;
}

const ScrollPosition: React.FC<Props> = ({ scrollStore, conversationStore, messageListEl }) => (
  <WithCurrentUser
    children={(currentUserResource) =>
      scrollStore.scrollPosition
        .map((scrollPosition) => (
          <div className={style.scrollToNewMessageWrapper}>
            <ScrollToNewMessage
              scrollPosition={scrollPosition}
              conversationStore={conversationStore}
              scrollToBottom={() => scrollToBottom(messageListEl)}
              className={style.scrollToNewMessageButton}
              currentUserResource={currentUserResource}
            />
          </div>
        ))
        .getOrElse(emptyFragment)
    }
  />
);

export default observer(ScrollPosition);
