import * as React from 'react';
import { observer } from 'mobx-react';
import FlashError from '../FlashMessage/FlashError';
import * as style from './style.module.css';
import WithBackgroundImage from '../WithBackgroundImage';
import { TPlainTextKey } from '../../Translations';

interface Props {
  errorMessage: TPlainTextKey;
}
const SsoError: React.FC<Props> = ({ errorMessage }) => {
  return (
    <WithBackgroundImage>
      <div className={style.wrapper}>
        <div className={style.main}>
          <div className={style.content}>
            <FlashError>{errorMessage}</FlashError>
          </div>
        </div>
      </div>
    </WithBackgroundImage>
  );
};

export default observer(SsoError);
