import { InactivityLogoutTracker } from './Core';
import { WarningModal } from './WarningModal';

export function InactivityLogout() {
  const state = InactivityLogoutTracker.useInactivityTracking();

  switch (state.kind) {
    case 'uninitialized':
    case 'active':
    case 'timed-out':
      return <></>;
    case 'inactive':
      return (
        <WarningModal
          expiration={state.expiration}
          reset={() => InactivityLogoutTracker.activeNow('inactivity:reset')}
        />
      );
  }
}
