import React from 'react';
import { ExperienceResource } from '../Types';

export type ExperienceContextState = {
  invitationUuid: string;
  experienceResource: ExperienceResource;
};

export const ExperienceContext = React.createContext<ExperienceContextState | null>(null);

export const ExperienceContextProvider = ExperienceContext.Provider;
