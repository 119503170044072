import { action, observable } from 'mobx';
import { assertNever } from '@kofno/piper';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { TPlainTextKey } from '../../../../../../../Translations';
import {
  readyForPaymentConfirmation,
  paymentDeclined,
  PaymentCheckoutFormState,
  waiting,
  confirmingPayment,
} from './Types';

class PaymentCheckoutFormStore {
  @observable
  state: PaymentCheckoutFormState;

  constructor() {
    this.state = waiting();
  }

  @action
  readyForPaymentConfirmation = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'payment-declined':
      case 'confirming-payment':
        this.state = readyForPaymentConfirmation();
        break;
      case 'ready-for-payment-confirmation':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  confirmingPayment = (stripe: Stripe, elements: StripeElements) => {
    switch (this.state.kind) {
      case 'waiting':
        break;
      case 'payment-declined':
      case 'ready-for-payment-confirmation':
        this.state = confirmingPayment(stripe, elements);
        break;
      case 'confirming-payment':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  paymentDeclined = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'waiting':
        break;
      case 'ready-for-payment-confirmation':
      case 'confirming-payment':
        this.state = paymentDeclined(message);
        break;
      case 'payment-declined':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default PaymentCheckoutFormStore;
