import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';
import BeginnableAlert from './BeginnableAlert';
import UpcomingAlert from './UpcomingAlert';
import GraduatedAlert from './GraduatedAlert';
import ReturnableAlert from './ReturnableAlert';

function ExperienceAlert() {
  const { experienceResource } = useExperiencePopoverContext();

  switch (experienceResource.payload.kind) {
    case 'beginnable':
    case 'resumable':
      return <BeginnableAlert />;
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
    case 'upcoming':
      return <UpcomingAlert experience={experienceResource.payload} />;
    case 'graduated':
      return <GraduatedAlert />;
    case 'returnable':
      return <ReturnableAlert />;
  }
}

export default observer(ExperienceAlert);
