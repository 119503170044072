import { assertNever } from '@kofno/piper';
import { fromNullable, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../SegmentStore';
import { windowMessageDecoder } from './Decoders';
import { WindowMessage } from './Types';

interface Props {
  segmentStore: SegmentStore;
  iFrameRef: React.RefObject<HTMLIFrameElement>;
}

@observer
class WindowMessageReactions extends React.Component<Props> {
  messageHandler = (e: { data: string }) => {
    windowMessageDecoder.decodeJson(e.data).cata({
      Err: (err) => {
        //TODO Some sort of error handling... maybe. We may not care if we are sent bad messages
        return;
      },
      Ok: (windowMessage: WindowMessage) => {
        switch (windowMessage.kind) {
          case 'complete-dont-advance':
            this.props.segmentStore.complete();
            break;
          case 'complete-advance':
            this.props.segmentStore.completeAndAdvance();
            break;
          case 'results':
            this.props.segmentStore.updateResults(windowMessage.segmentPayload);
            break;
          case 'get-results':
            const { segmentStore, iFrameRef } = this.props;
            if (iFrameRef && typeof iFrameRef != 'string') {
              const iFrame = iFrameRef.current;
              segmentStore.results
                .map((r) => r.value)
                .andThen(fromNullable)
                .orElse(() => just('no-results'))
                .do((value) => {
                  segmentStore.enclosureOrigin.do((origin) => {
                    if (iFrame && iFrame.contentWindow) {
                      iFrame.contentWindow.postMessage({ value }, origin);
                    }
                  });
                });
            }
            break;
          default:
            assertNever(windowMessage);
        }
      },
    });
  };

  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  render() {
    return <span />;
  }
}

export default WindowMessageReactions;
