import { Time, toJS } from '@execonline-inc/time';
import { DependencyList, useEffect } from 'react';
import * as rxjs from 'rxjs';
import { useTemporaryEffect } from './useTemporaryEffect';

type Fn = (timestamp: Date) => void;

/**
 * Executes the given function at each time interval.
 */
export const useInterval = (interval: Time, fn: Fn, deps: DependencyList = []) => {
  return useEffect(() => {
    const timer = rxjs.interval(toJS(interval));

    const subscription = timer.subscribe(() => fn(new Date()));

    return () => subscription.unsubscribe();
  }, [...deps]);
};

/**
 * Executes the given function at each time interval, but stops after a duration of time.
 */
export const useIntervalForDuration = (
  config: { interval: Time; duration: Time },
  fn: Fn,
  deps: DependencyList = [],
) => {
  return useTemporaryEffect(
    config.duration,
    () => {
      const timer = rxjs.interval(toJS(config.interval));

      const subscription = timer.subscribe(() => fn(new Date()));

      return () => subscription.unsubscribe();
    },
    [...deps],
  );
};
