import { findPayloadByUuid } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import EmbeddedDocumentAssetStore, { State } from '../Store';

interface Props extends RCProps<EmbeddedDocumentAssetStore> {}

class EmbeddedDocumentAssetReactions extends ReactionComponent<
  EmbeddedDocumentAssetStore,
  State,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'waiting':
        break;
      case 'initializing':
        findPayloadByUuid(store.documentId, store.embeddedDocumentAssets).do(store.ready);
        break;
      case 'ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default EmbeddedDocumentAssetReactions;
