import { Typography } from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../Translations';

interface Props {
  text?: TPlainTextKey;
}

function Loading({ text }: Props) {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <Typography className="text-center" variant="bodyMedium">
        <T kind={fromNullable<TPlainTextKey>(text).getOrElseValue('Loading...')} />
      </Typography>
    </div>
  );
}

export default observer(Loading);
