import * as React from 'react';
import { ActiveProgramModule } from '../../../ProgramStore/Types';
import { completionStatus } from '../../../Progresso';
import { AlreadyTranslated } from '../../../Translations';
import CompleteSegmentIcon from '../../Cutesies/CompleteSegmentIcon';
import StartedModuleGraphic from '../../Cutesies/StartedModuleGraphic';
import UnstartedSegmentIcon from '../../Cutesies/UnstartedSegmentIcon';
import * as style from '../style.module.css';

interface Props {
  programModule: ActiveProgramModule;
}
const OutlineModule: React.FC<Props> = ({ programModule }) => {
  const moduleClass = () => {
    switch (completionStatus(programModule.percentComplete)) {
      case 'complete':
        return style.complete;
      case 'in-progress':
        return style.current;
      case 'unstarted':
        return style.inaccessible;
    }
  };

  const moduleIcon = () => {
    switch (completionStatus(programModule.percentComplete)) {
      case 'complete':
        return <CompleteSegmentIcon />;
      case 'in-progress':
        return <StartedModuleGraphic percentComplete={programModule.percentComplete} />;
      case 'unstarted':
        return <UnstartedSegmentIcon />;
    }
  };

  return (
    <li className={style.module} data-test-outline-module={programModule.id}>
      <div className={moduleClass()}>
        <div
          className={style.moduleIcon}
          data-test-module-icon={completionStatus(programModule.percentComplete)}
        >
          {moduleIcon()}
          <div className={style.moduleLine} />
        </div>
        <div className={style.moduleLabel} data-test-module-title={programModule.title}>
          <AlreadyTranslated content={programModule.title} />
        </div>
      </div>
    </li>
  );
};
export default OutlineModule;
