import { noop } from '@kofno/piper';
import { PickerFileMetadata } from 'filestack-js/build/main/lib/picker';
import { Task } from 'taskarian';
import { scrubFilename } from '../../../SimpleFileUploadStore/UploadActions';

interface FileNotFound {
  kind: 'file-not-found';
}

interface InvalidFileName {
  kind: 'invalid-filename';
  message: string;
}

type FileNameError = FileNotFound | InvalidFileName;

export const invalidFileName = (message: string): InvalidFileName => ({
  kind: 'invalid-filename',
  message,
});

export const escapeFilenameForUpload = (
  file: PickerFileMetadata,
): Task<FileNameError, PickerFileMetadata> => {
  return new Task((reject, resolve) => {
    if (file) {
      try {
        const filename = scrubFilename(decodeURI(file.filename));
        resolve({ ...file, filename });
      } catch (e) {
        reject(invalidFileName(`Filename ${file.filename} is not accepted`));
      }
    } else {
      reject({ kind: 'file-not-found' });
    }
    return noop;
  });
};
