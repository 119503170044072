import { Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../Translations';
import { EmailField, TermsAndConditionsField } from '../../../Form';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { AuthSignUp } from '../Types';
import FormFields from './FormFields';
import { SignUpStore } from './Types';

interface Props {
  registerResource: RegisterResource;
  authForm: AuthSignUp;
}

function Form({ registerResource, authForm }: Props) {
  const submit = (store: SignUpStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.creating();
  };

  switch (registerResource.payload.auth.kind) {
    case 'password-auth':
    case 'sso-auth':
      return (
        <form
          data-testid="sign-up-form"
          className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
          onSubmit={submit(authForm.store)}
        >
          <div className="flex sm:gap-6">
            <div className="basis-full sm:basis-1/2">
              <EmailField
                data-testid="sign-up-form_email-text-field"
                value={registerResource.payload.emailAddress}
                disabled
              />
            </div>
            <div className="sm:basis-1/2" />
          </div>
          <FormFields authForm={authForm} />
          {registerResource.payload.auth.termsAccepted || (
            <TermsAndConditionsField
              checked={authForm.store.termsAcceptance}
              onChange={authForm.store.setTermsAcceptance}
            />
          )}
          <div className="mt-6">
            <Button
              data-testid="sign-up-form_continue-button"
              type="submit"
              size="lg"
              radius="sm"
              color="success"
              disabled={authForm.store.formDisabled}
            >
              <T kind="Continue" />
            </Button>
          </div>
        </form>
      );
    case 'locked-access-auth':
      return emptyFragment();
  }
}

export default observer(Form);
