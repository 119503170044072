import { createContext } from 'react';

export type AutoLaunchableSource =
  | 'learning_platform'
  | 'discovery_portal'
  | 'discovery_portal_and_learning_platform'
  | 'program_dashboard'
  | 'share_url_with_auto_enroll_program'
  | 'none';

export const AutoLaunchableContext = createContext<AutoLaunchableSource>('none');
