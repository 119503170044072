import { concat } from '@execonline-inc/collections';
import { err, ok, Result } from 'resulty';
import { NonEmptyList } from 'nonempty-list';
import { detectBrowser, UserAgent } from '../../UserAgent';
import { VideoSourceFile } from '../JWPlayer/Types';
import { videoSourcesByLabel } from './Resource';
import {
  AnnouncementVideoAsset,
  VideoPlaybackAdaptiveSources,
  VideoPlaybackQuality,
  VideoPlaybackSource,
  VideoPlaybackSourceLabel,
} from './Types';

export interface Vimeo {
  kind: 'vimeo';
  vimeoId: number;
}

export interface ProgressiveLow {
  kind: 'progressive-low';
  sources: NonEmptyList<VideoSourceFile>;
}

export interface EdgePlayer {
  kind: 'edge';
  sources: NonEmptyList<VideoSourceFile>;
}

export interface ProgressivePlayer {
  kind: 'progressive';
  sources: NonEmptyList<VideoSourceFile>;
}

export interface Adaptive {
  kind: 'adaptive';
}

export interface NoSelection {
  kind: 'no-selection';
}

export interface Undetermined {
  kind: 'undetermined';
}

const vimeo = (vimeoId: number): Vimeo => ({ kind: 'vimeo', vimeoId });

const progressiveLow = (sources: NonEmptyList<VideoSourceFile>): ProgressiveLow => ({
  kind: 'progressive-low',
  sources,
});

const edgePlayer = (sources: NonEmptyList<VideoSourceFile>): EdgePlayer => ({
  kind: 'edge',
  sources,
});

const progressivePlayer = (sources: NonEmptyList<VideoSourceFile>): ProgressivePlayer => ({
  kind: 'progressive',
  sources,
});

const adaptive = (): Adaptive => ({ kind: 'adaptive' });

export const noSelection = (): NoSelection => ({ kind: 'no-selection' });

export const undetermined = (): Undetermined => ({ kind: 'undetermined' });

export type SelectedPlayer =
  | Undetermined
  | Vimeo
  | ProgressiveLow
  | EdgePlayer
  | ProgressivePlayer
  | Adaptive
  | NoSelection;

export interface MissingVimeoId {
  kind: 'missing-vimeo-id';
}

export interface MissingAdaptiveSources {
  kind: 'missing-adaptive-sources';
}

const missingVimeoId = (): MissingVimeoId => ({ kind: 'missing-vimeo-id' });

const missingAdaptiveSources = (): MissingAdaptiveSources => ({ kind: 'missing-adaptive-sources' });

export interface MissingProgressiveSources {
  kind: 'missing-progressive-sources';
  labels: VideoPlaybackSourceLabel[];
}

const missingProgressiveSources = (
  labels: VideoPlaybackSourceLabel[]
): MissingProgressiveSources => ({
  kind: 'missing-progressive-sources',
  labels,
});

export type VideoSelectionFailure =
  | MissingVimeoId
  | MissingAdaptiveSources
  | MissingProgressiveSources;

const vimeoPlayer = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], Vimeo> => {
  return announcementVideoAsset.vimeoId.cata({
    Just: n => ok(vimeo(n)),
    Nothing: () => err([missingVimeoId()]) as Result<VideoSelectionFailure[], Vimeo>,
  });
};

/**
 * Select the Progressive player, either as an explicit choice or as the fallback from
 * a failed adaptive player selection.
 */
const selectProgressive = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'progressive':
    case 'adaptive':
      return progressiveFromLabels(progressivePlayer, announcementVideoAsset.progressiveSources, [
        'Low',
        'Medium',
        'High',
      ]);
    case 'vimeo':
      return err([]);
  }
};

const selectVimeo = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'vimeo':
      return vimeoPlayer(announcementVideoAsset);
    case 'progressive':
    case 'adaptive':
      return err([]);
  }
};

const onlyLow = (qualities: VideoPlaybackQuality[]): boolean =>
  qualities.length === 1 && qualities[0] === 'low';

const selectProgressiveLow = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  const preferredPlayback = announcementVideoAsset.preferredVideoPlayback;
  switch (preferredPlayback.type) {
    case 'progressive':
      return onlyLow(preferredPlayback.qualities)
        ? progressiveFromLabels(progressiveLow, announcementVideoAsset.progressiveSources, ['Low'])
        : err([]);
    case 'adaptive':
    case 'vimeo':
      return err([]);
  }
};

const selectIeForMsie = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return announcementVideoAsset.adaptiveSources.cata({
        Just: (adaptiveSources: VideoPlaybackAdaptiveSources) =>
          progressiveFromLabels(progressivePlayer, adaptiveSources.fallbackSources, [
            'Low',
            'Medium',
            'High',
          ]),
        Nothing: () => err<VideoSelectionFailure[], SelectedPlayer>([missingAdaptiveSources()]),
      });
    case 'progressive':
      return vimeoPlayer(announcementVideoAsset);
    case 'vimeo':
      return err([]);
  }
};

const selectIeForTrident11 = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return announcementVideoAsset.adaptiveSources.cata({
        Just: (adaptiveSources: VideoPlaybackAdaptiveSources) =>
          progressiveFromLabels(progressivePlayer, adaptiveSources.fallbackSources, [
            'Low',
            'Medium',
            'High',
          ]),
        Nothing: () => err<VideoSelectionFailure[], SelectedPlayer>([missingAdaptiveSources()]),
      });
    case 'progressive':
      return vimeoPlayer(announcementVideoAsset);
    case 'vimeo':
      return err([]);
  }
};

const selectIE = (
  ua: UserAgent,
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  const browserDetected = detectBrowser(ua);
  switch (browserDetected.kind) {
    case 'msie':
      return selectIeForMsie(announcementVideoAsset);
    case 'trident':
      if (browserDetected.versionNumber === 11) {
        return selectIeForTrident11(announcementVideoAsset);
      } else {
        return err([]);
      }
    case 'safari':
    case 'electron':
    case 'edge':
    case 'other':
      return err([]);
  }
};

const selectSafariGivenSafariBrowser = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return announcementVideoAsset.adaptiveSources.cata({
        Just: (adaptiveSources: VideoPlaybackAdaptiveSources) =>
          progressiveFromLabels(progressivePlayer, adaptiveSources.fallbackSources, [
            'Low',
            'Medium',
            'High',
          ]),
        Nothing: () => err<VideoSelectionFailure[], SelectedPlayer>([missingAdaptiveSources()]),
      });
    case 'progressive':
    case 'vimeo':
      return err([]);
  }
};

const selectSafari = (
  ua: UserAgent,
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  const browserDetected = detectBrowser(ua);
  switch (browserDetected.kind) {
    case 'safari':
      return selectSafariGivenSafariBrowser(announcementVideoAsset);
    case 'msie':
    case 'trident':
    case 'electron':
    case 'edge':
    case 'other':
      return err([]);
  }
};

const selectEdgeGivenEdge = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'progressive':
      return progressiveFromLabels(edgePlayer, announcementVideoAsset.progressiveSources, [
        'Medium',
        'High',
      ]);
    case 'vimeo':
    case 'adaptive':
      return err([]);
  }
};

const selectEdge = (
  ua: UserAgent,
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  const browserDetected = detectBrowser(ua);
  switch (browserDetected.kind) {
    case 'edge':
      return selectEdgeGivenEdge(announcementVideoAsset);
    case 'msie':
    case 'other':
    case 'trident':
    case 'electron':
    case 'safari':
      return err([]);
  }
};

const selectAdaptive = (
  announcementVideoAsset: AnnouncementVideoAsset
): Result<VideoSelectionFailure[], SelectedPlayer> => {
  switch (announcementVideoAsset.preferredVideoPlayback.type) {
    case 'adaptive':
      return announcementVideoAsset.adaptiveSources.cata({
        Just: () => ok<VideoSelectionFailure[], SelectedPlayer>(adaptive()),
        Nothing: () => err<VideoSelectionFailure[], SelectedPlayer>([missingAdaptiveSources()]),
      });
    case 'progressive':
    case 'vimeo':
      return err([]);
  }
};

const progressiveFromLabels = (
  fn: (vs: NonEmptyList<VideoSourceFile>) => SelectedPlayer,
  sources: VideoPlaybackSource[],
  labels: VideoPlaybackSourceLabel[]
): Result<VideoSelectionFailure[], SelectedPlayer> =>
  videoSourcesByLabel(sources, labels).cata({
    Just: sources => ok(fn(sources)),
    Nothing: () =>
      err<VideoSelectionFailure[], SelectedPlayer>([missingProgressiveSources(labels)]),
  });

export interface ErrorTrackedResult<E, T> {
  result: T;
  errors: E[];
}

type SelectionResults = ErrorTrackedResult<VideoSelectionFailure, SelectedPlayer>;

export const announcementSelectPlayer = (
  ua: UserAgent,
  announcementVideoAsset: AnnouncementVideoAsset
): SelectionResults => {
  const nothingSelected: Result<VideoSelectionFailure[], SelectionResults> = err([]);
  return nothingSelected
    .orElse(withErrorTracking(selectProgressiveLow(announcementVideoAsset)))
    .orElse(withErrorTracking(selectEdge(ua, announcementVideoAsset)))
    .orElse(withErrorTracking(selectIE(ua, announcementVideoAsset)))
    .orElse(withErrorTracking(selectSafari(ua, announcementVideoAsset)))
    .orElse(withErrorTracking(selectVimeo(announcementVideoAsset)))
    .orElse(withErrorTracking(selectAdaptive(announcementVideoAsset)))
    .orElse(withErrorTracking(selectProgressive(announcementVideoAsset)))
    .cata({
      Ok: s => s,
      Err: errs => ({ result: noSelection(), errors: errs }),
    });
};

const withErrorTracking = <E, T>(result: Result<E[], T>) => (
  errs: E[]
): Result<E[], ErrorTrackedResult<E, T>> =>
  result.map(r => ({ result: r, errors: errs })).mapError(concat(errs));
