import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import {
  BeginnableAction,
  EnrollableAction,
  GraduatedAction,
  NotEnrollableAction,
  ResumableAction,
  ReturnableAction,
  UpcomingAction,
} from '../../Experience/ExperienceActions';
import { Experience } from '../Types';

interface Props {
  invitationUuid: string;
  experience: Experience;
  experiencesStore: ExperiencesStore;
  buttonProps?: ButtonProps;
  onClick?: () => void;
}

function ExperienceActions({
  invitationUuid,
  experience,
  experiencesStore,
  buttonProps,
  onClick,
}: Props) {
  switch (experience.kind) {
    case 'beginnable':
      return <BeginnableAction experience={experience} buttonProps={buttonProps} />;
    case 'commerce-enrollable':
    case 'enrollable':
      return (
        <EnrollableAction
          experience={experience}
          buttonProps={{ onClick: onClick, ...buttonProps }}
        />
      );
    case 'not-enrollable':
      return <NotEnrollableAction experience={experiencesStore.notEnrollableAlert} />;
    case 'resumable':
      return <ResumableAction experience={experience} buttonProps={buttonProps} />;
    case 'upcoming':
      return (
        <UpcomingAction
          invitationUuid={invitationUuid}
          experience={experience}
          buttonProps={buttonProps}
        />
      );
    case 'graduated':
      return <GraduatedAction />;
    case 'returnable':
      return <ReturnableAction experience={experience} buttonProps={buttonProps} />;
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(ExperienceActions);
