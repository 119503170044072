import { mergeObjectDecoders, nullableBlankString, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, {
  array,
  boolean,
  date,
  field,
  maybe,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import { learningPartnerResourceDecoder } from '../../LearningPartnersStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { ProcessingStatus } from '../../TeamResourceStore/Types';
import { videoAssetResourceDecoder } from '../../VideoStore/Legacy/Decoders';
import {
  DocumentResourceParts,
  Program,
  ResourceBase,
  ResourceParts,
  ResourceResource,
  ResourcesDashboard,
  UploadedAssignment,
  UploadedAssignmentResource,
  UserUploadedDocumentResourceParts,
  VideoResourceParts,
} from './Types';

const baseResourceDecoder: Decoder<ResourceBase> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string));

const documentResourcePartsDecoder: Decoder<DocumentResourceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('important', field('important', boolean))
  .assign('description', field('description', nullableBlankString))
  .assign('assetFileExtension', field('asset_file_extension', nullableBlankString));

const videoResourcePartsDecoder: Decoder<VideoResourceParts> = succeed({})
  .assign('kind', field('kind', stringLiteral('video')))
  .assign('videoAssetResource', field('video_asset_resource', videoAssetResourceDecoder))
  .assign('important', field('important', boolean))
  .assign('assetFileExtension', field('asset_file_extension', nullableBlankString))
  .assign('description', field('description', nullableBlankString))
  .assign('programId', field('program_id', number))
  .assign('segmentId', field('segment_id', maybe(number)))
  .assign('moduleId', field('module_id', maybe(number)));

const processingStatusDecoder: Decoder<ProcessingStatus> = oneOf([
  stringLiteral<ProcessingStatus>('completed'),
  stringLiteral<ProcessingStatus>('processing'),
]);

const uploadedAssignmentDecoder: Decoder<UploadedAssignment> = succeed({})
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('processingStatus', field('processing_status', processingStatusDecoder))
  .assign('createdAt', field('created_at', date))
  .assign('fileExtension', field('file_extension', nullableBlankString));

const uploadedAssignmentResourceDecoder: Decoder<UploadedAssignmentResource> =
  resourceDecoder(uploadedAssignmentDecoder);

const userUploadedDocumentResourcePartsDecoder: Decoder<UserUploadedDocumentResourceParts> =
  succeed({})
    .assign('kind', field('kind', stringLiteral('user-uploaded-document')))
    .assign('student', field('student', learningPartnerResourceDecoder))
    .assign('assignment', field('assignment', uploadedAssignmentResourceDecoder));

export const resourcePartsDecoder: Decoder<ResourceParts> = oneOf<ResourceParts>([
  documentResourcePartsDecoder.map<ResourceParts>(identity),
  videoResourcePartsDecoder.map<ResourceParts>(identity),
  userUploadedDocumentResourcePartsDecoder.map<ResourceParts>(identity),
]);

export const resourceItemDecoder = mergeObjectDecoders(baseResourceDecoder, resourcePartsDecoder);

export const resourceResourceDecoder: Decoder<ResourceResource> =
  resourceDecoder(resourceItemDecoder);

const programDecoder: Decoder<Program> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('startOn', field('start_on', maybe(date)));

const programResourceDecoder = resourceDecoder(programDecoder);

const resourcesDashboardDecoder: Decoder<ResourcesDashboard> = succeed({})
  .assign('programResources', field('programs', array(programResourceDecoder)))
  .assign('resourceResources', field('resources', array(resourceResourceDecoder)));

export const resourcesDashboardResourceDecoder = resourceDecoder(resourcesDashboardDecoder);
