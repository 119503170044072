import { Error } from '../../../../../../ErrorHandling';
import { FreebusyAdResource } from '../../../../../Dashboard/FreebusyCoachingAd/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Reloading {
  kind: 'reloading';
}

interface Ready {
  kind: 'ready';
  scheduleSessionResource: FreebusyAdResource;
}

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (): Loading => ({ kind: 'loading' });

export const reloading = (): Reloading => ({ kind: 'reloading' });

export const ready = (scheduleSessionResource: FreebusyAdResource): Ready => ({
  kind: 'ready',
  scheduleSessionResource,
});

export type State = Waiting | Loading | Ready | Reloading | Error;
