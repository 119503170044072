import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import ErrorActionableReaction, { EAProps } from '../../ErrorActionableReaction';
import Store from './Store';
import { State } from './Types';

interface Props extends EAProps<Store> {}

class Reactions extends ErrorActionableReaction<Store, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'navigate':
        break;
      case 'error':
        warn(state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(Reactions);
