import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { HTMLAttributes } from 'react';

function ExperienceSection({ children, className, ...others }: HTMLAttributes<HTMLDivElement>) {
  return (
    <section
      data-testid="learning-collection-experience-section"
      className={cn('mx-auto flex flex-col px-0 py-10 xl:container', className)}
      {...others}
    >
      {children}
    </section>
  );
}

export default observer(ExperienceSection);
