import { observer } from 'mobx-react';
import { SvgIcon, SvgIconProps } from '@execonline-inc/execonline-ui';

function LeadershipCoachingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 62 35">
      <path
        opacity="0.2"
        d="M61.8037 55.6906L25.8393 14.7969H0.0104761L37.012 55.6906L0 96.6256H25.8917L61.8037 55.6906Z"
        fill="url(#paint0_linear_4664_4390)"
      />
      <path
        opacity="0.6"
        d="M61.0717 20.5236L43.5726 0.625977H31.0051L49.0089 20.5236L31 40.4412H43.5981L61.0717 20.5236Z"
        fill="url(#paint1_linear_4664_4390)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4664_4390"
          x1="73.2251"
          y1="119.115"
          x2="-10.8681"
          y2="29.9298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE629D" />
          <stop offset="1" stopColor="#482A4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4664_4390"
          x1="66.629"
          y1="51.3837"
          x2="25.7119"
          y2="7.98917"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE629D" />
          <stop offset="1" stopColor="#482A4E" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export default observer(LeadershipCoachingIcon);
