import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import ThemeContext from '../../Themes/ThemeContext';
import { ModalSize, modalBodyClassNames, modalBodySizeMapping } from './Types';
import * as style from './style.module.css';

interface ModalBodyProps {
  className?: string;
  size?: ModalSize;
  children: ReactNode;
}

function ModalBody({ className, size = 'md', children }: ModalBodyProps) {
  return (
    <ThemeContext.Consumer>
      {({ kind }) => (
        <div
          data-theme={kind}
          className={clsx(
            style.body,
            modalBodySizeMapping[size],
            modalBodyClassNames[kind],
            className,
            modalBodyClassNames['light'],
          )}
          data-test-modal-body={true}
        >
          {children}
        </div>
      )}
    </ThemeContext.Consumer>
  );
}

export default observer(ModalBody);
