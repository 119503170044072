import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../../LinkyLinky';
import SegmentStore from '../../../SegmentStore';
import IFrameContent from '../../IFrameContent';
import WindowMessageReactions from '../../WindowMessageReactions';
import SurveyCompleted from './SurveyCompleted';

interface Props {
  segmentStore: SegmentStore;
}

const StreamlinedSurveySegment: React.FC<Props> = ({ segmentStore }) => {
  const iFrameRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  return findLink('enclosure', segmentStore.links)
    .map((link) => {
      return (
        <div data-test-survey-segment={link.href}>
          <WindowMessageReactions segmentStore={segmentStore} iFrameRef={iFrameRef} />
          <IFrameContent
            source={link.href}
            style={{ border: 0 }}
            seamless={true}
            width="100%"
            allowFullScreen={true}
            height="750px"
            scrollbars="on"
            iFrameRef={iFrameRef}
          />
        </div>
      );
    })
    .getOrElse(() => <SurveyCompleted />);
};
export default observer(StreamlinedSurveySegment);
