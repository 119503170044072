import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const LockIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 18 22">
      <path
        d="M15 7.062h1.654a1 1 0 0 1 1 1V21a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V8.062a1 1 0 0 1 1-1h1.778v-.95A6.097 6.097 0 0 1 8.89 0 6.097 6.097 0 0 1 15 6.111v.95ZM8.89 2.716c-1.934 0-3.396 1.52-3.396 3.53v.884h6.79v-.883c0-2.01-1.462-3.531-3.395-3.531Z"
        fill="#666"
      />
    </SVGIcon>
  );
};

export default observer(LockIcon);
