import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../Person/types';
import Popout from '../Popout';
import PopoutStore from '../Popout/PopoutStore';
import Profile from './Details/Profile';

interface Props {
  popoutStore: PopoutStore;
  personStore: PersonStoreContract;
  personRef: React.RefObject<HTMLDivElement>;
}

const ProfilePopout: React.FC<Props> = ({ popoutStore, personStore, personRef }) => (
  <Popout store={popoutStore} personRef={personRef}>
    <div data-test-profile-popout={true} style={{ margin: '12px 8px' }}>
      <Profile store={personStore} />
    </div>
  </Popout>
);

export default observer(ProfilePopout);
