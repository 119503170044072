import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { teamUrl } from '../../../ClientRoutes';
import TeamDiscussionStore from '../../../TeamDiscussionStore';
import { T } from '../../../Translations';
import InputWithLabel from '../../Form/InputWithLabel';
import TextareaWithLabel from '../../Form/TextareaWithLabel';
import * as style from './style.module.css';

interface Props {
  store: TeamDiscussionStore;
}

@observer
class TeamDiscussionForm extends React.Component<Props, {}> {
  setTitle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.store.setTitle(event.currentTarget.value);
  };

  setContent = (event: React.FormEvent<HTMLTextAreaElement>) => {
    this.props.store.setContent(event.currentTarget.value);
  };

  render() {
    return (
      <>
        <h3 data-test-team-discussion-form-title="Post to Team Discussion Area">
          <T kind="Post to Team Discussion Area" />
        </h3>
        {this.props.store.teamId
          .map((id) => (
            <Link to={teamUrl(id)} data-test-team-link={true}>
              <T kind="View Team Profile" />
            </Link>
          ))
          .getOrElseValue(<span />)}
        <form className={style.form} data-test-team-discussion-form={true}>
          <div className={style.field}>
            <InputWithLabel
              labelClassName={style.label}
              labelText="Title"
              name="title"
              id="title"
              type="text"
              className={style.input}
              onChange={this.setTitle}
              value={this.props.store.teamDiscussionTitle.getOrElseValue('')}
            />
          </div>
          <div className={style.field}>
            <TextareaWithLabel
              labelClassName={style.label}
              labelText="Content"
              onChange={this.setContent}
              className={style.textarea}
              name="content"
              id="content"
              value={this.props.store.teamDiscussionContent.getOrElseValue('')}
            />
          </div>
        </form>
      </>
    );
  }
}

export default TeamDiscussionForm;
