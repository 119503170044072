import { observer } from 'mobx-react';
import * as React from 'react';
import LearningPartnerUpload from '../LearningPartnerUpload';
import { LearningPartnerAssignment } from '../Types';

interface Props {
  assignment: LearningPartnerAssignment;
}

const LearningPartnerUploads: React.FC<Props> = ({ assignment }) => {
  const uploads: React.ReactNode = assignment.resources
    .map((documentResource) => documentResource.payload)
    .map((document) => (
      <LearningPartnerUpload key={document.id} assignment={assignment} resource={document} />
    ));
  return <>{uploads}</>;
};

export default observer(LearningPartnerUploads);
