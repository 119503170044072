import React from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { AlertProps, alertClassNameMapping } from './Types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    { className, children, shape = 'rounded-md', variant = 'solid', status = 'success', ...others },
    ref
  ) => {
    return (
      <div
        ref={ref}
        role="alert"
        className={twMerge(
          clsx(
            'p-3 text-center',
            'border-solid border-[3px]',
            shape,
            alertClassNameMapping[status][variant],
            className
          )
        )}
        {...others}
      >
        {children}
      </div>
    );
  }
);

export default observer(Alert);
