import * as React from 'react';
import { CalendarEvent } from '../../../EventsStore/Types';
import EventTimeEntry from './EventTimeEntry';

interface Props {
  event: CalendarEvent;
}

const EventStartOn: React.FC<Props> = ({ event }) => {
  switch (event.kind) {
    case 'upcoming-event':
    case 'current-coaching-event':
    case 'live-event':
      return <EventTimeEntry title="Starts" date={event.startAt} format="time-of-day" />;
    case 'other-event':
    case 'past-event':
      return <EventTimeEntry title="When" date={event.startAt} format="time-of-day" />;
  }
};

export default EventStartOn;
