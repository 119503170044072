import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const LowerHandIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 21 21">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M10 0a2.999 2.999 0 0 0-2.531 1.406A2.949 2.949 0 0 0 6 1C4.355 1 3 2.355 3 4v3.656L.906 10.344c-1.277 1.652-1.191 4.023.188 5.594l2.375 2.687A6.982 6.982 0 0 0 8.719 21H14c3.855 0 7-3.145 7-7V5c0-1.645-1.355-3-3-3-.535 0-1.031.156-1.469.406A2.999 2.999 0 0 0 14 1c-.535 0-1.031.156-1.469.406A2.999 2.999 0 0 0 10 0Zm0 2c.566 0 1 .434 1 1v3h2V4c0-.566.434-1 1-1 .566 0 1 .434 1 1v2h2V5c0-.566.434-1 1-1 .566 0 1 .434 1 1v9c0 2.773-2.227 5-5 5H8.719a4.97 4.97 0 0 1-3.75-1.688l-2.375-2.718a2.338 2.338 0 0 1-.094-3l.5-.656V12h2V4c0-.566.434-1 1-1 .566 0 1 .434 1 1v2h2V3c0-.566.434-1 1-1Z"
      />
    </SVGIcon>
  );
};

export default observer(LowerHandIcon);
