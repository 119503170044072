import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../Links';
import { rootResourceStore } from '../../../RootResourceStore';
import { T } from '../../../Translations';
import ClassicCopyrightNotice from '../../Footer/ClassicFooter/ClassicCopyrightNotice';
import ClassicDivider from '../../Footer/ClassicFooter/ClassicDivider';
import * as style from '../style.module.css';

function Footer() {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => (
      <div className={style.footer} data-test-footer={true} role="contentinfo">
        <ClassicCopyrightNotice />
        <ClassicDivider />
        <T
          kind="<link1>Privacy ..."
          link1={(content) => (
            <a
              href={privacyLink.href}
              target="_blank"
              rel="noopener noreferrer"
              data-test-privacy-policy-link={true}
            >
              {content}
            </a>
          )}
        />
        <ClassicDivider />
        <T
          kind="<link1>Terms ..."
          link1={(content) => (
            <a
              href={termsLink.href}
              target="_blank"
              rel="noopener noreferrer"
              data-test-terms-of-use-link={true}
            >
              {content}
            </a>
          )}
        />
        <ClassicDivider />
        <T
          kind="<link1>Sitemap</link1>."
          link1={(content) => (
            <a href="/p3/sitemap" data-test-sitemap-link={true}>
              {content}
            </a>
          )}
        />
      </div>
    ))
    .getOrElse(() => <></>);
}

export default observer(Footer);
