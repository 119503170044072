import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import { T } from '../../Translations';
import * as platformStyle from '../Platform/style.module.css';
import * as style from '../TeamMemberList/style.module.css';
import LoadMoreProgramMembers from './LoadMoreProgramMembers';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

const ProgramMemberRoutes: React.FC<Props> = ({ presenceMembersStore }) => (
  <Switch>
    <Route
      exact={true}
      path="/program_chats/:programId"
      render={() => (
        <div className={style.scrollableList}>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Program Members" />
          </h2>
          <LoadMoreProgramMembers presenceMembersStore={presenceMembersStore} />
        </div>
      )}
    />
    <Route
      exact={true}
      path="/program_chat"
      render={() => (
        <>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Program Members" />
          </h2>
          <LoadMoreProgramMembers presenceMembersStore={presenceMembersStore} />
        </>
      )}
    />
  </Switch>
);

export default observer(ProgramMemberRoutes);
