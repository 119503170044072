import { mapMaybe } from '@execonline-inc/collections';
import type Decoder from 'jsonous';
import { Task } from 'taskarian';
import { DecoderError, FetchError, HttpError, NetworkError } from './Errors';
import { ForRequest, HeaderTuple, HeaderTupleM, ReadResponse } from './Types';

export const toHeaders = (pairs: readonly HeaderTupleM[]): Headers => {
  const headers = new Headers();

  mapMaybe<HeaderTupleM, HeaderTuple>(
    ([name, value]) => value.map((v) => [name, v]),
    pairs,
  ).forEach((pair) => headers.set(...pair));

  return headers;
};

const dispatchRequest = (request: Request): Task<NetworkError, Response> =>
  Task.fromPromise(() => fetch(request)).mapError((error) => ({ kind: 'network-error', error }));

const whenResponseOk = (response: ReadResponse<string>): Task<HttpError, ReadResponse<string>> =>
  response.response.ok
    ? Task.succeed(response)
    : Task.fail({ kind: 'http-error', response, error: 'HTTP request not successful' });

const readResponse = (response: Response): Task<NetworkError, ReadResponse<string>> =>
  Task.fromPromise(() => response.text())
    .map((data) => ({ response, data }))
    .mapError((error) => ({ kind: 'network-error', error }));

export const decodedBy =
  <T>(decoder: Decoder<T>) =>
  (response: ReadResponse<string>): Task<DecoderError, ReadResponse<T>> =>
    decoder
      .decodeJson(response.data)
      .mapError((error): DecoderError => ({ kind: 'decoder-error', response, error }))
      .cata<Task<DecoderError, ReadResponse<T>>>({
        Err: Task.fail,
        Ok: (data) => Task.succeed({ response: response.response, data }),
      });

const mergeHeaders = (...headers: readonly HeadersInit[]): Headers =>
  new Headers(headers.flatMap((h) => Array.from<[string, string]>(new Headers(h))));

const toRequest = ({ method, href, config, options = {} }: ForRequest): Request =>
  new Request(href, {
    ...config.options,
    ...options,
    method,
    headers: options.headers ? mergeHeaders(config.headers(), options.headers) : config.headers(),
    signal: options.signal || config.signal,
  });

export const fetchWithoutDecoder = (req: ForRequest): Task<FetchError, ReadResponse<string>> =>
  Task.succeed<FetchError, Request>(toRequest(req))
    .andThen(dispatchRequest)
    .andThen(readResponse)
    .andThen(whenResponseOk)
    .orElse((error) =>
      req.config.toRetry({
        ...req,
        error,
        fetch: fetchWithoutDecoder,
      }),
    );

export const fetchWithDecoder = <T>(
  req: ForRequest,
  decoder: Decoder<T>,
): Task<FetchError, ReadResponse<T>> => fetchWithoutDecoder(req).andThen(decodedBy(decoder));
