import { observer } from 'mobx-react';
import * as React from 'react';
import SupportLink from '../../../../src/Native/AEP/SupportLink/SupportLink';
import { T } from '../../../Translations';
import * as style from './style.module.css';
import { Typography } from '@execonline-inc/execonline-ui';

const EmptyState: React.FC = () => (
  <div>
    <Typography as="p" className={style.strong} color="black">
      <T kind={'Earn certificates from top business schools. Enroll with ExecOnline.'} />
    </Typography>
    <Typography as="p" className={style.description} color="black">
      <T kind={'You are not currently enrolled in any Experiences. Browse ...'} />
    </Typography>
    <Typography as="p" className={style.description} color="black">
      <T
        kind="If you do not see the Explore link you should ..."
        link={(content) => (
          <SupportLink link="https://support.execonline.com/s/?cid=0031Y000069fvn7QAA">
            <Typography className="underline">{content}</Typography>
          </SupportLink>
        )}
      />
    </Typography>
    <Typography as="p" className={style.description} color="black">
      <T kind={'The ExecOnline team looks forward to hosting you in ...'} />
    </Typography>
  </div>
);

export default observer(EmptyState);
