import { observer } from 'mobx-react';
import { ExperienceVideoPreview } from '../../../Common/ProgramExperienceVideo';
import ExperiencesStore from '../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';

interface Props {
  experiencesStore: ExperiencesStore;
}

function ExperienceVideo({ experiencesStore }: Props) {
  return (
    <ExperienceVideoPreview
      className="mt-10 h-[12.125rem] w-86 flex-shrink-0 rounded-3xl sm:h-[16.25rem] sm:w-115 lg:mt-0"
      experiencesStore={experiencesStore}
    />
  );
}

export default observer(ExperienceVideo);
