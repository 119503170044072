import {
  Type,
  type StaticDecode,
  type StringOptions,
  type TSchema,
  type TTransform,
} from '@sinclair/typebox/type';
import * as date from 'date-fns';
import { just, nothing, type Maybe } from 'maybeasy';

export const TransformType = {
  DateTime: (options?: StringOptions) => {
    const T = Type.String({ ...options, format: 'date-time' });

    const decoder = (d: string) => date.parseISO(d);

    const encoder = (d: Date) =>
      date.formatISO(d, {
        format: 'extended',
        representation: 'complete',
      });

    return Type.Transform(T).Decode(decoder).Encode(encoder);
  },
  Date: (options?: StringOptions) => {
    const T = Type.String({ ...options, format: 'date' });

    const decoder = (d: string) => date.parseISO(d);

    const encoder = (d: Date) =>
      date.formatISO(d, {
        format: 'extended',
        representation: 'date',
      });

    return Type.Transform(T).Decode(decoder).Encode(encoder);
  },
  Time: (options?: StringOptions) => {
    const T = Type.String({ ...options, format: 'time' });

    const decoder = (d: string) => date.parseISO(d);

    const encoder = (d: Date) =>
      date.formatISO(d, {
        format: 'extended',
        representation: 'time',
      });

    return Type.Transform(T).Decode(decoder).Encode(encoder);
  },
  Maybe: <S extends TSchema>(type: S) => {
    type ExplicitJust<V> = {
      readonly kind: 'just';
      readonly value: V;
    };

    type ExplicitNothing = {
      readonly kind: 'nothing';
    };

    type ExplicitMaybe<V> = ExplicitJust<V> | ExplicitNothing;

    type T = StaticDecode<S>;

    const T = Type.Union([
      Type.Object({
        kind: Type.Readonly(Type.Literal('just')),
        value: Type.Readonly(type),
      }),
      Type.Object({
        kind: Type.Readonly(Type.Literal('nothing')),
      }),
    ]);

    const decoder = (maybe: ExplicitMaybe<T>): Maybe<T> => {
      switch (maybe.kind) {
        case 'nothing':
          return nothing();
        case 'just':
          return just(maybe.value);
      }
    };

    const encoder = (maybe: Maybe<T>) => {
      return maybe.cata<ExplicitMaybe<T>>({
        Just: (value) => ({ kind: 'just', value }),
        Nothing: () => ({ kind: 'nothing' }),
      });
    };

    /**
     * The TypeBox types are unsatisfiable without the typecast to `any` here.  The functions are
     * typed correctly, but the compiler seems to get choked up on TypeBox's internals.  The type
     * issue arises when this transformer is made generic (`extends TSchema`).  The issue is not
     * present when, for example, it's hardcoded for `Type.String()`, so it seems that things are
     * too generic for TypeScript to be satisfied.
     */
    return Type.Transform(T)
      .Decode(decoder as any)
      .Encode(encoder as any) as TTransform<typeof T, Maybe<T>>;
  },
};
