import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import { FSInitializationError } from './Errors';
import { State } from './States';
import { FsFn } from './Types';

export class FullStoryToolingStore {
  @observable state: State = { kind: 'waiting' };

  @action loading = (): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = { kind: 'loading' };
        break;
      case 'loading':
      case 'loaded':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action loaded = (fs: FsFn): void => {
    switch (this.state.kind) {
      case 'loading':
        this.state = { kind: 'loaded', fs };
        break;
      case 'waiting':
      case 'loaded':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action errored = (error: FSInitializationError): void => {
    switch (this.state.kind) {
      case 'loading':
        this.state = { kind: 'errored', error };
        break;
      case 'waiting':
      case 'loaded':
      case 'errored':
        break;
      default:
        assertNever(this.state);
    }
  };
}
