import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';

const FlashWarning: React.FC = ({ children }) => (
  <div className={style.notice} data-test-flash-message="warning">
    <div className={style.warning}>{children}</div>
  </div>
);

export default observer(FlashWarning);
