import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { L } from '../../../../../../../../../Translations';
import { AvailabilityResource } from '../../../../../../Experience/Types';

interface Props {
  availability: AvailabilityResource;
}

function ScheduledDateTitle({ availability }: Props) {
  switch (availability.payload.kind) {
    case 'on-demand':
      return emptyFragment();
    case 'scheduled':
      return (
        <Typography fontWeight="bold" as="span">
          <L localizeable={availability.payload.date} format="long-weekday-month-year" />
        </Typography>
      );
  }
}

export default observer(ScheduledDateTitle);
