import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../UI';

const ClassicDivider: React.FC = () => (
  <Typography className="mx-2 leading-none" component="span">
    <NotTranslated text="|" />
  </Typography>
);

export default observer(ClassicDivider);
