import { assertNever } from '@kofno/piper';
import { fromEmpty, just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { validateEmail } from '../EmailAddress';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert, successAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { creating, loading, ready, requested, ResetPasswordState, waiting } from './Types';

export const missingEmailErrorMessage = 'Email address is required';

class ResetPasswordStore {
  @observable
  state: ResetPasswordState;

  constructor() {
    this.state = waiting();
  }

  @computed
  get email(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
      case 'creating':
      case 'requested':
        return this.state.email;
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (email: Maybe<string>) => {
    this.state = ready(email);
  };

  @action
  creating = () => {
    validateEmail(this.email).cata({
      Err: (err) => {
        switch (err.kind) {
          case 'invalid-email-error': {
            this.state = error('Please provide a valid email address');
            break;
          }
          case 'missing-email-error': {
            this.state = error(missingEmailErrorMessage);
            break;
          }
          default:
            assertNever(err);
        }
      },
      Ok: (email: string) => {
        this.state = creating(email);
      },
    });
  };

  @action
  setEmail = (email: string) => {
    this.state = ready(fromEmpty(email));
  };

  @action
  requested = () => {
    switch (this.state.kind) {
      case 'creating':
        this.state = requested();
        break;
      case 'ready':
      case 'waiting':
      case 'loading':
      case 'requested':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'ready':
      case 'creating':
        return nothing();
      case 'requested':
        return just(
          successAlert(
            'You will receive an email with instructions about how to reset your password in a few minutes.'
          )
        );
      case 'error':
        return just(this.state).map(errorAlert);
    }
  }
}

export default ResetPasswordStore;
