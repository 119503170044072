import { observer } from 'mobx-react';
import * as React from 'react';
import { PresentationSegment as TPresentationSegment } from '../../../PresentationStore/Types';
import SegmentStore from '../../../SegmentStore';
import WindowMessageReactions from '../../WindowMessageReactions';

interface Props {
  segmentStore: SegmentStore;
  segment: TPresentationSegment;
}

const PresentationSegment: React.FC<Props> = ({ segmentStore, segment }) => {
  const iFrameRef: React.RefObject<HTMLIFrameElement> = React.createRef();
  return segmentStore.enclosureLink
    .get()
    .map((link) => {
      return (
        <div data-test-presentation-segment={segment.id}>
          <WindowMessageReactions segmentStore={segmentStore} iFrameRef={iFrameRef} />
          <div data-test-iframe-content={link.href}>
            <iframe
              src={link.href}
              style={{ border: 0 }}
              title="Presentation"
              seamless={true}
              width={'100%'}
              height={`${segment.height}px`}
              allowFullScreen={true}
              ref={iFrameRef}
              allow="camera;microphone"
            />
          </div>
        </div>
      );
    })
    .getOrElseValue(<span />);
};
export default observer(PresentationSegment);
