import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { teamUrl } from '../../ClientRoutes';
import { T } from '../../Translations';
import ResourcesIcon from '../Cutesies/ResourcesIcon';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const TeamFiles: React.FC<Props> = ({ communityTeamResource }) => (
  <div className={style.item}>
    <div className={style.filesIcon}>
      <ResourcesIcon fill="var(--accent-secondary)" />
    </div>
    <Link to={teamUrl(communityTeamResource.payload.id)} data-test-team-files-link={true}>
      <T kind="File: {{count}}" count={communityTeamResource.payload.assignmentCount} />
    </Link>
  </div>
);

export default observer(TeamFiles);
