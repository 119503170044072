import { observable, action } from 'mobx';
import { State, ready, pollLaunched, EventPoll } from './Types';

class EventPollStore {
  @observable
  state: State = ready();

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  launchPoll = (poll: EventPoll) => {
    this.state = pollLaunched(poll);
  };
}

export default EventPollStore;
