import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { teamConferenceUrl } from '../../ClientRoutes';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import TeamStore from '../../TeamStore';
import { TeamMemberResource, TeamResource } from '../../TeamsStore/Types';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import TeamMemberList from '../TeamMemberList';
import * as style from './style.module.css';

interface Props {
  teamResource: TeamResource;
  teamStore: TeamStore;
  presenceMembersStore: PresenceMembersStore;
  presenceStore: GlobalPresenceMembersStore;
}

const TeamMeetingContent: React.FC<Props> = ({
  teamResource,
  teamStore,
  presenceMembersStore,
  presenceStore,
}) =>
  teamResource.payload.conferenceRoom
    .map(() => (
      <p>
        <Link
          to={teamConferenceUrl(teamResource.payload.id)}
          className={style.joinButton}
          data-test-team-conference-link={true}
          aria-describedBy="team-meeting-instructions"
        >
          <T kind="Launch Video Meeting" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </Link>
        <p className={style.actionsText} id="team-meeting-instructions">
          <T kind="Team video meetings are used to..." />
        </p>
        {teamStore
          .onlineTeamMembers(presenceMembersStore)
          .map((onlineTeamMembers: TeamMemberResource[]) => (
            <TeamMemberList
              teamMembers={onlineTeamMembers}
              presenceMembersStore={just(presenceMembersStore)}
            />
          ))
          .getOrElse(emptyFragment)}
      </p>
    ))
    .getOrElse(() => <></>);

export default observer(TeamMeetingContent);
