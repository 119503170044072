import * as React from 'react';
import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import CalendarDateIcon from '../../CalendarDateIcon';
import { L, T } from '../../../Translations';
import { UpcomingSessionPayload } from '../../../ProgramStore/Types';
import UpcomingSessionPlaceholder from './UpcomingSessionPlaceholder';
import style from './styles.module.css';
interface Props {
  upcomingSession: Maybe<UpcomingSessionPayload>;
}

const UpcomingSession: React.FC<Props> = ({ upcomingSession }) => {
  return upcomingSession
    .map((upcomingSessionResource) => {
      return (
        <div className={style.blockWrapper}>
          <p className={style.programSecondaryTitle}>
            <T kind="Upcoming session" />
          </p>
          <div className={style.sessionBlock}>
            <CalendarDateIcon date={upcomingSessionResource.payload.startAt} />
            <div className={style.eventContainer}>
              <div className={style.dateContainer}>
                <L localizeable={upcomingSessionResource.payload.startAt} format="long-weekday" />
                <span>, </span>
                <L localizeable={upcomingSessionResource.payload.startAt} format="time-of-day" />
              </div>
              <p>
                <T
                  kind="{{count}} minute"
                  count={upcomingSessionResource.payload.durationInMinutes}
                />
              </p>
            </div>
          </div>
        </div>
      );
    })
    .getOrElse(() => <UpcomingSessionPlaceholder />);
};
export default observer(UpcomingSession);
