import { assertNever } from '@kofno/piper';
import { AppyError, callApi } from '../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../ErrorActionableReaction';
import { findLink } from '../../LinkyLinky';
import NotesStore, { State } from '../../NotesStore';
import { notesResourceDecoder } from '../../NotesStore/Decoders';
import { Linkable } from '../../Resource/Types';

const notesEndPoint = callApi(notesResourceDecoder, {});

interface Props extends EAProps<NotesStore> {
  resourceWithNotes: Linkable;
}

const handleNotesError = (store: NotesStore) => (error: AppyError) => handleError(store, error);

class NotesReactions extends ErrorActionableReaction<NotesStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'loading':
        findLink('notes', this.props.resourceWithNotes.links)
          .map(notesEndPoint)
          .map((task) => task.fork(handleNotesError(this.props.store), this.props.store.ready));
        break;
      case 'waiting':
        this.props.store.loading();
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default NotesReactions;
