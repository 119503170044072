import * as React from 'react';
import ProgramResourcesStore from './ProgramResourcesStore';
import { ProgramResourcesModule } from './ProgramResourcesStore/Types';
import DisplayedCount from './DisplayedCount';

interface Props {
  store: ProgramResourcesStore;
  module: ProgramResourcesModule;
}

const ResourceCount: React.FC<Props> = ({ store, module }) =>
  store
    .moduleResourceCount(module.id)
    .map((count) => <DisplayedCount count={count} />)
    .getOrElseValue(<></>);

export default ResourceCount;
