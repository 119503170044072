import { ReactElement, ReactNode } from 'react';

interface Props {
  icon: ReactElement;
  children: ReactNode;
}

function ExperienceAlert({ icon, children }: Props) {
  return (
    <div className="flex items-center gap-x-2 rounded bg-neutral-100 px-3.5 py-2">
      {icon}
      {children}
    </div>
  );
}

export default ExperienceAlert;
