import { nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { now } from 'mobx-utils';
import * as React from 'react';
import { ChatMessage as TChatMessage } from '../../ConversationStore/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { PersonStore } from '../../Person';
import { distanceOfTimeInWords } from '../../Time';
import { T, TProps } from '../../Translations';
import * as style from '../Conference/style.module.css';
import LinkifyWithCharEntities from '../LinkifyWithCharEntities';
import ChatPerson from './ChatPerson';

interface Props {
  message: TChatMessage;
  personStore: PersonStore;
}

const messageAge = (createdAt: Date): TProps => {
  const observableNow = new Date(now(60000));
  return observableNow < createdAt
    ? distanceOfTimeInWords(createdAt, observableNow)
    : distanceOfTimeInWords(observableNow, createdAt);
};

const ChatMessage: React.FC<Props> = ({ message, personStore }) => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <div className={style.chat}>
        <div
          className={
            message.userId == currentUserResource.payload.id ? style.outgoing : style.incoming
          }
        >
          <div className={style.person}>
            <div className={style.avatar}>
              <ChatPerson personStore={personStore} presenceStore={nothing()} />
            </div>
          </div>
          <div className={style.message}>
            <p className={style.content}>
              <LinkifyWithCharEntities content={message.content} />
            </p>
            <div className={style.timestamp}>
              <T {...messageAge(message.createdAt)} />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default observer(ChatMessage);
