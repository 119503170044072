import { findPayload } from '@execonline-inc/collections';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutChannelImpl from '../../BreakoutChannelImpl';
import { whenLocalConferenceRoom } from '../../Conference/Types';
import { CurrentUserResource } from '../../CurrentUser/Types';
import EventsStore from '../../EventsStore';
import EventsReactions from '../../EventsStore/EventsReactions';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import ReturnActionImpl from './ReturnActionImpl';

interface Props {
  eventId: number;
  teamId: number;
  staffRequestStore: StaffRequestStore;
  currentUserResource: CurrentUserResource;
}

class ReturnAction extends React.Component<Props> {
  eventsStore: EventsStore = new EventsStore(this.props.currentUserResource);

  renderBreakoutChannel = () => {
    const state = this.eventsStore.state;
    switch (state.kind) {
      case 'ready':
      case 'refresh':
        return just({})
          .assign('event', findPayload(this.props.eventId, state.eventsResource.payload))
          .assign('conferenceRoom', ({ event }) => event.payload.conferenceRoom)
          .assign('breakoutRooms', ({ conferenceRoom }) =>
            whenLocalConferenceRoom(conferenceRoom.payload).andThen((cr) => cr.breakoutRooms)
          )
          .map(({ conferenceRoom, breakoutRooms }) => {
            const breakoutStore = new BreakoutChannelStore(breakoutRooms.first);
            return (
              <BreakoutChannelImpl
                channelName={`event-${this.props.eventId}`}
                publicChannelStore={breakoutStore}
              >
                <ReturnActionImpl
                  breakoutStore={breakoutStore}
                  eventId={this.props.eventId}
                  conferenceRoomResource={conferenceRoom}
                  staffRequestStore={this.props.staffRequestStore}
                />
              </BreakoutChannelImpl>
            );
          })
          .getOrElse(() => <span />);

      case 'error':
      // Need to chat with E & B about what to do here
      case 'loading':
      case 'waiting':
        return <span />;
    }
  };

  render() {
    return (
      <>
        {this.renderBreakoutChannel()}
        <EventsReactions store={this.eventsStore} fireImmediately={true} />
      </>
    );
  }
}

export default observer(ReturnAction);
