import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunityResource } from '../../CommunitiesStore/Types';
import CommunityContent from './CommunityContent';

interface Props {
  communitiesStore: CommunitiesStore;
  communitiesResource: CommunityResource[];
}
const CommunitiesContent: React.FC<Props> = ({ communitiesStore, communitiesResource }) => (
  <>
    {communitiesResource.map((communityResource) => (
      <CommunityContent
        communitiesStore={communitiesStore}
        communityResource={communityResource}
        key={communityResource.payload.id}
      />
    ))}
  </>
);

export default observer(CommunitiesContent);
