import { Experience } from '../../../Common/Experience/Types';

export const schoolPartnerLogoProps = (
  experience: Experience,
): { height: number; logoLinkType?: 'logo-on-white' | 'logo-on-dark' } => {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return { logoLinkType: 'logo-on-dark', height: 32 };
    case 'aep':
    case 'epc':
    case 'msuite':
      return { logoLinkType: 'logo-on-white', height: 50 };
  }
};
