import { useEffect } from 'react';
import { equals } from '@execonline-inc/collections';
import { useOpenable } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import useMobileNavbarSwitcher from './useMobileNavbarSwitcher';

function useNavbarOpenable() {
  const { isOpen, onOpen, onClose } = useOpenable();
  const mode = useMobileNavbarSwitcher();

  useEffect(() => {
    when(equals(mode, 'desktop') && isOpen, true).do(onClose);
  }, [isOpen, mode]);

  return { mode, isOpen, onOpen, onClose };
}

export default useNavbarOpenable;
