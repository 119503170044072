import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import ProgramChatStore from '.';
import { AppyError, callApi } from '../Appy';
import { communitiesUrl } from '../ClientRoutes';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { nav } from '../Navigation';
import { Link } from '../Resource/Types';
import { programResourceDecoder } from './Decoders';
import { ProgramState } from './Types';

const programEndPoint = callApi(programResourceDecoder, {});

type ProgramChatReactionError = MissingProgramId | MissingLinkError | AppyError;

export interface Props extends EAProps<ProgramChatStore> {
  store: ProgramChatStore;
  programId: Maybe<number>;
  currentUserResource: CurrentUserResource;
}

interface MissingProgramId {
  kind: 'missing-program-id';
}

const handleBadStatusRest = (store: ProgramChatStore, err: BadStatus) => {
  switch (err.response.status) {
    case 404:
      store.error('You are not a member of this program.');
      break;
    case 400:
      store.error('Document was rejected by the server. Wrong format.');
      break;
    case 401:
      store.error('You need to be authenticated to perform that operation');
      break;
    case 403:
      store.error('You are not permitted to perform that operation');
      break;
    default:
      handleError(store, err);
  }
};

const handleBadStatus = (store: ProgramChatStore, err: BadStatus) => {
  switch (store.state.kind) {
    case 'error':
    case 'ready':
    case 'loading':
    case 'waiting':
      handleBadStatusRest(store, err);
      break;
    default:
      assertNever(store.state);
  }
};

const handleProgramRequestError =
  (store: ProgramChatStore) => (error: ProgramChatReactionError) => {
    switch (error.kind) {
      case 'missing-link-error':
        nav(communitiesUrl);
        break;
      case 'missing-program-id':
        store.error('Program ID is not present');
        break;
      case 'bad-status':
        handleBadStatus(store, error);
        break;
      case 'bad-url':
      case 'timeout':
      case 'missing-application-id':
      case 'network-error':
      case 'bad-payload':
        handleError(store, error);
        break;
    }
  };

const processTemplateLink =
  (programId: Maybe<number>) =>
  (link: Link): Task<MissingProgramId, Link> =>
    Task.succeed<MissingProgramId, Maybe<number>>(programId)
      .andThen(toTask({ kind: 'missing-program-id' } as MissingProgramId))
      .map((v) => ({
        ...link,
        href: link.href.replace('{program_id}', `${v}`),
      }));

const programLink =
  (programId: Maybe<number>) =>
  (links: ReadonlyArray<Link>): Task<ProgramChatReactionError, Link> =>
    Task.succeed<ProgramChatReactionError, ReadonlyArray<Link>>(links)
      .andThen(findLinkT('program-template'))
      .andThen(processTemplateLink(programId))
      .orElse(() => findLinkT('program', links));

class ProgramChatReactions extends ErrorActionableReaction<ProgramChatStore, ProgramState, Props> {
  tester = () => this.props.store.state;

  effect = (state: ProgramState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        Task.succeed<ProgramChatReactionError, ReadonlyArray<Link>>(
          this.props.currentUserResource.links,
        )
          .andThen(programLink(this.props.programId))
          .andThen(programEndPoint)
          .fork(handleProgramRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProgramChatReactions;
