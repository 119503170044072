import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import { ProgramDetailResource } from '../../../../../ProgramStore/Types';
import ActiveProgramOverview from '../../../../ActiveProgramOverview';
import CompletedProgramOverview from '../../../../CompletedProgramOverview';
import ExpiredProgramOverview from '../../../../ExpiredProgramOverview';
import UpcomingProgramOverview from '../../../../UpcomingProgramOverview';

interface Props {
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
}

const ProgramOverviewWrapper: React.FC<Props> = ({ programDetailResource, store }) => {
  switch (programDetailResource.payload.kind) {
    case 'active':
      return (
        <ActiveProgramOverview
          programDetailResource={programDetailResource}
          activeProgram={programDetailResource.payload}
          store={store}
        />
      );
    case 'upcoming':
      return (
        <UpcomingProgramOverview
          programDetail={programDetailResource.payload}
          links={programDetailResource.links}
        />
      );
    case 'completed':
      return (
        <CompletedProgramOverview
          programDetail={programDetailResource.payload}
          links={programDetailResource.links}
        />
      );
    case 'expired':
      return (
        <ExpiredProgramOverview
          programDetail={programDetailResource.payload}
          links={programDetailResource.links}
        />
      );
    case 'inactive':
      return <></>;
  }
};

export default ProgramOverviewWrapper;
