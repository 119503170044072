import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { ok } from 'resulty';
import { dashboardUrl } from '../../ClientRoutes';
import ProgramStore from '../../ProgramStore';
import ProgramSummaryImpl from './ProgramSummaryImpl';
import { findProgramResource, ProgramSummaryError, whenViewable } from './Types';

interface Params {
  programId: string;
}

interface Props {
  match: { params: Params };
}

class ProgramSummary extends React.Component<Props, {}> {
  programStore: ProgramStore = new ProgramStore();

  programResourceResult = ok<ProgramSummaryError, string>(this.props.match.params.programId)
    .andThen(findProgramResource)
    .andThen(whenViewable);

  componentDidMount() {
    this.programResourceResult.cata({
      Ok: this.programStore.loading,
      Err: noop,
    });
  }

  render() {
    return this.programResourceResult.cata({
      Ok: (programResource) => (
        <ProgramSummaryImpl programStore={this.programStore} programResource={programResource} />
      ),
      Err: (e) => {
        switch (e.kind) {
          case 'program-not-found':
            return <Redirect to="/404" />;
          case 'program-not-viewable':
            return <Redirect to={dashboardUrl} />;
        }
      },
    });
  }
}

export default observer(ProgramSummary);
