import { Maybe, nothing } from 'maybeasy';
import AttachmentUploadStore from '../AttachmentUploadStore';
import { UploadedAttachmentResources } from '../AttachmentUploadStore/Types';
import { Error } from '../ErrorHandling';
import { SegmentBase, TeamDiscussionSubmissionParams } from '../SegmentStore/Types';

interface Waiting {
  kind: 'waiting';
  segment: TeamDiscussionSegment;
}

interface Loading {
  kind: 'loading';
  segment: TeamDiscussionSegment;
}

interface Ready {
  kind: 'ready';
  segment: TeamDiscussionSegment;
  submissionParams: TeamDiscussionSubmissionParams;
}

export type TeamDiscussionState = Waiting | Loading | Ready | Error;

export const waiting = (segment: TeamDiscussionSegment): Waiting => ({
  kind: 'waiting',
  segment,
});

export const loading = (segment: TeamDiscussionSegment): Loading => ({
  kind: 'loading',
  segment,
});

export const ready = (segment: TeamDiscussionSegment): Ready => ({
  kind: 'ready',
  segment,
  submissionParams: {
    type: 'team-discussion',
    uploads: nothing(),
    title: segment.teamDiscussion.andThen((td: TeamDiscussion) => td.title),
    content: segment.teamDiscussion.andThen((td: TeamDiscussion) => td.content),
    attachmentUploadStore: new AttachmentUploadStore(),
  },
});

export interface TeamDiscussion {
  title: Maybe<string>;
  content: Maybe<string>;
}

export interface TeamDiscussionParts {
  type: 'team-discussion';
  content: string;
  teamDiscussion: Maybe<TeamDiscussion>;
  uploadedAttachments: UploadedAttachmentResources;
  teamId: Maybe<number>;
}

export interface TeamDiscussionSegment extends SegmentBase, TeamDiscussionParts {}
