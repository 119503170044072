import { Maybe } from 'maybeasy';
import { CommunitiesResource } from '../../../../CommunitiesStore/Types';
import { Error } from '../../../../ErrorHandling';

export interface SearchParameter {
  key: string;
  value: string;
}

export type SearchParameters = SearchParameter[];

interface Common {
  searchParameters: SearchParameters;
}

interface Waiting extends Common {
  kind: 'waiting';
}

export interface Loading extends Common {
  kind: 'loading';
}

export interface AddFilter extends Common {
  kind: 'add-filter';
  filterType: string;
  filter: string;
}

export interface LoadAll extends Common {
  kind: 'load-all';
  communitiesResource: CommunitiesResource;
}

interface Ready extends Common {
  kind: 'ready';
  communitiesResource: CommunitiesResource;
}

export const addFilter = (
  searchParameters: SearchParameters,
  filterType: string,
  filter: string
): AddFilter => {
  return {
    kind: 'add-filter',
    searchParameters: searchParameters,
    filterType: filterType,
    filter: filter,
  };
};
export const loading = (state: Ready | Waiting | LoadAll | AddFilter): Loading => {
  const { kind, ...previous } = state;
  return {
    kind: 'loading',
    ...previous,
  };
};

export const ready = (
  communitiesResource: CommunitiesResource,
  state: Loading | Waiting | LoadAll
): Ready => {
  const { kind, ...previous } = state;
  return {
    kind: 'ready',
    ...previous,
    communitiesResource,
  };
};

export const loadAll = (
  communitiesResource: CommunitiesResource,
  state: Ready | Waiting | Loading
): LoadAll => {
  const { kind, ...previous } = state;
  return {
    kind: 'load-all',
    ...previous,
    communitiesResource,
  };
};

export const waiting = (): Waiting => ({
  kind: 'waiting',
  searchParameters: [
    { key: 'team_id', value: '' },
    { key: 'program_id', value: '' },
    { key: 'query', value: '' },
  ],
});

export interface Program {
  id: Maybe<number>;
  title: Maybe<string>;
}

export type CoachTeamsState = Waiting | Loading | Ready | LoadAll | AddFilter | Error;

export const replaceSearchParameter = (
  key: string,
  value: string,
  searchParams: SearchParameters
): SearchParameters => {
  return searchParams.filter((param) => param.key !== key).concat({ key, value });
};
