import { Button, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import { EmailField, FormTextField, TermsAndConditionsField } from '../../../../Form';
import { InvitationStore } from '../../InvitationStore';
import { LockedAccessState, State } from '../../InvitationStore/Types';
import LockedUserStatusMessage from './LockedUserStatusMessage';

interface Props {
  store: InvitationStore;
  state: LockedAccessState;
}

interface LockedUserMessageProps {
  state: State;
}

const LockedUserMessage: React.FC<LockedUserMessageProps> = ({ state }): JSX.Element => {
  switch (state.kind) {
    case 'registering':
    case 'requesting-profile-info':
    case 'authenticating-email-domain':
    case 'authenticating-user':
    case 'error':
    case 'instant-sso':
    case 'pending-email-confirmation':
    case 'requesting-profile-info-password-backend':
    case 'registered':
    case 'requesting-email-address':
    case 'validating-email-from-identified-user':
    case 'waiting':
      return emptyFragment();
    case 'requesting-profile-info-locked-access':
      return <LockedUserStatusMessage />;
  }
};

function ProfileForm({ store, state }: Props) {
  const submit = (store: InvitationStore) => (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.registering();
  };

  return (
    <>
      <LockedUserMessage state={store.state} />
      <form
        data-testid="registration-form"
        className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6"
        onSubmit={submit(store)}
      >
        <div className="flex sm:gap-6">
          <div className="basis-full sm:basis-1/2">
            <EmailField
              data-testid="registration-form_email-text-field"
              value={state.emailAddress}
              disabled={!store.emailInputEnabled}
            />
          </div>
          <div className="sm:basis-1/2" />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row md:gap-6">
          <FormTextField
            data-testid="registration-form_first-name-text-field"
            label={<T kind="First Name" />}
            value={state.firstName}
            disabled={!store.formInputsEnabled}
            onChange={store.setFirstName}
          />
          <FormTextField
            data-testid="registration-form_last-name-text-field"
            label={<T kind="Last Name" />}
            value={state.lastName}
            disabled={!store.formInputsEnabled}
            onChange={store.setLastName}
          />
        </div>
        <TermsAndConditionsField
          checked={state.termsAccepted}
          disabled={!store.formInputsEnabled}
          onChange={store.setTermsAcceptance}
        />
        <div className="mt-6">
          <Button
            data-testid="registration-form_confirm-registration-button"
            type="submit"
            color="success"
            radius="sm"
            size="lg"
          >
            <T kind="Confirm Registration" />
          </Button>
        </div>
      </form>
    </>
  );
}

export default observer(ProfileForm);
