import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { Button, ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { mapMaybe } from '@execonline-inc/collections';
import { T } from '../../../../../Translations';
import { whenNoConflictAvailability } from '../Availability';
import { Experience } from '../Types';

interface Props {
  experience: Experience;
  buttonProps?: ButtonProps;
}

function EnrollableAction({ experience, buttonProps }: Props) {
  const { availabilities } = experience;

  return fromArrayMaybe(mapMaybe(whenNoConflictAvailability, availabilities))
    .map(() => (
      <Button color="gray" fullWidth {...buttonProps}>
        <T kind="Enroll" />
      </Button>
    ))
    .getOrElse(emptyFragment);
}

export default observer(EnrollableAction);
