import { toResult } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { err, ok, Result } from 'resulty';

interface PasswordMissing {
  kind: 'password-missing';
}

interface PasswordConfirmationMissing {
  kind: 'password-confirmation-missing';
}

interface PasswordConfirmationFailed {
  kind: 'password-confirmation-failed';
}

export interface Password {
  password: string;
  passwordConfirmation: string;
}

type ValidationError = PasswordMissing | PasswordConfirmationMissing | PasswordConfirmationFailed;

const missingPassword = (): PasswordMissing => ({ kind: 'password-missing' });

const missingPasswordConfirmation = (): PasswordConfirmationMissing => ({
  kind: 'password-confirmation-missing',
});

const matchError = (): PasswordConfirmationFailed => ({
  kind: 'password-confirmation-failed',
});

const getPassword = toResult<PasswordMissing, string>(missingPassword());

const getPasswordConfirmation = toResult<PasswordConfirmationMissing, string>(
  missingPasswordConfirmation()
);

const confirmPassword = ({
  password,
  passwordConfirmation,
}: Password): Result<PasswordConfirmationFailed, Password> =>
  password === passwordConfirmation ? ok({ password, passwordConfirmation }) : err(matchError());

export const validatePassword = (
  password: Maybe<string>,
  confirmation: Maybe<string>
): Result<ValidationError, Password> =>
  (ok({}) as Result<ValidationError, {}>)
    .assign('password', getPassword(password))
    .assign('passwordConfirmation', getPasswordConfirmation(confirmation))
    .andThen(confirmPassword);
