import { observer } from 'mobx-react';
import { Experience } from '../../../../Experience/Types';
import { ProgramHours } from '../../../../Experience/ExperienceBody';
import LearningJourneyPrograms from './LearningJourneyPrograms';
import LearningJourneyPartners from './LearningJourneyPartners';

interface Props {
  experience: Experience;
}

function LearningJourneyOverviewItems({ experience }: Props) {
  return (
    <div className="flex flex-wrap gap-x-3 gap-y-1 empty:hidden sm:gap-x-6">
      <LearningJourneyPrograms experience={experience} />
      <ProgramHours hours={experience.totalHours} />
      <LearningJourneyPartners experience={experience} />
    </div>
  );
}

export default observer(LearningJourneyOverviewItems);
