import { byPayloadId, find } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { programRoute } from '../../ClientRoutes';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import { programsStore } from '../../ProgramsStore';
import { AlreadyTranslated } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const ProgramLink: React.FC<Props> = ({ communityTeamResource }) => (
  <Link to={programRoute(communityTeamResource.payload.courseId)} className={style.teamProgramLink}>
    {programsStore.resource
      .map(({ payload }) => payload.programs)
      .andThen(find(byPayloadId(communityTeamResource.payload.courseId)))
      .map((p) => <AlreadyTranslated content={p.payload.title} />)
      .getOrElse(() => (
        <></>
      ))}
  </Link>
);

export default observer(ProgramLink);
