import { observer } from 'mobx-react';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TeamMemberResource } from '../../TeamsStore/Types';
import { T } from '../../Translations';
import * as platformStyle from '../Platform/style.module.css';
import TeamMember from '../TeamsSummary/TeamsRenderer/TeamsSummary/TeamMember';
import TeamMemberOnBody from './TeamMemberOnBody';
import TeamMemberOnLight from './TeamMemberOnLight';
import * as style from './style.module.css';

interface Props {
  teamMembers: TeamMemberResource[];
}

const TeamMemberRoutes: React.FC<Props> = ({ teamMembers }) => (
  <Switch>
    <Route
      exact={true}
      path="/"
      render={() => (
        <>
          {teamMembers.map((teamMemberResource: TeamMemberResource) => (
            <TeamMember
              key={teamMemberResource.payload.id}
              teamMemberResource={teamMemberResource}
            />
          ))}
        </>
      )}
    />
    <Route
      exact={true}
      path="/communities"
      render={() => (
        <>
          {teamMembers.map((teamMemberResource: TeamMemberResource) => (
            <TeamMemberOnLight
              key={teamMemberResource.payload.id}
              teamMemberResource={teamMemberResource}
            />
          ))}
        </>
      )}
    />
    <Route
      exact={true}
      path="/teams/:teamId"
      render={() => (
        <div className={style.scrollableList}>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Team Members" />
          </h2>
          {teamMembers.map((teamMemberResource: TeamMemberResource) => (
            <TeamMemberOnBody
              key={teamMemberResource.payload.id}
              teamMemberResource={teamMemberResource}
            />
          ))}
        </div>
      )}
    />
    <Route
      exact={true}
      path="/team"
      render={() => (
        <>
          <h2 className={platformStyle.screenReaderOnly}>
            <T kind="Team Members" />
          </h2>
          {teamMembers.map((teamMemberResource: TeamMemberResource) => (
            <TeamMemberOnBody
              key={teamMemberResource.payload.id}
              teamMemberResource={teamMemberResource}
            />
          ))}
        </>
      )}
    />
  </Switch>
);

export default observer(TeamMemberRoutes);
