import { observer } from 'mobx-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { directSegmentRoute } from '../ClientRoutes';
import { CurrentUserResource } from '../CurrentUser/Types';
import ResumerRedirectReactions from './Reactions';
import ResumerRedirectStore from './Store';

interface Props {
  currentUserResource: CurrentUserResource;
}

class ResumerRedirect extends React.Component<Props> {
  private resumerRedirectStore: ResumerRedirectStore;

  constructor(props: Props) {
    super(props);
    this.resumerRedirectStore = new ResumerRedirectStore();
  }

  redirect = (): React.ReactElement =>
    this.resumerRedirectStore.resumerResource
      .map((resource) => {
        switch (resource.payload.kind) {
          case 'resume-segment':
            return <Redirect to={directSegmentRoute(resource.payload)} />;
          case 'no-resumer':
            return <></>;
        }
      })
      .getOrElse(() => <></>);

  render() {
    return (
      <>
        <ResumerRedirectReactions
          store={this.resumerRedirectStore}
          currentUserResource={this.props.currentUserResource}
          fireImmediately
        />
        {this.redirect()}
      </>
    );
  }
}

export default observer(ResumerRedirect);
