import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../../../components/Modal/Store';
import EnrollmentStore from '../Enrollment/Store';
import ExpiredAccessModalContent from './ExpiredAccessModalContent';

interface Props {
  modalStore: ModalStore;
  enrollmentStore: EnrollmentStore;
}

const ExpiredAccessModal: React.FC<Props> = ({ modalStore, enrollmentStore }) => {
  switch (modalStore.state.kind) {
    case 'closed':
      return <></>;
    case 'open':
      return (
        <ExpiredAccessModalContent modalStore={modalStore} enrollmentStore={enrollmentStore} />
      );
  }
};

export default observer(ExpiredAccessModal);
