import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { EventResource } from '../EventsStore/Types';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { EventState, loading, ready, waiting } from './Types';

class EventStore {
  @observable
  state: EventState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (eventResource: EventResource) => {
    this.state = ready(eventResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get eventResource(): Maybe<EventResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.eventResource);
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get agenda(): Maybe<string> {
    switch (this.state.kind) {
      case 'ready':
        return this.state.eventResource.payload.agenda;
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default EventStore;
