import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, CompletedProgram } from '../../../ProgramStore/Types';
import { L, T } from '../../../Translations';
import * as style from '../../ProgramSummary/style.module.css';

interface Props {
  programDetail: ActiveProgram | CompletedProgram;
  kind: 'other' | 'danger';
}

const lockedOutFormatted = ({
  lockedOutOn,
  daysUntilLockedOut,
}: ActiveProgram | CompletedProgram) =>
  just({}).assign('lockedOutOn', lockedOutOn).assign('daysUntilLockedOut', daysUntilLockedOut);

const LockedOutDate: React.FC<Props> = ({ programDetail, kind }) =>
  lockedOutFormatted(programDetail)
    .map((h) => (
      <dl className={style.dl}>
        <dt
          className={style.contentAccessUntil}
          data-danger={kind === 'danger'}
          data-test-end-date-label={true}
        >
          <T kind="Access Ends" />
        </dt>
        <dd
          className={style.contentAccessUntil}
          data-danger={kind === 'danger'}
          data-test-locked-out-date-value={h.lockedOutOn}
        >
          <span>
            <L localizeable={h.lockedOutOn} format="long-month-day-year" />
            &nbsp;
            <T kind="({{count}} day from now)" count={h.daysUntilLockedOut} />
          </span>
        </dd>
      </dl>
    ))
    .getOrElseValue(<></>);

export default observer(LockedOutDate);
