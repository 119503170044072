import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ChatablePersonResourceList } from '../ConversationStore/Types';
import { error, Error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';

interface Waiting {
  kind: 'waiting';
}

interface Ready {
  kind: 'ready';
  chatablePersonResourceList: ChatablePersonResourceList;
}

interface Loading {
  kind: 'loading';
  link: Link;
}

const waiting = (): Waiting => ({
  kind: 'waiting',
});

const loading = (link: Link): Loading => ({
  kind: 'loading',
  link,
});

const ready = (chatablePersonResourceList: ChatablePersonResourceList): Ready => ({
  kind: 'ready',
  chatablePersonResourceList,
});

export type State = Waiting | Ready | Loading | Error;

class ChatablePersonResourcesStore {
  @observable
  state: State = waiting();

  @action
  waiting = () => {
    this.state = waiting();
  };

  @action
  loading = (link: Link) => {
    this.state = loading(link);
  };

  @action
  ready = (chatableResourceList: ChatablePersonResourceList) => {
    this.state = ready(chatableResourceList);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get chatablePersonResourceList(): Maybe<ChatablePersonResourceList> {
    switch (this.state.kind) {
      case 'loading':
      case 'error':
      case 'waiting':
        return nothing();
      case 'ready':
        return just(this.state.chatablePersonResourceList);
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default ChatablePersonResourcesStore;
