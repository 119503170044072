import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';
import * as style from '../../../../ProgramSummary/style.module.css';

const CurrentWarningRemainderMessage = () => {
  return (
    <div
      className={`${style.scheduledCohortProgressWarningTooltip} ${style.scheduledCohortProgressTooltip}`}
      data-test-progress-tooltip={true}
    >
      <span>
        <T kind="Reminder: experience is expiring soon" />
      </span>
      <div className={style.scheduledCohortProgressWarningTooltipArrow} />
    </div>
  );
};

export default observer(CurrentWarningRemainderMessage);
