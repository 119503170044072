import * as React from 'react';
import Title from '.';
import { TPlainTextKey, translation, TranslationsContext } from '../Translations';

interface Props {
  title: TPlainTextKey;
}

const I18nTitle: React.FC<Props> = ({ title }) => (
  <TranslationsContext.Consumer>
    {(ts) => <Title title={translation(title)(ts)} />}
  </TranslationsContext.Consumer>
);

export default I18nTitle;
