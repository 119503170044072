import { NotTranslated } from '@execonline-inc/translations';
import * as style from '../style.module.css';
import { observer } from 'mobx-react';

export interface DisplayItemDivProps {
  item: string;
}

const DisplayItemDiv: React.FC<DisplayItemDivProps> = ({ item }) => (
  <div className={style.ellipsesText}>
    <NotTranslated text={item} />
  </div>
);

export default observer(DisplayItemDiv);
