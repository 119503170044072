import * as React from 'react';
import { observer } from 'mobx-react';
import { CommunityResource, CommunityTeam, CommunityProgram } from '../../CommunitiesStore/Types';
import PresenceTeam from '../TeamSummary/PresenceTeam';
import ProgramChatSummary from '../ProgramChatSummary';
import CommunitiesStore from '../../CommunitiesStore';
import { resource } from '../../Resource/Types';

interface Props {
  communityResource: CommunityResource;
  communitiesStore: CommunitiesStore;
}

const CommunityContent: React.FC<Props> = ({ communityResource, communitiesStore }) => {
  switch (communityResource.payload.kind) {
    case 'team':
      return (
        <PresenceTeam
          communityTeamResource={resource<CommunityTeam>(
            communityResource.links,
            communityResource.payload
          )}
          communitiesStore={communitiesStore}
        />
      );
    case 'program':
      return (
        <ProgramChatSummary
          communityProgramResource={resource<CommunityProgram>(
            communityResource.links,
            communityResource.payload
          )}
        />
      );
    default:
      return <></>;
  }
};

export default observer(CommunityContent);
