import { log } from '@execonline-inc/logging';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import {
  CaptionSourceFile,
  InternalPlayerOptions,
  PlayerOptionsError,
  SourceFile,
  TracksSourceFile,
} from '../../JWPlayer/Types';
import { autoLoadIndex, sprite, subtitles, thumbnail } from '../Resource';
import { OverviewVideoResource, ReqHlsVideoAssetResource } from '../Types';
import { jwPlayerKey, jwPlayerUrl } from './Loader';

/**
 * See https://developer.jwplayer.com/jw-player/docs/developer-guide/customization/configuration-reference/#setup
 *
 * There are a number of additional options that could be used. This interface
 * just includes the most likely set needed, as most are optional.
 */
interface GoogleAnalyticsIntegrationConfig {
  label: 'mediaid';
}

export interface ReqHlsPlayerOptions {
  playerUrl: string;
  playerKey: string;

  // Media
  file: Maybe<string>;
  image: string;
  title: Maybe<string>;
  mediaid: Maybe<string>;
  sources: Maybe<SourceFile[]>;
  tracks: TracksSourceFile[];

  // Autostart
  autostart: boolean;

  // Appearance
  displaytitle: boolean;
  width: Maybe<number | string>;
  height: Maybe<number | string>;
  aspectratio: Maybe<string>; //width:height format

  //Custom for us, lets our setup know to set the player's aspect ratio based on
  //the video's actual dimensions rather than the player's dimensions
  dynamicallySetAspectRatio: boolean;
  thumbnailImage: Maybe<string>;
  sprite: Maybe<string>;
  subtitles: Maybe<ReadonlyArray<CaptionSourceFile>>;
  ga: Maybe<GoogleAnalyticsIntegrationConfig>;
  playbackRateControls: boolean;
  autoLoadIndex: Maybe<number>;
}

type CustomizablePlayerOptions = Pick<
  InternalPlayerOptions,
  'width' | 'height' | 'dynamicallySetAspectRatio' | 'sources' | 'file' | 'autostart'
>;

export const overviewPlayerOptions = (
  resource: OverviewVideoResource,
  opts: CustomizablePlayerOptions,
): Task<PlayerOptionsError, ReqHlsPlayerOptions> =>
  Task.succeed<PlayerOptionsError, {}>({})
    .assign('playerUrl', jwPlayerUrl())
    .assign('playerKey', jwPlayerKey())
    .assign('file', Task.succeed(just(opts.file)))
    .assign('image', Task.succeed(''))
    .assign('title', Task.succeed(resource.payload.title))
    .assign('mediaid', Task.succeed(resource.payload.mediaId))
    .assign('sources', Task.succeed(just(opts.sources)))
    .assign('tracks', Task.succeed([]))
    .assign('autostart', Task.succeed(opts.autostart || false))
    .assign('displaytitle', Task.succeed(false))
    .assign('width', Task.succeed(fromNullable(opts.width)))
    .assign('height', Task.succeed(fromNullable(opts.height)))
    .assign('aspectratio', Task.succeed(nothing<string>()))
    .assign('dynamicallySetAspectRatio', Task.succeed(opts.dynamicallySetAspectRatio || false))
    .assign('thumbnailImage', thumbnail(resource.links).map(just))
    .assign('sprite', sprite(resource).map(just))
    .assign('subtitles', subtitles(resource.payload).map(just))
    .assign('playbackRateControls', Task.succeed(true))
    .assign('autoLoadIndex', Task.succeed(just(-1)))
    .assign(
      'ga',
      Task.succeed(
        just<GoogleAnalyticsIntegrationConfig>({
          label: 'mediaid',
        }),
      ),
    );

export const playerOptions = (
  resource: ReqHlsVideoAssetResource,
  opts: CustomizablePlayerOptions,
): Task<PlayerOptionsError, ReqHlsPlayerOptions> =>
  Task.succeed<PlayerOptionsError, {}>({})
    .assign('playerUrl', jwPlayerUrl())
    .assign('playerKey', jwPlayerKey())
    .assign('file', Task.succeed(just(opts.file)))
    .assign('image', Task.succeed(''))
    .assign('title', Task.succeed(resource.payload.title))
    .assign('mediaid', Task.succeed(resource.payload.mediaId))
    .assign('sources', Task.succeed(just(opts.sources)))
    .assign('tracks', Task.succeed([]))
    .assign('autostart', Task.succeed(false))
    .assign('displaytitle', Task.succeed(true))
    .assign('width', Task.succeed(fromNullable(opts.width)))
    .assign('height', Task.succeed(fromNullable(opts.height)))
    .assign('aspectratio', Task.succeed(nothing<string>()))
    .assign('dynamicallySetAspectRatio', Task.succeed(opts.dynamicallySetAspectRatio || false))
    .assign('thumbnailImage', thumbnail(resource.links).map(just))
    .assign('sprite', sprite(resource).map(just))
    .assign('subtitles', subtitles(resource.payload).map(just))
    .assign('playbackRateControls', Task.succeed(true))
    .assign('autoLoadIndex', Task.succeed(autoLoadIndex(resource.payload)))
    .assign(
      'ga',
      Task.succeed(
        just<GoogleAnalyticsIntegrationConfig>({
          label: 'mediaid',
        }),
      ),
    );

export const prepareOptions = (options: ReqHlsPlayerOptions): InternalPlayerOptions => {
  const orUndefined = <T>(t: T): T | undefined => t;
  const finalOptions = {
    ...options,
    file: options.file.getOrElseValue(''),
    title: options.title.getOrElseValue(''),
    mediaid: options.mediaid.map(orUndefined).getOrElseValue(undefined),
    sources: options.sources.getOrElseValue([]),
    width: options.width.map(orUndefined).getOrElseValue(undefined),
    height: options.height.map(orUndefined).getOrElseValue(undefined),
    aspectratio: options.aspectratio.map(orUndefined).getOrElseValue(undefined),
    thumbnailImage: options.thumbnailImage.getOrElseValue(''),
    sprite: options.sprite.getOrElseValue(''),
    subtitles: options.subtitles.getOrElseValue([]),
    ga: options.ga.map(orUndefined).getOrElseValue(undefined),
    tracks: [...options.tracks, ...options.subtitles.getOrElseValue([])],
    renderCaptionsNatively: true,
  };
  log('Final video player options', finalOptions);
  return finalOptions;
};
