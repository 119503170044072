import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SSO } from '../../SingleSignOn';
import { TPlainTextKey } from '../../Translations';
import { loginError, ssoFlowFailure } from '../Errors';
import { AwaitingSsoFlow } from '../States';
import { LoginStore } from '../Store';

interface Props {
  store: LoginStore;
  state: AwaitingSsoFlow;
}

const SingleSignOn: React.FC<Props> = ({ store, state }) => (
  <SSO.Initiate
    state={{
      urls: state.ssoUrls,
      userExperience: { kind: 'standard', username: state.username },
    }}
    onFailure={onFailure(store, state)}
    onSuccess={onSuccess(store)}
  />
);

export default observer(SingleSignOn);

const onSuccess = (store: LoginStore) => store.successfulLogin;

const onFailure = (loginStore: LoginStore, loginState: AwaitingSsoFlow) =>
  SSO.Errors.onFailure({
    handleSsoError: SSO.Errors.handleSsoError({
      onSessionAlreadyEstablished: onSuccess(loginStore),
      handler: handleError(loginStore, loginState),
    }),
  });

const handleError =
  (loginStore: LoginStore, loginState: AwaitingSsoFlow) =>
  (err: SSO.Error) =>
  (message: TPlainTextKey): void =>
    loginStore.awaitingUsername({
      username: just(loginState.username),
      error: just(loginError({ sourceError: ssoFlowFailure(err), message })),
    });
