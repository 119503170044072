import { observer } from 'mobx-react';
import * as React from 'react';
import RegisterResourceStore from '../../DiscoveryPortal/RegisterResourceStore';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import SsoLoginStoreReactions from '../SsoLoginStore/Reactions';
import { AuthSignIn } from '../Types';
import UsePasswordStoreReactions from '../UsePasswordStore/Reactions';

interface Props {
  authForm: AuthSignIn;
  registerResource: RegisterResource;
  registerResourceStore: RegisterResourceStore;
}

const SignInReactions: React.FC<Props> = ({
  authForm,
  registerResource,
  registerResourceStore,
}) => {
  switch (authForm.kind) {
    case 'password-sign-in':
      return (
        <UsePasswordStoreReactions
          store={authForm.store}
          links={registerResource.links}
          registerResourceStore={registerResourceStore}
          fireImmediately
        />
      );
    case 'sso-sign-in':
      return (
        <SsoLoginStoreReactions
          store={authForm.store}
          auth={authForm.auth}
          registerResource={registerResource}
          fireImmediately
        />
      );
  }
};

export default observer(SignInReactions);
