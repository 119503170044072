import { mapMaybe } from '@execonline-inc/collections';
import { toTask } from '@execonline-inc/maybe-adapter';
import { noop } from '@kofno/piper';
import { nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { warnAndNotify } from '../../Honeybadger';
import { findLink, findLinkT } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import {
  CaptionSourceFile,
  HighStreamMissing,
  LowStreamMissing,
  MediumStreamMissing,
  ThumbnailPreviewMissingError,
  VideoSourceFile,
  highStreamMissing,
  lowStreamMissing,
  mediumStreamMissing,
  thumbnailPreviewMissingError,
} from '../JWPlayer/Types';
import {
  HighProgressiveStreamResource,
  LowProgressiveStreamResource,
  MediumProgressiveStreamResource,
  OverviewVideo,
  OverviewVideoResource,
  ReqHlsVideoAsset,
  ReqHlsVideoAssetResource,
  SelectedPlayer,
  Subtitle,
  SubtitleResource,
  VideoSelectionFailure,
  autoLoadIndexT,
  missingProgressiveHigh,
  missingProgressiveLow,
  missingProgressiveMedium,
  progressivePlayer,
} from './Types';

const getLowProgressiveStream = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<LowStreamMissing, VideoSourceFile> => {
  return new Task<LowStreamMissing, VideoSourceFile>((reject, resolve) => {
    reqHlsVideoAsset.lowProgressiveStream.do((resource: LowProgressiveStreamResource) => {
      findLink('view', resource.links)
        .map((lowProgressiveStreamLink: Link) =>
          resolve({
            file: lowProgressiveStreamLink.href,
            label: 'Low',
            default: resource.payload.default ? 'true' : 'false',
          }),
        )
        .getOrElse(() => reject(lowStreamMissing(`Low Stream is missing!`)));
    });
    return noop;
  });
};

const getMediumProgressiveStream = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<MediumStreamMissing, VideoSourceFile> => {
  return new Task<MediumStreamMissing, VideoSourceFile>((reject, resolve) => {
    reqHlsVideoAsset.mediumProgressiveStream.do((resource: MediumProgressiveStreamResource) => {
      findLink('view', resource.links)
        .map((mediumProgressiveStreamLink: Link) =>
          resolve({
            file: mediumProgressiveStreamLink.href,
            label: 'Medium',
            default: resource.payload.default ? 'true' : 'false',
          }),
        )
        .getOrElse(() => reject(mediumStreamMissing(`Medium Stream is missing!`)));
    });
    return noop;
  });
};

export const getHighProgressiveStream = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<HighStreamMissing, VideoSourceFile> => {
  return new Task<HighStreamMissing, VideoSourceFile>((reject, resolve) => {
    reqHlsVideoAsset.highProgressiveStream.do((resource: HighProgressiveStreamResource) => {
      findLink('view', resource.links)
        .map((highProgressiveStreamLink: Link) =>
          resolve({
            file: highProgressiveStreamLink.href,
            label: 'High',
            default: resource.payload.default ? 'true' : 'false',
          }),
        )
        .getOrElse(() => reject(highStreamMissing(`High Stream is missing!`)));
    });
    return noop;
  });
};

export const fallbackProgressiveSources = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<never, VideoSourceFile[]> =>
  Task.succeed({})
    .assign('low', getLowProgressiveStream(reqHlsVideoAsset))
    .assign('med', getMediumProgressiveStream(reqHlsVideoAsset))
    .assign('high', getHighProgressiveStream(reqHlsVideoAsset))
    .map(({ low, med, high }) => [low, med, high])
    .orElse(() => {
      warnAndNotify('MissingFallbackSources', 'Fallback sources failed to load', {
        videoAssetUuid: reqHlsVideoAsset.uuid,
      });
      return Task.succeed([]);
    });

export const subtitles = (
  reqHlsVideoAsset: ReqHlsVideoAsset | OverviewVideo,
): Task<never, ReadonlyArray<CaptionSourceFile>> => {
  return new Task<never, ReadonlyArray<CaptionSourceFile>>((_, resolve) => {
    const cs = mapMaybe((subtitle: SubtitleResource) => {
      return findLink('view', subtitle.links)
        .orElse(() => {
          warnAndNotify('MissingACaption', "We're captions for this video", {
            videoResourceId: reqHlsVideoAsset.uuid,
          });
          return nothing();
        })
        .map((subtitleLink: Link): CaptionSourceFile => {
          return {
            kind: 'captions',
            file: subtitleLink.href,
            label: subtitle.payload.label,
          };
        });
    }, reqHlsVideoAsset.subtitles);
    resolve(cs);

    return noop;
  });
};

export const thumbnail = (links: ReadonlyArray<Link>): Task<ThumbnailPreviewMissingError, string> =>
  findLinkT('thumbnail', links)
    .map(({ href }) => href)
    .mapError((_) => thumbnailPreviewMissingError(`Warning: thumbnail preview image is missing`));

export const sprite = (
  resource: ReqHlsVideoAssetResource | OverviewVideoResource,
): Task<never, string> =>
  findLinkT('sprite', resource.links)
    .map(({ href }) => href)
    .orElse((_) => {
      warnAndNotify('MissingVideoSprite', 'Sprite preview missing; video still loaded', {
        videoAssetUuid: resource.payload.uuid,
      });
      return Task.succeed('');
    });

export const progressiveSources = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], SelectedPlayer> =>
  Task.succeed<VideoSelectionFailure[], {}>({})
    .assign('low', lowProgressiveSource(reqHlsVideoAsset))
    .assign('med', mediumProgressiveSource(reqHlsVideoAsset))
    .assign('high', highProgressiveSource(reqHlsVideoAsset))
    .map(({ low, med, high }) => [low, med, high])
    .map(progressivePlayer);

const reqHlsVideoAssetSubtitles = (asset: ReqHlsVideoAsset): ReadonlyArray<Subtitle> =>
  asset.subtitles.map((s) => s.payload);

export const autoLoadIndex = autoLoadIndexT(reqHlsVideoAssetSubtitles);

export const lowProgressiveSource = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], VideoSourceFile> =>
  toTask(
    [missingProgressiveLow()],
    reqHlsVideoAsset.lowProgressiveStream.assign('link', ({ links }) => findLink('view', links)),
  ).map<VideoSourceFile>(({ link, payload }) => ({
    file: link.href,
    label: 'Low',
    default: payload.default ? 'true' : 'false',
  }));

export const lowProgressiveSources = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], VideoSourceFile[]> =>
  toTask(
    [missingProgressiveLow()],
    reqHlsVideoAsset.lowProgressiveStream.assign('link', ({ links }) => findLink('view', links)),
  ).map<VideoSourceFile[]>(({ link, payload }) => [
    {
      file: link.href,
      label: 'Low',
      default: payload.default ? 'true' : 'false',
    },
  ]);

export const mediumProgressiveSource = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], VideoSourceFile> =>
  toTask(
    [missingProgressiveMedium()],
    reqHlsVideoAsset.mediumProgressiveStream.assign('link', ({ links }) => findLink('view', links)),
  ).map<VideoSourceFile>(({ link, payload }) => ({
    file: link.href,
    label: 'Medium',
    default: payload.default ? 'true' : 'false',
  }));

export const highProgressiveSource = (
  reqHlsVideoAsset: ReqHlsVideoAsset,
): Task<VideoSelectionFailure[], VideoSourceFile> =>
  toTask(
    [missingProgressiveHigh()],
    reqHlsVideoAsset.highProgressiveStream.assign('link', ({ links }) => findLink('view', links)),
  ).map<VideoSourceFile>(({ link, payload }) => ({
    file: link.href,
    label: 'High',
    default: payload.default ? 'true' : 'false',
  }));
