import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../CurrentUser/Types';
import { T } from '../Translations';
import FlashError from '../components/FlashMessage/FlashError';
import Loader from '../components/Loader';
import PreferredLanguageSelector from './PreferredLanguageSelector';
import { preferredLanguageSelectionStore } from './Store';

interface Props {
  currentUserResource: CurrentUserResource;
}

class PreferredLanguageSelection extends React.Component<Props> {
  componentDidMount(): void {
    preferredLanguageSelectionStore.detectingPreferredLanguage(this.props.currentUserResource);
  }

  render() {
    const { children } = this.props;
    switch (preferredLanguageSelectionStore.state.kind) {
      case 'waiting':
      case 'detecting-preferred-language':
      case 'updating-preference':
      case 'changing-browser-language':
        return <Loader />;
      case 'requires-user-selection':
        return <PreferredLanguageSelector />;
      case 'preference-identified':
        return <>{children}</>;
      case 'errored':
        return (
          <FlashError>
            <T kind={preferredLanguageSelectionStore.state.userMessage} />
          </FlashError>
        );
    }
  }
}

export default observer(PreferredLanguageSelection);
