import { noop } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { buildRedirectForm } from './Builder';
import { toFormParams } from './Functions';
import { Props } from './Types';

class Form extends React.Component<Props> {
  componentDidMount(): void {
    toFormParams(this.props)
      .andThen(buildRedirectForm)
      .do((form) => form.submit())
      .fork(this.props.onSubmissionFailure, noop);
  }

  render() {
    return <></>;
  }
}

export default observer(Form);
