import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import {
  PaymentProcessing,
  PaymentWizardStore,
  PaymentPresence,
  PaymentOrderDetails,
  WaitingForMessage,
  MessageReceived,
  RequestPaymentStatus,
  PaymentStillProcessing,
  PaymentStatusRequested,
} from '../Common';

interface Props {
  store: PaymentWizardStore;
  state:
    | WaitingForMessage
    | MessageReceived
    | RequestPaymentStatus
    | PaymentStillProcessing
    | PaymentStatusRequested;
}

function PaymentProcessingModalBody({ store, state }: Props) {
  return (
    <>
      <PaymentOrderDetails />
      <PaymentPresence
        channelName={`presence-stripe-payment-intent-${state.paymentIntentId}`}
        store={store}
      >
        <div className="box-border flex h-full w-full flex-col content-center items-center self-center py-32 md:basis-3/5 md:py-0">
          <PaymentProcessing />
          <Typography className="mt-9" color="black" variant="h5" as="p" fontWeight="medium">
            <T kind="Processing Your Payment" />
          </Typography>
          <Typography className="mt-16 text-gray-700" variant="bodyMedium" as="p">
            <T
              kind="Please do <strong>not</strong> close this dialog or your browser tab."
              strong={(value) => (
                <Typography className="underline underline-offset-2" fontWeight="bold" as="span">
                  {value}
                </Typography>
              )}
            />
          </Typography>
        </div>
      </PaymentPresence>
    </>
  );
}

export default observer(PaymentProcessingModalBody);
