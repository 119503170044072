import { observer } from 'mobx-react';
import { T } from '../../../Translations';

const ErrorHeader: React.FC = () => (
  <h2 data-test-error-header="Please fix the following errors:">
    <T kind="Please fix the following errors:" />
  </h2>
);

export default observer(ErrorHeader);
