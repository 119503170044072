import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../../Person/types';
import { Position } from '../../PositionContext';
import OnlineStatus from './SharedInternals/OnlineStatus';
import PersonBasePopoverable from './SharedInternals/PersonBasePopoverable';
import PersonDetails from './SharedInternals/PersonDetails';

interface Props {
  store: PersonStoreContract;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  position: Position;
}

const PersonParticipant: React.FC<Props> = ({ store, presenceStore, position }) => (
  <PersonBasePopoverable personStore={store}>
    {{
      onlineStatus: <OnlineStatus presenceStore={presenceStore} personStore={store} kind="light" />,
      details: just(
        <PersonDetails
          organization={store.organization}
          name={store.name}
          jobTitle={nothing()}
          position={position}
        />
      ),
    }}
  </PersonBasePopoverable>
);

export default observer(PersonParticipant);
