import { ButtonProps } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceActions, LearnMoreAction } from '../../../Common/Experience/ExperienceActions';
import { useExperienceContext } from '../../../Common/Experience/ExperienceContext';
import { useExperienceEnrollmentContext } from '../../../Common/ProgramExperienceEnrollment';
import { useExperiencesContext } from '../../ExperienceSelection/Experiences/ExperiencesContext';

const buttonProps: ButtonProps = {
  className: 'w-auto min-w-36',
  color: 'primary',
  fullWidth: false,
};

const learnMoreButtonProps: ButtonProps = {
  className: 'w-auto min-w-36',
  fullWidth: false,
};

function PromotableExperienceTileFooter() {
  const experiencesStore = useExperiencesContext();
  const { invitationUuid, experienceResource } = useExperienceContext();
  const { onOpen } = useExperienceEnrollmentContext();

  const { payload: experience } = experienceResource;

  return (
    <div className="mb-0 mt-10 flex flex-row items-center gap-4 lg:mb-20">
      <ExperienceActions
        invitationUuid={invitationUuid}
        experience={experience}
        experiencesStore={experiencesStore}
        buttonProps={buttonProps}
        onClick={onOpen}
      />
      <LearnMoreAction
        buttonProps={learnMoreButtonProps}
        invitationUuid={invitationUuid}
        experience={experience}
      />
    </div>
  );
}

export default observer(PromotableExperienceTileFooter);
