import { useCallback, useState } from 'react';
import { Maybe, nothing } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { identity } from '@kofno/piper';
import { first, mapMaybe } from '@execonline-inc/collections';
import { getFirstNonConflictAvailability } from '../../../../Experience/Availability';
import { AvailabilityResource, AvailabilityResources } from '../../../../Experience/Types';

function useExperienceEnrollmentAvailability(availabilities: AvailabilityResources): {
  availability: Maybe<AvailabilityResource>;
  assignAvailability: (availability: Maybe<AvailabilityResource>) => void;
  resetAvailability: () => void;
} {
  const [selectedAvailability, setAvailability] = useState<Maybe<AvailabilityResource>>(nothing());

  const nonConflictAvailability = fromArrayMaybe(availabilities).andThen(
    getFirstNonConflictAvailability,
  );

  const availability = first(mapMaybe(identity, [selectedAvailability, nonConflictAvailability]));

  const assignAvailability = useCallback((availability: Maybe<AvailabilityResource>) => {
    setAvailability(availability);
  }, []);

  const resetAvailability = useCallback(() => {
    setAvailability(nothing());
  }, []);

  return { availability, assignAvailability, resetAvailability };
}

export default useExperienceEnrollmentAvailability;
