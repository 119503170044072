import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import ConferenceEmbed from './ConferenceEmbed';
import { SingletonConference } from './SingletonConference';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
  conferenceRosterStore: ConferenceRosterStore;
}

const SingletonConferenceEmbed: React.FC<Props> = ({
  conferenceRoomResource,
  conferenceRosterStore,
}) => (
  <SingletonConference>
    <ConferenceEmbed
      conferenceRoomResource={conferenceRoomResource}
      conferenceRosterStore={conferenceRosterStore}
    />
  </SingletonConference>
);

export default observer(SingletonConferenceEmbed);
