import { assertNever } from '@kofno/piper';
import { callApi } from '../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { findLink } from '../../../LinkyLinky';
import { Link } from '../../../Resource/Types';
import { courseDocumentResourceDecoder } from '../../CourseDocument/Decoders';
import { OpenAction } from '../../Openable';
import LinkedCourseDocumentStore, { State } from '../Store';

interface Props extends EAProps<LinkedCourseDocumentStore> {
  store: LinkedCourseDocumentStore;
  open: OpenAction;
}

const courseDocumentLink = (link: Link, documentId: string): Link => ({
  ...link,
  href: link.href.replace('{uuid}', `${documentId}`),
});

class LinkedCourseDocumentReactions extends ErrorActionableReaction<
  LinkedCourseDocumentStore,
  State,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store, open } = this.props;
    switch (state.kind) {
      case 'waiting':
      case 'error':
        break;
      case 'loading':
        const getCourseDocument = callApi(courseDocumentResourceDecoder, {});
        findLink('course-document-template', store.links).map((link) =>
          getCourseDocument(courseDocumentLink(link, store.documentId)).fork(
            (e) => handleError(store, e),
            store.ready
          )
        );
        break;
      case 'ready':
        open();
        break;
      default:
        assertNever(state);
    }
  };
}

export default LinkedCourseDocumentReactions;
