import { observer } from 'mobx-react';
import NavigationBar from '../../../../components/Platform/NavigationBar';

function ExperienceHeader() {
  return (
    <div className="bg-white">
      <NavigationBar className="mx-auto bg-white xl:container" />
    </div>
  );
}

export default observer(ExperienceHeader);
