import { observer } from 'mobx-react';
import { Select, SelectMenuItem, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { ScheduledOption } from './Types';

interface Props {
  value: string;
  options: ReadonlyArray<ScheduledOption>;
  onChange: (value: string) => void;
}

function ScheduledSelect({ value, options, onChange }: Props) {
  return (
    <div className="flex max-w-full flex-col gap-y-2 sm:max-w-60">
      <Typography className="tracking-[0.2px]">
        <T kind="Start Date" />
      </Typography>
      <Select value={value} onChange={onChange}>
        {options.map(({ label }, index) => (
          <SelectMenuItem key={index} value={label}>
            {label}
          </SelectMenuItem>
        ))}
      </Select>
    </div>
  );
}

export default observer(ScheduledSelect);
