import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Title from '../../../Title';
import Footer from '../../../components/Footer';
import { ExperienceContextProvider } from '../Common/Experience/ExperienceContext';
import DiscoveryContext, { FromState, learnMore } from '../DiscoveryContext';
import EnrollmentStore from '../DiscoveryPortal/Enrollment/Store';
import ExperiencesStore from '../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { PreviewModeContext } from '../DiscoveryPortal/PreviewMode/PreviewModeContext';
import { RegisterResource } from '../DiscoveryPortal/RegisterResourceStore/Types';
import DeliveryChannelSection from './DeliveryChannelSection';
import ExperienceDetailViewStore from './ExperienceDetailViewStore';
import ExperienceDetailViewReactions from './ExperienceDetailViewStore/ExperienceDetailViewReactions';
import ExperienceDetails from './ExperienceDetails';
import ExperienceHeader from './ExperienceHeader';
import ExperienceHero from './ExperienceHero';

interface Props {
  experienceId: string;
  invitationUuid: string;
  registerResource: RegisterResource;
  enrollmentStore: EnrollmentStore;
  experiencesStore: ExperiencesStore;
  discoveryReferenceState: FromState;
}

function ExperienceDetailView({
  experienceId,
  enrollmentStore,
  registerResource,
  experiencesStore,
  invitationUuid,
  discoveryReferenceState,
}: Props) {
  const experienceDetailViewStore = useConst(() => new ExperienceDetailViewStore());

  useEffect(() => {
    experienceDetailViewStore.loading(experienceId, registerResource);
  }, [experienceId, registerResource]);

  return (
    <DiscoveryContext.Provider value={learnMore(discoveryReferenceState)}>
      <div className="flex flex-grow flex-col">
        {experienceDetailViewStore.experienceResource
          .map((experienceResource) => (
            <ExperienceContextProvider value={{ invitationUuid, experienceResource }}>
              <Title title={experienceResource.payload.title.text} />
              <ExperienceHeader />
              <div className="flex-grow">
                <DeliveryChannelSection />
                <ExperienceHero
                  experiencesStore={experiencesStore}
                  enrollmentStore={enrollmentStore}
                  registerResource={registerResource}
                />
                <ExperienceDetails />
              </div>
              <PreviewModeContext.Consumer>
                {(previewMode) => {
                  switch (previewMode) {
                    case 'default':
                      return <Footer />;
                    case 'preview':
                      return emptyFragment();
                  }
                }}
              </PreviewModeContext.Consumer>
            </ExperienceContextProvider>
          ))
          .getOrElse(emptyFragment)}
        <ExperienceDetailViewReactions
          store={experienceDetailViewStore}
          discoveryPortal={experiencesStore.discoveryPortal}
        />
      </div>
    </DiscoveryContext.Provider>
  );
}

export default observer(ExperienceDetailView);
