import { explicitMaybe, secondsDecoder, stringLiteral } from '@execonline-inc/decoders';
import { seconds } from '@execonline-inc/time';
import { identity } from '@kofno/piper';
import Decoder, {
  array,
  boolean,
  date,
  dateISO,
  field,
  maybe,
  nullable,
  number,
  oneOf,
  string,
  succeed,
} from 'jsonous';
import { getOrElseValue } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { languagesResourceDecoder } from '../../ProfileFormStore/Decoders';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { Resource } from '../../Resource/Types';
import { presentationStyleDecoder } from '../../SegmentStore/Decoders';
import { alreadyTranslatedText } from '../../Translations';
import { coachProfileResourceDecoder } from '../../components/EmbeddedFormFieldAsset/Decoders';
import { linkableResourceDecoder } from '../../components/Linkable/Decoders';
import {
  AccessDate,
  AccessibleAnytime,
  AccessibleOn,
  ActiveProgram,
  ActiveProgramChapter,
  ActiveProgramModule,
  CoachingProductDetails,
  CoachingSession,
  CoachingSessionResource,
  CompletedProgram,
  ExpiredProgram,
  GroupCoachingProductDetails,
  Image,
  InactiveProgram,
  NavigationType,
  ParticipateableProgram,
  ProductDetails,
  ProductDetailsResource,
  Program,
  ProgramBase,
  ProgramProductDetails,
  ProgramSegment,
  ProgramSequenceProductDetails,
  Progress,
  StartDate,
  StartsAnytime,
  StartsOn,
  UpcomingProgram,
  UpcomingProgramChapter,
  UpcomingProgramModule,
  UpcomingSession,
  UpcomingSessionPayload,
} from '../Types';

export const statusDecoder: Decoder<ProgramSegment['status']> = oneOf([
  stringLiteral<ProgramSegment['status']>('Complete'),
  stringLiteral<ProgramSegment['status']>('Accessible'),
  stringLiteral<ProgramSegment['status']>('Inaccessible'),
  stringLiteral<ProgramSegment['status']>('Started'),
]);

export const segmentDecoder: Decoder<ProgramSegment> = succeed({})
  .assign('id', field('id', number))
  .assign('programId', field('program_id', number))
  .assign('title', field('title', string))
  .assign('moduleId', field('module_id', number))
  .assign('longTitle', field('long_title', string))
  .assign('isCurrentSegment', field('current_segment', boolean))
  .assign('timeToComplete', field('seconds_to_complete', maybe(secondsDecoder)))
  .assign('status', field('status', statusDecoder))
  .assign('moduleId', field('module_id', number))
  .assign('links', field('links', linksDecoder));

const offeringTypeDecoder: Decoder<ProductDetails['kind']> = oneOf<ProductDetails['kind']>([
  stringLiteral<ProductDetails['kind']>('coaching'),
  stringLiteral<ProductDetails['kind']>('program'),
]);

export const activeProgramModuleDecoder: Decoder<ActiveProgramModule> = succeed({})
  .assign('kind', succeed<'active'>('active'))
  .assign('id', field('id', number))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('percentComplete', field('percent_complete', number))
  .assign('isCurrentModule', field('current_module', boolean))
  .assign('isMilestone', field('milestone', boolean))
  .assign('gatedMessage', field('gated_message', maybe(alreadyTranslatedText)))
  .assign('isGated', field('gated', boolean))
  .assign('startsOn', field('starts_on', maybe(date)))
  .assign('endsOn', field('ends_on', maybe(date)))
  .assign('daysSinceModuleEnd', field('days_since_module_end', explicitMaybe(number)))
  .assign('daysRemaining', field('days_remaining', maybe(number)))
  .assign('label', field('label', maybe(string)))
  .assign('timeToComplete', field('seconds_to_complete', maybe(secondsDecoder)))
  .assign('expectedPercentComplete', field('expected_percent_complete', explicitMaybe(number)))
  .assign('expectedModule', field('expected_module', explicitMaybe(boolean)))
  .assign('segments', field('segments', array(segmentDecoder)))
  .assign('offeringType', field('offering_type', explicitMaybe(offeringTypeDecoder)))
  .map<ActiveProgramModule>(identity);

export const activeProgramChapterDecoder: Decoder<ActiveProgramChapter> = succeed({})
  .assign('name', field('name', string))
  .assign('modules', field('modules', array(activeProgramModuleDecoder)));

const startsAnytimeDecoder: Decoder<StartsAnytime> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('starts-anytime')),
);

const startsOnDecoder: Decoder<StartsOn> = succeed({})
  .assign('kind', field('kind', stringLiteral('starts-on')))
  .assign('date', field('date', date));

const startDateDecoder: Decoder<StartDate> = oneOf<StartDate>([
  startsAnytimeDecoder.map<StartDate>(identity),
  startsOnDecoder.map<StartDate>(identity),
]);

const accessibleAnytimeDecoder: Decoder<AccessibleAnytime> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('accessible-anytime')),
);

const accessibleOnDecoder: Decoder<AccessibleOn> = succeed({})
  .assign('kind', field('kind', stringLiteral('accessible-on')))
  .assign('date', field('date', date));

const accessDateDecoder: Decoder<AccessDate> = oneOf<AccessDate>([
  accessibleAnytimeDecoder.map<AccessDate>(identity),
  accessibleOnDecoder.map<AccessDate>(identity),
]);

const upcomingProgramModuleDecoder: Decoder<UpcomingProgramModule> = succeed({})
  .assign('kind', succeed<'upcoming'>('upcoming'))
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('gatedUntil', field('gated_until', maybe(date)))
  .map<UpcomingProgramModule>(identity);

const upcomingProgramChapterDecoder: Decoder<UpcomingProgramChapter> = succeed({})
  .assign('name', field('name', string))
  .assign('modules', field('modules', array(upcomingProgramModuleDecoder)));

const programFormatDecoder: Decoder<Program['programFormat']> = oneOf([
  stringLiteral<Program['programFormat']>('modules'),
  stringLiteral<Program['programFormat']>('chapters'),
]);

const programInstanceKindDecoder: Decoder<ParticipateableProgram['programInstanceKind']> = oneOf([
  stringLiteral<ParticipateableProgram['programInstanceKind']>('scheduled'),
  stringLiteral<ParticipateableProgram['programInstanceKind']>('on-demand'),
]);

export const imageDecoder: Decoder<Image> = succeed({})
  .assign('src', field('src', string))
  .assign('alt', field('alt', string));

const sessionDecoder: Decoder<CoachingSession> = succeed({})
  .assign('id', field('id', number))
  .assign('date', field('date', dateISO))
  .assign('startAt', field('start_at', explicitMaybe(dateISO)))
  .assign('endAt', field('end_at', explicitMaybe(dateISO)))
  .assign('accessAt', field('access_at', explicitMaybe(dateISO)))
  .assign('duration', field('duration', nullable(number.map(seconds))));

const programProductDetailsDecoder: Decoder<ProgramProductDetails> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral<'program'>('program')),
);

const programSequenceProductDetailsDecoder: Decoder<ProgramSequenceProductDetails> = succeed(
  {},
).assign('kind', field('kind', stringLiteral<'program-sequence'>('program-sequence')));

export const upcomingSessionDecoder: Decoder<UpcomingSession> = succeed({})
  .assign('kind', field('kind', stringLiteral<'group-coaching-session'>('group-coaching-session')))
  .assign('startAt', field('start_at', dateISO))
  .assign('durationInMinutes', field('duration_in_minutes', number))
  .assign(
    'coachingProfileResource',
    field('coaching_profile_resource', explicitMaybe(coachProfileResourceDecoder)),
  )
  .assign('themeDetails', field('theme_details', alreadyTranslatedText))
  .assign('name', field('name', alreadyTranslatedText));

export const upcomingSessionResourceDecoder: Decoder<UpcomingSessionPayload> =
  resourceDecoder(upcomingSessionDecoder);

const groupCoachingProductDetailsDecoder: Decoder<GroupCoachingProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral<'group-coaching'>('group-coaching')))
  .assign('id', field('id', number))
  .assign('title', field('title', alreadyTranslatedText))
  .assign(
    'memberProfileResources',
    field('member_profile_resources', array(profileResourceDecoder)),
  )
  .assign(
    'upcomingSessionResource',
    field('upcoming_session_resource', explicitMaybe(upcomingSessionResourceDecoder)),
  );

export const conferenceRoomResourceDecoder: Decoder<CoachingSessionResource> =
  resourceDecoder(sessionDecoder);

const orientationStatusDecoder = explicitMaybe(
  oneOf([
    stringLiteral<CoachingProductDetails['orientationStatus']>('Unstarted'),
    stringLiteral<CoachingProductDetails['orientationStatus']>('Started'),
    stringLiteral<CoachingProductDetails['orientationStatus']>('Completed'),
  ]),
).map(getOrElseValue<CoachingProductDetails['orientationStatus']>('NotApplicable'));

const coachingProductDetailsDecoder: Decoder<CoachingProductDetails> = succeed({})
  .assign('kind', field('kind', stringLiteral<'coaching'>('coaching')))
  .assign('orientationStatus', field('orientation_status', orientationStatusDecoder))
  .assign('selectedCoach', field('selected_coach', explicitMaybe(coachProfileResourceDecoder)))
  .assign('pastSessionsCount', field('past_sessions_count', number))
  .assign('sessions', field('sessions', array(conferenceRoomResourceDecoder)));

const productDetailsResourceDecoder: Decoder<ProductDetailsResource> = resourceDecoder(
  oneOf<ProductDetails>([
    programProductDetailsDecoder.map<ProductDetails>(identity),
    coachingProductDetailsDecoder.map<ProductDetails>(identity),
    programSequenceProductDetailsDecoder.map<ProductDetails>(identity),
    groupCoachingProductDetailsDecoder.map<ProductDetails>(identity),
  ]),
);

const experienceOriginDecoder: Decoder<Program['experienceOrigin']> = oneOf([
  stringLiteral<Program['experienceOrigin']>('commerce-direct-paid'),
  stringLiteral<Program['experienceOrigin']>('commerce-direct-unpaid'),
  stringLiteral<Program['experienceOrigin']>('unavailable'),
]);

const navigationDecoder: Decoder<NavigationType> = oneOf([
  stringLiteral<NavigationType>('navigable'),
  stringLiteral<NavigationType>('advanceable'),
]);

const baseProgramPayloadDecoder: Decoder<ProgramBase> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('programFormat', field('program_format', programFormatDecoder))
  .assign('availableLanguages', field('available_languages', languagesResourceDecoder))
  .assign('productDetails', field('product_details', productDetailsResourceDecoder))
  .assign('directRegistrationGuid', field('direct_registration_guid', explicitMaybe(string)))
  .assign('experienceOrigin', field('experience_origin', experienceOriginDecoder))
  .assign('linkables', field('linkables', array(linkableResourceDecoder).map(fromArrayMaybe)))
  .assign('presentationStyle', field('presentation_style', explicitMaybe(presentationStyleDecoder)))
  .assign('navigation', field('navigation', navigationDecoder));

const progressKindDecoder: Decoder<Progress['kind']> = oneOf([
  stringLiteral<Progress['kind']>('program-progress'),
  stringLiteral<Progress['kind']>('registration-segment-progress'),
  stringLiteral<Progress['kind']>('module-progress'),
]);

export const progressDecoder: Decoder<Progress> = succeed({})
  .assign('kind', field('kind', progressKindDecoder))
  .assign('completedSegments', field('completed_segments', number))
  .assign('totalSegments', field('total_segments', number));

const accessKindDecoder: Decoder<ParticipateableProgram['accessKind']> = oneOf([
  stringLiteral<ParticipateableProgram['accessKind']>('current-unlocked'),
  stringLiteral<ParticipateableProgram['accessKind']>('current-warning'),
  stringLiteral<ParticipateableProgram['accessKind']>('current-danger'),
  stringLiteral<ParticipateableProgram['accessKind']>('ended-unlocked'),
  stringLiteral<ParticipateableProgram['accessKind']>('ended-warning'),
  stringLiteral<ParticipateableProgram['accessKind']>('ended-danger'),
]);

const participateablePartsDecoder: Decoder<ParticipateableProgram> = succeed({})
  .assign('daysSinceProgramEnd', field('days_since_program_end', explicitMaybe(number)))
  .assign('daysRemaining', field('days_remaining', maybe(number)))
  .assign('endsOn', field('ends_on', maybe(date)))
  .assign('userProductLicenseEndsOn', field('user_product_license_ends_on', explicitMaybe(dateISO)))
  .assign('lockedOutOn', field('locked_out_on', explicitMaybe(date)))
  .assign('accessKind', field('access_kind', accessKindDecoder))
  .assign('daysBehind', field('days_behind', explicitMaybe(number)))
  .assign('sfProgramUid', field('sf_program_uid', maybe(string)))
  .assign('currentChapterName', field('current_chapter_name', nullable(alreadyTranslatedText)))
  .assign('modules', field('modules', array(activeProgramModuleDecoder)))
  .assign('chapters', field('chapters', array(activeProgramChapterDecoder)))
  .assign('courseCompletedAt', field('course_completed_at', maybe(date)))
  .assign('accessEndsOn', field('access_ends_on', maybe(date)))
  .assign('programInstanceKind', field('program_instance_kind', programInstanceKindDecoder))
  .assign('progress', field('progress', progressDecoder))
  .assign('moduleProgress', field('module_progress', explicitMaybe(progressDecoder)))
  .assign('daysUntilLockedOut', field('days_until_locked_out', explicitMaybe(number)))
  .map<ParticipateableProgram>(identity);

const activeProgramDecoder: Decoder<ActiveProgram> = field('kind', stringLiteral('active'))
  .andThen((kind) => participateablePartsDecoder.map((parts) => ({ kind, ...parts })))
  .andThen((parts) => baseProgramPayloadDecoder.map((base) => ({ ...base, ...parts })));

const completedProgramDecoder: Decoder<CompletedProgram> = field('kind', stringLiteral('completed'))
  .andThen((kind) => participateablePartsDecoder.map((parts) => ({ kind, ...parts })))
  .andThen((parts) => baseProgramPayloadDecoder.map((base) => ({ ...base, ...parts })));

const upcomingProgramDecoder: Decoder<UpcomingProgram> = succeed({})
  .assign('kind', field('kind', stringLiteral('upcoming')))
  .assign('message', field('message', string))
  .assign('modules', field('modules', array(upcomingProgramModuleDecoder)))
  .assign('chapters', field('chapters', array(upcomingProgramChapterDecoder)))
  .assign('startDate', field('start_date', startDateDecoder))
  .assign('accessDate', field('access_date', accessDateDecoder))
  .andThen((parts) => baseProgramPayloadDecoder.map((base) => ({ ...base, ...parts })));

const inactiveProgramDecoder: Decoder<InactiveProgram> = field(
  'kind',
  stringLiteral('inactive'),
).andThen((kind) => baseProgramPayloadDecoder.map((base) => ({ kind, ...base })));

const expiredProgramDecoder: Decoder<ExpiredProgram> = field(
  'kind',
  stringLiteral('expired'),
).andThen((kind) => baseProgramPayloadDecoder.map((base) => ({ kind, ...base })));

export const programDecoder: Decoder<Program> = oneOf<Program>([
  activeProgramDecoder.map<Program>(identity),
  completedProgramDecoder.map<Program>(identity),
  upcomingProgramDecoder.map<Program>(identity),
  inactiveProgramDecoder.map<Program>(identity),
  expiredProgramDecoder.map<Program>(identity),
  upcomingProgramDecoder.map<Program>(identity),
]);

export const programDetailsResourceDecoder: Decoder<Resource<Program>> =
  resourceDecoder(programDecoder);
