import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceParticipant } from '../../../ConferenceRosterStore/Types';
import NotArrivedParticipants from './NotArrivedParticipants';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const NotArrived: React.FC<Props> = ({ conferenceRosterStore }) => (
  <div data-test-participants-not-arrived={true}>
    {conferenceRosterStore.notPresentParticipants
      .map((participants) =>
        participants.filter(({ participantType }) => participantType !== 'Observer')
      )
      .andThen(fromEmpty)
      .cata({
        Just: (participants: ConferenceParticipant[]) => (
          <NotArrivedParticipants
            conferenceRosterStore={conferenceRosterStore}
            participants={participants}
          />
        ),
        Nothing: () => <></>,
      })}
  </div>
);

export default observer(NotArrived);
