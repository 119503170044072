import { observer } from 'mobx-react';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import EventNotificationToast from './EventNotificationToast';

interface Props {
  eventNotifications: ReadonlyArray<ScheduledToastStore>;
}

const EventNotificationsToast: React.FC<Props> = ({ eventNotifications }) => (
  <>
    {eventNotifications.map((store) => (
      <EventNotificationToast store={store} key={store.scheduledToast.id} />
    ))}
  </>
);

export default observer(EventNotificationsToast);
