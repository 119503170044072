import { ReactNode } from 'react';

type ButtonColor = {
  primary: string;
  secondary: string;
  warning: string;
};

export type ButtonProps = {
  children: ReactNode;
  className: string;
  color?: keyof ButtonColor;
  onClick: () => void;
  disabled?: boolean;
};
export const buttonColor: ButtonColor = {
  primary: 'bg-teal-600-old',
  secondary: 'bg-grey-600',
  warning: 'bg-red-600-old',
};
export const buttonStyle: string =
  'text-sm font-semibold rounded-md max-w-xs p-3 text-white whitespace-nowrap';
