import 'core-js/features/array/find-index';
import 'core-js/features/set';
import 'core-js/features/string/pad-start';
import 'core-js/features/symbol';
import 'core-js/features/weak-set';
import 'es6-shim';
import 'react';
import * as ReactDOM from 'react-dom';
import 'url-polyfill';
import 'url-search-params-polyfill';
import { configureHoneybadger } from './Honeybadger';
import { App } from './components/App';
import './reset.css';

configureHoneybadger();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
