import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const ItalicIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 7 13">
      <path
        fill="currentColor"
        d="M1.358 0v1.358h1.995l-1.398 9.506H0v1.358h5.432v-1.358H3.375l1.398-9.506H6.79V0z"
      />
    </SVGIcon>
  );
};

export default observer(ItalicIcon);
