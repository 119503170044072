import { VideoAssetResource } from '../components/Video/Types';
import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  resourceResource: ResourceResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (resourceResource: ResourceResource): Ready => ({
  kind: 'ready',
  resourceResource,
});

export type ResourceState = Waiting | Loading | Ready | Error;

export interface ResourceBase {
  id: number;
  title: string;
  createdAt: Date;
}

export interface DocumentParts {
  kind: 'document';
  attachment: AttachmentResource;
}

export interface VideoParts {
  kind: 'video';
  videoAssetResource: VideoAssetResource;
}

export type ResourceParts = DocumentParts | VideoParts;

export interface Document extends ResourceBase, DocumentParts {}
export interface Video extends ResourceBase, VideoParts {}

export type ResourceItem = Document | Video;

export interface Attachment {
  title: string;
}

export type AttachmentResource = Resource<Attachment>;
export type ResourceResource = Resource<ResourceItem>;
