import * as React from 'react';
import { TPlainTextKey } from '../../../Translations';
import Label from '../Label';
import Textarea from '../Textarea';

interface Props {
  labelClassName?: string;
  labelText: TPlainTextKey;
  required?: boolean;
  value?: string;
  name: string;
  className?: string;
  onChange(event?: React.FormEvent<HTMLTextAreaElement>): void;
  autoFocus?: boolean;
  placeholder?: TPlainTextKey;
  id: string;
  disabled?: boolean;
}

class InputWithLabel extends React.PureComponent<Props, {}> {
  render() {
    return (
      <>
        <Label
          labelText={this.props.labelText}
          required={this.props.required}
          htmlFor={this.props.id}
          className={this.props.labelClassName}
        />
        <Textarea
          value={this.props.value}
          name={this.props.name}
          className={this.props.className}
          autoFocus={this.props.autoFocus}
          placeholder={this.props.placeholder}
          id={this.props.id}
          title={this.props.labelText}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
      </>
    );
  }
}

export default InputWithLabel;
