import * as React from 'react';
import TeamStore from '../../TeamStore';
import { T } from '../../Translations';
import PresenceTeamProfile from './PresenceTeamProfile';

interface Props {
  store: TeamStore;
}

const SingularTeamProfile: React.FC<Props> = ({ store }) => {
  return store.team
    .map(() => <PresenceTeamProfile teamStore={store} />)
    .getOrElseValue(
      <div>
        <T kind="You don't belong to any teams." />
      </div>
    );
};
export default SingularTeamProfile;
