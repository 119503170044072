import * as React from 'react';
import { observer } from 'mobx-react';
import { EventResource } from '../../EventsStore/Types';
import { findLink } from '../../LinkyLinky';
import TimerForm from './TimerForm';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import BreakoutTimerReactions from '../../BreakoutTimerStore/BreakoutTimerReactions';

interface Props {
  eventResource: EventResource;
  timerStore: BreakoutTimerStore;
}

const Timer: React.FC<Props> = ({ eventResource, timerStore }) => {
  return eventResource.payload.conferenceRoom
    .andThen((conferenceRoom) => findLink('timer', conferenceRoom.links))
    .map((link) => (
      <>
        <TimerForm store={timerStore} />
        <BreakoutTimerReactions timerLink={link} store={timerStore} fireImmediately={true} />
      </>
    ))
    .getOrElseValue(<span />);
};

export default observer(Timer);
