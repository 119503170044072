import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import { PersonStore } from '../../Person';
import Person from '../Person';

interface Props {
  personStore: PersonStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
}

const ChatPerson: React.FC<Props> = ({ personStore, presenceStore }) => (
  <Person personStore={personStore} presenceStore={presenceStore} />
);

export default observer(ChatPerson);
