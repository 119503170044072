import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { Linkable } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import SurveyModalStore from '../components/SurveyModal/Modal/Store';
import { State, loading, ready, waiting } from './Types';

class PendingSurveyStore {
  @observable
  state: State;

  constructor() {
    this.state = waiting();
  }

  @action
  load = (linkable: Linkable) => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = loading(linkable);
        break;
      case 'ready':
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (surveyModalStore: SurveyModalStore) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
        this.state = ready(surveyModalStore);
        break;
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get whenLinks(): Maybe<Linkable> {
    switch (this.state.kind) {
      case 'loading':
        return just(this.state.linkable);
      case 'waiting':
      case 'ready':
      case 'error':
        return nothing();
    }
  }

  @computed
  get surveyModalStore(): Maybe<SurveyModalStore> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.surveyModalStore);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

const pendingSurveyStore = new PendingSurveyStore();

export { pendingSurveyStore, PendingSurveyStore };
