import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import ProgramResourcesSegmentResource from './ProgramResourcesSegmentResource';
import { ProgramResourcesSegment as ProgramResourcesSegmentType } from './ProgramResourcesStore/Types';
import * as style from './style.module.css';

interface Props {
  segment: ProgramResourcesSegmentType;
  moduleId: number;
}

const ProgramResourcesSegment: React.FC<Props> = ({ segment, moduleId }) => {
  return (
    <>
      <p className={style.segmentTitle}>
        <NotTranslated text={segment.longTitle} />
      </p>
      {segment.resources
        .map((resources) =>
          resources
            .toArray()
            .map((resource) => (
              <ProgramResourcesSegmentResource
                key={resource.payload.id}
                resource={resource}
                moduleId={moduleId}
                segmentId={segment.id}
              />
            ))
        )
        .andThen<React.ReactNode>(fromEmpty)
        .getOrElseValue(<></>)}
    </>
  );
};
export default observer(ProgramResourcesSegment);
