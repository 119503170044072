import {
  CheckBoxIndicator,
  Select,
  SelectMenuItem,
  Typography,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { TPlainTextKey } from '../../../../../../Translations';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';
import FilterPlaceholder from './FilterPlaceholder';
import CommonFilterStore from './Store';
import { FilterOption, isFilterItemSelected } from './Types';

interface Props {
  store: CommonFilterStore;
  label: TPlainTextKey;
  filterOptions: FilterOption[];
}

const handleChange =
  (store: CommonFilterStore) =>
  (value: string[] = []) => {
    store.appendSelectedValues(value);
    store.applySelectedValues();
  };

const handleClean = (store: CommonFilterStore) => () => {
  store.clearSelectedValues();
};

const renderedValue = (store: CommonFilterStore, label: TPlainTextKey) => (value: string[]) => {
  return <FilterPlaceholder selectedOptions={value} title={label} onClear={handleClean(store)} />;
};

function DropdownFilter({ filterOptions, label, store }: Props) {
  const { translate } = useTranslationsContext();
  const selectedValues = [...store.state.filter.appliedValues];

  return (
    <Select
      className="w-1/4"
      multiple
      value={selectedValues}
      renderValue={renderedValue(store, label)}
      MenuListProps={{
        'aria-label': translate('{{filterName}} Filters', { filterName: label }),
      }}
      onChange={handleChange(store)}
    >
      {filterOptions.map(({ value, label }) => {
        return (
          <SelectMenuItem
            value={value}
            key={value}
            startContent={
              <CheckBoxIndicator size="sm" checked={isFilterItemSelected(selectedValues, value)} />
            }
          >
            <Typography className="whitespace-normal text-left text-black" variant="bodyMedium">
              {label}
            </Typography>
          </SelectMenuItem>
        );
      })}
    </Select>
  );
}

export default observer(DropdownFilter);
