import { log } from '@execonline-inc/logging';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { EventResource } from '../../../EventsStore/Types';
import PresenceMembersStore from '../../../Socket/PresenceChannel/PresenceMembersStore';
import TabStore from '../../../TabStore';
import PositionContext from '../../PositionContext';
import Agenda from '../Agenda';
import GroupChat from '../GroupChat';
import ScrollToBottomStore from '../GroupChat/GroupChatImpl/ScrollToBottomStore';
import Participants from '../Participants';

interface Props {
  tabStore: TabStore;
  presenceMembersStore: PresenceMembersStore;
  agenda: Maybe<string>;
  eventResource: Maybe<EventResource>;
  conferenceRosterStore: ConferenceRosterStore;
}

class ActiveTabPane extends React.Component<Props> {
  scrollToBottomStore = new ScrollToBottomStore();

  render() {
    const {
      tabStore,
      presenceMembersStore,
      conferenceRosterStore,
      agenda,
      eventResource,
    } = this.props;
    switch (tabStore.activeTab) {
      case 'group chat':
        this.scrollToBottomStore.load();
        return (
          <PositionContext.Provider value={{ kind: 'chat' }}>
            <GroupChat
              conversationStore={presenceMembersStore.conversationStore}
              conferenceRosterStore={conferenceRosterStore}
              scrollToBottomStore={this.scrollToBottomStore}
            />
          </PositionContext.Provider>
        );
      case 'participants':
        return (
          <PositionContext.Provider value={{ kind: 'participant' }}>
            <Participants conferenceRosterStore={conferenceRosterStore} />
          </PositionContext.Provider>
        );
      case 'agenda':
        return agenda
          .map((agenda) => <Agenda agenda={agenda} eventResource={eventResource} />)
          .getOrElseValue(<span />);
      default:
        log(`Unexpected tab type: ${tabStore.activeTab}`);
        return <span />;
    }
  }
}

export default observer(ActiveTabPane);
