import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import { mapMaybe } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { whenNoConflictAvailability } from '../../../../Common/Experience/Availability';
import {
  AvailabilityResources,
  CommerceEnrollableExperience,
  EnrollableExperience,
} from '../../../../Common/Experience/Types';
import GetStartedButton from './GetStartedButton';

interface Props {
  availabilities: AvailabilityResources;
  experience: CommerceEnrollableExperience | EnrollableExperience;
  onClick: () => void;
}

function EnrollableAction({ availabilities, experience, onClick }: Props) {
  return fromArrayMaybe(mapMaybe(whenNoConflictAvailability, availabilities))
    .map((availabilities) => (
      <GetStartedButton
        availabilities={availabilities}
        offeringType={experience.offeringType}
        onClick={onClick}
      />
    ))
    .getOrElse(emptyFragment);
}

export default observer(EnrollableAction);
