import * as React from 'react';
import { observer } from 'mobx-react';
import PollsModalStore from '../PollsModal/PollsModalStore';
import PollIcon from '../../components/Cutesies/PollIcon';
import * as style from './style.module.css';
import { T } from '../../Translations';

interface Props {
  store: PollsModalStore;
}

const PollLauncherButton: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'open':
    case 'closed':
    case 'poll-selected':
    case 'poll-selection-ready':
    case 'error':
    case 'poll-launch-request':
    case 'poll-closed':
      return (
        <button className={style.button} onClick={store.open}>
          <PollIcon fill="#fff" />
        </button>
      );
    case 'poll-launch-active':
      return (
        <button className={style.closeButton} onClick={store.closePoll}>
          <span className={style.closeIcon}>&times;</span>
          <T kind="Close Poll" />
        </button>
      );
  }
};

export default observer(PollLauncherButton);
