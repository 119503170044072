import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { RegisterResource } from '../../../RegisterResourceStore/Types';
import {
  DiscoveryPortalResource,
  ExperienceResources,
  ProductCollection,
} from '../../../../Common/Experience/Types';
import { Maybe, nothing } from 'maybeasy';
import { Link } from '../../../../../../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  registerResource: RegisterResource;
}

interface ProductCollections {
  kind: 'product-collections';
  productCollections: NonEmptyList<ProductCollection>;
  search: Maybe<string>;
  allExperiences: ExperienceResources;
  resource: DiscoveryPortalResource;
}

interface Experiences {
  kind: 'experiences';
  experiences: ExperienceResources;
  search: Maybe<string>;
  allExperiences: ExperienceResources;
  resource: DiscoveryPortalResource;
}

interface ProductCollectionsAndExperiences {
  kind: 'product-collections-and-experiences';
  productCollections: NonEmptyList<ProductCollection>;
  experiences: ExperienceResources;
  search: Maybe<string>;
  allExperiences: ExperienceResources;
  resource: DiscoveryPortalResource;
}

interface Error {
  kind: 'error';
  message: string;
}

interface Searching {
  kind: 'searching';
  search: Maybe<string>;
  link: Link;
  allExperiences: ExperienceResources;
  resource: DiscoveryPortalResource;
}

export type Ready = ProductCollections | ProductCollectionsAndExperiences | Experiences;

export type State = Loading | LoadingState;

type LoadingState = Waiting | Ready | Error | Searching;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (registerResource: RegisterResource, state: LoadingState): Loading => ({
  kind: 'loading',
  registerResource,
});

export const ready = (discoveryPortalResource: DiscoveryPortalResource): Ready =>
  fromArrayMaybe(discoveryPortalResource.payload.productCollections)
    .map<Ready>((productCollections) => ({
      kind: 'product-collections-and-experiences',
      productCollections,
      experiences: discoveryPortalResource.payload.experiences,
      resource: discoveryPortalResource,
      search: nothing(),
      allExperiences: discoveryPortalResource.payload.experiences,
    }))
    .getOrElse(() => ({
      kind: 'experiences',
      experiences: discoveryPortalResource.payload.experiences,
      _discoveryPortal: discoveryPortalResource.payload,
      search: nothing(),
      allExperiences: discoveryPortalResource.payload.experiences,
      resource: discoveryPortalResource,
    }));

export const readyWithSearch = (
  resource: DiscoveryPortalResource,
  search: Maybe<string>,
  allExperiences: ExperienceResources,
): Ready =>
  search
    .map<Ready>(() => {
      return {
        kind: 'experiences',
        experiences: resource.payload.experiences,
        _discoveryPortal: resource.payload,
        search,
        allExperiences,
        resource: resource,
      };
    })
    .getOrElse(() =>
      ready({ ...resource, payload: { ...resource.payload, experiences: allExperiences } }),
    );

export const error = (message: string): Error => ({
  kind: 'error',
  message,
});

export const searching = (
  state: Ready,
  search: Maybe<string>,
  link: Link,
  allExperiences: ExperienceResources,
): Searching => {
  const { kind, ...previous } = state;
  return {
    kind: 'searching',
    ...previous,
    search,
    link,
    allExperiences,
  };
};
