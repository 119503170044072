import { observer } from 'mobx-react';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { conferenceWindowName } from './Types';

const ConferenceLink: React.FC<LinkProps> = (props) => (
  <Link {...props} rel="noreferrer noopener" target={conferenceWindowName} />
);

export default observer(ConferenceLink);
