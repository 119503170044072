import React from 'react';
import { Maybe } from 'maybeasy';
import EnrollmentStore from '../../../../DiscoveryPortal/Enrollment/Store';
import { ExperienceResource } from '../../../Experience/Types';

export type EnrolledModalContextState = {
  invitationUuid: string;
  experienceResource: Maybe<ExperienceResource>;
  enrollmentStore: EnrollmentStore;
};

export const EnrolledModalContext = React.createContext<EnrolledModalContextState | null>(null);
