import i18next, { TFunction } from '@prebsch-exo/i18next';
import { Task } from 'taskarian';
import { I18nextInteractionError, i18nextInteractionError } from '../ChangeLocale';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';

export const changeLocale = (
  languageCode: SupportedLanguageCode,
): Task<I18nextInteractionError, TFunction> =>
  Task.fromPromise(() => i18next.changeLanguage(languageCode)).mapError<I18nextInteractionError>(
    i18nextInteractionError,
  );
