import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Maybe } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { LectureSegment, LectureSlideResource, VisibleSlide } from '../LectureStore/Types';
import { Resource } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
  currentScroll: number;
}

interface Ready {
  kind: 'ready';
  currentScroll: number;
}

interface Scrolling {
  kind: 'scrolling';
  direction: Direction;
  currentScroll: number;
}

const waiting = (): Waiting => ({
  kind: 'waiting',
  currentScroll: 0,
});

const ready = (currentScroll: number): Ready => ({
  kind: 'ready',
  currentScroll: currentScroll,
});

const scrolling = (direction: Direction, currentScroll: number): Scrolling => ({
  kind: 'scrolling',
  direction,
  currentScroll,
});

export type Direction = 'left' | 'right';

export type State = Waiting | Ready | Scrolling;

class LectureSlideStore {
  @observable
  state: State = waiting();

  constructor(private segment: LectureSegment, private kettle: Kettle) {}

  get visibleSlides(): Resource<VisibleSlide>[] {
    return this.segment.slides.filter(
      (slideResource) => slideResource.payload.visible
    ) as Resource<VisibleSlide>[];
  }

  @computed
  get current(): Maybe<Resource<VisibleSlide>> {
    return this.kettle.videoState.position
      .map((pos) => find((slide: Resource<VisibleSlide>) => slide.payload.time <= pos))
      .andThen((fn) => fn(this.visibleSlides.reverse()));
  }

  @computed
  get showPrevArrow(): boolean {
    switch (this.state.kind) {
      case 'ready':
      case 'scrolling':
        return this.state.currentScroll !== 0;
      case 'waiting':
        return false;
    }
  }

  showNextArrow(elementToScroll: HTMLDivElement | null): boolean {
    return computed(() => {
      if (!elementToScroll) {
        return false;
      }
      switch (this.state.kind) {
        case 'ready':
        case 'scrolling':
          return (
            this.state.currentScroll < elementToScroll.scrollWidth - elementToScroll.offsetWidth
          );
        case 'waiting':
          return false;
      }
    }).get();
  }

  isCurrent(candidate: LectureSlideResource): boolean {
    return this.current
      .map((slide) => candidate.payload.id === slide.payload.id)
      .getOrElseValue(false);
  }

  @action
  scroll(direction: Direction) {
    switch (this.state.kind) {
      case 'ready':
        this.state = scrolling(direction, this.state.currentScroll);
        break;
      case 'waiting':
      case 'scrolling':
        break;
      default:
        assertNever(this.state);
    }
  }

  @action
  seekTo(slide: LectureSlideResource) {
    this.kettle.seekTo(slide.payload.time);
  }

  @action
  updateCurrentScroll(position: number) {
    switch (this.state.kind) {
      case 'ready':
      case 'scrolling':
        this.state.currentScroll = position;
        break;
      case 'waiting':
        break;
      default:
        assertNever(this.state);
    }
  }

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'scrolling':
        this.state = ready(this.state.currentScroll);
        break;
      case 'ready':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default LectureSlideStore;
