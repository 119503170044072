import { observer } from 'mobx-react';
import * as React from 'react';
import { FlashAlert } from '../../Notifications/Types';
import Message from './Message';

export interface Props {
  alert: FlashAlert;
}

const FlashMessage: React.FC<Props> = ({ alert }) => (
  <div role="alert">
    <Message alert={alert} />
  </div>
);

export default observer(FlashMessage);
