import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ExperienceFiltersResource } from '../../Common/Experience/Types';
import ExperiencesStore from './Experiences/ExperiencesStore';
import { useExperienceFilters } from './Filters/FiltersContext';
import FilterSection from './Filters/FilterSection';
import FilterStore from './Filters/FilterStore';
import WelcomeMessage from './WelcomeMessage';

interface Props {
  experiencesStore: ExperiencesStore;
  experienceFilters: ExperienceFiltersResource;
}

function ExperienceFiltersSelectionContent({ experienceFilters, experiencesStore }: Props) {
  const filterStore = useConst(() => new FilterStore(experienceFilters.payload));
  const { initializeFilters } = useExperienceFilters();

  useEffect(() => {
    initializeFilters(filterStore);
  }, [filterStore]);

  return (
    <>
      <WelcomeMessage experiencesStore={experiencesStore} />
      <FilterSection filterStore={filterStore} experiencesStore={experiencesStore} />
    </>
  );
}

export default observer(ExperienceFiltersSelectionContent);
