import * as React from 'react';
import { UploadedAttachmentResource } from '../../../AttachmentUploadStore/Types';
import { findSelfLink } from '../../../LinkyLinky';
import { Link } from '../../../Resource/Types';
import { NotTranslated } from '../../../Translations';

interface Props {
  assignment: UploadedAttachmentResource;
}

const CompleteAttachment: React.FC<Props> = ({ assignment }) => (
  <div data-test-complete-attachment={assignment.payload.id}>
    {findSelfLink(assignment.links)
      .map((link: Link) => (
        <a
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          data-test-attachment-link={assignment.payload.filename}
        >
          <NotTranslated text={assignment.payload.filename} />
        </a>
      ))
      .getOrElseValue(
        <span data-test-attachment-filename={assignment.payload.filename}>
          <NotTranslated text={assignment.payload.filename} />
        </span>
      )}
  </div>
);

export default CompleteAttachment;
