import { useConst } from '@execonline-inc/react-hooks.private';
import { observer } from 'mobx-react';
import { useCurrentUserContext } from '../../CurrentUser';
import AutoLaunchableModal from '../AutoLaunchableModal';
import AutoLaunchableReactions from './AutoLaunchableReactions';
import { AutoLaunchableStore } from './AutoLaunchableStore';
import { useAutoLaunchableContext } from './hooks';

function AutoLaunchable() {
  const currentUserResource = useCurrentUserContext();
  const autoLaunchableMode = useAutoLaunchableContext();
  const autoLaunchableStore = useConst(() => new AutoLaunchableStore());

  return (
    <>
      <AutoLaunchableModal autoLaunchableStore={autoLaunchableStore} />
      <AutoLaunchableReactions
        store={autoLaunchableStore}
        currentUserResource={currentUserResource}
        autoLaunchableMode={autoLaunchableMode}
        fireImmediately
      />
    </>
  );
}

export default observer(AutoLaunchable);
