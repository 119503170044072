import { asMaybe } from '@execonline-inc/error-handling';
import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Maybe, nothing } from 'maybeasy';
import { action, computed, observable, toJS } from 'mobx';
import { Result, err, ok } from 'resulty';
import { MissingLinkError } from '../../../../LinkyLinky';
import {
  AssertionBackendResource,
  LockedAccess,
  PasswordBackend,
  ThirdPartyBackend,
} from '../../../../Login/Types';
import { Resource, resource } from '../../../../Resource/Types';
import { assertionBackendToUrls } from '../../../../SingleSignOn/Functions';
import { TPlainTextKey } from '../../../../Translations';
import { UseCaseErrorMessage } from '../../../ErrorMessage/Types';
import { InvitationStore } from '../InvitationStore';
import {
  InstantSsoReady,
  SharedInvitationResource,
  State,
  error,
  loading,
  ready,
  sharedInvitationLinkInvalid,
  waiting,
} from './Types';

export class SharedInvitationResourceStore {
  @observable
  state: State = waiting();

  constructor(public readonly invitationStore: InvitationStore) {}

  @action
  loading = (opaqueUseCaseIdentifier: string): void => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = loading(opaqueUseCaseIdentifier);
        break;
      case 'loading':
      case 'ready':
      case 'error':
      case 'shared-invitation-link-invalid':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (resource: SharedInvitationResource): void => {
    switch (this.state.kind) {
      case 'loading':
        this.state = ready(this.state.opaqueUseCaseIdentifier, resource);
        break;
      case 'waiting':
      case 'ready':
      case 'error':
      case 'shared-invitation-link-invalid':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey): void => {
    this.state = error(message);
  };

  @action
  sharedInvitationLinkInvalid = (message: UseCaseErrorMessage): void => {
    this.state = sharedInvitationLinkInvalid(message);
  };

  @computed
  get instantSso(): Maybe<InstantSsoReady> {
    switch (this.state.kind) {
      case 'ready': {
        const { assertionBackend } = this.state.resource.payload;
        const opaqueUseCaseIdentifier = this.state.opaqueUseCaseIdentifier;

        return asMaybe(
          ok<InstantSsoComputationError, AssertionBackendResource>(assertionBackend)
            .andThen(whenThirdPartyBackend)
            .map((backend) => ({ backend }))
            .assign('urls', ({ backend }) => assertionBackendToUrls(backend))
            .map(
              ({ backend, ...rest }): InstantSsoReady => ({
                ...rest,
                opaqueUseCaseIdentifier,
                username: nothing<string>(),
              })
            )
            .elseDo((err) => warn(`Could not compute instant SSO data.`, toJS(err)))
        );
      }
      case 'error':
      case 'shared-invitation-link-invalid':
      case 'waiting':
      case 'loading':
        return nothing();
    }
  }
}

interface IncorrectAssertionBackend {
  kind: 'incorrect-assertion-backend';
  assertionKind: PasswordBackend['kind'] | LockedAccess['kind'];
}

type InstantSsoComputationError = IncorrectAssertionBackend | MissingLinkError;

const whenThirdPartyBackend = (
  assertionBackend: AssertionBackendResource
): Result<IncorrectAssertionBackend, Resource<ThirdPartyBackend>> => {
  switch (assertionBackend.payload.kind) {
    case 'third-party-backend':
      return ok(resource(assertionBackend.links, assertionBackend.payload));
    case 'locked-access':
    case 'password-backend':
      return err({
        kind: 'incorrect-assertion-backend',
        assertionKind: assertionBackend.payload.kind,
      });
  }
};
