import { equals, find } from '@execonline-inc/collections';
import { JsonValue } from '@execonline-inc/decoders';
import { asMaybe } from '@execonline-inc/error-handling';
import { array } from 'jsonous';
import { Maybe } from 'maybeasy';
import { getFieldValue } from '../../../../../FormFields';
import { selectValueDecoder } from '../../../Decoders';
import { FormFieldOutputValue, SelectFieldValue, StringFormField } from '../../../Types';

export const stringInputOptionLabel = (stringFormField: StringFormField) =>
  stringFormField.label.map((label) => label.text).getOrElseValue(stringFormField.name);

export const convertToSelectFieldValue = (label: string, value: string): SelectFieldValue => ({
  label,
  value,
});

export const inputValue = (stringFormField: StringFormField, fieldValue: SelectFieldValue[]) =>
  find((v) => equals(v.label, stringInputOptionLabel(stringFormField)), fieldValue)
    .map((v) => v.value)
    .getOrElseValue('');

const validateValue = (value: FormFieldOutputValue): Maybe<SelectFieldValue[]> =>
  asMaybe(array(selectValueDecoder).decodeAny(value));

export const fieldValueM = (results: Maybe<JsonValue>, uuid: string) =>
  getFieldValue(results, uuid).andThen(validateValue).getOrElseValue([]);
