import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '../../../../../Translations';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

function ExperienceDescription() {
  const { experienceResource } = useExperiencePopoverContext();

  switch (experienceResource.payload.kind) {
    case 'beginnable':
    case 'returnable':
    case 'resumable':
    case 'upcoming':
    case 'graduated':
      return emptyFragment();
    case 'enrollable':
    case 'commerce-enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return (
        <Typography variant="caption" className="whitespace-pre-line">
          <AlreadyTranslated content={experienceResource.payload.description} />
        </Typography>
      );
  }
}

export default observer(ExperienceDescription);
