import { noop } from '@kofno/piper';
import { nothing } from 'maybeasy';
import * as React from 'react';
import { Link } from '../../../../Resource/Types';
import { T } from '../../../../Translations';
import Loader from '../../../Loader';
import Modal from '../../../Modal';
import ActionMenuStore from '../ActionMenu/ActionMenuStore';
import * as style from './style.module.css';

interface Props {
  actionMenuStore: ActionMenuStore;
  links: ReadonlyArray<Link>;
}

const ReportMessageModal: React.FC<Props> = ({ actionMenuStore, links }) => {
  switch (actionMenuStore.state.kind) {
    case 'message-report-requested':
      return (
        <Modal
          close={actionMenuStore.ready}
          title={<T kind="Please confirm you want to report this message" />}
        >
          <div className={style.buttons}>
            <button
              className={style.secondaryButton}
              onClick={actionMenuStore.ready}
              data-test-report-message-no-button
            >
              <T kind="Cancel" />
            </button>
            <button
              className={style.primaryButton}
              onClick={() => actionMenuStore.reportMessage(links)}
              data-test-report-message-yes-button
            >
              <T kind="Report" />
            </button>
          </div>
        </Modal>
      );
    case 'reporting-message':
      return (
        <Modal close={noop} title={<T kind="Please confirm you want to report this message" />}>
          <>
            <Loader color="black" text={nothing()} />
            <div className={style.buttons}>
              <button className={style.secondaryButton} disabled={true}>
                <T kind="Cancel" />
              </button>
              <button className={style.primaryButton} disabled={true}>
                <T kind="Report" />
              </button>
            </div>
          </>
        </Modal>
      );
    case 'message-report-success':
      return (
        <Modal close={actionMenuStore.ready} title={<T kind="We have received your report" />}>
          <div className={style.buttons}>
            <button
              className={style.primaryButton}
              onClick={actionMenuStore.ready}
              data-test-report-message-success-button
            >
              <T kind="Close" />
            </button>
          </div>
        </Modal>
      );
    case 'reaction-selected':
    case 'deleting-message':
    case 'message-delete-requested':
    case 'ready':
    case 'reaction-menu-open':
    case 'error':
      return <></>;
  }
};
export default ReportMessageModal;
