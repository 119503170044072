import { observer } from 'mobx-react';
import { fromNullable } from 'maybeasy';
import ThemeContext from '../../../Themes/ThemeContext';
import { ColorPalette } from '../../../Themes/ThemeContext/Types';
import { setIconColor } from '../helpers';
import SVGIcon from '../SVGIcon';

interface Props {
  color?: keyof ColorPalette;
}

const ArrowDownIcon: React.FC<Props> = ({ color }) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <SVGIcon viewBox="6 -2 30 30">
            <path
              fill={setIconColor(fromNullable(color), theme)}
              d="M10.219 5.781 8.78 7.22l12.5 12.5.719.687.719-.687 7.655-7.655 4.845-4.845L33.78 5.78 22 17.563 10.219 5.78Z"
            />
          </SVGIcon>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default observer(ArrowDownIcon);
