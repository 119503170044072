import { findPayloadByUuid } from '@execonline-inc/collections';
import { Kettle } from 'kettle-corn';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReqHlsVideo from '.';
import { LegacyAndNewVideoAssetResource } from '../../SegmentStore/Types';
import { videoResourceCata } from '../Segment/LectureSegment/Video/Context';
import Video from '../Video';
import VideoAssetUnavailable from '../Video/VideoAssetUnavailable';

interface Props {
  uuid: string;
  embeddedVideoAssets: LegacyAndNewVideoAssetResource[];
  height: string | number;
  width: string | number;
  dynamicallySetAspectRatio?: boolean;
}

const ReqHlsEmbeddedVideoPlayer: React.FC<Props> = ({
  uuid,
  embeddedVideoAssets,
  height,
  width,
  dynamicallySetAspectRatio,
}) => {
  return just(embeddedVideoAssets)
    .andThen(findPayloadByUuid(uuid))
    .map((reqHlsVideoAssetResource) => {
      return videoResourceCata(
        {
          legacy: (video) => (
            <Video
              id={reqHlsVideoAssetResource.payload.uuid}
              className="embedded-video"
              videoResource={video}
              kettle={new Kettle()}
              width={width}
              height={height}
              dynamicallySetAspectRatio={dynamicallySetAspectRatio}
            >
              {{
                playButton: nothing(),
              }}
            </Video>
          ),
          hls: (video) => (
            <ReqHlsVideo
              id={reqHlsVideoAssetResource.payload.uuid}
              className="embedded-video"
              videoResource={video}
              kettle={new Kettle()}
              width={width}
              height={height}
              dynamicallySetAspectRatio={dynamicallySetAspectRatio}
            >
              {{
                playButton: nothing(),
              }}
            </ReqHlsVideo>
          ),
        },
        reqHlsVideoAssetResource
      );
    })
    .getOrElseValue(<VideoAssetUnavailable />);
};

export default observer(ReqHlsEmbeddedVideoPlayer);
