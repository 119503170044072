import { Button, ButtonProps, cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useTranslationsContext } from '../../../../../Translations/UseTranslationsContext';
import AdvanceButtonContent from './AdvanceButtonContent';

function AdvanceButton({ className, children, startContent, endContent, ...others }: ButtonProps) {
  const { translate } = useTranslationsContext();

  return (
    <Button
      className={cn(className, 'w-5/12 max-w-56', { 'pl-14': startContent, 'pr-14': endContent })}
      title={translate('Advance')}
      radius="sm"
      color="green"
      startContent={<AdvanceButtonContent className="left-0">{startContent}</AdvanceButtonContent>}
      endContent={<AdvanceButtonContent className="right-0">{endContent}</AdvanceButtonContent>}
      {...others}
    >
      <>{children}</>
    </Button>
  );
}

export default observer(AdvanceButton);
