import { flatMap, mapMaybe } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { NonEmptyList, fromArrayMaybe } from 'nonempty-list';
import { MonthProgramDetailResources } from '../CalendarStore/Types';
import { CalendarDay, CalendarMonth, CalendarWeek } from '../Calendaring';
import {
  DateRange,
  between,
  dateRange,
  endOfDay,
  isSameDay,
  rangeCenter,
  rangeIntersection,
  startOfDay,
} from '../Date';
import { ActiveProgramModule } from '../ProgramStore/Types';

interface BaseDayModule {
  date: Date;
}

interface EmptyModule extends BaseDayModule {
  kind: 'empty';
}

type ModuleDisplayType = 'start' | 'middle' | 'end';

export interface DisplayModule extends BaseDayModule {
  kind: 'display';
  name: Maybe<string>;
  displayType: ModuleDisplayType;
  id: number;
  programModule: ActiveProgramModule;
}

// Used to mark an empty place on the calendar when a lower module shouldn't
// try to shift upwards.
const emptyModule = (date: Date): EmptyModule => ({
  kind: 'empty',
  date,
});

const displayModule = (
  date: Date,
  name: Maybe<string>,
  displayType: ModuleDisplayType,
  programModule: ActiveProgramModule,
): DisplayModule => ({
  kind: 'display',
  date,
  name,
  displayType,
  programModule,
  id: programModule.id,
});

export type DayModule = EmptyModule | DisplayModule;

export function moduleKey(module: DayModule, id: number) {
  switch (module.kind) {
    case 'display':
      return `${module.date.toISOString()}-${module.name.getOrElseValue(module.id.toString())}`;
    case 'empty':
      return `${module.date.toISOString()}-${id}-empty`;
  }
}

export type ModuleOrder = ReadonlyArray<DayModule>;

interface ModulesForDay {
  date: Date;
  modules: ModuleOrder;
}

export interface ModuleSchedule {
  modulesForDays: ReadonlyArray<ModulesForDay>;
}

const moduleResourceOn =
  (date: Date) =>
  (programModule: ActiveProgramModule): boolean =>
    just({})
      .assign('beginning', programModule.startsOn)
      .assign('end', programModule.endsOn)
      .map(between(date))
      .getOrElseValue(false);

const moduleRange = (programModule: ActiveProgramModule): Maybe<DateRange> =>
  just({})
    .assign('startsOn', programModule.startsOn)
    .assign('endsOn', programModule.endsOn)
    .map((dates) => dateRange(startOfDay(dates.startsOn), endOfDay(dates.endsOn)))
    .andThen((range) => range.map(just).getOrElse(nothing));

const moduleWeekIntersection = (
  programModule: ActiveProgramModule,
  calendarWeek: CalendarWeek,
): Maybe<DateRange> =>
  just({})
    .assign('mRange', moduleRange(programModule))
    .assign(
      'wRange',
      dateRange(startOfDay(calendarWeek[0].date), endOfDay(calendarWeek[6].date))
        .map(just)
        .getOrElse(nothing),
    )
    .andThen((dates) => rangeIntersection(dates.mRange, dates.wRange).map(just).getOrElse(nothing));

const getNameOn = (programModule: ActiveProgramModule, calendarWeek: CalendarWeek): Maybe<Date> =>
  moduleWeekIntersection(programModule, calendarWeek).map(rangeCenter);

const getDisplayType = (
  programModule: ActiveProgramModule,
  calendarDay: CalendarDay,
): Maybe<ModuleDisplayType> => {
  return just({})
    .assign('startsOn', programModule.startsOn)
    .assign('endsOn', programModule.endsOn)
    .andThen<ModuleDisplayType>((dates) => {
      if (isSameDay(calendarDay.date, dates.startsOn)) {
        return just<ModuleDisplayType>('start');
      } else if (isSameDay(calendarDay.date, dates.endsOn)) {
        return just<ModuleDisplayType>('end');
      } else if (moduleResourceOn(calendarDay.date)(programModule)) {
        return just<ModuleDisplayType>('middle');
      } else {
        return nothing();
      }
    });
};

const returnedModule = (
  name: Maybe<string>,
  programModule: ActiveProgramModule,
  calendarDay: CalendarDay,
): DayModule =>
  programModule['offeringType']
    .andThen((offeringType) => {
      switch (offeringType) {
        case 'program-sequence':
        case 'coaching':
        case 'group-coaching':
          return just(emptyModule(calendarDay.date));
        case 'program':
          return getDisplayType(programModule, calendarDay).map<DayModule>((displayType) =>
            displayModule(calendarDay.date, name, displayType, programModule),
          );
        default:
          assertNever(offeringType);
      }
    })
    .getOrElse(() => emptyModule(calendarDay.date));

const dayModuleForCalendarDay =
  (nameOn: Maybe<Date>, programModule: ActiveProgramModule) =>
  (calendarDay: CalendarDay): DayModule => {
    const name: Maybe<string> = nameOn.andThen((date) =>
      isSameDay(date, calendarDay.date)
        ? programModule.label.orElse(() => just(programModule.title.text))
        : nothing(),
    );

    return returnedModule(name, programModule, calendarDay);
  };

const trimTrailingEmptyModules = (
  dayModules: ReadonlyArray<DayModule>,
): ReadonlyArray<DayModule> => {
  const lastDisplayIndex = dayModules.map((dayModule) => dayModule.kind).lastIndexOf('display');
  return dayModules.slice(0, lastDisplayIndex + 1);
};

const allActiveModules = (
  monthProgramDetailResources: MonthProgramDetailResources,
): ReadonlyArray<ActiveProgramModule[]> =>
  monthProgramDetailResources.map((programDetailResource) => {
    switch (programDetailResource.payload.kind) {
      case 'active':
      case 'completed':
        return programDetailResource.payload.modules;
      case 'expired':
        return [];
      case 'upcoming':
        return [];
      case 'inactive':
        return [];
    }
  });

// The dayModulesForProgramWeek array holds DayModules grouped by the module
// they belong to. The whole array is the information for a single week and
// Program. The sub-arrays each hold 7 DayModules, one for each day of the
// week. Since a single program's modules never overlap, we can flatten this
// array of arrays into a single array.
const flattenModulesForProgram = (
  dayModulesForProgramWeek: NonEmptyList<DayModule[]>,
): NonEmptyList<DayModule> =>
  new NonEmptyList(0, [1, 2, 3, 4, 5, 6]).map((d) =>
    dayModulesForProgramWeek
      .find((dayModulesForWeek) => dayModulesForWeek[d].kind === 'display')
      .map((dayModulesForWeek) => dayModulesForWeek[d])
      .getOrElse(() => emptyModule(dayModulesForProgramWeek.first[d].date)),
  );

const dayModulesForWeek =
  (monthProgramDetailResources: MonthProgramDetailResources) =>
  (calendarWeek: CalendarWeek): ModulesForDay[] => {
    const modulesThisWeek = allActiveModules(monthProgramDetailResources).map((programModules) =>
      programModules.filter((programModule) =>
        moduleWeekIntersection(programModule, calendarWeek)
          .map((_) => true)
          .getOrElseValue(false),
      ),
    );

    const daysOfModules = fromArrayMaybe(
      mapMaybe(
        fromArrayMaybe,
        modulesThisWeek.map((programModules) =>
          programModules.map((programModule) => {
            const nameOn = getNameOn(programModule, calendarWeek);
            return calendarWeek.map(dayModuleForCalendarDay(nameOn, programModule));
          }),
        ),
      ),
    ).map((ary) => ary.map(flattenModulesForProgram));

    return calendarWeek.map((day, i) => ({
      date: day.date,
      modules: daysOfModules
        .map((ary) =>
          trimTrailingEmptyModules(
            ary.map((weekOfModules) => weekOfModules.toArray()[i]).toArray(),
          ),
        )
        .getOrElseValue([]),
    }));
  };

export const modulesForDays = (
  monthProgramDetailResources: MonthProgramDetailResources,
  calendarMonth: CalendarMonth,
): ReadonlyArray<ModulesForDay> =>
  flatMap(dayModulesForWeek(monthProgramDetailResources), calendarMonth.weeks.toArray());

export const monthModuleSchedule = (
  monthProgramDetailResources: MonthProgramDetailResources,
  calendarMonth: CalendarMonth,
): ModuleSchedule => ({
  modulesForDays: modulesForDays(monthProgramDetailResources, calendarMonth),
});
