import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import { Auth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import PasswordInstructions from './PasswordInstructions';

interface Props {
  auth: Auth;
  sharedInvitationResource: SharedInvitationResource;
}

const Instructions: React.FC<Props> = ({ auth, sharedInvitationResource }) => {
  switch (auth.kind) {
    case 'locked-access-auth':
      return <></>;
    case 'password-auth':
      return <PasswordInstructions sharedInvitationResource={sharedInvitationResource} />;
    case 'sso-auth':
      return (
        <Typography>
          <T kind="Continue with your corporate..." />
        </Typography>
      );
  }
};

export default observer(Instructions);
