import { warnAndNotify } from '../Honeybadger';

export interface DynamicImportError {
  kind: 'dynamic-import-error';
  error: unknown;
}

export const dynamicImportError = (error: unknown): DynamicImportError => ({
  kind: 'dynamic-import-error',
  error,
});

export const handleDynamicImportError = (error: DynamicImportError): void => {
  warnAndNotify('DynamicImportError', 'Unable to dynamically load a module.', { error });
};
