import { observer } from 'mobx-react';
import { T } from '../Translations';

function AnnouncementsHeader() {
  return (
    <h2>
      <T kind="Announcements" />
    </h2>
  );
}

export default observer(AnnouncementsHeader);
