import { observer } from 'mobx-react';
import * as React from 'react';
import DocumentResourceView from './DocumentResourceView';
import { cata, ResourceResource } from './Types';
import VideoResourceView from './VideoResourceView';

interface Props {
  resource: ResourceResource;
}

const ResourceView: React.FC<Props> = ({ resource }) => {
  return cata(
    {
      document: (r) => <DocumentResourceView resource={r} />,
      video: (r) => <VideoResourceView resource={r} />,
    },
    resource
  );
};

export default observer(ResourceView);
