import { log } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import { deleteToApi, postToApi, putToApi } from '../Appy';
import { ConferenceRoomResource } from '../Conference/Types';
import { warnAndNotify } from '../Honeybadger';
import { findLinkT } from '../LinkyLinky';
import { Link, Linkable } from '../Resource/Types';
import { HandActionError, HandEventClock } from './types';

const decodeLinkForUser =
  <E = never>(id: number) =>
  (link: Link): Task<E, Link> => {
    const decoded = decodeURI(link.href);
    const href = decoded.replace('{id}', String(id));
    return Task.succeed<E, Link>({ ...link, href });
  };

export const handleError = (error: HandActionError) => {
  switch (error.kind) {
    case 'bad-payload':
    case 'bad-status':
    case 'bad-url':
    case 'network-error':
    case 'timeout':
      warnAndNotify('HandActionError', 'Network error trying to change hand raised state', error);
      break;
    case 'missing-application-id':
      warnAndNotify('HandActionError', 'Missing Application ID', error);
      break;
    case 'missing-api-compatibility':
      warnAndNotify('HandActionError', 'Missing API compatibility', error);
      break;
    case 'missing-link-error':
      warnAndNotify('HandActionError', `User doesn't have permission to perform operation`, error);
      break;
    case 'missing-conference-id-error':
      warnAndNotify('HandActionError', 'Missing the conference room ID', error);
      break;
    default:
      assertNever(error);
  }
};

export const raiseHand = (resource: Linkable) =>
  Task.succeed<HandActionError, ReadonlyArray<Link>>(resource.links)
    .andThen(findLinkT('my-hand-up'))
    .andThen(postToApi({}));

export const lowerHand = (resource: Linkable) =>
  Task.succeed<HandActionError, ReadonlyArray<Link>>(resource.links)
    .andThen(findLinkT('my-hand-down'))
    .andThen(deleteToApi);

export const lowerOtherUserHand = ({ links }: ConferenceRoomResource, id: number) =>
  Task.succeed<HandActionError, ReadonlyArray<Link>>(links)
    .andThen(findLinkT('other-hand-down'))
    .andThen(decodeLinkForUser(id))
    .do((link) => log('lowerOtherUserHand', link))
    .andThen(deleteToApi);

const broadcastOrder = (clock: HandEventClock): number => {
  switch (clock.kind) {
    case 'hand-event-bottom':
      return 0;
    case 'hand-event-order':
      return clock.value;
  }
};

export const broadcastHand = (
  { links }: Linkable,
  clock: HandEventClock,
  status: 'hand-up' | 'hand-down',
) =>
  Task.succeed<HandActionError, ReadonlyArray<Link>>(links)
    .andThen(findLinkT('my-hand-broadcast'))
    .andThen(putToApi({ order: broadcastOrder(clock), status }));
