import * as React from 'react';
import { Assignment, Document } from '../../../../TeamResourceStore/Types';
import { NotTranslated } from '../../../../Translations';
import * as style from '../../style.module.css';
import DocumentAddedOn from '../DocumentResource/DocumentAddedOn';
import StudentDetails from './StudentDetails';

interface Props {
  assignment: Assignment;
  resource: Document;
}

const AssignmentDetails: React.FC<Props> = ({ assignment, resource }) => (
  <div className={style.details}>
    <a href="#" className={style.fileTitle} data-test-team-file-link={resource.title}>
      <NotTranslated text={resource.title} />
    </a>
    <StudentDetails assignment={assignment} />
    &nbsp;
    <DocumentAddedOn resource={resource} />
  </div>
);

export default AssignmentDetails;
