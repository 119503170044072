import * as React from 'react';
import { DropdownListItem } from '../Types';
import ActionListDropdownItem from './ActionListDropdownItem';
import IconListDropdownItem from './IconListDropdownItem';
import LinkListDropdownItem from './LinkListDropdownItem';
import UseCaseDropdownItem from './UseCaseDropdownItem';
import WaffleListDropdownItem from './WaffleListDropdownItem';

interface Props {
  item: DropdownListItem;
}

const ListItem: React.FC<Props> = ({ item }) => {
  switch (item.kind) {
    case 'action-list-item':
      return <ActionListDropdownItem item={item} />;
    case 'link-list-item':
      return (
        <li>
          <LinkListDropdownItem item={item} />
        </li>
      );
    case 'icon-list-item':
      return <IconListDropdownItem item={item} />;
    case 'waffle-icon-list-item':
      return <WaffleListDropdownItem item={item} />;
    case 'use-case-item':
      return <UseCaseDropdownItem item={item} />;
  }
};

export default ListItem;
