import { observer } from 'mobx-react';
import { Program } from '../../../../../ProgramStore/Types';
import ReminderMessage from './ReminderMessage';

interface Props {
  program: Program;
}

const CohortPositionWarningMessage = ({ program }: Props) => {
  switch (program.kind) {
    case 'active':
      return <ReminderMessage activeProgram={program} />;
    case 'completed':
    case 'expired':
    case 'inactive':
    case 'upcoming':
      return <></>;
  }
};

export default observer(CohortPositionWarningMessage);
