import { fromEmpty, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { ExperienceResources } from '../../../../Common/Experience/Types';
import { TileViewProgramExperience } from '../../../../Common/ProgramExperience';
import EnrollmentStore from '../../../Enrollment/Store';
import NoExperiences from '../Common/NoExperiences';
import { useExperiencesContext } from '../ExperiencesContext';

interface Props {
  invitationUuid: string;
  experienceResources: ExperienceResources;
  enrollmentStore: EnrollmentStore;
}

function TileExperiences({ invitationUuid, experienceResources, enrollmentStore }: Props) {
  const experiencesStore = useExperiencesContext();

  return fromEmpty(experienceResources)
    .map((experienceResources) => (
      <div className="mt-14 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {experienceResources.map((experienceResource) => {
          return (
            <div
              key={experienceResource.payload.experienceId}
              className="box-border flex w-full sm:max-w-full xl:min-w-[17rem] xl:max-w-[26.625rem]"
            >
              <TileViewProgramExperience
                invitationUuid={invitationUuid}
                experienceResource={experienceResource}
                experiencesStore={experiencesStore}
                enrollmentStore={enrollmentStore}
              />
            </div>
          );
        })}
      </div>
    ))
    .getOrElse(() => <NoExperiences searchValue={nothing()} />);
}

export default observer(TileExperiences);
