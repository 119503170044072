import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';

type Decorator = (children: JSX.Element) => JSX.Element;

interface WhenOneOrAllProps<T> {
  values: NonEmptyList<T>;
  hasOne: (t: T) => JSX.Element;
  hasMany: (t: T) => JSX.Element;
  hasManyDecorator?: Decorator;
}

const defaultDecorator: Decorator = (children: JSX.Element) => <>{children}</>;

function WhenOneOrAll<T>({ values, hasOne, hasMany, hasManyDecorator }: WhenOneOrAllProps<T>) {
  const decorateHasMany =
    typeof hasManyDecorator === 'undefined' ? defaultDecorator : hasManyDecorator;

  return when(values.length === 1, values)
    .map((values) => hasOne(values.first))
    .getOrElse(() => decorateHasMany(<>{values.map(hasMany).toArray()}</>));
}

export default observer(WhenOneOrAll);
