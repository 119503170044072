import { warn } from '@execonline-inc/logging';
import { always, noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Task } from 'taskarian';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { fromNullableT } from '../../TaskExt';
import TeamsStore from '../../TeamsStore';
import { toastsStore } from '../../ToastsStore';
import ToastsReactions from '../../ToastsStore/ToastsReactions';
import ToastsRefreshReactions from '../../ToastsStore/ToastsRefreshReactions';
import { windowAddListener } from '../../Window';
import { PlatformWrapperRef } from '../PlatformWrapperContext';
import EventNotifications from './EventNotifications';
import MessageNotifications from './MessageNotifications';
import * as style from './style.module.css';

interface Props {
  teamsStore: Maybe<TeamsStore>;
  platformWrapper: PlatformWrapperRef;
  currentUserResource: CurrentUserResource;
}

class ToastsList extends React.Component<Props> {
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  getPlatformWrapperRect = (): Task<string, DOMRect> =>
    fromNullableT(this.props.platformWrapper.current)
      .map((ref) => ref.getBoundingClientRect())
      .mapError(always('Unable to access platform wrapper element ref.'));

  getToastsContainer = (): Task<string, HTMLDivElement> =>
    fromNullableT(this.ref.current).mapError(
      always('Unable to access toast container element ref.'),
    );

  setToastsContainerPosition = (): void => {
    Task.succeed<string, {}>({})
      .assign('toastsContainer', this.getToastsContainer())
      .assign('platformWrapperRect', this.getPlatformWrapperRect())
      .fork(warn, ({ toastsContainer, platformWrapperRect }) => {
        toastsContainer.style.right = `${platformWrapperRect.left + 35}px`;
      });
  };

  componentDidMount() {
    toastsStore.loading();
    windowAddListener('resize', this.setToastsContainerPosition).fork(
      (e) => warn('Unable to add window resize listener for toast container', e),
      noop,
    );
  }

  componentDidUpdate() {
    this.setToastsContainerPosition();
  }

  render() {
    return (
      <>
        <div className={style.toasts} aria-live="polite" ref={this.ref}>
          <EventNotifications toasts={toastsStore.toasts} />
          <MessageNotifications teamsStore={this.props.teamsStore} />
          <ToastsReactions
            store={toastsStore}
            currentUserResource={this.props.currentUserResource}
          />
          <ToastsRefreshReactions store={toastsStore} />
        </div>
      </>
    );
  }
}

export default observer(ToastsList);
