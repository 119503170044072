import * as React from 'react';
import { observer } from 'mobx-react';

import ProgramStore from '../../../ProgramStore';
import UpcomingSessionsBinding from './UpcomingSessionsBinding';
import ChannelAvailabilityStore from '../../ChannelAvailabilityStore';

interface Props {
  programStore: ProgramStore;
  channelAvailabilityStore: ChannelAvailabilityStore;
}

const UpcomingSessionsHandler: React.FC<Props> = ({ programStore, channelAvailabilityStore }) => {
  switch (channelAvailabilityStore.channelAvailability.kind) {
    case 'available': {
      const channel = channelAvailabilityStore.channelAvailability.channel;
      return <UpcomingSessionsBinding channel={channel} store={programStore} />;
    }
    case 'not-available': {
      return <span />;
    }
  }
};

export default observer(UpcomingSessionsHandler);
