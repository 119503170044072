import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ActiveProgramModule,
  ParticipateableProgram,
} from '../../../../../../../ProgramStore/Types';
import { AlreadyTranslated, T } from '../../../../../../../Translations';
import CircleIcon from '../../../../../../Cutesies/CircleIcon';
import CompletedModuleIcon from '../../../../../../Cutesies/CompletedModuleIcon';
import FlagIcon from '../../../../../../Cutesies/FlagIcon';
import * as style from '../../../../../../ProgramSummary/style.module.css';

interface Props {
  programModule: ActiveProgramModule;
  accessKind: ParticipateableProgram['accessKind'];
  isFinalModule: boolean;
}

const color = (accessKind: ParticipateableProgram['accessKind']): string => {
  switch (accessKind) {
    case 'current-unlocked':
      return '#DEE6E7';
    case 'ended-unlocked':
    case 'current-warning':
    case 'ended-warning':
      return '#D99F23';
    case 'current-danger':
    case 'ended-danger':
      return '#B3090F';
  }
};

const OnDemandModuleIcon: React.FC<Props> = ({ programModule, accessKind, isFinalModule }) => {
  if (isFinalModule) {
    return (
      <div className={style.moduleFinal}>
        <FlagIcon fillColor={color(accessKind)} />
      </div>
    );
  } else if (programModule.percentComplete >= 100) {
    return <CompletedModuleIcon />;
  } else if (programModule.isCurrentModule) {
    return programModule.daysSinceModuleEnd
      .map((days) => {
        return (
          <div className={style.onDemandModule}>
            <div className={style.onDemandModuleTooltip} data-test-progress-tooltip={true}>
              <div>
                <AlreadyTranslated content={programModule.title} />
              </div>
              <div>
                <T kind="Due {{count}} day ago" count={days} />
              </div>
              <div className={style.onDemandModuleTooltipArrow} />
            </div>
            <CircleIcon fillColor={'var(--accent)'} />
          </div>
        );
      })
      .getOrElseValue(<CircleIcon fillColor={'var(--accent)'} />);
  } else {
    return <CircleIcon fillColor={'var(--accent)'} />;
  }
};

export default observer(OnDemandModuleIcon);
