import { Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function SkeletonMobilePromotableExperienceTileView() {
  return (
    <div className="box-border flex w-full flex-shrink-0 snap-start grid-cols-2 flex-col gap-x-16">
      <Skeleton className="mb-8 h-52" variant="rectangle" />
      <div className="flex flex-col">
        <Skeleton className="mb-8 w-10/12" size="lg" />
        <Skeleton className="mb-4 w-10/12" />
        <div className="flex w-full flex-col gap-y-2">
          <Skeleton />
          <Skeleton />
          <Skeleton className="w-8/12" />
        </div>
      </div>
      <div className="mt-6 flex items-center gap-10">
        <Skeleton className="w-3/12" variant="rectangle" size="md" />
        <Skeleton className="w-3/12" variant="rectangle" size="md" />
      </div>
    </div>
  );
}

export default observer(SkeletonMobilePromotableExperienceTileView);
