import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../CurrentUser/Store';
import CenteredLogo from '../CenteredLogo';
import Loader from '../Loader';
import Background from './Background';
import Details from './Details';
import SubscriptionStore from './Store';
import UnsubscribeReactions from './Store/Reactions';

interface Props {
  uuid: string;
}

class Unsubscribe extends React.Component<Props> {
  subscriptionStore = new SubscriptionStore(this.props.uuid);

  render() {
    switch (currentUserStore.state.kind) {
      case 'anonymous':
      case 'ready':
      case 'refreshing':
        return (
          <>
            <Background>
              <CenteredLogo />
              <Details store={this.subscriptionStore} />
            </Background>
            <UnsubscribeReactions store={this.subscriptionStore} fireImmediately={true} />
          </>
        );
      case 'waiting':
      case 'loading':
      case 'logging-out':
      case 'errored':
        return <Loader />;
    }
  }
}

export default observer(Unsubscribe);
