import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

interface Props {
  fillColor: string;
}

const StartIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SVGIcon viewBox="0 0 11 15">
      <path fill={fillColor} d="M10.074 8.5 0 15V2z" />
    </SVGIcon>
  );
};

export default observer(StartIcon);
