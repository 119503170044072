import { log, warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import {
  Buffering,
  Ended,
  Initialized,
  Paused,
  Playing,
  Ready,
  VideoState,
} from 'kettle-corn/Kettle/VideoState';
import { Maybe, fromNullable, just } from 'maybeasy';
import { IReactionDisposer, reaction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Task } from 'taskarian';
import { warnAndNotify } from '../../../Honeybadger';
import {
  JWPlayerFn,
  JWPlayerInitializationError,
  JWPlayerObj,
  MetaEvent,
  SourceFile,
} from '../../JWPlayer/Types';
import { loadJWPlayer } from './Loader';
import { VideoPlayerOptions, prepareOptions } from './Types';
import * as style from './style.module.css';

export interface Props {
  id: string;
  className: string;
  kettle: Kettle;
  options: Task<JWPlayerInitializationError, VideoPlayerOptions>;
  fallbackSources?: SourceFile[];
}

class JWPlayer extends React.Component<Props, {}> {
  private container?: HTMLDivElement | null;
  private stopReactions?: IReactionDisposer;

  registerKettleReactions = (playerObj: JWPlayerObj) => {
    const { kettle } = this.props;
    return reaction(
      () => kettle.videoMessage.length,
      (_length: number) => {
        kettle.popMessage().map((msg) =>
          msg.cata({
            play: () => {
              playerObj.play();
            },
            pause: () => {
              playerObj.pause();
            },
            seekTo: (pos) => {
              playerObj.seek(pos);
            },
          }),
        );
      },
    );
  };

  updateKettle = (
    playerObj: JWPlayerObj,
    fn: (pos: Maybe<number>, duration: Maybe<number>) => VideoState,
  ) => {
    const { kettle } = this.props;
    const pos = just(playerObj.getPosition());
    const dur = just(playerObj.getDuration());
    kettle.setVideoState(fn(pos, dur));
  };

  ready = (playerObj: JWPlayerObj) =>
    this.updateKettle(playerObj, (pos, dur) => new Ready(pos, dur));

  handlePlay = (playerObj: JWPlayerObj) => () =>
    this.updateKettle(playerObj, (pos, dur) => new Playing(pos, dur));

  handlePause = (playerObj: JWPlayerObj) => () =>
    this.updateKettle(playerObj, (pos, dur) => new Paused(pos, dur));

  handleEnd = (playerObj: JWPlayerObj) => () =>
    this.updateKettle(playerObj, (pos, dur) => new Ended(pos, dur));

  handleTime = (playerObj: JWPlayerObj) => () => {
    const { kettle } = this.props;
    this.updateKettle(playerObj, (pos, dur) =>
      kettle.videoState.cata({
        playing: () => new Playing(pos, dur),
        paused: () => new Paused(pos, dur),
        ended: () => new Ended(pos, dur),
        buffering: () => new Buffering(pos, dur),
        ready: () => new Ready(pos, dur),
        initialized: () => new Initialized(),
      }),
    );
  };

  handleFallback = (err: string, playerObj: JWPlayerObj, options: VideoPlayerOptions) => {
    warn(`Initial setup failed for ${this.props.id}`, err);
    fromNullable(this.props.fallbackSources)
      .map((sources) => ({
        ...options,
        sources: just(sources),
      }))
      .do((newOptions) => {
        this.setup(playerObj, newOptions);
      })
      .elseDo(() => {
        warnAndNotify(
          'Lecture video failed to load',
          `No fallback sources for ${this.props.id}`,
          this.props,
        );
      });
  };

  setAspectRatio = (playerObj: JWPlayerObj, options: VideoPlayerOptions) => (e: MetaEvent) => {
    const newOptions = { ...options, aspectratio: just(`${e.width}:${e.height}`) };
    this.finalSetup(playerObj, newOptions);
  };

  setup = (playerObj: JWPlayerObj, options: VideoPlayerOptions) => {
    if (options.dynamicallySetAspectRatio) {
      playerObj.setup(prepareOptions(options));
      playerObj.on('ready', () => {
        this.stopReactions = this.registerKettleReactions(playerObj);
        playerObj.on('meta', this.setAspectRatio(playerObj, options));
      });
    } else {
      this.finalSetup(playerObj, options);
    }
  };

  finalSetup = (playerObj: JWPlayerObj, options: VideoPlayerOptions) => {
    playerObj.setup(prepareOptions(options));
    playerObj.on('ready', () => {
      this.stopReactions = this.registerKettleReactions(playerObj);
      this.ready(playerObj);
      playerObj.on('play', this.handlePlay(playerObj));
      playerObj.on('pause', this.handlePause(playerObj));
      playerObj.on('time', this.handleTime(playerObj));
      playerObj.on('seeked', this.handleTime(playerObj));
      playerObj.on('ended', this.handleEnd(playerObj));
      playerObj.on('meta', this.handleTime(playerObj));
      playerObj.on('complete', this.handleEnd(playerObj));
    });
  };

  initializationError = (err: JWPlayerInitializationError): void => {
    switch (err.kind) {
      case 'thumbnail-preview-missing-error':
      case 'low-stream-missing-error':
      case 'medium-stream-missing-error':
      case 'high-stream-missing-error':
      case 'sprite-missing-error':
      case 'missing-link-error':
      case 'subtitle-link-missing-error':
        warnAndNotify('Unexpected Video Load Error', `${err.kind}`, err);
        break;
      case 'missing-var-error':
        warnAndNotify(
          'MissingVarError',
          `Unable to load JWPlayer: Missing environment variable ${err.key}`,
          err,
        );
        break;
      case 'jwplayer-load-error':
        warnAndNotify('JWPlayerLoadError', err.key, err);
        break;
      default:
        assertNever(err);
    }
  };

  initializationSuccess = (options: VideoPlayerOptions, player: JWPlayerFn) => {
    if (!this.container) {
      return;
    }
    const playerObj = player(this.container);
    log(`JWPlayer options for ${this.props.id}`, options);
    this.setup(playerObj, options);

    options.autoLoadIndex.do((index) => playerObj.setCurrentCaptions(index));

    playerObj.on('error', () => {
      this.handleFallback('error', playerObj, options);
    });
    playerObj.on('setupError', () => {
      this.handleFallback('setupError', playerObj, options);
    });
  };

  componentDidMount() {
    Task.succeed<JWPlayerInitializationError, {}>({})
      .assign('options', this.props.options)
      .assign('player', ({ options }) => loadJWPlayer(options.playerUrl, options.playerKey))
      .fork(this.initializationError, ({ options, player }) =>
        this.initializationSuccess(options, player),
      );
  }

  componentWillUnmount() {
    if (window.jwplayer && this.container) {
      window.jwplayer(this.container).remove();
    }

    if (this.stopReactions) {
      this.stopReactions();
    }
  }

  refContainer = (container: HTMLDivElement | null) => {
    this.container = container;
  };

  render() {
    const { id, className } = this.props;
    return (
      <div
        id={id}
        className={`${className} ${style.root}`}
        ref={this.refContainer}
        data-test-jwplayer={id}
      />
    );
  }
}

export default observer(JWPlayer);
