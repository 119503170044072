import { observer } from 'mobx-react';
import * as React from 'react';
import ScheduledToastStore from '../../../ToastsStore/ScheduledToastStore';
import { T, translation, TranslationsContext } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  store: ScheduledToastStore;
}

const DismissButton: React.FC<Props> = ({ store }) => {
  const dismiss = () => {
    store.dismiss();
  };

  return (
    <TranslationsContext.Consumer>
      {(ts) => (
        <button
          className={`${style.button} m-0`}
          onClick={dismiss}
          data-test-toast-dismiss={true}
          aria-label={translation('Dismiss dropdown notification.')(ts)}
        >
          <T kind="Dismiss" />
        </button>
      )}
    </TranslationsContext.Consumer>
  );
};

export default observer(DismissButton);
