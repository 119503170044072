import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../LinkyLinky';
import { Link, Rel } from '../../../Resource/Types';
import ProgramResourcesStore from '../ProgramResourceList/ProgramResourcesStore';
import CrossProgramResourcesStore from './CrossProgramResourcesStore';
import { crossProgramResourcesResourceDecoder } from './CrossProgramResourcesStore/Decoders';
import {
  CrossProgramResourcesResource,
  CrossProgramResourcesState,
} from './CrossProgramResourcesStore/Types';

export interface Props extends EAProps<CrossProgramResourcesStore> {
  store: CrossProgramResourcesStore;
  rel: Rel;
}

type CrossProgramResourcesError = AppyError | MissingLinkError;

const handleProgramResourcesError =
  (store: CrossProgramResourcesStore) => (error: CrossProgramResourcesError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify('ProgramResourcesReactions', `Missing link: ${error.rel}`, toJS(store.state));
        break;
      default:
        handleError(store, error);
    }
  };

const initializeProgramStores =
  (store: CrossProgramResourcesStore) =>
  (crossProgramResourcesResource: CrossProgramResourcesResource) => {
    const programStores = crossProgramResourcesResource.payload.map((programResource) => {
      const store = new ProgramResourcesStore();
      store.ready(programResource);
      return store;
    });
    store.ready(programStores);
  };

class CrossProgramResourcesReactions extends ErrorActionableReaction<
  CrossProgramResourcesStore,
  CrossProgramResourcesState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: CrossProgramResourcesState) => {
    const { store } = this.props;

    switch (state.kind) {
      case 'loading':
        Task.succeed<CrossProgramResourcesError, ReadonlyArray<Link>>(state.links)
          .andThen(findLinkT(this.props.rel))
          .andThen(callApi(crossProgramResourcesResourceDecoder, {}))
          .fork(handleProgramResourcesError(store), initializeProgramStores(store));
        break;
      case 'ready':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default CrossProgramResourcesReactions;
