import { NonEmptyList } from 'nonempty-list';
import { Maybe, just, nothing } from 'maybeasy';
import { mapMaybe } from '@execonline-inc/collections';
import { LocalizationFormat, Localizeable } from '@execonline-inc/translations';
import { AvailabilityResource } from '../../../../Common/Experience/Types';

export type ScheduledOption = {
  label: string;
  value: AvailabilityResource;
};

const getAvailabilityOption =
  (localize: (localizeable: Localizeable, format: LocalizationFormat) => string) =>
  (availability: AvailabilityResource): Maybe<ScheduledOption> => {
    switch (availability.payload.kind) {
      case 'on-demand':
        return nothing();
      case 'scheduled':
        return just({
          label: localize(availability.payload.date, 'long-weekday-month-year'),
          value: availability,
        });
    }
  };

export const getScheduledOptions = (
  availabilities: NonEmptyList<AvailabilityResource>,
  localize: (localizeable: Localizeable, format: LocalizationFormat) => string,
): ReadonlyArray<ScheduledOption> => {
  return mapMaybe(getAvailabilityOption(localize), availabilities.toArray());
};

export const getSelectedScheduledOption = (
  availability: Maybe<AvailabilityResource>,
  localize: (localizeable: Localizeable, format: LocalizationFormat) => string,
): string => {
  return availability
    .andThen(getAvailabilityOption(localize))
    .map((option) => option.label)
    .getOrElseValue('');
};
