import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const MenuCircleIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 21 21">
      <g fill="none">
        <circle cx="10.5" cy="10.5" r="10.5" fill="#666" />
        <path
          fill="#FFF"
          d="M5.513 9.1a1.312 1.312 0 1 0-.002 2.623A1.312 1.312 0 0 0 5.513 9.1Zm4.987 0a1.312 1.312 0 1 0-.002 2.623A1.312 1.312 0 0 0 10.5 9.1Zm4.988 0a1.312 1.312 0 1 0-.002 2.623 1.312 1.312 0 0 0 .002-2.623Z"
        />
      </g>
    </SVGIcon>
  );
};

export default observer(MenuCircleIcon);
