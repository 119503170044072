import { observer } from 'mobx-react';
import { CurrentUserResource } from '../CurrentUser/Types';
import Loader from '../components/Loader';
import AnnouncementsSection from './AnnouncementsSection';
import { useAnnouncementsLoading } from './UseAnnouncementsLoading';

interface AnnouncementsProps {
  currentUserResource: CurrentUserResource;
}

function Announcements({ currentUserResource }: AnnouncementsProps) {
  const { state, loadMoreAnnouncements } = useAnnouncementsLoading({
    perPage: 5,
    links: currentUserResource.links,
  });

  switch (state.kind) {
    case 'initial-load':
      return <Loader />;
    case 'some-announcements-loaded':
    case 'all-announcements-loaded':
    case 'loading-more-announcements':
    case 'loading-more-failed':
      return <AnnouncementsSection state={state} onLoadMore={loadMoreAnnouncements} />;
    case 'initial-load-failed':
      return <></>;
  }
}

export default observer(Announcements);
