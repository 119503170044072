import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import SurveyModalStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import clickOutsideStore from '../../../../ClickOutsideStore';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { surveyCompletionTrackingResourceDecoder } from '../../../../VideoStore/Legacy/Decoders';
import {
  SurveyCompletionTrackingResource,
  SurveyStatus,
  TopicViewStatus,
} from '../../../ReqHlsVideo/Types';
import { State } from './Types';

interface Props extends RCProps<SurveyModalStore> {
  kettle: Maybe<Kettle>;
}

interface EncodedSurveyTracking {
  topic_view_status?: TopicViewStatus;
  survey_status: SurveyStatus;
}

const surveyTrackingEncoder = (
  topicViewStatus: Maybe<TopicViewStatus>,
  surveyStatus: SurveyStatus,
): EncodedSurveyTracking =>
  withConditionalFields(topicViewStatus, {
    survey_status: surveyStatus,
  });

const withConditionalFields = (
  topicViewStatus: Maybe<TopicViewStatus>,
  encodedSurveyTracking: EncodedSurveyTracking,
): EncodedSurveyTracking => {
  return topicViewStatus.cata({
    Just: (topicViewStatus: TopicViewStatus) => {
      encodedSurveyTracking.topic_view_status = topicViewStatus;
      return encodedSurveyTracking;
    },
    Nothing: () => encodedSurveyTracking,
  });
};

type SurveyTrackingResourceReactionError = MissingLinkError | AppyError;

const handleRequestError = (err: SurveyTrackingResourceReactionError): void =>
  warn('VPFAlumniSurveyTrackingError', ` ${err.kind}`, err);

const handleSurveyUndoDismiss = (
  surveyTrackingResource: SurveyCompletionTrackingResource,
  store: SurveyModalStore,
) => {
  switch (surveyTrackingResource.payload.launchPosition) {
    case 'launch-at-start':
      store.waiting(surveyTrackingResource);
      store.open(nothing());
      break;
    case 'never-launch':
    case 'pause-launch':
    case 'launch-at-end':
      break;
  }
};

class SurveyModalReactions extends ReactionComponent<SurveyModalStore, State, Props> {
  componentWillUnmount() {
    clickOutsideStore.enable();
  }

  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store, kettle } = this.props;
    switch (state.kind) {
      case 'showing-survey':
        kettle.do((k) => k.pause());
        clickOutsideStore.disable();
        state.topicViewStatus.map((_) => {
          Task.succeed<SurveyTrackingResourceReactionError, ReadonlyArray<Link>>(store.links)
            .andThen(findLinkT('self'))
            .andThen(
              callApi(surveyCompletionTrackingResourceDecoder, {
                survey_completion_tracking: surveyTrackingEncoder(state.topicViewStatus, 'pending'),
              }),
            )
            .fork(handleRequestError, store.exit);
        });
        break;
      case 'survey-closed-and-complete':
        clickOutsideStore.enable();
        Task.succeed<SurveyTrackingResourceReactionError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('self'))
          .andThen(
            callApi(surveyCompletionTrackingResourceDecoder, {
              survey_completion_tracking: surveyTrackingEncoder(nothing(), 'completed'),
            }),
          )
          .fork(handleRequestError, store.exit);
        break;
      case 'survey-closed-and-dismissed':
        clickOutsideStore.enable();
        Task.succeed<SurveyTrackingResourceReactionError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('self'))
          .andThen(
            callApi(surveyCompletionTrackingResourceDecoder, {
              survey_completion_tracking: surveyTrackingEncoder(nothing(), 'dismissed'),
            }),
          )
          .fork(handleRequestError, store.surveyDismissed);
        break;
      case 'survey-undo-dismiss':
        Task.succeed<SurveyTrackingResourceReactionError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('self'))
          .andThen(
            callApi(surveyCompletionTrackingResourceDecoder, {
              survey_completion_tracking: surveyTrackingEncoder(nothing(), 'pending'),
            }),
          )
          .fork(handleRequestError, (surveyTrackingResource: SurveyCompletionTrackingResource) =>
            handleSurveyUndoDismiss(surveyTrackingResource, store),
          );
        break;
      case 'never-show-survey':
      case 'survey-dismissed':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default SurveyModalReactions;
