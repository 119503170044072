import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import LastReadMessageStore from '.';
import { AppyError, putToApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { LastReadMessageState } from './Types';

export interface Props extends EAProps<LastReadMessageStore> {
  store: LastReadMessageStore;
}

type LastReadMessageReactionsError = AppyError | MissingLinkError;

const handleMessagesError =
  (store: LastReadMessageStore) => (error: LastReadMessageReactionsError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Messages are not available');
        break;
      default:
        handleError(store, error);
    }
  };

class LastReadMessageAPIReactions extends ErrorActionableReaction<
  LastReadMessageStore,
  LastReadMessageState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: LastReadMessageState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'ready':
      case 'error':
        break;
      case 'last-seen-message':
        Task.succeed<LastReadMessageReactionsError, ReadonlyArray<Link>>(state.message.links)
          .andThen(findLinkT('last-read-message'))
          .andThen(putToApi({}))
          .fork(handleMessagesError(store), () => store.ready());
        break;
      default:
        assertNever(state);
    }
  };
}

export default LastReadMessageAPIReactions;
