import { readVarT } from '@execonline-inc/environment';
import { toTask } from '@execonline-inc/maybe-adapter';
import {
  loadStripe,
  PaymentIntent,
  PaymentIntentResult,
  Stripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { fromNullable, Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import {
  CommerceLoadingError,
  PaymentIntentResource,
} from '../../../DiscoveryPortal/Enrollment/Store/Types';
import {
  ClientSecret,
  PaymentIntentResourceKey,
  PaymentParams,
  StripePaymentIntentId,
} from './Types';

const baseTheme: 'none' | 'flat' | 'stripe' | 'night' | undefined = 'flat';

const asStripeTask: <T>(t: Maybe<T>) => Task<CommerceLoadingError, T> = toTask({
  kind: 'stripe-loading-error',
});

const fetchClientSecret = (
  resource: PaymentIntentResource,
): Task<CommerceLoadingError, ClientSecret> => asStripeTask(resource.payload.clientSecret);

const fetchStripePaymentIntentId = (
  resource: PaymentIntentResource,
): Task<CommerceLoadingError, StripePaymentIntentId> =>
  asStripeTask(resource.payload.stripePaymentIntentId);

const retrievePayment = (
  stripe: Stripe,
  clientSecret: ClientSecret,
): Task<CommerceLoadingError, PaymentIntentResult> =>
  Task.fromPromise<CommerceLoadingError, PaymentIntentResult>(() =>
    stripe.retrievePaymentIntent(clientSecret),
  );

const succeedWithStripe = (stripe: Stripe): Task<CommerceLoadingError, Stripe> =>
  Task.succeed<CommerceLoadingError, Stripe>(stripe);

const failStripeLoad: Task<CommerceLoadingError, Stripe> = Task.fail<CommerceLoadingError, Stripe>({
  kind: 'stripe-loading-error',
});

const validatePaymentIntentId =
  (stripePaymentIntentId: StripePaymentIntentId, stripe: Stripe) => (intent: PaymentIntent) =>
    intent.id === stripePaymentIntentId ? succeedWithStripe(stripe) : failStripeLoad;

const handlePaymentResponse =
  (stripe: Stripe, stripePaymentIntentId: StripePaymentIntentId) =>
  (response: PaymentIntentResult) =>
    asStripeTask(fromNullable(response.paymentIntent)).andThen(
      validatePaymentIntentId(stripePaymentIntentId, stripe),
    );

const verifyClientSecret =
  (stripePaymentIntentId: StripePaymentIntentId, clientSecret: ClientSecret) =>
  (stripe: Stripe): Task<CommerceLoadingError, Stripe> =>
    retrievePayment(stripe, clientSecret)
      .andThen(handlePaymentResponse(stripe, stripePaymentIntentId))
      .orElse(() => failStripeLoad);

const load = (publishableKey: string): Task<CommerceLoadingError, Stripe | null> =>
  Task.fromPromise<CommerceLoadingError, Stripe | null>(() => loadStripe(publishableKey));

const checkNull = (stripe: Stripe | null): Task<CommerceLoadingError, Stripe> =>
  asStripeTask(fromNullable(stripe));

const fetchApiKey = (): Task<CommerceLoadingError, string> =>
  readVarT('REACT_APP_STRIPE_PUBLISHABLE_KEY');

const loadAndVerify = ({
  stripePaymentIntentId,
  clientSecret,
}: PaymentParams): Task<CommerceLoadingError, Stripe> =>
  fetchApiKey()
    .andThen(load)
    .andThen(checkNull)
    .andThen(verifyClientSecret(stripePaymentIntentId, clientSecret));

export const loadAndValidateStripe = ({ paymentIntentResource }: PaymentIntentResourceKey) =>
  Task.succeed<CommerceLoadingError, {}>({})
    .assign('clientSecret', fetchClientSecret(paymentIntentResource))
    .assign('stripePaymentIntentId', fetchStripePaymentIntentId(paymentIntentResource))
    .andThen(loadAndVerify);

export const loadOptions = (
  paymentIntentResource: Maybe<PaymentIntentResource>,
): Maybe<StripeElementsOptions> =>
  paymentIntentResource
    .andThen((paymentIntentResource) => paymentIntentResource.payload.clientSecret)
    .map((secret) => {
      return {
        clientSecret: secret,
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap',
          },
        ],
        appearance: {
          theme: baseTheme,
          variables: {
            fontFamily: 'Open Sans',
            spacingUnit: '4px',
            borderRadius: '6px',
            fontSizeBase: '14px',
            gridRowSpacing: '24px',
            colorBackground: '#FFF',
            colorDanger: '#FF5654',
            colorDangerText: '#FF5654',
          },
          rules: {
            '.Label': {
              fontSize: '14px',
              marginBottom: '8px',
              fontWeight: '500',
              letterSpacing: '0.2px',
            },
            '.Error': {
              paddingLeft: '2px',
              paddingTop: '6px',
            },
            '.Input': {
              border: '1px solid rgba(22, 48, 75, 0.20)',
              padding: '8px 16px',
              letterSpacing: '0.2px',
              lineHeight: '24px',
            },
            '.Input::placeholder': {},
            '.Input:focus': {},
            '.Input--invalid': {},
            '.CheckboxInput': {
              border: '1px solid #E5E7EB',
            },
            '.CheckboxInput--checked': {
              backgroundColor: '#187a90',
            },
            '.CheckboxLabel': {},
          },
        },
      };
    });
