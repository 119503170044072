import { assertNever } from '@kofno/piper';
import { array } from 'jsonous';
import { Maybe } from 'maybeasy';
import { getFieldValue } from '../../../../../FormFields';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import { FormFieldOutputValue, SelectFieldValue } from '../../../Types';
import { State } from '../Store/Types';
import RadioFieldStore from '../Store';
import { selectValueDecoder } from '../../../Decoders';
import { asMaybe } from '@execonline-inc/error-handling';

interface Props extends RCProps<RadioFieldStore> {}

const validateValue = (value: FormFieldOutputValue): Maybe<SelectFieldValue[]> =>
  asMaybe(array(selectValueDecoder).decodeAny(value));

class RadioFieldReactions extends ReactionComponent<RadioFieldStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'initializing':
        state.segmentStore.addEmbeddedFormFieldOutput(store);
        const value = getFieldValue(state.segmentStore.results, store.id)
          .andThen(validateValue)
          .getOrElseValue([]);
        store.ready(state.formField, value);
        break;
      case 'waiting':
      case 'ready':
      case 'auto-saving':
        break;
      default:
        assertNever(state);
    }
  };
}

export default RadioFieldReactions;
