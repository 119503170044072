import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { resetPasswordRoute } from '../../../ClientRoutes';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import { T } from '../../../Translations';
import Label from '../../Form/Label';
import * as style from './style.module.css';

const PasswordReset: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (currentUserResource.payload.assertionBackend.payload.kind) {
        case 'password-backend':
          return (
            <div className={style.labelAndInput}>
              <Label className={style.label} labelText="Password" />
              <Link
                data-test-update-password-link={true}
                className={style.updatePassword}
                to={resetPasswordRoute}
              >
                <T kind="update my password" />
              </Link>
            </div>
          );
        case 'third-party-backend':
        case 'locked-access':
          return <></>;
      }
    }}
  />
);

export default observer(PasswordReset);
