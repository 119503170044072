import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LanguageResource, LanguagesResource } from '../../../ProfileFormStore/Types';
import { Button, Typography } from '../../UI';
import { Separator } from '../Types';

interface Props {
  languages: LanguagesResource;
  separator: Separator;
  onSelect: (languageResource: LanguageResource) => void;
}

const ClassicLanguageLinks: React.FC<Props> = ({ languages, separator, onSelect }) => (
  <>
    {languages.payload.map((language, index) => (
      <React.Fragment key={language.payload.code}>
        {whenSeparatorRequired(index)
          .map(() => (
            <Typography className="mx-2 leading-none" component="span">
              {separator}
            </Typography>
          ))
          .getOrElse(emptyFragment)}
        <Button
          style={{ color: 'var(--accent)' }}
          className="!p-0"
          variant="text"
          onClick={() => onSelect(language)}
        >
          <Typography
            className="capitalize underline hover:!text-inherit"
            variant="body2"
            fontWeight="bold"
            component="a"
          >
            {language.payload.localName}
          </Typography>
        </Button>
      </React.Fragment>
    ))}
  </>
);

export default observer(ClassicLanguageLinks);

const whenSeparatorRequired = (index: number): Maybe<number> => when(index !== 0, index);
