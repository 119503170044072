import { observer } from 'mobx-react';
import { MenuItem } from '@execonline-inc/execonline-ui';
import { DropdownListItem } from '../../../../Dropdown/Types';
import ApplicationMenuItem from './ApplicationMenuItem';
import LinkMenuItem from './LinkMenuItem';
import UseCaseMenuItem from './UseCaseMenuItem';

interface Props {
  items: readonly DropdownListItem[];
}

function NavigationMenuItems({ items }: Props) {
  return (
    <>
      {items.map((item) => {
        switch (item.kind) {
          case 'action-list-item':
            return <MenuItem />;
          case 'link-list-item':
            return <LinkMenuItem key={item.href} item={item} />;
          case 'icon-list-item':
            return <MenuItem />;
          case 'waffle-icon-list-item':
            return <ApplicationMenuItem key={item.href} item={item} />;
          case 'use-case-item':
            return <UseCaseMenuItem key={item.name.text} item={item} />;
        }
      })}
    </>
  );
}

export default observer(NavigationMenuItems);
