import { observer } from 'mobx-react';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import Anchor from '../../../LinkyLinky/Anchor';
import When from '../../../When';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import * as style from '../style.module.css';

interface Props extends Omit<ComponentProps<typeof Anchor>, 'ref'> {
  external: boolean;
  to: string;
  label: React.ReactElement;
}

const DefaultChildren = observer(({ label }: Pick<Props, 'label'>) => (
  <>
    {label}
    <div className={style.buttonIcon}>
      <ArrowRightIcon />
    </div>
  </>
));

function ActionLink({ external, to, label, children, ...linkProps }: Props) {
  const content = children || <DefaultChildren label={label} />;
  return (
    <When predicate={external} otherwise={() => <Link to={to} children={content} {...linkProps} />}>
      <Anchor href={to} children={content} {...linkProps} />
    </When>
  );
}

export default observer(ActionLink);
