import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import handRaisers from '../../../HandRaisers';
import { findLink } from '../../../LinkyLinky';
import RaiseHandIcon from '../../Cutesies/RaiseHandIcon';
import * as style from './style.module.css';

interface Props {
  id: number;
}

const coachHandIcon = (id: number): Maybe<JSX.Element> => {
  return just(handRaisers.conferenceRoomLinks)
    .andThen(findLink('other-hand-down'))
    .andThen(() => handRaisers.whenHandRaised(id))
    .map((handRaiser) => (
      <div onClick={handRaiser.lowerOtherUserHand} className={style.handIconPointer}>
        <RaiseHandIcon fill="#000000" />
      </div>
    ));
};

const regularHandIcon = (id: number): Maybe<JSX.Element> => {
  return handRaisers.whenHandRaised(id).map(() => (
    <div className={style.handIcon}>
      <RaiseHandIcon fill="#000000" />
    </div>
  ));
};

const HandRaise: React.FC<Props> = ({ id }) => {
  return coachHandIcon(id)
    .orElse(() => regularHandIcon(id))
    .getOrElse(() => <></>);
};

export default observer(HandRaise);
