import { readVarM } from '@execonline-inc/environment';
import { configureHoneybadger as _configureHoneybadger } from '@execonline-inc/error-reporting.private';

export { Honeybadger, warnAndNotify } from '@execonline-inc/error-reporting.private';

export const configureHoneybadger = () =>
  _configureHoneybadger({
    apiKey: readVarM('REACT_APP_HB_KEY'),
    environment: readVarM('REACT_APP_ENV'),
    revision: readVarM('REACT_APP_VERSION'),
  });
