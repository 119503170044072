import * as links from '@execonline-inc/links.private';
import { pick } from '@kofno/piper';
import type Decoder from 'jsonous';
import { Maybe } from 'maybeasy';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import { decodedBy, fetchWithDecoder, fetchWithoutDecoder, toHeaders } from './Core';
import { Config, ForRequest, RequestOptions } from './Types';

export type * from './Errors';
export type * from './Types';
export { decodedBy, toHeaders };

type LinksModule<Rel extends string, Endpoint extends string> = ReturnType<
  typeof links.initialize<Rel, Endpoint>
>;

const type = 'application/json' as const;
type Type = typeof type;

export const initialize = <Rel extends string, Endpoint extends string>(
  LinksModule: LinksModule<Rel, Endpoint>,
  config: Config,
) => {
  type Links = ReadonlyArray<links.Link<Rel, Endpoint | undefined>>;

  type Found<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  > =
    Extract<L[number], links.LinkIdentity<R, E, M, Type>> extends never
      ? L[number] & links.Link<R, E, M, Type>
      : Extract<L[number], links.LinkIdentity<R, E, M, Type>>;

  const _fetchWithoutDecoder = (req: Omit<ForRequest, 'config'>) =>
    fetchWithoutDecoder({ ...req, config });

  const withoutDecoder = {
    fetch: _fetchWithoutDecoder,

    get: (href: string, options?: Omit<RequestOptions, 'body'>) =>
      _fetchWithoutDecoder({ method: 'get', href, options }),

    head: (href: string, options?: Omit<RequestOptions, 'body'>) =>
      _fetchWithoutDecoder({ method: 'head', href, options }),

    post: (href: string, options?: RequestOptions) =>
      _fetchWithoutDecoder({ method: 'post', href, options }),

    put: (href: string, options?: RequestOptions) =>
      _fetchWithoutDecoder({ method: 'put', href, options }),

    patch: (href: string, options?: RequestOptions) =>
      _fetchWithoutDecoder({ method: 'patch', href, options }),

    options: (href: string, options?: RequestOptions) =>
      _fetchWithoutDecoder({ method: 'options', href, options }),

    delete: (href: string, options?: RequestOptions) =>
      _fetchWithoutDecoder({ method: 'delete', href, options }),
  };

  const _fetchWithDecoder = <T>(req: Omit<ForRequest, 'config'>, decoder: Decoder<T>) =>
    fetchWithDecoder({ ...req, config }, decoder);

  const withDecoder = {
    fetch: _fetchWithDecoder,

    get: <T>(href: string, decoder: Decoder<T>, options?: Omit<RequestOptions, 'body'>) =>
      _fetchWithDecoder({ method: 'get', href, options }, decoder),

    head: <T>(href: string, decoder: Decoder<T>, options?: Omit<RequestOptions, 'body'>) =>
      _fetchWithDecoder({ method: 'head', href, options }, decoder),

    post: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      _fetchWithDecoder({ method: 'post', href, options }, decoder),

    put: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      _fetchWithDecoder({ method: 'put', href, options }, decoder),

    patch: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      _fetchWithDecoder({ method: 'patch', href, options }, decoder),

    options: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      _fetchWithDecoder({ method: 'options', href, options }, decoder),

    delete: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      _fetchWithDecoder({ method: 'delete', href, options }, decoder),
  };

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>): (list: L) => Maybe<Found<L, R, E, M>>;

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>, list: L): Maybe<Found<L, R, E, M>>;

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >({ rel, endpoint, method }: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>, list?: L) {
    const exec = (list: L): Maybe<Found<L, R, E, M>> =>
      LinksModule.findLinkBy({ rel, endpoint, method, type }, list);

    return typeof list === 'undefined' ? exec : exec(list);
  }

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(
    props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>,
  ): (list: L) => Result<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>>;

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(
    props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>,
    list: L,
  ): Result<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>>;

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >({ rel, endpoint, method }: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>, list?: L) {
    const exec = (list: L): Result<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>> =>
      LinksModule.findLinkByR({ rel, endpoint, method, type }, list);

    return typeof list === 'undefined' ? exec : exec(list);
  }

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(
    props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>,
  ): (list: L) => Task<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>>;

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >(
    props: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>,
    list: L,
  ): Task<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>>;

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends links.Method = links.Method,
  >({ rel, endpoint, method }: Omit<links.LinkIdentity<R, E, M, Type>, 'type'>, list?: L) {
    const exec = (list: L): Task<links.MissingLinkError<R, E, M, Type>, Found<L, R, E, M>> =>
      LinksModule.findLinkByT({ rel, endpoint, method, type }, list);

    return typeof list === 'undefined' ? exec : exec(list);
  }

  return {
    config,

    withoutDecoder,
    withDecoder,

    findLinkBy,
    findLinkByR,
    findLinkByT,

    get: <T>(href: string, decoder: Decoder<T>, options?: Omit<RequestOptions, 'body'>) =>
      withDecoder.get(href, decoder, options).map(pick('data')),

    head: <T>(href: string, decoder: Decoder<T>, options?: Omit<RequestOptions, 'body'>) =>
      withDecoder.head(href, decoder, options).map(pick('data')),

    post: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      withDecoder.post(href, decoder, options).map(pick('data')),

    put: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      withDecoder.put(href, decoder, options).map(pick('data')),

    patch: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      withDecoder.patch(href, decoder, options).map(pick('data')),

    options: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      withDecoder.options(href, decoder, options).map(pick('data')),

    delete: <T>(href: string, decoder: Decoder<T>, options?: RequestOptions) =>
      withDecoder.delete(href, decoder, options).map(pick('data')),
  } as const;
};
