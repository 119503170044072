import { Kettle } from 'kettle-corn';
import ErrorActionableReaction, { EAProps } from '../../../../ErrorActionableReaction';
import SegmentStore from '../../../../SegmentStore';
import { Segment } from '../../../../SegmentStore/Types';

export interface Props extends EAProps<SegmentStore> {
  store: SegmentStore;
  kettle: Kettle;
  segment: Segment;
}

const withinCompletionRange = (kettle: Kettle): boolean => {
  return kettle.videoState.duration
    .andThen(duration =>
      kettle.videoState.position.map(pos => (duration > 0 && duration - pos <= 10 ? true : false))
    )
    .getOrElseValue(false);
};

class LectureVideoReactions extends ErrorActionableReaction<SegmentStore, boolean, Props> {
  tester = () => withinCompletionRange(this.props.kettle);

  effect = () => {
    const { store, segment } = this.props;

    segment.status.cata({
      Just: value => {
        if (value !== 'Complete') {
          store.complete();
        }
      },
      Nothing: () => {
        store.complete();
      },
    });
  };
}

export default LectureVideoReactions;
