import * as React from 'react';
import { observer } from 'mobx-react';
import TeamMember from './TeamMember';
import { TeamResource } from '../../../../TeamsStore/Types';
import { Link } from 'react-router-dom';
import * as style from '../../style.module.css';
import TeamStore from '../../../../TeamStore';
import { just } from 'maybeasy';

interface Props {
  teamResource: TeamResource;
}

class TeamMembers extends React.Component<Props> {
  store = new TeamStore();

  componentDidMount() {
    this.store.ready(this.props.teamResource);
  }

  render() {
    return (
      <div data-test-team-members={true}>
        {this.store.truncatedTeamMembers.map(teamMemberResource => (
          <TeamMember key={teamMemberResource.payload.id} teamMemberResource={teamMemberResource} />
        ))}
        {just({})
          .assign('count', this.store.overflowTeamMemberCount)
          .assign('teamResource', this.store.team)
          .map(({ count, teamResource }) => (
            <span className={style.overflowLink}>
              <Link
                to={`/teams/${teamResource.payload.id}/team-members`}
                data-test-team-link="Team"
              >
                {`+${count}`}
              </Link>
            </span>
          ))
          .getOrElseValue(<></>)}
      </div>
    );
  }
}

export default observer(TeamMembers);
