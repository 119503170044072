import { observer } from 'mobx-react';
import * as React from 'react';
import DynamicHeading from '../../../Accessibility/DynamicHeading';
import ExpandableItemStore, { ExpandableItem } from '../../../ExpandableItemStore';
import { T } from '../../../Translations';
import * as style from '../style.module.css';
import ProgramResourcesModule from './ProgramResourcesModule';
import ProgramResourcesSegments from './ProgramResourcesSegments';
import ProgramResourcesStore from './ProgramResourcesStore';
import { ProgramResourcesModule as ProgramResourcesModuleType } from './ProgramResourcesStore/Types';

interface Props {
  store: ProgramResourcesStore;
  headingLevel: Heading;
}

type Heading = 2 | 3;
type Subheading = 3 | 4;

class ProgramResourcesModules extends React.Component<Props> {
  expandableItemStore: ExpandableItemStore<ProgramResourcesModuleType> = new ExpandableItemStore();

  toggle = (item: ExpandableItem<ProgramResourcesModuleType>) => {
    this.expandableItemStore.toggle(item);
  };

  setExpandableItems = (items: ExpandableItem<ProgramResourcesModuleType>[]) => {
    this.expandableItemStore.setExpandableItems(items);
  };

  componentDidMount() {
    this.props.store.programResourcesResource.map((resource) =>
      this.setExpandableItems(resource.payload.modules.map((m) => ({ item: m, open: false })))
    );
  }

  render() {
    const { store, headingLevel } = this.props;

    const subheadingLevel = (): Subheading => {
      switch (headingLevel) {
        case 2:
          return 3;
        case 3:
          return 4;
      }
    };

    return (
      <>
        <DynamicHeading level={headingLevel} className={style.header}>
          <T kind="Modules" />
        </DynamicHeading>
        {this.expandableItemStore.expandableItems.map((expandableModule) => (
          <ProgramResourcesModule
            key={expandableModule.item.id}
            store={store}
            expandableModule={expandableModule}
            toggle={this.toggle}
            headingLevel={subheadingLevel()}
          >
            <ProgramResourcesSegments store={store} module={expandableModule.item} />
          </ProgramResourcesModule>
        ))}
      </>
    );
  }
}

export default observer(ProgramResourcesModules);
