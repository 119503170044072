import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as avatarStyle from '../../Person/style.module.css';

interface Props {
  name: string;
}

const SelectedCoachName: React.FC<Props> = ({ name }) => (
  <div className={avatarStyle.details}>
    <NotTranslated text={name} />
  </div>
);

export default observer(SelectedCoachName);
