import { UseFetch } from '@execonline-inc/react-hooks.private';
import Links from '../Links/Init';
import Resourceable from '../Resourceable/Init';
import Fetch from './Init';

export const { findLinkBy, findLinkByR, findLinkByT } = Fetch;

export const {
  useFindLinkBy,
  useFindLinkByR,
  useFindLinkByT,
  useFetch,
  useFetchResponse,
  useFetchCore,
  useFetchResource,
} = UseFetch.initialize(Links, Fetch, Resourceable);
