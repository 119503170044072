import { observer } from 'mobx-react';
import { whenZero } from '@execonline-inc/numbers';
import { GroupCoachingProductDetails } from '../../../../../Common/Experience/Types';
import NoAvailabilityButton from './NoAvailabilityButton';
import GetStartedButton from './GetStartedButton';
import GroupCoachingSeatsRemaining from './GroupCoachingSeatsRemaining';

interface Props {
  productDetails: GroupCoachingProductDetails;
}

function EnrollableAction({ productDetails }: Props) {
  const { remainingSeats } = productDetails;

  return (
    <div className="mt-20 flex w-full flex-wrap-reverse items-center gap-4 sm:mt-10">
      {remainingSeats
        .andThen(whenZero)
        .map(() => <NoAvailabilityButton />)
        .getOrElse(() => (
          <GetStartedButton />
        ))}
      <GroupCoachingSeatsRemaining count={remainingSeats} />
    </div>
  );
}

export default observer(EnrollableAction);
