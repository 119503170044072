import { observer } from 'mobx-react';
import { now } from 'mobx-utils';
import * as React from 'react';
import { ok } from 'resulty';
import { eventConferenceUrl } from '../../../ClientRoutes';
import { between, dateRange, everyMinute } from '../../../Date';
import CalendarEventStore from '../../../EventsStore/CalendarEventStore';
import { EventResource, VideoEvent } from '../../../EventsStore/Types';
import { warnAndNotify } from '../../../Honeybadger';
import { findLink } from '../../../LinkyLinky';
import { distanceOfTimeInWords } from '../../../Time';
import { L, NotTranslated, T } from '../../../Translations';
import * as style from '../style.module.css';
import ActionLink from './ActionLink';
import PendingActionLabel from './PendingActionLabel';

interface Props {
  store: CalendarEventStore;
}

function toLabelText(event: VideoEvent) {
  switch (event.actionLabel.kind) {
    case 'custom-action-label':
      return <NotTranslated text={event.actionLabel.customLabel} />;
    case 'default-action-label':
      return <T kind={event.actionLabel.defaultLabel} />;
  }
}

const inactionLabel = (event: VideoEvent, actionLabel: (theLabel: JSX.Element) => JSX.Element) => {
  switch (event.kind) {
    case 'current-coaching-event':
      return actionLabel(toLabelText(event));
    case 'upcoming-event':
      return (
        <div className={style.actionPending} data-test-action-pending={true}>
          <T
            kind="Your event starts in <expectedDelay/> at <time/>. Please come back at that time."
            expectedDelay={<T {...distanceOfTimeInWords(new Date(now()), event.startAt)} />}
            time={<L localizeable={event.startAt} format="time-of-day" />}
          />
        </div>
      );
    case 'live-event':
      return (
        <div className={style.actionPending} data-test-action-pending={true}>
          <T kind="The link for this meeting will be available shortly before the start time." />
        </div>
      );
  }
};

const whenActionable = (event: VideoEvent, actionLabel: (theLabel: JSX.Element) => JSX.Element) => {
  return dateRange(event.notificationsActiveAt, event.endAt)
    .map(between(everyMinute.get()))
    .orElse((err) => {
      warnAndNotify('ActionLabel', err.message, event);
      return ok(false);
    })
    .map((actionable) =>
      actionable ? actionLabel(toLabelText(event)) : inactionLabel(event, actionLabel),
    )
    .getOrElse(() => <></>);
};

const eventLabel = (event: VideoEvent, eventResource: EventResource) => {
  return whenActionable(event, (theLabel: JSX.Element) =>
    eventResource.payload.conferenceRoom
      .map((conferenceRoomResource) => {
        const conferenceRoom = conferenceRoomResource.payload;
        switch (conferenceRoom.kind) {
          case 'daily-live-meeting':
            return actionLink(eventConferenceUrl(event.id), false, theLabel);
          case 'gtw-live-meeting':
            return findLink('external-conference-url', conferenceRoomResource.links)
              .map((link) => actionLink(link.href, true, theLabel))
              .getOrElse(() => <PendingActionLabel />);
          case 'zoom-live-meeting':
            return findLink('external-conference-url', conferenceRoomResource.links)
              .map((link) => actionLink(link.href, true, theLabel))
              .getOrElse(() => <PendingActionLabel />);
          case 'external-live-meeting':
            return findLink('external-conference-url', conferenceRoomResource.links)
              .map((link) => actionLink(link.href, true, theLabel))
              .getOrElse(() => <></>);
        }
      })
      .getOrElse(() => <></>),
  );
};

const actionLink = (to: string, external: boolean, theLabel: JSX.Element) => (
  <ActionLink
    to={to}
    external={external}
    label={theLabel}
    className={style.buttonCallToAction}
    data-test-action-link={theLabel}
  />
);

const ActionLabel: React.FC<Props> = ({ store }) => {
  const event = store.event;
  switch (event.kind) {
    case 'other-event':
    case 'past-event':
      return <></>;
    case 'upcoming-event':
    case 'current-coaching-event':
    case 'live-event':
      return <div className={style.actions}>{eventLabel(event, store.resource)}</div>;
  }
};

export default observer(ActionLabel);
