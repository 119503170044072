import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram } from '../../../../../ProgramStore/Types';
import ModalStore from '../../../../Modal/Store';
import * as style from '../../style.module.css';
import OrientationModalGraphic from '../OrientationModalGraphic';
import ProgramTitle from '../ProgramTitle';
import ResumeButton from '../ResumeButton';
import AccessUntilInfo from './AccessUntilInfo';

interface Props {
  activeProgram: ActiveProgram;
  modalStore: ModalStore;
}

const StartedOrientation: React.FC<Props> = ({ activeProgram, modalStore }) => (
  <div className={style.programTile}>
    <OrientationModalGraphic />
    <div className={style.footer}>
      <div className={style.footerContent}>
        <ProgramTitle title={activeProgram.title} />
        <AccessUntilInfo activeProgram={activeProgram} />
      </div>
      <ResumeButton modalStore={modalStore} />
    </div>
  </div>
);

export default observer(StartedOrientation);
