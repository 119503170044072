export interface Open {
  kind: 'open';
}
interface Closed {
  kind: 'closed';
}

export const open = (): Open => ({ kind: 'open' });
export const closed = (): Closed => ({ kind: 'closed' });

export type State = Open | Closed;
