import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { CarouselPage, pageContentsArray } from '../../../Common/Experience/Carousel';

interface Props<T> {
  page: Maybe<CarouselPage<T>>;
  children: (props: { items: ReadonlyArray<T> }) => ReactNode;
}

function ExperienceList<T>({ page, children }: Props<T>) {
  return (
    <>
      {page
        .map(pageContentsArray)
        .map((items) => children({ items }))
        .getOrElseValue([])}
    </>
  );
}

export default observer(ExperienceList);
