import { observer } from 'mobx-react';
import * as React from 'react';
import Announcements from '../../Announcements';
import Main from '../../BrowserSupport/Main';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { lightTheme } from '../../Native/styles';
import Notifications from '../../Notifications';
import ResumerRedirect from '../../ResumerRedirect';
import ThemeContext from '../../Themes/ThemeContext';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import AutoLaunchable from '../AutoLaunchable';
import MoreExperiences from '../MoreExperiences';
import PendingSurvey from '../PendingSurvey';
import * as platformStyle from '../Platform/style.module.css';
import Support from '../Support';
import AnalyticsEvent from '../Tooling/GoogleTagManagerTooling/AnalyticsEvent';
import ActivePrograms from './ActivePrograms';
import CompletedPrograms from './CompletedPrograms';
import ExpiredPrograms from './ExpiredPrograms';
import FreebusyCoachingAd from './FreebusyCoachingAd';
import FreebusyProgramAd from './FreebusyProgramAd';
import NoProgram from './NoProgram';
import UpcomingPrograms from './UpcomingPrograms';
import * as style from './style.module.css';

const Dashboard: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <>
        <ResumerRedirect currentUserResource={currentUserResource} />
        <I18nTitle title="Dashboard" />
        <div className={platformStyle.container}>
          <Notifications />
          <PendingSurvey currentUserResource={currentUserResource} />
          <AutoLaunchable />
          <AnalyticsEvent
            hitType="event"
            eventCategory="Dashboard"
            eventAction="visit"
            eventLabel="Dashboard Visit"
          />
          {/* Temporary removal of prompt for organizations that can't currently upload photos.
              https://execonline.leankit.com/card/897821821
            currentUserStore.avatarLink.cata({
              Just: _ => <></>,
              Nothing: () =>
                findLink('profile', currentUserStore.links)
                  .map(() => <UploadPhotoPrompt />)
                  .getOrElse(() => <span />),
            })} */}
          <Main id="main">
            <ThemeContext.Provider value={lightTheme}>
              <div className={platformStyle.programs}>
                <h1 className={platformStyle.screenReaderOnly}>
                  <T kind="Dashboard" />
                </h1>
                <ActivePrograms />
                <UpcomingPrograms />
                <CompletedPrograms />
                <ExpiredPrograms />
              </div>
              <div className={style.section}>
                <Announcements currentUserResource={currentUserResource} />
                <NoProgram />
                <div>
                  <FreebusyCoachingAd currentUserResource={currentUserResource} />
                  <FreebusyProgramAd currentUserResource={currentUserResource} />
                  <MoreExperiences />
                  <Support currentUserResource={currentUserResource} />
                </div>
              </div>
            </ThemeContext.Provider>
          </Main>
        </div>
      </>
    )}
  />
);

export default observer(Dashboard);
