import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const CalendarClockIcon: React.FC = () => (
  <SVGIcon style={{ height: 24, width: 24 }} viewBox="0 0 24 24">
    <path
      fill="white"
      d="M3 3.75v16.5h8.25a7.087 7.087 0 0 1-.328-1.5H4.5V8.25h15v2.79a7.081 7.081 0 0 1 1.5.515V3.75H3Zm1.5 1.5h15v1.5h-15v-1.5Zm2.25 5.25V12h1.5v-1.5h-1.5Zm3 0V12h4.43a7.023 7.023 0 0 1 2.32-.96v-.54H9.75ZM18 12c-3.305 0-6 2.695-6 6s2.695 6 6 6 6-2.695 6-6-2.695-6-6-6ZM6.75 13.5V15h1.5v-1.5h-1.5Zm3 0V15h1.805c.252-.54.565-1.043.937-1.5H9.75Zm8.25 0a4.49 4.49 0 0 1 4.5 4.5 4.49 4.49 0 0 1-4.5 4.5 4.49 4.49 0 0 1-4.5-4.5 4.49 4.49 0 0 1 4.5-4.5Zm-.75.75v4.5H21v-1.5h-2.25v-3h-1.5Zm-.773.75h.023c-.006.003-.018-.003-.023 0Z"
    />
  </SVGIcon>
);

export default observer(CalendarClockIcon);
