import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import ModalStore from '../../../../Modal/Store';
import ModalSegment from './ModalSegment';
import ModalWrapping from './ModalWrapping';

interface Props {
  modalStore: ModalStore;
  programStore: ProgramStore;
}

class OrientationModal extends React.Component<Props, {}> {
  render() {
    const { modalStore, programStore } = this.props;

    switch (modalStore.state.kind) {
      case 'open':
        return programStore.currentModule
          .map((module) => (
            <ModalWrapping close={modalStore.close} title={module.title.text}>
              <ModalSegment programStore={programStore} modalStore={modalStore} />
            </ModalWrapping>
          ))
          .getOrElse(() => <></>);
      case 'closed':
        return <></>;
    }
  }
}

export default observer(OrientationModal);
