import {
  BadgeCheckIcon,
  Chip,
  Typography,
  emptyFragment,
  useMediaQuery,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { L, T } from '../../../../../../Translations';
import { UpcomingExperience } from '../../../../Common/Experience/Types';
import { getStartDateVariant } from './Types';

interface Props {
  experience: UpcomingExperience;
}

function UpcomingAction({ experience }: Props) {
  const breakPoint = useMediaQuery();

  return (
    <Chip
      className="mt-20 w-fit gap-x-2 p-3 sm:mt-6 sm:gap-x-4 sm:px-6 sm:py-4"
      color="white"
      radius="xl"
    >
      <BadgeCheckIcon color="teal" size="2xl" />
      <div>
        <Typography variant="bodySmall" color="black">
          <T kind="You are already enrolled." />{' '}
          <T kind="This program starts on <date/>" date={emptyFragment()} />
        </Typography>
        <Typography
          color="black"
          fontWeight="medium"
          variant={getStartDateVariant(breakPoint)}
          as="p"
        >
          <L localizeable={experience.startOn} format="long-weekday-month-year" />
        </Typography>
      </div>
    </Chip>
  );
}

export default observer(UpcomingAction);
