import { LanguagesResource } from '../ProfileFormStore/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { TPlainTextKey } from '../Translations';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  availableLanguages: LanguagesResource;
}

interface Switching {
  kind: 'switching';
  code: SupportedLanguageCode;
}

interface Error {
  kind: 'error';
  message: TPlainTextKey;
}

export const switching = (code: SupportedLanguageCode): Switching => ({
  kind: 'switching',
  code,
});

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (): Loading => ({ kind: 'loading' });

export const ready = (availableLanguages: LanguagesResource): Ready => ({
  kind: 'ready',
  availableLanguages,
});

export type State = Waiting | Loading | Ready | Switching | Error;
