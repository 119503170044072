import { fromBool, toResult } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe, fromEmpty, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ok } from 'resulty';
import { FlashAlert, errorAlert, serverErrorAlert } from '../../../../Notifications/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';
import {
  State,
  created,
  creating,
  error,
  ready,
  readyWithClientErrors,
  readyWithServerErrors,
  serverError,
  waiting,
} from './Types';

class SetPasswordStore {
  @observable
  state: State = waiting();

  @action
  ready = (termsAcceptance: boolean, message: Maybe<TPlainTextKey>) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
        this.state = ready(termsAcceptance, message);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithClientErrors = (termsAcceptance: boolean, message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
        this.state = readyWithClientErrors(termsAcceptance, message, this.state);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithServerErrors = (termsAcceptance: boolean, message: AlreadyTranslatedText) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
        this.state = readyWithServerErrors(termsAcceptance, message, this.state);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setPassword = (password: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
        this.state.password = password;
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setPasswordConfirmation = (passwordConfirmation: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
        this.state.passwordConfirmation = passwordConfirmation;
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTermsAcceptance = (termsAcceptance: boolean) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        this.state.termsAcceptance = termsAcceptance;
        break;
      case 'waiting':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  creating = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
        const { password, passwordConfirmation, termsAcceptance } = this.state;

        ok<TPlainTextKey, string>(password)
          .map(fromEmpty)
          .andThen(toResult("Password can't be blank"))
          .map(fromBool(password === passwordConfirmation))
          .andThen(toResult('Password confirmation must match password'))
          .map(fromBool(termsAcceptance))
          .andThen(toResult('Must accept terms of use and privacy policy'))
          .do((password) => (this.state = creating(password)))
          .elseDo(this.error);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  created = () => {
    switch (this.state.kind) {
      case 'creating':
        this.state = created();
        break;
      case 'waiting':
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        this.state = error(message, this.termsAcceptance, this.state);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  serverError = (message: AlreadyTranslatedText) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        this.state = serverError(message, this.termsAcceptance, this.state.password);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get password(): Maybe<string> {
    switch (this.state.kind) {
      case 'creating':
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        return just(this.state.password);
      case 'waiting':
      case 'created':
        return nothing();
    }
  }

  @computed
  get passwordConfirmation(): Maybe<string> {
    switch (this.state.kind) {
      case 'creating':
        return just(this.state.password);
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        return just(this.state.passwordConfirmation);
      case 'waiting':
      case 'created':
        return nothing();
    }
  }

  @computed
  get termsAcceptance(): boolean {
    switch (this.state.kind) {
      case 'waiting':
        return false;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        return this.state.termsAcceptance;
      case 'creating':
      case 'created':
        return true;
    }
  }

  @computed
  get formDisabled(): boolean {
    switch (this.state.kind) {
      case 'creating':
      case 'waiting':
        return true;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
      case 'created':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'server-error':
        return just(this.state).map(serverErrorAlert);
      case 'ready':
        return this.state.message
          .map((message) => ({ kind: 'error', message }) as const)
          .map(errorAlert);
      case 'ready-with-client-errors':
        return just(errorAlert({ kind: 'error', message: this.state.message }));
      case 'ready-with-server-errors':
        return just(serverErrorAlert({ kind: 'server-error', message: this.state.message }));
      case 'waiting':
      case 'creating':
      case 'created':
        return nothing();
    }
  }
}

export default SetPasswordStore;
