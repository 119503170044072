import { observer } from 'mobx-react';
import * as React from 'react';

interface Props {
  href: string;
}

const ExternalLink: React.FC<Props> = ({ children, href }) => {
  return <a href={href} target="_blank" rel="noreferrer" children={children} />;
};

export default observer(ExternalLink);
