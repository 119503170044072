import { observer } from 'mobx-react';
import { ReactNode } from 'react';
import { OpenableState } from './Types';

interface WithOpenableLogicProps {
  state: OpenableState;
  children: ReactNode;
  whenClosed?: () => ReactNode;
}

function WithOpenableLogic({ state, children, whenClosed }: WithOpenableLogicProps) {
  switch (state.kind) {
    case 'closed':
      return whenClosed ? <>{whenClosed()}</> : <></>;
    case 'open':
      return <>{children}</>;
  }
}

export default observer(WithOpenableLogic);
