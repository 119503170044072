import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { sessionStore } from '../Session/Store';

interface WhenNotImpersonatingProps {
  children: JSX.Element;
}

function WhenNotImpersonating({ children }: WhenNotImpersonatingProps) {
  return sessionStore.impersonatingBy.map(emptyFragment).getOrElse(() => children);
}

export default observer(WhenNotImpersonating);
