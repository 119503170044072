interface Ready {
  kind: 'ready';
}

interface Requested {
  kind: 'requested';
}

export const requested = (): Requested => ({ kind: 'requested' });

export const ready = (): Ready => ({ kind: 'ready' });

export type StaffRequestState = Ready | Requested;
