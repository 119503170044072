import Decoder, { succeed, field, number, string, array } from 'jsonous';
import { EventPoll, EventPollResource, EventPollsResource } from './Types';
import { resourceDecoder } from '../Resource/Decoders';

const eventPollDecoder: Decoder<EventPoll> = succeed({})
  .assign('id', field('id', number))
  .assign('name', field('name', string))
  .assign('url', field('url', string));

const eventPollResourceDcoder: Decoder<EventPollResource> = resourceDecoder(eventPollDecoder);

export const eventPollsResourceDecoder: Decoder<EventPollsResource> = resourceDecoder(
  array(eventPollResourceDcoder)
);
