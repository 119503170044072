import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import StaffPresenceStore from '.';
import { ConferenceParticipant } from '../../../ConferenceRosterStore/Types';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { StaffPresenceState, uniqMembers } from './Types';

interface Props extends RCProps<StaffPresenceStore> {
  store: StaffPresenceStore;
}

const isCoachOrProfessor = (participant: ConferenceParticipant): boolean =>
  participant.participantType === 'Professor' || participant.participantType === 'Coach';

class StaffPresenceReactions extends ReactionComponent<
  StaffPresenceStore,
  StaffPresenceState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: StaffPresenceState) => {
    switch (state.kind) {
      case 'setting-staff-list':
        state.presentParticipants.do((presentParticipants) => {
          const presentStaff = presentParticipants
            .filter(isCoachOrProfessor)
            .map((presentConferenceStaff) => ({
              id: presentConferenceStaff.id,
              shortName: presentConferenceStaff.profile.payload.shortName.getOrElseValue(
                'Staff Member'
              ),
            }));
          this.props.store.presentStaffList.replace(uniqMembers(presentStaff));
        });
        this.props.store.ready();
        break;
      case 'waiting':
      case 'ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(StaffPresenceReactions);
