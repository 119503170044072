import { observer } from 'mobx-react';
import { DigitalCertificateAnnouncement } from '../../Announcements/Decoders/Types';
import { DocumentAssetResource } from '../../SegmentStore/Types';
import { L, NotTranslated, T } from '../../Translations';
import DownArrowIcon from '../Cutesies/DownArrowIcon';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import DocumentIcon from '../Resources/AssignmentResources/DocumentResource/DocumentIcon';
import * as style from '../Resources/style.module.css';
import * as embeddedFileStyle from './style.module.css';

interface EmbeddedDigitalCertificateDocumentProps {
  digitalCertificateAnnouncement: DigitalCertificateAnnouncement;
  announcementSharedResource: DocumentAssetResource;
}

function EmbeddedDigitalCertificateDocument({
  digitalCertificateAnnouncement,
  announcementSharedResource,
}: EmbeddedDigitalCertificateDocumentProps) {
  const { openableState, open, close } = useOpenable();

  return (
    <>
      <div className={style.resource} data-test-document-resource={true}>
        <a onClick={open} data-test-resource-button={true}>
          <div className={style.icon}>
            <DocumentIcon />
          </div>
          <div className={style.details}>
            <h4 className={embeddedFileStyle.name} data-test-resource-title={true}>
              <NotTranslated text={announcementSharedResource.payload.title} />
            </h4>
            <div
              className={style.date}
              data-test-resource-added-on={digitalCertificateAnnouncement.createdAt}
            >
              <T
                kind="Document added on <date/>"
                date={
                  <span className={style.timestamp}>
                    <L
                      localizeable={digitalCertificateAnnouncement.createdAt}
                      format="long-month-day-year"
                    />
                  </span>
                }
              />
            </div>
          </div>
          <div className={embeddedFileStyle.download}>
            <div className={embeddedFileStyle.downloadIcon}>
              <DownArrowIcon />
            </div>
          </div>
        </a>
        <DownloadViewModal
          resource={announcementSharedResource}
          modalState={openableState}
          close={close}
        />
      </div>
    </>
  );
}

export default observer(EmbeddedDigitalCertificateDocument);
