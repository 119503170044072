import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const UserIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 11 11">
      <path
        fill="#000000"
        d="M5 0a3.507 3.507 0 0 0-3.5 3.5c0 1.197.61 2.26 1.531 2.89A4.997 4.997 0 0 0 0 11h1c0-2.232 1.768-4 4-4s4 1.768 4 4h1a4.997 4.997 0 0 0-3.031-4.61A3.505 3.505 0 0 0 8.5 3.5C8.5 1.572 6.928 0 5 0Zm0 1c1.387 0 2.5 1.113 2.5 2.5S6.387 6 5 6a2.492 2.492 0 0 1-2.5-2.5C2.5 2.113 3.613 1 5 1Z"
      />
    </SVGIcon>
  );
};

export default observer(UserIcon);
