import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../../../ErrorHandling';
import { FlashAlert, errorAlert } from '../../../Notifications/Types';
import { TPlainTextKey } from '../../../Translations';
import { AutoLaunchableResource, State, loading, nothingToLaunch, ready } from './Types';

class AutoLaunchableStore {
  @observable
  state: State;

  constructor() {
    this.state = loading();
  }

  @action
  load = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'nothing-to-launch':
        this.state = loading();
        break;
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (autoLaunchableResource: AutoLaunchableResource) => {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
        this.state = ready(autoLaunchableResource);
        break;
      case 'nothing-to-launch':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  nothingToLaunch = () => {
    switch (this.state.kind) {
      case 'loading':
        this.state = nothingToLaunch();
        break;
      case 'ready':
      case 'nothing-to-launch':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'nothing-to-launch':
        return nothing();
    }
  }
}

export { AutoLaunchableStore };
