import { ChevronRightIcon, ListItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T } from '../../../../../Translations';

export interface Props {
  onClick: () => void;
}

function LearningMenu({ onClick }: Props) {
  return (
    <ListItem
      role="menuitem"
      className="justify-between"
      endContent={<ChevronRightIcon size="sm" />}
      onClick={onClick}
    >
      <T kind="Learning Collections" />
    </ListItem>
  );
}

export default observer(LearningMenu);
