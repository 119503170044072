import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, field, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../Resource/Decoders';
import { FreebusyConfig, ProposerParticipant } from '../../Freebusy/Types';
import { FreebusyAdResource, FreebusyAdsResource } from './Types';

export const freebusyProposerParticipantDecoder: Decoder<ProposerParticipant> = succeed({})
  .assign('email', field('email', string))
  .assign('name', field('name', string))
  .assign('timeZone', field('time_zone', string))
  .assign('role', field('role', stringLiteral('proposer')));

export const freebusyAdDecoder: Decoder<FreebusyConfig> = succeed({})
  .assign('kind', field('kind', stringLiteral('freebusy-program')))
  .assign('link', field('link', string))
  .assign('agenda', field('agenda', string))
  .assign('proposerParticipant', field('proposer_participant', freebusyProposerParticipantDecoder))
  .assign('eventUuid', field('event_uuid', string))
  .map<FreebusyConfig>(identity);

export const freebusyAdResourceDecoder: Decoder<FreebusyAdResource> = resourceDecoder(
  freebusyAdDecoder
);

export const freebusyAdsResourceDecoder: Decoder<FreebusyAdsResource> = resourceDecoder(
  array(freebusyAdResourceDecoder)
);
