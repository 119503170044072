const methods = ['get', 'post', 'put', 'patch', 'head', 'options', 'delete'] as const;

export type Method = (typeof methods)[number];

export interface MissingLinkError<
  R extends string,
  E extends string | undefined,
  M extends Method,
  T extends string,
> {
  kind: 'missing-link-error';
  rel: R;
  endpoint?: E;
  method?: M;
  type?: T;
}

export const missingLinkError = <
  R extends string,
  E extends string | undefined,
  M extends Method,
  T extends string,
>({
  rel,
  endpoint,
  method,
  type,
}: LinkIdentity<R, E, M, T>): MissingLinkError<R, E, M, T> => ({
  kind: 'missing-link-error',
  rel,
  endpoint,
  method,
  type,
});

export interface LinkIdentity<
  R extends string,
  E extends string | undefined,
  M extends Method,
  T extends string,
> {
  rel: R;
  endpoint?: E;
  method?: M;
  type?: T;
}

export interface Link<
  R extends string,
  E extends string | undefined,
  M extends Method = Method,
  T extends string = string,
> {
  rel: R;
  endpoint?: E;
  method: M;
  type: T;
  href: string;
}

export type EndpointLink<
  R extends string,
  E extends string | undefined = undefined,
  M extends Method = Method,
  T extends string = string,
> = Link<R, E, M, T> & { endpoint: E };
