import { Maybe, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { EventResource } from '../../EventsStore/Types';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import ConferencePanel from '../ConferencePanel';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import { BreakoutStores, RoomConfiguration } from './Types';

interface Props {
  presenceMembersStore: PresenceMembersStore;
  conferenceRoomResource: ConferenceRoomResource;
  agenda: Maybe<string>;
  conferenceRosterStore: ConferenceRosterStore;
  roomConfig: RoomConfiguration;
  breakoutStores: Maybe<BreakoutStores>;
  eventResource: Maybe<EventResource>;
  staffRequestStore: StaffRequestStore;
}

const PanelRenderer: React.FC<Props> = ({
  presenceMembersStore,
  conferenceRoomResource,
  agenda,
  conferenceRosterStore,
  roomConfig,
  breakoutStores,
  eventResource,
  staffRequestStore,
}) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (roomConfig.kind) {
        case 'breakout-room':
          return (
            <ConferencePanel
              presenceMembersStore={presenceMembersStore}
              conferenceRoomResource={conferenceRoomResource}
              returnToEventId={just(roomConfig.returnToEventId)}
              agenda={agenda}
              teamId={just(roomConfig.teamId)}
              conferenceRosterStore={conferenceRosterStore}
              breakoutStores={nothing()}
              eventId={nothing()}
              eventResource={eventResource}
              staffRequestStore={staffRequestStore}
              currentUserResource={currentUserResource}
            />
          );
        case 'event-room':
          return (
            <ConferencePanel
              presenceMembersStore={presenceMembersStore}
              conferenceRoomResource={conferenceRoomResource}
              returnToEventId={nothing()}
              agenda={agenda}
              teamId={nothing()}
              conferenceRosterStore={conferenceRosterStore}
              breakoutStores={breakoutStores}
              eventId={just(roomConfig.eventId)}
              eventResource={eventResource}
              staffRequestStore={staffRequestStore}
              currentUserResource={currentUserResource}
            />
          );
        case 'team-room':
          return (
            <ConferencePanel
              presenceMembersStore={presenceMembersStore}
              conferenceRoomResource={conferenceRoomResource}
              returnToEventId={nothing()}
              agenda={agenda}
              teamId={just(roomConfig.teamId)}
              conferenceRosterStore={conferenceRosterStore}
              breakoutStores={nothing()}
              eventId={nothing()}
              eventResource={eventResource}
              staffRequestStore={staffRequestStore}
              currentUserResource={currentUserResource}
            />
          );
        case 'error':
          // TODO proper error handling...
          return <span />;
      }
    }}
  />
);

export default observer(PanelRenderer);
