import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import TeamStore from '../../TeamStore';
import ContentFaker from '../ContentFaker';
import PresenceTeamProfile from './PresenceTeamProfile';
import SingularTeamProfile from './SingularTeamProfile';

interface Props {
  teamId: Maybe<number>;
  teamStore: TeamStore;
}

const TeamView: React.FC<Props> = ({ teamStore, teamId }) => {
  switch (teamStore.state.kind) {
    case 'ready': {
      return teamId
        .map(() => <PresenceTeamProfile teamStore={teamStore} />)
        .getOrElseValue(<SingularTeamProfile store={teamStore} />);
    }
    case 'loading':
    case 'waiting': {
      return (
        <>
          <ContentFaker />
        </>
      );
    }
    case 'error': {
      return <></>;
    }
  }
};
export default observer(TeamView);
