import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import { useEnrollmentModalContext } from '../../Common';

function EnrollmentModalSubHeader() {
  const { experienceResource } = useEnrollmentModalContext();
  const { title, duration, offeringType } = experienceResource.payload;

  switch (offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'program-sequence':
      return duration
        .map((duration) => (
          <Typography variant="bodyMedium">
            <T
              kind="{{title}} is designed to be completed in {{count}} week"
              count={duration}
              title={title.text}
            />
          </Typography>
        ))
        .getOrElse(emptyFragment);
    case 'coaching':
      return (
        <Typography variant="bodyMedium">
          <T kind="Leadership Coaching is available to you for the duration of your license." />
        </Typography>
      );
    case 'group-coaching':
      return emptyFragment();
  }
}

export default observer(EnrollmentModalSubHeader);
