import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import Notifications from './Notifications';
import * as style from './style.module.css';

interface Props {
  toasts: Maybe<NonEmptyList<ScheduledToastStore>>;
}

const EventNotifications: React.FC<Props> = ({ toasts }) => {
  return (
    <div className={style.eventNotifications}>
      {toasts
        .map((toasts) => <Notifications scheduledToastStores={toasts} />)
        .getOrElse(() => (
          <></>
        ))}
    </div>
  );
};

export default observer(EventNotifications);
