import { observer } from 'mobx-react';
import * as React from 'react';
import Modal from './Modal';
import SurveyModalStore from './Modal/Store';
import SurveyModalContent from './SurveyModalContent';

interface Props {
  store: SurveyModalStore;
}

const SurveyModal: React.FC<Props> = ({ store }) => {
  return (
    <>
      <Modal closeAndDismiss={store.closeAndDismiss}>
        <SurveyModalContent store={store} />
      </Modal>
    </>
  );
};
export default observer(SurveyModal);
