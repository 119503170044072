import { invalidStateTransition } from '@execonline-inc/store-ext.private';
import { milliseconds, Seconds, seconds, toMilliseconds } from '@execonline-inc/time';
import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { Requesting, SessionTimeoutState, State } from './States';
import { SessionData } from './Types';

type ObservableNow = (interval: number) => number;

export class SessionStore<SessionError, MessageOnFailure> {
  invalidStateTransition = invalidStateTransition('SessionStore', () => this.state);

  // @observable
  state: State<SessionError, MessageOnFailure> = {
    kind: 'requesting',
    messageOnFailure: nothing(),
  };

  constructor(private readonly now: ObservableNow) {}

  // @action
  requesting = ({
    messageOnFailure,
  }: Pick<Requesting<MessageOnFailure>, 'messageOnFailure'>): void => {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
      case 'present':
        this.state = { kind: 'requesting', messageOnFailure };
        break;
      default:
        assertNever(this.state);
    }
  };

  // @action
  present = (sessionData: SessionData): void => {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
      case 'present':
        this.state = { kind: 'present', sessionData };
        break;
      default:
        assertNever(this.state);
    }
  };

  // @action
  absent = (): void => {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
      case 'present':
        this.state = { kind: 'absent' };
        break;
      default:
        assertNever(this.state);
    }
  };

  // @action
  errored = (error: SessionError): void => {
    switch (this.state.kind) {
      case 'requesting':
        this.state = { kind: 'errored', error };
        break;
      case 'absent':
      case 'errored':
      case 'present':
        this.invalidStateTransition('errored');
        break;
      default:
        assertNever(this.state);
    }
  };

  // @computed
  get userId(): Maybe<number> {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
        return nothing();
      case 'present':
        return this.state.sessionData.userId;
    }
  }

  // @computed
  get sessionToken(): Maybe<string> {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
        return nothing();
      case 'present':
        return just(this.state.sessionData.sessionToken);
    }
  }

  // @computed
  get impersonatingBy(): Maybe<string> {
    switch (this.state.kind) {
      case 'requesting':
      case 'absent':
      case 'errored':
        return nothing();
      case 'present':
        return this.state.sessionData.impersonatingBy;
    }
  }

  // @computed
  get expiryTimeLeft(): Maybe<Seconds> {
    switch (this.state.kind) {
      case 'present':
        return this.state.sessionData.expiresAt
          .map((expiresAt) =>
            milliseconds(expiresAt.getTime() - this.now(toMilliseconds(seconds(10)).milliseconds)),
          )
          .map((millisecondsLeft) => seconds(Math.floor(millisecondsLeft.milliseconds / 1000)));
      case 'requesting':
      case 'absent':
      case 'errored':
        return nothing();
    }
  }

  // @computed
  get timeoutState(): SessionTimeoutState {
    return this.expiryTimeLeft
      .map(({ seconds }): SessionTimeoutState => {
        if (seconds < 0) {
          return 'expired';
        } else if (seconds < 60) {
          return 'expiring';
        } else {
          return 'current';
        }
      })
      .getOrElseValue('not-applicable');
  }
}
