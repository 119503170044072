import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { PromotableExperienceResource } from '../Types';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function PromotableExperienceTileTitle({ experienceResource }: Props) {
  return experienceResource.payload.promotableMarketingTitle
    .map((title) => (
      <Typography className="mb-4 sm:mb-8" variant="h5">
        <AlreadyTranslated content={title} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PromotableExperienceTileTitle);
