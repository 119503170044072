import { observer } from 'mobx-react';
import WhenImpersonating from '../../Impersonation/WhenImpersonating';
import WhenNotImpersonating from '../../Impersonation/WhenNotImpersonating';
import { findLink } from '../../LinkyLinky';
import { Linkable } from '../../Resource/Types';
import { T } from '../../Translations';
import DownloadViewRouting from './DownloadViewRouting';

interface DownloadModalTitleProps {
  resource: Linkable;
}

const DefaultModalTitle = observer(({ resource }: DownloadModalTitleProps) =>
  findLink('view', resource.links)
    .map(() => <T kind="Download or View File" />)
    .getOrElse(() => <T kind="Download File" />)
);

const ImpersonatingModalTitle = observer(({ resource }: DownloadModalTitleProps) => {
  return findLink('view', resource.links)
    .map(() => (
      <>
        <WhenImpersonating>
          <T kind="View File" />
        </WhenImpersonating>
        <WhenNotImpersonating>
          <T kind="Download or View File" />
        </WhenNotImpersonating>
      </>
    ))
    .getOrElse(() => <T kind="Download File" />);
});

function ModalTitle({ resource }: DownloadModalTitleProps) {
  return (
    <DownloadViewRouting
      uploadedByYou={() => <ImpersonatingModalTitle resource={resource} />}
      otherwise={() => <DefaultModalTitle resource={resource} />}
    />
  );
}

export default observer(ModalTitle);
