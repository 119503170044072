import { observer } from 'mobx-react';
import * as React from 'react';
import BroadcastMessageStore from '../../BroadcastMessageStore';
import { T } from '../../Translations';
import ModalStore from '../Modal/Store';
import BroadcastIcon from './BroadcastIcon';
import BroadcastMessageModal from './BroadcastMessageModal';
import * as style from './style.module.css';

interface Props {
  store: BroadcastMessageStore;
}

const BroadcastMessage: React.FC<Props> = ({ store }) => {
  const modalStore = new ModalStore();

  return (
    <>
      <button
        className={style.broadcastButton}
        onClick={(e) => {
          e.preventDefault();
          modalStore.open();
        }}
        data-test-broadcast-message-link={true}
      >
        <T kind="Broadcast Message to All Rooms" />
        <div className={style.buttonIcon}>
          <BroadcastIcon />
        </div>
      </button>

      <BroadcastMessageModal store={store} modalStore={modalStore} />
    </>
  );
};

export default observer(BroadcastMessage);
