import { find, mapMaybe } from '@execonline-inc/collections';
import { fromEmpty, Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import {
  ChatablePersonResource,
  ChatablePersonResourceList,
  ChatMessageReplyResource,
} from '../../../../ConversationStore/Types';

export type PreviousState =
  | 'empty-replies'
  | 'lone-reply'
  | 'abridged-replies'
  | 'expanded-replies';

export interface EmptyReplies {
  kind: 'empty-replies';
}

export interface LoneReply {
  kind: 'lone-reply';
  reply: ChatMessageReplyResource;
}

export interface AbridgedReplies {
  kind: 'abridged-replies';
  uniqueRepliers: ReadonlyArray<ChatablePersonResource>;
  totalReplies: number;
}

export interface ExpandedReplies {
  kind: 'expanded-replies';
  replies: NonEmptyList<ChatMessageReplyResource>;
}

export const createEmptyReplies = (): RepliesState => ({ kind: 'empty-replies' });

export const createLoneReply = (reply: ChatMessageReplyResource): RepliesState => ({
  kind: 'lone-reply',
  reply,
});

const getChatableResource = (chatablePersonResourceList: ChatablePersonResourceList) => (
  userId: number
): Maybe<ChatablePersonResource> =>
  find((tmr) => tmr.payload.id === userId, chatablePersonResourceList);

const uniqueByAuthorResponses = (
  replies: NonEmptyList<ChatMessageReplyResource>
): ReadonlyArray<ChatMessageReplyResource> =>
  replies.toArray().reduce<ReadonlyArray<ChatMessageReplyResource>>(
    (accum, reply) =>
      find((v) => v.payload.userId === reply.payload.userId, accum)
        .map<ReadonlyArray<ChatMessageReplyResource>>(() => accum)
        .getOrElseValue([...accum, reply]),
    []
  );

export const uniqueRepliers = (
  replies: NonEmptyList<ChatMessageReplyResource>,
  chatablePersonResourceList: ChatablePersonResourceList
): ReadonlyArray<ChatablePersonResource> => {
  return mapMaybe(
    getChatableResource(chatablePersonResourceList),
    uniqueByAuthorResponses(replies).map((reply) => reply.payload.userId)
  );
};

export const createAbridgedReplies = (
  replies: NonEmptyList<ChatMessageReplyResource>,
  chatablePersonResourceList: ChatablePersonResourceList
): RepliesState => ({
  kind: 'abridged-replies',
  uniqueRepliers: uniqueRepliers(replies, chatablePersonResourceList),
  totalReplies: replies.length,
});

export const createExpandedReplies = (
  replies: NonEmptyList<ChatMessageReplyResource>
): RepliesState => ({
  kind: 'expanded-replies',
  replies,
});

export const createRepliesState = (
  abridged: boolean,
  chatablePersonResourceList: ChatablePersonResourceList
) => (replies: NonEmptyList<ChatMessageReplyResource>): RepliesState => {
  return fromEmpty(replies.rest)
    .map(() =>
      abridged
        ? createAbridgedReplies(replies, chatablePersonResourceList)
        : createExpandedReplies(replies)
    )
    .getOrElse(() => createLoneReply(replies.first));
};

export const abridged = (previousState: Maybe<PreviousState>): boolean =>
  previousState
    .map((previousState) => {
      switch (previousState) {
        case 'abridged-replies':
        case 'empty-replies':
        case 'lone-reply':
          return true;
        case 'expanded-replies':
          return false;
      }
    })
    .getOrElseValue(true);

export type RepliesState = EmptyReplies | LoneReply | AbridgedReplies | ExpandedReplies;
