import { observer } from 'mobx-react';
import * as React from 'react';
import { L } from '../../Translations';
import * as style from './style.module.css';
import { LiveForumDatesNode } from './Types';

interface Props {
  node: LiveForumDatesNode;
}

const LiveForumDates: React.FC<Props> = ({ node }) => {
  return (
    <div>
      {node.dates.map((date) => (
        <span key={date.payload.id} className={style.date}>
          <L localizeable={date.payload.start_at} format="long-date-and-time" />
        </span>
      ))}
    </div>
  );
};

export default observer(LiveForumDates);
