import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { Experience } from '../../../../Common/Experience/Types';
import ExperienceDuration from './ExperienceDuration';
import ExperiencePrice from './ExperiencePrice';

interface Props {
  experience: Experience;
  className?: string;
}

function ExperienceOverview({ className, experience }: Props) {
  const { duration, price } = experience;

  return (
    <div className={cn('flex flex-row gap-4', className)}>
      <ExperienceDuration duration={duration} />
      <ExperiencePrice price={price} />
    </div>
  );
}

export default observer(ExperienceOverview);
