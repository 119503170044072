import { find } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import { CurrentUserResource } from '../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps } from '../../ErrorActionableReaction';
import { findLinkT } from '../../LinkyLinky';
import { Link } from '../../Resource/Types';
import BreakoutChannelStore from '../../Socket/BreakoutChannel/BreakoutChannelStore';
import TeamsStore from '../../TeamsStore';
import {
  handleTeamRequestError,
  teamsEndPoint,
  TeamsReactionError,
} from '../../TeamsStore/TeamsReactions';
import { TeamResource, TeamsResource, TeamsState } from '../../TeamsStore/Types';

export interface Props extends EAProps<TeamsStore> {
  store: TeamsStore;
  breakoutStore: BreakoutChannelStore;
  currentUserResource: CurrentUserResource;
}

class BreakoutReactions extends ErrorActionableReaction<TeamsStore, TeamsState, Props> {
  tester = () => this.props.store.state;

  initialize = (teamsResource: TeamsResource) => {
    const breakoutStore = this.props.breakoutStore;
    const eventId = breakoutStore.breakout.eventId;

    const hasAssociatedEventId = (team: TeamResource) =>
      find((id) => id === eventId, team.payload.associatedEventIds).cata({
        Just: () => true,
        Nothing: () => false,
      });

    find(hasAssociatedEventId, teamsResource.payload).map((team) =>
      breakoutStore.updateBreakoutTeamId(team.payload.id),
    );

    this.props.store.ready(teamsResource);
  };

  effect = (state: TeamsState) => {
    switch (state.kind) {
      case 'ready':
        break;
      case 'loading':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(findLinkT('team-details'))
          .andThen(teamsEndPoint)
          .fork(handleTeamRequestError(this.props.store), this.initialize);
        break;
      case 'waiting':
      case 'error':
      case 'loading-more':
        break;
      default:
        assertNever(state);
    }
  };
}

export default BreakoutReactions;
