import { assertNever } from '@kofno/piper';
import { LegacyAndNewVideoAssetResource } from '../../../../SegmentStore/Types';
import { ReqHlsVideoAssetResource } from '../../../ReqHlsVideo/Types';
import { VideoAssetResource } from '../../../Video/Types';

const isLegacyVideo = (
  resource: LegacyAndNewVideoAssetResource
): resource is VideoAssetResource => {
  return resource.payload.kind === 'legacy-video-asset';
};
const isReqHlsVideo = (
  resource: LegacyAndNewVideoAssetResource
): resource is ReqHlsVideoAssetResource => {
  return resource.payload.kind === 'req-hls-video-asset';
};
type VideoResourceCata<T> = {
  legacy: (v: VideoAssetResource) => T;
  hls: (v: ReqHlsVideoAssetResource) => T;
};

export const videoResourceCata = <T>(
  handlers: VideoResourceCata<T>,
  v: LegacyAndNewVideoAssetResource
): T => {
  if (isLegacyVideo(v)) {
    return handlers.legacy(v);
  } else if (isReqHlsVideo(v)) {
    return handlers.hls(v);
  } else {
    return assertNever(v);
  }
};
