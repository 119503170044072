import { observer } from 'mobx-react';
import { Position } from '../../../PositionContext';
import * as React from 'react';
import * as style from '../../style.module.css';

interface Props {
  name: String;
  position: Position;
}

const TeamTitle: React.FC<Props> = ({ name, position }) => {
  switch (position.kind) {
    case 'supPanel':
      return (
        <div className={style.titleOnSupPanel} data-test-team-name={name}>
          {name}
        </div>
      );
    case 'overview':
    case 'nav':
    case 'dashboard':
    case 'tooltip':
    case 'chat':
    case 'participant':
    case 'footer':
    case 'none':
      return (
        <div className={style.titleOnContent} data-test-team-name={name}>
          {name}
        </div>
      );
  }
};

export default observer(TeamTitle);
