import { find } from '@execonline-inc/collections';
import { pipe, UnaryFunction } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { CommunityTeamResource } from '../CommunitiesStore/Types';
import { payload } from '../Resource/Types';
import { TeamResource } from '../TeamsStore/Types';
import { ActiveProgram, CompletedProgram, ExpiredProgram, Program, ProgramResource } from './Types';

export const findProgram = (programId: number) =>
  find((r: ProgramResource) => r.payload.id === programId);

export const findProgramFromCommunityTeam = (communityTeamResource: CommunityTeamResource) =>
  findProgram(communityTeamResource.payload.courseId);

export const findProgramFromTeam = (teamResource: TeamResource) =>
  findProgram(teamResource.payload.courseId);

export const activeProgram = (program: Program): boolean => {
  switch (program.kind) {
    case 'active':
    case 'completed':
    case 'expired':
      return true;
    case 'upcoming':
    case 'inactive':
      return false;
  }
};

export const activeProgramSequence = (program: Program): boolean => {
  switch (program.kind) {
    case 'active':
      return true;
    case 'completed':
    case 'expired':
    case 'upcoming':
    case 'inactive':
      return false;
  }
};

export const currentProgram = (program: Program): boolean => {
  switch (program.kind) {
    case 'active':
      return program.productFamilyKind !== 'program-sequence';
    case 'completed':
    case 'upcoming':
    case 'inactive':
    case 'expired':
      return false;
  }
};

export const currentProgramSequence = (program: Program): boolean => {
  switch (program.kind) {
    case 'active':
      return program.productFamilyKind === 'program-sequence';
    case 'completed':
    case 'upcoming':
    case 'inactive':
    case 'expired':
      return false;
  }
};

export const completeProgram = (program: Program): boolean => {
  switch (program.kind) {
    case 'completed':
      return true;
    case 'active':
    case 'upcoming':
    case 'inactive':
    case 'expired':
      return false;
  }
};

export const upcomingProgram = (program: Program): boolean => {
  switch (program.kind) {
    case 'upcoming':
      return true;
    case 'completed':
    case 'active':
    case 'inactive':
    case 'expired':
      return false;
  }
};

export const expiredProgram = (program: Program): boolean => {
  switch (program.kind) {
    case 'expired':
      return true;
    case 'completed':
    case 'active':
    case 'inactive':
    case 'upcoming':
      return false;
  }
};

export const visibleProgram = (program: Program): boolean => {
  switch (program.dashboardPresence) {
    case 'visible':
      return true;
    case 'hidden':
      return false;
  }
};

export const activeProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  activeProgram,
);

export const visibleProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  visibleProgram,
);

export const currentProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  currentProgram,
);

export const currentProgramSequenceResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  currentProgramSequence,
);

export const completedProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  completeProgram,
);

export const upcomingProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  upcomingProgram,
);

export const expiredProgramResource: UnaryFunction<ProgramResource, boolean> = pipe(
  payload,
  expiredProgram,
);

export const whenActiveProgram = (
  program: Program,
): Maybe<ActiveProgram | CompletedProgram | ExpiredProgram> => {
  switch (program.kind) {
    case 'active':
    case 'completed':
    case 'expired':
      return just(program);
    case 'upcoming':
    case 'inactive':
      return nothing();
  }
};
