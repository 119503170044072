import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSessionResource } from '../../../../../ProgramStore/Types';
import { Link, Rel } from '../../../../../Resource/Types';
import { L, NotTranslated, TPlainTextKey } from '../../../../../Translations';
import CalendarDateIcon from '../../../../CalendarDateIcon';
import CalendarEditIcon from '../../../../Cutesies/CalendarEditIcon';
import { DropdownListItem } from '../../../../Dropdown/Types';
import KebabMenu from '../../../../KebabMenu';
import * as style from '../../style.module.css';

import SessionDuration from '../SessionDuration';
import JoinButton from './JoinButton';

interface Props {
  session: CoachingSessionResource;
}

const SessionRow: React.FC<Props> = ({ session }) => {
  const dropDownLinkRels: Rel[] = ['reschedule'];

  const dropdownListItem = (link: Link, name: TPlainTextKey): DropdownListItem => ({
    kind: 'icon-list-item',
    href: link.href,
    name: name,
    icon: <CalendarEditIcon />,
  });

  const dropdownMenuItems = (): DropdownListItem[] =>
    session.links
      .filter((link) => dropDownLinkRels.indexOf(link.rel) !== -1)
      .map((link) => dropdownListItem(link, 'Reschedule'));

  return (
    <div className={style.flexRow}>
      <CalendarDateIcon date={session.payload.date} />
      <div className={style.sessionDate}>
        <div className={style.boldText}>
          <L localizeable={session.payload.date} format="long-weekday" />
          <span>
            <NotTranslated text=", " />
          </span>
          <L localizeable={session.payload.date} format="time-of-day" />
        </div>
        <SessionDuration session={session.payload} />
      </div>
      <div>
        <KebabMenu menuItems={dropdownMenuItems()} ariaLabelText={'Menu'} title={'Menu'} />
        <JoinButton session={session} />
      </div>
    </div>
  );
};

export default observer(SessionRow);
