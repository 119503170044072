import { find } from '@execonline-inc/collections';
import { toTask, when } from '@execonline-inc/maybe-adapter';
import i18next from '@prebsch-exo/i18next';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import { CurrentUserResource } from '../CurrentUser/Types';
import { languageTag } from '../LanguageParser';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import {
  PreferredLanguageNotEstablished,
  UnsupportedBrowserLanguage,
  preferredLanguageNotEstablished,
  unsupportedBrowserLanguage,
} from './Errors';
import { PreferenceIdentified } from './States';

export const whenBrowserLanguageChangeNeeded = (
  state: PreferenceIdentified,
): Maybe<SupportedLanguageCode> =>
  currentBrowserLanguage().andThen((browserLanguage) =>
    when(state.language !== browserLanguage, state.language),
  );

export const whenUserPreferredLanguagePresent = (
  currentUserResource: CurrentUserResource,
): Task<PreferredLanguageNotEstablished, SupportedLanguageCode> =>
  toTask(preferredLanguageNotEstablished(), currentUserResource.payload.preferredLanguage);

export const whenBrowserPreferredLanguageValid = (
  currentUserResource: CurrentUserResource,
): Task<UnsupportedBrowserLanguage, SupportedLanguageCode> =>
  toTask(
    unsupportedBrowserLanguage(),
    currentBrowserLanguage().andThen(findAllowedLanguage(currentUserResource)),
  );

const findAllowedLanguage =
  (currentUserResource: CurrentUserResource) =>
  (value: string): Maybe<SupportedLanguageCode> =>
    find(
      (code) => code === value,
      currentUserResource.payload.allowedLanguages.payload.map(({ payload }) => payload.code),
    );

const currentBrowserLanguage = () => languageTag(i18next.language);
