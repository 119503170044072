import { findPayload } from '@execonline-inc/collections';
import { parseIntM } from '@execonline-inc/numbers';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import EventStore from '../../EventStore';
import EventReactions from '../../EventStore/EventReactions';
import { programsStore } from '../../ProgramsStore';
import { ProgramResource } from '../../ProgramsStore/Types';
import TeamStore from '../../TeamStore';
import TeamReactions from '../../TeamStore/TeamReactions';
import Conference from '../Conference';
import ConferenceNotReady from '../Conference/ConferenceNotReady';
import { breakoutRoomConfig, roomConfigError, RoomConfiguration } from '../Conference/Types';

export interface Params {
  teamId: string;
  eventId: string;
}

export interface Props {
  match: { params: Params };
}

@observer
class TeamBreakoutConference extends React.Component<Props, {}> {
  teamStore: TeamStore = new TeamStore();
  teamId = parseIntM(this.props.match.params.teamId);
  eventStore: EventStore = new EventStore();

  roomConfig = (): RoomConfiguration => {
    return just({})
      .assign('returnToEventId', parseIntM(this.props.match.params.eventId))
      .assign('teamId', parseIntM(this.props.match.params.teamId))
      .map(
        ({ returnToEventId, teamId }) =>
          breakoutRoomConfig(returnToEventId, teamId) as RoomConfiguration
      )
      .getOrElseValue(roomConfigError());
  };

  componentDidMount() {
    this.teamStore.loading();
    this.eventStore.loading();
  }

  programName = (programId: number): Maybe<string> =>
    programsStore.resource
      .map(({ payload }) => payload.programs)
      .andThen(findPayload(programId))
      .map((program: ProgramResource) => program.payload.title.text);

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            {just({})
              .assign('teamResource', this.teamStore.team)
              .assign(
                'conferenceRoom',
                this.teamStore.team.andThen((team) => team.payload.conferenceRoom)
              )
              .map(({ teamResource, conferenceRoom }) => (
                <Conference
                  title={teamResource.payload.name}
                  detail={this.programName(teamResource.payload.courseId)}
                  conversationResource={
                    teamResource.payload.teamChat.payload.breakoutConversationResource
                  }
                  agenda={this.eventStore.agenda}
                  eventResource={this.eventStore.eventResource}
                  conferenceableResource={teamResource}
                  roomConfig={this.roomConfig()}
                  currentUserResource={currentUserResource}
                  conferenceRoom={conferenceRoom.payload}
                />
              ))
              .getOrElse(() => (
                <ConferenceNotReady teamStore={this.teamStore} />
              ))}
            <TeamReactions
              store={this.teamStore}
              teamId={this.teamId}
              currentUserResource={currentUserResource}
            />
            <EventReactions
              store={this.eventStore}
              eventId={parseIntM(this.props.match.params.eventId)}
              currentUserResource={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default TeamBreakoutConference;
