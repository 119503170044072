import { observer } from 'mobx-react';
import { whenZero } from '@execonline-inc/numbers';
import { noop } from '@kofno/piper';
import { useExperienceContext } from '../../Experience/ExperienceContext';
import { GroupCoachingProductDetails } from '../../Experience/Types';
import { useExperienceEnrollmentContext } from '../../ProgramExperienceEnrollment';

interface Props {
  productDetails: GroupCoachingProductDetails;
  children: (onClick: () => void) => React.ReactNode;
}

function GroupCoachingActions({ productDetails, children }: Props) {
  const { experienceResource } = useExperienceContext();
  const { onOpen } = useExperienceEnrollmentContext();
  const { kind } = experienceResource.payload;
  const { remainingSeats } = productDetails;

  switch (kind) {
    case 'commerce-enrollable':
    case 'enrollable':
      return remainingSeats
        .andThen(whenZero)
        .map(() => <>{children(noop)}</>)
        .getOrElse(() => <>{children(onOpen)}</>);
    case 'beginnable':
    case 'graduated':
    case 'resumable':
    case 'returnable':
    case 'upcoming':
    case 'not-enrollable':
    case 'not-licensed':
      return <>{children(noop)}</>;
  }
}

export default observer(GroupCoachingActions);
