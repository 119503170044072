import { observer } from 'mobx-react';
import ExperiencesStore from '../../../../DiscoveryPortal/ExperienceSelection/Experiences/ExperiencesStore';
import { ExperienceVideoPreview, ExperienceViewLogo } from '../../../ProgramExperienceVideo';

interface Props {
  className: string;
  experiencesStore: ExperiencesStore;
  height: number;
}

function ProgramExperienceCardVideo({ className, experiencesStore, height }: Props) {
  return (
    <ExperienceVideoPreview className={className} experiencesStore={experiencesStore}>
      {({ experienceResource }) => (
        <ExperienceViewLogo
          className={className}
          data={experienceResource.payload.schoolPartner}
          height={height}
        />
      )}
    </ExperienceVideoPreview>
  );
}

export default observer(ProgramExperienceCardVideo);
