import { observer } from 'mobx-react';
import { ButtonExperience } from '../Common';
import { BeginnableExperience } from '../../../../Common/Experience/Types';
import { exitToSegment } from '../../../../Common/Experience/Actions/Exitable/Types';

interface Props {
  experience: BeginnableExperience;
}

function BeginnableCardFooter({ experience }: Props) {
  const { programId, moduleId, segmentId } = experience;

  return (
    <ButtonExperience
      intent="Begin Experience"
      exitContext={exitToSegment({ programId, moduleId, segmentId })}
    />
  );
}

export default observer(BeginnableCardFooter);
