import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { Link } from '../../../../../Resource/Types';
import ExternalLink from '../../../ExternalLink';
import { T } from '../../../../../Translations';

interface Props {
  certLink: Link;
}

function ViewSample({ certLink }: Props) {
  return (
    <ExternalLink href={certLink.href}>
      <Typography color="teal" variant="caption">
        <T kind="view sample" />
      </Typography>
    </ExternalLink>
  );
}

export default observer(ViewSample);
