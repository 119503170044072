import { observer } from 'mobx-react';
import clsx from 'clsx';
import { BrowserShieldIcon, CircleProgressIcon } from '@execonline-inc/execonline-ui';

function PaymentProcessing() {
  return (
    <div className="relative flex items-center text-gray-200">
      <CircleProgressIcon
        className={clsx(
          'h-[8.375rem] w-[8.375rem]',
          'origin-center animate-[spin_2s_linear_infinite]',
        )}
        color="inherit"
      />
      <BrowserShieldIcon
        className={clsx(
          'absolute left-1/2 top-1/2 h-[3.75rem] w-[3.75rem]',
          '-translate-x-1/2 -translate-y-1/3',
        )}
        color="inherit"
      />
    </div>
  );
}

export default observer(PaymentProcessing);
