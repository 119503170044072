import { stringLiteral } from '@execonline-inc/decoders';
import Decoder, { array, field, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import {
  PusherChannels,
  PusherSettings,
  PusherSettingsResource,
  ToolConfigResource,
  Toolings,
  ToolingsResource,
} from './Types';

const pusherChannelsDecoder: Decoder<PusherChannels> = succeed({})
  .assign('calendarEventsChannel', field('calendar_events_channel', string))
  .assign('notificationChannel', field('notification_channel', string));

const pusherSettingsDecoder: Decoder<PusherSettings> = succeed({})
  .assign('kind', field('kind', stringLiteral('pusher-settings')))
  .assign('key', field('key', string))
  .assign('cluster', field('cluster', string))
  .assign('channels', field('channels', pusherChannelsDecoder));

const pusherSettingsResourceDecoder: Decoder<PusherSettingsResource> = resourceDecoder(
  pusherSettingsDecoder
);

const toolConfigResourceDecoder: Decoder<ToolConfigResource> = oneOf([
  pusherSettingsResourceDecoder,
]);

const toolingsDecoder: Decoder<Toolings> = array(toolConfigResourceDecoder);

export const toolingsResourceDecoder: Decoder<ToolingsResource> = resourceDecoder(toolingsDecoder);
