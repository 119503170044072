interface ProgramTypeEnterprise {
  kind: 'program-type-aep';
}

interface ProgramTypeMsuite {
  kind: 'program-type-msuite';
}

interface ProgramTypeAlp {
  kind: 'program-type-alp';
}

interface ProgramTypeAll {
  kind: 'program-type-all';
}

interface ProgramTypeNone {
  kind: 'program-type-none';
}

export const programTypeEnterprise = (): ProgramTypeEnterprise => {
  return { kind: 'program-type-aep' };
};

export const programTypeMsuite = (): ProgramTypeMsuite => {
  return { kind: 'program-type-msuite' };
};

export const programTypeAlp = (): ProgramTypeAlp => {
  return { kind: 'program-type-alp' };
};

export const programTypeAll = (): ProgramTypeAll => {
  return { kind: 'program-type-all' };
};

export const programTypeNone = (): ProgramTypeNone => {
  return { kind: 'program-type-none' };
};

export type ProgramFilter = State['kind'];

export type ProgramFilterType = 'AEP' | 'MSuite' | 'ALP' | 'ALL';

export type ProgramType = 'AEP' | 'MSuite' | 'EPC' | 'ALL';

export type State =
  | ProgramTypeEnterprise
  | ProgramTypeMsuite
  | ProgramTypeAlp
  | ProgramTypeAll
  | ProgramTypeNone;
