import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSessionResource } from '../../../../../ProgramStore/Types';
import { sessionStore } from '../SessionStore';
import JoinLink from './JoinLink';

interface Props {
  session: CoachingSessionResource;
}

const JoinButton: React.FC<Props> = ({ session }) => {
  const store = sessionStore(session.payload);

  switch (store.coachingSession.kind) {
    case 'hidden':
      return <></>;
    case 'visible':
      return <JoinLink sessionId={session.payload.id} />;
  }
};

export default observer(JoinButton);
