import * as React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import { AuthSignUp } from '../Types';
import Instructions from './Instructions';

interface Props {
  sharedInvitationResource: SharedInvitationResource;
  authForm: AuthSignUp;
}

const UserAuthInstructions: React.FC<Props> = ({ sharedInvitationResource, authForm }) => {
  switch (authForm.auth.kind) {
    case 'sso-auth':
      return (
        <Typography>
          <T kind="Continue to launch your corporate ..." />
        </Typography>
      );
    case 'password-auth':
      return <Instructions sharedInvitationResource={sharedInvitationResource} />;
  }
};

export default observer(UserAuthInstructions);
