import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';

import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import FreebusyStore, { FreebusySettings } from '../../../../../FreebusyStore';
import FreebusyReactions from '../../../../../FreebusyStore/FreebusyReactions';
import { TranslationsContext } from '../../../../../Translations';
import { FreebusyAdResource } from '../../../../Dashboard/FreebusyCoachingAd/Types';
import { AvailabilityQuery, AvailabilityRequest, InitSettings } from '../../../../Freebusy/Types';
import FreebusyDisplayStates from './FreebusyDisplayStates';
import ScheduleSessionStore from './Store';

interface Props {
  scheduleSessionResource: FreebusyAdResource;
  scheduleSessionStore: ScheduleSessionStore;
  currentUserResource: CurrentUserResource;
}

class ScheduleSessionReady extends React.Component<Props, {}> {
  freebusyStore = new FreebusyStore('freebusy-coaching');

  initSettings: InitSettings = {
    buttonType: 'custom',
    buttonId: 'freebusy-leadership-coaching-button',
  };

  availabilityRequest: AvailabilityRequest = {
    link: this.props.scheduleSessionResource.payload.link,
    display: 'modal',
    language: this.props.currentUserResource.payload.preferredLanguage.getOrElseValue('en'),
  };

  availabilityQuery: AvailabilityQuery = {
    management: {
      canProposeNewTime: false,
    },
    agenda: this.props.scheduleSessionResource.payload.agenda,
    participants: [
      {
        email: this.props.scheduleSessionResource.payload.proposerParticipant.email,
        name: this.props.scheduleSessionResource.payload.proposerParticipant.name,
        timeZone: this.props.scheduleSessionResource.payload.proposerParticipant.timeZone,
        role: this.props.scheduleSessionResource.payload.proposerParticipant.role,
      },
    ],
  };

  freebusySettings: FreebusySettings = {
    availabilityRequest: this.availabilityRequest,
    availabilityQuery: this.availabilityQuery,
    initSettings: this.initSettings,
  };

  componentDidMount() {
    this.freebusyStore.initializingStore(
      this.freebusySettings,
      this.props.scheduleSessionResource.payload.eventUuid
    );
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => (
            <FreebusyReactions
              links={just(this.props.scheduleSessionResource.links)}
              store={this.freebusyStore}
              translationsState={ts}
              segmentStore={nothing()}
            />
          )}
        </TranslationsContext.Consumer>
        <FreebusyDisplayStates
          freebusyStore={this.freebusyStore}
          scheduleSessionStore={this.props.scheduleSessionStore}
        />
      </>
    );
  }
}

export default observer(ScheduleSessionReady);
