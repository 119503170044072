import { T } from '../../Translations';
import CenteredLogo from '../CenteredLogo';
import NeedAssistance from '../NeedAssistance';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../ClientRoutes';
import ArrowLeftIcon from '../Cutesies/ArrowLeftIcon';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import * as style from './style.module.css';
import { observer } from 'mobx-react';

function NotFoundContent() {
  return (
    <>
      <CenteredLogo size="large" />
      <h1 className={style.titleLightTheme} data-test-title={true}>
        <T kind="We're sorry, but we couldn't find what you were looking for." />
      </h1>
      <NeedAssistance />
      <div className={style.actions}>
        <a href="javascript:history.back();" className={style.backButton}>
          <div className={style.buttonIcon}>
            <ArrowLeftIcon />
          </div>
          <T kind="Back to previous page" />
        </a>
        <Link to={dashboardUrl} className={style.dashboardButton}>
          <T kind="Go to dashboard" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </Link>
      </div>
    </>
  );
}

export default observer(NotFoundContent);
