import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramResource } from '../../../../ProgramsStore/Types';
import * as style from '../../style.module.css';
import ProgramOverviewHeader from '../ProgramOverviewHeader';

interface Props {
  programResource: ProgramResource;
}

const CoachProgramDashboard: React.FC<Props> = ({ programResource }) => {
  const { payload, links } = programResource;
  return (
    <div className={style.program}>
      <div className={style.headerAsBody}>
        <ProgramOverviewHeader id={payload.id} title={payload.title} links={links} />
      </div>
    </div>
  );
};

export default observer(CoachProgramDashboard);
