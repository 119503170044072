import * as React from 'react';
import FreebusyStore from '../../FreebusyStore';
import { observer } from 'mobx-react';
import Loader from '../Loader';
import SegmentStore from '../../SegmentStore';
import { Maybe } from 'maybeasy';
import ProgramReadyButton from './ProgramReadyButton';
import ProgramSubmittedButton from './ProgramSubmittedButton';

interface Props {
  store: FreebusyStore;
  segmentStore: Maybe<SegmentStore>;
}

const ProgramSchedulingButton: React.FC<Props> = ({ store, segmentStore }) => {
  switch (store.state.kind) {
    case 'waiting':
    case 'error':
      return <></>;
    case 'submitting':
      return <Loader />;
    case 'loading':
    case 'initializing':
    case 'initializing-store':
    case 'ready':
      return <ProgramReadyButton segmentStore={segmentStore} freebusyStore={store} />;
    case 'submitted':
      return (
        <ProgramSubmittedButton
          eventDetails={store.state.eventDetails}
          segmentStore={segmentStore}
        />
      );
  }
};

export default observer(ProgramSchedulingButton);
