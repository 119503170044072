import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ResourceForAssignmentIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 19 24">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M.812.096v23h17.633V5.93l-.248-.276-5.29-5.308-.275-.249H.812Zm1.763 1.77h8.817v5.307h5.29v14.154H2.575V1.865Zm10.58 1.271 2.26 2.267h-2.26V3.137Zm-6.86 6.69-.193.608-1.709 5.308-.055.138v1.023h1.764v-.774l.027-.11h1.708l.028.11v.774h1.763V15.88l-.055-.138-1.708-5.308-.193-.608H6.295Zm5.978.885v1.769H10.51v1.769h1.763v1.77h1.764v-1.77H15.8v-1.77h-1.763v-1.768h-1.764Zm-5.29 2.709.276.829h-.551l.275-.83Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourceForAssignmentIcon);
