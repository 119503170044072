import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { rootResourceStore } from '../../RootResourceStore';
import FullStoryTooling from './FullStoryTooling';

function FeatureSwitches() {
  switch (rootResourceStore.state.kind) {
    case 'ready':
      return when(rootResourceStore.fullStoryFeatureSwitch, {})
        .map(() => <FullStoryTooling />)
        .getOrElse(emptyFragment);
    case 'loading':
    case 'error':
      return <></>;
  }
}

export default observer(FeatureSwitches);
