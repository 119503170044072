import { Button, Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../Translations';
import { ExitStore } from '../AEP/Common/Experience/Actions';
import { exitToDashboard } from '../AEP/Common/Experience/Actions/Exitable/Types';

interface Props {
  message: TPlainTextKey;
  exitStore: ExitStore;
}

function Messaging({ message, exitStore }: Props) {
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-y-6 px-6">
      <Typography>
        <T kind="There was an error:" />
      </Typography>
      <Typography>
        <T kind={message} />
      </Typography>
      <Button
        size="lg"
        color="success"
        radius="sm"
        onClick={() => exitStore.exit(exitToDashboard())}
      >
        <T kind="Go to dashboard" />
      </Button>
    </div>
  );
}

export default observer(Messaging);
