import { observer } from 'mobx-react';
import * as React from 'react';
import FeatureSwitches from './FeatureSwitches';
import GoogleTagManagerTooling from './GoogleTagManagerTooling';
import OneTrustTooling from './OneTrustTooling';

const Tooling: React.FC = () => {
  return (
    <>
      <OneTrustTooling />
      <FeatureSwitches />
      <GoogleTagManagerTooling />
    </>
  );
};

export default observer(Tooling);
