import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { BadStatus } from 'ajaxian';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import TeamStore from '.';
import { AppyError, callApi } from '../Appy';
import { communitiesUrl } from '../ClientRoutes';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { nav } from '../Navigation';
import { Link } from '../Resource/Types';
import { teamResourceDecoder } from '../TeamsStore/Decoders';
import { TeamState } from './Types';

const teamEndPoint = callApi(teamResourceDecoder, {});

type TeamReactionError = MissingTeamId | MissingLinkError | AppyError;

export interface Props extends EAProps<TeamStore> {
  store: TeamStore;
  teamId: Maybe<number>;
  currentUserResource: CurrentUserResource;
}

interface MissingTeamId {
  kind: 'missing-team-id';
}

const handleBadStatusRest = (store: TeamStore, err: BadStatus) => {
  switch (err.response.status) {
    case 404:
      store.error('You are not a member of this team.');
      break;
    case 400:
      store.error('Document was rejected by the server. Wrong format.');
      break;
    case 401:
      store.error('You need to be authenticated to perform that operation');
      break;
    case 403:
      store.error('You are not permitted to perform that operation');
      break;
    default:
      handleError(store, err);
  }
};

const handleBadStatus = (store: TeamStore, err: BadStatus) => {
  switch (store.state.kind) {
    case 'error':
    case 'ready':
    case 'loading':
    case 'waiting':
      handleBadStatusRest(store, err);
      break;
    default:
      assertNever(store.state);
  }
};

const handleTeamRequestError = (store: TeamStore) => (error: TeamReactionError) => {
  switch (error.kind) {
    case 'missing-link-error':
      nav(communitiesUrl);
      break;
    case 'missing-team-id':
      store.error('Team ID is not present');
      break;
    case 'bad-status':
      handleBadStatus(store, error);
      break;
    case 'bad-url':
    case 'timeout':
    case 'missing-application-id':
    case 'network-error':
    case 'bad-payload':
      handleError(store, error);
      break;
  }
};

const processTemplateLink =
  (teamId: Maybe<number>) =>
  (link: Link): Task<MissingTeamId, Link> =>
    Task.succeed<MissingTeamId, Maybe<number>>(teamId)
      .andThen(toTask({ kind: 'missing-team-id' } as MissingTeamId))
      .map((v) => ({
        ...link,
        href: link.href.replace('{team_id}', `${v}`),
      }));

const teamLink =
  (teamId: Maybe<number>) =>
  (links: ReadonlyArray<Link>): Task<TeamReactionError, Link> =>
    Task.succeed<TeamReactionError, ReadonlyArray<Link>>(links)
      .andThen(findLinkT('team-template'))
      .andThen(processTemplateLink(teamId))
      .orElse(() => findLinkT('team', links));

class TeamReactions extends ErrorActionableReaction<TeamStore, TeamState, Props> {
  tester = () => this.props.store.state;

  effect = (state: TeamState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        Task.succeed<TeamReactionError, ReadonlyArray<Link>>(this.props.currentUserResource.links)
          .andThen(teamLink(this.props.teamId))
          .andThen(teamEndPoint)
          .fork(handleTeamRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default TeamReactions;
