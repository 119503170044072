import * as React from 'react';
import { T } from '../../Translations';
import * as style from './style.module.css';

const ConferenceRoomInfo: React.FC = () => (
  <span className={style.headerInfo}>
    <T
      kind="Daily Dial In: <phoneNumber>US +1 (209) 503-8039 / CA +1 (226) 807-8039</phoneNumber>"
      phoneNumber={(content) => <strong>{content}</strong>}
    />{' '}
    -{' '}
    <a href="#">
      <T kind="international numbers" />
    </a>
  </span>
);
export default ConferenceRoomInfo;
