import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from '../../../Resource/Types';
import SegmentStore from '../../../SegmentStore';
import { AssignmentDueSegment, HtmlNode } from '../../../SegmentStore/Types';
import { nodeDecoder } from '../../ContentEmbed/Types';
import SegmentEmbed from '../../SegmentEmbed';
import { whenSegmentEmbeddable } from '../../SegmentEmbed/Types';

interface Props {
  segment: AssignmentDueSegment;
  segmentStore: SegmentStore;
  links: ReadonlyArray<Link>;
}

class AssignmentContent extends React.Component<Props, {}> {
  transform = (htmlNode: HtmlNode, index: number) => {
    return nodeDecoder('assignment-due')
      .decodeAny(htmlNode)
      .andThen(whenSegmentEmbeddable)
      .cata({
        Err: (err) => undefined,
        Ok: (node) => (
          <SegmentEmbed
            segmentStore={just(this.props.segmentStore)}
            key={index}
            segment={this.props.segment}
            node={node}
            links={this.props.links}
          />
        ),
      });
  };

  render() {
    return <>{ReactHtmlParser(this.props.segment.content, { transform: this.transform })}</>;
  }
}

export default observer(AssignmentContent);
