import { observer } from 'mobx-react';

interface Props {
  languages: string[];
}

function FormattedLanguage({ languages }: Props) {
  switch (languages.length) {
    case 1:
      return <span className="font-bold">{languages[0]}</span>;
    case 2:
      return (
        <span>
          <span className="font-bold">{languages[0]}</span> <span className="font-normal">and</span>{' '}
          <span className="font-bold">{languages[1]}</span>
        </span>
      );
    default:
      const allButLast = languages.slice(0, -1).join(', ');
      const last = languages[languages.length - 1];
      return (
        <span>
          <span className="font-bold">{allButLast}</span>, <span className="font-normal">and</span>{' '}
          <span className="font-bold">{last}</span>
        </span>
      );
  }
}
export default observer(FormattedLanguage);
