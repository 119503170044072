import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const BreadCrumbSeparatorIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 9 12">
      <path
        stroke={defaultTheme.variables['icon-standard-on-dark']}
        fill={defaultTheme.variables['icon-standard-on-dark']}
        d="m1.736 0 5.632 5.632L7.72 6l-.352.368L1.736 12 1 11.264 6.264 6 1 .736z"
      />
    </SVGIcon>
  );
};

export default observer(BreadCrumbSeparatorIcon);
