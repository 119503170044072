import { observer } from 'mobx-react';
import * as React from 'react';
import { NotifiableStore } from '../../../../ErrorActionableReaction';
import ExecOnlineLogo from '../../ExecOnlineLogo';
import RegistrationHeaderTitleAndDescription from '../../RegistrationHeaderTitleAndDescription';
import { InvitationStore } from '../../SharedOpenEnrollment/InvitationStore';
import { SharedInvitationResource } from '../../SharedOpenEnrollment/SharedInvitationResourceStore/Types';
import AlertMessage from './AlertMessage';

interface Props {
  notifiableStore: NotifiableStore;
  invitationStore: InvitationStore;
  sharedInvitationResource: SharedInvitationResource;
}

const FormHeader: React.FC<Props> = ({
  notifiableStore,
  sharedInvitationResource,
  invitationStore,
  children,
}) => (
  <>
    <div className="my-10">
      <ExecOnlineLogo size="small" />
    </div>
    <div className="my-10">
      <RegistrationHeaderTitleAndDescription sharedInvitationResource={sharedInvitationResource} />
      {children}
    </div>
    <div>
      <AlertMessage invitationStore={invitationStore} notifiableStore={notifiableStore} />
    </div>
  </>
);

export default observer(FormHeader);
