import { observer } from 'mobx-react';
import { Typography } from '../../../../components/UI';
import { NotTranslated, T } from '../../../../Translations';
import { ForgotPasswordField } from '../../../Form';
import MailToLink from '../../MailToLink';

const supportEmail = 'support@execonline.com';

function Notice() {
  return (
    <div className="flex w-[600px] max-w-full flex-col gap-4 md:gap-6">
      <Typography>
        <T
          kind="If this email address is in our database you ..."
          emailAddress={(() => {
            return (
              <MailToLink email={supportEmail}>
                <Typography className="underline" component="span">
                  <NotTranslated text={supportEmail} />
                </Typography>
              </MailToLink>
            );
          })()}
        />
      </Typography>
      <div>
        <ForgotPasswordField />
      </div>
    </div>
  );
}

export default observer(Notice);
