import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import AttachmentUploadStore from '../../../AttachmentUploadStore';
import AttachmentUploadReactions from '../../../AttachmentUploadStore/AttachmentUploadReactions';
import NotificationSource from '../../../NotificationSource';
import SegmentStore from '../../../SegmentStore';
import TeamDiscussionStore from '../../../TeamDiscussionStore';
import TeamDiscussionReactions from '../../../TeamDiscussionStore/TeamDiscussionReactions';
import { TeamDiscussionSegment as segment } from '../../../TeamDiscussionStore/Types';
import { T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import Label from '../../Form/Label';
import AttachmentUploader from '../AttachmentUploads/AttachmentUploader';
import UploadedAttachments from '../AttachmentUploads/UploadedAttachments';
import UploadsToSubmit from '../AttachmentUploads/UploadsToSubmit';
import * as style from './style.module.css';
import TeamDiscussionDetails from './TeamDiscussionDetails';
import TeamDiscussionForm from './TeamDiscussionForm';

interface Props {
  segmentStore: SegmentStore;
  segment: segment;
}

@observer
class TeamDiscussionSegment extends React.Component<Props, {}> {
  private teamDiscussionStore: TeamDiscussionStore;
  private attachmentUploadStore: AttachmentUploadStore = new AttachmentUploadStore();

  constructor(props: Props) {
    super(props);

    this.teamDiscussionStore = new TeamDiscussionStore(
      this.props.segmentStore,
      this.attachmentUploadStore,
      this.props.segment
    );
  }

  componentDidMount() {
    this.teamDiscussionStore.loading();
    this.attachmentUploadStore.loading();
  }

  render() {
    const { segmentStore, segment } = this.props;

    return (
      <div data-test-team-discussion-segment={segment.id}>
        {ReactHtmlParser(segment.content)}
        {segment.teamDiscussion.cata({
          Just: () => <TeamDiscussionDetails segment={segment} store={this.teamDiscussionStore} />,
          Nothing: () => (
            <div className={style.teamDiscussions}>
              <section className={style.section}>
                <TeamDiscussionForm store={this.teamDiscussionStore} />
                <div className={style.field}>
                  <Label className={style.label} labelText="Add an Attachment (optional)" />
                  <AttachmentUploader store={this.attachmentUploadStore} />
                  <div className={style.items}>
                    <UploadedAttachments segment={segment} />
                    <UploadsToSubmit store={this.attachmentUploadStore} />
                  </div>
                </div>
              </section>
              <section className={style.section}>
                <button
                  type="submit"
                  disabled={!this.teamDiscussionStore.areSubmissionParamsValid}
                  className={style.submitButton}
                  onClick={() =>
                    segmentStore.submitAndAdvance(
                      this.teamDiscussionStore.teamDiscussionSubmissionParameters
                    )
                  }
                  data-test-submit-team-discussion-button={true}
                >
                  <T kind="Submit Team Discussion" />
                  <div className={style.buttonIcon}>
                    <ArrowRightIcon />
                  </div>
                </button>
              </section>
            </div>
          ),
        })}
        <AttachmentUploadReactions
          store={this.attachmentUploadStore}
          segmentStore={segmentStore}
          segment={segment}
        />
        <TeamDiscussionReactions store={this.teamDiscussionStore} />
        <NotificationSource store={this.attachmentUploadStore} />
        <NotificationSource store={this.teamDiscussionStore} />
      </div>
    );
  }
}

export default TeamDiscussionSegment;
