import { observer } from 'mobx-react';
import * as React from 'react';
import SegmentStore from '../../../../../SegmentStore';
import { SelectFormField } from '../../../Types';
import CheckboxesSelect from './CheckboxesSelect';
import DropdownSelect from './DropdownSelect';

interface Props {
  segmentStore: SegmentStore;
  fieldDescription: SelectFormField;
  uuid: string;
}

const Select: React.FC<Props> = ({ segmentStore, fieldDescription, uuid }) => {
  return fieldDescription.maxSelections > 1 ? (
    <CheckboxesSelect segmentStore={segmentStore} fieldDescription={fieldDescription} uuid={uuid} />
  ) : (
    <DropdownSelect segmentStore={segmentStore} fieldDescription={fieldDescription} uuid={uuid} />
  );
};

export default observer(Select);
