import clsx from 'clsx';
import { fromNullable } from 'maybeasy';
import * as React from 'react';
import * as style from './style.module.css';

import {
  T,
  TPlainTextKey,
  TranslationsContext,
  TranslationsState,
  translation,
} from '../../../Translations';

interface Props {
  type: string;
  value?: string;
  name: string;
  className?: string;
  onChange(event: React.FormEvent<HTMLInputElement>): void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  autoFocus?: boolean;
  hint?: TPlainTextKey;
  id: string;
  required?: boolean;
  labelText?: string;
  readOnly?: boolean;
  labelClassName?: string;
  disabled?: boolean;
  autoComplete?: string;
  ariaLabel?: TPlainTextKey;
}

const inputImpl = (props: Props) => (ts: TranslationsState) => (
  <>
    <input
      type={props.type}
      readOnly={props.readOnly}
      value={props.value}
      name={props.name}
      className={props.className ? props.className : style.input}
      autoFocus={props.autoFocus}
      id={props.id}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      disabled={props.disabled}
      autoComplete={props.autoComplete}
      data-test-input={props.id}
      aria-label={props.ariaLabel ? translation(props.ariaLabel)(ts) : undefined}
    />
    {fromNullable(props.hint)
      .map((hint) => (
        <div className={clsx('mt-1 text-left text-xs font-medium text-black', style.labelHint)}>
          <T kind={hint} />
        </div>
      ))
      .getOrElse(() => (
        <></>
      ))}
  </>
);

class Input extends React.PureComponent<Props> {
  render() {
    return <TranslationsContext.Consumer>{inputImpl(this.props)}</TranslationsContext.Consumer>;
  }
}

export default Input;
