import * as React from 'react';
import { observer } from 'mobx-react';

import ProgramStore from '../ProgramStore';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';
import UpcomingSessionsHandler from '../Socket/UpcomingSessionsChannel/UpcomingSessionsHandler';
import UpcomingSessionsChannel from '../Socket/UpcomingSessionsChannel';

interface Props {
  channelName: string;
  programStore: ProgramStore;
}

class Refresh extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();

  render() {
    return (
      <>
        <UpcomingSessionsChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <UpcomingSessionsHandler
          programStore={this.props.programStore}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
      </>
    );
  }
}

export default observer(Refresh);
