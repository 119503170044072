import { noop, pipe } from '@kofno/piper';
import { Cancel, Reject, Resolve, Task } from 'taskarian';
import { errorMessage } from '../../../ExceptionHandling';
import { OneTrustError, oneTrustLoadError } from './Types';

declare global {
  export interface Window {
    OneTrust?: unknown;
    OneTrustStub?: unknown;
  }
}

const cookieConsentLoaderScript = (
  key: string,
  resolve: Resolve<void>,
  reject: Reject<OneTrustError>,
): HTMLScriptElement => {
  const script = document.createElement.bind(document)('script');
  script.type = 'text/javascript';
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  script.setAttribute('charset', 'UTF-8');
  script.setAttribute('data-domain-script', key);
  script.onload = () => {
    resolve();
  };
  script.onerror = () => {
    reject(oneTrustLoadError('One Trust Stub failed to load'));
  };
  return script;
};

const cookieConsentBannerScript = (): HTMLScriptElement => {
  const script = document.createElement.bind(document)('script');
  script.innerHTML = `function OptanonWrapper() {}`;
  script.type = 'text/javascript';
  return script;
};

const loadOneTrustStub = (key: string): Task<OneTrustError, void> =>
  new Task<OneTrustError, void>((reject, resolve) => {
    const rejectException = pipe(errorMessage, oneTrustLoadError, reject);
    try {
      if (document.head) {
        document.head.appendChild(cookieConsentBannerScript());
        document.head.appendChild(cookieConsentLoaderScript(key, resolve, reject));
      } else {
        reject(oneTrustLoadError('No document head to load scripts into'));
      }
    } catch (e) {
      rejectException(e);
    }
    return noop;
  });

const checkForGlobalOneTrust = new Task<OneTrustError, void>((reject, resolve) => {
  if (window.OneTrust) {
    resolve();
  } else {
    reject(oneTrustLoadError('OneTrust is not loaded'));
  }
  return noop;
});

const repeatCheck = <T, E>(check: Task<E, T>): Task<never, void> =>
  new Task<never, void>((_, resolve) => {
    let cancel: Cancel | undefined;
    const intervalID = setInterval(() => {
      cancel = check.fork(noop, () => {
        clearInterval(intervalID);
        resolve();
      });
    }, 100);

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
      if (cancel) {
        cancel();
      }
    };
  });

const timeout = (): Task<OneTrustError, never> =>
  new Task<OneTrustError, never>((reject, _) => {
    const timerID = setTimeout(() => {
      reject(oneTrustLoadError('OneTrust took too long to load'));
    }, 2000);

    return () => {
      if (timerID) {
        clearTimeout(timerID);
      }
    };
  });

const waitForOneTrustToLoad = () =>
  Task.race<OneTrustError, void>([timeout(), repeatCheck(checkForGlobalOneTrust)]);

export const loadOneTrust = (key: string): Task<OneTrustError, void> =>
  checkForGlobalOneTrust.orElse(() => loadOneTrustStub(key).andThen(waitForOneTrustToLoad));
