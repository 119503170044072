import { Seconds, seconds } from '@execonline-inc/time';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { now } from 'mobx-utils';
import { active, expired, expiring, State, waiting } from './Types';

class BreakoutTimeRemainingStore {
  @observable
  expiresAt: Maybe<Seconds> = nothing();

  @action
  startTimer = (expiresAt: Seconds) => {
    this.expiresAt = just(expiresAt);
  };

  @computed
  get timeRemaining(): Maybe<Seconds> {
    const diffInSeconds = (secondsSinceEpoch: Seconds) =>
      Math.floor((secondsSinceEpoch.seconds - now(1000)) / 1000);

    return this.expiresAt.map(diffInSeconds).map(s => seconds(s));
  }

  @computed
  get state(): State {
    return just({})
      .assign('timeRemaining', this.timeRemaining)
      .map(({ timeRemaining }) => {
        if (timeRemaining.seconds > 60) {
          return active();
        }
        if (timeRemaining.seconds > 0) {
          return expiring();
        }
        if (timeRemaining.seconds <= 0) {
          return expired();
        }
        return waiting();
      })
      .getOrElseValue(waiting());
  }
}

export default BreakoutTimeRemainingStore;
