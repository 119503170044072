import { assertNever } from '@kofno/piper';
import { string } from 'jsonous';
import { just, Maybe, nothing } from 'maybeasy';
import { getFieldValue } from '../../../../../FormFields';
import ReactionComponent, { RCProps } from '../../../../../ReactionComponent';
import { FormFieldOutputValue } from '../../../Types';
import StringFieldStore from '../Store';
import { State } from '../Store/Types';

interface Props extends RCProps<StringFieldStore> {}

const validateValue = (value: FormFieldOutputValue): Maybe<string> =>
  string.decodeAny(value).map(just).getOrElse(nothing);

class StringFieldReactions extends ReactionComponent<StringFieldStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'initializing':
        state.segmentStore.addEmbeddedFormFieldOutput(store);
        getFieldValue(state.segmentStore.results, store.id)
          .andThen(validateValue)
          .do((value) => store.ready(state.formField, value))
          .elseDo(() => store.ready(state.formField, ''));
        break;
      case 'waiting':
      case 'ready':
      case 'auto-saving':
        break;
      default:
        assertNever(state);
    }
  };
}

export default StringFieldReactions;
