import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { T } from '../../Translations';
import ConjointInput from './ConjointInput';
import * as style from './style.module.css';
import { CustomerCharacteristicList } from './Types';

interface Props {
  store: ConjointAnalysisStore;
}

const CustomerCharacteristics: React.FC<Props> = ({ store }) => {
  return (
    <>
      <br />
      <h2>
        <T kind="STEP 1: ENTER YOUR CUSTOMER CHARACTERISTICS QUESTIONS" />
      </h2>
      <div className={style.stepContent}>
        <p>
          <T kind="What customer characteristics would you like ..." />
        </p>
        <p>
          <T kind="Please enter your four (4) questions and descriptions." />
        </p>
        <h2>
          <T kind="Customer Characteristics" />
        </h2>
      </div>
      <fieldset className={style.segmentationSection}>
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Question 1"
          name="q1"
          hint="e.g. What is your age?"
          required
        />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Description"
          name="q1_desc"
          hint="e.g. 20-29, 30-39, 40-49, 50-59, 60+"
          required
        />
        <br />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Question 2"
          name="q2"
          hint="e.g. What is your location?"
          required
        />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Description"
          name="q2_desc"
          hint="e.g. New York, Boston, Chicago, Miami, San Francisco"
          required
        />
        <br />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Question 3"
          name="q3"
          hint="e.g. What is your gender?"
          required
        />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Description"
          name="q3_desc"
          hint="e.g. Male, Female"
          required
        />
        <br />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Question 4"
          name="q4"
          hint="e.g. What is your role?"
          required
        />
        <ConjointInput<CustomerCharacteristicList>
          onChange={store.setCustomerCharacteristicValue}
          labelText="Description"
          name="q4_desc"
          hint="e.g. Staff, Management, Executive"
          required
        />
      </fieldset>
    </>
  );
};

export default CustomerCharacteristics;
