import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import ViewableAttachmentsStore from '../../../ViewableAttachmentStore';
import * as style from '../../BoxViewer/style.module.css';
import Loader from '../../Loader';
import AttachmentView from './AttachmentView';

interface Props {
  viewableAttachmentsStore: ViewableAttachmentsStore;
}

const ViewableAttachmentView: React.FC<Props> = ({ viewableAttachmentsStore }) => {
  switch (viewableAttachmentsStore.state.kind) {
    case 'ready': {
      return <AttachmentView link={viewableAttachmentsStore.state.link} />;
    }
    case 'loading':
    case 'waiting':
      return (
        <div className={style.loader}>
          <Loader text={just('Your file is loading...')} />
        </div>
      );
    case 'error': {
      return (
        <div>
          <NotTranslated text={viewableAttachmentsStore.state.message} />
        </div>
      );
    }
  }
};

export default observer(ViewableAttachmentView);
