import { observer } from 'mobx-react';
import * as React from 'react';
import { DigitalCertificateAnnouncement } from '../../Announcements/Decoders/Types';
import EmbeddedDigitalCertificateDocument from './EmbeddedDigitalCertificateDocument';

const DigitalCertificateSharedResourcePreview: React.FC<{
  digitalCertificateAnnouncement: DigitalCertificateAnnouncement;
}> = ({ digitalCertificateAnnouncement }) => {
  const announcementSharedResource = digitalCertificateAnnouncement.announcementSharedResource;
  return (
    <EmbeddedDigitalCertificateDocument
      digitalCertificateAnnouncement={digitalCertificateAnnouncement}
      announcementSharedResource={announcementSharedResource}
    />
  );
};

export default observer(DigitalCertificateSharedResourcePreview);
