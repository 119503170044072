import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { ExperienceResource, leadershipCoachingExperience } from '../../../Experience/Types';
import LeadershipCoachingCompetencies from '../LeadershipCoachingCompetencies';

interface Props {
  experienceResource: ExperienceResource;
}

function ExperienceProductDetails({ experienceResource }: Props) {
  return leadershipCoachingExperience(experienceResource.payload)
    .map(() => <LeadershipCoachingCompetencies />)
    .getOrElse(emptyFragment);
}

export default observer(ExperienceProductDetails);
