import { handleSsoError, onFailure } from './Errors';
import Initiate from './Initiate';
import * as TypesModule from './Types';

export const SSO = { Initiate, Errors: { onFailure, handleSsoError } };

export namespace SSO {
  export type State = TypesModule.State;
  export type URLs = TypesModule.URLs;
  export type Error = TypesModule.SsoError;
}
