import { observer } from 'mobx-react';
import { Chip, Typography } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../../../Translations';
import { UpcomingExperience } from '../../../Types';

interface Props {
  experience: UpcomingExperience;
}

function ProgramUpcomingDates({ experience }: Props) {
  return (
    <Chip className="self-start" color="gray" variant="outlined">
      <Typography
        color="inherit"
        className="tracking-[0.2px]"
        variant="caption"
        fontWeight="medium"
        as="span"
      >
        <T
          kind="Enrolled Starts <date/>"
          date={<L localizeable={experience.startOn} format="long-month-day-year" />}
        />
      </Typography>
    </Chip>
  );
}

export default observer(ProgramUpcomingDates);
