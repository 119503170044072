import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TeamSummaryResource } from '../../../TeamsSummaryStore/Types';
import * as style from '../../TeamSummary/style.module.css';
import { Maybe } from 'maybeasy';
import { NonEmptyList } from 'nonempty-list';
import { TeamMemberResource } from '../../../TeamsStore/Types';

interface Props {
  teamSummaryResource: TeamSummaryResource;
  overflowedMembers: Maybe<NonEmptyList<TeamMemberResource>>;
}

const CommunityMembersCountAfterTruncate: React.FC<Props> = ({
  teamSummaryResource,
  overflowedMembers,
}) => {
  return (
    <>
      {overflowedMembers
        .map((overflowedMembers) => (
          <span className={style.overflowLink}>
            <Link to={`/teams/${teamSummaryResource.payload.id}`} data-test-team-link="Team">
              {`+${overflowedMembers.toArray().length}`}
            </Link>
          </span>
        ))
        .getOrElse(() => (
          <></>
        ))}
    </>
  );
};

export default observer(CommunityMembersCountAfterTruncate);
