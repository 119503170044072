import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const ShareFileIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 20 26">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        d="M0 0v26h20V6.594l-.281-.313-6-6L13.406 0H0Zm2 2h10v6h6v16H2V2Zm12 1.438L16.563 6H14V3.437Zm-3.719 6.843L8.844 11.72 11.125 14H5v2h6.125l-2.281 2.281 1.437 1.438 4-4L14.97 15l-.688-.719-4-4Z"
      />
    </SVGIcon>
  );
};

export default observer(ShareFileIcon);
