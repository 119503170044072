import * as React from 'react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

interface Props {
  percentComplete: number;
}

const StartedModuleGraphic: React.FC<Props> = ({ percentComplete }) => {
  const strokeDasharray = `${percentComplete} ${100 - percentComplete}`;
  return (
    <SVGIcon viewBox="0 0 42 42">
      <circle
        stroke={defaultTheme.variables['info-bg-success-muted']}
        strokeWidth="7"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
      />
      <circle
        stroke={defaultTheme.variables['info-bg-success']}
        strokeWidth="7"
        strokeDasharray={strokeDasharray}
        strokeDashoffset="25"
        cx="21"
        cy="21"
        r="15.91549430918954"
        fill="transparent"
      />
    </SVGIcon>
  );
};

export default StartedModuleGraphic;
