import * as React from 'react';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import RenderContext from '../../RenderContext';
import TeamsRendererImpl from './TeamsRendererImpl';

interface Props {
  programDetailResource: ProgramDetailResource;
}

const TeamsRenderer: React.FC<Props> = ({ programDetailResource }) => (
  <RenderContext
    render={(renderContext) => (
      <TeamsRendererImpl
        programDetailResource={programDetailResource}
        position={renderContext.position}
      />
    )}
  />
);

export default TeamsRenderer;
