import { observer } from 'mobx-react';
import * as React from 'react';
import CalendarRefresh from '../../CalendarRefresh';
import CalendarStore from '../../CalendarStore';
import CalendarReactions from '../../CalendarStore/CalendarReactions';
import { Linkable } from '../../Resource/Types';
import WithPusherSettings from '../../ToolingsStore/WithPusherSettings';
import UpdateCalendarState from './UpdateCalendarState';

interface Props {
  linkable: Linkable;
  headingLevel: 2 | 3;
}

class Calendar extends React.Component<Props, {}> {
  private calendarStore: CalendarStore;

  constructor(props: Props) {
    super(props);
    this.calendarStore = new CalendarStore(this.props.linkable);
  }

  render() {
    return (
      <>
        <CalendarReactions store={this.calendarStore} fireImmediately={true} />
        <UpdateCalendarState store={this.calendarStore} headingLevel={this.props.headingLevel} />
        <WithPusherSettings
          children={({ payload }) => (
            <CalendarRefresh
              calenderStore={this.calendarStore}
              channelName={payload.channels.calendarEventsChannel}
            />
          )}
        />
      </>
    );
  }
}

export default observer(Calendar);
