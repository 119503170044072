import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import TeamEventsOverview from './TeamEventsOverview';
import ProgramOverview from './ProgramOverview';
import TeamMembersOverview from './TeamMembersOverview';
import JoinTeamConference from './JoinTeamConference';

interface Props {
  communityTeamResource: CommunityTeamResource;
  communitiesStore: CommunitiesStore;
}

const TeamSummaryOverview: React.FC<Props> = ({ communityTeamResource, communitiesStore }) => (
  <div className={style.overview} data-test-team-overview={true}>
    <TeamEventsOverview communityTeamResource={communityTeamResource} />
    <ProgramOverview communityTeamResource={communityTeamResource} />
    <TeamMembersOverview
      communityTeamResource={communityTeamResource}
      communitiesStore={communitiesStore}
    />
    <JoinTeamConference communityTeamResource={communityTeamResource} />
  </div>
);

export default observer(TeamSummaryOverview);
