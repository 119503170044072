import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { BreadcrumbItem, Button, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { discoveryPortalRoute } from '../../../../../../ClientRoutes';
import { T } from '../../../../../../Translations';
import NativeLink from '../../../../../NativeLink';
import { RegistrationInvitationKind } from '../../../../Common/Experience/Types';

interface Props {
  invitationUuid: string;
  kind: RegistrationInvitationKind;
  name: Maybe<AlreadyTranslatedText>;
}

function ExploreCrumb({ invitationUuid, kind, name }: Props) {
  switch (kind) {
    case 'direct-enrollment':
      return emptyFragment();
    case 'new-program-family-shared-open-enrollment':
    case 'open-enrollment':
    case 'preview-only':
    case 'program-family-shared-open-enrollment':
    case 'shared-open-enrollment':
      return (
        <BreadcrumbItem color="inherit">
          <NativeLink to={discoveryPortalRoute(invitationUuid)}>
            {(onClick) => (
              <Button className="p-0 text-inherit" variant="link" onClick={onClick}>
                <Typography variant="bodyMedium" fontWeight="light" color="inherit">
                  {name
                    .map((text) => <AlreadyTranslated content={text} />)
                    .getOrElse(() => (
                      <T kind="Explore" />
                    ))}
                </Typography>
              </Button>
            )}
          </NativeLink>
        </BreadcrumbItem>
      );
  }
}

export default observer(ExploreCrumb);
