import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import ProgramTypeStore from './ProgramTypeStore';
import { ProgramFilter, ProgramFilterType, ProgramType } from './ProgramTypeStore/Types';

interface Props {
  coachTeamsSummaryStore: coachTeamsSummaryStore;
  programTypeStore: ProgramTypeStore;
  programFilter: ProgramFilter;
  programFilterType: ProgramFilterType;
  programType: ProgramType;
  programFilterStyle: string;
  selectedProgramFilterStyle: string;
}

const setProgramFilter = (
  programFilterType: ProgramFilterType,
  programTypeStore: ProgramTypeStore
) => {
  switch (programFilterType) {
    case 'AEP':
      programTypeStore.setProgramTypeAep();
      break;
    case 'MSuite':
      programTypeStore.setProgramTypeMsuite();
      break;
    case 'ALP':
      programTypeStore.setProgramTypeAlp();
      break;
    case 'ALL':
      programTypeStore.setProgramTypeAll();
  }
};

const changeProgramFilter = (
  coachTeamsSummaryStore: coachTeamsSummaryStore,
  programFilterType: ProgramFilterType,
  programFilter: ProgramFilter,
  programType: ProgramType,
  programTypeStore: ProgramTypeStore
) => {
  when(programTypeStore.state.kind !== programFilter, programFilterType).do((programFilterType) => {
    setProgramFilter(programFilterType, programTypeStore);
    coachTeamsSummaryStore.addFilter('offering_type', programType);
    coachTeamsSummaryStore.loading();
  });
};

const ProgramTypeFilterItem: React.FC<Props> = ({
  coachTeamsSummaryStore,
  programTypeStore,
  programFilter,
  programFilterType,
  programType,
  programFilterStyle,
  selectedProgramFilterStyle,
}) => (
  <div
    className={when(programTypeStore.state.kind === programFilter, selectedProgramFilterStyle)
      .map((selectedProgramFilterStyle) => selectedProgramFilterStyle)
      .getOrElseValue(programFilterStyle)}
    onClick={() =>
      changeProgramFilter(
        coachTeamsSummaryStore,
        programFilterType,
        programFilter,
        programType,
        programTypeStore
      )
    }
  >
    {programFilterType}
  </div>
);

export default observer(ProgramTypeFilterItem);
