import { always } from '@kofno/piper';
import { fromEmpty } from 'maybeasy';
import { findLink } from '../../../../../LinkyLinky';
import { ExperienceResource } from '../Types';

export const getPrimaryCompetencies = (experienceResource: ExperienceResource) => {
  const { links, payload } = experienceResource;

  return findLink('attributes', links).map(always(payload.primaryCompetencies)).andThen(fromEmpty);
};
