import { observer } from 'mobx-react';
import * as React from 'react';
import { ActiveProgram, ActiveProgramModule } from '../../../../../ProgramStore/Types';
import * as style from '../../../../ProgramSummary/style.module.css';
import CurrentCohortModule from '../CurrentCohortModule';
import CurrentModule from '../CurrentModule';
import ModuleIcon from '../ModuleIcon';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
  percentIncomplete: number;
  percentComplete: number;
  expectedPercentComplete: number;
}

const ScheduledProgramModuleProgressContent: React.FC<Props> = ({
  programModule,
  programDetail,
  percentIncomplete,
  percentComplete,
  expectedPercentComplete,
}) => {
  const dangerThresholdDays = 13;
  const cohortProgressLineStyle =
    programDetail.daysBehind.getOrElseValue(0) > dangerThresholdDays
      ? style.cohortProgressLineDanger
      : style.cohortProgressLine;
  return (
    <>
      <div className={style.progressLine}>
        <div className={style.progressLine} data-complete={percentComplete} />
        <CurrentModule programModule={programModule} programDetail={programDetail} />
        <div
          className={`${style.progressLine} ${cohortProgressLineStyle}`}
          data-complete={expectedPercentComplete}
        />
        <CurrentCohortModule programModule={programModule} programDetail={programDetail} />
        <div className={style.progressLine} data-incomplete={percentIncomplete} />
      </div>
      <div className={style.moduleIcon}>
        <ModuleIcon
          programModule={programModule}
          daysBehind={programDetail.daysBehind.getOrElseValue(0)}
        />
      </div>
    </>
  );
};

export default observer(ScheduledProgramModuleProgressContent);
