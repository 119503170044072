import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  termsAcceptance: boolean;
  emailAddress: string;
}

export interface ReadyWithClientErrors {
  kind: 'ready-with-client-errors';
  termsAcceptance: boolean;
  message: TPlainTextKey;
  emailAddress: string;
}

export interface ReadyWithServerErrors {
  kind: 'ready-with-server-errors';
  termsAcceptance: boolean;
  message: AlreadyTranslatedText;
  emailAddress: string;
}

export interface Creating {
  kind: 'creating';
  emailAddress: string;
}

export interface Created {
  kind: 'created';
  emailAddress: string;
}

export interface ServerError {
  kind: 'server-error';
  message: AlreadyTranslatedText;
  termsAcceptance: boolean;
  emailAddress: string;
}

export interface Error {
  kind: 'error';
  message: TPlainTextKey;
  termsAcceptance: boolean;
  emailAddress: string;
}

export type State =
  | Waiting
  | Ready
  | ReadyWithClientErrors
  | ReadyWithServerErrors
  | Creating
  | Created
  | Error
  | ServerError;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const ready = (termsAcceptance: boolean, emailAddress: string): Ready => ({
  kind: 'ready',
  termsAcceptance,
  emailAddress,
});

export const readyWithClientErrors = (
  termsAcceptance: boolean,
  message: TPlainTextKey,
  emailAddress: string
): ReadyWithClientErrors => ({
  kind: 'ready-with-client-errors',
  termsAcceptance,
  message,
  emailAddress,
});

export const readyWithServerErrors = (
  termsAcceptance: boolean,
  message: AlreadyTranslatedText,
  emailAddress: string
): ReadyWithServerErrors => ({
  kind: 'ready-with-server-errors',
  termsAcceptance,
  message,
  emailAddress,
});

export const creating = (emailAddress: string): Creating => ({
  kind: 'creating',
  emailAddress,
});

export const created = (emailAddress: string): Created => ({
  kind: 'created',
  emailAddress,
});

export const error = (
  message: TPlainTextKey,
  termsAcceptance: boolean,
  emailAddress: string
): Error => ({
  kind: 'error',
  message,
  termsAcceptance,
  emailAddress,
});

export const serverError = (
  message: AlreadyTranslatedText,
  termsAcceptance: boolean,
  emailAddress: string
): ServerError => ({
  kind: 'server-error',
  message,
  termsAcceptance,
  emailAddress,
});
