import { just, Maybe, nothing } from 'maybeasy';
import { AppyError } from '../Appy';
import { MissingLinkError } from '../LinkyLinky';

export interface HandEventOrder {
  kind: 'hand-event-order';
  value: number;
}

export interface HandEventBottom {
  kind: 'hand-event-bottom';
}

export type HandEventClock = HandEventOrder | HandEventBottom;

export const handEventBottom = (): HandEventBottom => ({
  kind: 'hand-event-bottom',
});

export const handEventOrder = (value: number): HandEventOrder => ({
  kind: 'hand-event-order',
  value,
});

export const version = (value?: number) =>
  typeof value === 'undefined' ? handEventBottom() : handEventOrder(value);

export const whenUpdatable = (
  oldClock: HandEventClock,
  newClock: HandEventClock
): Maybe<HandEventClock> => {
  const oldClockOrder = (oldClock: HandEventOrder): Maybe<HandEventClock> => {
    switch (newClock.kind) {
      case 'hand-event-bottom':
        return just(newClock);
      case 'hand-event-order':
        return newClock.value > oldClock.value ? just(newClock) : nothing();
    }
  };
  switch (oldClock.kind) {
    case 'hand-event-bottom':
      return just(newClock);
    case 'hand-event-order':
      return oldClockOrder(oldClock);
  }
};

export interface HandDown {
  kind: 'hand-down';
  clock: HandEventClock;
}

export interface HandUp {
  kind: 'hand-up';
  clock: HandEventClock;
}

export interface RaisingHand {
  kind: 'raising-hand';
  clock: HandEventBottom;
}

export interface LoweringHand {
  kind: 'lowering-hand';
  clock: HandEventBottom;
}

export interface OtherLoweringHand {
  kind: 'other-lowering-hand';
  clock: HandEventBottom;
}

export type HandRaiseState = HandDown | HandUp | RaisingHand | LoweringHand | OtherLoweringHand;

export type HandRaiseStatus = HandRaiseState['kind'];

export const handDown = (clock: HandEventClock): HandDown => ({
  kind: 'hand-down',
  clock,
});

export const handUp = (clock: HandEventClock): HandUp => ({
  kind: 'hand-up',
  clock,
});

export const raisingHand = (): RaisingHand => ({
  kind: 'raising-hand',
  clock: { kind: 'hand-event-bottom' },
});

export const loweringHand = (): LoweringHand => ({
  kind: 'lowering-hand',
  clock: { kind: 'hand-event-bottom' },
});

export const otherLoweringHand = (): OtherLoweringHand => ({
  kind: 'other-lowering-hand',
  clock: { kind: 'hand-event-bottom' },
});

export interface MissingConferenceIdError {
  kind: 'missing-conference-id-error';
}

export const missingConferenceIdError = (): MissingConferenceIdError => ({
  kind: 'missing-conference-id-error',
});

export type HandActionError = AppyError | MissingLinkError | MissingConferenceIdError;
