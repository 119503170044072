import { Maybe } from 'maybeasy';
import { LastReadMessage } from '../Conference/Types';
import { Error } from '../ErrorHandling';

export interface Ready {
  kind: 'ready';
  message: Maybe<LastReadMessage>;
}

export const ready = (message: Maybe<LastReadMessage>): Ready => ({
  kind: 'ready',
  message,
});

export interface LastSeenMessage {
  kind: 'last-seen-message';
  message: LastReadMessage;
}

export const updateLastReadMessage = (message: LastReadMessage): LastSeenMessage => ({
  kind: 'last-seen-message',
  message,
});

export type LastReadMessageState = Ready | Error | LastSeenMessage;
