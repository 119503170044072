import { observer } from 'mobx-react';
import * as React from 'react';
import LockedAccessStore from '../LockedAccessStore';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import Instructions from './Instructions';
import Notice from './Notice';

interface Props {
  registerResource: RegisterResource;
  store: LockedAccessStore;
}

const LockedAccess: React.FC<Props> = ({ store, registerResource }) => (
  <>
    <RegistrationView allowLanguagePicker={true}>
      <RegistrationFormHeader
        notifiableStore={store}
        sharedInvitationResource={registerResource.payload.sharedInvitation}
      >
        <Instructions
          organizationName={
            registerResource.payload.sharedInvitation.payload.organization.payload.name
          }
        />
      </RegistrationFormHeader>
      <Notice />
    </RegistrationView>
  </>
);

export default observer(LockedAccess);
