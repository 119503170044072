import { mapMaybe } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';

export function whenAny<T, S>(
  fn: (v: T) => Maybe<S>,
  values: ReadonlyArray<T>
): Maybe<NonEmptyList<S>>;

export function whenAny<T, S>(
  fn: (v: T) => Maybe<S>
): (values: ReadonlyArray<T>) => Maybe<NonEmptyList<S>>;

export function whenAny<T, S>(fn: (v: T) => Maybe<S>, values?: ReadonlyArray<T>) {
  const doit = (values: ReadonlyArray<T>) => fromArrayMaybe(mapMaybe(fn, values));
  return typeof values === 'undefined' ? doit : doit(values);
}

/**
 * `Array.prototype.includes()` is not available for our current TypeScript target of "es5".
 * So this will provide the functionality in a declarative functional form until native
 * support is possible.
 */
export function includes<T>(value: T, list: ReadonlyArray<T>): boolean;
export function includes<T>(value: T): (list: ReadonlyArray<T>) => boolean;
export function includes<T>(value: T, list?: ReadonlyArray<T>) {
  const doit = (list: ReadonlyArray<T>): boolean => list.indexOf(value) > -1;
  return typeof list === 'undefined' ? doit : doit(list);
}

export function equalArrays<T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>): boolean {
  return a.length === b.length && a.every((element, index) => element === b[index]);
}
