import * as React from 'react';
import EmptyResourcePage from '../../EmptyResourcePage';

const EmptyState: React.FC = () => (
  <EmptyResourcePage
    title="You are currently not on a team"
    description="Your Team page will include a listing of participants on your ..."
  />
);

export default EmptyState;
