import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { T } from '../../../Translations';
import { translation, TranslationsContext } from '../../../Translations';
import * as style from './style.module.css';

interface Props {
  closeAndDismiss: () => void;
  children: React.ReactNode;
}

class Modal extends React.Component<Props> {
  portal: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.portal = document.createElement('div');
    this.portal.id = `Survey portal`;
    const root = document.getElementById('root');
    if (root) {
      root.appendChild(this.portal);
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (root && this.portal) {
      root.removeChild(this.portal);
    }
  }

  render() {
    const { closeAndDismiss, children } = this.props;
    return ReactDOM.createPortal(
      <TranslationsContext.Consumer>
        {(ts) => (
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              fallbackFocus: '#modal-container',
              allowOutsideClick: true,
            }}
          >
            <div
              className={style.modal}
              data-test-modal={true}
              aria-modal={true}
              id="modal-container"
              tabIndex={-1}
            >
              <div className={style.body} data-test-modal-body={true}>
                <span className={style.text}>
                  <T kind="Save for later" />
                </span>
                <button
                  className={style.closeButton}
                  onClick={closeAndDismiss}
                  data-test-close-button={true}
                  aria-label={translation('Save for later')(ts)}
                  role="button"
                  autoFocus
                >
                  &times;
                </button>
                <div className={style.content} data-test-modal-content={true}>
                  {children}
                </div>
              </div>
              <div className={style.shade} data-test-modal-shade={true} />
            </div>
          </FocusTrap>
        )}
      </TranslationsContext.Consumer>,
      this.portal
    );
  }
}

export default Modal;
