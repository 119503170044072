import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamResource } from '../../TeamsStore/Types';
import { T } from '../../Translations';
import * as style from '../TeamProfile/style.module.css';
import TeamBreadCrumbLink from './TeamBreadCrumbLink';

interface Props {
  teamResource: TeamResource;
  teamId: number;
}

const Breadcrumb: React.FC<Props> = ({ teamId, teamResource }) => (
  <div className={style.teamListHeader}>
    <TeamBreadCrumbLink teamResource={teamResource} teamId={teamId} />
    <div className={style.teamSubName}>
      <T kind="Member List" />
    </div>
  </div>
);

export default observer(Breadcrumb);
