import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../../CurrentUser/Store';
import Login from '../../../Login';
import ThemeContext, { currentGlobalTheme } from '../../../Themes/ThemeContext';
import AuthenticatedView from '../../AuthenticatedView';
import { AutoLaunchableContext } from '../../AutoLaunchable/AutoLaunchableContext';
import Loader from '../../Loader';

const CurrentUserStateView: React.FC = () => {
  switch (currentUserStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'logging-out':
      return <Loader />;
    case 'anonymous':
    case 'errored':
      return (
        <ThemeContext.Provider value={currentGlobalTheme()}>
          <Login />
        </ThemeContext.Provider>
      );
    case 'ready':
    case 'refreshing':
      return (
        <AutoLaunchableContext.Provider value="learning_platform">
          <AuthenticatedView state={currentUserStore.state} />
        </AutoLaunchableContext.Provider>
      );
  }
};

export default observer(CurrentUserStateView);
