import * as React from 'react';
import * as style from '../style.module.css';

interface Props {
  count: number;
}
const Badge: React.FC<Props> = ({ count }) => {
  if (count === 0) {
    return null;
  }
  return (
    <div className={style.tabBadge} data-test-tab-badge={count}>
      {count}
    </div>
  );
};

export default Badge;
