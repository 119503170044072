import { observer } from 'mobx-react';
import { ActiveProgram } from '../../../../../../../ProgramStore/Types';
import { T } from '../../../../../../../Translations';
import * as style from '../../../../../../ProgramSummary/style.module.css';

interface Props {
  programDetail: ActiveProgram;
}

const OnDemandCohortPosition = ({ programDetail }: Props) => {
  switch (programDetail.accessKind) {
    case 'current-unlocked':
      return (
        <div className={style.onDemandProgressLine} data-current={true}>
          <div className={style.onDemandCohortProgressTooltip} data-test-progress-tooltip={true}>
            <span>
              <T kind="Expected progress" />
            </span>
            <div className={style.onDemandCohortProgressTooltipArrow} />
          </div>
        </div>
      );
    case 'ended-unlocked':
    case 'ended-warning':
      return programDetail.daysSinceProgramEnd
        .map(() => (
          <>
            <div
              className={style.onDemandCohortProgressWarningTooltip}
              data-test-progress-tooltip={true}
            >
              <span>
                <T kind="The recommended completion time period has passed. Please continue ..." />
              </span>
              <div className={style.onDemandCohortProgressWarningTooltipArrow} />
            </div>
          </>
        ))
        .getOrElseValue(<></>);
    case 'current-warning':
      return (
        <>
          <div
            className={style.onDemandCohortProgressWarningTooltip}
            data-test-progress-tooltip={true}
          >
            <span>
              <T kind="Reminder: experience is expiring soon" />
            </span>
            <div className={style.onDemandCohortProgressWarningTooltipArrow} />
          </div>
        </>
      );
    case 'current-danger':
      return (
        <>
          <div
            className={style.onDemandCohortProgressDangerTooltip}
            data-test-progress-tooltip={true}
          >
            <span>
              <T kind="Reminder: experience is expiring soon" />
            </span>
            <div className={style.onDemandCohortProgressDangerTooltipArrow} />
          </div>
        </>
      );
    case 'ended-danger':
      return programDetail.daysSinceProgramEnd
        .map((days) => (
          <>
            <div
              className={style.onDemandCohortProgressDangerTooltip}
              data-test-progress-tooltip={true}
            >
              <span>
                <T kind="Warning: your experience ended {{count}} day ago" count={days} />
              </span>
              <div className={style.onDemandCohortProgressDangerTooltipArrow} />
            </div>
          </>
        ))
        .getOrElseValue(<></>);
  }
};

export default observer(OnDemandCohortPosition);
