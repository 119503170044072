import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  cn,
  emptyFragment,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { BackgroundImage } from '../../../../../components/UI';
import { localPublicUrl } from '../../../../MobileImageBackground/Types';
import { Experience } from '../../../Common/Experience/Types';
import EnrollmentCardBody from './EnrollmentCardBody';
import EnrollmentCardFooter from './EnrollmentCardFooter';
import EnrollmentCardHeader from './EnrollmentCardHeader';

interface Props {
  className?: string;
  experience: Experience;
}

function EnrollmentCard({ className, experience }: Props) {
  switch (experience.kind) {
    case 'beginnable':
    case 'resumable':
    case 'returnable':
    case 'graduated':
      return (
        <Card className={cn('w-86 bg-white md:w-88', className)} radius="md">
          <CardHeader className="px-0.5 pb-0 pt-0.5">
            <BackgroundImage
              className="rounded-t-md bg-[length:100vw_100vh]"
              src={localPublicUrl('learn-more-graduated-header.svg')}
            >
              <div className="px-4 pb-5 pt-4 text-center">
                <EnrollmentCardHeader experience={experience} />
              </div>
            </BackgroundImage>
          </CardHeader>
          <BackgroundImage
            className="bg-auto bg-right [background-position-y:1rem]"
            src={localPublicUrl('learn-more-card.svg')}
          >
            <CardBody className="min-h-32 px-6 pt-6">
              <EnrollmentCardBody experience={experience} />
            </CardBody>
          </BackgroundImage>
          <CardFooter className="px-6 pb-8 empty:hidden">
            <EnrollmentCardFooter experience={experience} />
          </CardFooter>
        </Card>
      );
    case 'commerce-enrollable':
    case 'upcoming':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
  }
}

export default observer(EnrollmentCard);
