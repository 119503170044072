import { map, take } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ActiveProgramModule,
  analyticsEventLabel,
  Program,
  upcomingModules,
} from '../../../../../ProgramStore/Types';
import { L, NotTranslated, T } from '../../../../../Translations';
import Modal from '../../../../Modal';
import PositionContext from '../../../../PositionContext';
import * as style from '../../../../ProgramSummary/style.module.css';
import AnalyticsEvent from '../../../../Tooling/GoogleTagManagerTooling/AnalyticsEvent';
import CohortProgramLabel from './CohortProgramLabel';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: Program;
  onClose: () => void;
}

const NextModules = observer(({ programDetail }: { programDetail: Program }) =>
  upcomingModules(programDetail)
    .map(take(3))
    .map(
      map(({ id, label, title, endsOn }) => (
        <p key={id}>
          <strong>
            <NotTranslated
              text={label.cata({
                Just: (value: string) => `${value} - `,
                Nothing: () => '',
              })}
            />
            <NotTranslated text={`${title}:`} />{' '}
          </strong>
          {endsOn
            .map((endsOn) => <L localizeable={endsOn} format="short-month-day-year" />)
            .getOrElseValue(<></>)}
        </p>
      ))
    )
    .map((elements) => <>{elements}</>)
    .getOrElseValue(<></>)
);

class ProgramModal extends React.Component<Props> {
  render() {
    const { programModule, programDetail, onClose } = this.props;

    return (
      <Modal
        close={onClose}
        title={<T kind="Keeping Pace with Your Cohort" />}
        data-test-module-id={programModule.id}
      >
        <PositionContext.Consumer>
          {(value) =>
            analyticsEventLabel(value)
              .map((label) => (
                <AnalyticsEvent
                  hitType="event"
                  eventCategory="ProgressCohortPopup"
                  eventAction="click"
                  eventLabel={label}
                />
              ))
              .getOrElseValue(<span />)
          }
        </PositionContext.Consumer>
        <div className={style.cohortModuleContent}>
          <p>
            <T
              kind="Your cohort is made up of the global community ..."
              name={<CohortProgramLabel programDetail={programDetail} />}
            />
          </p>
          <div className={style.warning}>
            <p>
              <T kind="The cohort has reached this ExecOnline-recommended ..." />
            </p>
          </div>
          <p>
            <T kind="Of course, professional and other obligations ..." />
          </p>
          <div className={style.upcomingModuleDates}>
            <h4>
              <T kind="Due date for upcoming modules" />
            </h4>
            <NextModules programDetail={programDetail} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default observer(ProgramModal);
