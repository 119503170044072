import { observer } from 'mobx-react';
import { useExperienceContext } from '../ExperienceContext';
import ExperiencePrice from './ExperiencePrice';
import ExperienceProductBadge from './ExperienceProductBadge';

function ExperienceFooter() {
  const { experienceResource } = useExperienceContext();

  return (
    <div className="flex w-full items-center justify-between">
      <ExperienceProductBadge experienceResource={experienceResource} />
      <ExperiencePrice experienceResource={experienceResource} />
    </div>
  );
}

export default observer(ExperienceFooter);
