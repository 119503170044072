import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ChatDeleteIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 13 16">
      <path
        fill={defaultTheme.variables['icon-standard-on-light']}
        d="M5.032 0c-.329 0-.666.115-.904.354a1.28 1.28 0 0 0-.354.904v.63H0v1.257h.629V13.21c0 1.034.853 1.887 1.887 1.887h7.549a1.897 1.897 0 0 0 1.887-1.887V3.145h.629V1.887H8.806v-.629c0-.33-.115-.666-.353-.904A1.28 1.28 0 0 0 7.548 0H5.032Zm0 1.258h2.516v.63H5.032v-.63ZM1.887 3.145h8.807V13.21c0 .349-.28.629-.63.629H2.517a.627.627 0 0 1-.629-.63V3.146Zm1.258 1.887v6.92h1.258v-6.92H3.145Zm2.516 0v6.92H6.92v-6.92H5.661Zm2.516 0v6.92h1.258v-6.92H8.177Z"
      />
    </SVGIcon>
  );
};

export default observer(ChatDeleteIcon);
