import { fromNullable } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { now } from 'mobx-utils';
import { ScheduledToast } from './Types';

interface Starting {
  kind: 'starting';
  scheduledToast: ScheduledToast;
}

interface Dismissed {
  kind: 'dismissed';
  scheduledToast: ScheduledToast;
}

interface Active {
  kind: 'active';
  scheduledToast: ScheduledToast;
}

interface Inactive {
  kind: 'inactive';
  scheduledToast: ScheduledToast;
}

const starting = (scheduledToast: ScheduledToast): Starting => ({
  kind: 'starting',
  scheduledToast,
});

const active = (scheduledToast: ScheduledToast): Active => ({
  kind: 'active',
  scheduledToast,
});

const inactive = (scheduledToast: ScheduledToast): Inactive => ({
  kind: 'inactive',
  scheduledToast,
});

const dismissed = (scheduledToast: ScheduledToast): Dismissed => ({
  kind: 'dismissed',
  scheduledToast,
});

export type State = Starting | Active | Inactive | Dismissed;

class ScheduledToastStore {
  @observable
  scheduledToast: ScheduledToast;

  @observable
  dismissed: boolean;

  constructor(scheduledToast: ScheduledToast) {
    this.scheduledToast = scheduledToast;
    this.dismissed = fromNullable(localStorage.getItem('dismissedToasts'))
      .map(dns => dns.indexOf(this.scheduledToast.uuid) >= 0)
      .getOrElseValue(false);
  }

  @action
  dismiss = () => {
    this.dismissed = true;
  };

  @computed
  get state(): State {
    const time = now(6000);

    if (this.dismissed) {
      return dismissed(this.scheduledToast);
    }
    if (
      this.scheduledToast.activeAt.getTime() < time &&
      this.scheduledToast.startAt.getTime() > time
    ) {
      return starting(this.scheduledToast);
    }

    if (
      this.scheduledToast.startAt.getTime() < time &&
      this.scheduledToast.endAt.getTime() > time
    ) {
      return active(this.scheduledToast);
    }

    return inactive(this.scheduledToast);
  }
}

export default ScheduledToastStore;
