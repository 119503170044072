import { cn, emptyFragment } from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { HTMLAttributes } from 'react';

function AdvanceButtonContent({ className, children }: HTMLAttributes<HTMLDivElement>) {
  return fromNullable(children)
    .map((icon) => (
      <div
        className={cn(
          'absolute bottom-0 top-0 flex h-full w-4 items-center justify-center bg-black/10 px-2.5',
          className,
        )}
      >
        {icon}
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(AdvanceButtonContent);
