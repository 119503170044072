import { observer } from 'mobx-react';
import ThemeContext from '../../../Themes/ThemeContext';
import { lightTheme } from '../../../Native/styles';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import EventsStore from '../../../EventsStore';
import CurrentEvents from '../../CurrentEvents';
import * as platformStyle from '../../Platform/style.module.css';
import SupplementalCalendarRouter from './SupplementalCalendarRouter';

interface Props {
  eventsStore: EventsStore;
}

function SupplementalPanel({ eventsStore }: Props) {
  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <aside className={platformStyle.supPanel2024}>
          <ThemeContext.Provider value={lightTheme}>
            <CurrentEvents eventsStore={eventsStore} />
            <SupplementalCalendarRouter
              eventsStore={eventsStore}
              currentUserResource={currentUserResource}
            />
          </ThemeContext.Provider>
        </aside>
      )}
    />
  );
}

export default observer(SupplementalPanel);
