import * as React from 'react';
import { ProgramSegment } from '../../../../../../ProgramStore/Types';
import CompleteSegment from './CompleteSegment';
import CurrentSegment from './CurrentSegment';
import InaccessibleSegment from './InaccessibleSegment';
import UnstartedSegment from './UnstartedSegment';

interface Props {
  segment: ProgramSegment;
}

type RenderStatus = 'Current' | ProgramSegment['status'];

const renderStatus = (segment: ProgramSegment): RenderStatus =>
  segment.isCurrentSegment ? 'Current' : segment.status;

const Segment: React.StatelessComponent<Props> = ({ segment }) => {
  switch (renderStatus(segment)) {
    case 'Complete':
      return <CompleteSegment segment={segment} />;
    case 'Inaccessible':
      return <InaccessibleSegment segment={segment} />;
    case 'Current':
    case 'Started':
      return <CurrentSegment segment={segment} />;
    case 'Accessible':
      return <UnstartedSegment segment={segment} />;
  }
};
export default Segment;
