import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';
import { teamConferenceUrl } from '../../ClientRoutes';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import { when } from '@execonline-inc/maybe-adapter';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const JoinTeamConference: React.FC<Props> = ({ communityTeamResource }) =>
  when<React.ReactElement>(
    communityTeamResource.payload.hasConferenceRoom,
    <Link
      to={teamConferenceUrl(communityTeamResource.payload.id)}
      data-test-team-conference-link={true}
    >
      <button className={style.mobileJoinButton}>
        <T kind="Join Team Conference Room" />
        <div className={style.buttonIcon}>
          <ArrowRightIcon />
        </div>
      </button>
    </Link>
  ).getOrElse(() => <></>);

export default observer(JoinTeamConference);
