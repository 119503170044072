import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import Person from '../../../../../components/Person';
import { PersonStoreContract } from '../../../../../Person/types';
import { profileStore } from '../../../../../ProfileStore';
import { ActiveProgram, ActiveProgramModule } from '../../../../../ProgramStore/Types';
import { T } from '../../../../../Translations';
import * as style from '../../../../ProgramSummary/style.module.css';

interface Props {
  programModule: ActiveProgramModule;
  programDetail: ActiveProgram;
}

const checkWarningLimit = (daysBehind: number): Maybe<number> => {
  return daysBehind >= 7 ? just(daysBehind) : nothing();
};

const personToolTip = (personStore: PersonStoreContract) => (
  <div className={style.moduleProgressTooltipAvatar} data-test-progress-tooltip={true}>
    <div className={style.moduleProgressTooltipAvatarPerson}>
      <Person personStore={personStore} presenceStore={nothing()} />
    </div>
    <div className={style.moduleProgressTooltipArrow} />
  </div>
);

const TooltipContent: React.FC<Props> = ({ programModule, programDetail }) =>
  profileStore.personStoreContract
    .map((personStore) => {
      switch (programDetail.programInstanceKind) {
        case 'scheduled':
          return programDetail.daysBehind
            .andThen(checkWarningLimit)
            .map(() => personToolTip(personStore))
            .getOrElse(() => (
              <div className={style.moduleProgressTooltip} data-test-progress-tooltip={true}>
                <div className={style.tooltipModuleDescription}>
                  {programModule.label.getOrElseValue('')}
                  <br />
                  {programModule.daysRemaining
                    .map((daysRemaining) => (
                      <T kind="{{count}} Day Remaining in Module" count={daysRemaining} />
                    ))
                    .getOrElseValue(<></>)}
                </div>
                <div className={style.moduleProgressTooltipArrow} />
              </div>
            ));
        case 'on-demand':
          return personToolTip(personStore);
      }
    })
    .getOrElse(() => <></>);

export default observer(TooltipContent);
