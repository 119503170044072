import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const VideoIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 28 28">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        d="M14 0C6.28 0 0 6.28 0 14s6.28 14 14 14 14-6.28 14-14S21.72 0 14 0Zm0 2.333A11.648 11.648 0 0 1 25.667 14 11.648 11.648 0 0 1 14 25.667 11.648 11.648 0 0 1 2.333 14 11.648 11.648 0 0 1 14 2.333ZM9.333 5.98v16.042L11.083 21l10.5-5.98 1.75-1.02-1.75-1.02L11.083 7l-1.75-1.02Zm2.334 4.01L18.63 14l-6.963 4.01V9.99Z"
      />
    </SVGIcon>
  );
};

export default observer(VideoIcon);
