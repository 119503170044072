import { findPayload } from '@execonline-inc/collections';
import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { teamUrl } from '../ClientRoutes';
import { error } from '../ErrorHandling';
import { findLink } from '../LinkyLinky';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { Link } from '../Resource/Types';
import { TPlainTextKey } from '../Translations';
import {
  loading,
  loadingMore,
  ready,
  TeamResource,
  TeamsResource,
  TeamsState,
  UnreadTeamMessageNotification,
  waiting,
} from './Types';

class TeamsStore {
  @observable
  state: TeamsState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    this.state = loading();
  };

  @action
  ready = (teamsResource: TeamsResource) => {
    this.state = ready(teamsResource);
  };

  @action
  loadingMore = (teamsResource: TeamsResource) => {
    this.state = loadingMore(teamsResource);
  };

  @action
  moreLoaded = (teamsResource: TeamsResource) => {
    switch (this.state.kind) {
      case 'loading-more':
        const oldResource = this.state.teamsResource;
        const updatedResource = {
          ...teamsResource,
          payload: oldResource.payload.concat(teamsResource.payload),
        };
        this.state = ready(updatedResource);
        break;
      case 'ready':
      case 'waiting':
      case 'error':
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get teams(): Maybe<TeamsResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'loading-more':
        return just(this.state.teamsResource);
      case 'loading':
      case 'waiting':
      case 'error':
        return nothing();
    }
  }

  teamResource = createTransformer((id: number): Maybe<TeamResource> => {
    switch (this.state.kind) {
      case 'ready':
      case 'loading-more':
        return findPayload(id, this.state.teamsResource.payload);
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  });

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'loading-more':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get nextPageLink(): Maybe<Link> {
    switch (this.state.kind) {
      case 'loading-more':
      case 'ready':
        return this.teams.map((teamsResource) => teamsResource.links).andThen(findLink('next'));
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get unreadTeamMessageNotifications(): UnreadTeamMessageNotification[] {
    switch (this.state.kind) {
      case 'loading-more':
      case 'ready':
        return this.state.teamsResource.metadata.unreadTeamChatMessages
          .filter((unreadTeamChatMessages) => unreadTeamChatMessages.teamPageContent === 'Chat')
          .map((unreadTeamChatMessages) => ({
            unreadCount: unreadTeamChatMessages.unreadCount.getOrElseValue(0),
            teamLink: teamUrl(unreadTeamChatMessages.teamId),
          }))
          .filter((unreadTeamMessageNotification) => unreadTeamMessageNotification.unreadCount > 0);

      case 'error':
      case 'loading':
      case 'waiting':
        return [];
    }
  }
}

export default TeamsStore;
