import { noop } from '@kofno/piper';
import { fromNullable, Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import {
  storageGetItemError,
  StorageGetItemError,
  storageRemoveItemError,
  StorageRemoveItemError,
  storageSetItemError,
  StorageSetItemError,
  StorageType,
} from './Types';

const storage = (type: StorageType): Storage => {
  switch (type) {
    case 'LocalStorage':
      return window.localStorage;
    case 'SessionStorage':
      return window.sessionStorage;
  }
};

export const storageGetItem =
  (storageType: StorageType) =>
  (key: string): Task<StorageGetItemError, Maybe<string>> =>
    new Task((reject, resolve) => {
      try {
        const value = storage(storageType).getItem(key);
        resolve(fromNullable(value));
      } catch (error) {
        reject(storageGetItemError({ storageType, error }));
      }
      return noop;
    });

export const storageSetItem =
  (storageType: StorageType) =>
  (key: string, value: string): Task<StorageSetItemError, string> =>
    new Task((reject, resolve) => {
      try {
        storage(storageType).setItem(key, value);
        resolve(value);
      } catch (error) {
        reject(storageSetItemError({ storageType, error }));
      }
      return noop;
    });

export const storageRemoveItem =
  (storageType: StorageType) =>
  (key: string): Task<StorageRemoveItemError, void> =>
    new Task((reject, resolve) => {
      try {
        storage(storageType).removeItem(key);
        resolve();
      } catch (error) {
        reject(storageRemoveItemError({ storageType, error }));
      }
      return noop;
    });
