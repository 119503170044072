import { observer } from 'mobx-react';
import * as React from 'react';
import { T, TPlainTextKey } from '../../../../Translations';
import * as style from './../style.module.css';

interface Props {
  tooltipText: TPlainTextKey;
  children: JSX.Element;
}

const ActionMenuButton: React.FC<Props> = ({ tooltipText, children }) => {
  return (
    <div className={style.actionMenuButtonWrapper}>
      <div className={style.actionMenuButtonHoverText}>
        <T kind={tooltipText} />
      </div>
      {children}
    </div>
  );
};

export default observer(ActionMenuButton);
