import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { HtmlNode } from '../../../SegmentStore/Types';
import ContentEmbed from '../../../components/ContentEmbed';
import { nodeDecoder } from '../../../components/ContentEmbed/Types';
import { AnnouncementResource } from '../../Decoders/Types';
import * as style from '../../style.module.css';
import { embeddable, whenAnnouncementEmbeddableNode } from './Types';

interface Props {
  announcement: AnnouncementResource;
  footerHTML: string;
}

const transform = (announcement: AnnouncementResource) => (
  htmlNode: HtmlNode,
  key: number
): React.ReactNode =>
  nodeDecoder('announcement')
    .decodeAny(htmlNode)
    .andThen(whenAnnouncementEmbeddableNode)
    .andThen((node) => embeddable(node, announcement))
    .cata({
      Err: () => undefined,
      Ok: (embeddable) => <ContentEmbed key={key} embeddable={embeddable} />,
    });

const DigitalCertificateContent: React.FC<Props> = ({ announcement, footerHTML }) => {
  return (
    <div data-test-announcement-content className={style.content}>
      <div className={style.description}>
        {announcement.payload.descriptionHtml
          .map((html) => ReactHtmlParser(html))
          .getOrElseValue(<span>{announcement.payload.description}</span>)}
      </div>
      {ReactHtmlParser(footerHTML, { transform: transform(announcement) })}
    </div>
  );
};

export default observer(DigitalCertificateContent);
