import * as React from 'react';
import { T } from '../../../Translations';
import ProgramResourcesSegment from './ProgramResourcesSegment';
import { ProgramResourcesModule } from './ProgramResourcesStore/Types';
import * as style from './style.module.css';

interface Props {
  module: ProgramResourcesModule;
  count: number;
}

const DisplayedSegments: React.FC<Props> = ({ module, count }) => {
  switch (count) {
    case 0:
      return (
        <span className={style.emptySegmentsText}>
          <T kind="No resources to display" />
        </span>
      );
    default:
      return (
        <>
          {module.segments
            .filter((segment) =>
              segment.resources.map((resources) => resources.toArray().length).getOrElseValue(0)
            )
            .map((segment) => (
              <ProgramResourcesSegment key={segment.id} segment={segment} moduleId={module.id} />
            ))}
        </>
      );
  }
};

export default DisplayedSegments;
