import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Stripe } from '@stripe/stripe-js';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { PaymentIntentResource } from '../../../../../DiscoveryPortal/Enrollment/Store/Types';
import { StripeClient, StripeContext, loadOptions } from '../../../../Stripe';
import { PaymentCheckoutFormStore, onPaymentCheckoutFormSubmit } from '../../Common';
import PaymentCardForm from './PaymentCardForm';
import PaymentErrorNotification from './PaymentErrorNotification';

interface Props {
  stripe: Stripe;
  price: Maybe<number>;
  store: PaymentCheckoutFormStore;
  paymentIntentResource: Maybe<PaymentIntentResource>;
}

function PaymentCheckoutForm({ price, stripe, store, paymentIntentResource }: Props) {
  return (
    <div className="flex basis-3/5 flex-col gap-y-3 p-6 pt-10 md:py-12 md:pl-8 md:pr-12">
      <PaymentErrorNotification store={store} />
      <StripeClient stripe={stripe} options={loadOptions(paymentIntentResource)}>
        <StripeContext.Consumer>
          {(elements) =>
            elements
              .map((elements) => (
                <PaymentCardForm
                  store={store}
                  price={price}
                  stripe={stripe}
                  onSubmit={onPaymentCheckoutFormSubmit(store, stripe, elements)}
                />
              ))
              .getOrElse(emptyFragment)
          }
        </StripeContext.Consumer>
      </StripeClient>
    </div>
  );
}

export default observer(PaymentCheckoutForm);
