import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import EventsStore from '.';
import { AppyError, callApi } from '../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Linkable } from '../Resource/Types';
import { eventsResourceDecoder } from './Decoders';
import { EventsState } from './Types';

const eventsEndPoint = callApi(eventsResourceDecoder, {});

type EventsReactionError = MissingLinkError | AppyError;

export interface Props extends EAProps<EventsStore> {
  store: EventsStore;
}

interface EventsStateWithLinkable {
  state: EventsState;
  whenLinks: Maybe<Linkable>;
}

type EventRequestError = MissingLinkError | AppyError;

const handleEventRequestError = (store: EventsStore) => (error: EventRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error("Events aren't available");
      break;
    default:
      handleError(store, error);
  }
};

class EventsReactions extends ErrorActionableReaction<EventsStore, EventsStateWithLinkable, Props> {
  tester = (): EventsStateWithLinkable => ({
    state: this.props.store.state,
    whenLinks: this.props.store.whenLinks,
  });

  effect = ({ state, whenLinks }: EventsStateWithLinkable) => {
    switch (state.kind) {
      case 'waiting':
        whenLinks.do(this.props.store.loading);
        break;
      case 'ready':
      case 'error':
        break;
      case 'refresh':
        Task.succeed<EventsReactionError, Linkable>(state.eventsResource)
          .map((linkable) => linkable.links)
          .andThen(findLinkT('self'))
          .andThen(eventsEndPoint)
          .fork(handleEventRequestError(this.props.store), this.props.store.ready);
        break;
      case 'loading':
        Task.succeed<EventsReactionError, Linkable>(state.linkable)
          .map((linkable) => linkable.links)
          .andThen(findLinkT('events'))
          .andThen(eventsEndPoint)
          .fork(handleEventRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(EventsReactions);
