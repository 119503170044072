interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Open {
  kind: 'open';
}

interface Reopened {
  kind: 'reopened';
}

interface Closed {
  kind: 'closed';
}
interface ClosedAndRouting {
  kind: 'closed-and-routing';
}
interface ConfirmAndRouting {
  kind: 'confirm-and-routing';
}

interface Confirming {
  kind: 'confirming';
}

export const confirming = (): Confirming => ({ kind: 'confirming' });

export const confirmAndRouting = (): ConfirmAndRouting => ({ kind: 'confirm-and-routing' });

export const closed = (): Closed => ({ kind: 'closed' });

export const closedAndRouting = (): ClosedAndRouting => ({ kind: 'closed-and-routing' });

export const open = (): Open => ({ kind: 'open' });

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (): Loading => ({ kind: 'loading' });

export type EnrolledModalState =
  | Waiting
  | Loading
  | Open
  | Closed
  | ClosedAndRouting
  | Confirming
  | ConfirmAndRouting
  | Reopened;
