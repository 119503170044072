import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { warnAndNotify } from '../../../Honeybadger';
import { ProgramDetailResource, ProgramSegment } from '../../../ProgramStore/Types';
import ProgramSequenceProgress from './ProgramSequenceProgress';
import Progress from './Progress';

interface Props {
  programDetailResource: ProgramDetailResource;
  currentSegment: Maybe<ProgramSegment>;
}

const ProgressBar: React.FC<Props> = ({ programDetailResource }) => {
  switch (programDetailResource.payload.kind) {
    case 'active':
      switch (programDetailResource.payload.productDetails.payload.kind) {
        case 'coaching':
          // This shouldn't ever happen since we don't display progress bars for coaching
          warnAndNotify(
            'Configuration Error',
            'Progress bar rendered for a coaching program',
            'Program detail progress',
          );
          return emptyFragment();
        case 'group-coaching':
        case 'program':
          return <Progress programDetail={programDetailResource.payload} />;
        case 'program-sequence':
          return <ProgramSequenceProgress programDetail={programDetailResource.payload} />;
      }
    case 'completed':
    case 'expired':
    case 'upcoming':
    case 'inactive':
      return emptyFragment();
  }
};

export default observer(ProgressBar);
