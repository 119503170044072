import { base64Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import Decoder, { array, at, field, succeed } from 'jsonous';
import { errorDecoder, linksDecoder, resourceDecoder } from '../../Resource/Decoders';
import { ResourceWithErrors } from '../../Resource/Types';
import { Conjoint, ConjointAnalysisNode, ConjointAnalysisResource } from './Types';

const conjointDecoder: Decoder<Conjoint> = succeed({});

export const conjointAnalysisResourceDecoder: Decoder<ConjointAnalysisResource> = resourceDecoder(
  conjointDecoder
);

export const conjointAnalysisResourceDecoderWithErrors: Decoder<
  ResourceWithErrors<Conjoint>
> = succeed({})
  .assign('payload', field('payload', conjointDecoder))
  .assign('links', field('links', linksDecoder))
  .assign('errors', field('errors', array(errorDecoder)));

export const conjointAnalysisDecoder: Decoder<ConjointAnalysisNode> = succeed({})
  .assign(
    'resource',
    at(
      ['attribs', `data-attr-conjoint`],
      pipeD(jsonParserDecoder(base64Decoder), conjointAnalysisResourceDecoder)
    )
  )
  .assign('kind', succeed<'conjoint-analysis'>('conjoint-analysis'));
