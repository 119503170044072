import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceParticipant } from '../../../ConferenceRosterStore/Types';
import { GlobalPresenceContext } from '../../../GlobalPresence/GlobalPresenceContext';
import { conferenceParticipantStore } from '../../../People';
import Person from '../../Person';
import HandRaise from '../ChatPersonDetails/HandRaise';
import * as style from '../style.module.css';

interface Props {
  participant: ConferenceParticipant;
  conferenceRosterStore: Maybe<ConferenceRosterStore>;
}

const Participant: React.FC<Props> = ({ participant }) => {
  const personStore = conferenceParticipantStore(participant);

  return (
    <li>
      <div className={style.participant} data-test-conference-participant={personStore.name}>
        <GlobalPresenceContext.Consumer>
          {(value) => <Person personStore={personStore} presenceStore={just(value)} />}
        </GlobalPresenceContext.Consumer>
        <div>
          <HandRaise id={participant.id} />
        </div>
      </div>
    </li>
  );
};

export default observer(Participant);
