import * as React from 'react';
import { observer } from 'mobx-react';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import LoadingMembers from './LoadingMembers';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

const LoadProgramMember: React.FC<Props> = ({ presenceMembersStore }) => (
  <>
    {presenceMembersStore.programMembersStore.shownMembers
      .map((shownMembers) => (
        <LoadingMembers presenceMembersStore={presenceMembersStore} shownMembers={shownMembers} />
      ))
      .getOrElse(() => (
        <></>
      ))}
  </>
);

export default observer(LoadProgramMember);
