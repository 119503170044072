import { noop } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { errorMessage } from '../ExceptionHandling';

export function setItem(key: string, value: string): (storage: Storage) => Task<string, string>;
export function setItem(key: string, value: string, storage: Storage): Task<string, string>;
export function setItem(key: string, value: string, storage?: Storage) {
  const setter = (storage: Storage) =>
    new Task<string, string>((reject, resolve) => {
      try {
        storage.setItem(key, value);
        resolve(value);
      } catch (e) {
        reject(errorMessage(e));
      }
      return noop;
    });

  return typeof storage === 'undefined' ? setter : setter(storage);
}

export function getItem(key: string): (storage: Storage) => Task<string, Maybe<string>>;
export function getItem(key: string, storage: Storage): Task<string, Maybe<string>>;
export function getItem(key: string, storage?: Storage) {
  const getter = (storage: Storage) =>
    new Task<string, Maybe<string>>((reject, resolve) => {
      try {
        const value = storage.getItem(key);
        if (value) {
          resolve(just(value));
        } else {
          resolve(nothing());
        }
      } catch (e) {
        reject(errorMessage(e));
      }
      return noop;
    });

  return typeof storage === 'undefined' ? getter : getter(storage);
}
