import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T, TPlainTextKey } from '../../../../../../Translations';

interface Props {
  title: TPlainTextKey;
}

function MenuFilterTitle({ title }: Props) {
  return (
    <span className="mr-4 flex w-full justify-between">
      <Typography variant="bodyMedium" fontWeight="medium" as="span">
        <T kind={title} />
      </Typography>
    </span>
  );
}

export default observer(MenuFilterTitle);
