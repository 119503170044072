import { equals, take } from '@execonline-inc/collections';
import { emptyFragment, useOpenable } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import { handleEnterKeyDown, handleMouseDown } from '../../Types';
import { CompetencyResource } from '../Types';
import ExperienceCompetencyChip from './ExperienceCompetencyChip';

const getCompetencies = (competencies: CompetencyResource[], expanded: boolean) => {
  return when(expanded, true)
    .map(() => competencies)
    .getOrElse(() => [...take(4, competencies)]);
};

interface Props {
  competencies: CompetencyResource[];
}

function ExperienceCompetencyItems({ competencies }: Props) {
  const { isOpen: expanded, onOpen } = useOpenable();

  const items = getCompetencies(competencies, expanded);
  const restCount = competencies.length - items.length;

  return (
    <div className="flex flex-wrap gap-1 sm:gap-2">
      <>
        {items.map((value) => (
          <ExperienceCompetencyChip key={value.payload.id}>
            <AlreadyTranslated content={value.payload.name} />
          </ExperienceCompetencyChip>
        ))}
        {when(equals(restCount, 0), true)
          .map(emptyFragment)
          .getOrElse(() => (
            <ExperienceCompetencyChip
              tabIndex={0}
              className="cursor-pointer"
              onClick={handleMouseDown(onOpen)}
              onKeyDown={handleEnterKeyDown(onOpen)}
            >
              +{restCount}
            </ExperienceCompetencyChip>
          ))}
      </>
    </div>
  );
}

export default observer(ExperienceCompetencyItems);
