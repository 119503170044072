import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../ProgramStore';
import { ProgramDetailResource } from '../../../ProgramStore/Types';
import ProgramOverviewHeader from '../../Dashboard/ProgramDashboard/ProgramOverviewHeader';
import * as style from '../../Dashboard/style.module.css';
import ProgramOverview from '../../ProgramOverview';

interface Props {
  programDetailResource: ProgramDetailResource;
  store: ProgramStore;
}

const ActiveProgramProductOverview: React.FC<Props> = ({ programDetailResource, store }) => (
  <div className={style.program} data-test-active-program={true}>
    <div className={style.header}>
      <ProgramOverviewHeader
        id={programDetailResource.payload.id}
        title={programDetailResource.payload.title}
        links={programDetailResource.links}
      />
    </div>
    <ProgramOverview
      programDetailResource={programDetailResource}
      currentSegment={store.currentSegment}
      programStore={store}
    />
  </div>
);

export default observer(ActiveProgramProductOverview);
