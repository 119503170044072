import { observer } from 'mobx-react';
import React from 'react';
import { Typography } from '../../UI';
import { T } from '../../../Translations';
import LanguageIcon from '../../Cutesies/LanguageIcon';

const LanguageNotAvailableMessage: React.FC = () => {
  return (
    <div className="flex mt-2.5">
      <div className="flex h-5">
        <LanguageIcon fillColor="icon-standard-on-light" />
      </div>
      <Typography
        className="flex flex-shrink-0 !text-gray-darkest ml-2.5"
        variant="body2"
        component="span"
      >
        <T kind="Not available in language" />
      </Typography>
    </div>
  );
};

export default observer(LanguageNotAvailableMessage);
