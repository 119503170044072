import { Typography } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import Main from '../../BrowserSupport/Main';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import CompleteSegmentIcon from '../Cutesies/CompleteSegmentIcon';
import LogoGraphic from '../Cutesies/LogoGraphic';

function Confirmation() {
  return (
    <Main className="container mx-auto min-h-screen">
      <I18nTitle title="FreeBusy Requirements Check" />
      <div className="px-2 text-gray-600">
        <div className="mx-auto my-12 w-[18.75rem] lg:w-[25rem]">
          <LogoGraphic />
        </div>
        <div className="mt-28 flex flex-col items-center">
          <div className="h-28 w-28">
            <CompleteSegmentIcon />
          </div>
          <Typography className="mt-5" variant="h5" as="h1">
            <T kind="Test Successful" />
          </Typography>
          <Typography className="mt-4 text-center" fontWeight="medium">
            <T kind="You have successfully completed the test ..." />
          </Typography>
        </div>
      </div>
    </Main>
  );
}

export default observer(Confirmation);
