import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AvailabilityResource, ExperienceResource } from '../../../../../Experience/Types';
import { TileViewProgramExperiencePreview } from '../../../../../ProgramExperience';
import { EnrollmentModalContextOptions } from '../../../../Common';
import AvailabilityHeader from './AvailabilityHeader';
import AvailabilityTitle from './AvailabilityTitle';
import AvailabilityDescription from './AvailabilityDescription';

interface Props {
  invitationUuid: string;
  experienceResource: ExperienceResource;
  availability: AvailabilityResource;
  options: EnrollmentModalContextOptions;
}

function ScheduledExperience({ availability, invitationUuid, experienceResource, options }: Props) {
  return (
    <div className="grid auto-rows-auto justify-center gap-11 md:grid-cols-2 md:grid-rows-1 md:justify-normal">
      <TileViewProgramExperiencePreview
        invitationUuid={invitationUuid}
        experienceResource={experienceResource}
      />
      <div>
        <AvailabilityHeader availability={availability} />
        <AvailabilityTitle availability={availability} experienceResource={experienceResource} />
        <AvailabilityDescription experienceResource={experienceResource} />
        {options.scheduledExperienceOptions.getOrElse(emptyFragment)}
      </div>
    </div>
  );
}

export default observer(ScheduledExperience);
