import { observer } from 'mobx-react';
import * as React from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { AppyError } from '../../Appy';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { handleError } from '../../ErrorActionableReaction';
import FileUploadStore from '../../FileUploadStore';
import { MissingLinkError } from '../../LinkyLinky';
import { SimpleFileUploadResponse } from '../../SimpleFileUploadStore/Types';
import { scrubFilename, upload } from '../../SimpleFileUploadStore/UploadActions';
import { T, TPlainTextKey } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  store: FileUploadStore;
  buttonClass: string;
  buttonText: TPlainTextKey;
  currentUserResource: CurrentUserResource;
}

type FileUploadError = MissingLinkError | AppyError;

const handleRequestError = (store: FileUploadStore) => (error: FileUploadError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Link not available');
      break;
    default:
      handleError(store, error);
  }
};

class SimpleFileUpload extends React.Component<Props, {}> {
  getSignedUrl = (file: File, callback: (payload: SimpleFileUploadResponse) => void) => {
    const { name, type } = file;

    const scrubedName = scrubFilename(name);

    upload(scrubedName, type, this.props.currentUserResource).fork(
      (err) => handleRequestError(this.props.store),
      (data) => {
        callback(data.payload);
      }
    );
  };

  onUploadError = () => {
    this.props.store.error('Your upload could not be processed at this time');
  };

  onUploadFinish = (res: SimpleFileUploadResponse) => {
    this.props.store.uploadSuccessful({
      handle: '',
      filename: res.objName,
      uploadFilePath: res.tempFilePath,
      temporaryCdnUrl: res.signedUrl,
    });
  };

  onUploadStart = (file: File, next: (file: File) => void) => {
    this.props.store.uploadInProgess();
    next(file);
  };

  render() {
    return (
      <div>
        <ReactS3Uploader<SimpleFileUploadResponse>
          accept="image/jpg, image/jpeg, image/png, image/gif"
          getSignedUrl={this.getSignedUrl}
          onError={this.onUploadError}
          onFinish={this.onUploadFinish}
          signingUrlWithCredentials={true}
          uploadRequestHeaders={{ 'x-amz-acl': 'bucket-owner-full-control' }}
          contentDisposition="auto"
          autoUpload={true}
          preprocess={this.onUploadStart}
          server={process.env.REACT_APP_API_ROOT}
          id="fileInput"
          className={style.fileInput}
        />
        <label htmlFor="fileInput" className={`${this.props.buttonClass}  ${style.fileInputLabel}`}>
          <T kind={this.props.buttonText} />
        </label>
      </div>
    );
  }
}

export default observer(SimpleFileUpload);
