import * as React from 'react';
import { observer } from 'mobx-react';
import CalendarStore from '../CalendarStore';
import ChannelAvailabilityStore from '../Socket/ChannelAvailabilityStore';
import CalendarHandler from '../Socket/CalendarChannel/CalendarHandler';
import CalendarChannel from '../Socket/CalendarChannel';

interface Props {
  calenderStore: CalendarStore;
  channelName: string;
}

class CalendarRefresh extends React.Component<Props, {}> {
  private channelAvailabilityStore: ChannelAvailabilityStore = new ChannelAvailabilityStore();

  render() {
    return (
      <>
        <CalendarChannel
          name={this.props.channelName}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
        <CalendarHandler
          calenderStore={this.props.calenderStore}
          channelAvailabilityStore={this.channelAvailabilityStore}
        />
      </>
    );
  }
}

export default observer(CalendarRefresh);
