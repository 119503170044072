import { emptyFragment, ListItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { CurrentUserResource } from '../../../../../CurrentUser/Types';
import { T } from '../../../../../Translations';
import { supportMenu } from '../../SupportMenu/Types';

export interface Props {
  user: CurrentUserResource;
  onClick: () => void;
}

function SupportMenu({ user, onClick }: Props) {
  const handleClick = (href: string) => () => {
    onClick();
    window.open(href, '_blank', 'noopener noreferrer');
  };

  return supportMenu(user)
    .map(({ href }) => (
      <ListItem role="menuitem" onClick={handleClick(href)}>
        <T kind="Support" />
      </ListItem>
    ))
    .getOrElse(emptyFragment);
}

export default observer(SupportMenu);
