import * as React from 'react';
import _DocumentIcon from '../../../Cutesies/DocumentIcon';
import * as style from '../../style.module.css';

const DocumentIcon: React.FC = () => (
  <div className={style.icon}>
    <_DocumentIcon />
  </div>
);

export default DocumentIcon;
