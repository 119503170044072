import { observer } from 'mobx-react';
import { useExperienceContext } from '../../Common/Experience/ExperienceContext';
import { LearnMoreProgramDetails } from '../LearnMoreProgram';
import ExperienceProductDetails from './ExperienceProductDetails';

function ExperienceDetails() {
  const { experienceResource } = useExperienceContext();

  switch (experienceResource.payload.offeringType) {
    case 'coaching':
    case 'group-coaching':
    case 'program-sequence':
      return <ExperienceProductDetails experienceResource={experienceResource} />;
    case 'epc':
    case 'aep':
    case 'msuite':
      return <LearnMoreProgramDetails experienceResource={experienceResource} />;
  }
}

export default observer(ExperienceDetails);
