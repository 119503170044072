import { find } from '@execonline-inc/collections';
import { just, Maybe, nothing } from 'maybeasy';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import ConversationStore from '.';
import EvictingDeque from '../Collections/EvictingDeque';
import { SanitizedHtml } from '../components/LinkifyWithCharEntities';
import MessageReplyStore from '../components/TeamProfile/TeamChat/MessageReplyStore';
import { Error } from '../ErrorHandling';
import { ProfileResource } from '../ProfileStore/Types';
import {
  Link,
  Resource,
  resource,
  ResourceWithEmbedded,
  resourceWithEmbedded,
} from '../Resource/Types';
import { TeamMember } from '../TeamsStore/Types';

export interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

export interface Reloading {
  kind: 'reloading';
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

export interface LoadingNext {
  kind: 'loading-next';
  link: Link;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

export interface LoadNextSuccess {
  kind: 'load-next-success';
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

export interface LoadingPrevious {
  kind: 'loading-previous';
  link: Link;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

interface LoadNewMessage {
  kind: 'load-new-message';
  message: ChatMessageResource;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

interface LoadNewReply {
  kind: 'load-new-reply';
  reply: ChatMessageReplyResource;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

interface UpdateMessage {
  kind: 'update-message';
  message: ChatMessageResource;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

interface UpdateReply {
  kind: 'update-reply';
  reply: ChatMessageReplyResource;
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

export interface Ready {
  kind: 'ready';
  deque: EvictingDeque<ChatMessagesResource>;
  unseenMessage: Maybe<ChatMessageResource>;
  messageReplyStores: MessageReplyStore[];
}

export interface FirstLoaded {
  kind: 'first-loaded';
  deque: EvictingDeque<ChatMessagesResource>;
  messageReplyStores: MessageReplyStore[];
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const reloading = (
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): Reloading => ({
  kind: 'reloading',
  deque,
  messageReplyStores,
});

export const firstLoaded = (
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): FirstLoaded => ({
  kind: 'first-loaded',
  deque,
  messageReplyStores,
});

export const loadingNext = (
  link: Link,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): LoadingNext => ({
  kind: 'loading-next',
  link,
  deque,
  messageReplyStores,
});

export const loadNextSuccess = (
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): LoadNextSuccess => ({
  kind: 'load-next-success',
  deque,
  messageReplyStores,
});

export const loadingPrevious = (
  link: Link,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): LoadingPrevious => ({
  kind: 'loading-previous',
  link,
  deque,
  messageReplyStores,
});

export const loadNewMessage = (
  message: ChatMessageResource,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): LoadNewMessage => ({
  kind: 'load-new-message',
  message,
  deque,
  messageReplyStores,
});

export const loadNewReply = (
  reply: ChatMessageReplyResource,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): LoadNewReply => ({
  kind: 'load-new-reply',
  reply,
  deque,
  messageReplyStores,
});

export const updateMessage = (
  message: ChatMessageResource,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): UpdateMessage => ({
  kind: 'update-message',
  message,
  deque,
  messageReplyStores,
});

export const updateReply = (
  reply: ChatMessageReplyResource,
  deque: EvictingDeque<ChatMessagesResource>,
  messageReplyStores: MessageReplyStore[]
): UpdateReply => ({
  kind: 'update-reply',
  reply,
  deque,
  messageReplyStores,
});

export const ready = (
  deque: EvictingDeque<ChatMessagesResource>,
  unseenMessage: Maybe<ChatMessageResource>,
  messageReplyStores: MessageReplyStore[]
): Ready => ({
  kind: 'ready',
  deque,
  unseenMessage,
  messageReplyStores,
});

export type ConversationState =
  | Ready
  | Loading
  | Waiting
  | Error
  | LoadingNext
  | LoadNextSuccess
  | Reloading
  | LoadingPrevious
  | LoadNewMessage
  | UpdateMessage
  | FirstLoaded
  | LoadNewReply
  | UpdateReply;

export type MessageSubscriptionStatus = 'subscribed' | 'unsubscribed' | 'ineligible';

export type Subscription = {
  messageId: number;
  status: MessageSubscriptionStatus;
};

export type SubscriptionResource = Resource<Subscription>;

export interface ChatMessageReaction {
  userId: number;
  emoji: string;
}

export type ChatMessageReactionResource = Resource<ChatMessageReaction>;

export type ChatMessageReactionsResource = Resource<ChatMessageReactionResource[]>;

export interface ChatMessageReply {
  kind: 'chat-message-reply';
  id: number;
  content: SanitizedHtml;
  age: string;
  userId: number;
  createdAt: Date;
  reactions: ChatMessageReactionsResource;
  offensiveMessageReports: ChatMessageOffensiveReportsResource;
  subscription: SubscriptionResource;
}

export interface Author {
  id: number;
  email: string;
  profile: ProfileResource;
}

export type AuthorResource = Resource<Author>;

export interface MessageReplyEmbedded {
  author: AuthorResource;
  offensiveMessageReports: ChatMessageOffensiveReportsResource;
  reactions: ChatMessageReactionsResource;
  subscription: SubscriptionResource;
}

export type ChatMessageReplyResource = ResourceWithEmbedded<ChatMessageReply, MessageReplyEmbedded>;

export type ChatMessageReplies = Maybe<NonEmptyList<ChatMessageReplyResource>>;

export type ChatMessageRepliesResource = Resource<ChatMessageReplyResource[]>;

export interface ChatMessageOffensiveReport {}

export type ChatMessageOffensiveReportResource = Resource<ChatMessageOffensiveReport>;

export type ChatMessageOffensiveReportsResource = Resource<ChatMessageOffensiveReportResource[]>;

export interface ChatMessage {
  kind: 'chat-message';
  id: number;
  content: SanitizedHtml;
  age: string;
  userId: number;
  createdAt: Date;
  reactions: ChatMessageReactionsResource;
  replies: Maybe<ChatMessageRepliesResource>;
  offensiveMessageReports: ChatMessageOffensiveReportsResource;
  subscription: SubscriptionResource;
}

export interface MessageEmbedded extends MessageReplyEmbedded {
  replies: Maybe<ChatMessageRepliesResource>;
}

export type ChatMessageResource = ResourceWithEmbedded<ChatMessage, MessageEmbedded>;
export type ChatMessagesResource = Resource<ChatMessageResource[]>;

export interface MessageGroup {
  dateStamp: string;
  messageResources: ChatMessageResource[];
}

export interface MessageActionItem {
  createdAt: number;
  type: string;
  action: () => void;
}

export type ChatablePerson = TeamMember;
export type ChatablePersonResource = Resource<ChatablePerson>;
export type ChatablePersonResourceList = ChatablePersonResource[];

export const authoredBy = (userId: number) => (
  message: ChatMessageResource
): Maybe<ChatMessageResource> => (userId === message.payload.userId ? just(message) : nothing());

export const notAuthoredBy = (userId: number) => (
  message: ChatMessageResource
): Maybe<ChatMessageResource> => (userId !== message.payload.userId ? just(message) : nothing());

export const whenFirstLoaded = (conversationStore: ConversationStore) => (): Maybe<'bottom'> =>
  conversationStore.firstLoaded ? just('bottom') : nothing();
const chatMessageResourceWithoutSpecifiedReplyMessage = (replyId: number) => (
  chatMessageResource: ChatMessageResource
): ChatMessageResource =>
  resourceWithEmbedded<ChatMessage, MessageEmbedded>(
    chatMessageResource.links,
    {
      ...chatMessageResource.payload,
      replies: chatMessageResource.payload.replies.map((repliesResource) =>
        resource(
          repliesResource.links,
          repliesResource.payload.filter((replyResource) => replyResource.payload.id !== replyId)
        )
      ),
    },
    chatMessageResource.embedded
  );

export const chatMessagesResourceWithoutSpecifiedMessage = (messageId: number) => (
  chatMessagesResource: ChatMessagesResource
): ChatMessagesResource =>
  resource<ChatMessageResource[]>(
    chatMessagesResource.links,
    chatMessagesResource.payload
      .filter((chatMessageResource) => chatMessageResource.payload.id !== messageId)
      .map(chatMessageResourceWithoutSpecifiedReplyMessage(messageId))
  );

export const findMessageReplyStoreById = (
  id: number,
  messageReplyStores: MessageReplyStore[]
): Maybe<MessageReplyStore> =>
  find((messageReplyStore) => messageReplyStore.messageId === id, messageReplyStores);

export const getMessageReplyStores = (
  deque: EvictingDeque<ChatMessagesResource>,
  chatablePersonResourceList: Maybe<ChatablePersonResourceList>,
  messageReplyStores: MessageReplyStore[]
): MessageReplyStore[] => {
  return deque.items
    .reduce((r: ChatMessageResource[], a): ChatMessageResource[] => r.concat(...a.payload), [])
    .map((chatMessageResource) => {
      const messageReplyStore = findMessageReplyStoreById(
        chatMessageResource.payload.id,
        messageReplyStores
      );
      const replies = chatMessageResource.payload.replies;
      return new MessageReplyStore(
        chatMessageResource.payload.id,
        replies.map((r) => r.payload).andThen(fromArrayMaybe),
        chatablePersonResourceList.getOrElseValue([]),
        messageReplyStore.map((messageReplyStore) => messageReplyStore.previousRepliesState)
      );
    });
};
