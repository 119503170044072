import * as React from 'react';
import { observer } from 'mobx-react';
import WithAuthentication from './Login/WithAuthentication';
import PageLayoutWrapper from './PageLayoutWrapper';

const PageLayoutWithAuthenticationWrapper: React.FC = ({ children }) => {
  return (
    <PageLayoutWrapper>
      <WithAuthentication>{children}</WithAuthentication>
    </PageLayoutWrapper>
  );
};

export default observer(PageLayoutWithAuthenticationWrapper);
