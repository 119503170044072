import { base64Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, at, field, maybe, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import {
  AttributeLabel,
  ColumnLabel,
  ConjointAnalysisResultsMaybeResource,
  ConjointAnalysisResultsNode,
  ConjointAnalysisResultsResource,
  ConjointResults,
  Respondent,
} from './Types';

const conjointResultsColumnLabel: Decoder<ColumnLabel> = succeed({})
  .assign('name', field('name', string))
  .assign('characteristics', field('characteristics', array(string)))
  .map<ColumnLabel>(identity);

const conjointResultsAttributeLabel: Decoder<AttributeLabel> = succeed({})
  .assign('name', field('name', string))
  .assign('options', field('options', array(string)));

const conjointResultsRespondent: Decoder<Respondent> = succeed({})
  .assign('name', field('name', string))
  .assign('characteristics', field('characteristics', array(string)))
  .assign('response', field('response', array(array(number))))
  .map<Respondent>(identity);

const conjointResultsDecoder: Decoder<ConjointResults> = succeed({})
  .assign('columnLabels', field('columnLabels', conjointResultsColumnLabel))
  .assign('attributeLabels', field('attributeLabels', array(conjointResultsAttributeLabel)))
  .assign('respondents', field('respondents', array(conjointResultsRespondent)));

export const conjointAnalysisResourceDecoder: Decoder<
  ConjointAnalysisResultsResource
> = resourceDecoder(conjointResultsDecoder);

export const conjointAnalysisMaybeResourceDecoder: Decoder<
  ConjointAnalysisResultsMaybeResource
> = maybe(conjointAnalysisResourceDecoder);

export const conjointAnalysisResultsDecoder: Decoder<ConjointAnalysisResultsNode> = succeed({})
  .assign(
    'resource',
    at(
      ['attribs', `data-attr-conjoint-results`],
      pipeD(jsonParserDecoder(base64Decoder), conjointAnalysisMaybeResourceDecoder)
    )
  )
  .assign('kind', succeed<'conjoint-analysis-results'>('conjoint-analysis-results'));
