import { mapMaybe } from '@execonline-inc/collections';
import { always } from '@kofno/piper';
import { fromEmpty } from 'maybeasy';
import { fromArray } from 'nonempty-list';
import { Result } from 'resulty';
import { extensionDecoder } from './Decoders';

export const fileExtension = (filename: string): Result<string, string> => {
  const [, ...extensions] = mapMaybe(fromEmpty, filename.split('.'));
  return fromArray(extensions)
    .map((fs) => fs.reverse())
    .map(({ first }) => first);
};

export const validateFile = (file: File): Result<string, File> => {
  return fileExtension(file.name)
    .andThen((v) => extensionDecoder.decodeAny(v))
    .map(always(file));
};
