import { SvgIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';

function LeadershipPersonaAlexIcon() {
  return (
    <SvgIcon size="inherit" viewBox="0 0 40 40">
      <path
        fill="#002060"
        fill-opacity=".3"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
      />
      <mask id="a" width="40" height="40" x="0" y="0" maskUnits="userSpaceOnUse">
        <path
          fill="#fff"
          d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20Z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#F1C9A5"
          d="M29.578 32.31C28.288 29.87 20 28.338 20 28.338s-8.289 1.531-9.578 3.972C9.469 35.697 8.672 40 8.672 40h22.656s-.538-4.614-1.75-7.69Z"
        />
        <path
          fill="#E4B692"
          d="M20 28.338s8.289 1.531 9.578 3.972c1.212 3.076 1.75 7.69 1.75 7.69H20V28.338Z"
        />
        <path fill="#F1C9A5" d="M22.256 23.377h-4.513v7.552h4.513v-7.552Z" />
        <path fill="#E4B692" d="M22.256 23.377H20v7.552h2.256v-7.552Z" />
        <path
          fill="#DDAC8C"
          d="M17.743 26.876c.406 1.165 2.044 1.833 3.247 1.833.435 0 .859-.073 1.266-.206v-5.127h-4.513v3.5Z"
          opacity=".1"
        />
        <path
          fill="#F1C9A5"
          d="M25.802 18.61c0-4.717-2.598-7.4-5.802-7.4-3.204 0-5.802 2.683-5.802 7.4 0 6.346 2.598 8.543 5.802 8.543 3.204 0 5.802-2.462 5.802-8.543Z"
        />
        <path
          fill="#E4B692"
          d="M24.919 21.882c-1.052-.11-1.762-1.244-1.584-2.53.176-1.287 1.172-2.243 2.224-2.134 1.052.11 1.76 1.242 1.583 2.53-.176 1.288-1.171 2.243-2.223 2.134Z"
        />
        <path
          fill="#F1C9A5"
          d="M12.899 19.748c-.178-1.287.531-2.42 1.582-2.53 1.053-.11 2.049.847 2.225 2.135.178 1.286-.533 2.42-1.584 2.53-1.052.108-2.047-.847-2.223-2.135Z"
        />
        <path
          fill="#E4B692"
          d="M25.802 18.61c0-4.717-2.598-7.4-5.802-7.4v15.943c3.204 0 5.802-2.462 5.802-8.543Z"
        />
        <path
          fill="#265A68"
          d="M14.42 20.174s.797-1.534 1.137-4.482c2.408 2.288 5.244 2.654 8.993 0 .092 2.15.72 3.49 1.457 4.482.454-3.714 1.455-5.545 1.12-7.496-2.5.413-3.379-1.64-7.127-1.64-3.749 0-4.583 1.975-6.913 1.798 0 2.32 1.11 3.555 1.332 7.338Z"
        />
        <path
          fill="#2D5972"
          d="M29.578 32.31c-.826-1.564-4.524-2.753-7.076-3.407a2.51 2.51 0 0 1-5.004 0c-2.552.654-6.25 1.844-7.076 3.407-.953 3.387-1.75 7.69-1.75 7.69h22.656s-.538-4.614-1.75-7.69Z"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(LeadershipPersonaAlexIcon);
