import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import { fromArrayMaybe } from 'nonempty-list';
import * as React from 'react';
import ProfessorsStore from '../../ProfessorsStore';
import { ProfessorsResource } from '../../ProfessorsStore/Types';
import ProfessorBody from './ProfessorBody';
import ProfessorHeader from './ProfessorHeader';

interface Props {
  professorsStore: ProfessorsStore;
}

const Professors: React.FC<Props> = ({ professorsStore }) =>
  professorsStore.professors
    .assign<'resource', ProfessorsResource>('resource', just)
    .assign('professors', ({ resource }) => fromArrayMaybe(resource.payload))
    .map(({ resource, professors }) => (
      <>
        <ProfessorHeader professors={professors} />
        <ProfessorBody professorsResource={resource} />
      </>
    ))
    .getOrElseValue(<div />);

export default observer(Professors);
