import { observer } from 'mobx-react';
import * as React from 'react';
import SurveyModal from './SurveyModal';
import SurveyModalStore from './Modal/Store';

interface Props {
  store: SurveyModalStore;
}

const SurveyModalImpl: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'never-show-survey':
    case 'waiting':
    case 'survey-closed-and-complete':
    case 'survey-closed-and-dismissed':
    case 'survey-undo-dismiss':
    case 'survey-dismissed':
      return <></>;
    case 'showing-survey':
      return <SurveyModal store={store} />;
  }
};

export default observer(SurveyModalImpl);
