import { emptyFragment } from '@execonline-inc/execonline-ui';
import { warn } from '@execonline-inc/logging';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useFetch, useFindLinkByR } from '../../../../../Fetch';
import EnrollmentStore from '../../Enrollment/Store';
import { promotableGroupResourceDecoder } from '../Decoders';
import { SkeletonPromotableExperiences } from '../PromotableExperiences';
import { PromotableGroupsItemResource } from '../Types';
import PromotableGroupView from './PromotableGroupView';

interface Props {
  data: PromotableGroupsItemResource;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotableGroup({ data, invitationUuid, enrollmentStore }: Props) {
  const link = useFindLinkByR({ rel: 'self', method: 'get' }, data.links);
  const state = useFetch(link, promotableGroupResourceDecoder);

  switch (state.kind) {
    case 'loading':
      return <SkeletonPromotableExperiences />;
    case 'errored':
      warn('Failed to fetch promotable group resource.', toJS(state.error));
      return emptyFragment();
    case 'ready':
      return (
        <PromotableGroupView
          data={state.data}
          invitationUuid={invitationUuid}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(PromotableGroup);
