import { observer } from 'mobx-react';
import * as React from 'react';
import ModalStore from '../../components/Modal/Store';
import ProgramStore from '../../ProgramStore';
import ProgressBar from '../ProgramOverview/ProgressBar';
import ProgramLanguageNotAvailableMessage from '../ProgramOverview/ProgramOverviewDashboard/ProgramLanguageNotAvailableMessage';
import WhatsNext from '../ProgramOverview/WhatsNext';
import RelatedPeople from '../ProgramOverview/ProgramOverviewDashboard/RelatedPeople';
import TimeRemaining from '../ProgramOverview/TimeRemaining';
import ResumeButton from './ActiveCoachingProductOverview/Orientation/ResumeButton';
import { ProgramDetailResource } from '../../ProgramStore/Types';
import NotSupportedInLanguageStore from '../../NotSupportedInLanguageStore';
import * as style from '../ProgramSummary/style.module.css';

interface Props {
  modalStore: ModalStore;
  store: ProgramStore;
  programDetailResource: ProgramDetailResource;
  programNotSupportedInLanguageStore: NotSupportedInLanguageStore;
}

const StreamlinedOverviewContent: React.FC<Props> = ({
  modalStore,
  store,
  programDetailResource,
  programNotSupportedInLanguageStore,
}) => (
  <div className={style.overview} data-test-program-overview={programDetailResource.payload.id}>
    <ProgressBar
      programDetailResource={programDetailResource}
      currentSegment={store.currentSegment}
    />
    <div className={style.details}>
      <div>
        <ResumeButton modalStore={modalStore} />
        <ProgramLanguageNotAvailableMessage
          programNotSupportedInLanguageStore={programNotSupportedInLanguageStore}
        />
      </div>
      <div className={style.detailsCourse}>
        <WhatsNext segment={store.currentSegment} />
      </div>

      <div className={style.detailsPeople}>
        <RelatedPeople programDetailResource={programDetailResource} programStore={store} />
      </div>
      <div className={style.detailsCourse}>
        <TimeRemaining programDetailResource={programDetailResource} />
      </div>
    </div>
  </div>
);

export default observer(StreamlinedOverviewContent);
