import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import EngagementStore from '../Video/EngagementStore';
import EngagementReactions from '../Video/EngagementStore/Reactions';
import VideoReactions from './VideoReactions';

export interface Props {
  kettle: Kettle;
  assetId: number;
  courseRegProgramId: number;
}

class VideoEngagement extends React.Component<Props, {}> {
  private engagementStore = new EngagementStore();

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <VideoReactions
              store={this.props.kettle}
              fireImmediately={true}
              engagementStore={this.engagementStore}
              assetId={this.props.assetId}
              programId={this.props.courseRegProgramId}
            />
            <EngagementReactions
              store={this.engagementStore}
              currentUserResource={currentUserResource}
              fireImmediately
            />
          </>
        )}
      />
    );
  }
}

export default observer(VideoEngagement);
