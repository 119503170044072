import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import TeamMemberList from './TeamMemberList';
import * as style from './style.module.css';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';

interface Props {
  communityTeamResource: CommunityTeamResource;
  communitiesStore: CommunitiesStore;
}

const TeamMembersOverview: React.FC<Props> = ({ communityTeamResource, communitiesStore }) => (
  <dl className={style.dl} data-test-team-members={true}>
    <dt>
      <h3>
        <T kind="Team Members" />
      </h3>
    </dt>
    <div className={style.members}>
      <TeamMemberList
        key={communityTeamResource.payload.id}
        communityTeamResource={communityTeamResource}
        communitiesStore={communitiesStore}
      />
    </div>
  </dl>
);

export default observer(TeamMembersOverview);
