import { pipeD, jsonParserDecoder, base64ToUTF8Decoder } from '@execonline-inc/decoders';
import Decoder, { at, succeed } from 'jsonous';
import { surveyCompletionTrackingResourceDecoder } from '../../../VideoStore/Legacy/Decoders';
import { CoachingSurveyButtonNode } from './Types';

export const coachingSurveyButtonNodeDecoder: Decoder<CoachingSurveyButtonNode> = succeed({})
  .assign('kind', succeed<'coaching-survey-button-node'>('coaching-survey-button-node'))
  .assign(
    'surveyTrackingResource',
    at(
      ['attribs', 'data-coaching-survey-button-identity'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), surveyCompletionTrackingResourceDecoder)
    )
  );
