import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { AppyError, callApi } from '../../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { findLink } from '../../../LinkyLinky';
import { courseDocumentResourceDecoder } from '../Decoders';
import CourseDocumentStore, { State } from '../Store';
import { CourseDocumentResource } from '../Types';

export interface Props extends EAProps<CourseDocumentStore> {
  store: CourseDocumentStore;
}

const handleRequestError = (store: CourseDocumentStore) => (error: AppyError) => {
  handleError(store, error);
};

class CourseDocumentReactions extends ErrorActionableReaction<CourseDocumentStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        callApi(
          courseDocumentResourceDecoder,
          {}
        )(state.link).fork(handleRequestError(store), (courseDocument: CourseDocumentResource) =>
          store.ready(courseDocument)
        );
        break;
      case 'ready':
        store.courseDocument.map((document) => {
          switch (document.kind) {
            case 'document':
              findLink('download', store.links).map((link) => {
                window.open(link.href, '_self');
              });
          }
        });
        break;
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(CourseDocumentReactions);
