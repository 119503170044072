import { SegmentResource } from '../../../SegmentStore/Types';

interface Props {
  segmentResource: SegmentResource;
}

function Errors({ segmentResource }: Props) {
  switch (segmentResource.payload.type) {
    case 'overview':
      return (
        <>
          {segmentResource.payload.embeddedFormFieldAssets.map((embeddedFormFieldAsset) =>
            embeddedFormFieldAsset.payload.fieldDescription.errors.map((error) => (
              <p className="text-red-500">
                Form field "{embeddedFormFieldAsset.payload.fieldDescription.name}" has an error:{' '}
                {error.message}
              </p>
            )),
          )}
        </>
      );
    default:
      return <></>;
  }
}

export default Errors;
