import { noop } from '@kofno/piper';
import { Task } from 'taskarian';
import { FormBuildError, formBuildError } from './Errors';
import { Params } from './Types';

export const buildRedirectForm = (params: Params): Task<FormBuildError, HTMLFormElement> =>
  new Task((reject, resolve) => {
    try {
      const form = document.createElement('form');
      form.action = params.href;
      form.method = 'post';

      const tokenInput = document.createElement('input');
      tokenInput.type = 'hidden';
      tokenInput.name = 'authenticity_token';
      tokenInput.value = params.csrfToken;

      const usernameInput = document.createElement('input');
      usernameInput.type = 'hidden';
      usernameInput.name = 'username';
      usernameInput.value = params.username.getOrElseValue('');

      const jwtInput = document.createElement('input');
      jwtInput.type = 'hidden';
      jwtInput.name = 'jwt';
      jwtInput.value = params.opaqueUseCaseIdentifier.getOrElseValue('');

      form.appendChild(tokenInput);
      form.appendChild(usernameInput);
      form.appendChild(jwtInput);
      document.body.appendChild(form);

      resolve(form);
    } catch (error) {
      reject(formBuildError(error));
    }
    return noop;
  });
