import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../../ProgramStore';
import { L, T } from '../../../../../Translations';
import * as style from '../../style.module.css';

interface Props {
  programStore: ProgramStore;
}

const RemainingDaysMessage: React.FC<Props> = ({ programStore }) =>
  fromNullable(programStore.remainingDays)
    .andThen(() => programStore.programEndsOn)
    .map((programEndsOn) => (
      <div className={style.smallText}>
        <T
          kind="You may continue to schedule sessions with your coach until <dateSpan><date /></dateSpan>"
          date={<L localizeable={programEndsOn} format="long-month-day-year" />}
          dateSpan={(translated) => <span className={style.dateSpan}>{translated}</span>}
        />
      </div>
    ))
    .getOrElse(() => (
      <div className={style.smallText}>
        <T kind="You must have an active license to schedule sessions with your coach" />
      </div>
    ));

export default observer(RemainingDaysMessage);
