import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, date, field, number, oneOf, string, succeed } from 'jsonous';
import { fromArrayMaybe } from 'nonempty-list';
import { registrationTypeDecoder } from '../ProgramsStore/Decoders';
import { resourceDecoder } from '../Resource/Decoders';
import { alreadyTranslatedText } from '../Translations';
import {
  ActiveInvitation,
  CurrentlyEnrolled,
  Discovery,
  DiscoveryResource,
  EnrollmentStatusType,
  FutureEnrolled,
  InactiveInvitation,
  InvitationActivation,
  PastEnrolled,
  RegistrationInvitationSource,
  UseCaseExplorePayload,
  UseCaseExploreResource,
} from './Types';

const filteringDecoder: Decoder<Discovery['filtering']> = oneOf([
  stringLiteral<Discovery['filtering']>('filterable'),
  stringLiteral<Discovery['filtering']>('non-filterable'),
]);

const pastEnrollmentStatusDecoder: Decoder<PastEnrolled> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('past-enrolled')),
);

const currentEnrollmentStatusDecoder: Decoder<CurrentlyEnrolled> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('currently-enrolled')),
);

const futureEnrollmentStatusDecoder: Decoder<FutureEnrolled> = succeed({})
  .assign('kind', field('kind', stringLiteral('future-enrolled')))
  .assign('date', field('date', date));

const enrollmentStatusTypeDecoder: Decoder<EnrollmentStatusType> = oneOf<EnrollmentStatusType>([
  pastEnrollmentStatusDecoder.map<EnrollmentStatusType>(identity),
  currentEnrollmentStatusDecoder.map<EnrollmentStatusType>(identity),
  futureEnrollmentStatusDecoder.map<EnrollmentStatusType>(identity),
]);

const activeRegistrationDecoder: Decoder<ActiveInvitation> = succeed({})
  .assign('kind', field('kind', stringLiteral('active-invitation')))
  .assign('type', field('type', registrationTypeDecoder));

const inactiveRegistrationDecoder: Decoder<InactiveInvitation> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('inactive-invitation')),
);

const registrationKindDecoder: Decoder<InvitationActivation> = oneOf<InvitationActivation>([
  activeRegistrationDecoder.map<InvitationActivation>(identity),
  inactiveRegistrationDecoder.map<InvitationActivation>(identity),
]);

const registrationInvitationSourceDecoder: Decoder<RegistrationInvitationSource> = oneOf([
  stringLiteral<RegistrationInvitationSource>('new-program-family-shared-open-enrollment'),
  stringLiteral<RegistrationInvitationSource>('program-family-shared-open-enrollment'),
  stringLiteral<RegistrationInvitationSource>('shared-open-enrollment'),
  stringLiteral<RegistrationInvitationSource>('open-enrollment'),
  stringLiteral<RegistrationInvitationSource>('direct-enrollment'),
  stringLiteral<RegistrationInvitationSource>('preview-only'),
]);

const useCaseExplorePayloadDecoder: Decoder<UseCaseExplorePayload> = succeed({})
  .assign('id', field('id', number))
  .assign('uuid', field('invitation_uuid', string))
  .assign('name', field('name', alreadyTranslatedText))
  .assign('registrationType', field('registration_type', registrationKindDecoder))
  .assign('programsCount', field('programs_count', number))
  .assign('enrollmentStatus', field('enrollment', enrollmentStatusTypeDecoder))
  .assign('source', field('source', registrationInvitationSourceDecoder));

export const useCaseExploreResourceDecoder: Decoder<UseCaseExploreResource> = resourceDecoder(
  useCaseExplorePayloadDecoder,
);

const discoveryDecoder: Decoder<Discovery> = succeed({})
  .assign(
    'learningCollections',
    field('learning_collections', array(useCaseExploreResourceDecoder).map(fromArrayMaybe)),
  )
  .assign('filtering', field('filtering', filteringDecoder));

export const discoveryResourceDecoder: Decoder<DiscoveryResource> =
  resourceDecoder(discoveryDecoder);
