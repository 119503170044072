import * as React from 'react';
import { observer } from 'mobx-react';
import FreebusyProgramAdStore from './FreebusyProgramAdStore';
import FreebusyProgramAdReady from './FreebusyProgramAdReady';
import { take, map } from '@execonline-inc/collections';
import { just } from 'maybeasy';
import AdDisplayContent from './AdDisplayContent';
import FreebusyStore from '../../../FreebusyStore';

interface Props {
  store: FreebusyProgramAdStore;
}

class FreebusyProgramAdStates extends React.Component<Props> {
  freebusyStore = new FreebusyStore('freebusy-program');
  render() {
    const { store } = this.props;
    switch (store.state.kind) {
      case 'waiting':
      case 'error':
      case 'loading':
        return <></>;
      case 'reloading':
        return <AdDisplayContent freebusyStore={this.freebusyStore} />;
      case 'ready':
        return just(store.state.freebusyProgramAdsResource.payload)
          .map(take(1))
          .map(
            map(freebusyProgramAdResource => (
              <FreebusyProgramAdReady
                freebusyProgramAdStore={store}
                freebusyProgramAdResource={freebusyProgramAdResource}
              />
            ))
          )
          .map(ad => <>{ad}</>)
          .getOrElseValue(<></>);
    }
  }
}

export default observer(FreebusyProgramAdStates);
