import * as React from 'react';
import { AlertNode } from '../../ContentEmbed/Types';
interface Props {
  node: AlertNode;
}
const Alert: React.FC<Props> = ({ node }) => {
  alert(node.content);
  return <span />;
};
export default Alert;
