import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { learningPartnerStore } from '../../../People';
import { PersonStore } from '../../../Person';
import { Document } from '../../../TeamResourceStore/Types';
import { L, NotTranslated, T } from '../../../Translations';
import DocumentIcon from '../../Cutesies/DocumentIcon';
import DownArrowIcon from '../../Cutesies/DownArrowIcon';
import { useOpenable } from '../../Openable';
import * as avatarStyle from '../../Person/style.module.css';
import PersonPhoto from '../../PersonPhoto';
import * as style from '../../Resources/style.module.css';
import LearningPartnerUploadModal from '../LearningPartnerUploadModal';
import { LearningPartnerAssignment } from '../Types';
import * as learningPartnerUploadStyle from './style.module.css';

interface Props {
  assignment: LearningPartnerAssignment;
  resource: Document;
}

function LearningPartnerUpload({ assignment, resource }: Props) {
  const { openableState, close, open } = useOpenable();
  const store: PersonStore = useMemo(() => learningPartnerStore(assignment.learningPartner), [
    assignment,
  ]);

  return (
    <div className={style.resource} data-test-learning-partner-upload={true}>
      <a onClick={open} data-test-learning-partner-upload-button={true}>
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.details}>
          <h2 className={style.name} data-test-resource-title={true}>
            <NotTranslated text={resource.title} />
          </h2>
          <div className={style.date} data-test-resource-added-on={resource.createdAt}>
            <T
              kind="Document added on <date/>"
              date={
                <span className={style.timestamp}>
                  <L localizeable={resource.createdAt} format="short-month-day-year" />
                </span>
              }
            />
          </div>
        </div>
        <div className={avatarStyle.person}>
          <div className={avatarStyle.avatar}>
            <PersonPhoto avatar={store.avatar} />
          </div>
          <div className={learningPartnerUploadStyle.avatarDetails}>
            <NotTranslated text={store.name.getOrElseValue('')} />
          </div>
        </div>
        <div className={learningPartnerUploadStyle.download}>
          <div className={learningPartnerUploadStyle.downloadIcon}>
            <DownArrowIcon />
          </div>
        </div>
      </a>
      <LearningPartnerUploadModal assignment={assignment} state={openableState} close={close} />
    </div>
  );
}

export default observer(LearningPartnerUpload);
