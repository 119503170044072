import { observer } from 'mobx-react';
import * as React from 'react';
import ConferenceRosterStore from '../../../ConferenceRosterStore';
import { ConferenceEventType } from '../../../ConferenceRosterStore/Types';
import PrivateRoster from './PrivateRoster';
import PublicRoster from './PublicRoster';

interface Props {
  conferenceRosterStore: ConferenceRosterStore;
}

const Participants: React.FC<Props> = ({ conferenceRosterStore }) =>
  conferenceRosterStore.eventType
    .map((eventType: ConferenceEventType) => {
      switch (eventType) {
        case 'private':
          return <PrivateRoster conferenceRosterStore={conferenceRosterStore} />;
        case 'public':
          return <PublicRoster conferenceRosterStore={conferenceRosterStore} />;
      }
    })
    .getOrElseValue(<></>);

export default observer(Participants);
