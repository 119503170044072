import { noop } from '@kofno/piper';
import { Task } from 'taskarian';

export interface CouldNotReloadError {
  kind: 'could-not-reload-error';
}

export const couldNotReloadError = (): CouldNotReloadError => ({ kind: 'could-not-reload-error' });

// TODO: Using `rootResourceStore.reload` works for many pages on the web, but
// sometimes it results in a 404 page (eg. on a segment page, taking a course).
const webReload = (): Task<CouldNotReloadError, void> =>
  new Task((reject, resolve) => {
    try {
      window.location.reload();
    } catch {
      reject(couldNotReloadError());
      return noop;
    }
    resolve();
    return noop;
  });

export const locationReload = (): Task<CouldNotReloadError, void> => {
  return webReload();
};
