import { observer } from 'mobx-react';
import * as React from 'react';
import { CloseAction, OpenableState, WithOpenableLogic } from '../../Openable';
import AssignmentModal from '../../TeamProfile/TeamFiles/DocumentResource/AssignmentModal';
import { LearningPartnerAssignment } from '../Types';

interface Props {
  assignment: LearningPartnerAssignment;
  state: OpenableState;
  close: CloseAction;
}

const LearningPartnerUploadModal: React.FC<Props> = ({ state, close, assignment }) => {
  return (
    <WithOpenableLogic state={state}>
      <AssignmentModal assignment={assignment} close={close} />
    </WithOpenableLogic>
  );
};

export default observer(LearningPartnerUploadModal);
