import { assertNever } from '@kofno/piper';
import { ContentState, EditorState } from 'draft-js';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { FlashAlert, errorAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { ChatReplyState, broadcasting, hidden, ready, reset, updating } from './Types';

class ChatReplyStore {
  @observable
  state: ChatReplyState = hidden(EditorState.createEmpty());

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  setEditorMessage = (reply: EditorState) => {
    this.state = ready(reply);
  };

  @action
  hide = () => {
    switch (this.state.kind) {
      case 'broadcasting':
      case 'ready':
        this.state = hidden(this.state.reply);
        break;
      case 'hidden':
      case 'error':
      case 'updating':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  broadcasting = () => {
    switch (this.state.kind) {
      case 'updating':
        this.state = broadcasting(this.state.reply);
        break;
      case 'broadcasting':
      case 'error':
      case 'ready':
      case 'hidden':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = () => {
    switch (this.state.kind) {
      case 'broadcasting':
      case 'ready':
      case 'updating':
      case 'hidden':
        this.state = ready(this.state.reply);
        break;
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  reset = () => {
    this.state = reset();
  };

  @action
  updating = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = updating(this.state.reply);
        break;
      case 'broadcasting':
      case 'error':
      case 'updating':
      case 'hidden':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'broadcasting':
      case 'updating':
      case 'hidden':
        return nothing();
    }
  }

  @computed
  get message(): Maybe<EditorState> {
    switch (this.state.kind) {
      case 'ready':
      case 'broadcasting':
      case 'updating':
        return just(this.state.reply);
      case 'error':
      case 'hidden':
        return nothing();
    }
  }

  @computed
  get currentMessageContent(): Maybe<ContentState> {
    switch (this.state.kind) {
      case 'ready':
      case 'broadcasting':
      case 'updating':
        return just(this.state.reply.getCurrentContent());
      case 'error':
      case 'hidden':
        return nothing();
    }
  }
}

export default ChatReplyStore;
