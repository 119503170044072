import { findIndex } from '@execonline-inc/collections';
import { fromEmpty, Maybe } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ConferenceParticipants } from '../../../ConferenceRosterStore/Types';
import { ready, settingStaffList, StaffPresenceMember, StaffPresenceState, waiting } from './Types';

class StaffPresenceStore {
  presentStaffList = observable.array<StaffPresenceMember>([]);

  @observable
  state: StaffPresenceState = waiting();

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  setStaffList = (presentParticipants: Maybe<ConferenceParticipants>) => {
    this.state = settingStaffList(presentParticipants);
  };

  @action
  addStaff = (staffMember: StaffPresenceMember) => {
    this.presentStaffList.push(staffMember);
  };

  @action
  removeStaff = (idToRemove: number) => {
    findIndex((s) => s.id === idToRemove, this.presentStaffList).do((idx) =>
      this.presentStaffList.splice(idx, 1)
    );
  };

  @computed
  get displayedPresentStaffList(): Maybe<string> {
    return fromEmpty(this.presentStaffList).map((list) =>
      list.map((staffMember) => staffMember.shortName).join(', ')
    );
  }
}

export default StaffPresenceStore;
