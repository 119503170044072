import { observer } from 'mobx-react';
import { LeadershipPersonaSelectFormFieldOption } from '../../../Types';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import PersonaTraitIcon from './PersonaTraitIcon';

interface Props {
  option: LeadershipPersonaSelectFormFieldOption;
  trait: AlreadyTranslatedText;
}

const PersonaTrait = ({ option, trait }: Props) => (
  <div className="flex items-center gap-x-1.5 text-sm">
    <PersonaTraitIcon option={option} />
    <AlreadyTranslated content={trait} />
  </div>
);

export default observer(PersonaTrait);
