import { observer } from 'mobx-react';
import * as React from 'react';
import BreakoutTimeRemaining from '../ConferencePanel/BreakoutTimeRemaining';
import BreakoutTimeRemainingStore from '../ConferencePanel/BreakoutTimeRemaining/BreakoutTimeRemainingStore';
import * as style from './style.module.css';
import { TimerIcon } from '@execonline-inc/execonline-ui';

interface Props {
  timeRemainingStore: BreakoutTimeRemainingStore;
}

const RemainingTime: React.FC<Props> = ({ timeRemainingStore }) => {
  switch (timeRemainingStore.state.kind) {
    case 'active':
    case 'expiring':
      return (
        <div className={style.timerDisplay}>
          <span className={style.timerIcon}>
            <TimerIcon color="black" />
          </span>
          <span className={style.timerValue}>
            <BreakoutTimeRemaining breakoutTimeRemainingStore={timeRemainingStore} />
          </span>
        </div>
      );
    case 'waiting':
    case 'expired':
      return <></>;
  }
};

export default observer(RemainingTime);
