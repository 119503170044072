import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { DiscoveryStore } from '.';
import { callApi } from '../Appy';
import { Errored } from '../CurrentUser/Context/WhenUserDataLoaded/ContingentStoreCommonStates';
import { findLinkT } from '../LinkyLinky';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { Link } from '../Resource/Types';
import { discoveryResourceDecoder } from './Decoders';
import { State } from './States';

interface Props extends RCProps<DiscoveryStore> {}

export class DiscoveryReactions extends ReactionComponent<DiscoveryStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
      case 'refreshing':
        Task.succeed<Errored['error'], ReadonlyArray<Link>>(state.currentUserResource.links)
          .andThen(findLinkT('discovery-portal'))
          .andThen(callApi(discoveryResourceDecoder, {}))
          .fork(store.errored, store.ready);
        break;
      case 'ready':
        break;
      case 'errored':
        warn('Failed to fetch current user discovery resource.', toJS(state.error));
        break;
      default:
        assertNever(state);
    }
  };
}
