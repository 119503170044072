import {
  CloseIcon,
  emptyFragment,
  MagnifyingGlassIcon,
  TextField,
} from '@execonline-inc/execonline-ui';
import { noop } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useRef } from 'react';
import { fromNullableT } from '../../../../../../TaskExt';
import { useTranslationsContext } from '../../../../../../Translations/UseTranslationsContext';

export interface Props {
  search: Maybe<string>;
  onChange: (value: Maybe<string>) => void;
}

function SearchBoxField({ search, onChange }: Props) {
  const ref = useRef<HTMLInputElement>(null);
  const { translate } = useTranslationsContext();

  const handleClear = () => {
    fromNullableT(ref.current).fork(noop, (current) => current.focus());
    onChange(nothing());
  };

  const closeIcon = () =>
    search
      .map(() => <CloseIcon size="sm" className="cursor-pointer" onClick={handleClear} />)
      .getOrElse(emptyFragment);

  return (
    <TextField
      ref={ref}
      className="h-full rounded-md !shadow-none !outline-0"
      inputClassName="pl-0 "
      value={search.getOrElseValue('')}
      placeholder={translate('Search in this collection')}
      startContent={<MagnifyingGlassIcon size="sm" />}
      autoFocus
      fullWidth
      endContent={closeIcon()}
      onChange={(e) => onChange(just(e.target.value))}
    />
  );
}

export default observer(SearchBoxField);
