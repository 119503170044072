import { observer } from 'mobx-react';
import * as React from 'react';
import FreebusyStore from '../../../FreebusyStore';
import { T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import CoachAdGraphic from '../../Cutesies/CoachAdGraphic';
import * as style from './style.module.css';

interface Props {
  freebusyStore: FreebusyStore;
}

const AdDisplayContent: React.FC<Props> = ({ freebusyStore }) => (
  <div className={style.coaching} data-test-coaching-ad={true}>
    <h2>
      <T kind="Coaching" />
    </h2>
    <div className={style.container}>
      <div className={style.graphic}>
        <div className={style.image}>
          <CoachAdGraphic />
        </div>
      </div>
      <div className={style.content}>
        <h2 className={style.title}>
          <T kind="Schedule Your Coaching Session" />
        </h2>
        <p className={style.description}>
          <T kind="Use our secure and convenient portal to find available ..." />
        </p>
        <button
          className={style.buttonSecondaryWithIcon}
          data-test-coaching-ad-button
          id="freebusy-coaching-ad-button"
          disabled={freebusyStore.schedulingDisabled}
        >
          <T kind="Schedule Your Session Now" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </button>
      </div>
    </div>
  </div>
);

export default observer(AdDisplayContent);
