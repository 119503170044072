import { Time } from '@execonline-inc/time';
import { always, pipe } from '@kofno/piper';
import { expiresIn, whenExpired } from './Core';
import { type Data } from './Types';

interface Uninitialized {
  kind: 'uninitialized';
}

export interface Active {
  kind: 'active';
  expiration: Date;
}

export interface Inactive {
  kind: 'inactive';
  expiration: Date;
}

interface TimedOut {
  kind: 'timed-out';
}

export type State = Uninitialized | Active | Inactive | TimedOut;

export const nextState = (data: Data): Data => {
  const { config, state } = data;
  switch (state.kind) {
    case 'active':
      return whenExpired(state.expiration)
        .map(always(config.gracePeriod))
        .map<Data>(pipe(expiresIn, inactive, (state) => ({ config, state })))
        .getOrElseValue(data);
    case 'inactive':
      return whenExpired(state.expiration)
        .map<Data>(pipe(timedOut, (state) => ({ config, state })))
        .getOrElseValue(data);
    case 'uninitialized':
    case 'timed-out':
      return data;
  }
};

export const uninitialized = (): Uninitialized => ({
  kind: 'uninitialized',
});

export const activeUntil = (expiration: Date): Active => ({
  kind: 'active',
  expiration,
});

export const activeNow = (duration: Time): Active => ({
  kind: 'active',
  expiration: expiresIn(duration),
});

export const inactive = (expiration: Date): Inactive => ({
  kind: 'inactive',
  expiration,
});

export const timedOut = (): TimedOut => ({
  kind: 'timed-out',
});
