import { findItem } from '@execonline-inc/collections';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReactions from '../../../../ChatMessageStore/ChatReactions';
import ConferenceRosterStore from '../../../../ConferenceRosterStore';
import ConversationStore from '../../../../ConversationStore';
import ConversationUIReactions from '../../../../ConversationStore/ConversationUIReactions';
import { conferenceParticipantStore } from '../../../../People';
import ChatMessage from '../../../ChatMessage';
import ChatMessageForm from '../../../ChatMessageForm';
import * as style from '../../../Conference/style.module.css';
import NewChatMessage from '../../../NewChatMessage';
import GroupChatReactions from './GroupChatReactions';
import ScrollToBottomReactions from './ScrollToBottomReactions';
import ScrollToBottomStore from './ScrollToBottomStore';

interface Props {
  conversationStore: ConversationStore;
  conferenceRosterStore: ConferenceRosterStore;
  scrollToBottomStore: ScrollToBottomStore;
}
class GroupChatImpl extends React.Component<Props> {
  messageListEl: React.RefObject<HTMLDivElement> = React.createRef();
  scrollObserver: Maybe<MutationObserver> = nothing();

  componentDidMount() {
    fromNullable(this.messageListEl.current).map((el) => {
      const observer = new MutationObserver(this.props.scrollToBottomStore.autoScroll);
      observer.observe(el, { childList: true });
      this.scrollObserver = just(observer);
    });
  }

  componentWillUnmount() {
    this.scrollObserver.do((observer) => observer.disconnect());
  }

  render() {
    const { conversationStore, conferenceRosterStore, scrollToBottomStore } = this.props;
    return (
      <>
        <div className={style.chats}>
          <div className={style.scroller} ref={this.messageListEl}>
            {conversationStore.messages.map((message) =>
              conferenceRosterStore.expectedParticipants
                .map((ps) => ps.concat())
                .andThen(findItem(message.userId))
                .map(conferenceParticipantStore)
                .map((personStore) => (
                  <ChatMessage message={message} key={message.id} personStore={personStore} />
                ))
                .getOrElseValue(<span key={message.id} />)
            )}
          </div>
          <NewChatMessage store={conversationStore.chatMessageStore}>
            {{
              form: <ChatMessageForm store={conversationStore.chatMessageStore} />,
            }}
          </NewChatMessage>
        </div>
        <ConversationUIReactions store={conversationStore} messageListRef={this.messageListEl} />
        <GroupChatReactions store={conversationStore} />
        <ChatReactions
          store={conversationStore.chatMessageStore}
          conversationStore={conversationStore}
          lastReadMessageStore={nothing()}
        />
        <ScrollToBottomReactions
          store={scrollToBottomStore}
          messageListRef={this.messageListEl}
          fireImmediately={true}
        />
      </>
    );
  }
}
export default observer(GroupChatImpl);
