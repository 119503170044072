import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReplyStore from '../../../../ChatReplyStore';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../../ConversationStore/Types';
import SubscriptionStore from '../SubscriptionStore';
import * as style from './../style.module.css';
import ActionMenuStore from './ActionMenuStore';
import DeleteThisMessage from './DeleteThisMessage';
import ReactToThisMessage from './ReactToThisMessage';
import ReplyToThisMessage from './ReplyToThisMessage';
import ReportThisMessage from './ReportThisMessage';
import ResubscribeButton from './ResubscribeButton';

interface Props {
  store: ActionMenuStore;
  messageResource: ChatMessageResource | ChatMessageReplyResource;
  chatReplyStore: ChatReplyStore;
  subscriptionStore: SubscriptionStore;
}

const ActionMenuBase: React.FC<Props> = ({
  store,
  messageResource,
  chatReplyStore,
  subscriptionStore,
}) => {
  const offensiveMessageReports = messageResource.payload.offensiveMessageReports;
  return (
    <div className={style.actionMenu}>
      <ReportThisMessage store={store} offensiveMessageReports={offensiveMessageReports} />
      {subscriptionStore.subscriptionResource
        .map((resource) => (
          <ResubscribeButton store={subscriptionStore} subscriptionResource={resource} />
        ))
        .getOrElse(emptyFragment)}
      <DeleteThisMessage store={store} messageResource={messageResource} />
      <ReactToThisMessage store={store} />
      <ReplyToThisMessage chatReplyStore={chatReplyStore} messageResource={messageResource} />
    </div>
  );
};

export default observer(ActionMenuBase);
