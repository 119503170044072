import { action, observable } from 'mobx';
import { ready, initializing, State, newMessage, loading } from './Types';

class ScrollToBottomStore {
  @observable
  state: State = initializing();

  @action
  autoScroll = () => {
    switch (this.state.kind) {
      case 'initializing':
        this.load();
        break;
      case 'ready':
        this.newMessage();
        break;
      case 'loading':
      case 'new-message':
        break;
    }
  };

  @action
  initializing = () => {
    this.state = initializing();
  };

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  load = () => {
    this.state = loading();
  };

  @action
  newMessage = () => {
    this.state = newMessage();
  };
}

export default ScrollToBottomStore;
