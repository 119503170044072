import * as React from 'react';
import { observer } from 'mobx-react';
import { ActiveProgram, ActiveProgramModule, ProgramSegment } from '../../../../ProgramStore/Types';
import { AlreadyTranslated } from '../../../../Translations';
import * as seqStyle from './style.module.css';
import { when } from '@execonline-inc/maybe-adapter';

interface Props {
  programDetail: ActiveProgram;
}

const seqModuleClassName = (sequenceModule: ActiveProgramModule): string =>
  when(sequenceModule.isCurrentModule, {})
    .map(() => `${seqStyle.step} ${seqStyle.current}`)
    .getOrElseValue(seqStyle.step);

const seqmentTitleClassName = (segment: ProgramSegment): string =>
  when(segment.isCurrentSegment, {})
    .map(() => `${seqStyle.partnerText} ${seqStyle.current}`)
    .orElse(() =>
      when(segment.status === 'Complete', `${seqStyle.partnerText} ${seqStyle.complete}`)
    )
    .getOrElseValue(seqStyle.partnerText);

const Progress: React.FC<Props> = ({ programDetail }) => (
  <div className={seqStyle.progress}>
    <div className={seqStyle.container}>
      <div className={seqStyle.moduleArrows}>
          <div className={seqStyle.overflow}>
            {programDetail.modules.map((sequenceModule) => (
              <span className={seqModuleClassName(sequenceModule)} key={sequenceModule.id}>
                <span>
                  <AlreadyTranslated content={sequenceModule.title} />
                </span>
              </span>
            ))}
        </div>
      </div>
      <div>
        <div className={seqStyle.columns}>
          {programDetail.modules.map((sequenceModule) => (
            <div className={seqStyle.column} key={sequenceModule.id}>
              <div className={seqStyle.row1}>
                {sequenceModule.segments.map((segment) => (
                  <div className={seqStyle.segment} key={segment.id}>
                    <div className={seqmentTitleClassName(segment)}>
                      <span>{segment.title}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default observer(Progress);
