export type IconColor =
  | 'primary'
  | 'secondary'
  | 'white'
  | 'black'
  | 'gray'
  | 'disabled'
  | 'inherit';

export type IconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'inherit';

export const fontSizeIconClassNameMapping: Record<IconSize, string> = {
  xxs: 'text-font-size-xs',
  xs: 'text-font-size-md',
  sm: 'text-font-size-lx',
  md: 'text-font-size-2xl',
  lg: 'text-font-size-4xl',
  inherit: 'text-font-size-inherit',
};
