import { observer } from "mobx-react"
import { T, TPlainTextKey } from '../../Translations';
import { Link, Route } from 'react-router-dom';
import BreadCrumbSeparatorIcon from '../Cutesies/BreadCrumbSeparatorIcon';
import TeamArrowIcon from '../Cutesies/TeamArrowIcon';
import * as style from '../TeamProfile/style.module.css';

interface Props{
    teamName: string;
    teamLink: TPlainTextKey;
}

const BreadCrumbLink: React.FC<Props> = ({ teamName, teamLink }) => (
  <>
    <h1 className={style.breadcrumb} data-test-page-header="Epcot Squad">
      <Route
        exact={true}
        path="/teams/:teamId"
        render={() => (
          <>
            <Link to="/communities" data-test-communities-link="Communities">
              <T kind={teamLink} />
            </Link>
            <span className={style.breadcrumbSeparator}>
              <BreadCrumbSeparatorIcon />
            </span>
          </>
        )}
      />
      <span data-test-team-name={teamName}>{teamName}</span>
    </h1>
    <div className={style.buttonIcon}>
      <TeamArrowIcon />
    </div>
  </>
);

export default observer(BreadCrumbLink)