import { just, Maybe, nothing } from 'maybeasy';
import { Error } from '../ErrorHandling';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}
interface Ready {
  kind: 'ready';
  email: Maybe<string>;
}

interface Creating {
  kind: 'creating';
  email: Maybe<string>;
}

interface Requested {
  kind: 'requested';
  email: Maybe<string>;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (email: Maybe<string>): Ready => ({
  kind: 'ready',
  email: email,
});

export const creating = (email: string): Creating => ({
  kind: 'creating',
  email: just(email),
});

export const requested = (): Requested => ({
  kind: 'requested',
  email: nothing(),
});

export type ResetPasswordState = Waiting | Loading | Ready | Creating | Requested | Error;
