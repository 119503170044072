import { fromNullable, just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoom } from '../../Conference/Types';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { Event, EventResource } from '../../EventsStore/Types';
import { toastsStore } from '../../ToastsStore';
import Conference from '../Conference';
import { eventRoomConfig } from '../Conference/Types';
import EventToastDismisserReaction from './EventToastDismisserReaction';

interface Props {
  event: Event;
  eventResource: EventResource;
  conferenceRoom: LocalConferenceRoom;
  eventId: number;
}

const LocalConference: React.FC<Props> = ({ event, eventResource, conferenceRoom, eventId }) => (
  <WithCurrentUser
    children={(currentUserResource) => (
      <>
        <Conference
          title={event.title}
          detail={fromNullable(event.description)}
          conferenceableResource={eventResource}
          agenda={event.agenda}
          eventResource={just(eventResource)}
          conversationResource={conferenceRoom.conversationResource}
          roomConfig={eventRoomConfig(eventId)}
          currentUserResource={currentUserResource}
          conferenceRoom={conferenceRoom}
        />
        <EventToastDismisserReaction uuid={event.uuid} store={toastsStore} fireImmediately={true} />
      </>
    )}
  />
);

export default observer(LocalConference);
