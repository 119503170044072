import { ButtonProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SegmentResource } from '../../../../../SegmentStore/Types';
import SegmentStore from '../../../../../SegmentStore';
import AdvanceButton from '../AdvanceButton';

interface Props extends ButtonProps {
  store: SegmentStore;
}

function AdvancerButton({ store, ...others }: Props) {
  return store.segmentResource.cata({
    Just: (segmentResource: SegmentResource) => {
      switch (segmentResource.payload.type) {
        case 'overview':
        case 'presentation':
        case 'team-discussion':
        case 'external-program':
        case 'lecture':
          return <AdvanceButton {...others} />;
        case 'assignment-due':
        case 'survey':
          return emptyFragment();
      }
    },
    Nothing: () => {
      return emptyFragment();
    },
  });
}

export default observer(AdvancerButton);
