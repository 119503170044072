import { observer } from 'mobx-react';
import React from 'react';
import { DeliveryChannel, ExperienceResource } from '../../Common/Experience/Types';
import DeliveryChannelContent from './DeliveryChannelContent';
import Store from './Store';

interface Props {
  experienceResource: ExperienceResource;
  deliveryChannel: DeliveryChannel;
}

class Content extends React.Component<Props> {
  store = new Store();

  render() {
    switch (this.store.state.kind) {
      case 'hiding':
        return <></>;
      case 'showing':
        return (
          <DeliveryChannelContent
            experienceResource={this.props.experienceResource}
            deliveryChannel={this.props.deliveryChannel}
            store={this.store}
          />
        );
    }
  }
}

export default observer(Content);
