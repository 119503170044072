import { observer } from 'mobx-react';
import * as React from 'react';
import ModulesCompletedNone from './ModulesCompletedNone';
import ModulesCompletedSome from './ModulesCompletedSome';

interface Props {
  completedCount: number;
  remainingCount: number;
}

const ModulesCompleted: React.FC<Props> = ({ completedCount, remainingCount }) =>
  completedCount > 0 ? (
    <ModulesCompletedSome completedCount={completedCount} remainingCount={remainingCount} />
  ) : (
    <ModulesCompletedNone remainingCount={remainingCount} />
  );

export default observer(ModulesCompleted);
