import * as React from 'react';
import { observer } from 'mobx-react';
import WithCurrentUser from '../../../../../CurrentUser/Context/WithCurrentUser';
import Hero from './Hero';
import { ExternalProgramResource } from '../../../../../SegmentStore/Types';

interface Props {
  externalProgramResource: ExternalProgramResource;
}

const HeroSection: React.FC<Props> = ({ externalProgramResource }) => {
  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <Hero
          currentUserResource={currentUserResource}
          externalProgramResource={externalProgramResource}
        />
      )}
    />
  );
};

export default observer(HeroSection);
