import { when } from '@execonline-inc/maybe-adapter';
import { Time, toJS } from '@execonline-inc/time';
import { assertNever, pick } from '@kofno/piper';
import * as date from 'date-fns';
import { Maybe, just, nothing } from 'maybeasy';
import { not } from 'ramda';
import * as xoid from 'xoid';
import { Active, activeNow, activeUntil } from './States';
import { Actions, Atom, Config, type Data } from './Types';

export const whenExpired = (expiration: Date): Maybe<Date> =>
  when(not(date.isFuture(expiration)), expiration);

export const whenNearlyExpired = (expiration: Date, proximity: Time): Maybe<Date> =>
  whenExpired(subDuration(expiration, proximity));

export const userActivityDetected = (e: string, atom: Atom) => (): void => {
  const { state } = atom.value;
  switch (state.kind) {
    case 'active':
      atom.actions.activeNow(e);
      break;
    case 'inactive':
    case 'timed-out':
    case 'uninitialized':
      break;
    default:
      assertNever(state);
  }
};

export const handleInactivityTimeout = ({ config, state }: Data): void => {
  switch (state.kind) {
    case 'timed-out':
      config.onTimeout();
      break;
    case 'uninitialized':
    case 'active':
    case 'inactive':
      break;
    default:
      assertNever(state);
  }
};

export const now = (): Date => new Date();

export const addDuration = (timestamp: Date, duration: Time): Date =>
  date.addMilliseconds(timestamp, toJS(duration));

export const subDuration = (timestamp: Date, duration: Time): Date =>
  date.subMilliseconds(timestamp, toJS(duration));

export const expiresIn = (duration: Time): Date => addDuration(now(), duration);

export const isDocumentHidden = (): boolean => {
  switch (document.visibilityState) {
    case 'hidden':
      return true;
    case 'visible':
      return false;
  }
};

const unlessActivityStale = (timestamp: Date, duration: Time): Maybe<Date> => {
  const stalenessThreshold = subDuration(now(), duration);
  return when(timestamp >= stalenessThreshold, timestamp);
};

/**
 * In the event that activity messages are received delayed or out of order, this will ensure that
 * the activity expiration is not unintentionally set to be shorter.
 */
const latestActivity = (state1: Active, state2: Active): Active => ({
  kind: 'active',
  expiration: date.max([state1, state2].map(pick('expiration'))),
});

export const actions = (atom: xoid.Atom<Data>): Actions => {
  const reconfig = (reconfigurations: Partial<Config>): void => {
    atom.update((previous) => ({
      ...previous,
      config: { ...previous.config, ...reconfigurations },
    }));
    actions.activeNow('inactivity:reconfigured');
  };

  const reportActivity = (event: string, { expiration }: Active): void =>
    atom.value.config.onLocalActivity(event, expiration);

  const handleActivity =
    (event: string) =>
    (state: Active): void => {
      atom.update((previous) => ({ ...previous, state }));
      reportActivity(event, state);
    };

  const whenActiveNow = (): Maybe<Active> => {
    const { config, state } = atom.value;

    switch (state.kind) {
      case 'uninitialized':
      case 'inactive':
        return just(activeNow(config.timeLimit));
      case 'active':
        return just(latestActivity(activeNow(config.timeLimit), state));
      case 'timed-out':
        return nothing();
    }
  };

  const whenActiveAt = (timestamp: Date): Maybe<Active> => {
    const { config, state } = atom.value;

    switch (state.kind) {
      case 'uninitialized':
        return just(activeUntil(addDuration(timestamp, config.timeLimit)));
      case 'active':
        return just(latestActivity(activeUntil(addDuration(timestamp, config.timeLimit)), state));
      case 'inactive':
        return unlessActivityStale(timestamp, config.timeLimit)
          .map(() => addDuration(timestamp, config.timeLimit))
          .map(activeUntil);
      case 'timed-out':
        return nothing();
    }
  };

  const whenActiveUntil = (timestamp: Date): Maybe<Active> => {
    const { config, state } = atom.value;

    switch (state.kind) {
      case 'uninitialized':
        return just(activeUntil(timestamp));
      case 'active':
        return just(latestActivity(activeUntil(timestamp), state));
      case 'inactive':
        return unlessActivityStale(timestamp, config.gracePeriod).map(activeUntil);
      case 'timed-out':
        return nothing();
    }
  };

  const actions = {
    reconfig,

    activeNow: (event: string): Maybe<Active> => whenActiveNow().do(handleActivity(event)),

    activeAt: (timestamp: Date, event: string): Maybe<Active> =>
      whenActiveAt(timestamp).do(handleActivity(event)),

    activeUntil: (timestamp: Date, event: string): Maybe<Active> =>
      whenActiveUntil(timestamp).do(handleActivity(event)),

    /**
     * Used for updating the inactivity timer after receiving the update from an external
     * source (not the current window).
     *
     * Does not report activity as that will trigger updates to external sources, leading to
     * an infinite loop.
     */
    activeExternally: (expiration: number): Maybe<Active> =>
      whenActiveUntil(new Date(expiration)).do((state) =>
        atom.update((previous) => ({ ...previous, state })),
      ),
  };

  return actions;
};
