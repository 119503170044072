import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { error } from '../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../Notifications/Types';
import { Linkable } from '../../../Resource/Types';
import { TPlainTextKey } from '../../../Translations';
import { FreebusyAdsResource, loading, ready, reloading, State, waiting } from './Types';

class FreebusyCoachingAdStore {
  @observable
  state: State = waiting();

  readonly currentUserResource: CurrentUserResource;

  constructor(currentUserResource: CurrentUserResource) {
    this.currentUserResource = currentUserResource;
  }

  @action
  load = (linkable: Linkable) => {
    this.state = loading(linkable);
  };

  @action
  ready = (freebusyCoachingAdsResource: FreebusyAdsResource) => {
    this.state = ready(freebusyCoachingAdsResource);
  };

  @action
  reload = () => {
    this.state = reloading(this.currentUserResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'waiting':
      case 'loading':
      case 'reloading':
      case 'ready':
        return nothing();
    }
  }
}

export default FreebusyCoachingAdStore;
