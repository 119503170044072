import { warn } from '@execonline-inc/logging';
import { parseIntM } from '@execonline-inc/numbers';
import { always, assertNever, pipe, UnaryFunction } from '@kofno/piper';
import Decoder, { field, number, succeed } from 'jsonous';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as Pusher from 'pusher-js';
import * as React from 'react';
import { Result } from 'resulty';
import { eventPollDecoder } from '../../../components/Conference/EventPoll/EventPollStore/Decoders';
import StaffPresenceStore from '../../../components/EventDashboard/StaffPresenceStore';
import { staffPresenceMemberDecoder } from '../../../components/EventDashboard/StaffPresenceStore/Decoders';
import { PusherStaffPresenceMember } from '../../../components/EventDashboard/StaffPresenceStore/Types';
import StaffRequestStore from '../../../components/EventDashboard/StaffRequestStore';
import { ChatMessageReplyResource, ChatMessageResource } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { handEventBottom, HandEventClock, handEventOrder } from '../../../HandRaiser/types';
import handRaisers from '../../../HandRaisers';
import { warnAndNotify } from '../../../Honeybadger';
import { organizationStore } from '../../../Organization/Store';
import { warnAndNotifyDecoderError } from '../../../Resource/Decoders';
import { subscriptionErrorDecoder } from '../../BreakoutChannel/Handler/Binding';
import PresenceMembersStore from '../PresenceMembersStore';
import { memberDecoder, membersDecoder } from '../PresenceMembersStore/Decoders';
import { PresenceMember, PusherMembers } from '../PresenceMembersStore/Types';

interface Props {
  channel: Pusher.Channel;
  store: PresenceMembersStore;
  staffPresenceStore: StaffPresenceStore;
  staffRequestStore: StaffRequestStore;
  currentUserResource: CurrentUserResource;
}

const transform = (pusherMembers: PusherMembers): Result<string, PresenceMember[]> => {
  return membersDecoder
    .decodeAny(pusherMembers.members)
    .map((memberPairs) => memberPairs.map(([_, member]) => member));
};

const alwaysBottom = pipe(always, handEventBottom, succeed) as UnaryFunction<
  string,
  Decoder<HandEventClock>
>;

const versionDecoder = field('order', number)
  .map<HandEventClock>(handEventOrder)
  .orElse(alwaysBottom);

const handraisingPayload = succeed({})
  .assign('id', field('user_id', number))
  .assign('version', versionDecoder);

const decodeSubscriptionError = (data: unknown) => subscriptionErrorDecoder.decodeAny(data);

class PresenceBinding extends React.Component<Props, {}> {
  componentDidMount() {
    const { channel } = this.props;

    channel.bind('pusher:subscription_succeeded', (data: PusherMembers) => {
      transform(data).cata({
        Err: (err) =>
          warnAndNotify('PresenceBinding', 'pusher:subscription_succeeded', { failure: err }),
        Ok: this.props.store.setMembers,
      });
    });

    channel.bind('pusher:subscription_error', (data: unknown) => {
      const errorName = '{PRESENCE_FAIL}';
      const errorMessage = `Failed to subscribe to channel ${channel.name}`;
      just({})
        .assign(
          'accountUID',
          organizationStore.resource.andThen(({ payload }) => payload.accountUid)
        )
        .assign('contactUID', this.props.currentUserResource.payload.contactUid)
        .do((accountInfo) =>
          decodeSubscriptionError(data)
            .do((error_data) =>
              warn(errorName, errorMessage, {
                ...error_data,
                ...accountInfo,
              })
            )
            .elseDo(() =>
              warn(errorName, errorMessage, {
                ...accountInfo,
              })
            )
        )
        .elseDo(() =>
          decodeSubscriptionError(data).do((error_data) =>
            warn(errorName, errorMessage, {
              ...error_data,
            })
          )
        );
    });

    channel.bind('pusher:member_added', (data: unknown) => {
      field('info', memberDecoder)
        .decodeAny(data)
        .cata({
          Ok: this.props.store.addMember,
          Err: (err) => warnAndNotify('PresenceBinding', 'pusher:member_added', { failure: err }),
        });
    });

    channel.bind('pusher:member_removed', (data: { id: string }) => {
      parseIntM(data.id)
        .do(this.props.store.removeMember)
        .andThen(handRaisers.findHandRaiser)
        .do((hr) => hr.handDown(handEventBottom()));
    });

    channel.bind('office_hours:hand_raised', (payload: unknown) =>
      handraisingPayload.decodeAny(payload).cata({
        Ok: ({ id, version }) => {
          handRaisers
            .findHandRaiser(id)
            .orElse(() => handRaisers.addHandRaiser(id))
            .map((hr) => hr.handUp(version));
        },
        Err: warnAndNotifyDecoderError('Unexpected office_hours:hand_raised payload'),
      })
    );

    channel.bind('office_hours:hand_lowered', (payload: unknown) =>
      handraisingPayload.decodeAny(payload).cata({
        Ok: ({ id, version }) => {
          handRaisers
            .findHandRaiser(id)
            .orElse(() => handRaisers.addHandRaiser(id))
            .map((hr) => hr.handDown(version));
        },
        Err: warnAndNotifyDecoderError('Unexpected office_hours:hand_lowered payload'),
      })
    );

    channel.bind('new-poll', (payload: unknown) => {
      eventPollDecoder.decodeAny(payload).cata({
        Ok: (eventPoll) => {
          this.props.store.launchPoll(eventPoll);
        },
        Err: warnAndNotifyDecoderError('Unexpected new-poll payload'),
      });
    });

    channel.bind('close-poll', this.props.store.closePoll);

    channel.bind('new-message', (data: ChatMessageResource | ChatMessageReplyResource) => {
      this.props.store.newMessage(data);
    });

    channel.bind('updated-message', (data: ChatMessageResource | ChatMessageReplyResource) => {
      this.props.store.updatedMessage(data);
    });

    channel.bind('deleted-message', (id: number) => {
      this.props.store.deletedMessage(id);
    });

    channel.bind('client-help-request', (data: { action: 'cancel' | 'request' }) => {
      switch (data.action) {
        case 'cancel': {
          this.props.staffRequestStore.ready();
          break;
        }
        case 'request': {
          this.props.staffRequestStore.request();
          break;
        }
        default:
          assertNever(data.action);
      }
    });

    channel.bind('staff-joined', (pusherStaffPresenceMember: PusherStaffPresenceMember) => {
      staffPresenceMemberDecoder.decodeAny(pusherStaffPresenceMember).cata({
        Ok: (staffPresenceMember) => {
          this.props.staffPresenceStore.addStaff(staffPresenceMember);
          this.props.staffRequestStore.ready();
        },
        Err: (err) => warnAndNotify('PresenceBinding', 'staff-joined', { failure: err }),
      });
    });

    channel.bind('staff-left', (id: number) => {
      this.props.staffPresenceStore.removeStaff(id);
    });
  }

  componentWillUnmount() {
    const { channel } = this.props;
    channel.unbind_all();
  }

  render() {
    return null;
  }
}

export default observer(PresenceBinding);
