import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../Resource/Types';
import ScheduledToastStore from '../../../ToastsStore/ScheduledToastStore';
import { T } from '../../../Translations';
import * as style from '../style.module.css';
import DismissButton from './DismissButton';

interface Props {
  store: ScheduledToastStore;
  link: Link;
}
const ExternalTemplate: React.FC<Props> = ({ store, link }) => {
  return (
    <div className={style.actions}>
      <DismissButton store={store} />
      <a
        className={style.button}
        href={link.href}
        data-test-toast-join={true}
        target="_blank"
        rel="noopener noreferrer"
      >
        <T kind="Join Meeting" />
      </a>
    </div>
  );
};

export default observer(ExternalTemplate);
