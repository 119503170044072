import { observer } from 'mobx-react';
import Announcement from './Announcement';
import AnnouncementsActions from './AnnouncementsActions';
import AnnouncementsHeader from './AnnouncementsHeader';
import {
  AllAnnouncementsLoaded,
  LoadingMoreAnnouncements,
  LoadingMoreFailed,
  OnLoadMore,
  SomeAnnouncementsLoaded,
} from './UseAnnouncementsLoading';
import * as style from './style.module.css';

interface AnnouncementsSectionProps {
  state:
    | AllAnnouncementsLoaded
    | SomeAnnouncementsLoaded
    | LoadingMoreAnnouncements
    | LoadingMoreFailed;
  onLoadMore: OnLoadMore;
}

function AnnouncementsSection({ state, onLoadMore }: AnnouncementsSectionProps) {
  const { announcements } = state;
  return (
    <div className={style.announcementsContainer}>
      <AnnouncementsHeader />
      {announcements.map((announcement) => (
        <Announcement announcement={announcement} key={announcement.payload.id} />
      ))}
      <AnnouncementsActions state={state} onLoadMore={onLoadMore} />
    </div>
  );
}

export default observer(AnnouncementsSection);
