import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Task } from 'taskarian';
import { getCookieT } from '../Cookies';
import Loader from '../components/Loader';
import Form from './Form';
import { opaqueUseCaseIdentifier, username } from './Functions';
import { SsoError, State, loadingText } from './Types';

interface Props {
  state: State;
  onFailure: (err: SsoError) => void;
  onSuccess: () => void;
}

const Initiate: React.FC<Props> = ({ state, onFailure, onSuccess }) => {
  return (
    <>
      <Loader text={just(loadingText)} />
      <Form
        username={username(state)}
        opaqueUseCaseIdentifier={opaqueUseCaseIdentifier(state)}
        href={Task.succeed(state.urls.start)}
        csrfToken={getCookieT('csrf_token')}
        onSubmissionFailure={onFailure}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default observer(Initiate);
