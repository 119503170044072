import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import NotSupportedInLanguageStore from '../../../NotSupportedInLanguageStore';
import ProgramStore from '../../../ProgramStore';
import { ProgramDetailResource, ProgramSegment } from '../../../ProgramStore/Types';
import ProgramButton from './ProgramButton';
import PositionContext from '../../PositionContext';
import WhatsNext from '../../ProgramOverview/WhatsNext';
import * as style from '../../ProgramSummary/style.module.css';
import ProgressBar from '../ProgressBar';
import TimeRemaining from '../TimeRemaining';
import ProgramLanguageNotAvailableMessage from './ProgramLanguageNotAvailableMessage';
import RelatedPeople from './RelatedPeople';

interface Props {
  programDetailResource: ProgramDetailResource;
  currentSegment: Maybe<ProgramSegment>;
  programStore: ProgramStore;
  currentUserResource: CurrentUserResource;
}

const ProgramOverview: React.FC<Props> = ({
  programDetailResource,
  currentSegment,
  programStore,
  currentUserResource,
}) => {
  const programNotSupportedInLanguageStore = new NotSupportedInLanguageStore(
    programDetailResource.payload.availableLanguages,
    currentUserResource,
  );
  return (
    <PositionContext.Provider value={{ kind: 'dashboard' }}>
      <div className={style.overview} data-test-program-overview={programDetailResource.payload.id}>
        <ProgressBar
          programDetailResource={programDetailResource}
          currentSegment={currentSegment}
        />
        <div className={style.details}>
          <div>
            <ProgramButton programStore={programStore} />
            <ProgramLanguageNotAvailableMessage
              programNotSupportedInLanguageStore={programNotSupportedInLanguageStore}
            />
          </div>
          <div className={style.detailsCourse}>
            <WhatsNext segment={currentSegment} />
          </div>

          <div className={style.detailsPeople}>
            <RelatedPeople
              programDetailResource={programDetailResource}
              programStore={programStore}
            />
          </div>
          <div className={style.detailsCourse}>
            <TimeRemaining programDetailResource={programDetailResource} headingLevel={6} />
          </div>
        </div>
      </div>
    </PositionContext.Provider>
  );
};

export default observer(ProgramOverview);
