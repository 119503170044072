import { observer } from 'mobx-react';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../../../Translations';
import { Experience, learningJourneyExperience } from '../../../../../Experience/Types';

interface Props {
  experience: Experience;
}

function LearningJourneyPrograms({ experience }: Props) {
  return learningJourneyExperience(experience)
    .map(({ sections }) => (
      <Typography className="text-gray-900" variant="caption">
        <T kind="{{count}} Program" count={sections.length} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(LearningJourneyPrograms);
