import { observer } from 'mobx-react';
import { ButtonProps } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { ResumableExperience } from '../../../Common/Experience/Types';
import { exitToDashboard, exitToSegment } from '../../../Common/Experience/Actions/Exitable/Types';
import { Exitable } from '../../../Common/Experience/Actions';

interface Props {
  experience: ResumableExperience;
  buttonProps?: ButtonProps;
}

function ResumableAction({ experience, buttonProps }: Props) {
  const exitContext = () => {
    switch (experience.offeringType) {
      case 'aep':
      case 'epc':
      case 'msuite':
        return exitToSegment(experience);
      case 'coaching':
      case 'program-sequence':
      case 'group-coaching':
        return exitToDashboard();
    }
  };

  return (
    <Exitable buttonProps={{ fullWidth: true, ...buttonProps }} exitTo={exitContext}>
      <T kind="Resume Experience" />
    </Exitable>
  );
}

export default observer(ResumableAction);
