import { findPayloadT, ItemNotFound, PayloadNotFound } from '@execonline-inc/collections';
import { warn } from '@execonline-inc/logging';
import { always, assertNever } from '@kofno/piper';
import { HttpError } from 'ajaxian';
import { Task } from 'taskarian';
import { AppyError, callApi, MissingApiCompatibility, MissingApplicationId } from '../Appy';
import { handleError } from '../ErrorActionableReaction';
import { findLinkT, MissingLinkError } from '../LinkyLinky';
import { nav } from '../Navigation';
import { programsStore } from '../ProgramsStore';
import { ProgramResource } from '../ProgramsStore/Types';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { getProgramResources } from '../ResourcesStore/Types';
import ResourceStore from '../ResourceStore';
import { resourceResourceDecoder } from '../ResourceStore/Decoders';
import { ResourceState } from '../ResourceStore/Types';

const resourceEndPoint = callApi(resourceResourceDecoder, {});

export interface Props extends RCProps<ResourceStore> {
  store: ResourceStore;
  params: Params;
}

interface Params {
  programId: string;
  moduleId: string;
  segmentId: string;
  resourceId: string;
}

type ResourceRequestError =
  | MissingApplicationId
  | MissingApiCompatibility
  | MissingLinkError
  | HttpError
  | ItemNotFound
  | PayloadNotFound;

const handleResourceRequestError = (store: ResourceStore) => (error: ResourceRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('Resource link is unavailable');
      break;
    case 'item-not-found':
    case 'payload-not-found':
      nav('/404');
      break;
    default:
      handleError(store, error);
  }
};

type SegmentResourceReactionError = MissingLinkError | PayloadNotFound | AppyError;

class SegmentResourceReactions extends ReactionComponent<ResourceStore, ResourceState, Props> {
  programId = parseInt(this.props.params.programId, 10);
  moduleId = parseInt(this.props.params.moduleId, 10);
  segmentId = parseInt(this.props.params.segmentId, 10);
  resourceId = parseInt(this.props.params.resourceId, 10);

  tester = () => this.props.store.state;

  effect = (state: ResourceState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
        break;
      case 'loading':
        programsStore.resource
          .map(({ payload }) => payload.programs)
          .map<Task<SegmentResourceReactionError, ProgramResource[]>>(Task.succeed)
          .getOrElse(always(Task.succeed([])))
          .andThen(findPayloadT(this.programId))
          .map((programResource) => programResource.links)
          .andThen(findLinkT('resources'))
          .andThen(getProgramResources)
          .map((program) => program.payload.segmentResources)
          .map((segmentResources) => segmentResources.payload)
          .andThen(findPayloadT(this.moduleId))
          .map((moduleResource) => moduleResource.payload.segments)
          .andThen(findPayloadT(this.segmentId))
          .map((segment) => segment.payload.resources)
          .andThen(findPayloadT(this.resourceId))
          .map((s) => s.links)
          .andThen(findLinkT('self'))
          .andThen(resourceEndPoint)
          .fork(handleResourceRequestError(this.props.store), this.props.store.ready);
        break;
      case 'error':
        warn('Segment resource error:', state.message);
        break;
      default:
        assertNever(state);
    }
  };
}

export default SegmentResourceReactions;
