abstract class Channel<Message> {
  protected readonly channel: BroadcastChannel;

  constructor(
    public readonly name: string,
    protected readonly onMessage: (message: Message) => void,
  ) {
    this.channel = new BroadcastChannel(this.name);
    this.channel.onmessage = ({ data }) => onMessage(data);
  }

  close = () => this.channel.close();
}

export class NamedChannel<Message> extends Channel<Message> {
  constructor(name: string, onMessage: (message: Message) => void) {
    super(`broadcast-channel/${name}`, onMessage);
  }

  postMessage = (message: Message): void => {
    this.channel.postMessage(message);
  };
}

export class SignalChannel extends Channel<unknown> {
  constructor(name: string, onSignal: () => void) {
    super(`signal-channel/${name}`, onSignal);
  }

  signal = (): void => {
    this.channel.postMessage({});
  };
}
