import { noop } from '@kofno/piper';
import saveAs from 'file-saver';
import { Task } from 'taskarian';
import { errorMessage } from '../ExceptionHandling';

export interface SaveAsFailed {
  kind: 'save-as-failed';
  message: string;
}

export const saveAsT =
  (fileName: string) =>
  (blob: Blob): Task<SaveAsFailed, Blob> =>
    new Task((reject, resolve) => {
      try {
        saveAs(blob, fileName);
        resolve(blob);
      } catch (err) {
        reject({ kind: 'save-as-failed', message: errorMessage(err) });
      }
      return noop;
    });
