import * as React from 'react';
import FreebusyStore, { FreebusySettings } from '../../../FreebusyStore';
import FreebusyReactions from '../../../FreebusyStore/FreebusyReactions';
import { observer } from 'mobx-react';
import { InitSettings, AvailabilityQuery, AvailabilityRequest } from '../../Freebusy/Types';
import { FreebusyAdResource } from './Types';
import { just, nothing } from 'maybeasy';
import AdDisplayStates from './AdDisplayStates';
import FreebusyProgramAdStore from './FreebusyProgramAdStore';
import { TranslationsContext } from '../../../Translations';

interface Props {
  freebusyProgramAdResource: FreebusyAdResource;
  freebusyProgramAdStore: FreebusyProgramAdStore;
}

class FreebusyProgramAd extends React.Component<Props, {}> {
  freebusyStore = new FreebusyStore('freebusy-program');

  initSettings: InitSettings = {
    buttonType: 'custom',
    buttonId: 'freebusy-program-ad-button',
  };

  availabilityRequest: AvailabilityRequest = {
    link: this.props.freebusyProgramAdResource.payload.link,
    display: 'modal',
    language: 'en',
  };

  availabilityQuery: AvailabilityQuery = {
    management: {
      canProposeNewTime: false,
    },
    agenda: this.props.freebusyProgramAdResource.payload.agenda,
    participants: [
      {
        email: this.props.freebusyProgramAdResource.payload.proposerParticipant.email,
        name: this.props.freebusyProgramAdResource.payload.proposerParticipant.name,
        timeZone: this.props.freebusyProgramAdResource.payload.proposerParticipant.timeZone,
        role: this.props.freebusyProgramAdResource.payload.proposerParticipant.role,
      },
    ],
  };

  freebusySettings: FreebusySettings = {
    availabilityRequest: this.availabilityRequest,
    availabilityQuery: this.availabilityQuery,
    initSettings: this.initSettings,
  };

  componentDidMount() {
    this.freebusyStore.initializingStore(
      this.freebusySettings,
      this.props.freebusyProgramAdResource.payload.eventUuid
    );
  }

  render() {
    return (
      <>
        <TranslationsContext.Consumer>
          {(ts) => (
            <FreebusyReactions
              links={just(this.props.freebusyProgramAdResource.links)}
              store={this.freebusyStore}
              translationsState={ts}
              segmentStore={nothing()}
            />
          )}
        </TranslationsContext.Consumer>
        <AdDisplayStates
          freebusyStore={this.freebusyStore}
          freebusyProgramAdStore={this.props.freebusyProgramAdStore}
        />
      </>
    );
  }
}

export default observer(FreebusyProgramAd);
