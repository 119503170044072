import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUserReactions } from '../../CurrentUser/Reactions';
import { currentUserStore } from '../../CurrentUser/Store';
import { discoveryStore } from '../../DiscoveryStore';
import { DiscoveryReactions } from '../../DiscoveryStore/Reactions';
import CurrentHoneybadgerUserReactions from '../../Honeybadger/CurrentHoneybadgerUserReactions';
import { OrganizationReactions } from '../../Organization/Reactions';
import { organizationStore } from '../../Organization/Store';
import { PreferredLanguageSelectionReactions } from '../../PreferredLanguageSelection/Reactions';
import { preferredLanguageSelectionStore } from '../../PreferredLanguageSelection/Store';
import { profileStore } from '../../ProfileStore';
import { ProfileReactions } from '../../ProfileStore/Reactions';
import { programsStore } from '../../ProgramsStore';
import { ProgramsReactions } from '../../ProgramsStore/Reactions';
import { toolingsStore } from '../../ToolingsStore';
import { ToolingsReactions } from '../../ToolingsStore/Reactions';

const AppReactions: React.FC = () => (
  <>
    <CurrentUserReactions store={currentUserStore} fireImmediately />
    <OrganizationReactions store={organizationStore} fireImmediately />
    <ProfileReactions store={profileStore} fireImmediately />
    <DiscoveryReactions store={discoveryStore} fireImmediately />
    <ProgramsReactions store={programsStore} fireImmediately />
    <ToolingsReactions store={toolingsStore} fireImmediately />
    <PreferredLanguageSelectionReactions store={preferredLanguageSelectionStore} fireImmediately />
    <CurrentHoneybadgerUserReactions store={currentUserStore} fireImmediately={true} />
  </>
);

export default observer(AppReactions);
