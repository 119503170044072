import { observer } from 'mobx-react';
import React from 'react';
import ChatReplyStore from '../../../../ChatReplyStore';
import ChatReplyIcon from '../../../Cutesies/ChatReplyIcon';
import * as style from './../style.module.css';
import ActionMenuButton from './ActionMenuButton';

interface Props {
  chatReplyStore: ChatReplyStore;
}

const ChatReplyMenuButton: React.FC<Props> = ({ chatReplyStore }) => {
  const state = chatReplyStore.state;
  switch (state.kind) {
    case 'ready':
      return (
        <ActionMenuButton tooltipText="Reply to this message">
          <button
            className={style.actionMenuButton}
            data-test-action-menu-reply-field-toggle
            onClick={chatReplyStore.hide}
          >
            <ChatReplyIcon />
          </button>
        </ActionMenuButton>
      );
    case 'hidden':
      return (
        <ActionMenuButton tooltipText="Reply to this message">
          <button
            className={style.actionMenuButton}
            data-test-action-menu-reply-field-toggle
            onClick={chatReplyStore.ready}
          >
            <ChatReplyIcon />
          </button>
        </ActionMenuButton>
      );
    case 'broadcasting':
    case 'error':
    case 'updating':
      return (
        <ActionMenuButton tooltipText="Reply to this message">
          <button
            className={style.actionMenuButton}
            data-test-action-menu-reply-field-toggle
            disabled={true}
          >
            <ChatReplyIcon />
          </button>
        </ActionMenuButton>
      );
  }
};

export default observer(ChatReplyMenuButton);
