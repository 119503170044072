import { observer } from 'mobx-react';
import * as React from 'react';
import { CompletedProgram } from '../../ProgramStore/Types';
import { Link } from '../../Resource/Types';
import CompletedBadgeGraphic from '../Cutesies/CompletedBadgeGraphic';
import UniversityLogoOnWhite from '../Dashboard/UniversityLogoOnWhite';
import CompletedProgramOverviewDates from './CompletedProgramOverviewDates';
import CompletedProgramOverviewHeader from './CompletedProgramOverviewHeader';
import * as style from './style.module.css';

interface Props {
  programDetail: CompletedProgram;
  links: ReadonlyArray<Link>;
}

const CompletedProgramOverview: React.FC<Props> = ({ programDetail, links }) => (
  <div className={style.program} data-test-completed-program={true}>
    <div className={style.container}>
      <div className={style.badge}>
        <CompletedBadgeGraphic />
      </div>
      <div className={style.content}>
        <CompletedProgramOverviewHeader programDetail={programDetail} />
        <CompletedProgramOverviewDates programDetail={programDetail} />
      </div>
      <div className={style.logo}>
        <UniversityLogoOnWhite links={links} />
      </div>
    </div>
  </div>
);

export default observer(CompletedProgramOverview);
