import { useContext } from 'react';
import { ExperienceEnrollmentContext } from '../Types';

function useExperienceEnrollmentContext() {
  const context = useContext(ExperienceEnrollmentContext);

  if (!context) {
    throw new Error(
      'useExperienceEnrollmentContext has to be used within the ExperienceEnrollmentContextProvider.',
    );
  }

  return context;
}

export default useExperienceEnrollmentContext;
