import { observer } from 'mobx-react';
import { BadgeCheckIcon, Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { findLink } from '../../../../../LinkyLinky';
import { T } from '../../../../../Translations';
import { ExperienceResource } from '../../../Common/Experience/Types';
import ViewSample from './ViewSample';

interface Props {
  experienceResource: ExperienceResource;
}

function Certificate({ experienceResource }: Props) {
  const schoolPartnerResource = experienceResource.payload.schoolPartner;

  return findLink('download-digital-cert-preview', experienceResource.links)
    .map((certLink) => (
      <div className="flex items-center gap-x-2.5">
        <BadgeCheckIcon color="black" size="md" />
        <Typography className="flex flex-wrap items-center" variant="bodyMedium" color="black">
          <T
            kind="{{schoolName}} Certificate"
            schoolName={schoolPartnerResource.payload.name.text}
          />
          <Typography className="ml-0.5" variant="bodySmall" color="black" as="span">
            (<ViewSample certLink={certLink} />)
          </Typography>
        </Typography>
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Certificate);
