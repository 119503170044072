import { observer } from 'mobx-react';
import { T } from '../../Translations';
import LogoGraphic from '../Cutesies/LogoGraphic';
import * as style from './style.module.css';
import TermsAndConditionsStore from '../../TermsAndConditionsStore';
import { Link as RelLink } from '../../Resource/Types';
import { Link } from 'react-router-dom';
import { clsx } from 'clsx';
import { Typography } from '@execonline-inc/execonline-ui';

interface Props {
  termsLink: RelLink;
  store: TermsAndConditionsStore;
  privacyLink: RelLink;
}

function Content({ termsLink, store, privacyLink }: Props) {
  return (
    <div className={style.wrapper}>
      <div className={style.main}>
        <div className={style.logo}>
          <LogoGraphic />
        </div>
        <Typography as="h1" className={style.titleLightTheme} data-test-title={true}>
          <T kind="Terms and Conditions" />
        </Typography>
        <div className={style.contentLightTheme}>
          <Typography as="p" color="black">
            <T kind="To ensure a positive experience for all ..." />
          </Typography>
          <Typography as="p">
            <T
              kind="I have read and agree to the terms ..."
              link1={(content) => (
                <a href={termsLink.href} target="_blank" rel="noopener noreferrer">
                  {content}
                </a>
              )}
              link2={(content) => (
                <a href={privacyLink.href} target="_blank" rel="noopener noreferrer">
                  {content}
                </a>
              )}
            />
          </Typography>
        </div>
        <div className={style.actions}>
          <Link to="/logout" className={(clsx(style.buttonLinkLightTheme), 'mr-7')}>
            <T kind="Cancel" />
          </Link>
          <button onClick={store.save} className={style.button}>
            <T kind="Accept" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default observer(Content);
