import { observable, action } from 'mobx';
import { ExitContext } from '../Types';
import { State, ready, exiting } from './Types';

class ExitStore {
  @observable
  state: State = ready();

  @action
  exit = (exitContext: ExitContext) => {
    this.state = exiting(exitContext);
  };
}
export default ExitStore;
