import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import { EventResource } from '../../EventsStore/Types';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import PanelRenderer from './PanelRenderer';
import { BreakoutStores, RoomConfiguration } from './Types';
import * as style from './style.module.css';

const ConferenceSupPanel: React.FC<{
  presenceMembersStore: PresenceMembersStore;
  conferenceRoomResource: ConferenceRoomResource;
  agenda: Maybe<string>;
  eventResource: Maybe<EventResource>;
  conferenceRosterStore: ConferenceRosterStore;
  roomConfig: RoomConfiguration;
  breakoutStores: Maybe<BreakoutStores>;
  staffRequestStore: StaffRequestStore;
}> = ({
  presenceMembersStore,
  conferenceRoomResource,
  agenda,
  eventResource,
  conferenceRosterStore,
  roomConfig,
  breakoutStores,
  staffRequestStore,
}) => (
  <div className={style.conferenceSupPanel}>
    <PanelRenderer
      roomConfig={roomConfig}
      presenceMembersStore={presenceMembersStore}
      conferenceRoomResource={conferenceRoomResource}
      agenda={agenda}
      conferenceRosterStore={conferenceRosterStore}
      breakoutStores={breakoutStores}
      eventResource={eventResource}
      staffRequestStore={staffRequestStore}
    />
  </div>
);

export default observer(ConferenceSupPanel);
