import { observer } from 'mobx-react';
import * as React from 'react';
import RegisterResourceStore from '../../DiscoveryPortal/RegisterResourceStore';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../RegistrationFormHeader';
import RegistrationView from '../../RegistrationView';
import { AuthSignIn } from '../Types';
import Form from './Form';
import Instructions from './Instructions';
import SignInReactions from './SignInReactions';

interface Props {
  authForm: AuthSignIn;
  registerResource: RegisterResource;
  registerResourceStore: RegisterResourceStore;
}

const SignIn: React.FC<Props> = ({ authForm, registerResource, registerResourceStore }) => (
  <>
    <RegistrationView allowLanguagePicker={true}>
      <RegistrationFormHeader
        notifiableStore={authForm.store}
        sharedInvitationResource={registerResource.payload.sharedInvitation}
      >
        <Instructions
          auth={authForm.auth}
          sharedInvitationResource={registerResource.payload.sharedInvitation}
        />
      </RegistrationFormHeader>
      <Form authForm={authForm} registerResource={registerResource} />
    </RegistrationView>
    <SignInReactions
      authForm={authForm}
      registerResource={registerResource}
      registerResourceStore={registerResourceStore}
    />
  </>
);

export default observer(SignIn);
