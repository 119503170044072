import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';
import CommunitiesStore from '../../CommunitiesStore';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';
import TeamSummaryHeader from './TeamSummaryHeader';
import TeamSummaryOverview from './TeamSummaryOverview';

interface Props {
  communityTeamResource: CommunityTeamResource;
  communitiesStore: CommunitiesStore;
}

const TeamSummary: React.FC<Props> = ({ communityTeamResource, communitiesStore }) => (
  <div className={style.main} data-test-team-summary={true}>
    <div className={style.team}>
      <TeamSummaryHeader communityTeamResource={communityTeamResource} />
      <TeamSummaryOverview
        communityTeamResource={communityTeamResource}
        communitiesStore={communitiesStore}
      />
    </div>
  </div>
);

export default observer(TeamSummary);
