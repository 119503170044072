import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import TeamStore from '../../TeamStore';
import Loader from '../Loader';
import * as platformStyle from '../Platform/style.module.css';
import NoConference from './NoConference';

interface Props {
  teamStore: TeamStore;
}

const ConferenceNotReady: React.FC<Props> = ({ teamStore }) => {
  switch (teamStore.state.kind) {
    case 'waiting':
    case 'loading':
      return (
        <div className={platformStyle.contentLoader}>
          <Loader />
        </div>
      );
    case 'ready':
    case 'error':
      return (
        <WithCurrentUser
          children={(currentUserResource) => (
            <NoConference currentUserResource={currentUserResource} />
          )}
        />
      );
  }
};

export default observer(ConferenceNotReady);
