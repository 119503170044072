import { MissingVarError } from '@execonline-inc/environment';
import { MissingLinkError } from '../../LinkyLinky';

/**
 * See https://developer.jwplayer.com/jw-player/docs/developer-guide/customization/configuration-reference/#setup
 *
 * There are a number of additional options that could be used. This interface
 * just includes the most likely set needed, as most are optional.
 */
export interface GoogleAnalyticsIntegrationConfig {
  label: 'mediaid';
}

export interface JWPlayerFn {
  (element: HTMLElement): JWPlayerObj;
  key?: string;
}

export interface ProgressiveLow {
  kind: 'progressive-low';
  sources: VideoSourceFile[];
}

export interface ProgressiveMedium {
  kind: 'progressive-medium';
  sources: VideoSourceFile[];
}

export interface ProgressiveHigh {
  kind: 'progressive-high';
  sources: VideoSourceFile[];
}

// This type is driven by the JWPlayer options, which accept a string for this boolean value
type VideoSourceDefault = 'true' | 'false';

export interface VideoSourceFile {
  file: string;
  label: string;
  default: VideoSourceDefault;
}

const videoSourceDefault = (isDefault: boolean): VideoSourceDefault =>
  isDefault ? 'true' : 'false';

type CaptionLanguage = string;

export interface CaptionSourceFile {
  kind: 'captions';
  file: string;
  label: CaptionLanguage;
}

export interface ThumbnailsSourceFile {
  kind: 'thumbnails';
  file: string;
}

export type TracksSourceFile = CaptionSourceFile | ThumbnailsSourceFile;

export type SourceFile = VideoSourceFile | CaptionSourceFile | ThumbnailsSourceFile;

export type VideoPlaybackType = 'vimeo' | 'progressive' | 'adaptive';

export type VideoPlaybackSourceLabel = 'Low' | 'Medium' | 'High';

export type VideoPlaybackQuality = 'low' | 'medium' | 'high';

export interface VideoPlaybackSource {
  file: string;
  label: VideoPlaybackSourceLabel;
  default: boolean;
}

export const videoSourceFile = (videoPlaybackSource: VideoPlaybackSource): VideoSourceFile => ({
  file: videoPlaybackSource.file,
  label: videoPlaybackSource.label,
  default: videoSourceDefault(videoPlaybackSource.default),
});

export const captionSourceFile = (file: string, label: string): CaptionSourceFile => ({
  kind: 'captions',
  file: file,
  label: label,
});

export const thumbnailsSourceFile = (file: string): ThumbnailsSourceFile => ({
  kind: 'thumbnails',
  file: file,
});

export interface InternalPlayerOptions {
  playerUrl: string;
  playerKey: string;

  // Media
  file: string; // The adaptive stream manifest link
  image: string; // Placeholder image until video is loaded and played
  title: string;
  mediaid?: string;
  sources: SourceFile[]; // Array of mp4 sources
  tracks: SourceFile[]; // For captions, thumbnails, which are not required

  // Autostart
  autostart?: boolean;

  // Appearance
  displaytitle?: boolean;
  width?: number | string;
  height?: number | string;
  aspectratio?: string; //width:height format
  //Custom for us, lets our setup know to set the player's aspect ratio based on
  //the video's actual dimensions rather than the player's dimensions
  dynamicallySetAspectRatio?: boolean;
  thumbnailImage: string;
  sprite: string;
  subtitles: ReadonlyArray<CaptionSourceFile>;
  ga?: GoogleAnalyticsIntegrationConfig;
  playbackRateControls?: boolean;
  renderCaptionsNatively: boolean;
}

export interface JWPlayerObj {
  setup: (options: InternalPlayerOptions) => void;
  setCurrentCaptions: (index: number) => void;
  remove: () => void;
  play: () => void;
  pause: () => void;
  seek: (pos: number) => void;
  getPosition: () => number;
  getDuration: () => number;
  on: <K extends keyof JWPlayerEventMap>(type: K, cb: JWPlayerEventMap[K]) => void;
}

export interface JWPlayerEventMap {
  ready: () => void;
  meta: (event: MetaEvent) => void;
  play: () => void;
  pause: () => void;
  seeked: () => void;
  time: () => void;
  ended: () => void;
  setupError: () => void;
  error: () => void;
  complete: () => void;
}

export interface MetaEvent {
  duration: number;
  height: number;
  width: number;
  type: 'meta';
}

export interface LowStreamMissing {
  kind: 'low-stream-missing-error';
  key: string;
}

export const lowStreamMissing = (key: string): LowStreamMissing => ({
  kind: 'low-stream-missing-error',
  key,
});

export interface MediumStreamMissing {
  kind: 'medium-stream-missing-error';
  key: string;
}

export const mediumStreamMissing = (key: string): MediumStreamMissing => ({
  kind: 'medium-stream-missing-error',
  key,
});

export interface HighStreamMissing {
  kind: 'high-stream-missing-error';
  key: string;
}

export const highStreamMissing = (key: string): HighStreamMissing => ({
  kind: 'high-stream-missing-error',
  key,
});

export interface SubtitleLinkMissingError {
  kind: 'subtitle-link-missing-error';
  key: string;
}

export const subtitleLinkMissingError = (key: string): SubtitleLinkMissingError => ({
  kind: 'subtitle-link-missing-error',
  key,
});

export interface ThumbnailPreviewMissingError {
  kind: 'thumbnail-preview-missing-error';
  key: string;
}

export const thumbnailPreviewMissingError = (key: string): ThumbnailPreviewMissingError => ({
  kind: 'thumbnail-preview-missing-error',
  key,
});

export interface SpriteMissingError {
  kind: 'sprite-missing-error';
  key: string;
}

export const spriteMissingError = (key: string): SpriteMissingError => ({
  kind: 'sprite-missing-error',
  key,
});

export type PlayerOptionsError =
  | LowStreamMissing
  | MediumStreamMissing
  | HighStreamMissing
  | SubtitleLinkMissingError
  | ThumbnailPreviewMissingError
  | SpriteMissingError
  | MissingVarError
  | MissingLinkError;

export type JWPlayerInitializationError = JWPlayerLoadError | PlayerOptionsError;

export interface JWPlayerLoadError {
  kind: 'jwplayer-load-error';
  key: string;
}

export const jwPlayerLoadError = (key: string): JWPlayerLoadError => ({
  kind: 'jwplayer-load-error',
  key,
});

export interface ProgressiveSourcesObject {
  lowProgressiveStream: VideoSourceFile;
  mediumProgressiveStream: VideoSourceFile;
  highProgressiveStream: VideoSourceFile;
}
