import * as React from 'react';
import { T, TPlainTextKey } from '../../../../Translations';
import RevealIcon from '../../../Cutesies/RevealIcon';
import * as style from '../style.module.css';

type ToggleKind = 'show' | 'hide';

interface Props {
  kind: ToggleKind;
  onClick: () => void;
}

const kindText = (kind: ToggleKind): TPlainTextKey => {
  switch (kind) {
    case 'show':
      return 'Show Slides';
    case 'hide':
      return 'Hide Slides';
  }
};

const kindClass = (kind: ToggleKind): string => {
  switch (kind) {
    case 'show':
      return style.showMobileSlidesButton;
    case 'hide':
      return style.hideMobileSlidesButton;
  }
};

const SlidesToggle: React.FC<Props> = ({ kind, onClick }) => (
  <button className={kindClass(kind)} onClick={onClick} data-test-slide-toggle-button={kind}>
    <div className={style.buttonIcon}>
      <RevealIcon />
    </div>
    <T kind={kindText(kind)} />
    <div className={style.buttonIcon}>
      <RevealIcon />
    </div>
  </button>
);

export default SlidesToggle;
