import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../../CurrentUser/Context/WithCurrentUser';
import ProgramStore from '../../../../ProgramStore';
import { ProgramResource } from '../../../../ProgramsStore/Types';
import ProgramReactions from '../../../ProgramReactions';
import ProgramOverviewRenderer from './ProgramOverviewRenderer';

interface Props {
  programResource: ProgramResource;
}

class StudentProgramDashboard extends React.Component<Props, {}> {
  programStore: ProgramStore = new ProgramStore();

  componentDidMount() {
    this.programStore.loading(this.props.programResource);
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <ProgramOverviewRenderer
              store={this.programStore}
              programResource={this.props.programResource}
            />
            <ProgramReactions store={this.programStore} currentUserResource={currentUserResource} />
          </>
        )}
      />
    );
  }
}

export default observer(StudentProgramDashboard);
