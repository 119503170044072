import { MissingVarError } from '@execonline-inc/environment';
import { log } from '@execonline-inc/logging';
import { fromNullable, just, Maybe, nothing } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { Task } from 'taskarian';
import {
  CaptionSourceFile,
  GoogleAnalyticsIntegrationConfig,
  InternalPlayerOptions,
  SourceFile,
  TracksSourceFile,
} from '../../JWPlayer/Types';
import { autoLoadIndexT } from '../../ReqHlsVideo/Types';
import { thumbnail } from '../Resource';
import {
  AnnouncementVideoAssetResource,
  VideoAssetResource,
  VideoPlaybackSubtitles,
} from '../Types';
import { jwPlayerKey, jwPlayerUrl } from './Loader';

export interface VideoPlayerOptions {
  playerUrl: string;
  playerKey: string;

  // Media
  file: Maybe<string>;
  image: string;
  title: Maybe<string>;
  mediaid: Maybe<string>;
  sources: Maybe<SourceFile[]>;
  tracks: TracksSourceFile[];

  // Autostart
  autostart: boolean;

  // Appearance
  displaytitle: boolean;
  width: Maybe<number | string>;
  height: Maybe<number | string>;
  aspectratio: Maybe<string>; //width:height format

  //Custom for us, lets our setup know to set the player's aspect ratio based on
  //the video's actual dimensions rather than the player's dimensions
  dynamicallySetAspectRatio: boolean;
  thumbnailImage: Maybe<string>;
  sprite: Maybe<string>;
  subtitles: Maybe<ReadonlyArray<CaptionSourceFile>>;
  ga: Maybe<GoogleAnalyticsIntegrationConfig>;
  playbackRateControls: boolean;
  autoLoadIndex: Maybe<number>;
}

interface CustomizablePlayerOptions {
  width?: number | string;
  height?: number | string;
  dynamicallySetAspectRatio: boolean;
  sources?: SourceFile[];
  file?: string;
  tracks?: SourceFile[];
}

const subtitle = (sub: VideoPlaybackSubtitles): CaptionSourceFile => ({
  ...sub,
  kind: 'captions',
});

const subtitles = (sub: VideoPlaybackSubtitles[]): Maybe<ReadonlyArray<CaptionSourceFile>> =>
  fromArrayMaybe(sub)
    .map((ary) => ary.map(subtitle))
    .map((lst) => lst.toArray());

const videoAssestResourceSubtitles = (
  resource: VideoAssetResource | AnnouncementVideoAssetResource,
): ReadonlyArray<VideoPlaybackSubtitles> => resource.payload.subtitles;

const autoLoadIndex = autoLoadIndexT(videoAssestResourceSubtitles);

export const videoPlayerOptions = (
  resource: VideoAssetResource | AnnouncementVideoAssetResource,
  opts: CustomizablePlayerOptions,
): Task<MissingVarError, VideoPlayerOptions> =>
  Task.succeed<MissingVarError, {}>({})
    .assign('image', Task.succeed(thumbnail(resource.links).getOrElseValue('')))
    .assign('title', Task.succeed(resource.payload.title))
    .assign('mediaid', Task.succeed(resource.payload.mediaId))
    .assign('ga', Task.succeed(just<GoogleAnalyticsIntegrationConfig>({ label: 'mediaid' })))
    .assign('playbackRateControls', Task.succeed(true))
    .assign('playerUrl', jwPlayerUrl())
    .assign('playerKey', jwPlayerKey())
    .assign('height', Task.succeed(fromNullable(opts.height)))
    .assign('width', Task.succeed(fromNullable(opts.width)))
    .assign('dynamicallySetAspectRatio', Task.succeed(opts.dynamicallySetAspectRatio || false))
    .assign('sources', Task.succeed(fromNullable(opts.sources)))
    .assign('file', Task.succeed(fromNullable(opts.file)))
    .assign('tracks', Task.succeed([]))
    .assign('autostart', Task.succeed(false))
    .assign('displaytitle', Task.succeed(true))
    .assign('aspectratio', Task.succeed(nothing<string>()))
    .assign('thumbnailImage', Task.succeed(thumbnail(resource.links)))
    .assign('sprite', Task.succeed(nothing<string>()))
    .assign('subtitles', Task.succeed(subtitles(resource.payload.subtitles)))
    .assign('autoLoadIndex', Task.succeed(autoLoadIndex(resource)));

export const prepareOptions = (options: VideoPlayerOptions): InternalPlayerOptions => {
  const orUndefined = <T>(t: T): T | undefined => t;
  const finalOptions = {
    ...options,
    file: options.file.getOrElseValue(''),
    title: options.title.getOrElseValue(''),
    mediaid: options.mediaid.map(orUndefined).getOrElseValue(undefined),
    sources: options.sources.getOrElseValue([]),
    width: options.width.map(orUndefined).getOrElseValue(undefined),
    height: options.height.map(orUndefined).getOrElseValue(undefined),
    aspectratio: options.aspectratio.map(orUndefined).getOrElseValue(undefined),
    thumbnailImage: options.thumbnailImage.getOrElseValue(''),
    sprite: options.sprite.getOrElseValue(''),
    subtitles: options.subtitles.getOrElseValue([]),
    ga: options.ga.map(orUndefined).getOrElseValue(undefined),
    tracks: [...options.tracks, ...options.subtitles.getOrElseValue([])],
    renderCaptionsNatively: true,
  };
  log('Final video player options (JWPlayer)', finalOptions);
  return finalOptions;
};
