import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Typography } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../../../../Translations';

interface Props {
  date: Maybe<Date>;
}

function GroupCoachSessionDateTime({ date }: Props) {
  return (
    <Typography className="text-gray-500" variant="hint">
      {date
        .map((value) => (
          <>
            {<L localizeable={value} format="short-month-day-year" />} @{' '}
            {<L localizeable={value} format="time-of-day" />}
          </>
        ))
        .getOrElse(() => (
          <T kind="Session not scheduled yet" />
        ))}
    </Typography>
  );
}

export default observer(GroupCoachSessionDateTime);
