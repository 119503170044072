import { sort, SortComparator } from '@execonline-inc/collections';
import { CurrentUserResource } from '../CurrentUser/Types';
import { Error } from '../ErrorHandling';
import { ParticipantType } from '../Person/types';
import { ProfileResource } from '../ProfileStore/Types';
import { Resource } from '../Resource/Types';

export type ConferenceEventType = 'public' | 'private';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface RosterLoaded {
  kind: 'roster-loaded';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
  providerParticipants: ReadonlyArray<ProviderParticipant>;
}

interface Ready {
  kind: 'ready';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
  providerParticipants: ReadonlyArray<ProviderParticipant>;
}

interface Updating {
  kind: 'updating';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
  providerParticipants: ReadonlyArray<ProviderParticipant>;
}

interface Polling {
  kind: 'polling';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
  providerParticipants: ReadonlyArray<ProviderParticipant>;
}

interface PollingComplete {
  kind: 'polling-complete';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
  providerParticipants: ReadonlyArray<ProviderParticipant>;
}

interface Stopped {
  kind: 'stopped';
  conferenceEventType: ConferenceEventType;
  expectedParticipants: ReadonlyArray<ConferenceParticipant>;
}

export type ConferenceRosterState =
  | Waiting
  | Loading
  | RosterLoaded
  | Ready
  | Updating
  | Polling
  | PollingComplete
  | Stopped
  | Error;

const userFirstComparator = (userId: number): SortComparator<ConferenceParticipant> => (a, _) =>
  a.id === userId ? -1 : 0;

export function currentUserFirstOrder(
  currentUserResource: CurrentUserResource,
  participants: ConferenceParticipants
): ConferenceParticipants;
export function currentUserFirstOrder(
  currentUserResource: CurrentUserResource
): (participants: ConferenceParticipants) => ConferenceParticipants;
export function currentUserFirstOrder(
  currentUserResource: CurrentUserResource,
  participants?: ConferenceParticipants
) {
  const doit = (participants: ConferenceParticipants) =>
    sort(userFirstComparator(currentUserResource.payload.id), participants);

  return typeof participants === 'undefined' ? doit : doit(participants);
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const rosterLoaded = (
  conferenceEventType: ConferenceEventType,
  expectedParticipants: ReadonlyArray<ConferenceParticipant>,
  currentUserResource: CurrentUserResource
): RosterLoaded => ({
  kind: 'roster-loaded',
  conferenceEventType,
  expectedParticipants: currentUserFirstOrder(currentUserResource, expectedParticipants),
  providerParticipants: [],
});

export const ready = (state: RosterLoaded | Updating | Polling | PollingComplete): Ready => {
  const { kind, ...previous } = state;
  return {
    kind: 'ready',
    ...previous,
  };
};

export const updating = (
  providerParticipants: ReadonlyArray<ProviderParticipant>,
  state: RosterLoaded | Ready
): Updating => {
  const { kind, ...previous } = state;
  return {
    kind: 'updating',
    ...previous,
    providerParticipants,
  };
};

export const polling = (state: RosterLoaded | Ready): Polling => {
  const { kind, ...previous } = state;
  return {
    kind: 'polling',
    ...previous,
  };
};

export const pollingComplete = (
  providerParticipants: ReadonlyArray<ProviderParticipant>,
  state: RosterLoaded | Polling
): PollingComplete => {
  const { kind, ...previous } = state;
  return {
    kind: 'polling-complete',
    ...previous,
    providerParticipants,
  };
};

export const stopped = (
  state: Ready | RosterLoaded | Updating | Polling | PollingComplete
): Stopped => {
  const { kind, ...previous } = state;
  return {
    kind: 'stopped',
    ...previous,
  };
};

export type ConferenceParticipants = ReadonlyArray<ConferenceParticipant>;

export interface ConferenceParticipant {
  id: number;
  participantType: ParticipantType;
  email: string;
  profile: ProfileResource;
}

export interface ProviderParticipant {
  uuid: string;
  name: string;
}

export interface ConferenceRoster {
  kind: ConferenceEventType;
  participants: ConferenceParticipants;
}

export type ConferenceRosterResource = Resource<ConferenceRoster>;

export type PresentParticipants = ProviderParticipant[];

export type ConferencePresentParticipantsResource = Resource<PresentParticipants>;

export interface HandRaisingGroups {
  raised: ConferenceParticipants;
  lowered: ConferenceParticipants;
}
