import { observer } from 'mobx-react';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import DefaultSegmentContent from './DefaultSegmentContent';
import SurveySegmentContent from './SurveySegmentContent';

interface Props {
  store: SegmentStore;
  segmentResource: SegmentResource;
}

function SegmentContent({ store, segmentResource }: Props) {
  switch (segmentResource.payload.type) {
    case 'survey':
      return <SurveySegmentContent store={store} segmentResource={segmentResource} />;
    case 'assignment-due':
    case 'lecture':
    case 'overview':
    case 'external-program':
    case 'presentation':
    case 'team-discussion':
      return <DefaultSegmentContent store={store} segmentResource={segmentResource} />;
  }
}

export default observer(SegmentContent);
