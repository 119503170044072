import { Time } from '@execonline-inc/time';
import { Maybe, nothing } from 'maybeasy';
import Pusher from 'pusher-js';
import { Error } from '../ErrorHandling';

export interface Initialized {
  kind: 'initialized';
  pusher: Pusher;
}

export interface Connecting {
  kind: 'connecting';
  pusher: Pusher;
  connectingIn: Maybe<Time>;
}

export interface Connected {
  kind: 'connected';
  pusher: Pusher;
}

export interface Unavailable {
  kind: 'unavailable';
  pusher: Pusher;
  connectingIn: Maybe<Time>;
}

export interface Failed {
  kind: 'failed';
  pusher: Pusher;
}

export interface Disconnected {
  kind: 'disconnected';
}

export type SocketAvailability =
  | Initialized
  | Connecting
  | Connected
  | Unavailable
  | Failed
  | Disconnected;

export const initialized = (pusher: Pusher): Initialized => ({
  kind: 'initialized',
  pusher,
});

export const connecting = (pusher: Pusher): Connecting => ({
  kind: 'connecting',
  pusher,
  connectingIn: nothing(),
});

export const connected = (pusher: Pusher): Connected => ({
  kind: 'connected',
  pusher,
});

export const unavailable = (pusher: Pusher): Unavailable => ({
  kind: 'unavailable',
  pusher,
  connectingIn: nothing(),
});

export const failed = (pusher: Pusher): Failed => ({
  kind: 'failed',
  pusher,
});

export const disconnected = (): Disconnected => ({
  kind: 'disconnected',
});

export interface Waiting {
  kind: 'waiting';
}

export interface Ready {
  kind: 'ready';
  socket: SocketAvailability;
}

export interface Unmounting {
  kind: 'unmounting';
  socket: SocketAvailability;
}

export interface Unmounted {
  kind: 'unmounted';
}

export type SocketState = Waiting | Ready | Unmounting | Unmounted | Error;

export const ready = (socket: SocketAvailability): Ready => ({
  kind: 'ready',
  socket,
});

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const unmounting = (socket: SocketAvailability): Unmounting => ({
  kind: 'unmounting',
  socket,
});

export const unmounted = (socket: SocketAvailability): Unmounted => ({
  kind: 'unmounted',
});
