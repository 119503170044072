import { observer } from 'mobx-react';
import { BreadcrumbItem, Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';

interface Props {
  title: AlreadyTranslatedText;
}

function ExperienceCrump({ title }: Props) {
  return (
    <BreadcrumbItem isLast>
      <Typography
        fontWeight="light"
        variant="bodyMedium"
        className="tracking-normal"
        color="inherit"
      >
        <AlreadyTranslated content={title} />
      </Typography>
    </BreadcrumbItem>
  );
}

export default observer(ExperienceCrump);
