import { observer } from 'mobx-react';
import { Kettle } from 'kettle-corn';
import { cn } from '@execonline-inc/execonline-ui';
import { VideoSourceFile } from '../../../../components/JWPlayer/Types';
import JWPlayer from '../../../../components/ReqHlsVideo/JWPlayer';
import { overviewPlayerOptions } from '../../../../components/ReqHlsVideo/JWPlayer/Types';
import { OverviewVideoResource } from '../../../../components/ReqHlsVideo/Types';

interface Props {
  className?: string;
  video: OverviewVideoResource;
  sources: VideoSourceFile[];
}

function ExperienceVideoPreviewModalBody({ className, sources, video }: Props) {
  const options = overviewPlayerOptions(video, {
    sources,
    width: '100%',
    height: '100%',
    autostart: true,
    file: '',
  });
  const kettle = new Kettle();

  return (
    <JWPlayer
      id={video.payload.uuid}
      className={cn('bg-[#e1e1e1]', className)}
      kettle={kettle}
      options={options}
      fallbackSources={sources}
    />
  );
}

export default observer(ExperienceVideoPreviewModalBody);
