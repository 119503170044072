import { emptyFragment } from '@execonline-inc/execonline-ui';
import { seconds } from '@execonline-inc/time';
import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ConferenceRoomResource } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import ConversationAPIReactions from '../../ConversationStore/ConversationAPIReactions';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { EventResource } from '../../EventsStore/Types';
import handRaisers from '../../HandRaisers';
import HandStatusBroadcaster from '../../HandRaisers/HandStatusBroadcaster';
import { findLink } from '../../LinkyLinky';
import NotificationSource from '../../NotificationSource';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import TabStore from '../../TabStore';
import { T } from '../../Translations';
import * as style from '../Conference/style.module.css';
import { BreakoutStores } from '../Conference/Types';
import StaffRequestStore from '../EventDashboard/StaffRequestStore';
import * as segmentPanelStyle from '../SegmentPanel/style.module.css';
import ActiveTabPane from './ActiveTabPane';
import BreakoutAction from './BreakoutAction';
import HandAction from './HandAction';
import ReturnAction from './ReturnAction';
import Tabs from './Tabs';

interface Props {
  presenceMembersStore: PresenceMembersStore;
  conferenceRoomResource: ConferenceRoomResource;
  returnToEventId: Maybe<number>;
  teamId: Maybe<number>;
  agenda: Maybe<string>;
  eventResource: Maybe<EventResource>;
  conferenceRosterStore: ConferenceRosterStore;
  breakoutStores: Maybe<BreakoutStores>;
  eventId: Maybe<number>;
  staffRequestStore: StaffRequestStore;
  currentUserResource: CurrentUserResource;
}

class ConferencePanel extends React.Component<Props> {
  tabStore = new TabStore('group chat');

  componentDidMount() {
    this.props.presenceMembersStore.conversationStore.loading();
  }

  componentWillUnmount() {
    handRaisers
      .findHandRaiser(this.props.currentUserResource.payload.id)
      .do((hr) => hr.loweringHand());
  }

  render() {
    const {
      presenceMembersStore,
      conferenceRoomResource,
      conferenceRosterStore,
      returnToEventId,
      teamId,
      agenda,
      eventResource,
      eventId,
      staffRequestStore,
      currentUserResource,
    } = this.props;
    const links = conferenceRoomResource.links;

    return (
      <>
        <h2 className={style.supPanelHeader}>
          <T kind="Meeting Actions" />
        </h2>
        {findLink('my-hand-up', links)
          .andThen(() => findLink('my-hand-down', links))
          .map(() => <HandAction id={currentUserResource.payload.id} />)
          .getOrElse(emptyFragment)}

        {this.props.breakoutStores
          .map((breakoutStores) => (
            <BreakoutAction
              conferenceRosterStore={conferenceRosterStore}
              breakoutStore={breakoutStores.first}
              conferenceResource={conferenceRoomResource}
              eventId={eventId}
            />
          ))
          .orElse(() =>
            just({})
              .assign('eventId', returnToEventId)
              .assign('teamId', teamId)
              .map((returnProps) => (
                <ReturnAction
                  eventId={returnProps.eventId}
                  teamId={returnProps.teamId}
                  staffRequestStore={staffRequestStore}
                  currentUserResource={currentUserResource}
                />
              )),
          )
          .getOrElse(() => (
            <span />
          ))}
        <section className={segmentPanelStyle.section}>
          <Tabs
            tabStore={this.tabStore}
            presenceMembersStore={presenceMembersStore}
            agenda={agenda}
            conferenceRosterStore={conferenceRosterStore}
          />
          <ActiveTabPane
            tabStore={this.tabStore}
            presenceMembersStore={presenceMembersStore}
            agenda={agenda}
            eventResource={eventResource}
            conferenceRosterStore={conferenceRosterStore}
          />
          <ConversationAPIReactions store={presenceMembersStore.conversationStore} />
          <NotificationSource store={presenceMembersStore.conversationStore.chatMessageStore} />
          <NotificationSource store={presenceMembersStore.conversationStore} />
        </section>
        <HandStatusBroadcaster interval={seconds(60)} currentUserResource={currentUserResource} />
      </>
    );
  }
}

export default observer(ConferencePanel);
