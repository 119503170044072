import { IFrameComponent, iframeResizer } from 'iframe-resizer';
import { Maybe, fromNullable, nothing } from 'maybeasy';
import * as React from 'react';

export type Scrollbars = 'on' | 'off' | 'auto';

const allowScrolling = (setting: Scrollbars | undefined): boolean | 'auto' => {
  if (typeof setting === 'undefined') {
    return false;
  }

  switch (setting) {
    case 'on':
      return true;
    case 'off':
      return false;
    case 'auto':
      return 'auto';
  }
};

export interface Props {
  source: string;
  style: React.CSSProperties;
  seamless: boolean;
  width: string;
  allowFullScreen: boolean;
  height: string;
  scrollbars?: Scrollbars;
  iFrameRef: React.RefObject<HTMLIFrameElement>;
}

class IFrameContent extends React.Component<Props> {
  private iframeComponents: Maybe<IFrameComponent[]>;

  constructor(props: Props) {
    super(props);
    this.iframeComponents = nothing();
  }

  componentDidMount() {
    this.iframeComponents = fromNullable(this.props.iFrameRef.current).map((frame) =>
      iframeResizer(
        {
          checkOrigin: false,
          scrolling: allowScrolling(this.props.scrollbars),
          heightCalculationMethod: 'lowestElement',
          initCallback: () => {
            window.scrollTo({ top: 0 });
            if (frame.contentWindow) {
              frame.contentWindow.postMessage({ kind: 'scrollToTop' }, '*');
            }
          },
        },
        frame
      )
    );
  }

  componentWillUnmount() {
    this.iframeComponents.map((cs) => cs.map((iframe) => iframe.iFrameResizer.close()));
  }

  render() {
    const { source, style, seamless, width, height, allowFullScreen } = this.props;
    return (
      <div data-test-iframe-content={source}>
        <iframe
          src={source}
          title="Survey"
          style={style}
          seamless={seamless}
          width={width}
          height={height}
          allowFullScreen={allowFullScreen}
          ref={this.props.iFrameRef}
        />
      </div>
    );
  }
}

export default IFrameContent;
