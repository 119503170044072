import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingProductDetails } from '../../../../../ProgramStore/Types';
import SessionsCountInfo from './SessionsCountInfo';

interface Props {
  coachingProductDetails: CoachingProductDetails;
}

const SessionsHistory: React.FC<Props> = ({ coachingProductDetails }) => (
  <SessionsCountInfo value={coachingProductDetails.pastSessionsCount} />
);

export default observer(SessionsHistory);
