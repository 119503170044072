import { useMediaQuery } from '@execonline-inc/execonline-ui';

function useMobileFilterSwitcher(): 'desktop' | 'mobile' {
  const breakPoint = useMediaQuery();

  switch (breakPoint) {
    case 'xl':
    case 'lg':
    case 'md':
      return 'desktop';
    case 'sm':
    case 'xs':
      return 'mobile';
  }
}

export default useMobileFilterSwitcher;
