import { observer } from 'mobx-react';
import * as React from 'react';
import ConversationStore from '../../../ConversationStore';
import { T } from '../../../Translations';

interface Props {
  store: ConversationStore;
}

const MoreMessages: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'loading-next':
    case 'loading-previous':
      return (
        <div>
          <T kind="Loading More Messages" />
        </div>
      );
    case 'error':
    case 'loading':
    case 'reloading':
    case 'ready':
    case 'load-next-success':
    case 'waiting':
    case 'load-new-message':
    case 'load-new-reply':
    case 'update-message':
    case 'first-loaded':
    case 'update-reply':
      return <span />;
  }
};

export default observer(MoreMessages);
