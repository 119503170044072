import { missingLinkError } from '@execonline-inc/links.private';
import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { just } from 'maybeasy';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { AppyError, callApi } from '../../../Appy';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../Honeybadger';
import { MissingLinkError, findLinkByT } from '../../../Links';
import { Link } from '../../../Resource/Types';
import { freebusyAdsResourceDecoder } from './Decoders';
import FreebusyCoachingAdStore from './FreebusyCoachingAdStore';
import { State } from './Types';

interface Props extends EAProps<FreebusyCoachingAdStore> {
  store: FreebusyCoachingAdStore;
  currentUserResource: CurrentUserResource;
}

type FreebusyCoachingAdError = AppyError | MissingLinkError;

const handleFreebusyCoachingError =
  (store: FreebusyCoachingAdStore) => (error: FreebusyCoachingAdError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify(
          'FreebusyCoachingAdReactions',
          `Missing link: ${error.rel}`,
          toJS(store.state),
        );
        break;
      default:
        handleError(store, error);
    }
  };

class FreebusyCoachingAdReactions extends ErrorActionableReaction<
  FreebusyCoachingAdStore,
  State,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'waiting':
        this.props.store.load(this.props.currentUserResource);
        break;
      case 'loading':
      case 'reloading':
        toTask<FreebusyCoachingAdError, ReadonlyArray<Link>>(
          missingLinkError({ rel: 'freebusy-coaching' }),
          just(state.linkable.links),
        )
          .andThen(findLinkByT({ rel: 'freebusy-coaching' }))
          .andThen(callApi(freebusyAdsResourceDecoder, {}))
          .fork(handleFreebusyCoachingError(this.props.store), this.props.store.ready);
        break;
      case 'error':
      case 'ready':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(FreebusyCoachingAdReactions);
