import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { PersonStoreContract } from '../../../Person/types';
import { Position } from '../../PositionContext';
import * as style from '../style.module.css';
import PersonBase from './SharedInternals/PersonBase';
import PersonDetails from './SharedInternals/PersonDetails';

interface Props {
  store: PersonStoreContract;
  position: Position;
}

const PersonNav: React.FC<Props> = ({ store, position }) => {
  return (
    <div className={style.nav}>
      <PersonBase store={store}>
        {{
          onlineStatus: nothing(),
          details: just(
            <PersonDetails
              organization={nothing()}
              name={nothing()}
              jobTitle={nothing()}
              position={position}
            />
          ),
        }}
      </PersonBase>
    </div>
  );
};

export default observer(PersonNav);
