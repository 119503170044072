import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import Loader from '../../../components/Loader';
import { ContingencyResolution, Props } from './Types';

function WhenUserDataLoadedView({ contingencies, children }: PropsWithChildren<Props>) {
  switch (reducedResolution(contingencies)) {
    case 'waiting':
    case 'loading':
      return <Loader delay={0} />;
    case 'refreshing':
    case 'finished':
      return <>{children}</>;
  }
}

export default observer(WhenUserDataLoadedView);

const reducedResolution = (contingencies: Props['contingencies']): ContingencyResolution =>
  contingencies.reduce<ContingencyResolution>((memo, next) => {
    switch (next.resolution) {
      case 'waiting':
        return 'waiting';
      case 'loading':
        return when(memo === 'waiting', memo).getOrElseValue('loading');
      case 'refreshing':
        return when(memo === 'waiting' || memo === 'loading', memo).getOrElseValue('refreshing');
      case 'finished':
        return memo;
    }
  }, 'finished');
