import { warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Task } from 'taskarian';
import { UserLanguageStore } from '.';
import { callApi } from '../Appy';
import { I18nextInteractionError } from '../ChangeLocale';
import { changeLocale } from '../LocaleChanger';
import { CouldNotReloadError, locationReload } from '../Location';
import { languagesResourceDecoder } from '../ProfileFormStore/Decoders';
import ReactionComponent, { RCProps } from '../ReactionComponent';
import { rootResourceStore } from '../RootResourceStore';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { State } from './Types';

interface Props extends RCProps<UserLanguageStore> {
  store: UserLanguageStore;
}

type LanguageSwitchError = I18nextInteractionError | CouldNotReloadError;

const handleLanguageSwitchError = (store: UserLanguageStore) => (error: LanguageSwitchError) => {
  switch (error.kind) {
    case 'i18next-interaction-error':
    case 'could-not-reload-error':
      store.error('Unable to update your language at this time.');
      break;
    default:
      assertNever(error);
  }
};

class SupportedLanguageReactions extends ReactionComponent<UserLanguageStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        rootResourceStore
          .findLinkT('supported-languages')
          .andThen(callApi(languagesResourceDecoder, {}))
          .fork(warn, store.ready);
        break;
      case 'switching':
        Task.succeed<LanguageSwitchError, SupportedLanguageCode>(state.code)
          .andThen(changeLocale)
          .andThen(locationReload)
          .fork(handleLanguageSwitchError(store), store.load);
        break;
      case 'waiting':
        store.load();
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default SupportedLanguageReactions;
