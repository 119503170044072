import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReactionIcon from '../../../Cutesies/ChatReactionIcon';
import * as style from './../style.module.css';
import ActionMenuButton from './ActionMenuButton';
import ActionMenuStore from './ActionMenuStore';

interface Props {
  store: ActionMenuStore;
}

const ReactToThisMessage: React.FC<Props> = ({ store }) => (
  <ActionMenuButton tooltipText="React to this message">
    <button
      className={style.actionMenuButton}
      onClick={store.toggleReactionMenu}
      data-test-action-menu-reaction-menu-toggle
    >
      <ChatReactionIcon />
    </button>
  </ActionMenuButton>
);

export default observer(ReactToThisMessage);
