import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../../../ProgramStore';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import PresenceProgram from '../../PresenceProgram';

interface Props {
  programDetailResource: ProgramDetailResource;
  programStore: ProgramStore;
}

const RelatedPeople: React.FC<Props> = ({ programDetailResource, programStore }) =>
  programStore.registrationType
    .map((registrationType) => {
      switch (registrationType) {
        case 'Student':
        case 'Auditor':
          return <PresenceProgram programDetailResource={programDetailResource} />;
        case 'Professor':
        case 'Coach':
          return <></>;
      }
    })
    .getOrElseValue(<></>);

export default observer(RelatedPeople);
