import { observer } from 'mobx-react';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import * as style from '../../TeamSummary/style.module.css';
import ProgramTypeStore from './ProgramTypeStore';
import ProgramTypeFilterItem from './ProgramTypeFilterItem';

interface Props {
  coachTeamsSummaryStore: coachTeamsSummaryStore;
}

const ProgramTypeFilter: React.FC<Props> = ({ coachTeamsSummaryStore }) => {
  const programTypeStore = new ProgramTypeStore();

  return (
    <div className={style.programTypeFilter}>
      <ProgramTypeFilterItem
        coachTeamsSummaryStore={coachTeamsSummaryStore}
        programTypeStore={programTypeStore}
        programFilter="program-type-aep"
        programFilterType="AEP"
        programType="AEP"
        programFilterStyle={style.programTypeEnterprise}
        selectedProgramFilterStyle={style.programTypeEnterpriseSelected}
      />
      <ProgramTypeFilterItem
        coachTeamsSummaryStore={coachTeamsSummaryStore}
        programTypeStore={programTypeStore}
        programFilter="program-type-msuite"
        programFilterType="MSuite"
        programType="MSuite"
        programFilterStyle={style.programTypeMsuite}
        selectedProgramFilterStyle={style.programTypeMsuiteSelected}
      />
      <ProgramTypeFilterItem
        coachTeamsSummaryStore={coachTeamsSummaryStore}
        programTypeStore={programTypeStore}
        programFilter="program-type-alp"
        programFilterType="ALP"
        programType="EPC"
        programFilterStyle={style.programTypeAlp}
        selectedProgramFilterStyle={style.programTypeAlpSelected}
      />
      <ProgramTypeFilterItem
        coachTeamsSummaryStore={coachTeamsSummaryStore}
        programTypeStore={programTypeStore}
        programFilter="program-type-all"
        programFilterType="ALL"
        programType="ALL"
        programFilterStyle={style.programTypeAll}
        selectedProgramFilterStyle={style.programTypeAllSelected}
      />
    </div>
  );
};

export default observer(ProgramTypeFilter);
