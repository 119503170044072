import { explicitMaybe, stringLiteral } from '@execonline-inc/decoders';
import Decoder, { array, field, number, string, succeed, oneOf } from 'jsonous';
import {
  AnnouncementVideoAsset,
  AnnouncementVideoAssetResource,
  VideoAsset,
  VideoAssetResource,
  VideoPlaybackAdaptiveSources,
} from '../../components/Video/Types';
import { resourceDecoder } from '../../Resource/Decoders';
import {
  preferredVideoPlaybackDecoder,
  videoPlaybackSourceDecoder,
  videoPlaybackSubtitlesDecoder,
} from '../Shared/Decoders';
import {
  LaunchPosition,
  SurveyStatus,
  SurveyCompletionTracking,
  SurveyCompletionTrackingResource,
} from '../../components/ReqHlsVideo/Types';

const videoPlaybackAdaptiveSourcesDecoder: Decoder<VideoPlaybackAdaptiveSources> = succeed({})
  .assign('file', field('file', string))
  .assign('fallbackSources', field('fallback_sources', array(videoPlaybackSourceDecoder)))
  .assign('thumbnails', field('thumbnails', explicitMaybe(string)));

const maybeAdaptiveSourcesDecoder = explicitMaybe(videoPlaybackAdaptiveSourcesDecoder);

export const launchPositionDecoder: Decoder<LaunchPosition> = oneOf([
  stringLiteral<LaunchPosition>('launch-at-end'),
  stringLiteral<LaunchPosition>('launch-at-start'),
  stringLiteral<LaunchPosition>('never-launch'),
  stringLiteral<LaunchPosition>('pause-launch'),
]);

export const surveyStatusDecoder: Decoder<SurveyStatus> = oneOf([
  stringLiteral<SurveyStatus>('pending'),
  stringLiteral<SurveyStatus>('dismissed'),
  stringLiteral<SurveyStatus>('completed'),
]);

export const surveyCompletionTrackingDecoder: Decoder<SurveyCompletionTracking> = succeed({})
  .assign('id', field('id', number))
  .assign('surveyStatus', field('survey_status', surveyStatusDecoder))
  .assign('launchPosition', field('launch_position', launchPositionDecoder));

export const surveyCompletionTrackingResourceDecoder: Decoder<SurveyCompletionTrackingResource> =
  resourceDecoder(surveyCompletionTrackingDecoder);

export const videoAssetDecoder: Decoder<VideoAsset> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('id', field('id', number))
  .assign('courseRegProgramId', field('course_reg_program_id', explicitMaybe(number)))
  .assign(
    'surveyCompletionTracking',
    field('survey_completion_tracking', explicitMaybe(surveyCompletionTrackingResourceDecoder))
  )
  .assign('kind', field('kind', stringLiteral('legacy-video-asset')))
  .assign('title', field('title', explicitMaybe(string)))
  .assign('vimeoId', field('vimeo_id', explicitMaybe(number)))
  .assign('mediaId', field('media_id', explicitMaybe(string)))
  .assign(
    'preferredVideoPlayback',
    field('preferred_video_playback', preferredVideoPlaybackDecoder)
  )
  .assign('progressiveSources', field('progressive_sources', array(videoPlaybackSourceDecoder)))
  .assign('adaptiveSources', field('adaptive_sources', maybeAdaptiveSourcesDecoder))
  .assign('subtitles', field('subtitles', array(videoPlaybackSubtitlesDecoder)));

export const announcementVideoAssetDecoder: Decoder<AnnouncementVideoAsset> = succeed({})
  .assign('uuid', field('uuid', string))
  .assign('id', field('id', number))
  .assign('courseRegProgramId', field('course_reg_program_id', explicitMaybe(number)))
  .assign(
    'surveyCompletionTracking',
    field('survey_completion_tracking', explicitMaybe(surveyCompletionTrackingResourceDecoder))
  )
  .assign('title', field('title', explicitMaybe(string)))
  .assign('vimeoId', field('vimeo_id', explicitMaybe(number)))
  .assign('mediaId', field('media_id', explicitMaybe(string)))
  .assign(
    'preferredVideoPlayback',
    field('preferred_video_playback', preferredVideoPlaybackDecoder)
  )
  .assign('progressiveSources', field('progressive_sources', array(videoPlaybackSourceDecoder)))
  .assign('adaptiveSources', field('adaptive_sources', maybeAdaptiveSourcesDecoder))
  .assign('subtitles', field('subtitles', array(videoPlaybackSubtitlesDecoder)));

export const videoAssetResourceDecoder: Decoder<VideoAssetResource> = resourceDecoder(
  videoAssetDecoder
);

export const announcementVideoAssetResourceDecoder: Decoder<
  AnnouncementVideoAssetResource
> = resourceDecoder(announcementVideoAssetDecoder);
