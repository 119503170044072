import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { NotTranslated, T } from '../../../Translations';
import ArrowRightIcon from '../../Cutesies/ArrowRightIcon';
import * as style from '../../TeamSummary/style.module.css';
import { CommunityProgramResource } from '../../../CommunitiesStore/Types';
import { programUrl } from '../../../ClientRoutes';

interface Props {
  programResource: CommunityProgramResource;
}

const ProgramSummaryContent: React.FC<Props> = ({ programResource }) => (
  <div className={style.background}>
    <div className={style.communitiesListWrapper}>
      <div className={style.communityProgramName}>
        <Link to={programUrl(programResource.payload.id)} data-test-team-link={true}>
          <h2 className={style.name} data-test-team-name={programResource.payload.name}>
            <NotTranslated text={programResource.payload.name} />
          </h2>
        </Link>
      </div>
      <div className={style.messageRoomLink}>
        <Link to={programUrl(programResource.payload.id)} data-test-team-link={true}>
          <div className={style.newProfileButton}>
            <T kind="Chat" />
            <div className={style.buttonIcon}>
              <ArrowRightIcon />
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

export default observer(ProgramSummaryContent);
