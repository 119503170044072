import { when } from '@execonline-inc/maybe-adapter';
import * as React from 'react';
import { Link } from '../../Resource/Types';
import { T, TPlainTextKey } from '../../Translations';
import { teamOrCommunitiesMenuItem } from './TeamBreadCrumbLink';

interface Props {
  links: ReadonlyArray<Link>;
}

const checkTeamLabel = (linkContent: TPlainTextKey): TPlainTextKey =>
  when<TPlainTextKey>(linkContent === 'Team', 'Team').getOrElseValue('Teams');

const TeamLabelBreadCrumb: React.FC<Props> = ({ links }) =>
  teamOrCommunitiesMenuItem(links)
    .map((teamlabel) => <T kind={checkTeamLabel(teamlabel.content)} />)
    .getOrElse(() => <></>);

export default TeamLabelBreadCrumb;
