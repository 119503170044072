import { TranslationsContext } from '@execonline-inc/translations';
import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { handleEscKeyDown } from '../../../../../Accessibility/KeyboardEvents';
import { translation } from '../../../../../Translations';
import * as style from './style.module.css';

interface Props {
  close: () => void;
  title: React.ReactNode;
  children: React.ReactNode;
}

class ModalWrapping extends React.Component<Props> {
  portal: HTMLElement;

  constructor(props: Props) {
    super(props);
    this.portal = document.createElement('div');
    this.portal.id = `${this.props.title} portal`;
    const root = document.getElementById('root');
    if (root) {
      root.appendChild(this.portal);
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (root && this.portal) {
      root.removeChild(this.portal);
    }
  }

  render() {
    const { close, title, children } = this.props;
    return ReactDOM.createPortal(
      <TranslationsContext.Consumer>
        {(ts) => (
          <FocusTrap
            focusTrapOptions={{
              escapeDeactivates: false,
              fallbackFocus: '#coaching-orientation-modal-container',
              allowOutsideClick: true,
            }}
          >
            <div
              className={style.modal}
              data-test-modal={true}
              aria-modal={true}
              onKeyDown={handleEscKeyDown(close)}
              id="coaching-orientation-modal-container"
              tabIndex={-1}
            >
              <div className={style.body} data-test-modal-body={true}>
                <button
                  className={style.closeButton}
                  onClick={close}
                  data-test-close-button={true}
                  aria-label={translation('Close')(ts)}
                  role="button"
                  autoFocus
                >
                  &#x2715;
                </button>
                <h1 className={style.heading} data-test-modal-title={true}>
                  {title}
                </h1>
                {children}
              </div>
              <div className={style.shade} onClick={close} data-test-modal-shade={true} />
            </div>
          </FocusTrap>
        )}
      </TranslationsContext.Consumer>,
      this.portal
    );
  }
}

export default ModalWrapping;
