import { observer } from 'mobx-react';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { courseDocumentsRoute } from '../../../ClientRoutes';
import { NotTranslated } from '../../../Translations';
import PlayIcon from '../../Cutesies/PlayIcon';
import VideoIcon from '../../Cutesies/VideoIcon';
import * as style from './style.module.css';
import { VideoResource } from './Types';

interface Props {
  resource: VideoResource;
}

const VideoResourceView: React.FC<Props> = ({ resource }) => {
  return (
    <div className={style.resource}>
      <Link
        to={courseDocumentsRoute(resource.payload.videoAssetResource.payload.uuid)}
        data-test-resource-link={true}
        target="_blank"
        className={style.content}
      >
        <div className={style.icon}>
          <VideoIcon />
        </div>
        <div className={style.resourceDetails}>
          <span className={style.name} data-test-resource-title={true}>
            <NotTranslated text={resource.payload.title} />
          </span>
          {resource.payload.description
            .map((description) => (
              <div className={style.description}>{ReactHtmlParser(description)}</div>
            ))
            .getOrElseValue(<></>)}
        </div>
        <div className={style.download}>
          <div className={style.downloadIcon}>
            <PlayIcon />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default observer(VideoResourceView);
