import { observer } from 'mobx-react';
import * as React from 'react';
import LanguageSelector from '../../LanguageSelector';
import Divider from './Divider';

const FooterLanguageSwitcherImpl: React.FC = () => (
  <>
    <Divider />
    <LanguageSelector separator={'-'} />
  </>
);

export default observer(FooterLanguageSwitcherImpl);
