import { observer } from 'mobx-react';
import * as React from 'react';
import { fromBool } from '@execonline-inc/maybe-adapter';
import { EditorState } from 'draft-js';
import ChatMessageStore from '../../ChatMessageStore';
import ChatEditor from '../TeamProfile/TeamChat/StyleControls/ChatEditor';
import * as style from './style.module.css';

interface Props {
  store: ChatMessageStore;
}

const CoachChatMessageForm: React.FC<Props> = ({ store }) => {
  const submitChatMessage = () =>
    store.message.andThen((m) => fromBool(m.getCurrentContent().hasText(), m)).do(store.updating);

  const onChange = (value: EditorState) => {
    store.setEditorMessage(value);
  };

  return (
    <div className={style.formWrapper}>
      <form className={style.form}>
        <div className={style.textarea}>
          <ChatEditor
            editorState={store.message.getOrElse(EditorState.createEmpty)}
            submitChatMessage={submitChatMessage}
            store={store}
            onChange={onChange}
            placeholder="Send a message to this room..."
            readOnly={store.state.kind === 'updating'}
          />
        </div>
      </form>
    </div>
  );
};
export default observer(CoachChatMessageForm);
