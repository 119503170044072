import React from 'react';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { T } from '../../../Translations';
import { CoachProfileResource } from '../../EmbeddedFormFieldAsset/Types';
import CoachViewProfileModal from '../ActiveCoachingProductOverview/Orientation/CompletedOrientation/CoachViewProfileModal';
import ModalStore from '../../Modal/Store';
import CoachLabel from '../ActiveCoachingProductOverview/Orientation/CoachLabel';
import YourCoachPlaceholder from './YourCoachPlaceholder';
import style from './styles.module.css';
interface Props {
  coachProfile: Maybe<CoachProfileResource>;
}

const YourCoach: React.FC<Props> = ({ coachProfile }) => {
  const modalStore = new ModalStore();

  return coachProfile
    .map((coachProfile) => {
      return (
        <div className={style.blockWrapper}>
          <p className={style.programSecondaryTitle}>
            <T kind="Your coach" />
          </p>
          <div className={style.sessionBlockNospace}>
            <CoachViewProfileModal coachProfile={coachProfile} modalStore={modalStore} />
            <CoachLabel
              coachProfile={coachProfile}
              children={
                <button className={style.coachOpen} onClick={() => modalStore.open()}>
                  <T kind="view profile" />
                </button>
              }
            />
          </div>
        </div>
      );
    })
    .getOrElse(() => <YourCoachPlaceholder />);
};

export default observer(YourCoach);
