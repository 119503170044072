import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoom } from '../../Conference/Types';
import ConferenceRosterStore from '../../ConferenceRosterStore';
import ConferenceRosterReactions from '../../ConferenceRosterStore/ConferenceRosterReactions';
import ConferenceRosterRefreshReactions from '../../ConferenceRosterStore/ConferenceRosterRefreshReactions';
import ConversationStore from '../../ConversationStore';
import ConversationAPIReactions from '../../ConversationStore/ConversationAPIReactions';
import { ChatablePersonResourceList } from '../../ConversationStore/Types';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { TeamResource } from '../../TeamsStore/Types';
import CoachChatRoom from './CoachChatRoom';
import StaffPresenceStore from './StaffPresenceStore';
import StaffPresenceConferenceRosterReactions from './StaffPresenceStore/StaffPresenceConferenceRosterReactions';
import StaffPresenceReactions from './StaffPresenceStore/StaffPresenceReactions';
import StaffRequestStore from './StaffRequestStore';
import TeamChatCoachTitle from './TeamChatCoachTitle';

interface Props {
  conversationStore: ConversationStore;
  teamResource: TeamResource;
  eventId: number;
  roomId: string;
  chatablePersonResourceList: ChatablePersonResourceList;
  staffPresenceStore: StaffPresenceStore;
  staffRequestStore: StaffRequestStore;
  currentUserResource: CurrentUserResource;
  conferenceRoom: LocalConferenceRoom;
}

class BreakoutChat extends React.Component<Props> {
  conferenceRosterStore = new ConferenceRosterStore(
    this.props.currentUserResource,
    this.props.conferenceRoom
  );

  componentDidMount() {
    this.props.conversationStore.loading();
    this.conferenceRosterStore.loading();
  }

  render() {
    return (
      <>
        <TeamChatCoachTitle
          teamResource={this.props.teamResource}
          roomId={this.props.roomId}
          eventId={this.props.eventId}
          staffPresenceStore={this.props.staffPresenceStore}
          staffRequestStore={this.props.staffRequestStore}
          conferenceRosterStore={this.conferenceRosterStore}
        />
        <CoachChatRoom
          conversationStore={this.props.conversationStore}
          chatablePersonResourceList={this.props.chatablePersonResourceList}
        />
        <ConversationAPIReactions store={this.props.conversationStore} />
        <ConferenceRosterReactions
          store={this.conferenceRosterStore}
          conferenceableResource={this.props.teamResource}
          fireImmediately={true}
        />
        <ConferenceRosterRefreshReactions store={this.conferenceRosterStore} />
        <StaffPresenceConferenceRosterReactions
          staffPresenceStore={this.props.staffPresenceStore}
          store={this.conferenceRosterStore}
        />
        <StaffPresenceReactions store={this.props.staffPresenceStore} />
      </>
    );
  }
}

export default observer(BreakoutChat);
