import { log, warn } from '@execonline-inc/logging';
import { assertNever } from '@kofno/piper';
import { Kettle } from 'kettle-corn';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SurveyModal from '../SurveyModal';
import { announcementSelectPlayer } from './AnnouncementPlayerSelection';
import AnnouncementRenderedPlayer from './AnnouncementRenderedPlayer';
import { SelectedPlayer, VideoSelectionFailure, undetermined } from './PlayerSelection';
import { AnnouncementVideoAssetResource } from './Types';
import VideoEngagement from './VideoEngagement';

export interface Props {
  id: string;
  className: string;
  videoResource: AnnouncementVideoAssetResource;
  kettle: Kettle;
  width: number | string;
  height: number | string;
  dynamicallySetAspectRatio: boolean;
  children: {
    playButton: Maybe<JSX.Element>;
  };
}

interface State {
  playerSelection: SelectedPlayer;
}

const logFailures = (videoResource: AnnouncementVideoAssetResource) => (
  failure: VideoSelectionFailure
): void => {
  switch (failure.kind) {
    case 'missing-vimeo-id':
      warn(
        'VimeoPlayerRenderingFailure',
        `Vimeo player applies for a user, but Vimeo ID is missing from the video asset ${videoResource.payload.uuid}`,
        videoResource
      );
      break;
    case 'missing-progressive-sources':
      warn(
        'ProgressivePlayerRenderingFailure',
        `None of the specified source labels (${failure.labels.toString()}) have a corresponding progessive video source for video asset ${
          videoResource.payload.uuid
        }.`,
        videoResource
      );
      break;
    case 'missing-adaptive-sources':
      warn(
        'AdaptivePlayerRenderingFailure',
        `Adaptive player applies for a user, but adaptive sources are missing from the video asset ${videoResource.payload.uuid}. Falling back to ProgressivePlayer.`,
        videoResource
      );
      break;
    default:
      assertNever(failure);
  }
};

/**
 * Video will take the user's context (browser and server) into account in
 * order to determine the proper video player to render, which will be either:
 *  - VimeoPlayer playing a video uploaded to Vimeo,
 *  - JWPlayer playing a progressive source,
 *  - JWPlayer playing an adaptvie source, falling back to a progressive source
 *
 * NoPlayer is rendered in the case that no appropriate player can be
 * determined by the user's context. This should not happen. The rendering of
 * NoPlayer will trigger an alert.
 */
@observer
class AnnouncementVideo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      playerSelection: undetermined(),
    };
  }

  componentDidMount() {
    const { result, errors } = announcementSelectPlayer(
      window.navigator.userAgent,
      this.props.videoResource.payload
    );
    errors.forEach(logFailures(this.props.videoResource));
    this.setState({ ...this.state, playerSelection: result });
  }

  render() {
    const forwardProps = { ...this.props, selectedPlayer: this.state.playerSelection };
    const { playButton } = forwardProps.children;
    log(
      'Player kind',
      forwardProps.selectedPlayer.kind,
      'for',
      forwardProps.videoResource.payload.uuid
    );
    return (
      <div data-test-video={this.props.id}>
        {playButton.getOrElseValue(<></>)}
        <AnnouncementRenderedPlayer {...forwardProps} />
        {forwardProps.videoResource.payload.courseRegProgramId
          .map((courseRegProgramId) => (
            <VideoEngagement
              kettle={this.props.kettle}
              assetId={forwardProps.videoResource.payload.id}
              courseRegProgramId={courseRegProgramId}
            />
          ))
          .getOrElse(() => (
            <></>
          ))}
        {forwardProps.videoResource.payload.surveyCompletionTracking
          .map((surveyTracking) => (
            <SurveyModal kettle={this.props.kettle} surveyTrackingResource={surveyTracking} />
          ))
          .getOrElse(() => (
            <></>
          ))}
      </div>
    );
  }
}

export default AnnouncementVideo;
