import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ChatMessageReactionsResource } from '../../../../ConversationStore/Types';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { Link } from '../../../../Resource/Types';
import { TPlainTextKey } from '../../../../Translations';
import MessageReactionStore from '../MessageReactionStore';
import {
  deletingMessage,
  messageDeleteRequested,
  messageReportRequested,
  messageReportSuccess,
  reactionMenuOpen,
  reactionSelected,
  ready,
  reportingMessage,
  State,
} from './Types';

interface Props {
  reactionStore: MessageReactionStore;
  messageLinks: ReadonlyArray<Link>;
}
class ActionMenuStore {
  reactionStore: MessageReactionStore;

  constructor({ reactionStore }: Props) {
    this.reactionStore = reactionStore;
  }

  @observable
  state: State = ready();

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  toggleReactionMenu = (e: React.MouseEvent<HTMLElement>) => {
    switch (this.state.kind) {
      case 'ready':
        this.state = reactionMenuOpen(e.pageX, e.pageY);
        break;
      case 'reaction-menu-open':
      case 'reaction-selected':
        this.state = ready();
        break;
      case 'deleting-message':
      case 'message-delete-requested':
      case 'message-report-requested':
      case 'reporting-message':
      case 'message-report-success':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  selectReaction = (emoji: string, reactionsResource: ChatMessageReactionsResource) => {
    this.state = reactionSelected(emoji, reactionsResource);
  };

  @action
  requestMessageDelete = () => {
    this.state = messageDeleteRequested();
  };

  @action
  deleteMessage = (links: ReadonlyArray<Link>) => {
    switch (this.state.kind) {
      case 'message-delete-requested':
        this.state = deletingMessage(links);
        break;
      case 'ready':
      case 'reaction-menu-open':
      case 'reaction-selected':
      case 'deleting-message':
      case 'message-report-requested':
      case 'reporting-message':
      case 'message-report-success':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestMessageReport = () => {
    this.state = messageReportRequested();
  };

  @action
  reportMessage = (links: ReadonlyArray<Link>) => {
    this.state = reportingMessage(links);
  };

  @action
  messageReportSuccess = () => {
    this.state = messageReportSuccess();
  };
  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(errorAlert(this.state));
      case 'ready':
      case 'reaction-menu-open':
      case 'reaction-selected':
      case 'message-delete-requested':
      case 'deleting-message':
      case 'message-report-requested':
      case 'reporting-message':
      case 'message-report-success':
        return nothing();
    }
  }
}

export default ActionMenuStore;
