import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../../../Translations';
import * as style from './style.module.css';

interface Props {
  coachName: string;
}

const CoachLabelName: React.FC<Props> = ({ coachName, children }) => (
  <div className={style.coachNameWrapper}>
    <p className={style.coachName}>
      <NotTranslated text={coachName} />
    </p>
    <div>{children}</div>
  </div>
);

export default observer(CoachLabelName);
