import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const AlarmClockIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 25 25">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M6.25 0A14.207 14.207 0 0 0 0 6.378l1.781.892a12.112 12.112 0 0 1 5.344-5.452L6.25 0Zm12.5 0-.875 1.818a12.112 12.112 0 0 1 5.344 5.452L25 6.378A14.207 14.207 0 0 0 18.75 0ZM12.5 1.53c-6.063 0-11 5.039-11 11.225a11.33 11.33 0 0 0 3.063 7.78l-2.094 3.38L4.156 25l1.938-3.125A10.81 10.81 0 0 0 12.5 23.98a10.81 10.81 0 0 0 6.406-2.105L20.813 25l1.687-1.084-2.063-3.38a11.33 11.33 0 0 0 3.063-7.78c0-6.187-4.938-11.225-11-11.225Zm0 2.041c4.98 0 9 4.102 9 9.184 0 5.082-4.02 9.184-9 9.184s-9-4.102-9-9.184c0-5.082 4.02-9.184 9-9.184Zm-1 2.041v8.164h6v-2.041h-4V5.612h-2Z"
      />
    </SVGIcon>
  );
};

export default observer(AlarmClockIcon);
