import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useConst } from '@execonline-inc/react-hooks.private';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../../../../Links';
import { ProgramModuleProgress } from '../../../../../../ProgramModuleProgress';
import { ProgramModuleProgressProvider } from '../../../../../../ProgramModuleProgress/Context';
import ProgramStore from '../../../../../../ProgramStore';
import { Link } from '../../../../../../Resource/Types';
import SegmentStore from '../../../../../../SegmentStore';
import { SegmentResource } from '../../../../../../SegmentStore/Types';
import { T } from '../../../../../../Translations';
import ModalStore from '../../../../../Modal/Store';
import SubmitAndAdvanceButton from '../../../../../Segment/SegmentNavigation/NextAdvanceButton/SubmitAndAdvanceButton';
import PreviousAdvanceButton from '../../../../../Segment/SegmentNavigation/PreviousAdvanceButton';
import SegmentReactions from '../../../../../SegmentReactions';
import ContentStates from './ContentStates';

interface Props {
  programStore: ProgramStore;
  modalStore: ModalStore;
}

const nextSegmentPresent = (links: ReadonlyArray<Link>) => findLinkBy({ rel: 'next' }, links);

function ModalSegment({ programStore, modalStore }: Props) {
  const segmentStore = useConst(() => new SegmentStore('Streamlined'));

  return programStore.currentSegment
    .map((currentSegment) => {
      return (
        <ProgramModuleProgressProvider store={segmentStore}>
          <div className="mt-8">
            <ProgramModuleProgress />
          </div>
          <ContentStates store={segmentStore} />
          <div className="mt-5 flex justify-between">
            <PreviousAdvanceButton data-testid="previous-segment-button" store={segmentStore}>
              <T kind="Previous" />
            </PreviousAdvanceButton>
            <SubmitAndAdvanceButton
              data-testid="next-segment-button"
              className="ml-auto"
              store={segmentStore}
            >
              {nextSegmentPresent(segmentStore.links)
                .map(() => <T kind="Next" />)
                .getOrElse(() => (
                  <T kind="Finish" />
                ))}
            </SubmitAndAdvanceButton>
          </div>
          <SegmentReactions
            params={{
              programId: String(currentSegment.programId),
              moduleId: String(currentSegment.moduleId),
              segmentId: String(currentSegment.id),
            }}
            store={segmentStore}
            advanceHook={(nextSegment: Maybe<SegmentResource>) => {
              programStore.programDetailResource.map(programStore.reloading);
              nextSegment.elseDo(modalStore.close);
            }}
            fireImmediately={true}
          />
        </ProgramModuleProgressProvider>
      );
    })
    .getOrElse(emptyFragment);
}

export default observer(ModalSegment);
