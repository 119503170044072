import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LocalConferenceRoomResource } from '../../Conference/Types';
import { findLink } from '../../LinkyLinky';
import { rootResourceStore } from '../../RootResourceStore';
import { T } from '../../Translations';
import ClassicCopyrightNotice from '../Footer/ClassicFooter/ClassicCopyrightNotice';
import PrivacyModal from './PrivacyModal';
import PrivacyModalStore from './PrivacyModalStore';
import PrivacyModalReactions from './PrivacyModalStore/PrivacyModalReactions';
import * as style from './style.module.css';

interface Props {
  conferenceRoomResource: LocalConferenceRoomResource;
}

class PrivacyDisclaimer extends React.Component<Props> {
  modalStore = new PrivacyModalStore(this.props.conferenceRoomResource.payload.programId);

  componentDidMount() {
    this.modalStore.load();
  }

  render() {
    return just({})
      .assign('privacyLink', findLink('privacy')(rootResourceStore.links))
      .assign('termsLink', findLink('terms_and_conditions')(rootResourceStore.links))
      .map(({ privacyLink, termsLink }) => (
        <>
          <div className={style.privacy} data-test-footer={true}>
            <T
              kind="Use of this site constitutes acceptance of our <link1>Terms ..."
              link1={(content) => (
                <a
                  href={termsLink.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test-terms-of-use-link={true}
                >
                  {content}
                </a>
              )}
              link2={(content) => (
                <a
                  href={privacyLink.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-test-privacy-policy-link={true}
                >
                  {content}
                </a>
              )}
              link3={(content) => (
                <a
                  href="#"
                  onClick={this.modalStore.reopen}
                  data-test-live-meeting-policy-link={true}
                >
                  {content}
                </a>
              )}
            />
            &nbsp;
            <ClassicCopyrightNotice className="!text-inherit" />
          </div>
          <PrivacyModal
            store={this.modalStore}
            conferenceRoomResource={this.props.conferenceRoomResource}
          />
          <PrivacyModalReactions store={this.modalStore} />
        </>
      ))
      .getOrElse(() => <></>);
  }
}

export default observer(PrivacyDisclaimer);
