import { observer } from 'mobx-react';
import * as React from 'react';
import { ResourceItem as TResourceItem } from '../../../ResourceStore/Types';
import VideoResource from './VideoResource';

interface Props {
  resource: TResourceItem;
}

const ResourceItem: React.FC<Props> = ({ resource }) => {
  switch (resource.kind) {
    case 'document':
      return <span />;
    case 'video':
      return <VideoResource resource={resource} />;
  }
};

export default observer(ResourceItem);
