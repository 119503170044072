import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { ExperienceResources, UseCaseDisplayType } from '../../../Common/Experience/Types';
import EnrollmentStore from '../../Enrollment/Store';
import { ListViewExperiences } from './ListViewExperiences';
import { TileViewExperiences } from './TileViewExperiences';

interface Props {
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
  experienceResources: ExperienceResources;
  displayType: UseCaseDisplayType;
  searchValue: Maybe<string>;
}

function Experiences({
  enrollmentStore,
  invitationUuid,
  experienceResources,
  displayType,
  searchValue,
}: Props) {
  const displayTypeWithSearch = searchValue
    .map<UseCaseDisplayType>(() => 'list')
    .getOrElseValue(displayType);

  switch (displayTypeWithSearch) {
    case 'grid':
      return (
        <TileViewExperiences
          kind="experiences"
          invitationUuid={invitationUuid}
          productCollection={nothing()}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
        />
      );
    case 'list':
      return (
        <ListViewExperiences
          kind="experiences"
          invitationUuid={invitationUuid}
          searchValue={searchValue}
          productCollection={nothing()}
          experienceResources={experienceResources}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(Experiences);
