import * as React from 'react';
import { ResourceResource } from '../Types';
import TopLevelDocumentResource from './TopLevelDocumentResource';
import TopLevelVideoResource from './TopLevelVideoResource';
import * as style from './style.module.css';

interface Props {
  resources: ResourceResource[];
}

const TopLevelResources: React.FC<Props> = ({ resources }) => (
  <div className={style.resources}>
    {resources.map((resource) => {
      switch (resource.payload.kind) {
        case 'document':
          return (
            <TopLevelDocumentResource
              key={resource.payload.id}
              document={resource.payload}
              links={resource.links}
            />
          );
        case 'video':
          return <TopLevelVideoResource key={resource.payload.id} video={resource.payload} />;
        case 'user-uploaded-document':
          return <></>;
      }
    })}
  </div>
);

export default TopLevelResources;
