import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ChatReplyStore from '../../../ChatReplyStore';
import ChatReplyReactions from '../../../ChatReplyStore/ChatReplyReactions';
import ConversationStore from '../../../ConversationStore';
import { ChatMessageResource } from '../../../ConversationStore/Types';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import LastReadMessageStore from '../../../LastReadMessageStore';
import NewChatMessage from '../../NewChatMessage';
import MessageReplyStore from './MessageReplyStore';
import ShowReplies from './ShowReplies';
import TeamChatReplyForm from './TeamChatReplyForm';
import * as style from './style.module.css';

interface Props {
  messageReplyStore: MessageReplyStore;
  messageResource: ChatMessageResource;
  conversationStore: ConversationStore;
  chatReplyStore: ChatReplyStore;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  lastReadMessageStore: Maybe<LastReadMessageStore>;
}

const MessageReplies: React.FC<Props> = (props) => {
  const {
    conversationStore,
    messageResource,
    chatReplyStore,
    messageReplyStore,
    presenceStore,
    lastReadMessageStore,
  } = props;
  return (
    <WithCurrentUser
      children={(currentUserResource) => (
        <div className={style.replyContainer}>
          <ShowReplies
            messageReplyStore={messageReplyStore}
            presenceStore={presenceStore}
            chatReplyStore={chatReplyStore}
            lastReadMessageStore={lastReadMessageStore}
            currentUserResource={currentUserResource}
          />
          <NewChatMessage store={chatReplyStore}>
            {{
              form: <TeamChatReplyForm store={chatReplyStore} placeholder="Type your reply..." />,
            }}
          </NewChatMessage>
          <ChatReplyReactions
            store={chatReplyStore}
            conversationStore={conversationStore}
            messageResource={messageResource}
            lastReadMessageStore={lastReadMessageStore}
          />
        </div>
      )}
    />
  );
};

export default observer(MessageReplies);
