import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { handleEnterKeyPress } from '../../../../Accessibility/KeyboardEvents';
import { T } from '../../../../Translations';
import { PasswordField } from '../../../Form';
import { AuthSignUp } from '../Types';

interface Props {
  authForm: AuthSignUp;
}

function FormFields({ authForm }: Props) {
  switch (authForm.kind) {
    case 'password-sign-up':
      return (
        <div className="flex flex-col gap-4 sm:flex-row md:gap-6">
          <PasswordField
            data-testid="sign-up-form_password-text-field"
            value={authForm.store.password.getOrElseValue('')}
            onKeyDown={handleEnterKeyPress(authForm.store.creating)}
            onChange={authForm.store.setPassword}
          />
          <PasswordField
            data-testid="sign-up-form_confirm-password-text-field"
            label={<T kind="Confirm Password" />}
            value={authForm.store.passwordConfirmation.getOrElseValue('')}
            onKeyDown={handleEnterKeyPress(authForm.store.creating)}
            onChange={authForm.store.setPasswordConfirmation}
          />
        </div>
      );
    case 'sso-sign-up':
      return emptyFragment();
  }
}

export default observer(FormFields);
