import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../../GlobalPresence/GlobalPresenceStore';
import { PersonStoreContract } from '../../../Person/types';
import { Position } from '../../PositionContext';
import OnlineStatus from './SharedInternals/OnlineStatus';
import PersonBasePopoverable from './SharedInternals/PersonBasePopoverable';
import PersonDetails from './SharedInternals/PersonDetails';

interface Props {
  store: PersonStoreContract;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  position: Position;
}

const PersonSupPanel: React.FC<Props> = ({ store, presenceStore, position }) => (
  <PersonBasePopoverable personStore={store}>
    {{
      onlineStatus: <OnlineStatus presenceStore={presenceStore} personStore={store} kind="dark" />,
      details: details(store, position),
    }}
  </PersonBasePopoverable>
);

export default observer(PersonSupPanel);

const details = (store: PersonStoreContract, position: Position): Maybe<JSX.Element> => {
  switch (store.kind) {
    case 'professor':
    case 'message-author':
      return just(
        <PersonDetails
          organization={store.organization}
          name={store.name}
          jobTitle={store.position}
          position={position}
        />
      );
    case 'conference-participant':
    case 'learning-partner':
    case 'team-member':
    case 'user':
      return nothing();
  }
};
