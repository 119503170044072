import { observer } from 'mobx-react';
import * as React from 'react';
import Image from '../../../../../../Image';

interface Props {
  href: string;
  alt: string;
}

const HeroSchoolPartnerLogoImage: React.FC<Props> = ({ href, alt }) => (
  <Image src={href} alt={alt} />
);

export default observer(HeroSchoolPartnerLogoImage);
