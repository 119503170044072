import { observer } from 'mobx-react';
import * as React from 'react';
import ConjointAnalysisStore from '../../ConjointAnalysisStore';
import { T } from '../../Translations';
import Input from '../Form/Input';
import * as style from './style.module.css';

interface Props {
  store: ConjointAnalysisStore;
}

const ProductDescription: React.FC<Props> = ({ store }) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    store.setDescription(event.currentTarget.value);
  };
  return (
    <>
      <br />
      <h3>
        <T kind="STEP 2: DESCRIBE YOUR PRODUCT OR SERVICE" />
      </h3>
      <div className={style.stepContent}>
        <p>
          <T kind="Which product or service will you ask ..." />
        </p>
        <label htmlFor="ps_desc" data-alt="required question">
          <T kind="Product/Service Description" /> *
        </label>
        <Input
          onChange={handleChange}
          type="text"
          id="ps_desc"
          name="ps_desc"
          hint="e.g. Shopping for a new apartment"
          required
        />
      </div>
    </>
  );
};

export default observer(ProductDescription);
