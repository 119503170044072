import { T } from '../../Translations';
import * as style from './style.module.css';

interface ViewHeaderProps {
  filename: string;
}

export const ViewHeader: React.FC<ViewHeaderProps> = ({ filename }) => (
  <h1 className={style.fileName}>
    <T kind="Filename: {{name}}" name={filename} />
  </h1>
);
