import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { UnreadTeamMessageNotification } from '../../../../TeamsStore/Types';
import { T, translation, TranslationsContext } from '../../../../Translations';
import ChatIcon from '../../../Cutesies/ChatIcon';
import ModalStore from '../../../Modal/Store';
import * as style from '../../../Toasts/style.module.css';

interface Props {
  notification: UnreadTeamMessageNotification;
}

class UnreadMessageNotification extends React.Component<Props> {
  store = new ModalStore();

  componentDidMount() {
    this.store.open();
  }

  render() {
    const { notification } = this.props;
    switch (this.store.state.kind) {
      case 'open':
        return (
          <TranslationsContext.Consumer>
            {(ts) => (
              <div className={style.toast} data-test-unread-notification={true}>
                <div className={style.content}>
                  <div className={style.icon}>
                    <ChatIcon fill="#B3090F" />
                  </div>
                  <div className={style.details}>
                    <h2 className={style.title}>
                      <T kind="You have {{count}} new message" count={notification.unreadCount} />
                    </h2>
                    <p className={style.description}>
                      <T kind="Your team is talking! Join the discussion!" />
                    </p>
                  </div>
                </div>
                <div className={style.actions}>
                  <button
                    className={`${style.button} m-0`}
                    onClick={this.store.close}
                    data-test-unread-notification-dismiss={true}
                    aria-label={translation('Dismiss dropdown notification.')(ts)}
                  >
                    <T kind="Dismiss" />
                  </button>
                  <Link
                    className={style.button}
                    to={notification.teamLink}
                    data-test-unread-notification-view={true}
                  >
                    <T kind="View Messages" />
                  </Link>
                </div>
              </div>
            )}
          </TranslationsContext.Consumer>
        );
      case 'closed':
        return <></>;
      default:
        return assertNever(this.store.state);
    }
  }
}

export default observer(UnreadMessageNotification);
