import { action, observable } from 'mobx';
import { Link } from '../../../Resource/Types';
import { State, closed, open, postponing } from './Types';

class AutoLaunchableModalStore {
  @observable
  state: State = open();

  @action
  close = () => {
    this.state = closed();
  };

  @action
  open = () => {
    this.state = open();
  };

  @action
  postpone = (link: Link) => {
    this.state = postponing(link);
  };
}

export default AutoLaunchableModalStore;
