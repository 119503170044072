import { base64ToUTF8Decoder, jsonParserDecoder, pipeD } from '@execonline-inc/decoders';
import Decoder, { array, at, field, number, string, succeed } from 'jsonous';
import {
  LearningPartner,
  LearningPartnerResource,
  LearningPartnersResource,
} from '../../LearningPartnersStore/Types';
import { profileResourceDecoder } from '../../ProfileStore/Decoders';
import { resourceDecoder } from '../../Resource/Decoders';
import { LearningPartnersNode } from './Types';

const learningPartnerDecoder: Decoder<LearningPartner> = succeed({})
  .assign('id', field('id', number))
  .assign('email', field('email', string))
  .assign('profile', field('profile', profileResourceDecoder));

export const learningPartnerResourceDecoder: Decoder<LearningPartnerResource> = resourceDecoder(
  learningPartnerDecoder
);

const learningPartnersResourceDecoder: Decoder<LearningPartnersResource> = resourceDecoder(
  array(learningPartnerResourceDecoder)
);

export const learningPartnersDecoder: Decoder<LearningPartnersNode> = succeed({})
  .assign(
    'learningPartners',
    at(
      ['attribs', 'data-attr-learning-partners'],
      pipeD(jsonParserDecoder(base64ToUTF8Decoder), learningPartnersResourceDecoder)
    )
  )
  .assign('kind', succeed<'learning-partners'>('learning-partners'));
