import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import ProgramMemberRoutes from './ProgramMemberRoutes';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

function ProgramMemberList({ presenceMembersStore }: Props) {
  const presenceStore = useContext(GlobalPresenceContext);

  useEffect(() => {
    presenceStore.addPresenceStore(presenceMembersStore);

    return () => {
      presenceStore.removePresenceStore(presenceMembersStore);
    };
  });

  return <ProgramMemberRoutes presenceMembersStore={presenceMembersStore} />;
}

export default observer(ProgramMemberList);
