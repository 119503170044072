import { just } from 'maybeasy';
import { fromArrayMaybe } from 'nonempty-list';
import { mapMaybe } from '@execonline-inc/collections';
import { identity } from '@kofno/piper';
import { VideoSourceFile } from '../../../../components/JWPlayer/Types';
import {
  HighProgressiveStreamResource,
  LowProgressiveStreamResource,
  MediumProgressiveStreamResource,
  OverviewVideoResource,
} from '../../../../components/ReqHlsVideo/Types';
import { findLinkBy } from '../../../../Links';

function findVideoLinks(
  stream:
    | LowProgressiveStreamResource
    | MediumProgressiveStreamResource
    | HighProgressiveStreamResource,
) {
  const link = findLinkBy({ rel: 'view', method: 'get' }, stream.links);

  return link.map<VideoSourceFile>((link) => ({
    file: link.href,
    label: stream.payload.label,
    default: stream.payload.default ? 'true' : 'false',
  }));
}

export function getVideoDetails(video: OverviewVideoResource) {
  const { payload, links } = video;

  const thumbnail = findLinkBy({ rel: 'thumbnail', method: 'get' }, links);
  const sources = fromArrayMaybe(
    mapMaybe(identity, [
      findVideoLinks(payload.lowProgressiveStream),
      findVideoLinks(payload.mediumProgressiveStream),
      findVideoLinks(payload.highProgressiveStream),
    ]),
  );

  return just({})
    .assign('video', just(video))
    .assign('thumbnail', thumbnail)
    .assign('sources', sources);
}
