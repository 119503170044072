import { observer } from 'mobx-react';
import * as React from 'react';
import LogoGraphic from '../Cutesies/LogoGraphic';
import * as style from './style.module.css';

type LogoSize = 'regular' | 'large';

interface Props {
  size?: LogoSize;
}

const CenteredLogo: React.FC<Props> = ({ size = 'regular' }) => (
  <div className={`${style.logo} ${style[size]}`}>
    <LogoGraphic />
  </div>
);

export default observer(CenteredLogo);
