import { Error } from '../ErrorHandling';
import { ProfileResource } from '../ProfileStore/Types';
import { ProgramDetailResource } from '../ProgramStore/Types';
import { Resource } from '../Resource/Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  programDetailResource: ProgramDetailResource;
}
interface Ready {
  kind: 'ready';
  learningPartnersResource: LearningPartnersResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (programDetailResource: ProgramDetailResource): Loading => ({
  kind: 'loading',
  programDetailResource,
});

export const ready = (learningPartnersResource: LearningPartnersResource): Ready => ({
  kind: 'ready',
  learningPartnersResource,
});

export type LearningPartnersState = Waiting | Loading | Ready | Error;
export interface LearningPartner {
  id: number;
  email: string;
  profile: ProfileResource;
}

export type LearningPartnersResource = Resource<LearningPartnerResource[]>;
export type LearningPartnerResource = Resource<LearningPartner>;
