import { assertNever } from '@kofno/piper';
import { fromArray } from 'nonempty-list';
import { default as AssignmentDueStore, default as AttachmentUploadStore } from '.';
import ErrorActionableReaction, { EAProps } from '../ErrorActionableReaction';
import SegmentStore from '../SegmentStore';
import { Upload } from '../Uploads';
import { AttachableSegment, AttachmentUploadState } from './Types';

export interface Props extends EAProps<AssignmentDueStore> {
  store: AttachmentUploadStore;
  segmentStore: SegmentStore;
  segment: AttachableSegment;
}

const removeUpload = (removable: Upload) => (candidate: Upload): boolean =>
  candidate.uploadFilePath !== removable.uploadFilePath;

class AttachmentUploadReactions extends ErrorActionableReaction<
  AttachmentUploadStore,
  AttachmentUploadState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: AttachmentUploadState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        store.readyWithoutUpload(this.props.segment);
        break;
      case 'upload-successful':
        state.uploadsToSubmit.cata({
          Just: (uploadsToSubmit) => store.ready(uploadsToSubmit.concat(state.newUploads)),
          Nothing: () => store.ready(state.newUploads),
        });
        break;
      case 'removing-upload':
        fromArray(state.uploadsToSubmit.filter(removeUpload(state.uploadToRemove))).cata({
          Ok: store.ready,
          Err: () => store.readyWithoutUpload(this.props.segment),
        });
        break;
      case 'ready':
      case 'ready-without-upload':
      case 'waiting':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default AttachmentUploadReactions;
