import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { UpcomingExperience } from '../../Experience/Types';
import { L, T } from '../../../../../Translations';
import { EnrolledIcon } from './Icons';
import ExperienceAlert from './ExperienceAlert';

interface Props {
  experience: UpcomingExperience;
}

function UpcomingAlert({ experience }: Props) {
  return (
    <ExperienceAlert icon={<EnrolledIcon />}>
      <Typography variant="caption">
        <T kind="You are already enrolled." />{' '}
        <T
          kind="This program starts on <date/>"
          date={<L localizeable={experience.startOn} format="long-month-day-year" />}
        />
      </Typography>
    </ExperienceAlert>
  );
}

export default observer(UpcomingAlert);
