import { observer } from 'mobx-react';
import * as React from 'react';
import OnEventsReady from '../../EventsStore/OnEventsReady';
import { EventsState, onlyUpcoming } from '../../EventsStore/Types';
import UpcomingEventsImpl from './UpcomingEventsImpl';

interface Props {
  state: EventsState;
  limit: number;
  viewAllButton: boolean;
}

const UpdateEventsState: React.FC<Props> = ({ state, limit, viewAllButton }) => (
  <OnEventsReady
    state={state}
    filter={onlyUpcoming}
    renderWithEvents={(events) => (
      <UpcomingEventsImpl events={events} limit={limit} viewAllButton={viewAllButton} />
    )}
    renderWithoutEvents={() => <></>}
  />
);

export default observer(UpdateEventsState);
