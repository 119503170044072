import { observer } from 'mobx-react';
import * as React from 'react';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import FileUploadStore from '../../../FileUploadStore';
import ProfileFormStore from '../../../ProfileFormStore';
import { T, translation, TranslationsContext } from '../../../Translations';
import Button from '../../Form/Button';
import Label from '../../Form/Label';
import Image from '../../Image';
import PhotoUploadReactions from './PhotoUploadReactions';
import * as style from './style.module.css';

interface Props {
  avatarUrl: string;
  store: ProfileFormStore;
  fileUploadStore: FileUploadStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
}

const PhotoDisplay: React.FC<Props> = ({
  avatarUrl,
  fileUploadStore,
  store,
  countryRegionOptionsStore,
}) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <div data-test-photo-display={true}>
        <Label className={style.label} labelText="Photo" />
        <div className={style.photoSection}>
          <div className={style.avatar}>
            <Image alt={translation('Profile Photo')(ts)} src={avatarUrl} />
          </div>
          <div className={style.fileStackSection}>
            <div className={style.photoNote} data-test-photo-note={true}>
              <T kind="Note: This photo will be visible to other ExecOnline participants." />
            </div>
            <div className={style.fileStackUploader}>
              <Button
                type="button"
                value="remove photo"
                onClick={fileUploadStore.removing}
                className={style.fileStackButton}
                dataTestDeleteButton={true}
              />
            </div>
          </div>
        </div>
        <PhotoUploadReactions
          store={fileUploadStore}
          profileStore={store}
          countryRegionOptionsStore={countryRegionOptionsStore}
          fireImmediately
        />
      </div>
    )}
  </TranslationsContext.Consumer>
);

export default observer(PhotoDisplay);
