import * as React from 'react';
import { Link } from 'react-router-dom';
import Main from '../../BrowserSupport/Main';
import I18nTitle from '../../Title/I18nTitle';
import { T } from '../../Translations';
import LogoGraphic from '../Cutesies/LogoGraphic';
import * as style from './style.module.css';

const Sitemap: React.FC = () => {
  const linkStyle = style.linkOnDark;

  return (
    <>
      <I18nTitle title="Sitemap" />
      <Main className={style.wrapper}>
        <div className={style.subWrapper}>
          <div className={style.logo}>
            <LogoGraphic />
          </div>
          <h1 className={style.title}>
            <T kind="Sitemap" />
          </h1>
          <div>
            <ul className={style.list}>
              <li>
                <Link className={linkStyle} to="/">
                  <T kind="Dashboard" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/calendar">
                  <T kind="Calendar" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/team">
                  <T kind="Team" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/program_chat">
                  <T kind="Program" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/communities">
                  <T kind="Communities" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/resources">
                  <T kind="Resources" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/notes">
                  <T kind="Notes" />
                </Link>
              </li>
              <li>
                <Link className={linkStyle} to="/profile/edit">
                  <T kind="Edit Profile" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Main>
    </>
  );
};

export default Sitemap;
