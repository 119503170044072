import { findPayload } from '@execonline-inc/collections';
import { parseIntM } from '@execonline-inc/numbers';
import { hours } from '@execonline-inc/time';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import BreakoutTimerStore from '../../BreakoutTimerStore';
import BroadcastMessageStore from '../../BroadcastMessageStore';
import ChatablePersonResourcesStore from '../../ChatableResourcesStore';
import { whenLocalConferenceRoom } from '../../Conference/Types';
import EventsStore from '../../EventsStore';
import { InactivityLogoutTracker } from '../../Inactivity/Core';
import { NotTranslated } from '../../Translations';
import Loader from '../Loader';
import * as platformStyle from '../Platform/style.module.css';
import BreakoutWithTimer from './BreakoutWithTimer';
import * as style from './style.module.css';

interface Props {
  eventsStore: EventsStore;
  eventId: string;
  broadcastMessageStore: BroadcastMessageStore;
  timerStore: BreakoutTimerStore;
  chatablePersonResourcesStore: ChatablePersonResourcesStore;
}

function Dashboard({
  eventsStore,
  eventId,
  broadcastMessageStore,
  timerStore,
  chatablePersonResourcesStore,
}: Props) {
  InactivityLogoutTracker.useInactivityExtension(hours(2));

  return (
    <div className={style.dashboard}>
      <>
        <div className={platformStyle.containerFull}>
          <div>
            {parseIntM(eventId)
              .map((eventId: number) => {
                switch (eventsStore.state.kind) {
                  case 'waiting':
                  case 'loading':
                    return (
                      <div className={platformStyle.contentLoader}>
                        <Loader />
                      </div>
                    );
                  case 'ready':
                  case 'refresh':
                    return just({})
                      .assign(
                        'eventResource',
                        findPayload(eventId, eventsStore.state.eventsResource.payload),
                      )
                      .assign(
                        'conferenceRoom',
                        ({ eventResource }) => eventResource.payload.conferenceRoom,
                      )
                      .assign('breakoutRooms', ({ conferenceRoom }) =>
                        whenLocalConferenceRoom(conferenceRoom.payload).andThen(
                          (cr) => cr.breakoutRooms,
                        ),
                      )
                      .map(({ eventResource, breakoutRooms }) => (
                        <BreakoutWithTimer
                          breakoutRoom={breakoutRooms.first}
                          broadcastMessageStore={broadcastMessageStore}
                          eventResource={eventResource}
                          timerStore={timerStore}
                          chatablePersonResourcesStore={chatablePersonResourcesStore}
                        />
                      ))
                      .getOrElseValue(<span />);
                  case 'error':
                    return (
                      <div>
                        <NotTranslated text={eventsStore.state.message} />
                      </div>
                    );
                }
              })
              .getOrElseValue(<span />)}
          </div>
        </div>
      </>
    </div>
  );
}

export default observer(Dashboard);
