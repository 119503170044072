import { UnaryFunction, assertNever } from '@kofno/piper';
import { succeed } from 'jsonous';
import { Kettle } from 'kettle-corn';
import { Maybe, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import { AppyError, callApi } from '../../Appy';
import ErrorActionableReaction, { EAProps, handleError } from '../../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../../LinkyLinky';
import NoteStore, { State } from '../../NoteStore';
import { NoteResource } from '../../NoteStore/Types';
import { noteResourceDecoder } from '../../NotesStore/Decoders';
import { Link } from '../../Resource/Types';
import { SegmentResource } from '../../SegmentStore/Types';

interface MissingNoteContent {
  kind: 'missing-note-content';
}

const missingNoteContent = (): MissingNoteContent => ({
  kind: 'missing-note-content',
});

interface MissingNoteContext {
  kind: 'missing-note-context';
}

const missingNoteContext = (): MissingNoteContext => ({
  kind: 'missing-note-context',
});

type NoteRequestError = MissingLinkError | MissingNoteContext | MissingNoteContent | AppyError;

const handleNoterequestError = (store: NoteStore) => (error: NoteRequestError) => {
  switch (error.kind) {
    case 'missing-link-error':
      store.error('You do not have permission to modify notes');
      break;
    case 'missing-note-context':
      store.error('You cannot create a note outside of a course segment');
      break;
    case 'missing-note-content':
      store.error('A note cannot be saved without content');
      break;
    default:
      handleError(store, error);
  }
};

interface NoteOutput {
  note: {
    content: string;
    position?: number;
  };
}

const withConditionalFields = (
  noteOutput: NoteOutput,
  resource: Maybe<ContextResource>,
  kettle: Maybe<Kettle>,
): NoteOutput => {
  resource.map((cr: ContextResource) => {
    switch (cr.payload.type) {
      case 'lecture':
        kettle.map((kettle: Kettle) =>
          kettle.videoState.position.map(
            (seconds: number) => (noteOutput.note.position = Math.round(seconds)),
          ),
        );
        break;
      case 'overview':
      case 'survey':
      case 'presentation':
      case 'assignment-due':
      case 'external-program':
      case 'team-discussion':
        break;
      default:
        assertNever(cr.payload);
    }
  });
  return noteOutput;
};

const noteEncoder = (
  resource: Maybe<ContextResource>,
  kettle: Maybe<Kettle>,
  content: string,
): NoteOutput => {
  return withConditionalFields(
    {
      note: {
        content,
      },
    },
    resource,
    kettle,
  );
};

const creationSuccess = (store: NoteStore) => (noteResource: NoteResource) => {
  store.notesStore.add(noteResource);
  store.new();
};

const deletionSuccess = (store: NoteStore, note: NoteResource) => () => {
  store.notesStore.remove(note);
};

// Over time this will become a union of all the things a note could be attached to
type ContextResource = SegmentResource;

export interface Props extends EAProps<NoteStore> {
  contextResource: Maybe<ContextResource>;
  kettle: Maybe<Kettle>;
}

const saveNote = (resource: Maybe<ContextResource>, kettle: Maybe<Kettle>, content: string) =>
  callApi(noteResourceDecoder, noteEncoder(resource, kettle, content));

const updateNote = (resource: Maybe<ContextResource>, content: Maybe<string>) =>
  content
    .map<
      UnaryFunction<Link, Task<NoteRequestError, NoteResource>>
    >((c) => callApi(noteResourceDecoder, noteEncoder(resource, nothing(), c)))
    .getOrElseValue((l: Link) => Task.fail(missingNoteContent()));

const deleteNote = callApi(succeed({}), {});

class NoteReactions extends ErrorActionableReaction<NoteStore, State, Props> {
  tester = () => this.props.store.state;

  effect = (state: State) => {
    switch (state.kind) {
      case 'creating':
        this.props.contextResource
          .map<Task<NoteRequestError, SegmentResource>>(Task.succeed)
          .getOrElse(() => Task.fail(missingNoteContext()))
          .map((r) => r.links)
          .andThen(findLinkT('notes-create'))
          .andThen(saveNote(this.props.contextResource, this.props.kettle, state.content))
          .fork(handleNoterequestError(this.props.store), creationSuccess(this.props.store));
        break;
      case 'updating':
        Task.succeed<NoteRequestError, ReadonlyArray<Link>>(this.props.store.links)
          .andThen(findLinkT('update'))
          .andThen(updateNote(this.props.contextResource, this.props.store.content))
          .fork(handleNoterequestError(this.props.store), this.props.store.ready);
        break;
      case 'deleting':
        Task.succeed<NoteRequestError, ReadonlyArray<Link>>(state.noteResource.links)
          .andThen(findLinkT('delete'))
          .andThen(deleteNote)
          .fork(
            handleNoterequestError(this.props.store),
            deletionSuccess(this.props.store, state.noteResource),
          );
        break;
      case 'loading':
        this.props.store.notesStore.loading();
        break;
      case 'error':
      case 'waiting':
      case 'ready':
      case 'new':
      case 'editing':
      case 'confirming':
        break;
      default:
        assertNever(state);
    }
  };
}

export default NoteReactions;
