import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../Translations';
import CalendarIcon from '../Cutesies/CalendarIcon';
import * as style from './style.module.css';
import { CommunityTeamResource } from '../../CommunitiesStore/Types';

interface Props {
  communityTeamResource: CommunityTeamResource;
}

const TeamEventsOverview: React.FC<Props> = ({ communityTeamResource }) => (
  <div className={style.item}>
    <div className={style.eventsIcon}>
      <CalendarIcon />
    </div>
    <a href="#" data-test-team-events-link={true}>
      <T kind="{{count}} event" count={communityTeamResource.payload.teamEventCount} />
    </a>
  </div>
);

export default observer(TeamEventsOverview);
