import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { sessionStore } from '../../../Session/Store';
import { SSO } from '../../../SingleSignOn';
import { TPlainTextKey } from '../../../Translations';
import { InvitationStore } from './InvitationStore';
import { InstantSso } from './InvitationStore/Types';
import { UserState } from './Types';

interface Props {
  store: InvitationStore;
  state: InstantSso;
  currentUserState: UserState;
}

const SingleSignOn: React.FC<Props> = ({ store, state, currentUserState }) => {
  switch (currentUserState.kind) {
    case 'anonymous':
      return (
        <SSO.Initiate
          state={{
            urls: state.instantSsoReady.urls,
            userExperience: {
              kind: 'streamlined',
              username: state.instantSsoReady.username,
              opaqueUseCaseIdentifier: state.instantSsoReady.opaqueUseCaseIdentifier,
            },
          }}
          onFailure={onFailure(store)}
          onSuccess={onSuccess}
        />
      );
    case 'ready':
    case 'refreshing':
      return <></>;
  }
};

export default observer(SingleSignOn);

const onSuccess = (): void => {
  sessionStore.requesting({ messageOnFailure: just('Something went wrong') });
};

const onFailure = (store: InvitationStore) =>
  SSO.Errors.onFailure({
    handleSsoError: SSO.Errors.handleSsoError({
      onSessionAlreadyEstablished: onSuccess,
      handler: handleError(store),
    }),
  });

const handleError =
  (store: InvitationStore) =>
  (_err: SSO.Error) =>
  (message: TPlainTextKey): void =>
    store.error(message);
