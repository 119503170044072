import { emptyFragment } from '@execonline-inc/execonline-ui';
import { identity } from '@kofno/piper';
import { Maybe, fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { findLink } from '../../LinkyLinky';
import { Resource } from '../../Resource/Types';
import AsModal from '../Modal/AsModal';
import { CloseAction, OpenableState } from '../Openable';
import DownloadButtonRenderer from './DownloadButtonRenderer';
import DownloadModalTitle from './DownloadModalTitle';
import ViewButton from './ViewButton';
import * as style from './style.module.css';

interface File {
  title: string;
  description?: Maybe<string>;
}

type FileResource = Resource<File>;
interface Props {
  resource: FileResource;
  close: CloseAction;
  modalState: OpenableState;
}

function DownloadViewModal({ resource, close, modalState }: Props) {
  const description = fromNullable(resource.payload.description).andThen(identity);
  return (
    <AsModal close={close} state={modalState} title={<DownloadModalTitle resource={resource} />}>
      <div className={style.content}>
        {description.map((d) => <p>{ReactHtmlParser(d)}</p>).getOrElse(emptyFragment)}
        <h3 className={style.fileTitle}>{resource.payload.title}</h3>
        <div className={style.buttons}>
          <DownloadButtonRenderer resource={resource} />
          <ViewButton link={findLink('view', resource.links)} />
        </div>
      </div>
    </AsModal>
  );
}

export default observer(DownloadViewModal);
