import { observer } from 'mobx-react';
import * as React from 'react';
import AttachmentUploadStore from '../../../../AttachmentUploadStore';
import SegmentStore from '../../../../SegmentStore';
import { T } from '../../../../Translations';
import UploadIcon from '../../../Cutesies/UploadIcon';
import * as style from '../style.module.css';

interface Props {
  segmentStore: SegmentStore;
  attachmentUploadStore: AttachmentUploadStore;
}

const submit = (segmentStore: SegmentStore, attachmentUploadStore: AttachmentUploadStore) => () =>
  attachmentUploadStore.uploadsToSubmit.map(uploads =>
    segmentStore.submitAndAdvance({
      type: 'assignment-due',
      uploads: uploads,
      attachmentUploadStore,
    })
  );

const ActiveButton: React.StatelessComponent<Props> = ({ segmentStore, attachmentUploadStore }) => (
  <button
    type="submit"
    className={style.submitButton}
    onClick={submit(segmentStore, attachmentUploadStore)}
    data-test-submit-button={true}
  >
    <T kind="Upload and Submit Assignment" />
    <div className={style.buttonIcon}>
      <UploadIcon />
    </div>
  </button>
);

export default observer(ActiveButton);
