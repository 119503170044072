import * as React from 'react';
import { just, nothing } from 'maybeasy';
import StarIcon from '../Cutesies/StarIcon';

interface Props {
  className: string;
  important: boolean;
}

const ImportantIcon: React.FC<Props> = ({ className, important }) => {
  const maybeImportant = important ? just(important) : nothing<boolean>();
  return maybeImportant
    .map(() => (
      <div className={className}>
        <StarIcon />
      </div>
    ))
    .getOrElseValue(<></>);
};
export default ImportantIcon;
