import { observer } from 'mobx-react';
import * as React from 'react';
import { TeamsResource } from '../../../../TeamsStore/Types';
import Team from './Team';

interface Props {
  teamsResource: TeamsResource;
}

const TeamBody: React.FC<Props> = ({ teamsResource }) => {
  return (
    <dd data-test-team-body={true}>
      {teamsResource.payload.map(teamResource => (
        <Team key={teamResource.payload.id} teamResource={teamResource} />
      ))}
    </dd>
  );
};

export default observer(TeamBody);
