import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import PlayLectureIcon from '../../../../Cutesies/PlayLectureIcon';
import Loader from '../../../../Loader';
import * as style from './style.module.css';
interface Props {
  kettle: Kettle;
}

@observer
class PlayButton extends React.Component<Props, {}> {
  render() {
    return this.props.kettle.videoState.cata({
      ready: () => (
        <div
          className={style.root}
          onClick={(e) => {
            e.stopPropagation();
            this.props.kettle.play();
          }}
          data-test-play-button={true}
        >
          <div className={style.button}>
            <p className={style.text}>
              <T kind="Start Lecture" />
            </p>
            <div className={style.icon}>
              <PlayLectureIcon />
            </div>
          </div>
        </div>
      ),
      initialized: () => (
        <div className={style.root}>
          <Loader color="black" type="spin" />
        </div>
      ),
      playing: () => <div />,
      paused: () => <div />,
      ended: () => <div />,
      buffering: () => <div />,
    });
  }
}

export default PlayButton;
