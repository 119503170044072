import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

const DocumentIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 25 30">
      <path
        fill={defaultTheme.variables['icon-accent-on-light']}
        d="M0 0v30h25V0H0Zm2.5 2.5h20v25h-20v-25ZM5 8.75v2.5h2.5v-2.5H5Zm5 0v2.5h10v-2.5H10Zm-5 5v2.5h2.5v-2.5H5Zm5 0v2.5h10v-2.5H10Zm-5 5v2.5h2.5v-2.5H5Zm5 0v2.5h10v-2.5H10Z"
      />
    </SVGIcon>
  );
};

export default observer(DocumentIcon);
