import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { L, T } from '../../../../../Translations';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';

function ExperienceActionHint() {
  const { experienceResource } = useExperiencePopoverContext();

  switch (experienceResource.payload.kind) {
    case 'beginnable':
    case 'resumable':
      return (
        <Typography className="hidden" variant="hint">
          <T kind="You have {{count}} day left to complete this program" count={86} />
        </Typography>
      );
    case 'commerce-enrollable':
    case 'enrollable':
    case 'not-enrollable':
    case 'not-licensed':
      return emptyFragment();
    case 'upcoming':
      return (
        <Typography variant="hint">
          <T
            kind="Program starts <date/>"
            date={
              <L localizeable={experienceResource.payload.startOn} format="long-month-day-year" />
            }
          />
        </Typography>
      );
    case 'returnable':
    case 'graduated':
      return (
        <Typography className="hidden" variant="hint">
          <T kind="Yor have {{count}} day left to access this program" count={86} />
        </Typography>
      );
  }
}

export default observer(ExperienceActionHint);
