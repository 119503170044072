import { stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { array, date, field, number, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../Resource/Decoders';
import { alreadyTranslatedText } from '../Translations';
import {
  EmbedToastConferenceRoom,
  EventToast,
  ExternalConferenceRoom,
  GoToWebinarConferenceRoom,
  ReminderToast,
  Toast,
  ToastConferenceRoomResource,
  Toasts,
  ToastsResource,
  ZoomConferenceRoom,
} from './Types';

const externalConferenceRoomDecoder: Decoder<ExternalConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('external-live-meeting'))
);

const embedToastConferenceRoomDecoder: Decoder<EmbedToastConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('embed-toast-live-meeting'))
);

const goToWebinarConferenceRoomDecoder: Decoder<GoToWebinarConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('gtw-live-meeting'))
);

const zoomConferenceRoomDecoder: Decoder<ZoomConferenceRoom> = succeed({}).assign(
  'kind',
  field('kind', stringLiteral('zoom-live-meeting'))
);

const conferenceRoomResourceDecoder: Decoder<ToastConferenceRoomResource> = oneOf<ToastConferenceRoomResource>(
  [
    resourceDecoder(goToWebinarConferenceRoomDecoder).map<ToastConferenceRoomResource>(identity),
    resourceDecoder(zoomConferenceRoomDecoder).map<ToastConferenceRoomResource>(identity),
    resourceDecoder(embedToastConferenceRoomDecoder).map<ToastConferenceRoomResource>(identity),
    resourceDecoder(externalConferenceRoomDecoder).map<ToastConferenceRoomResource>(identity),
  ]
);

const baseToastDecoder = succeed({})
  .assign('activeAt', field('active_at', date))
  .assign('inactiveAt', field('inactive_at', date))
  .assign('endAt', field('end_at', date))
  .assign('startAt', field('start_at', date))
  .assign('title', field('title', alreadyTranslatedText))
  .assign('uuid', field('uuid', string))
  .assign('id', field('id', number))
  .assign('conferenceRoom', field('conference_room', conferenceRoomResourceDecoder));

const eventToastDecoder: Decoder<EventToast> = baseToastDecoder.assign(
  'kind',
  field('kind', stringLiteral('event-notification'))
);

const reminderToastDecoder: Decoder<ReminderToast> = baseToastDecoder.assign(
  'kind',
  field('kind', stringLiteral('reminder-notification'))
);

const toastDecoder: Decoder<Toast> = oneOf<Toast>([
  reminderToastDecoder.map<Toast>(identity),
  eventToastDecoder.map<Toast>(identity),
]);

const toastsDecoder: Decoder<Toasts> = succeed({})
  .assign('expiresAt', field('expires_at', date))
  .assign('toasts', field('notifications', array(toastDecoder)));

export const toastsResourceDecoder: Decoder<ToastsResource> = resourceDecoder(toastsDecoder);
