import * as React from 'react';
import { LectureSegment as Lecture } from '../../../LectureStore/Types';
import SegmentStore from '../../../SegmentStore';
import { SegmentResource } from '../../../SegmentStore/Types';
import AssignmentDueSegment from '../AssignmentDueSegment';
import LectureSegment from '../LectureSegment';
import OverviewSegment from '../OverviewSegment';
import PresentationSegment from '../PresentationSegment';
import SurveySegment from '../SurveySegment';
import TeamDiscussionSegment from '../TeamDiscussionSegment';
import ExternalProgramSegment from '../ExternalProgramSegment';
import DetailViewStore from '../ExternalProgramSegment/DetailViewStore';

interface Props {
  store: SegmentStore;
  segmentResource: SegmentResource;
}

const SegmentDetail: React.FC<Props> = ({ store, segmentResource }) => {
  const detailViewStore = new DetailViewStore();
  switch (segmentResource.payload.type) {
    case 'overview': {
      return (
        <OverviewSegment
          segmentStore={store}
          segment={segmentResource.payload}
          links={segmentResource.links}
        />
      );
    }
    case 'presentation': {
      return <PresentationSegment segmentStore={store} segment={segmentResource.payload} />;
    }
    case 'lecture': {
      const lecture: Lecture = segmentResource.payload;
      return <LectureSegment segmentStore={store} segment={lecture} />;
    }
    case 'survey': {
      return <SurveySegment segmentStore={store} />;
    }
    case 'assignment-due': {
      return (
        <AssignmentDueSegment
          segmentStore={store}
          segment={segmentResource.payload}
          links={segmentResource.links}
        />
      );
    }
    case 'team-discussion': {
      return <TeamDiscussionSegment segmentStore={store} segment={segmentResource.payload} />;
    }
    case 'external-program':
      return (
        <ExternalProgramSegment
          segment={segmentResource.payload}
          detailViewStore={detailViewStore}
        />
      );
  }
};

export default SegmentDetail;
