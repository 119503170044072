import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { useExperienceContext } from '../../../Experience/ExperienceContext';
import ExperienceBodyContent from './ExperienceBodyContent';

function ExperienceBody() {
  const { experienceResource } = useExperienceContext();

  return (
    <>
      <Typography
        className="mb-3 cursor-pointer"
        variant="subtitleMedium"
        as="h5"
        fontWeight="medium"
      >
        <AlreadyTranslated content={experienceResource.payload.title} />
      </Typography>
      <div className="flex flex-col gap-5">
        <ExperienceBodyContent experienceResource={experienceResource} />
      </div>
    </>
  );
}

export default observer(ExperienceBody);
