import React from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { BackgroundImageProps } from './Types';

const BackgroundImage: React.FC<BackgroundImageProps> = ({ src, className, children }) => {
  const backgroundImageStyle = { backgroundImage: `url("${src}")` };

  return (
    <div className="relative z-0 box-border flex flex-1 flex-col">
      <div className="absolute inset-0 z-[-1] overflow-hidden">
        <div
          className={twMerge(
            clsx('absolute inset-0 flex flex-1 bg-cover bg-center bg-no-repeat', className),
          )}
          style={{ ...backgroundImageStyle }}
        />
      </div>
      {children}
    </div>
  );
};

export default BackgroundImage;
