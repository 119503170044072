import { observer } from 'mobx-react';
import { useRef } from 'react';
import { LoginReactions } from './Reactions';
import { LoginStore } from './Store';
import LoginView from './Views/LoginView';

function Login() {
  const store = useRef(new LoginStore());

  return (
    <>
      <LoginView store={store.current} />
      <LoginReactions store={store.current} />
    </>
  );
}

export default observer(Login);
