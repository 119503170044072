import { action, observable } from 'mobx';

interface Expanded {
  kind: 'expanded';
}

interface Collapsed {
  kind: 'collapsed';
}

interface Unset {
  kind: 'unset';
}

export type State = Expanded | Collapsed | Unset;

class NoteDetailStore {
  @observable
  state: State = {
    kind: 'unset',
  };

  @action
  expand = () => {
    this.state = { kind: 'expanded' };
  };

  @action
  collapse = () => {
    this.state = { kind: 'collapsed' };
  };
}

export default NoteDetailStore;
