import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { profileStore } from '../../../../../../ProfileStore';
import { T } from '../../../../../../Translations';

function UpdateFocusModalHeader({ children }: PropsWithChildren<{}>) {
  return (
    <>
      {profileStore.personStoreContract
        .andThen((info) => info.shortName)
        .map((shortName) => (
          <Typography variant="subtitleMedium" as="h5">
            <>
              <T kind="Welcome {{username}}" username={shortName} />
              <span>,</span>
            </>
          </Typography>
        ))
        .getOrElse(emptyFragment)}
      <Typography className="mt-2" variant="h5" as="p">
        {children}
      </Typography>
    </>
  );
}

export default observer(UpdateFocusModalHeader);
