import { parseIntM } from '@execonline-inc/numbers';
import { _VimeoPlayer } from 'kettle-corn/VimeoPlayer';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Props as VideoProps } from './index';
import NoPlayer from './NoPlayer';
import ReqHlsAdaptivePlayer from './ReqHlsAdaptivePlayer';
import ReqHlsProgressivePlayer from './ReqHlsProgressivePlayer';
import { SelectedPlayer } from './Types';

interface Props extends VideoProps {
  selectedPlayer: SelectedPlayer;
}

const ReqHlsRenderedPlayer: React.FC<Props> = (props) => {
  switch (props.selectedPlayer.kind) {
    case 'undetermined':
      return <></>;
    case 'vimeo':
      const vimeoId = props.selectedPlayer.vimeoId;
      return parseIntM(props.height.toString())
        .map<JSX.Element>((height) => (
          <_VimeoPlayer
            id={props.id}
            className={props.className}
            videoId={vimeoId}
            kettle={props.kettle}
            height={height}
          />
        ))
        .getOrElse(() => <></>);
    case 'progressive-low':
    case 'progressive':
      return (
        <ReqHlsProgressivePlayer
          id={props.id}
          className={props.className}
          videoResource={props.videoResource}
          kettle={props.kettle}
          width={props.width}
          height={props.height}
          dynamicallySetAspectRatio={props.dynamicallySetAspectRatio}
          sources={props.selectedPlayer.sources}
        />
      );
    case 'adaptive':
      return (
        <ReqHlsAdaptivePlayer
          id={props.id}
          className={props.className}
          videoResource={props.videoResource}
          kettle={props.kettle}
          width={props.width}
          height={props.height}
          dynamicallySetAspectRatio={props.dynamicallySetAspectRatio}
          adaptiveFile={props.selectedPlayer.file}
          fallbackSources={props.selectedPlayer.fallbackSources}
        />
      );
    case 'no-selection':
      return <NoPlayer videoResource={props.videoResource} />;
  }
};

export default observer(ReqHlsRenderedPlayer);
