import {
  AnySlide,
  VisibleSlide,
  HiddenSlide,
  LectureSlideResource,
} from '../../LectureStore/Types';
import { Resource, resource } from '../../Resource/Types';

const visibleSlide = (anySlide: AnySlide, visiblePosition: number): VisibleSlide => ({
  ...anySlide,
  visible: true,
  visiblePosition,
});

const hiddenSlide = (anySlide: AnySlide): HiddenSlide => ({
  ...anySlide,
  visible: false,
});

interface LectureSlideAccumulator {
  hiddenSlides: number;
  accum: LectureSlideResource[];
}

const lectureSlideAccumulator = (
  hiddenSlides: number,
  accum: LectureSlideResource[]
): LectureSlideAccumulator => ({
  hiddenSlides,
  accum,
});

const accumulateVisibleSlide = (
  { hiddenSlides, accum }: LectureSlideAccumulator,
  { links, payload }: Resource<AnySlide>
): LectureSlideAccumulator => {
  const visiblePosition = Math.max(0, payload.absolutePosition - hiddenSlides);
  return lectureSlideAccumulator(hiddenSlides, [
    ...accum,
    resource(links, visibleSlide(payload, visiblePosition)),
  ]);
};

const accumulateHiddenSlide = (
  { hiddenSlides, accum }: LectureSlideAccumulator,
  { links, payload }: Resource<AnySlide>
): LectureSlideAccumulator => {
  return lectureSlideAccumulator(hiddenSlides + 1, [
    ...accum,
    resource(links, hiddenSlide(payload)),
  ]);
};

const accumulateLectureSlides = (accum: LectureSlideAccumulator, slide: Resource<AnySlide>) =>
  slide.payload.visible
    ? accumulateVisibleSlide(accum, slide)
    : accumulateHiddenSlide(accum, slide);

export const identifyVisibility = (slides: Resource<AnySlide>[]) =>
  slides
    .sort((a, b) => a.payload.absolutePosition - b.payload.absolutePosition)
    .reduce(accumulateLectureSlides, lectureSlideAccumulator(0, [])).accum;
