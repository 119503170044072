import { createContext } from 'react';

export type PreviewPlatformMode = 'preview';
export type DefaultPlatformMode = 'default';

export type PlatformMode = PreviewPlatformMode | DefaultPlatformMode;

export const PreviewModeContext = createContext<PlatformMode>('default');

export const PreviewModeProvider = PreviewModeContext.Provider;
