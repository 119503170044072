import { just, Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren, useCallback, useState } from 'react';
import { ExperienceResources } from '../../../../../Common/Experience/Types';
import FilterStore from '../../FilterStore';
import { ExperienceFiltersContext } from './Types';

function ExperienceFiltersProvider({ children }: PropsWithChildren<{}>) {
  const [filterStore, setFilterStore] = useState<Maybe<FilterStore>>(nothing());

  const initializeFilters = (store: FilterStore) => {
    setFilterStore(just(store));
  };

  const filterExperienceResources = useCallback(
    (experienceResources: ExperienceResources): ExperienceResources => {
      return filterStore
        .map<ExperienceResources>((store) => {
          return experienceResources.filter(store.showFilteredExperience);
        })
        .getOrElseValue(experienceResources);
    },
    [filterStore.andThen((store) => store.appliedCount())],
  );

  return (
    <ExperienceFiltersContext.Provider
      value={{
        initializeFilters,
        filterExperienceResources,
      }}
    >
      {children}
    </ExperienceFiltersContext.Provider>
  );
}

export default observer(ExperienceFiltersProvider);
