import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  description: Maybe<AlreadyTranslatedText>;
}

function GroupCoachSessionDescription({ description }: Props) {
  return description
    .map((value) => (
      <Typography variant="caption">
        <AlreadyTranslated content={value} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachSessionDescription);
