import * as React from 'react';
import { observer } from 'mobx-react';
import { defaultTheme } from '../../../Themes';
import SVGIcon from '../SVGIcon';

interface Props {
  fillColor: IconFillColor;
}

export type IconFillColor =
  | 'icon-on-dark'
  | 'icon-standard-on-dark'
  | 'icon-on-light'
  | 'icon-standard-on-light';

const LanguageIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SVGIcon viewBox="0 0 21 14">
      <path
        fill={defaultTheme.variables[fillColor]}
        d="M18.5 0a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16.5ZM6.812 3H5.187L2 11h1.875l.594-1.656h3.062L8.125 11H10L6.812 3Zm8.375 0h-1.375v1.344H11v1.312h5.5c-.258 1.59-1.114 2.711-2.438 3.344-.902-.515-1.656-1.824-1.656-2.562H11c0 .976.711 2.414 1.469 3.093-.422.086-1.086.125-1.469.125V11c.852 0 2.055-.176 3-.531a7.722 7.722 0 0 0 2.844.531V9.656c-.391 0-.75-.039-1.094-.094 1.27-.879 2.047-2.172 2.25-3.906V4.344h-2.813V3ZM6 5.031l-1.063 3h2.125L6 5.031Z"
      />
    </SVGIcon>
  );
};

export default observer(LanguageIcon);
