import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import CommunityProgramOptionsStore from '.';
import { AppyError, callApi } from '../../../../Appy';
import { CurrentUserResource } from '../../../../CurrentUser/Types';
import { handleError } from '../../../../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../../../../LinkyLinky';
import ReactionComponent, { RCProps } from '../../../../ReactionComponent';
import { Link } from '../../../../Resource/Types';
import { teamsProgramsOptionsResourceDecoder } from './Decoders';
import { CoachTeamsState } from './Types';

interface Props extends RCProps<CommunityProgramOptionsStore> {
  store: CommunityProgramOptionsStore;
  resourceWithTeams: CurrentUserResource;
}

export type TeamsReactionError = MissingLinkError | AppyError;

type TeamRequestError = MissingLinkError | AppyError;

export const handleTeamRequestError =
  (store: CommunityProgramOptionsStore) => (error: TeamRequestError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Team link is unavailable');
        break;
      default:
        handleError(store, error);
    }
  };

class CommunityProgramOptionsReactions extends ReactionComponent<
  CommunityProgramOptionsStore,
  CoachTeamsState,
  Props
> {
  tester = () => this.props.store.state;
  effect = (state: CoachTeamsState) => {
    const { store } = this.props;
    switch (state.kind) {
      case 'loading':
        Task.succeed<TeamsReactionError, ReadonlyArray<Link>>(this.props.resourceWithTeams.links)
          .andThen(findLinkT('coach-team-options'))
          .andThen(callApi(teamsProgramsOptionsResourceDecoder, {}))
          .fork(handleTeamRequestError(store), store.ready);
        break;
      case 'ready':
      case 'error':
      case 'waiting':
        break;
      default:
        assertNever(state);
    }
  };
}

export default observer(CommunityProgramOptionsReactions);
