import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { error } from '../ErrorHandling';
import { errorAlert, FlashAlert } from '../Notifications/Types';
import { TPlainTextKey } from '../Translations';
import { Upload, Uploads } from '../Uploads';
import {
  AttachableSegment,
  AttachmentUploadState,
  loading,
  ready,
  readyWithoutUpload,
  removingUpload,
  uploadSuccessful,
  waiting,
} from './Types';

class AttachmentUploadStore {
  @observable
  state: AttachmentUploadState;

  constructor() {
    this.state = waiting();
  }

  @computed
  get uploadsToSubmit(): Maybe<Uploads> {
    switch (this.state.kind) {
      case 'upload-successful':
        return this.state.uploadsToSubmit;
      case 'ready':
      case 'removing-upload':
        return just(this.state.uploadsToSubmit);
      case 'ready-without-upload':
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready': {
        this.state = loading();
        break;
      }

      case 'ready-without-upload':
      case 'upload-successful':
      case 'removing-upload':
      case 'error':
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (uploadsToSubmit: Uploads) => {
    switch (this.state.kind) {
      case 'upload-successful':
      case 'removing-upload': {
        this.state = ready(this.state.segment, uploadsToSubmit);
        break;
      }
      case 'waiting':
      case 'ready':
      case 'loading':
      case 'ready-without-upload':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithoutUpload = (segment: AttachableSegment) => {
    switch (this.state.kind) {
      case 'removing-upload':
      case 'loading':
        this.state = readyWithoutUpload(segment);
        break;
      case 'ready':
      case 'upload-successful':
      case 'waiting':
      case 'ready-without-upload':
      case 'error': {
        break;
      }
      default:
        assertNever(this.state);
    }
  };

  @action
  uploadSuccessful = (uploads: Uploads) => {
    switch (this.state.kind) {
      case 'ready-without-upload':
        this.state = uploadSuccessful(this.state.segment, nothing(), uploads);
        break;
      case 'ready':
        this.state = uploadSuccessful(
          this.state.segment,
          just(this.state.uploadsToSubmit),
          uploads
        );
        break;
      case 'waiting':
      case 'loading':
      case 'upload-successful':
      case 'removing-upload':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  removingUpload = (uploadToRemove: Upload) => {
    switch (this.state.kind) {
      case 'ready': {
        this.state = removingUpload(this.state.segment, this.state.uploadsToSubmit, uploadToRemove);
        break;
      }
      case 'waiting':
      case 'ready-without-upload':
      case 'loading':
      case 'upload-successful':
      case 'removing-upload':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'waiting':
      case 'ready-without-upload':
      case 'loading':
      case 'upload-successful':
      case 'removing-upload':
        return nothing();
    }
  }
}

export default AttachmentUploadStore;
