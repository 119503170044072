import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../LinkyLinky';
import NotesStore from '../../NotesStore';
import ProgramStore from '../../ProgramStore';
import SegmentStore from '../../SegmentStore';
import ProgramSegmentReactions from '../../SegmentStore/ProgramSegmentReactions';
import TabStore, { ActiveTab } from '../../TabStore';
import PositionContext from '../PositionContext';
import ActiveTabPane from './ActiveTabPane';
import ProgressSection from './ProgressSection';
import Tabs from './Tabs';
import TitleSection from './TitleSection';

interface Props {
  segmentStore: SegmentStore;
  programStore: ProgramStore;
}

@observer
class SegmentPanel extends React.Component<Props, {}> {
  activeTab = findLink('notes', this.props.segmentStore.links)
    .map<ActiveTab>(() => 'notes')
    .getOrElseValue('outline');
  tabStore = new TabStore(this.activeTab);
  notesStore = new NotesStore();

  render() {
    return (
      <PositionContext.Provider value={{ kind: 'supPanel' }}>
        <TitleSection resource={this.props.programStore.programDetailResource} />
        <ProgressSection programStore={this.props.programStore} />
        <Tabs
          tabStore={this.tabStore}
          notesStore={this.notesStore}
          segmentStore={this.props.segmentStore}
        />
        <ActiveTabPane
          segmentResource={this.props.segmentStore.segmentResource}
          tabStore={this.tabStore}
          programDetailResource={this.props.programStore.programDetailResource}
          notesStore={this.notesStore}
          segmentStore={this.props.segmentStore}
        />
        <ProgramSegmentReactions
          fireImmediately={true}
          store={this.props.segmentStore}
          programStore={this.props.programStore}
        />
      </PositionContext.Provider>
    );
  }
}

export default SegmentPanel;
