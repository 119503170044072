import { observer } from 'mobx-react';
import * as React from 'react';
import TabStore from '../../../TabStore';
import { T } from '../../../Translations';
import OverviewTabIcon from '../../Cutesies/OverviewTabIcon';
import * as style from '../style.module.css';

interface Props {
  tabStore: TabStore;
}

const OutlineTab: React.FC<Props> = ({ tabStore }) => {
  const handleClick = () => {
    tabStore.setActiveTab('outline');
  };

  return (
    <button
      className={`${style.tab} ${tabStore.activeTab === 'outline' ? style.active : ''}`}
      onClick={handleClick}
      data-test-outline-tab={true}
    >
      <span className={style.tabIcon}>
        <OverviewTabIcon />
      </span>
      <span className={style.tabLabel} data-test-tab-title="Overview">
        <T kind="Overview" />
      </span>
    </button>
  );
};
export default observer(OutlineTab);
