import * as React from 'react';

interface Props {
  level: 1 | 2 | 3 | 4 | 5 | 6 | null;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const DynamicHeading: React.FC<Props> = ({ level, children, ...rest }) => {
  const Heading = level ? (`h${level}` as const) : 'span';

  return <Heading {...rest}>{children}</Heading>;
};

export default DynamicHeading;
