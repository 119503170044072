import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { T, TPlainTextKey } from '../../Translations';
import TeamArrowIcon from '../Cutesies/TeamArrowIcon';
import * as style from '../TeamProfile/style.module.css';
import BreadCrumbLink from './BreadCrumbLink';
import { MenuItemContent } from './TeamBreadCrumbLink';

interface Props {
  menuItemContent: MenuItemContent;
  teamLink: TPlainTextKey;
  teamId: number;
  teamName: string;
}

const TeamBreadCrumbLinkContent: React.FC<Props> = ({
  menuItemContent,
  teamLink,
  teamId,
  teamName,
}) => (
  <>
    <Link to={menuItemContent.to} className={style.newButton}>
      <T kind={teamLink} />
      <div className={style.buttonIcon}>
        <TeamArrowIcon />
      </div>
    </Link>
    <Link to={`/teams/${teamId}`} className={style.newButton}>
      <BreadCrumbLink teamName={teamName} teamLink={teamLink} />
    </Link>
  </>
);

export default observer(TeamBreadCrumbLinkContent);
