import { observer } from 'mobx-react';
import React from 'react';
import ScheduledToastStore from '../../ToastsStore/ScheduledToastStore';
import ReminderNotificationToast from './ReminderNotificationToast';

interface Props {
  reminderNotifications: ReadonlyArray<ScheduledToastStore>;
}

const ReminderNotificationsToast: React.FC<Props> = ({ reminderNotifications }) => (
  <>
    {reminderNotifications.map((store) => (
      <ReminderNotificationToast store={store} key={store.scheduledToast.id} />
    ))}
  </>
);

export default observer(ReminderNotificationsToast);
