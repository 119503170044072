import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../../../CurrentUser/Context/WithCurrentUser';
import { RegisterResource } from '../../../DiscoveryPortal/RegisterResourceStore/Types';
import RegistrationFormHeader from '../../../RegistrationFormHeader';
import RegistrationView from '../../../RegistrationView';
import Instructions from './Instructions';
import TermsAndConditionsReactions from './Reactions';
import TermsAndConditionsStore from './Store';
import TermsAndConditionsView from './TermsAndConditionsView';

interface Props {
  registerResource: RegisterResource;
}

class TermsAndConditions extends React.Component<Props> {
  store = new TermsAndConditionsStore();

  componentDidMount() {
    this.store.loading();
  }

  render() {
    const { registerResource } = this.props;
    return (
      <>
        <RegistrationView allowLanguagePicker={true}>
          <RegistrationFormHeader
            notifiableStore={this.store}
            sharedInvitationResource={registerResource.payload.sharedInvitation}
          >
            <Instructions />
          </RegistrationFormHeader>
          <TermsAndConditionsView store={this.store} />
        </RegistrationView>
        <WithCurrentUser
          children={(currentUserResource) => (
            <TermsAndConditionsReactions
              store={this.store}
              currentUserResource={currentUserResource}
            />
          )}
        />
      </>
    );
  }
}

export default observer(TermsAndConditions);
