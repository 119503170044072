import { observer } from 'mobx-react';
import { GroupCoachingProductDetails } from '../../../Common/Experience/Types';
import { ExperienceDetailsSection, ExperienceDetailsSectionHeader } from '../../Common';
import GroupCoachingSessions from './GroupCoachingSessions';

interface Props {
  productDetails: GroupCoachingProductDetails;
}

function LearnMoreGroupCoachingDetails({ productDetails }: Props) {
  return (
    <div className="box-border flex flex-col bg-white px-4 py-12 sm:p-12">
      <ExperienceDetailsSection>
        <ExperienceDetailsSectionHeader
          title="Included Sessions"
          description="Once you elect to enroll in this group coaching package, you will be sent..."
        />
        <GroupCoachingSessions productDetails={productDetails} />
      </ExperienceDetailsSection>
    </div>
  );
}

export default observer(LearnMoreGroupCoachingDetails);
