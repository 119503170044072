import React from 'react';
import { observer } from 'mobx-react';
import { Chip, Typography } from '@execonline-inc/execonline-ui';

interface Props {
  children: React.ReactNode;
}

function ExperienceProductLabel({ children }: Props) {
  return (
    <Chip className="bg-[#F9F9F9]">
      <Typography className="flex items-center gap-x-2 text-black" variant="caption">
        {children}
      </Typography>
    </Chip>
  );
}

export default observer(ExperienceProductLabel);
