import { Typography, emptyFragment, useMediaQuery } from '@execonline-inc/execonline-ui';
import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import { T, TPlainTextKey } from '../../../../../Translations';
import { getDescriptionVariant, getTitleVariant } from './Types';

interface Props {
  title: TPlainTextKey;
  subTitle?: TPlainTextKey;
  description?: TPlainTextKey;
}

function ExperienceDetailsSectionHeader({ title, subTitle, description }: Props) {
  const breakPoint = useMediaQuery();

  return (
    <div className="flex flex-col">
      <Typography variant={getTitleVariant(breakPoint)} fontWeight="bold" color="black" as="h5">
        <T kind={title} />
      </Typography>
      {fromNullable(subTitle)
        .map((value) => (
          <Typography className="mt-2 text-black" variant="bodyMedium" as="p">
            <T kind={value} />
          </Typography>
        ))
        .getOrElse(emptyFragment)}
      {fromNullable(description)
        .map((value) => (
          <Typography
            className="mt-4 text-black sm:mt-6"
            variant={getDescriptionVariant(breakPoint)}
            as="p"
          >
            <T kind={value} />
          </Typography>
        ))
        .getOrElse(emptyFragment)}
    </div>
  );
}

export default observer(ExperienceDetailsSectionHeader);
