import { fromEmpty } from 'maybeasy';
import { SchoolPartnerResource } from '../../../../Common/Experience/SchoolPartner';
import { ExperienceFiltersResource } from '../../../../Common/Experience/Types';
import { FilterOption } from '../CommonFilter/Types';

export type SchoolPartnerFilterOptions = SomeSchoolPartners | AllSchoolPartners | NoSchoolPartners;

export type SomeSchoolPartners = FilterOption & {
  kind: 'some-school-partners';
};

export type AllSchoolPartners = FilterOption & {
  kind: 'All';
  value: 'All';
};

export type NoSchoolPartners = FilterOption & {
  kind: 'no-school-partners';
  value: 'None';
};

const noSchoolPartner = (): SchoolPartnerFilterOptions => ({
  kind: 'no-school-partners',
  label: 'None',
  value: 'None',
});

const someSchoolPartner = (resource: SchoolPartnerResource): SchoolPartnerFilterOptions => ({
  kind: 'some-school-partners',
  label: resource.payload.name.text,
  value: resource.payload.name.text,
});

const schoolPartnerFilterObject = (resource: SchoolPartnerResource): SchoolPartnerFilterOptions =>
  fromEmpty(resource.payload.name.text)
    .map(() => someSchoolPartner(resource))
    .getOrElse(noSchoolPartner);

const getSchoolPartnerOptions = (
  options: ExperienceFiltersResource,
): SchoolPartnerFilterOptions[] => options.payload.schoolPartners.map(schoolPartnerFilterObject);

export const allSchoolPartnerOptions = (
  options: ExperienceFiltersResource,
): SchoolPartnerFilterOptions[] => [...getSchoolPartnerOptions(options)];
