import { observer } from 'mobx-react';
import * as React from 'react';
import { findLink } from '../../../LinkyLinky';
import { T } from '../../../Translations';
import ResourceForAssignmentIcon from '../../Cutesies/ResourceForAssignmentIcon';
import ResourceLearningPartnerIcon from '../../Cutesies/ResourceLearningPartnerIcon';
import ResourceLiveMeetingRecordingIcon from '../../Cutesies/ResourceLiveMeetingRecordingIcon';
import ResourceUploadedByYouIcon from '../../Cutesies/ResourceUploadedByYouIcon';
import ResourcesIcon from '../../Cutesies/ResourcesIcon';
import ResourcesImportantIcon from '../../Cutesies/ResourcesImportantIcon';
import ModalStore from '../../Modal/Store';
import TopLevelResources from '../TopLevelResources';
import { ResourcesDashboardResource } from '../Types';
import * as style from '../style.module.css';
import CategoryButton from './CategoryButton';

interface Props {
  resource: ResourcesDashboardResource;
}

class ResourcesDashboardView extends React.Component<Props> {
  modalStore: ModalStore = new ModalStore();

  render() {
    const { resource } = this.props;
    const importantResources = resource.payload.resourceResources;

    return (
      <>
        <section className={style.section}>
          <h1>
            <T kind="Resources" />
          </h1>
          <p className={style.resourcesSubTitle}>
            <T kind="Search or browse for resources" />
          </p>
        </section>
        <section className={style.section}>
          <h2 className={style.header}>
            <T kind="Browse by category" />
          </h2>
          <div className={style.categoryButtons}>
            {findLink('my-personalized-resources', resource.links)
              .map(() => (
                <CategoryButton
                  description="Personalized To You"
                  href="/resources/personalized_to_you"
                >
                  {{
                    icon: <ResourcesImportantIcon />,
                  }}
                </CategoryButton>
              ))
              .getOrElseValue(<></>)}
            {findLink('my-uploaded-resources', resource.links)
              .map(() => (
                <CategoryButton description="Uploaded By You" href="/resources/uploaded_by_you">
                  {{
                    icon: <ResourceUploadedByYouIcon />,
                  }}
                </CategoryButton>
              ))
              .getOrElseValue(<></>)}
            {findLink('learning-partner-resources', resource.links)
              .map(() => (
                <CategoryButton
                  description="Shared By A Learning Partner"
                  href="/resources/uploaded_by_learning_partner"
                >
                  {{
                    icon: <ResourceLearningPartnerIcon />,
                  }}
                </CategoryButton>
              ))
              .getOrElseValue(<></>)}
            {findLink('needed-for-assignment-resources', resource.links)
              .map(() => (
                <CategoryButton
                  description="Needed For Assignments"
                  href="/resources/needed_for_assignments"
                >
                  {{
                    icon: <ResourceForAssignmentIcon />,
                  }}
                </CategoryButton>
              ))
              .getOrElseValue(<></>)}
            <CategoryButton description="By Programs" href="/resources/programs">
              {{
                icon: <ResourcesIcon fill="#FFF" />,
              }}
            </CategoryButton>
            {findLink('live-meeting-recording-resources', resource.links)
              .map(() => (
                <CategoryButton
                  description="Live Meeting Recordings"
                  href="/resources/live_meeting_recordings"
                >
                  {{
                    icon: <ResourceLiveMeetingRecordingIcon />,
                  }}
                </CategoryButton>
              ))
              .getOrElseValue(<></>)}
          </div>
        </section>
        <section className={style.section}>
          <h2 className={style.header}>
            <T kind="Important Resources" />
          </h2>
          <TopLevelResources resources={importantResources} />
        </section>
      </>
    );
  }
}

export default observer(ResourcesDashboardView);
