import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { first } from '@execonline-inc/collections';
import { getConflictAvailability } from '../../../../Common/Experience/Availability';
import { AvailabilityResources } from '../../../../Common/Experience/Types';
import NoAvailability from './NoAvailability';

interface Props {
  className: string;
  availabilities: AvailabilityResources;
}

function NotEnrollableAction({ className, availabilities }: Props) {
  return first(availabilities)
    .andThen(getConflictAvailability)
    .cata({
      Just: (conflict) => <NoAvailability conflict={conflict} className={className} />,
      Nothing: emptyFragment,
    });
}

export default observer(NotEnrollableAction);
