import { observer } from 'mobx-react';
import { ComponentProps, ReactElement } from 'react';
import { findLink } from '../LinkyLinky';
import { Link, Rel } from '../Resource/Types';
import When from '../When';

interface WhenLinkProps {
  rel: Rel;
  links: ReadonlyArray<Link>;
  children: ReactElement | ((link: Link) => ReactElement);
  otherwise?: ComponentProps<typeof When>['otherwise'];
}

function WhenLink({ rel, links, children, otherwise }: WhenLinkProps) {
  return <When predicate={findLink(rel, links)} children={children} otherwise={otherwise} />;
}

export default observer(WhenLink);
