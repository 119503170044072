import { observable, action } from 'mobx';
import { StaffRequestState, requested, ready } from './Types';

class StaffRequestStore {
  @observable
  state: StaffRequestState = ready();

  @action
  ready = () => {
    this.state = ready();
  };

  @action
  request = () => {
    this.state = requested();
  };
}

export default StaffRequestStore;
