import { when } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { UseCaseIdentifier } from '../../../Preview/Types';
import { AutoLaunchableContext } from '../../../components/AutoLaunchable/AutoLaunchableContext';
import PageLayoutWrapper from '../../PageLayoutWrapper';
import DiscoveryPortalImpl from './DiscoveryPortalImpl';
import RegisterResourceStore from './RegisterResourceStore';
import RegisterResourceReactions from './RegisterResourceStore/RegisterResourceReactions';
import WhenRegisterResource from './RegisterResourceStore/WhenRegisterResource';

interface Props {
  params: Maybe<URLSearchParams>;
  identifier: UseCaseIdentifier;
}

class DiscoveryPortal extends React.Component<Props> {
  registerResourceStore = new RegisterResourceStore();

  whenUseCaseIdentifierChanged = (
    previous: UseCaseIdentifier,
    current: UseCaseIdentifier,
  ): Maybe<UseCaseIdentifier> => {
    switch (current.kind) {
      case 'use-case-uuid':
        return when(previous.kind !== current.kind || previous.uuid !== current.uuid, current);
      case 'use-case-id':
        return when(previous.kind !== current.kind || previous.id !== current.id, current);
    }
  };

  loadUseCase = (identifier: UseCaseIdentifier) => {
    switch (identifier.kind) {
      case 'use-case-uuid':
        this.registerResourceStore.loading(identifier.uuid);
        break;
      case 'use-case-id':
        this.registerResourceStore.loadingPreview(identifier.id);
        break;
      default:
        assertNever(identifier);
    }
  };

  componentDidMount() {
    this.loadUseCase(this.props.identifier);
  }

  componentDidUpdate(prevProps: Props) {
    this.whenUseCaseIdentifierChanged(prevProps.identifier, this.props.identifier).do(
      this.loadUseCase,
    );
  }

  render() {
    return (
      <AutoLaunchableContext.Provider value="discovery_portal">
        <RegisterResourceReactions
          store={this.registerResourceStore}
          params={this.props.params}
          fireImmediately
        />
        <PageLayoutWrapper>
          <WhenRegisterResource store={this.registerResourceStore}>
            {(registerResource) => {
              switch (this.props.identifier.kind) {
                case 'use-case-uuid':
                  return (
                    <DiscoveryPortalImpl
                      registerResource={registerResource}
                      registerResourceStore={this.registerResourceStore}
                    />
                  );
                case 'use-case-id':
                  return (
                    <DiscoveryPortalImpl
                      registerResource={registerResource}
                      registerResourceStore={this.registerResourceStore}
                    />
                  );
              }
            }}
          </WhenRegisterResource>
        </PageLayoutWrapper>
      </AutoLaunchableContext.Provider>
    );
  }
}

export default observer(DiscoveryPortal);
