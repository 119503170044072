import { observer } from 'mobx-react';
import * as React from 'react';
import Notifications from '../../Notifications';
import NotificationSource from '../../NotificationSource';
import EditPasswordStore from '../../EditPasswordStore';
import EditPasswordReactions from '../../EditPasswordStore/EditPasswordReactions';
import CenteredLogo from '../CenteredLogo';
import WithBackgroundImage from '../WithBackgroundImage';
import EditPasswordRenderer from './EditPasswordRenderer';

interface Params {
  token: string;
}

interface Props {
  match: { params: Params };
}

@observer
class EditPassword extends React.Component<Props, {}> {
  params = this.props.match.params;
  editPasswordStore = new EditPasswordStore();

  componentDidMount() {
    this.editPasswordStore.loading();
  }

  render() {
    return (
      <>
        <WithBackgroundImage>
          <CenteredLogo />
          <Notifications />
          <EditPasswordRenderer store={this.editPasswordStore} />
        </WithBackgroundImage>

        <EditPasswordReactions store={this.editPasswordStore} params={this.props.match.params} />
        <NotificationSource store={this.editPasswordStore} />
      </>
    );
  }
}

export default EditPassword;
