import { emptyFragment, ScrollBox } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { ExperienceHtmlView } from '../../../ExperienceDetailView/Common';
import { PromotableExperienceResource } from '../Types';

interface Props {
  experienceResource: PromotableExperienceResource;
}

function PromotableExperienceTileDescription({ experienceResource }: Props) {
  return experienceResource.payload.promotableMarketingCopy
    .map((description) => (
      <ScrollBox className="h-40 sm:h-32">
        <ExperienceHtmlView data={description} />
      </ScrollBox>
    ))
    .getOrElse(emptyFragment);
}

export default observer(PromotableExperienceTileDescription);
