import { toTask } from '@execonline-inc/maybe-adapter';
import { useConst } from '@execonline-inc/react-hooks.private';
import { noop } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import SegmentReactions from '../../../../../../components/SegmentReactions';
import SegmentStore from '../../../../../../SegmentStore';
import { Segment, SegmentResource, UponCompletion } from '../../../../../../SegmentStore/Types';
import UpdateFocusModalContent from './UpdateFocusModalContent';

interface Props {
  segment: Segment;
  onClose: (uponCompletion: Maybe<UponCompletion>) => void;
}

function UpdateFocusModalView({ segment, onClose }: Props) {
  const { id, programTitle, moduleId, programId } = segment;
  const segmentStore = useConst(() => new SegmentStore('Streamlined'));

  const handleClose = () => {
    onClose(segmentStore.uponCompletion);
  };

  const params = useMemo(
    () => ({
      programId: String(programId),
      moduleId: String(moduleId),
      segmentId: String(id),
    }),
    [programId, moduleId, id],
  );

  const handleAdvanceHook = (nextSegment: Maybe<SegmentResource>) => {
    toTask<string, SegmentResource>('segment-error')(nextSegment).fork(handleClose, noop);
  };

  return (
    <>
      <UpdateFocusModalContent programTitle={programTitle} segmentStore={segmentStore} />
      <SegmentReactions
        params={params}
        store={segmentStore}
        advanceHook={handleAdvanceHook}
        fireImmediately
      />
    </>
  );
}

export default observer(UpdateFocusModalView);
