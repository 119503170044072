import * as React from 'react';
import { T } from '../../Translations';

const TableHeader: React.FC<{}> = () => (
  <thead>
    <tr>
      <th>
        <T kind="Name" />
      </th>
      <th>
        <T kind="Organization" />
      </th>
      <th>
        <T kind="Division" />
      </th>
      <th>
        <T kind="Position" />
      </th>
      <th>
        <T kind="Location" />
      </th>
      <th>
        <T kind="Time Zone" />
      </th>
      <th>
        <T kind="Email" />
      </th>
    </tr>
  </thead>
);

export default TableHeader;
