import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const CalendarIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 18 19">
      <path
        fill={defaultTheme.variables['info-bg-danger']}
        d="M3.273 0v.783H0V18.02h18V.783h-3.273V0h-1.636v.783H4.909V0H3.273ZM1.636 2.35h1.637v.784h1.636V2.35h8.182v.784h1.636V2.35h1.637v1.567H1.636V2.35Zm0 3.134h14.728v10.969H1.636V5.484Zm4.91 1.567v1.567h1.636V7.051H6.545Zm3.272 0v1.567h1.637V7.051H9.818Zm3.273 0v1.567h1.636V7.051h-1.636Zm-9.818 3.134v1.567h1.636v-1.567H3.273Zm3.272 0v1.567h1.637v-1.567H6.545Zm3.273 0v1.567h1.637v-1.567H9.818Zm3.273 0v1.567h1.636v-1.567h-1.636ZM3.273 13.32v1.567h1.636V13.32H3.273Zm3.272 0v1.567h1.637V13.32H6.545Zm3.273 0v1.567h1.637V13.32H9.818Z"
      />
    </SVGIcon>
  );
};

export default observer(CalendarIcon);
