import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { NotTranslated } from '../../../Translations';

interface Props {
  title: Maybe<string>;
}

function Title({ title }: Props) {
  return title
    .map((t) => {
      return (
        <h1 className="m-0 pt-1.5" data-test-segment-title={t}>
          <NotTranslated text={t} />
        </h1>
      );
    })
    .getOrElse(emptyFragment);
}

export default observer(Title);
