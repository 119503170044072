import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { identity } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';

interface WhenTrueProps {
  predicate: boolean;
  children: ReactElement | ((t: boolean) => ReactElement);
  otherwise?: () => ReactElement;
}

interface WhenJustProps<T> {
  predicate: Maybe<T>;
  children: ReactElement | ((t: T) => ReactElement);
  otherwise?: () => ReactElement;
}

type WhenProps<T> = WhenTrueProps | WhenJustProps<T>;

const WhenJust = observer(function <T>({ predicate, children, otherwise }: WhenJustProps<T>) {
  const alternative = typeof otherwise === 'undefined' ? emptyFragment : otherwise;
  const content = (t: T) => (typeof children === 'function' ? children(t) : children);
  return predicate.map(content).getOrElse(alternative);
});

const WhenTrue = observer(({ predicate, children, otherwise }: WhenTrueProps) => {
  return (
    <WhenJust predicate={when(identity, predicate)} children={children} otherwise={otherwise} />
  );
});

function When<T>({ predicate, children, otherwise }: WhenProps<T>) {
  if (typeof predicate === 'boolean') {
    return <WhenTrue predicate={predicate} children={children} otherwise={otherwise} />;
  } else {
    return <WhenJust predicate={predicate} children={children} otherwise={otherwise} />;
  }
}

export default observer(When);
