import * as React from 'react';
import { NotifiableStore } from '../../ErrorActionableReaction';
import NotificationsStore from '../../Notifications/Store';

interface Props {
  notificationsStore: NotificationsStore;
  notifiableStore: NotifiableStore;
}

class MountNotifier extends React.Component<Props, {}> {
  componentDidMount() {
    const { notifiableStore, notificationsStore } = this.props;
    notificationsStore.addStore(notifiableStore);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { notifiableStore, notificationsStore } = this.props;
    if (prevProps.notifiableStore !== notifiableStore) {
      notificationsStore.removeStore(prevProps.notifiableStore);
      notificationsStore.addStore(notifiableStore);
    }
  }

  componentWillUnmount() {
    const { notifiableStore, notificationsStore } = this.props;
    notificationsStore.removeStore(notifiableStore);
  }

  render() {
    return <></>;
  }
}

export default MountNotifier;
