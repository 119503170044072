import { Kettle } from 'kettle-corn';
import * as React from 'react';
import { SourceFile, videoSourceFile } from '../JWPlayer/Types';
import JWPlayer from './JWPlayer';
import { videoPlayerOptions } from './JWPlayer/Types';
import { announcementAdaptiveTracks } from './Resource';
import {
  AnnouncementVideoAsset,
  AnnouncementVideoAssetResource,
  VideoPlaybackAdaptiveSources,
} from './Types';

interface Props {
  id: string;
  className: string;
  videoResource: AnnouncementVideoAssetResource;
  kettle: Kettle;
  width: number | string;
  height: number | string;
  dynamicallySetAspectRatio: boolean;
}

/**
 * AnnouncementAdaptivePlayer wraps JWPlayer and defaults to attempting to play the
 * adaptive stream specified by the file property on the top-level of the
 * adaptive sources.
 *
 * By passing along fallback sources, JWPlayer will attempt those progressive
 * sources if the adaptive stream fails.
 */
const AnnouncementAdaptivePlayer: React.FC<Props> = ({
  id,
  className,
  videoResource,
  kettle,
  width,
  height,
  dynamicallySetAspectRatio,
}) => {
  const announcementFallbackSources = (videoAsset: AnnouncementVideoAsset): SourceFile[] =>
    videoAsset.adaptiveSources
      .map((adaptiveSources) => adaptiveSources.fallbackSources.map(videoSourceFile))
      .getOrElseValue([]);

  const options = videoPlayerOptions(videoResource, {
    width,
    height,
    dynamicallySetAspectRatio,
    file: videoResource.payload.adaptiveSources
      .map((adaptiveSources: VideoPlaybackAdaptiveSources) => adaptiveSources.file)
      .getOrElseValue(''),
    tracks: announcementAdaptiveTracks(videoResource.payload),
  });

  return (
    <JWPlayer
      id={id}
      className={className}
      kettle={kettle}
      options={options}
      fallbackSources={announcementFallbackSources(videoResource.payload)}
    />
  );
};

export default AnnouncementAdaptivePlayer;
