import { observer } from 'mobx-react';
import WithCurrentUser from '../../../../../../CurrentUser/Context/WithCurrentUser';
import { Experience } from '../../../../Common/Experience/Types';
import UnsupportedLanguageMessage from './UnsupportedLanguageMessage';

interface Props {
  experience: Experience;
}

function HeroBanner({ experience }: Props) {
  return (
    <WithCurrentUser>
      {(currentUser) => {
        <UnsupportedLanguageMessage currentUser={currentUser} experience={experience} />;
      }}
    </WithCurrentUser>
  );
}

export default observer(HeroBanner);
