import { just, Maybe, nothing } from 'maybeasy';

export type ScrollPosition = 'top' | 'near-top' | 'middle' | 'near-bottom' | 'bottom';

export interface ScrollEvent {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

export const scrollEvent = (reactScrollEvent: React.UIEvent<HTMLDivElement>): ScrollEvent => ({
  scrollTop: reactScrollEvent.currentTarget.scrollTop,
  scrollHeight: reactScrollEvent.currentTarget.scrollHeight,
  clientHeight: reactScrollEvent.currentTarget.clientHeight,
});

export const whenAtBottom = (pos: ScrollPosition): Maybe<'bottom'> => {
  switch (pos) {
    case 'bottom':
      return just(pos);
    case 'middle':
    case 'near-bottom':
    case 'near-top':
    case 'top':
      return nothing();
  }
};
