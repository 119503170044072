import { emptyFragment } from '@execonline-inc/execonline-ui';
import { pipe } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { warnAndNotify } from '../../Honeybadger';
import WhenImpersonating from '../../Impersonation/WhenImpersonating';
import WhenNotImpersonating from '../../Impersonation/WhenNotImpersonating';
import { findLinkBy } from '../../Links';
import { findLink } from '../../LinkyLinky';
import { Link, Linkable } from '../../Resource/Types';
import DownloadButton from './DownloadButton';
import DownloadViewRouting from './DownloadViewRouting';

interface Props {
  resource: Linkable;
}

const DefaultDownloadButton = observer(({ resource }: Props) => {
  const [downloadLink, setDownloadLink] = useState<Maybe<Link>>(nothing());

  useEffect(() => {
    findLinkBy({ rel: 'download', type: 'text/html' }, resource.links)
      .orElse(() => findLinkBy({ rel: 'download' }, resource.links))
      .elseDo(() => {
        warnAndNotify(
          '[DownloadViewModal]',
          `Download Modal opened with no download link.`,
          resource,
        );
      })
      .do(pipe(just, setDownloadLink));
  });

  return <DownloadButton link={downloadLink} />;
});

const UploadedByYouDownloadButton = observer(({ resource }: Props) => {
  return (
    <>
      <WhenImpersonating>
        {findLink('view', resource.links)
          .map(emptyFragment)
          .getOrElse(() => (
            <DefaultDownloadButton resource={resource} />
          ))}
      </WhenImpersonating>
      <WhenNotImpersonating>
        <DefaultDownloadButton resource={resource} />
      </WhenNotImpersonating>
    </>
  );
});

function DownloadButtonRenderer({ resource }: Props) {
  return (
    <DownloadViewRouting
      uploadedByYou={() => <UploadedByYouDownloadButton resource={resource} />}
      otherwise={() => <DefaultDownloadButton resource={resource} />}
    />
  );
}

export default observer(DownloadButtonRenderer);
