import { noop } from '@kofno/piper';
import b64toBlob from 'b64-to-blob';
import { Task } from 'taskarian';
import { errorMessage } from '../ExceptionHandling';

export type Base64Data = string;

export interface Base64ConversionError {
  kind: 'b64-conversion-error';
  message: string;
}

const removeNewlinesForIe11Compatibility = (data: string): string => data.replace(/\s/g, '');

export const b64toBlobT =
  (contentType: string) =>
  (data: Base64Data): Task<Base64ConversionError, Blob> =>
    new Task((reject, resolve) => {
      try {
        const blob = b64toBlob(removeNewlinesForIe11Compatibility(data), contentType);
        resolve(blob);
      } catch (err) {
        reject({ kind: 'b64-conversion-error', message: errorMessage(err) });
      }
      return noop;
    });
