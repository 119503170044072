import { useMemo } from 'react';
import { ResourceResource } from '../../../../TeamResourceStore/Types';
import { NotTranslated, T } from '../../../../Translations';
import DownloadViewModal from '../../../DownloadViewModal';
import { useOpenable } from '../../../Openable';

interface Props {
  attachment: ResourceResource;
  index: number;
}

function Attachment({ attachment, index }: Props) {
  const { openableState, close, open } = useOpenable();
  const attachmentCounter = useMemo(() => index + 1, [index]);

  switch (attachment.payload.processingStatus) {
    case 'completed':
      return (
        <li>
          {`${attachmentCounter}. `}
          <a onClick={open} data-test-select-attachment>
            <NotTranslated text={attachment.payload.title} />
          </a>
          <DownloadViewModal resource={attachment} close={close} modalState={openableState} />{' '}
        </li>
      );
    case 'processing':
      return (
        <li>
          <T kind="This file is processing" />
        </li>
      );
  }
}

export default Attachment;
