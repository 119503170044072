import { Auth, PasswordAuth, SsoAuth } from '../DiscoveryPortal/RegisterResourceStore/Types';
import LockedAccessStore from './LockedAccessStore';
import SetPasswordStore from './SetPasswordStore';
import SsoLoginStore from './SsoLoginStore';
import UsePasswordStore from './UsePasswordStore';

const passwordAuthenticationFormKind = (auth: PasswordAuth): AuthenticationForm => {
  switch (auth.passwordState.kind) {
    case 'password-set':
      return { kind: 'password-sign-in', auth, store: auth.passwordState.store };
    case 'reset-password':
      return { kind: 'password-sign-up', auth, store: auth.passwordState.store };
  }
};

const ssoAuthenticationFormKind = (auth: SsoAuth): AuthenticationForm => {
  return auth.termsAccepted
    ? { kind: 'sso-sign-in', auth, store: auth.store }
    : { kind: 'sso-sign-up', auth, store: auth.store };
};

export const authenticationFormKind = (auth: Auth): AuthenticationForm => {
  switch (auth.kind) {
    case 'password-auth':
      return passwordAuthenticationFormKind(auth);
    case 'sso-auth':
      return ssoAuthenticationFormKind(auth);
    case 'locked-access-auth':
      return { kind: 'locked-access', store: auth.store };
  }
};

export interface SsoSignIn {
  kind: 'sso-sign-in';
  auth: SsoAuth;
  store: SsoLoginStore;
}

export interface SsoSignUp {
  kind: 'sso-sign-up';
  auth: SsoAuth;
  store: SsoLoginStore;
}

export interface PasswordSignIn {
  kind: 'password-sign-in';
  auth: PasswordAuth;
  store: UsePasswordStore;
}

export interface PasswordSignUp {
  kind: 'password-sign-up';
  auth: PasswordAuth;
  store: SetPasswordStore;
}

export interface LockedAccess {
  kind: 'locked-access';
  store: LockedAccessStore;
}

export type AuthSignUp = SsoSignUp | PasswordSignUp;

export type AuthSignIn = SsoSignIn | PasswordSignIn;

export type AuthenticationForm = AuthSignUp | AuthSignIn | LockedAccess;
