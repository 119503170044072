import { nullableBlankString, stringLiteral } from '@execonline-inc/decoders';
import { identity } from '@kofno/piper';
import Decoder, { field, oneOf, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../../Resource/Decoders';
import { videoAssetResourceDecoder } from '../../../VideoStore/Legacy/Decoders';
import { CourseDocument, CourseDocumentResource, DocumentResource, VideoResource } from '../Types';

const documentPartsDecoder: Decoder<DocumentResource> = succeed({})
  .assign('kind', field('kind', stringLiteral('document')))
  .assign('title', field('title', string))
  .assign('description', field('description', nullableBlankString));

const videoPartsDecoder: Decoder<VideoResource> = succeed({})
  .assign('kind', field('kind', stringLiteral('video')))
  .assign('title', field('title', string))
  .assign('description', field('description', nullableBlankString))
  .assign('videoAssetResource', field('video_asset_resource', videoAssetResourceDecoder));

export const courseDocumentDecoder: Decoder<CourseDocument> = oneOf<CourseDocument>([
  documentPartsDecoder.map<CourseDocument>(identity),
  videoPartsDecoder.map<CourseDocument>(identity),
]);

export const courseDocumentResourceDecoder: Decoder<CourseDocumentResource> = resourceDecoder(
  courseDocumentDecoder
);
