import { fromBool, toResult } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ok } from 'resulty';
import { error as errorCtor, serverError as serverErrorCtor } from '../../../../ErrorHandling';
import { FlashAlert, errorAlert, serverErrorAlert } from '../../../../Notifications/Types';
import { AlreadyTranslatedText, TPlainTextKey } from '../../../../Translations';
import {
  State,
  created,
  creating,
  error,
  ready,
  readyWithClientErrors,
  readyWithServerErrors,
  serverError,
  waiting,
} from './Types';

class SsoLoginStore {
  @observable
  state: State = waiting();

  @action
  ready = (termsAcceptance: boolean, emailAddress: string) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        this.state = ready(termsAcceptance, emailAddress);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithClientErrors = (
    termsAcceptance: boolean,
    message: TPlainTextKey,
    emailAddress: string
  ) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        this.state = readyWithClientErrors(termsAcceptance, message, emailAddress);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  readyWithServerErrors = (
    termsAcceptance: boolean,
    message: AlreadyTranslatedText,
    emailAddress: string
  ) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        this.state = readyWithServerErrors(termsAcceptance, message, emailAddress);
        break;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTermsAcceptance = (termsAcceptance: boolean) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        this.state.termsAcceptance = termsAcceptance;
        break;
      case 'waiting':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  creating = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
        const { termsAcceptance, emailAddress } = this.state;

        ok<TPlainTextKey, boolean>(termsAcceptance)
          .map(fromBool(termsAcceptance))
          .andThen(toResult('Must accept terms of use and privacy policy'))
          .do(() => (this.state = creating(emailAddress)))

          .elseDo(this.error);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'creating':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  created = () => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        this.state = created(this.state.emailAddress);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        this.state = error(message, this.termsAcceptance, this.state.emailAddress);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  serverError = (message: AlreadyTranslatedText) => {
    switch (this.state.kind) {
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'creating':
        this.state = serverError(message, this.termsAcceptance, this.state.emailAddress);
        break;
      case 'waiting':
      case 'error':
      case 'server-error':
      case 'created':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get termsAcceptance(): boolean {
    switch (this.state.kind) {
      case 'waiting':
        return false;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
        return this.state.termsAcceptance;
      case 'creating':
      case 'created':
        return true;
    }
  }

  @computed
  get formDisabled(): boolean {
    switch (this.state.kind) {
      case 'creating':
      case 'waiting':
        return true;
      case 'ready':
      case 'ready-with-client-errors':
      case 'ready-with-server-errors':
      case 'error':
      case 'server-error':
      case 'created':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'server-error':
        return just(this.state).map(serverErrorAlert);
      case 'ready-with-client-errors':
        return just(this.state.message).map(errorCtor).map(errorAlert);
      case 'ready-with-server-errors':
        return just(this.state.message).map(serverErrorCtor).map(serverErrorAlert);
      case 'ready':
      case 'waiting':
      case 'creating':
      case 'created':
        return nothing();
    }
  }
}

export default SsoLoginStore;
