import * as React from 'react';
import { T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  minutes: number;
}

const CompletionTime: React.FC<Props> = ({ minutes }) => {
  return (
    <span className={style.timeToComplete}>
      <T kind="{{count}} minute" count={minutes} />
    </span>
  );
};

export default CompletionTime;
