import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { findLink } from '../../LinkyLinky';
import CommunitiesSummary from '../CommunitiesSummary';
import CoachCommunitySummary from './CoachCommunitySummary';

const CoachCommunities: React.FC = () => (
  <WithCurrentUser
    children={(currentUserResource) =>
      findLink('coach-communities')(currentUserResource.links)
        .map(() => <CoachCommunitySummary />)
        .getOrElse(() => <CommunitiesSummary />)
    }
  />
);

export default observer(CoachCommunities);
