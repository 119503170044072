import { first } from '@execonline-inc/collections';
import { toResult, toTask } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { Result } from 'resulty';
import { Task } from 'taskarian';
import { Link, LinkIdentity, Method, MissingLinkError, missingLinkError } from './Types';

export * from './Types';

export const initialize = <Rel extends string, Endpoint extends string>() => {
  type Links = ReadonlyArray<Link<Rel, Endpoint | undefined>>;

  type Found<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  > =
    Extract<L[number], LinkIdentity<R, E, M, T>> extends never
      ? L[number] & Link<R, E, M, T>
      : Extract<L[number], LinkIdentity<R, E, M, T>>;

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(props: LinkIdentity<R, E, M, T>): (list: L) => Maybe<Found<L, R, E, M, T>>;

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(props: LinkIdentity<R, E, M, T>, list: L): Maybe<Found<L, R, E, M, T>>;

  function findLinkBy<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >({ rel, endpoint, method, type }: LinkIdentity<R, E, M, T>, list?: L) {
    const exec = (list: L): Maybe<Found<L, R, E, M, T>> => {
      let items: ReadonlyArray<Link<Rel, Endpoint | undefined>> = list;

      if (typeof rel !== 'undefined') {
        items = items.filter((l) => l.rel === rel);
      }

      if (typeof endpoint !== 'undefined') {
        items = items.filter((l) => l.endpoint === endpoint);
      }

      if (typeof method !== 'undefined') {
        items = items.filter((l) => l.method === method);
      }

      if (typeof type !== 'undefined') {
        items = items.filter((l) => l.type === type);
      }

      /**
       * The code complexity required to do this "the right way" without type assertion greatly
       * outweighs the risk of type assertion here in my opinion.
       */
      return first(items).map((link) => link as Found<L, R, E, M, T>);
    };

    return typeof list === 'undefined' ? exec : exec(list);
  }

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(
    props: LinkIdentity<R, E, M, T>,
  ): (list: L) => Result<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>>;

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(
    props: LinkIdentity<R, E, M, T>,
    list: L,
  ): Result<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>>;

  function findLinkByR<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(props: LinkIdentity<R, E, M, T>, list?: L) {
    const exec = (list: L): Result<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>> =>
      toResult(missingLinkError(props), findLinkBy(props, list));

    return typeof list === 'undefined' ? exec : exec(list);
  }

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(
    props: LinkIdentity<R, E, M, T>,
  ): (list: L) => Task<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>>;

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(
    props: LinkIdentity<R, E, M, T>,
    list: L,
  ): Task<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>>;

  function findLinkByT<
    L extends Links,
    R extends Rel,
    E extends Endpoint | undefined,
    M extends Method = Method,
    T extends string = string,
  >(props: LinkIdentity<R, E, M, T>, list?: L) {
    const exec = (list: L): Task<MissingLinkError<R, E, M, T>, Found<L, R, E, M, T>> =>
      toTask(missingLinkError(props), findLinkBy(props, list));

    return typeof list === 'undefined' ? exec : exec(list);
  }

  /**
   * Helper function to add an endpoint to a non-endpoint-aware link.
   */
  function declareEndpoint<E extends Endpoint, L>(endpoint: E) {
    return (link: L): L & { endpoint: E } => ({ ...link, endpoint });
  }

  return {
    findLinkBy,
    findLinkByR,
    findLinkByT,
    declareEndpoint,
  } as const;
};
