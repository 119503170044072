import { cn } from '@execonline-inc/execonline-ui';
import { fromEmpty } from 'maybeasy';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { useExperiencesContext } from '../../ExperienceSelection/Experiences/ExperiencesContext';
import { whenSearchValue } from '../../ExperienceSelection/Filters/SearchBox/Functions';

function PromotableSection({ children }: PropsWithChildren<{}>) {
  const experiencesStore = useExperiencesContext();
  const hidePromotables = whenSearchValue(experiencesStore.state).andThen(fromEmpty).isJust();

  return (
    <div
      className={cn('relative overflow-hidden bg-white shadow-section', {
        hidden: hidePromotables,
      })}
    >
      {children}
    </div>
  );
}

export default observer(PromotableSection);
