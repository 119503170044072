import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { GroupCoachingProductDetails } from '../../../../Common/Experience/Types';
import GroupCoachingSessionsListItem from './GroupCoachingSessionsListItem';

interface Props {
  productDetails: GroupCoachingProductDetails;
}

function GroupCoachingSessions({ productDetails }: Props) {
  return productDetails.groupCoachingSessions
    .map((groupCoachingSessions) => (
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        {groupCoachingSessions.payload.map(({ payload }) => (
          <GroupCoachingSessionsListItem key={payload.id} session={payload} />
        ))}
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachingSessions);
