import { observer } from 'mobx-react';
import { fromEmpty } from 'maybeasy';
import { emptyFragment, Typography } from '@execonline-inc/execonline-ui';
import { T, TPlainTextKey } from '../../../../../../Translations';
import FilterChipButton from './FilterChipButton';

interface Props {
  selectedOptions: string[];
  title: TPlainTextKey;
  onClear: () => void;
}

function FilterPlaceholder({ selectedOptions, title, onClear }: Props) {
  return (
    <span className="flex gap-x-2 overflow-hidden">
      {fromEmpty(selectedOptions)
        .map(() => <FilterChipButton value={selectedOptions.length} onClear={onClear} />)
        .getOrElse(emptyFragment)}
      <Typography
        variant="bodyMedium"
        className="overflow-hidden text-ellipsis whitespace-nowrap"
        as="span"
      >
        <T kind={title} />
      </Typography>
    </span>
  );
}

export default observer(FilterPlaceholder);
