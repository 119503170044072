import { PopoverTrigger } from '@execonline-inc/execonline-ui';
import { OfferingType, ExperienceResource } from '../../Experience/Types';

export function getTriggerTypeByExperienceOfferingType(offeringType: OfferingType): PopoverTrigger {
  switch (offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'coaching':
      return 'hover';
    case 'program-sequence':
    case 'group-coaching':
      return 'none';
  }
}

export function getTriggerTypeByExperience(experience: ExperienceResource): PopoverTrigger {
  const { kind, offeringType } = experience.payload;

  switch (kind) {
    case 'commerce-enrollable':
    case 'not-enrollable':
    case 'not-licensed':
    case 'enrollable':
      return getTriggerTypeByExperienceOfferingType(offeringType);
    case 'resumable':
    case 'beginnable':
    case 'returnable':
    case 'upcoming':
      return 'hover';
    case 'graduated':
      return 'none';
  }
}
