import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../../Translations';
import { Position } from '../../../PositionContext';
import * as style from './../../style.module.css';

interface Props {
  name: Maybe<string>;
  jobTitle: Maybe<string>;
  organization: Maybe<string>;
  position: Position;
}

const PersonDetails: React.FC<Props> = ({ organization, name, jobTitle, position }) => {
  const renderSubtitle = (jobTitle: string) =>
    jobTitle.length === 0 ? (
      <></>
    ) : (
      <h5 className={style.subtitle}>
        <NotTranslated text={jobTitle} />
      </h5>
    );
  const styleType = (position: Position) =>
    (function (kind) {
      switch (kind) {
        case 'supPanel':
          return style.detailsSupPanel;
        case 'participant':
          return style.detailsSupPanel;
        default:
          return style.details;
      }
    })(position.kind);

  return (
    <div className={styleType(position)} data-test-person-details={true}>
      <p className={style.title}>
        <NotTranslated text={name.getOrElseValue('')} />
      </p>
      {jobTitle.map(renderSubtitle).getOrElseValue(<span />)}
      {organization
        .map((org) => (
          <span className={style.organization}>
            <NotTranslated text={org} />
          </span>
        ))
        .getOrElseValue(<></>)}
    </div>
  );
};

export default observer(PersonDetails);
