import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { ActiveProgramModule } from '../../../../ProgramStore/Types';
import { ProgramDetailResource } from '../../../../ProgramStore/Types';
import ArrowRightIcon from '../../../Cutesies/ArrowRightIcon';
import ArrowLeftIcon from '../../../Cutesies/ArrowLeftIcon';
import ScrollStore from './Store';
import Module from '../Module/index';
import style from './style.module.css';

interface IProps {
  programDetailResource: ProgramDetailResource;
}

const Modules: React.FC<IProps> = ({ programDetailResource }) => {
  const modules = (programDetailResource: ProgramDetailResource): ActiveProgramModule[] | [] => {
    switch (programDetailResource.payload.kind) {
      case 'active':
        return programDetailResource.payload.modules;
      case 'completed':
      case 'expired':
      case 'inactive':
      case 'upcoming':
        return [];
    }
  };
  const scrollStore = useRef(new ScrollStore());

  return (
    <div className={style.modules}>
      <div
        className={clsx(style.button, style.leftBtn, {
          [style.isBtnHidden]: scrollStore.current.leftButtonDisabled,
        })}
        onClick={() => scrollStore.current.scrollLeft()}
      >
        <ArrowLeftIcon />
      </div>
      <div
        className={clsx(style.button, style.rightBtn, {
          [style.isBtnHidden]: scrollStore.current.rightButtonDisabled,
        })}
        onClick={() => scrollStore.current.scrollRight()}
      >
        <ArrowRightIcon />
      </div>
      <div
        className={style.modulesContainerInner}
        ref={(elem: HTMLDivElement) => scrollStore.current.addContainer(elem)}
      >
        {modules(programDetailResource).map((sequenceModule: ActiveProgramModule) => {
          const segments = sequenceModule.segments;
          return (
            <Module
              scrollStore={scrollStore.current}
              sequenceModule={sequenceModule}
              segments={segments}
            />
          );
        })}
      </div>
    </div>
  );
};

export default observer(Modules);
