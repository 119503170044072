import { observer } from 'mobx-react';
import * as React from 'react';
import { currentUserStore } from '../../CurrentUser/Store';
import CenteredLogo from '../CenteredLogo';
import Loader from '../Loader';
import SubscriptionStore from '../Unsubscribe/Store';
import Details from '../Unsubscribe/Details';
import Background from '../Unsubscribe/Background';
import UnsubscribeReactions from './Reactions';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';

interface Props {
  id: string;
}

class UnsubscribeProgressEmail extends React.Component<Props> {
  subscriptionStore = new SubscriptionStore(this.props.id);

  render() {
    switch (currentUserStore.state.kind) {
      case 'anonymous':
      case 'ready':
      case 'refreshing':
        return (
          <>
            <Background>
              <CenteredLogo />
              <Details store={this.subscriptionStore} />
            </Background>
            <WithCurrentUser
              children={(currentUserResource) => (
                <UnsubscribeReactions
                  store={this.subscriptionStore}
                  currentUserResource={currentUserResource}
                  fireImmediately={true}
                />
              )}
            ></WithCurrentUser>
          </>
        );
      case 'waiting':
      case 'loading':
      case 'logging-out':
      case 'errored':
        return <Loader />;
    }
  }
}

export default observer(UnsubscribeProgressEmail);
