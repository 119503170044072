import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Image } from '@execonline-inc/execonline-ui';
import { Avatar } from '../../Avatar';
import { NotTranslated, translation, TranslationsContext } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  avatar: Avatar;
}

const PersonPhoto: React.FC<Props> = ({ avatar }) => {
  switch (avatar.kind) {
    case 'avatar-link':
      return (
        <TranslationsContext.Consumer>
          {(ts) => <Image alt={translation('User Photo')(ts)} src={avatar.link.href} />}
        </TranslationsContext.Consumer>
      );
    case 'avatar-initials':
      return (
        <span className={clsx(style.initials, 'bg-primary-DEFAULT group-hover:bg-primary-700 ')}>
          <NotTranslated text={avatar.initials} />
        </span>
      );
  }
};

export default observer(PersonPhoto);
