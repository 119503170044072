import { observer } from 'mobx-react';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../Translations';
import { Experience } from '../../../Common/Experience/Types';

interface Props {
  experience: Experience;
}

function ExperiencesLogoSuffix({ experience }: Props) {
  switch (experience.offeringType) {
    case 'coaching':
    case 'group-coaching':
      return (
        <Typography
          className="self-end lowercase"
          color="white"
          fontWeight="semibold"
          variant="bodyLarge"
          as="span"
        >
          <T kind="Coaching" />
        </Typography>
      );
    case 'program-sequence':
      return (
        <Typography
          className="self-end"
          color="white"
          fontWeight="semibold"
          variant="bodyLarge"
          as="span"
        >
          <T kind="Learning Journey" />
        </Typography>
      );
    case 'aep':
    case 'epc':
    case 'msuite':
      return emptyFragment();
  }
}

export default observer(ExperiencesLogoSuffix);
