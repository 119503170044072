import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../Translations';

interface Props {
  remainingCount: number;
}
const ModulesCompletedNone: React.FC<Props> = ({ remainingCount }) => {
  return (
    <span data-test-modules-remaining={remainingCount}>
      <T kind="You have {{count}} module remaining" count={remainingCount} />
    </span>
  );
};

export default observer(ModulesCompletedNone);
