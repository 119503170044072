import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { AlreadyTranslated, AlreadyTranslatedText } from '@execonline-inc/translations';
import { Typography, emptyFragment } from '@execonline-inc/execonline-ui';

interface Props {
  title: Maybe<AlreadyTranslatedText>;
}

function GroupCoachSessionTitle({ title }: Props) {
  return title
    .map((value) => (
      <Typography className="text-gray-900" variant="bodySmall">
        <AlreadyTranslated content={value} />
      </Typography>
    ))
    .getOrElse(emptyFragment);
}

export default observer(GroupCoachSessionTitle);
