import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import ProportionalImage from '../../../../../../components/ProportionalImage';
import { findLink } from '../../../../../../LinkyLinky';
import { SchoolPartnerLogoProps } from '../Types';

function SchoolPartnerLogo({
  height,
  data,
  logoLinkType = 'logo-on-white',
}: SchoolPartnerLogoProps) {
  return just(data.links)
    .andThen(findLink(logoLinkType))
    .map(({ href }) => (
      <ProportionalImage href={href} alt={data.payload.name.text} height={height} />
    ))
    .getOrElse(emptyFragment);
}

export default observer(SchoolPartnerLogo);
