import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { serverError } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert, serverErrorAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';
import { InstantSsoReady } from '../SharedInvitationResourceStore/Types';
import {
  authenticatingEmailDomain,
  authenticatingUser,
  error,
  instantSso,
  pendingEmailConfirmation,
  PendingEmailConfirmation,
  registered,
  registering,
  requestingEmailAddress,
  RequestingEmailAddress,
  requestingProfileInfo,
  RequestingProfileInfo,
  requestingProfileInfoLockedAccess,
  OmitRequestingProfileInfoLockedAccess,
  requestingProfileInfoPasswordBackend,
  OmitRequestingProfileInfoPasswordBackend,
  State,
  validatingEmailFromIdentifiedUser,
  waiting,
} from './Types';

export class InvitationStore {
  @observable
  state: State = waiting();

  @action
  requestingEmailAddress = (state: Omit<RequestingEmailAddress, 'kind'>): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
        this.state = requestingEmailAddress(state);
        break;
      case 'requesting-email-address':
      case 'requesting-profile-info':
      case 'pending-email-confirmation':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  pendingEmailConfirmation = (state: Omit<PendingEmailConfirmation, 'kind'>): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
        this.state = pendingEmailConfirmation(state);
        break;
      case 'requesting-email-address':
      case 'requesting-profile-info':
      case 'pending-email-confirmation':
      case 'registering':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  authenticatingEmailDomain = (): void => {
    switch (this.state.kind) {
      case 'requesting-email-address':
        this.state = authenticatingEmailDomain(this.state.emailAddress.trim());
        break;
      case 'waiting':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'registering':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  validatingEmailFromIdentifiedUser = (email: string): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'instant-sso':
        this.state = validatingEmailFromIdentifiedUser(email);
        break;
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'registering':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestingProfileInfo = (state: Omit<RequestingProfileInfo, 'kind'>): void => {
    switch (this.state.kind) {
      case 'authenticating-email-domain':
      case 'registering':
        this.state = requestingProfileInfo(state);
        break;
      case 'waiting':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestingProfileInfoLockedAccess = (state: OmitRequestingProfileInfoLockedAccess): void => {
    switch (this.state.kind) {
      case 'authenticating-email-domain':
      case 'registering':
        this.state = requestingProfileInfoLockedAccess(state);
        break;
      case 'waiting':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  requestingProfileInfoPasswordBackend = (
    state: OmitRequestingProfileInfoPasswordBackend,
  ): void => {
    switch (this.state.kind) {
      case 'authenticating-email-domain':
      case 'registering':
        this.state = requestingProfileInfoPasswordBackend(state);
        break;
      case 'waiting':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  authenticatingUser = (uuid: string, experienceId: Maybe<string>): void => {
    switch (this.state.kind) {
      case 'validating-email-from-identified-user':
      case 'authenticating-email-domain':
        this.state = authenticatingUser(uuid, experienceId);
        break;
      case 'waiting':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'requesting-profile-info':
      case 'registering':
      case 'authenticating-user':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  registering = (): void => {
    switch (this.state.kind) {
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        this.state = registering({
          ...this.state,
          emailAddress: this.state.emailAddress.trim(),
          firstName: this.state.firstName.trim(),
          lastName: this.state.lastName.trim(),
        });
        break;
      case 'requesting-email-address':
      case 'waiting':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  registered = (): void => {
    switch (this.state.kind) {
      case 'registering':
        this.state = registered();
        break;
      case 'requesting-profile-info':
      case 'requesting-email-address':
      case 'waiting':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'authenticating-user':
      case 'instant-sso':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  instantSso = (instantSsoReady: InstantSsoReady): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
        this.state = instantSso(instantSsoReady);
        break;
      case 'registering':
      case 'requesting-profile-info':
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey): void => {
    this.state = error(message);
  };

  @action
  setEmailAddress = (emailAddress: string): void => {
    switch (this.state.kind) {
      case 'requesting-email-address':
        this.state.emailAddress = emailAddress;
        break;
      case 'waiting':
      case 'authenticating-email-domain':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'registering':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setFirstName = (firstName: string): void => {
    switch (this.state.kind) {
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        this.state.firstName = firstName;
        break;
      case 'requesting-email-address':
      case 'waiting':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setLastName = (lastName: string): void => {
    switch (this.state.kind) {
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        this.state.lastName = lastName;
        break;
      case 'requesting-email-address':
      case 'waiting':
      case 'pending-email-confirmation':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  setTermsAcceptance = (termsAccepted: boolean): void => {
    switch (this.state.kind) {
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        this.state.termsAccepted = termsAccepted;
        break;
      case 'requesting-email-address':
      case 'pending-email-confirmation':
      case 'waiting':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get formInputsEnabled(): boolean {
    switch (this.state.kind) {
      case 'requesting-email-address':
      case 'requesting-profile-info':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
        return true;
      case 'waiting':
      case 'authenticating-email-domain':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        return false;
    }
  }

  @computed
  get emailInputEnabled(): boolean {
    switch (this.state.kind) {
      case 'requesting-email-address':
        return this.formInputsEnabled;
      case 'waiting':
      case 'authenticating-email-domain':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'pending-email-confirmation':
      case 'validating-email-from-identified-user':
      case 'requesting-profile-info':
      case 'registering':
      case 'authenticating-user':
      case 'instant-sso':
      case 'registered':
      case 'error':
        return false;
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'requesting-email-address':
      case 'requesting-profile-info':
        return this.state.alertMessage.map(serverError).map(serverErrorAlert);
      case 'pending-email-confirmation':
      case 'waiting':
      case 'authenticating-email-domain':
      case 'validating-email-from-identified-user':
      case 'registering':
      case 'requesting-profile-info-locked-access':
      case 'requesting-profile-info-password-backend':
      case 'registered':
      case 'authenticating-user':
      case 'instant-sso':
        return nothing();
      case 'error':
        return just(errorAlert(error(this.state.message)));
    }
  }
}
