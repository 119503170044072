import { find } from '@execonline-inc/collections';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { error } from '../../../../ErrorHandling';
import { errorAlert, FlashAlert } from '../../../../Notifications/Types';
import { TPlainTextKey } from '../../../../Translations';
import { ProgramResource } from '../../Types';
import { loading, ProgramResourcesResource, ProgramResourcesState, ready, waiting } from './Types';

class ProgramResourcesStore {
  @observable
  state: ProgramResourcesState;

  constructor() {
    this.state = waiting();
  }

  public moduleResourceCount = createTransformer((id: number): Maybe<number> => {
    switch (this.state.kind) {
      case 'ready':
        return find(
          (module) => module.id === id,
          this.state.programResourcesResource.payload.modules,
        ).map((module) => {
          const resourceCount = module.segments.reduce((count, segment) => {
            return count + segment.resources.map((r) => r.length).getOrElseValue(0);
          }, 0);
          return resourceCount;
        });

      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  });

  @action
  load = (programResource: ProgramResource) => {
    this.state = loading(programResource);
  };

  @action
  ready = (programResourcesResource: ProgramResourcesResource) => {
    this.state = ready(programResourcesResource);
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @computed
  get programResourcesResource(): Maybe<ProgramResourcesResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.programResourcesResource);
      case 'error':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }

  @computed
  get notification(): Maybe<FlashAlert> {
    switch (this.state.kind) {
      case 'error':
        return just(this.state).map(errorAlert);
      case 'ready':
      case 'loading':
      case 'waiting':
        return nothing();
    }
  }
}

export default ProgramResourcesStore;
