import {
  CloseIcon,
  CommentLanguageIcon,
  emptyFragment,
  IconButton,
  Typography,
} from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { CurrentUserResource } from '../../../../../../CurrentUser/Types';
import NotSupportedInLanguageStore from '../../../../../../NotSupportedInLanguageStore';
import { T } from '../../../../../../Translations';
import { Experience } from '../../../../Common/Experience/Types';

interface Props {
  currentUser: CurrentUserResource;
  experience: Experience;
}

function UnsupportedLanguageMessage({ currentUser, experience }: Props) {
  const store = useMemo(
    () => new NotSupportedInLanguageStore(experience.availableLanguages, currentUser),
    [experience.availableLanguages, currentUser],
  );

  switch (store.state.kind) {
    case 'ready':
      return (
        <div className="relative">
          <div className="absolute h-full w-full bg-black opacity-25" />
          <div className="mx-auto box-border flex w-full justify-between px-8 py-6 xl:container">
            <div className="relative flex h-5 items-center">
              <CommentLanguageIcon color="white" />
              <div className="ml-2.5">
                <Typography variant="bodyMedium" color="white">
                  <T kind="This experience is not available in this language." />
                </Typography>
              </div>
            </div>
            <IconButton className="z-[1]" onClick={store.hide}>
              <CloseIcon color="white" />
            </IconButton>
          </div>
        </div>
      );
    case 'hidden':
      return emptyFragment();
  }
}

export default observer(UnsupportedLanguageMessage);
