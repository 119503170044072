import { emptyFragment } from '@execonline-inc/execonline-ui';
import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import ExperienceHtmlView from '../ExperienceHtmlView';

interface Props {
  description: Maybe<AlreadyTranslatedText>;
  className?: string;
}

function ExperienceDescription({ className, description }: Props) {
  return description
    .map((data) => <ExperienceHtmlView data={data} className={className} />)
    .getOrElse(emptyFragment);
}

export default observer(ExperienceDescription);
