import { toTask } from '@execonline-inc/maybe-adapter';
import { always, noop } from '@kofno/piper';
import { Maybe, fromEmpty } from 'maybeasy';
import { Task } from 'taskarian';
import { sessionStore } from '../../Session/Store';
import {
  FormFailure,
  MissingIdentificationParam,
  SessionAlreadyEstablished,
  missingIdentificationParam,
  sessionAlreadyEstablished,
} from './Errors';
import { Params, Props } from './Types';

export const toFormParams = (props: Props): Task<FormFailure, Params> =>
  Task.succeed<FormFailure, Props>(props)
    .andThen(checkSessionAlreadyEstablished)
    .andThen(validateIdentificationParamPresence)
    .map(({ username, opaqueUseCaseIdentifier }) => ({ username, opaqueUseCaseIdentifier }))
    .assign('href', props.href)
    .assign('csrfToken', props.csrfToken);

const checkSessionAlreadyEstablished = <T>(passthrough: T): Task<SessionAlreadyEstablished, T> =>
  new Task((reject, resolve) => {
    // If there is already a token, they've already authenticated, and are in
    // an infinite loop.  This happens on Safari and Firefox after allowing the
    // SSO pop-up for the first time: The parent doesn't get a reference to the
    // child window, so the parent can't close the child once the user is
    // logged-in, and the child ends up back on this SSO Auth page, which
    // starts the SSO process over again. Let's stop that.
    sessionStore.sessionToken.cata({
      Just: () => reject(sessionAlreadyEstablished()),
      Nothing: () => resolve(passthrough),
    });
    return noop;
  });

const validateIdentificationParamPresence = (
  props: Props,
): Task<MissingIdentificationParam, Props> =>
  toTask(missingIdentificationParam(props), whenAnIdentificationParamIsPresent(props));

const whenAnIdentificationParamIsPresent = (props: Props): Maybe<Props> =>
  [props.username, props.opaqueUseCaseIdentifier]
    .reduce((prev, next) => prev.andThen(fromEmpty).orElse(always(next.andThen(fromEmpty))))
    .map(always(props));
