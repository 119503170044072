import { Maybe } from 'maybeasy';
import * as React from 'react';
import { Salutation } from '../../../ProfileFormStore/Types';
import { TPlainTextKey, translation, TranslationsContext } from '../../../Translations';

interface Props {
  value: Maybe<Salutation | ''>;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: TranslatableSelectOption[];
  className: Maybe<string>;
  name: string;
  placeholder: Maybe<TPlainTextKey>;
  id: string;
}
export interface TranslatableSelectOption {
  value: Salutation;
  content: TPlainTextKey;
}

const TranslatableSelect: React.FC<Props> = (props) => (
  <TranslationsContext.Consumer>
    {(ts) => (
      <select
        className={props.className.getOrElseValue('')}
        name={props.name}
        value={props.value.getOrElseValue('')}
        onChange={props.onChange}
        data-test-select={props.name}
        id={props.id}
      >
        {props.placeholder
          .map((placeholder) => <option value="">{translation(placeholder)(ts)}</option>)
          .getOrElse(() => (
            <></>
          ))}
        {props.options.map((option) => (
          <option value={option.value} key={option.value}>
            {translation(option.content)(ts)}
          </option>
        ))}
      </select>
    )}
  </TranslationsContext.Consumer>
);

export default TranslatableSelect;
