import { observer } from 'mobx-react';
import * as React from 'react';
import Main from '../../../BrowserSupport/Main';
import CountryOptionsStore from '../../../CountryOptionsStore';
import CountryRegionOptionsStore from '../../../CountryRegionOptionsStore';
import FileUploadStore from '../../../FileUploadStore';
import { lightTheme } from '../../../Native/styles';
import Notifications from '../../../Notifications';
import NotificationSource from '../../../NotificationSource';
import ProfileFormStore from '../../../ProfileFormStore';
import ThemeContext from '../../../Themes/ThemeContext';
import I18nTitle from '../../../Title/I18nTitle';
import { T, translation, TranslationsContext } from '../../../Translations';
import AutoLaunchable from '../../AutoLaunchable';
import Footer from '../../Footer';
import Loader from '../../Loader';
import * as platformStyle from '../../Platform/style.module.css';
import SkipNavLink from '../../SkipNavLink';
import ProfileErrorMessage from '../Error/ProfileErrorMessage';
import Preview from '../Preview';
import Account from './Account';
import AvatarUploadSection from './AvatarUploadSection';
import Personal from './Personal';
import Social from './Social';
import * as style from './style.module.css';
import SubmitButton from './SubmitButton';
import Work from './Work';
import NavigationBar from '../../Platform/NavigationBar';

interface Props {
  profileFormStore: ProfileFormStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
  countryOptionsStore: CountryOptionsStore;
  fileUploadStore: FileUploadStore;
}

const ProfileEditView: React.FC<Props> = ({
  profileFormStore,
  countryRegionOptionsStore,
  countryOptionsStore,
  fileUploadStore,
}) => {
  switch (profileFormStore.state.kind) {
    case 'waiting':
    case 'loading':
    case 'loading-countries':
    case 'loading-regions':
    case 'updating':
      return <Loader />;
    case 'checking-required-fields':
    case 'countries-loaded':
    case 'country-selected':
    case 'error':
    case 'loaded':
    case 'ready-with-errors':
    case 'ready-with-no-countries':
    case 'region-selected':
    case 'regions-loaded':
    case 'selecting-country':
    case 'succeed':
    case 'update-success':
      return (
        <TranslationsContext.Consumer>
          {(ts) => (
            <>
              <div className={platformStyle.containerFull}>
                <I18nTitle title="My Profile" />
                <SkipNavLink />
                <NavigationBar />
              </div>
              <div className={platformStyle.container} data-test-edit-profile={true}>
                <Notifications />
                <AutoLaunchable />
                <NotificationSource store={profileFormStore} />
                <div className={style.editSection}>
                  <Main id="main" className={style.profileContainer}>
                    <ThemeContext.Provider value={lightTheme}>
                      <div className={style.formArea} data-test-profile-form-area={true}>
                        <h1 className={style.pageHeader} data-test-page-header="My Profile">
                          <T kind="My Profile" />
                        </h1>
                        <form
                          className={style.form}
                          data-test-profile-form={true}
                          aria-label={translation('Edit Profile')(ts)}
                        >
                          <ProfileErrorMessage store={profileFormStore} />
                          <fieldset className={style.fieldset}>
                            <h2 className={style.fieldHeader} data-test-account-header="Account">
                              <T kind="Account" />
                            </h2>
                            <div className={style.inputSet}>
                              <Account profileFormStore={profileFormStore} />
                              <div className={style.column}>
                                <AvatarUploadSection
                                  profileStore={profileFormStore}
                                  fileUploadStore={fileUploadStore}
                                  countryRegionOptionsStore={countryRegionOptionsStore}
                                />
                              </div>
                            </div>
                          </fieldset>
                          <Personal
                            profileStore={profileFormStore}
                            countryRegionOptionsStore={countryRegionOptionsStore}
                          />
                          <Work
                            profileStore={profileFormStore}
                            countryRegionOptionsStore={countryRegionOptionsStore}
                            countryOptionsStore={countryOptionsStore}
                          />
                          <Social profileStore={profileFormStore} />
                          <div className={style.actions}>
                            <SubmitButton profileStore={profileFormStore} />
                          </div>
                        </form>
                      </div>
                    </ThemeContext.Provider>
                  </Main>
                  <aside
                    aria-label={translation('Profile Preview')(ts)}
                    className={style.profilePreview}
                    data-test-profile-preview={true}
                  >
                    <Preview store={profileFormStore} />
                  </aside>
                </div>
                <Footer />
              </div>
            </>
          )}
        </TranslationsContext.Consumer>
      );
  }
};

export default observer(ProfileEditView);
