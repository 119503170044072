import { ScrollBox, Skeleton } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SkeletonTileViewProgramExperience } from '../../../Common/ProgramExperience';
import { PromotableSection } from '../PromotableSection';

function SkeletonPromotableProductCollection() {
  return (
    <PromotableSection>
      <div className="mx-auto flex flex-col px-0 py-10 xl:container">
        <div className="flex flex-col gap-4 px-6 md:px-16">
          <Skeleton className="w-4/12" size="lg" />
          <Skeleton />
          <Skeleton className="w-8/12" />
        </div>
        <div className="px-6 md:px-16">
          <div className="overflow-visible">
            <div className="mt-8 flex w-full flex-col justify-between">
              <div className="hidden w-full grid-cols-4 gap-x-4 xl:grid">
                {Array.from({ length: 4 }, (_, index) => (
                  <SkeletonTileViewProgramExperience key={index} />
                ))}
              </div>
              <div className="hidden items-center justify-center gap-3 pt-6 xl:flex">
                {Array.from({ length: 3 }, (_, index) => (
                  <Skeleton key={index} className="size-3" variant="circle" />
                ))}
              </div>
              <ScrollBox
                className="-mx-2.5 -mb-2.5 flex pb-2.5 pt-3 xl:hidden"
                orientation="horizontal"
              >
                <div className="flex flex-shrink-0 gap-x-4 px-2.5">
                  {Array.from({ length: 4 }, (_, index) => (
                    <SkeletonTileViewProgramExperience
                      key={index}
                      className="w-[19.75rem] flex-shrink-0"
                    />
                  ))}
                </div>
              </ScrollBox>
            </div>
          </div>
        </div>
      </div>
    </PromotableSection>
  );
}

export default observer(SkeletonPromotableProductCollection);
