import { seconds } from '@execonline-inc/time';
import { Maybe } from 'maybeasy';
import { fromArrayMaybe, NonEmptyList } from 'nonempty-list';
import { ok } from 'resulty';
import { ConferenceRoomResource } from '../Conference/Types';
import { between, dateRange, every10Sec, everyMinute, isBefore, isSameDay } from '../Date';
import { Error } from '../ErrorHandling';
import { EventPollsResource } from '../EventPolls/Types';
import { warnAndNotify } from '../Honeybadger';
import { Linkable, Resource } from '../Resource/Types';
import { DocumentAssetResource } from '../SegmentStore/Types';
import { TPlainTextKey } from '../Translations';
import { calendarEventStore } from './CalendarEventStore';

interface Waiting {
  kind: 'waiting';
  linkable: Linkable;
}

interface Loading {
  kind: 'loading';
  linkable: Linkable;
}

interface Ready {
  kind: 'ready';
  eventsResource: EventsResource;
}

interface Refresh {
  kind: 'refresh';
  eventsResource: EventsResource;
}

export const waiting = (linkable: Linkable): Waiting => ({
  kind: 'waiting',
  linkable,
});

export const loading = (linkable: Linkable): Loading => ({
  kind: 'loading',
  linkable,
});

export const ready = (eventsResource: EventsResource): Ready => ({
  kind: 'ready',
  eventsResource,
});

export const refresh = (eventsResource: EventsResource): Refresh => ({
  kind: 'refresh',
  eventsResource,
});

export type EventsState = Waiting | Loading | Ready | Refresh | Error;

export type EventType =
  | 'Coaching'
  | 'Course'
  | 'Team'
  | 'Section'
  | 'EnterpriseGroup'
  | 'SegmentSession';

export type AccessKind = 'early' | 'default';

interface BaseEvent {
  id: number;
  title: string;
  uuid: string;
  startAt: Date;
  notificationsActiveAt: Date;
  earlyAccessAt: Maybe<Date>;
  endAt: Date;
  description: string;
  agenda: Maybe<string>;
  conferenceRoom: Maybe<ConferenceRoomResource>;
  embeddedDocumentAssets: DocumentAssetResource[];
  polls: EventPollsResource;
  type: EventType;
}

export interface Event extends BaseEvent {
  live: boolean;
  actionLabel: Maybe<string>;
}

export type EventsResource = Resource<EventResource[]>;
export type EventResource = Resource<Event>;

export const startsOn = (date: Date) => (event: EventResource): boolean =>
  isSameDay(date, event.payload.startAt);

export const isUpcoming = (event: EventResource): boolean =>
  isBefore(everyMinute.get(), event.payload.startAt);

interface Startable {
  startAt: Date;
}

interface Endable {
  endAt: Date;
}

export function isCurrent(event: Startable & Endable): boolean {
  const { startAt, endAt } = event;

  const secs = seconds(startAt.getTime() - new Date().getTime());
  const now: Date = secs.seconds < 59 ? every10Sec.get() : everyMinute.get();

  return dateRange(startAt, endAt)
    .map(between(now))
    .orElse((err) => {
      warnAndNotify('EventStore', err.message, event);
      return ok(false);
    })
    .getOrElseValue(false);
}

export const isLive = (event: EventResource): boolean => event.payload.live;

export const onlyUpcoming = (events: EventResource[]): Maybe<NonEmptyList<EventResource>> =>
  fromArrayMaybe(events.filter(isUpcoming));

export const onlyCurrent = (events: EventResource[]): Maybe<NonEmptyList<EventResource>> =>
  fromArrayMaybe(events.filter((e) => isCurrent(e.payload)));

export const onlyLive = (events: EventResource[]): Maybe<NonEmptyList<EventResource>> =>
  fromArrayMaybe(events.filter(isLive));

export const isLiveEventResource = (r: EventResource): boolean => {
  switch (calendarEventStore(r).event.kind) {
    case 'current-coaching-event':
    case 'upcoming-event':
    case 'live-event':
      return true;
    case 'past-event':
    case 'other-event':
      return false;
  }
};

export const findLiveEventResource = (
  resources: NonEmptyList<EventResource>
): Maybe<EventResource> => resources.find(isLiveEventResource);

export interface CustomActionLabel {
  kind: 'custom-action-label';
  customLabel: string;
}

export interface DefaultActionLabel {
  kind: 'default-action-label';
  defaultLabel: TPlainTextKey;
}

export type ActionLabel = CustomActionLabel | DefaultActionLabel;

const customActionLabel = (customLabel: string): CustomActionLabel => ({
  kind: 'custom-action-label',
  customLabel,
});

const defaultActionLabel = (defaultLabel: TPlainTextKey): DefaultActionLabel => ({
  kind: 'default-action-label',
  defaultLabel,
});

type EventSpan = 'single-day' | 'multi-day';

interface BaseCalendarEvent extends BaseEvent {
  span: EventSpan;
}

const span = (eventResource: EventResource): EventSpan => {
  const { startAt, endAt } = eventResource.payload;

  if (isSameDay(startAt, endAt)) {
    return 'single-day';
  } else {
    return 'multi-day';
  }
};

export interface PastEvent extends BaseCalendarEvent {
  kind: 'past-event';
}

export interface OtherEvent extends BaseCalendarEvent {
  kind: 'other-event';
}

export interface LiveEvent extends BaseCalendarEvent {
  kind: 'live-event';
  actionLabel: ActionLabel;
}

export interface UpcomingCoachingEvent extends BaseCalendarEvent {
  kind: 'upcoming-event';
  actionLabel: ActionLabel;
}

export interface CurrentCoachingEvent extends BaseCalendarEvent {
  kind: 'current-coaching-event';
  actionLabel: ActionLabel;
}

export type VideoEvent = LiveEvent | CoachingEvent;
export type CoachingEvent = UpcomingCoachingEvent | CurrentCoachingEvent;
export type CalendarEvent =
  | PastEvent
  | OtherEvent
  | LiveEvent
  | UpcomingCoachingEvent
  | CurrentCoachingEvent;

export const liveEvent = (eventResource: EventResource): LiveEvent => ({
  kind: 'live-event',
  span: span(eventResource),
  ...eventResource.payload,
  actionLabel: eventResource.payload.actionLabel
    .map<ActionLabel>(customActionLabel)
    .getOrElseValue(defaultActionLabel('Join Meeting')),
});

export const upcomingEvent = (eventResource: EventResource): UpcomingCoachingEvent => ({
  kind: 'upcoming-event',
  span: span(eventResource),
  ...eventResource.payload,
  actionLabel: eventResource.payload.actionLabel
    .map<ActionLabel>(customActionLabel)
    .getOrElseValue(defaultActionLabel('Join Meeting')),
});

export const currentEvent = (eventResource: EventResource): CurrentCoachingEvent => ({
  kind: 'current-coaching-event',
  span: span(eventResource),
  ...eventResource.payload,
  actionLabel: eventResource.payload.actionLabel
    .map<ActionLabel>(customActionLabel)
    .getOrElseValue(defaultActionLabel('Join Meeting')),
});

export const otherEvent = (eventResource: EventResource): OtherEvent => ({
  kind: 'other-event',
  span: span(eventResource),
  ...eventResource.payload,
});

export const pastEvent = (eventResource: EventResource): PastEvent => ({
  kind: 'past-event',
  span: span(eventResource),
  ...eventResource.payload,
});
