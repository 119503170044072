import { Kettle } from 'kettle-corn';
import { fromNullable } from 'maybeasy';
import * as React from 'react';
import { SourceFile, videoSourceFile } from '../JWPlayer/Types';
import JWPlayer from './JWPlayer';
import { videoPlayerOptions } from './JWPlayer/Types';
import { adaptiveTracks } from './Resource';
import { VideoAsset, VideoAssetResource } from './Types';

interface Props {
  id: string;
  className: string;
  videoResource: VideoAssetResource;
  kettle: Kettle;
  width: number | string;
  height: number | string;
  dynamicallySetAspectRatio?: boolean;
}

/**
 * AdaptivePlayer wraps JWPlayer and defaults to attempting to play the
 * adaptive stream specified by the file property on the top-level of the
 * adaptive sources.
 *
 * By passing along fallback sources, JWPlayer will attempt those progressive
 * sources if the adaptive stream fails.
 */
const AdaptivePlayer: React.FC<Props> = ({
  id,
  className,
  videoResource,
  kettle,
  width,
  height,
  dynamicallySetAspectRatio,
}) => {
  const fallbackSources = (videoAsset: VideoAsset): SourceFile[] =>
    videoAsset.adaptiveSources
      .map<SourceFile[]>((adaptiveSources) => adaptiveSources.fallbackSources.map(videoSourceFile))
      .getOrElseValue([]);

  const options = videoPlayerOptions(videoResource, {
    width,
    height,
    dynamicallySetAspectRatio: fromNullable(dynamicallySetAspectRatio).getOrElseValue(false),
    file: videoResource.payload.adaptiveSources
      .map((adaptiveSources) => adaptiveSources.file)
      .getOrElseValue(''),
    tracks: adaptiveTracks(videoResource.payload),
  });

  return (
    <JWPlayer
      id={id}
      className={className}
      kettle={kettle}
      options={options}
      fallbackSources={fallbackSources(videoResource.payload)}
    />
  );
};

export default AdaptivePlayer;
