import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import DynamicHeading from '../../../Accessibility/DynamicHeading';
import { ProgramSegment } from '../../../ProgramStore/Types';
import { NotTranslated, T } from '../../../Translations';
import * as style from '../../ProgramSummary/style.module.css';

interface Props {
  segment: Maybe<ProgramSegment>;
  headingLevel?: 3 | 4 | 5 | 6;
}
const WhatsNext: React.FC<Props> = ({ segment, headingLevel = 4 }) => {
  return segment
    .map((programSegment) => (
      <dl className={style.dl}>
        <dt data-test-next-label={true}>
          <DynamicHeading level={headingLevel} className={style.subHeader}>
            <T kind="What's Next" />
          </DynamicHeading>
        </dt>
        <dd data-test-next-segment={programSegment.title}>
          <NotTranslated text={programSegment.title} />
        </dd>
      </dl>
    ))
    .getOrElseValue(<></>);
};
export default observer(WhatsNext);
