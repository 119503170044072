import * as React from 'react';
import EnrollNowButton from '../../EnrollNowButton';
import BeginOrResumeButton from '../../BeginOrResumeButton';
import ProgramStore from '../../../ProgramStore';

interface Props {
  programStore: ProgramStore;
}
const ProgramButton: React.FC<Props> = ({ programStore }) => {
  switch (programStore.experienceOrigin) {
    case 'commerce-direct-unpaid':
      return programStore.directRegistrationGuid
        .map((guid) => <EnrollNowButton guid={guid} />)
        .getOrElse(() => <BeginOrResumeButton programStore={programStore} />);
    case 'commerce-direct-paid':
    case 'unavailable':
      return <BeginOrResumeButton programStore={programStore} />;
  }
};

export default ProgramButton;
