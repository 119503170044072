import { observer } from 'mobx-react';
import { SvgIcon, SvgIconProps } from '@execonline-inc/execonline-ui';

function OnDemandProgramIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g>
        <defs>
          <linearGradient
            id="a"
            x1="20.5"
            x2="3"
            y1="-1"
            y2="21.5"
            gradientTransform="scale(1.2)"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00A0C4" />
            <stop offset="1" stopColor="#006177" />
          </linearGradient>
        </defs>
        <path
          fill="url(#a)"
          d="M5.75 3C4.2383 3 3 4.2383 3 5.75v12.5C3 19.7617 4.2383 21 5.75 21h12.5c1.5117 0 2.75-1.2383 2.75-2.75V5.75C21 4.2383 19.7617 3 18.25 3Zm3.4766 5.0781a.7308.7308 0 0 1 .371.086l6.0274 3.1718c.25.1328.4023.3868.4023.6641 0 .2773-.1523.5313-.4023.664l-6.0273 3.172a.7385.7385 0 0 1-.3477.0859c-.1328 0-.2695-.0352-.3867-.1094-.2266-.1367-.3633-.375-.3633-.6406V8.828c0-.2656.1367-.5039.3633-.6406a.7289.7289 0 0 1 .3633-.1094Zm0 0"
        />
      </g>
    </SvgIcon>
  );
}

export default observer(OnDemandProgramIcon);
