import { Card, CardProps, emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import { useExperienceContext } from '../../../Experience/ExperienceContext';
import { learningJourneyExperience } from '../../../Experience/Types';

const ProgramExperienceCard = forwardRef<HTMLDivElement, CardProps>(
  function ProgramExperienceCard(props, ref) {
    const { experienceResource } = useExperienceContext();

    return (
      <div ref={ref} tabIndex={-1} className="relative flex w-full flex-col overflow-visible">
        <>
          <Card {...props} />
          {learningJourneyExperience(experienceResource.payload)
            .map(() => (
              <div className="flex w-full flex-col items-center">
                <div className="h-2 w-[96%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
                <div className="h-2 w-[93%] rounded-b-[3px] bg-[#F4F4F4] shadow-program-card" />
              </div>
            ))
            .getOrElse(emptyFragment)}
        </>
      </div>
    );
  },
);

export default observer(ProgramExperienceCard);
