import { observer } from 'mobx-react';
import * as React from 'react';
import { ProgramResource } from '../../../ProgramsStore/Types';
import { AlreadyTranslated, T } from '../../../Translations';
import * as style from './style.module.css';

interface Props {
  programResource: ProgramResource;
}
const ProgramNotReady: React.FC<Props> = ({ programResource }) => (
  <div className={style.program} data-test-not-ready-program={true}>
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.header}>
          <h3 className={style.title} data-test-program-title={programResource.payload.title}>
            <AlreadyTranslated content={programResource.payload.title} />
          </h3>
        </div>
        <dl className={style.dl}>
          <dd>
            <T kind="Program Not Ready" />
          </dd>
        </dl>
      </div>
    </div>
  </div>
);

export default observer(ProgramNotReady);
