import { observer } from 'mobx-react';
import * as React from 'react';
import CommunitiesStore from '../../CommunitiesStore';
import CommunitiesReactions from '../../CommunitiesStore/CommunitiesReactions';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import CommunitiesImpl from './CommunitiesImpl';

class CommunitiesSummary extends React.Component {
  communitiesStore: CommunitiesStore = new CommunitiesStore();

  componentDidMount() {
    this.communitiesStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <>
            <CommunitiesImpl communitiesStore={this.communitiesStore} />
            <CommunitiesReactions
              store={this.communitiesStore}
              resourceWithCommunities={currentUserResource}
            />
          </>
        )}
      />
    );
  }
}

export default observer(CommunitiesSummary);
