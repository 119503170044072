import { observer } from 'mobx-react';
import * as React from 'react';
import { ResourcesDashboardResource } from '../Types';
import PersonalResourcesStore from './PersonalResourcesStore';
import PersonalResourcesReactions from './PersonalResourcesStore/PersonalResourcesReactions';
import PersonalResourcesView from './PersonalResourcesView';

interface Props {
  resource: ResourcesDashboardResource;
}

class PersonalizedToYou extends React.Component<Props> {
  store = new PersonalResourcesStore();

  componentDidMount() {
    this.store.load(this.props.resource.links);
  }

  render() {
    return (
      <>
        <PersonalResourcesView store={this.store} />
        <PersonalResourcesReactions store={this.store} />
      </>
    );
  }
}

export default observer(PersonalizedToYou);
