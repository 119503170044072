import { observer } from 'mobx-react';
import * as React from 'react';
import NoteStore from '../../../../NoteStore';
import NoteItem from '../../../Notes/NoteItem';

interface Props {
  noteStore: NoteStore;
}

@observer
class NoteForm extends React.Component<Props> {
  componentDidMount() {
    this.props.noteStore.new();
  }

  render() {
    return <NoteItem key={0} noteStore={this.props.noteStore} hideContext={true} />;
  }
}

export default NoteForm;
