import { noop } from '@kofno/piper';
import { Task } from 'taskarian';

export const conferenceWindowName = 'execonline-conference-event';

export const setWindowName = new Task<string, void>((reject, resolve) => {
  try {
    window.name = conferenceWindowName;
    resolve();
  } catch {
    reject('Failed to set the window name');
  }
  return noop;
});

export const clearWindowName = new Task<string, void>((reject, resolve) => {
  try {
    window.name = '';
    resolve();
  } catch {
    reject('Failed to clear the window name');
  }
  return noop;
});
