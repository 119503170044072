import LiveRegion from 'live-region';
import * as React from 'react';
import { sendUserDataToGtmWithPageTitle } from '../Analytics/Functions';
import { currentUserStore } from '../CurrentUser/Store';

interface Props {
  title: string;
}

const baseTitle = 'ExecOnline';
const liveRegion = new LiveRegion({
  ariaLive: 'assertive',
  role: 'log',
  ariaRelevant: 'additions',
  ariaAtomic: 'true',
});

class Title extends React.Component<Props, {}> {
  get fullTitle() {
    return `${this.props.title} - ${baseTitle}`;
  }

  componentDidMount() {
    document.title = this.fullTitle;
    liveRegion.announce(this.fullTitle);
    sendUserDataToGtmWithPageTitle(currentUserStore, this.fullTitle);
  }

  componentDidUpdate() {
    document.title = this.fullTitle;
    liveRegion.announce(this.fullTitle);
    sendUserDataToGtmWithPageTitle(currentUserStore, this.fullTitle);
  }

  componentWillUnmount() {
    document.title = baseTitle;
  }

  render() {
    return null;
  }
}

export default Title;
