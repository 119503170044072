import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import ConversationStore from '../../ConversationStore';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import ProgramChatStore from '../../ProgramChatStore';
import TeamStore from '../../TeamStore';
import ProgramChatImpl from '../ProgramProfile/ProgramChat/ProgramChatImpl';
import TeamChatImpl from '../TeamProfile/TeamChat/TeamChatImpl';

interface Props {
  conversationStore: ConversationStore;
  programChatStore: Maybe<ProgramChatStore>;
  teamStore: Maybe<TeamStore>;
  presenceStore: Maybe<GlobalPresenceMembersStore>;
  kind: 'team-chat' | 'program-chat';
}

const CommunitiesChatImpl: React.FC<Props> = ({
  conversationStore,
  programChatStore,
  teamStore,
  presenceStore,
  kind,
}) => (
  <WithCurrentUser
    children={(currentUserResource) => {
      switch (kind) {
        case 'program-chat':
          return programChatStore
            .map((programChatStore) => (
              <ProgramChatImpl
                conversationStore={conversationStore}
                programChatStore={programChatStore}
                presenceStore={presenceStore}
                currentUserResource={currentUserResource}
              />
            ))
            .getOrElse(() => <></>);
        case 'team-chat':
          return teamStore
            .map((teamStore) => (
              <TeamChatImpl
                conversationStore={conversationStore}
                teamStore={teamStore}
                presenceStore={presenceStore}
                currentUserResource={currentUserResource}
              />
            ))
            .getOrElse(() => <></>);
      }
    }}
  />
);

export default observer(CommunitiesChatImpl);
