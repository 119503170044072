import { assertNever } from '@kofno/piper';
import { toJS } from 'mobx';
import { Task } from 'taskarian';
import { AppyError, deleteToApi, postToApi } from '../../../Appy';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../../../ErrorActionableReaction';
import { warnAndNotify } from '../../../Honeybadger';
import { MissingLinkError, findLinkT } from '../../../LinkyLinky';
import { Link } from '../../../Resource/Types';
import MessageReactionStore, { State } from './MessageReactionStore';

export interface Props extends EAProps<MessageReactionStore> {
  store: MessageReactionStore;
  currentUserResource: CurrentUserResource;
}

type UpdateError = AppyError | MissingLinkError;

// Leaving room for more robust error handling later
const handleUpdateError =
  (store: MessageReactionStore, currentUserResource: CurrentUserResource) =>
  (error: UpdateError) => {
    switch (error.kind) {
      case 'missing-link-error':
        warnAndNotify('MessageReactionReactions', `Missing link: ${error.rel}`, {
          reactionStoreState: toJS(store.state),
          currentUserId: currentUserResource.payload.id.toString(),
        });
        break;
      default:
        handleError(store, error);
    }
  };

class MessageReactionReactions extends ErrorActionableReaction<MessageReactionStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State) => {
    const { store, currentUserResource } = this.props;
    switch (state.kind) {
      case 'adding-reaction':
        Task.succeed<UpdateError, ReadonlyArray<Link>>(store.baseResourceLinks)
          .andThen(findLinkT('create'))
          .andThen(postToApi({ reaction: state.emoji }))
          .fork(handleUpdateError(store, currentUserResource), store.ready);
        break;
      case 'removing-reaction':
        Task.succeed<UpdateError, ReadonlyArray<Link>>(state.reactionResource.links)
          .andThen(findLinkT('delete'))
          .andThen(deleteToApi)
          .fork(handleUpdateError(store, currentUserResource), store.ready);
        break;
      case 'ready':
      case 'error':
        break;
      default:
        assertNever(state);
    }
  };
}

export default MessageReactionReactions;
