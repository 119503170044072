import * as React from 'react';
import { TPlainTextKey, translation, TranslationsContext } from '../../../Translations';
import Option, { SelectOption } from './Option';

interface Props {
  value: string;
  onChange(event: React.ChangeEvent<HTMLSelectElement>): void;
  options: SelectOption[];
  className?: string;
  name: string;
  placeholder?: TPlainTextKey;
  id: string;
  disabled?: boolean;
  hidden?: boolean;
}

class Select extends React.PureComponent<Props, {}> {
  renderOptions = (options: SelectOption[]) => {
    return options.map((option: SelectOption) => {
      return <Option key={option.value} value={option.value} content={option.content} />;
    });
  };
  render() {
    return (
      <TranslationsContext.Consumer>
        {(ts) => (
          <select
            className={this.props.className}
            name={this.props.name}
            value={this.props.value}
            onChange={this.props.onChange}
            data-test-select={this.props.name}
            id={this.props.id}
            disabled={this.props.disabled}
          >
            {this.props.placeholder && (
              <option value="" hidden={this.props.hidden === undefined ? true : this.props.hidden}>
                {this.props.placeholder ? translation(this.props.placeholder)(ts) : undefined}
              </option>
            )}
            {this.renderOptions(this.props.options)}
          </select>
        )}
      </TranslationsContext.Consumer>
    );
  }
}

export default Select;
