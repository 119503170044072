import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { useEnrollmentModalContext } from '../../../Common';
import CancelButton from './CancelButton';
import EnrollNowButton from './EnrollNowButton';

function EnrollableFooter() {
  const { experienceResource, options } = useEnrollmentModalContext();

  switch (experienceResource.payload.offeringType) {
    case 'aep':
    case 'epc':
    case 'msuite':
    case 'coaching':
    case 'program-sequence':
      return (
        <>
          <CancelButton />
          {options.programFooterButtons.getOrElse(emptyFragment)}
          <EnrollNowButton />
        </>
      );
    case 'group-coaching':
      return (
        <>
          <CancelButton />
          <EnrollNowButton />
        </>
      );
  }
}

export default observer(EnrollableFooter);
