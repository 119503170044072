import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import ContentFaker from '../../ContentFaker';
import FlashMessage from '../../FlashMessage';
import BreadCrumb from '../BreadCrumb';
import ProgramResourcesModules from '../ProgramResourceList/ProgramResourcesModules';
import ProgramResourcesStore from '../ProgramResourceList/ProgramResourcesStore';
import ProgramWideResources from '../ProgramResourceList/ProgramWideResources';
import * as style from '../ProgramResourceList/style.module.css';

interface Props {
  store: ProgramResourcesStore;
}

const ProgramResourcesView: React.FC<Props> = ({ store }) => {
  switch (store.state.kind) {
    case 'ready':
      const programResourcesResource = store.state.programResourcesResource;
      return (
        <>
          <section className={style.section}>
            <BreadCrumb
              path="/resources/programs/:programId"
              title={<NotTranslated text={programResourcesResource.payload.title} />}
            />
          </section>
          <section className={style.section}>
            <ProgramWideResources resources={programResourcesResource.payload.resources} />
          </section>
          <section className={style.section}>
            <ProgramResourcesModules headingLevel={2} store={store} />
          </section>
        </>
      );
    case 'waiting':
    case 'loading':
      return <ContentFaker />;
    case 'error':
      return store.notification
        .map((alert) => <FlashMessage alert={alert} />)
        .getOrElseValue(<></>);
  }
};

export default observer(ProgramResourcesView);
