import { first } from '@execonline-inc/collections';
import { warn, warnAndNotify as warnAndNotifyImpl } from '@execonline-inc/logging';
import { when } from '@execonline-inc/maybe-adapter';
import { BacktraceFrame, Notice } from '@honeybadger-io/core/build/src/types';
import { default as Honeybadger } from '@honeybadger-io/js';
import { always } from '@kofno/piper';
import { fromEmpty, fromNullable, just, Maybe } from 'maybeasy';

interface Config {
  apiKey: Maybe<string>;
  environment: Maybe<string>;
  revision: Maybe<string>;
}

export const configureHoneybadger = ({ apiKey, environment, revision }: Config): void => {
  apiKey.cata({
    Nothing: () => warn('Honeybadger not configured'),
    Just: (apiKey) => {
      Honeybadger.configure({
        apiKey,
        environment: environment.getOrElseValue('development'),
        revision: revision.andThen(fromEmpty).getOrElseValue('unknown'),
        debug: true,
      });
    },
  });
};

const whenFrameSourceInvalid = when<BacktraceFrame>((frame) =>
  ['file://', 'snippet://', '<anonymous>'].some((prefix) => frame.file.startsWith(prefix)),
);

const whenNoticeIsNoise = (notice: Notice): Maybe<Notice> =>
  just(notice.backtrace).andThen(first).andThen(whenFrameSourceInvalid).map(always(notice));

Honeybadger.beforeNotify((notice): boolean =>
  fromNullable(notice).andThen(whenNoticeIsNoise).map(always(false)).getOrElseValue(true),
);

const notifier: ReturnType<typeof warnAndNotifyImpl> = (
  errorName: string,
  message: string,
  context: {},
) => Honeybadger.notify(errorName, message, context);

export const warnAndNotify = warnAndNotifyImpl(notifier);

export { Honeybadger };
