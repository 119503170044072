import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';

const DeliveryInfoIcon: React.FC = () => (
  <SVGIcon viewBox="0 0 22 22">
    <path
      fill="#fff"
      d="M11 0C4.935 0 0 4.935 0 11s4.935 11 11 11 11-4.935 11-11S17.065 0 11 0Zm0 1.692A9.296 9.296 0 0 1 20.308 11 9.296 9.296 0 0 1 11 20.308 9.296 9.296 0 0 1 1.692 11 9.296 9.296 0 0 1 11 1.692Zm-.846 4.231v1.692h1.692V5.923h-1.692Zm0 3.385v6.769h1.692v-6.77h-1.692Z"
    />
  </SVGIcon>
);

export default observer(DeliveryInfoIcon);
