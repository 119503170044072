import * as React from 'react';
import { observer } from 'mobx-react';
import ProgramMembersReactions from '../../ProgramMembersStore/ProgramMembersReactions';
import LoadProgramMember from './LoadProgramMember';
import LoadMoreButtonImpl from './LoadMoreButtonImpl';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

const LoadMoreProgramMembers: React.FC<Props> = ({ presenceMembersStore }) => (
  <>
    <LoadProgramMember presenceMembersStore={presenceMembersStore} />
    <LoadMoreButtonImpl programMembersStore={presenceMembersStore.programMembersStore} />
    <ProgramMembersReactions
      store={presenceMembersStore.programMembersStore}
      programMembers={presenceMembersStore.members}
      fireImmediately={true}
    />
  </>
);

export default observer(LoadMoreProgramMembers);
