import { distanceFromDate } from '@execonline-inc/time-distance';
import { TParameterizedKey } from '../../../../../../Translations';

export type ExperienceDescription = Extract<
  TParameterizedKey,
  | 'This experience began <startOnDate/> and ends on <endOnDate/>. '
  | 'This experience starts on <startOnDate/> and ends on <endOnDate/>. '
>;

export const experienceDescription = ({ startOn }: { startOn: Date }): ExperienceDescription =>
  distanceFromDate(startOn, new Date())
    .map<ExperienceDescription>((vector) => {
      switch (vector.direction) {
        case 'future':
          return 'This experience starts on <startOnDate/> and ends on <endOnDate/>. ';
        case 'now':
        case 'past':
          return 'This experience began <startOnDate/> and ends on <endOnDate/>. ';
      }
    })
    .getOrElseValue('This experience began <startOnDate/> and ends on <endOnDate/>. ');
