import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Button } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import Exit from '../../../../../Exit';
import ExitStore from '../../../../Common/Experience/Actions/Exitable/ExitStore';
import { exitToDashboard } from '../../../../Common/Experience/Actions/Exitable/Types';

interface Props {
  buttonText: 'Begin Your Experience' | 'Resume Your Experience' | 'Return to Experience';
}

function AlreadyEnrolledButton({ buttonText }: Props) {
  const exitStore = useMemo(() => new ExitStore(), []);

  switch (exitStore.state.kind) {
    case 'ready':
      return (
        <Button
          radius="md"
          variant="text"
          size="lg"
          className="mt-20 min-w-full bg-white text-black sm:mt-14 sm:min-w-48"
          onClick={() => exitStore.exit(exitToDashboard())}
        >
          <T kind={buttonText} />
        </Button>
      );
    case 'exiting':
      return <Exit exitContext={exitStore.state.exitContext} />;
  }
}

export default observer(AlreadyEnrolledButton);
