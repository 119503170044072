import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from './style.module.css';
import SurveyModalStore from './Modal/Store';
import { T } from '../../Translations';
import ArrowRightIcon from '../Cutesies/ArrowRightIcon';

interface Props {
  store: SurveyModalStore;
}

const ReopenSurveyButton: React.FC<Props> = ({ store }) => {
  const reopenSurvey = () => {
    store.reopenSurvey();
  };

  switch (store.state.kind) {
    case 'waiting':
    case 'survey-closed-and-complete':
    case 'survey-closed-and-dismissed':
    case 'survey-undo-dismiss':
    case 'showing-survey':
    case 'never-show-survey':
      return <></>;
    case 'survey-dismissed':
      return (
        <button className={style.buttonSecondaryWithIcon} onClick={reopenSurvey}>
          <T kind="Provide feedback" />
          <div className={style.buttonIcon}>
            <ArrowRightIcon />
          </div>
        </button>
      );
  }
};

export default observer(ReopenSurveyButton);
