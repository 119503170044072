import { observer } from 'mobx-react';
import { Chip, ChipProps, Typography, cn } from '@execonline-inc/execonline-ui';

function ExperienceCompetencyChip({ children, className, ...others }: ChipProps) {
  return (
    <Chip className={cn('py-1', className)} variant="outlined" color="gray" size="sm" {...others}>
      <Typography className="tracking-[0.2px]" variant="caption" fontWeight="medium">
        {children}
      </Typography>
    </Chip>
  );
}

export default observer(ExperienceCompetencyChip);
