import { observer } from 'mobx-react';
import { LearnMoreAction } from '../../../../Experience/ExperienceActions';
import { useEnrollmentModalContext } from '../../../Common';

function LearnMoreButton() {
  const { invitationUuid, experienceResource } = useEnrollmentModalContext();

  return (
    <LearnMoreAction invitationUuid={invitationUuid} experience={experienceResource.payload} />
  );
}

export default observer(LearnMoreButton);
