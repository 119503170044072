import { emptyFragment } from '@execonline-inc/execonline-ui';
import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { AlreadyTranslatedText } from '../../../../../Translations';

interface Props {
  publicDetailedDescriptionHtml: Maybe<AlreadyTranslatedText>;
}

const ExperienceDescription: React.FC<Props> = ({ publicDetailedDescriptionHtml }) =>
  publicDetailedDescriptionHtml
    .map((descriptionHtml) => <div className="pr-5">{ReactHtmlParser(descriptionHtml.text)}</div>)
    .getOrElse(emptyFragment);

export default observer(ExperienceDescription);
