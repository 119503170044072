import { fromArray, NonEmptyList } from 'nonempty-list';
import { Result } from 'resulty';
import {
  asWeeks,
  dateRange,
  DateRange,
  eachDay,
  endOfDay,
  endOfWeek,
  everyMinute,
  firstDayOfMonth,
  InvalidDateRange,
  isAfter,
  isBefore,
  isSameDay,
  lastDayOfMonth,
  NotDivisibleIntoWeeks,
  startOfDay,
  startOfWeek,
  Week,
  WeekStarter,
} from '../Date';

interface CurrentMonthDay {
  kind: 'current-month-day';
  date: Date;
}

export const currentMonthDay = (date: Date): CurrentMonthDay => ({
  kind: 'current-month-day',
  date,
});

interface NextMonthDay {
  kind: 'next-month-day';
  date: Date;
}

export const nextMonthDay = (date: Date): NextMonthDay => ({ kind: 'next-month-day', date });

interface PreviousMonthDay {
  kind: 'previous-month-day';
  date: Date;
}

export const previousMonthDay = (date: Date): PreviousMonthDay => ({
  kind: 'previous-month-day',
  date,
});

interface Today {
  kind: 'today';
  date: Date;
}

export const today = (date: Date): Today => ({ kind: 'today', date });

export type CalendarDay = Today | PreviousMonthDay | NextMonthDay | CurrentMonthDay;

export type CalendarWeek = [
  CalendarDay,
  CalendarDay,
  CalendarDay,
  CalendarDay,
  CalendarDay,
  CalendarDay,
  CalendarDay
];

export const weekKey = ([beginning, ..._]: CalendarWeek) => `week-${beginning.date.getTime()}`;

export interface CalendarMonth {
  weeks: NonEmptyList<CalendarWeek>;
}

interface NotEnoughWeeksInMonth {
  kind: 'not-enough-weeks-in-month';
  message: string;
}

const notEnoughWeeksInMonth = (message: string): NotEnoughWeeksInMonth => ({
  kind: 'not-enough-weeks-in-month',
  message,
});

const calendarMonthCtor = (dayOfMonth: Date) => (
  weeks: ReadonlyArray<Week>
): Result<CalendarMonthError, CalendarMonth> =>
  fromArray(weeks.map(toCalendarWeek(dayOfMonth)))
    .mapError(notEnoughWeeksInMonth)
    .map(weeks => ({ weeks }));

const toCalendarWeek = (dayOfMonth: Date) => (week: Week): CalendarWeek => {
  return week.map(asCalendarDay(dayOfMonth)) as CalendarWeek;
};

const asCalendarDay = (dayOfMonth: Date) => (thisDate: Date): CalendarDay => {
  const startOfMonth = startOfDay(firstDayOfMonth(dayOfMonth));
  const endOfMonth = endOfDay(lastDayOfMonth(dayOfMonth));

  if (isBefore(thisDate, startOfMonth)) {
    return previousMonthDay(thisDate);
  } else if (isAfter(thisDate, endOfMonth)) {
    return nextMonthDay(thisDate);
  } else if (isSameDay(everyMinute.get(), thisDate)) {
    return today(thisDate);
  } else {
    return currentMonthDay(thisDate);
  }
};

type CalendarMonthError = NotDivisibleIntoWeeks | InvalidDateRange | NotEnoughWeeksInMonth;

export const calendarMonth = (
  weekStartsOn: WeekStarter,
  aDayOfTheMonth: Date
): Result<CalendarMonthError, CalendarMonth> => {
  const firstDay = startOfWeek(weekStartsOn, startOfDay(firstDayOfMonth(aDayOfTheMonth)));
  const lastDay = endOfWeek(weekStartsOn, endOfDay(lastDayOfMonth(aDayOfTheMonth)));
  return (dateRange(firstDay, lastDay) as Result<CalendarMonthError, DateRange>)
    .map(eachDay)
    .andThen(asWeeks)
    .andThen(calendarMonthCtor(aDayOfTheMonth));
};
