import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import Currency from '../../../../../../Currency';
import { ExperienceOverviewChip } from '../../../Common';

interface Props {
  price: Maybe<number>;
}

function ExperiencePrice({ price }: Props) {
  return price
    .map((price) => (
      <ExperienceOverviewChip>
        <Currency priceInDollars={price / 100} />
      </ExperienceOverviewChip>
    ))
    .getOrElse(emptyFragment);
}

export default observer(ExperiencePrice);
