import { T } from '../../../Translations';
import PersonIcon from '../../Cutesies/Person';
import style from './styles.module.css';

const YourCoachPlaceholder = () => {
  return (
    <div className={style.blockWrapper}>
      <p className={style.programSecondaryTitle}>
        <T kind="Your coach" />
      </p>
      <div className={style.sessionBlock}>
        <div className={style.coachPlaceholder}>
          <PersonIcon />
        </div>
        <div className={style.coachTitle}>
          <p>
            <T kind="No coach set for further sessions" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default YourCoachPlaceholder;
