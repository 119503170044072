import { assertNever } from '@kofno/piper';
import { just, Maybe, nothing } from 'maybeasy';
import { action, computed, observable } from 'mobx';
import { ExperienceResource } from '../../Common/Experience/Types';
import { RegisterResource } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { error, loading, ready, State, waiting } from './Types';

class ExperienceDetailViewStore {
  @observable
  state: State = waiting();

  @action
  loading = (experienceId: string, registerResource: RegisterResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        this.state = loading(experienceId, registerResource);
        break;
      case 'loading':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (experienceResource: ExperienceResource): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        this.state = ready(experienceResource);
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: string): void => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        this.state = error(message);
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get experienceResource(): Maybe<ExperienceResource> {
    switch (this.state.kind) {
      case 'ready':
        return just(this.state.experienceResource);
      case 'waiting':
      case 'loading':
      case 'error':
        return nothing();
    }
  }
}

export default ExperienceDetailViewStore;
