import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import ReactHtmlParser from 'react-html-parser';
import { NotTranslated } from '../../../Translations';
import DocumentIcon from '../../Cutesies/DocumentIcon';
import DownArrowIcon from '../../Cutesies/DownArrowIcon';
import DownloadViewModal from '../../DownloadViewModal';
import { useOpenable } from '../../Openable';
import { DocumentResource } from './Types';
import * as style from './style.module.css';

interface Props {
  resource: DocumentResource;
}

function DocumentResourceView({ resource }: Props) {
  const { openableState, open, close } = useOpenable();

  return (
    <div className={style.resource}>
      <a className={style.content} onClick={open} data-test-document-resource={true}>
        <div className={style.icon}>
          <DocumentIcon />
        </div>
        <div className={style.resourceDetails}>
          <span className={style.name} data-test-resource-title={true}>
            <NotTranslated text={resource.payload.title} />{' '}
            {resource.payload.assetFileExtension
              .map((assetFileExtension) => (
                <span className={style.metaData}>
                  <NotTranslated text={assetFileExtension} />
                </span>
              ))
              .getOrElse(emptyFragment)}
          </span>
          {resource.payload.description
            .map((description) => (
              <div className={style.description}>{ReactHtmlParser(description)}</div>
            ))
            .getOrElse(emptyFragment)}
        </div>
        <div className={style.download}>
          <div className={style.downloadIcon}>
            <DownArrowIcon />
          </div>
        </div>
      </a>
      <DownloadViewModal resource={resource} close={close} modalState={openableState} />
    </div>
  );
}

export default observer(DocumentResourceView);
