import { observer } from 'mobx-react';
import { handleEnterOrSpaceKeyPress } from '../../Accessibility/KeyboardEvents';
import { calendarEventStore } from '../../EventsStore/CalendarEventStore';
import { EventResource } from '../../EventsStore/Types';
import { L, NotTranslated } from '../../Translations';
import EventDetails from '../EventDetails';
import { useOpenable } from '../Openable';
import EventTimes from './EventTimes';
import * as style from './style.module.css';

interface Props {
  eventResource: EventResource;
}

function EventSummary({ eventResource }: Props) {
  const { openableState, open, close } = useOpenable();

  const event = eventResource.payload;

  return (
    <>
      <div
        className={style.event}
        onClick={open}
        data-test-event-summary={event.id}
        role="button"
        onKeyPress={handleEnterOrSpaceKeyPress(open)}
        tabIndex={0}
      >
        <div className={style.date}>
          <div className={style.month}>
            <L localizeable={event.startAt} format="short-month" />
          </div>
          <div className={style.day}>{event.startAt.getDate()}</div>
        </div>
        <div className={style.details} data-test-event-details={true}>
          <p className={style.title} data-test-event-title={event.title}>
            <NotTranslated text={event.title} />
          </p>
          <div className={style.timestamp} data-test-event-datetime={true}>
            <EventTimes store={calendarEventStore(eventResource)} />
          </div>
        </div>
      </div>
      <EventDetails modalState={openableState} close={close} event={eventResource} />
    </>
  );
}

export default observer(EventSummary);
