import { observer } from 'mobx-react';
import { Maybe } from 'maybeasy';
import { Stripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { PaymentCheckoutFormStore } from '../../../Common';
import PaymentCardCharge from '../PaymentCardCharge';
import PaymentCardSelect from '../PaymentCardSelect';
import PaymentCheckoutButton from '../PaymentCheckoutButton';

interface Props {
  store: PaymentCheckoutFormStore;
  price: Maybe<number>;
  stripe: Stripe;
  onSubmit: (e: React.FormEvent) => void;
}

function PaymentCardForm({ store, onSubmit, price, stripe }: Props) {
  switch (store.state.kind) {
    case 'waiting':
      return <PaymentCardSelect store={store} />;
    case 'ready-for-payment-confirmation':
    case 'payment-declined':
    case 'confirming-payment':
      return (
        <form className="flex h-full w-full flex-grow flex-col" onSubmit={onSubmit}>
          <PaymentElement className="flex flex-grow flex-col" />
          <div className="mt-12 sm:mt-4">
            <PaymentCardCharge price={price} />
            <PaymentCheckoutButton stripe={stripe} state={store.state} />
          </div>
        </form>
      );
  }
}

export default observer(PaymentCardForm);
