import { useContext } from 'react';
import { CurrentUserContext } from '../Context';

function useCurrentUserContext() {
  const context = useContext(CurrentUserContext);

  if (!context) {
    throw new Error('useCurrentUserContext has to be used within the CurrentUserContext.Provider.');
  }

  return context;
}

export default useCurrentUserContext;
