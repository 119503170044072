import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from '../../../Resource/Types';
import { T } from '../../../Translations';
import * as style from '../style.module.css';

interface Props {
  link: Link;
}

const LinkToSupport: React.FC<Props> = ({ link }) => {
  return (
    <p className="text-black">
      <T
        kind="If you need additional help, you can <link>view ..."
        link={(content) => (
          <a href={link.href} title={link.rel}>
            {content}
          </a>
        )}
        emailAddress={
          <a className={style.mailToLinkLightTheme} href="mailto:support@execonline.com">
            support@execonline.com
          </a>
        }
      />
    </p>
  );
};

export default observer(LinkToSupport);
