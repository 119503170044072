import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import * as React from 'react';
import { handleEnterKeyPress, handleEscKeyDown } from '../../Accessibility/KeyboardEvents';
import clickOutsideStore from '../../ClickOutsideStore';
import ListItem from './ListItem';
import * as style from './style.module.css';
import { DropdownListItem } from './Types';

type State = {
  isOpen: boolean;
};

type StyleKind = 'responsive' | 'default';
type StyleKindType = { [key in StyleKind]: string };

export interface Props {
  header: JSX.Element;
  list: ReadonlyArray<DropdownListItem>;
  menuAlignment: 'left' | 'below' | 'right' | 'rightClose';
  ariaLabelledby?: string;
  styleKind: StyleKind;
  dropdownTitle?: JSX.Element;
}

class Dropdown extends React.Component<Props> {
  dropdownRef: React.RefObject<HTMLDivElement> = React.createRef();
  state: State = {
    isOpen: false,
  };

  close = () => {
    this.setState({
      isOpen: false,
    });
  };

  toggle = () => {
    const { isOpen } = this.state;
    clickOutsideStore.addListener({ ref: this.dropdownRef, callback: this.close });
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { isOpen } = this.state;

    const classStyleByType: StyleKindType = {
      default: 'px-1',
      responsive: 'w-full lg:min-w-full h-screen lg:h-fit lg:max-h-72 lg:min-w-max overflow-y-auto',
    };

    // This is meant to fix an accessibility report item that is only an issue in the old UI
    // We can remove it when the old UI is deprecated
    const uniqueId: string = 'dropdown-container-' + Math.random().toString(16).slice(-4);

    return (
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          escapeDeactivates: false,
          allowOutsideClick: true,
          fallbackFocus: '#dropdown-container',
        }}
      >
        <div
          onClick={this.toggle}
          ref={this.dropdownRef}
          data-test-dropdown={true}
          id={uniqueId}
          tabIndex={-1}
        >
          <div
            data-test-dropdown-header={this.props.header}
            tabIndex={0}
            onKeyPress={handleEnterKeyPress(this.toggle)}
            onKeyDown={handleEscKeyDown(this.close)}
          >
            {this.props.header}
          </div>
          {this.state.isOpen && (
            <>
              <div
                className={clsx(style.waffleDropdownShadow, [
                  'absolute z-10 py-2',
                  'rounded-md border-slate-300',
                  'min-w-max list-none bg-white',
                  `${style[this.props.menuAlignment]}`,
                  classStyleByType[this.props.styleKind],
                ])}
                onKeyDown={handleEscKeyDown(this.close)}
                aria-labelledby={this.props.ariaLabelledby}
              >
                {this.props.dropdownTitle}
                <ul>
                  {this.props.list.map((item) => {
                    switch (item.kind) {
                      case 'use-case-item':
                        return <ListItem key={item.name.text} item={item} />;
                      case 'action-list-item':
                      case 'icon-list-item':
                      case 'waffle-icon-list-item':
                      case 'link-list-item':
                        return <ListItem key={item.name} item={item} />;
                    }
                  })}
                </ul>
              </div>
            </>
          )}
        </div>
      </FocusTrap>
    );
  }
}

export default Dropdown;
