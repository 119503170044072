import { format } from 'date-fns/fp';
import { observer } from 'mobx-react';
import * as React from 'react';
import { StartDate as TStartDate } from '../../TeamsSummaryStore/Types';

interface Props {
  startDate: TStartDate;
}

const StartDate: React.FC<Props> = ({ startDate }) => {
  switch (startDate.kind) {
    case 'date-string':
      return <span>{format('MMMM dd, yyyy', startDate.date)}</span>;
    case 'on-demand':
      return <span>On Demand</span>;
    case 'starts-anytime':
      return <span />;
  }
};

export default observer(StartDate);
