import * as React from 'react';
import { ExpandableItem } from '../../../../../ExpandableItemStore';
import { ActiveProgramModule } from '../../../../../ProgramStore/Types';
import Toggle from './Toggle';
import ToggleGate from './ToggleGate';

interface Props {
  expandableModule: ExpandableItem<ActiveProgramModule>;
}

const ExpandModule: React.FC<Props> = ({ expandableModule }) => {
  if (expandableModule.item.isGated) {
    return <ToggleGate expandableModule={expandableModule} />;
  }
  return <Toggle expandableModule={expandableModule} />;
};

export default ExpandModule;
