import { observer } from 'mobx-react';
import * as React from 'react';
import { TranslationsContext, TranslationsState } from '@execonline-inc/translations';
import { warnAndNotify } from '../Honeybadger';
import { assertNever } from '@kofno/piper';

export interface Props {
  priceInDollars: number;
  options?: Intl.NumberFormatOptions;
}

const numberFormatter = (language: string, options: Intl.NumberFormatOptions) =>
  new Intl.NumberFormat(language, options).format;

const noDecimals = (language: string, value: number, options: Intl.NumberFormatOptions): string =>
  numberFormatter(language, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  })(value);

const decimals = (language: string, value: number, options: Intl.NumberFormatOptions): string =>
  numberFormatter(language, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  })(value);

const localization = (
  language: string,
  price: number,
  options: Intl.NumberFormatOptions,
): string => {
  return price % 1 === 0
    ? noDecimals(language, price, options)
    : decimals(language, price, options);
};

const localizer = (price: number, options: Intl.NumberFormatOptions) => (ts: TranslationsState) => {
  switch (ts.kind) {
    case 'loaded-from-fallback':
    case 'loaded':
      return localization(ts.language, price, options);
    case 'uninitialized':
      warnAndNotify('currencyLocalizationError', 'localization is uninitialized', price);
      return String(price);
    default:
      assertNever(ts);
  }
};

const Currency: React.FC<Props> = ({ priceInDollars, options = {} }) => (
  <TranslationsContext.Consumer>
    {(ts) => localizer(priceInDollars, options)(ts)}
  </TranslationsContext.Consumer>
);

export default observer(Currency);
