import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LectureSegment } from '../../../../LectureStore/Types';
import CurrentSlidePpt from './CurrentSlidePpt';

interface Props {
  segment: LectureSegment;
  kettle: Kettle;
  refWrapperEl: React.RefObject<HTMLDivElement>;
  pptRefEl: React.RefObject<HTMLIFrameElement>;
  pptSizerEl: React.RefObject<HTMLDivElement>;
}

@observer
class CurrentSlide extends React.Component<Props, {}> {
  render() {
    const { segment, kettle, pptRefEl, refWrapperEl, pptSizerEl } = this.props;
    return (
      <CurrentSlidePpt
        segment={segment}
        kettle={kettle}
        refEl={pptRefEl}
        refWrapperEl={refWrapperEl}
        pptSizerEl={pptSizerEl}
      />
    );
  }
}

export default CurrentSlide;
