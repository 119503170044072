import { observer } from 'mobx-react';
import { Typography } from '@execonline-inc/execonline-ui';
import { T } from '../../../../../../Translations';
import CompetenciesSection from '../CompetenciesSection';

function LeadershipCoachingCompetencies() {
  return (
    <CompetenciesSection>
      <Typography className="rounded bg-neutral-100 px-2.5 py-2" variant="caption">
        <T kind="Leadership coaching addresses all competencies in the ExecOnline catalog in addition to the individualized development needs of the participant." />
      </Typography>
    </CompetenciesSection>
  );
}

export default observer(LeadershipCoachingCompetencies);
