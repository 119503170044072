import { observer } from 'mobx-react';
import { AlreadyTranslated } from '@execonline-inc/translations';
import { Typography } from '@execonline-inc/execonline-ui';
import { useExperienceContext } from '../../../Experience/ExperienceContext';
import { ExperiencesHeaderLogo } from '../Common';
import ExperienceBodyContent from './ExperienceBodyContent';

function ExperienceBody() {
  const { experienceResource } = useExperienceContext();

  return (
    <div className="flex flex-auto flex-col gap-4 lg:flex-row">
      <div className="order-2 flex flex-col lg:order-1">
        <div className="flex flex-col gap-y-3">
          <Typography
            className="cursor-pointer text-gray-800"
            variant="bodyMedium"
            as="h5"
            fontWeight="medium"
          >
            <AlreadyTranslated content={experienceResource.payload.title} />
          </Typography>
          <ExperienceBodyContent experienceResource={experienceResource} />
        </div>
      </div>
      <div className="order-1 flex flex-shrink-0 empty:hidden lg:order-2 lg:ml-auto">
        <ExperiencesHeaderLogo experience={experienceResource.payload} />
      </div>
    </div>
  );
}

export default observer(ExperienceBody);
