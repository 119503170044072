import { observer } from 'mobx-react';
import * as React from 'react';
import { NotTranslated } from '../../../Translations';
import Modal from '../../Modal';
import ModalStore from '../../Modal/Store';

interface Props {
  store: ModalStore;
  title: string;
  details: string;
}

const DetailsModal: React.FC<Props> = ({ store, title, details }) => {
  switch (store.state.kind) {
    case 'open':
      return (
        <Modal close={store.close} title={title}>
          <NotTranslated text={details} />
        </Modal>
      );
    case 'closed':
      return <></>;
  }
};
export default observer(DetailsModal);
