import { find } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChatMessageReactionResource } from '../../../ConversationStore/Types';
import { CurrentUserResource } from '../../../CurrentUser/Types';
import { NotTranslated } from '../../../Translations';
import MessageReactionStore from './MessageReactionStore';
import * as style from './style.module.css';

export interface ReactionListItem {
  emoji: string;
  chatMessageReactionResources: ChatMessageReactionResource[];
}

interface Props {
  reactionListItem: ReactionListItem;
  reactionStore: MessageReactionStore;
  currentUserResource: CurrentUserResource;
}

const ActiveMessageReaction: React.FC<Props> = ({
  reactionListItem,
  reactionStore,
  currentUserResource,
}) => {
  const emoji = reactionListItem.emoji;

  return find(
    (resource) => resource.payload.userId === currentUserResource.payload.id,
    reactionListItem.chatMessageReactionResources
  ).cata({
    Just: (reaction) => (
      <button
        key={emoji}
        className={style.outgoingReaction}
        onClick={() => reactionStore.removeReaction(reaction)}
        data-test-outgoing-active-reaction
      >
        <span role="img">
          <NotTranslated text={emoji} />
        </span>
        <span>{reactionListItem.chatMessageReactionResources.length}</span>
      </button>
    ),
    Nothing: () => (
      <button
        key={emoji}
        className={style.incomingReaction}
        onClick={() => reactionStore.addReaction(emoji)}
        data-test-incoming-active-reaction
      >
        <span role="img">
          <NotTranslated text={emoji} />
        </span>
        <span>{reactionListItem.chatMessageReactionResources.length}</span>
      </button>
    ),
  });
};

export default observer(ActiveMessageReaction);
