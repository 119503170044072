import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import {
  programTypeAll,
  programTypeAlp,
  programTypeEnterprise,
  programTypeMsuite,
  programTypeNone,
  State,
} from './Types';

class ProgramTypeStore {
  @observable
  state: State = programTypeNone();

  @action setProgramTypeAep = () => {
    switch (this.state.kind) {
      case 'program-type-msuite':
      case 'program-type-alp':
      case 'program-type-all':
      case 'program-type-none':
        this.state = programTypeEnterprise();
        break;
      case 'program-type-aep':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action setProgramTypeMsuite = () => {
    switch (this.state.kind) {
      case 'program-type-aep':
      case 'program-type-alp':
      case 'program-type-all':
      case 'program-type-none':
        this.state = programTypeMsuite();
        break;
      case 'program-type-msuite':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action setProgramTypeAlp = () => {
    switch (this.state.kind) {
      case 'program-type-aep':
      case 'program-type-msuite':
      case 'program-type-all':
      case 'program-type-none':
        this.state = programTypeAlp();
        break;
      case 'program-type-alp':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action setProgramTypeAll = () => {
    switch (this.state.kind) {
      case 'program-type-aep':
      case 'program-type-msuite':
      case 'program-type-alp':
      case 'program-type-none':
        this.state = programTypeAll();
        break;
      case 'program-type-all':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action setProgramTypeNone = () => {
    switch (this.state.kind) {
      case 'program-type-aep':
      case 'program-type-msuite':
      case 'program-type-alp':
      case 'program-type-all':
        this.state = programTypeNone();
        break;
      case 'program-type-none':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default ProgramTypeStore;
