interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
}

interface Ready {
  kind: 'ready';
}

interface Selected {
  kind: 'selected';
}

export const selected = (): Selected => ({ kind: 'selected' });

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const initializing = (): Initializing => ({ kind: 'initializing' });

export const ready = (): Ready => ({ kind: 'ready' });

export type State = Waiting | Initializing | Ready | Selected;
