import * as React from 'react';
import { observer } from 'mobx-react';
import * as style from './style.module.css';
import { NotTranslated } from '../../Translations';
import TeamMember from '../TeamsSummary/TeamsRenderer/TeamsSummary/TeamMember';
import { TeamMemberResource } from '../../TeamsStore/Types';

interface Props {
  teamMemberResource: TeamMemberResource;
}

const TeamMemberOnLight: React.FC<Props> = ({ teamMemberResource }) => {
  return (
    <div className={style.memberFull}>
      <TeamMember teamMemberResource={teamMemberResource} />
      <div className={style.memberDetails}>
        {teamMemberResource.payload.profile.payload.name
          .map(name => (
            <h4 className={style.titleOnLight}>
              <NotTranslated text={name} />
            </h4>
          ))
          .getOrElseValue(<></>)}
        {teamMemberResource.payload.profile.payload.position
          .map(position => (
            <h5 className={style.subtitleOnLight}>
              <NotTranslated text={position} />
            </h5>
          ))
          .getOrElseValue(<></>)}
      </div>
    </div>
  );
};

export default observer(TeamMemberOnLight);
