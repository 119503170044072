import { equals } from '@execonline-inc/collections';
import { emptyFragment } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';
import { NonEmptyList, fromArrayMaybe } from 'nonempty-list';
import { usePreviewContext } from '../../../DiscoveryPortal/PreviewMode/usePreviewContext';
import { getScheduledAvailabilities } from '../../Experience/Availability';
import { AvailabilityResource } from '../../Experience/Types';
import { useExperiencePopoverContext } from '../ExperiencePopoverContext';
import UpcomingDatesInfo from './UpcomingDatesInfo';

function ExperienceUpcomingDates() {
  const { experienceResource } = useExperiencePopoverContext();
  const previewMode = usePreviewContext();

  const filtered = when(
    equals(previewMode, 'preview'),
    experienceResource.payload.availabilities,
  ).getOrElse(() => getScheduledAvailabilities(experienceResource.payload.availabilities));

  return fromArrayMaybe(filtered)
    .map((list: NonEmptyList<AvailabilityResource>) => <UpcomingDatesInfo availabilities={list} />)
    .getOrElse(emptyFragment);
}

export default observer(ExperienceUpcomingDates);
