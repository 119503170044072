import { first, mapMaybe } from '@execonline-inc/collections';
import { assertNever, identity } from '@kofno/piper';
import { Maybe, just, nothing } from 'maybeasy';
import { Task } from 'taskarian';
import ProfileFormStore from '.';
import { AppyError, callApi } from '../Appy';
import { changeLocaleT } from '../ChangeLocale';
import CountryOptionsStore from '../CountryOptionsStore';
import CountryRegionOptionsStore from '../CountryRegionOptionsStore';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { NotifiableStore } from '../ErrorActionableReaction';
import { handleAppyError } from '../ErrorHandling';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { profileStore } from '../ProfileStore';
import { RCProps } from '../ReactionComponent';
import { Link } from '../Resource/Types';
import { SupportedLanguageCode } from '../SupportedLanguages/Types';
import { TPlainTextKey } from '../Translations';
import {
  profileFormResourceDecoder,
  profileFormResourceDecoderWithErrors,
  userInputErrorDecoder,
} from './Decoders';
import {
  ProfileFormResource,
  ProfileFormResourceWithErrors,
  ProfileState,
  Succeed,
  UserInputError,
  UserInputErrorMessage,
} from './Types';

interface Props {
  store: ProfileFormStore;
  countryRegionOptionsStore: CountryRegionOptionsStore;
  countryOptionsStore: CountryOptionsStore;
  currentUserResource: CurrentUserResource;
}

interface EncodedProfile {
  first_name: string;
  last_name: string;
  default_landing_page: string;
  division: string;
  cell_phone: string;
  work_phone: string;
  time_zone: string;
  current_position: string;
  work_extension: string;
  location_country_id: string;
  location_region_id: string;
  salutation: string;
  facebook_page: string;
  linked_in_page: string;
  twitter_page: string;
  avatar?: string;
  preferred_language: SupportedLanguageCode;
}

const profileEncoder = (
  resource: ProfileFormResource,
  avatarPathToSubmit: Maybe<string>,
  code: SupportedLanguageCode,
): EncodedProfile =>
  withConditionalFields(avatarPathToSubmit, {
    first_name: resource.payload.firstName.getOrElseValue(''),
    last_name: resource.payload.lastName.getOrElseValue(''),
    default_landing_page: resource.payload.defaultLandingPage
      .map<string>(identity)
      .getOrElseValue(''),
    division: resource.payload.division.getOrElseValue(''),
    cell_phone: resource.payload.cellPhone.getOrElseValue(''),
    work_phone: resource.payload.workPhone.number.getOrElseValue(''),
    time_zone: resource.payload.timeZone.map((timeZone) => timeZone.name).getOrElseValue(''),
    current_position: resource.payload.currentPosition.getOrElseValue(''),
    work_extension: resource.payload.workPhone.extension.getOrElseValue(''),
    location_country_id: resource.payload.country.map((c) => c.id).getOrElseValue(''),
    location_region_id: resource.payload.region.map((r) => r.id).getOrElseValue(''),
    salutation: resource.payload.salutation.map<string>(identity).getOrElseValue(''),
    facebook_page: resource.payload.facebookPage.getOrElseValue(''),
    linked_in_page: resource.payload.linkedInPage.getOrElseValue(''),
    twitter_page: resource.payload.twitterPage.getOrElseValue(''),
    preferred_language: code,
  });

const withConditionalFields = (
  avatar: Maybe<string>,
  encodedProfile: EncodedProfile,
): EncodedProfile => {
  return avatar.cata({
    Just: (avatar: string) => {
      encodedProfile.avatar = avatar;
      return encodedProfile;
    },
    Nothing: () => encodedProfile,
  });
};

const scrollToNotification = () => {
  window.scrollTo({ top: 0 });
};

const successfulUpdate =
  (store: ProfileFormStore, selectedLanguageCode: SupportedLanguageCode) =>
  (resource: ProfileFormResource) => {
    store.succeed(resource, selectedLanguageCode);
  };

type SubmissionError = MissingLinkError | AppyError;
type ProfileRetrievalError = MissingLinkError | AppyError;

const handleMissingLink = (
  store: ProfileFormStore,
  error: MissingLinkError,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  switch (error.rel) {
    case 'profile':
      store.error(
        `You do not have access to your profile at this time.`,
        countryRegionOptionsStore,
      );
      break;
    case 'update':
      store.error(
        `You are not able to update your profile at this time.`,
        countryRegionOptionsStore,
      );
      break;
    default:
      store.error('You cannot perform this operation at this time.', countryRegionOptionsStore);
      break;
  }
};

export interface ErrorActionable {
  error: (
    errorMessage: TPlainTextKey,
    countryRegionOptionsStore: CountryRegionOptionsStore,
  ) => void;
}

export interface EAProps<Store extends NotifiableStore> extends RCProps<Store> {}

export const handleError = <Store extends ErrorActionable>(
  store: Store,
  error: AppyError,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  store.error(handleAppyError(error), countryRegionOptionsStore);
};

const userInputErrorMessage = (
  userInputErrorKind: UserInputError['kind'],
): UserInputErrorMessage => {
  switch (userInputErrorKind) {
    case 'region-invalid-error':
    case 'region-id-missing-error':
    case 'region-missing-error':
      return 'Select a region';
    case 'first-name-missing-error':
      return 'Add your first name';
    case 'last-name-missing-error':
      return 'Add your last name';
    case 'salutation-error':
      return 'Please select a salutation from the list';
  }
};

const handleBadStatus = (
  statusMessage: string,
  error: AppyError,
  countryRegionOptionsStore: CountryRegionOptionsStore,
  store: ProfileFormStore,
) => {
  userInputErrorDecoder
    .decodeJson(statusMessage)
    .map(({ kind }) => ({
      type: 'error',
      param: kind,
      code: '400',
      source: 'server',
      message: userInputErrorMessage(kind),
    }))
    .cata({
      Ok: (userInputError) => {
        store.setServerErrors([userInputError]);
        store.error(userInputError.message, countryRegionOptionsStore);
      },
      Err: () => {
        store.error('An unexpected error occurred.', countryRegionOptionsStore);
      },
    });
  profileFormResourceDecoderWithErrors.decodeJson(statusMessage).cata({
    Ok: (resource) =>
      store.readyWithErrors(
        resource,
        initiallySelectedLanguage(resource),
        countryRegionOptionsStore,
        store.avatarLink,
        store.serverErrors,
      ),
    Err: () => handleError(store, error, countryRegionOptionsStore),
  });
  scrollToNotification();
};

const initiallySelectedLanguage = (
  resource: ProfileFormResource | ProfileFormResourceWithErrors,
): SupportedLanguageCode =>
  resource.payload.preferredLanguageResource
    .map((languageResource) => languageResource.payload.code)
    .getOrElseValue('en');

export const handleServerErrors =
  (store: ProfileFormStore, countryRegionOptionsStore: CountryRegionOptionsStore) =>
  (error: SubmissionError): void => {
    switch (error.kind) {
      case 'bad-status':
        error.response.status === 400
          ? handleBadStatus(error.response.body, error, countryRegionOptionsStore, store)
          : handleError(store, error, countryRegionOptionsStore);
        break;
      case 'bad-url':
      case 'timeout':
      case 'network-error':
      case 'bad-payload':
      case 'missing-application-id':
      case 'missing-api-compatibility':
        handleError(store, error, countryRegionOptionsStore);
        break;
      case 'missing-link-error':
        handleMissingLink(store, error, countryRegionOptionsStore);
        break;
      default:
        assertNever(error);
    }
  };

export const successfullyLoad =
  (store: ProfileFormStore) => (profileResource: ProfileFormResource) => {
    store.loaded(
      profileResource,
      nothing(),
      initiallySelectedLanguage(profileResource),
      store.avatarLink,
    );
  };

const handleChangeLocaleError = (
  store: ProfileFormStore,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => store.error('Unable to update your language at this time.', countryRegionOptionsStore);

const handleChangeLocaleSuccess = (store: ProfileFormStore, state: Succeed) =>
  store.updateSuccess(
    state.profileFormResource,
    just('Profile updated successfully, refreshing page...'),
    state.selectedLanguageCode,
    store.avatarLink,
  );

const handleChangeLocaleSkipped = (store: ProfileFormStore, state: Succeed) =>
  store.updateSuccess(
    state.profileFormResource,
    just('Profile updated successfully'),
    state.selectedLanguageCode,
    store.avatarLink,
  );

const getRegionError = (
  store: ProfileFormStore,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  switch (countryRegionOptionsStore.state.kind) {
    case 'ready-with-regions':
      return store.region
        .map((_) => nothing<UserInputErrorMessage>())
        .getOrElse(() => just(userInputErrorMessage('region-missing-error')));
    case 'error':
    case 'waiting':
    case 'loading':
    case 'ready-without-regions':
    case 'ready-with-errors':
      return nothing<UserInputErrorMessage>();
  }
};

const handleSetErrorsForBlankRequiredField = (
  store: ProfileFormStore,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  store.setRequiredFieldErrors([
    store.firstName
      .map((_) => nothing<UserInputErrorMessage>())
      .getOrElse(() => just(userInputErrorMessage('first-name-missing-error'))),
    store.lastName
      .map((_) => nothing<UserInputErrorMessage>())
      .getOrElse(() => just(userInputErrorMessage('last-name-missing-error'))),
    store.country.andThen(() => getRegionError(store, countryRegionOptionsStore)),
    ...store.requiredFieldErrors,
  ]);
  return;
};

const handleRequiredFields = (
  store: ProfileFormStore,
  countryRegionOptionsStore: CountryRegionOptionsStore,
) => {
  handleSetErrorsForBlankRequiredField(store, countryRegionOptionsStore);
  scrollToNotification();
};

class ProfileFormReactions extends ErrorActionableReaction<ProfileFormStore, ProfileState, Props> {
  tester = () => this.props.store.state;
  effect = (state: ProfileState): void => {
    const { store, countryRegionOptionsStore, countryOptionsStore } = this.props;
    switch (state.kind) {
      case 'loading':
        Task.succeed<ProfileRetrievalError, ReadonlyArray<Link>>(
          this.props.currentUserResource.links,
        )
          .andThen(findLinkT('edit-profile'))
          .andThen(callApi(profileFormResourceDecoder, {}))
          .fork(handleServerErrors(store, countryRegionOptionsStore), successfullyLoad(store));
        break;
      case 'checking-required-fields':
        handleRequiredFields(store, countryRegionOptionsStore);
        first(mapMaybe(identity, store.requiredFieldErrors))
          .do((_) => store.error('Your request failed.', countryRegionOptionsStore))
          .elseDo(() => store.updating());
        break;
      case 'updating':
        Task.succeed<SubmissionError, ReadonlyArray<Link>>(store.links)
          .andThen(findLinkT('update'))
          .andThen(
            callApi(profileFormResourceDecoder, {
              profile: profileEncoder(
                state.profileFormResource,
                state.avatarPathToSubmit,
                state.selectedLanguageCode,
              ),
            }),
          )
          .do(scrollToNotification)
          .fork(
            handleServerErrors(store, countryRegionOptionsStore),
            successfulUpdate(store, state.selectedLanguageCode),
          );
        break;
      case 'succeed':
        changeLocaleT(state.selectedLanguageCode).fork(
          (err) => {
            switch (err.kind) {
              case 'locale-change-skipped':
                handleChangeLocaleSkipped(store, state);
                break;
              case 'i18next-interaction-error':
              case 'could-not-reload-error':
                handleChangeLocaleError(store, countryRegionOptionsStore);
                break;
              default:
                assertNever(err);
            }
          },
          () => handleChangeLocaleSuccess(store, state),
        );
        break;
      case 'loaded':
        store.loadingCountries(state.profileFormResource, store.avatarLink);
        break;
      case 'loading-countries':
        countryOptionsStore.loading();
        break;
      case 'selecting-country':
        store.profileFormResource
          .do((resource) =>
            store.countrySelected(
              resource,
              state.countries,
              state.event.currentTarget.value,
              store.avatarLink,
            ),
          )
          .elseDo(() =>
            store.error(
              'An error occurred, please refresh the page and try again',
              countryRegionOptionsStore,
            ),
          );
        break;
      case 'countries-loaded':
      case 'country-selected':
        store.country
          .do((country) =>
            store.loadingRegions(
              country.id,
              state.profileFormResource,
              state.selectedLanguageCode,
              store.avatarLink,
            ),
          )
          .elseDo(() => store.readyWithNoCountries(state.profileFormResource, store.avatarLink));
        break;
      case 'loading-regions':
        store.country.map((country) => countryRegionOptionsStore.loading(just(country.id)));
        break;
      case 'update-success':
        profileStore.loading(this.props.currentUserResource);
        break;
      case 'error':
      case 'ready-with-no-countries':
      case 'waiting':
      case 'regions-loaded':
      case 'region-selected':
      case 'ready-with-errors':
        break;
      default:
        assertNever(state);
    }
  };
}

export default ProfileFormReactions;
