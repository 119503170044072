import * as React from 'react';
import { Maybe } from 'maybeasy';
import { updateFsProgramFields } from './Loader';

interface Props {
  title: string;
  sfProgramUid: Maybe<string>;
}

class UpdateFsProgramField extends React.Component<Props, {}> {
  componentDidMount() {
    const { title, sfProgramUid } = this.props;
    updateFsProgramFields(title, sfProgramUid);
  }

  render() {
    return <></>;
  }
}

export default UpdateFsProgramField;
