import * as React from 'react';
import { observer } from 'mobx-react';
// TODO nope.
import * as style from '../../ProfilePopout/style.module.css';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const LinkedInIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 16 15">
      <path
        d="M1.763 0C.8 0 0 .772 0 1.705v11.59C0 14.228.799 15 1.763 15h11.99c.965 0 1.764-.772 1.764-1.705V1.705C15.517.772 14.718 0 13.754 0H1.764Zm0 1.364h11.99a.34.34 0 0 1 .354.34v11.591a.34.34 0 0 1-.353.341H1.764a.34.34 0 0 1-.353-.34V1.704a.34.34 0 0 1 .352-.341Zm2.072 1.172c-.67 0-1.212.524-1.212 1.171 0 .648.543 1.172 1.212 1.172.67 0 1.213-.524 1.213-1.172 0-.647-.543-1.171-1.213-1.171Zm6.37 3.11c-1.01 0-1.683.536-1.961 1.044h-.045v-.895H6.216v6.478h2.072V9.077c0-.845.173-1.662 1.256-1.662 1.066 0 1.08.953 1.08 1.704v3.154h2.072V8.714c0-1.741-.383-3.068-2.49-3.068Zm-7.384.15v6.477h2.094V5.795H2.821Z"
        id={style.linkedin}
        fill={defaultTheme.variables['icon-standard-on-light']}
      />
    </SVGIcon>
  );
};

export default observer(LinkedInIcon);
