import { toTask } from '@execonline-inc/maybe-adapter';
import { assertNever } from '@kofno/piper';
import { Maybe } from 'maybeasy';
import { Task } from 'taskarian';
import ViewableAttachmentStore from '.';
import { CurrentUserResource } from '../CurrentUser/Types';
import ErrorActionableReaction, { EAProps, handleError } from '../ErrorActionableReaction';
import { MissingLinkError, findLinkT } from '../LinkyLinky';
import { Link } from '../Resource/Types';
import { ViewableAttachmentState } from './Types';

type ViewableAttachmentReactionError = MissingAttachmentId | MissingLinkError;

interface MissingAttachmentId {
  kind: 'missing-attachment-id';
}

export interface Props extends EAProps<ViewableAttachmentStore> {
  store: ViewableAttachmentStore;
  attachmentId: Maybe<number>;
  currentUserResource: CurrentUserResource;
}

const handleAttachmentRequestError =
  (store: ViewableAttachmentStore) => (error: ViewableAttachmentReactionError) => {
    switch (error.kind) {
      case 'missing-link-error':
        store.error('Attachment link is unavailable');
        break;
      case 'missing-attachment-id':
        store.error('Attachment ID is not present');
        break;
      default:
        handleError(store, error);
    }
  };

const resolveAttachmentUrl = (template: string, attachmentId: number): string =>
  template.replace('{attachment_id}', `${attachmentId}`);

const processTemplateLink =
  (attachmentId: Maybe<number>) =>
  (link: Link): Task<MissingAttachmentId, Link> =>
    Task.succeed<MissingAttachmentId, Maybe<number>>(attachmentId)
      .andThen(toTask({ kind: 'missing-attachment-id' } as MissingAttachmentId))
      .map((v) => ({
        ...link,
        href: resolveAttachmentUrl(link.href, v),
      }));

const attachmentLink =
  (attachmentId: Maybe<number>) =>
  (links: ReadonlyArray<Link>): Task<ViewableAttachmentReactionError, Link> =>
    Task.succeed<ViewableAttachmentReactionError, ReadonlyArray<Link>>(links)
      .andThen(findLinkT('viewable-attachment-template'))
      .andThen(processTemplateLink(attachmentId));

class ViewableAttachmentReactions extends ErrorActionableReaction<
  ViewableAttachmentStore,
  ViewableAttachmentState,
  Props
> {
  tester = () => this.props.store.state;

  effect = (state: ViewableAttachmentState) => {
    switch (state.kind) {
      case 'waiting':
      case 'ready':
      case 'error':
        break;
      case 'loading':
        Task.succeed<ViewableAttachmentReactionError, ReadonlyArray<Link>>(
          this.props.currentUserResource.links,
        )
          .andThen(attachmentLink(this.props.attachmentId))
          .fork(handleAttachmentRequestError(this.props.store), this.props.store.ready);
        break;
      default:
        assertNever(state);
    }
  };
}

export default ViewableAttachmentReactions;
