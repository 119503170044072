import { observer } from 'mobx-react';
import { just } from 'maybeasy';
import { Button, CircleCheckIcon, Typography } from '@execonline-inc/execonline-ui';
import { EnrollmentResource } from '../../../../DiscoveryPortal/Enrollment/Store/Types';
import { T } from '../../../../../../Translations';
import { PaymentIntentId } from '../Common';
import ExperienceDetailMessage from './ExperienceDetailMessage';
import RedirectText from './RedirectText';

interface Props {
  paymentIntentId: string;
  enrollmentResource: EnrollmentResource;
  onContinue: (paymentIntentId: string) => void;
}

function PaymentSuccessfulModalBody({ paymentIntentId, enrollmentResource, onContinue }: Props) {
  function handleContinue() {
    onContinue(paymentIntentId);
  }

  return (
    <div className="my-auto box-border flex h-full w-full flex-col content-center items-center self-center px-6 md:px-12 lg:px-52">
      <CircleCheckIcon className="h-[8.375rem] w-[8.375rem]" color="green" />
      <Typography
        className="mt-9 text-center"
        color="black"
        variant="h5"
        as="p"
        fontWeight="medium"
      >
        <T kind="Successfully Purchased Access" />
      </Typography>
      <div className="mt-3 text-center empty:hidden">
        <PaymentIntentId value={just(paymentIntentId)} />
      </div>
      <div className="mt-10">
        <ExperienceDetailMessage enrollment={enrollmentResource.payload} />
      </div>
      <div className="mt-8 w-full text-center">
        <Button className="w-full sm:w-80" color="gray" size="md" onClick={handleContinue}>
          <RedirectText enrollment={enrollmentResource.payload} />
        </Button>
      </div>
    </div>
  );
}

export default observer(PaymentSuccessfulModalBody);
