import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ResourceLearningPartnerIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 29 17">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M7.527 0C4.421 0 1.882 2.55 1.882 5.667c0 1.874.919 3.541 2.323 4.574C1.724 11.477 0 14.038 0 17h1.882a5.643 5.643 0 0 1 5.645-5.667A5.643 5.643 0 0 1 13.172 17h1.882a5.643 5.643 0 0 1 5.645-5.667A5.643 5.643 0 0 1 26.345 17h1.881c0-2.962-1.723-5.523-4.204-6.759a5.679 5.679 0 0 0 2.323-4.574c0-3.118-2.54-5.667-5.646-5.667-3.105 0-5.645 2.55-5.645 5.667 0 1.874.919 3.541 2.323 4.574a7.598 7.598 0 0 0-3.264 3.1 7.598 7.598 0 0 0-3.263-3.1 5.679 5.679 0 0 0 2.322-4.574C13.172 2.549 10.632 0 7.527 0Zm0 1.889a3.754 3.754 0 0 1 3.764 3.778c0 2.099-1.673 3.777-3.764 3.777a3.754 3.754 0 0 1-3.763-3.777c0-2.1 1.672-3.778 3.763-3.778Zm13.172 0a3.754 3.754 0 0 1 3.764 3.778c0 2.099-1.672 3.777-3.764 3.777a3.754 3.754 0 0 1-3.763-3.777c0-2.1 1.672-3.778 3.763-3.778Z"
      />
    </SVGIcon>
  );
};

export default observer(ResourceLearningPartnerIcon);
