import { observer } from 'mobx-react';
import * as React from 'react';
import {
  ActiveProgram,
  ActiveProgramChapter,
  ActiveProgramModule,
} from '../../../../ProgramStore/Types';
import { NotTranslated } from '../../../../Translations';
import StartIcon from '../../../Cutesies/StartIcon';
import * as style from '../../../ProgramSummary/style.module.css';
import ProgramModuleProgress from '../ProgramModuleProgress';

interface Props {
  programDetail: ActiveProgram;
}

const fillColor = (chapter: ActiveProgramChapter, daysBehind: number) => {
  const firstModule = chapter.modules[0];
  const color = daysBehind > 13 ? '#B3090F' : '#D99F23';
  if (!firstModule) {
    return '#DEE6E7';
  }
  if (firstModule.isCurrentModule || firstModule.percentComplete > 0) {
    return '#2AAB39';
  }

  return firstModule.expectedPercentComplete
    .map((epc) => (epc > 0 ? color : '#DEE6E7'))
    .getOrElseValue('#DEE6E7');
};

const ChapterProgress: React.FC<Props> = ({ programDetail }) => {
  return (
    <>
      {programDetail.chapters.map((chapter) => (
        <div
          className={style.chapter}
          key={chapter.name}
          data-test-chapter={chapter.name}
          data-modules-count={chapter.modules.length}
        >
          <ol className={style.progressBar} data-test-progress-bar={true}>
            <li className={style.module} data-test-module-icon={true}>
              <div className={style.moduleIcon}>
                <StartIcon
                  fillColor={fillColor(chapter, programDetail.daysBehind.getOrElseValue(0))}
                />
              </div>
            </li>
            {chapter.modules.map((programModule: ActiveProgramModule) => {
              return (
                <ProgramModuleProgress
                  programModule={programModule}
                  key={programModule.id}
                  programDetail={programDetail}
                />
              );
            })}
          </ol>
          <div className={style.chapterLabel}>
            <NotTranslated text={chapter.name} />
          </div>
        </div>
      ))}
    </>
  );
};

export default observer(ChapterProgress);
