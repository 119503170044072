import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { SubDayProps } from '.';
import { handleEnterOrSpaceKeyPress } from '../../../Accessibility/KeyboardEvents';
import { moduleKey } from '../../../DayModules';
import { useTranslationsContext } from '../../../Translations/UseTranslationsContext';
import DayEvents from '../../DayEvents';
import { useOpenable } from '../../Openable';
import ManyEvents from '../EventType/ManyEvents';
import ShowModule from '../ShowModule';
import * as style from '../style.module.css';

function CurrentMonthDay({ day, store, modules }: SubDayProps) {
  const { localize } = useTranslationsContext();
  const { openableState, open, close } = useOpenable();
  const events = store.events(day);

  return (
    <>
      <li data-test-current-month-day={day.date.getDate()}>
        <div
          onClick={open}
          className={style.content}
          tabIndex={0}
          onKeyPress={handleEnterOrSpaceKeyPress(open)}
          role="button"
          aria-label={localize(day.date, 'long-month-day')}
        >
          <div className={style.number}>{day.date.getDate()}</div>
          {modules.map((md, index) => (
            <ShowModule key={moduleKey(md, index)} md={md} />
          ))}
          {events.map((evts) => <ManyEvents events={evts} />).getOrElse(emptyFragment)}
        </div>
      </li>
      <DayEvents
        modalState={openableState}
        close={close}
        day={day}
        events={events}
        modules={modules}
      />
    </>
  );
}

export default observer(CurrentMonthDay);
