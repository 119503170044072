import { Maybe, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext } from 'react';
import {
  LocalConferenceRoomResource,
  whenLocalConferenceRoomResource,
} from '../../Conference/Types';
import ConversationPresence from '../../ConversationPresence';
import WithCurrentUser from '../../CurrentUser/Context/WithCurrentUser';
import { CurrentUserResource } from '../../CurrentUser/Types';
import { GlobalPresenceContext } from '../../GlobalPresence/GlobalPresenceContext';
import { TeamResource, TeamsResource } from '../../TeamsStore/Types';

interface TeamPresenceProps {
  teamsResource: Maybe<TeamsResource>;
}

interface PresenceProps {
  conferenceRoomResource: LocalConferenceRoomResource;
  currentUserResource: CurrentUserResource;
}

function Presence({ conferenceRoomResource, currentUserResource }: PresenceProps) {
  const globalPresence = useContext(GlobalPresenceContext);

  return (
    <ConversationPresence
      conversationResource={conferenceRoomResource.payload.conversationResource}
      currentUserResource={currentUserResource}
      channelName={`global_conversation_${conferenceRoomResource.payload.conversationResource.payload.id}`}
      chatablePersonResourceList={nothing()}
      client={(presenceMembersStore) => {
        globalPresence.addPresenceStore(presenceMembersStore);
        return <span />;
      }}
    />
  );
}

const ConferenceRoomPresence: React.FC<{
  teamResource: TeamResource;
  currentUserResource: CurrentUserResource;
}> = ({ teamResource, currentUserResource }) =>
  teamResource.payload.conferenceRoom
    .andThen(whenLocalConferenceRoomResource)
    .map((conferenceRoomResource) => (
      <Presence
        conferenceRoomResource={conferenceRoomResource}
        currentUserResource={currentUserResource}
      />
    ))
    .getOrElseValue(<span />);

const TeamsPresences: React.FC<TeamPresenceProps> = ({ teamsResource }) => (
  <WithCurrentUser
    children={(currentUserResource) =>
      teamsResource
        .map((teamsResource) =>
          teamsResource.payload.map((teamResource) => (
            <ConferenceRoomPresence
              key={teamResource.payload.id}
              teamResource={teamResource}
              currentUserResource={currentUserResource}
            />
          ))
        )
        .getOrElseValue([])
    }
  />
);

export default observer(TeamsPresences);
