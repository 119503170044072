import { fromNullable } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { T } from '../../../../../Translations';
import * as style from '../../style.module.css';
import SectionTitle from '../SectionTitle';

interface Props {
  value: number;
}

const SessionsCountInfo: React.FC<Props> = ({ value }) =>
  fromNullable(value)
    .map((v) => (
      <div>
        <SectionTitle text="Session history" />
        <div className={style.sessionHistory}>
          <T
            kind="<sessionCount>{{count}} session(s)</sessionCount> completed with your coach so far"
            count={value}
            sessionCount={(translated) => <div className={style.boldLargeText}>{translated}</div>}
          />
        </div>
      </div>
    ))
    .getOrElse(() => <></>);

export default observer(SessionsCountInfo);
