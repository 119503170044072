import { warn } from '@execonline-inc/logging';
import { useConst } from '@execonline-inc/react-hooks.private';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { ReactNode, useEffect } from 'react';
import Loader from '../components/Loader';
import { useFetchResource } from '../Fetch';
import { rootResourceStore } from '../RootResourceStore';
import { T } from '../Translations';
import { rootLink } from './Types';

interface RootLoadedProps {
  children: ReactNode;
}

function RootLoaded({ children }: RootLoadedProps) {
  const link = useConst(rootLink);
  const state = useFetchResource(link);

  useEffect(() => {
    switch (state.kind) {
      case 'loading':
        break;
      case 'errored':
        rootResourceStore.error(state.error);
        warn('RootResourceError', 'Client could not load root resource', state.error);
        break;
      case 'ready':
        rootResourceStore.ready(state.data.resource);
        break;
      default:
        assertNever(state);
    }
  }, [state]);

  switch (rootResourceStore.state.kind) {
    case 'loading':
      return <Loader delay={0} />;
    case 'error':
      return <T kind="Unable to connect to the ExecOnline platform." />;
    case 'ready':
      return <>{children}</>;
  }
}

export default observer(RootLoaded);
