import { assertNever } from '@kofno/piper';
import { action, observable } from 'mobx';
import { TPlainTextKey } from '../../../Translations';
import { State, SubscriptionResource, error, unsubscribed, unsubscribing, waiting } from './Types';

class SubscriptionStore {
  @observable
  state: State = waiting();

  constructor(public readonly guid: string) {}

  @action
  unsubscribing = (guid: string) => {
    switch (this.state.kind) {
      case 'waiting':
        this.state = unsubscribing(guid);
        break;
      case 'unsubscribing':
      case 'error':
      case 'unsubscribed':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (message: TPlainTextKey) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'unsubscribing':
      case 'unsubscribed':
        this.state = error(message);
        break;
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  unsubscribed = (resource: SubscriptionResource) => {
    switch (this.state.kind) {
      case 'unsubscribing':
        this.state = unsubscribed(resource);
        break;
      case 'waiting':
      case 'error':
      case 'unsubscribed':
        break;
      default:
        assertNever(this.state);
    }
  };
}

export default SubscriptionStore;
