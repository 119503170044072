import { assertNever } from '@kofno/piper';
import { action, computed, observable } from 'mobx';
import { error } from '../../../../ErrorHandling';
import { TPlainTextKey } from '../../../../Translations';
import {
  loading,
  ready,
  CoachTeamsState,
  waiting,
  SearchParameters,
  loadAll,
  addFilter,
} from './Types';
import { Maybe, nothing, just } from 'maybeasy';
import { CommunitiesResource } from '../../../../CommunitiesStore/Types';

const PER_PAGE_COUNT = 10;

class CoachCommunitySummaryStore {
  @observable
  state: CoachTeamsState;

  constructor() {
    this.state = waiting();
  }

  @action
  loading = () => {
    switch (this.state.kind) {
      case 'waiting':
      case 'ready':
      case 'add-filter':
      case 'load-all':
        this.state = loading(this.state);
        break;
      case 'loading':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  ready = (communitiesResource: CommunitiesResource) => {
    switch (this.state.kind) {
      case 'waiting':
      case 'loading':
      case 'load-all':
        this.state = ready(communitiesResource, this.state);
        break;
      case 'ready':
      case 'add-filter':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  loadAll = () => {
    switch (this.state.kind) {
      case 'ready':
        this.state = loadAll(this.state.communitiesResource, this.state);
        break;
      case 'waiting':
      case 'loading':
      case 'load-all':
      case 'add-filter':
      case 'error':
        break;
      default:
        assertNever(this.state);
    }
  };

  @action
  error = (msg: TPlainTextKey) => {
    this.state = error(msg);
  };

  @action
  addFilter = (filterType: string, filter: string) => {
    switch (this.state.kind) {
      case 'ready':
      case 'loading':
      case 'waiting':
      case 'load-all':
        this.state = addFilter(this.state.searchParameters, filterType, filter);
        break;
      case 'error':
      case 'add-filter':
        break;
      default:
        assertNever(this.state);
    }
  };

  @computed
  get searchParameters(): SearchParameters {
    switch (this.state.kind) {
      case 'loading':
      case 'ready':
      case 'load-all':
      case 'add-filter':
        return [
          ...this.state.searchParameters,
          { key: 'per_page', value: this.perPageCount.toString() },
        ];
      case 'waiting':
      case 'error':
        return [];
    }
  }

  @computed
  get communitiesResource(): Maybe<CommunitiesResource> {
    switch (this.state.kind) {
      case 'ready':
      case 'load-all':
        return just(this.state.communitiesResource);
      case 'waiting':
      case 'loading':
      case 'error':
      case 'add-filter':
        return nothing();
    }
  }

  @computed
  get perPageCount(): number {
    switch (this.state.kind) {
      case 'ready':
        return this.state.communitiesResource.metadata.perPage;
      case 'load-all':
        return this.state.communitiesResource.metadata.resultsCount;
      case 'waiting':
      case 'loading':
      case 'add-filter':
        return PER_PAGE_COUNT;
      case 'error':
        return 0;
    }
  }
}

export default CoachCommunitySummaryStore;
