import { AlreadyTranslatedText } from '@execonline-inc/translations';
import { callApi } from '../Appy';
import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';
import { ResourceResource } from '../ResourceStore/Types';
import { SegmentResourcesResource } from '../SegmentResourceStore/Types';
import { TeamResourcesResource } from '../TeamResourceStore/Types';
import { programResourceResourceDecoder } from './Decoders';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  resourcesResource: ResourcesResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const ready = (resourcesResource: ResourcesResource): Ready => ({
  kind: 'ready',
  resourcesResource,
});

export type ResourcesState = Waiting | Loading | Ready | Error;

export interface Resources {
  teamResources: TeamResourcesResource;
  programResources: ProgramResourcesResource;
}

export interface ProgramResource {
  id: number;
  title: AlreadyTranslatedText;
  resources: ResourceResource[];
  segmentResources: SegmentResourcesResource;
}

export type ProgramResourceResource = Resource<ProgramResource>;
export type ProgramResourcesResource = Resource<ProgramResourceResource[]>;
export type ResourcesResource = Resource<Resources>;

export const getProgramResources = callApi(programResourceResourceDecoder, {});
