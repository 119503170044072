import { Maybe } from 'maybeasy';
import { Error } from '../ErrorHandling';
import { Resource } from '../Resource/Types';
import { Upload } from '../Uploads';

export interface SimpleFileUploadResponse {
  signedUrl: string;
  objName: string;
  folderName: string;
  fileName: string;
  tempFilePath: string;
}
export type SimpleFileUploadResource = Resource<SimpleFileUploadResponse>;

interface Waiting {
  kind: 'waiting';
}

export interface Loading {
  kind: 'loading';
}

interface Ready {
  kind: 'ready';
  newUpload: Upload;
}

interface ReadyWithErrors {
  kind: 'ready-with-errors';
  newUpload: Maybe<Upload>;
  error: string;
}

interface ReadyWithoutUpload {
  kind: 'ready-without-upload';
}

interface UploadSuccessful {
  kind: 'upload-successful';
  newUpload: Upload;
}

interface UploadInProgress {
  kind: 'upload-in-progress';
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (): Loading => ({
  kind: 'loading',
});

export const uploadInProgess = (): UploadInProgress => ({
  kind: 'upload-in-progress',
});

export const ready = (newUpload: Upload): Ready => ({
  kind: 'ready',
  newUpload,
});

export const readyWithoutUpload = (): ReadyWithoutUpload => ({
  kind: 'ready-without-upload',
});

export const readyWithErrors = (
  error: string,
  newUpload: Maybe<Upload>,
  file: File
): ReadyWithErrors => ({
  kind: 'ready-with-errors',
  newUpload,
  error,
});

export const uploadSuccessful = (newUpload: Upload): UploadSuccessful => ({
  kind: 'upload-successful',
  newUpload,
});

export type SimpleFileUploadState =
  | Waiting
  | Loading
  | Ready
  | Error
  | ReadyWithoutUpload
  | ReadyWithErrors
  | UploadSuccessful
  | UploadInProgress;

export interface Extension {
  extension: string;
}

export const acceptedFileExtensions = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
  'zip',
  'pdf',
  'ppt',
  'pptx',
  'pwptx',
  'odt',
  'pages',
  'rtf',
  'pps',
  'jpg',
  'jpeg',
  'gif',
  'png',
  'vsd',
  'vsdx',
  'vdx',
  'vss',
  'vsx',
  'vst',
  'vtx',
];

export type AcceptedFileExtensions = typeof acceptedFileExtensions[number];
