import * as React from 'react';
import { observer } from 'mobx-react';
import ProgramStore from '../../../ProgramStore';
import { GroupCoachingProductDetails } from '../../../ProgramStore/Types';
import UpcomingSession from './UpcomingSession';
import YourTeam from './YourTeam';
import YourCoach from './YourCoach';
import { AlreadyTranslated, T } from '../../../Translations';
import YourCoachPlaceholder from './YourCoachPlaceholder';
import UpcomingSessionPlaceholder from './UpcomingSessionPlaceholder';
import style from './styles.module.css';
interface Props {
  productDetails: GroupCoachingProductDetails;
  store: ProgramStore;
}

const GroupCoachingProgramOverview: React.FC<Props> = ({ productDetails }) => {
  return productDetails.upcomingSessionResource
    .map((upcomingSessionResource) => {
      return (
        <div className={style.program}>
          <h3 className={style.programTitle}>
            <AlreadyTranslated content={upcomingSessionResource.payload.name} />
          </h3>
          <div className={style.sessionOverview}>
            <UpcomingSession upcomingSession={productDetails.upcomingSessionResource} />
            <YourTeam members={productDetails.memberProfileResources} />
            <YourCoach coachProfile={upcomingSessionResource.payload.coachingProfileResource} />
          </div>
          <div className={style.detailsWrapper}>
            <p className={style.programSecondaryTitle}>
              <T kind="Theme Details" />
            </p>
            <div className={style.sessionDetails}>
              {upcomingSessionResource.payload.themeDetails.text}
            </div>
          </div>
        </div>
      );
    })
    .getOrElse(() => (
      <div className={style.program}>
        <h3 className={style.programTitle}>
          <AlreadyTranslated content={productDetails.title} />
        </h3>
        <div className={style.sessionOverview}>
          <UpcomingSessionPlaceholder />
          <YourTeam members={productDetails.memberProfileResources} />
          <YourCoachPlaceholder />
        </div>
      </div>
    ));
};

export default observer(GroupCoachingProgramOverview);
