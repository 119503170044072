import Decoder, { array, field, number, string, succeed } from 'jsonous';
import { resourceDecoder } from '../../Resource/Decoders';
import { resourceResourceDecoder } from '../../ResourceStore/Decoders';
import {
  Module,
  Segment,
  SegmentItemResource,
  SegmentResourceResource,
  SegmentResourcesResource,
} from '../Types';

export const segmentDecoder: Decoder<Segment> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('resources', field('resources', array(resourceResourceDecoder)));

export const segmentItemResourceDecoder: Decoder<SegmentItemResource> = resourceDecoder(
  segmentDecoder
);

export const segmentResourceDecoder: Decoder<Module> = succeed({})
  .assign('id', field('id', number))
  .assign('title', field('title', string))
  .assign('segments', field('segments', array(segmentItemResourceDecoder)));

export const segmentResourceResourceDecoder: Decoder<SegmentResourceResource> = resourceDecoder(
  segmentResourceDecoder
);

export const segmentResourcesResourceDecoder: Decoder<SegmentResourcesResource> = resourceDecoder(
  array(segmentResourceResourceDecoder)
);
