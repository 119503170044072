import { ExitContext } from '../Types';

interface Ready {
  kind: 'ready';
}

interface Exiting {
  kind: 'exiting';
  exitContext: ExitContext;
}

export const ready = (): Ready => ({ kind: 'ready' });

export const exiting = (exitContext: ExitContext): Exiting => ({ kind: 'exiting', exitContext });

export type State = Ready | Exiting;
