import { readVarT } from '@execonline-inc/environment';
import { assertNever } from '@kofno/piper';
import { observer } from 'mobx-react';
import { Task } from 'taskarian';
import { currentUserStore } from '../../../CurrentUser/Store';
import { warnAndNotify } from '../../../Honeybadger';
import ReactionComponent, { RCProps } from '../../../ReactionComponent';
import { FSInitializationError } from './Errors';
import { Identification } from './Identification';
import { loadFS } from './Loader';
import { State } from './States';
import { FullStoryToolingStore } from './Store';

interface Props extends RCProps<FullStoryToolingStore> {}

class FullStoryToolingReactions extends ReactionComponent<FullStoryToolingStore, State, Props> {
  tester = () => this.props.store.state;
  effect = (state: State): void => {
    const { store } = this.props;

    switch (state.kind) {
      case 'waiting':
        break;
      case 'loading':
        Task.succeed<FSInitializationError, string>('REACT_APP_FULLSTORY_KEY')
          .andThen(readVarT)
          .andThen(loadFS)
          .fork(store.errored, store.loaded);
        break;
      case 'loaded':
        break;
      case 'errored':
        handleFsLoadError(state.error);
        break;
      default:
        assertNever(state);
    }
  };

  render() {
    const { state } = this.props.store;
    switch (state.kind) {
      case 'loaded':
        return <Identification fs={state.fs} store={currentUserStore} fireImmediately />;
      case 'waiting':
      case 'loading':
      case 'errored':
        return <></>;
    }
  }
}

export default observer(FullStoryToolingReactions);

const handleFsLoadError = (err: FSInitializationError): void => {
  switch (err.kind) {
    case 'missing-var-error':
      warnAndNotify(
        'MissingVarError',
        `Unable to load FullStory: Missing environment variable ${err.key}`,
        err,
      );
      break;
    case 'fs-load-error':
      warnAndNotify('FullStory load error', err.key, err);
      break;
    default:
      assertNever(err);
  }
};
