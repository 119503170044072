import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramChatStore from '../../ProgramChatStore';
import PresenceProgramProfile from './PresenceProgramProfile';
import SingularProgramProfile from './SingularProgramProfile';

interface Props {
  programId: Maybe<number>;
  programChatStore: ProgramChatStore;
}

const ProgramProfileImpl: React.FC<Props> = ({ programChatStore, programId }) =>
  programId
    .map(() => <PresenceProgramProfile programChatStore={programChatStore} />)
    .getOrElse(() => <SingularProgramProfile programChatStore={programChatStore} />);

export default observer(ProgramProfileImpl);
