import { observer } from 'mobx-react';
import * as React from 'react';
import GlobalPresenceMembersStore from '../../GlobalPresence/GlobalPresenceStore';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import { TeamMemberResource } from '../../TeamsStore/Types';
import MemberListItem from './MemberListItem';

interface Props {
  teamMembers: TeamMemberResource[];
  globalPresence: GlobalPresenceMembersStore;
  presenceMembersStore: PresenceMembersStore;
}

class TeamMembers extends React.Component<Props> {
  componentDidMount() {
    const { globalPresence, presenceMembersStore } = this.props;
    globalPresence.addPresenceStore(presenceMembersStore);
  }

  componentWillUnmount() {
    const { globalPresence, presenceMembersStore } = this.props;
    globalPresence.removePresenceStore(presenceMembersStore);
  }

  render() {
    const { teamMembers, globalPresence } = this.props;
    return (
      <>
        {teamMembers.map((teamMember) => (
          <MemberListItem teamMember={teamMember} globalPresence={globalPresence} />
        ))}
      </>
    );
  }
}

export default observer(TeamMembers);
