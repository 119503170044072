import { observer } from 'mobx-react';
import * as React from 'react';
import { Alert, Typography } from '../../../../components/UI';
import { T } from '../../../../Translations';
import { InvitationStore } from '../InvitationStore';
import { FormState } from '../InvitationStore/Types';
import EmailForm from './EmailForm';
import ProfileForm from './ProfileForm';

interface Props {
  store: InvitationStore;
  state: FormState;
}

const FormBody: React.FC<Props> = ({ store, state }) => {
  switch (state.kind) {
    case 'requesting-email-address':
    case 'authenticating-email-domain':
      return <EmailForm store={store} state={state} />;
    case 'requesting-profile-info':
    case 'requesting-profile-info-locked-access':
    case 'requesting-profile-info-password-backend':
    case 'registering':
      return <ProfileForm store={store} state={state} />;
    case 'registered':
      return (
        <Alert className="mb-3 sm:mb-7" variant="outlined" status="success">
          <Typography className="mb-3" variant="body2" color="black">
            <T kind="Please check your email for instructions ..." />
          </Typography>
          <Typography variant="body2" color="black">
            <T kind="You may now close this window." />
          </Typography>
        </Alert>
      );
    case 'pending-email-confirmation':
      return <></>;
  }
};

export default observer(FormBody);
