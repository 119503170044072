import { emptyFragment } from '@execonline-inc/execonline-ui';
import { just, nothing } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../../CurrentUser/Context/WithCurrentUser';
import { GlobalPresenceContext } from '../../../../GlobalPresence/GlobalPresenceContext';
import { LearningPartnerResource } from '../../../../LearningPartnersStore/Types';
import { learningPartnerStore } from '../../../../People';
import { NotTranslated } from '../../../../Translations';
import Person from '../../../Person';
import * as style from './style.module.css';

interface Props {
  studentResource: LearningPartnerResource;
}

class StudentInfo extends React.Component<Props> {
  personStore = learningPartnerStore(this.props.studentResource);
  render() {
    const { studentResource } = this.props;

    return (
      <WithCurrentUser
        children={(currentUserResource) =>
          just(currentUserResource.payload.id)
            .andThen((id) => (id === studentResource.payload.id ? nothing() : just(true)))
            .map(() => (
              <div className={style.studentInfo}>
                <GlobalPresenceContext.Consumer>
                  {(value) => <Person personStore={this.personStore} presenceStore={just(value)} />}
                </GlobalPresenceContext.Consumer>
                <span className={style.studentName}>
                  {this.personStore.name
                    .map((name) => <NotTranslated text={name} />)
                    .getOrElse(emptyFragment)}
                </span>
              </div>
            ))
            .getOrElse(emptyFragment)
        }
      />
    );
  }
}

export default observer(StudentInfo);
