import { emptyFragment } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { just } from 'maybeasy/esm/mod';
import { observer } from 'mobx-react';
import { findLinkBy } from '../../../Links';
import { rootResourceStore } from '../../../RootResourceStore';
import * as style from '../style.module.css';
import CopyrightNotice from './CopyrightNotice';
import FooterLanguageSwitcherImpl from './FooterLanguageSwitcherImpl';
import FooterLegalLinks from './FooterLegalLinks';

function Footer() {
  return just({})
    .assign('privacyLink', findLinkBy({ rel: 'privacy' }, rootResourceStore.links))
    .assign('termsLink', findLinkBy({ rel: 'terms_and_conditions' }, rootResourceStore.links))
    .map(({ privacyLink, termsLink }) => {
      const links = { privacyLink, termsLink };
      return (
        <div
          className={clsx(style.footer, 'flex flex-wrap items-center justify-center')}
          data-test-footer={true}
          role="contentinfo"
        >
          <CopyrightNotice className="text-neutral-900" />
          <FooterLegalLinks {...links} />
          <FooterLanguageSwitcherImpl />
        </div>
      );
    })
    .getOrElse(emptyFragment);
}

export default observer(Footer);
