import { Kettle } from 'kettle-corn';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LectureSegment } from '../../../../LectureStore/Types';
import Slides from '../Slides';
import * as style from '../style.module.css';
import SlidesToggle from './SlidesToggle';
import SlideNavigationStore from './Store';

interface Props {
  segment: LectureSegment;
  kettle: Kettle;
}

@observer
class SlideNavigation extends React.Component<Props, {}> {
  slideNavigationStore: SlideNavigationStore = new SlideNavigationStore();

  render() {
    const { segment, kettle } = this.props;

    switch (this.slideNavigationStore.state.kind) {
      case 'showing-slides':
        return (
          <div className={style.mobileSlideNav} data-test-slide-navigation="showing">
            <div className={style.mobileSlides}>
              <Slides segment={segment} kettle={kettle} />
            </div>
            <SlidesToggle kind="hide" onClick={this.slideNavigationStore.hide} />
          </div>
        );
      case 'hiding-slides':
        return (
          <div className={style.mobileSlideNav} data-test-slide-navigation="hiding">
            <SlidesToggle kind="show" onClick={this.slideNavigationStore.show} />
          </div>
        );
    }
  }
}

export default SlideNavigation;
