import { emptyFragment } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { NonEmptyList } from 'nonempty-list';
import EnrollmentStore from '../../Enrollment/Store';
import { promotableProgramFamilyResourceDecoder } from '../Decoders';
import { PromotableProgramFamilyResource } from '../Types';
import useFetchPromotableProgramFamilies from './hooks/useFetchPromotableProgramFamilies';
import PromotableExperiencesView from './PromotableExperiencesView';
import SkeletonPromotableExperiences from './SkeletonPromotableExperiences';

interface Props {
  data: NonEmptyList<PromotableProgramFamilyResource>;
  invitationUuid: string;
  enrollmentStore: EnrollmentStore;
}

function PromotableExperiences({ data, invitationUuid, enrollmentStore }: Props) {
  const links = data.toArray().map((resource) => resource.links);
  const state = useFetchPromotableProgramFamilies(links, promotableProgramFamilyResourceDecoder);

  switch (state.kind) {
    case 'loading':
      return <SkeletonPromotableExperiences />;
    case 'errored':
      return emptyFragment();
    case 'ready':
      return (
        <PromotableExperiencesView
          invitationUuid={invitationUuid}
          experienceResources={state.data}
          enrollmentStore={enrollmentStore}
        />
      );
  }
}

export default observer(PromotableExperiences);
