import { cn } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { PropsWithChildren } from 'react';
import { Experience } from '../../../../Common/Experience/Types';
import { learnMoreBackgroundClassName, learnMoreBackgroundUrl } from './Types';

interface Props {
  experience: Experience;
}

function HeroBackground({ experience, children }: PropsWithChildren<Props>) {
  const style = { backgroundImage: learnMoreBackgroundUrl(experience) };
  const className = learnMoreBackgroundClassName(experience);

  return (
    <div className="relative z-0 box-border flex flex-1 flex-col">
      <div className="absolute inset-0 z-[-1] overflow-hidden">
        <div
          className={cn('absolute inset-0 flex flex-1 bg-contain bg-no-repeat', className)}
          style={style}
        />
      </div>
      {children}
    </div>
  );
}

export default observer(HeroBackground);
