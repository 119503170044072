import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useCaseLink } from '../../../../ClientRoutes';
import ArrowRightIcon from '../../../Cutesies/ArrowRightIcon';
import { UseCaseItem } from '../../Types';
import UseCaseInfo from './UseCaseInfo';

interface Props {
  item: UseCaseItem;
}

const UseCaseDropdownItem = ({ item }: Props) => {
  return (
    <li>
      <Link
        className={clsx(
          'px-3 py-2',
          'flex justify-between lg:justify-start',
          'text-xs font-bold leading-6 text-gray-900-old',
        )}
        to={useCaseLink(item)}
      >
        <UseCaseInfo data={item.content} />
        <div className="my-auto block w-4 lg:hidden">
          <ArrowRightIcon color={'body-text'} />
        </div>
      </Link>
    </li>
  );
};

export default UseCaseDropdownItem;
