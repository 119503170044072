import { toMinutes } from '@execonline-inc/time';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSession } from '../../../../../ProgramStore/Types';
import { T } from '../../../../../Translations';

interface Props {
  session: CoachingSession;
}

const SessionDuration: React.FC<Props> = ({ session }) => (
  <div>
    {session.duration
      .map(toMinutes)
      .map(({ minutes }) => (
        <span>
          <T kind="{{count}} minute" count={minutes} />
        </span>
      ))
      .getOrElse(() => (
        <></>
      ))}
  </div>
);

export default observer(SessionDuration);
