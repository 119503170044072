export { asMaybe, asTask, runMaybe, runResult, runTask } from '@execonline-inc/error-handling';

/**
 * Generic error structure for fatal errors that do not themselves need further processing.
 */
export interface FatalError {
  message: string;
  details?: Record<string, unknown>;
}

export const fatal = (
  message: FatalError['message'],
  details?: FatalError['details'],
): FatalError => ({ message, details });

/**
 * Sometimes we want to remove the error details when they aren't helpful to output.
 */
export const withoutErrorDetails = ({ message }: FatalError) => ({ message });

export const fail = ({ message, details }: FatalError): void => {
  const util = require('util');
  details
    ? console.error(message, util.inspect(details, { depth: Infinity, colors: true }))
    : console.error(message);
  process.exitCode = 1;
};
