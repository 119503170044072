import { observer } from 'mobx-react';
import * as React from 'react';
import WithCurrentUser from '../../../CurrentUser/Context/WithCurrentUser';
import * as style from '../../TeamSummary/style.module.css';
import coachTeamsSummaryStore from './CoachCommunitySummaryStore';
import CoachRequestReactions from './CoachCommunitySummaryStore/Reactions';
import CommunitiesSummaryContent from './CommunitiesSummaryContent';
import CommunityProgramFilters from './CommunityProgramFilters';
import CommunityProgramOptionsStore from './CommunityProgramOptionsStore';
import CommunityProgramOptionsReactions from './CommunityProgramOptionsStore/Reactions';

class CoachCommunitySummary extends React.Component<{}> {
  coachTeamsSummaryStore: coachTeamsSummaryStore = new coachTeamsSummaryStore();
  teamProgramOptionsStore: CommunityProgramOptionsStore = new CommunityProgramOptionsStore();

  componentDidMount() {
    this.teamProgramOptionsStore.loading();
  }

  render() {
    return (
      <WithCurrentUser
        children={(currentUserResource) => (
          <div className={style.coachTeamsSection}>
            <CommunityProgramFilters
              optionsStore={this.teamProgramOptionsStore}
              coachTeamsSummaryStore={this.coachTeamsSummaryStore}
            />
            <CommunitiesSummaryContent coachTeamsSummaryStore={this.coachTeamsSummaryStore} />
            <CommunityProgramOptionsReactions
              store={this.teamProgramOptionsStore}
              resourceWithTeams={currentUserResource}
            />
            <CoachRequestReactions
              store={this.coachTeamsSummaryStore}
              currentUserResource={currentUserResource}
            />
          </div>
        )}
      />
    );
  }
}

export default observer(CoachCommunitySummary);
