import { PropsWithChildren, useMemo } from 'react';
import { observer } from 'mobx-react';
import ChannelAvailabilityStore from '../../../../../../../Socket/ChannelAvailabilityStore';
import PresenceChannel from '../../../../../../../Socket/PresenceChannel';
import PaymentWizardStore from '../PaymentWizardStore';
import PresenceHandler from './PresenceHandler';

interface Props {
  channelName: string;
  store: PaymentWizardStore;
}

function PaymentPresence({ channelName, store, children }: PropsWithChildren<Props>) {
  const channelAvailabilityStore = useMemo(() => new ChannelAvailabilityStore(), []);

  return (
    <>
      <PresenceChannel name={channelName} channelAvailabilityStore={channelAvailabilityStore} />
      <PresenceHandler channelAvailabilityStore={channelAvailabilityStore} store={store} />
      {children}
    </>
  );
}

export default observer(PaymentPresence);
