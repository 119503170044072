import { useContext } from 'react';
import { CurrentUserLinksContext } from '../../CurrentUser/Context';
import { useFetch, useFindLinkByR } from '../../Fetch';
import { lightTheme } from '../../Native/styles';
import ThemeContext from '../../Themes/ThemeContext';
import ContentFaker from '../ContentFaker';
import { resourcesDashboardResourceDecoder } from './Decoders';
import { Routes } from './Routes';

export function View() {
  const links = useContext(CurrentUserLinksContext);
  const link = useFindLinkByR({ rel: 'resources-dashboard', method: 'get' }, links);
  const state = useFetch(link, resourcesDashboardResourceDecoder);

  switch (state.kind) {
    case 'ready':
      return (
        <ThemeContext.Provider value={lightTheme}>
          <Routes resource={state.data} />
        </ThemeContext.Provider>
      );
    case 'loading':
      return <ContentFaker />;
    case 'errored':
      return <></>;
  }
}
