import { observer } from 'mobx-react';
import * as React from 'react';
import * as style from '../style.module.css';
import Footer from './Footer';

const Background: React.FC = ({ children }) => (
  <div className={style.withBackgroundImage}>
    <div className={style.content}>
      <div className={style.main}>{children}</div>
    </div>
    <Footer />
  </div>
);

export default observer(Background);
