import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const StarIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 12 12">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="m5.801.132-.197.473a1 1 0 0 1-.002.005L4.406 3.425a1 1 0 0 1-.81.602L.7 4.351a1 1 0 0 0-.005 0l-.49.058a.23.23 0 0 0-.131.396l.374.35a1 1 0 0 1 .006.006L2.64 7.239a1 1 0 0 1 .29.923l-.616 3.057v.002l-.105.513a.222.222 0 0 0 .33.234l.43-.257a.998.998 0 0 0 .005-.003l2.506-1.526a1 1 0 0 1 1.04 0l2.506 1.526a.998.998 0 0 0 .005.003l.43.257a.222.222 0 0 0 .33-.234l-.104-.513v-.002L9.07 8.162a1 1 0 0 1 .29-.923l2.187-2.078a1 1 0 0 1 .007-.006l.373-.35a.23.23 0 0 0-.13-.396l-.49-.057a1 1 0 0 0-.006-.001l-2.897-.324a1 1 0 0 1-.81-.602L6.4.61a1 1 0 0 1-.002-.005L6.199.132a.215.215 0 0 0-.398 0Z"
      />
    </SVGIcon>
  );
};

export default observer(StarIcon);
