import { find } from '@execonline-inc/collections';
import { Kettle } from 'kettle-corn';
import { action, computed, observable, reaction } from 'mobx';
import { LectureSlide } from '../../../../LectureStore/Types';
import { Resource } from '../../../../Resource/Types';
import { LectureSegment } from '../../../../LectureStore/Types';

interface SlidesOnly {
  kind: 'slides-only';
}

interface VideoOnly {
  kind: 'video-only';
}

const slidesOnly = (): SlidesOnly => ({
  kind: 'slides-only',
});

const videoOnly = (): VideoOnly => ({
  kind: 'video-only',
});

export type State = SlidesOnly | VideoOnly;

class SwitcherooStore {
  constructor(private segment: LectureSegment, private kettle: Kettle) {
    reaction(
      () => this.isCurrentlyVideoOnly,
      isCurrentlyVideoOnly => {
        if (isCurrentlyVideoOnly) {
          this.videoOnly();
        } else {
          this.slidesOnly();
        }
      },
      { fireImmediately: true }
    );
  }

  @observable
  state: State = slidesOnly();

  @action
  slidesOnly = () => {
    this.state = slidesOnly();
  };

  @action
  videoOnly = () => {
    this.state = videoOnly();
  };

  @computed
  get isCurrentlyVideoOnly(): boolean {
    return this.kettle.videoState.position
      .map(pos => find((slide: Resource<LectureSlide>) => slide.payload.time <= pos))
      .andThen(fn => fn(this.segment.slides.reverse()))
      .map(s => s.payload.videoonly)
      .getOrElseValue(false);
  }
}

export default SwitcherooStore;
