import { observer } from 'mobx-react';
import * as React from 'react';
import PresenceMembersStore from '../../Socket/PresenceChannel/PresenceMembersStore';
import OnlineProgramMembers from './OnlineProgramMembers';
import ProgramMemberHeader from './ProgramMemberHeader';

interface Props {
  presenceMembersStore: PresenceMembersStore;
}

const ProgramMemberContent: React.FC<Props> = ({ presenceMembersStore }) => (
  <>
    <ProgramMemberHeader />
    <OnlineProgramMembers presenceMembersStore={presenceMembersStore} />
  </>
);

export default observer(ProgramMemberContent);
