import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const TimerIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 18 18">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9Zm0 1.5c4.151 0 7.5 3.349 7.5 7.5s-3.349 7.5-7.5 7.5A7.488 7.488 0 0 1 1.5 9c0-4.151 3.349-7.5 7.5-7.5Zm0 .75a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75A.751.751 0 0 0 9 2.25ZM4.758 4.008a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75a.751.751 0 0 0-.75-.75Zm7.945.234L9.375 7.547A1.5 1.5 0 1 0 9 10.5a1.5 1.5 0 0 0 1.453-1.875l3.328-3.328-1.078-1.055ZM3 8.25a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75A.751.751 0 0 0 3 8.25Zm12 0a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75a.751.751 0 0 0-.75-.75ZM4.758 12.492a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75a.751.751 0 0 0-.75-.75Zm8.484 0a.751.751 0 0 0-.75.75c0 .413.337.75.75.75s.75-.337.75-.75a.751.751 0 0 0-.75-.75Z"
      />
    </SVGIcon>
  );
};

export default observer(TimerIcon);
