import { observer } from 'mobx-react';
import { take } from '@execonline-inc/collections';
import { GroupCoachingSessionResources } from '../../../../Types';
import GroupCoachingSessionListItem from '../GroupCoachingSessionListItem';

interface Props {
  sessions: GroupCoachingSessionResources;
}

const MAX_SESSION_COUNT = 3;

const getSessions = (sessions: GroupCoachingSessionResources) => {
  return [...take(MAX_SESSION_COUNT, sessions)];
};

function GroupCoachingSessionList({ sessions }: Props) {
  const collection = getSessions(sessions);

  return (
    <ul className="m-0 flex list-none flex-col gap-2.5">
      {collection.map(({ payload }, order) => (
        <GroupCoachingSessionListItem key={order} order={order} session={payload} />
      ))}
    </ul>
  );
}

export default observer(GroupCoachingSessionList);
