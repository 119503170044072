import * as React from 'react';
import { CalendarEvent } from '../../EventsStore/Types';
import { L, T } from '../../Translations';

interface Props {
  event: CalendarEvent;
}

const EventRange: React.FC<Props> = ({ event }) => (
  <>
    <T
      kind="<date1/>, <time1/> – <date2/>, <time2/>"
      date1={<L localizeable={event.startAt} format="short-month-day-year" />}
      time1={<L localizeable={event.startAt} format="time-of-day" />}
      date2={<L localizeable={event.endAt} format="short-month-day-year" />}
      time2={<L localizeable={event.endAt} format="time-of-day" />}
    />
  </>
);

export default EventRange;
