import * as React from 'react';
import { T } from '../../../Translations';
import * as style from './style.module.css';

interface Props {}

const ProgramChatFooter: React.FC<Props> = () => (
  <div className={style.footer}>
    <p className={style.footerText}>
      <T
        kind="All discussions can only be seen by program members ..."
        link={(content) => (
          <a href="https://www.execonline.com/code-of-conduct" target="_blank">
            {content}
          </a>
        )}
      />
    </p>
  </div>
);

export default ProgramChatFooter;
