import { HTMLAttributes } from 'react';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  shape?: AlertShape;
  status?: AlertStatus;
  variant?: AlertVariant;
}

export type AlertShape =
  | 'rounded-none'
  | 'rounded'
  | 'rounded-sm'
  | 'rounded-md'
  | 'rounded-lg'
  | 'rounded-xl'
  | 'rounded-full';

export type AlertStatus = 'success' | 'info' | 'warning' | 'error';

export type AlertVariant = 'solid' | 'outlined';

export const alertClassNameMapping: Record<AlertStatus, Record<AlertVariant, string>> = {
  success: {
    solid: 'border-forestGreen bg-forestGreen text-neutral-100',
    outlined: 'border-forestGreen bg-honeydew',
  },
  info: {
    solid: 'border-davysGray bg-davysGray text-neutral-100',
    outlined: 'border-davysGray bg-aliceBlue',
  },
  warning: {
    solid: 'border-goldenrod bg-goldenrod text-neutral-100',
    outlined: 'border-goldenrod bg-oldLace',
  },
  error: {
    solid: 'border-rose-700-old bg-rose-700-old text-neutral-100',
    outlined: 'border-rose-700-old bg-mistyRose',
  },
};
