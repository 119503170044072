import { MissingVarError } from '@execonline-inc/environment';
import { MissingLinkError } from '../../LinkyLinky';

export type FreebusyKind = 'freebusy-program' | 'freebusy-coaching';

export interface FreebusyConfig {
  kind: FreebusyKind;
  link: string;
  agenda: string;
  proposerParticipant: ProposerParticipant;
  eventUuid: string;
}

export interface ProposerParticipant {
  email: string;
  name: string;
  role: 'proposer';
  timeZone: string;
}

export interface InitSettings {
  buttonType: string;
  buttonId: string;
}

export interface ManagementConfig {
  canProposeNewTime: boolean;
}

export interface AvailabilityQuery {
  agenda: string;
  management: ManagementConfig;
  participants: ProposerParticipant[];
}

export interface AvailabilityRequest {
  link: string;
  display: string;
  language: string;
}

interface Organizer {
  email: string;
  name: string;
  timeZone: string;
}

export interface Proposal {
  organizer: Organizer;
  participants: ProposerParticipant[];
  startTime: string;
  durationInMin: number;
  subject: string;
  location: string;
  id: string;
}

export interface FreebusyResponse {
  statusCode: number;
  id: string;
}

export interface EventDetails {
  date: Date;
  title: string;
}

export interface FreebusyLoadError {
  kind: 'freebusy-load-error';
  key: string;
}

export const freebusyLoadError = (key: string): FreebusyLoadError => ({
  kind: 'freebusy-load-error',
  key,
});

export type FreebusyInitializationError = MissingVarError | FreebusyLoadError | MissingLinkError;

export type RoleType = 'proposer' | 'requiredAttendee';

export interface Freebusy {
  pickatime: PickATime;
}

export interface PickATime {
  init(settings: InitSettings): void;
  onclick(fn: () => void): void;
  open(availabilityRequest: AvailabilityRequest, availabilityQuery: AvailabilityQuery): void;
  onclose(fn: (response: FreebusyResponse, proposal: Proposal) => void): void;
  onselect(fn: (response: FreebusyResponse, proposal: Proposal) => void): void;
  onerror(fn: (error: FreebusyInitializationError) => void): void;
}
