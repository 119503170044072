import { find } from '@execonline-inc/collections';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CalendarDay } from '../../../Calendaring';
import CalendarStore from '../../../CalendarStore';
import { isSameDay } from '../../../Date';
import { ModuleOrder, ModuleSchedule } from '../../../DayModules';
import CurrentMonthDay from './CurrentMonthDay';
import NextMonthDay from './NextMonthDay';
import PreviousMonthDay from './PreviousMonthDay';
import Today from './Today';

interface Props {
  day: CalendarDay;
  store: CalendarStore;
  moduleSchedule: ModuleSchedule;
}

export interface SubDayProps {
  day: CalendarDay;
  store: CalendarStore;
  modules: ModuleOrder;
}

const Day: React.FC<Props> = (props) => {
  const { day, moduleSchedule } = props;
  const modulesForDay = find(
    (forDay) => isSameDay(forDay.date, day.date),
    moduleSchedule.modulesForDays
  );
  const modules = modulesForDay.map((mfd) => mfd.modules).getOrElseValue([]);

  switch (day.kind) {
    case 'previous-month-day':
      return <PreviousMonthDay {...props} modules={modules} />;
    case 'next-month-day':
      return <NextMonthDay {...props} modules={modules} />;
    case 'today':
      return <Today {...props} modules={modules} />;
    case 'current-month-day':
      return <CurrentMonthDay {...props} modules={modules} />;
  }
};

export default observer(Day);
