import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const ExternalIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 22 23">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M13 0v2h5.563L6.28 14.281 7.72 15.72 20 3.438V9h2V0ZM0 4v18h18V9l-2 2v9H2V6h9l2-2Z"
      />
    </SVGIcon>
  );
};

export default observer(ExternalIcon);
