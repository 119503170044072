import { just } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import SsoLoginStore from '.';
import { sessionStore } from '../../../../Session/Store';
import { SSO } from '../../../../SingleSignOn';
import { TPlainTextKey } from '../../../../Translations';
import { SsoAuth } from '../../DiscoveryPortal/RegisterResourceStore/Types';
import { Created } from './Types';

interface Props {
  store: SsoLoginStore;
  state: Created;
  auth: SsoAuth;
}

const SingleSignOn: React.FC<Props> = ({ store, state, auth }) => (
  <SSO.Initiate
    state={{
      urls: auth.urls,
      userExperience: { kind: 'standard', username: state.emailAddress },
    }}
    onFailure={onFailure(store)}
    onSuccess={onSuccess}
  />
);

export default observer(SingleSignOn);

const onSuccess = (): void => {
  sessionStore.requesting({ messageOnFailure: just('Something went wrong') });
};

const onFailure = (store: SsoLoginStore) =>
  SSO.Errors.onFailure({
    handleSsoError: SSO.Errors.handleSsoError({
      onSessionAlreadyEstablished: onSuccess,
      handler: handleError(store),
    }),
  });

const handleError =
  (store: SsoLoginStore) =>
  (_err: SSO.Error) =>
  (message: TPlainTextKey): void =>
    store.error(message);
