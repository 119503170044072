import React from 'react';
import { observer } from 'mobx-react';
import { AlreadyTranslated, AlreadyTranslatedText } from '../../../../../Translations';
import SegmentStatusIcon from '../SegmentStatusIcon/SegmentStatusIcon';
import { ProgramSegment } from '../../../../../ProgramStore/Types';
import style from './style.module.css';
import ScrollStore from '../../Modules/Store';
import clsx from 'clsx';

interface IProps {
  segment: ProgramSegment;
  scrollStore: ScrollStore;
}

const Segment: React.FC<IProps> = ({ scrollStore, segment }) => {
  const text: AlreadyTranslatedText = {
    kind: 'already-translated-text',
    text: segment.title,
  };
  return (
    <div
      ref={(element: never) => scrollStore.addElements(element)}
      className={clsx(style.progress_step, 'z-[0]')}
      key={segment.id}
    >
      <div className={style.step_count}>
        <SegmentStatusIcon status={segment.status} />
      </div>
      <div className={style.step_description}>
        <AlreadyTranslated content={text} />
      </div>
    </div>
  );
};

export default observer(Segment);
