import { take, takeLastUntil } from '@execonline-inc/collections';
import { Maybe } from 'maybeasy';
import { NonEmptyList, fromArrayMaybe } from 'nonempty-list';
import { CommunityTeamResource } from '../CommunitiesStore/Types';
import { TeamMemberResource, TeamResource } from '../TeamsStore/Types';
import { TeamSummaryResource } from '../TeamsSummaryStore/Types';

const LARGE_TEAM_COUNT = 7;
export const TRUNCATED_DISPLAY_COUNT = 6;

export interface TruncatedTeamMembers {
  truncatedMembers: ReadonlyArray<TeamMemberResource>;
  overflowedMembers: Maybe<NonEmptyList<TeamMemberResource>>;
}

const isTeamLargeToTruncateWithCount = (
  resource: TeamResource | TeamSummaryResource | CommunityTeamResource,
  truncatedDisplayCount: number
): boolean =>
  resource.payload.teamMembers.length > LARGE_TEAM_COUNT &&
  LARGE_TEAM_COUNT >= truncatedDisplayCount;

export const isLargeTeam = (resource: TeamResource | TeamSummaryResource): boolean =>
  resource.payload.teamMembers.length > LARGE_TEAM_COUNT;

export const truncateTeamMembers = (
  teamResource: TeamResource | TeamSummaryResource | CommunityTeamResource,
  truncatedDisplayCount: number
): ReadonlyArray<TeamMemberResource> =>
  isTeamLargeToTruncateWithCount(teamResource, truncatedDisplayCount)
    ? take(truncatedDisplayCount, teamResource.payload.teamMembers)
    : teamResource.payload.teamMembers;

export const teamMembersCountAfterTruncate = (
  teamResource: TeamResource | TeamSummaryResource,
  truncatedDisplayCount: number
): TruncatedTeamMembers => {
  const displayedMembers = truncateTeamMembers(teamResource, truncatedDisplayCount);
  const lastMember = displayedMembers[displayedMembers.length - 1];
  return {
    truncatedMembers: displayedMembers,
    overflowedMembers: fromArrayMaybe(
      takeLastUntil(
        (teamMemberResource: TeamMemberResource) =>
          teamMemberResource.payload.id === lastMember.payload.id,
        teamResource.payload.teamMembers
      )
    ),
  };
};
