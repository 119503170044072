import * as React from 'react';
import { observer } from 'mobx-react';
import CoachTeamsSummaryStore from './CoachCommunitySummaryStore';
import * as style from '../../TeamSummary/style.module.css';
import { fromBool } from '@execonline-inc/maybe-adapter';
import { T } from '../../../Translations';
import { Maybe } from 'maybeasy';
import { CommunitiesResource } from '../../../CommunitiesStore/Types';

interface Props {
  coachTeamsSummaryStore: CoachTeamsSummaryStore;
  communitiesResource: CommunitiesResource;
}

const checkIfteamCountIsMoreThanPageCount = (
  teamsSummaryResource: CommunitiesResource
): Maybe<Boolean> =>
  fromBool(
    teamsSummaryResource.metadata.resultsCount > teamsSummaryResource.metadata.perPage,
    true
  );

const LoadAllCommunities: React.FC<Props> = ({ communitiesResource, coachTeamsSummaryStore }) => {
  return (
    <>
      {checkIfteamCountIsMoreThanPageCount(communitiesResource)
        .map(() => (
          <div className={style.loadAll}>
            <button
              onClick={() => coachTeamsSummaryStore.loadAll()}
              data-test-load-all-teams={true}
            >
              <T kind="Load all results" />
            </button>
          </div>
        ))
        .getOrElse(() => (
          <></>
        ))}
    </>
  );
};

export default observer(LoadAllCommunities);
