import { Route, Router, Switch } from 'react-router-dom';
import history from '../../History';
import { useMessageBus } from '../../MessageBus';
import RootLoaded from '../../RootLoaded';
import { Routes as ServiceRoutes } from '../../Services/Routes';
import { SessionReactions } from '../../Session/Reactions';
import { sessionStore } from '../../Session/Store';
import WhenApplicationSession from '../../Session/WhenApplicationSession';
import { TranslationsLoader, loader } from '../../Translations';
import Loader from '../Loader';
import TimeOutReactions from '../TimeOutReactions';
import Tooling from '../Tooling';
import AppReactions from './AppReactions';
import AppRoutes from './AppRoutes';

export function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/service/*" component={ServiceRoutes} />
        <Route path="*" component={_App} />
      </Switch>
    </Router>
  );
}

function _App() {
  useMessageBus();

  return (
    <TranslationsLoader loader={loader('en')} loading={<Loader delay={0} />}>
      <RootLoaded>
        <Tooling />
        <SessionReactions store={sessionStore} fireImmediately />
        <TimeOutReactions store={sessionStore} fireImmediately />
        <AppReactions />
        <WhenApplicationSession>
          <AppRoutes />
        </WhenApplicationSession>
      </RootLoaded>
    </TranslationsLoader>
  );
}
