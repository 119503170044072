import { Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import ProgramStore from '../../ProgramStore';
import { ProgramDetailResource, ProgramSegment } from '../../ProgramStore/Types';
import RenderContext from '../RenderContext';
import ProgramOverviewRenderer from './ProgramOverviewRenderer';

interface Props {
  programDetailResource: ProgramDetailResource;
  currentSegment: Maybe<ProgramSegment>;
  programStore: ProgramStore;
}

const ProgramOverview: React.FC<Props> = ({
  programDetailResource,
  currentSegment,
  programStore,
}) => {
  return (
    <RenderContext
      render={(renderContext) => (
        <ProgramOverviewRenderer
          programDetailResource={programDetailResource}
          currentSegment={currentSegment}
          position={renderContext.position}
          programStore={programStore}
        />
      )}
    />
  );
};

export default observer(ProgramOverview);
