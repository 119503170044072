namespace iSpringCommand {
  export interface gotoTimestamp {
    kind: 'gotoTimestamp';
    slide: number;
    slideStep: number;
    timeOffset: number;
    autostart: boolean;
  }

  export type Command = gotoTimestamp;

  export interface Message<C extends Command> {
    kind: 'ispring-player-command';
    command: C;
  }
}

const toCommandMessage = <C extends iSpringCommand.Command>(
  command: C
): iSpringCommand.Message<C> => ({
  kind: 'ispring-player-command',
  command,
});

export const gotoTimestamp = (
  data: Omit<iSpringCommand.gotoTimestamp, 'kind'>
): iSpringCommand.Message<iSpringCommand.gotoTimestamp> =>
  toCommandMessage({ kind: 'gotoTimestamp', ...data });
