import { List, ListItem } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { useCaseLink } from '../../../../../ClientRoutes';
import History from '../../../../../History';
import { UseCaseItem } from '../../../../Dropdown/Types';
import { DiscoveryStateProvider } from '../../Common';
import UseCaseInfo from '../../Common/NavigationMenuItems/UseCaseMenuItem/UseCaseInfo';
import { dropdownMenuItems } from '../../UseCaseMenu/Types';

export interface Props {
  onClick: () => void;
}

function UseCaseMenuList({ onClick }: Props) {
  const handleMenuItemClick = (item: UseCaseItem) => () => {
    onClick();
    History.push(useCaseLink(item));
  };

  return (
    <DiscoveryStateProvider>
      {({ items }) => (
        <List role="menu">
          {dropdownMenuItems(items).map((item) => {
            return (
              <ListItem
                key={item.name.text}
                role="menuitem"
                className="group"
                onClick={handleMenuItemClick(item)}
              >
                <UseCaseInfo data={item.content} />
              </ListItem>
            );
          })}
        </List>
      )}
    </DiscoveryStateProvider>
  );
}

export default observer(UseCaseMenuList);
