import { observer } from 'mobx-react';
import { DropdownFilter } from '../CommonFilter';
import DurationFilterStore from './Store';
import { DurationFilterOptions } from './Types';

interface Props {
  store: DurationFilterStore;
  filterOptions: DurationFilterOptions[];
}

function DropdownDurationFilter({ store, filterOptions }: Props) {
  return <DropdownFilter label="Time Commitment" store={store} filterOptions={filterOptions} />;
}

export default observer(DropdownDurationFilter);
