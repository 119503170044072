import { observer } from 'mobx-react';
import { TPlainTextKey, T } from '../../Translations';
import * as style from './style.module.css';

interface Props {
  title: TPlainTextKey;
  completedSessionCount: number;
}

const CompletedSessionCount: React.FC<Props> = ({ title, completedSessionCount }) => (
  <dl className={style.dl}>
    <dt>
      <T kind={title} />
    </dt>
    <dd className={style.date}>
      <T
        kind="You have completed total of <sessionCount>{{count}} session(s)</sessionCount> with your coach"
        count={completedSessionCount}
        sessionCount={(translated) => <strong>{translated}</strong>}
      />
    </dd>
  </dl>
);

export default observer(CompletedSessionCount);
