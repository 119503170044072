import SegmentStore from '../../../../../SegmentStore';
import { StringFormField } from '../../../Types';

interface Waiting {
  kind: 'waiting';
}

interface Initializing {
  kind: 'initializing';
  segmentStore: SegmentStore;
  formField: StringFormField;
}

interface Ready {
  kind: 'ready';
  formField: StringFormField;
  value: string;
}

interface AutoSaving {
  kind: 'auto-saving';
  formField: StringFormField;
  value: string;
}

export type State = Waiting | Initializing | Ready | AutoSaving;

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const initializing = (
  segmentStore: SegmentStore,
  formField: StringFormField,
): Initializing => ({
  kind: 'initializing',
  segmentStore,
  formField,
});

export const ready = (formField: StringFormField, value: string): Ready => ({
  kind: 'ready',
  formField,
  value,
});

export const autoSaving = (formField: StringFormField, value: string): AutoSaving => ({
  kind: 'auto-saving',
  formField,
  value,
});
