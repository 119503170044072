import { equals } from '@execonline-inc/collections';
import { toTask, when } from '@execonline-inc/maybe-adapter';
import { Maybe } from 'maybeasy';
import { SegmentResource } from '../../SegmentStore/Types';

export const whenSegmentT = (segment: Maybe<SegmentResource>) => {
  return toTask<string, SegmentResource>('segment-error')(segment);
};

export const whenPresentationStyleT = (segment: SegmentResource) => {
  return toTask<string, string>('presentation-style-message')(
    when(equals('Streamlined'), segment.payload.presentationStyle),
  );
};
