import { Error } from '../ErrorHandling';
import { Link } from '../Resource/Types';
import { ResumerResource } from './Types';

interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
  link: Link;
}

interface Ready {
  kind: 'ready';
  resumerResource: ResumerResource;
}

export const waiting = (): Waiting => ({
  kind: 'waiting',
});

export const loading = (link: Link): Loading => ({
  kind: 'loading',
  link,
});

export const ready = (resumerResource: ResumerResource): Ready => ({
  kind: 'ready',
  resumerResource,
});

export type ResumerRedirectState = Waiting | Loading | Ready | Error;
