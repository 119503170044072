interface Waiting {
  kind: 'waiting';
}

interface Loading {
  kind: 'loading';
}

interface Open {
  kind: 'open';
}

interface Reopened {
  kind: 'reopened';
}

export const reopened = (): Reopened => ({ kind: 'reopened' });

interface Closed {
  kind: 'closed';
}

interface Confirming {
  kind: 'confirming';
}

export const confirming = (): Confirming => ({ kind: 'confirming' });

export const closed = (): Closed => ({ kind: 'closed' });

export const open = (): Open => ({ kind: 'open' });

export const waiting = (): Waiting => ({ kind: 'waiting' });

export const loading = (): Loading => ({ kind: 'loading' });

export type State = Waiting | Loading | Open | Closed | Confirming | Reopened;
