import { cn } from '@execonline-inc/execonline-ui';
import { when } from '@execonline-inc/maybe-adapter';
import { observer } from 'mobx-react';

interface Props {
  total: number;
  currentStep: number;
}

function ProgressBar({ total, currentStep }: Props) {
  const range = (start: number, stop: number, step: number) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  return (
    <div className="flex gap-x-1">
      {range(1, total, 1).map((step) => (
        <div
          key={step}
          className={cn(
            'h-2 flex-grow',
            when(step <= currentStep, 'bg-teal-400').getOrElseValue('bg-[#e4e4e4]'),
          )}
        />
      ))}
    </div>
  );
}

export default observer(ProgressBar);
