import * as React from 'react';
import { observer } from 'mobx-react';
import SVGIcon from '../SVGIcon';
import { defaultTheme } from '../../../Themes';

const MessagesIcon: React.FC = () => {
  return (
    <SVGIcon viewBox="0 0 28 26">
      <path
        fill={defaultTheme.variables['icon-on-dark']}
        d="M0 0v16h4v5.094l1.625-1.313L10.344 16H20V0H0Zm2 2h16v12H9.656l-.281.219L6 16.906V14H2V2Zm20 2v2h4v12h-4v2.906L18.344 18h-7.5l-2.5 2h9.312L24 25.094V20h4V4h-6Z"
      />
    </SVGIcon>
  );
};

export default observer(MessagesIcon);
