import { NotTranslated } from '@execonline-inc/translations';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CoachingSelectedCoachNameNode } from '../../ContentEmbed/Types';

interface Props {
  node: CoachingSelectedCoachNameNode;
}
const SelectedCoachNameEmbeddable: React.FC<Props> = ({ node }) => {
  return <NotTranslated text={node.name} />;
};

export default observer(SelectedCoachNameEmbeddable);
