import { find } from '@execonline-inc/collections';
import ReactionComponent, { RCProps } from '../../../../../../../ReactionComponent';
import CoachSelectStore from '../../Store';
import CoachSelectOptionStore from '../Store';
import { SelectFieldValue } from '../../../../../Types';

interface Props extends RCProps<CoachSelectOptionStore> {
  coachSelectStore: CoachSelectStore;
}

class CoachSelectOptionFieldValueReactions extends ReactionComponent<
  CoachSelectOptionStore,
  SelectFieldValue[],
  Props
> {
  tester = (): SelectFieldValue[] => this.props.coachSelectStore.value;

  effect = (selections: SelectFieldValue[]) => {
    const { store } = this.props;
    find((selection) => selection.value === store.id.toString(), selections)
      .do(() => store.select())
      .elseDo(() => store.ready());
  };
}

export default CoachSelectOptionFieldValueReactions;
